import React, {Component} from "react";
import cloneDeep from "lodash/cloneDeep";
import classes from "./chips.module.scss";
import PropTypes from "prop-types";

class Chips extends Component {

  state = {
    inputValue: ''
  };

  keyDown = e => {
    if (e.which === 9) { // Tab
      e.preventDefault();
      const isUniqueItem = this.props.chips.every(chip => chip.label !== this.state.inputValue.trim());
      if (this.state.inputValue.trim() && isUniqueItem) {
        const newChips = cloneDeep(this.props.chips);
        newChips.push({label: this.state.inputValue, id: null});
        this.props.onChange(newChips);
        this.setState({inputValue: ''});
      }
    }
  };

  change = e => {
    this.setState({inputValue: e.target.value});
  };

  deleteChip = chip => {
    let newChips = cloneDeep(this.props.chips);
    newChips = newChips.filter(newChip => newChip.label !== chip.label);
    this.props.onChange(newChips);
  };

  render() {
    const {chips, name, placeholder} = this.props;

    return (
      <div className={classes.chips}>
        {chips.map((chip, i) => <div key={i} className={classes.chip}>{chip.label}<i onClick={() => this.deleteChip(chip)} className={classes.delete_chip} /></div>)}
        <input name={name} onChange={this.change} onKeyDown={this.keyDown} value={this.state.inputValue} placeholder={placeholder} type="text"/>
      </div>
    );
  }
}

Chips.propTypes = {
  onChange: PropTypes.func.isRequired,
  chips: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default Chips;
