import React from 'react';
import classes from './info-item.module.scss';

export const InfoItem = ({ name, value }) => {
  return (
    <div className={classes.container}>
      <span className={classes.name}>{name}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};
