import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { InspectionDateUnlinkFromCarModalContent } from './unlink-from-car.content';
import { OpenInspectionDateUnlinkCarModalParams } from './unlink-from-car.props';

export const useInspectionDateUnlinkFromCarModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openInspectionDateUnlinkFromCarModal = (params: OpenInspectionDateUnlinkCarModalParams) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Prüftermin unzugeordnet',
        children: <InspectionDateUnlinkFromCarModalContent {...params} />,
        allButtonsHidden: true,
        buttons: [{ type: 'cancel', action: closeAlert }]
      })
    );
  };

  return { openInspectionDateUnlinkFromCarModal };
};
