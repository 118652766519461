import React from 'react';
import { StyledInfoHintWithoutBackground } from 'components/info-hint/info-hint-view.styled';
import { InfoHintAddingInformationToFinanceRateProps } from './adding-information-to-finance-rate.props';
import { StyledInfoHintLink } from './adding-information-to-finance-rate.styled';
import { VEHICLE_DETAIL_ELEMENTS_ID } from 'pages/existing-vehicle-page/utils';
import { ReplaceText } from 'components/replace-text/replace-text';
import { useDispatch } from 'react-redux';
import { setVehicleDetailsMainTab } from 'actions/pages/vehicle-details.action';
import { useVehicleDetailsMainTabsData } from 'pages/existing-vehicle-page/components/layout/main-tabs/hooks/use-main-tabs/use-main-tabs';

export const InfoHintAddingInformationToFinanceRate = ({ text }: InfoHintAddingInformationToFinanceRateProps) => {
  const dispatch = useDispatch();
  const { getTabIndex } = useVehicleDetailsMainTabsData();

  return (
    <StyledInfoHintWithoutBackground type='warning' iconSize={12}>
      <ReplaceText
        id='infoHintInformationToFinanceRate'
        size='12'
        text={text}
        replaceWord='Finanzrate'
        replaceComponent={() => (
          <StyledInfoHintLink
            href={`#${VEHICLE_DETAIL_ELEMENTS_ID.tab.mainTab}`}
            onClick={() => dispatch(setVehicleDetailsMainTab({ activeIndex: getTabIndex('leasing') }))}
          >
            Finanzrate
          </StyledInfoHintLink>
        )}
      />
    </StyledInfoHintWithoutBackground>
  );
};
