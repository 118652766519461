import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OrderStatusSectionProps, VehicleOfferEventData } from './order-status-section.props';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { getStatusSectionEventDescriptions, takeStatusSectionLatestEvent, takeStatusSectionOldestEvent } from './utils';
import { AppPage, User, VehicleOfferEventDescription } from 'common/interfaces';
import OrderStatus from './order-status';
import {
  StyledOrderStatusSectionContainer,
  StyledPanelPlaceholder,
  StyledVerticalLine
} from './order-status-section.styled';
import Pagination from 'components/pagination';
import { useLocation } from 'react-router-dom';
import { useAllAppPages } from 'components/order-status-section/use-all-app-pages';
import useVehicleOffersEventDescriptions from 'hooks/react-query/use-vehicle-offers-event-descriptions';
import { Flex, Text } from 'common/common-components.styled';
import Switch from 'react-switch';
import { useTheme } from 'styled-components';
import { VEHICLE_OFFER_EVENT_DESCRIPTIONS } from '../../constants';
import debounce from 'lodash/debounce';
import { Section } from 'pages/existing-vehicle-page/existing-vehicle';
import { PAGES } from 'common/enums';

const getOrderStatus = (
  events: VehicleOfferEventData[],
  eventDescription: VehicleOfferEventDescription,
  mainRoleId: string,
  statusesContainerWidth: number,
  appPage?: AppPage
) => {
  if (
    eventDescription.eventsCount &&
    eventDescription.eventsCount > 1 &&
    ![
      VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationCreated.id,
      VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded.id,
      VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleConfigurationBasedOnGRVCreated.id
    ].includes(eventDescription.uds_fahrzeugbestellungeneventdescriptionid)
  ) {
    return Array(eventDescription.eventsCount)
      .fill(null)
      .map((_, i) => (
        <OrderStatus
          key={eventDescription.uds_fahrzeugbestellungeneventdescriptionid + `_${i}`}
          eventDescription={eventDescription}
          event={takeStatusSectionLatestEvent(
            events.filter(
              event => event.uds_eventdescription_id === eventDescription.uds_fahrzeugbestellungeneventdescriptionid
            )
          )}
          mainRoleId={mainRoleId}
          appPage={appPage}
          statusesContainerWidth={statusesContainerWidth}
        />
      ));
  }
  return (
    <OrderStatus
      key={eventDescription.uds_fahrzeugbestellungeneventdescriptionid}
      eventDescription={eventDescription}
      event={takeStatusSectionOldestEvent(
        events.filter(
          event => event.uds_eventdescription_id === eventDescription.uds_fahrzeugbestellungeneventdescriptionid
        )
      )}
      mainRoleId={mainRoleId}
      appPage={appPage}
      statusesContainerWidth={statusesContainerWidth}
    />
  );
};

const OrderStatusSection = ({ events }: OrderStatusSectionProps) => {
  const { vehicleOffersEventDescriptions } = useVehicleOffersEventDescriptions();
  const user = useTypedSelector<User>(state => state.app.user);
  const location = useLocation();
  const theme = useTheme();
  const statusesContainer = useRef<HTMLDivElement | null>(null);

  const [paginationParams, setPaginationParams] = useState(defaultPaginationParams);
  const [statusesContainerWidth, setStatusesContainerWidth] = useState(0);
  const [showOnlyDoneEvents, setShowOnlyDoneEvents] = useState(true);

  const { appPages } = useAllAppPages();

  const isDealerDetailsPage = location.pathname.includes(PAGES.REQUESTS_DETAILS);
  const pageUrl = location.pathname.replace(/\/$/, '');
  const mainRoleId = user.mainRole?.id;
  const appPage = useMemo(() => appPages?.find(appPage => appPage.uds_page_url === pageUrl), [appPages]);
  const eventDescriptions = useMemo(
    () =>
      getStatusSectionEventDescriptions(
        vehicleOffersEventDescriptions || [],
        events,
        mainRoleId,
        appPage,
        showOnlyDoneEvents
      ).flatMap(eventDescription => {
        return getOrderStatus(events, eventDescription, mainRoleId, statusesContainerWidth, appPage);
      }),
    [vehicleOffersEventDescriptions, events, appPage, showOnlyDoneEvents, statusesContainerWidth]
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setStatusesContainerWidth(statusesContainer.current?.offsetWidth || 0);
    };
    const handleWindowResizeDebounced = debounce(handleWindowResize, 50);

    handleWindowResizeDebounced();
    window.addEventListener('resize', handleWindowResizeDebounced);

    return () => {
      window.removeEventListener('resize', handleWindowResizeDebounced);
    };
  }, []);

  const pageChangeHandler = (page: number) => {
    setPaginationParams(paginationParams => ({ ...paginationParams, start: page }));
  };

  const toggleShowOnlyDoneEvents = () => {
    setShowOnlyDoneEvents(flag => !flag);
    pageChangeHandler(1);
  };

  if (isDealerDetailsPage && !eventDescriptions.length) {
    return null;
  }

  return (
    <Section>
      <div>
        <Flex justify='space-between' align='center' bottom='50'>
          <Text bold size='22' color='grey800'>
            Status
          </Text>
          <Flex>
            <Text pointer onClick={toggleShowOnlyDoneEvents} right='10'>
              Nur bereits erledigte Positionen anzeigen
            </Text>
            <Switch
              offColor={theme.colors.grey250}
              onColor={theme.colors.blue}
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={toggleShowOnlyDoneEvents}
              checked={showOnlyDoneEvents}
            />
          </Flex>
        </Flex>
        <div ref={statusesContainer}>
          <StyledOrderStatusSectionContainer>
            {eventDescriptions.length ? (
              <>
                {eventDescriptions.slice(
                  paginationParams.start === 1 ? 0 : paginationParams.number * (paginationParams.start - 1),
                  paginationParams.number * paginationParams.start
                )}

                <StyledVerticalLine />
              </>
            ) : (
              <StyledPanelPlaceholder
                title='Keine Statusänderungen'
                description='Die Bestellung enthält noche keine Statusänderungen.'
                children={null}
              />
            )}
          </StyledOrderStatusSectionContainer>

          {eventDescriptions.length > paginationParams.number && (
            <Pagination
              activePage={paginationParams.start}
              itemsCountPerPage={paginationParams.number}
              totalItemsCount={eventDescriptions.length}
              onPageChange={pageChangeHandler}
              noScroll={true}
            />
          )}
        </div>
      </div>
    </Section>
  );
};

const defaultPaginationParams = {
  start: 1,
  number: 10
};

export default OrderStatusSection;
