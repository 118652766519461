import { DocumentTypeByAppQueryOptions } from 'hooks/react-query/document-type/document-type-by-app/document-type-by-app.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export const documentTypeByAppDataQueryOptions: DocumentTypeByAppQueryOptions = {
  fetch: { isShowLoading: false }
};

export const getFilesByDocumentTypeQueryOptions: QueryHookOptions = {
  fetch: { isShowLoading: false }
};
