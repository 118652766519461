import { OpenPageProps } from 'components/dashboard-component-chart/dashboard.props';
import { AutocompleteFilterFromState } from 'hooks/use-session-filter';
import { useHistory } from 'react-router-dom';

export const useOpenPageViaDashBoard = (company: { id: string | null; name: string | null }) => {
  const history = useHistory<AutocompleteFilterFromState>();

  const openPage = ({ page, companyCrmName, filterForServer = {}, filterForClient = {}, search }: OpenPageProps) => {
    return () => {
      history.push({
        pathname: page,
        search,
        state: {
          filterForServer: { [companyCrmName]: company.id, ...filterForServer },
          filterForClient: { [companyCrmName]: company.name, ...filterForClient }
        }
      });
    };
  };

  return { openPage };
};
