import { FormSection } from 'components/sections/form-section/form-section';
import React from 'react';
import { CreateNewNotesIcon } from './components/buttons/create-new-notes';
import useNotes from 'hooks/use-notes';
import { NotesEntityNames } from 'hooks/use-notes/use-notes.types';
import { useQuery } from 'hooks';

export const VehicleDetailsNotes = () => {
  const query = useQuery();
  const vehicleId = query.id as string;
  const { getContent, toggleCreateNewNote } = useNotes({ entityName: NotesEntityNames.Vehicle, id: vehicleId });

  return (
    <FormSection title='Notizen' titleRightComponent={<CreateNewNotesIcon onClick={toggleCreateNewNote} />}>
      {getContent()}
    </FormSection>
  );
};
