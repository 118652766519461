/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styles from './components.module.scss';
import axios from 'axios';
import { Distance } from 'common/common-components.styled';
import { ComponentsProps } from './components.props';

const key = 'IQDVgSj5_AiCN9a_enJNSXUTv2YIAlznMkes-yVLIYg';

const ZipcodeFieldComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldName,
  fieldPlaceholder = '11111',
  smallField = false,
  optional = false,
  noNeedDEvalidation = false
}: ComponentsProps) => {
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = reactHookFormData;

  const validateGermanyPostalCodeAsync = async (postalCode: string, type: string) => {
    const url = `https://atlas.microsoft.com/search/address/structured/json?subscription-key=${key}&api-version=1.0&language=de-DE&countryCode=DE&postalCode=${postalCode}`;
    try {
      const { data } = await axios.get(url);
      if (
        watch(fieldRegister).search(/^[0-9]{1,5}$/) === 0 &&
        (data.results.length > 0 || watch(fieldRegister) === '')
      ) {
        clearErrors(type);
      }
      if (data.results.length <= 0) {
        setError(type, {
          type: 'custom',
          message: 'Die eingegebene Postleitzahl ist in Deutschland nicht registriert. Bitte gib eine andere PLZ ein.'
        });
      }
      return data.results.length > 0;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const changeValue = (value: string) => {
    noNeedDEvalidation ? setValue(fieldRegister, value.toUpperCase()) : setValue(fieldRegister, value);
  };

  useEffect(() => {
    if (
      !noNeedDEvalidation &&
      !!watch(fieldRegister) &&
      watch(fieldRegister).search(/^[0-9]{1,5}$/) === 0 &&
      watch(fieldRegister).length > 4
    ) {
      validateGermanyPostalCodeAsync(watch(fieldRegister), fieldRegister);
    }
    if (noNeedDEvalidation && !!watch(fieldRegister) && watch(fieldRegister).search(/^[A-Z0-9-]{1,10}$/) === -1) {
      setError(fieldRegister, {
        type: 'custom',
        message: 'Das Feld darf nur Zahlen, Buchstaben und "-" enthalten.'
      });
    }
    if (
      !optional &&
      watch(fieldRegister) !== '' &&
      !noNeedDEvalidation &&
      watch(fieldRegister).search(/^[0-9]{1,5}$/) === -1
    ) {
      setError(fieldRegister, {
        type: 'custom',
        message: 'Die eingegebene Postleitzahl ist in Deutschland nicht registriert. Bitte gib eine andere PLZ ein.'
      });
    }
    if (
      (!noNeedDEvalidation && optional) ||
      watch(fieldRegister) === '' ||
      (noNeedDEvalidation && !!watch(fieldRegister) && watch(fieldRegister).search(/^[A-Z0-9-]{1,10}$/) === 0)
    ) {
      clearErrors(fieldRegister);
    }
  }, [watch(fieldRegister)]);

  return (
    <div className={smallField ? styles.one_field : styles.field_container}>
      <div className={styles.form_name}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>(optional)</span>}
        </span>
      </div>
      <input
        placeholder={fieldPlaceholder}
        {...register(fieldRegister, {
          required: optional ? false : 'PLZ erforderlich.',
          pattern: {
            value: !noNeedDEvalidation ? /^[0-9]{1,5}$/ : /^[A-Z0-9-]{1,10}$/,
            message: !noNeedDEvalidation
              ? 'Die eingegebene Postleitzahl ist in Deutschland nicht registriert. Bitte gib eine andere PLZ ein.'
              : 'Das Feld darf nur Zahlen, Buchstaben und "-" enthalten.'
          },
          maxLength: !noNeedDEvalidation ? 5 : 10
        })}
        type='text'
        maxLength={!noNeedDEvalidation ? 5 : 10}
        onChange={e => changeValue(e.target.value)}
        autoComplete='nope'
        className={errors[fieldRegister] ? styles.input__icons_err__text : styles.input_standart}
        id={`inputZipCode${fieldName}`}
      />
      <div className={styles.err__row}>
        {errors[fieldRegister] && typeof errors[fieldRegister]?.message === 'string' && (
          <>
            <img src='/assets/images/err-icon.svg' />
            <p>{errors[fieldRegister]?.message as string}</p>
          </>
        )}
      </div>

      <Distance top='10' />
    </div>
  );
};

export default ZipcodeFieldComponent;
