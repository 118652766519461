import styled, { css } from 'styled-components';
import { DoubleRightArrow } from 'components/icons-new/double-right-arrow';
import { Flex } from 'common/common-components.styled';

interface StyledSideMenuProps {
  isMinimized: boolean;
  open: boolean;
  isDesktop: boolean;
}

export const StyledSideMenu = styled.section<StyledSideMenuProps>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-width: ${({ isMinimized }) => (isMinimized ? '65px' : '300px')};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  height: auto;
  z-index: 101;
  transition: all 0.1s ease-in;

  ${({ isDesktop, open }) =>
    !isDesktop &&
    css`
      transform: translateX(${open ? '0px' : '-300px'});
      position: fixed;
      top: 0;
      bottom: 0;
    `};
`;

interface StyledDoubleRightArrowProps {
  isMinimized: boolean;
}

export const StyledDoubleRightArrow = styled(DoubleRightArrow)<StyledDoubleRightArrowProps>`
  transform: rotate(${({ isMinimized }) => (isMinimized ? '0deg' : '180deg')});
`;

export const StyledHeader = styled(Flex)<{ isMinimized: boolean }>`
  justify-content: ${({ isMinimized }) => (isMinimized ? 'center' : 'initial')};
  align-items: center;
  padding: ${({ isMinimized }) => (isMinimized ? '0 15px' : '0 20px')};
  min-height: 60px;
  background: ${({ theme }) => theme.colors.blue};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
`;
