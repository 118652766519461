import { IconProps } from 'common/interfaces';
import React from 'react';

export const RefreshArrow = ({ width = 16, height = 18, className, color = '#102F3F' }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_669_6369)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line
        d='M13.6708 9.99599C13.6389 11.303 13.1675 12.5612 12.3328 13.5675C11.4981 14.5737 10.3486 15.2695 9.06994 15.5423C7.79129 15.8152 6.45799 15.6492 5.28521 15.0714C4.11243 14.4934 3.16854 13.5373 2.60586 12.3572C2.04317 11.177 1.8945 9.8417 2.18385 8.56668C2.4732 7.29167 3.18369 6.15132 4.20066 5.32962C5.21767 4.50794 6.48178 4.05286 7.78916 4.03781C9.09559 4.02277 10.369 4.44802 11.4043 5.24499C11.4049 5.24554 11.4057 5.2461 11.4064 5.24666L12.3618 4.00682C12.361 4.00619 12.3602 4.00556 12.3594 4.00493C12.1192 3.82004 11.869 3.65092 11.6102 3.49808C10.4533 2.81471 9.12624 2.45706 7.77113 2.47266C6.11198 2.49176 4.50761 3.0693 3.21696 4.1121C1.92631 5.15489 1.02463 6.60211 0.657413 8.22026C0.290199 9.83841 0.478866 11.5331 1.19298 13.0308C1.90709 14.5286 3.10499 15.742 4.59339 16.4754C6.0818 17.2088 7.77387 17.4194 9.39662 17.0731C11.0194 16.7268 12.4781 15.8439 13.5375 14.5668C14.4027 13.5238 14.9603 12.2676 15.1577 10.9384C15.202 10.6401 15.2282 10.3382 15.2356 10.0342L13.6708 9.99599Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line
        d='M12.7671 4.93483L11.6746 1.3607C11.6013 1.12047 11.347 0.985256 11.1067 1.05868C10.8665 1.13211 10.7313 1.38638 10.8047 1.6266L11.6312 4.33077L8.69555 5.22804C8.45535 5.30146 8.32012 5.55573 8.3935 5.79597C8.46694 6.03623 8.7212 6.17145 8.96147 6.09801L12.7671 4.93483Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_669_6369'>
        <rect width='15.7143' height='17.1429' fill='white' transform='translate(0.142822 0.428589)' />
      </clipPath>
    </defs>
  </svg>
);
