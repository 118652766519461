import { getEntityByFieldQuery } from 'services/crm-entity-service';
import { crmDataTypes } from '../../constants';
import { User } from 'common/interfaces';
import { ConditionOperator } from 'common/enums';

export const getDriversActiveCars = (user: User) =>
  getEntityByFieldQuery({
    entityName: 'new_fuhrpark',
    columns: ['new_name', 'new_fuhrparkid', 'statecode'],
    conditions: [
      {
        attributeTypeCode: crmDataTypes.Lookup,
        attributeName: 'new_sparteid',
        conditionOperator: ConditionOperator.In,
        value: user.childCompanies.concat([
          {
            id: user.companyId,
            name: user.companyName
          }
        ])
      },
      {
        attributeTypeCode: crmDataTypes.Lookup,
        attributeName: 'new_fahrerid',
        conditionOperator: ConditionOperator.Equal,
        value: [
          {
            id: user.id,
            logicalName: '',
            name: ''
          }
        ]
      },
      {
        attributeTypeCode: crmDataTypes.Picklist,
        attributeName: 'statecode',
        conditionOperator: ConditionOperator.Equal,
        value: [{ value: '0' }]
      }
    ]
  });
