import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledHorizontalLineTooltipHoverBlock = styled(Flex)`
  position: absolute;
  right: 0;
  width: 80%;
  height: 5px;
  opacity: 1;
`;
