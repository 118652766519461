import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { GetAppointmentCalendarResponse } from './use-get-appointment-calendar.props';

export const useGetCalendarAppointmentQuery = (vehicleIdProps?: string) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || (queryLocation.id as string | undefined);

  const { fetch } = useFetch<ResponseModel<GetAppointmentCalendarResponse>>({
    loadingKey: 'getCalendarAppointment',
    ...fleetRequestConfig.getCalendarAppointment(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.FleetCalendarAppointment, vehicleId], () => fetch(), {
    enabled: !!vehicleId
  });

  return { getCalendarAppointmentQuery: data?.data.data, ...restQueryProps };
};
