import { FormFieldTypes } from 'common/enums';

export const fromDateField = [
  {
    name: 'from',
    type: FormFieldTypes.Date,
    defaultValue: '',
    label: 'Von',
    placeholder: 'TT.MM.JJJJ',
    validation: {}
  }
];

export const toDateField = [
  {
    name: 'to',
    type: FormFieldTypes.Date,
    defaultValue: '',
    label: 'Bis',
    placeholder: 'TT.MM.JJJJ',
    validation: {}
  }
];

export const calenDarStartDateField = [
  {
    name: 'startDate',
    type: FormFieldTypes.Date,
    defaultValue: '',
    label: 'Datum',
    placeholder: 'TT.MM.JJJJ',
    validation: {}
  }
];
