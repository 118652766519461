import { SessionFilter } from '../use-session-filter';
import { useQuery } from 'react-query';
import { DEFAULT_TABLE_DATA } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { PAGES } from 'common/enums';
import { /*getFahrzeugkostenFilters,*/ getFahrzeugkostenTable } from 'services/vehicle-costs-service';
import { ResponseModel } from 'common/interfaces';
import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

export const useVehicleCostsQuery = (tableParams: TableParams, sessionFilter: SessionFilter) => {

  const {
    data: tableDataQuery,
    isFetching: isFetchingTable,
    isFetched
  } = useQuery<AxiosResponse<ResponseModel>>(
    [PAGES.VEHICLE_COSTS, tableParams, sessionFilter?.filterForServer],
    () => getFahrzeugkostenTable(tableParams, sessionFilter?.filterForServer),
    { enabled: !isEmpty(sessionFilter?.filterForServer) }
  );

  const isFetching = isFetchingTable;

  return {
    table: tableDataQuery?.data?.data || DEFAULT_TABLE_DATA,
    isFetched,
    isFetching
  };
};

interface TableParams {
  pagination: { start: number; number: number };
  sort: { predicate?: string; reverse?: boolean };
};
