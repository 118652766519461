import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CalendarPlanningIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.742432' y='2.65938' width='17.9998' height='5.83461' fill={hovered ? 'white' : 'none'} />
      {hovered && (
        <path d='M16.4178 17.3128L0.75268 17.3129V6.32283H18.7422L18.7422 15.7433L16.4178 17.3128Z' fill={fill} />
      )}
      <path
        d='M9.50562 17.3518L13.8604 17.3518L13.8604 18.8659L18.5055 15.8376L13.8604 12.8094L13.8604 14.3235L12.1025 14.3235'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M4.40332 6.32039H18.7423'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.28455 2.65938H5.62354'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.79328 2.65938H0.742432V17.3034H7.20812H13.7192V18.8659L18.719 15.741L13.7192 12.6161V14.1786H11.8443'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7763 2.65938H11.1152'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.7423 12.17V2.65938H16.6067'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.18286 2.65938C3.18286 1.8204 3.73201 1.13396 4.4032 1.13396C5.07439 1.13396 5.62354 1.8204 5.62354 2.65938C5.62354 3.49836 5.07439 4.1848 4.4032 4.1848'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.67456 2.65935C8.67456 1.82037 9.22371 1.13393 9.8949 1.13393C10.5661 1.13393 11.1152 1.82037 11.1152 2.65935C11.1152 3.49833 10.5661 4.18477 9.8949 4.18477'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.166 2.65935C14.166 1.82037 14.7152 1.13393 15.3864 1.13393C16.0575 1.13393 16.6067 1.82037 16.6067 2.65935C16.6067 3.49833 16.0575 4.18477 15.3864 4.18477'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
