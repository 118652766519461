import React, { useEffect, useRef, useState } from 'react';
import { Flex } from 'common/common-components.styled';
import CarLogo from './car-logo';
import { CarContainer, HiddenText } from './calendar-view-styled';
import moment from 'moment';
import { asyncInterval } from 'utils/async-interval';
import { Moment } from 'moment-timezone';
import CarEvent from './car-event';
import { AnyObject } from 'common/interfaces';
import { Car, Event } from './calendar-view';
import DayOfWEek from './day-of-week';

interface Props {
  isOddCarInList: boolean;
  car: Car;
  refetch: () => void;
  indexOfRow: number;
  timeGap: number;
}

const CarRow = ({ car, isOddCarInList, refetch, indexOfRow, timeGap }: Props) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [rowSize, setRowSize] = useState({
    width: 0,
    height: 100
  });
  const [dayCellWidth, setDayCellWidth] = useState(0);
  const [sizeOfSpace, setSizeOfSpace] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);

  useEffect(() => {
    asyncInterval(() => document.getElementById(car.id), 50, 40)
      .then(eventRow => {
        setRowSize({ width: eventRow.clientWidth, height: eventRow.offsetHeight });
        setDayCellWidth(eventRow.firstElementChild.offsetWidth);
        setSizeOfSpace((eventRow.clientWidth - eventRow.firstElementChild.offsetWidth * 7) / 6);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (rowRef.current && rowRef.current.firstElementChild) {
        setRowSize({ width: rowRef.current.clientWidth, height: rowRef.current.clientHeight || 100 });
        setDayCellWidth(rowRef.current.firstElementChild?.clientWidth);
        setSizeOfSpace((rowRef.current.clientWidth - rowRef.current.firstElementChild?.clientWidth * 7) / 6);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const filteredEvents = car.events
      .filter(
        (event: Event) =>
          moment(event.startDate).isSame(moment(car.days[0]), 'week') ||
          moment(event.endDate).isSame(moment(car.days[0]), 'week') ||
          moment(car.days[0]).isBetween(event.startDate, event.endDate)
      )
      .sort((a: Event, b: Event) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf());
    const eventsWithOverlapData: Event[] = filteredEvents.map((event: Event, index: number) => {
      const nextEvent = filteredEvents[index + 1];
      const prevEvent = filteredEvents[index - 1];
      const overlapData = getOverlapData(event, nextEvent, prevEvent);

      return {
        ...event,
        ...overlapData,
        left: getPosition(event.startDate),
        width: getWidthOfEvent(event.startDate, event.endDate),
        height: overlapData.overlap ? 45 : rowSize.height - 5
      };
    });

    const overlapGroups: Event[][] = [];
    eventsWithOverlapData.forEach(event => {
      if (event.nextEventOverlap && !event.prevEventOverlap) {
        overlapGroups.push([event]);
      }
      if (event.prevEventOverlap) {
        overlapGroups[overlapGroups.length - 1].push(event);
      }
    });

    const events: Event[] = eventsWithOverlapData.map((event: Event) => {
      const overlapGroup = overlapGroups.find(group =>
        group.some(groupEvent => groupEvent.uds_poolkalenderid === event.uds_poolkalenderid)
      );
      const indexInOverlapGroup = overlapGroup?.findIndex(
        groupEvent => groupEvent.uds_poolkalenderid === event.uds_poolkalenderid
      );
      return {
        ...event,
        top: getTopPosition(event.overlap, indexInOverlapGroup, overlapGroup)
      };
    });

    const overlappedEventMaxAmount = overlapGroups.sort((a, b) => b.length - a.length)[0]?.length;
    if (overlappedEventMaxAmount && overlappedEventMaxAmount > 2) {
      setRowSize({
        ...rowSize,
        height: 50 * overlappedEventMaxAmount
      });
    }

    setFilteredEvents(events);
  }, [car.events, rowSize.width, rowSize.height, dayCellWidth]);

  const getTopPosition = (overlap: boolean, indexInOverlapGroup?: number, overlapGroup?: Event[]) => {
    if (overlap && indexInOverlapGroup && overlapGroup) {
      if (indexInOverlapGroup > 0) {
        return indexInOverlapGroup * 50;
      }
    }

    return 0;
  };

  const getPosition = (date: Moment, isOneDayRent?: boolean) => {
    const startedFromPassedWeeks = moment(car.days[0]).startOf('day').valueOf() > moment(date).startOf('day').valueOf();
    const endedInFutureWeeks =
      moment(car.days[car.days.length - 1])
        .startOf('day')
        .valueOf() < moment(date).startOf('day').valueOf();

    if (startedFromPassedWeeks) {
      return 0;
    }

    if (endedInFutureWeeks) {
      return rowSize.width;
    }

    const day = moment(date).isoWeekday();
    const percentsOfPassedDayTime = dayCellWidth / 12;
    const passedDayWidth = (percentsOfPassedDayTime * moment(date).hours()) / 2;
    const sizeOfSpaces = isOneDayRent ? sizeOfSpace * (day - 1) - sizeOfSpace : sizeOfSpace * (day - 1);
    const passedDays = day - 1;
    const left = passedDays * dayCellWidth + passedDayWidth + sizeOfSpaces;
    return left;
  };

  const getWidthOfEvent = (startDate: Moment, endDate: Moment) => {
    const startPosition = getPosition(startDate);
    const isOneDayRent = moment(endDate).startOf('day').valueOf() === moment(endDate).valueOf();
    const endPosition = getPosition(endDate, isOneDayRent);
    return endPosition - startPosition;
  };

  const getOverlap = (event: Event, comparedEvent: Event) => {
    return (
      moment(event.startDate).isBetween(comparedEvent.startDate, comparedEvent.endDate, undefined, '[]') ||
      moment(comparedEvent.startDate).isBetween(event.startDate, event.endDate, undefined, '[]')
    );
  };

  const getOverlapData = (event: Event, nextEvent: Event | undefined, prevEvent: Event | undefined) => {
    let nextEventOverlap = false;
    let prevEventOverlap = false;

    if (nextEvent) {
      nextEventOverlap = getOverlap(event, nextEvent);
    }

    if (prevEvent) {
      prevEventOverlap = getOverlap(event, prevEvent);
    }

    return {
      overlap: nextEventOverlap || prevEventOverlap,
      nextEventOverlap,
      prevEventOverlap
    };
  };

  const getAbilityToBookCar = (day: Moment) => {
    const sortedCarEvents = car.events.sort(
      (a: Event, b: Event) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
    );

    const eventForSpecificDay = sortedCarEvents.find((event: AnyObject) =>
      moment(day.startOf('day')).isBetween(
        moment(event.startDate).startOf('day'),
        moment(event.endDate).startOf('day'),
        undefined,
        '[]'
      )
    );

    return !eventForSpecificDay;
  };

  const detectHover = (day: Moment, mouseEnterEvent: boolean) => {
    if (!mouseEnterEvent) {
      console.log('mouse leaved');
      return;
    }
    const isBookingavailable = getAbilityToBookCar(day);
    if (isBookingavailable && mouseEnterEvent) {
      console.log('mouse entered');
    }
  };

  return (
    <>
      {car.days.length && (
        <Flex height={rowSize.height.toString()} style={{ maxHeight: rowSize.height }}>
          <CarContainer isOddCarInList={isOddCarInList} height={rowSize.height}>
            <Flex width='40%' align='center' justify='center' right='13'>
              <CarLogo id={car.id} spinnerSize='20px' />
            </Flex>
            <Flex direction='column' width='60%' padding='10'>
              <HiddenText>{car.name}</HiddenText>
              <HiddenText>{car.model}</HiddenText>
            </Flex>
          </CarContainer>

          <Flex width='80%' justify='space-between' ref={rowRef} id={car.id} position='relative'>
            {car.days.map((day: Moment, index) => (
              <DayOfWEek key={index} car={car} day={day} rowHeight={rowSize.height} timeGap={timeGap} />
            ))}

            {Boolean(filteredEvents.length) &&
              filteredEvents.map((event: Event) => (
                <CarEvent key={event.uds_poolkalenderid} event={event} refetch={refetch} indexOfRow={indexOfRow} />
              ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CarRow;
