import React, { FC } from 'react';
import { TableComponentsProps } from './table-components.props';

export const CarManufacturer: FC<TableComponentsProps> = ({ row }) => {
  if (!row) {
    return null;
  }

  return (
    <span>{!row.new_hersteller && !row.new_model ? '-' : `${row.new_hersteller || ''} ${row.new_model || ''}`}</span>
  );
};
