import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const StyledOptionalEquipmentCell = styled(Flex)`
  padding: 25px 10px;
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.media.tabletHorizontal}) {
    padding: 20px 5px;
  }
`;

export const StyledCodeCell = styled(StyledOptionalEquipmentCell)`
  width: 15%;
`;

export const StyledDescriptionCell = styled(StyledOptionalEquipmentCell)`
  width: 35%;
`;

export const StyledNetListPriceCell = styled(StyledOptionalEquipmentCell)`
  width: 25%;
`;

export const StyledListPriceCell = styled(StyledOptionalEquipmentCell)`
  width: 25%;
`;
