import styled, { css } from 'styled-components';

export const StyledRow = styled.div<{ borderIsNeeded?: boolean; color?: string }>`
  text-decoration: none;
  background-color: #fff;
  padding: 11px 25px;
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  color: #22273a;
  position: relative;

  ${({ borderIsNeeded, color }) =>
    borderIsNeeded &&
    color &&
    css`
      &::before {
        content: ' ';
        position: absolute;
        border-color: ${color};
        border-radius: 5px 0 0 5px;
        border-style: solid;
        height: 100%;
        width: 5px;
        margin-top: -11px;
        left: -5px;
      }
    `};
`;
