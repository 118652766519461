import styled from 'styled-components';
import TableComponent from 'components/table-component';
import ExpandIcon from 'components/icons/expand-icon.js';

export const StyledTableComponent = styled(TableComponent)`
  margin-bottom: 20px !important;
`;

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  max-width: 1600px;
  min-width: 800px;
`;

interface StyledExpandIconProps {
  open: boolean;
}

export const StyledExpandIcon = styled(ExpandIcon)<StyledExpandIconProps>`
  fill: ${({ open, theme }) => (open ? theme.colors.grey500 : theme.colors.grey900)};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const StyledTextContainer = styled.div`
  margin-right: 20px;
  width:100%;
`;
