import { LookupResponse, PicklistResponse } from 'common/interfaces';

export interface UseCarInspectionDateQueryParams {
  fuhrparkId?: string;
  accountId: string;
}

export type GetCarInspectionDateResponse = {
  allPruftermines: GetCarInspectionDateResponseItem[];
  assignedPruftermines: GetCarInspectionDateResponseItem[];
  pruftermineHistories: InspectionDateHistoryResponseItem[];
};

export type GetCarInspectionDateResponseItem = {
  id: string;
  attributes: Partial<{
    uds_name: string;
    uds_inspection_intervalcode: Partial<PicklistResponse>;
    uds_protocol_requiredcode: Partial<PicklistResponse<'Ja' | 'Nein', GetCarInspectionDateProtocolRequiredEnum>>;
    uds_inspection_datesid: string;
  }>;
};

export enum GetCarInspectionDateProtocolRequiredEnum {
  Yes = 100000000,
  No = 100000001
}

export type InspectionDateHistoryResponseItem = {
  id: string;
  attributes: Partial<{
    uds_next_inspection: Partial<PicklistResponse<string, string>>;
    uds_statuscode: PicklistResponse<string, InspectionDateHistoryStatusValueEnum>;
    uds_last_inspection: Partial<PicklistResponse<string, string>>;
    uds_carid: LookupResponse;
    uds_name: string;
    uds_inspection_type: string;
    uds_protocolcode: Partial<PicklistResponse<string, GetCarInspectionDateProtocolRequiredEnum>>;
    uds_inspection_historyid: string;
    uds_inspection_datesid: Partial<LookupResponse>;
  }>;
};

export enum InspectionDateHistoryStatusValueEnum {
  Completed = 100000000,
  Canceled = 100000001,
  Overdue = 100000002,
  Active = 100000003
}
