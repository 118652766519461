import React, { Fragment, Component } from 'react';
import VehicleList from '../../components/vehicle-list';
import Overlay from '../../components/overlay';
import Footer from '../../components/footer/footer-view';
import Header from '../../components/header';
import Filters from '../../components/filters';
import Footnotes from './footnotes';
import Sidebar from '../../components/sidebar';
import { withLinkedInTag } from '../../hoc/withLinkedInTag';
import PropTypes from 'prop-types';
import ComparisonPanel from "../../components/comparison-panel";
import {Route} from "react-router-dom";
import BreadcrumbsPanel from "../../components/breadcrumbs-panel";

class RootPage extends Component {
  componentDidMount() {
    this.props.showOverlay();
  }

  render() {
    return (
      <Fragment>
        <Sidebar open={this.props.sidebarOpen} onSetOpen={() => this.props.toggleSidebar(!this.props.sidebarOpen)} />
        <ComparisonPanel />
        <Header />
        <Overlay />
        <section style={{background: 'linear-gradient(167.08deg, rgba(32, 114, 128, 0) -9.15%, rgba(32, 114, 128, 0.15) 97.48%)'}}>
          <div
            style={{ paddingTop: '15px' }}
          >
            <div className='container clearfix vehicle-list-container'>
              <Filters />
              <VehicleList />
            </div>

            <Footnotes />
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

RootPage.propTypes = {
  showOverlay: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired
};

export default withLinkedInTag(RootPage);
