import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { PAGES } from 'common/enums';
import useOverlay from 'hooks/use-overlay';
import { getRepairStatus } from 'pages/repair-status/utils';
import { defaultTableData } from 'pages/repair-status/authorized/repair-status';
import { getResponseFormData } from 'utils/get-response-data';
import { ResponseModel } from 'common/interfaces';
import { IRepairPartnerTable } from 'pages/repair-status/components/repair-status-item/repair-status-item.props';
import { QueryHookOptions } from '../react-query.props';
import { AxiosResponse } from 'axios';

export const useRepairStatusQuery = (
  tableParams: TableParams | { fuhrparkId: string },
  isHideLoading: boolean = false,
  isModify: boolean = false,
  options: QueryHookOptions<AxiosResponse<ResponseModel<Partial<IRepairPartnerTable>[]>>> = {}
) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const { data, isFetching, isFetched } = useQuery(
    [PAGES.REPAIRED, tableParams],
    () => getRepairStatus(tableParams),
    options.options
  );

  //@ts-ignore
  const table = useMemo<ResponseModel<Partial<IRepairPartnerTable>[]>>(() => {
    if (!data) {
      return { data: [] };
    }
    return isModify ? { data: getResponseFormData(data.data, [{ name: 'items', type: 'array' }], true) } : data.data;
  }, [JSON.stringify(data), isModify]);

  useEffect(() => {
    if (isHideLoading) {
      return;
    }

    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [isFetching]);

  return {
    table: table?.data || defaultTableData,
    isFetched,
    isFetching
  };
};

interface TableParams {
  pagination: { start: number; number: number };
}
