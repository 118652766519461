import React from 'react';

function DoneIcon({ fill = '#30BF30', width = 16, height = 16 }) {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.28293 7.03999C4.97365 6.73071 4.47221 6.73071 4.16293 7.03999C3.85365 7.34927 3.85365 7.85071 4.16293 8.15998L6.49582 10.4929C6.88634 10.8834 7.51951 10.8834 7.91003 10.4929L14.6429 3.76C14.9522 3.45072 14.9522 2.94927 14.6429 2.63999C14.3336 2.33071 13.8322 2.33072 13.5229 2.64L7.20292 8.95999L5.28293 7.03999ZM14.7024 7.99999C14.537 7.99999 14.4036 8.13432 14.396 8.2995C14.2381 11.6819 11.4226 14.4 8.00293 14.4C4.48292 14.4 1.60293 11.52 1.60293 7.99999C1.60293 4.48001 4.48292 1.6 8.00293 1.6C8.57817 1.6 9.08878 1.66463 9.59287 1.79389C9.69822 1.8209 9.8106 1.79231 9.8875 1.71541L10.7004 0.90261C10.8577 0.745202 10.7943 0.477616 10.5808 0.414525C9.75329 0.169957 8.87809 0 8.00293 0C3.60293 0 0.00292969 3.6 0.00292969 7.99999C0.00292969 12.4 3.60293 16 8.00293 16C12.3025 16 15.8382 12.5624 15.9973 8.29983C16.0035 8.13456 15.8688 7.99999 15.7035 7.99999H14.7024Z'
        fill={fill}
      />
    </svg>
  );
}

export default DoneIcon;
