import { useCallback } from 'react';
import { TimeMeasure } from 'common/enums';

export const useTime = () => {
  const stringifyTime = useCallback((timeValue: number): string => {
    if (timeValue < 10) {
      return `0${timeValue}`;
    }

    return timeValue.toString();
  }, []);

  const parseTime = useCallback((timeValue: string): number => {
    if (timeValue[0] === '0') {
      return +timeValue.replace('0', '');
    }

    return +timeValue;
  }, []);

  const stringifyTimeList = useCallback(
    (timeList: number[]): string[] => timeList.map(item => stringifyTime(item)),
    [stringifyTime]
  );

  const parseTimeList = useCallback(
    (timeList: string[]): number[] => timeList.map(item => parseTime(item)),
    [parseTime]
  );

  const getUpperTimeLimit = useCallback((timeMeasure: TimeMeasure) => {
    switch (timeMeasure) {
      case TimeMeasure.Hour:
        return 23;

      case TimeMeasure.Minutes:
        return 59;

      default:
        return -1;
    }
  }, []);

  const addTimeForward = (timeList: number[], timeMeasure: TimeMeasure) => {
    const cloneData = [...timeList];

    if (cloneData[cloneData.length - 1] === getUpperTimeLimit(timeMeasure)) {
      cloneData.push(0);
    } else {
      cloneData.push(cloneData[cloneData.length - 1] + 1);
    }

    return cloneData;
  };

  const addTimeBackward = (timeList: number[], timeMeasure: TimeMeasure) => {
    const cloneData = [...timeList];

    if (cloneData[0] === 0) {
      cloneData.unshift(getUpperTimeLimit(timeMeasure));
    } else {
      cloneData.unshift(cloneData[0] - 1);
    }

    return cloneData;
  };

  return {
    stringifyTime,
    parseTime,
    stringifyTimeList,
    parseTimeList,
    getUpperTimeLimit,
    addTimeBackward,
    addTimeForward
  };
};
