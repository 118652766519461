import React from 'react';
import classes from './alert.module.scss';
import { PrefixForId } from '../../common/enums';
import capitalize from 'lodash/capitalize';

interface ButtonProps {
  type: string;
  title: string;
  hide?: boolean;
  disabled?: boolean;
  action: () => void;
}

export interface AlertData {
  buttons: ButtonProps[];
  title: string;
  className?: string;
  children?: any;
  contentPaddingBottomDisabled?:boolean;
  allButtonsHidden?: boolean;
  hideCloseIcon?: boolean;
  onCloseAlert?: () => void;
}

export interface AlertProps {
  parameters: AlertData;
}

const Alert = ({ parameters }: AlertProps) => {
  const cancelButton = parameters.buttons.find(button => button.type === 'cancel');
  const isHideCancelButton = !cancelButton || parameters.hideCloseIcon;
  const isContentPaddingBottomDisabled = parameters?.contentPaddingBottomDisabled;

  return (
    <>
      <div
        id={PrefixForId.Modal + 'Alert'}
        className={parameters.className ? `${classes.body} ${parameters.className}` : classes.body}
      >
        {parameters.title && (
          <div className={classes.header}>
            <h5 id={PrefixForId.Modal + PrefixForId.Title}>{parameters.title}</h5>
            {!isHideCancelButton && (
              <img
                onClick={parameters.onCloseAlert || cancelButton?.action}
                src='/assets/images/alert_close.svg'
                id={PrefixForId.Modal + PrefixForId.Image + PrefixForId.Close}
                alt='alert_close'
              />
            )}
          </div>
        )}
        <div className={!isContentPaddingBottomDisabled ? classes.content : classes.content_padding_bottom_disabled}>
          {parameters.children}
        </div>
        {!parameters.allButtonsHidden && (
          <div className={classes.buttons}>
            {parameters.buttons.map(
              button =>
                !button.hide && (
                  <button
                    key={button.title}
                    onClick={button.action}
                    className={`button-mobexo ${
                      button.type === 'cancel' || button.type === 'navigation' || button.type === 'secondary'
                        ? 'button-mobexo__secondary'
                        : 'button-mobexo__main'
                    }  ${button.disabled ? 'button-mobexo__disable' : ''}`}
                    id={PrefixForId.Modal + PrefixForId.Button + capitalize(button.type)}
                    disabled={button.disabled}
                  >
                    {button.title}
                  </button>
                )
            )}
          </div>
        )}
      </div>
      <div style={{ height: '20px' }} />
    </>
  );
};

export default Alert;
