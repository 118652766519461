import { connect } from 'react-redux';

import Sorting from './sorting-view';
import { changeSort } from '../../actions/app_action';

const mapStateToProps = state => ({
  predicate: state.app.smartTable.sort.predicate,
  reverse: state.app.smartTable.sort.reverse
});

const mapDispatchToProps = dispatch => ({
  changeHandler(sort) {
    dispatch(changeSort(sort));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Sorting);
