import React from 'react';
import classes from './kilometer.module.scss';
import { USER_ROLES } from '../../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { AllDataIsAvailableDriver } from './components/mapping/driver/all-data-is-available/all-data-is-available';
import { AllDataIsAvailable } from './components/mapping/common/all-data-is-available/all-data-is-available';
import { MileageAndSomeOfLeasingDataIsNotAvailable } from './components/mapping/common/mileage-and-some-of-leasing-data-is-not-available/mileage-and-some-of-leasing-data-is-not-available';
import { KilometerWidgetNoMilageData } from './components/mapping/common/no-milage-data/no-milage-data';
import { SomeOfLeasingDataIsNotAvailable } from './components/mapping/common/some-of-leasing-data-is-not-available/some-of-leasing-data-is-not-available';
import { useIsShowKilometerWidget } from './hooks/use-is-show-kilometer-widget';
import { NoMilageDriver } from './components/mapping/driver/no-milage-data/no-milage-data';

export const KilometerWidget = () => {
  const isDriverRole = useIsUserHaveRole(USER_ROLES.driver.id);
  const { actualMileageIsAvailable, isAllLeasingFieldsAvailable } = useIsShowKilometerWidget();

  const getMappingForCommonRoles = () => {
    if (actualMileageIsAvailable && !isAllLeasingFieldsAvailable) {
      return <SomeOfLeasingDataIsNotAvailable />;
    }

    if (!actualMileageIsAvailable && !isAllLeasingFieldsAvailable) {
      return <MileageAndSomeOfLeasingDataIsNotAvailable />;
    }

    if (!actualMileageIsAvailable && isAllLeasingFieldsAvailable) {
      return <KilometerWidgetNoMilageData />;
    }

    return <AllDataIsAvailable />;
  };

  const getMappingForDriver = () => {
    if (!actualMileageIsAvailable) {
      return <NoMilageDriver />;
    }
    return <AllDataIsAvailableDriver />;
  };

  const renderChildren = () => {
    if (isDriverRole) {
      return getMappingForDriver();
    }

    return getMappingForCommonRoles();
  };

  return <div className={classes.container}>{renderChildren()}</div>;
};
