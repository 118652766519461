import { IModifiedTableRow } from 'hooks/react-query/incidents/my-requests/my-requests.props';
import { IActiveStep } from 'reducers/page/my-requests/my-requests.props';

export const ACTIVE_STEP = 'ACTIVE_STEP';
export const IS_EDIT_MODE = 'IS_EDIT_MODE';
export const IS_ACTIVE_DAMAGE_PREVIEW = 'IS_ACTIVE_DAMAGE_PREVIEW';
export const UPDATE_INCIDENT_ID = 'UPDATE_INCIDENT_ID';
export const COMPLETED_INCIDENT_ID = 'COMPLETED_INCIDENT_ID';
export const OPEN_INCIDENT_DATA = 'OPEN_INCIDENT_DATA';

export const setActiveStep = (activeStep: IActiveStep[]) => ({
  type: ACTIVE_STEP,
  payload: activeStep
});

export const setIsEditMode = (isEditMode: boolean) => ({
  type: IS_EDIT_MODE,
  payload: isEditMode
});

export const setIsActiveDamagePreview = (isActiveDamagePreview: boolean) => ({
  type: IS_ACTIVE_DAMAGE_PREVIEW,
  payload: isActiveDamagePreview
});

export const setUpdateIncidentId = (updateIncidentId: string) => ({
  type: UPDATE_INCIDENT_ID,
  payload: updateIncidentId
});

export const setCompletedIncidentId = (completedIncidentId: string) => ({
  type: COMPLETED_INCIDENT_ID,
  payload: completedIncidentId
});

export const setOpenIncidentData = (rowData: Partial<IModifiedTableRow>) => ({
  type: OPEN_INCIDENT_DATA,
  payload: rowData
});
