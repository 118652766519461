import { AxiosRequestConfig } from 'axios';
import { LetterPayload } from './letter-modal.types';

class LetterModalRequestConfig {
  getLetter = (data: LetterPayload): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/getemail',
    data
  });
}

export const letterModalRequestConfig = new LetterModalRequestConfig();
