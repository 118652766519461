import React from 'react';
import { FormFieldTypes } from 'common/enums';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useGetPoolCarClassesQuery } from 'hooks/react-query/fleet/use-get-pool-car-classes/use-get-pool-car-classes.query';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../../../../../constants';

export const usePoolCarClassesField = () => {
  const isEnableEditModePoolCarClass = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { poolCarClassesForOptionSet } = useGetPoolCarClassesQuery(undefined, {
    options: { enabled: isEnableEditModePoolCarClass },
    fetch: { isShowLoading: false }
  });

  const poolCarClassesField: FormInfoItemEditableProps = {
    value: fuhrpark?.uds_poolcar_classid,
    label: 'Pool-Car Klasse',
    isDisableEditMode: !isEnableEditModePoolCarClass,
    editableComponent: (
      <VehicleDetailsEditForm
        formFields={[
          generateFormField.optionSet({
            name: 'uds_poolcar_classid',
            type: FormFieldTypes.Lookup,
            defaultValue: fuhrpark?.uds_poolcar_classid_id,
            options: poolCarClassesForOptionSet
          })
        ]}
      />
    )
  };

  return { poolCarClassesField };
};
