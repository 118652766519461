import React, {Component} from 'react';
import PropTypes from "prop-types";
import MenuOverlay from "../menu-overlay";

class Menu extends Component {

  render() {
    const {component, params, closeMenu} = this.props;

    return (
      component && <MenuOverlay params={params} component={component} closeMenu={closeMenu} />
    );
  }
}

Menu.propTypes = {
  params: PropTypes.objectOf({
    menuPosition: PropTypes.objectOf({
      top: PropTypes.number,
      left: PropTypes.number
    })
  }),
  component: PropTypes.object,
  closeMenu: PropTypes.func.isRequired
};

export default Menu;
