import React, { useState, useCallback } from 'react';
import { FilterDataProps, PlateNumberProps, CompanyProps, FilterWrapperProps } from '../lease-end-page.props';
import {
  ClearAllFiltersButton,
  Filter,
  FilterContainer,
  PickersContainer,
  NumberPicker,
  KilometersFilter
} from 'components/filter-items';
import { FilterButton, FilterInput } from 'components/filter-items/filter-elements';
import { Flex } from 'common/common-components.styled';
import { AnyObject } from 'chart.js/dist/types/basic';
import { EMPTY_FILTER_STATE } from '../leasing-end-page';
import { convertDateToServer } from 'utils/convert-date-to-server';
import { StyledDroplistItem, StyledAddressLine, StyledPlateNumberDroplistItem } from './lease-end-filters.styled';
import moment from 'moment';
import { lastAndCurrentYears, threeYearsArray } from '../utils';
import { getCustomizedOrder } from '../utils';
import { CustomizedColumnInfo } from 'components/columns-settings/column-settings.types';

interface LeasingEndFiltersProps {
  filterData: FilterDataProps;
  sessionFilter: any;
  setSessionFilter: any;
  goToFirstPage: () => void;
  customizedColumnsInfo: CustomizedColumnInfo[] | null;
}

const plateNumberDroplistItem = (item: PlateNumberProps) => {
  return <StyledPlateNumberDroplistItem isActive={item.state === 0}>{item.item}</StyledPlateNumberDroplistItem>;
};

const companyWithAdress = (item: CompanyProps) => {
  return (
    <>
      <StyledDroplistItem>
        {item.value}
        <StyledAddressLine>{`${item.postalcode}, ${item.city}`}</StyledAddressLine>
      </StyledDroplistItem>
    </>
  );
};

const LeasingEndFilters = ({
  filterData,
  sessionFilter,
  setSessionFilter,
  goToFirstPage,
  customizedColumnsInfo
}: LeasingEndFiltersProps) => {
  const [isOpen, setOpen] = useState('');

  const getDigitalRangeLabel = useCallback(
    (field: string) => {
      return sessionFilter.filterForClient[field]
        ? `${sessionFilter.filterForClient[field].minValue} - ${sessionFilter.filterForClient[field].maxValue}`
        : sessionFilter.filterForClient[field];
    },
    [sessionFilter]
  );

  const getMinValue = useCallback(
    (field: string) => {
      return sessionFilter.filterForClient[field] ? sessionFilter.filterForClient[field].minValue : null;
    },
    [sessionFilter]
  );

  const getMaxValue = useCallback(
    (field: string) => {
      return sessionFilter.filterForClient[field] ? sessionFilter.filterForClient[field].maxValue : null;
    },
    [sessionFilter]
  );

  const getMonthsRange = useCallback(
    (field: string) => {
      if (!sessionFilter.filterForServer[field]) {
        return;
      }

      const parsedStartDate = moment(sessionFilter.filterForServer[field].start, 'YYYY-MM-DD').format('MM.YYYY');
      const parsedEndDate = moment(sessionFilter.filterForServer[field].end, 'YYYY-MM-DD').format('MM.YYYY');
      if (parsedStartDate === parsedEndDate) {
        return parsedStartDate;
      }
      return `${parsedStartDate} - ${parsedEndDate}`;
    },
    [sessionFilter]
  );

  const plateNumber = sessionFilter.filterForClient.new_name;
  const manufacturerLabel = sessionFilter.filterForClient.new_hersteller;
  const modelLabel = sessionFilter.filterForClient.new_model;
  const companyNameLabel = sessionFilter.filterForClient.new_sparteid;
  const fahrerLabel = sessionFilter.filterForClient.new_fahrerid;
  const costManagerLabel = sessionFilter.filterForClient.new_kstverantw;
  const firstRegistrationLabel = sessionFilter.filterForClient.new_ezl;
  const leaseDurationLabel = getDigitalRangeLabel('uds_leasinglaufzeit_decimal');
  const leasingEndLabel = sessionFilter.filterForClient.new_leasingende;
  const leaseEndExtendedLabel = sessionFilter.filterForClient.uds_leaseenddate_extended;
  const leaseReturnLabel = sessionFilter.filterForClient.uds_lease_return_date;
  const replacementStatusLabel = sessionFilter.filterForClient.uds_replacementstate;
  const deliveryDateValue = sessionFilter.filterForClient.uds_vsl_liefertermin_laut_handler;
  const deliveryDateLabel = getMonthsRange('uds_vsl_liefertermin_laut_handler');
  const actualMileageLabel = getDigitalRangeLabel('uds_actualmileage');
  const averageMileageLabel = getDigitalRangeLabel('uds_averagemonthlymileage');
  const estimatedMileageLabel = getDigitalRangeLabel('uds_summeprojektion');
  const totalMileageLabel = getDigitalRangeLabel('uds_laufleistung');
  const leaseReturnStatusLabel = sessionFilter.filterForClient.uds_fahrzeugruckgabe;
  const checklistLabel = sessionFilter.filterForClient.uds_preparationstate;

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'kennzeichen':
        return 'new_name';

      case 'hersteller':
        return 'new_hersteller';

      case 'modell':
        return 'new_model';

      case 'unternehmen':
        return 'new_sparteid';

      case 'fahrer':
        return 'new_fahrerid';

      case 'kostenverantwortlicher':
        return 'new_kstverantw';

      case 'erstzulassung':
        return 'new_ezl';

      case 'leasingdauer':
        return 'uds_leasinglaufzeit_decimal';

      case 'leasingende':
        return 'new_leasingende';

      case 'leasingvertrag stillschweigend verlängert bis':
        return 'uds_leaseenddate_extended';

      case 'leasingrückgabe bis':
        return 'uds_lease_return_date';

      case 'gesamtlaufleistung':
        return 'uds_laufleistung';

      case 'kilometerstand':
        return 'uds_actualmileage';

      case 'durschnittliche kilometer pro monat':
        return 'uds_averagemonthlymileage';

      case 'voraussichtlicher kilometerstand bei leasingende':
        return 'uds_summeprojektion';

      case 'ersatzbeschaffung':
        return 'uds_replacementstate';

      case 'voraussichtliches lieferungsdatum von ersatzbeschaffung':
        return 'uds_vsl_liefertermin_laut_handler';

      case 'leasingrückgabe-status':
        return 'uds_fahrzeugruckgabe';

      case 'checkliste für fahrzeugrückgabe':
        return 'uds_preparationstate';

      default:
        return '';
    }
  };

  const toggleFilterIsOpen = useCallback((title = '') => {
    setOpen(title);
  }, []);

  const saveFilter = useCallback(
    async (title: string, serverValue: any, clientValue: string) => {
      setSessionFilter(
        { [convertHeaderToCrmName(title)]: serverValue },
        { [convertHeaderToCrmName(title)]: clientValue }
      );
    },
    [setSessionFilter]
  );

  const handleInput = useCallback(
    (title: string, value: any) => {
      toggleFilterIsOpen();
      goToFirstPage();

      switch (title) {
        case 'Kennzeichen':
          saveFilter(title, value.item, value.item);
          break;

        case 'Unternehmen':
        case 'Fahrer':
        case 'Kostenverantwortlicher':
          saveFilter(title, value.id, value.value);
          break;

        case 'Hersteller':
        case 'Modell':
          saveFilter(title, value, value as string);
          break;
        case 'Erstzulassung':
        case 'Leasingende':
        case 'Leasingvertrag stillschweigend verlängert bis':
        case 'Leasingrückgabe bis':
        case 'Voraussichtliches Lieferungsdatum von Ersatzbeschaffung':
          saveFilter(title, convertDateToServer(value, { end: 'end', start: 'start' }), value);
          break;
        case 'Leasingdauer':
        case 'Kilometerstand':
        case 'Durschnittliche Kilometer pro Monat':
        case 'Voraussichtlicher Kilometerstand bei Leasingende':
        case 'Gesamtlaufleistung':
          saveFilter(title, { start: value.minValue, end: value.maxValue }, value);
          break;

        case 'Ersatzbeschaffung':
        case 'Leasingrückgabe-Status':
        case 'Checkliste für Fahrzeugrückgabe':
          saveFilter(title, value.value, value.label);
          break;
      }
    },
    [saveFilter]
  );

  const clearFilter = useCallback(
    (title = '') => {
      toggleFilterIsOpen();
      goToFirstPage();

      if (!title) {
        setSessionFilter(EMPTY_FILTER_STATE, EMPTY_FILTER_STATE);
        return;
      }

      saveFilter(title, null, '');
    },
    [goToFirstPage, saveFilter, setSessionFilter, toggleFilterIsOpen]
  );

  const getButtonsForFilter = (buttonsArray: any[], title: string, count?: number, buttonLabelKey?: string) => {
    return buttonsArray.map((item: any, index: number) => {
      if (count) {
        if (index < count) {
          return (
            <FilterButton
              key={index}
              title={title}
              handleInput={handleInput}
              label={buttonLabelKey ? item[buttonLabelKey] : item}
              value={item}
            />
          );
        }
        return;
      }
      return (
        <FilterButton
          key={index}
          title={title}
          handleInput={handleInput}
          label={buttonLabelKey ? item[buttonLabelKey] : item}
          value={item}
        />
      );
    });
  };

  const defaultFiltersArray = [
    {
      title: 'Kennzeichen',
      value: plateNumber,
      headerTitle: 'Kennzeichen',
      isWithButtons: true,
      buttonsArray: filterData.new_name,
      buttonsAmount: 2,
      buttonLabelKey: 'item',
      isWithInput: true,
      dropListDataValueName: 'item',
      dropListDataWithId: true,
      dropListData: filterData.new_name,
      placeholder: 'Kennzeichen suchen',
      isWithoutSearchButton: true,
      customDroplistItem: plateNumberDroplistItem,
      isHidden: false,
      propName: 'new_name',
      columnId: 'plateNumber'
    },
    {
      title: 'Hersteller',
      value: manufacturerLabel,
      headerTitle: 'Hersteller',
      isWithButtons: true,
      buttonsArray: filterData.new_hersteller,
      buttonsAmount: 2,
      isWithInput: true,
      dropListData: filterData.new_hersteller,
      placeholder: 'Hersteller suchen',
      isWithoutSearchButton: true,
      isHidden: false,
      propName: 'new_hersteller',
      columnId: 'manufacturer'
    },
    {
      title: 'Modell',
      value: modelLabel,
      headerTitle: 'Modell',
      isWithButtons: true,
      buttonsArray: filterData.new_model,
      buttonsAmount: 2,
      isWithInput: true,
      dropListData: filterData.new_model,
      placeholder: 'Modell suchen',
      isWithoutSearchButton: true,
      isHidden: false,
      propName: 'new_model',
      columnId: 'model'
    },
    {
      title: 'Unternehmen',
      value: companyNameLabel,
      headerTitle: 'Unternehmen',
      isWithButtons: true,
      buttonsArray: filterData.new_sparteid,
      buttonsAmount: 2,
      buttonLabelKey: 'value',
      isWithInput: true,
      dropListDataValueName: 'value',
      dropListDataWithId: true,
      dropListData: filterData.new_sparteid,
      placeholder: 'Unternehmen suchen',
      isWithoutSearchButton: true,
      customDroplistItem: companyWithAdress,
      isHidden: false,
      propName: 'new_sparteid',
      columnId: 'company'
    },
    {
      title: 'Fahrer',
      value: fahrerLabel,
      headerTitle: 'Fahrer',
      isWithButtons: true,
      buttonsArray: filterData.new_fahrerid,
      buttonsAmount: 2,
      buttonLabelKey: 'value',
      isWithInput: true,
      dropListDataValueName: 'value',
      dropListDataWithId: true,
      dropListData: filterData.new_fahrerid,
      placeholder: 'Fahrer suchen',
      isWithoutSearchButton: true,
      isHidden: false,
      propName: 'new_fahrerid',
      columnId: 'driver'
    },
    {
      title: 'Kostenverantwortlicher',
      value: costManagerLabel,
      headerTitle: 'Kostenverantwortlicher',
      isWithButtons: true,
      buttonsArray: filterData.new_kstverantw,
      buttonsAmount: 2,
      buttonLabelKey: 'value',
      isWithInput: true,
      dropListDataValueName: 'value',
      dropListDataWithId: true,
      dropListData: filterData.new_kstverantw,
      placeholder: 'Kostenverantwortlicher suchen',
      isWithoutSearchButton: true,
      isHidden: false,
      propName: 'new_kstverantw',
      columnId: 'costManager'
    },
    {
      title: 'Erstzulassung',
      value: firstRegistrationLabel,
      headerTitle: 'Erstzulassung',
      isWithButtons: true,
      buttonsArray: lastAndCurrentYears,
      isDatePicker: true,
      isHidden: false,
      propName: 'new_ezl',
      columnId: 'registration'
    },
    {
      title: 'Leasingdauer',
      value: leaseDurationLabel,
      headerTitle: 'Leasingdauer',
      isWithNumberPicker: true,
      helperText: 'Anzahl der Monate',
      propName: 'uds_leasinglaufzeit_decimal',
      isHidden: false,
      columnId: 'leaseTerm'
    },
    {
      title: 'Leasingende',
      value: leasingEndLabel,
      headerTitle: 'Leasingende',
      isWithButtons: true,
      buttonsArray: threeYearsArray,
      isDatePicker: true,
      isHidden: false,
      propName: 'new_leasingende',
      columnId: 'leaseEnd'
    },
    {
      title: 'Leasingvertrag stillschweigend verlängert bis',
      value: leaseEndExtendedLabel,
      headerTitle: 'Leasingvertrag stillschweigend verlängert bis',
      isWithButtons: true,
      buttonsArray: threeYearsArray,
      isDatePicker: true,
      isHidden: false,
      propName: 'uds_leaseenddate_extended',
      columnId: 'extendDate'
    },
    {
      title: 'Leasingrückgabe bis',
      value: leaseReturnLabel,
      headerTitle: 'Leasingrückgabe bis',
      isWithButtons: true,
      buttonsArray: threeYearsArray,
      isDatePicker: true,
      isHidden: false,
      propName: 'uds_lease_return_date',
      columnId: 'leaseReturn'
    },
    {
      title: 'Gesamtlaufleistung',
      value: totalMileageLabel,
      headerTitle: 'Gesamtlaufleistung',
      isWithKilometersPicker: true,
      propName: 'uds_laufleistung',
      isHidden: false,
      columnId: 'totalMileage'
    },
    {
      title: 'Kilometerstand',
      value: actualMileageLabel,
      headerTitle: 'Kilometerstand',
      isWithKilometersPicker: true,
      propName: 'uds_actualmileage',
      isHidden: false,
      columnId: 'mileage'
    },
    {
      title: 'Durschnittliche Kilometer pro Monat',
      value: averageMileageLabel,
      headerTitle: 'Durschnittliche Kilometer pro Monat',
      isWithKilometersPicker: true,
      propName: 'uds_averagemonthlymileage',
      isHidden: false,
      columnId: 'averageMileage'
    },
    {
      title: 'Voraussichtlicher Kilometerstand bei Leasingende',
      value: estimatedMileageLabel,
      headerTitle: 'Voraussichtlicher Kilometerstand bei Leasingende',
      isWithKilometersPicker: true,
      propName: 'uds_summeprojektion',
      isHidden: false,
      columnId: 'estimatedMileage'
    },
    {
      title: 'Ersatzbeschaffung',
      value: replacementStatusLabel,
      headerTitle: 'Ersatzbeschaffung',
      isWithButtons: true,
      buttonsArray: filterData.uds_replacementstate,
      buttonLabelKey: 'label',
      isNothingFound: !filterData.uds_replacementstate?.length,
      isHidden: false,
      propName: 'uds_replacementstate',
      columnId: 'replacementOrdering'
    },
    {
      title: 'Voraussichtliches Lieferungsdatum von Ersatzbeschaffung',
      value: deliveryDateLabel,
      headerTitle: 'Voraussichtliches Lieferungsdatum von Ersatzbeschaffung',
      isDatePicker: true,
      isMonthsPicker: true,
      isHidden: false,
      propName: 'uds_vsl_liefertermin_laut_handler',
      monthsPickerValue: deliveryDateValue,
      columnId: 'replacementDelivery'
    },
    {
      title: 'Leasingrückgabe-Status',
      value: leaseReturnStatusLabel,
      headerTitle: 'Leasingrückgabe-Status',
      isWithButtons: true,
      buttonsArray: filterData.uds_fahrzeugruckgabe,
      buttonLabelKey: 'label',
      isNothingFound: !filterData.uds_fahrzeugruckgabe.length,
      isHidden: false,
      propName: 'uds_fahrzeugruckgabe',
      columnId: 'leaseReturnStatus'
    },
    {
      title: 'Checkliste für Fahrzeugrückgabe',
      value: checklistLabel,
      headerTitle: 'Checkliste für Fahrzeugrückgabe',
      isWithButtons: true,
      buttonsArray: filterData.uds_preparationstate,
      buttonLabelKey: 'label',
      isNothingFound: !filterData.uds_preparationstate.length,
      isHidden: false,
      propName: 'uds_preparationstate',
      columnId: 'checklistStatus'
    }
  ];

  const FiltersWrapper = ({ filtersArray }: FilterWrapperProps) => {
    return (
      <Flex align='center' wrap='wrap'>
        {filtersArray.map(
          filter =>
            !filter.isHidden && (
              <Filter
                key={filter.title}
                title={filter.title}
                isOpen={isOpen}
                value={filter.value}
                toggleFilterIsOpen={toggleFilterIsOpen}
                clearFilter={clearFilter}
              >
                <FilterContainer
                  header={filter.headerTitle}
                  onClose={() => {
                    toggleFilterIsOpen();
                  }}
                  isNothingFound={filter.isNothingFound}
                >
                  {filter.buttonsArray &&
                    getButtonsForFilter(filter.buttonsArray, filter.title, filter.buttonsAmount, filter.buttonLabelKey)}
                  {filter.isWithInput && (
                    <FilterInput
                      title={filter.title}
                      handleInput={handleInput}
                      dropListDataValueName={filter.dropListDataValueName}
                      dropListDataWithId={filter.dropListDataWithId}
                      dropListData={filter.dropListData}
                      placeholder={filter.placeholder}
                      withoutSearchButton={filter.isWithoutSearchButton}
                      // @ts-ignore
                      customDroplistItem={filter.customDroplistItem}
                    />
                  )}
                  {filter.isDatePicker && (
                    <PickersContainer
                      title={filter.title}
                      value={filter.isMonthsPicker ? filter.monthsPickerValue : filter.value}
                      handleInput={handleInput}
                      monthYearPicker={filter.isMonthsPicker}
                    />
                  )}
                  {filter.isWithNumberPicker && (
                    <NumberPicker
                      pickerHandler={(value: AnyObject) => handleInput(filter.title, value)}
                      minValue={getMinValue(filter.propName)}
                      maxValue={getMaxValue(filter.propName)}
                      helperText={filter.helperText}
                    />
                  )}
                  {filter.isWithKilometersPicker && (
                    <KilometersFilter
                      pickerHandler={(value: AnyObject) => handleInput(filter.title, value)}
                      minValue={getMinValue(filter.propName)}
                      maxValue={getMaxValue(filter.propName)}
                    />
                  )}
                </FilterContainer>
              </Filter>
            )
        )}
        <ClearAllFiltersButton filterState={sessionFilter?.filterForClient || {}} clearFilter={clearFilter} />
      </Flex>
    );
  };

  return (
    <FiltersWrapper
      filtersArray={
        customizedColumnsInfo ? getCustomizedOrder(customizedColumnsInfo, defaultFiltersArray) : defaultFiltersArray
      }
    />
  );
};

export default LeasingEndFilters;
