import React from 'react';
import classes from './main-tabs.module.scss';
import { Tab } from 'components/tab/tab.controller';
import { useVehicleDetailsMainTabsData } from './hooks/use-main-tabs/use-main-tabs';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { VEHICLE_DETAIL_ELEMENTS_ID } from 'pages/existing-vehicle-page/utils';
import { useDispatch } from 'react-redux';
import { setVehicleDetailsMainTab } from 'actions/pages/vehicle-details.action';

export const VehicleDetailsMainTabs = () => {
  const { mainTabsData } = useVehicleDetailsMainTabsData();

  const mainTab = useTypedSelector(state => state.vehicleDetails.mainTab);
  const dispatch = useDispatch();

  return (
    <Tab
      id={VEHICLE_DETAIL_ELEMENTS_ID.tab.mainTab}
      className={classes.container}
      type='single'
      ui='section'
      data={mainTabsData}
      activeTab={{
        value: mainTab.activeIndex,
        setValue: value => dispatch(setVehicleDetailsMainTab({ activeIndex: value }))
      }}
    />
  );
};
