import { RequestStatuses } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useQuery } from 'react-query';
import { ResponseModel } from 'common/interfaces';
import { getServicesByAccount } from 'services/services-service';
import { CompanyService } from 'components/global-reference-vehicles/action-switcher/leasing-price-dialog/confirmation-component/confirmation-component.props';
import { AxiosResponse } from 'axios';

export const useUserCompanyActiveServices = (companyId?: string) => {
  const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
    if (data.level === RequestStatuses.Success) {
      return getResponseFormData(data, [{ name: null, type: 'array' }]) as CompanyService[];
    }
    return [];
  };

  const { data: userCompanyActiveServices } = useQuery(
    ['user-company-active-services', companyId],
    () => getServicesByAccount(companyId),
    {
      select: modifyResponse,
      enabled: !!companyId
    }
  );

  return {
    userCompanyActiveServices
  };
};
