import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledNoteItemContainer = styled(Flex)`
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 24px 40px 0px rgba(51, 86, 102, 0.07), 0px 3px 9px 0px rgba(51, 86, 102, 0.07),
    0px 1px 2.5px 0px rgba(51, 86, 102, 0.07);
  border-radius: 3px;
  padding: 20px;
`;
