import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Distance, Flex, Text } from '../../../common/common-components.styled';
import FormHook from '../../../components/form-hook/form-hook';
import InfoHint from '../../../components/info-hint';
import { ChangeVehicleHistoryContext } from './change-vehicle-history.context';
import { useHistoryModalFields } from '../hooks/use-history-modal-fields';
import SubmitButton from './submit-button';
import { useFillHistoryFields } from '../hooks/use-fill-history-fields';
import { useHistoryUnavailableRanges } from '../hooks/use-history-unavailable-range';
import Spinner from 'components/spinner';
import { isEmpty } from 'lodash';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { LoadingState } from 'components/overlay/overlay.props';

type Props = {
  fieldType: 'Fahrer' | 'Fahrzeug';
  assignCar?: (carid: string) => Promise<void>;
};

const getContent = (isDriver: boolean, isAssign: boolean = false) => {
  if (!isAssign) {
    return `Bitte gib die Daten an, in denen der Fahrer zugeordnet sein soll. Die Fahrzeug Historie findest Du auf der Fahrzeugdetailseite und Fahrerdetailseite.`;
  }

  if (isDriver) {
    return `Du hast das Fahrzeug hinterlegt. Bitte gib ein Datum an, ab wann der Fahrer zugeordnet sein soll. Die Fahrzeug
    Historie findest Du auf der Fahrzeugdetailseite und Fahrerdetailseite.`;
  } else {
    return `Du hast den Fahrer gewechselt. Bitte gib ein Datum an, ab wann der Fahrer zugeordnet sein soll. Die Fahrzeug
      Historie findest Du auf der Fahrzeugdetailseite und Fahrerdetailseite.`;
  }
};

export const ChangeVehicleHistory = ({ fieldType, assignCar }: Props) => {
  const { showInfoHint, entityName, id, userId, carHistoryId, isAssign } = useContext(ChangeVehicleHistoryContext);
  const reactHookFormData = useForm();
  const { unavailableRanges } = useHistoryUnavailableRanges(
    entityName,
    fieldType === 'Fahrer' ? (userId as string) : id,
    carHistoryId || undefined
  );

  const loading = useTypedSelector<LoadingState>(state => state['app'].loading);

  const { changeVehicleHistoryFields } = useHistoryModalFields(reactHookFormData, unavailableRanges);
  useFillHistoryFields(reactHookFormData, unavailableRanges);

  if (!isEmpty(loading.state)) {
    return (
      <Flex justify='center' width='50px' margin='0 auto'>
        <Spinner />
      </Flex>
    );
  }

  return (
    <div>
      <Text color='grey900' bottom='15'>
        {getContent(fieldType === 'Fahrer', isAssign)}
      </Text>

      <InfoHint type='info'>
        Überschneidungen von Fahrzeug Historien sind nicht erlaubt. Du könntest nur ein Datum auswählen, das sich nicht
        mit früheren Datensätzen überschneidet.
      </InfoHint>
      <Distance top='15' />

      <FormHook reactHookFormData={reactHookFormData} formFields={changeVehicleHistoryFields} />

      {showInfoHint && (
        <>
          <InfoHint type='info'>
            Achtung: diese Änderung der Kostenstelle überträgt sich automatisch in alle zugehörigen Fahrzeuge.
          </InfoHint>
          <Distance top='15' />
        </>
      )}

      <Flex justify='flex-end'>
        <SubmitButton reactHookFormData={reactHookFormData} assignCar={assignCar} />
      </Flex>
    </div>
  );
};
