import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import { getMarksListAsync, getModelsListAsync } from '../../../../services/get-vehicle-list';

export default class Manufacturer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marks: []
    };
    this.manufacturerChangeHandler = this.manufacturerChangeHandler.bind(this);
  }

  async componentDidMount() {
    try {
      const { data: marks } = await getMarksListAsync();
      this.setState({ marks });
    } catch (e) {
      console.log('Error while retrieving marks list');
    }
  }

  async manufacturerChangeHandler({ target: { value } }) {
    const s = cloneDeep(this.props.search);
    s.predicateObject.manufacturer = value || null;
    s.predicateObject.model = null;
    this.props.setSearch(s);

    try {
      const { data } = await getModelsListAsync(value);
      this.props.setModels(data);
    } catch (ex) {
      console.log('Error while retrieving models list');
    }
  }

  render() {
    return (
      <div className='select filter-select'>
        <select
          value={this.props.manufacturer}
          onChange={this.manufacturerChangeHandler}
          className='form-control btn btn-default btn-block'
        >
          <option value=''>Alle Marken</option>
          {this.state.marks
            .sort((a, b) => {
            if(a.uds_name.toLowerCase() < b.uds_name.toLowerCase()) { return -1; }
            if(a.uds_name.toLowerCase() > b.uds_name.toLowerCase()) { return 1; }
            return 0;
          })
            .map(option => (
            <option value={option.uds_carbrandid} key={option.uds_carbrandid}>
              {option.uds_name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
