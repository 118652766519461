import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function Label({ label }) {
  return (
    <Fragment>
      <span className='label label-lg label-default label-border'>{label}</span>&nbsp;
    </Fragment>
  );
}

Label.propTypes = {
  label: PropTypes.string
};
