import { PAGES, ReactQueryKeys } from 'common/enums';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { VehicleDetailContext } from '../existing-vehicle.context';
import { GET_MY_VEHICLE_QUERY_KEY } from 'hooks/react-query/dashboard/get-my-vehicle/get-my-vehicle.query';

export const useVehicleDetailsClearCash = () => {
  const queryClient = useQueryClient();
  const { setRefreshKey } = useContext(VehicleDetailContext);

  const clearMainCash = () => {
    // Remove after refactoring.
    setRefreshKey(prevState => prevState + 1);
    queryClient.removeQueries([ReactQueryKeys.FleetById]);
    queryClient.removeQueries([GET_MY_VEHICLE_QUERY_KEY]);
  };

  const clearCash = () => {
    queryClient.removeQueries([ReactQueryKeys.FleetGetVehicleConstQuery]);
    queryClient.removeQueries([ReactQueryKeys.FleetCalendarAppointment]);
    queryClient.removeQueries([ReactQueryKeys.FleetLeasingPeriod]);
    queryClient.resetQueries([PAGES.MY_VEHICLES_DETAILS]);

    clearMainCash();
    clearVehicleHistoryCash();
  };

  const clearImagesCash = () => {
    queryClient.resetQueries([ReactQueryKeys.FleetFilesById]);
    queryClient.resetQueries([ReactQueryKeys.FleetById, ReactQueryKeys.Logo]);
    queryClient.resetQueries([ReactQueryKeys.Logo, PAGES.MY_VEHICLES_DETAILS, 'getMailList']);
  };

  const clearVehicleHistoryCash = () => {
    queryClient.removeQueries([ReactQueryKeys.GetVehicleHistoryList]);
    queryClient.removeQueries([ReactQueryKeys.GetCostCentersHistoryList]);
  };

  return { clearCash, clearMainCash, clearVehicleHistoryCash, clearImagesCash };
};
