import React from 'react';
import classes from './cost-list-item.module.scss';
import classNames from 'classnames';
import { CostListItemProps } from './cost-list-item.props';
import toMoney from 'utils/toMoney';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { useTheme } from 'styled-components';

export const CostListItem = ({ name, color, price, icon, className, tooltipText = '' }: CostListItemProps) => {
  const theme = useTheme();
  return (
    <div key={name} className={classNames(className, classes.item)}>
      <div className={classes.name_container}>
        {color ? <span className={classes.circle} style={{ background: color }} /> : icon}
        <span className={classes.name}>{name}</span>
        {tooltipText.length > 0 && (
          <TooltipHint className={classes.inline_tooltip} position='top' text={tooltipText}>
            <InfoHintIcon width={16} height={15} color={theme.colors.grey400} />
          </TooltipHint>
        )}
      </div>

      <span className={classes.price}>{typeof price === 'string' ? price : toMoney(price)}</span>
    </div>
  );
};
