import { connect } from 'react-redux';
import { setEndLoading } from 'actions/app_action';
import { Dispatch } from 'redux';
import { ErrorBoundaryComponent } from './error-boundary';

const mapDispatchToState = (dispatch: Dispatch) => ({
  hideOverlay: () => {
    dispatch(setEndLoading());
  }
});

export const ErrorBoundary = connect(null, mapDispatchToState)(ErrorBoundaryComponent);
