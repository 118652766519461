import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Checkbox from '../../checkbox';

import classes from '../price-calculator.module.scss';

function ListItem({ label, value, icon, checked, onChange, name, disabled }) {
  return (
    <li>
      <span
        className={classnames(classes.label, { 'line-through': !checked })}
        style={{ fontWeight: '600', color: checked || !disabled ? '#000' : 'rgb(204,204,204)' }}
      >
        {/*{icon}*/}
        {label}
      </span>
      <span className='d-inline-flex'>
        <span
          className={classnames({ 'line-through': !checked })}
          style={{
            paddingLeft: '5px',
            fontWeight: '600',
            paddingRight: '5px',
            color: checked || !disabled ? '#000' : 'rgb(204,204,204)'
          }}
        >
          {value}
        </span>
        <Checkbox
          onChange={onChange}
          inputProps={{
            name,
            checked,
            disabled
          }}
        />
      </span>
    </li>
  );
}

export default ListItem;

ListItem.defaultProps = {
  value: 'inkl.',
  checked: true,
  disabled: false
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  icon: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool
};
