import React from 'react';

export const FlagIcon = () => {
  return (
    <svg width='80' height='70' viewBox='0 0 80 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <ellipse cx='26' cy='60' rx='5' ry='3' fill='#E6F7FF' />
      <path
        d='M22.9231 58C21.7525 58.5117 21 59.3087 21 60.204C21 61.7482 23.2386 63 26 63C28.7614 63 31 61.7482 31 60.204C31 59.3087 30.2475 58.5117 29.0769 58'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M26.0023 20.7611V11.5H71.6982C71.8639 11.5 71.9982 11.6343 71.9982 11.7999V20.7611V30.7108V40.3605C71.9982 40.5262 71.8639 40.6605 71.6982 40.6605H60.4993H49.0003H37.5013H26.0023V30.7108V20.7611Z'
        fill='white'
      />
      <path
        d='M26.0023 11.5V20.7611V30.7108V40.6605M26.0023 11.5H71.6982C71.8639 11.5 71.9982 11.6343 71.9982 11.7999V20.7611V30.7108V40.3605C71.9982 40.5262 71.8639 40.6605 71.6982 40.6605H60.4993H49.0003H37.5013H26.0023M26.0023 11.5L26 9M26 59.7185L26.0023 40.6605'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M26.0039 30.7111V20.7614H37.5029V30.7111H26.0039ZM49.0018 30.7111H37.5029V40.6607H49.0018V30.7111ZM49.0018 20.7614V30.7111H60.5008V40.6607H70.2195C71.2027 40.6607 71.9998 39.8637 71.9998 38.8805V30.7111H60.5008V20.7614H71.9998V13.2805C71.9998 12.2973 71.2027 11.5002 70.2195 11.5002H60.5008V20.7614H49.0018ZM49.0018 20.7614V11.5002H37.5029V20.7614H49.0018Z'
        fill='#8FAFBF'
      />
    </svg>
  );
};
