import { IconProps } from 'common/interfaces';
import React from 'react';

export const CarMehanicIcon = ({ width = 89, height = 78, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 89 78'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M82.0023 33.2564V57.4077L7.97235 58.0929V33.2564C7.97235 31.612 8.28066 29.9334 8.86303 28.3918L10.1991 24.8633H3.86148C2.9708 24.8633 2.3199 24.0412 2.52544 23.1847L3.55316 18.7656C3.86148 17.5323 4.95772 16.6416 6.22524 16.6416H12.9397L21.7777 22.203H67.665L77.035 16.6416H83.7494C85.0169 16.6416 86.1474 17.5323 86.4214 18.7656L87.4492 23.1847C87.6547 24.0412 87.0038 24.8633 86.1131 24.8633H79.7755L81.1116 28.3918C81.694 29.9334 82.0023 31.5778 82.0023 33.2564Z'
      fill='#E6F7FF'
    />
    <path
      d='M82.0023 45.3834V33.2563C82.0023 31.5777 81.694 29.9334 81.1116 28.3918L79.7755 24.8633H86.1131C87.0038 24.8633 87.6547 24.0411 87.4492 23.1847L86.4215 18.7655C86.1474 17.5322 85.0169 16.6415 83.7494 16.6415H77.035L71.7593 6.12457C70.3548 3.34973 67.5114 1.56836 64.394 1.56836H25.5806C22.4632 1.56836 19.6198 3.31547 18.2153 6.12457L12.9397 16.6415H6.22524C4.95772 16.6415 3.86148 17.5322 3.55316 18.7655L2.52544 23.1847C2.3199 24.0411 2.9708 24.8633 3.86148 24.8633H10.1991L8.86303 28.3918C8.28066 29.9334 7.97235 31.612 7.97235 33.2563V45.3834'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.2283 37.1958H27.636L25.6833 33.3247'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M73.7805 37.1958H62.3728L64.3255 33.3247'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M34.0421 46.7881H55.9668'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.7095 22.1226H68.2993'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='24.6367' y='57.6177' width='40.7233' height='11.6484' fill='white' />
    <path
      d='M24.553 68.7124H65.4561'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.5187 57.7505H65.4218'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.1353 67.3424C23.4567 72.1384 18.8662 75.5641 13.4878 75.5641C8.65756 75.5641 3.8958 72.8236 2.52551 68.7127H12.1175C13.1795 67.6507 13.7961 67.0341 14.8581 65.9721V60.491C13.7961 59.429 13.1795 58.8123 12.1175 57.7504H2.52551C4.58095 53.7765 8.72607 50.8989 13.4878 50.8989C18.8662 50.8989 23.4224 54.3247 25.101 59.1207'
      fill='white'
    />
    <path
      d='M25.1353 67.3424C23.4567 72.1384 18.8662 75.5641 13.4878 75.5641C8.65756 75.5641 3.8958 72.8236 2.52551 68.7127H12.1175C13.1795 67.6507 13.7961 67.0341 14.8581 65.9721V60.491C13.7961 59.429 13.1795 58.8123 12.1175 57.7504H2.52551C4.58095 53.7765 8.72607 50.8989 13.4878 50.8989C18.8662 50.8989 23.4224 54.3247 25.101 59.1207'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M64.8737 59.1207C66.5523 54.3247 71.1428 50.8989 76.5212 50.8989C81.2829 50.8989 85.4281 53.7765 87.4835 57.7504H79.2618C78.1998 58.8123 77.5832 59.429 76.5212 60.491V65.9721C77.5832 67.0341 78.1998 67.6507 79.2618 68.7127H87.4835C86.1132 72.8236 81.2829 75.5641 76.5212 75.5641C71.1428 75.5641 66.5866 72.1384 64.908 67.3424'
      fill='white'
    />
    <path
      d='M64.8737 59.1207C66.5523 54.3247 71.1428 50.8989 76.5212 50.8989C81.2829 50.8989 85.4281 53.7765 87.4835 57.7504H79.2618C78.1998 58.8123 77.5832 59.429 76.5212 60.491V65.9721C77.5832 67.0341 78.1998 67.6507 79.2618 68.7127H87.4835C86.1132 72.8236 81.2829 75.5641 76.5212 75.5641C71.1428 75.5641 66.5866 72.1384 64.908 67.3424'
      stroke='#335566'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
