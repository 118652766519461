import { useContext, useEffect } from 'react';
import { HistoryModalFieldNames } from '../vehicle-history-modal/history-modal.types';
import { ChangeVehicleHistoryContext } from '../vehicle-history-modal/change-vehicle-history.context';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import { UnavailableRange } from '../components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';

export const useFillHistoryFields = (
  reactHookFormData: UseFormReturn,
  unavailableRanges: UnavailableRange[] | undefined
) => {
  const { isAssign, from, to } = useContext(ChangeVehicleHistoryContext);
  const { setValue } = reactHookFormData;

  useEffect(() => {
    if (isAssign && unavailableRanges?.length) {
      setValue(
        HistoryModalFieldNames.ValidFrom,
        moment(
          unavailableRanges[unavailableRanges?.length - 1].to || unavailableRanges[unavailableRanges?.length - 1].from
        )
          .add(1, 'day')
          .startOf('day'),
        defaultOptions
      );
    } else {
      setValue(HistoryModalFieldNames.ValidFrom, moment(from).startOf('day'), defaultOptions);
      if (to) {
        setValue(HistoryModalFieldNames.ValidTo, moment(to).startOf('day'), defaultOptions);
      }
    }
  }, [isAssign, unavailableRanges, from, to, setValue]);
};
