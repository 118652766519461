import { PicklistResponse, LookupResponse } from 'common/interfaces';
import { StatusId, StatusName } from 'components/aufgaben-component/task.prop';

export type InspectionDatesHistoryGetByIdResponse = Partial<{
  pruftermineHistory: Array<
    Partial<{
      attributes: InspectionDatesHistoryGetByIdResponseItemAttribute;
    }>
  >;
}>;

export type InspectionDatesHistoryGetByIdResponseItemAttribute = Partial<{
  uds_carid: LookupResponse;
  uds_inspection_type: string;
  uds_last_inspection: PicklistResponse;
  uds_name: string;
  uds_next_inspection: PicklistResponse;
  uds_protocolcode: PicklistResponse<string, ProtocolCodeValue>;
  uds_statuscode: PicklistResponse<StatusName, StatusId>;
}>;

export enum ProtocolCodeValue {
  Yes = 100000000,
  No = 100000001
}
