import { useQuery } from 'react-query';
import { getAllEventDescriptions } from 'services/event-descriptions-service';
import { ReactQueryKeys } from 'common/enums';
import { AxiosResponse } from 'axios';
import { EventDescriptionWebRoles, ResponseModel, VehicleOfferEventDescription } from 'common/interfaces';
import { RequestStatuses } from '../../constants';
import { getDataForTable, getResponseFormData } from 'utils/get-response-data';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
  if (data.level === RequestStatuses.Success) {
    const vehicleOffersEventDescriptions = getResponseFormData(data, [
      { name: null, type: 'array' }
    ]) as VehicleOfferEventDescription[];
    vehicleOffersEventDescriptions.forEach(vehicleOffersEventDescription => {
      vehicleOffersEventDescription.webroles = getDataForTable(
        vehicleOffersEventDescription.webroles
      ) as EventDescriptionWebRoles[];
    });

    return vehicleOffersEventDescriptions;
  }
  return [];
};

const useVehicleOffersEventDescriptions = () => {
  const { data: vehicleOffersEventDescriptions } = useQuery(
    [ReactQueryKeys.GetVehicleOffersEventDescriptions],
    () => getAllEventDescriptions(),
    {
      select: modifyResponse
    }
  );

  return { vehicleOffersEventDescriptions };
};

export default useVehicleOffersEventDescriptions;
