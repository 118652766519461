import React from 'react';
import { Rotate, Direction } from 'common/common-components.styled';
import { DoughnutWarningDiagram } from 'components/widgets/doughnut-warning/doughnut-warning';
import { useFleetLeasingPeriodQuery } from 'hooks/react-query/fleet/use-leasing-period/use-leasing-period';
import { ReamingLasingDate } from '../reaming-leasing-date/reaming-leasing-date';

export const LeasingPeriodDiagram = () => {
  const { fleetLeasingPeriodQuery, isFetching } = useFleetLeasingPeriodQuery();

  const getValuePercent = () => {
    if (fleetLeasingPeriodQuery?.full?.days && fleetLeasingPeriodQuery.remaining?.days) {
      return (fleetLeasingPeriodQuery.remaining.days / fleetLeasingPeriodQuery?.full?.days) * 100;
    }

    return 0;
  };

  const getWarningValue = () => {
    if (fleetLeasingPeriodQuery?.full?.months) {
      return (15 / fleetLeasingPeriodQuery.full.months) * 100;
    }

    return 0;
  };

  if (isFetching) {
    return null;
  }

  return (
    <Rotate rotateDirection={Direction.Left} width='fit-content' margin='0 auto'>
      <DoughnutWarningDiagram
        valuePercent={getValuePercent()}
        warningPercent={getWarningValue()}
        valueColor='blue'
        warningColor='darkGold'
      >
        <Rotate rotateDirection={Direction.Right}>
          <ReamingLasingDate />
        </Rotate>
      </DoughnutWarningDiagram>
    </Rotate>
  );
};
