import React from 'react';
import { TableComponentsProps } from '../table-components.props';
import classes from './vehicle-cell.module.scss';

const VehicleCell: React.FC<TableComponentsProps> = ({ row }) => {
  return (
    <span className={row?.statecode_value === 0 ? classes.car_active : classes.car_inactive}>
      {row && row.new_name}
    </span>
  );
};

export default VehicleCell;
