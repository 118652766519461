import React from 'react';
import { IconProps } from 'common/interfaces';

export const CarWithManyStarsIcon = ({ width, height }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 61 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M45.5737 65.9745H42.224V70.1625C42.224 70.665 42.559 71 43.0614 71H51.4356C51.9381 71 52.2731 70.665 52.2731 70.1625V64.9694L52.943 61.7866C53.0267 61.2002 53.1105 60.6977 53.1105 60.1114V51.903C53.1105 50.8979 52.943 49.8928 52.5243 48.8877L51.6869 46.7099H55.3715C55.874 46.7099 56.2927 46.2074 56.209 45.7048L55.539 43.0246C55.3715 42.2707 54.7016 41.7682 53.9479 41.7682H50.0958L46.9136 35.3187C46.0762 33.6435 44.3176 32.5547 42.3915 32.5547H18.6925C16.7664 32.5547 15.0078 33.6435 14.1704 35.3187L10.9882 41.7682H7.05235C6.29867 41.7682 5.62873 42.2707 5.46125 43.0246L4.7913 45.7048C4.70756 46.2074 5.04252 46.7099 5.62872 46.7099H9.31339L8.47597 48.8877C8.141 49.809 7.88977 50.8141 7.88977 51.903V60.1114C7.88977 60.6977 7.97352 61.284 8.05726 61.7866L8.72719 64.9694V70.1625C8.72719 70.665 9.06215 71 9.56461 71H17.9388C18.4413 71 18.7762 70.665 18.7762 70.1625V65.9745H15.4266'
        fill='white'
      />
      <path
        d='M42.2243 70.1623V65.9744H18.7765V70.1623C18.7765 70.6649 18.4415 70.9999 17.9391 70.9999H9.56485C9.0624 70.9999 8.72743 70.6649 8.72743 70.1623V64.9693L8.0575 61.7864C7.97376 61.2839 7.89001 60.6976 7.89001 60.1113V51.9029C7.89001 50.814 8.14125 49.8089 8.47621 48.8876L9.31364 46.7098H5.62896C5.04277 46.7098 4.7078 46.2073 4.79154 45.7047L5.46149 43.0245C5.62898 42.2706 6.29891 41.7681 7.05259 41.7681H10.9885L16.3159 44.1843H44.6661L50.096 41.7681H53.9482C54.7018 41.7681 55.3718 42.2706 55.5392 43.0245L56.2092 45.7047C56.2929 46.2073 55.8742 46.7098 55.3718 46.7098H51.6871L52.5245 48.8876C52.9432 49.8927 53.1107 50.8978 53.1107 51.9029V60.1113C53.1107 60.6976 53.027 61.2001 52.9432 61.7864L52.2733 64.9693V70.1623C52.2733 70.6649 51.9383 70.9999 51.4359 70.9999H43.0617C42.5592 70.9999 42.2243 70.6649 42.2243 70.1623Z'
        fill='#E6F7FF'
      />
      <path
        d='M45.5737 65.9745H42.224V70.1625C42.224 70.665 42.559 71 43.0614 71H51.4356C51.9381 71 52.2731 70.665 52.2731 70.1625V64.9694L52.943 61.7866C53.0267 61.2002 53.1105 60.6977 53.1105 60.1114V51.903C53.1105 50.8979 52.943 49.8928 52.5243 48.8877L51.6869 46.7099H55.3715C55.874 46.7099 56.2927 46.2074 56.209 45.7048L55.539 43.0246C55.3715 42.2707 54.7016 41.7682 53.9479 41.7682H50.0958L46.9136 35.3187C46.0762 33.6435 44.3176 32.5547 42.3915 32.5547H18.6925C16.7664 32.5547 15.0078 33.6435 14.1704 35.3187L10.9882 41.7682H7.05234C6.29867 41.7682 5.62873 42.2707 5.46125 43.0246L4.7913 45.7048C4.70756 46.2074 5.04252 46.7099 5.62872 46.7099H9.31339L8.47597 48.8877C8.141 49.809 7.88977 50.8141 7.88977 51.903V60.1114C7.88977 60.6977 7.97352 61.284 8.05726 61.7866L8.72719 64.9694V70.1625C8.72719 70.665 9.06215 71 9.56461 71H17.9388C18.4413 71 18.7762 70.665 18.7762 70.1625V65.9745H15.4266'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.939 65.9746H43.8991'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.9146 54.2481H19.8651L18.6927 51.9028'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M48.0862 54.2481H41.1356L42.308 51.9028'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M23.801 60.1113H37.1997'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.2642 44.1973H44.7365'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M30.5 1L33.9294 7.92775L41.5706 9.03252L36.0238 14.4412L37.3357 22.0364L30.5 18.446L23.6644 22.0364L24.9762 14.4412L19.4294 9.03252L27.0707 7.92775L30.5 1Z'
        fill='#FFEFBF'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M57.3134 16.5358L55.288 22.4738L59.0396 27.5143L52.7793 27.4222L49.1428 32.5547L47.2785 26.5706L41.2944 24.7064L46.4269 21.0699L46.3349 14.8096L51.3753 18.5612L57.3134 16.5358Z'
        fill='#FFEFBF'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.68663 16.5358L5.71204 22.4738L1.96045 27.5143L8.22073 27.4222L11.8572 32.5547L13.7215 26.5706L19.7056 24.7064L14.5731 21.0699L14.6652 14.8096L9.62472 18.5612L3.68663 16.5358Z'
        fill='#FFEFBF'
        stroke='#8FAFBF'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
