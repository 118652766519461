import React from 'react';
import { ApproveComponentPriceProps } from './approve-component-price.types';
import { Flex, Text } from 'common/common-components.styled';
import toMoney from 'utils/toMoney';

const ApproveComponentPrice = ({ label, price, bottom = '5' }: ApproveComponentPriceProps) => {
  return (
    <Flex bottom={bottom}>
      <Text color='grey600'>{label}</Text>
      <Text left='10' color='darkBlue'>
        {toMoney(price)}
      </Text>
    </Flex>
  );
};

export default ApproveComponentPrice;
