import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { CarDealershipSelectionFieldNames } from './car-dealership-selection.props';
import { FormSourceEnum } from 'components/form-hook/form-field/hooks/use-set-options/use-set-options.props';

export const carDealershipFields: FormFieldModel[] = [
  {
    name: CarDealershipSelectionFieldNames.CarDealership,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Autohaus',
    source: FormSourceEnum.GetUserCompanyCarDealerships,
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const contactPersonFields: FormFieldModel[] = [
  {
    name: CarDealershipSelectionFieldNames.ContactPerson,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Ansprechpartner',
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];
