import React, { useState } from 'react';
import { SortingDocumentsProps, SortingState } from './sorting-documents.props';
import { Flex } from 'common/common-components.styled';
import { SortingDocumentsTitleRow } from './components/title-row/title-row';
import { DocumentRow } from './components/document-row/document-row';
import { SortingTitleEnum } from './components/title-row/title-row.props';
import { SortingDocumentsContext } from './sorting-documents.context';
import { cloneDeep } from 'lodash';
import { getFileName } from 'utils/file/get-file-name';
import { SortingUtils } from 'utils/sorting/sorting';
import { IDocumentsGroupedByDescriptionItem } from 'components/download-documents-component/download-documents-component.props';

export const SortingDocuments = ({ data, sectionName }: SortingDocumentsProps) => {
  const [sorting, setSorting] = useState<SortingState>({ column: SortingTitleEnum.Name, isSortAlpha: true });

  const getSortedValue = (value: IDocumentsGroupedByDescriptionItem) => {
    switch (sorting.column) {
      case SortingTitleEnum.Size:
        return value.fileSize;
      case SortingTitleEnum.CreatedOn:
        return value.lastModified;
      case SortingTitleEnum.CreatedBy:
        return value.createdBy;
      default:
        return getFileName(value.filePath);
    }
  };

  const getSortedData = () => {
    const sortingUtils = new SortingUtils<IDocumentsGroupedByDescriptionItem>({
      data: cloneDeep(data),
      transformItemCallback: value => getSortedValue(value) || ''
    });

    return sortingUtils.getSortedData(!sorting.isSortAlpha);
  };

  return (
    <SortingDocumentsContext.Provider value={{ sorting, setSorting }}>
      <Flex direction='column' padding='0 20px'>
        <SortingDocumentsTitleRow />

        {getSortedData().map((file, _, allFiles) => (
          <DocumentRow key={file.filePath} file={file} allFiles={allFiles} sectionName={sectionName} />
        ))}
      </Flex>
    </SortingDocumentsContext.Provider>
  );
};
