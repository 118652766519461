import { useSelector } from 'react-redux';

const DashboardTitle = () => {
  const user = useSelector(state => state['app'].user);
  const userName = user.name?.split(' ')[0] || '';

  return `Hallo${userName ? ` ${userName}` : ''}!`;
};

export default DashboardTitle;
