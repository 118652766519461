import React from 'react';
import { TrashIcon } from 'components/icons-new/trash';
import { PAGES } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQueryClient } from 'react-query';
import { DeleteNotesButtonProps } from './delete-note.props';
import IconHover from 'components/icon-hover';
import { notesRequestConfig } from 'request-config/notes/notes.request-config';

export const DeleteNotesButton = ({ noteId, iconSize = 22 }: DeleteNotesButtonProps) => {
  const { fetch } = useFetch({ loadingKey: 'deleteNote', ...notesRequestConfig.deleteNote(noteId) });
  const queryClient = useQueryClient();

  const deleteHandler = () => {
    fetch({ callback: () => queryClient.resetQueries([PAGES.MY_VEHICLES_DETAILS, 'getFleetNotes']) });
  };

  return (
    <IconHover onClick={deleteHandler} pointer right='15'>
      <TrashIcon width={iconSize} height={iconSize} />
    </IconHover>
  );
};
