import axios from 'axios';
import authService from './auth-service';
import { GetPDFData, GetFinalPdfData } from 'pages/package-page/package-page.props';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getListProducts(body = {}) {
  const token = await authService.acquireTokenAsync();
  const response = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/ListProducts`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: body
  });

  const { data } = response.data;

  return data;
}

export async function getCompanyPackage(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetCompanyPackage`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getAvailablePackages(data = {}) {
  const token = await authService.acquireTokenAsync();
  const response = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetAvailablePackages`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });

  const { items } = response.data.data;

  // console.log(items);
  return response;
}

export async function updateCheckoutData(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/UpdateCheckoutData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getPricelistFilesList(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetPreislisteFilesList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function assignProducts(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/AssignProducts`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getContractPDF(data: GetPDFData) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetContractPDF`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob',
    data
  });
}

export async function getContractPDFPreview(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetContractPDFPreview`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob',
    data
  });
}

export async function getContractZip(data = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetContractZip`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob',
    data
  });
}

export async function GetFinalZip(data: GetFinalPdfData) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetFinalZip`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob',
    data
  });
}

export async function getAccountData(id: string) {
  const token = await authService.acquireTokenAsync();
  const response = await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmCheckout/GetAccountData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      id
    }
  });

  const { data } = response.data;

  return data;
}
