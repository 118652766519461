import { ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { GetFilesByDocumentTypeResponseItem } from './get-files-by-document-type.props';
import { AxiosResponse } from 'axios';
import { sharePointDocumentsRequestConfig } from 'request-config/sharepoint-documents/sharepoint-documents.request-config';
import { GetFilesByDocumentTypeProps } from 'request-config/sharepoint-documents/sharepoint-documents.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export const useGetFilesByDocumentTypeQuery = (data: GetFilesByDocumentTypeProps, options: QueryHookOptions = {}) => {
  const { fetch } = useFetch({
    loadingKey: 'getFilesByDocumentType',
    ...options.fetch,
    ...sharePointDocumentsRequestConfig.getFilesByDocumentType(data)
  });

  const { data: queryData, ...restQueries } = useQuery<
    AxiosResponse<ResponseModel<GetFilesByDocumentTypeResponseItem[]>>
  >([ReactQueryKeys.GetFilesByDocumentType, data.entityId, ...data.documentTypeIds], () => fetch(), {
    enabled: !!data.entityId && !!options.options?.enabled
  });

  return { files: queryData?.data, ...restQueries };
};
