import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { getVehicleLogoAsync } from 'services/get-vehicle-details';
import useOverlay from 'hooks/use-overlay';

export const useVehileLogo = (folderPath: string, defaulImg?: string) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const vehicleListLogoQuery = useQuery<AxiosResponse<string>>(
    ['vehicle list logo', folderPath],
    () => getVehicleLogoAsync(folderPath),
    { enabled: !!folderPath }
  );

  useEffect(() => {
    if (vehicleListLogoQuery.isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [vehicleListLogoQuery.isFetching, showOverlay, hideOverlay]);

  return {
    ...vehicleListLogoQuery,
    data: vehicleListLogoQuery.data?.data || defaulImg
  };
};
