import React from 'react';
import styles from './index.module.scss';

const SuccessQuiz = (showLink: any) => {
  return (
    <div className={styles.panel}>
      <img src='/assets/images/gold-cup-with-car.png' alt='success-image' className={styles.empty__img} />
      <h6 className={styles.title}>Vielen Dank!</h6>
      <div className={styles.correct}>Wenn du uns Feedback gibst, hilfst du uns, unsere Arbeit zu verbessern.</div>
      <div className={styles.buttons}>
        {showLink.showLink && (
          <a href='https://g.page/r/CcIVr6cjrZNdEAg/review' rel='noreferrer'>
            <button className={styles.button_all}>bei Google rezensieren</button>
          </a>
        )}
      </div>
    </div>
  );
};

export default SuccessQuiz;
