import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { errorMessages } from 'utils/error-messages';
import { CompleteTaskFormData } from './use-complete-task.props';

export const examinationDateFormField: FormFieldModel<keyof CompleteTaskFormData> = {
  name: 'scheduledend',
  label: 'Untersuchungsdatum',
  type: FormFieldTypes.Date,
  defaultValue: null,
  validation: { required: errorMessages.required }
};
