import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { crmEntityRequestConfig } from 'request-config/crm-entity/crm-entity.request-config';
import { GetEntityByFieldQueryProps } from 'request-config/crm-entity/crm-entity.types';
import { CrmEntityQueryKey } from '../crm-entity';
import { ResponseModel } from 'common/interfaces';
import { CrmEntityGetEntityByFieldResponse } from './get-entity-by-field.props';
import { getDataForTable } from 'utils/get-response-data';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export const useCrmEntityGetEntityByFieldQuery = (
  props: GetEntityByFieldQueryProps,
  {
    options
  }: QueryHookOptions<CrmEntityGetEntityByFieldResponse, ResponseModel<CrmEntityGetEntityByFieldResponse>> = {}
) => {
  const { fetch } = useFetch<ResponseModel<CrmEntityGetEntityByFieldResponse>>({
    loadingKey: `crmEntityGetEntityByField${JSON.stringify(props)}`,
    ...crmEntityRequestConfig.getEntityByFieldQuery(props)
  });

  const selectHandler = (data: CrmEntityGetEntityByFieldResponse): CrmEntityGetEntityByFieldResponse => ({
    ...data,
    entityes: getDataForTable(data?.entityes || [])
  });

  const { data, ...restQueryProps } = useQuery([CrmEntityQueryKey.GetEntityByFieldQuery, props], () => fetch(), {
    select: ({ data }) => selectHandler(data.data || {}),
    ...options
  });

  return {
    crmEntityGetEntityByFieldQuery: data || defaultQuery,
    ...restQueryProps
  };
};

const defaultQuery: CrmEntityGetEntityByFieldResponse = {
  entityName: '',
  entityes: []
};
