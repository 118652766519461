import React from 'react';
import { QualitySection } from './components/quality-section/quality-section';
import { QualityDiagram } from './components/quality-diagram/quality-diagram';
import { Flex } from 'common/common-components.styled';
import { useQualitySectionList } from './hooks/use-quality-section-list';
import { StyledWidgetTile } from './data-quality.styled';

export const DataQualityWidget = () => {
  const { qualitySectionList } = useQualitySectionList();
  return (
    <StyledWidgetTile>
      <Flex height='100%' direction='column' justify='center'>
        <QualityDiagram />

        <div>
          {qualitySectionList.map(item => (
            <QualitySection key={item.label} {...item} />
          ))}
        </div>
      </Flex>
    </StyledWidgetTile>
  );
};
