//   store.dispatch(

import {changeSort, toggleSidebar as toggleSidebarAction} from '../../../../actions/app_action';
import { connect } from 'react-redux';
import VehicleListHeader from './vehicle-list-header-view';

//   );

const mapStateToProps = state => {
  const predicateObject = state.app.smartTable.search.predicateObject;
  const activeFiltersObjects = Object.keys(predicateObject).filter(x => x !== 'carTypes' && x !== 'priceRange').reduce((prev, curr) => {
    if (predicateObject[curr] !== null) {
      prev = prev + 1;
    }
    return prev;
  },0)
  const filterCount = predicateObject.carTypes.length + activeFiltersObjects;
  return {
    sort: state.app.smartTable.sort,
    sidebarOpen: state.app.listSidebarOpen,
    filterCount
  }
};

const mapDispatchToProps = dispatch => ({
  resetSort() {
    dispatch(
      changeSort({
        predicate: 'uds_mobexo_gesamtrate',
        reverse: false
      })
    );
  },
  toggleSidebar(flag) {
    dispatch(toggleSidebarAction(flag));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleListHeader);
