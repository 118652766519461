/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import EmailFieldComponent from '../components/email-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TrashIcon from '../icons/trash-icon';
import useWindowSize from 'hooks/use-window-size';
import { StepProps, StepStylesProps } from '../report-damage.props';

const ChooseDamageHail = ({ reactHookFormData, handleNext }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const [showBlockWitnesses, setShowBlockWitnesses] = useState<boolean>(false);
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    setTimeout(() => (watch('anyWitnesses') ? setShowBlockWitnesses(true) : setShowBlockWitnesses(false)), 100);
    setShowBlock(true);
  }, [watch('anyWitnesses')]);

  const anyWitnessesFields =
    watch('anyWitnesses') === false ||
    !!watch('anyWitnessesDateEmpty') ||
    (!!watch('anyWitnessesName') &&
      watch('anyWitnessesName').length > 0 &&
      !!watch('anyWitnessesAdress') &&
      watch('anyWitnessesAdress').length > 0 &&
      !!watch('anyWitnessesEmail') &&
      watch('anyWitnessesEmail').length > 0 &&
      !!watch('anyWitnessesPhone') &&
      watch('anyWitnessesPhone').length > 0);

  const anyWitnessesFieldsSecond =
    watch('anyWitnesses') === false ||
    !watch('showSecondPersonAnyWitnesses') ||
    (!!watch('showSecondPersonAnyWitnesses') && !!watch('anyWitnessesDateSecondEmpty')) ||
    (!!watch('anyWitnessesNameSecond') &&
      watch('anyWitnessesNameSecond').length > 0 &&
      !!watch('anyWitnessesAdressSecond') &&
      watch('anyWitnessesAdressSecond').length > 0 &&
      !!watch('anyWitnessesEmailSecond') &&
      watch('anyWitnessesEmailSecond').length > 0 &&
      !!watch('anyWitnessesPhoneSecond') &&
      watch('anyWitnessesPhoneSecond').length > 0);

  const autoIsNotOkay =
    (watch('autoIsOkay') === false || !!watch('autoIsOkay')) &&
    !!watch('autoIsNotOkayFirma') &&
    !!watch('autoIsNotOkayAdress') &&
    !!watch('autoIsNotOkayTelephone');

  useEffect(() => {
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    watch('autoIsOkay') !== undefined &&
    autoIsNotOkay &&
    watch('anyWitnesses') !== undefined &&
    anyWitnessesFields &&
    anyWitnessesFieldsSecond &&
    !!watch('DetailedDamageDescription') &&
    watch('DetailedDamageDescription').length > 0 &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
  }, [watch()]);

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      checkButtons(['anyWitnesses', 'damagePrivatOrBusinessTrip', 'autoIsOkay']);
    }
  };

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  useEffect(() => {
    if (watch('autoIsOkay') === true) {
      clearErrors(['autoIsNotOkayAdress', 'autoIsNotOkayFirma', 'autoIsNotOkayTelephone']);
    }
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    if (!watch('anyWitnesses')) {
      clearErrors([
        'anyWitnessesAdress',
        'anyWitnessesEmail',
        'anyWitnessesName',
        'anyWitnessesPhone',
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('anyWitnesses')]);

  useEffect(() => {
    if (!watch('showSecondPersonAnyWitnesses')) {
      clearErrors([
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('showSecondPersonAnyWitnesses')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        <div>
          <h6>Wo kann das Fahrzeug besichtigt werden?</h6>

          <TextFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayFirma'
            fieldName='Firma/Name'
            fieldPlaceholder='Muster GmbH'
          />

          <Distance top='5' />

          <TextFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayAdress'
            fieldName='Anschrift'
            fieldPlaceholder='Musterstr. 100 12345 Berlin'
          />

          <Distance top='5' />

          <PhoneFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayTelephone'
            fieldName='Telefon'
          />

          <Distance top='30' />
        </div>

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Brauchst du einen Mietwagen?'
          fieldRegister='needRent'
          fieldRegisterSecond='needRent'
          optional={true}
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Sind dir Zeugen bekannt?'
          fieldRegister='anyWitnesses'
          fieldRegisterSecond='anyWitnesses'
        />

        <Distance top='20' />

        {watch('anyWitnesses') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockWitnesses} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Daten der Zeuge:</h6>

                <div
                  className={styles.checkbox}
                  onClick={() =>
                    clearErrors(['anyWitnessesName', 'anyWitnessesAdress', 'anyWitnessesEmail', 'anyWitnessesPhone'])
                  }
                >
                  <input
                    {...register('anyWitnessesDateEmpty', { required: false })}
                    id='anyWitnessesDateEmpty'
                    type='checkbox'
                  />
                  <label htmlFor='anyWitnessesDateEmpty'>Daten unbekannt</label>
                </div>
                <Distance top='15' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesName'
                  fieldName='Name'
                  fieldPlaceholder='Max Mustermann'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <EmailFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesEmail'
                  fieldName='E-mail adresse'
                  fieldPlaceholder='Muster@mail.com'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesPhone'
                  fieldName='Telefon'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='10' />

                <div
                  style={{ width: '100%' }}
                  className={watch('showSecondPersonAnyWitnesses') ? '' : styles.hide__block}
                >
                  <Distance top='15' />

                  <div className={styles.close_header}>
                    <h6>Daten der zweite Zeuge:</h6>
                    <div
                      id='deleteSecondPersonAnyWitnessesButton'
                      className={styles.close_block}
                      onClick={() => {
                        setValue('showSecondPersonAnyWitnesses', false);
                        clearErrors([
                          'anyWitnessesNameSecond',
                          'anyWitnessesAdressSecond',
                          'anyWitnessesEmailSecond',
                          'anyWitnessesPhoneSecond'
                        ]);
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>

                  <div
                    className={styles.checkbox}
                    onClick={() =>
                      clearErrors([
                        'anyWitnessesNameSecond',
                        'anyWitnessesAdressSecond',
                        'anyWitnessesEmailSecond',
                        'anyWitnessesPhoneSecond'
                      ])
                    }
                  >
                    <input
                      {...register('anyWitnessesDateSecondEmpty', { required: false })}
                      id='anyWitnessesDateSecondEmpty'
                      type='checkbox'
                    />
                    <label htmlFor='anyWitnessesDateSecondEmpty'>Daten unbekannt</label>
                  </div>
                  <Distance top='15' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesNameSecond'
                    fieldName='Name'
                    fieldPlaceholder='Max Mustermann'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesAdressSecond'
                    fieldName='Anschrift'
                    fieldPlaceholder='Musterstr. 100 12345 Berlin'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesEmailSecond'
                    fieldName='E-mail adresse'
                    fieldPlaceholder='Muster@mail.com'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesPhoneSecond'
                    fieldName='Telefon'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='10' />
                </div>

                <div
                  style={{ width: '100%' }}
                  onClick={() => setValue('showSecondPersonAnyWitnesses', true)}
                  className={watch('showSecondPersonAnyWitnesses') ? styles.hide__block : styles.hide__block__show}
                >
                  <Distance top='-10' />+ Weitere Person
                </div>

                <Distance top='10' />
              </div>
            </CSSTransition>
          </div>
        )}

        <Distance top={watch('anyWitnesses') === true ? '20' : '0'} />

        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='DetailedDamageDescription'
          fieldName='Ausführliche Schadenschilderung'
          fieldPlaceholder='Bitte schreib, wie der Schaden aufgetreten ist.'
        />

        <Distance top='20' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnToTypeButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ChooseDamageHail;
