class FieldsToValidateDetailCarInfo {
  common: Array<[string, string]> = [
    ['Schadendatum', 'Schadendatum'],
    ['Land', 'Land'],
    ['Stadt', 'Stadt'],
    ['Postleitzahl', 'Postleitzahl'],
    ['Strasse', 'Straße'],
    ['isContactPerson', 'isContactPerson']
  ];
  reportDamageAsVehicleUsers: Array<[string, string]> = [
    ['fahrerDataFullname', 'Name'],
    ['fahrerDataEmailaddress1', 'E-mail Adresse'],
    ['fahrerDataEmailaddressRepeat', 'E-mail Adresse'],
    ['fahrerDataMobilephone', 'Telefon']
  ];
  reportDamageAsByOtherPerson: Array<[string, string]> = [
    ['anotherPersonFahrerName', 'Name'],
    ['anotherPersonFahrerEmail', 'E-mail Adresse'],
    ['anotherPersonFahrerEmailRepeat', 'E-mail Adresse'],
    ['anotherPersonFahrerTelefon', 'Telefon']
  ];
  noContactPerson: Array<[string, string]> = [
    ['isContactPersonName', 'Name'],
    ['isContactPersonEmail', 'E-mail Adresse'],
    ['isContactPersonEmailRepeat', 'E-mail Adresse'],
    ['isContactPersonPhone', 'Telefon']
  ];
}

export const fieldsToValidateDetailCarInfo = new FieldsToValidateDetailCarInfo();

class ClearErrorsOnValueChange {
  contactPerson = [
    'isContactPersonName',
    'isContactPersonEmail',
    'isContactPersonEmailRepeat',
    'isContactPersonPhone',
    'isContactPersonPhoneSecond'
  ];
}

export const clearErrorsOnValueChange = new ClearErrorsOnValueChange();
