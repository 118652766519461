import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './leasing-request.module.scss';
import NumberFormat from 'react-number-format';
import cloneDeep from 'lodash/cloneDeep';
import UploadFile from '../../../setup-assistant/upload-document/upload-file';
import { STEP_STATUSES } from '../details/details';
import { crmDataTypes, LEASING_OFFER_STATUSES, MODAL_NAMES, MODALS, RequestStatuses } from '../../../../constants';
import { getRequestItem } from '../../../../utils/get-response-data';
import { updateLeasingangebote } from '../../utils';
import { useOverlay } from '../../../../hooks';
import sharepointService from '../../../../services/sharepoint-service';
import { setForm, toggleModal } from '../../../../actions/app_action';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../form';
import useLatestValue from '../../../../hooks/use-latest-value';
import { getFormValues } from '../../../../utils/get-form-values';
import { getUntouchedFields } from '../../../../utils/get-untouched-fields';
import { gapInsuranceFormFields, insuranceFormFields } from '../../../../models/forms/insurance-dialog-model';
import { setAdditionalValues } from './set-additional-values';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Text } from '../../../../common/common-components.styled';
import { StyledOneTimeCostsContainer } from '../../../../pages/details/order-widget/order-widget.styled';
import { DoneIcon } from 'components/icons/offer-statuses/done-icon';
import { DateBlueIcon } from '../../../icons/date-blue-icon';
import { StyledTextFieldWithoutBorders } from '../../../form-hook/form-field/form-field.styled';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledLeasingRequestDatePicker } from './leasing-request.styled';

export const AdditionalDataPanel = ({
  additionalDataPanel,
  step,
  onChange,
  onToggle,
  onTagClick,
  setNewSteps,
  bodyComponent
}) => {
  const dispatch = useDispatch();
  const forms = useSelector(state => state['app'].forms);
  const formsLatest = useLatestValue(forms);

  const getIcon = () => {
    const sum = additionalDataPanel.leasingInputs.reduce((accumulator, leasingInput) => {
      return accumulator + step.values[leasingInput.value].value;
    }, 0);

    if (additionalDataPanel.name === 'Dienstleistungen') {
      return sum > 0 ? (
        <div className={classes.title_container}>
          <NumberFormat
            value={sum}
            displayType={'text'}
            thousandSeparator='.'
            decimalSeparator=','
            fixedDecimalScale={true}
            decimalScale={2}
          />
          &nbsp;
          <span>EUR</span>
        </div>
      ) : null;
    }

    return !additionalDataPanel.expand && String(sum) !== '0' ? <DoneIcon /> : null;
  };

  const saveInsuranceData = () => {
    getFormValues(
      formsLatest.current,
      form => dispatch(setForm(form)),
      async values => {
        const newStep = cloneDeep(step);
        Object.entries(values).forEach(([key, value]) => {
          newStep.values[key].value =
            newStep.values[key].attributeTypeCode === crmDataTypes.Money
              ? parseFloat(String(value).replace(',', '.'))
              : value;
        });

        setNewSteps(newStep);
        dispatch(toggleModal(MODALS.insurance_dialog, null));
      }
    );
  };

  const clearInsuranceForm = formName => {
    onChange(0, formName);
    dispatch(toggleModal(MODALS.insurance_dialog, null));
  };

  const openInsuranceDialog = (event, fieldName) => {
    if (event.target.nodeName === 'INPUT' && ['new_versicherung_mobexo', 'uds_gap'].includes(fieldName)) {
      const dialogData = {
        buttons: [
          {
            type: 'cancel',
            title: 'Schließen',
            action: () => dispatch(toggleModal(MODALS.insurance_dialog, null))
          },
          {
            type: 'submit',
            title: 'Übernehmen',
            action: saveInsuranceData
          }
        ],
        clear: () => clearInsuranceForm(fieldName)
      };
      if (fieldName === 'new_versicherung_mobexo') {
        dialogData.title = 'Versicherung';
        dialogData.children = (
          <Form
            name={'new_versicherung_mobexo'}
            className={classes.insurance_dialog_form}
            formFields={insuranceFormFields}
          />
        );

        dispatch(
          setForm({
            name: 'new_versicherung_mobexo',
            form: {
              values: {
                new_versicherung_mobexo: step.values.new_versicherung_mobexo.value || '',
                uds_versicherung_sb_teilkasko: step.values.uds_versicherung_sb_teilkasko.value || '',
                new_versicherung_dienstleister: step.values.new_versicherung_dienstleister.value || null,
                uds_versicherung_sb_vollkasko: step.values.uds_versicherung_sb_vollkasko.value || ''
              },
              errors: {},
              untouched: getUntouchedFields(insuranceFormFields),
              isFormValid: true
            }
          })
        );
      }
      if (fieldName === 'uds_gap') {
        dialogData.title = 'GAP-Versicherung';
        dialogData.children = (
          <Form name={'uds_gap'} className={classes.insurance_dialog_form} formFields={gapInsuranceFormFields} />
        );

        dispatch(
          setForm({
            name: 'uds_gap',
            form: {
              values: {
                uds_gap: step.values.uds_gap.value || '',
                new_gap_dienstleister: step.values.new_gap_dienstleister.value || null
              },
              errors: {},
              untouched: getUntouchedFields(gapInsuranceFormFields),
              isFormValid: true
            }
          })
        );
      }
      event.target.blur();
      dispatch(toggleModal(MODALS.insurance_dialog, MODAL_NAMES.insurance_dialog, dialogData));
    }
  };

  return (
    <div
      className={
        additionalDataPanel.expand
          ? `${classes.additional_data_panel} ${classes.additional_data_panel_expanded}`
          : classes.additional_data_panel
      }
    >
      <div onClick={() => onToggle(additionalDataPanel.name)} className={classes.header}>
        <div className={classes.title_container}>
          <img
            src={additionalDataPanel.expand ? '/assets/images/minus_icon.svg' : '/assets/images/plus_icon.svg'}
            alt='header_icon'
          />
          <span>{additionalDataPanel.name}</span>
        </div>
        {getIcon()}
      </div>
      {additionalDataPanel.expand && (
        <div className={classes.body}>
          <div className={classes.tags}>
            {additionalDataPanel.name === 'Dienstleistungen' &&
              additionalDataPanel.leasingInputs.map(leasingInput => {
                if (leasingInput.isShow && !step.data[leasingInput.isShow]) {
                  return null;
                }
                return (
                  <div
                    key={leasingInput.label}
                    onClick={() => onTagClick(leasingInput.label)}
                    className={leasingInput.active ? `${classes.tag} ${classes.tag_active}` : classes.tag}
                  >
                    {`${leasingInput.active ? '' : '+ '}${leasingInput.label}`}
                  </div>
                );
              })}
          </div>
          <div className='mb-1' />
          {bodyComponent || null}
          {additionalDataPanel.leasingInputs.map(leasingInput => {
            if (additionalDataPanel.name === 'Dienstleistungen' && !leasingInput.active) {
              return null;
            }
            if (additionalDataPanel.name === 'Kommentar') {
              return (
                <div className={classes.textarea_container}>
                  <textarea
                    className={`${classes.no_borders} w-100`}
                    rows='3'
                    placeholder='Dein Kommentar...'
                    value={step.values[leasingInput.value].value}
                    onChange={e => onChange(e.target.value, leasingInput.value)}
                  />
                  {step.values[leasingInput.value].value && (
                    <img
                      onClick={() => onChange('', leasingInput.value)}
                      className={classes.clear_btn}
                      src='/assets/images/clear_icon.svg'
                      alt='clear_icon'
                    />
                  )}
                </div>
              );
            }
            return (
              <>
                <LeasingInput
                  key={leasingInput.label}
                  onChange={value => onChange(value.floatValue, leasingInput.value)}
                  value={step.values[leasingInput.value].value}
                  label={leasingInput.label}
                  labelColor={'#5F6466'}
                  onFocus={e => openInsuranceDialog(e, leasingInput.value)}
                  className={
                    leasingInput.className ? `${classes.no_borders} ${leasingInput.className}` : classes.no_borders
                  }
                  decimalScale={leasingInput.decimalScale}
                  prefix={leasingInput.prefix}
                  suffix={leasingInput.suffix}
                />
                <div className='mb-1' />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

AdditionalDataPanel.propTypes = {
  additionalDataPanel: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setNewSteps: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onTagClick: PropTypes.func.isRequired,
  bodyComponent: PropTypes.any
};

export const LeasingInputControl = ({
  suffix,
  prefix,
  completed = false,
  decimalScale,
  value,
  className,
  onChange,
  type,
  minDate,
  allowNegative,
  disabled
}) => {
  const [showClearButton, setShowClearButton] = useState(false);

  const onMouseEnter = () => {
    if (!suffix) {
      setShowClearButton(true);
    }
  };

  const onMouseLeave = () => {
    if (!suffix) {
      setShowClearButton(false);
    }
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className ? `${classes.input_block} ${className}` : classes.input_block}
    >
      {prefix && (
        <div
          className={
            disabled
              ? `${classes.currency_block} ${classes.currency_block_prefix} ${classes.disabled}`
              : `${classes.currency_block} ${classes.currency_block_prefix}`
          }
        >
          {prefix || ''}
        </div>
      )}
      <div
        style={{ width: prefix ? 'calc(100% - 40px)' : '100%' }}
        className={
          type === 'text' ? `${classes.input_suffix_container} ${classes.text_input}` : classes.input_suffix_container
        }
      >
        {type === 'text' ? (
          <>
            <input
              type='text'
              value={value}
              className={!completed ? `${classes.input} ${classes.text_input}` : ''}
              onChange={onChange}
            />
            {showClearButton && (
              <span className={suffix ? classes.currency_block : `${classes.currency_block} no-border`}>
                <img
                  onClick={() => onChange({ target: { value: '' } })}
                  className={classes.clear_btn}
                  src='/assets/images/clear_icon.svg'
                  alt='clear_icon'
                />
              </span>
            )}
          </>
        ) : type === 'date' ? (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StyledLeasingRequestDatePicker
                views={['month', 'year']}
                components={{
                  OpenPickerIcon: () => <DateBlueIcon />
                }}
                minDate={minDate}
                value={value}
                inputFormat={'MM . yyyy'}
                onChange={onChange}
                renderInput={({ inputProps, ...restParams }) => (
                  <StyledTextFieldWithoutBorders
                    {...restParams}
                    error={false}
                    inputProps={{
                      ...inputProps,
                      placeholder: '',
                      error: false
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {showClearButton && (
              <span className={suffix ? classes.currency_block : `${classes.currency_block} no-border`}>
                <img
                  onClick={() => onChange(null)}
                  className={classes.clear_btn}
                  src='/assets/images/clear_icon.svg'
                  alt='clear_icon'
                />
              </span>
            )}
          </>
        ) : (
          <>
            <NumberFormat
              className={!completed ? (disabled ? `${classes.input} ${classes.disabled}` : classes.input) : ''}
              value={value}
              displayType={!completed ? 'input' : 'text'}
              thousandSeparator='.'
              decimalSeparator=','
              allowNegative={allowNegative}
              disabled={disabled}
              fixedDecimalScale={true}
              decimalScale={decimalScale}
              onValueChange={onChange}
            />
            {suffix ? (
              <span
                className={suffix ? classes.currency_block : `${classes.currency_block} no-border`}
                onMouseEnter={() => setShowClearButton(true)}
                onMouseLeave={() => setShowClearButton(false)}
              >
                {showClearButton && !disabled ? (
                  <img
                    onClick={() => onChange({ floatValue: 0 })}
                    className={classes.clear_btn}
                    src='/assets/images/clear_icon.svg'
                    alt='clear_icon'
                  />
                ) : (
                  suffix
                )}
              </span>
            ) : showClearButton ? (
              <span className={suffix ? classes.currency_block : `${classes.currency_block} no-border`}>
                <img
                  onClick={() => onChange({ target: { value: '' } })}
                  className={classes.clear_btn}
                  src='/assets/images/clear_icon.svg'
                  alt='clear_icon'
                />
              </span>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

LeasingInputControl.propTypes = {
  completed: PropTypes.bool,
  minDate: PropTypes.string,
  decimalScale: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
};

const LeasingInput = ({
  label,
  suffix,
  prefix,
  className,
  decimalScale = 2,
  value,
  onChange,
  onFocus,
  completed = false,
  type,
  labelColor = '#000'
}) => {
  return (
    <div onFocus={onFocus} className={classes.leasing_input}>
      <span style={{ color: labelColor }} className={classes.label}>
        {label}
      </span>
      <LeasingInputControl
        suffix={suffix}
        prefix={prefix}
        decimalScale={decimalScale}
        value={value || ''}
        className={className}
        type={type}
        onChange={onChange}
        completed={completed}
      />
    </div>
  );
};

LeasingInput.propTypes = {
  label: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  labelColor: PropTypes.string,
  decimalScale: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  completed: PropTypes.bool
};

export const LeasingValue = ({ label, value, units, decimalScale = 2, className, sign }) => {
  return (
    <div className={className ? `${classes.leasing_value} ${className}` : classes.leasing_value}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>
        {sign && <span style={{ marginRight: '40px' }}>{sign}</span>}
        {typeof value === 'string' ? (
          value
        ) : (
          <NumberFormat
            value={value}
            displayType='text'
            thousandSeparator='.'
            decimalSeparator=','
            fixedDecimalScale={true}
            decimalScale={decimalScale}
          />
        )}

        {units && (
          <>
            &nbsp;<span>{units}</span>
          </>
        )}
      </span>
    </div>
  );
};

LeasingValue.propTypes = {
  label: PropTypes.string.isRequired,
  sign: PropTypes.string,
  decimalScale: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  units: PropTypes.string,
  className: PropTypes.string
};

const LeasingRequestComponent = ({
  step,
  details,
  snackbarSetup,
  refreshLeasingOffers,
  setNewSteps,
  setRefreshKey
}) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [validationError, setValidationError] = useState(false);
  const [isOfferConfirmed, setIsOfferConfirmed] = useState(false);
  const [dontSendOffer, setDontSendOffer] = useState(false);
  const user = useSelector(state => state['app'].user);

  const sumServices = useMemo(() => {
    const servicesAdditionalDataPanel = step.additionalDataPanels.find(panel => panel.name === 'Dienstleistungen');
    return servicesAdditionalDataPanel.leasingInputs.reduce((accumulator, leasingInput) => {
      if (leasingInput.isShow && !step.data[leasingInput.isShow]) {
        return accumulator;
      }
      if (step.status === STEP_STATUSES.completed) {
        return accumulator + (step.data[`${leasingInput.value}_value`] || 0);
      }
      return accumulator + step.values[leasingInput.value].value;
    }, 0);
  }, [step.values]);

  useEffect(() => {
    const newStep = cloneDeep(step);
    const maintenanceInput = newStep.additionalDataPanels
      .find(panel => panel.name === 'Dienstleistungen')
      .leasingInputs.find(leasingInput => leasingInput.label === 'Wartung und Verschleiß *');
    maintenanceInput.className =
      validationError && !step.values.new_wartung_und_verschless_imobexo.value ? classes.error : null;

    setNewSteps(newStep);
  }, [validationError, step.values.new_wartung_und_verschless_imobexo.value]);

  const onToggle = panelName => {
    const newStep = cloneDeep(step);
    newStep.additionalDataPanels.forEach(panel => {
      if (panel.name === panelName) {
        panel.expand = !panel.expand;
        return;
      }
      panel.expand = false;
    });

    setNewSteps(newStep);
  };

  const onAddingNewFiles = files => {
    const newStep = cloneDeep(step);
    newStep.values = {
      ...newStep.values,
      files
    };

    setNewSteps(newStep);
  };

  const onChange = (value, name) => {
    const newStep = cloneDeep(step);
    newStep.values[name].value = value === undefined ? 0 : value;
    if (name === 'new_versicherung_mobexo' && value === 0) {
      newStep.values.uds_versicherung_sb_teilkasko.value = '';
      newStep.values.new_versicherung_dienstleister.value = null;
      newStep.values.uds_versicherung_sb_vollkasko.value = '';
    }
    if (name === 'uds_gap' && value === 0) {
      newStep.values.new_gap_dienstleister.value = null;
    }
    if (name === 'uds_leasingfaktor' && value === 0) {
      return;
    }
    if (name === 'uds_leasingfaktor' && (value > 5 || value < 0.1)) {
      newStep.values[name].value = step.values[name].value;
    }
    setNewSteps(newStep);
  };

  const submit = async () => {
    if (!step.values.new_wartung_und_verschless_imobexo.value) {
      const newStep = cloneDeep(step);
      newStep.additionalDataPanels.forEach(panel => {
        if (panel.name === 'Dienstleistungen') {
          panel.expand = true;
          return;
        }
        panel.expand = false;
      });
      setNewSteps(newStep);
    }
    if (
      !step.values.new_finanzratemobexo.value ||
      !step.values.uds_leasingfaktor.value ||
      !step.values.uds_angebotnumber.value ||
      !step.values.new_wartung_und_verschless_imobexo.value ||
      !step.values.files?.length
    ) {
      setValidationError(true);
      return;
    }
    showOverlay();
    const requestData = {};

    step.values = setAdditionalValues(step.values, user.companyId);

    Object.entries(step.values).forEach(([key, value]) => {
      if (key !== 'files' && value.value) {
        if (
          ['uds_mehrkilometer', 'uds_minderkilometer', 'uds_je_mehrkilometer_wv', 'uds_je_minderkilometer_wv'].includes(
            key
          )
        ) {
          requestData[key] = getRequestItem(value.value / 100, value.attributeTypeCode);
          return;
        }
        requestData[key] = getRequestItem(value.value, value.attributeTypeCode);
      }
    });
    requestData.uds_leasingangeboteid = getRequestItem(step.data.uds_leasingangeboteid, crmDataTypes.Uniqueidentifier);
    requestData.uds_bezugid = getRequestItem(step.data.uds_bezugid_id, crmDataTypes.Lookup);
    requestData.uds_angebotsstatuscode = getRequestItem(LEASING_OFFER_STATUSES.priceReleased, crmDataTypes.Picklist);

    const dataToSend = {
      entityName: 'uds_leasingangebote',
      id: step.data.uds_leasingangeboteid,
      attributes: requestData
    };

    let leasingOfferResponse;
    let fileResponse;
    leasingOfferResponse = await updateLeasingangebote(dataToSend);
    if (leasingOfferResponse.data?.level === RequestStatuses.Success) {
      fileResponse = await sharepointService.uploadSharepointFile(
        step.values.files,
        'Leasingangebot an Kunde',
        step.data.uds_leasingangeboteid,
        'uds_leasingangebote'
      );
    }

    if (
      leasingOfferResponse.data?.level === RequestStatuses.Success &&
      fileResponse.data?.level === RequestStatuses.Success
    ) {
      snackbarSetup('Angebot wurde übermittelt.', 'success');
      refreshLeasingOffers();
      setRefreshKey(oldKey => oldKey + 1);
    } else {
      snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
      hideOverlay();
    }
    document.querySelector('.scroll-container').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const decline = async () => {
    showOverlay();
    const requestData = {};
    requestData.uds_leasingangeboteid = getRequestItem(step.data.uds_leasingangeboteid, crmDataTypes.Uniqueidentifier);
    requestData.uds_bezugid = getRequestItem(step.data.uds_bezugid_id, crmDataTypes.Lookup);
    requestData.uds_angebotsstatuscode = getRequestItem(LEASING_OFFER_STATUSES.offerDeclined, crmDataTypes.Picklist);

    const dataToSend = {
      entityName: 'uds_leasingangebote',
      id: step.data.uds_leasingangeboteid,
      attributes: requestData
    };

    const leasingOfferResponse = await updateLeasingangebote(dataToSend);

    if (leasingOfferResponse.data?.level === RequestStatuses.Success) {
      snackbarSetup('Änderungen wurden übernommen.', 'success');
      refreshLeasingOffers();
    } else {
      snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
      hideOverlay();
    }
    document.querySelector('.scroll-container').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onTagClick = tagName => {
    const newStep = cloneDeep(step);
    const servicesAdditionalDataPanel = newStep.additionalDataPanels.find(panel => panel.name === 'Dienstleistungen');
    const clickedLeasingInput = servicesAdditionalDataPanel.leasingInputs.find(
      leasingInput => leasingInput.label === tagName
    );
    if (!clickedLeasingInput.required) {
      newStep.values[clickedLeasingInput.value].value = 0;
      clickedLeasingInput.active = !clickedLeasingInput.active;
    }

    setNewSteps(newStep);
  };

  const isSectionFilled = sectionName => {
    const section = step.additionalDataPanels.find(additionalDataPanel => additionalDataPanel.name === sectionName);
    const sum = section.leasingInputs.reduce((accumulator, leasingInput) => {
      return accumulator + (step.data[`${leasingInput.value}_value`] || step.data[leasingInput.value] || 0);
    }, 0);

    return sum > 0;
  };

  const cancelOfferConfirmation = () => {
    setIsOfferConfirmed(false);
    setValidationError(false);
  };

  if (step.data.uds_angebotsstatuscode_value === LEASING_OFFER_STATUSES.offerDeclined) {
    return (
      <div className={classes.leasing_request_component}>
        <div className='mobexo-divider' />
        <div className='mb-2' />
        <div className={`d-flex j-c-space-between a-i-center ${classes.leasing_and_services}`}>
          <span className={classes.label}>Status</span>
          <span className={classes.dont_send_confirmed_message}>
            <img src='/assets/images/barpreisanfrage_details_cross_icon.svg' alt='cross_icon' />
            <span>Abgelehnt durch Leasinggesellschaft</span>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.leasing_request_component}>
      <div className='mobexo-divider' />
      <div className='mb-1' />
      {step.data.uds_angebotnumber && step.status === STEP_STATUSES.completed && (
        <>
          <LeasingValue className={classes.offer_number} label={'Angebot-Nr.'} value={step.data.uds_angebotnumber} />
          <div className='mb-2' />
        </>
      )}
      <LeasingValue
        label={'Nettolistenpreis (zzgl. MwSt)'}
        value={details.uds_listenpreis_netto_value || 0}
        units={'EUR'}
      />
      <div className='mb-1' />
      <LeasingValue
        label={'Barpreis ab Werk (zzgl. MwSt)'}
        value={details.uds_barpreis_netto_value || 0}
        units={'EUR'}
      />
      <div className='mb-1' />
      <LeasingValue
        label={'Jahresfahrleistung'}
        value={step.data.uds_laufleistungpa || 0}
        units={'km'}
        decimalScale={0}
      />
      <div className='mb-1' />
      <LeasingValue label={'Anzahlung / BAFA'} value={details.uds_einmaligekosten_anzahlung_value || 0} units={'EUR'} />
      <div className='mb-1' />

      {step.data.uds_kommentar_fuer_leasinggesellschaft ? (
        <>
          <Text margin='30px 0 10px' color='grey600'>
            Kommentar zur Anfrage
          </Text>
          <StyledOneTimeCostsContainer bottom='30'>
            <Text color='grey600'>{step.data.uds_kommentar_fuer_leasinggesellschaft}</Text>
          </StyledOneTimeCostsContainer>
        </>
      ) : (
        <div className='mt-30' />
      )}

      {step.status === STEP_STATUSES.active && (
        <>
          <LeasingInput
            label={'Finanzrate *'}
            suffix={'EUR'}
            decimalScale={2}
            className={validationError && !step.values.new_finanzratemobexo.value ? classes.error : null}
            value={step.values['new_finanzratemobexo'].value}
            onChange={value => onChange(value.floatValue, 'new_finanzratemobexo')}
            completed={false}
          />
          <div className='mb-1' />
          <LeasingInput
            label={'Leasingfaktor *'}
            decimalScale={2}
            suffix={''}
            className={validationError && !step.values.uds_leasingfaktor.value ? classes.error : null}
            value={step.values['uds_leasingfaktor'].value}
            onChange={value => onChange(value.floatValue, 'uds_leasingfaktor')}
            completed={false}
          />
          <div className='mb-1' />
          {step.additionalDataPanels.map(panel => (
            <AdditionalDataPanel
              key={panel.name}
              onToggle={onToggle}
              onChange={onChange}
              step={step}
              setNewSteps={setNewSteps}
              additionalDataPanel={panel}
              onTagClick={onTagClick}
            />
          ))}
          <div className='mb-1' />
        </>
      )}
      {step.status === STEP_STATUSES.completed && (
        <>
          <div className='mt-30' />
          <LeasingValue label={'Finanzrate'} value={step.data.new_finanzratemobexo_value || 0} units={'EUR'} />
          <div className='mb-1' />
          <LeasingValue label={'Dienstleistungen'} value={sumServices} units={'EUR'} sign={'+'} />
          <div className='mb-1' />
        </>
      )}
      <div className='mobexo-divider' />
      <div className='mb-1' />
      <LeasingValue
        className={classes.leasing_and_services}
        label={'Leasing-Rate inkl. Dienstleistungen'}
        value={
          step.status === STEP_STATUSES.completed
            ? (step.data.new_finanzratemobexo_value || 0) + sumServices
            : step.values['new_finanzratemobexo'].value + sumServices
        }
        units={'EUR'}
      />
      {step.status === STEP_STATUSES.completed && (
        <>
          <div className='mt-30' />
          <LeasingValue label={'Leasingfaktor'} value={step.data.uds_leasingfaktor || 0} />
          <div className='mb-1' />
          {isSectionFilled('Kilometerabrechnung Leasing') && (
            <LeasingValue label={'Kilometerabrechnung Leasing'} value={'eingetragen'} />
          )}
          <div className='mb-1' />
          {isSectionFilled('Kilometerabrechnung Wartung und Verschleiß') && (
            <LeasingValue label={'Kilometerabrechnung Wartung und Verschleiß'} value={'eingetragen'} />
          )}
          <div className='mt-30' />
          <div className={classes.comment_container}>
            <span>Kommentar:</span>
            <div className={classes.comment}>
              {step.data.uds_kommentar_der_leasinggesellschaft
                ? step.data.uds_kommentar_der_leasinggesellschaft
                : 'Du hast keinen Kommentar hinterlassen.'}
            </div>
          </div>
          <div className='mt-30' />
        </>
      )}
      <div className='mb-1' />
      <span className={classes.note}>
        Alle Werte zzgl. der jeweiligen Umsatzsteuer, die jeweils gültige Umsatzsteuer auf steuerbare Dienstleistungen
        wird gesondert berechntet.
      </span>
      <div className='mt-30' />
      {step.status === STEP_STATUSES.active && (
        <div>
          {!isOfferConfirmed ? (
            <div className={classes.make_offer}>
              {dontSendOffer ? (
                <>
                  <span className={classes.no_underline}>Bitte nochmal bestätigen.</span>
                  <div className='d-flex'>
                    <button onClick={decline} className='button-mobexo button-mobexo__secondary'>
                      Bestätigen
                    </button>
                    <div className='mr-1' />
                    <button onClick={() => setDontSendOffer(false)} className='button-mobexo button-mobexo__main'>
                      Zurück
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <span onClick={() => setDontSendOffer(true)}>Ich möchte kein Angebot abgeben x</span>
                  <button onClick={() => setIsOfferConfirmed(true)} className='button-mobexo button-mobexo__main'>
                    Angebot abgeben
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <LeasingInput
                label={'Angebot-Nr. *'}
                type={'text'}
                suffix={''}
                value={step.values['uds_angebotnumber'].value}
                className={validationError && !step.values.uds_angebotnumber.value ? classes.error : null}
                onChange={e => onChange(e.target.value, 'uds_angebotnumber')}
                completed={false}
              />
              <div className='mt-15' />
              <div>
                <UploadFile
                  onDropAccepted={onAddingNewFiles}
                  accept={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']}
                  acceptString={'pdf, jpg, jpeg, png'}
                  className={validationError && !step.values.files?.length ? classes.error : null}
                  multiple={true}
                  required={true}
                />
              </div>
              <div className='mt-15' />
              <div className={classes.buttons}>
                <button onClick={cancelOfferConfirmation} className='button-mobexo button-mobexo__secondary'>
                  Abbrechen
                </button>
                <div className='mr-1' />
                <button onClick={submit} className='button-mobexo button-mobexo__main'>
                  Absenden
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

LeasingRequestComponent.propTypes = {
  step: PropTypes.object,
  snackbarSetup: PropTypes.func.isRequired,
  setNewSteps: PropTypes.func.isRequired,
  refreshLeasingOffers: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  setRefreshKey: PropTypes.func
};

export default LeasingRequestComponent;
