import { split } from 'lodash';

const prefix = '@OData.Community.Display.V1.FormattedValue';

export function optionset(data, name) {
  return {
    value: data[name] || 0,
    name: data[`${name}${prefix}`] || ''
  };
}

export function lookup(data, name) {
  return {
    id: data[`_${name}_value`] || '',
    name: data[`_${name}_value${prefix}`] || '',
    entity: name
  };
}

const linkEntityPrefix = '_LinkEntityAliasPrefix';
export function linkEntityField(data, linkField, field) {
  return data[`${linkEntityPrefix}${linkField}.${field}`];
}

export function parseTags(data) {
  const arr = split(data, '|').map(t => t.trim());
  return arr[0] ? arr : [];
}
