import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getUserCompanyDriverLevels(ContactId, Pagination = { start: 1, number: 100 }) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrerlevel/companylist`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      Pagination
    }
  });
}

export async function createDriverLevel(ContactId, FahrerLevel) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrerlevel/createfahrerlevel`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      FahrerLevel
    }
  });
}

export async function updateDriverLevel(ContactId, NewFahrerLevel) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrerlevel/updatefahrerlevel`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      NewFahrerLevel
    }
  });
}

export async function getFahrerLevel(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fahrerlevel/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
