import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// export async function getVehicleListAsync(smartTableParam, basketId, personalConfigurations, checkLeasingangebote) {
//   const token = await authService.acquireTokenAsync();
//   return axios({
//     method: 'POST',
//     url: `${API_ENDPOINT}/api/vehicle/list`,
//     headers: {
//       Authorization: `Bearer ${token}`
//     },
//     data: {
//       ...smartTableParam,
//       basketId,
//       personalConfigurations,
//       checkLeasingangebote
//     }
//   });
// }

export async function getReconfigurationsList(smartTableParam, basketId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/getReconfigurationsList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...smartTableParam,
      basketId
    }
  });
}

export async function getReconfigurationsListFilterData(basketId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetReconfigurationsListFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      basketId
    }
  });
}

export async function getCompanyReferenceVehicleList(smartTableParam, basketId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/getCompanyReferenceVehicleList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...smartTableParam,
      basketId
    }
  });
}

export async function getCompanyReferenceVehicleListFilterData(basketId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetCompanyReferenceVehicleListFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      basketId
    }
  });
}

export async function getReferenceVehicleList(smartTableParam, basketId, configurationTypeCode) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/getReferenceVehicleList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...smartTableParam,
      basketId,
      configurationTypeCode
    }
  });
}

export async function getReferenceVehicleListFilterData(basketId, configurationTypeCode) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetReferenceVehicleListFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      basketId,
      configurationTypeCode
    }
  });
}

export async function getMarksListAsync() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/vehicle/marks`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getModelsListAsync(mark) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/models`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      mark
    }
  });
}

export async function getCompanyContactsVehicles(tableParams) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetCompanyContactsVehicles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...tableParams
    }
  });
}

export async function getCompanyContactsVehiclesFilterData() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetCompanyContactsVehiclesFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}

export async function getGlobalVehicleListAsync(smartTableParam, basketId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/GetGlobalReferenceVehicleList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...smartTableParam,
      basketId
    }
  });
}
