import FormField from 'components/form-hook/form-field/form-field';
import React from 'react';
import { InspectionDateCheckboxRadioOptionSetValue, InspectionDateLinkToCarModalFormProps } from './link-to-car.props';
import { getInspectionDateCheckboxFormFields, inspectionDateFormFields } from './link-to-car.field';

export const InspectionDateLinkToCarModalForm = ({
  reactHookFormData,
  firstRegistrationDate
}: InspectionDateLinkToCarModalFormProps) => {
  const { watch } = reactHookFormData;

  return (
    <div>
      <FormField
        reactHookFormData={reactHookFormData}
        field={getInspectionDateCheckboxFormFields(firstRegistrationDate)}
        distanceBetweenFields='10'
      />
      {watch('inspectionDateCheckbox') === InspectionDateCheckboxRadioOptionSetValue.IndividualDate && (
        <FormField reactHookFormData={reactHookFormData} field={inspectionDateFormFields} />
      )}
    </div>
  );
};
