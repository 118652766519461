import styled from 'styled-components';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface StyledReportDamageDatePickerProps {
  error?: boolean;
  borderColor?: string;
}

export const StyledReportDamageDatePicker = styled(DesktopDatePicker)<StyledReportDamageDatePickerProps>`
  width: 100%;
  border: 1px solid
    ${({ theme, error, borderColor }) =>
      error ? theme.colors.pink : borderColor ? borderColor : theme.colors.lightBlue} !important;
  border-radius: 2px;

  & .MuiOutlinedInput-root {
    padding: 8px 15px;
    flex-direction: row-reverse;
    letter-spacing: 0.01em;

    & .MuiIconButton-root {
      margin-right: 0;
      margin-left: -24px;
    }

    & .MuiInputBase-input {
      color: ${({ theme }) => theme.colors.grey800};

      &::placeholder {
        opacity: 0.7;
      }
    }
  }
`;
