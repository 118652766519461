import styled from 'styled-components';
import { IFormInfo } from './form-info.props';
import { Flex, Text } from 'common/common-components.styled';

export const StyledContainer = styled(Flex)<Pick<IFormInfo, 'isClickable'>>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  transition: 0.3s;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const StyledAnchor = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
`;

export const StyledValue = styled(Text)`
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
`;
