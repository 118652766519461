import { IconProps } from 'common/interfaces';
import React from 'react';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const PhoneIcon = ({ width = 25, height = 25, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.98535 3.08935C9.22559 3.2124 9.48926 3.30615 9.70019 3.47021C9.99902 3.69873 10.1572 4.03271 10.1748 4.40771C10.2158 5.22803 10.2627 6.04834 10.4619 6.85693C10.5381 7.17334 10.6318 7.48389 10.7197 7.80029C10.8545 8.3042 10.7314 8.73779 10.415 9.14209C9.91113 9.78662 9.4248 10.4429 8.93262 11.0991C8.90332 11.1401 8.89746 11.2222 8.9209 11.2632C9.6123 12.5347 10.4443 13.7007 11.5635 14.6382C12.2549 15.2183 13.0107 15.7104 13.8018 16.1382C13.8428 16.1616 13.9307 16.1558 13.9658 16.1265C14.6338 15.6284 15.3018 15.1245 15.9697 14.6147C16.3506 14.3276 16.7666 14.2397 17.2236 14.3394C17.8096 14.4741 18.3896 14.644 18.9756 14.7436C19.4619 14.8257 19.9658 14.8667 20.458 14.8667C21.1846 14.8667 21.8232 15.3413 21.958 16.0386C21.9639 16.062 21.9756 16.0796 21.9873 16.103C21.9873 17.3452 21.9873 18.5815 21.9873 19.8237C21.9756 19.8589 21.9639 19.8882 21.9521 19.9233C21.7471 20.6558 21.2197 21.0659 20.4639 21.0483C19.9307 21.0366 19.3975 21.0249 18.8701 20.9722C17.7861 20.8608 16.7256 20.6499 15.6826 20.3276C13.1689 19.5483 10.9658 18.2476 9.06738 16.4253C7.37402 14.7964 6.08496 12.8979 5.19434 10.73C4.56738 9.19482 4.18066 7.60693 4.05176 5.95459C4.03418 5.77295 4.01074 5.58545 3.9873 5.40381C3.9873 5.04053 3.9873 4.67725 3.9873 4.31396C4.08691 4.09131 4.16309 3.85693 4.29199 3.65771C4.50293 3.32959 4.84863 3.17725 5.21777 3.0835C6.47754 3.08936 7.73145 3.08935 8.98535 3.08935Z'
        stroke={color}
      />
    </svg>
  );
};
