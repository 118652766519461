import { IconProps } from 'common/interfaces';
import React from 'react';

export const DoubleRightArrow = ({ width = 21, height = 21, color = '#102F3F', className }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 21 21'
    fill='none'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.29728 3.38281L11.5461 10.305L4.13438 17.3828'
      stroke={color}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5913 3.38281L17.8402 10.305L10.4284 17.3828'
      stroke={color}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
