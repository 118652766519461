import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const BillIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M1.37737 1H11.0716H14.1312C14.3964 1 14.6508 1.10536 14.8383 1.29289L14.8942 1.34877C15.0724 1.52704 15.1768 1.76606 15.1863 2.01799L15.3129 5.35484V17.9921V18.4194C15.3129 18.7387 15.0402 19 14.707 19H1.37737C1.04414 19 0.771484 18.7387 0.771484 18.4194V1.58065C0.771484 1.26129 1.04414 1 1.37737 1Z'
          fill={fill}
        />
      )}
      <path
        d='M15.3129 7.08829V5.35484V2.12568C15.3129 1.8375 15.1885 1.56333 14.9718 1.37345L14.8283 1.24776C14.6459 1.08805 14.4118 1 14.1694 1H11.0716H1.37737C1.04414 1 0.771484 1.26129 0.771484 1.58065V18.4194C0.771484 18.7387 1.04414 19 1.37737 19H14.707C15.0402 19 15.3129 18.7387 15.3129 18.4194V17.9921'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.771 10.0207C16.378 7.86825 14.4322 9.57307 13.8893 11.4883C13.7849 11.8569 13.7785 12.4631 13.7785 12.4631C13.7785 12.4631 13.7694 13.1473 13.8893 13.5613C14.4448 15.4779 16.378 17.2232 18.771 15.0708'
        stroke={color}
        strokeLinecap='round'
      />
      <path d='M16.7496 11.5571L12.7456 11.5504' stroke={color} strokeLinecap='round' />
      <path d='M16.7117 13.4912H12.7456' stroke={color} strokeLinecap='round' />
      <path d='M3.77295 4.76849H6.77295' stroke={color} strokeLinecap='round' />
      <path d='M3.77295 7.4194H6.77295' stroke={color} strokeLinecap='round' />
      <path d='M3.77295 10H6.77295' stroke={color} strokeLinecap='round' />
      <path d='M3.77295 14.8784H6.12075' stroke={color} strokeLinecap='round' />
      <path d='M3.77295 12.5406H10.5273' stroke={color} strokeLinecap='round' />
    </svg>
  );
};
