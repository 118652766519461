import { useCostCenterPrimaryEntity } from 'components/cost-centers-settings/hooks/use-cost-center-main-entity';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const useVehicleDetailsCostCenterPrimaryEntity = () => {
  const user = useTypedSelector(state => state.app.user);

  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const costCenterPrimaryEntity = useCostCenterPrimaryEntity(fuhrpark?.new_sparteid_id || user.companyId);

  return costCenterPrimaryEntity;
};
