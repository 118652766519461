import { Flex } from 'common/common-components.styled';
import { StyledDiagramContainer } from 'components/containers/diagram/diagram.styled';
import { DrivingCarIcon } from 'components/icons-new/driving-car';
import React from 'react';
import { ChangeKilometerForm } from '../../../change-kilometer-form/change-kilometer-form';

export const NoMilageDriver = () => {
  return (
    <StyledDiagramContainer align='center' mobilePadding='30px'>
      <Flex justify='center' bottom='10'>
        <DrivingCarIcon />
      </Flex>

      <ChangeKilometerForm width='80%' />
    </StyledDiagramContainer>
  );
};
