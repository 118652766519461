import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { AnyObject, ResponseModel } from 'common/interfaces';
import { getEntityByFieldQuery } from 'services/crm-entity-service';
import { crmDataTypes, RequestStatuses } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { ConditionOperator } from 'common/enums';

export const useCarDealershipContacts = (companyId: string | null) => {
  const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
    if (data.level === RequestStatuses.Success) {
      const responseArray = getResponseFormData(data, [{ name: 'entityes', type: 'array' }]) as AnyObject[];
      return responseArray.map(item => ({
        value: item.contactid,
        label: item.fullname
      }));
    }
    return undefined;
  };

  const { data: contactPersonOptions } = useQuery(
    ['car-dealership-contacts', companyId],
    () =>
      getEntityByFieldQuery({
        entityName: 'contact',
        columns: ['fullname', 'contactid'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.Lookup,
            attributeName: 'parentcustomerid',
            conditionOperator: ConditionOperator.In,
            value: [
              {
                id: companyId,
                name: ''
              }
            ]
          },
          {
            attributeTypeCode: crmDataTypes.State,
            attributeName: 'statecode',
            conditionOperator: ConditionOperator.Equal,
            value: [{ value: '0' }]
          }
        ]
      }),
    {
      select: modifyResponse,
      enabled: !!companyId
    }
  );

  return {
    contactPersonOptions
  };
};
