import { AxiosRequestConfig } from 'axios';

class CrmCheckoutRequestConfig {
  private RESOURCE = 'crmcheckout';

  getFile = (folderName: string): AxiosRequestConfig => ({
    method: 'GET',
    url: `${this.RESOURCE}/Entscheidungshilfen/${folderName}`,
    responseType: 'blob'
  });

  getContractPdf = (id: string): AxiosRequestConfig => ({
    method: 'GET',
    url: `${this.RESOURCE}/GetVertragPdf/${id}`,
    responseType: 'blob'
  });

  getContractPdfPreview = (contractId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/GetVertragPdfPreview`,
    responseType: 'blob',
    data: {
      vertragId: contractId
    }
  });

  getExternalSigningZip = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/externalSigning/GetExternalSigningZip`,
    responseType: 'blob',
    data: {
      id
    }
  });
}

export const crmCheckoutRequestConfig = new CrmCheckoutRequestConfig();
