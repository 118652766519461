import { PickValue } from 'common/interfaces';
import { IVehicleDetailsState } from 'reducers/page/vehicle-details/vehicle-details.props';

export const MAIN_TAB = 'MAIN_TAB';
export const LEASING_TAB = 'LEASING_TAB';
export const FIELDS = 'FIELDS';

export const setVehicleDetailsMainTab = (mainTab: PickValue<IVehicleDetailsState, 'mainTab'>) => ({
  type: MAIN_TAB,
  payload: mainTab
});

export const setVehicleDetailsLeasingTab = (leasingTab: PickValue<IVehicleDetailsState, 'leasingTab'>) => ({
  type: LEASING_TAB,
  payload: leasingTab
});
