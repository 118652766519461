import { AxiosRequestConfig } from 'axios';
import { UpdateEmailPayload } from './account.types';

class AccountRequestConfig {
  updateEmail = (data: UpdateEmailPayload): AxiosRequestConfig => ({
    method: 'PATCH',
    url: 'account',
    data
  });

  getUserInfo = (): AxiosRequestConfig => ({
    method: 'GET',
    url: 'account/roles'
  });
}

export const accountRequestConfig = new AccountRequestConfig();
