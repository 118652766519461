import { OFFER_STATUSES } from '../constants';
import { VehicleOfferEvent, VehicleOfferEventDescription } from 'common/interfaces';
import { EventProps } from 'components/product/product-view.props';

export function takeLatestEvent(events: EventProps[], field: keyof EventProps = 'createdon') {
  if (!events.length) {
    return null;
  }

  return events.sort(
    (a, b) =>
      new Date(b[field].attributeValue as string).getTime() - new Date(a[field].attributeValue as string).getTime()
  )[0];
}

export function takeOldestEvent(events: EventProps[], field: keyof EventProps = 'createdon') {
  if (!events.length) {
    return null;
  }

  return events.sort(
    (a, b) =>
      new Date(a[field].attributeValue as string).getTime() - new Date(b[field].attributeValue as string).getTime()
  )[0];
}

export function getEventsByType(events: EventProps[], type: VehicleOfferEvent) {
  return events.filter(event => event.uds_eventdescription.attributeValue?.id === type.id);
}

export function getEventDescription(event: EventProps, vehicleOffersEventDescriptions: VehicleOfferEventDescription[]) {
  if (!vehicleOffersEventDescriptions) {
    return null;
  }
  const eventDescriptionId = event.uds_eventdescription.attributeValue?.id;
  return vehicleOffersEventDescriptions.find(
    eventDescription => eventDescription.uds_fahrzeugbestellungeneventdescriptionid === eventDescriptionId
  );
}

export function getMajorEvent(events: EventProps[], vehicleOffersEventDescriptions: VehicleOfferEventDescription[]) {
  if (!events?.length || !vehicleOffersEventDescriptions) {
    return {} as EventProps;
  }
  let majorEvent = events[0];
  events.forEach(event => {
    const eventDescription = getEventDescription(event, vehicleOffersEventDescriptions);
    const majorEventDescription = getEventDescription(majorEvent, vehicleOffersEventDescriptions);
    if ((eventDescription?.uds_hierarchie || 0) > (majorEventDescription?.uds_hierarchie || 0)) {
      majorEvent = event;
    }
  });

  return majorEvent;
}

export function getVehicleOfferStatus(
  events: EventProps[],
  vehicleOffersEventDescriptions: VehicleOfferEventDescription[],
  isStatusForFleetManager: boolean,
  isDriverReferenzfahrzeuge: boolean
) {
  if (!events?.length || !vehicleOffersEventDescriptions) {
    return OFFER_STATUSES.configurationCreated;
  }
  const majorEvent = getMajorEvent(events, vehicleOffersEventDescriptions);
  const majorEventDescription = getEventDescription(majorEvent, vehicleOffersEventDescriptions);

  return majorEventDescription
    ? isStatusForFleetManager
      ? majorEventDescription.uds_statusfuhrparkleiter
      : isDriverReferenzfahrzeuge
      ? majorEventDescription.uds_statusfahrer
      : majorEventDescription.uds_statusfahrerkonfigurationen
    : OFFER_STATUSES.configurationCreated;
}
