import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarBrushIcon = ({ width = 20, height = 16, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M17.7924 10.2216V7.24032C17.7924 6.88485 17.7271 6.53664 17.6038 6.21019L17.3209 5.46299H18.663C18.8516 5.46299 18.9894 5.28888 18.9459 5.10752L18.7282 4.1717C18.6702 3.91054 18.4308 3.72193 18.1624 3.72193H16.7405L15.6233 1.49482C15.3259 0.907212 14.7238 0.529984 14.0637 0.529984H5.8444C5.18425 0.529984 4.58213 0.899958 4.2847 1.49482L3.16752 3.72193H1.74566C1.47724 3.72193 1.2451 3.91054 1.17981 4.1717L0.962178 5.10752C0.918651 5.28888 1.05649 5.46299 1.2451 5.46299H2.58717L2.30424 6.21019C2.18092 6.53664 2.11563 6.89211 2.11563 7.24032V9.58049'
          fill='white'
        />
      )}
      {hovered && (
        <path
          d='M17.7924 7.24032V10.2216V12.3892H2.11563V9.58048V7.24032C2.11563 6.8921 2.18092 6.53664 2.30424 6.21019L2.58717 5.46298H1.2451C1.05649 5.46298 0.918651 5.28888 0.962178 5.10752L1.17981 4.1717C1.2451 3.91054 1.47724 3.72192 1.74566 3.72192H3.16752L4.96577 4.86979H14.9118L16.7405 3.72192H18.1624C18.4308 3.72192 18.6702 3.91054 18.7282 4.1717L18.9459 5.10752C18.9894 5.28888 18.8516 5.46298 18.663 5.46298H17.3209L17.6038 6.21019C17.7271 6.53664 17.7924 6.88485 17.7924 7.24032Z'
          fill={fill}
        />
      )}
      <path
        d='M17.7924 10.2216V7.24032C17.7924 6.88485 17.7271 6.53664 17.6038 6.21019L17.3209 5.46299H18.663C18.8516 5.46299 18.9894 5.28888 18.9459 5.10752L18.7282 4.1717C18.6702 3.91054 18.4308 3.72193 18.1624 3.72193H16.7405L15.6233 1.49482C15.3259 0.907212 14.7238 0.529984 14.0637 0.529984H5.8444C5.18425 0.529984 4.58213 0.899958 4.2847 1.49482L3.16752 3.72193H1.74566C1.47724 3.72193 1.2451 3.91054 1.17981 4.1717L0.962178 5.10752C0.918651 5.28888 1.05649 5.46299 1.2451 5.46299H2.58717L2.30424 6.21019C2.18092 6.53664 2.11563 6.89211 2.11563 7.24032V9.58049'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.86401 8.07459H6.27974L5.86623 7.25484'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0517 8.07459H13.636L14.0495 7.25484'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.63623 10.1058H12.2791'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.0249 4.88264H14.8909'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7228 14.0263V12.3066L2.05785 11.5423C2.05785 11.5423 0.954102 11.4394 0.954102 13.1339C0.954102 14.8284 2.05785 14.727 2.05785 14.727L11.7228 14.0263Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
      <path
        d='M13.2002 14.1449V12.2102C13.471 11.1534 15.2167 10.8669 16.057 11.7541C17.2328 12.9957 18.2988 12.5929 18.9542 12.4752C18.4666 13.5088 17.6315 15.47 15.7114 15.47C14.0645 15.47 13.444 14.5512 13.2002 14.1449Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
    </svg>
  );
};
