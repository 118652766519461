import styled from 'styled-components';
import InfoHint from './info-hint-view';
import { marginMixin, MarginProps } from 'common/common-components.styled';

export const StyledInfoHintWithoutBackground = styled(InfoHint)<MarginProps>`
  ${marginMixin}

  background: transparent;
  padding: 0;

  .info-hint-icon {
    margin-right: 6px;
  }
`;
