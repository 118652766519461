import React, { useContext } from 'react';
import { TabItemFrameProps } from './tab-item-frame/tab-item-frame.props';
import { SingleTabContext } from 'components/tab/type/single/single.context';
import { TabItemSection } from './ui/section/section';

export const TabItemController = (props: TabItemFrameProps) => {
  const { ui } = useContext(SingleTabContext);

  switch (ui) {
    case 'section':
      return <TabItemSection size='20' bold {...props} />;

    default:
      return null;
  }
};
