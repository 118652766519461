import React, { useEffect, useRef, useState } from 'react';
import { OrderStatusProps } from './order-status.props';
import { Flex, Text } from 'common/common-components.styled';
import { StyledCircle, StyledIconContainer, StyledOrderStatus } from './order-status.styled';
import { DocumentIcon } from 'components/icons/document-icon';
import { useTheme } from 'styled-components';
import More, { MoreMenuItem } from 'components/more';
import CheckIcon from 'components/icons/check';
import moment from 'moment';
import OrderStatusText from './order-status-text';

const OrderStatus = ({ eventDescription, event, mainRoleId, appPage, statusesContainerWidth }: OrderStatusProps) => {
  const theme = useTheme();

  const [textWidth, setTextWidth] = useState(315);

  const currentEventDescriptionWebRole = eventDescription.webroles.find(
    webrole => webrole.uds_apppageid_id === appPage?.uds_appageid && webrole.uds_b2c_webrollid_id === mainRoleId
  );
  const eventDescriptionAppText =
    !!event && currentEventDescriptionWebRole?.uds_app_status_text_when_done
      ? currentEventDescriptionWebRole.uds_app_status_text_when_done
      : currentEventDescriptionWebRole?.uds_app_status_text || '';

  useEffect(() => {
    if (statusesContainerWidth && statusesContainerWidth < 900) {
      setTextWidth((statusesContainerWidth - 270) / 2);
    }
  }, [statusesContainerWidth]);

  // const sendNotification = () => {};

  return (
    <Flex align='center' width='100%' bottom='20'>
      <Text minWidth='100px' color='black'>
        {event?.createdon ? moment(event.createdon).format('DD.MM.yyyy') : null}
      </Text>

      <StyledCircle isDone={!!event} />

      <Flex width='100%'>
        <StyledIconContainer align='center' justify='center' disabled={!event}>
          <DocumentIcon height={22} color={theme.colors.white} />
        </StyledIconContainer>

        <StyledOrderStatus padding='10px 20px' align='center' justify='space-between'>
          <Flex width='100%'>
            <OrderStatusText
              textWidth={textWidth ? (!event ? `${2 * textWidth}px` : `${textWidth}px`) : '0px'}
              color={!event ? 'grey400' : 'grey900'}
              text={eventDescriptionAppText}
            />
            {event && (
              <OrderStatusText
                textWidth={textWidth ? `${textWidth}px` : '0px'}
                color='grey900'
                text={`(${event.uds_erstelltvonportaluser || 'Fuhrpark-Team'} • ${
                  event.uds_portal_user_accountid || 'Mobexo'
                })`}
              />
            )}
          </Flex>

          <Flex left='10' width='25px'>
            {event ? (
              <CheckIcon />
            ) : // <More>
            //   <MoreMenuItem minWidth='195px' label='Send notification' onClick={sendNotification} img={null} />
            // </More>
            null}
          </Flex>
        </StyledOrderStatus>
      </Flex>
    </Flex>
  );
};

export default OrderStatus;
