import { useMemo } from 'react';
import { useTypedSelector } from '../use-typed-selector';
import { UserRoleId } from 'common/interfaces';
import { useGetUserProductsQuery } from '../react-query/product/get-user-product/get-user-product';
import { ProductId } from '../react-query/product/get-user-product/get-user-product.props';
import { USER_ROLES } from '../../constants';

export const useIsProductAvailable = (productId: ProductId, availabilityForRollsProps?: UserRoleId[] | 'all') => {
  const user = useTypedSelector(state => state.app.user);

  const getAvailabilityForRolls = (): UserRoleId[] | undefined => {
    if (Array.isArray(availabilityForRollsProps)) {
      return availabilityForRollsProps;
    }

    switch (productId) {
      case ProductId.SelfManagedClaims:
      case ProductId.ErComplaint:
        return [USER_ROLES.administrator.id, USER_ROLES.fleetManager.id];
    }
  };

  const availabilityForRolls = getAvailabilityForRolls();

  const { userProducts, ...restQueryProps } = useGetUserProductsQuery({
    options: { enabled: availabilityForRolls ? availabilityForRolls.includes(user.mainRole.id) : true }
  });

  const isAvailable = useMemo(() => {
    const isProductAvailable = userProducts.some(({ id }) => id === productId);
    const isAvailableForRole = availabilityForRolls?.some(roleId => roleId === user.mainRole.id) || false;

    return isProductAvailable && (isAvailableForRole || availabilityForRollsProps === 'all');
  }, [
    JSON.stringify(userProducts),
    availabilityForRolls,
    productId,
    user.mainRole.id,
    JSON.stringify(availabilityForRollsProps)
  ]);

  return { isAvailable, ...restQueryProps };
};
