export interface LicensePlateInputProps {
  className?: string;
  formClassName?: string;
}

export interface SwitchOptions {
  switchToNext?: boolean;
  switchToPrevious?: boolean;
  isStayInPlace?: boolean;
}

export enum InputName {
  REGION = 'REGION',
  LETTERS = 'LETTERS',
  NUMBERS = 'NUMBERS'
}

export enum InputErrorMessages {
  ENTER_LICENSE_PLATE = 'Kennzeichen eingeben',
  WRONG_MARK = 'Falsches Kennzeichen'
}

export interface ValidationOption {
  reg?: RegExp;
  customValidation?: (
    letter: string
  ) => {
    isValidated: boolean;
    error: string;
  };
}
