export const employeesImages = [
  '/assets/images/photos/employee_image.svg',
  '/assets/images/photos/thorsten_podlech.png'
];

export const reviewers = [
  {
    photo: '/assets/images/photos/holger_wenke.png',
    text: '„Mit Mobexo arbeitet Gegenbauer jetzt seit sechs Jahren. Gegenbauer hat 20.000 Mitarbeiter und über 2.000 Fahrzeuge. Wir sparen erhebliche Kosten für die Schäden ein und können die Leistungen im Schadenmanagement sehr empfehlen.“',
    name: 'Holger Wenke',
    position: 'Leiter Einkauf',
    logo: '/assets/images/gegenbauer_logo.svg'
  },
  {
    photo: '/assets/images/photos/patrick_lamwersiek.png',
    text: '„Mobexo bietet uns ein Rundum-Sorglos-Paket für den Schaden- und Pannenfall.“',
    name: 'Patrick Lamwersiek',
    position: 'Manager General Services Germany & Austria',
    logo: '/assets/images/konica_minolta_logo.svg'
  }
];
