import React, { memo, useContext, useEffect, useState } from 'react';
import classes from './consumption.module.scss';
import { CostListItem } from 'components/cost-list-item/cost-list-item';
import { CostListItemProps } from 'components/cost-list-item/cost-list-item.props';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { data as chartDataModel, options } from 'models/chart/dashboard/consumption.model';
import { ChartFuelLabels } from '../dashboard.props';
import { Distance } from 'common/common-components.styled';
import { VerbrauchInterface } from './consumption.props';
import { useTheme } from 'styled-components';
import { modifyFuelData, openPageVehicleCostCurrentYearProps } from '../utils';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import toMoney from 'utils/toMoney';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { CRM_VALUE as VEHICLE_COST_CRM_VALUE } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { COST_TYPE_VALUE } from 'pages/fahrzeugkosten-page/utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const ConsumptionTable = memo(({ consumptionData }: VerbrauchInterface) => {
  const [chartData, setChartData] = useState<ChartData<'line', number[], string>>(chartDataModel);
  const theme = useTheme();

  const currentYear = new Date().getFullYear();

  const { openPage } = useContext(FleetManagerDashboardContext);

  useEffect(() => {
    if (consumptionData && consumptionData?.verbrauch?.sumByMonthByYear[currentYear]) {
      const newChartData = modifyFuelData(consumptionData?.verbrauch?.sumByMonthByYear[currentYear], chartData);
      setChartData(newChartData);
    }
  }, [consumptionData]);

  const costList: CostListItemProps[] = [
    {
      color: theme.colors.blue,
      name: ChartFuelLabels.Diesel,
      price: toMoney(consumptionData?.verbrauch?.sumFuelByYear?.Diesel) || 0
    },
    {
      color: theme.colors.gold,
      name: ChartFuelLabels.Petrol,
      price: toMoney(consumptionData?.verbrauch?.sumFuelByYear?.Benzin) || 0
    },
    {
      color: theme.colors.green,
      name: ChartFuelLabels.Electricity,
      price: toMoney(consumptionData?.verbrauch?.sumFuelByYear?.Strom) || 0
    }
  ];

  const openPageUsageCostFuelCost = openPage(
    openPageVehicleCostCurrentYearProps(
      { [VEHICLE_COST_CRM_VALUE.costTypeGroup]: COST_TYPE_VALUE.fuelCost },
      { [VEHICLE_COST_CRM_VALUE.costTypeGroup]: COST_TYPE_VALUE.fuelCost }
    )
  );

  return (
    <div className={classes.line_container}>
      <div className={classes.left}>
        <div className={classes.top}>
          <ExpensesPerYear
            name='Verbrauchskosten'
            year={currentYear}
            price={consumptionData?.verbrauch?.sumByYear[currentYear] || 0}
            tooltipText={`Nettosumme aller Treibstoffkosten mit Rechnungsdatum innerhalb ${currentYear}`}
            openPage={openPageUsageCostFuelCost}
          />
          <Distance top='30' />
        </div>

        <div className={classes.cost_list}>
          {costList.map(costListItem => (
            <CostListItem {...costListItem} key={costListItem.name} />
          ))}
        </div>
      </div>

      <div className={classes.right}>
        {consumptionData && consumptionData?.verbrauch?.sumByMonthByYear[currentYear] ? (
          <Line options={options} data={chartData} height='260px' width='345px' />
        ) : (
          <div className={classes.empty_data}>
            <EmptyDataIcon hovered />
            <span>Es gibt keine Daten</span>
          </div>
        )}
      </div>
    </div>
  );
});
