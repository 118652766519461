import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function setMainVehicle(contactId, carId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/setmainmark`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      contactId,
      carId
    }
  });
}

export async function setMainAngebote(BasketId, ConfigId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/setmainconfig`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      BasketId,
      ConfigId
    }
  });
}

export async function deactiveteVehicle(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/deactivation`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      id
    }
  });
}

export async function getFahrzeugangeboteByConfigId(configId, hasEvents) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/vehicle/vehicleid`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      configId,
      hasEvents
    }
  });
}

export async function copyFaToPersonalConfig(fahrzeugangeboteId, driverId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/copyFaToPersonalConfig`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      fahrzeugangeboteId,
      driverId
    }
  });
}

export async function createApproveRecords(fahrzeugangeboteId, driverId, approverContactIds) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/createFreigaben`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      fahrzeugangeboteId,
      driverId,
      approverContactIds
    }
  });
}
