import React from 'react';
import { Flex, Button, Text } from 'common/common-components.styled';
import { LeaseExtentionPhases, StatusId } from 'components/aufgaben-component/task.prop';
import { useLeaseExtensionActions } from './utils';
import { WidgetButtonProps, ButtonsForLeaseExtendProps } from './lease-extension.props';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { mobexoEmployeeLeaseEndRequestConfig } from 'request-config/mobexo-employee-lease-end/mobexo-employee-lease-end.request-config';
import classes from '../../unfolded-task.module.scss';
import { useTheme } from 'styled-components';
import ThinArrowRight from 'components/icons-new/thin-arrow-right';
import { WidgetButtonContainer } from './buttons-for-lease-extension.styled';

export const ButtonsForLeaseEnd = ({
  isMobexoEmployee,
  currentPhase,
  setIsMenuOpen,
  plateNumber,
  refresh,
  leaseExtensionId,
  taskId,
  phaseInfo,
  files,
  leaseEndDate,
  taskStatusId,
  mileage
}: ButtonsForLeaseExtendProps) => {
  const {
    openDoNotExtendModal,
    openLeasingContractModal,
    openCanNotBeExtendedModal,
    openOfferForLeaseExtensionModal,
    openExtendLeaseAgreementModal,
    openTacitlyExtendAgreementModal,
    openOfferConfirmationModal,
    openExtendWithDateModal,
    openDeativateModal
  } = useLeaseExtensionActions({
    plateNumber,
    setIsMenuOpen,
    currentPhase,
    refresh,
    leaseExtensionId,
    taskId,
    phaseInfo,
    files,
    leaseEndDate
  });

  const { fetch } = useFetch({
    loadingKey: 'setting status',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: refresh
  });

  const handleUpdateStatusToDone = async () => {
    await fetch({
      requestConfig: mobexoEmployeeLeaseEndRequestConfig.setLVATaskStatus({
        taskId,
        statusCode: StatusId.Done
      })
    });
  };

  switch (currentPhase) {
    case LeaseExtentionPhases.Phase1:
      return (
        <div className={classes.button_container}>
          <Button secondary onClick={openDeativateModal}>
            Deaktivieren
          </Button>
          <Button onClick={handleUpdateStatusToDone} disabled={taskStatusId === StatusId.Done}>
            Erledigt
          </Button>
        </div>
      );

    case LeaseExtentionPhases.Phase2: // phase 2
      return isMobexoEmployee ? null : (
        <div className={classes.button_container}>
          <Button id={'doNotExtendButton'} secondary onClick={openDoNotExtendModal}>
            Nicht anpassen
          </Button>
          <Button id={'extendButton'} onClick={() => openLeasingContractModal(mileage)}>
            Anpassen
          </Button>
        </div>
      );

    case LeaseExtentionPhases.Phase3:
    case LeaseExtentionPhases.Phase24:
      return isMobexoEmployee ? (
        <Flex justify='space-between' top='30'>
          <Button id={'cannotBeExtendedButton'} secondary onClick={openCanNotBeExtendedModal}>
            Kann nicht verlängert werden
          </Button>
          <Flex gap='10px'>
            <Button id={'uploadOfferButton'} secondary onClick={openOfferForLeaseExtensionModal}>
              Angebot hochladen
            </Button>
            <Button id={'confirmExtensionButton'} onClick={openExtendLeaseAgreementModal}>
              Verlängerung bestätigen
            </Button>
          </Flex>
        </Flex>
      ) : null;

    case LeaseExtentionPhases.Phase4:
    case LeaseExtentionPhases.Phase25:
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'cannotBeExtendedButton'} secondary onClick={openCanNotBeExtendedModal}>
            Kann nicht verlängert werden
          </Button>
          <Button id={'confirmExtensionButton'} onClick={openTacitlyExtendAgreementModal}>
            Verlängerung bestätigen
          </Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase5: // phase 5
      return !isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'extendAnywayButton'} onClick={() => openLeasingContractModal(mileage)}>
            Trotzdem verlängern
          </Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase6: // phase 6
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'extendAnywayButton'} onClick={openOfferForLeaseExtensionModal}>
            Trotzdem verlängern
          </Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase7: // phase 7
      return !isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'viewOfferButton'} onClick={openOfferConfirmationModal}>
            Angebot anschauen
          </Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase11: // phase 11
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button onClick={handleUpdateStatusToDone} disabled={taskStatusId === StatusId.Done}>
            zur Kenntnis genommen
          </Button>
        </div>
      ) : (
        <div className={classes.button_container}>
          <Button secondary onClick={openDoNotExtendModal}>
            Nicht anpassen
          </Button>
          <Button id={'extendButton'} onClick={() => openLeasingContractModal(mileage)}>
            Anpassen
          </Button>
        </div>
      );

    case LeaseExtentionPhases.Phase14: // phase 14
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'cannotBeExtendedButton'} secondary onClick={openCanNotBeExtendedModal}>
            Kann nicht verlängert werden
          </Button>
          <Button onClick={openOfferForLeaseExtensionModal}>Neues Angebot hochladen</Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase15: // phase 15
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button id={'extendLeasingContractButton'} onClick={openExtendWithDateModal}>
            Leasingvertrag verlängern
          </Button>
        </div>
      ) : null;

    case LeaseExtentionPhases.Phase17: // && phase 17
      return isMobexoEmployee ? (
        <div className={classes.button_container}>
          <Button onClick={handleUpdateStatusToDone} disabled={taskStatusId === StatusId.Done}>
            zur Kenntnis genommen
          </Button>
        </div>
      ) : (
        <div className={classes.button_container}>
          <Button id={'viewOfferButton'} onClick={openOfferConfirmationModal}>
            Angebot anschauen
          </Button>
        </div>
      );

    default:
      return null;
  }
};
