import { CSSProperties } from 'react';
import styled, {css} from 'styled-components';
import { Button } from 'common/common-components.styled';
import Form from 'components/form';
import {Modal} from '@mui/material';

type StyledModalTitleProps = Pick<CSSProperties, 'backgroundColor'>;

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInnerWrapper = styled('div')`
   background-color:#fff;
   padding:20px 20px 20px 20px;
   max-width:500px;
`;

export const StyledModalTitle = styled('div')<StyledModalTitleProps>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
  `};
  border-radius: 5px 5px 0 0;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
    h5 {
      color: #fff;
      margin: 0;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
    }
    img {
      cursor: pointer;
    }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const StyledForm = styled(Form)`
  margin: 0;
  max-width: 300px;
  section {
    padding: 0 !important;
  }
`;
