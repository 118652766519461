import React from 'react';

export const DeleteIcon = ({ className, height = 16, width = 14, color = '#335566', ...props }) => {
  return (
    <svg
      className={className}
      {...props}
      width={width}
      height={height}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.729 13.283C10.729 13.6559 10.4804 13.9045 10.1075 13.9045H3.89245C3.51955 13.9045 3.27095 13.6559 3.27095 13.283V4.58203H2.02795V13.283C2.02795 14.3396 2.8359 15.1475 3.89245 15.1475H10.1075C11.164 15.1475 11.972 14.3396 11.972 13.283V4.58203H10.729V13.283Z'
        fill={color}
      />
      <path
        d='M10.1075 2.09652C10.1075 1.41287 9.54812 0.853516 8.86447 0.853516H5.13547C4.45182 0.853516 3.89247 1.41287 3.89247 2.09652V2.71802H0.784973V3.96102H13.215V2.71802H10.1075V2.09652ZM5.13547 2.09652H8.86447V2.71802H5.13547V2.09652Z'
        fill={color}
      />
      <path d='M7.62148 5.20312H6.37848V12.6611H7.62148V5.20312Z' fill={color} />
      <path d='M5.75698 5.20312H4.51398V12.6611H5.75698V5.20312Z' fill={color} />
      <path d='M9.48598 5.20312H8.24298V12.6611H9.48598V5.20312Z' fill={color} />
    </svg>
  );
};
