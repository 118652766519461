import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { CompanyQueryKeys } from '../company.props';
import { companyRequestConfig } from 'request-config/company/company.request-config';
import { CompaniesByTypesProps } from 'request-config/company/company.types';
import { CompaniesByTypesResponseItem } from './companies-by-types.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useCompaniesByTypesQuery = (
  props: CompaniesByTypesProps,
  { options }: QueryHookOptions<AxiosResponse<CompaniesByTypesResponseItem[]>, CompaniesByTypesResponseItem[]> = {}
) => {
  const { fetch } = useFetch<CompaniesByTypesResponseItem[]>({
    loadingKey: `companiesByTypes${JSON.stringify(props)}`,
    ...companyRequestConfig.companiesByTypes(props)
  });

  const { data, ...restQueryProps } = useQuery([CompanyQueryKeys.CompaniesByTypes, props], () => fetch(), options);

  return { companiesByTypesQuery: data?.data || [], ...restQueryProps };
};
