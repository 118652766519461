export class SmartTableParam {
  constructor(pagination, search, sort) {
    this.pagination = pagination;
    this.search = search; // {key:"value",key2:"value2"}
    this.sort = sort;
  }
}

export class Pagination {
  constructor(start, number, totalItemCount, numberOfPages) {
    this.start = start;
    this.number = number;
    this.totalItemCount = totalItemCount;
    this.numberOfPages = numberOfPages;
  }
}

export class Sort {
  constructor(predicate, reverse) {
    this.predicate = predicate;
    this.reverse = reverse;
  }
}
