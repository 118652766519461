import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { UNAUTHORIZED_PAGES } from 'common/enums';
import useOverlay from 'hooks/use-overlay';
import { getRepairStatusUnauthorized } from 'pages/repair-status/utils';
import { defaultTableData } from 'pages/repair-status/unauthorized/repair-status-unauthorized';

export const useRepairStatusUnauthorizedQuery = (tableParams: TableParams) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const { data, isFetching } = useQuery(
    [UNAUTHORIZED_PAGES.REPAIRED_STATUS, tableParams],
    () => getRepairStatusUnauthorized(tableParams),
    { enabled: !!tableParams.search }
  );

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [isFetching]);

  return {
    table: data?.data?.data || defaultTableData
  };
};

interface TableParams {
  pagination: { start: 1; number: 20 };
  search: {};
}
