import { Action } from 'store/store.props';
import { initialState } from './initial.state';
import { IDamageDetailState } from './damage-detail.props';
import { DAMAGE_DETAIL_EDIT_FORM } from 'actions/pages/damage-detail.action';

export const myDamageDetailReducer = (state: IDamageDetailState = initialState, action: Action<any>) => {
  switch (action.type) {
    case DAMAGE_DETAIL_EDIT_FORM:
      return {
        ...state,
        editForm: { ...state.editForm, ...action.payload }
      };
    default:
      return state;
  }
};
