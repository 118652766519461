import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarWithBorderIcon = ({
  width = 26,
  height = 26,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1' y='1' width='24' height='24' rx='1' fill={fill} />}

      <path
        d='M20.5896 25.0439H23.4C23.9601 25.0439 24.2401 25.0439 24.454 24.9349C24.6422 24.839 24.7951 24.686 24.891 24.4979C25 24.284 25 24.0039 25 23.4439V2.64388C25 2.08383 25 1.80381 24.891 1.58989C24.7951 1.40173 24.6422 1.24875 24.454 1.15288C24.2401 1.04388 23.9601 1.04388 23.4 1.04388H2.6C2.03995 1.04388 1.75992 1.04388 1.54601 1.15288C1.35785 1.24875 1.20487 1.40173 1.10899 1.58989C1 1.80381 1 2.08383 1 2.64388V23.4439C1 24.0039 1 24.284 1.10899 24.4979C1.20487 24.686 1.35785 24.839 1.54601 24.9349C1.75992 25.0439 2.03995 25.0439 2.6 25.0439H16.6615'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M18.2748 17.9678H17.1027V19.4332C17.1027 19.6091 17.2199 19.7263 17.3957 19.7263H20.326C20.5018 19.7263 20.619 19.6091 20.619 19.4332V17.6161L20.8534 16.5024C20.8827 16.2972 20.912 16.1213 20.912 15.9162V13.044C20.912 12.6923 20.8534 12.3406 20.7069 11.9889L20.4139 11.2268H21.7032C21.879 11.2268 22.0256 11.051 21.9962 10.8751L21.7618 9.93726C21.7032 9.67348 21.4688 9.49763 21.2051 9.49763H19.8572L18.7437 7.24088C18.4506 6.65471 17.8353 6.2737 17.1613 6.2737H8.86869C8.19473 6.2737 7.57937 6.65471 7.28635 7.24088L6.17285 9.49763H4.79563C4.53191 9.49763 4.29749 9.67348 4.23888 9.93726L4.00446 10.8751C3.97515 11.051 4.09236 11.2268 4.29748 11.2268H5.5868L5.29378 11.9889C5.17657 12.3112 5.08865 12.6629 5.08865 13.044V15.9162C5.08865 16.1213 5.11796 16.3265 5.14726 16.5024L5.38168 17.6161V19.4332C5.38168 19.6091 5.49889 19.7263 5.67471 19.7263H8.60496C8.78078 19.7263 8.89799 19.6091 8.89799 19.4332V17.9678H7.72589'
        fill='white'
      />
      <path
        d='M18.2748 17.9678H17.1027V19.4332C17.1027 19.6091 17.2199 19.7263 17.3957 19.7263H20.326C20.5018 19.7263 20.619 19.6091 20.619 19.4332V17.6161L20.8534 16.5024C20.8827 16.2972 20.912 16.1213 20.912 15.9162V13.044C20.912 12.6923 20.8534 12.3406 20.7069 11.9889L20.4139 11.2268H21.7032C21.879 11.2268 22.0256 11.051 21.9962 10.8751L21.7618 9.93726C21.7032 9.67348 21.4688 9.49763 21.2051 9.49763H19.8572L18.7437 7.24088C18.4506 6.65471 17.8353 6.2737 17.1613 6.2737H8.86869C8.19473 6.2737 7.57937 6.65471 7.28635 7.24088L6.17285 9.49763H4.79563C4.53191 9.49763 4.29749 9.67348 4.23888 9.93726L4.00446 10.8751C3.97515 11.051 4.09236 11.2268 4.29748 11.2268H5.5868L5.29378 11.9889C5.17657 12.3112 5.08865 12.6629 5.08865 13.044V15.9162C5.08865 16.1213 5.11796 16.3265 5.14726 16.5024L5.38168 17.6161V19.4332C5.38168 19.6091 5.49889 19.7263 5.67471 19.7263H8.60496C8.78078 19.7263 8.89799 19.6091 8.89799 19.4332V17.9678H7.72589'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.60497 17.9678H17.6888'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.84678 13.8646H9.27889L8.86866 13.0439'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.1539 13.8646H16.7218L17.132 13.0439'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6561 15.9162H15.3446'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.01891 10.3475H17.9818'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
