import React, { useEffect, useState } from 'react';
import { LicensePlateInput } from '../../../components/license-plate-input';
import { UnauthorizedPageContainer } from '../../../components/unauthorized-page-container';
import { RepairStatusItem } from '../components';
import PanelPlaceholder from '../../../components/panel-placeholder';
import classes from './repair-status-unauthorized.module.scss';
import { useSelector } from 'react-redux';
import { useRepairStatusUnauthorizedQuery } from 'hooks/react-query/repair-status-unauthorized';
import Pagination from '../../../components/pagination';
import HelmetComponent from 'components/helmet-component/helmet-component';
import { UNAUTHORIZED_PAGES } from 'common/enums';

const RepairStatusPageUnauthorized = () => {
  const licensePlate = useSelector(state => state.app.licensePlate);

  const [tableParams, setTableParams] = useState(defaultTableParams);
  const { table } = useRepairStatusUnauthorizedQuery(tableParams);
  const isVisibleBackToWebsiteBtn = !tableParams.search?.Car || !table?.count;
  const [openedItem, setOpenedItem] = useState({
    indx: -1,
    isActive: false
  });

  useEffect(() => {
    setTableParams(prevParams => ({
      ...prevParams,
      search: { Car: licensePlate?.fullValue?.toUpperCase() }
    }));
  }, [licensePlate]);

  const pageChangeHandler = page => {
    setTableParams(tableParams => ({ ...tableParams, pagination: { ...tableParams.pagination, start: page } }));
  };

  const openUnfoldedCaseHandler = indx => {
    setOpenedItem(() => ({ indx, isActive: true }));
  };

  const closeCases = () => {
    setOpenedItem({
      indx: -1,
      isActive: false
    });
  };

  return (
    <UnauthorizedPageContainer>
      <section className={classes.page}>
        <HelmetComponent title={UNAUTHORIZED_PAGES.REPAIRED_STATUS} />
        <h1 className={classes.title}>Reparaturstatus</h1>

        <div className={tableParams.search?.Car ? classes.license_plate_wrapper : classes.license_plate_no_car_wrapper}>
          <div className={tableParams.search?.Car ? classes.license_plate_title_container : null}>
            <p className={classes.license_plate_title}>Bitte gib dein Kennzeichen ein, </p>
            <p>um den Reperaturstatus und die Kontaktdaten der Werkstatt abzufragen.</p>
          </div>

          <LicensePlateInput
            className={tableParams.search?.Car ? classes.license_plate_input : null}
            formClassName={classes.license_plate_input_form}
          />
        </div>
        {tableParams.search?.Car ? (
          <>
            {table?.count ? (
              <>
                {table?.items.map((data, index) => (
                  <RepairStatusItem
                    key={data?.incidentid?.attributeValue}
                    data={data}
                    className={classes.item_container}
                    open={openedItem.isActive && openedItem.indx === index}
                    openCase={() => {
                      openUnfoldedCaseHandler(index);
                    }}
                    closeCases={closeCases}
                    unauthorized
                  />
                ))}
              </>
            ) : (
              <PanelPlaceholder
                title='Keinen Reparaturstatus gefunden'
                description={
                  <>
                    <p>Das Fahrzeug ist schadenfrei oder prüfe das eingegebene Kennzeichen.</p>
                    {isVisibleBackToWebsiteBtn ? (
                      <a
                        href='https://www.mobexo.de/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.back_to_website_link}
                        style={{ marginTop: tableParams.search?.Car ? '50px' : '220px' }}
                      >
                        Zurück zur Webseite
                      </a>
                    ) : null}
                  </>
                }
                img='/assets/images/imac-excel.svg'
                className={classes.panel_placeholder}
              />
            )}
          </>
        ) : null}
      </section>

      {table?.count > tableParams.pagination.number ? (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table.count}
          onPageChange={pageChangeHandler}
        />
      ) : null}
    </UnauthorizedPageContainer>
  );
};

export default RepairStatusPageUnauthorized;

export const defaultTableData = { count: 0, items: [] };
const defaultTableParams = {
  pagination: { start: 1, number: 20 },
  search: null
};
