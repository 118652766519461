import { AxiosRequestConfig } from 'axios';

class PortalCustomizationRequestConfig {
  getReportDamageSettings = (url: string): AxiosRequestConfig => ({
    method: 'POST',
    url: '/portalCustomization/GetSchadenmeldenSettings',
    data: { url }
  });
  getReportDamageLogo = (settingsId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: '/portalCustomization/GetSchadenmeldenLogo',
    responseType: 'blob',
    data: { settingsId }
  });
}

export const portalCustomizationRequestConfig = new PortalCustomizationRequestConfig();
