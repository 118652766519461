import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useOverlay } from '../../hooks';
import Button from '../../components/button';
import SideMenuRightContainer from '../../components/side-menu-right-container';
import classes from './fahrzeugkoste-container.module.scss';
import FahrzeugkostenFilters from './fahrzeugkosten-filters/fahrzeugkosten-filters';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../../selectors';
import TableComponent from '../../components/table-component';
import sharepointService from '../../services/sharepoint-service';
import Snackbar from '../../components/snackbar';
import { useSessionFilter } from 'hooks/use-session-filter';
import { setForm, toggleModal } from '../../actions/app_action';
import { MODALS, MODAL_NAMES, toLocaleStringSetup } from '../../constants';
import useLatestValue from '../../hooks/use-latest-value';
import { formFields } from '../../components/monatsrechnungen/formFields/formFields';
import Form from '../../components/form/form-redux';
import { useLocation } from 'react-router-dom';
import { createNewTaskHandler } from 'utils/create-new-task-handler';
import { PAGES } from 'common/enums';
import { useVehicleCostsQuery } from 'hooks/react-query/vehicle-costs';
import Pagination from '../../components/pagination';
import ButtonWithIcon from '../../components/button-with-icon';
import { AskQuestionIcon } from '../../components/icons/ask-qustion-icon';
import { DownloadDocumentIcon } from '../../components/icons/offer-statuses/download-document-icon';
import { setValue } from 'utils/get-response-data';
import { getFahrzuegkostenFile } from 'services/vehicle-costs-service';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { ButtonComponentType } from 'components/button/button.props';
import { useSetFilterFromLocationState } from '../../hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';
import { useFileDownload } from '../../hooks/useFileDownload';
import { vehicleCostRequestConfig } from '../../request-config/vehicle-cost/vehicle-cost.request-config';
import { DownloadZipIcon } from '../../components/icons-new/download-zip';
import { Distance, Flex } from '../../common/common-components.styled';
import moment from 'moment';
import { useOnLeavingPageReturnDefaultFilters } from './hooks/use-on-leaving-page-return-default-filters';
import usePaginationState from '../../hooks/use-pagination-state/use-pagination-state';
import PanelPlaceholder from '../../components/panel-placeholder';

const AskQuestion = ({ row, column }) => {
  return (
    <ButtonWithIcon
      tooltip='Frage stellen'
      icon={<AskQuestionIcon />}
      onClick={() => column.action(row[column.actionParameter])}
    />
  );
};

const VehicleReceipt = ({ row, column }) => {

  return (
    (row && row['show_download_flag']) && (
      <ButtonWithIcon
        tooltip='Beleg'
        icon={<DownloadDocumentIcon />}
        onClick={() => column.action(row['er.uds_eingangsrechnungid'])}
      />
    )
  );
};

const VehicleTax = ({ row }) => {
  return row['betrag_value'] ? row['betrag_value'].toLocaleString('de-DE', toLocaleStringSetup) : '-';
};

const VehicleCostType = ({ row }) => {
  return row['ledgeraccount.new_laname'] !== 'Erlöse aus Festwertpauschale'
    ? row['ledgeraccount.new_laname'] || '-'
    : row['uds_festwertmappingname'] || '-';
};

const VehicleNumber = ({ row }) => {
  return (
    <span className={row && row['fuhrpark.statecode'] === 'Aktiv' ? classes.active : classes.inactive}>
      {row['uds_fuhrparkid']}
    </span>
  );
};

const FahrzeugkostenContainer = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const forms = useSelector(state => state['app'].forms);
  const formsLatest = useLatestValue(forms);
  const { defaultPage, setDefaultPage } = usePaginationState(PAGES.VEHICLE_COSTS);
  const [tableParams, setTableParams] = useState(getDefaultTableParams(defaultPage));
  const [showOverlay, hideOverlay] = useOverlay();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const location = useLocation();
  const linkMonthsParams = (location.state && location.state.filterMonthsStatus) || null;

  const { sessionFilter, setSessionFilter } = useSessionFilter(
    PAGES.VEHICLE_COSTS,
    defaultFilterValue,
    defaultFilterValue
  );

  const { table, isFetching } = useVehicleCostsQuery(tableParams, sessionFilter);

  const goToFirstPage = () => {
    setDefaultPage(1);
    setTableParams(prev => ({
      ...prev,
      pagination: { start: 1, number: 20 }
    }));
  };

  useOnLeavingPageReturnDefaultFilters(setSessionFilter);
  useSetFilterFromLocationState(setSessionFilter, emptyFilterForServer, emptyFilterForClient, goToFirstPage);

  useEffect(() => {
    if (linkMonthsParams && linkMonthsParams.invoicedateServer && linkMonthsParams.invoicedateClient) {
      goToFirstPage();
      setSessionFilter(
        { ...emptyFilterForServer, invoicedate: linkMonthsParams.invoicedateServer },
        {
          ...emptyFilterForClient,
          invoicedate: linkMonthsParams.invoicedateClient
        }
      );
    }
  }, [location.state]);

  const handleDownload = useCallback(
    async invoiceId => {
      showOverlay();
      const fileResponse = await getFahrzuegkostenFile(invoiceId);
      if (fileResponse.data?.data) {
        sharepointService
          .downloadFileById(fileResponse.data.data.fileId)
          .then(({ data }) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(new Blob([data]));
            a.download = fileResponse.data.data.name;
            a.click();
          })
          .catch(err => console.log(err))
          .finally(hideOverlay);
      } else {
        hideOverlay();
        snackbarSetup('Diese Rechnung enthält keine Datei.', 'warning');
      }
    },
    [hideOverlay, showOverlay]
  );

  const pageChangeHandler = page => {
    setDefaultPage(page);
    setTableParams(tableParams => ({
      ...tableParams,
      pagination: { ...tableParams.pagination, start: page }
    }));
  };

  const downloadFile = useFileDownload({
    requestConfig: vehicleCostRequestConfig.getFileForExcel({
      ...tableParams,
      search: sessionFilter?.filterForServer,
      contactid: user?.id
    }),
    fileName: `Fahrzeugkosten ${moment().format('DD.MM.YYYY')}.xlsx`,
    encodeConfig: {
      path: ['data'],
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      encodeWay: 'b64Data'
    },
    isSuccessSnackbar: true,
    successSnackbarMessage: 'Download abgeschlossen.',
    isErrorSnackbar: true,
    errorSnackbarMessage: 'Download fehlgeschlagen.'
  });

  const downloadZipFile = useFileDownload({
    requestConfig: vehicleCostRequestConfig.generateZipVehicleCosts({
      ...tableParams,
      search: sessionFilter?.filterForServer
    }),
    fileName: `Fahrzeugkosten ${moment().format('DD.MM.YYYY')}.zip`,
    encodeConfig: {
      path: [],
      contentType: 'application/zip',
      encodeWay: 'newBlob'
    },
    isSuccessSnackbar: true,
    successSnackbarMessage: 'Download abgeschlossen.',
    isWarningSnackbar: true,
    warningSnackbarMessage: 'Keine Dokumente zum Herunterladen.'
  });

  const sortHandler = fieldName => {
    setTableParams({
      ...tableParams,
      sort: {
        predicate: fieldName,
        reverse: fieldName === tableParams?.sort?.predicate ? !tableParams?.sort?.reverse : false
      }
    });
  };

  const snackbarSetup = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setOpenSnackbar(true);
  };

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const openCreateNewTaskModal = useCallback(
    id => {
      const alertData = {
        title: 'Neue Frage',
        children: <Form name='frage' formFields={formFields} />,
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert
          },
          {
            type: 'submit',
            title: 'Speichern',
            action: () =>
              createNewTaskHandler({
                itemId: id,
                userId: user.id,
                closeAlert: closeAlert,
                dispatchForm: form => {
                  dispatch(setForm(form));
                },
                formsLatest: formsLatest.current,
                hideOverlay: hideOverlay,
                showOverlay: showOverlay,
                snackbarSetup: snackbarSetup,
                logicalName: 'uds_rahmenvertrge'
              })
          }
        ]
      };

      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [closeAlert, dispatch, formsLatest, hideOverlay, showOverlay, user.id]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Rechnungsbezug',
        type: 'text',
        propName: 'er.uds_name',
        sort: true,
        sortName: 'EingangsrechnungName',
        width: '8%',
        className: null
      },
      {
        name: 'Faktura',
        type: 'text',
        propName: 'uds_fakturanummer',
        sort: true,
        sortName: 'Fakturanummer',
        width: '8%',
        className: null,
        alwaysShowTooltip: true
      },
      {
        name: 'Fakturadatum',
        type: 'date',
        propName: 'uds_fakturadatum',
        sort: true,
        sortName: 'Fakturadate',
        width: '8%',
        className: null,
        customTooltip: (row = {}) => getDateString(row['uds_fakturadatum']),
        alwaysShowTooltip: true
      },
      {
        name: 'Rg-Empfänger',
        type: 'text',
        propName: 'fuhrpark.new_sparteid',
        sort: false,
        width: '8%',
        className: null
      },
      {
        name: 'Bu.-Nummer',
        type: 'text',
        propName: 'uds_name',
        sort: true,
        sortName: 'Name',
        width: '8%',
        className: null
      },
      {
        name: 'Fremdbeleg',
        type: 'text',
        propName: 'er.uds_invoicenumber',
        sort: false,
        width: '8%',
        className: null
      },
      {
        name: 'Rg-Datum',
        type: 'date',
        propName: 'er.uds_invoicedate',
        sort: false,
        width: '13%',
        className: null,
        customTooltip: (row = {}) => getDateString(row['er.uds_invoicedate']),
        alwaysShowTooltip: true
      },
      {
        name: 'Kostenart',
        type: 'text',
        component: <VehicleCostType row={{}} />,
        sort: false,
        sortName: 'ledgeraccount.new_laname',
        width: '8%',
        className: null
      },
      {
        name: 'Dienstleister',
        type: 'text',
        propName: 'er.uds_serviceproviderid',
        sort: false,
        width: '8%',
        className: null
      },
      {
        name: 'Kennzeichen',
        type: 'text',
        component: <VehicleNumber row={{}} />,
        propName: 'uds_fuhrparkid',
        sort: true,
        sortName: 'Fuhrpark',
        width: '9%',
        className: null
      },
      {
        name: 'Kilometerstand',
        type: 'text',
        propName: 'transaction.uds_mileage',
        sort: false,
        width: '9%',
        className: null
      },
      {
        name: 'Betrag (netto)',
        type: 'text',
        component: <VehicleTax row={{}} />,
        sort: false,
        width: '8%',
        className: null
      },
      {
        name: '',
        type: 'button',
        component: <VehicleReceipt row={{}} column={{}} />,
        action: handleDownload,
        actionParameter: 'show_download_flag',
        sort: false,
        width: '45px',
        className: null
      },
      {
        name: '',
        type: 'button',
        component: <AskQuestion row={{}} column={{}} />,
        actionParameter: 'uds_rahmenvertrgeid',
        action: id => openCreateNewTaskModal(id),
        sort: false,
        width: '45px',
        className: classes.question_button
      }
    ],
    [openCreateNewTaskModal, handleDownload]
  );

  const getDataForTable = items => {
    return items.map(item => {
      const incident = {};
      Object.entries(item).forEach(([key, value]) => {
        setValue(item, key, value, incident, true);
      });

      return incident;
    });
  };

  const rowsForTable = getDataForTable(table?.tableData);

  const renderSubstituteElement = ()=>{
    return (
      <PanelPlaceholder
        title='Nichts gefunden'
        description='Diese Seite enthält keine Ergebnisse.'
      />
    )
  }

  return (
    <Flex direction='column' paddingBottom={'80px'}>
      <div className={classes.page_container}>
        <div className={classes.wrapper}>
          <div className={classes.table_container}>
            <div className={classes.filters}>
              <FahrzeugkostenFilters
                sessionFilter={sessionFilter}
                setSessionFilter={setSessionFilter}
                goToFirstPage={goToFirstPage}
              />
              <div className={classes.inner_wrapper}>
                <ButtonWithIcon
                  tooltip='Laden Sie das ZIP-Archiv herunter'
                  icon={<DownloadZipIcon width={28} height={28} />}
                  onClick={downloadZipFile}
                />
                <Distance side='20' />
                <Button
                  component={ButtonComponentType.DownloadExcelIcon}
                  onClick={downloadFile}
                  iconWidth={32}
                  tooltip='Laden Sie die Excel-Datei herunter'
                  disabled={!rowsForTable?.length}
                />
              </div>
            </div>

            {
              <>
                <div className='mt-30' />
                <TableComponent
                  loading={isFetching}
                  columns={columns}
                  rows={rowsForTable}
                  rootTag={{
                    name: 'div',
                    propName: 'uds_rahmenvertrgeid'
                  }}
                  sort={sortHandler}
                  sortParams={tableParams.sort}
                  substitutionElement={renderSubstituteElement()}
                />
              </>
            }
            <Snackbar
              open={openSnackbar}
              type={snackbarType}
              onClose={() => setOpenSnackbar(false)}
              message={snackbarMessage}
            />
          </div>
        </div>
      </div>

      {table?.totalRecords > tableParams.pagination.number && (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table?.totalRecords}
          onPageChange={pageChangeHandler}
        />
      )}
    </Flex>
  );
};

export const emptyFilterState = {
  Fuhrparkid: null,
  Fakturanummer: null,
  Fakturadate: null,
  Accountid: null,
  Name: null,
  Eingangsrechungname: null,
  Invoicenummer: null,
  Invoicedate: null,
  Kostenart: null,
  KostenartGroup: null,
  Fahrzeugstatus: null
};

const getDefaultTableParams = defaultPage => ({
  pagination: {
    start: defaultPage,
    number: 20
  },
  sort: { predicate: 'EingangsrechnungName', reverse: false }
});

export const CRM_VALUE = {
  invoiceReference: 'Eingangsrechungname',
  invoiceNumber: 'Fakturanummer',
  invoiceDate: 'Fakturadate',
  company: 'Accountid',
  businessNumber: 'Name',
  secondInvoiceNumber: 'Invoicenummer',
  rgDatum: 'Invoicedate',
  costType: 'Kostenart',
  costTypeGroup: 'KostenartGroup',
  car: 'Fuhrparkid',
  status: 'Fahrzeugstatus'
};

export const emptyFilterForClient = {
  ...emptyFilterState
};

export const emptyFilterForServer = {
  ...emptyFilterState
};

export const defaultFilterValue = emptyFilterState;

export const DEFAULT_TABLE_DATA = { tableData: [], totalPages: 0, totalRecords: 0 };

const FahrzeugkostenPage = () => {
  return (
    <SideMenuRightContainer>
      <FahrzeugkostenContainer />
    </SideMenuRightContainer>
  );
};

export default FahrzeugkostenPage;
