import React from 'react';
import { DrivingLicenseDriverTableProps } from 'components/driving-license-control-component/driving-license-control.props';
import { getStatusColor } from './utils';
import { Circle } from 'common/common-components.styled';

export const DrivingLicenseDriverStatus = ({ row }: DrivingLicenseDriverTableProps) => {
  if (!row?.contactStatus) {
    return null;
  }

  return <Circle size='7px' color={getStatusColor(row?.statecode_value)} />;
};
