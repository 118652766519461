import classNames from 'classnames';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import { toLocaleStringSetup } from '../../../../constants';
import React, { useContext } from 'react';
import classes from '../cell.module.scss';
import { CellContext } from 'components/table-component/table-component-contexts';

export const MoneyCell = ({ column, row }: Pick<CellProps, 'column' | 'row'>) => {
  const { cellId } = useContext<ICellContext>(CellContext);

  return (
    <span
      id={cellId}
      style={{ width: column.width }}
      className={classNames(classes.table_text, column.className, classes.cell)}
    >
      {row[column.propName || ''] ? row[column.propName || ''].toLocaleString('de-DE', toLocaleStringSetup) : '-'}
    </span>
  );
};
