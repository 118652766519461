import { Button, Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import React from 'react';
import { useForm } from 'react-hook-form';
import { orderVehicleReportFormFields } from './orderVehicleReportFields';
import { OrderVehicleReportType, OrderVehicleReportTypes } from './order-vehicle-report.types';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetLeaseEndRequestConfig } from 'request-config/freet-lease-end/fleet-lease-end.request-config';

export const OrderVehicleReport = ({
  fuhrparkId,
  actionName,
  taskId,
  phaseInfo,
  closeAlert,
  callback
}: OrderVehicleReportTypes) => {
  const { fetch } = useFetch({
    loadingKey: 'setting phase',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: callback
  });

  const reactHookFormData = useForm<OrderVehicleReportType>({
    defaultValues: {
      person: phaseInfo.person,
      phone: phaseInfo.phone,
      address: phaseInfo.address,
      remark: ''
    }
  });

  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;

  const onSubmit = () => {
    closeAlert();
    handleSubmit(async data => {
      await fetch({
        requestConfig: fleetLeaseEndRequestConfig.setVirAction({
          actionName,
          fuhrparkId,
          taskId,
          address: data.address || '',
          name: data.person || '',
          phone: data.phone || '',
          remarks: data.remark
        })
      });
    })();
  };

  return (
    <Flex direction='column' gap='30px'>
      <Text>
        Bitte fülle alle Angaben vollständig aus, wir übermitteln diese Daten an den Dienstleister, der sich zur
        Terminabstimmung telefonisch mit dir in Verbindung setzt.
      </Text>
      <FormHook reactHookFormData={reactHookFormData} formFields={orderVehicleReportFormFields} />
      <Flex justify='flex-end'>
        <Button id={'submit'} disabled={!isValid} onClick={onSubmit}>
          Absenden
        </Button>
      </Flex>
    </Flex>
  );
};
