import { Flex } from 'common/common-components.styled';
import Button from 'components/button';
import { useFileDownload } from 'hooks/useFileDownload';
import React from 'react';
import { vehicleCostRequestConfig } from 'request-config/vehicle-cost/vehicle-cost.request-config';
import { ModalContentSubmitProps } from './modal-content.props';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../../constants';

export const ModalContentSubmit = ({ reactHookFormData, vehicleId }: ModalContentSubmitProps) => {
  const { handleSubmit } = reactHookFormData;

  const user = useTypedSelector(state => state.app.user);
  const dispatch = useDispatch();

  const downloadFile = useFileDownload({
    fileName: 'Fahrzeugkostenbogen.pdf',
    encodeConfig: {
      encodeWay: 'b64Data',
      path: ['outPdf'],
      contentType: 'application/pdf'
    }
  });

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const onSubmitHandler = handleSubmit(values => {
    closeAlert();
    downloadFile({
      requestConfig: vehicleCostRequestConfig.generatePdf({
        User: user.id,
        Month: values.month,
        Year: values.year,
        Kennzeichen: vehicleId
      })
    });
  });

  return (
    <Flex justify='flex-end' top='20'>
      <Button onClick={onSubmitHandler}>Berechnen und PDF herunterladen</Button>
    </Flex>
  );
};
