import { useCallback, useContext } from 'react';
import { setIsSetFormFromSavedValues, toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useSendFormRequest } from 'hooks/use-send-request';
import { getUnsavedChangesModalContent } from './utils';
import { UnSaveModalRefContext } from 'context/un-save-modal/un-save-modal-ref.context';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { AnyObject } from 'common/interfaces';

export const useUnsavedChangesModal = () => {
  const unsavedModalType = useTypedSelector(state => state.app.unsavedModalType);
  const dispatch = useDispatch();

  const { sendRequest } = useSendFormRequest();

  const { isFormsDirty, isFormsValid, saveFormValues } = useContext(UnSaveModalRefContext);

  const closeAlert = useCallback(() => {
    dispatch(setIsSetFormFromSavedValues(false));
    saveFormValues.current = {};

    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const getAlertData = useCallback(
    (data: AnyObject, callback: Function) => {
      const { title, description, cancelTitle, submitTitle } = getUnsavedChangesModalContent(unsavedModalType);
      return {
        title: title,
        children: description,
        onCloseAlert: closeAlert,
        buttons: [
          {
            type: 'cancel',
            title: cancelTitle,
            action: () => {
              closeAlert();
              callback();
            }
          },
          {
            type: 'submit',
            title: submitTitle,
            action: async () => {
              closeAlert();
              await sendRequest(data);

              callback();
            }
          }
        ]
      };
    },
    [closeAlert, dispatch, unsavedModalType, sendRequest]
  );

  const openModal = useCallback(
    (callback: Function) => {
      if (isFormsDirty.current && isFormsValid.current) {
        return dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(saveFormValues.current, callback)));
      }

      callback();
    },
    [dispatch, getAlertData, isFormsValid, saveFormValues.current]
  );

  return { openUnsavedChangesModal: openModal };
};
