import { ReactQueryKeys } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { dashboardRequestConfig } from 'request-config/dashboard/dashboard.request-config';
import { getDataForTable } from 'utils/get-response-data';
import { QueryHookOptions } from '../../react-query.props';
import { IDashboardRepairStatus } from './use-repair-status.props';
import { ResponseModel } from 'common/interfaces';

export const useDashboardRepairStatusQuery = (option: QueryHookOptions = {}) => {
  const { fetch } = useFetch<ResponseModel>({
    loadingKey: 'repairStatus',
    ...option.fetch,
    ...dashboardRequestConfig.repairStatus
  });

  const { data: queryData, ...restProps } = useQuery(
    [ReactQueryKeys.Dashboard, ReactQueryKeys.GetRepairStatus],
    () => fetch(),
    {
      select: data =>
        ({
          count: data.data.data.count,
          items: getDataForTable(data.data.data.items)
        } as IDashboardRepairStatus)
    }
  );

  return { repairStatus: queryData || defaultValue, ...restProps };
};

const defaultValue: IDashboardRepairStatus = {
  count: 0,
  items: []
};
