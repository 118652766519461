import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './panel.module.scss';

const Panel = props => {
  const { children, className, ...rest } = props;
  return (
    <div className={classnames(className, classes.panel)} {...rest}>
      {children}
    </div>
  );
};

Panel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Panel;
