import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const CarInGarage = ({ width = 24, height = 24, color: colorProp, fill: fillProp }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.381 23.81V5.069L12.001 0.572 23.619 5.069v18.741' fill={fill} />
      <path
        d='M0.381 23.81V5.069L12.001 0.572 23.619 5.069v18.741'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.12 9.941H1.88L6.004 6.942h11.993z'
        fill='white'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.127 22.311h1.125v1.499H5.253v-1.874l-0.14 -0.271a2.194 2.194 0 0 1 -0.235 -1.003v-2.849l0.375 -1.125H4.129a0.376 0.376 0 0 1 -0.375 -0.375l0.291 -0.872a0.376 0.376 0 0 1 0.357 -0.253H5.628l0.984 -1.958A1.879 1.879 0 0 1 8.29 12.19h7.431a1.879 1.879 0 0 1 1.677 1.04l0.984 1.959h1.227a0.376 0.376 0 0 1 0.357 0.253l0.291 0.872a0.376 0.376 0 0 1 -0.375 0.375h-1.125l0.375 1.125v2.839c0 0.346 -0.084 0.693 -0.235 1.003l-0.141 0.271v1.874h-2.998v-1.499h1.125'
        fill='white'
      />
      <path
        d='M7.127 22.311h1.125v1.499H5.253v-1.874l-0.14 -0.271a2.194 2.194 0 0 1 -0.235 -1.003v-2.849l0.375 -1.125H4.129a0.376 0.376 0 0 1 -0.375 -0.375l0.291 -0.872a0.376 0.376 0 0 1 0.357 -0.253H5.628l0.984 -1.958A1.879 1.879 0 0 1 8.29 12.19h7.431a1.879 1.879 0 0 1 1.677 1.04l0.984 1.959h1.227a0.376 0.376 0 0 1 0.357 0.253l0.291 0.872a0.376 0.376 0 0 1 -0.375 0.375h-1.125l0.375 1.125v2.839c0 0.346 -0.084 0.693 -0.235 1.003l-0.141 0.271v1.874h-2.998v-1.499h1.125'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.877 22.311h8.246'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.378 18.937h2.249l-0.375 -0.75'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.622 18.937h-2.249l0.375 -0.75'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.126 20.436h3.749'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.252 15.939h7.497'
        stroke={color}
        strokeWidth='1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
