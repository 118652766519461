import { PickValue } from 'common/interfaces';
import { CarSectionProps } from 'components/sections/car-section/car-section.props';
import { useDownloadFileQuery } from 'hooks/react-query/fleet/use-download-file/use-download-file';
import { useFilesByIdQuery } from 'hooks/react-query/fleet/use-files-by-id/use-files-by-id';

export const useVehicleDetailGallery = () => {
  const { filesByIdQuery, isFetching: filesByIdQueryIsFetching } = useFilesByIdQuery();

  const activeFiles = filesByIdQuery.filter(file => file.Access_Rights !== 'vom Kunden gelöscht');
  const images = activeFiles.filter(file => file.Description === 'Foto(s) Fahrzeug');

  const { downloadFileQuery, downloadFileQueryIsFetching } = useDownloadFileQuery(
    images.map(item => item.FilePath || '')
  );

  const files: PickValue<CarSectionProps, 'gallery'> = images.map(item => {
    const filePathSplit = item.FilePath?.split('/') || [];
    return {
      filePath: item.FilePath,
      name: filePathSplit[filePathSplit.length - 1],
      fileId: item.FileId,
      description: item.Description
    };
  });

  return {
    activeFiles,
    files,
    images: downloadFileQuery.filter(Boolean),
    isFetchingGallery: filesByIdQueryIsFetching || downloadFileQueryIsFetching
  };
};
