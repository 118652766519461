import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const StarIcon = ({ width = 20, height = 20, color: colorProps, fill: fillProps, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProps || defaultColor;
  const fill = fillProps || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.85023 17.268L4.68215 12.4339L1.1746 8.99383C0.859825 8.70153 0.769887 8.25185 0.882309 7.84713C1.01721 7.44241 1.37696 7.15012 1.78168 7.08266L6.63829 6.38565L8.81926 1.97873C9.2015 1.21426 10.4606 1.21426 10.8428 1.97873L13.0238 6.38565L17.8804 7.08266C18.3076 7.15012 18.6449 7.44241 18.7798 7.84713C18.9147 8.25185 18.8023 8.70153 18.4875 8.99383L16.7337 10.7139L14.98 12.4339L15.8119 17.268C15.8793 17.6952 15.7219 18.1224 15.3622 18.3698C15.1598 18.5272 14.935 18.5946 14.6877 18.5946C14.5078 18.5946 14.3279 18.5497 14.1705 18.4597L9.83105 16.1663L5.49159 18.4597C5.10935 18.6621 4.65967 18.6396 4.29992 18.3698C3.96266 18.1224 3.78278 17.6952 3.85023 17.268Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
      />
    </svg>
  );
};
