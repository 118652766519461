import React from 'react';
import { DamagedCarIcon } from 'components/icons-new/damaged-car';
import { PAGES, UNAUTHORIZED_PAGES } from 'common/enums';
import { BillIcon } from 'components/icons-new/bill';
import { CarRepairIcon } from 'components/icons-new/car-repair';
import { FileUploadIcon } from 'components/icons-new/file-upload';
import { Link } from 'react-router-dom';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { Tooltip } from '@mui/material';
import IconHover from 'components/icon-hover';
import { Flex } from 'common/common-components.styled';
import classes from './quick-links.module.scss';
import { useIsProductAvailable } from 'hooks/use-is-product-available/use-is-product-available';
import { ProductId } from 'hooks/react-query/product/get-user-product/get-user-product.props';
import { USER_ROLES } from '../../../constants';
import { useReportDamageCustomLink } from 'hooks/custom-links/use-report-damage';
import { useComponent } from 'hooks/use-component/use-component';

export function QuickLinks() {
  const isSiteAvailable = useTypedSelector(state => state.app.isSiteAvailable);

  const { isAvailable: isShowCreateDamage } = useIsProductAvailable(ProductId.SelfManagedClaims, [
    USER_ROLES.fleetManager.id
  ]);

  const { reportDamageCustomLink } = useReportDamageCustomLink();
  const { getIsDisplayComponent } = useComponent();

  const links = [
    {
      icon: <BillIcon width={32} height={32} />,
      tooltip: 'Letzte Monatsrechnung ansehen',
      linkTo: PAGES.MONTHLY_BILLS
    },
    {
      icon: <CarRepairIcon width={32} height={32} />,
      tooltip: 'Reparaturstatus abfragen',
      linkTo: UNAUTHORIZED_PAGES.REPAIRED_STATUS,
      blank: true
    },
    {
      icon: <FileUploadIcon width={32} height={32} />,
      tooltip: 'Rechnung an Mobexo senden',
      linkTo: PAGES.EINGANGSRECHNUNG_UPLOAD
    },
    {
      icon: <DamagedCarIcon width={32} height={32} />,
      tooltip: isShowCreateDamage ? 'Schaden anlegen' : 'Schaden melden',
      linkTo: isShowCreateDamage ? PAGES.CREATE_DAMAGE : reportDamageCustomLink,
      hidden: isShowCreateDamage ? false : !getIsDisplayComponent('reportDamageButton'),
      blank: !isShowCreateDamage
    }
  ];

  return (
    <Flex gap='10px' margin='10px 0 0'>
      {isSiteAvailable && (
        <>
          {links.map(({ icon, tooltip, linkTo, blank, hidden }, index) => {
            if (hidden) {
              return null;
            }
            return (
              <Link key={index} to={linkTo} target={blank ? '_blank' : undefined}>
                <Tooltip title={tooltip}>
                  <div className={classes.wrapper}>
                    <IconHover>{icon}</IconHover>
                  </div>
                </Tooltip>
              </Link>
            );
          })}
        </>
      )}
    </Flex>
  );
}
