import React from 'react';
import { StyledVehicleCostWidgetViewContainer } from '../../vehicle-costs.styled';
import { TotalRunningTimeHeader } from './components/header/header';
import { TotalRunningTimeDoughnut } from './components/doughnut/doughnut';

export const TotalRunningTimeCostsView = () => {
  return (
    <StyledVehicleCostWidgetViewContainer>
      <TotalRunningTimeHeader />
      <TotalRunningTimeDoughnut />
    </StyledVehicleCostWidgetViewContainer>
  );
};
