import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';

export const configurationFormFields: FormFieldModel[] = [
  {
    name: 'configuration',
    type: FormFieldTypes.OptionSet,
    defaultValue: '',
    label: 'Konfiguration',
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];
