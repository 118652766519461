import { AxiosRequestConfig } from 'axios';
import { DriverCheckProps } from './driver-check.types';

class DriverCheckRequestConfig {
  private RESOURCE = 'driversCheck';

  driverCheckDownloadExcel = ({ search, sort }: DriverCheckProps): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/GetDriversExcell`,
    data: {
      search,
      sort
    }
  });
}

export const driverCheckRequestConfig = new DriverCheckRequestConfig();
