import { useEffect, useState } from 'react';
import { asyncInterval } from 'utils/async-interval';

export const useShowWholeNote = (note: string, elementId: string) => {
  const [showWholeNote, setShowWholeNote] = useState(true);
  const [defaultContentHeight, setDefaultContentHeight] = useState(0);

  const getContentHeight = () => {
    asyncInterval(() => document.getElementById(elementId), 50, 40)
      .then(content => {
        setDefaultContentHeight(content.offsetHeight);
        setShowWholeNote(content.offsetHeight <= 60);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getContentHeight();
  }, [note.length]);

  return { showWholeNote, defaultContentHeight, setShowWholeNote };
};
