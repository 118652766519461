import React from 'react';
import PropTypes from 'prop-types';

export const DoneIcon = ({ color = '#fff', width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.25165 16.8942C7.04435 16.8942 6.90614 16.8252 6.76794 16.6869L2.2073 11.8499C1.9309 11.5735 1.9309 11.1589 2.2073 10.8825C2.4837 10.6061 2.89831 10.6061 3.17471 10.8825L7.18255 15.0976L16.7875 3.35053C17.0639 3.07412 17.4785 3.00502 17.7549 3.28143C18.0313 3.55783 18.1004 3.97243 17.824 4.24884L7.80445 16.6869C7.66625 16.8252 7.45895 16.8942 7.25165 16.8942Z'
        fill={color}
      />
    </svg>
  );
};

DoneIcon.propTypes = {
  color: PropTypes.string
};
