import { TableParams, defaultTableParams } from 'components/pool-car-booking/bookings-page';
import useOverlay from 'hooks/use-overlay';
import { SessionFilter } from 'hooks/use-session-filter';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getFilterData, getPoolCalendarList } from 'services/pool-car-booking-servises';
import isEmpty from 'lodash/isEmpty';
import { ReactQueryKeys } from 'common/enums';
import { PoolCarFilterData } from 'components/pool-car-booking/hooks/react-query/use-pool-cars-with-bookings/pool-cars-with-bookings.types';

const modifyFilterDataResponse = (data: PoolCarFilterData) => {
  return data;
};

export const usePoolCalendarQuery = (
  navigationURL: string,
  tableParams: TableParams,
  sessionFilter: SessionFilter,
  enabled: boolean = true
) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [rows, setRows] = useState();

  const {
    data: table,
    isLoading,
    refetch: refetchPoolCarBookings
  } = useQuery(
    [ReactQueryKeys.GetPoolCalendarList, `${navigationURL}`, tableParams, sessionFilter],
    () => getPoolCalendarList(tableParams, sessionFilter),
    { enabled: !isEmpty(sessionFilter?.filterForServer) && enabled }
  );
  const { data: poolCalendarListFilterData, isLoading: isStatusesLoading } = useQuery(
    [[`${navigationURL}_filter_data`]],
    () => getFilterData(),
    { enabled, select: modifyFilterDataResponse }
  );

  useEffect(() => {
    if (table) {
      const preparedRows = table.list.map((record: any) => {
        return {
          uds_name: record.poolcalendar[0].uds_name.attributeValue,
          new_model:
            record.fuhrpark[0].new_hersteller.attributeValue && record.fuhrpark[0].new_model.attributeValue
              ? `${record.fuhrpark[0].new_hersteller.attributeValue} ${record.fuhrpark[0].new_model.attributeValue}`
              : null,
          new_name: record.fuhrpark[0].new_name.attributeValue,
          uds_abholdatum: moment(record.poolcalendar[0].uds_abholdatum.attributeValue),
          uds_ruckgabedatum: moment(record.poolcalendar[0].uds_ruckgabedatum.attributeValue),
          uds_requester: record.poolcalendar[0].uds_requester.attributeValue?.name,
          uds_status: record.poolcalendar[0].uds_status.attributeValue?.label,
          uds_status_code: record.poolcalendar[0].uds_status.attributeValue?.value,
          uds_kilometervoraussichtlich: record.poolcalendar[0].uds_kilometervoraussichtlich.attributeValue,
          new_fuhrparkid: record.fuhrpark[0].new_fuhrparkid.attributeValue,
          uds_poolkalenderid: record.poolcalendar[0].uds_poolkalenderid.attributeValue,
          kmstand: record?.kmstand?.uds_mileage?.attributeValue,
          new_standortid: record.fuhrpark[0].new_standortid.attributeValue?.name,
          new_standortid_id: record.fuhrpark[0].new_standortid.attributeValue?.id,
          isAllowEdit: record.poolcalendar[0].isAllowEdit.attributeValue,
          uds_comment: record.poolcalendar[0].uds_comment.attributeValue
        };
      });
      setRows(preparedRows);
    }
  }, [table]);

  useEffect(() => {
    isLoading || isStatusesLoading ? showOverlay() : hideOverlay();
  }, [isLoading, isStatusesLoading, showOverlay, hideOverlay]);

  return {
    table: {
      ...tableParams,
      tableRows: rows || defaultTableParams.records,
      totalCount: table?.totalRecordCount || defaultTableParams.totalCount
    },
    poolCalendarListFilterData,
    refetchPoolCarBookings
  };
};
