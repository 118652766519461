import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { ReactQueryKeys } from 'common/enums';
import { FleetLeasingPeriodQueryType } from './use-leasing-period.props';

export const useFleetLeasingPeriodQuery = (vehicleIdProps?: string) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch<ResponseModel<FleetLeasingPeriodQueryType>>({
    loadingKey: 'fleetLeasingPeriodQuery',
    isShowErrorSnackbar: false,
    ...fleetRequestConfig.getLeasingPeriod(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.FleetLeasingPeriod, vehicleId], () => fetch(), {
    enabled: !!vehicleId
  });

  return { fleetLeasingPeriodQuery: data?.data?.data || {}, ...restQueryProps };
};
