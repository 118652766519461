import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const SaveIcon = ({ color: colorProp, width = 25, height = 24, hovered, ...restProps }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg
      {...restProps}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {hovered && (
        <path
          d='M17.9405 3H4.54822C3.99593 3 3.54822 3.44772 3.54822 4V18.6774V20C3.54822 20.5523 3.99593 21 4.54822 21H20.5482C21.1005 21 21.5482 20.5523 21.5482 20V6.60776C21.5482 6.34255 21.4429 6.08819 21.2553 5.90065L18.6476 3.29289C18.46 3.10536 18.2057 3 17.9405 3Z'
          fill='#E6F7FF'
        />
      )}
      <path
        d='M5.58044 17.9678V12.4566C5.58044 11.9043 6.02816 11.4566 6.58044 11.4566H18.2256C18.7779 11.4566 19.2256 11.9043 19.2256 12.4566V17.9678C19.2256 18.52 18.7779 18.9678 18.2256 18.9678H6.58044C6.02816 18.9678 5.58044 18.52 5.58044 17.9678Z'
        fill={hovered ? '#E6F7FF' : 'none'}
        stroke={color}
        strokeLinecap='round'
      />
      <path
        d='M5.84106 17.9678V12.4566C5.84106 11.9043 6.28878 11.4566 6.84106 11.4566H18.2256C18.7779 11.4566 19.2256 11.9043 19.2256 12.4566V17.9678C19.2256 18.52 18.7779 18.9678 18.2256 18.9678H6.84106C6.28878 18.9678 5.84106 18.52 5.84106 17.9678Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinecap='round'
      />
      {hovered && (
        <path
          d='M15.9031 8.51607H10.1587L7.32238 8.51608H7.08044C6.25202 8.51608 5.58044 7.8445 5.58044 7.01608V3.99995C5.58044 3.44766 6.02816 2.99995 6.58044 2.99995L15.9031 2.99994C16.4554 2.99994 16.9031 3.44765 16.9031 3.99994V7.51607C16.9031 8.06835 16.4554 8.51607 15.9031 8.51607Z'
          fill='white'
        />
      )}
      <path
        d='M3.54822 18.6774V20C3.54822 20.5523 3.99593 21 4.54822 21H20.5482C21.1005 21 21.5482 20.5523 21.5482 20V6.60776C21.5482 6.34255 21.4429 6.08819 21.2553 5.90065L18.6476 3.29289C18.46 3.10536 18.2057 3 17.9405 3H4.54822C3.99593 3 3.54822 3.44772 3.54822 4V16.0901'
        stroke={color}
        strokeLinecap='round'
      />
      <path
        d='M10.314 8.51606H15.9031C16.4554 8.51606 16.9031 8.06834 16.9031 7.51606V3.29025M5.84106 2.99994V7.01607C5.84106 7.8445 6.49718 8.51607 7.30654 8.51607H7.5429'
        stroke={color}
        strokeLinecap='round'
      />
      <path
        d='M14.8034 4.53465H14.3518C14.0757 4.53465 13.8518 4.75851 13.8518 5.03465V6.48149C13.8518 6.75763 14.0757 6.98149 14.3518 6.98149H14.8034C15.0796 6.98149 15.3034 6.75763 15.3034 6.48149V5.03465C15.3034 4.75851 15.0796 4.53465 14.8034 4.53465Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinecap='round'
      />
    </svg>
  );
};
