import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { DriverDashboardDetailItem } from '../../../../dashboard-component-chart/detail-item/detail-item';
import { notLeasingServiceDetailsList } from './utils';
import { NotLeasingServicesDetailsProps } from './not-leasing-services-details.props';
import { getDeviceType } from 'utils/get-device-type';
import { DeviceTypes } from '../../../../../constants';

export const NotLeasingServicesDetails = ({ isColumnDetailItem, ...restFexProps }: NotLeasingServicesDetailsProps) => {
  return (
    <Flex direction='column' {...restFexProps}>
      <Text bold={getDeviceType() !== DeviceTypes.Mobile}>Für Nicht-Leasingleistungen:</Text>

      <Flex direction='column'>
        {notLeasingServiceDetailsList.map(item => (
          <DriverDashboardDetailItem key={item.title} isColumn={isColumnDetailItem} {...item} />
        ))}
      </Flex>
    </Flex>
  );
};
