import React from 'react';
import { CustomDroppableContentContainerProps } from 'components/draggable-list/draggable-list.props';
import { GetCarInspectionDateResponseItem } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';
import {
  StyledInspectionDateDroppableContentContainer,
  StyledInspectionDatePenIconHover
} from './custom-droppable-content-container.styled';
import { useIsEditableItem } from './hooks/use-is-editable-item';
import { PenIcon } from 'components/icons-new/pen';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { getInspectionDateItemDescriptionText } from '../../utils';
import { useInspectionDateLinkToCarModal } from 'hooks/modals/inspection-date/link-to-car/link-to-car.modal';
import { useMyVehicleIsTaskOverdueOnSelectInitRegistration } from '../../hooks/use-is-task-overdue-on-selecting-initial-registration';

export const CustomDroppableContentContainer = (
  props: CustomDroppableContentContainerProps<GetCarInspectionDateResponseItem>
) => {
  const { item, children } = props;

  const { fleetByIdQuery } = useFleetByIdQuery();

  const { openInspectionDateLinkToCarModal } = useInspectionDateLinkToCarModal();
  const { getIsTaskOverdue } = useMyVehicleIsTaskOverdueOnSelectInitRegistration();
  const { isEditable, relatedInspectionDateHistoryId } = useIsEditableItem(
    item.attributes.uds_inspection_datesid || ''
  );

  return (
    <StyledInspectionDateDroppableContentContainer isEditable={isEditable} {...props}>
      {children}

      {isEditable && (
        <StyledInspectionDatePenIconHover>
          <PenIcon
            onClick={() =>
              openInspectionDateLinkToCarModal({
                fuhrparkId: fleetByIdQuery.fuhrpark?.new_fuhrparkid || '',
                pruftermineId: item.id,
                firstRegistrationDate: fleetByIdQuery.fuhrpark?.new_ezl,
                editableHistoryId: relatedInspectionDateHistoryId,
                willOverdueTaskCreatedOnSelectingFirstRegistrationDate: getIsTaskOverdue(item.attributes),
                description: getInspectionDateItemDescriptionText(item.attributes)
              })
            }
          />
        </StyledInspectionDatePenIconHover>
      )}
    </StyledInspectionDateDroppableContentContainer>
  );
};
