import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TASK_STATUS_CODES } from '../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { getChecklistFile } from 'components/aufgaben-component/utils';
import DownloadChecklistFile from './components/download-checklist-file/download-checklist-file';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';

export const useChecklist = () => {
  const { car } = useContext(LeasingWidgetsContext);
  const { checklist } = car;
  const status = checklist.widgetState.attributeValue.value;
  const [checklistFileData, setChecklistFileData] = useState({
    fileId: '',
    fileName: ''
  });

  useEffect(() => {
    getChecklistFile().then(response => {
      setChecklistFileData({ fileId: response.data.data[0].fileId, fileName: response.data.data[0].name });
    });
  }, []);

  const description = useMemo(() => {
    switch (status) {
      case TASK_STATUS_CODES.open:
      case TASK_STATUS_CODES.postponed:
        return <DownloadChecklistFile fileId={checklistFileData.fileId} fileName={checklistFileData.fileName} />;

      case TASK_STATUS_CODES.processing:
        return (
          <WidgetContentText>
            Wir überprüfen die Checkliste und melden uns bei dir, sobald die Prüfung erledigt ist.
          </WidgetContentText>
        );

      case TASK_STATUS_CODES.completed:
        return (
          <WidgetContentText>
            Wir haben die Checkliste überprüft. Sollten wir Unstimmigkeiten festgestellt haben, melden wir uns bei dir.
          </WidgetContentText>
        );
    }
  }, [status, checklistFileData]);

  return {
    statusLabel: checklist.widgetState.attributeValue.label,
    statusColor: TASK_STATUS_CODES.completed === checklist.widgetState.attributeValue.value ? 'green' : 'darkGold',
    description
  };
};
