/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import BigDamageIcon from '../icons/big-damage';
import SmallDamageIcon from '../icons/small-damage';
import CarDamageIcon from '../icons/car-damage';
import DamagePartChoose from '../damage-part-component/damage-part-choose';
import { damage } from '../utils';
import PlusIcon from '../icons/plus';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide.module.css';
import slideReverse from '../transition/damage-backward-slide.module.css';
import slideReturn from '../transition/return-slide.module.css';
import sideSlide from '../transition/side-slide.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import useWindowSize from 'hooks/use-window-size';
import TwoButtonsComponent from '../components/two-buttons-component';
import ThreeButtonsComponent from '../components/three-buttons-component';
import SmallDamageChoosenIcon from '../icons/small-damage-choosen';
import BigDamageChoosenIcon from '../icons/big-damage-choosen';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import CarIconMobile from '../car-icon-mobile-component/car-icon-mobile';
import ArrowLeftBigIcon from '../icons/arrow-left-big';
import { StepProps, DamagePartsItemProps, StepStylesProps, OtherDamagePartsItemProps } from '../report-damage.props';

const ParkingChooseDamage = ({ reactHookFormData, handleNext }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showChooseDamage, setShowChooseDamage] = useState<boolean>(false);
  const [showChooseDamageSlider, setShowChooseDamageSlider] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    watch('hasDamage') !== undefined &&
    watch('accidentScatch') !== undefined &&
    !!watch('damageSize') &&
    !!watch('damageDealer') &&
    (watch('hasDamage') === false ||
      (Array.isArray(watch('damageParts')) &&
        watch('damageParts').length > 0 &&
        watch('damageParts').filter((el: OtherDamagePartsItemProps) => el[1].type === true).length < 1)) &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    watch('hasDamage') === true ? setShowChooseDamage(true) : setShowChooseDamage(false);
    setTimeout(() =>
      watch('hasDamage') === true ? setShowChooseDamageSlider(true) : setShowChooseDamageSlider(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  const setDamageSize = (size: string) => {
    setValue('damageSize', size);
  };

  useEffect(() => {
    if (watch('hasDamage') === false) {
      setValue('carDamage', undefined);
      setValue('damageParts', undefined);
      setValue('damageList', damage);
    }
  }, [watch('hasDamage')]);

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  const songstigeValue = () => {
    const songstigeItem = { type: true, value: 100000041 };
    const songstige =
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      !!watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        ? watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        : [];
    if (
      (songstige.length === 0 && songstige.length <= 8) ||
      (songstige.length <= 8 && songstige.some((el: OtherDamagePartsItemProps) => el[1].type !== true))
    ) {
      const newSongstigeITem = ['Sonstiges', songstigeItem];
      const damagePartsArray = Array.isArray(watch('damageParts')) ? watch('damageParts') : [];
      damagePartsArray.push(newSongstigeITem);
      setValue('damageParts', damagePartsArray);
    }
  };

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const checkDamageParts = () => {
    if (
      !!watch('hasDamage') &&
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      watch('damageParts').length > 0
    ) {
      watch('damageParts').forEach((item: OtherDamagePartsItemProps) => {
        if (item[1].type === true) {
          setError(`damageParts.${item[0]}`, {
            type: 'manual',
            message: 'Error'
          });
        }
      });
    }
  };

  useEffect(() => {
    !watch('hasDamage') && clearErrors('damageParts');
  }, [watch('hasDamage')]);

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      !!watch('hasDamage') && checkDamageParts();
      checkButtons(['hasDamage', 'accidentScatch', 'damageSize', 'damageDealer']);
    }
  };

  useEffect(() => {
    if (watch('damageSize') !== undefined) {
      clearErrors('damageSize');
    }
  }, [watch('damageSize')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Unfallskizze vorhanden'
          fieldRegister='accidentScatch'
          fieldRegisterSecond='accidentScatch'
          needTooltip={true}
          tooltipPosition='bottom'
          tooltipText='Bitte eine möglichst detaillierte Skizze erstellen, aus der auch die Fahrtrichtung/Position deines Autos hervorgeht. Der Upload erfolgt am Ende des Formulars.'
        />
        <Distance top='20' />

        <div style={showChooseDamage ? { display: 'flex' } : {}}>
          <div className={styles.buttons_damage_block}>
            <TwoButtonsComponent
              reactHookFormData={reactHookFormData}
              fieldName='Sind an deinem Fahrzeug Schäden entstanden?'
              fieldRegister='hasDamage'
              fieldRegisterSecond='hasDamage'
            />

            {showChooseDamage && windowWidth < 1400 && watch('damageList') !== undefined && (
              <div style={{ overflow: 'hidden' }}>
                <CSSTransition
                  in={showChooseDamageSlider}
                  timeout={400}
                  classNames={slideQuest}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={styles.songstige_block_small}>
                    {windowWidth < 900 ? (
                      <CarIconMobile reactHookFormData={reactHookFormData} />
                    ) : (
                      <CarDamageIcon reactHookFormData={reactHookFormData} />
                    )}
                    <div
                      className={
                        windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full'
                          ? styles.songstige_block_mobile
                          : styles.songstige_block
                      }
                    >
                      {windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full' && (
                        <div
                          id='returnButton'
                          className={styles.back_item}
                          onClick={() => setValue('carImageMobile', 'full')}
                        >
                          <ArrowLeftBigIcon />
                          <div className={styles.back_text}>Zurück</div>
                        </div>
                      )}
                      <div id='otherPartsButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                        <PlusIcon />
                        <Distance side='10' />
                        Sonstige Teile
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            )}
            {showChooseDamage && <DamagePartChoose reactHookFormData={reactHookFormData} />}
          </div>
          {showChooseDamage && windowWidth >= 1400 && watch('damageList') !== undefined && (
            <div style={{ overflow: 'hidden' }}>
              <CSSTransition
                in={showChooseDamageSlider}
                timeout={600}
                classNames={sideSlide}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <CarDamageIcon reactHookFormData={reactHookFormData} />
                  <div className={styles.songstige_block}>
                    <div id='otherPartsButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                      <PlusIcon />
                      <Distance side='10' />
                      Sonstige Teile
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>

        <Distance top={watch('hasDamage') === true ? '30' : '20'} />

        <div className={styles.form_name}>
          <span className={errors?.damageSize ? styles.form_name_err : ''}>
            <p>
              Ist der Schaden größer als 10x15 Zentimeter? <br />
              Das entspricht ca. der Größe einer Postkarte
            </p>
            <div
              className={styles.tooltip}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <TooltipHint
                position={windowWidth > 900 ? 'top' : 'center'}
                text='Aufgrund deiner Auswahl wird die Reparatur an einen Spezial-Betrieb für Kleinst-Schäden ("SMART-Repair") vermittelt - bist Du sicher, dass der Schaden kleiner als 10*15cm ist?'
              >
                <InfoHintIcon color='#919699' width={16} height={16} hovered={isHovered} />
              </TooltipHint>
            </div>
          </span>
        </div>
        <div className={styles.row_columns}>
          <div className={styles.half_input}>
            <div
              id='bigDamageIconButton'
              className={watch('damageSize') === 'big' ? styles.damage_part_choosen : styles.damage_part}
              onClick={() => setDamageSize('big')}
            >
              {watch('damageSize') === 'big' ? <BigDamageChoosenIcon /> : <BigDamageIcon />}
              <p>Ja, größer</p>
            </div>
          </div>
          <div className={styles.half_input}>
            <div
              id='smallDamageIconButton'
              className={watch('damageSize') === 'small' ? styles.damage_part_choosen : styles.damage_part}
              onClick={() => setDamageSize('small')}
            >
              {watch('damageSize') === 'small' ? <SmallDamageChoosenIcon /> : <SmallDamageIcon />}
              <p>Nein, kleiner</p>
            </div>
          </div>
        </div>

        <Distance top='30' />

        <ThreeButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Wer hat deiner Meinung nach den Schaden verursacht?'
          fieldRegister='damageDealer'
          firstButtonText='Ich/Fahrzeugnutzer'
          firstButtonValue='Ich/Fahrzeugnutzer'
          secondButtonText='Unfallgegner'
          secondButtonValue='Unfallgegner'
          thirdButtonText='Unsicher'
          thirdButtonValue='Unsicher'
        />

        <Distance top='20' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ParkingChooseDamage;
