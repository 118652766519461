import { useEffect } from 'react';
import useOverlay from 'hooks/use-overlay';
import { getUserAvatar } from 'services/user-service';
import { setUser } from 'actions/app_action';
import { getFileForDownload } from 'components/download-expansion-component/download-expansion-component';
import { useDispatch } from 'react-redux';
import { UserInfoResponse } from 'hooks/react-query/use-user-info/use-user-info.types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { User } from 'common/interfaces';

const useSetUserInfoToRedux = () => {
  const dispatch = useDispatch();
  const user = useTypedSelector<User>(state => state['app'].user);
  const [showOverlay, hideOverlay] = useOverlay('initialUserData');

  useEffect(() => {
    if (user.id) {
      const fetchAvatar = async () => {
        showOverlay();
        getUserAvatar(user.id)
          .then(async avatarData => {
            if (avatarData.data) {
              const { data } = await getFileForDownload(avatarData.data.filerelativepath);
              const blob = new Blob([data], { type: 'application/octet-stream' });
              const avatar = URL.createObjectURL(blob);

              dispatch(
                setUser({
                  ...user,
                  avatar
                })
              );
            }
          })
          .finally(hideOverlay);
      };

      fetchAvatar();
    }
  }, [user.id]);
};

const getUserData = (userResponse: UserInfoResponse) => {
  const userRoles = userResponse.roles.map(role => ({ id: role.uds_b2c_webroleid, name: role.uds_name }));
  return {
    ...userResponse,
    id: userResponse.id,
    name: userResponse.fullName,
    email: userResponse.email,
    companyId: userResponse.account?.id,
    isTestUser: userResponse.isTestUser,
    companyName: userResponse.account?.name,
    roles: userRoles,
    mainRole: userResponse.mainB2CWebrolleId
      ? userRoles.find(role => role.id === userResponse.mainB2CWebrolleId) || userRoles[0]
      : userRoles[0],
    childCompanies: userResponse.childCompanies,
    appPages: userResponse.appPages.pages || [],
    driversCheckFunction: {
      isManager: userResponse.isManager,
      isController: userResponse.isController
    }
  };
};

export default useSetUserInfoToRedux;
