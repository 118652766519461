import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function createVehicleOffer(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fleetVehicle/createFahrzeugangebote`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function createEvent(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fleetVehicle/createEvent`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function updateVehicleOffer(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fleetVehicle/updateFahrzeugangebote`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function updateLeasingOffer(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fleetVehicle/updateLeasingangebote`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function duplicateFahrzeugangebote(
  fahrzeugangeboteId,
  contactId,
  uds_kstverantw,
  uds_altes_fahrzeugid,
  uds_zuordnung,
  uds_baskettypecode
) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fleetVehicle/duplicateFahrzeugangebote`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      fahrzeugangeboteId,
      contactId,
      uds_kstverantw,
      uds_altes_fahrzeugid,
      uds_zuordnung,
      uds_baskettypecode
    }
  });
}
