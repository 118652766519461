import React from 'react';
import PropTypes from 'prop-types';

import Label from './label-view';

export default function ProductLabels({ labels }) {
  return (
    <div className='product-labels'>
      {labels.map((label,i) => (
        <Label label={label} key={i} />
      ))}
      <div className='clearer' />
    </div>
  );
}

ProductLabels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string)
};
