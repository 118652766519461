import styled from 'styled-components';
import { Select } from '@mui/material';
import { DropDownIcon } from 'components/icons/drop-down-icon';

export const StyledChildCompanySwitcher = styled(Select)`
  width: auto;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiSelect-select {
    color: ${({ theme }) => theme.colors.blue};
    padding: 10px 15px;
  }
  & .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const StyledDropDownIconGreen = styled(DropDownIcon)`
  position: absolute;
  right: 7px;
  top: calc(50% - 10px) !important;

  & path {
    stroke: ${({ theme }) => theme.colors.green};
  }
`;
