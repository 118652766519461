import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

interface StyledBudgetScaleProps {
  borderColor: string;
}

export const StyledBudgetScale = styled(Flex)<StyledBudgetScaleProps>`
  border: 1px solid ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-radius: 20px;
  height: 40px;
  overflow: hidden;
`;
