import React, { useEffect, useState } from 'react';
import classes from './pool-car-booking.module.scss';
import Spinner from 'components/spinner';
import { getCarGallery } from 'services/pool-car-booking-servises';
import { Flex } from 'common/common-components.styled';

interface Props {
  id: string;
  spinnerSize?: string;
  isCalendarView?: boolean;
}

const CarLogo = ({ id, spinnerSize = '50px', isCalendarView }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const imageSpinnerStyle = {
    width: spinnerSize,
    top: '50%',
    left: '45px',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  };

  useEffect(() => {
    const getCarImage = async () => {
      setLoading(true);
      const response = await getCarGallery(id);
      //@ts-ignore
      setImage(response?.data ?? '/assets/images/vehicle-placeholders/PKW_small.svg');
      setLoading(false);
    };

    getCarImage();
  }, []);

  return (
    <div className={classes.image_container} onClick={e => e.stopPropagation()}>
      {loading ? (
        <div className={classes.image_loader_container}>
          <Spinner style={imageSpinnerStyle} />
        </div>
      ) : (
        <img
          className={classes.car_photo}
          width={isCalendarView ? 90 : 'auto'}
          height={isCalendarView ? 60 : 'auto'}
          src={image}
          alt={'car photo'}
        />
      )}
    </div>
  );
};

export default CarLogo;
