import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledVehicleCostHeaderContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  z-index: 2;
`;
