import {
  ConsumptionLabelEnum,
  ConsumptionValueEnum,
  DataQualityLabel,
  DataQualityValue,
  VehicleStateCodeLabel,
  VehicleStateCodeValue,
  VehicleStatusesLabel,
  VehicleStatusesValue
} from '../my-vehicles.props';

export const CONSUMPTION_BUTTONS = [
  { value: ConsumptionValueEnum.Increased, label: ConsumptionLabelEnum.Increased },
  { value: ConsumptionValueEnum.Normal, label: ConsumptionLabelEnum.Normal }
];

export const VEHICLE_STATUS_BUTTONS = [
  {
    id: VehicleStateCodeValue.Active,
    name: VehicleStateCodeLabel.Active
  },
  {
    id: VehicleStateCodeValue.Inactive,
    name: VehicleStateCodeLabel.Inactive
  }
];

export const VEHICLE_STATUS_PROCESSING_BUTTONS = [
  {
    id: VehicleStatusesValue.InOperation,
    name: VehicleStatusesLabel.InOperation
  },
  {
    id: VehicleStatusesValue.Ordered,
    name: VehicleStatusesLabel.Ordered
  },
  {
    id: VehicleStatusesValue.Controlled,
    name: VehicleStatusesLabel.Controlled
  },
  {
    id: VehicleStatusesValue.ControlRequested,
    name: VehicleStatusesLabel.ControlRequested
  },
  {
    id: VehicleStatusesValue.InControl,
    name: VehicleStatusesLabel.InControl
  }
];

export const DATA_QUALITY_BUTTONS = [
  { value: DataQualityValue.Good, label: DataQualityLabel.Good },
  { value: DataQualityValue.Bad, label: DataQualityLabel.Bad },
  { value: DataQualityValue.Average, label: DataQualityLabel.Average }
];
