import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';

export enum EditBookingFieldNames {
  StartDate = 'uds_abholdatum',
  EndDate = 'uds_ruckgabedatum',
  PlannedKilometers = 'uds_kilometervoraussichtlich',
  LicensePlate = 'uds_fuhrpark'
}

export const editBookingFormFields: FormFieldModel<EditBookingFieldNames>[] = [
  {
    name: EditBookingFieldNames.StartDate,
    type: FormFieldTypes.DateTime,
    defaultValue: null,
    label: 'Abholdatum',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: EditBookingFieldNames.EndDate,
    type: FormFieldTypes.DateTime,
    defaultValue: null,
    label: 'Rückgabedatum',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: EditBookingFieldNames.PlannedKilometers,
    type: FormFieldTypes.Kilometers,
    defaultValue: '',
    label: 'Geplante Kilometer',
    validation: {
      required: 'Bitte prüfe die Eingabe.',
      min: {
        value: 0,
        message: 'Der Wert in diesem Feld sollte zwischen 0 und 1.000.000 liegen.'
      },
      max: {
        value: 1000000,
        message: 'Der Wert in diesem Feld sollte zwischen 0 und 1.000.000 liegen.'
      }
    }
  }
];

export const licensePlateFormFields: FormFieldModel<EditBookingFieldNames>[] = [
  {
    name: EditBookingFieldNames.LicensePlate,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    options: [],
    label: 'Fahrzeug (Kennzeichen)',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];
