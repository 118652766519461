import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { useChangeCostCenterModals } from 'components/cost-centers-settings/hooks/use-change-cost-center-modals';
import { StateCodeValueEnum } from 'components/driving-license-control-component/driving-license-control.props';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useVehicleDetailsClearCash } from '../use-clear-cash';
import { USER_ROLES } from '../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { VehicleDetailsEditFormSubmitCallbackParams } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form.props';

export const useVehicleDetailCostCenterFieldSubmit = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();

  const { openCostCenterHistoryModal } = useChangeCostCenterModals();
  const { clearVehicleHistoryCash, clearMainCash } = useVehicleDetailsClearCash();

  const availableForRoles = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const { fuhrpark } = fleetByIdQuery;

  const isActiveStateCode = fuhrpark?.statecode_value === StateCodeValueEnum.Active;

  const costCenterSubmitCallback = ({ values }: VehicleDetailsEditFormSubmitCallbackParams) => {
    if (values.new_kostenstelleid && fuhrpark?.new_fuhrparkid && isActiveStateCode && availableForRoles) {
      openCostCenterHistoryModal(
        () => {
          clearVehicleHistoryCash();
          clearMainCash();
        },
        PrimaryEntityNames.Vehicle,
        fuhrpark.new_fuhrparkid,
        undefined,
        values.new_kostenstelleid,
        false
      );

      return true;
    }

    return false;
  };

  return { costCenterSubmitCallback };
};
