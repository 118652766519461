import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { VehicleStatusChangeModalContent } from './modal-content/modal-content';
import { VehicleStatusChangeModalContentData } from './modal-content/modal-content.props';

export const useVehicleStatusChangeModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openVehicleStatusChangeModal = (vehicleId: string, presetFormData: VehicleStatusChangeModalContentData) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Statusänderung',
        children: <VehicleStatusChangeModalContent vehicleId={vehicleId} presetFormData={presetFormData} />,
        hideCloseIcon: false,
        allButtonsHidden: true,
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert
          }
        ]
      })
    );
  };

  return { openVehicleStatusChangeModal };
};
