import React from 'react';
import { Flex, Button, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { isEmpty } from 'lodash';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { cancelLeaseExtensionFormFields } from './cancel-lease-extension-task-form-fields';
// eslint-disable-next-line max-len
import { CancelLeaseExtensionProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';

const CancelLeaseExtensionTask = ({ onSubmit, onCancel }: CancelLeaseExtensionProps) => {
  const theme = useTheme();

  const reactHookFormData = useForm();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const submit = () => {
    handleSubmit(data => {
      onSubmit(data[LeasingContractFields.Comment]);
    })();
  };

  return (
    <Flex direction='column'>
      <Text>Bitte geben Sie den Grund an, warum Sie diese Aufgabe stornieren möchten</Text>
      <FormHook reactHookFormData={reactHookFormData} formFields={cancelLeaseExtensionFormFields} />
      <Flex justify='space-between'>
        <Text color={theme.colors.blue} pointer onClick={onCancel}>&larr; Abbrechen</Text>
        <Button disabled={!isEmpty(errors)} onClick={submit}>Bestätigen</Button>
      </Flex>
    </Flex>
  );
};

export default CancelLeaseExtensionTask;