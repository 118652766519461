import { VehicleTypeId } from './vehicle-gallery.props';

export const PLACEHOLDER_IMAGES: { [key in VehicleTypeId]: string } = {
  [VehicleTypeId.PersonalCar]: '/assets/images/vehicle-placeholders/PKW.svg',
  [VehicleTypeId.Truck]: '/assets/images/vehicle-placeholders/LKW.svg',
  [VehicleTypeId.Trailer]: '/assets/images/vehicle-placeholders/Anhanger.svg',
  [VehicleTypeId.Bicycle]: '/assets/images/vehicle-placeholders/Fahrrad.svg',
  [VehicleTypeId.Tool]: '/assets/images/vehicle-placeholders/Werkzeug.svg',
  [VehicleTypeId.Transporter]: '/assets/images/vehicle-placeholders/Transporter.svg',
  [VehicleTypeId.Forklift]: '/assets/images/vehicle-placeholders/Gabelstapler.svg'
};

export const defaultSpinnerStyle = {
  width: '100px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
};
