import { PAGES } from 'common/enums';
import { AnyObject } from 'common/interfaces';

export enum TileTitle {
  Driver = 'Fahrer',
  Vehicle = 'Fahrzeuge',
  InOperation = 'in Betrieb',
  Controlled = 'Ausgesteuert',
  Ordered = 'Bestellt',
  Configured = 'Konfiguriert',
  FatQuality = 'Datenqualität',
  Damage = 'Schäden',
  UpcomingTasks = 'Anstehende Aufgaben',
  UpcomingTasksSorted = 'Überfällige Aufgaben',
  VehicleCosts = 'Fahrzeugkosten',
  DriverLicense = 'Führerschein',
  Consumption = 'Verbrauch',
  TopFiveCosts = 'Top 5 Kosten',
  CO2Emission = 'CO2 Emissionen'
}

export enum DiagramMessage {
  Good = 'Datenqualität ist gut',
  Medium = 'Datenqualität ist mittel',
  Bad = 'Datenqualität ist schlecht'
}

export enum DiagramTooltipMessage {
  Good = 'Bei mehr als 90% der Flottenfahrzeuge sind Leasing- & Dienstleistungsinformationen hinterlegt',
  Medium = 'Bei 80-90% der Flottenfahrzeuge sind Leasing- & Dienstleistungsinformationen hinterlegt',
  Bad = 'Bei weniger als 80% der Flottenfahrzeuge sind Leasing- & Dienstleistungsinformationen hinterlegt'
}

export interface MonthData {
  month: number;
  fuels: {
    name: string;
    amount: number;
  }[];
}

export enum ChartFuelLabels {
  Diesel = 'Diesel',
  Petrol = 'Benzin',
  Electricity = 'Strom'
}

export enum ChartCO2Labels {
  Diesel = 'Deine Flotte, Diesel',
  Petrol = 'Deine Flotte, Benzin',
  Electricity = 'Deine Flotte, Electric'
}

export interface FleetManagerDashboardContextProps {
  activeCompany: { id: string; name: string };
  openPage: (props: OpenPageProps) => () => void;
}

export interface OpenPageProps {
  page: PAGES;
  companyCrmName: string;
  search?: string;
  filterForServer?: AnyObject;
  filterForClient?: AnyObject;
}
