import styled from 'styled-components';
import { css } from 'styled-components';

interface BigFilterContainerProps {
  isActive: boolean;
}

export const BigFilterStyledContainer = styled.div<BigFilterContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;

  &:hover {
    filter: drop-shadow(0px 7.59435px 17.869px rgba(171, 176, 178, 0.17))
      drop-shadow(0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17));

    .icon {
      display: none !important;
    }

    .icon-hovered {
      display: block !important;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.16));

      .icon {
        display: none;
      }

      .icon-hovered {
        display: block;
      }

      &:hover {
        filter: drop-shadow(2px 4px 5px rgba(0, 0, 0, 0.16));
      }
    `};
`;

export const StyledFilterIconContainer = styled.div`
  height: 38px;
  margin-right: 20px;
`;

export const StyledTitle = styled.h4`
  color: #335566;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const StyledSubtitle = styled.p`
  color: #8fafbf;
  font-size: 14px;
  font-weight: 400;
`;
