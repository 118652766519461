import React from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import InfoIcon from '../icons/information';

const InfoBlockComponent = ({ fieldText }: { fieldText: string }) => {
  return (
    <div className={styles.info_block_container}>
      <div className={styles.info_logo}>
        <InfoIcon />
      </div>
      <div className={styles.info_text}>{fieldText}</div>
      <Distance top='10' />
    </div>
  );
};

export default InfoBlockComponent;
