import styled, { css } from 'styled-components';
import img from 'assets/images/drop-down-blue-icon.svg';

interface InputProps {
  error?: boolean;
}

export const StyledInput = styled.input<InputProps>`
  width: 50%;
  margin-top: 5px;
  height: 38px;
  border: 0.5px solid rgba(119, 124, 127, 0.5);
  border-radius: 2px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-position-y: 50%;
  padding-left: 25px;
  font-size: 12px !important;
  color: ${({ theme }) => theme.colors.black};
  &:focus {
    border: 1px solid rgba(119, 124, 127, 1);
  }

  ${({ error }) =>
    error &&
    css`
      border: 0.5px solid #bf0040;
      &:focus {
        padding-left: 25px;
        border: 1px solid #bf0040;
      }
      label {
        color: #bf0040;
      }
    `};
`;

interface DropDownProps {
  top: number;
}

export const StyledDropdown = styled.div<DropDownProps>`
  width: 70%;
  min-width: 200px;
  min-height: 50px;
  max-height: 200px;
  overflow-x: hidden;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  z-index: 10;
  cursor: default;
  position: absolute;
  top: ${({ top }) => `${top}px`};
`;

interface ListItemProps {
  disabled?: boolean;
}

export const StyledListItem = styled.li<ListItemProps>`
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 1px;
  padding: 5px;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.grey300};

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        cursor: not-allowed;
      }
    `};
`;
