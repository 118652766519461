import React from 'react';

const ArrowLeftIcon = ({ width = '6', height = '8', viewBox = '0 0 6 8' }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.51852 1.00003L1.41187 3.9667L4.58834 7.00003'
        stroke='#5F6466'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowLeftIcon;
