import { API_ENDPOINT } from 'endpoints';
import authService from 'services/auth-service';
import axios from 'axios';

type CrateTaskPayload = {
  contactid: string;
  frage: string;
  dueDate: string;
  comment?: string;
};

export const getSupportBoxContacts = async () => {
  try {
    const url = `${API_ENDPOINT}/api/support/GetSupportBoxContacts`;
    const token = await authService.acquireTokenAsync();
    return await axios({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const createTask = async (payload: CrateTaskPayload) => {
  try {
    const url = `${API_ENDPOINT}/api/support/CreateTask`;
    const token = await authService.acquireTokenAsync();
    const { contactid, frage, dueDate, comment } = payload;

    await axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        contactid,
        frage,
        dueDate,
        comment
      }
    });
  } catch (error) {
    console.log(error);
  }
};
