import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const EuroWithPercentageIcon = ({
  width = 22,
  height = 22,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1' y='1' width='18' height='18' rx='1' fill={fill} />}
      <path
        d='M19.25 11.375V2.44994C19.25 2.0299 19.25 1.81988 19.1683 1.65945C19.0963 1.51832 18.9816 1.40359 18.8405 1.33168C18.6801 1.24994 18.47 1.24994 18.05 1.24994H2.45C2.02996 1.24994 1.81994 1.24994 1.65951 1.33168C1.51839 1.40359 1.40365 1.51832 1.33175 1.65945C1.25 1.81988 1.25 2.0299 1.25 2.44994V18.0499C1.25 18.47 1.25 18.68 1.33175 18.8404C1.40365 18.9816 1.51839 19.0963 1.65951 19.1682C1.81994 19.2499 2.02996 19.2499 2.45 19.2499H12.9961'
        stroke={color}
        strokeLinecap='round'
      />
      <path
        d='M13.3743 7.03008C10.3165 4.27974 7.83013 6.45815 7.1365 8.90547C7.00301 9.37646 6.99483 10.151 6.99483 10.151C6.99483 10.151 6.98319 11.0253 7.1365 11.5543C7.84621 14.0033 10.3165 16.2334 13.3743 13.4831'
        stroke={color}
        strokeLinecap='round'
      />
      <path d='M10.7422 9.31676L5.62598 9.30824' stroke={color} strokeLinecap='round' />
      <path d='M10.6938 11.1918H5.62598' stroke={color} strokeLinecap='round' />
      <path
        d='M14.7124 15.4289C14.7124 16.2181 15.3522 16.8579 16.1414 16.8579C16.9306 16.8579 17.5703 16.2181 17.5703 15.4289C17.5703 14.6398 16.9306 14 16.1414 14C15.3522 14 14.7124 14.6398 14.7124 15.4289Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.6421 19.7158C18.6421 20.505 19.2819 21.1447 20.0711 21.1447C20.8602 21.1447 21.5 20.505 21.5 19.7158C21.5 18.9266 20.8602 18.2868 20.0711 18.2868C19.2819 18.2868 18.6421 18.9266 18.6421 19.7158Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.5 14.3572L14.7125 21.1447'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
