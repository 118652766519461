import React from 'react';
import { EditBookingProps } from './edit-booking.types';
import { Button, Distance, Flex, Text } from 'common/common-components.styled';
import { InfoBlock } from 'pages/details/order-widget/order-widget';
import useBookingInfoItems from 'components/pool-car-booking/hooks/use-booking-info-items';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import useEditBookingFields from 'components/pool-car-booking/hooks/use-edit-booking-fields';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { UpdateBookingRequestData } from 'request-config/pool-calendar/pool-calendar.types';
import { getRequestAttributes } from 'utils/get-request-attributes';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { updateBookingAttributes } from 'components/pool-car-booking/booking-more-menu';
import { convertLocalDateToUTC } from 'utils/convert-UTC-date-to-local-date';
import cloneDeep from 'lodash/cloneDeep';
import { useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import useCheckUnavailableDates from 'components/pool-car-booking/hooks/use-check-unavailable-dates';
import useFillBookingFields from 'components/pool-car-booking/hooks/use-fill-booking-fields';

const EditBooking = ({ booking, closeAlert }: EditBookingProps) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;
  const { fetch } = useFetch({ loadingKey: 'updatePoolCarBooking', isShowSuccessSnackbar: true });
  const queryClient = useQueryClient();

  const bookingInfoItems = useBookingInfoItems(booking, reactHookFormData);
  const { editBookingFields } = useEditBookingFields(booking, reactHookFormData);

  useCheckUnavailableDates(booking, reactHookFormData);
  useFillBookingFields(booking, reactHookFormData);

  const updateBooking = () => {
    handleSubmit(data => {
      const dataToSend = cloneDeep(data);
      dataToSend.uds_abholdatum = convertLocalDateToUTC(dataToSend.uds_abholdatum);
      dataToSend.uds_ruckgabedatum = convertLocalDateToUTC(dataToSend.uds_ruckgabedatum);
      const requestData: UpdateBookingRequestData = {
        id: booking.uds_poolkalenderid,
        attributes: getRequestAttributes(updateBookingAttributes, dataToSend)
      };

      closeAlert();
      fetch({
        requestConfig: poolCalendarRequestConfig.updatePoolCarBooking(requestData),
        callback: () => {
          queryClient.resetQueries([ReactQueryKeys.GetPoolCalendarList]);
          queryClient.resetQueries([ReactQueryKeys.GetPoolCarsWithBookings]);
          queryClient.resetQueries([ReactQueryKeys.GetUnavailableDateRanges]);
        }
      });
    })();
  };

  return (
    <div>
      <Text size='20' bold bottom='20' color='grey800'>
        {booking.uds_name}
      </Text>

      <FormHook reactHookFormData={reactHookFormData} formFields={editBookingFields} />
      <Distance top='30' />

      {bookingInfoItems.map(bookingInfoItem => (
        <div key={bookingInfoItem.key}>
          <InfoBlock title={bookingInfoItem.label} value={bookingInfoItem.value} align='flex-start' textAlign='left' />
          <Distance top='20' />
        </div>
      ))}

      <Flex justify='flex-end'>
        <Button onClick={closeAlert} right='10' secondary>
          Abbrechen
        </Button>
        <Button onClick={updateBooking} disabled={!isValid}>
          Speichern
        </Button>
      </Flex>
    </div>
  );
};

export default EditBooking;
