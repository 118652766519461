import React from 'react';
import classes from './vehicle-costs.module.scss';
import { TotalRunningTimeCostsView } from './view/total-running-time-costs/total-running-time-costs';
import { CostByYearView } from './view/cost-by-year/cost-by-year';
import { StyledVehicleCostWidgetTile } from './vehicle-costs.styled';
import { useVehicleCostWidgetEmptyPlaceholder } from './hooks/use-empty-placeholder';

export const VehicleCostsWidget = () => {
  const { emptyPlaceholder } = useVehicleCostWidgetEmptyPlaceholder();

  return (
    <div className={classes.container}>
      <StyledVehicleCostWidgetTile id='vehicleCostsWidget' emptyPlaceholder={emptyPlaceholder}>
        <TotalRunningTimeCostsView />
        <CostByYearView />
      </StyledVehicleCostWidgetTile>
    </div>
  );
};
