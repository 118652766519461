import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { AnyObject, ResponseModel } from 'common/interfaces';
import { getEntityById } from 'services/crm-entity-service';
import { RequestStatuses } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';

export const useCompanyDetails = (companyId: string | null, queryKey: string) => {
  const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
    if (data.level === RequestStatuses.Success) {
      return getResponseFormData(data, [{ name: 'attributes', type: 'object' }]) as AnyObject;
    }
    return undefined;
  };

  const { data: companyDetails } = useQuery(
    [queryKey, companyId],
    () =>
      getEntityById({
        entityId: companyId,
        entityName: 'account',
        columns: ['name', 'address1_line1', 'address1_postalcode', 'address1_city', 'telephone1', 'accountid']
      }),
    {
      select: modifyResponse,
      enabled: !!companyId
    }
  );

  return {
    companyDetails
  };
};
