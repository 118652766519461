import React from 'react';
import { TabItemFrameProps } from '../../tab-item-frame/tab-item-frame.props';
import { StyledTabItemSection, StyledTabTriangle } from './section.styled';
import { TabItemFrame } from '../../tab-item-frame/tab-item-frame';
import { Flex } from 'common/common-components.styled';

export const TabItemSection = ({
  children,
  isActive,
  tabIndex,
  isHide,
  className,
  ...restTextProps
}: TabItemFrameProps) => {
  if (isHide) {
    return null;
  }
  return (
    <Flex direction='column' flex={1} className={className}>
      <StyledTabItemSection isActive={isActive}>
        <TabItemFrame isActive={isActive} tabIndex={tabIndex} {...restTextProps}>
          {children}
        </TabItemFrame>
      </StyledTabItemSection>

      {isActive && <StyledTabTriangle />}
    </Flex>
  );
};
