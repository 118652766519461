import React from 'react';
import classes from '../my-vehicles.module.scss';
import { TableComponentsProps } from './table-components.props';
import { getChipColor } from '../utils';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

export const DriverStatus = ({ row }: TableComponentsProps) => {
  if (!row) {
    return null;
  }

  return (
    <EllipsisWithTooltip tooltip={row.new_status_carcode}>
      <span style={{ color: getChipColor(row.new_status_carcode) }} className={classes.driver_status}>
        {row.new_status_carcode || '-'}
      </span>
    </EllipsisWithTooltip>
  );
};
