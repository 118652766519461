import { useTheme } from 'styled-components';

const useDefaultIconsColor = () => {
  const theme = useTheme();
  const defaultColor = theme.colors.blue;
  const defaultFill = theme.colors.whiteBlue;

  return { defaultColor, defaultFill };
};

export default useDefaultIconsColor;
