import React from 'react';
import { FormSection } from 'components/sections/form-section/form-section';
import LeasingSection from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/leasing/leasing-section/leasing-section';

export const VehicleDetailsLeasingTabPanel = () => {
  return (
    <FormSection id='leasingSection' title='Leasing & Leistungen'>
      <LeasingSection />
    </FormSection>
  );
};
