import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutDiagramProps } from './doughnut.props';
import { useDoughnutData } from './hooks/use-data';
import { useDoughnutOptions } from './hooks/use-options';
import { Flex } from 'common/common-components.styled';
import { StyledFocusedSegmentContainer } from './doughnut.styled';

export const DoughnutDiagram = ({
  data,
  options = {},
  defaultActiveSegmentIndex,
  renderFocusedSegment,
  renderFocusedSegmentContainerProps,
  ...flexProps
}: DoughnutDiagramProps) => {
  const filteredData = data.filter(item => !!item.value);

  const { doughnutData, setDoughnutData } = useDoughnutData(
    filteredData,
    options.excludeActiveSegments ? -1 : defaultActiveSegmentIndex || 0
  );
  const { doughnutOptions, segmentIndex } = useDoughnutOptions(
    options,
    filteredData,
    setDoughnutData,
    defaultActiveSegmentIndex || 0
  );

  const isShowRenderFocusedSegment = !!filteredData[segmentIndex.hovered];

  return (
    <Flex position='relative' width='100%' {...flexProps}>
      <Doughnut data={doughnutData} options={doughnutOptions} />
      <StyledFocusedSegmentContainer {...renderFocusedSegmentContainerProps}>
        {isShowRenderFocusedSegment ? renderFocusedSegment({ data: filteredData[segmentIndex.hovered] }) : null}
      </StyledFocusedSegmentContainer>
    </Flex>
  );
};
