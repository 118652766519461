import styled, { css } from 'styled-components';
import { StyledInfoCard } from 'pages/details/order-widget/order-widget.styled';

interface StyledGrvLeasingOffersSwitcherProps {
  open: boolean;
  hasError: boolean;
}

export const StyledGrvLeasingOffersSwitcher = styled(StyledInfoCard)<StyledGrvLeasingOffersSwitcherProps>`
  border: 1px solid
    ${({ theme, open, hasError }) => (hasError ? theme.colors.pink : open ? theme.colors.blue : theme.colors.lightBlue)};

  ${({ hasError }) =>
    hasError &&
    css`
      background: ${({ theme }) => theme.colors.extraLightPink};
    `};
`;
