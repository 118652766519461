import React, { useContext } from 'react';
import { Distance, Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import InfoHint from 'components/info-hint/info-hint-view';
import useChangeCostCenterFields from '../hooks/use-change-cost-center-fields';
import useFillHistoryFields from '../hooks/use-fill-history-fields';
import { useForm } from 'react-hook-form';
import SubmitButton from './submit-button';
import { ChangeCostCenterContext } from './change-cost-center.context';

const ChangeCostCenter = () => {
  const { showInfoHint, item } = useContext(ChangeCostCenterContext);
  const reactHookFormData = useForm();

  useFillHistoryFields(reactHookFormData);
  const { changeCostCenterFields } = useChangeCostCenterFields(reactHookFormData);

  return (
    <div>
      <Text color='grey900' bottom='15'>
        {!item
          ? 'Du hast die Kostenstelle geändert. Bitte gib ein Datum an, ab wann die Kostenstelle gültig sein soll. Die Kostenstellen-Historie findest Du im Tab “Fahzeug Historie” auf der Fahrzeugdetailseite.'
          : 'Bitte gib ein Datum an, ab wann die Kostenstelle gültig sein soll. Die Kostenstellen-Historie findest Du in der “Kostenstellen Historie” auf der Fahrzeugdetailseite.'}
      </Text>

      <InfoHint type='info'>
        Überschneidungen von Kostenstellen Historien sind nicht erlaubt. Du könntest nur ein Datum auswählen, das sich
        nicht mit früheren Datensätzen überschneidet.
      </InfoHint>
      <Distance top='15' />

      <FormHook reactHookFormData={reactHookFormData} formFields={changeCostCenterFields} />

      {showInfoHint && (
        <>
          <InfoHint type='info'>
            Achtung: diese Änderung der Kostenstelle überträgt sich automatisch in alle zugehörigen Fahrzeuge.
          </InfoHint>
          <Distance top='15' />
        </>
      )}

      <Flex justify='flex-end'>
        <SubmitButton reactHookFormData={reactHookFormData} />
      </Flex>
    </div>
  );
};

export default ChangeCostCenter;
