import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classes from './aufgaben-filters.module.scss';
import {
  ClearAllFiltersButton,
  Filter,
  FilterButton,
  FilterContainer,
  FilterInput,
  PickersContainer
} from '../../../filter-items';
import { STATECODE_NAMES, TASK_STATUS_CODES } from '../../../../constants';
import { getData, getFormatedDate } from 'utils/convert-date-to-server';
import { useMultiplyFilter } from 'hooks/use-multiply-filter/use-multiply-filter';

export const AufgabenFilters = ({
  filterData,
  closeUnfoldedTaskHandler,
  sessionFilter,
  setSessionFilter,
  goToFirstPage
}) => {
  const filterCars = filterData?.regardingObjects?.map(company => company.name) || [];
  const filterDrivers = filterData?.executorContacts?.map(company => company.name) || [];
  const filterCompanies = filterData?.executorFirms?.map(company => company.name) || [];
  const filterDueDate = filterData?.scheduledendStatuses || [];
  const filterTaskType = filterData?.taskTypes || [];

  const [isOpen, setOpen] = useState('');

  const companyLabel = sessionFilter.filterForClient[CRM_VALUE.company];
  const taskTypeLabel = sessionFilter.filterForClient[CRM_VALUE.taskType];
  const carLabel = sessionFilter.filterForClient[CRM_VALUE.isWithoutCar]
    ? WITHOUT_VEHICLE
    : sessionFilter.filterForClient[CRM_VALUE.car];
  const driverLabel =
    sessionFilter.filterForClient[CRM_VALUE.driver]?.name || sessionFilter.filterForClient?.executorContact || null;

  const {
    label: statusLabel,
    allLabels: statusAllLabels,
    submitFilter: statusSubmitFilter
  } = useMultiplyFilter(sessionFilter, CRM_VALUE.status);

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'unternehmen':
        return 'executorFirmId';

      case 'aufgabentyp':
        return 'aufgabenTyp';

      case 'kennzeichen':
        return 'regardingObjectId';

      case 'mitarbeiter':
        return 'executorContact';

      case 'fälligkeit':
        return 'scheduledendStatus';

      case 'status':
        return 'statuses';

      case 'iswithoutcar':
        return 'isWithoutCar';

      default:
        return '';
    }
  };

  const saveFilter = (title, serverValue, clientValue) => {
    if (title.toLowerCase() === 'kennzeichen') {
      setSessionFilter(
        {
          [convertHeaderToCrmName(title)]: serverValue === WITHOUT_VEHICLE ? null : serverValue,
          [convertHeaderToCrmName('iswithoutcar')]: serverValue === WITHOUT_VEHICLE ? true : null
        },
        {
          [convertHeaderToCrmName(title)]: clientValue === WITHOUT_VEHICLE ? null : clientValue,
          [convertHeaderToCrmName('iswithoutcar')]: clientValue === WITHOUT_VEHICLE ? true : null
        }
      );
      return;
    }

    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue }
    );
  };

  const toggleFilterIsOpen = (title = null) => {
    setOpen(title);
  };

  const clearFilter = (title = '') => {
    closeUnfoldedTaskHandler();
    toggleFilterIsOpen();
    goToFirstPage();

    if (!title) {
      return setSessionFilter(EMPTY_FILTER_STATE_SERVER, EMPTY_FILTER_STATE_CLIENT);
    }

    if (title.toLowerCase() === 'fälligkeit') {
      return setSessionFilter(
        {
          [CRM_VALUE.startScheduledDate]: null,
          [CRM_VALUE.endScheduledDate]: null,
          [CRM_VALUE.dueDateStatus]: null
        },
        { [CRM_VALUE.dueDateFull]: null, [CRM_VALUE.dueDateStatus]: null }
      );
    }

    saveFilter(title, null, null);
  };

  const handleInput = (title, value) => {
    closeUnfoldedTaskHandler();
    goToFirstPage();

    if (title.toLowerCase() === 'status') {
      const { filterFromServer, filterFromClient } = statusSubmitFilter(value.value, value.label);
      return saveFilter(title, filterFromServer, filterFromClient);
    }

    toggleFilterIsOpen();

    if (title.toLowerCase() === 'unternehmen') {
      const company = filterData?.executorFirms?.find(companyItem => companyItem.name === value);
      if (company) {
        saveFilter(title, company.id, company.name);
      }
      return;
    }

    if (title.toLowerCase() === 'kennzeichen') {
      if (value === WITHOUT_VEHICLE) {
        return saveFilter(title, value, value);
      }
      const car = filterData?.regardingObjects?.find(carItem => carItem.name === value);
      if (car) {
        saveFilter(title, car.id, car.name);
      }
      return;
    }

    if (title.toLowerCase() === 'mitarbeiter') {
      const driver = filterData?.executorContacts?.find(driverItem => driverItem.name === value);
      if (driver) {
        saveFilter(title, driver, driver);
      }
      return;
    }

    if (title.toLowerCase() === 'aufgabentyp') {
      const taskType = filterData?.taskTypes?.find(taskTypeItem => taskTypeItem.value === value);
      if (taskType) {
        saveFilter(title, taskType.value, taskType.label);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.dueDateStatus) {
      if (value === DUE_DATE_NAMES.weekAhead.toLowerCase()) {
        return setSessionFilter(
          {
            [CRM_VALUE.status]: [TASK_STATUS_CODES.open],
            [CRM_VALUE.dueDateStatus]: value,
            [CRM_VALUE.endScheduledDate]: null,
            [CRM_VALUE.startScheduledDate]: null
          },
          {
            [CRM_VALUE.status]: [STATECODE_NAMES.open],
            [CRM_VALUE.dueDateStatus]: value,
            [CRM_VALUE.dueDateFull]: null
          }
        );
      } else if (value !== DUE_DATE_NAMES.overdue.toLowerCase()) {
        const date = getData(value);
        return setSessionFilter(
          {
            [CRM_VALUE.startScheduledDate]: date.length > 0 ? getFormatedDate(date[0]) : '',
            [CRM_VALUE.endScheduledDate]: date.length > 0 ? getFormatedDate(date[1]) : '',
            [CRM_VALUE.dueDateStatus]: null
          },
          { [CRM_VALUE.dueDateFull]: value, [CRM_VALUE.dueDateStatus]: null }
        );
      } else {
        return setSessionFilter(
          {
            [CRM_VALUE.endScheduledDate]: null,
            [CRM_VALUE.startScheduledDate]: null,
            [CRM_VALUE.dueDateStatus]: value
          },
          {
            [CRM_VALUE.dueDateFull]: null,
            [CRM_VALUE.dueDateStatus]: value
          }
        );
      }
    }
    saveFilter(title, value, value);
  };

  const getDueDateFilterLabel = () => {
    const dueStatus = sessionFilter.filterForClient[CRM_VALUE.dueDateStatus];
    if (dueStatus) {
      return dueStatus === 'in den nächsten 7 tagen fällig' ? DUE_DATE_NAMES.weekAhead : DUE_DATE_NAMES.overdue;
    } else {
      return sessionFilter.filterForClient[CRM_VALUE.dueDateFull];
    }
  };

  return (
    <section className={classes.filters}>
      <Filter
        title='Aufgabentyp'
        isOpen={isOpen}
        value={taskTypeLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Aufgabentyp' onClose={toggleFilterIsOpen} isNothingFound={!filterTaskType.length}>
          {filterTaskType.map(taskType => (
            <FilterButton
              handleInput={handleInput}
              title='aufgabentyp'
              label={taskType.label}
              value={taskType.value}
              idEnding={taskType.value}
              key={taskType.value}
            />
          ))}
        </FilterContainer>
      </Filter>
      <Filter
        title='Kennzeichen'
        isOpen={isOpen}
        value={carLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Kennzeichen' onClose={toggleFilterIsOpen}>
          {filterCars.map((car, indx) => {
            if (indx < 2) {
              return (
                <FilterButton
                  handleInput={handleInput}
                  title='kennzeichen'
                  label={car}
                  value={car}
                  idEnding={indx}
                  key={indx}
                />
              );
            }
          })}
          <FilterButton
            handleInput={handleInput}
            title='kennzeichen'
            label='ohne Fahrzeug'
            value={WITHOUT_VEHICLE}
            idEnding='WithoutVehicle'
          />
          <FilterInput title='kennzeichen' handleInput={handleInput} dropListData={filterCars} withoutSearchButton />
        </FilterContainer>
      </Filter>
      <Filter
        title='Mitarbeiter'
        isOpen={isOpen}
        value={driverLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Mitarbeiter' onClose={toggleFilterIsOpen}>
          {filterDrivers.map((driver, indx) => {
            if (indx < 0) {
              return (
                <FilterButton
                  handleInput={handleInput}
                  title='Mitarbeiter'
                  label={driver}
                  value={driver}
                  idEnding={indx}
                  key={indx}
                />
              );
            }
          })}
          <FilterInput handleInput={handleInput} title='Mitarbeiter' dropListData={filterDrivers} withoutSearchButton />
        </FilterContainer>
      </Filter>
      <Filter
        title='Unternehmen'
        isOpen={isOpen}
        value={companyLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Unternehmen' onClose={toggleFilterIsOpen}>
          {filterCompanies.map((company, indx) => {
            if (indx < 2) {
              return (
                <FilterButton
                  handleInput={handleInput}
                  title='unternehmen'
                  label={company}
                  value={company}
                  idEnding={indx}
                  key={indx}
                />
              );
            }
          })}
          <FilterInput
            title='Unternehmen'
            handleInput={handleInput}
            dropListData={filterCompanies}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Fälligkeit'
        isOpen={isOpen}
        value={getDueDateFilterLabel()}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Fälligkeit' onClose={toggleFilterIsOpen}>
          {filterDueDate.some(dueDate => dueDate.toLowerCase() === DUE_DATE_NAMES.overdue.toLowerCase()) ? (
            <FilterButton
              handleInput={handleInput}
              title='fälligkeit'
              label={DUE_DATE_NAMES.overdue}
              idEnding={'InFuture'}
              value={DUE_DATE_NAMES.overdue.toLowerCase()}
            />
          ) : null}
          {filterDueDate.some(dueDate => dueDate.toLowerCase() === DUE_DATE_NAMES.weekAhead.toLowerCase()) ? (
            <FilterButton
              handleInput={handleInput}
              title='fälligkeit'
              label={DUE_DATE_NAMES.weekAhead}
              idEnding={'WeekAhead'}
              value={DUE_DATE_NAMES.weekAhead.toLowerCase()}
            />
          ) : null}
          <PickersContainer
            handleInput={handleInput}
            title='fälligkeit'
            value={sessionFilter.filterForClient[CRM_VALUE.dueDateFull]}
          />
        </FilterContainer>
      </Filter>
      <Filter
        title='Status'
        isOpen={isOpen}
        value={statusLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Status' onClose={toggleFilterIsOpen}>
          {statusButtonData.map(statecode => (
            <FilterButton
              key={statecode.value}
              handleInput={handleInput}
              title='status'
              label={statecode.label}
              value={statecode}
              idEnding={statecode.value}
              active={statusAllLabels.some(filter => statecode.label === filter)}
            />
          ))}
        </FilterContainer>
      </Filter>

      <ClearAllFiltersButton filterState={sessionFilter.filterForClient} clearFilter={clearFilter} />
    </section>
  );
};

export const statusButtonData = [
  { label: STATECODE_NAMES.open, value: TASK_STATUS_CODES.open },
  { label: STATECODE_NAMES.processing, value: TASK_STATUS_CODES.processing },
  { label: STATECODE_NAMES.completed, value: TASK_STATUS_CODES.completed },
  { label: STATECODE_NAMES.canceled, value: TASK_STATUS_CODES.canceled },
  { label: STATECODE_NAMES.postponed, value: TASK_STATUS_CODES.postponed }
];

const EMPTY_FILTER_STATE_SERVER = {
  executorFirmId: null,
  regardingObjectId: null,
  scheduledendStatus: null,
  statuses: null,
  aufgabenTyp: null,
  executorContact: null,
  isWithoutCar: null,
  StartScheduledDate: null,
  EndScheduledDate: null
};

const EMPTY_FILTER_STATE_CLIENT = {
  executorFirmId: null,
  regardingObjectId: null,
  scheduledendStatus: null,
  statuses: null,
  aufgabenTyp: null,
  executorContact: null,
  isWithoutCar: null,
  scheduledendDate: null
};

export const DUE_DATE_NAMES = {
  overdue: 'Überfällig',
  weekAhead: 'In den nächsten 7 Tagen fällig'
};

export const DUE_DATE_VALUES = {
  overdue: 'überfällig'
};

export const CRM_VALUE = {
  company: 'executorFirmId',
  taskType: 'aufgabenTyp',
  car: 'regardingObjectId',
  driver: 'executorContact',
  dueDateStatus: 'scheduledendStatus',
  status: 'statuses',
  isWithoutCar: 'isWithoutCar',
  startScheduledDate: 'StartScheduledDate',
  endScheduledDate: 'EndScheduledDate',
  dueDateFull: 'scheduledendDate'
};

const WITHOUT_VEHICLE = 'ohne Fahrzeug';

AufgabenFilters.propTypes = {
  filterData: PropTypes.shape({
    executorFirms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        logicalName: PropTypes.string
      })
    ),
    regardingObjects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        logicalName: PropTypes.string
      })
    ),
    scheduledendStatuses: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
      })
    ),
    taskTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
      })
    ),
    executorContacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        logicalName: PropTypes.string
      })
    )
  }),
  user: PropTypes.shape({
    avatar: PropTypes.string,
    childCompanies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    companyId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  closeUnfoldedTaskHandler: PropTypes.func.isRequired,
  sessionFilter: PropTypes.shape({
    predicateObject: PropTypes.shape({
      executorFirmId: PropTypes.oneOfType([PropTypes.string]),
      isWithoutCar: PropTypes.bool,
      regardingObjectId: PropTypes.oneOfType([PropTypes.string]),
      scheduledendStatus: PropTypes.oneOfType([PropTypes.string]),
      statuses: PropTypes.oneOfType([PropTypes.number]),
      aufgabenTyp: PropTypes.oneOfType([PropTypes.number]),
      executorContact: PropTypes.oneOfType([PropTypes.string])
    })
  }).isRequired,
  setSessionFilter: PropTypes.func.isRequired,
  goToFirstPage: PropTypes.func.isRequired
};

AufgabenFilters.defaultProps = {
  filterData: undefined
};
