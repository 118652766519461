import React from 'react';
import { ReplaceTextProps } from './replace-text.props';
import { Text } from 'common/common-components.styled';

export const ReplaceText = ({ text, replaceWord, replaceComponent, ...textProps }: ReplaceTextProps) => {
  const parts = text.split(replaceWord);

  return (
    <Text {...textProps}>
      {text.split(replaceWord).map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index !== parts.length - 1 && replaceComponent()}
        </React.Fragment>
      ))}
    </Text>
  );
};
