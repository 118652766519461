import { UseFormReturn } from 'react-hook-form';
import { ConnectInspectionDateToCarParams } from 'request-config/inspection-dates/inspection-dates.types';

export type OpenInspectionDateLinkToCarModalParams = Omit<ConnectInspectionDateToCarParams, 'lastInspection'> & {
  willOverdueTaskCreatedOnSelectingFirstRegistrationDate: boolean;
  editableHistoryId?: string;
  firstRegistrationDate: string | undefined;
  description: string;
};

export interface InspectionDateLinkToCarModalFormData {
  inspectionDateCheckbox: string;
  inspectionDate: string;
}

export interface InspectionDateLinkToCarModalFormProps
  extends Pick<OpenInspectionDateLinkToCarModalParams, 'firstRegistrationDate'> {
  reactHookFormData: UseFormReturn<InspectionDateLinkToCarModalFormData>;
}

export enum InspectionDateCheckboxRadioOptionSetValue {
  FirstRegistration = 'firstRegistration',
  IndividualDate = 'IndividualDate'
}
