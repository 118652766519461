import { CostCentersHistoryItem } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';

export interface ChangeCostCenterContextProps {
  callback: () => void;
  entityName: PrimaryEntityNames;
  id: string;
  closeModal: () => void;
  item?: CostCentersHistoryItem;
  showInfoHint?: boolean;
  costCenterId?: string;
}

export enum ChangeCostCenterFieldNames {
  ValidFrom = 'uds_assignment_valid_from',
  ValidTo = 'uds_assignment_valid_to'
}
