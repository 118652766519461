import { CircleOutline } from 'common/common-components.styled';
import { CircleCheckMark } from 'components/icons/circle-check-mark';
import React from 'react';
import { StepProcessHorizontalItemProps } from './step-process-horizontal-item.props';
import { useTheme } from 'styled-components';

export const StepIcon = ({ type, id }: Pick<StepProcessHorizontalItemProps, 'type' | 'id'>) => {
  const theme = useTheme();
  switch (type) {
    case 'done':
      return (
        <div id={id + 'Done'}>
          <CircleCheckMark color={theme.colors.blue} width={24} height={24} />
        </div>
      );

    case 'active':
      return (
        <div id={id + 'Active'}>
          <CircleOutline color='blue' size='24px' />
        </div>
      );

    case 'inactive':
      return (
        <div id={id + 'Inactive'}>
          <CircleOutline color='midBlue' size='24px' />
        </div>
      );

    default:
      return null;
  }
};
