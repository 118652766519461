import styled from 'styled-components';

interface StyledDroppableContainerProps {
  isDraggingOver: boolean;
}

export const StyledDroppableContainer = styled.div<StyledDroppableContainerProps>`
  flex: 1;
  word-break: break-all;
  height: 100%;
  background-color: ${({ isDraggingOver, theme }) => (isDraggingOver ? theme.colors.grey25 : theme.colors.white)};
`;

interface ItemProps {
  isDragging: boolean;
}

export const StyledDroppableContentContainer = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 5px;
  box-shadow: ${({ isDragging, theme }) => (isDragging ? `3px 4px 4px ${theme.colors.grey200}` : null)};
  padding: 10px;
  margin-bottom: 10px;
`;
