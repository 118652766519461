import styled from 'styled-components';

export const ConfigurationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;
