import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Select from '../../select';
import classes from './price-options.module.scss';

function PriceOptions(props) {
  return (
    <div className={`${classes.select_wrapper} product-feat text-center`}>
      <p style={{color: props.selectProps.style.color}}>{props.label}</p>
      <div className={classnames('select filter-select', classes.option_set_container)}>
        <Select
          options={props.options}
          selectProps={{
            className: 'form-control btn btn-default btn-block select-manufacturer',
            ...props.selectProps
          }}
        />
      </div>
    </div>
  );
}

PriceOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  label: PropTypes.string,
  selectProps: PropTypes.object
};

export default PriceOptions;
