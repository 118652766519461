import { Text } from 'common/common-components.styled';
import styled from 'styled-components';
import { css } from 'styled-components';

export const LeasingWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 230px;
`;

export const StyledStatusTextWrapper = styled.div<{ isStatusHidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  white-space: nowrap;
  margin-bottom: 20px;
`;

export const StatusLabel = styled(Text)<{ statusColor: string }>`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey400};

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme, statusColor }) => theme.colors[statusColor]};
    margin-right: 5px;
    margin-bottom: 2px;
  }
`;

export const StyledBulletPointsList = styled.ul`
  display: flex;
  gap: 5px;
`;

export const StyledBulletPoint = styled.li<{ isActive?: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid #c6cacc;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;

  ${({ isActive }) =>
    isActive &&
    css`
      border: none;
      background: #335566;
    `}
`;

export const WidgetContentText = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey900};
  width: 100%;
`;

export const LeasingWidgetContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;
