import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';

import { inspectionDatesRequestConfig } from 'request-config/inspection-dates/inspection-dates.request-config';
import { InspectionDatesHistoryGetByIdResponse } from './get-history-by-task-id.props';

export const useInspectionDatesHistoryGetByIdQuery = (taskId?: string | null) => {
  const { fetch } = useFetch<ResponseModel<InspectionDatesHistoryGetByIdResponse>>({
    loadingKey: `${INSPECTION_DATES_HISTORY_GET_BY_ID_QUERY_KEY}${taskId}`,
    isShowLoading: false,
    ...inspectionDatesRequestConfig.getInspectionDateHistoryByTaskId(taskId as string)
  });

  const { data, ...restProps } = useQuery([INSPECTION_DATES_HISTORY_GET_BY_ID_QUERY_KEY, taskId], () => fetch(), {
    enabled: !!taskId
  });

  return {
    inspectionDatesHistoryGetByIdQuery: data?.data?.data?.pruftermineHistory?.[0]?.attributes || {},
    ...restProps
  };
};

export const INSPECTION_DATES_HISTORY_GET_BY_ID_QUERY_KEY = 'inspectionDatesHistoryGetByIdQuery';
