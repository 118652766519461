import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

const ArrowUpIcon = ({ width = 25, height = 25, color: colorProp, strokeWidth }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 16.5078L12.0156 7.49221M21 16.5078L12.0157 7.52343'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width={strokeWidth}
      />
    </svg>
  );
};

export default ArrowUpIcon;
