import React from 'react';
import classes from './mobile-table-component.module.scss';
import { getStatusColorForTaskPages } from 'utils/get-status-color-task-pages';
import { MobileItemColumn, MobileTableComponentProps } from '../tasks-page.props';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { DescriptionComponent } from '../tasks-page';
import { useTheme } from 'styled-components';

const MobileTableComponent = ({ rowData, indx, setSnackbarState, openMobileItemIndx }: MobileTableComponentProps) => {
  const theme = useTheme();

  const containerStyle = {
    borderColor: theme.colors[getStatusColorForTaskPages(rowData)]
  };

  return (
    <li style={containerStyle} className={classes.container} data-index={indx}>
      <p className={classes.value}>{rowData.description || '-'}</p>
      <p className={classes.value_secondary}>{rowData.scheduledend ? getDateString(rowData.scheduledend) : '-'}</p>

      {openMobileItemIndx === indx && (
        <>
          <ul className={classes.list_column}>
            {itemColumn.map(fieldItem => (
              <li key={fieldItem.udsName} className={classes.item_column}>
                <div className={classes.field_name}>{fieldItem.title}</div>
                <div className={classes.field_value}>{rowData[fieldItem.udsName] || '-'}</div>
              </li>
            ))}
          </ul>
          <DescriptionComponent row={rowData} setSnackbarState={setSnackbarState} />
        </>
      )}
    </li>
  );
};

const itemColumn: MobileItemColumn[] = [
  {
    title: 'Fragesteller',
    udsName: 'uds_isfragesteller'
  },
  {
    title: 'Besitzer',
    udsName: 'ownerid'
  },
  {
    title: 'Zuständige',
    udsName: 'uds_executor_contactid'
  },
  {
    title: 'Bezug',
    udsName: 'regardingobjectid'
  }
];

export default MobileTableComponent;
