import React from 'react';
import { ButtonComponentType } from 'components/button/button.props';
import { DownloadExelProps, FieldOrderTypes } from './download-exel.types';
import Button from 'components/button';
import { useDownloadExel } from './use-donwload-exel';
import ArrowUpIcon from 'components/icons-new/arrow-up';
import { Popover } from '@mui/material';
import { ArrowDownIcon } from 'components/icons-new/arrow-down';
import { useTheme } from 'styled-components';
import { Text } from 'common/common-components.styled';
import Download from 'components/icons/download';
import classes from './download-exel.module.scss';

const DownloadExel: React.FC<DownloadExelProps> = ({ tableParams, sessionFilter }) => {
  const theme = useTheme();
  const { anchorEl, options, handleShowOptions, handleCloseHistoryList, handleDownloadFile } = useDownloadExel(
    tableParams,
    sessionFilter
  );

  return (
    <div className={classes.download_exel_container}>
      <Button
        component={ButtonComponentType.DownloadExcelIcon}
        onClick={() =>
          handleDownloadFile(
            sessionFilter.filterForServer.uds_mileagerecommendation
              ? FieldOrderTypes.Accounting
              : FieldOrderTypes.Standart
          )
        }
        iconWidth={32}
        tooltip='Download File Excel'
      />

      <div className={classes.chevron_container} onClick={handleShowOptions}>
        {anchorEl ? (
          <ArrowUpIcon width={14} height={14} strokeWidth={3} />
        ) : (
          <ArrowDownIcon width={14} height={14} strokeWidth={3} />
        )}
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseHistoryList}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ transform: 'translate(0px, 15px)' }}
      >
        <div className={classes.options_container}>
          {options.map(option => (
            <div className={classes.option} onClick={() => handleDownloadFile(option.fieldOrderType)}>
              <Download fill={theme.colors.darkBlue} />
              <Text>{option.label}</Text>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default DownloadExel;
