import React from 'react';

export const UserIcon = ({ color = '#102F3F', width = 21, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.6166 11.95C13.6416 10.7583 12.1249 10 10.4999 10C7.46659 10 5.08325 12.3833 5.08325 15.4167C5.08325 15.7417 5.19159 15.9583 5.40825 16.175C5.62492 16.3917 5.84159 16.5 6.16659 16.5H13.7499H14.8333C15.4833 16.5 15.9166 16.0667 15.9166 15.4167C15.9166 14.1167 15.3749 12.925 14.6166 11.95Z'
        fill={color}
      />
      <path
        d='M10.4999 10C8.65821 10 7.24988 8.59167 7.24988 6.75C7.24988 4.90833 8.65821 3.5 10.4999 3.5C12.3415 3.5 13.7499 4.90833 13.7499 6.75C13.7499 8.59167 12.3415 10 10.4999 10Z'
        fill={color}
      />
    </svg>
  );
};
