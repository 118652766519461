import React from 'react';
import { FormInfoListProps } from './form-info-list.props';
import { Grid } from 'common/common-components.styled';
import { FormInfoListContext } from './form-info-list.context';
import { FormInfoItemController } from './components/form-info.controller';
import useWindowSize from 'hooks/use-window-size';

export const FormInfoList = ({
  data,
  type = 'default',
  valueStyle,
  labelStyle,
  columns = 1,
  ...gridProps
}: FormInfoListProps) => {
  const filteredData = data.filter(item => !item.isHide);

  const { windowWidth } = useWindowSize(500);

  return (
    <FormInfoListContext.Provider value={{ type }}>
      <Grid gridTemplateColumns={columns} gap='0 25px' width='100%' {...gridProps}>
        {filteredData.map((dataItem, dataIndex) => (
          <FormInfoItemController
            key={dataIndex}
            isShowDivider={dataIndex < filteredData.length - columns || windowWidth <= 550}
            valueStyle={valueStyle}
            labelStyle={labelStyle}
            {...dataItem}
          />
        ))}
      </Grid>
    </FormInfoListContext.Provider>
  );
};
