import React, { cloneElement, useMemo } from 'react';
import { OfferStatusProps, OfferStatusSectionProps } from './offer-status-section.types';
import { OFFER_STATUSES } from '../../../constants';
import { CloseIcon } from '../../icons/close-icon';
import { Flex, Text } from 'common/common-components.styled';
import { Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import { ItemContainer, StyledDeclinedState } from './offer-status-section.styled';
import { ShoppingCart } from 'components/icons-new/shopping-cart';
import { CalculatorIcon } from 'components/icons-new/calculator';
import { Car } from 'components/icons-new/car';
import { PenIcon } from 'components/icons-new/pen';
import { CheckedIcon } from 'components/icons-new/checked-icon';

const OfferStatus: React.FC<OfferStatusProps> = ({ status, isFirstItem, isLastitem }) => {
  const theme = useTheme();
  const { canceled, active } = status;

  const color = canceled ? theme.colors.pink : active ? theme.colors.darkBlue : theme.colors.extraLightBlue;

  return (
    <Tooltip title={status.tooltipText}>
      <ItemContainer color={color} isFirstItem={isFirstItem} isLastItem={isLastitem}>
        {status.canceled && status.canceledIcon
          ? cloneElement(status.canceledIcon, { color: theme.colors.white })
          : cloneElement(status.icon, { color: status.active ? theme.colors.white : theme.colors.lightBlue })}
      </ItemContainer>
    </Tooltip>
  );
};

const OfferStatusSection: React.FC<OfferStatusSectionProps> = ({ status }) => {
  const theme = useTheme();

  const offerStatuses = useMemo(
    () => [
      {
        icon: <Car />,
        active: [
          OFFER_STATUSES.offerRequested,
          OFFER_STATUSES.priceRequested,
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected,
          OFFER_STATUSES.dealershipSelected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Angebot anfragen
            </Text>
            <Text size='12'>
              Fahrer bzw. Fuhrparkleiter fragt das Barpreisangebot beim Händler und das Leasingangebot bei der
              Leasinggesellschaft an.
            </Text>
          </>
        )
      },
      {
        icon: <CalculatorIcon />,
        active: [
          OFFER_STATUSES.priceRequested,
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected,
          OFFER_STATUSES.dealershipSelected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Preise kalkulieren
            </Text>
            <Text size='12' bottom='10'>
              Der zuständige Händler kalkuliert den Barpreis und übermittelt sein Angebot.
            </Text>
            <Text size='12' bottom='10'>
              Wenn der Barpreis übermittelt wurde, wird eine Leasinganfrage generiert.
            </Text>
            <Text size='12'>Wenn auch das Leasingangebot da ist, kann das Fahrzeug angefordert werden.</Text>
          </>
        )
      },
      {
        icon: <ShoppingCart />,
        active: [
          OFFER_STATUSES.requirementApproved,
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved,
          OFFER_STATUSES.approvalRejected
        ].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Fahrzeug anfordern
            </Text>
            <Text size='12' bottom='10'>
              Fahrer bzw. Fuhrparkleiter fordert ein Fahrzeug an. Es können in der Regel mehrere Konfigurationen
              erstellt werden, aber nur eine davon kann man tatsächlich anfordern.
            </Text>
            <Text size='12'>Wurde das Fahrzeug angefordert, löst Mobexo bis zu 2 weitere Vergleichsangebote aus.</Text>
          </>
        )
      },
      {
        icon: <PenIcon fill={'none'} />,
        canceledIcon: <CloseIcon />,
        active: [
          OFFER_STATUSES.vehicleRequested,
          OFFER_STATUSES.purchaseTriggered,
          OFFER_STATUSES.approvedByFleetManager,
          OFFER_STATUSES.orderApproved
        ].includes(status),
        canceled: [OFFER_STATUSES.approvalRejected].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Freigaben einholen
            </Text>
            <Text size='12'>
              Wenn die Vergleichsangebote übermittelt wurden, kann sich der Fuhrparkleiter für einen Leasinggeber
              entscheiden, die Anforderung freigeben und ggfs. weitere Freigaben einleiten.
            </Text>
          </>
        )
      },
      {
        icon: <CheckedIcon />,
        canceledIcon: <CloseIcon />,
        active: [OFFER_STATUSES.orderApproved].includes(status),
        canceled: [OFFER_STATUSES.approvalRejected].includes(status),
        tooltipText: (
          <>
            <Text size='14' bottom='10' bold>
              Fahrzeug bestellen
            </Text>
            <Text size='12' bottom='10'>
              Wenn alle Bestellfreigaben eingeholt wurden, gilt die Anforderung als freigegeben.
            </Text>
            <Text size='12' bottom='10'>
              Die signierte verbindliche Bestellung wird an der Konfiguration gespeichert. Im selben Schritt wird die
              Bestellung an das Autohaus und die Leasinggesellschaft weitergeleitet.
            </Text>
            <Text size='12'>
              Den weiteren Bestellfortschritt kann man auf der Seite <b>Bestellungen</b> einsehen.
            </Text>
          </>
        )
      }
    ],
    [status]
  );

  return (
    <>
      {status === OFFER_STATUSES.approvalRejected ? (
        <Tooltip title={'Leasinganfrage wurde abgelehnt oder archiviert (siehe Ablehnungsgrund).'}>
          <StyledDeclinedState>
            <CloseIcon color={theme.colors.white} />
            <Text left='10' top='2' size='12' color='white'>
              Vorgang abgebrochen
            </Text>
          </StyledDeclinedState>
        </Tooltip>
      ) : (
        <Flex align='center' position='relative' gap='2px'>
          {offerStatuses.map((offerStatus, index) => (
            <OfferStatus
              key={index}
              status={offerStatus}
              isFirstItem={index === 0}
              isLastitem={index + 1 === offerStatuses.length}
            />
          ))}
        </Flex>
      )}
    </>
  );
};

export default OfferStatusSection;
