import React from 'react';
import { useDispatch } from 'react-redux';
import { setVehicleDetailsMainTab } from 'actions/pages/vehicle-details.action';
import { Flex, Text } from 'common/common-components.styled';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { ReplaceText } from 'components/replace-text/replace-text';
import { useVehicleDetailsMainTabsData } from 'pages/existing-vehicle-page/components/layout/main-tabs/hooks/use-main-tabs/use-main-tabs';
import { VEHICLE_DETAIL_ELEMENTS_ID } from 'pages/existing-vehicle-page/utils';

export const ReturnLeaseTimeIsNotExistPlaceholder = () => {
  const dispatch = useDispatch();
  const { getTabIndex } = useVehicleDetailsMainTabsData();
  return (
    <Flex direction='column' align='center'>
      <EmptyDataIcon hovered />

      <Text color='blue' size='18' bold margin='20px 0 10px'>
        Nichts gefunden
      </Text>
      <ReplaceText
        id='infoHintInformationToFinanceRate'
        size='12'
        textAlign='center'
        text='Es fehlen Infos über Leasingstart und Leasingende. Bitte ergänze diese in der Finanzrate.'
        replaceWord='Finanzrate.'
        replaceComponent={() => (
          <a
            href={`#${VEHICLE_DETAIL_ELEMENTS_ID.tab.mainTab}`}
            onClick={() => dispatch(setVehicleDetailsMainTab({ activeIndex: getTabIndex('leasing') }))}
          >
            <Text display='inline' color='blue' size='12' underline>
              Finanzrate.
            </Text>
          </a>
        )}
      />
    </Flex>
  );
};
