import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { CostCentersHistoryItem } from '../components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';

export enum HistoryModalFieldNames {
  ValidFrom = 'uds_assignment_valid_from',
  ValidTo = 'uds_assignment_valid_to'
}

export type HistoryItem = {
  fuhrpakr: {
    new_name: {
      attributeValue: string;
      attributeTypeCode: number;
    };
    uds_model_composite: {
      attributeValue: string;
      attributeTypeCode: number;
    };
  };
  fuhrparkHistory: {
    createdon: {
      attributeValue: string;
      attributeTypeCode: number;
    };
    uds_assignment_valid_from: {
      attributeValue: string;
      attributeTypeCode: number;
    };
    uds_assignment_valid_to: {
      attributeValue: string;
      attributeTypeCode: number;
    };
    uds_fuhrparkhistoryid: {
      attributeValue: string;
      attributeTypeCode: number;
    };
    uds_kennzeichen: {
      attributeTypeCode: number;
      attributeValue: {
        id: string;
        logicalName: string;
        name: string;
      };
    };
    uds_kostentrager: {
      attributeTypeCode: number;
      attributeValue: {
        id: string;
        logicalName: string;
        name: string;
      };
    };
  };
};

export type VehicleHistoryListResponse = {
  count: number;
  items: HistoryItem[];
};

export interface ChangeVehicleHistoryContextProps {
  callback: () => void;
  entityName: PrimaryEntityNames | string;
  id: string;
  closeModal: () => void;
  item?: CostCentersHistoryItem;
  showInfoHint?: boolean;
  userId?: string;
  carHistoryId?: string;
  isAssign?: boolean;
  from?: string;
  to?: string;
}
