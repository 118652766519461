import { OptionSetFileErrorEnum, OptionSetFileValueItem } from '../option-set-file.props';
import { useContext, useEffect } from 'react';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';

export const useValidateOptionSetFile = () => {
  const { reactHookFormData, field } = useContext(formFieldProvider);
  const { setError, getValues, formState } = reactHookFormData;
  const { isSubmitting } = formState;

  const isValidFileOptionSet = () => {
    const optionSetFileValue = (getValues(field.name) as OptionSetFileValueItem[]) || [];
    const optionsSetError: number[] = [];
    //@ts-ignore
    optionSetFileValue.map((valueItem, index) => {
      if (valueItem.files.length && !valueItem.documentType) {
        optionsSetError.push(index);
      }
    });
    if (optionsSetError.length) {
      setError(field.name + OptionSetFileErrorEnum.Option, { type: JSON.stringify(optionsSetError) });

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (isSubmitting) {
      isValidFileOptionSet();
    }
  }, [isSubmitting]);

  return { isValidFileOptionSet };
};
