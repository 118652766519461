import React, { useContext } from 'react';
import { Text } from 'common/common-components.styled';
import { formFieldProvider } from '../../form-field';

export const Tip = () => {
  const {
    field: { tip }
  } = useContext(formFieldProvider);

  if (!tip) {
    return <></>;
  }

  return (
    <Text size='10' color='grey500' fontWeight={600} top='5'>
      {tip}
    </Text>
  );
};
