import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StyledButton } from '../../pages/details/order-widget/order-widget.styled';

class Configurator extends Component {
  constructor(props) {
    super(props);
  }

  openConfigurator = () => {
    if (
      this.props.isGlobalReferenceVehicle &&
      this.props.validationFunc &&
      !this.props.validationFunc(this.props.distance, this.props.deliveryDate)
    ) {
      return;
    }
    this.props.openConfiguratorModal(
      this.props.vehicleOfferId,
      this.props.leasingOfferId,
      this.props.distance,
      this.props.deliveryDate,
      this.props.isGlobalReferenceVehicle,
      this.props.referenceDistance,
      this.props.referenceDuration
    );
  };

  render() {
    return (
      <div className='w-100'>
        <StyledButton secondary={!this.props.isGlobalReferenceVehicle} onClick={this.openConfigurator}>
          Konfigurieren
        </StyledButton>
      </div>
    );
  }
}

Configurator.propTypes = {
  openConfiguratorModal: PropTypes.func,
  isGlobalReferenceVehicle: PropTypes.bool.isRequired,
  leasingOfferId: PropTypes.string.isRequired,
  vehicleOfferId: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string,
  distance: PropTypes.number,
  validationFunc: PropTypes.func,
  referenceDistance: PropTypes.number,
  referenceDuration: PropTypes.number
};

export default Configurator;
