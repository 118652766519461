import axios from 'axios';
import { get } from 'lodash';

import { optionset, lookup, parseTags } from '../utils/dynamics-response-parsers';
import { getSharepointLocation } from '../utils/get-sharepoint-location';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getVehicleLogoAsync(folder, cancelToken) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/logo`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      folder
    },
    cancelToken,
    responseType: 'blob',
    transformResponse: function (data) {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(data);
      return imageUrl;
    }
  });
}

export async function getVehicleDetailsAsync(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/details`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      vehicleId: id
    }
  });
}

export async function getNettoPrice(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/vehicle/nettoprice`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id
    }
  });
}

export async function getVehicleImagesAsync(folder) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/gallery`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      folder
    }
  });
}

export async function getHandlerBestellungenWigetInfo(FahrzeugauslieferungId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Bestellungen/GetHandlerBestellungenWidgetInfo`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      FahrzeugauslieferungId
    }
  });
}

export async function saveHandlerBestellungenOrderNumbers(
  FahrzeugauslieferungId64,
  InternalOrderNumber,
  ManufacturerOrderNumber,
  ManufacturerOrderDate
) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Fuhrpark/SaveHandlerBestellungenOrderNumbers`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      FahrzeugauslieferungId64,
      InternalOrderNumber,
      ManufacturerOrderNumber,
      ManufacturerOrderDate
    }
  });
}
