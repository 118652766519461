interface ConvertDateToServer {
  start?: string;
  end?: string;
}

export const formatDate = (date: Date): string => {
  return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const getPastYearDate = (): Array<string> => {
  return [
    formatDate(new Date(new Date().getFullYear() - 1, 0, 1)),
    formatDate(new Date(new Date().getFullYear() - 1, 11, 31))
  ];
};

export const getCurrentYearDate = (): Array<string> => {
  return [formatDate(new Date(new Date().getFullYear(), 0, 1)), formatDate(new Date(new Date().getFullYear(), 11, 31))];
};

export const getNextYearDate = (): Array<string> => {
  return [
    formatDate(new Date(new Date().getFullYear() + 1, 0, 1)),
    formatDate(new Date(new Date().getFullYear() + 1, 11, 31))
  ];
};

export const getData = (date: string): Array<string> => {
  if (!date) {
    return [];
  }
  if (date === 'Letztes Jahr') {
    return getPastYearDate();
  }
  if (date === 'Aktuelles Jahr') {
    return getCurrentYearDate();
  }
  if (date === 'Nächstes Jahr') {
    return getNextYearDate();
  }
  if (date === 'Kein Datum') {
    return ['', ''];
  }
  return [date.split('-')[0].trim(), date.split('-')[1].trim()];
};

export const getFormatedDate = (date: string): string => {
  return date.split('.').reverse().join('-');
};

export const convertDateToServer = (value: string, options?: ConvertDateToServer) => {
  const date = getData(value);

  return {
    [options?.start || 'startdate']: date.length > 0 ? getFormatedDate(date[0]) : null,
    [options?.end || 'enddate']: date.length > 0 ? getFormatedDate(date[1]) : null
  };
};
