import React, { memo, useEffect, useState, useRef, useContext } from 'react';
import classes from './vehicle-costs-chart.module.scss';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
  ChartData,
  ChartOptions
} from 'chart.js';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import { data as chartDataModel } from 'models/chart/dashboard/vehicle-costs.model';
import { VehicleCostsChartProps, SegmentItem } from './vehicle-costs-chart.props';
import { PercentColor, PercentSetupType } from 'components/expenses-per-year/expenses-per-year.props';
import { Position } from 'common/enums';
import { AnyObject } from 'chart.js/dist/types/basic';
import { useTheme } from 'styled-components';
import { Distance } from 'common/common-components.styled';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import toMoney from 'utils/toMoney';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { openPageVehicleCostCurrentYearProps, openPageVehicleCostLastYearProps } from '../utils';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

ChartJS.register(ArcElement, Tooltip, Legend);

export const VehicleCostsCircleChart = memo(
  ({ vehicleCostsData = defaultVehicleCostsData }: VehicleCostsChartProps) => {
    const [chartData, setChartData] = useState<ChartData<'bar', number[], string>>(chartDataModel);
    const [hoveredSegment, setHoveredSegment] = useState<ActiveElement | null>(null);
    const [choosenSegment, setChoosenSegment] = useState<ActiveElement | null>(null);
    const [activeSegment, setActiveSegment] = useState<number | null>(0);
    const chartRef = useRef<ChartJS<'doughnut', number[], string> | null>(null);
    const [segmentOffsets, setSegmentOffsets] = useState<number[]>([20, 0, 0, 0, 0]);
    const [segments, setSegments] = useState<SegmentItem[]>([]);
    const theme = useTheme();

    const currentYear = new Date().getFullYear();

    const { openPage } = useContext(FleetManagerDashboardContext);

    // @ts-ignore
    const handleRef = (instance: any) => {
      if (instance && instance.$context && instance.$context.chart) {
        chartRef.current = instance.$context.chart;
      }
    };

    const vehicleCostCurrentYear = vehicleCostsData.topKosten.topKostenByYear[currentYear];

    const colors = [
      theme.colors.lightBlue,
      theme.colors.pink,
      theme.colors.blue,
      theme.colors.green,
      theme.colors.gold
    ];

    useEffect(() => {
      if (chartRef.current) {
        const initialIndex = 0;
        const arc = chartRef.current.getDatasetMeta(0).data[initialIndex] as ArcElement;
        if (arc) {
          const initialSegment = { index: initialIndex } as ActiveElement;
          setChoosenSegment(initialSegment);
          setHoveredSegment(initialSegment);
        }
      }
    }, [chartData]);

    const activeIndex = activeSegment !== null ? activeSegment : 4;

    useEffect(() => {
      const newOffsets = [0, 0, 0, 0, 0];
      if (activeIndex != null) {
        newOffsets[activeIndex] = 20;
      }
      setSegmentOffsets(newOffsets);
    }, [activeIndex]);

    const minPercentage = 3;
    const totalSum = segments.reduce((sum, segment) => sum + segment.sum, 0);
    const percentages = segments.map(segment => (segment.sum / totalSum) * 100) || [];
    let additionalPercentages = 0;

    percentages.forEach((percentage, index) => {
      if (percentage < minPercentage) {
        additionalPercentages += minPercentage - percentage;
        percentages[index] = minPercentage;
      }
    });

    const percentPerUnit = additionalPercentages / percentages.filter(percentage => percentage > minPercentage).length;
    percentages.forEach((percentage, index) => {
      if (percentage > minPercentage) {
        percentages[index] = percentage - percentPerUnit;
      }
    });

    const data: ChartData<'doughnut', number[], string> = {
      labels: segments?.map((item: SegmentItem) => item.label) || [],
      datasets: [
        {
          data: percentages,
          borderColor: (context: AnyObject) => {
            const segmentIndex = context.dataIndex;
            const activeIndex = activeSegment !== null ? activeSegment : 4;
            return segmentIndex === activeIndex
              ? context.dataset.hoverBackgroundColor[segmentIndex]
              : theme.colors.darkBlue;
          },
          offset: segmentOffsets,
          hoverBackgroundColor: colors,
          hoverBorderColor: colors,
          borderWidth: 0,
          backgroundColor: colors,
          borderJoinStyle: 'round',
          spacing: 0
        }
      ]
    };

    const options: ChartOptions<'doughnut'> = {
      cutout: '76%', // option for margin inside the circle,
      elements: {
        arc: {
          borderColor: (context: AnyObject) => {
            return context.dataset.hoverBackgroundColor[context.index];
          },
          borderJoinStyle: 'miter'
        }
      },
      radius: 110,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        }
      },
      onClick: (event: ChartEvent, segments: ActiveElement[]) => {
        if (segments.length > 0) {
          const segmentIndex = segments[0].index;
          setActiveSegment(segmentIndex);
          const newOffsets = [0, 0, 0, 0, 0];
          newOffsets[segmentIndex] = 20;
          setSegmentOffsets(newOffsets);
        }
      },
      onHover: (event: ChartEvent, elements: ActiveElement[]) => {
        const canvas = event.native && (event.native.target as HTMLCanvasElement);
        if (canvas && elements.length > 0) {
          const newSegment = elements[0] as ActiveElement;
          if (!hoveredSegment || newSegment.index !== hoveredSegment.index) {
            setHoveredSegment(newSegment);
            const newOffsets = [0, 0, 0, 0, 0];
            newOffsets[newSegment.index] = 20;
            setSegmentOffsets(newOffsets);
          }
          canvas.style.cursor = 'pointer';
        } else if (canvas) {
          canvas.style.cursor = 'default';
          if (activeSegment !== null && hoveredSegment?.index !== activeSegment) {
            setHoveredSegment(activeSegment !== null ? ({ index: activeSegment } as ActiveElement) : null);
          }
          const newOffsets = [0, 0, 0, 0, 0];
          if (activeSegment !== null) {
            newOffsets[activeSegment] = 20;
          }
          if (JSON.stringify(segmentOffsets) !== JSON.stringify(newOffsets)) {
            setSegmentOffsets(newOffsets);
          }
        }
      }
    };

    const percentageFromLastYearToCurrent = vehicleCostsData.topKosten.percent || 0;
    const percentSetup: PercentSetupType = {
      percent: Math.abs(percentageFromLastYearToCurrent),
      color: percentageFromLastYearToCurrent > 0 ? PercentColor.Green : PercentColor.Red,
      arrowDirection: percentageFromLastYearToCurrent > 0 ? Position.Bottom : Position.Top
    };

    const getCostsSum = (value: string | number) => {
      if (typeof value === 'string') {
        return value;
      }
      return toMoney(value);
    };

    useEffect(() => {
      setChartData(prevState => ({
        ...prevState,
        datasets: prevState.datasets.map(dataset => {
          const kostenByYear = vehicleCostCurrentYear || [];
          dataset.data = kostenByYear.map(item => item.kostenSum);
          return dataset;
        })
      }));
      if (currentYear && vehicleCostsData?.topKosten?.topKostenByYear[currentYear]) {
        const hoverTextBlocks = vehicleCostsData?.topKosten?.topKostenByYear[currentYear].map((item, index) => ({
          color: colors[index % colors.length],
          label: item.kostenName,
          sum: item.kostenSum
        }));
        setSegments(hoverTextBlocks);
      }
    }, [currentYear, vehicleCostsData.topKosten.topKostenByYear]);

    return (
      <div className={classes.container}>
        <div className={classes.top_container}>
          <div className={classes.left}>
            <div>
              <ExpensesPerYear
                name='Gesamtkosten'
                year={currentYear}
                price={vehicleCostsData?.topKosten?.sumByYear[currentYear] || 0}
                percentSetup={vehicleCostsData?.topKosten?.percent ? percentSetup : undefined}
                tooltipText={`Nettosumme aller Kosten mit Rechnungsdatum innerhalb ${currentYear}`}
                openPage={openPage(openPageVehicleCostCurrentYearProps())}
              />
              <Distance top='40' />
              <ExpensesPerYear
                name='Gesamtkosten'
                year={currentYear - 1}
                price={vehicleCostsData.topKosten.sumByYear[currentYear - 1]}
                tooltipText={`Nettosumme aller Kosten mit Rechnungsdatum innerhalb ${currentYear}`}
                openPage={openPage(openPageVehicleCostLastYearProps())}
              />
            </div>
          </div>

          <div className={classes.canvas_block}>
            {segments && Array.isArray(segments) && segments.length > 0 && (
              <Doughnut ref={handleRef} data={data} options={options} />
            )}
            {vehicleCostCurrentYear && vehicleCostCurrentYear?.length === 0 && segments?.length === 0 && (
              <div className={classes.empty_data}>
                <EmptyDataIcon hovered />
                <span>Es gibt keine Daten zu den 5 teuersten Kosten</span>
              </div>
            )}
            {choosenSegment && hoveredSegment && segments?.length > 0 && vehicleCostCurrentYear?.length > 0 && (
              <div className={classes.text_block}>
                {segments?.map(
                  (item: SegmentItem, index: number) =>
                    hoveredSegment?.index === index && (
                      <>
                        <EllipsisWithTooltip tooltip={getCostsSum(vehicleCostCurrentYear[index]?.kostenSum)}>
                          <span>{getCostsSum(vehicleCostCurrentYear[index]?.kostenSum)}</span>
                        </EllipsisWithTooltip>
                        <div className={classes.bottom_container_item}>
                          <span style={{ backgroundColor: item.color }} />
                          <p>{item.label}</p>
                        </div>
                      </>
                    )
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.bottom_container}>
          {segments &&
            segments.length > 0 &&
            segments?.map((item: SegmentItem) => (
              <div className={classes.bottom_container_item} key={item.label}>
                <span style={{ backgroundColor: item.color }} />
                <p>{item.label}</p>
              </div>
            ))}
        </div>
      </div>
    );
  }
);

const defaultVehicleCostsData = {
  monthsKosten: {
    averageSumByYear: {},
    percent: 0,
    sumByMonthByYear: {},
    sumByYear: {}
  },
  topKosten: {
    percent: 0,
    sumByYear: {},
    topKostenByYear: {}
  }
};
