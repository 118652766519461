import React, { cloneElement, MouseEvent, useContext } from 'react';
import classes from '../cell.module.scss';
import More, { MoreMenuItem } from 'components/more';
import { CellProps, ICellContext, MenuItemType } from 'components/table-component/table-component.props';
import classNames from 'classnames';
import { CellContext } from 'components/table-component/table-component-contexts';

export const MenuCell = ({ column, row }: Pick<CellProps, 'column' | 'row'>) => {
  const { cellId } = useContext<ICellContext>(CellContext);

  const onClickHandler = (menuItem: MenuItemType) => {
    menuItem.action(row[menuItem.actionParameter]);
  };

  return (
    <div
      id={cellId}
      style={{ width: column.width }}
      className={classNames(classes.table_menu, column.className, classes.cell)}
    >
      {column.component ? (
        cloneElement(column.component, { row, column })
      ) : (
        <More>
          {column.menuItems?.map(menuItem => (
            <MoreMenuItem
              key={menuItem.label}
              label={menuItem.label}
              img={menuItem.icon}
              onClick={() => onClickHandler(menuItem)}
            />
          ))}
        </More>
      )}
    </div>
  );
};
