/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import ZipcodeFieldComponent from '../components/zipcode-field-component';
import useWindowSize from 'hooks/use-window-size';
import { StepProps, StepStylesProps } from '../report-damage.props';

const ChooseMartenDamage = ({ reactHookFormData, handleNext }: StepProps) => {
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const { windowWidth } = useWindowSize();
  const { watch, clearErrors, setValue, setError, handleSubmit } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    autoIsNotOkay &&
    !!watch('detailDamageComment') &&
    watch('detailDamageComment').length > 0
      ? setButtonActive(true)
      : setButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  const autoIsNotOkay =
    (watch('autoIsOkay') === false &&
      !!watch('autoIsNotOkayFirma') &&
      watch('autoIsNotOkayFirma').length > 0 &&
      !!watch('autoIsNotOkayTelephone') &&
      watch('autoIsNotOkayTelephone').length > 0 &&
      !!watch('autoIsNotOkayPLZ') &&
      watch('autoIsNotOkayPLZ').length > 4 &&
      !!watch('autoIsNotOkayStadt') &&
      watch('autoIsNotOkayStadt').length > 0 &&
      !!watch('autoIsNotOkayStreet') &&
      watch('autoIsNotOkayStreet').length > 0) ||
    !!watch('autoIsOkay');

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      checkButtons(['damagePrivatOrBusinessTrip', 'autoIsOkay']);
    }
  };

  useEffect(() => {
    if (watch('autoIsOkay') === true) {
      clearErrors([
        'autoIsNotOkayAdress',
        'autoIsNotOkayFirma',
        'autoIsNotOkayTelephone',
        'autoIsNotOkayPLZ',
        'autoIsNotOkayStadt',
        'autoIsNotOkayStreet'
      ]);
    }
  }, [watch('autoIsOkay')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        {watch('autoIsOkay') === false && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockAutoIsOk} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Wo befindet sich das Fahrzeug aktuell?</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayFirma'
                  fieldName='Firma/Name'
                  fieldPlaceholder='Muster GmbH'
                />

                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayTelephone'
                  fieldName='Telefon'
                />

                <Distance top='5' />

                <div className={styles.row_columns_inputs}>
                  <ZipcodeFieldComponent
                    reactHookFormData={reactHookFormData}
                    smallField={windowWidth > 900 && true}
                    fieldRegister='autoIsNotOkayPLZ'
                    fieldName='Postleitzahl'
                  />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='autoIsNotOkayStadt'
                    fieldName='Stadt'
                    fieldPlaceholder='Muster Stadt'
                    doubleField={windowWidth > 900 && true}
                  />
                </div>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayStreet'
                  fieldName='Straße, Hausnummer'
                  fieldPlaceholder='Musterstr. 100'
                />
                <Distance top='30' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Brauchst du einen Mietwagen?'
          fieldRegister='needRent'
          fieldRegisterSecond='needRent'
          optional={true}
        />

        <Distance top='20' />

        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='detailDamageComment'
          fieldName='Ausführliche Schadenschilderung'
          fieldPlaceholder='Bitte schreib, wie der Schaden aufgetreten ist.'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte fertige die Schilderung ausführlich an. Aus deiner Beschreibung muss klar hervorgehen, wer den Schaden verschuldet hat oder ob du ggf. eine Mitschuld tragen musst oder der Unfall für dich - und aus welchen Gründen? - unabwendbar war. Bitte beachte, dass du zur wahrheitsgemäßen Unfallschilderung verpflichtet bist, da ansonsten der Versicherungsschutz erlischt. '
        />

        <Distance top='15' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnToTypeButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ChooseMartenDamage;
