import React from 'react';
import { useDispatch } from 'react-redux';
import { Text } from 'common/common-components.styled';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS, RequestStatuses } from '../../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';

const useChangeVehicleTypeModal = () => {
  const dispatch = useDispatch();
  const { fetch } = useFetch({
    loadingKey: 'cancelFutureBookings'
  });

  const openChangeVehicleTypeModal = (id: string, updateCallback: () => void) => {
    const alertData = {
      title: 'Zuordnung ändern',
      children: (
        <>
          <Text bottom='15' color='grey900'>
            Dieses Poolfahrzeug hat zukunftige Buchungen.
          </Text>
          <Text color='grey900'>Wenn Du die Zuordnung änderst, werden die Buchungen automatisch storniert.</Text>
        </>
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        },
        {
          type: 'submit',
          title: 'Zuordnung speichern',
          action: async () => {
            closeModal();
            const response = await fetch({ requestConfig: poolCalendarRequestConfig.cancelFutureBookings(id) });
            if (response.data?.level === RequestStatuses.Success) {
              updateCallback();
            }
          }
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return { openChangeVehicleTypeModal };
};

export default useChangeVehicleTypeModal;
