import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { UnusedFleetQueryKeys } from '../fleet.props';
import { ResponseModel } from 'common/interfaces';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useDescriptionChoicesQuery = ({
  options
}: QueryHookOptions<AxiosResponse<ResponseModel<string[]>>, ResponseModel<string[]>> = {}) => {
  const { fetch } = useFetch<ResponseModel<string[]>>({
    loadingKey: 'descriptionChoices',
    ...fleetRequestConfig.descriptionChoices
  });

  const { data, ...restQueryProps } = useQuery(UnusedFleetQueryKeys.DescriptionChoices, () => fetch(), options);

  return {
    descriptionChoicesQuery: data?.data?.data || [],
    ...restQueryProps
  };
};
