import React, { cloneElement } from 'react';
import { FormInfoItemDefaultProps } from '../default.props';
import { Flex, Text } from 'common/common-components.styled';
import { StyledLabelTooltip } from '../default.styled';
import { useHighlightedFormFields } from 'hooks/use-highlighted-form-fields';
import useWindowSize from 'hooks/use-window-size';

export const FormInfoItemDefaultLabel = ({ label, labelIcon, labelStyle, value }: FormInfoItemDefaultProps) => {
  const { highlightedColorTheme, highlightedColorString } = useHighlightedFormFields(label, value);

  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  if (labelIcon) {
    return (
      <StyledLabelTooltip title={label}>
        <Flex align='center'>{cloneElement(labelIcon, { width: 20, height: 20, color: highlightedColorTheme })}</Flex>
      </StyledLabelTooltip>
    );
  }

  return (
    <Text
      color={highlightedColorString || 'grey500'}
      size={isMobile ? '14' : '16'}
      width='35%'
      wordBreak='break-word'
      {...labelStyle}
    >
      {label}
    </Text>
  );
};
