import React, { LegacyRef, cloneElement, forwardRef } from 'react';
import classes from './flat-list.module.scss';
import { IFlatListItem, IFlatList, FlatListElement } from './flat-list.props';
import { FixedSizeList as List, ListProps } from 'react-window';
import classNames from 'classnames';
import { ValueOf } from 'common/interfaces';

export const FlatList = forwardRef<FlatListElement, IFlatList>((props, ref) => {
  const { data, renderItem, itemSize = 22, width = '100%', height, hideScrollBar = true, onScroll } = props;

  return (
    <List
      itemCount={data.length}
      itemSize={itemSize}
      height={height}
      width={width}
      className={classNames({ [classes.no_scroll_bar]: hideScrollBar })}
      onScroll={onScroll as ValueOf<Pick<ListProps, 'onScroll'>>}
      ref={ref as LegacyRef<List>}
      useIsScrolling
    >
      {props => cloneElement<IFlatListItem>(renderItem, { ...props, row: data[props.index] })}
    </List>
  );
});
