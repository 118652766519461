import { connect } from 'react-redux';
import { toggleModal } from '../../actions/app_action';
import { MODALS, MODAL_NAMES } from '../../constants';
import Configurator from './configurator-view';

const mapDispatchToProps = dispatch => ({
  openConfiguratorModal(
    vehicleOfferId,
    leasingOfferId,
    distance,
    deliveryDate,
    isGlobalReferenceVehicle,
    referenceDistance,
    referenceDuration
  ) {
    dispatch(
      toggleModal(MODALS.general_configurator, MODAL_NAMES.configurator, {
        vehicleOfferId,
        leasingOfferId,
        distance,
        deliveryDate,
        isGlobalReferenceVehicle,
        referenceDistance,
        referenceDuration
      })
    );
  }
});

export default connect(null, mapDispatchToProps)(Configurator);
