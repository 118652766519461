import { Position } from 'common/enums';
import { mainTheme } from 'common/global-styles';
import toMoney from 'utils/toMoney';

export const options = {
  plugins: {
    legend: { display: false },
    tooltip: {
      yAlign: Position.Bottom as const,
      xAlign: Position.Center as const,
      callbacks: {
        label: (context: any) => `${toMoney(context.parsed.y)}`,
        title: () => ''
      }
    }
  },
  scales: {
    y: {
      ticks: {
        color: [mainTheme.colors.grey300],
        padding: 10
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};

export const data = {
  labels: [''],
  datasets: [
    {
      label: '',
      data: [0],
      backgroundColor: [mainTheme.colors.darkGold, mainTheme.colors.midBlue, mainTheme.colors.darkBlue],
      hoverBackgroundColor: [mainTheme.colors.darkGold, mainTheme.colors.midBlue, mainTheme.colors.darkBlue],
      barThickness: 15
    }
  ]
};
