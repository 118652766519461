import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';

const modifyResponse = ({ data }: ApiResponse<boolean>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data;
  }
  return undefined;
};

const useIsFutureBookingExist = (carId: string) => {
  const { fetch } = useFetch<ResponseModel<boolean>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...poolCalendarRequestConfig.isFutureBookingExist(carId)
  });

  const { data: isFutureBookingExist, refetch: isFutureBookingExistRefetch } = useQuery<
    ApiResponse<boolean>,
    unknown,
    boolean | undefined
  >([ReactQueryKeys.IsFutureBookingExist, carId], () => fetch(), {
    enabled: !!carId,
    select: modifyResponse
  });

  return { isFutureBookingExist, isFutureBookingExistRefetch };
};

export default useIsFutureBookingExist;
