import React from 'react';
import classes from './file-viewer-controller.module.scss';
import { FileViewerControllerProps } from './file-viewer-controller.interface';
import { FileFormatTypes } from 'common/enums';
import FileViewer from 'react-file-viewer';
import PdfViewer from 'components/pdf-viewer/pdf-viewer';
import Spinner from 'components/spinner';

export const FileViewerController = (props: FileViewerControllerProps) => {
  const { type, objectURL } = props;

  if (!objectURL) {
    return (
      <Spinner
        style={{
          width: '100px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    );
  }

  switch (type) {
    case FileFormatTypes.PDF:
      return (
        <div className={classes.pdf_container}>
          {props.blob && <PdfViewer file={props.blob} scale={props.zoomScale} />}
        </div>
      );
    default:
      return (
        <FileViewer
          fileType={type === FileFormatTypes.HEIC ? FileFormatTypes.PNG : type}
          filePath={props.objectURL}
          onError={(err: any) => console.log(err, 'error in file-viewer')}
        />
      );
  }
};
