import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledEmptyPlaceholderImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const StyledCameraIconContainer = styled(Flex)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  cursor: pointer;
  padding-right: 0.5px;
`;
