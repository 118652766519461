import React from 'react';
import { StyledVehicleCostWidgetViewContainer } from '../../vehicle-costs.styled';
import { CostByYearHeader } from './components/header/header';
import { CostByYearBar } from './components/bar/bar';

export const CostByYearView = () => {
  return (
    <StyledVehicleCostWidgetViewContainer>
      <CostByYearHeader />
      <CostByYearBar />
    </StyledVehicleCostWidgetViewContainer>
  );
};
