import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const InfoHintIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'help' }}
    >
      {hovered && <circle cx='9.54822' cy='10' r='9' fill={fill} />}
      <path
        d='M12.366 18.5501C11.4796 18.842 10.5324 19 9.54822 19C4.57766 19 0.548218 14.9706 0.548218 10C0.548218 5.02944 4.57766 1 9.54822 1C14.5188 1 18.5482 5.02944 18.5482 10C18.5482 12.6738 17.3822 15.0753 15.531 16.7237'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.08215 8.4032H9.82409V13.629M8.08215 13.629H11.566'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.54827 7.17917C10.0039 7.17917 10.3733 6.80981 10.3733 6.35417C10.3733 5.89854 10.0039 5.52917 9.54827 5.52917C9.09263 5.52917 8.72327 5.89854 8.72327 6.35417C8.72327 6.80981 9.09263 7.17917 9.54827 7.17917Z'
        fill={color}
      />
    </svg>
  );
};
