/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TrashIcon from '../icons/trash-icon';
import useWindowSize from 'hooks/use-window-size';
import { StepProps, StepStylesProps } from '../report-damage.props';

const ParkingStepFour = ({ reactHookFormData, handleNext, handleBack }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const [showBlockNeedPolice, setShowBlockNeedPolice] = useState<boolean>(false);
  const [showBlockNeedPoliceGetCaseNumber, setShowBlockNeedPoliceGetCaseNumber] = useState<boolean>(false);
  const [showBlockNeedPoliceCriminalWarning, setShowBlockNeedPoliceCriminalWarning] = useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDriverWasTested, setShowBlockNeedPoliceDrunkDriverWasTested] =
    useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDrugsTest, setShowBlockNeedPoliceDrunkDrugTest] = useState<boolean>(false);
  const {
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
  }, []);

  const needPolice =
    (!!watch('needPolice') &&
      !!watch('needPoliceDienststelle') &&
      watch('needPoliceDienststelle').length > 0 &&
      watch('needPoliceGetCaseNumber') !== undefined &&
      watch('needPoliceCriminalWarning') !== undefined &&
      watch('needPoliceComplaintFiled') !== undefined &&
      watch('needPoliceDrunkDriverWasTested') !== undefined &&
      watch('needPoliceDrunkDrugsTest') !== undefined) ||
    watch('needPolice') === false;

  const needPoliceCaseNumber =
    (!!watch('needPolice') &&
      (watch('needPoliceGetCaseNumber') === false ||
        (!!watch('needPoliceGetCaseNumber') &&
          !!watch('needPoliceGetCaseNumberAktenzeichen') &&
          watch('needPoliceGetCaseNumberAktenzeichen').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceCriminalWarning =
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') &&
          !!watch('needPoliceCriminalWarningWho') &&
          watch('needPoliceCriminalWarningWho').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceDrunkDriverWasTested =
    watch('needPoliceDrunkDriverWasTested') === false ||
    (!!watch('needPoliceDrunkDriverWasTested') &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonName') &&
      watch('needPoliceCriminalWarningWhoFirstPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel').length > 0);

  const needPoliceDrunkDriverWasTestedSecondPerson =
    !watch('showSecondDrunkPerson') ||
    (!!watch('showSecondDrunkPerson') &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonName') &&
      watch('needPoliceCriminalWarningWhoSecondPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel').length > 0);

  const needPoliceDrunkDrugsTest =
    watch('needPoliceDrunkDrugsTest') === false ||
    (!!watch('needPoliceDrunkDrugsTest') &&
      !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') &&
      watch('needPoliceDrunkDrugsTestWhoWasTestedFirst').length > 0 &&
      watch('needPoliceDrunkDrugsTestResultFirst') !== undefined &&
      (!watch('showSecondDrugPerson') ||
        (!!watch('showSecondDrugPerson') &&
          !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') &&
          watch('needPoliceDrunkDrugsTestWhoWasTestedSecond').length > 0 &&
          watch('needPoliceDrunkDrugsTestResultSecond') !== undefined)));

  const needPoliceDrunkDriver =
    watch('needPolice') === false ||
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      watch('needPoliceDrunkDriverWasTested') === false &&
      watch('needPoliceDrunkDrugsTest') === false) ||
    ((watch('needPoliceCriminalWarning') === false ||
      (watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      needPoliceDrunkDriverWasTested &&
      needPoliceDrunkDriverWasTestedSecondPerson &&
      needPoliceDrunkDrugsTest);

  const autoIsNotOkay =
    (watch('autoIsOkay') === false &&
      !!watch('autoIsNotOkayFirma') &&
      !!watch('autoIsNotOkayAdress') &&
      !!watch('autoIsNotOkayTelephone')) ||
    !!watch('autoIsOkay');

  useEffect(() => {
    watch('damageSize') !== undefined &&
    watch('autoIsOkay') !== undefined &&
    watch('needPolice') !== undefined &&
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    autoIsNotOkay &&
    needPolice &&
    needPoliceCaseNumber &&
    needPoliceCriminalWarning &&
    needPoliceDrunkDriver &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    setTimeout(() => (!!watch('needPolice') ? setShowBlockNeedPolice(true) : setShowBlockNeedPolice(false)), 100);
  }, [watch('needPolice')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceGetCaseNumber')
          ? setShowBlockNeedPoliceGetCaseNumber(true)
          : setShowBlockNeedPoliceGetCaseNumber(false),
      100
    );
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceCriminalWarning')
          ? setShowBlockNeedPoliceCriminalWarning(true)
          : setShowBlockNeedPoliceCriminalWarning(false),
      100
    );
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceDrunkDriverWasTested')
          ? setShowBlockNeedPoliceDrunkDriverWasTested(true)
          : setShowBlockNeedPoliceDrunkDriverWasTested(false),
      100
    );
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceDrunkDrugsTest')
          ? setShowBlockNeedPoliceDrunkDrugTest(true)
          : setShowBlockNeedPoliceDrunkDrugTest(false),
      100
    );
  }, [watch('needPoliceDrunkDrugsTest')]);

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  let slideClassName: StepStylesProps = watch('previousStep') === 3 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 4), 500);
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      checkButtons(['damagePrivatOrBusinessTrip', 'autoIsOkay', 'needPolice']);
      !!watch('needPolice') &&
        checkButtons([
          'needPoliceGetCaseNumber',
          'needPoliceCriminalWarning',
          'needPoliceComplaintFiled',
          'needPoliceDrunkDriverWasTested',
          'needPoliceDrunkDrugsTest'
        ]);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        checkButtons(['needPoliceDrunkDrugsTestResultFirst']);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        !!watch('showSecondDrugPerson') &&
        checkButtons(['needPoliceDrunkDrugsTestResultSecond']);
    }
  };

  const moveToPreviousStep = () => {
    if (handleBack) {
      setValue('previousStep', 4);
      setShowBlock(false);
      setTimeout(() => {
        handleBack();
      }, 500);
    }
  };

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') === false) {
      clearErrors('needPoliceGetCaseNumberAktenzeichen');
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') === false) {
      clearErrors('needPoliceCriminalWarningWho');
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') === false) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') === false) {
      clearErrors('needPoliceDrunkDrugsTestWhoWasTestedFirst');
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('needPolice') === false) {
      clearErrors([
        'needPoliceDienststelle',
        'needPoliceCriminalWarningWho',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDienststelle',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond',
        'needPoliceGetCaseNumberAktenzeichen',
        'needPoliceAdressAndOffice',
        'needPoliceComplaintFiled',
        'needPoliceCriminalWarning',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTest',
        'needPoliceGetCaseNumber'
      ]);
    }
  }, [watch('needPolice')]);

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') !== undefined) {
      clearErrors(['needPoliceGetCaseNumberAktenzeichen', 'needPoliceGetCaseNumber']);
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') !== undefined) {
      clearErrors(['needPoliceCriminalWarningWho', 'needPoliceCriminalWarning']);
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') !== undefined) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') !== undefined) {
      clearErrors([
        'needPoliceDrunkDrugsTest',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('autoIsOkay') === true) {
      clearErrors(['autoIsNotOkayAdress', 'autoIsNotOkayFirma', 'autoIsNotOkayTelephone']);
    }
  }, [watch('autoIsOkay')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 4 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />
        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        {watch('autoIsOkay') === false && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockAutoIsOk} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Wo kann das Fahrzeug besichtigt werden?</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayFirma'
                  fieldName='Firma/Name'
                  fieldPlaceholder='Muster GmbH'
                />

                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                />

                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayTelephone'
                  fieldName='Telefon'
                />

                <Distance top='30' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Brauchst du einen Mietwagen?'
          fieldRegister='needRent'
          fieldRegisterSecond='needRent'
          optional={true}
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Wurde ein Polizeibericht aufgenommen?'
          fieldRegister='needPolice'
          fieldRegisterSecond='needPolice'
        />

        <Distance top='20' />

        {watch('needPolice') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockNeedPolice} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Details zur polizeilichen Unfallaufnahme:</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceDienststelle'
                  fieldName='Dienststelle'
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceAdressAndOffice'
                  fieldName='Anschrift und Telefonnummer der Dienststelle'
                  fieldPlaceholder='Musterstr. 100, 12345 Berlin, Tel.'
                  optional={true}
                />

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Hast du ein Aktenzeichen erhalten?'
                  fieldRegister='needPoliceGetCaseNumber'
                  fieldRegisterSecond='needPoliceGetCaseNumber'
                />
                <Distance top='10' />

                {!!watch('needPoliceGetCaseNumber') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceGetCaseNumber}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <Distance top='10' />
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceGetCaseNumberAktenzeichen'
                          fieldName='Aktenzeichen/Tagebuchnummer'
                        />
                        <Distance top='10' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <Distance top={!!watch('needPoliceGetCaseNumber') ? '10' : '20'} />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde eine gebührenpflichtige Verwarnung ausgesprochen?'
                  fieldRegister='needPoliceCriminalWarning'
                  fieldRegisterSecond='needPoliceCriminalWarning'
                />
                <Distance top='20' />

                {!!watch('needPoliceCriminalWarning') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceCriminalWarning}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWho'
                          fieldName='Wer hat die gebührenpflichtige Verwarnung erhalten?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde Anzeige erstattet?'
                  fieldRegister='needPoliceComplaintFiled'
                  fieldRegisterSecond='needPoliceComplaintFiled'
                />
                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Alkoholtest durchgeführt?'
                  fieldRegister='needPoliceDrunkDriverWasTested'
                  fieldRegisterSecond='needPoliceDrunkDriverWasTested'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDriverWasTested') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceDrunkDriverWasTested}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonName'
                          fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonDrunkLevel'
                          fieldName='Wert Alkoholtest'
                          fieldPlaceholder='z.B. 1,1 Promille'
                        />

                        <div
                          style={{ width: '100%' }}
                          className={watch('showSecondDrunkPerson') ? '' : styles.hide__block}
                        >
                          <div>
                            <Distance top='20' />

                            <div className={styles.close_header}>
                              <h6>Weitere Person:</h6>
                              <div
                                id='removeAnotherPersonButton'
                                className={styles.close_block}
                                onClick={() => {
                                  setValue('showSecondDrunkPerson', false);
                                  clearErrors([
                                    'needPoliceCriminalWarningWhoSecondPersonName',
                                    'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                                  ]);
                                }}
                              >
                                <TrashIcon />
                              </div>
                            </div>

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonName'
                              fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                              fieldPlaceholder='Max Mustermann'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                            <Distance top='5' />

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                              fieldName='Wert Alkoholtest'
                              fieldPlaceholder='z.B. 1,1 Promille'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                          </div>
                        </div>

                        <div
                          style={{ width: '100%' }}
                          onClick={() => setValue('showSecondDrunkPerson', true)}
                          className={watch('showSecondDrunkPerson') ? styles.hide__block : styles.hide__block__show}
                        >
                          <Distance top='-5' />+ Weitere Person
                        </div>
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Drogentest durchgeführt?'
                  fieldRegister='needPoliceDrunkDrugsTest'
                  fieldRegisterSecond='needPoliceDrunkDrugsTest'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDrugsTest') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceDrunkDrugsTest}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedFirst'
                          fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='5' />

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Wert Drogentest'
                          fieldRegister='needPoliceDrunkDrugsTestResultFirst'
                          fieldRegisterSecond='needPoliceDrunkDrugsTestResultFirst'
                          firstButtonText='Positiv'
                          firstButtonValue='Positiv'
                          secondButtonText='Negativ'
                          secondButtonValue='Negativ'
                        />

                        <div
                          style={{ width: '100%' }}
                          className={watch('showSecondDrugPerson') ? '' : styles.hide__block}
                        >
                          <div>
                            <Distance top='20' />

                            <div className={styles.close_header}>
                              <h6>Weitere Person:</h6>
                              <div
                                id='anotherPersonButton'
                                className={styles.close_block}
                                onClick={() => {
                                  setValue('showSecondDrugPerson', false);
                                  clearErrors([
                                    'needPoliceDrunkDrugsTestWhoWasTestedSecond',
                                    'needPoliceDrunkDrugsTestResultSecond'
                                  ]);
                                }}
                              >
                                <TrashIcon />
                              </div>
                            </div>

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedSecond'
                              fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                              fieldPlaceholder='Max Mustermann'
                              optional={!watch('showSecondDrugPerson')}
                              optionalText=''
                            />
                            <Distance top='5' />

                            <TwoButtonsComponent
                              reactHookFormData={reactHookFormData}
                              fieldName='Wert Drogentest'
                              fieldRegister='needPoliceDrunkDrugsTestResultSecond'
                              fieldRegisterSecond='needPoliceDrunkDrugsTestResultSecond'
                              firstButtonText='Positiv'
                              firstButtonValue='Positiv'
                              secondButtonText='Negativ'
                              secondButtonValue='Negativ'
                              optional={!watch('showSecondDrugPerson')}
                              optionalText=''
                            />
                          </div>
                        </div>

                        <div
                          id='showSecondDrugPersonButton'
                          style={{ width: '100%' }}
                          onClick={() => setValue('showSecondDrugPerson', true)}
                          className={watch('showSecondDrugPerson') ? styles.hide__block : styles.hide__block__show}
                        >
                          <Distance top='-5' />+ Weitere Person
                        </div>
                        <Distance top='30' />
                      </div>
                    </CSSTransition>
                  </div>
                )}
              </div>
            </CSSTransition>
          </div>
        )}

        <Distance top={watch('needPolice') === true ? '10' : '0'} />

        {/* <Distance top='20' /> */}
        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ParkingStepFour;
