import React, { useEffect, useState } from 'react';
import useSize from '@react-hook/size';
import { tooltipProps } from './utils';
import { StyledHorizontalLineTooltipHoverBlock } from './horizontal-line-plugin-tooltip.styled';
import { HorizontalLinePluginTooltipProps } from './horizontal-line-plugin-tooltip.props';
import { BarPluginId } from '../../hooks/plugins/plugin.enum';
import { TooltipComponent } from 'components/tooltip-component/tooltip-component';

export const HorizontalLinePluginTooltip = ({
  barRef,
  diagramPlugins = [],
  labelCallback
}: HorizontalLinePluginTooltipProps) => {
  const [top, setTop] = useState(0);
  const [_, chartHeight] = useSize(barRef.current?.canvas || null);

  const horizontalLinePlugin = diagramPlugins.find(item => item.plugin.id === BarPluginId.HorizontalLine);

  useEffect(() => {
    if (horizontalLinePlugin) {
      setTimeout(() => setTop(barRef.current?.scales?.y?.getPixelForValue(horizontalLinePlugin.value) || 0), 200);
    }
  }, [chartHeight]);

  const getTooltipText = () => {
    if (!horizontalLinePlugin) {
      return '';
    }

    if (labelCallback) {
      return labelCallback(horizontalLinePlugin.value.toString());
    }

    return horizontalLinePlugin.value.toLocaleString('de-DE');
  };

  if (!horizontalLinePlugin) {
    return null;
  }

  return (
    <TooltipComponent title={getTooltipText()} arrow componentsProps={tooltipProps}>
      <StyledHorizontalLineTooltipHoverBlock positionTop={`${top - 5}px`} />
    </TooltipComponent>
  );
};
