import { Text } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledTabItemFrame = styled(Text)`
  text-align: ${({ textAlign }) => textAlign || 'center'};
  width: ${({ width }) => width || '100%'};
  padding: ${({ padding }) => padding || '10px'};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: max-content;
  } ;
`;
