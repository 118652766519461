import React from 'react';
import PropTypes from 'prop-types';

import { withPageContainer } from '../../hoc/withPageContainer';
import classes from './side-menu-right-container.module.scss';

const SideMenuRightContainer = props => {
  const { children, containerClassName, className, ...rest } = props;
  return (
    <div className={`${classes.container} ${containerClassName} ${className}`} {...rest}>
      {children}
    </div>
  );
};

SideMenuRightContainer.propTypes = {
  containerClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any
};

export default withPageContainer(SideMenuRightContainer);
