export interface TableData {
  contactStatus?: ContactStatusEnum;
  contactid?: string;
  control?: string;
  daysSinceLastCheck?: number;
  firstname?: string;
  fullname?: string;
  emailaddress1?: string;
  incompleteFieldsPercent?: { percent: number; incompleteCount: number };
  lastname?: string;
  new_manager_driverscheckeskalation?: boolean;
  new_gueltigkeitsstatus_driverscheckneu?: string;
  new_gueltigkeitsstatus_driverscheckneu_value?: number;
  new_siegelnummer?: string;
  parentcustomerid?: string;
  parentcustomerid_id?: string;
  uds_fuhrerscheinnummer?: string;
  uds_isdrivercheckneeded?: boolean;
  uds_fuhrerscheinprufung?: string;
  uds_kontrolleur?: boolean;
  statecode?: StateCodeLabelEnum;
  statecode_value?: StateCodeValueEnum;
}

export enum StateCodeLabelEnum {
  Active = 'Aktiv',
  Inacive = 'Inaktiv'
}

export enum StateCodeValueEnum {
  Active = 0,
  Inacive = 1
}

export enum ContactStatusEnum {
  Active = 'Aktiv',
  Invited = 'Eingeladen',
  ActiveUser = 'aktiver Nutzer',
  Inactive = 'Inaktiv',
  InviteNow = 'Empty'
}

export interface DrivingLicenseDriverTableProps {
  row: TableData;
}

export interface TableComponentMobileProps {
  row: TableData;
  openSettingPageViaModal: OpenSettingPageViaModalType;
}

export type OpenSettingPageViaModalType = (row: TableData, state?: Object) => void;

export type DiagramStatus = { label: string; count: number; percent: number };

export interface StatusesReq {
  statuses: DiagramStatus[];
  totalRecordsBeforeStatusFilterCount: number;
}
