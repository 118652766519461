import React, { useMemo } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import {
  CustomBackdrop,
  GeneralInfoWrapper,
  HeaderToolsContainer,
  LetterModalTitle,
  LetterToggleButtonContainer,
  OpenSansLabel
} from './letter-modal-styled';
import { Flex } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import EmlFileIcon from 'components/icons/eml-file-icon';
import Download from 'components/icons/download';
import sharepointService from 'services/sharepoint-service';
import { useOverlay } from 'hooks';
import Attachment from './attachment';
import { FileFormatTypes } from 'common/enums';
import Spinner from 'components/spinner';
import MsgFilelIcon from 'components/icons/msg-file-icon';
import ArrowLeft from 'components/icons-new/arrow-left';
import ArrowRight from 'components/icons-new/arrow-right';
import { CloseIcon } from 'components/icons-new/close';
import { useLetter } from './hooks/use-letter';

type Letter = {
  filePath: string;
  fileId: string;
};

interface Props {
  isOpen: boolean;
  allLetters: Letter[];
  selectedLetter: Letter;
  selectedLetterIndex: number;
  onClose: () => void;
  handleSwitchLetter: (switchToNextFile: boolean) => void;
}

const getFileIcon = (fileName: string) => {
  const isEmlFile = fileName.includes(FileFormatTypes.EML);
  return isEmlFile ? <EmlFileIcon /> : <MsgFilelIcon />;
};

const LetterModal = ({
  isOpen,
  allLetters,
  selectedLetter,
  selectedLetterIndex,
  onClose,
  handleSwitchLetter
}: Props) => {
  const theme = useTheme();
  const filePathArr = selectedLetter.filePath.split('/');
  const fileName = filePathArr[filePathArr.length - 1];
  const [showOverlay, hideOverlay] = useOverlay();
  const { email, isLoading } = useLetter(fileName, selectedLetter.fileId);
  const spinnerStyle = {
    width: '50px'
  };

  const downloadFile = async () => {
    try {
      showOverlay();
      const { data } = await sharepointService.downloadFileById(selectedLetter.fileId);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([data]));
      a.download = fileName;
      a.click();
    } catch (err) {
      console.log(err);
    } finally {
      hideOverlay();
    }
  };

  const generalInfo = useMemo(() => {
    if (email) {
      const { fileContent } = email;

      return [
        { label: 'Von', content: fileContent.from || '-' },
        { label: 'An', content: fileContent.to || '-' },
        { label: 'Datum', content: fileContent.date || '-' },
        { label: 'Betreff', content: fileContent.subject || '-' }
      ];
    } else {
      return [];
    }
  }, [email]);

  return (
    <Dialog sx={{ zIndex: 105, m: '35px' }} fullWidth maxWidth={'md'} open={isOpen} onClose={onClose}>
      {allLetters.length > 1 && (
        <CustomBackdrop>
          <LetterToggleButtonContainer>
            <IconButton
              disabled={selectedLetterIndex === 0 || isLoading}
              sx={{
                bgcolor: theme.colors.grey700,
                borderRadius: '5px'
              }}
              onClick={() => handleSwitchLetter(false)}
            >
              <ArrowLeft color={theme.colors.white} />
            </IconButton>
          </LetterToggleButtonContainer>

          <LetterToggleButtonContainer isNextButton>
            <IconButton
              disabled={selectedLetterIndex === allLetters.length - 1 || isLoading}
              sx={{ bgcolor: theme.colors.grey700, borderRadius: '5px' }}
              onClick={() => handleSwitchLetter(true)}
            >
              <ArrowRight color={theme.colors.white} />
            </IconButton>
          </LetterToggleButtonContainer>
        </CustomBackdrop>
      )}

      <LetterModalTitle>
        <Flex align='center' width='100%'>
          <Flex right='10'>{getFileIcon(fileName)}</Flex>
          <Flex maxWidth='550px'>
            <OpenSansLabel ellipsis color='white'>
              {fileName}
            </OpenSansLabel>
          </Flex>
        </Flex>
        <HeaderToolsContainer>
          <Flex align='center' onClick={downloadFile} pointer>
            <Flex>
              <Download width='24' height='24' fill={theme.colors.white} />
            </Flex>
            <OpenSansLabel left='8' right='8' size='20' fontWeight={600} color='white'>
              Herunterladen
            </OpenSansLabel>
          </Flex>

          <Flex width='20px' align='center' pointer onClick={onClose}>
            <CloseIcon width={15} color={theme.colors.white} />
          </Flex>
        </HeaderToolsContainer>
      </LetterModalTitle>
      <DialogContent sx={{ p: '20px', zIndex: 110, bgcolor: theme.colors.white }}>
        {isLoading ? (
          <Flex height='400px' width='100%' align='center' justify='center'>
            <Spinner style={spinnerStyle} />
          </Flex>
        ) : (
          <>
            <Flex direction='column'>
              {Boolean(generalInfo.length) &&
                generalInfo.map(item => (
                  <GeneralInfoWrapper>
                    <Flex height='30px' align='center' maxWidth='250px' width='25%' justify='flex-start'>
                      <OpenSansLabel size='20' as={'span'} fontWeight={600} color='blue'>
                        {item.label}:
                      </OpenSansLabel>
                    </Flex>
                    <Flex height='30px' align='center' width='75%' justify='flex-start'>
                      <OpenSansLabel size='16' as={'span'}>
                        {item.content}
                      </OpenSansLabel>
                    </Flex>
                  </GeneralInfoWrapper>
                ))}
            </Flex>
            {email?.fileContent.htmlContent && (
              <Flex padding='25px 0 0'>
                <div dangerouslySetInnerHTML={{ __html: email?.fileContent.htmlContent }} />
              </Flex>
            )}

            <Flex direction='column' top='25'>
              {Boolean(email?.fileContent.attachments?.length) && (
                <OpenSansLabel size='20' fontWeight={600} color='blue'>
                  Anhang:
                </OpenSansLabel>
              )}

              {Boolean(email?.fileContent.attachments?.length) && (
                <Flex wrap='wrap'>
                  {email?.fileContent.attachments.map(attachment => (
                    <Attachment file={attachment} allFiles={email?.fileContent.attachments} />
                  ))}
                </Flex>
              )}
            </Flex>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LetterModal;
