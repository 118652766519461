/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CompletedStepIcon from '../icons/completed-step';
import UncompletedStepIcon from '../icons/uncompleted-step';
import useWindowSize from 'hooks/use-window-size';

import { styled } from '@mui/material/styles';

import styles from './choose-element-component.module.scss';
import ChooseDamage from '../collision-damage-component/choose-damage';
import StepFour from '../collision-damage-component/step-four';
import StepFive from '../collision-damage-component/step-five';
import StepSix from '../collision-damage-component/step-six';
import StepSeven from '../collision-damage-component/step-seven';
import ParkingChooseDamage from '../parking-damage-component/parking-choose-damage';
import ParkingStepFour from '../parking-damage-component/parking-step-four';
import ParkingStepFive from '../parking-damage-component/parking-step-five';
import ParkingStepSix from '../parking-damage-component/parking-step-six';
import ParkingStepSeven from '../parking-damage-component/parking-step-seven';
import ChooseDamageHail from '../hail-damage-component/hail-choose-damage';
import StepSevenHailDamage from '../hail-damage-component/step-seven-hail-damage';
import ChooseFireDamage from '../fire-damage-component/choose-fire-damage';
import StepFourFireDamage from '../fire-damage-component/step-four-fire-damage';
import StepFiveFireDamage from '../fire-damage-component/step-five-fire-damage';
import StepSixFireDamage from '../fire-damage-component/step-six-fire-damage';
import FileBlock from '../storm-damage-component/file-storm-block';
import ChooseGlassDamage from '../glass-damage-component/choose-glass-damage';
import StepFourGlassDamage from '../glass-damage-component/step-four-glass-damage';
import ChooseAnimalDamage from '../animal-damage-component/choose-animal-damage';
import FileBlockAnimal from '../animal-damage-component/file-animal-block';
import StepFourAnimalDamage from '../animal-damage-component/step-four-animal-damage';
import StepFiveAnimalDamage from '../animal-damage-component/step-five-animal-damage';
import ChooseMartenDamage from '../marten-damage-component/choose-marten-damage';
import FileBlockMarten from '../marten-damage-component/file-marten-block';
import ChooseThiefDamage from '../thief-damage-component/choose-thief-damage';
import FileBlockThief from '../thief-damage-component/file-thief-block';
import ChooseBurglaryDamage from '../burglary-damage-component/choose-burglary-damage';
import FileBlockBurglary from '../burglary-damage-component/file-burglary-block';
import StepFourBurglaryDamage from '../burglary-damage-component/step-four-burglary-damage';
import ChooseOtherDamage from '../other-damage-component/choose-other-damage';
import StepFourOtherDamage from '../other-damage-component/step-four-other-damage';
import StepFiveOtherDamage from '../other-damage-component/step-five-other-damage';
import FileBlockOther from '../other-damage-component/file-other-block';
import StepFourVandalismDamage from '../vandalism-damage-component/step-four-vandalism-damage';
import ChooseVandalismDamage from '../vandalism-damage-component/choose-vandalism-damage';
import FileBlockVandalism from '../vandalism-damage-component/file-vandalism-block';
import GlassFileBlock from '../glass-damage-component/file-glass-block';
import ChooseStormDamage from '../storm-damage-component/choose-storm-damage';
import { UseFormProps } from '../report-damage.props';

const ChooseElementFlow = ({ reactHookFormData }: UseFormProps) => {
  const [activeStep, setActiveStep] = useState<number>(2);
  const { windowWidth } = useWindowSize();
  const { watch, setValue } = reactHookFormData;

  useEffect(() => {
    !!watch('activeStep') && setActiveStep(watch('activeStep'));
  }, []);

  useEffect(() => {
    !!watch('neededStep') && setActiveStep(watch('neededStep'));
  }, [watch('neededStep')]);

  useEffect(() => {
    setValue('activeStep', activeStep);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const collisionDamage = [
    {
      id: 0,
      component: <ChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: <StepFour reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
    },
    {
      id: 4,
      component: <StepFive reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
    },
    {
      id: 5,
      component: <StepSix reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
    },
    {
      id: 6,
      component: <StepSeven reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const parkingDamage = [
    {
      id: 0,
      component: <ParkingChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ParkingChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ParkingChooseDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <ParkingStepFour reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: (
        <ParkingStepFive reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 5,
      component: (
        <ParkingStepSix reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 6,
      component: <ParkingStepSeven reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const fireDamage = [
    {
      id: 0,
      component: <ChooseFireDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseFireDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseFireDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourFireDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: (
        <StepFiveFireDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 5,
      component: <StepSixFireDamage reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const hailDamage = [
    {
      id: 0,
      component: <ChooseDamageHail reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseDamageHail reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseDamageHail reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: <StepSevenHailDamage reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const stormDamage = [
    {
      id: 0,
      component: <ChooseStormDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseStormDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseStormDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: <FileBlock reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const glassDamage = [
    {
      id: 0,
      component: <ChooseGlassDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseGlassDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseGlassDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourGlassDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: <GlassFileBlock reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const animalDamage = [
    {
      id: 0,
      component: <ChooseAnimalDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseAnimalDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseAnimalDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourAnimalDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: (
        <StepFiveAnimalDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 5,
      component: <FileBlockAnimal reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const martenDamage = [
    {
      id: 0,
      component: <ChooseMartenDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseMartenDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseMartenDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: <FileBlockMarten reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const thiefDamage = [
    {
      id: 0,
      component: <ChooseThiefDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseThiefDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseThiefDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: <FileBlockThief reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const burglaryDamage = [
    {
      id: 0,
      component: <ChooseBurglaryDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseBurglaryDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseBurglaryDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourBurglaryDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: <FileBlockBurglary reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const otherDamage = [
    {
      id: 0,
      component: <ChooseOtherDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseOtherDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseOtherDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourOtherDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 4,
      component: (
        <StepFiveOtherDamage reactHookFormData={reactHookFormData} handleBack={handleBack} handleNext={handleNext} />
      )
    },
    {
      id: 5,
      component: <FileBlockOther reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  const vandalismDamage = [
    {
      id: 0,
      component: <ChooseVandalismDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 1,
      component: <ChooseVandalismDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 2,
      component: <ChooseVandalismDamage reactHookFormData={reactHookFormData} handleNext={handleNext} />
    },
    {
      id: 3,
      component: (
        <StepFourVandalismDamage
          reactHookFormData={reactHookFormData}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )
    },
    {
      id: 4,
      component: <FileBlockVandalism reactHookFormData={reactHookFormData} handleBack={handleBack} />
    }
  ];

  let steps;

  switch (watch('damageType')) {
    case 'Auffahrschaden':
    case 'Vorfahrt verletzt':
    case 'Fahrspurwechsel':
      steps = collisionDamage;
      break;

    case 'Parkschaden':
      steps = parkingDamage;
      break;

    case 'Brandschaden':
      steps = fireDamage;
      break;

    case 'Hagelschaden':
      steps = hailDamage;
      break;

    case 'Sturmschaden':
      steps = stormDamage;
      break;

    case 'Glasschaden':
      steps = glassDamage;
      break;

    case 'Wild-/ Tierschaden':
      steps = animalDamage;
      break;

    case 'Marderschaden':
      steps = martenDamage;
      break;

    case 'Totaldiebstahl':
      steps = thiefDamage;
      break;

    case 'Einbruch/ Teildiebstahl':
      steps = burglaryDamage;
      break;

    case 'Sonstiges':
      steps = otherDamage;
      break;

    case 'Sachbesch./ Vandalismus':
      steps = vandalismDamage;
      break;

    default:
      steps = collisionDamage;
      break;
  }

  let verticalConnectorHeight: number;

  switch (steps.length) {
    case 7:
      verticalConnectorHeight = 46;
      break;
    case 6:
      verticalConnectorHeight = 64;
      break;
    case 5:
      verticalConnectorHeight = 91;
      break;
    case 4:
      verticalConnectorHeight = 135;
      break;
    default:
      verticalConnectorHeight = 46;
  }

  const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#335566',
        borderWidth: 2,
        height: verticalConnectorHeight,
        alternativeLabel: true
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#335566',
        borderWidth: 2,
        height: verticalConnectorHeight,
        alternativeLabel: true
      }
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#ACD2E5',
        borderWidth: 2,
        height: verticalConnectorHeight,
        alternativeLabel: true
      }
    },
    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#ACD2E5',
        borderWidth: 2,
        height: verticalConnectorHeight,
        alternativeLabel: true
      }
    }
  }));

  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    return completed ? (
      <div className={styles.circle}>
        <CompletedStepIcon />
      </div>
    ) : active ? (
      <div className={styles.circle}>
        <UncompletedStepIcon />
      </div>
    ) : (
      <div className={styles.circle}>
        <UncompletedStepIcon color='#ACD2E5' />
      </div>
    );
  };

  const HorizontalIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    let styleActive: string;
    let styleCompleted: string;
    let styleUncompleted: string;

    switch (steps.length) {
      case 8:
        styleActive = styles.mobile_active8;
        styleCompleted = styles.mobile_completed8;
        styleUncompleted = styles.mobile_uncompleted8;
        break;
      case 7:
        styleActive = styles.mobile_active7;
        styleCompleted = styles.mobile_completed7;
        styleUncompleted = styles.mobile_uncompleted7;
        break;
      case 6:
        styleActive = styles.mobile_active6;
        styleCompleted = styles.mobile_completed6;
        styleUncompleted = styles.mobile_uncompleted6;
        break;
      case 5:
        styleActive = styles.mobile_active5;
        styleCompleted = styles.mobile_completed5;
        styleUncompleted = styles.mobile_uncompleted5;
        break;
      case 4:
        styleActive = styles.mobile_active4;
        styleCompleted = styles.mobile_completed4;
        styleUncompleted = styles.mobile_uncompleted4;
        break;
      default:
        styleActive = styles.mobile_active8;
        styleCompleted = styles.mobile_completed8;
        styleUncompleted = styles.mobile_uncompleted8;
    }

    return completed ? (
      <div className={styleCompleted} />
    ) : active ? (
      <div className={styleActive} />
    ) : (
      <div className={styleUncompleted} />
    );
  };

  let windowLeftSize = 200;

  if (windowWidth <= 1100) {
    windowLeftSize = 150;
  }
  if (windowWidth <= 1020) {
    windowLeftSize = 120;
  }
  if (windowWidth <= 950) {
    windowLeftSize = 80;
  }
  if (windowWidth <= 900) {
    windowLeftSize = 20;
  }

  const desktopStepperStyle = {
    maxHeight: 620,
    height: 'fit-content',
    position: 'sticky',
    marginLeft: 0,
    alignContent: 'flex-start',
    width: 45
  };

  const mobileStepperStyle = {
    paddingTop: '15px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 30px)',
    margin: '0 15px'
  };

  const EmptyConnector = () => {
    return <div className={styles.empty_connector} />;
  };

  return windowWidth > 900 ? (
    <Box
      sx={{
        width: 1140
      }}
    >
      {
        <div className={styles.step_box}>
          <div className={styles.stepper} style={{ left: windowLeftSize }}>
            <Stepper
              sx={desktopStepperStyle}
              activeStep={activeStep}
              orientation='vertical'
              connector={<QontoConnector />}
            >
              {steps.map(step => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={QontoStepIcon} />
                </Step>
              ))}
            </Stepper>
          </div>
          <div className={styles.step_content}>{steps[activeStep].component}</div>
        </div>
      }
    </Box>
  ) : (
    <Box
      sx={{
        width: '100%'
      }}
    >
      {
        <div className={styles.step_box}>
          <div className={styles.stepper}>
            <Stepper sx={mobileStepperStyle} activeStep={activeStep} connector={<EmptyConnector />}>
              {steps.map(step => (
                <Step sx={{ padding: 0 }} key={step.id}>
                  <StepLabel classes={{ iconContainer: styles.step_style }} StepIconComponent={HorizontalIcon} />
                </Step>
              ))}
            </Stepper>
          </div>
          <div className={styles.step_content}>{steps[activeStep].component}</div>
        </div>
      }
    </Box>
  );
};

export default ChooseElementFlow;
