import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const StyledIconContainer = styled(Flex)`
  min-width: 25px;
  height: 32px;
`;

interface IsOpenProps {
  open: boolean;
}

export const StyledMenuItemContainer = styled.div<IsOpenProps>`
  &:last-child {
    border-bottom: none;
  }

  ${({ open }) =>
    open &&
    css`
      background-color: ${({ theme }) => theme.colors.grey50};
    `};
`;

export const StyledExpandItem = styled.div<IsOpenProps>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  background-color: ${({ theme }) => theme.colors.grey50};

  ${({ open }) =>
    open &&
    css`
      max-height: 100vh;
      transition: max-height 1s;
    `};
`;

const subtitleHoverMixin = css`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-left: 5px solid ${({ theme }) => theme.colors.emeraldGreen};
  padding-left: 15px;

  .icon {
    display: none;
  }
  .icon-hovered {
    display: block;
  }
`;

interface StyledSubtitle extends IsOpenProps {
  dashboardTourIsStarted: boolean;
}

export const StyledSubtitle = styled.div<StyledSubtitle>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey600};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  pointer-events: ${({ dashboardTourIsStarted }) => (dashboardTourIsStarted ? 'none' : 'auto')};

  &:hover {
    ${subtitleHoverMixin}
  }

  ${({ open }) => open && subtitleHoverMixin};
`;

export const StyledInfoChip = styled.div`
  margin-left: 15px;
  text-transform: lowercase;
  font-weight: 100;
`;
