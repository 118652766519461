import React, { useEffect, useState } from 'react';
import { CarDealershipSelectionFieldNames, CarDealershipSelectionProps } from './car-dealership-selection.props';
import FormHook from 'components/form-hook/form-hook';
import {
  carDealershipFields,
  contactPersonFields
} from 'pages/details/car-dealership-selection/car-dealership-selection-form-fields';
import { useCarDealershipContacts } from 'pages/details/react-query/use-car-dealership-contacts';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import cloneDeep from 'lodash/cloneDeep';

const CarDealershipSelection = ({ reactHookFormData }: CarDealershipSelectionProps) => {
  const { getValues, setValue } = reactHookFormData;

  const [contactPersonFormFields, setContactPersonFormFields] = useState(contactPersonFields);

  const carDealershipValue = getValues(CarDealershipSelectionFieldNames.CarDealership);
  const contactPersonValue = getValues(CarDealershipSelectionFieldNames.ContactPerson);

  const { contactPersonOptions } = useCarDealershipContacts(carDealershipValue || null);

  useEffect(() => {
    if (contactPersonValue) {
      setValue(CarDealershipSelectionFieldNames.ContactPerson, '', defaultOptions);
    }

    const newContactPersonFormFields = cloneDeep(contactPersonFormFields);
    newContactPersonFormFields[0].options = contactPersonOptions || [];
    newContactPersonFormFields[0].disabled = !carDealershipValue;
    setContactPersonFormFields(newContactPersonFormFields);
  }, [contactPersonOptions]);

  return (
    <div>
      <FormHook reactHookFormData={reactHookFormData} formFields={carDealershipFields} />
      <FormHook reactHookFormData={reactHookFormData} formFields={contactPersonFormFields} />
    </div>
  );
};

export default CarDealershipSelection;
