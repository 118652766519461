import React from 'react';
import { Flex } from 'common/common-components.styled';
import { StyledDiagramContainer } from 'components/containers/diagram/diagram.styled';
import { DrivingCarIcon } from 'components/icons-new/driving-car';
import { CurrentKilometerReading } from '../../../current-kilometer-reading/current-kilometer-reading';
import useWindowSize from 'hooks/use-window-size';

export const AllDataIsAvailableDriver = () => {
  const { windowWidth } = useWindowSize(500);
  return (
    <StyledDiagramContainer align='center' mobilePadding='30px'>
      <Flex justify='center' bottom={windowWidth <= 550 ? '30' : '10'}>
        <DrivingCarIcon />
      </Flex>

      <CurrentKilometerReading editFormProps={{ width: '80%' }} />
    </StyledDiagramContainer>
  );
};
