import styled from 'styled-components';
import RemoveIcon from 'components/icons/remove-icon';
import AddIcon from 'components/icons/add-icon';
import { Flex } from 'common/common-components.styled';

interface StyledIconProps {
  color: string;
}

export const StyledRemoveIcon = styled(RemoveIcon)<StyledIconProps>`
   cursor: ${({ color, theme }) => color === theme.colors.grey300 ? 'normal' : 'pointer'};
`;

export const StyledAddIcon = styled(AddIcon)<StyledIconProps>`
   cursor: ${({ color, theme }) => color === theme.colors.grey300 ? 'normal' : 'pointer'};
`;

export const StyledIconsContainer = styled(Flex)`
   position: relative;
   min-height: 20px;
`;

