import { TextareaAutosize } from '@mui/material';
import styled, { css } from 'styled-components';
import { IStyledTextAreaField } from './text-area.props';

export const StyledTextArea = styled(TextareaAutosize)<IStyledTextAreaField>`
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => `${maxHeight}px` || 'auto'};
  font-size: 14px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey400 : theme.colors.black)};
  letter-spacing: 0.02em !important;
  border-radius: 3px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)};
  background-color: ${({ theme }) => theme.colors.white};
  resize: vertical;
  padding: 10px 15px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
  ${({ isPrefix }) =>
    isPrefix &&
    css`
      padding-left: 0;
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${`${maxHeight}px` || 'auto'};
      overflow: auto !important;
    `};
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey300};
  }
`;
