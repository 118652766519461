import React, {Component, Fragment} from 'react';
import classes from './fahrzeug-select.module.scss';

class FahrzeugSelect extends Component {

  render() {
    return (
      <Fragment>
        <p className={classes.title}>{this.props.title}</p>
        <select className={classes.select} onChange={this.props.onChange} name="fahrzeug">
          <option value="0">Bitte auswählen</option>
          {this.props.options.map(option =>
            <option key={option.value} value={option.value}>
              {option.name}&nbsp;&nbsp;-&nbsp;&nbsp;{option.hersteller}&nbsp;{option.model}
            </option>
          )}
        </select>
      </Fragment>
    );
  }
}

export default FahrzeugSelect;
