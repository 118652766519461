import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { CrmDataTypes } from 'common/enums';

export const useFormInfoListDataEngineAndGears = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const formInfoListDataEngineAndGears: FormInfoItemEditableProps[] = [
    {
      value: fuhrpark?.uds_gearbox,
      label: 'Getriebe',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.text({ name: 'uds_gearbox', defaultValue: fuhrpark?.uds_gearbox })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_hubraum && `${fuhrpark.uds_hubraum.toLocaleString('de-DE')} cm³`,
      label: 'Hubraum',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.number({ name: 'uds_hubraum', defaultValue: fuhrpark?.uds_hubraum })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_bodyname,
      label: 'Karosserie',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.text({ name: 'uds_bodyname', defaultValue: fuhrpark?.uds_bodyname })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_leistung && `${fuhrpark.uds_leistung.toLocaleString('de-DE')} kW`,
      label: 'Leistung',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.number({ name: 'uds_leistung', defaultValue: fuhrpark?.uds_leistung })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_enginetypecode,
      label: 'Kraftstoff',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'uds_enginetypecode',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.uds_enginetypecode_value,
              options: OPTION_SET.uds_enginetypecode
            })
          ]}
        />
      )
    }
  ];

  return { formInfoListDataEngineAndGears };
};
