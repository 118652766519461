import { ReactNode } from 'react';

export enum InfoHintType {
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
  Danger = 'danger'
}

export type IconType = 'error' | 'warning' | 'info' | 'success' | 'danger';

export interface InfoHintProps {
  children: ReactNode;
  className?: string;
  type?: IconType | InfoHintType;
  iconSize?: number;
  iconColor?: string;
}
