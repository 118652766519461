import React from 'react';

export const DownArrowIcon = props => {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.5 1.96211L6.05 6.41211L1.5 1.86211'
        stroke={props.color || '#335566'}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
