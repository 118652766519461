import React from 'react';
import { FormFieldTypes } from 'common/enums';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useGetDriverWithCarQuery } from 'hooks/react-query/driver/get-drivers-with-car/use-get-drivers-with-car';
import { useVehicleDetailIsDisableEditMode } from 'pages/existing-vehicle-page/hooks/use-is-disable-edit-mode';
export const useCorporateTaxResponsibilityField = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isDisableEditMode } = useVehicleDetailIsDisableEditMode();

  const { getDriverWithCarQuery } = useGetDriverWithCarQuery(undefined, undefined, {
    options: { enabled: !isDisableEditMode },
    fetch: { isShowLoading: false }
  });

  const corporateTaxResponsibilityField: FormInfoItemEditableProps = {
    value: fuhrpark?.new_kstverantw,
    label: 'KST-Verantwort.',
    isDisableEditMode,
    editableComponent: (
      <VehicleDetailsEditForm
        formFields={[
          generateFormField.optionSet({
            name: 'new_kstverantw',
            type: FormFieldTypes.Lookup,
            defaultValue: fuhrpark?.new_kstverantw_id,
            options: getDriverWithCarQuery?.map(item => ({ label: item.name || '-', value: item.id || '-' })) || []
          })
        ]}
      />
    )
  };

  return { corporateTaxResponsibilityField };
};
