import React from 'react';
import { IRepairStatusWidget } from './repair-status.props';
import { RepairPartnerItem } from './components/item/item';
import { RepairPartnerExpanded } from './components/expanded/expanded';
import { RepairStatusWidgetContext } from './repair-status.context';
import { StyledContainer } from './repair-status.styled';

export const RepairStatusWidget = ({
  data,
  isExtended,
  setIsExtended,
  headerComponent,
  isLoading
}: IRepairStatusWidget) => {
  return (
    <RepairStatusWidgetContext.Provider value={{ data, isExtended, setIsExtended, headerComponent, isLoading }}>
      <StyledContainer>
        <RepairPartnerItem />
        <RepairPartnerExpanded />
      </StyledContainer>
    </RepairStatusWidgetContext.Provider>
  );
};
