import { useEffect, useRef, useState, CSSProperties } from 'react';

const useEllipsisTooltip = (offsetTop = 0, offsetLeft = 0, alwaysShow = false, neverShow = false) => {
  const content = useRef<HTMLElement | HTMLElement[]>([]);

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [isShowTip, setIsShowTip] = useState(false);
  const [activeTipIndex, setActiveTipIndex] = useState(-1);
  const [style, setStyle] = useState<CSSProperties | CSSProperties[]>(defaultStyle);

  useEffect(() => {
    if (Array.isArray(content.current)) {
      setStyle(Array(content.current.length).fill(defaultStyle));
    }
  }, [setStyle]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    if ((isShowTip || activeTipIndex > 0) && content?.current) {
      if (Array.isArray(content.current)) {
        const containerPosition = content.current[activeTipIndex].getBoundingClientRect();

        setStyle(prevStyle => {
          if (Array.isArray(prevStyle)) {
            return addNewStyle(prevStyle, getTipPosition(containerPosition));
          }
          return prevStyle;
        });

        return;
      }

      const containerPosition = content.current.getBoundingClientRect();
      const newStyle: CSSProperties = {
        ...(style as CSSProperties),
        ...getTipPosition(containerPosition)
      };

      setStyle(newStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowTip, activeTipIndex, offsetTop, offsetLeft]);

  const getTipPosition = (containerPosition: DOMRect): CSSProperties => ({
    top: `${containerPosition.bottom + offsetTop}px`,
    left: `${containerPosition.left + offsetLeft}px`
  });

  const addNewStyle = (
    style: CSSProperties[] | CSSProperties,
    addStyle: CSSProperties
  ): CSSProperties[] | CSSProperties => {
    if (Array.isArray(style)) {
      return style.map((styleItem, prevStyleIndex) => {
        if (prevStyleIndex === activeTipIndex) {
          return { ...styleItem, ...addStyle };
        }

        return styleItem;
      });
    }

    return {
      ...style,
      ...addStyle
    };
  };

  const createTimeout = (offsetWidth: number, scrollWidth: number, index?: number) => {
    if (alwaysShow || offsetWidth < scrollWidth) {
      const timeoutid = window.setTimeout(() => {
        if (typeof index === 'number') {
          return setActiveTipIndex(index);
        }
        setIsShowTip(true);
      }, 1000);

      setTimeoutId(timeoutid);
    }
  };

  const onMouseEnter = (elemId?: number) => {
    if (neverShow) {
      return;
    }
    setIsShowTip(false);

    if (content?.current) {
      if (Array.isArray(content.current)) {
        const { offsetWidth, scrollWidth } = content.current[elemId as number];
        return createTimeout(offsetWidth, scrollWidth, elemId);
      }

      createTimeout(content.current.offsetWidth, content.current.scrollWidth);
    }
  };

  const onMouseLeave = () => {
    if (neverShow) {
      return;
    }
    setIsShowTip(false);
    setActiveTipIndex(-1);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return { isShowTip, style, content, onMouseEnter, onMouseLeave, activeTipIndex };
};

const defaultStyle: CSSProperties = {
  position: 'fixed',
  padding: '5px 10px',
  maxWidth: '250px',
  fontSize: '12px',
  color: '#fff',
  backgroundColor: '#777777',
  borderRadius: '3px',
  zIndex: '1000',
  lineHeight: '18px',
  whiteSpace: 'normal'
};

export default useEllipsisTooltip;
