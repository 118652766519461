import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const driversCheckUpdateAccount = async data => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/updateAccount`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data
    }
  });
};

export const updateDriver = async (contactId, booked) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/updateDriver`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      contactId,
      booked
    }
  });
};

export const getIsAccountBooked = async accountId => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/getIsAccountBooked`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
};
