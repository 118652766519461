import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const LocationIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProps, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProps || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M0.692444 7.85714C0.692444 12.1429 7.54821 19 7.54821 19C7.54821 19 8.4693 18.0787 9.63625 16.7005L10.8964 15.1399C12.6365 12.8722 14.404 10.0332 14.404 7.85714C14.404 4.08571 11.3189 1 7.54821 1C3.77754 1 0.692444 4.08571 0.692444 7.85714Z'
          fill={fill}
        />
      )}
      <path
        d='M7.54818 11.2857C9.44135 11.2857 10.9761 9.75068 10.9761 7.85713C10.9761 5.96358 9.44135 4.42856 7.54818 4.42856C5.65501 4.42856 4.1203 5.96358 4.1203 7.85713C4.1203 9.75068 5.65501 11.2857 7.54818 11.2857Z'
        fill='white'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.63625 16.7005C8.4693 18.0787 7.54821 19 7.54821 19C7.54821 19 0.692444 12.1429 0.692444 7.85714C0.692444 4.08571 3.77754 1 7.54821 1C11.3189 1 14.404 4.08571 14.404 7.85714C14.404 10.0332 12.6365 12.8722 10.8964 15.1399'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
