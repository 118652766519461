import React from 'react';
import { StepIcon } from './step-icon';
import { Flex, StyledStepUnderline, StyledStepUnderlineProps, Text } from 'common/common-components.styled';
import { StepProcessHorizontalItemProps } from './step-process-horizontal-item.props';
import useWindowSize from 'hooks/use-window-size';

export const StepProcessHorizontalItem = ({
  title,
  description,
  isHideUnderline,
  type,
  id
}: StepProcessHorizontalItemProps) => {
  const { windowWidth } = useWindowSize(500);

  const stepUnderlineCommonProps: StyledStepUnderlineProps = {
    isActive: type !== 'inactive',
    right: windowWidth <= 550 ? '0' : '10',
    top: windowWidth <= 550 ? '10' : '0'
  };

  return (
    <Flex bottom={windowWidth <= 550 ? '10' : ''}>
      <Flex direction='column' align='center'>
        <StepIcon type={type} id={id} />
        {!isHideUnderline && windowWidth < 550 && <StyledStepUnderline isVertical {...stepUnderlineCommonProps} />}
      </Flex>

      <Flex direction='column' left='10'>
        <Flex align='center'>
          <Text
            color={type === 'inactive' ? 'midBlue' : 'blue'}
            size={windowWidth <= 550 ? '14' : '18'}
            bold={windowWidth > 550}
            right='10'
          >
            {title || '-'}
          </Text>

          {!isHideUnderline && windowWidth > 550 && <StyledStepUnderline {...stepUnderlineCommonProps} />}
        </Flex>

        <Text color='midBlue' size='12'>
          {description || '-'}
        </Text>
      </Flex>
    </Flex>
  );
};
