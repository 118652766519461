import React from 'react';
import LeasingWidget from '../components/leasing-widget';
import { Car } from 'components/icons-new/car';
import { useTheme } from 'styled-components';
import { useReplacementVehicleOrdering } from './use-replacement-vehicle-ordering';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import ReplacementVehicleOrderingButtons from './components/replacement-vehicle-ordering-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';

const ReplacementVehicleOrdering = () => {
  const theme = useTheme();
  const { statusLabel, statusColor, showBulletPoints, bulletPoinstController, description, showInfoIcon } =
    useReplacementVehicleOrdering();

  return (
    <LeasingWidget
      image={<Car color={theme.colors.midBlue} hovered={true} width={60} height={40} />}
      statusColor={statusColor}
      statusLabel={statusLabel}
      showBulletPoints={showBulletPoints}
      bulletPoinstController={bulletPoinstController}
      buttons={<ReplacementVehicleOrderingButtons />}
      id={'replacementVehicleOrdering'}
    >
      <WidgetWrapper showInfoIcon={showInfoIcon}>
        <DescriptionContainer>{description}</DescriptionContainer>
      </WidgetWrapper>
    </LeasingWidget>
  );
};

export default ReplacementVehicleOrdering;
