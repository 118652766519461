import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const EmailWarningIcon = ({ width = 32, height = 32, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.8437 7.43332H5.43796C4.64734 7.43332 4.00047 8.08013 4.00047 8.87067L4 13.7968V20.9836C4 21.7741 4.64745 22.4209 5.43807 22.4209H15.9718H24.8438C25.6345 22.4209 26.2813 21.7741 26.2813 20.9836L26.2818 16.0574L26.2817 13.7968L26.2812 8.87067C26.2812 8.08013 25.6343 7.43332 24.8437 7.43332Z'
        fill='white'
      />
      <path
        d='M4 10.6525L14.1972 15.5844C14.7902 15.8719 15.482 15.8719 16.0749 15.5844L26.2812 10.6525'
        fill={hovered ? fill : 'none'}
      />
      <path
        d='M4 10.6525L14.1972 15.5844C14.7902 15.8719 15.482 15.8719 16.0749 15.5844L26.2812 10.6525V8.32892L25.0833 7.45358H5.1349L4 8.32892V10.6525Z'
        fill={hovered ? fill : 'none'}
      />
      <path
        d='M4 10.6525L14.1972 15.5844C14.5271 15.7443 15.1121 15.8407 15.4691 15.8227L20 14'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.29492 21.7596L12.3418 14.7782'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.2812 12.4365V8.87067C26.2812 8.08013 25.6343 7.43332 24.8437 7.43332H5.43796C4.64734 7.43332 4.00047 8.08013 4.00047 8.87067L4 13.7968V20.9836C4 21.7741 4.64745 22.4209 5.43807 22.4209H15.7091'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.1787 24.1724H28.917C29.6152 24.1724 30.0549 23.4017 29.7032 22.781L24.1997 13.4655C23.848 12.8448 22.979 12.8448 22.6273 13.4655L17.1239 22.781C16.7721 23.4017 17.2118 24.1724 17.9101 24.1724H20.6757'
        fill={hovered ? fill : 'none'}
      />
      <path
        d='M26.1787 24.1724H28.917C29.6152 24.1724 30.0549 23.4017 29.7032 22.781L24.1997 13.4655C23.848 12.8448 22.979 12.8448 22.6273 13.4655L17.1239 22.781C16.7721 23.4017 17.2118 24.1724 17.9101 24.1724H20.6757'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.4072 25.4141C24.0739 25.4141 24.6143 24.8737 24.6143 24.207C24.6143 23.5404 24.0739 23 23.4072 23C22.7406 23 22.2002 23.5404 22.2002 24.207C22.2002 24.8737 22.7406 25.4141 23.4072 25.4141Z'
        fill={color}
      />
      <path
        d='M23.4132 15.7242C23.8684 15.7242 24.2305 16.0966 24.2098 16.5517L24.0546 21.6948C24.0443 22.0414 23.7598 22.3155 23.4132 22.3155C23.0667 22.3155 22.7822 22.0414 22.7719 21.6948L22.6167 16.5517C22.6012 16.0966 22.9632 15.7242 23.4132 15.7242Z'
        fill={color}
      />
    </svg>
  );
};
