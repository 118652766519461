import React, { useContext } from 'react';
import { SortingRow } from '../sorting-row/sorting-row';
import moment from 'moment';
import { DocumentRowFileName } from './components/file-name';
import useModal from 'hooks/use-modal';
import { MODALS, MODAL_NAMES } from '../../../../../../../constants';
import { Flex } from 'common/common-components.styled';
import { DocumentRowProps } from './document-row.props';
import { getFileSize } from 'utils/file/get-file-size-string';
import { useIsShowThreeStateCheckbox } from './hooks/use-is-show-three-state-checkbox';
import { FileFormatTypes } from 'common/enums';
import { SortingDocumentsTabsContext } from 'components/documents/sorting-tabs-documents/sorting-documents-tabs.context';
import { DocumentRowRightComponents } from './components/right-components';

export const DocumentRow = ({ file, allFiles, sectionName }: DocumentRowProps) => {
  const { fileId, filePath, fileSize, lastModified } = file;

  const { isShowThreeStateCheckbox, changeCheckboxHandler, mouseMoveHandler, mouseLeaveHandler } =
    useIsShowThreeStateCheckbox(file, sectionName);

  const { showLetterModal } = useContext(SortingDocumentsTabsContext);

  const createdOnMoment = moment(lastModified);

  const getFileExtension = (filePath: string) => {
    const extensionArr = filePath.split('.');
    return extensionArr[extensionArr.length - 1] as FileFormatTypes;
  };

  const [showFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, { file, allFiles });

  const clickHandler = () => {
    FILE_FORMAT_FOR_LETTER_MODAL.includes(getFileExtension(filePath)) ? showLetterModal(fileId) : showFileViewer();
  };

  return (
    <Flex
      position='relative'
      pointer
      onClick={clickHandler}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <SortingRow
        name={
          <DocumentRowFileName
            {...file}
            sectionName={sectionName}
            isShowThreeStateCheckbox={isShowThreeStateCheckbox}
            changeCheckboxHandler={changeCheckboxHandler}
          />
        }
        size={fileSize ? getFileSize(fileSize) : '-'}
        createdOn={createdOnMoment.isValid() ? createdOnMoment.format('DD.MM.YYYY') : '-'}
        createdBy={file.createdBy || '-'}
        rightComponent={<DocumentRowRightComponents file={file} />}
      />
    </Flex>
  );
};

const FILE_FORMAT_FOR_LETTER_MODAL = [FileFormatTypes.EML, FileFormatTypes.MSG];
