import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import { EditFormProps } from './edit-form.props';
import IconHover from 'components/icon-hover';
import { CloseIcon } from 'components/icons-new/close';
import { SaveIcon } from 'components/icons-new/save';

export function EditForm<TFieldValues>({
  idPrefix,
  label,
  onSubmit,
  onClose,
  containerFlex,
  ...restFormHookProps
}: EditFormProps<TFieldValues>) {
  const { reactHookFormData, formFields } = restFormHookProps;
  const { handleSubmit } = reactHookFormData;

  return (
    <Flex direction='column' width='100%' {...containerFlex}>
      <Flex justify='space-between' align='center' bottom='5'>
        <Text color='darkBlue' right='3'>
          {label}
        </Text>

        <Flex align='center'>
          {onClose && (
            <IconHover onClick={onClose} height='16px'>
              <CloseIcon id={`${idPrefix}CloseForm`} width={14} height={14} />
            </IconHover>
          )}

          <IconHover onClick={handleSubmit(onSubmit)} height='20px' left='10'>
            <SaveIcon id={`${idPrefix}Submit`} width={20} height={20} />
          </IconHover>
        </Flex>
      </Flex>

      <FormHook distanceBetweenFields={formFields.length ? '0' : undefined} {...restFormHookProps} />
    </Flex>
  );
}
