import React from 'react';
import { IAutocompleteListboxProps } from './listbox.props';
import { StyledAutocompleteAddNewItemButtonContainer } from './listbox.styled';
import { useSetDefaultContainerStyle } from './hooks/use-set-container-style';

export const AutocompleteListbox = ({ children, addNewItemButton, ...props }: IAutocompleteListboxProps) => {
  const { buttonRef, containerStyle } = useSetDefaultContainerStyle(!!addNewItemButton.isHide);

  return (
    <div>
      <div style={containerStyle} {...props}>
        {children}
        <StyledAutocompleteAddNewItemButtonContainer ref={buttonRef} {...addNewItemButton} />
      </div>
    </div>
  );
};
