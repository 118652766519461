import { PAGES } from 'common/enums';
import { useQuery, UseQueryOptions } from 'react-query';
import { requestService } from 'services/requests-service/requests-service';
import { modifyTable } from './my-requests/utils';

export const useIncidentByIdQuery = (incidentId: string, options = DEFAULT_OPTIONS) => {
  const incidentByIdQuery = useQuery(
    [PAGES.MY_REQUESTS, incidentId],
    () => requestService.getRepairPartnerById(incidentId),
    {
      ...options,
      select: ({ data }) => modifyTable(data.data),
      enabled: !!incidentId && options.enabled
    }
  );

  return {
    ...incidentByIdQuery,
    data: incidentByIdQuery?.data
  };
};

const DEFAULT_OPTIONS: Pick<UseQueryOptions, 'enabled'> = {
  enabled: true
};
