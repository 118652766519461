import React, { useEffect, useState } from 'react';
import Form from '../../../components/form/form-redux.js';
import { Button, Divider, Flex, Text } from '../../../common/common-components.styled';
import { AnyObject } from '../../../common/interfaces.js';
import { StyledAddIcon, StyledIconsContainer, StyledRemoveIcon } from './approvers-info.styled';
import cloneDeep from 'lodash/cloneDeep';
import ThreeStateCheckbox from '../../../components/three-state-checkbox';
import { CircleExclamationMark } from 'components/icons/crcle_exclamation_mark';
import { useTheme } from 'styled-components';
import { useOverlay } from 'hooks';
import sharepointService from 'services/sharepoint-service';
import Spinner from 'components/spinner';
import { CheckboxData } from 'components/onboarding-component/onboarding-interfaces';
import useApproverFormFields from './hooks/use-approver-form-fields';
import { SetApproversAutomaticallyValues } from 'pages/company-settings-page/company-settings-component/approval-rules/approval-rules.types';

interface ApproversInfoProps {
  submit(isAutomaticType?: boolean): void;
  fileId: string | null;
  loading: boolean;
  setShowApproversInfo(flag: boolean): void;
  offerStatus: number;
  isBasedOnBulkOrderVehicle?: boolean;
  driverId?: string;
}

const ApproversInfo = ({
  submit,
  setShowApproversInfo,
  offerStatus,
  fileId,
  loading,
  isBasedOnBulkOrderVehicle,
  driverId
}: ApproversInfoProps) => {
  const theme = useTheme();
  const [showOverlay, hideOverlay] = useOverlay();
  const [noApprovalsNeeded, setNoApprovalsNeeded] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [approverLoading, setApproverLoading] = useState(false);

  const { formFields, setFormFields, defaultApproverFields, orderApproveTypeValue } = useApproverFormFields(
    noApprovalsNeeded,
    true,
    driverId
  );

  const isPrefilledType = orderApproveTypeValue === SetApproversAutomaticallyValues.Prefilled;
  const isAutomaticType = orderApproveTypeValue === SetApproversAutomaticallyValues.Automatic;

  useEffect(() => {
    if (isAutomaticType) {
      setTimeout(() => submit(true));
    }
  }, [isAutomaticType]);

  const addApprover = () => {
    if (formFields.columns[0].fields.length > 2) {
      return;
    }
    setApproverLoading(true);
    setTimeout(() => setApproverLoading(false), 1000);
    const newFormFields = cloneDeep(formFields);
    newFormFields.columns[0].fields.push({
      ...newFormFields.columns[0].fields[0],
      name: `approver${newFormFields.columns[0].fields.length + 1}`,
      label: `Freigeber ${newFormFields.columns[0].fields.length + 1}`
    });

    setFormFields(newFormFields);
  };

  const removeApprover = () => {
    if (formFields.columns[0].fields.length < 2) {
      return;
    }
    setApproverLoading(true);
    setTimeout(() => setApproverLoading(false), 1000);
    const newFormFields = cloneDeep(formFields);
    newFormFields.columns[0].fields.pop();
    setFormFields(newFormFields);
  };

  const openBindingOrderDocument = (event: AnyObject) => {
    event.stopPropagation();
    if (loading || !fileId) {
      return;
    }
    showOverlay();
    sharepointService
      .downloadFileById(fileId)
      .then(({ data }: { data: Blob }) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = fileURL;
        }
      })
      .catch(err => console.log(err))
      .finally(hideOverlay);
  };

  const checkboxChanged = (data: CheckboxData, name: string) => {
    switch (name) {
      case 'noApprovals':
        setNoApprovalsNeeded(!data.checked);
        setFormFields(defaultApproverFields);
        break;
      case 'acceptTerms':
        setAcceptTermsAndConditions(!data.checked);
        break;
    }
  };

  if (isAutomaticType) {
    return null;
  }

  return (
    <>
      {!noApprovalsNeeded && (
        <Flex direction='column' top='20'>
          <Form name={'approver'} formFields={formFields} />
          {!isPrefilledType && (
            <StyledIconsContainer align='center' top='20'>
              {approverLoading ? (
                <Spinner
                  style={{
                    width: '20px',
                    position: 'absolute',
                    left: '80px',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              ) : (
                <>
                  <StyledRemoveIcon
                    onClick={removeApprover}
                    color={formFields.columns[0].fields.length < 2 ? theme.colors.grey300 : theme.colors.blue}
                  />
                  <Text margin='0 10px' color='grey600'>
                    Anzahl Freigeber
                  </Text>
                  <StyledAddIcon
                    onClick={addApprover}
                    color={formFields.columns[0].fields.length > 2 ? theme.colors.grey300 : theme.colors.blue}
                  />
                </>
              )}
            </StyledIconsContainer>
          )}
        </Flex>
      )}

      {/*{!isBasedOnBulkOrderVehicle && (*/}
      {/*  <>*/}
      {/*    <Flex align='center' top='20'>*/}
      {/*      <ThreeStateCheckbox*/}
      {/*        indeterminate={false}*/}
      {/*        className={null}*/}
      {/*        onChange={data => checkboxChanged(data, 'noApprovals')}*/}
      {/*        checked={noApprovalsNeeded}*/}
      {/*      />*/}
      {/*      <Text*/}
      {/*        left='5'*/}
      {/*        pointer*/}
      {/*        onClick={() => checkboxChanged({ checked: noApprovalsNeeded, indeterminate: false }, 'noApprovals')}*/}
      {/*        color='black'*/}
      {/*      >*/}
      {/*        Freigabe durch Fuhrparkleiter erteilt.*/}
      {/*      </Text>*/}
      {/*    </Flex>*/}

      {/*    {!noApprovalsNeeded && <Divider margin='20px 0 40px' />}*/}

      {/*    <Flex top='10' align='center'>*/}
      {/*      <ThreeStateCheckbox*/}
      {/*        indeterminate={false}*/}
      {/*        className={null}*/}
      {/*        onChange={data => checkboxChanged(data, 'acceptTerms')}*/}
      {/*        checked={acceptTermsAndConditions}*/}
      {/*      />*/}
      {/*      <Text*/}
      {/*        left='5'*/}
      {/*        pointer*/}
      {/*        onClick={() =>*/}
      {/*          checkboxChanged({ checked: acceptTermsAndConditions, indeterminate: false }, 'acceptTerms')*/}
      {/*        }*/}
      {/*        color='black'*/}
      {/*      >*/}
      {/*        Ich akzeptiere die{' '}*/}
      {/*        <Text onClick={openBindingOrderDocument} as='span' bold color='blue'>*/}
      {/*          Verbindliche Bestellung.*/}
      {/*        </Text>*/}
      {/*      </Text>*/}
      {/*    </Flex>*/}

      {/*    <Flex padding='0 0 0 30px' margin='5px 0 20px' align='center'>*/}
      {/*      {formErrors && !acceptTermsAndConditions && (*/}
      {/*        <>*/}
      {/*          <CircleExclamationMark color={theme.colors.pink} width={'12'} height={'12'} />*/}
      {/*          <Text left='10' size='12' color='pink'>*/}
      {/*            Dieses Feld wird benötigt.*/}
      {/*          </Text>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </Flex>*/}
      {/*  </>*/}
      {/*)}*/}

      {!isBasedOnBulkOrderVehicle && (
        <Flex justify='flex-end' align='flex-start' top='20'>
          <Button onClick={() => setShowApproversInfo(false)} secondary>
            Zurück
          </Button>
          <Flex left='10' align='center' direction='column'>
            <Button onClick={() => submit()} bottom='5'>
              Verbindlich bestellen
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ApproversInfo;
