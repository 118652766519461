import React from 'react';
import { VehicleCostHeader } from '../../../../components/header/header';
import { useFleetGetVehicleConstQuery } from 'hooks/react-query/fleet/use-get-vehicle-costs/use-get-vehicle-costs';
import toMoney from 'utils/toMoney';
import { PAGES } from 'common/enums';
import { CRM_VALUE } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { useOpenPageViaVehicleDetailPage } from 'pages/existing-vehicle-page/hooks/use-open-page';

export const TotalRunningTimeHeader = () => {
  const { fleetGetVehicleConstQuery } = useFleetGetVehicleConstQuery();
  const { openPage } = useOpenPageViaVehicleDetailPage();

  const getTitle = () => {
    if (typeof fleetGetVehicleConstQuery?.all === 'number') {
      return toMoney(Math.round(fleetGetVehicleConstQuery?.all), { minimumFractionDigits: 0 });
    }

    return '-';
  };

  return (
    <VehicleCostHeader
      idPrefix='totalRunningTimeWidget'
      title={getTitle()}
      description='Kosten Gesamtlaufzeit'
      onDescriptionClick={openPage({ page: PAGES.VEHICLE_COSTS, carCrmName: CRM_VALUE.car })}
    />
  );
};
