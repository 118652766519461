import { IconProps } from 'common/interfaces';
import React from 'react';

export const DownloadDocumentIcon = ({ fill = 'none' }: IconProps) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.5806 12.2258V6.80645L14.1613 6.94099V1H1.77419C1.34839 1 1 1.34839 1 1.77419V24.2258C1 24.6516 1.34839 25 1.77419 25H19.5806'
      fill={fill}
    />
    <path
      d='M25.0006 23.4516V25H13.3877V23.4516'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M17.2582 10.6774V16.4839H15.3228L19.1937 22.6774L23.0647 16.4839H21.1292V14.14' fill='white' />
    <path
      d='M19.5806 12.2258V6.80645L14.1613 1H1.77419C1.34839 1 1 1.34839 1 1.77419V24.2258C1 24.6516 1.34839 25 1.77419 25H11.0645'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.2582 10.6774V16.4839H15.3228L19.1937 22.6774L23.0647 16.4839H21.1292V14.1613'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.5805 6.80645H14.5482C14.3353 6.80645 14.1611 6.63226 14.1611 6.41935V4.09677'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
