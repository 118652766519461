import { SessionFilter } from '../use-session-filter';

export const useMultiplyFilter = (sessionFilter: SessionFilter, filterName: string) => {
  const getLabel = () => {
    const allValues = sessionFilter.filterForClient[filterName] || [];
    const [firstValue] = allValues;

    if (allValues.length > 1) {
      return `${firstValue} + ${allValues.length - 1}`;
    }

    return firstValue;
  };

  const submitFilter = (valueForServer: string | number, valueForClient: string | number) => {
    const filterFromServer: Array<string | number> = sessionFilter.filterForServer[filterName] || [];
    const filterFromClient: Array<string | number> = sessionFilter.filterForClient[filterName] || [];

    if (filterFromServer.some(serverItemValue => serverItemValue === valueForServer)) {
      const filterIndex = filterFromServer.indexOf(valueForServer);

      filterFromServer.splice(filterIndex, 1);
      filterFromClient.splice(filterIndex, 1);

      return { filterFromServer, filterFromClient };
    }

    filterFromServer.push(valueForServer);
    filterFromClient.push(valueForClient);

    return { filterFromServer, filterFromClient };
  };

  return { label: getLabel(), allLabels: (sessionFilter.filterForClient[filterName] || []) as string[], submitFilter };
};
