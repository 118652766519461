import { IconProps } from 'common/interfaces';
import React from 'react';

export const EditIcon = ({ width = 16, height = 16, color = '#8FAFBF', ...props }: IconProps) => (
  <svg width={width} height={height} {...props} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.9286 0L0 10.9286L0.0714286 15.9286L5.07143 16L16 5.07143L10.9286 0ZM14 5.07143L13.5 5.57143L10.5 2.57143L11 2.07143L14 5.07143ZM4.5 14.5714H1.5V11.5714L9.5 3.5L10.5 4.5L2.71429 12.2857L3.71429 13.2857L11.5 5.5L12.5 6.5L4.5 14.5714Z'
      fill={color}
    />
  </svg>
);
