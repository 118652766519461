import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../constants';
import { useDispatch } from 'react-redux';
import UploadCarPhotoModal from 'components/dashboard-driver/driver-vehicle/upload-modal';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { dashboardRequestConfig } from 'request-config/dashboard/dashboard.request-config';
import { OpenCreateCarImageModalParams } from './use-create-car-image.props';

export const useCreateCarImageModal = () => {
  const dispatch = useDispatch();

  const { fetch } = useFetch({ loadingKey: 'createCarPhoto', isShowLoading: false, isShowErrorSnackbar: true });

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openCreateCarImageModal = ({
    vehicleId,
    callbackBeforeRequest,
    callbackAfterRequest
  }: OpenCreateCarImageModalParams) => {
    const alertData = {
      title: 'Foto hochladen',
      children: (
        <UploadCarPhotoModal
          uploadNewLogo={async file => {
            closeAlert();
            callbackBeforeRequest && callbackBeforeRequest();

            await fetch({
              requestConfig: dashboardRequestConfig.uploadCarLogo(vehicleId, file),
              callback: () => callbackAfterRequest && callbackAfterRequest()
            });
          }}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          action: closeAlert,
          hide: true
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openCreateCarImageModal };
};
