import { FormFieldTypes } from 'common/enums';
import { UDS_AUFGABENTYP_FUHRPARK } from '../../../../constants';
import moment from 'moment';

export const getConductionDateField = (taskTypeCode: number) => [
  {
    name: 'conductionDate',
    type: FormFieldTypes.Date,
    defaultValue: null,
    validation: {
      required: 'Bitte auswählen'
    },
    maxDate: (function () {
      if (taskTypeCode === UDS_AUFGABENTYP_FUHRPARK.inspectionDates) {
        return;
      }

      return moment().startOf('day');
    })()
  }
];
