import { connect } from 'react-redux';
import Menu from './menu-view';
import {toggleMenu} from "../../actions/app_action";

const mapStateToProps = (state) => ({
  params: state.app.menu.params,
  component: state.app.menu.component
});

const mapDispatchToProps = (dispatch) => ({
  closeMenu() {
    dispatch(toggleMenu(null, null));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
