import { ShoppingCart } from 'components/icons-new/shopping-cart';
import { DocumentApprovedIcon } from 'components/icons-new/document-approved';
import { ReturnKeys } from 'components/icons-new/return-keys';
import { UVVIcon } from 'components/icons-new/uvv-icon';
import { HUIcon } from 'components/icons-new/hu-icon';
import { CarRepairIcon } from 'components/icons-new/car-repair';
import React from 'react';
import { DocumentIcon } from 'components/icons-new/document';
import classes from '../aufgaben-item/aufgaben-item.module.scss';
import { TaskTypeId } from 'components/aufgaben-component/task.prop';
import { CarCheckedIcon } from 'components/icons-new/car-checked';
import { CalendarCheckedIcon } from 'components/icons-new/calendar-checked';

interface TaskTypeIconProps {
  type?: TaskTypeId;
  color?: string;
}

export const TaskTypeIcon = ({ type, color }: TaskTypeIconProps) => {
  switch (type) {
    case TaskTypeId.Uvv:
      return <UVVIcon className={classes.icons} color={color} />;

    case TaskTypeId.Hu:
      return <HUIcon className={classes.icons} color={color} />;

    case TaskTypeId.Allgemein:
      return <DocumentIcon className={classes.icons} color={color} />;

    case TaskTypeId.LeaseExtention:
      return <DocumentApprovedIcon className={classes.icons} color={color} />;

    case TaskTypeId.Checklist:
      return <DocumentApprovedIcon className={classes.icons} color={color} />;

    case TaskTypeId.Reparaturfreigabe:
      return <CarRepairIcon className={classes.icons} color={color} />;

    case TaskTypeId.Replacement:
      return <ShoppingCart className={classes.icons} color={color} />;

    case TaskTypeId.VehicleReturn:
      return <ReturnKeys className={classes.icons} color={color} />;

    case TaskTypeId.Appraise:
      return <CarCheckedIcon className={classes.icons} color={color} width={20} />;

    case TaskTypeId.InspectionDates:
      return <CalendarCheckedIcon className={classes.icons} color={color} width={20} />;

    default:
      return null;
  }
};
