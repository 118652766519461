import React from 'react';
import classes from './video-player.module.scss';
import { IVideoPlayerProps } from './video-player.props';

export const VideoPlayer = ({ src = '', title, isOpenVideoTutuorials }: IVideoPlayerProps) => {

  return (
    <div className={classes.container}>
      <div>
        <iframe width='100%' height='191px' src={`${src}?rel=0`} title={title}
          frameBorder='0'
          allowFullScreen />
      </div>
    </div>
  );
};
