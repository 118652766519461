import React from 'react';

const TrashIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.9332 5.13338H3.06657C2.66657 5.13338 2.3999 4.86672 2.3999 4.46672C2.3999 4.06672 2.66657 3.80005 3.06657 3.80005H16.9332C17.3332 3.80005 17.5999 4.06672 17.5999 4.46672C17.5999 4.86672 17.2666 5.13338 16.9332 5.13338Z'
        fill='#ABB0B2'
      />
      <path
        d='M11.7331 4.73333C11.5331 3.93333 10.7998 3.33333 9.99977 3.33333C9.13311 3.33333 8.39977 3.93333 8.26644 4.73333L6.93311 4.46667C7.26644 3.06667 8.53311 2 9.99977 2C11.4664 2 12.7331 3.06667 12.9998 4.46667L11.7331 4.73333Z'
        fill='#ABB0B2'
      />
      <path
        d='M5.4668 18.0001C4.80013 18.0001 4.20013 17.4001 4.13346 16.7334L3.4668 5.20009C3.4668 4.80009 3.73346 4.53342 4.0668 4.46675C4.4668 4.46675 4.73346 4.73342 4.80013 5.06675L5.4668 16.6001L14.4668 16.6668L15.1335 5.06675C15.1335 4.66675 15.4668 4.40009 15.8668 4.46675C16.2668 4.46675 16.5335 4.80009 16.4668 5.20009L15.8001 16.7334C15.7335 17.4668 15.2001 18.0001 14.4668 18.0001H5.4668Z'
        fill='#ABB0B2'
      />
      <path
        d='M7.46634 15.5333C7.13301 15.5333 6.79967 15.2666 6.79967 14.9333L6.33301 6.93328C6.33301 6.53328 6.59967 6.26662 6.93301 6.19995C7.26634 6.19995 7.59967 6.46662 7.66634 6.79995L8.13301 14.7333C8.13301 15.2 7.86634 15.5333 7.46634 15.5333Z'
        fill='#ABB0B2'
      />
      <path
        d='M12.5329 15.5332C12.4662 15.5332 12.4662 15.5332 12.5329 15.5332C12.1329 15.5332 11.8662 15.1999 11.8662 14.7999L12.3329 6.86656C12.3329 6.46656 12.6662 6.19989 13.0662 6.26656C13.4662 6.26656 13.7329 6.59989 13.6662 6.99989L13.1995 14.9332C13.1329 15.2666 12.8662 15.5332 12.5329 15.5332Z'
        fill='#ABB0B2'
      />
      <path
        d='M9.99967 15.5333C9.59967 15.5333 9.33301 15.2666 9.33301 14.8666V6.93327C9.33301 6.53327 9.59967 6.2666 9.99967 6.2666C10.3997 6.2666 10.6663 6.53327 10.6663 6.93327V14.8666C10.6663 15.2666 10.333 15.5333 9.99967 15.5333Z'
        fill='#ABB0B2'
      />
    </svg>
  );
};

export default TrashIcon;
