import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { PenIcon } from 'components/icons-new/pen';

export const StyledHistoryItem = styled(Flex)<{ editable: boolean }>`
  position: relative;
  ${({ editable }) =>
    editable &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.grey50};
      }
    `};
`;

interface InFutureProps {
  inFuture: boolean;
}

export const StyledCircle = styled.div<InFutureProps>`
  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme, inFuture }) => (inFuture ? theme.colors.midBlue : theme.colors.blue)};
  border-radius: 50%;
`;

export const StyledGradientLine = styled.div<InFutureProps>`
  width: 2px;
  height: 62px;
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme, inFuture }) => (inFuture ? theme.colors.midBlue : theme.colors.blue)} 75%,
    ${({ theme, inFuture }) => (inFuture ? theme.colors.midBlue : theme.colors.blue)} 100%
  );
`;

export const StyledPenIcon = styled(PenIcon)`
  display: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2.2610347270965576px 5.32008171081543px 0 ${({ theme }) => theme.colors.grey200};
  border-radius: 5px;
  padding: 5px;
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 2.2610347270965576px 5.32008171081543px 0 ${({ theme }) => theme.colors.grey300};
  }

  ${StyledHistoryItem}:hover & {
    display: block;
  }
`;

export const StyledDataContainer = styled.div<{ startInFuture: boolean }>`
  color: ${({ startInFuture, theme }) => (startInFuture ? theme.colors.midBlue : theme.colors.blue)};
  font-size: 12px;
  margin-top: 5px;
`;
