import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const UserIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;
  return (
    <>
      <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.2742 19C15.2448 19 19.2742 14.9706 19.2742 10C19.2742 5.02944 15.2448 1 10.2742 1C5.30364 1 1.2742 5.02944 1.2742 10C1.2742 14.9706 5.30364 19 10.2742 19Z'
          fill={hovered ? fill : 'none'}
          stroke={color}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.82259 12.5186C7.62501 11.8145 7.08065 10.5807 7.08065 7.96776C7.08065 6.12422 8.35081 4.77422 10.2742 4.77422C12.1976 4.77422 13.4677 6.12422 13.4677 7.96776C13.4677 10.5807 12.9234 11.8145 11.7258 12.5186M8.53228 12.3298V13.1282C8.53228 13.5129 8.27825 13.854 7.90808 13.9629L5.62906 14.6451M12.0161 12.3298V13.1282C12.0161 13.5129 12.2701 13.854 12.6403 13.9629L14.9193 14.6451'
          stroke={color}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  );
};
