import { Flex, Text } from 'common/common-components.styled';
import { useFleetLeasingPeriodQuery } from 'hooks/react-query/fleet/use-leasing-period/use-leasing-period';
import React from 'react';

export const ReamingLasingDate = () => {
  const { fleetLeasingPeriodQuery } = useFleetLeasingPeriodQuery();
  const remainingLasing = fleetLeasingPeriodQuery.remaining;

  return (
    <Flex direction='column' align='center'>
      <Text id='reamingLasingDateValue' color='darkBlue' size='24' bold>
        {remainingLasing?.months || remainingLasing?.days || '-'}
      </Text>
      <Text id='reamingLasingDateLabel' color='grey400'>
        {remainingLasing?.months ? 'Monate' : 'Tage'}
      </Text>
    </Flex>
  );
};
