import { useEffect } from 'react';
import { EditBookingFieldNames } from 'components/pool-car-booking/form-fields/edit-booking.form-fields';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import { Booking } from 'components/pool-car-booking/edit-booking/edit-booking.types';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

const useFillBookingFields = (booking: Booking, reactHookFormData: UseFormReturn) => {
  const { setValue } = reactHookFormData;

  useEffect(() => {
    setValue(EditBookingFieldNames.EndDate, booking.uds_ruckgabedatum, defaultOptions);
    setValue(EditBookingFieldNames.StartDate, booking.uds_abholdatum, defaultOptions);
    setValue(EditBookingFieldNames.PlannedKilometers, booking.uds_kilometervoraussichtlich, defaultOptions);
    setValue(EditBookingFieldNames.LicensePlate, booking.new_fuhrparkid, defaultOptions);
  }, []);
};

export default useFillBookingFields;
