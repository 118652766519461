import React, { useEffect, useState } from 'react';
import { vehicleDetailsFields } from 'models/vehicle-offer-details-fields';
import useReleaseData from 'pages/release-external-page/hooks/use-release-data';
import cloneDeep from 'lodash/cloneDeep';
import { VehicleOfferData } from './use-release-data/use-release-data.types';
import { VehicleDetailsField } from '../release-external-page.types';
import { PicklistResponse } from 'common/interfaces';
import { EuroWithPercentageIcon } from 'components/icons-new/euro-with-percentage';

const getVehicleMainFieldsValue = (field: VehicleDetailsField, vehicleOfferData: VehicleOfferData) => {
  if (field.name === 'Leistung') {
    field.value = vehicleOfferData[field.fieldName] ? `${vehicleOfferData[field.fieldName]} kW` : null;
    return;
  }
  if (field.name === 'Hubraum') {
    field.value = vehicleOfferData[field.fieldName] ? `${vehicleOfferData[field.fieldName]} ccm` : null;
    return;
  }
  if (field.name === 'Listenpreis (brutto)') {
    field.fieldName = 'uds_listenpreis';
    field.tooltipText = 'Listenpreis (brutto)';
    field.value =
      vehicleOfferData[field.fieldName]?.value?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) || null;
    return;
  }
  if (field.name === 'Innenfarbe') {
    field.fieldName = 'uds_barpreis_netto';
    field.tooltipText = 'Barpreis (netto)';
    field.icon = <EuroWithPercentageIcon width={22} />;
    field.value =
      vehicleOfferData[field.fieldName]?.value?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) || null;
    return;
  }
  if (field.name === 'Kraftstoff' || field.name === 'Getriebe' || field.name === 'Fahrzeugaufbau') {
    field.value = vehicleOfferData[field.fieldName]
      ? (vehicleOfferData[field.fieldName] as PicklistResponse).label
      : null;
    return;
  }

  field.value = vehicleOfferData[field.fieldName] as string | null;
};

const useVehicleDetailsFields = () => {
  const [vehicleMainDetailsFields, setVehicleMainDetailsFields] = useState<VehicleDetailsField[][]>(
    vehicleDetailsFields as VehicleDetailsField[][]
  );

  const { vehicleOfferData } = useReleaseData();

  useEffect(() => {
    if (vehicleOfferData) {
      const newVehicleMainDetailsFields = cloneDeep(vehicleMainDetailsFields);
      newVehicleMainDetailsFields.forEach(section => {
        section.forEach(field => {
          getVehicleMainFieldsValue(field, vehicleOfferData);
        });
      });
      setVehicleMainDetailsFields(newVehicleMainDetailsFields);
    }
  }, [vehicleOfferData]);

  return { vehicleMainDetailsFields };
};

export default useVehicleDetailsFields;
