import React from 'react';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { StyledInfoHintWithoutBackground } from 'components/info-hint/info-hint-view.styled';
import { ConsumptionEstimateValue } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id.props';
import { Text } from 'common/common-components.styled';

export const ConsumptionInfoHint = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const iconColor =
    fuhrpark?.uds_consumption_estimate_value_value === ConsumptionEstimateValue.Increased ? 'darkGold' : 'green';

  return (
    <StyledInfoHintWithoutBackground type='warning' iconSize={12} iconColor={iconColor}>
      <Text color='grey600' size='12'>
        {fuhrpark?.uds_consumption_estimate_value || ''}
      </Text>
    </StyledInfoHintWithoutBackground>
  );
};
