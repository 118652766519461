import React from 'react';
import { UnsavedModalContent, UnsavedModalType } from './unsaved-changes.props';
import { Text } from 'common/common-components.styled';

export const getUnsavedChangesModalContent = (type: UnsavedModalType): UnsavedModalContent => {
  switch (type) {
    case UnsavedModalType.BeforeEditNextField:
      return {
        title: 'Nicht gespeicherte Daten',
        description: (
          <>
            <Text>Bitte beachte, dass die Daten im vorher bearbeiteten Feld nicht gespeichert wurden.</Text>
            <Text top='20'>
              Möchtest du die vorherigen Änderungen speichern, bevor du mit der Bearbeitung des nächsten Felds beginnst?
            </Text>
          </>
        ),
        submitTitle: 'Ja, speichern',
        cancelTitle: 'Nein, abbrechen'
      };

    default:
      return {
        title: 'Nicht gespeicherte Änderungen',
        description: <span>Möchten Sie Ihre Änderungen speichern, bevor Sie diese Seite verlassen?</span>,
        submitTitle: 'Speichern und fortfahren',
        cancelTitle: 'Änderungen verwerfen'
      };
  }
};
