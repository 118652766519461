import React from 'react';
import PropTypes from 'prop-types';

export const UserWithStarIcon = ({ color = '#8FAFBF', width = '24', height = '24' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_267_3375)'>
        <g clipPath='url(#clip1_267_3375)'>
          <g clipPath='url(#clip2_267_3375)'>
            <g clipPath='url(#clip3_267_3375)'>
              <path d='M13.1198 17.3198C11.8598 15.7798 9.8998 14.7998 7.7998 14.7998C3.8798 14.7998 0.799805 17.8798 0.799805 21.7998C0.799805 22.2198 0.939805 22.4998 1.2198 22.7798C1.4998 23.0598 1.7798 23.1998 2.1998 23.1998H11.9998H13.3998C14.2398 23.1998 14.7998 22.6398 14.7998 21.7998C14.7998 20.1198 14.0998 18.5798 13.1198 17.3198Z' fill={color} />
              <path d='M7.79961 14.7994C5.41961 14.7994 3.59961 12.9794 3.59961 10.5994C3.59961 8.21941 5.41961 6.39941 7.79961 6.39941C10.1796 6.39941 11.9996 8.21941 11.9996 10.5994C11.9996 12.9794 10.1796 14.7994 7.79961 14.7994Z' fill={color} />
            </g>
            <path d='M20.6249 11.9999C20.5129 11.9999 20.4009 11.9719 20.3029 11.9159L17.6009 10.4879L14.8989 11.9159C14.6609 12.0419 14.3809 12.0279 14.1569 11.8599C13.9469 11.7059 13.8349 11.4399 13.8769 11.1739L14.3949 8.16387L12.2109 6.02188C12.0149 5.83988 11.9589 5.55988 12.0289 5.30788C12.1129 5.05587 12.3369 4.87388 12.5889 4.83188L15.6129 4.39788L16.9709 1.65387C17.2089 1.17787 17.9929 1.17787 18.2309 1.65387L19.5889 4.39788L22.6129 4.83188C22.8789 4.87388 23.0889 5.05587 23.1729 5.30788C23.2569 5.55988 23.1869 5.83988 22.9909 6.02188L20.8069 8.16387L21.3249 11.1739C21.3669 11.4399 21.2689 11.7059 21.0449 11.8599C20.9189 11.9579 20.7789 11.9999 20.6249 11.9999Z' fill={color} />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_267_3375'>
          <rect width='22.4' height='22.4' fill='white' transform='translate(0.799805 0.799805)' />
        </clipPath>
        <clipPath id='clip1_267_3375'>
          <rect width='22.4' height='22.4' fill='white' transform='translate(0.799805 0.799805)' />
        </clipPath>
        <clipPath id='clip2_267_3375'>
          <rect width='22.4' height='22.4' fill='white' transform='translate(0.799805 0.799805)' />
        </clipPath>
        <clipPath id='clip3_267_3375'>
          <rect width='22.4' height='16.8' fill='white' transform='translate(0.799805 6.39941)' />
        </clipPath>
      </defs>
    </svg>

  );
};

UserWithStarIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
