import React from 'react';

import classes from './vehicle-list-header.module.scss';
import ProposalFor from './proposal-for';
import Sorting from '../../../../components/sorting';
import classnames from "classnames";

const options = [
  { value: 'createdon-true', label: 'Neueste zuerst' },
  { value: 'uds_mobexo_gesamtrate-false', label: 'Leasingrate aufsteigend' },
  { value: 'uds_mobexo_gesamtrate-true', label: 'Leasingrate absteigend' }
];

function VehicleListHeader({ sidebarOpen, toggleSidebar, filterCount }) {
  return (
    <div className={classes.container}>
      <div
        className={classnames(classes.btn, 'pointer')}
        style={{ width: '45%', maxWidth: '60px' }}
        onClick={() => toggleSidebar(!sidebarOpen)}
      >
        <i className='fas fa-filter' /> Filter
        {filterCount > 0 && <span className={classnames(classes.filter_count)}>{filterCount}</span>}
      </div>
      <ProposalFor />
      <div id='sorting' className={classes.sort}>
        <Sorting options={options} />
      </div>
    </div>
  );
}

export default VehicleListHeader;
