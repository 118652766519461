export const insuranceFormFields = {
  columns: [
    {
      width: '50%',
      fields: [
        {
          name: 'new_versicherung_mobexo',
          type: 'number',
          placeholder: 'Betrag *',
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_versicherung_sb_teilkasko',
          type: 'number',
          placeholder: 'SB Teilkasto *',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '50%',
      fields: [
        {
          name: 'new_versicherung_dienstleister',
          type: 'lookup',
          data: [],
          source: 'getInsuranceCompanies',
          placeholder: 'Versicherung *',
          defaultValue: null,
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_versicherung_sb_vollkasko',
          type: 'number',
          placeholder: 'SB Vollkasko *',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const gapInsuranceFormFields = {
  columns: [
    {
      width: '50%',
      fields: [
        {
          name: 'uds_gap',
          type: 'number',
          placeholder: 'Betrag *',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '50%',
      fields: [
        {
          name: 'new_gap_dienstleister',
          type: 'lookup',
          data: [],
          source: 'getInsuranceCompanies',
          placeholder: 'Versicherung *',
          defaultValue: null,
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};
