/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import countries from '../../../helpers/country-codes.json';
import CloseIcon from '../icons/close';
import { ComponentsProps } from './components.props';

const PhoneFieldComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldName,
  zeroName = false,
  optional = false,
  optionalText = '(optional)',
  close = false,
  closeValue = '',
  readOnly = false
}: ComponentsProps) => {
  const [textValue, setTextValue] = useState('');
  const registerWithCode = `${fieldRegister}Code`;
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = reactHookFormData;

  const checkNumber = /^\d{1,15}$/;

  const changeValue = (value: string) => {
    const sanitizedValue = value.startsWith('0') ? value.slice(1) : value;
    setTextValue(sanitizedValue);
    setValue(fieldRegister, sanitizedValue);
  };

  useEffect(() => {
    if (watch(fieldRegister)) {
      if (watch(fieldRegister).search(checkNumber) === -1) {
        setError(fieldRegister, {
          type: 'manual',
          message: 'Das Feld darf nur Zahlen enthalten.'
        });
      } else {
        clearErrors(fieldRegister);
      }
    }
    if (watch(fieldRegister) === '') {
      clearErrors(fieldRegister);
    }
  }, [watch(fieldRegister)]);

  const shouldCloseStyle = close ? styles.form_name_row : styles.form_name;

  return (
    <div className={styles.field_container}>
      {!zeroName && (
        <div className={readOnly ? styles.form_name_block : shouldCloseStyle}>
          <span>
            {fieldName} {optional && <span className={styles.optional}>{optionalText}</span>}{' '}
          </span>
          {close && (
            <div
              className={styles.close_block}
              onClick={() => {
                setValue(closeValue, false);
                clearErrors(fieldRegister);
              }}
              id='removePhoneButton'
            >
              <CloseIcon />
            </div>
          )}
        </div>
      )}
      <div className={styles.input__block}>
        <div className={styles.input__block__img}>
          <select
            {...register(registerWithCode, { required: !optional || !readOnly })}
            defaultValue={'+49'}
            disabled={readOnly}
            className={errors[registerWithCode] ? styles.input__icons_err : styles.select__code}
            id='selectPhoneNumbers'
          >
            <>
              {countries.map(item => (
                <option id={`optionPhone${item.dial_code}`} key={item.name} value={item.dial_code}>
                  {`${item.dial_code}`}
                </option>
              ))}
            </>
          </select>
        </div>
        <input
          {...register(fieldRegister, {
            required: optional || readOnly ? false : 'Telefon erforderlich.',
            pattern: {
              value: /^\d{1,15}$/,
              message: 'Das Feld darf nur Zahlen enthalten.'
            },
            maxLength: 15
          })}
          placeholder='0 00 00 00 00 00'
          value={watch(fieldRegister) ? watch(fieldRegister) : textValue}
          onChange={e => changeValue(e.target.value)}
          maxLength={15}
          autoComplete='off'
          type='text'
          readOnly={readOnly}
          className={
            errors[fieldRegister]
              ? styles.input__icons_err__tel
              : readOnly
              ? styles.input__icons_block
              : styles.input__icons
          }
          id={`${fieldName}PhoneInput`}
        />
        <div className={styles.err__row}>
          {errors[fieldRegister]?.message && typeof errors[fieldRegister]?.message === 'string' && (
            <>
              <img src='/assets/images/err-icon.svg' />
              <p>{String(errors[fieldRegister]?.message)}</p>
            </>
          )}
        </div>
      </div>

      <Distance top='10' />
    </div>
  );
};

export default PhoneFieldComponent;
