import { useSessionState } from 'hooks/use-session-state';
import { PaginationState } from './use-pagination-state.types';
import { useCallback } from 'react';
import { PAGES } from 'common/enums';

const usePaginationState = (page?: PAGES) => {
  const { state: paginationState, setSessionState: setPaginationState } = useSessionState<PaginationState>(
    PAGINATION_STATE_KEY,
    {}
  );

  const defaultPage = page ? paginationState[page] || 1 : 1;

  const setDefaultPage = useCallback(
    (pageNumber: number) => {
      if (!page) {
        return;
      }
      setPaginationState({ ...paginationState, [page]: pageNumber });
    },
    [paginationState, setPaginationState]
  );

  const clearDefaultPage = useCallback(
    (pageToClear: PAGES) => {
      delete paginationState[pageToClear];
      setPaginationState(paginationState);
    },
    [paginationState, setPaginationState]
  );

  return { defaultPage, setDefaultPage, clearDefaultPage };
};

const PAGINATION_STATE_KEY = 'pagination-state';

export default usePaginationState;
