/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import EmailFieldComponent from '../components/email-field-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TrashIcon from '../icons/trash-icon';
import useWindowSize from 'hooks/use-window-size';
import { StepProps, StepStylesProps } from '../report-damage.props';

const StepFourBurglaryDamage = ({ reactHookFormData, handleBack, handleNext }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockKeys, setShowBlockKeys] = useState<boolean>(false);
  const [showBlockWitnesses, setShowBlockWitnesses] = useState<boolean>(false);
  const [showBlockNeedPolice, setShowBlockNeedPolice] = useState<boolean>(false);
  const [showBlockNeedPoliceGetCaseNumber, setShowBlockNeedPoliceGetCaseNumber] = useState<boolean>(false);
  const [showBlockNeedPoliceCriminalWarning, setShowBlockNeedPoliceCriminalWarning] = useState<boolean>(false);
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
  }, []);

  useEffect(() => {
    setTimeout(() => (watch('youHaveAllKeys') === false ? setShowBlockKeys(true) : setShowBlockKeys(false)), 100);
  }, [watch('youHaveAllKeys')]);

  const youHaveAllKeysFields =
    (watch('youHaveAllKeys') === false && !!watch('whereIsKeys') && watch('whereIsKeys').length > 0) ||
    !!watch('youHaveAllKeys');

  useEffect(() => {
    setTimeout(() => (!!watch('anyWitnesses') ? setShowBlockWitnesses(true) : setShowBlockWitnesses(false)), 100);
  }, [watch('anyWitnesses')]);

  useEffect(() => {
    setTimeout(() => (!!watch('needPolice') ? setShowBlockNeedPolice(true) : setShowBlockNeedPolice(false)), 100);
  }, [watch('needPolice')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceGetCaseNumber')
          ? setShowBlockNeedPoliceGetCaseNumber(true)
          : setShowBlockNeedPoliceGetCaseNumber(false),
      100
    );
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    setTimeout(
      () =>
        !!watch('needPoliceCriminalWarning')
          ? setShowBlockNeedPoliceCriminalWarning(true)
          : setShowBlockNeedPoliceCriminalWarning(false),
      100
    );
  }, [watch('needPoliceCriminalWarning')]);

  const needPolice =
    (!!watch('needPolice') &&
      !!watch('needPoliceDienststelle') &&
      watch('needPoliceDienststelle').length > 0 &&
      watch('needPoliceGetCaseNumber') !== undefined &&
      watch('needPoliceCriminalWarning') !== undefined &&
      watch('needPoliceComplaintFiled') !== undefined) ||
    watch('needPolice') === false;

  const needPoliceCaseNumber =
    (!!watch('needPolice') &&
      (watch('needPoliceGetCaseNumber') === false ||
        (!!watch('needPoliceGetCaseNumber') &&
          !!watch('needPoliceGetCaseNumberAktenzeichen') &&
          watch('needPoliceGetCaseNumberAktenzeichen').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceCriminalWarning =
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') &&
          !!watch('needPoliceCriminalWarningWho') &&
          watch('needPoliceCriminalWarningWho').length > 0))) ||
    watch('needPolice') === false;

  const anyWitnessesFields =
    watch('anyWitnesses') === false ||
    !!watch('anyWitnessesDateEmpty') ||
    (!!watch('anyWitnessesName') &&
      watch('anyWitnessesName').length > 0 &&
      !!watch('anyWitnessesAdress') &&
      watch('anyWitnessesAdress').length > 0 &&
      !!watch('anyWitnessesEmail') &&
      watch('anyWitnessesEmail').length > 0 &&
      !!watch('anyWitnessesPhone') &&
      watch('anyWitnessesPhone').length > 0);

  const anyWitnessesFieldsSecond =
    watch('anyWitnesses') === false ||
    !watch('showSecondPersonAnyWitnesses') ||
    (!!watch('showSecondPersonAnyWitnesses') && !!watch('anyWitnessesDateSecondEmpty')) ||
    (!!watch('anyWitnessesNameSecond') &&
      watch('anyWitnessesNameSecond').length > 0 &&
      !!watch('anyWitnessesAdressSecond') &&
      watch('anyWitnessesAdressSecond').length > 0 &&
      !!watch('anyWitnessesEmailSecond') &&
      watch('anyWitnessesEmailSecond').length > 0 &&
      !!watch('anyWitnessesPhoneSecond') &&
      watch('anyWitnessesPhoneSecond').length > 0);

  useEffect(() => {
    !!watch('howToVehicleWasSecured') &&
    watch('howToVehicleWasSecured').length > 0 &&
    youHaveAllKeysFields &&
    watch('needPolice') !== undefined &&
    needPolice &&
    needPoliceCaseNumber &&
    needPoliceCriminalWarning &&
    anyWitnessesFields &&
    anyWitnessesFieldsSecond &&
    !!watch('detailDamageComment') &&
    watch('detailDamageComment').length > 0 &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
  }, [watch()]);

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  let slideClassName: StepStylesProps = watch('previousStep') === 3 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 4), 500);
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      checkButtons(['needPolice', 'youHaveAllKeys', 'anyWitnesses']);
      !!watch('needPolice') &&
        checkButtons([
          'needPoliceGetCaseNumber',
          'needPoliceCriminalWarning',
          'needPoliceComplaintFiled',
          'needPoliceDrunkDriverWasTested',
          'needPoliceDrunkDrugsTest'
        ]);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        checkButtons(['needPoliceDrunkDrugsTestResultFirst']);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        !!watch('showSecondDrugPerson') &&
        checkButtons(['needPoliceDrunkDrugsTestResultSecond']);
    }
  };

  const moveToPreviousStep = () => {
    if (handleBack) {
      setValue('previousStep', 4);
      setShowBlock(false);
      setTimeout(() => {
        handleBack();
      }, 500);
    }
  };

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') === false) {
      clearErrors('needPoliceGetCaseNumberAktenzeichen');
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') === false) {
      clearErrors('needPoliceCriminalWarningWho');
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') === false) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') === false) {
      clearErrors('needPoliceDrunkDrugsTestWhoWasTestedFirst');
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('needPolice') === false) {
      clearErrors([
        'needPoliceDienststelle',
        'needPoliceCriminalWarningWho',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDienststelle',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond',
        'needPoliceGetCaseNumberAktenzeichen',
        'needPoliceAdressAndOffice',
        'needPoliceComplaintFiled',
        'needPoliceCriminalWarning',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTest',
        'needPoliceGetCaseNumber'
      ]);
    }
  }, [watch('needPolice')]);

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') !== undefined) {
      clearErrors(['needPoliceGetCaseNumberAktenzeichen', 'needPoliceGetCaseNumber']);
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') !== undefined) {
      clearErrors(['needPoliceCriminalWarningWho', 'needPoliceCriminalWarning']);
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') !== undefined) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') !== undefined) {
      clearErrors([
        'needPoliceDrunkDrugsTest',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (!!watch('youHaveAllKeys')) {
      clearErrors('whereIsKeys');
    }
  }, [watch('youHaveAllKeys')]);

  useEffect(() => {
    if (!watch('anyWitnesses')) {
      clearErrors([
        'anyWitnessesAdress',
        'anyWitnessesEmail',
        'anyWitnessesName',
        'anyWitnessesPhone',
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('anyWitnesses')]);

  useEffect(() => {
    if (!watch('showSecondPersonAnyWitnesses')) {
      clearErrors([
        'anyWitnessesAdressSecond',
        'anyWitnessesEmailSecond',
        'anyWitnessesNameSecond',
        'anyWitnessesPhoneSecond'
      ]);
    }
  }, [watch('showSecondPersonAnyWitnesses')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 4 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='howToVehicleWasSecured'
          fieldName='Wie war das Fahrzeug gegen Diebstahl gesichert?'
          // needTooltip={true}
          // tooltipPosition='bottom'
          // tooltipText=''
        />

        <Distance top='15' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Liegen dir alle vorhandenen Fahrzeugschlüssel vor?'
          fieldRegister='youHaveAllKeys'
          fieldRegisterSecond='youHaveAllKeys'
        />

        <Distance top='20' />

        {watch('youHaveAllKeys') === false && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockKeys} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='whereIsKeys'
                  fieldName='Wo befinden sich die Fahrzeugschlüssel?'
                />

                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Wurde ein Polizeibericht aufgenommen?'
          fieldRegister='needPolice'
          fieldRegisterSecond='needPolice'
        />

        <Distance top='20' />

        {watch('needPolice') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockNeedPolice} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Details zur polizeilichen Unfallaufnahme:</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceDienststelle'
                  fieldName='Dienststelle'
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceAdressAndOffice'
                  fieldName='Anschrift und Telefonnummer der Dienststelle'
                  fieldPlaceholder='Musterstr. 100, 12345 Berlin, Tel.'
                  optional={true}
                />

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Hast du ein Aktenzeichen erhalten?'
                  fieldRegister='needPoliceGetCaseNumber'
                  fieldRegisterSecond='needPoliceGetCaseNumber'
                />
                <Distance top='10' />

                {!!watch('needPoliceGetCaseNumber') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceGetCaseNumber}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <Distance top='10' />
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceGetCaseNumberAktenzeichen'
                          fieldName='Aktenzeichen/Tagebuchnummer'
                        />
                        <Distance top='-10' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde eine gebührenpflichtige Verwarnung ausgesprochen?'
                  fieldRegister='needPoliceCriminalWarning'
                  fieldRegisterSecond='needPoliceCriminalWarning'
                />
                <Distance top='20' />

                {!!watch('needPoliceCriminalWarning') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceCriminalWarning}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWho'
                          fieldName='Wer hat die gebührenpflichtige Verwarnung erhalten?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde Anzeige erstattet?'
                  fieldRegister='needPoliceComplaintFiled'
                  fieldRegisterSecond='needPoliceComplaintFiled'
                />
                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Sind dir Zeugen bekannt?'
          fieldRegister='anyWitnesses'
          fieldRegisterSecond='anyWitnesses'
        />

        <Distance top='20' />

        {watch('anyWitnesses') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockWitnesses} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Daten der Zeuge:</h6>

                <div
                  className={styles.checkbox}
                  onClick={() =>
                    clearErrors(['anyWitnessesName', 'anyWitnessesAdress', 'anyWitnessesEmail', 'anyWitnessesPhone'])
                  }
                >
                  <input
                    {...register('anyWitnessesDateEmpty', { required: false })}
                    id='anyWitnessesDateEmpty'
                    type='checkbox'
                  />
                  <label htmlFor='anyWitnessesDateEmpty'>Daten unbekannt</label>
                </div>
                <Distance top='15' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesName'
                  fieldName='Name'
                  fieldPlaceholder='Max Mustermann'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <EmailFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesEmail'
                  fieldName='E-mail adresse'
                  fieldPlaceholder='Muster@mail.com'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyWitnessesPhone'
                  fieldName='Telefon'
                  optional={!!watch('anyWitnessesDateEmpty')}
                  optionalText=''
                  readOnly={!!watch('anyWitnessesDateEmpty')}
                />
                <Distance top='10' />

                <div
                  style={{ width: '100%' }}
                  className={!!watch('showSecondPersonAnyWitnesses') ? '' : styles.hide__block}
                >
                  <Distance top='15' />

                  <div className={styles.close_header}>
                    <h6>Daten der zweite Zeuge:</h6>
                    <div
                      id='deleteSecondPersonAnyWitnessesButton'
                      className={styles.close_block}
                      onClick={() => {
                        setValue('showSecondPersonAnyWitnesses', false);
                        clearErrors([
                          'anyWitnessesNameSecond',
                          'anyWitnessesAdressSecond',
                          'anyWitnessesEmailSecond',
                          'anyWitnessesPhoneSecond'
                        ]);
                      }}
                    >
                      <TrashIcon />
                    </div>
                  </div>

                  <div
                    className={styles.checkbox}
                    onClick={() =>
                      clearErrors([
                        'anyWitnessesNameSecond',
                        'anyWitnessesAdressSecond',
                        'anyWitnessesEmailSecond',
                        'anyWitnessesPhoneSecond'
                      ])
                    }
                  >
                    <input
                      {...register('anyWitnessesDateSecondEmpty', { required: false })}
                      id='anyWitnessesDateSecondEmpty'
                      type='checkbox'
                    />
                    <label htmlFor='anyWitnessesDateSecondEmpty'>Daten unbekannt</label>
                  </div>
                  <Distance top='15' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesNameSecond'
                    fieldName='Name'
                    fieldPlaceholder='Max Mustermann'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesAdressSecond'
                    fieldName='Anschrift'
                    fieldPlaceholder='Musterstr. 100 12345 Berlin'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesEmailSecond'
                    fieldName='E-mail adresse'
                    fieldPlaceholder='Muster@mail.com'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='5' />

                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anyWitnessesPhoneSecond'
                    fieldName='Telefon'
                    optional={!watch('showSecondPersonAnyWitnesses') || !!watch('anyWitnessesDateSecondEmpty')}
                    optionalText=''
                    readOnly={!!watch('anyWitnessesDateSecondEmpty')}
                  />
                  <Distance top='10' />
                </div>

                <div
                  id='showSecondPersonAnyWitnessesButton'
                  style={{ width: '100%' }}
                  onClick={() => setValue('showSecondPersonAnyWitnesses', true)}
                  className={!!watch('showSecondPersonAnyWitnesses') ? styles.hide__block : styles.hide__block__show}
                >
                  <Distance top='-10' />+ Weitere Person
                </div>

                <Distance top='10' />
              </div>
            </CSSTransition>
          </div>
        )}

        <Distance top={watch('anyWitnesses') === true ? '20' : '0'} />

        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='detailDamageComment'
          fieldName='Ausführliche Schadenschilderung'
          fieldPlaceholder='Bitte schreib, wie der Schaden aufgetreten ist.'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte fertige die Schilderung ausführlich an. Aus deiner Beschreibung muss klar hervorgehen, wer den Schaden verschuldet hat oder ob du ggf. eine Mitschuld tragen musst oder der Unfall für dich - und aus welchen Gründen? - unabwendbar war. Bitte beachte, dass du zur wahrheitsgemäßen Unfallschilderung verpflichtet bist, da ansonsten der Versicherungsschutz erlischt. '
        />

        <Distance top='15' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default StepFourBurglaryDamage;
