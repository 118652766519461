import React from 'react';
import { toggleModal } from 'actions/app_action';
import { Flex, Text } from 'common/common-components.styled';
import FormField from 'components/form-hook/form-field/form-field';
import { MODALS } from '../../../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { examinationDateFormField } from './use-complete-task.form-field';
import { CompleteTaskFormData, CompleteTaskModalContentProps } from './use-complete-task.props';
import Button from 'components/button/button';
import { crmTaskRequestConfig } from 'request-config/crm-task/crm-task.request-config';
import moment from 'moment';
import { useQueryClient } from 'react-query';

export const CompleteTaskModalContent = ({ submitData }: CompleteTaskModalContentProps) => {
  const dispatch = useDispatch();

  const { fetch } = useFetch({
    loadingKey: 'setDoneStatus',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Aufgabe wurde erfolgreich erledigt',
    errorMessage: 'Änderungen wurden nicht übernommen.'
  });

  const reactHookFormData = useForm<CompleteTaskFormData>();
  const { handleSubmit } = reactHookFormData;

  const queryClient = useQueryClient();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const onSubmitHandler = handleSubmit(value => {
    closeAlert();
    fetch({
      requestConfig: crmTaskRequestConfig.setDoneStatus(
        moment(value.scheduledend).local().format('YYYY-MM-DD'),
        submitData
      ),
      callback: () => queryClient.clear()
    });
  });

  return (
    <div>
      <Text bottom='20'>Bitte wähle ein Datum, an dem die Untersuchung durchgeführt wurde:</Text>
      <Flex width='50%'>
        <FormField reactHookFormData={reactHookFormData} field={examinationDateFormField} />
      </Flex>

      <Button margin='20px 0 0 auto' onClick={onSubmitHandler}>
        Speichern
      </Button>
    </div>
  );
};
