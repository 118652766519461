import React, { useState } from 'react';
import { RequestConfirmationProps } from './request-confirmation.types';
import { Button, Distance, Flex, Text } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { errorMessages } from 'utils/error-messages';
import InfoHint from 'components/info-hint/info-hint-view';
import { InfoHintType } from 'components/info-hint/info-hint.props';
import useOldestEvents from 'hooks/use-oldest-events';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleOfferRequestConfig } from 'request-config/vehicle-offer/vehicle-offer.request-config';
import { ConfirmOrderByCarDealerData } from 'request-config/vehicle-offer/vehicle-offer.type';

const RequestConfirmation = ({ events, refreshLeasingOffers, vehicleOfferId }: RequestConfirmationProps) => {
  const [declineReasonField, showDeclineReasonField] = useState(false);

  const { orderAcceptedByDealerEvent, orderRejectedByDealerEvent } = useOldestEvents(events);
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;
  const { fetch } = useFetch({ loadingKey: 'confirmOrderByCarDealer' });

  const isRequestDone = orderAcceptedByDealerEvent || orderRejectedByDealerEvent;

  const decline = () => {
    handleSubmit(data => {
      const dataToSend: ConfirmOrderByCarDealerData = {
        fahrzeugangeboteId: vehicleOfferId,
        notiz: data.declineReason,
        status: false
      };

      showDeclineReasonField(false);
      fetch({
        requestConfig: vehicleOfferRequestConfig.confirmOrderByCarDealer(dataToSend),
        callback: refreshLeasingOffers
      });
    })();
  };

  const submit = () => {
    const dataToSend: ConfirmOrderByCarDealerData = {
      fahrzeugangeboteId: vehicleOfferId,
      notiz: '',
      status: true
    };

    fetch({
      requestConfig: vehicleOfferRequestConfig.confirmOrderByCarDealer(dataToSend),
      callback: refreshLeasingOffers
    });
  };

  return (
    <Flex padding='0 20px 20px' direction='column'>
      <Text color='grey500'>
        Der Fuhrparkleiter fragt diese Konfiguration an. Nimm die Bestellung an oder lehne sie ab.
      </Text>

      {declineReasonField && (
        <>
          <Distance top='30' />
          <FormHook
            reactHookFormData={reactHookFormData}
            distanceBetweenFields='0'
            formFields={[
              generateFormField.textArea({
                label: 'Ablehnungsgrund',
                name: 'declineReason',
                validation: {
                  required: errorMessages.required
                }
              })
            ]}
          />
        </>
      )}

      {isRequestDone && (
        <>
          <Distance top='30' />
          <InfoHint type={InfoHintType.Info}>
            {orderAcceptedByDealerEvent
              ? `Die Bestellung wurde am ${getDateString(
                  orderAcceptedByDealerEvent?.createdon.attributeValue
                )} angenommen.`
              : `Die Bestellung wurde am ${getDateString(
                  orderRejectedByDealerEvent?.createdon.attributeValue
                )} abgelehnt.`}
          </InfoHint>
        </>
      )}

      {!isRequestDone && (
        <Flex top='30' justify='flex-end'>
          {declineReasonField ? (
            <>
              <Button right='10' secondary onClick={() => showDeclineReasonField(false)}>
                Zurück
              </Button>
              <Button disabled={!isValid} onClick={decline}>
                Bestellung ablehnen
              </Button>
            </>
          ) : (
            <>
              <Button right='10' secondary onClick={() => showDeclineReasonField(true)}>
                Bestellung ablehnen
              </Button>
              <Button onClick={submit}>Bestellung annehmen</Button>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default RequestConfirmation;
