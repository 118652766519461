import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const StyledPaginationGap = styled(Flex)`
  max-width: 138px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
`;

export const StyledPaginationGapContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);
`;

export const BottomTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 9px 0 9px;
  border-color: ${({ theme }) => theme.colors.white} transparent transparent transparent;
  margin: 0 auto;
`;
