import React, { cloneElement } from 'react';
import { IconHoverProps } from './icon-hover.props';
import { StyledIconHoverContainer } from 'components/icon-hover/icon-hover.styled';

const IconHover = ({ children, hoveredColor, ...rest }: IconHoverProps) => {
  const iconId = children.props.id;
  return (
    <StyledIconHoverContainer {...rest}>
      <div className='icon-hovered'>
        {cloneElement(children, {
          hovered: true,
          color: hoveredColor || children.props.color,
          id: iconId && `${iconId}Hovered`
        })}
      </div>
      <div className='icon'>{cloneElement(children, { hovered: false })}</div>
    </StyledIconHoverContainer>
  );
};

export default IconHover;
