import React from 'react';
import {
  StyledCardContainer,
  StyledCardHeader,
  StyledCardContent,
  StyledPriceWrapper,
  StyledLine,
  StyledCheckoutButton,
  StyledCardTextWrapper,
  StyledDescription,
  StyledPriceWithText,
  StyledOriginalPriceContainer,
  BenefitsContainer,
  BenefitsTitle,
  BenefitsTitleWrapper,
  StyledButtonWrapper,
  BenefitsWrapper,
  BenefitContainer,
  SubBenefitContainer,
  SubBenefitWrapper,
  BenefitSpan,
  ExtraSectionWrapper
} from './checkout-card.styled';
import { Flex, Text } from 'common/common-components.styled';
import { AnyObject } from 'common/interfaces';
import { CheckoutCardProps } from './checkout-card.props';
import { buttonTypes, cardsBackgrounds, cardsColors } from './constants';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import { PackageOption } from 'components/checkout-component/checkout-component.props';
import { PackageTypes } from 'common/enums';
import { Checked } from 'components/icons-new/checked';
import { PlusIcon } from 'components/icons-new/plus';

const getBackgroundColor = (packageName: string) => {
  return (cardsBackgrounds as AnyObject)[packageName];
};

const getColor = (packageName: string) => {
  return (cardsColors as AnyObject)[packageName];
};

const getButtonType = (currentCard: string) => {
  return (buttonTypes.trial as AnyObject)[currentCard];
};

const CheckoutCard = ({
  isHeader,
  header,
  packageName,
  packageDescription,
  price,
  isDiscount,
  priceWithDiscount,
  pricingDetails,
  buttonText,
  onClick,
  isBenefitsExist,
  fee,
  packageOptions,
  extraOptions,
  packageOptionsMinHeight,
  extraOptionsMinHeight,
  width
}: CheckoutCardProps) => {
  const buttonType = getButtonType(packageName);
  const theme = useTheme();
  const isProfessionalOrComfortPackage =
    packageName === PackageTypes.Professional || packageName === PackageTypes.Comfort;

  const getDescriptions = () => {
    return packageOptions?.mainOptions?.map((mainOption: PackageOption) => {
      return (
        <>
          <BenefitContainer key={mainOption.name}>
            <Flex minWidth='15px' right='15'>
              <Checked width='15' color={theme.colors.emeraldGreen} />
            </Flex>
            <BenefitSpan isBold={mainOption.isBold}>{mainOption.name}</BenefitSpan>
          </BenefitContainer>
          {mainOption.childOptions && (
            <SubBenefitContainer>
              {mainOption.childOptions.map((childOption: PackageOption) => (
                <SubBenefitWrapper key={childOption.name}>
                  <Flex minWidth='15px' right='15'>
                    <Checked width='15' color={theme.colors.emeraldGreen} />
                  </Flex>
                  <BenefitSpan isBold={childOption.isBold}>{childOption.name}</BenefitSpan>
                </SubBenefitWrapper>
              ))}
            </SubBenefitContainer>
          )}
        </>
      );
    });
  };

  return (
    <StyledCardContainer width={width} isHeader={isHeader}>
      {isHeader && (
        <StyledCardHeader>
          <Text color='white' size='14' textAlign='center'>
            {header}
          </Text>
        </StyledCardHeader>
      )}
      <StyledCardContent
        backgroundColor={getBackgroundColor(packageName)}
        color={getColor(packageName)}
        isHeader={isHeader}
      >
        <StyledCardTextWrapper>
          <Text color={isProfessionalOrComfortPackage ? 'emeraldGreen' : undefined} size='24' bold bottom='30'>
            {packageName}
          </Text>
          <StyledDescription>{packageDescription}</StyledDescription>
          <StyledPriceWithText>
            <StyledPriceWrapper isDiscount={isDiscount}>
              {isDiscount && (
                <StyledOriginalPriceContainer>
                  <NumberFormat
                    style={{ fontSize: '30px' }}
                    color={theme.colors.darkBlue}
                    value={price}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                  <StyledLine color={isProfessionalOrComfortPackage ? 'white' : 'blackPearl'} />
                </StyledOriginalPriceContainer>
              )}
              {price !== null ? (
                <NumberFormat
                  style={{ fontSize: '64px' }}
                  color={theme.colors.darkBlue}
                  value={isDiscount ? priceWithDiscount : price}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={'€'}
                />
              ) : (
                <Text size='22' bold padding='30px'>
                  Preis auf Anfrage
                </Text>
              )}
            </StyledPriceWrapper>
            <>
              <Text>{pricingDetails}</Text>
              <Text top='15'>{fee}</Text>
            </>
          </StyledPriceWithText>
        </StyledCardTextWrapper>

        <StyledButtonWrapper>
          <StyledCheckoutButton type={buttonType} onClick={onClick}>
            {buttonText.toUpperCase()}
          </StyledCheckoutButton>
        </StyledButtonWrapper>

        {isBenefitsExist && (
          <BenefitsContainer>
            <BenefitsTitleWrapper>
              <BenefitsTitle>Mobexo Fleetmanager</BenefitsTitle>
            </BenefitsTitleWrapper>

            <BenefitsWrapper minHeight={packageOptionsMinHeight} packageName={packageName}>
              {getDescriptions()}
            </BenefitsWrapper>

            <BenefitsTitleWrapper>
              <BenefitsTitle>Kostenpflichtige Zusatzpakete</BenefitsTitle>
            </BenefitsTitleWrapper>

            <ExtraSectionWrapper minHeight={extraOptionsMinHeight}>
              {!!extraOptions.length && (
                <BenefitsWrapper extraSection={true} packageName={packageName}>
                  {extraOptions.map((extraOption: string) => (
                    <BenefitContainer key={extraOption}>
                      <Flex minWidth='20px' right='15'>
                        <PlusIcon
                          width='20'
                          strokeWidth='2'
                          color={isProfessionalOrComfortPackage ? theme.colors.white : theme.colors.blackPearl}
                        />
                      </Flex>
                      <span>{extraOption}</span>
                    </BenefitContainer>
                  ))}
                </BenefitsWrapper>
              )}
            </ExtraSectionWrapper>
          </BenefitsContainer>
        )}

        {isBenefitsExist && (
          <StyledButtonWrapper>
            <StyledCheckoutButton type={buttonType} onClick={onClick}>
              {buttonText.toUpperCase()}
            </StyledCheckoutButton>
          </StyledButtonWrapper>
        )}
      </StyledCardContent>
    </StyledCardContainer>
  );
};

export default CheckoutCard;
