import React, { Children, ReactElement, cloneElement, isValidElement } from 'react';
import { SliceChildrenContainerProps } from './slice-children-container.props';

export const SliceChildrenContainer = ({ children, start = 0, end, itemProps = {} }: SliceChildrenContainerProps) => {
  return (
    <>
      {Children.toArray(children)
        .slice(start, end)
        .map(element => (isValidElement(element) ? cloneElement(element as ReactElement, itemProps) : element))}
    </>
  );
};
