import { Flex, Text } from 'common/common-components.styled';
import styled from 'styled-components';
import { CalendarItemProps } from './calendar-item.props';
import { PickValue } from 'common/interfaces';

const getTextColor = (type: PickValue<CalendarItemProps, 'type'>) => {
  switch (type) {
    case 'overdue':
      return 'pink';
    case 'executed':
      return 'grey400';
    default:
      return 'darkBlue';
  }
};

export const StyledContainer = styled(Flex)<Pick<CalendarItemProps, 'type'>>`
  align-items: center;
  background: ${({ theme, type }) => theme.colors[type === 'nearestUpcoming' ? 'extraLightGreen' : 'white']};
  padding: ${({ type }) => `10px ${type === 'nearestUpcoming' ? '10px' : '5px'}`};
`;

export const StyledDate = styled(Text)<Pick<CalendarItemProps, 'type'>>`
  color: ${({ theme, type }) => theme.colors[getTextColor(type)]};
  font-size: 20px;
  font-weight: bold;
`;

export const StyledDescription = styled(Text)<Pick<CalendarItemProps, 'type'>>`
  color: ${({ theme, type }) => theme.colors[getTextColor(type)]};
`;
