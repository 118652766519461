export const ADD_CAR = 'ADD_CAR';
export const DELETE_CAR = 'DELETE_CAR';
export const DELETE_ALL_CARS = 'DELETE_ALL_CARS';
export const TOGGLE_COMPARISON_PANEL = 'TOGGLE_COMPARISON_PANEL';

export const addToComparison = car => ({
  type: ADD_CAR,
  payload: car
});

export const deleteFromComparison = id => ({
  type: DELETE_CAR,
  payload: id
});

export const deleteAllFromComparison = () => ({
  type: DELETE_ALL_CARS
});

export const toggleComparisonPanel = flag => ({
  type: TOGGLE_COMPARISON_PANEL,
  payload: flag
});
