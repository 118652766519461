import React, { useEffect, useMemo } from 'react';
import { TaskWidget } from '../task/task';
import { IUpcomingTasksWidgets } from './upcoming tasks.props';
import { useDashboardTaskQuery } from 'hooks/react-query/tasks/get-dashboard-tasks';
import { dateCompare } from 'utils/date/compare';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import { OpenPageProps } from 'components/dashboard-component-chart/dashboard.props';

export const UpcomingTasksWidgets = ({ toggleLoading }: IUpcomingTasksWidgets) => {
  const { data = [], isFetching } = useDashboardTaskQuery(null);

  const history = useHistory();
  const openPage =
    ({ search }: OpenPageProps) =>
    () => {
      history.push({ pathname: PAGES.TASKS, search });
    };

  useEffect(() => {
    if (toggleLoading) {
      toggleLoading(isFetching);
    }
  }, [isFetching]);

  const sortedTasks = useMemo(
    () =>
      data.sort((a, b) =>
        dateCompare(
          a.scheduledend ? new Date(a.scheduledend) : new Date('1900-01-01T00:00:00+00:00'),
          b.scheduledend ? new Date(b.scheduledend) : new Date('1900-01-01T00:00:00+00:00')
        )
      ),
    [data?.length]
  );

  return <TaskWidget records={sortedTasks} emptyText='Es gibt keine anstehenden Aufgaben' openPage={openPage} />;
};
