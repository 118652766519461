import { PAGES, UNAUTHORIZED_PAGES } from 'common/enums';
import { defaultUrlTree } from 'components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';

const getPageTitle = (title: string) => {
  return title.split('/')[1];
};

export const getSEOItems = (title: string) => {
  if (title === 'dashboard') {
    return {
      title: 'Willkommen | Dashboard'
    };
  }

  if (title === getPageTitle(PAGES.NEW_CONFIGURATION)) {
    return {
      title: 'Referenzfahrzeuge'
    };
  }

  if (title === UNAUTHORIZED_PAGES.REPORT_DAMAGE) {
    return {
      title: 'Schadenmeldung'
    };
  }

  if (title === UNAUTHORIZED_PAGES.REPAIRED_STATUS) {
    return {
      title: 'Reparaturstatus'
    };
  }

  if (title === UNAUTHORIZED_PAGES.SIGNING) {
    return {
      title: 'Vertrag | Freigabe'
    };
  }

  if (title === UNAUTHORIZED_PAGES.RELEASE) {
    return {
      title: 'Freigabe'
    };
  }

  if (title === PAGES.CREATE_DAMAGE) {
    return {
      title: 'Schaden anlegen'
    };
  }

  return {
    //@ts-ignore
    title: defaultUrlTree.children?.[title]
      ? //@ts-ignore
        defaultUrlTree.children[title].title
      : 'Willkommen bei Mobexo - Schaden & Fuhrpark-Management'
  };
};
