import React from 'react';
import PropTypes from 'prop-types';
import classes from './three-state-checkbox.module.scss';
import { PrefixForId } from '../../common/enums';

const DisabledCheckbox = ({ className }) => {
  return (
    <svg
      id='DisabledCheckbox'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='3.75' y='1.27454' width='16.5' height='16.5' rx='1.25' fill='white' />
      <rect x='3.75' y='1.27454' width='16.5' height='16.5' rx='1.25' stroke='#F0F1F2' strokeWidth='1.5' />
    </svg>
  );
};

const DisabledCheckboxChecked = ({ className }) => {
  return (
    <svg
      id='DisabledCheckboxChecked'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='none'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' fill='#F0F1F2' />
      <path d='M6 12L10 16L18 8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#F0F1F2' strokeWidth='1.5' />
    </svg>
  );
};

const UncheckedCheckbox = ({ className }) => {
  return (
    <svg
      id='UncheckedCheckbox'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='none'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' fill='white' />
    </svg>
  );
};

const UncheckedCheckboxHover = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='#E6F7FF'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' />
    </svg>
  );
};

const CheckedCheckbox = ({ className }) => {
  return (
    <svg
      id='CheckedCheckbox'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='none'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' fill='#335566' />
      <path d='M6 12L10 16L18 8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' />
    </svg>
  );
};

const CheckedCheckboxHover = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='#E6F7FF'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' fill='#335566' />
      <path d='M6 12L10 16L18 8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' />
    </svg>
  );
};

const IndeterminateCheckbox = ({ className }) => {
  return (
    <svg
      id='IndeterminateCheckbox'
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='none'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' fill='#335566' />
      <path d='M6 12H18' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' />
    </svg>
  );
};

const IndeterminateCheckboxHover = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z'
        fill='#E6F7FF'
      />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' fill='#335566' />
      <path d='M6 12H18' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <rect x='3.75' y='3.75' width='16.5' height='16.5' rx='1.25' stroke='#335566' strokeWidth='1.5' />
    </svg>
  );
};

const ThreeStateCheckbox = ({ checked, disabled = false, indeterminate, onChange, className, prefixId = '' }) => {
  const changeHandler = e => {
    if (!disabled) {
      e.stopPropagation();
      onChange({ checked, indeterminate });
    }
  };

  return (
    <div
      onClick={changeHandler}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      className={`${classes.checkbox} ${className}`}
      id={prefixId + PrefixForId.ThreeState}
      data-checked={checked}
      data-indeterminate={indeterminate}
    >
      {disabled ? (
        checked ? (
          <DisabledCheckboxChecked />
        ) : (
          <DisabledCheckbox />
        )
      ) : indeterminate ? (
        <>
          <IndeterminateCheckbox className={classes.mouseout} />
          <IndeterminateCheckboxHover className={classes.mouseover} />
        </>
      ) : checked ? (
        <>
          <CheckedCheckbox className={classes.mouseout} />
          <CheckedCheckboxHover className={classes.mouseover} />
        </>
      ) : (
        <>
          <UncheckedCheckbox className={classes.mouseout} />
          <UncheckedCheckboxHover className={classes.mouseover} />
        </>
      )}
    </div>
  );
};

ThreeStateCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool.isRequired,
  className: PropTypes.string,
  prefixId: PropTypes.string
};

export default ThreeStateCheckbox;
