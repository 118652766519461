import React from 'react';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { Flex, Text } from 'common/common-components.styled';
import { VehicleDetailCarSectionStatus } from './status';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailCarSectionDescription = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isMobile } = useWindowSize(500);

  return (
    <Flex align='center'>
      <Text color='grey500' size={isMobile ? '14' : '16'} top={isMobile ? '5' : '10'} right='20'>
        Mobexo-Car-ID: ${fuhrpark?.uds_modexocarinternalid || '-'}
      </Text>
      {isMobile && <VehicleDetailCarSectionStatus />}
    </Flex>
  );
};
