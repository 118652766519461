import { FleetDataQualityAttributesResponse } from 'hooks/react-query/fleet/get-data-quality/get-data-quality.props';
import { useFleetDataQualityQuery } from 'hooks/react-query/fleet/get-data-quality/get-data-quality.query';

export const useDataQuality = () => {
  const { fleetDataQualityQuery } = useFleetDataQualityQuery();

  const getQualityValue = (fieldList: Array<keyof FleetDataQualityAttributesResponse>) => {
    let accumulator = 0;

    fieldList.forEach(item => {
      const itemValue = fleetDataQualityQuery?.[item];

      if (typeof itemValue === 'number') {
        accumulator = accumulator + itemValue;
      }
    });

    return accumulator;
  };

  const getFilledQualityColor = () => {
    if (filledQualityPercent > 90) {
      return 'green';
    }
    if (filledQualityPercent > 80) {
      return 'darkGold';
    }

    return 'pink';
  };

  const filledQuality = getQualityValue([
    'uds_filledfields_finanzrate_count',
    'uds_filledfields_insurance_count',
    'uds_filledfields_tire_replacement_count',
    'uds_filledfields_vehicle_overview_count',
    'uds_filledfields_winter_tires_count'
  ]);
  const amountOfQuality = getQualityValue([
    'uds_allfields_finanzrate_count',
    'uds_allfields_insurance_count',
    'uds_allfields_tire_replacement_count',
    'uds_allfields_vehicle_overview_count',
    'uds_allfields_winter_tires_count'
  ]);

  const filledQualityPercent = amountOfQuality ? (filledQuality / amountOfQuality) * 100 : 0;

  return { filledQuality, amountOfQuality, filledQualityPercent, filledQualityPercentColor: getFilledQualityColor() };
};
