import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { Moment } from 'moment';
import { EditBookingFieldNames } from '../form-fields/edit-booking.form-fields';
import { useEffect } from 'react';
import useUnavailableDateRanges from './react-query/use-unavailable-date-ranges/use-unavailable-date-ranges';
import { Booking } from '../edit-booking/edit-booking.types';
import { UnavailableRange } from './react-query/use-unavailable-date-ranges/use-unavailable-date-ranges.types';

const isDateInUnavailableRange = (date: Moment, unavailableRanges: UnavailableRange[]) => {
  const unavailableRange = unavailableRanges.find(range => date.isBetween(range.from, range.to, undefined, '[]'));

  return { isDateInRange: !!unavailableRange, unavailableRange };
};

const isUnavailableRangeBetweenDates = (startDate: Moment, endDate: Moment, unavailableRanges: UnavailableRange[]) => {
  const unavailableRange = unavailableRanges.find(
    range =>
      range.from.isBetween(startDate, endDate, undefined, '[]') &&
      range.to.isBetween(startDate, endDate, undefined, '[]')
  );

  return { isRangeBetweenDates: !!unavailableRange, unavailableRange };
};

const useCheckUnavailableDates = (booking: Booking, reactHookFormData: UseFormReturn) => {
  const { getValues, setError, clearErrors } = reactHookFormData;
  const startDateValue: Moment | null | undefined = getValues(EditBookingFieldNames.StartDate);
  const endDateValue: Moment | null | undefined = getValues(EditBookingFieldNames.EndDate);
  const licensePlateValue: string = getValues(EditBookingFieldNames.LicensePlate);

  const { unavailableRanges } = useUnavailableDateRanges(licensePlateValue, booking.uds_poolkalenderid);

  useEffect(() => {
    if (startDateValue?.isValid() && endDateValue?.isValid()) {
      const dateObjects = [
        { label: EditBookingFieldNames.StartDate, value: startDateValue },
        { label: EditBookingFieldNames.EndDate, value: endDateValue }
      ];

      for (let i = 0; i < dateObjects.length; i++) {
        const { isDateInRange, unavailableRange } = isDateInUnavailableRange(dateObjects[i].value, unavailableRanges);

        if (isDateInRange) {
          setError(dateObjects[i].label, {
            type: 'manual',
            message: `Nicht verfügbarer Datumsbereich ${unavailableRange?.from.format(
              'DD.MM.yyyy HH:mm'
            )} - ${unavailableRange?.to.format('DD.MM.yyyy HH:mm')}`
          });
          return;
        } else {
          clearErrors(dateObjects[i].label);
        }
      }

      const { isRangeBetweenDates, unavailableRange } = isUnavailableRangeBetweenDates(
        startDateValue,
        endDateValue,
        unavailableRanges
      );
      if (isRangeBetweenDates) {
        dateObjects.forEach(dateObject => {
          setError(dateObject.label, {
            type: 'manual',
            message: `Nicht verfügbarer Datumsbereich ${unavailableRange?.from.format(
              'DD.MM.yyyy HH:mm'
            )} - ${unavailableRange?.to.format('DD.MM.yyyy HH:mm')}`
          });
        });
      } else {
        clearErrors(dateObjects.map(dateObject => dateObject.label));
      }
    }
  }, [startDateValue?.format(), endDateValue?.format(), JSON.stringify(unavailableRanges)]);
};

export default useCheckUnavailableDates;
