import React from 'react';
import { Distance, Flex, Text } from 'common/common-components.styled';
import styles from './report-damage-component.module.scss';
import { AnyObject } from 'common/interfaces';

const SaveFieldsAlert = ({ saveToLocalStorage, savedFields }: AnyObject) => {
  return (
    <Flex direction='column' align='center'>
      <Text color='grey800' margin='10px 0 20px' size='14' textAlign='left'>
        Achtung, die zuvor heruntergeladenen Dateien können nicht in deinem Browser gespeichert werden. Bitte lade die
        Dateien vor dem Versand der Schadenmeldung erneut hoch.
      </Text>
      <div className={styles.alert_buttons}>
        <button
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            saveToLocalStorage(savedFields);
          }}
          className={styles.alert_button}
        >
          Alles klar
        </button>
      </div>
      <Distance top='-5' />
    </Flex>
  );
};

export default SaveFieldsAlert;
