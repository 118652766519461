import { useEffect, useState } from 'react';
import { WindowSize } from 'common/interfaces';
import { debounce } from 'lodash';

function useWindowSize(debounceValue: number = 500): WindowSize {
  const [windowSize, setWindowSize] = useState<Pick<WindowSize, 'windowHeight' | 'windowWidth'>>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  useEffect(() => {
    window.addEventListener('resize', resizeHandlerDebounced);
    return () => {
      window.removeEventListener('resize', resizeHandlerDebounced);
    };
  }, []);

  const resizeHandler = () => {
    setWindowSize({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };

  const resizeHandlerDebounced = debounce(resizeHandler, debounceValue);

  return { ...windowSize, isMobile: windowSize.windowWidth <= 550 };
}

export default useWindowSize;
