import React from 'react';
import PropTypes from 'prop-types';

export const CircleErrorMark = ({ color = '#BF0040', width = 18, height = 19 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.01821 18.0716C6.76639 18.0716 4.58721 17.1999 2.98915 15.6018C1.31845 13.9311 0.446777 11.752 0.446777 9.50014C0.446777 7.24832 1.31845 5.06915 2.98915 3.47108C4.58721 1.80038 6.69375 0.928711 9.01821 0.928711C11.27 0.928711 13.4492 1.80038 15.0473 3.39844C18.3887 6.73985 18.3887 12.1878 15.0473 15.5292C13.4492 17.1273 11.27 18.0716 9.01821 18.0716ZM9.01821 2.3815C7.12959 2.3815 5.31361 3.10789 4.0061 4.48803C2.62595 5.79554 1.89956 7.61152 1.89956 9.50014C1.89956 11.3888 2.62595 13.2047 4.0061 14.5122C5.31361 15.8198 7.12959 16.6188 9.01821 16.6188C10.9068 16.6188 12.7228 15.8924 14.0303 14.5122C16.7906 11.752 16.7906 7.24832 14.0303 4.48803C12.6502 3.10789 10.9068 2.3815 9.01821 2.3815Z' fill={color} />
      <path d='M6 6.5L12 12.5' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 12.5L12 6.5' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

CircleErrorMark.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
