import { UseFormReturn } from 'react-hook-form';

export interface CarDealershipSelectionProps {
  reactHookFormData: UseFormReturn;
}

export enum CarDealershipSelectionFieldNames {
  CarDealership = 'uds_anbietenterhandlerid',
  ContactPerson = 'uds_haendlerkontakt'
}
