import { Action } from 'store/store.props';
import { initialState } from './initial.state';
import { MAIN_TAB, LEASING_TAB } from 'actions/pages/vehicle-details.action';
import { IVehicleDetailsState } from './vehicle-details.props';

export const vehicleDetailsReducer = (state: IVehicleDetailsState = initialState, action: Action<any>) => {
  switch (action.type) {
    case MAIN_TAB:
      return {
        ...state,
        mainTab: { ...state.mainTab, ...action.payload }
      };
    case LEASING_TAB:
      return {
        ...state,
        leasingTab: { ...state.leasingTab, ...action.payload }
      };

    default:
      return state;
  }
};
