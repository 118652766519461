import React, { MutableRefObject, createContext, useMemo, useRef } from 'react';
import { AnyObject } from 'common/interfaces';
import { ContextProps } from '../context.props';

interface IUnSaveModalRefContext {
  isFormsDirty: MutableRefObject<boolean>;
  isFormsValid: MutableRefObject<boolean>;
  saveFormValues: MutableRefObject<AnyObject>;
}

export const UnSaveModalRefContext = createContext<IUnSaveModalRefContext>({
  isFormsDirty: { current: false },
  isFormsValid: { current: false },
  saveFormValues: { current: {} }
});

export const UnSaveModalRefContextContainer = ({ children }: ContextProps) => {
  const isFormsDirty = useRef(true);
  const isFormsValid = useRef(false);
  const saveFormValues = useRef({});

  const value = useMemo<IUnSaveModalRefContext>(() => ({ isFormsDirty, isFormsValid, saveFormValues }), []);

  return <UnSaveModalRefContext.Provider value={value}>{children}</UnSaveModalRefContext.Provider>;
};
