import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const NewDriverIcon = ({ width = 19, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.93604 10.801C4.67829 10.6598 4.41973 10.5019 4.41973 10.5019C2.95036 9.63808 2.28247 8.12419 2.28247 4.9183C2.28247 2.65637 3.84089 1 6.20077 1C8.56066 1 10.1191 2.65637 10.1191 4.9183C10.1191 8.12419 9.45119 9.63808 7.98182 10.5019C7.98182 10.5019 7.56383 10.7203 7.26983 10.801M4.06356 10.2705V11.2501C4.06356 11.722 3.75187 12.1406 3.2977 12.2742L0.501465 13.1112M8.33813 10.2704V11.25C8.33813 11.722 8.64982 12.1405 9.10399 12.2741'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.983 19C16.117 19 17.8469 17.2701 17.8469 15.1361C17.8469 13.0021 16.117 11.2722 13.983 11.2722C11.8491 11.2722 10.1191 13.0021 10.1191 15.1361C10.1191 17.2701 11.8491 19 13.983 19Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9183 15.1361L12.0471 15.1361'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.983 13.2006L13.983 17.0718'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
