import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

interface StyledMobileBookingItemProps {
  color: string;
}

export const StyledMobileBookingItem = styled(Flex)<StyledMobileBookingItemProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  border-left: 6px solid rgba(0, 0, 0, 0);
  padding: 15px 20px;
  border-color: ${({ theme, color }) => theme.colors[color]};
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;
