import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { SetDoneStatusCrmTaskParams } from 'request-config/crm-task/crm-task.types';
import { CompleteTaskModalContent } from './use-complete-task.content';

export const useCompleteTaskModal = (submitData: SetDoneStatusCrmTaskParams) => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openCompleteTaskModal = () => {
    const alertData = {
      title: 'Aufgabe erledigen',
      children: <CompleteTaskModalContent submitData={submitData} />,
      allButtonsHidden: true,
      buttons: [{ type: 'cancel', action: closeAlert }]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openCompleteTaskModal };
};
