import { FileDownloadProps } from 'hooks/useFileDownload';
import { TableParams } from '../my-vehicles.props';
import { SessionFilter } from 'hooks/use-session-filter';

export interface DownloadExelProps {
  tableParams: TableParams;
  sessionFilter: SessionFilter;
}

export enum FieldOrderTypes {
  Standart = 0,
  Accounting = 1
}
