import axios from 'axios';
import authService from '../../services/auth-service';
import { API_ENDPOINT } from '../../constants';

export const getRepairStatusUnauthorized = ({ pagination, search }) => {
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/RepairStatusDriver/GetIncidentsByLicensePlate`,
    data: {
      pagination,
      search
    }
  });
};

export const getRepairStatus = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/RepairStatusDriver/GetIncidents`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data
  });
};

export const formatDate = date => {
  if (new Date(date).getTime()) {
    return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  return 'Noch kein Termin';
};
