import React from 'react';
import { ConfirmationModalProps } from '../extend-lease-agreement.props';
import NumberFormat from 'react-number-format';
import { Flex, Text, Button } from 'common/common-components.styled';

const ConfirmationModal = ({
  plateNumber,
  isChangeTerms,
  onCancel,
  onSubmit,
  updatedMileage,
  updatedMonths
}: ConfirmationModalProps) => {
  const formattedMileage = (
    <NumberFormat
      thousandSeparator='.'
      decimalSeparator=','
      displayType='text'
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={' km'}
      value={updatedMileage}
    />
  );

  return (
    <>
      {isChangeTerms ? (
        <Text as='span'>
          Bist du sicher, dass du den Leasingvertrag für <strong>{plateNumber.attributeValue?.name || ''}</strong> um{' '}
          <strong>{updatedMonths} Monate</strong> verlängern{' '}
          {updatedMileage && (
            <Text as='span'>
              und auch die Laufleistung auf <strong>{formattedMileage}</strong> erhöhen
            </Text>
          )}{' '}
          möchtest?
        </Text>
      ) : (
        <Text>
          Bist du sicher, dass du den Leasingvertrag für <strong>{plateNumber.attributeValue?.name || ''}</strong>{' '}
          verlängern möchtest?{' '}
        </Text>
      )}
      <Flex justify='flex-end' top='20'>
        <Button secondary right='10' onClick={onCancel}>
          Abbrechen
        </Button>
        <Button onClick={onSubmit}>Bestätigen</Button>
      </Flex>
    </>
  );
};

export default ConfirmationModal;
