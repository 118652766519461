import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SearchItemProps, CarPolicyProps, MobilityBudgetProps } from 'components/aufgaben-component/task.prop';
import { Flex, Text, Button } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import { getDriversPolicies, setDriversPolicies } from 'components/aufgaben-component/utils';
import Spinner from 'components/spinner';
import { StyledInput, StyledDropdown, StyledListItem } from '../delegate-task/delegate-task.styled';

interface CarPoliciesForDriverProps {
  taskId: string;
  driver: SearchItemProps;
  handleGoBack: () => void;
  closeAlert: () => void;
  onSubmitDelegation: (selectedUser: SearchItemProps, taskId: string) => void;
}

const spinnerStyle = {
  width: '75px',
  zIndex: 97
};

const CarPoliciesForDriver = ({
  taskId,
  driver,
  handleGoBack,
  closeAlert,
  onSubmitDelegation
}: CarPoliciesForDriverProps) => {
  const [availablePolicies, setAvailablePolicies] = useState<CarPolicyProps[]>([]);
  const [availableMobilityBudgets, setAvailableMobilityBudgets] = useState<any[]>([]);
  const [policiesSearchValue, setPoliciesSearchValue] = useState('');
  const [mobilitySearchValue, setMobilitySearchValue] = useState('');
  const [policiesListVisible, setPoliciesListVisible] = useState(false);
  const [mobilityListVisible, setMobilityListVisible] = useState(false);
  const [filteredPoliciesList, setFilteredPoliciesList] = useState<any>([]);
  const [filteredMobilityList, setFilteredMobilityList] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dropdownPoliciesRef = useRef<any>(null);
  const dropdownMobilityRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    getDriversPolicies(driver.id).then(response => {
      setAvailablePolicies(response.data.data.carPolicy.items);
      setAvailableMobilityBudgets(response.data.data.mobilitatBidget.items);
      setValue('policies', response.data.data.userCarPolicy);
      setValue('budget', response.data.data.userMobilitat);
      response.data.data.userCarPolicy.name && setPoliciesSearchValue(response.data.data.userCarPolicy.name);
      response.data.data.userMobilitat.name && setMobilitySearchValue(response.data.data.userMobilitat.name);
      setIsLoading(false);
    });
  }, [driver, setValue]);

  useEffect(() => {
    const enabledButton = watch('policies') && watch('budget');
    setIsButtonDisabled(!enabledButton);
  }, [watch, setIsButtonDisabled, policiesSearchValue, mobilitySearchValue, isLoading]);

  const filterList = useCallback((list: any[], searchValue: string) => {
    const lowerCaseQuery = searchValue ? searchValue.toLowerCase() : '';
    const filteredList = searchValue
      ? list.filter((el: any) => el.uds_name && el.uds_name.toLowerCase().includes(lowerCaseQuery))
      : list;
    return filteredList;
  }, []);

  useEffect(() => {
    const filteredList = filterList(availablePolicies, policiesSearchValue);
    setFilteredPoliciesList(filteredList);
  }, [availablePolicies, policiesSearchValue, filterList]);

  useEffect(() => {
    const filteredList = filterList(availableMobilityBudgets, mobilitySearchValue);
    setFilteredMobilityList(filteredList);
  }, [availableMobilityBudgets, mobilitySearchValue, filterList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const handleClick = useCallback((e: any) => {
    if (dropdownPoliciesRef.current.contains(e.target)) {
      return;
    }

    if (dropdownMobilityRef.current.contains(e.target)) {
      return;
    }
    setPoliciesListVisible(false);
    setMobilityListVisible(false);
  }, []);

  const selectPolicyItem = useCallback(
    (item: CarPolicyProps) => {
      clearErrors('policies');
      setValue('policies', item);
      setPoliciesSearchValue(item.uds_name);
      setPoliciesListVisible(false);
    },
    [clearErrors, setValue]
  );

  const selectMobilityItem = useCallback(
    (item: MobilityBudgetProps) => {
      clearErrors('budget');
      setValue('budget', item);
      setMobilitySearchValue(item.uds_name);
      setMobilityListVisible(false);
    },
    [clearErrors, setValue]
  );

  const handlePolicyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPoliciesSearchValue(e.target.value);
      const policyFound = availablePolicies.find(policy => policy.uds_name === e.target.value);
      if (
        availablePolicies
          ?.map(el => el.uds_name)
          .find((el: string) => el.toLowerCase() === policiesSearchValue.toLowerCase())
      ) {
        clearErrors('policies');
      }
      if (filterList(availablePolicies, e.target.value).length === 0) {
        setError('policies', {
          type: 'manual',
          message: 'User not found'
        });
      }
      if (filterList(availablePolicies, e.target.value.toUpperCase()).length > 0) {
        clearErrors('policies');
      }
      if (policyFound) {
        selectPolicyItem(policyFound);
      } else {
        setValue('policies', null);
      }
      if (!policiesListVisible) {
        setPoliciesListVisible(true);
      }
    },
    [
      availablePolicies,
      clearErrors,
      policiesSearchValue,
      filterList,
      setError,
      setValue,
      policiesListVisible,
      selectPolicyItem
    ]
  );

  const handleMobilityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setMobilitySearchValue(e.target.value);
      const mobilityFound = availableMobilityBudgets.find(budget => budget.uds_name === e.target.value);
      if (
        availableMobilityBudgets
          ?.map(el => el.uds_name)
          .find((el: string) => el.toLowerCase() === mobilitySearchValue.toLowerCase())
      ) {
        clearErrors('budget');
      }
      if (filterList(availableMobilityBudgets, e.target.value).length === 0) {
        setError('budget', {
          type: 'manual',
          message: 'User not found'
        });
      }
      if (filterList(availableMobilityBudgets, e.target.value.toUpperCase()).length > 0) {
        clearErrors('budget');
      }
      if (mobilityFound) {
        selectPolicyItem(mobilityFound);
      } else {
        setValue('budget', null);
      }
      if (!mobilityListVisible) {
        setMobilityListVisible(true);
      }
    },
    [
      availableMobilityBudgets,
      filterList,
      mobilityListVisible,
      mobilitySearchValue,
      clearErrors,
      setError,
      selectPolicyItem,
      setValue
    ]
  );

  const onSubmit = useCallback(() => {
    onSubmitDelegation(driver, taskId);
    setDriversPolicies(driver.id, watch('policies').uds_carpolicyid, watch('budget').uds_car_policy_nutzerid);
  }, [driver, onSubmitDelegation, taskId, watch]);

  return (
    <form style={{ marginBottom: 0 }}>
      {isLoading && (
        <Flex
          width='560px'
          align='center'
          justify='center'
          style={{ backgroundColor: 'white', position: 'absolute', height: '310px' }}
        >
          <Spinner style={spinnerStyle} />
        </Flex>
      )}
      <Flex direction='column'>
        <Text color='black' bottom='30'>
          {`${driver.name} wurden untenstehende Policies zugewiesen,
          die du jetzt anpassen kannst. Die aktuelle Car Policy
          und das Mobilitätsbudget vom Fahrer werden nach dem Delegieren überschrieben.`}
        </Text>
        <Flex>
          <Text color='darkBlue'>Car Policy</Text>
          <Text color='pink'>*</Text>
        </Flex>
        <StyledInput
          type='text'
          {...register('policies', { required: 'Bitte gib Kennzeichen ein.' })}
          placeholder='Geschäftsleitung'
          value={policiesSearchValue}
          onChange={handlePolicyChange}
          onClick={() => {
            setPoliciesSearchValue('');
            setValue('policies', null);
          }}
          onFocus={() => {
            setPoliciesListVisible(true);
          }}
          autoComplete='off'
        />
        <StyledDropdown
          style={{ visibility: policiesListVisible ? 'visible' : 'hidden' }}
          ref={dropdownPoliciesRef}
          top={230}
        >
          {policiesListVisible && (
            <ul>
              {availablePolicies.length > 0 &&
                filteredPoliciesList.map((el: CarPolicyProps) => (
                  <StyledListItem key={el.uds_carpolicyid} onClick={() => selectPolicyItem(el)}>
                    <div className='item_text1'>{el.uds_name}</div>
                  </StyledListItem>
                ))}
            </ul>
          )}
        </StyledDropdown>
        <Flex top='30'>
          <Text color='darkBlue'>Mobilitätsbudget</Text>
          <Text color='pink'>*</Text>
        </Flex>
        <StyledInput
          type='text'
          {...register('budget', { required: 'Bitte gib Kennzeichen ein.' })}
          placeholder='Management Senior'
          value={mobilitySearchValue}
          onChange={handleMobilityChange}
          onClick={() => {
            setMobilitySearchValue('');
            setValue('budget', null);
          }}
          onFocus={() => {
            setMobilityListVisible(true);
          }}
          autoComplete='off'
        />
        <StyledDropdown
          style={{ visibility: mobilityListVisible ? 'visible' : 'hidden' }}
          ref={dropdownMobilityRef}
          top={320}
        >
          {mobilityListVisible && (
            <ul>
              {availableMobilityBudgets.length > 0 &&
                filteredMobilityList.map((el: MobilityBudgetProps) => (
                  <StyledListItem key={el.uds_car_policy_nutzerid} onClick={() => selectMobilityItem(el)}>
                    <div className='item_text1'>{el.uds_name}</div>
                  </StyledListItem>
                ))}
            </ul>
          )}
        </StyledDropdown>
        <Flex justify='space-between' align='center' top='30'>
          <Text color='blue' onClick={handleGoBack} pointer>
            ← Zuruck
          </Text>
          <Flex>
            <Button secondary onClick={closeAlert} right='10'>
              Abbrechen
            </Button>
            <Button onClick={onSubmit} type='submit' disabled={isButtonDisabled}>
              Delegieren
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default CarPoliciesForDriver;
