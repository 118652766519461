import { AxiosRequestConfig } from 'axios';
import { LeasingOfferProps } from './leasing-offer.types';

class LeasingOfferRequestConfig {
  getLeasingOfferExcel = ({ search, sort }: LeasingOfferProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'leasingangebote/getLeasingangeboteExcel',
    responseType: 'blob',
    data: {
      search,
      sort
    }
  });

  getAllByVehicleOffer = (vehicleOfferId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'leasingangebote/getAllByFahrzeugangebot',
    data: {
      uds_fahrzeugangeboteid: vehicleOfferId
    }
  });
}

export const leasingOfferRequestConfig = new LeasingOfferRequestConfig();
