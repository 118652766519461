import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import classes from './button.module.scss';

export default function Button({ label, icon, onChange, name, active }) {
  //const [isActive, setActive] = useState(false);

  const changeHandler = () => {
    //setActive(active);
    onChange({ name, active: !active });
  };

  return (
    <span className={classes.span} onClick={changeHandler}>
      <button
        type='button'
        className={classnames('tc btn btn-block center btn-default', classes.icon, { [classes.active]: active })}
      >
        {icon}
        {label}
      </button>
    </span>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};
