import { PAGES } from 'common/enums';

export enum MenuTitles {
  BusinessDetails = 'Geschäftsdetails',
  Contact = 'Kontakt',
  Company = 'Unternehmen',
  Payment = 'Zahlung',
  LedgerAccountMapping = 'Sachkonto Mapping',
  Notification = 'Benachrichtigung',
  Leasing = 'Leasing',
  CostCenters = 'Kostenstellen',
  PoolCalendar = 'Poolkalender',
  InspectionDates = 'Prüftermine',
  ConsumptionSurcharge = 'Verbrauchsaufschlag',
  OrderControl = 'Auftragsteuerung'
}

export interface SettingsMenuItems {
  title: MenuTitles;
  active: boolean;
  page: PAGES;
  beta?: boolean;
}
