import { useCalendarAppointmentWidgetIsShow } from 'pages/existing-vehicle-page/components/widgets/calendar-appointment/hooks/use-calendar-appointment-is-show';
import { useIsShowConsumptionWidget } from 'pages/existing-vehicle-page/components/widgets/consumption/hooks/use-is-show-widget';
import { useConsumptionWidgetVariant } from 'pages/existing-vehicle-page/components/widgets/consumption/hooks/use-widget-variant';
import { useIsShowKilometerWidget } from 'pages/existing-vehicle-page/components/widgets/kilometer/hooks/use-is-show-kilometer-widget';
import { useLeasingPeriodWidgetIsShow } from 'pages/existing-vehicle-page/components/widgets/leasing-period/hooks/use-leasing-period-is-show';
import { useVehicleCostWidgetIsShow } from 'pages/existing-vehicle-page/components/widgets/vehicle-const/hooks/use-is-show-widget';

export const useIsShowVehicleDetailTopWidgetLayout = () => {
  const { isShowKilometerWidget } = useIsShowKilometerWidget();
  const { isShowVehicleCostWidget } = useVehicleCostWidgetIsShow();
  const { calendarAppointmentWidgetIsShow } = useCalendarAppointmentWidgetIsShow();
  const { leasingPeriodWidgetIsShow } = useLeasingPeriodWidgetIsShow();
  const { isShowConsumptionWidget: isShowConsumptionWidgetGeneralCondition } = useIsShowConsumptionWidget('normal');

  const isShowConsumptionWidget = isShowConsumptionWidgetGeneralCondition;

  const isShowVehicleDetailTopWidgetLayout =
    isShowKilometerWidget ||
    isShowVehicleCostWidget ||
    calendarAppointmentWidgetIsShow ||
    leasingPeriodWidgetIsShow ||
    isShowConsumptionWidget;

  return {
    isShowVehicleDetailTopWidgetLayout,
    isShowKilometerWidget,
    isShowVehicleCostWidget,
    calendarAppointmentWidgetIsShow,
    leasingPeriodWidgetIsShow,
    isShowConsumptionWidget
  };
};
