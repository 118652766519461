import { FormFieldModel } from 'common/interfaces';
import { FormData } from './modal-content.props';
import { FormFieldTypes } from 'common/enums';
import { errorMessages } from 'utils/error-messages';
import moment from 'moment';

const monthIndexList = Array.from({ length: 12 }, (_, i) => i + 1);

const getMonthList = () => {
  return monthIndexList.map(monthIndex =>
    moment()
      .month(monthIndex - 1)
      .locale('de')
      .format('MMMM')
  );
};

const getDatesFromYear = () => {
  const currentYear = moment().year();
  const years = [];
  for (let year = currentYear; year >= 1995; year--) {
    years.push(year);
  }
  return years;
};

export const formFields: FormFieldModel<keyof FormData>[] = [
  {
    name: 'month',
    label: 'Monat',
    type: FormFieldTypes.OptionSet,
    options: getMonthList().map((month, index) => ({ label: month, value: (index + 1).toString() })),
    containerClassName: 'w-100',
    validation: { required: errorMessages.required }
  },
  {
    name: 'year',
    label: 'Jahr',
    type: FormFieldTypes.Lookup,
    options: getDatesFromYear().map(yearNumber => ({ label: yearNumber.toString(), value: yearNumber })),
    containerClassName: 'w-100 ml-2',
    validation: { required: errorMessages.required }
  }
];
