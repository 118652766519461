const zeroPrefix = str => String.prototype.slice.call(`0${str}`, -2);
const isValidDateObject = date => !isNaN(date.getTime());

/**
 *
 * @param {Date} date
 */
const toGermanLocale = date => {
  const day = zeroPrefix(date.getDate());
  const month = zeroPrefix(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const formatDate = (date, defaultValue = null) => {
  if (Object.prototype.toString.call(date) === '[object Date]' && isValidDateObject(date)) {
    return toGermanLocale(date);
  }

  if (Object.prototype.toString.call(date) === '[object String]') {
    date = new Date(date);
    if(isValidDateObject(date)) {
      return toGermanLocale(date);
    }
  }

  return defaultValue;
};
