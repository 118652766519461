import React from 'react';

export const MobexoLogoIcon = ({ color = '#fff' }) => {
  return (
    <svg width='163' height='20' viewBox='0 0 163 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M162.945 16.0297C162.945 18.1341 161.235 19.8442 159.13 19.8442C157.026 19.8442 155.316 18.1341 155.316 16.0297C155.316 13.9254 157.026 12.2152 159.13 12.2152C161.241 12.2152 162.945 13.9196 162.945 16.0297Z'
        fill='#E80032'
      />
      <path
        d='M20.9276 19.4384V8.20361L20.7073 8.15144L14.8638 19.4384H10.6725L4.858 8.20361L4.6435 8.25579V19.4384H0.115967V1.06158H5.48408L12.7826 15.5022L20.0812 1.06158H25.4493V19.4384H20.9276Z'
        fill={color}
      />
      <path
        d='M42.1971 19.8906C38.5043 19.8906 35.542 18.9804 33.3971 17.1776C31.2579 15.3862 30.1681 13.0558 30.1681 10.25C30.1681 7.46161 31.2463 5.13697 33.3681 3.33407C35.4956 1.52538 38.4695 0.609436 42.1971 0.609436C45.9246 0.609436 48.9043 1.52538 51.055 3.33407C53.1942 5.13697 54.284 7.46161 54.284 10.25C54.284 13.0558 53.2058 15.3862 51.084 17.1776C48.9507 18.9747 45.9652 19.8906 42.1971 19.8906ZM42.1971 4.34277C39.8435 4.34277 37.9826 4.85871 36.6782 5.8848C35.3623 6.91089 34.6956 8.38335 34.6956 10.2558C34.6956 12.1283 35.3739 13.6007 36.7072 14.6268C38.0348 15.6471 39.8782 16.1631 42.1971 16.1631C44.5855 16.1631 46.4637 15.6471 47.7681 14.6384C49.084 13.6181 49.7507 12.1457 49.7507 10.2558C49.7507 8.38335 49.0782 6.91089 47.7507 5.8848C46.4406 4.85871 44.5681 4.34277 42.1971 4.34277Z'
        fill={color}
      />
      <path
        d='M59.0029 19.4384V1.06158H70.0522C72.1971 1.06158 73.9247 1.50796 75.1826 2.39492C76.429 3.27028 77.0609 4.46448 77.0609 5.94274C77.0609 6.80071 76.8464 7.56014 76.429 8.19202C76.0116 8.8239 75.4145 9.29927 74.6609 9.61231L74.371 9.72825L74.6667 9.8268C75.687 10.1746 76.4928 10.7369 77.0725 11.5022C77.6464 12.2674 77.9421 13.1775 77.9421 14.2094C77.9421 15.7746 77.2812 17.05 75.971 17.9949C74.6493 18.9514 72.8522 19.4326 70.6145 19.4326H59.0029V19.4384ZM63.5247 15.8384H69.7276C71.9189 15.8384 73.0319 15.1659 73.0319 13.8384C73.0319 12.5109 71.9189 11.8326 69.7276 11.8326H63.5247V15.8384ZM63.5247 8.26738H69.7276C71.5884 8.26738 72.5276 7.65869 72.5276 6.45289C72.5276 5.86158 72.2551 5.40361 71.7276 5.09057C71.2174 4.78912 70.5044 4.6384 69.6232 4.6384H63.5247V8.26738Z'
        fill={color}
      />
      <path
        d='M83.2463 19.4384V1.06158H99.2521V4.71376H87.7739V8.18622H98.2956V11.8326H87.7739V15.763H99.2521V19.4384H83.2463Z'
        fill={color}
      />
      <path
        d='M120.151 19.4384L114.701 12.9109L109.229 19.4384H103.583L111.559 10.1978L104.081 1.06158H109.652L114.701 7.48477L119.757 1.06158H125.357L117.901 10.1978L125.826 19.4384H120.151Z'
        fill={color}
      />
      <path
        d='M139.861 19.8906C136.168 19.8906 133.206 18.9804 131.061 17.1776C128.922 15.3862 127.832 13.0558 127.832 10.25C127.832 7.46161 128.91 5.13697 131.032 3.33407C133.159 1.52538 136.133 0.609436 139.861 0.609436C143.588 0.609436 146.568 1.52538 148.719 3.33407C150.858 5.13697 151.948 7.46161 151.948 10.25C151.948 13.0558 150.87 15.3862 148.748 17.1776C146.609 18.9747 143.623 19.8906 139.861 19.8906ZM139.861 4.34277C137.507 4.34277 135.646 4.85871 134.342 5.8848C133.026 6.91089 132.359 8.38335 132.359 10.2558C132.359 12.1283 133.038 13.6007 134.371 14.6268C135.699 15.6471 137.542 16.1631 139.861 16.1631C142.249 16.1631 144.128 15.6471 145.432 14.6384C146.748 13.6181 147.415 12.1457 147.415 10.2558C147.415 8.38335 146.742 6.91089 145.415 5.8848C144.099 4.85871 142.232 4.34277 139.861 4.34277Z'
        fill={color}
      />
    </svg>
  );
};
