import { useMemo } from 'react';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';

export const useLeaseingContactForm = (currentMileageToggleValue: boolean, currentSilentExtendToggleValue: boolean) => {
  const leasingContractFormFields: FormFieldModel[] = useMemo(() => {
    return [
      {
        name: LeasingContractFields.AddedMonths,
        type: FormFieldTypes.Months,
        defaultValue: '',
        label: 'Leasingvertrag verlängern um...',
        disabled: currentSilentExtendToggleValue,
        validation: !currentSilentExtendToggleValue
          ? {
              required: 'Bitte auswählen',
              min: {
                value: 0,
                message: 'Negative Werte können nicht eingetragen werden.'
              },
              max: {
                value: 12,
                message: 'In diesem Feld ist ein Maximalwert von 12 zulässig'
              }
            }
          : {
              required: false
            }
      },
      {
        name: LeasingContractFields.IsChangeMileage,
        type: FormFieldTypes.Toggle,
        defaultValue: false,
        label: 'Laufleistung anpassen',
        disabled: currentSilentExtendToggleValue,
        toggleLabels: [
          {
            label: 'Laufleistung anpassen',
            value: true
          },
          {
            label: 'Laufleistung anpassen',
            value: false
          }
        ],
        validation: {}
      },
      {
        name: LeasingContractFields.Mileage,
        type: FormFieldTypes.Kilometers,
        defaultValue: '',
        disabled: currentSilentExtendToggleValue,
        label: 'Jährliche Laufleistung ändern auf...',
        hidden: !currentMileageToggleValue,
        validation: {
          min: {
            value: 1,
            message: 'Der Wert in diesem Feld darf nicht unter Eins liegen.'
          },
          max: {
            value: 200000,
            message: 'In diesem Feld ist ein Maximalwert von 200000 zulässig'
          }
        }
      },
      {
        name: LeasingContractFields.SilentlyExtend,
        type: FormFieldTypes.Toggle,
        defaultValue: false,
        label: 'Stillschweigend verlängern',
        toggleLabels: [
          {
            label: 'Stillschweigend verlängern',
            value: true
          },
          {
            label: 'Stillschweigend verlängern',
            value: false
          }
        ],
        validation: {}
      },
      {
        name: LeasingContractFields.silentExtendMonths,
        type: FormFieldTypes.Range,
        defaultValue: 1,
        marginLeft: '18',
        hidden: !currentSilentExtendToggleValue,
        rightLabel: 'MON.',
        width: '50%',
        min: 1,
        max: 6,
        marks: [
          {
            value: 1,
            label: '1'
          },
          {
            value: 2,
            label: '2'
          },
          {
            value: 3,
            label: '3'
          },
          {
            value: 4,
            label: '4'
          },
          {
            value: 5,
            label: '5'
          },
          {
            value: 6,
            label: '6'
          }
        ],
        step: 1,
        validation: {}
      },

      {
        name: LeasingContractFields.CurrentMileage,
        type: FormFieldTypes.Kilometers,
        defaultValue: '',
        label: 'Bitte aktuellen Km Stand angeben',
        validation: {}
      },

      {
        name: LeasingContractFields.Comment,
        type: FormFieldTypes.TextArea,
        defaultValue: '',
        label: 'Kommentare',
        validation: {
          maxLength: {
            value: 700,
            message: 'Die maximale Anzahl der verfügbaren Zeichen beträgt 700 Zeichen'
          }
        }
      }
    ];
  }, [currentMileageToggleValue, currentSilentExtendToggleValue]);

  return { leasingContractFormFields };
};
