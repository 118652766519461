/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import CarDamageIcon from '../icons/car-damage';
import DamagePartChoose from '../damage-part-component/damage-part-choose';
import { damage } from '../utils';
import PlusIcon from '../icons/plus';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide.module.css';
import slideReverse from '../transition/damage-backward-slide.module.css';
import slideReturn from '../transition/return-slide.module.css';
import sideSlide from '../transition/side-slide.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import ZipcodeFieldComponent from '../components/zipcode-field-component';
import EmailFieldComponent from '../components/email-field-component';
import ThreeButtonsComponent from '../components/three-buttons-component';
import useWindowSize from 'hooks/use-window-size';
import TrashIcon from '../icons/trash-icon';
import { driverFields, otherFields } from '../utils';
import CarIconMobile from '../car-icon-mobile-component/car-icon-mobile';
import ArrowLeftBigIcon from '../icons/arrow-left-big';
import { StepProps, StepStylesProps, DamagePartsItemProps, OtherDamagePartsItemProps } from '../report-damage.props';

const ChooseGlassDamage = ({ reactHookFormData, handleNext }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showChooseDamage, setShowChooseDamage] = useState<boolean>(false);
  const [showChooseDamageSlider, setShowChooseDamageSlider] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const [showBlockNeedPolice, setShowBlockNeedPolice] = useState<boolean>(false);
  const [showBlockNeedPoliceGetCaseNumber, setShowBlockNeedPoliceGetCaseNumber] = useState<boolean>(false);
  const [showBlockNeedPoliceCriminalWarning, setShowBlockNeedPoliceCriminalWarning] = useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDriverWasTested, setShowBlockNeedPoliceDrunkDriverWasTested] =
    useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDrugsTest, setShowBlockNeedPoliceDrunkDrugTest] = useState<boolean>(false);
  const [showBlockWhoDrive, setShowBlockWhoDrive] = useState<boolean>(false);
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    autoIsNotOkay &&
    watch('needPolice') !== undefined &&
    needPolice &&
    needPoliceCaseNumber &&
    needPoliceCriminalWarning &&
    needPoliceDrunkDriver &&
    whoDriveFields &&
    otherWhoDriveFields &&
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    watch('hasDamage') !== undefined &&
    (watch('hasDamage') === false ||
      (Array.isArray(watch('damageParts')) &&
        watch('damageParts').length > 0 &&
        watch('damageParts').filter((el: OtherDamagePartsItemProps) => el[1].type === true).length < 1)) &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    watch('hasDamage') === true ? setShowChooseDamage(true) : setShowChooseDamage(false);
    setTimeout(() =>
      watch('hasDamage') === true ? setShowChooseDamageSlider(true) : setShowChooseDamageSlider(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    setTimeout(() => (watch('needPolice') ? setShowBlockNeedPolice(true) : setShowBlockNeedPolice(false)), 100);
  }, [watch('needPolice')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceGetCaseNumber')
          ? setShowBlockNeedPoliceGetCaseNumber(true)
          : setShowBlockNeedPoliceGetCaseNumber(false),
      100
    );
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceCriminalWarning')
          ? setShowBlockNeedPoliceCriminalWarning(true)
          : setShowBlockNeedPoliceCriminalWarning(false),
      100
    );
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceDrunkDriverWasTested')
          ? setShowBlockNeedPoliceDrunkDriverWasTested(true)
          : setShowBlockNeedPoliceDrunkDriverWasTested(false),
      100
    );
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceDrunkDrugsTest')
          ? setShowBlockNeedPoliceDrunkDrugTest(true)
          : setShowBlockNeedPoliceDrunkDrugTest(false),
      100
    );
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    setTimeout(
      () =>
        (!watch('schadenAlsFahrzeugnutzer') && watch('whoDrive') === 'Fahrzeugnutzer') ||
        watch('whoDrive') === 'Andere Person'
          ? setShowBlockWhoDrive(true)
          : setShowBlockWhoDrive(false),
      100
    );
  }, [watch('whoDrive')]);

  const emailValidation = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z_\-\.]+)\.([a-zA-Z]{2,15})$/;

  useEffect(() => {
    if (!!watch('whoDriveEmailEmpty') || (!!watch('whoDriveEmailEmpty') && errors.whoDriveEmail)) {
      clearErrors('whoDriveEmail');
    }
    if (
      !watch('whoDriveEmailEmpty') &&
      !!watch('whoDriveEmail') &&
      watch('whoDriveEmail').search(emailValidation) === -1
    ) {
      setError('whoDriveEmail', {
        type: 'manual',
        message:
          'Ungültige E-Mail Adresse. Eine gültige E-Mail darf nur lateinische Buchstaben, Zahlen, @ und . enthalten.'
      });
    }
  }, [watch('whoDriveEmailEmpty'), watch('whoDriveEmail'), errors]);

  const autoIsNotOkay =
    (watch('autoIsOkay') === false &&
      !!watch('autoIsNotOkayFirma') &&
      !!watch('autoIsNotOkayAdress') &&
      !!watch('autoIsNotOkayTelephone')) ||
    !!watch('autoIsOkay');

  const needPolice =
    (!!watch('needPolice') &&
      !!watch('needPoliceDienststelle') &&
      watch('needPoliceDienststelle').length > 0 &&
      watch('needPoliceGetCaseNumber') !== undefined &&
      watch('needPoliceCriminalWarning') !== undefined &&
      watch('needPoliceDrunkDriverWasTested') !== undefined &&
      watch('needPoliceDrunkDrugsTest') !== undefined) ||
    watch('needPolice') === false;

  const needPoliceCaseNumber =
    (!!watch('needPolice') &&
      (watch('needPoliceGetCaseNumber') === false ||
        (!!watch('needPoliceGetCaseNumber') &&
          !!watch('needPoliceGetCaseNumberAktenzeichen') &&
          watch('needPoliceGetCaseNumberAktenzeichen').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceCriminalWarning =
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') &&
          !!watch('needPoliceCriminalWarningWho') &&
          watch('needPoliceCriminalWarningWho').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceDrunkDriverWasTested =
    watch('needPoliceDrunkDriverWasTested') === false ||
    (!!watch('needPoliceDrunkDriverWasTested') &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonName') &&
      watch('needPoliceCriminalWarningWhoFirstPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel').length > 0);

  const needPoliceDrunkDriverWasTestedSecondPerson =
    !watch('showSecondDrunkPerson') ||
    (!!watch('showSecondDrunkPerson') &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonName') &&
      watch('needPoliceCriminalWarningWhoSecondPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel').length > 0);

  const needPoliceDrunkDrugsTest =
    watch('needPoliceDrunkDrugsTest') === false ||
    (!!watch('needPoliceDrunkDrugsTest') &&
      !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') &&
      watch('needPoliceDrunkDrugsTestWhoWasTestedFirst').length > 0 &&
      watch('needPoliceDrunkDrugsTestResultFirst') !== undefined &&
      (!watch('showSecondDrugPerson') ||
        (!!watch('showSecondDrugPerson') &&
          !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') &&
          watch('needPoliceDrunkDrugsTestWhoWasTestedSecond').length > 0 &&
          watch('needPoliceDrunkDrugsTestResultSecond') !== undefined)));

  const needPoliceDrunkDriver =
    watch('needPolice') === false ||
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      watch('needPoliceDrunkDriverWasTested') === false &&
      watch('needPoliceDrunkDrugsTest') === false) ||
    ((watch('needPoliceCriminalWarning') === false ||
      (watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      needPoliceDrunkDriverWasTested &&
      needPoliceDrunkDriverWasTestedSecondPerson &&
      needPoliceDrunkDrugsTest);

  const whoDriveValue = watch('whoDrive');
  const isFahrzeugnutzer = whoDriveValue === 'Fahrzeugnutzer';

  const whoDriveFields =
    whoDriveValue === 'Niemand' ||
    whoDriveValue === 'Andere Person' ||
    (isFahrzeugnutzer && watch('schadenAlsFahrzeugnutzer')) ||
    (isFahrzeugnutzer &&
      (!!watch('whoDriveName') || !!watch('fahrerInfo')?.fullname?.attributeValue) &&
      (!!watch('whoDrivePhone') || !!watch('fahrerInfo')?.mobilephone?.attributeValue) &&
      (!!watch('whoDriveEmail') ||
        !!watch('whoDriveEmailEmpty') ||
        !!watch('fahrerInfo')?.emailaddress1?.attributeValue));

  const otherWhoDriveFields =
    watch('whoDrive') === 'Niemand' ||
    watch('whoDrive') === 'Fahrzeugnutzer' ||
    (watch('whoDrive') === 'Andere Person' &&
      !!watch('whoDriveNameAndere') &&
      !!watch('whoDrivePhoneAndere') &&
      (!!watch('whoDriveEmailAndere') || !!watch('whoDriveEmailAndereEmpty')));

  useEffect(() => {
    if (watch('whoDrive') === 'Andere Person' || watch('whoDrive') === 'Niemand') {
      driverFields.forEach(el => clearErrors(el));
    }
    if (watch('whoDrive') === 'Fahrzeugnutzer' || watch('whoDrive') === 'Niemand') {
      otherFields.forEach(el => clearErrors(el));
    }
    if (watch('schadenAlsFahrzeugnutzer') === true && watch('whoDrive') === 'Fahrzeugnutzer') {
      setValue('whoDriveName', watch('fahrerDataFullname'));
      setValue('whoDrivePhone', watch('fahrerDataMobilephone'));
      setValue('whoDriveEmail', watch('fahrerDataEmailaddress1'));
    }
  }, [watch('whoDrive')]);

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  useEffect(() => {
    if (watch('hasDamage') === false) {
      setValue('carDamage', undefined);
      setValue('damageParts', undefined);
      setValue('damageList', damage);
    }
  }, [watch('hasDamage')]);

  const songstigeValue = () => {
    const songstigeItem = { type: true, value: 100000041 };
    const songstige =
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      !!watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        ? watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        : [];
    if (
      (songstige.length === 0 && songstige.length <= 8) ||
      (songstige.length <= 8 && songstige.some((el: OtherDamagePartsItemProps) => el[1].type !== true))
    ) {
      const newSongstigeITem = ['Sonstiges', songstigeItem];
      const damagePartsArray = Array.isArray(watch('damageParts')) ? watch('damageParts') : [];
      damagePartsArray.push(newSongstigeITem);
      setValue('damageParts', damagePartsArray);
    }
  };

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const checkDamageParts = () => {
    if (
      !!watch('hasDamage') &&
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      watch('damageParts').length > 0
    ) {
      watch('damageParts').forEach((item: OtherDamagePartsItemProps) => {
        if (item[1].type === true) {
          setError(`damageParts.${item[0]}`, {
            type: 'manual',
            message: 'Error'
          });
        }
      });
    }
  };

  useEffect(() => {
    !watch('hasDamage') && clearErrors('damageParts');
  }, [watch('hasDamage')]);

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      !!watch('hasDamage') && checkDamageParts();
      checkButtons(['hasDamage', 'damagePrivatOrBusinessTrip', 'autoIsOkay', 'needPolice', 'whoDrive']);
      !!watch('needPolice') &&
        checkButtons([
          'needPoliceGetCaseNumber',
          'needPoliceCriminalWarning',
          'needPoliceDrunkDriverWasTested',
          'needPoliceDrunkDrugsTest'
        ]);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        checkButtons(['needPoliceDrunkDrugsTestResultFirst']);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        !!watch('showSecondDrugPerson') &&
        checkButtons(['needPoliceDrunkDrugsTestResultSecond']);
    }
  };

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') === false) {
      clearErrors('needPoliceGetCaseNumberAktenzeichen');
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') === false) {
      clearErrors('needPoliceCriminalWarningWho');
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') === false) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') === false) {
      clearErrors('needPoliceDrunkDrugsTestWhoWasTestedFirst');
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('needPolice') === false) {
      clearErrors([
        'needPoliceDienststelle',
        'needPoliceCriminalWarningWho',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDienststelle',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond',
        'needPoliceGetCaseNumberAktenzeichen',
        'needPoliceAdressAndOffice',
        'needPoliceCriminalWarning',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTest',
        'needPoliceGetCaseNumber'
      ]);
    }
  }, [watch('needPolice')]);

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') !== undefined) {
      clearErrors(['needPoliceGetCaseNumberAktenzeichen', 'needPoliceGetCaseNumber']);
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') !== undefined) {
      clearErrors(['needPoliceCriminalWarningWho', 'needPoliceCriminalWarning']);
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') !== undefined) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') !== undefined) {
      clearErrors([
        'needPoliceDrunkDrugsTest',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('whoDriveEmailAndereEmpty')) {
      clearErrors('whoDriveEmailAndere');
    }
  }, [watch('whoDriveEmailAndereEmpty')]);

  useEffect(() => {
    if (watch('whoDriveEmailEmpty')) {
      clearErrors('whoDriveEmail');
    }
  }, [watch('whoDriveEmailEmpty')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <div style={showChooseDamage ? { display: 'flex' } : {}}>
          <div className={styles.buttons_damage_block}>
            <TwoButtonsComponent
              reactHookFormData={reactHookFormData}
              fieldName='Sind an deinem Fahrzeug Schäden entstanden?'
              fieldRegister='hasDamage'
              fieldRegisterSecond='hasDamage'
            />

            {showChooseDamage && windowWidth < 1400 && watch('damageList') !== undefined && (
              <div style={{ overflow: 'hidden' }}>
                <CSSTransition
                  in={showChooseDamageSlider}
                  timeout={400}
                  classNames={slideQuest}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={styles.songstige_block_small}>
                    {windowWidth < 900 ? (
                      <CarIconMobile reactHookFormData={reactHookFormData} />
                    ) : (
                      <CarDamageIcon reactHookFormData={reactHookFormData} />
                    )}
                    <div
                      className={
                        windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full'
                          ? styles.songstige_block_mobile
                          : styles.songstige_block
                      }
                    >
                      {windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full' && (
                        <div
                          id='returnToCarImageButton'
                          className={styles.back_item}
                          onClick={() => setValue('carImageMobile', 'full')}
                        >
                          <ArrowLeftBigIcon />
                          <div className={styles.back_text}>Zurück</div>
                        </div>
                      )}
                      <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                        <PlusIcon />
                        <Distance side='10' />
                        Sonstige Teile
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            )}
            {showChooseDamage && <DamagePartChoose reactHookFormData={reactHookFormData} />}
          </div>
          {showChooseDamage && windowWidth >= 1400 && watch('damageList') !== undefined && (
            <div style={{ overflow: 'hidden' }}>
              <CSSTransition
                in={showChooseDamageSlider}
                timeout={600}
                classNames={sideSlide}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <CarDamageIcon reactHookFormData={reactHookFormData} />
                  <div className={styles.songstige_block}>
                    <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                      <PlusIcon />
                      <Distance side='10' />
                      Sonstige Teile
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>
        {watch('hasDamage') ? <Distance top='10' /> : <Distance top='20' />}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        {watch('autoIsOkay') === false && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockAutoIsOk} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Wo kann das Fahrzeug besichtigt werden?</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayFirma'
                  fieldName='Firma/Name'
                  fieldPlaceholder='Muster GmbH'
                />

                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                />

                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayTelephone'
                  fieldName='Telefon'
                />

                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Wurde ein Polizeibericht aufgenommen?'
          fieldRegister='needPolice'
          fieldRegisterSecond='needPolice'
        />

        <Distance top='20' />

        {watch('needPolice') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockNeedPolice} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Details zur polizeilichen Unfallaufnahme:</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceDienststelle'
                  fieldName='Dienststelle'
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceAdressAndOffice'
                  fieldName='Anschrift und Telefonnummer der Dienststelle'
                  fieldPlaceholder='Musterstr. 100, 12345 Berlin, Tel.'
                  optional={true}
                />

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName={'Hast du ein Aktenzeichen erhalten?'}
                  fieldRegister={'needPoliceGetCaseNumber'}
                  fieldRegisterSecond={'needPoliceGetCaseNumber'}
                />
                <Distance top='10' />

                {!!watch('needPoliceGetCaseNumber') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceGetCaseNumber}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <Distance top='10' />
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceGetCaseNumberAktenzeichen'
                          fieldName='Aktenzeichen/Tagebuchnummer'
                        />
                        <Distance top='10' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <Distance top={watch('needPoliceGetCaseNumber') ? '10' : '20'} />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde eine gebührenpflichtige Verwarnung ausgesprochen?'
                  fieldRegister='needPoliceCriminalWarning'
                  fieldRegisterSecond='needPoliceCriminalWarning'
                />
                <Distance top='20' />

                {!!watch('needPoliceCriminalWarning') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceCriminalWarning}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWho'
                          fieldName='Wer hat die gebührenpflichtige Verwarnung erhalten?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Alkoholtest durchgeführt?'
                  fieldRegister='needPoliceDrunkDriverWasTested'
                  fieldRegisterSecond='needPoliceDrunkDriverWasTested'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDriverWasTested') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceDrunkDriverWasTested}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonName'
                          fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                          fieldPlaceholder='Max Mustermann'
                          optional={!watch('needPoliceDrunkDriverWasTested')}
                          optionalText=''
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonDrunkLevel'
                          fieldName='Wert Alkoholtest'
                          fieldPlaceholder='z.B. 1,1 Promille'
                          optional={!watch('needPoliceDrunkDriverWasTested')}
                          optionalText=''
                        />

                        <div
                          style={{ width: '100%' }}
                          className={watch('showSecondDrunkPerson') ? '' : styles.hide__block}
                        >
                          <>
                            <Distance top='20' />

                            <div className={styles.close_header}>
                              <h6>Weitere Person:</h6>
                              <div
                                className={styles.close_block}
                                onClick={() => {
                                  setValue('showSecondDrunkPerson', false);
                                  clearErrors([
                                    'needPoliceCriminalWarningWhoSecondPersonName',
                                    'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                                  ]);
                                }}
                              >
                                <TrashIcon />
                              </div>
                            </div>

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonName'
                              fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                              fieldPlaceholder='Max Mustermann'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                            <Distance top='5' />

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                              fieldName='Wert Alkoholtest'
                              fieldPlaceholder='z.B. 1,1 Promille'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                          </>
                        </div>

                        <div
                          style={{ width: '100%' }}
                          onClick={() => setValue('showSecondDrunkPerson', true)}
                          className={watch('showSecondDrunkPerson') ? styles.hide__block : styles.hide__block__show}
                        >
                          <Distance top='-5' />+ Weitere Person
                        </div>
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Drogentest durchgeführt?'
                  fieldRegister='needPoliceDrunkDrugsTest'
                  fieldRegisterSecond='needPoliceDrunkDrugsTest'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDrugsTest') && (
                  <CSSTransition
                    in={showBlockNeedPoliceDrunkDrugsTest}
                    timeout={400}
                    classNames={slideQuest}
                    mountOnEnter
                    unmountOnExit
                  >
                    <>
                      <TextFieldComponent
                        reactHookFormData={reactHookFormData}
                        fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedFirst'
                        fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                        fieldPlaceholder='Max Mustermann'
                      />
                      <Distance top='5' />

                      <TwoButtonsComponent
                        reactHookFormData={reactHookFormData}
                        fieldName='Wert Drogentest'
                        fieldRegister='needPoliceDrunkDrugsTestResultFirst'
                        fieldRegisterSecond='needPoliceDrunkDrugsTestResultFirst'
                        firstButtonText='Positiv'
                        firstButtonValue='Positiv'
                        secondButtonText='Negativ'
                        secondButtonValue='Negativ'
                      />

                      <div
                        style={{ width: '100%' }}
                        className={watch('showSecondDrugPerson') ? '' : styles.hide__block}
                      >
                        <>
                          <Distance top='20' />

                          <div className={styles.close_header}>
                            <h6>Weitere Person:</h6>
                            <div
                              id='deleteSecondDrugPersonButton'
                              className={styles.close_block}
                              onClick={() => {
                                setValue('showSecondDrugPerson', false);
                                clearErrors([
                                  'needPoliceDrunkDrugsTestWhoWasTestedSecond',
                                  'needPoliceDrunkDrugsTestResultSecond'
                                ]);
                              }}
                            >
                              <TrashIcon />
                            </div>
                          </div>

                          <TextFieldComponent
                            reactHookFormData={reactHookFormData}
                            fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedSecond'
                            fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                            fieldPlaceholder='Max Mustermann'
                            optional={!watch('showSecondDrugPerson')}
                            optionalText=''
                          />
                          <Distance top='5' />

                          <TwoButtonsComponent
                            reactHookFormData={reactHookFormData}
                            fieldName='Wert Drogentest'
                            fieldRegister='needPoliceDrunkDrugsTestResultSecond'
                            fieldRegisterSecond='needPoliceDrunkDrugsTestResultSecond'
                            firstButtonText='Positiv'
                            firstButtonValue='Positiv'
                            secondButtonText='Negativ'
                            secondButtonValue='Negativ'
                            optional={!watch('showSecondDrugPerson')}
                            optionalText=''
                          />
                        </>
                      </div>

                      <div
                        id='showSecondDrugPersonButton'
                        style={{ width: '100%' }}
                        onClick={() => setValue('showSecondDrugPerson', true)}
                        className={watch('showSecondDrugPerson') ? styles.hide__block : styles.hide__block__show}
                      >
                        <Distance top='-5' />+ Weitere Person
                      </div>
                      <Distance top='20' />
                    </>
                  </CSSTransition>
                )}
              </div>
            </CSSTransition>
          </div>
        )}
        {watch('needPolice') === true && <Distance top='10' />}

        <ThreeButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='whoDrive'
          fieldName='Fahrer des Kundenfahrzeugs zum Zeitpunkt des Schadens'
          firstButtonText='Fahrzeugnutzer'
          firstButtonValue='Fahrzeugnutzer'
          secondButtonText='Andere Person'
          secondButtonValue='Andere Person'
          thirdButtonText='Niemand'
          thirdButtonValue='Niemand'
        />

        <Distance top='20' />

        {((!watch('schadenAlsFahrzeugnutzer') && watch('whoDrive') === 'Fahrzeugnutzer') ||
          watch('whoDrive') === 'Andere Person') && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockWhoDrive} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                {watch('whoDrive') === 'Andere Person' && (
                  <div>
                    <h6>Kontaktdaten:</h6>

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveNameAndere'
                      fieldName='Name'
                      fieldPlaceholder='Max Mustermann'
                    />
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDrivePhoneAndere'
                      fieldName='Telefon'
                    />
                    <Distance top='5' />

                    <EmailFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveEmailAndere'
                      optional={watch('whoDriveEmailAndereEmpty') === true}
                      optionalText=''
                      fieldName='E-mail adresse'
                      fieldPlaceholder='Muster@mail.com'
                      readOnly={!!watch('whoDriveEmailAndereEmpty')}
                    />
                    <Distance top='5' />

                    <div className={styles.checkbox}>
                      <input
                        {...register('whoDriveEmailAndereEmpty', { required: false })}
                        id='whoDriveEmailAndereEmpty'
                        type='checkbox'
                      />
                      <label htmlFor='whoDriveEmailAndereEmpty'>E-Mail unbekannt</label>
                    </div>
                    <Distance top='15' />

                    <div className={styles.row_columns_inputs}>
                      <ZipcodeFieldComponent
                        reactHookFormData={reactHookFormData}
                        smallField={windowWidth > 900 && true}
                        fieldRegister='whoDrivePLZAndere'
                        fieldName='Postleitzahl'
                        optional={true}
                      />

                      <TextFieldComponent
                        reactHookFormData={reactHookFormData}
                        fieldRegister='whoDriveStadtAndere'
                        fieldName='Stadt'
                        fieldPlaceholder='Muster Stadt'
                        optional={true}
                        doubleField={windowWidth > 900 && true}
                      />
                    </div>

                    <Distance top='5' />

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveAdressAndere'
                      fieldName='Straße, Hausnummer'
                      fieldPlaceholder='Musterstr. 100'
                      optional={true}
                    />
                    <Distance top='30' />
                  </div>
                )}

                {watch('whoDrive') === 'Fahrzeugnutzer' && (
                  <div>
                    <h6>Kontaktdaten:</h6>

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveName'
                      fieldName='Name'
                      fieldPlaceholder='Max Mustermann'
                    />
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDrivePhone'
                      fieldName='Telefon'
                    />
                    <Distance top='5' />

                    <EmailFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveEmail'
                      optional={watch('whoDriveEmailEmpty') === true}
                      optionalText=''
                      fieldName='E-mail adresse'
                      fieldPlaceholder='Muster@mail.com'
                      readOnly={!!watch('whoDriveEmailEmpty')}
                    />
                    <Distance top='5' />

                    <div className={styles.checkbox}>
                      <input
                        {...register('whoDriveEmailEmpty', { required: false })}
                        id='whoDriveEmailEmpty'
                        type='checkbox'
                      />
                      <label htmlFor='whoDriveEmailEmpty'>E-Mail unbekannt</label>
                    </div>
                    <Distance top='15' />

                    <div className={styles.row_columns_inputs}>
                      <ZipcodeFieldComponent
                        reactHookFormData={reactHookFormData}
                        smallField={!watch('fahrerInfo')?.address1_city?.attributeValue && windowWidth > 900 && true}
                        fieldRegister='whoDrivePLZ'
                        fieldName='Postleitzahl'
                        optional={true}
                      />

                      <TextFieldComponent
                        reactHookFormData={reactHookFormData}
                        fieldRegister='whoDriveStadt'
                        fieldName='Stadt'
                        fieldPlaceholder='Muster Stadt'
                        optional={true}
                        doubleField={
                          !watch('fahrerInfo')?.address1_postalcode?.attributeValue && windowWidth > 900 && true
                        }
                      />
                    </div>

                    <Distance top='5' />

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveAdress'
                      fieldName='Straße, Hausnummer'
                      fieldPlaceholder='Musterstr. 100'
                      optional={true}
                    />
                    <Distance top='30' />
                  </div>
                )}
              </div>
            </CSSTransition>
          </div>
        )}

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnToTypeButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ChooseGlassDamage;
