import { Tooltip } from '@mui/material';
import IconHover from 'components/icon-hover';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import React from 'react';
import { CLEAR_ALL_FILTERS_BUTTON_TEXT } from '../../utils';
import { Flex } from 'common/common-components.styled';
import { ClearAllFiltersButtonProps } from '../../clear-all-filters-button.props';

export const CompressedClearAllFiltersButton = ({ className, clearFilter }: ClearAllFiltersButtonProps) => {
  return (
    <Tooltip title={CLEAR_ALL_FILTERS_BUTTON_TEXT}>
      <Flex className={className} onClick={() => clearFilter()}>
        <IconHover>
          <CircleCloseIcon />
        </IconHover>
      </Flex>
    </Tooltip>
  );
};
