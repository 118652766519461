import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { msalInstance } from './services/auth-service';
import { ThemeProvider as MUiThemeProvider } from '@mui/material/styles';
import Pages from './pages';
import store from './store';

import './index.scss';
import { ThemeProvider } from 'styled-components';
import { mainTheme, materialTheme } from './common/global-styles';
import { reactQuerySetting } from 'config/react-query.config';
import reportWebVitals from './reportWebVitals';
import HelmetComponent from 'components/helmet-component/helmet-component';

export const queryClient = new QueryClient(reactQuerySetting);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <MUiThemeProvider theme={materialTheme}>
      <ThemeProvider theme={mainTheme}>
        <Provider store={store}>
          <Router basename='/'>
            <HelmetComponent />

            <MsalProvider instance={msalInstance}>
              <Pages />
            </MsalProvider>
          </Router>
        </Provider>
      </ThemeProvider>
    </MUiThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
