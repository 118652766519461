import { OnDragEndResponder } from 'react-beautiful-dnd';
import { InspectionDateDraggableEnum } from '../inspection-date.props';
import { useInspectionDateUnlinkFromCarModal } from 'hooks/modals/inspection-date/unlink-from-car/unlink-from-car.modal';
import { useInspectionDateLinkToCarModal } from 'hooks/modals/inspection-date/link-to-car/link-to-car.modal';
import { getInspectionDateItemDescriptionText } from '../utils';
import { useMyVehicleInspectionDateQuery } from 'pages/existing-vehicle-page/hooks/query/use-my-vehicle-inspection-date.query';
import { useMyVehicleIsTaskOverdueOnSelectInitRegistration } from './use-is-task-overdue-on-selecting-initial-registration';

export const useInspectionDateOnDragEnd = () => {
  const { fuhrpark, carInspectionDateQuery } = useMyVehicleInspectionDateQuery();

  const { openInspectionDateUnlinkFromCarModal } = useInspectionDateUnlinkFromCarModal();
  const { openInspectionDateLinkToCarModal } = useInspectionDateLinkToCarModal();

  const { getIsTaskOverdue } = useMyVehicleIsTaskOverdueOnSelectInitRegistration();

  const dragEndHandler: OnDragEndResponder = result => {
    const idsForSubmit = {
      fuhrparkId: fuhrpark?.new_fuhrparkid || '',
      pruftermineId: result.draggableId
    };

    if (result.source.droppableId === result.destination?.droppableId) {
      return;
    }

    if (result.source.droppableId === InspectionDateDraggableEnum.AssignedToVehicle) {
      return openInspectionDateUnlinkFromCarModal(idsForSubmit);
    }

    if (result.source.droppableId === InspectionDateDraggableEnum.NotAssignedToVehicle) {
      const draggableInspectionDateDate = carInspectionDateQuery.allPruftermines.find(
        item => item.id === idsForSubmit.pruftermineId
      );

      if (draggableInspectionDateDate) {
        openInspectionDateLinkToCarModal({
          ...idsForSubmit,
          firstRegistrationDate: fuhrpark?.new_ezl,
          willOverdueTaskCreatedOnSelectingFirstRegistrationDate: getIsTaskOverdue(
            draggableInspectionDateDate.attributes
          ),
          description: getInspectionDateItemDescriptionText(draggableInspectionDateDate.attributes)
        });
      }
    }
  };

  return { dragEndHandler };
};
