import axios from 'axios';
import authService from '../../services/auth-service';
import { API_ENDPOINT } from '../../constants';

export const getRecords = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/getDrivers`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};

export const getFilters = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/getDriversFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getStatuses = async (params, isDashboardRequest) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/getCalculatedStatuses`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      search: params,
      isDashboardRequest
    }
  });
};

export const getIsAccountBooked = async accountId => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driversCheck/getIsAccountBooked`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
};

export const formatDate = date => {
  if (new Date(date).getTime()) {
    return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  return '-';
};
