import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const NewCarIcon = ({ width = 27, height = 22, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 27 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.748 8.51413C23.9124 8.94939 23.9995 9.41368 23.9995 9.88764V10.439L23.748 16.4167H8.1267V17.9643C8.1267 18.3899 7.77848 18.7381 7.35289 18.7381H4.25764C3.83205 18.7381 3.48383 18.3899 3.48383 17.9643V15.9524L3.17431 14.4725C3.12595 14.221 3.09693 13.9501 3.09693 13.689V9.88764C3.09693 9.42335 3.18399 8.94939 3.34842 8.51413L3.72565 7.51784H1.93622C1.68473 7.51784 1.50095 7.28569 1.55899 7.04388L1.84917 5.7961C1.93622 5.44789 2.24575 5.19641 2.60364 5.19641H4.49946L5.98906 2.22691C6.38564 1.43375 7.18847 0.940445 8.06868 0.940445H19.0278C19.908 0.940445 20.7108 1.44343 21.1074 2.22691L22.597 5.19641H24.4928C24.8507 5.19641 25.1699 5.44789 25.2473 5.7961L25.5374 7.04388C25.5955 7.28569 25.4117 7.51784 25.1602 7.51784H23.3708L23.748 8.51413Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M23.748 8.51413C23.9124 8.9494 23.9995 9.41368 23.9995 9.88764V10.439L23.748 16.4167H8.1267V17.9643C8.1267 18.3899 7.77848 18.7381 7.35289 18.7381H4.25764C3.83205 18.7381 3.48383 18.3899 3.48383 17.9643V15.9524L3.17431 14.4725C3.12595 14.221 3.09693 13.9502 3.09693 13.689V9.88764C3.09693 9.42335 3.18399 8.9494 3.34842 8.51413L3.72565 7.51784H1.93622C1.68473 7.51784 1.50095 7.2857 1.55899 7.04388L1.84917 5.79611C1.93622 5.4479 2.24575 5.19641 2.60364 5.19641H4.49946L6.75682 6.72979H20.2504L22.597 5.19641H24.4928C24.8507 5.19641 25.1699 5.4479 25.2473 5.79611L25.5374 7.04388C25.5955 7.2857 25.4117 7.51784 25.1602 7.51784H23.3708L23.748 8.51413Z'
          fill={fill}
        />
      )}
      <path
        d='M6.57908 16.4167H8.1267V17.9643C8.1267 18.3899 7.77848 18.7381 7.35289 18.7381H4.25764C3.83205 18.7381 3.48383 18.3899 3.48383 17.9643V15.9524L3.17431 14.4725C3.12595 14.221 3.09693 13.9501 3.09693 13.689V9.88764C3.09693 9.42335 3.18399 8.94939 3.34842 8.51413L3.72565 7.51784H1.93622C1.68473 7.51784 1.50095 7.28569 1.55899 7.04388L1.84917 5.7961C1.93622 5.44789 2.24575 5.19641 2.60364 5.19641H4.49946L5.98906 2.22691C6.38564 1.43375 7.18847 0.940445 8.06868 0.940445H19.0278C19.908 0.940445 20.7108 1.44343 21.1074 2.22691L22.597 5.19641H24.4928C24.8507 5.19641 25.1699 5.44789 25.2473 5.7961L25.5374 7.04388C25.5955 7.28569 25.4117 7.51784 25.1602 7.51784H23.3708L23.748 8.51413C23.9124 8.94939 23.9995 9.41368 23.9995 9.88764V10.439'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.4281 11.7738H8.64909L8.09776 10.6808'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.97571 6.74403H20.1305'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='18.3813' cy='15.6783' r='5.415' fill={hovered ? 'white' : 'none'} />
      <path
        d='M8.08801 16.4166H13.0192'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1074 13.9493C13.8297 11.8079 15.8528 10.2616 18.2383 10.2616C21.2272 10.2616 23.655 12.6894 23.655 15.6783C23.655 18.6671 21.2272 21.095 18.2383 21.095C16.377 21.095 14.7318 20.1515 13.7564 18.7181'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.9329 15.6783H20.8297'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3813 13.2299V18.1267'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
