/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from './detail-car-info.module.scss';
import { Distance } from 'common/common-components.styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import AnotherPersonComponent from './another-person-component';
import DriverPersonComponent from './driver-person-component';
import ZipcodeFieldComponent from '../components/zipcode-field-component';
import TextFieldComponent from '../components/text-field-component';
import DateFieldComponent from '../components/date-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/slide-detail-carinfo.module.css';
import slideReverse from '../transition/backward-slide-detail-carinfo.module.css';
import slideReturn from '../transition/return-slide-detail-carinfo.module.css';
import { FormFieldTypes, PrefixForId } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import FormField from 'components/form-hook/form-field/form-field';
import { DEFAULT_TIME_VALUE } from 'components/form-hook/form-field/components/time-field/utils';
import { convertUTCToLocalDate, convertLocalToUTCDate } from '../utils';
import useWindowSize from 'hooks/use-window-size';
import { StyledTextFieldWithoutBorders } from 'components/form-hook/form-field/form-field.styled';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledReportDamageDatePicker } from 'pages/report-damage-page/report-damage-component.styled';
import { useTheme } from 'styled-components';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { UseFormProps } from '../report-damage.props';
import { clearErrorsOnValueChange, fieldsToValidateDetailCarInfo } from './utils';
import { useClearErrorOnValueChange } from '../hooks/use-clear-error-on-value-change';
import { OptionSetField } from '../components/option-set-field/option-set-field';
import { classificationDamageLocationCode } from './detail-car-info.field';

const DetailCarInfo = ({ reactHookFormData }: UseFormProps) => {
  const [buttonActive, setButtonActive] = useState(false);
  const [showBlock, setShowBlock] = useState(false);
  const [showAnderePersonBlock, setShowAnderePersonBlock] = useState(false);
  const [showDriverDataBlock, setShowDriverDataBlock] = useState(false);
  const [hoveredFirstCalendar, setHoveredFirstCalendar] = useState(false);
  const [hoveredSecondCalendar, setHoveredSecondCalendar] = useState(false);
  const { windowWidth } = useWindowSize();
  const theme = useTheme();
  const {
    watch,
    control,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    document.getElementById('unauthorizedPageContainer')?.scrollTo(0, 0);
    document.getElementById('SMform')?.scrollTo(0, 0);
    document.getElementById('UPcontentConatiner')?.scrollTo(0, 0);
    document.getElementById('detailBlockView')?.scrollTo(0, 0);
  }, []);

  let anotherPersonData =
    ((watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')) &&
    !!watch('anotherPersonFahrerName') &&
    watch('anotherPersonFahrerName').length > 0 &&
    !!watch('anotherPersonFahrerEmail') &&
    watch('anotherPersonFahrerEmail').length > 0 &&
    !!watch('anotherPersonFahrerEmailRepeat') &&
    watch('anotherPersonFahrerEmailRepeat').length > 0 &&
    !!watch('anotherPersonFahrerTelefon') &&
    watch('anotherPersonFahrerTelefon').length > 0 &&
    (!watch('showSecondFahrerAnother') ||
      (!!watch('showSecondFahrerAnother') &&
        !!watch('anotherPersonFahrerTelefon2') &&
        watch('anotherPersonFahrerTelefon2').length > 0));

  let driverPersonData =
    !!watch('schadenAlsFahrzeugnutzer') &&
    !!watch('fahrerDataFullname') &&
    watch('fahrerDataFullname').length > 0 &&
    !!watch('fahrerDataEmailaddress1') &&
    watch('fahrerDataEmailaddress1').length > 0 &&
    !!watch('fahrerDataEmailaddressRepeat') &&
    watch('fahrerDataEmailaddressRepeat').length > 0 &&
    !!watch('fahrerDataMobilephone') &&
    watch('fahrerDataMobilephone').length > 0 &&
    (!watch('showSecondFahrer') ||
      (!!watch('showSecondFahrer') && !!watch('fahrerDataMobilephone1') && watch('fahrerDataMobilephone1').length > 0));

  const isContactPersonData =
    (watch('isContactPerson') === false &&
      watch('isContactPersonName') &&
      watch('isContactPersonEmail') &&
      watch('isContactPersonEmailRepeat') &&
      watch('isContactPersonPhone') &&
      (watch('showSecondContactPersonPhone') ? watch('isContactPersonPhoneSecond') : true)) ||
    watch('isContactPerson') === true;

  useEffect(() => {
    !!watch('Schadendatum') &&
    !!watch('Uhrzeit') &&
    !!watch('Land') &&
    !!watch('Stadt') &&
    !!watch('Strasse') &&
    !!watch('Postleitzahl') &&
    watch('Postleitzahl').length > 4 &&
    (!!watch('schadenAlsFahrzeugnutzer') ||
      !!watch('schadenAlsAnderePerson') ||
      (watch('fuhrparkInfo') === null && watch('fahrerInfo') === null)) &&
    Object.keys(errors).length === 0 &&
    (anotherPersonData || driverPersonData) &&
    isContactPersonData
      ? setButtonActive(true)
      : setButtonActive(false);
  }, [watch()]);

  useEffect(() => {
    if (watch('schadenAlsFahrzeugnutzer') === true) {
      clearErrors('anotherPersonFahrerName');
      clearErrors('anotherPersonFahrerEmail');
      clearErrors('anotherPersonFahrerEmailRepeat');
      clearErrors('anotherPersonFahrerTelefon');
      clearErrors('anotherPersonFahrerTelefon2');
    }
  }, [watch('schadenAlsFahrzeugnutzer')]);

  useClearErrorOnValueChange(reactHookFormData, 'isContactPerson', clearErrorsOnValueChange.contactPerson);

  useEffect(() => {
    if (watch('schadenAlsAnderePerson') === true) {
      clearErrors('fahrerDataFullname');
      clearErrors('fahrerDataEmailaddress1');
      clearErrors('fahrerDataEmailaddressRepeat');
      clearErrors('fahrerDataMobilephone');
      clearErrors('fahrerDataMobilephone1');
    }
  }, [watch('schadenAlsAnderePerson')]);

  const setSchadenAlsFahrzeugnutzer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValue('schadenAlsFahrzeugnutzer', true);
    setValue('schadenAlsAnderePerson', undefined);
  };

  const setSchadenAlsAnderePerson = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValue('schadenAlsFahrzeugnutzer', undefined);
    setValue('schadenAlsAnderePerson', true);
  };

  useEffect(() => {
    if (!!watch('schadenAlsFahrzeugnutzer') || !!watch('schadenAlsAnderePerson')) {
      clearErrors('youAre');
    }
  }, [watch('schadenAlsFahrzeugnutzer'), watch('schadenAlsAnderePerson')]);

  useEffect(() => {
    (watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')
      ? setShowAnderePersonBlock(true)
      : setShowAnderePersonBlock(false);
  }, [watch('fuhrparkInfo'), watch('fahrerInfo'), watch('schadenAlsAnderePerson')]);

  useEffect(() => {
    watch('schadenAlsFahrzeugnutzer') ? setShowDriverDataBlock(true) : setShowDriverDataBlock(false);
  }, [watch('schadenAlsFahrzeugnutzer')]);

  useEffect(() => {
    if (watch('Schadendatum')) {
      clearErrors('Schadendatum');
      if (watch('Uhrzeit')) {
        let date = new Date(watch('Schadendatum'));
        date.setHours(watch('Uhrzeit').hour, watch('Uhrzeit').minutes);
        if (watch('Schadendatum').toISOString() !== date.toISOString()) {
          setValue('Schadendatum', date);
        }
      }
    }
  }, [watch('Schadendatum'), watch('Uhrzeit')]);

  const moveToPreviousStep = () => {
    setValue('direction', 'back');
    setShowBlock(false);
    setValue('previousStep', 1);
    setTimeout(() => {
      setValue('step', 0);
    }, 500);
  };

  const checkFields = (fieldsArr: Array<[string, string]>) => {
    fieldsArr.forEach((item: [string, string]) => {
      if (watch(item[0]) === undefined || watch(item[0]) === '') {
        setError(item[0], {
          type: 'manual',
          message: `${item[1]} erforderlich.`
        });
      }
    });
  };

  const onSubmitValidation = () => {
    checkFields(fieldsToValidateDetailCarInfo.common);

    if (watch('schadenAlsAnderePerson')) {
      checkFields(fieldsToValidateDetailCarInfo.reportDamageAsByOtherPerson);
      if (watch('showSecondFahrerAnother')) {
        checkFields([['anotherPersonFahrerTelefon2', 'Telefon']]);
      }
    }
    if (watch('schadenAlsFahrzeugnutzer')) {
      checkFields(fieldsToValidateDetailCarInfo.reportDamageAsVehicleUsers);
      if (watch('showSecondFahrer')) {
        checkFields([['fahrerDataMobilephone1', 'Telefon']]);
      }
    }

    if (watch('schadenAlsFahrzeugnutzer') || watch('schadenAlsAnderePerson')) {
      checkFields([['isContactPerson', '']]);
    }

    if (watch('isContactPerson') === false) {
      checkFields(fieldsToValidateDetailCarInfo.noContactPerson);
      if (watch('showSecondContactPersonPhone')) {
        checkFields([['isContactPersonPhoneSecond', 'Telefon']]);
      }
    }
  };

  const moveToNextStep = () => {
    if (buttonActive) {
      setValue('direction', 'forward');
      setShowBlock(false);
      watch('previousStep') !== 'detail'
        ? setTimeout(() => setValue('step', 2), 500)
        : setTimeout(() => setValue('step', 'detail'), 500);
      setTimeout(() => setValue('previousStep', 1), 500);
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      if (!showAnderePersonBlock && !showDriverDataBlock) {
        setError('youAre', {
          type: 'manual',
          message: 'Error'
        });
      }
      onSubmitValidation();
    }
  };

  const field: FormFieldModel = {
    name: 'Uhrzeit',
    type: FormFieldTypes.Time,
    defaultValue: watch('Uhrzeit') !== undefined ? watch('Uhrzeit') : DEFAULT_TIME_VALUE,
    validation: {}
  };

  const slideClassName =
    (watch('direction') === 'back' || watch('previousStep') === 'detail') && watch('previousStep') !== 2
      ? slideReturn
      : watch('direction') === 'forward'
      ? slide
      : slideReverse;

  return (
    <div id='detailBlockView' className={styles.block_view}>
      <CSSTransition in={showBlock} timeout={800} classNames={slideClassName} mountOnEnter unmountOnExit>
        <section className={styles.container}>
          <div className={styles.row_columns}>
            <DateFieldComponent
              reactHookFormData={reactHookFormData}
              fieldRegister='Schadendatum'
              fieldName='Schadendatum'
              halfField={true}
              tillToday={true}
              shouldTransformToUTC={false}
              needTooltip={true}
              tooltipText='Wenn das tatsächliche Datum unbekannt ist, gib bitte den Tag der Feststellung an.'
              tooltipPosition={windowWidth > 900 ? 'top' : 'bottom'}
            />

            <div className={styles.half_input}>
              {windowWidth <= 900 && <Distance top='-2' />}
              <div className={styles.form_name}>
                <span>Uhrzeit</span>
              </div>
              <Distance top='-2' />
              <FormField field={field} reactHookFormData={reactHookFormData} keepDataFromField={true} />
            </div>
          </div>
          <div className={styles.data_fields}>
            <h6>Wo hat sich der Schaden ereignet?</h6>

            <TextFieldComponent
              reactHookFormData={reactHookFormData}
              fieldRegister='Land'
              fieldName='Land'
              fieldPlaceholder='Muster Land'
            />

            <Distance top='5' />

            <div className={styles.row_columns}>
              <TextFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='Stadt'
                fieldName='Stadt'
                fieldPlaceholder='Muster Stadt'
                doubleField={true}
              />

              <ZipcodeFieldComponent
                reactHookFormData={reactHookFormData}
                smallField={true}
                fieldRegister='strPLZ'
                fieldName='PLZ'
                optional={true}
                noNeedDEvalidation={true}
              />
            </div>

            <Distance top='5' />

            <TextFieldComponent
              reactHookFormData={reactHookFormData}
              fieldRegister='Strasse'
              fieldName='Straße'
              fieldPlaceholder='Muster Straße'
            />

            <Distance top='5' />

            <OptionSetField
              reactHookFormData={reactHookFormData}
              fieldRegister='uds_classification_schadenortcode'
              fieldName='Wie lässt sich der Schadenort klassifizieren? (optional)'
              data={classificationDamageLocationCode}
            />
          </div>
          <Distance top='20' />

          {watch('fuhrparkInfo') !== null && watch('fahrerInfo') !== null && (
            <div className={styles.data_fields}>
              <div className={errors?.youAre ? styles.form_name_err : styles.form_name}>
                <span>Du meldest den Schaden als:</span>
              </div>

              <button
                id='vehicleUserButton'
                onClick={e => setSchadenAlsFahrzeugnutzer(e)}
                className={!watch('schadenAlsFahrzeugnutzer') ? styles.button_option_false : styles.button_option_true}
              >
                Fahrzeugnutzer
              </button>
              <Distance top='10' />
              <button
                id='otherPersonButton'
                onClick={e => setSchadenAlsAnderePerson(e)}
                className={!watch('schadenAlsAnderePerson') ? styles.button_option_false : styles.button_option_true}
              >
                Andere Person
              </button>
            </div>
          )}
          {watch('fuhrparkInfo') !== null && watch('fahrerInfo') !== null && <Distance top='30' />}
          {showAnderePersonBlock && (
            <>
              <AnotherPersonComponent reactHookFormData={reactHookFormData} />
              <Distance top='15' />
            </>
          )}
          {showDriverDataBlock && (
            <>
              <DriverPersonComponent reactHookFormData={reactHookFormData} />
              <Distance top='30' />
            </>
          )}
          <div className={styles.data_fields}>
            <ZipcodeFieldComponent
              reactHookFormData={reactHookFormData}
              fieldRegister='Postleitzahl'
              fieldName='Wo kann die Reparatur erfolgen?'
            />
          </div>
          <Distance top='30' />
          <div className={windowWidth <= 900 ? styles.mobile_column : styles.row_columns}>
            <div
              className={windowWidth <= 900 ? styles.data_fields : styles.half_input}
              onMouseEnter={() => setHoveredFirstCalendar(true)}
              onMouseLeave={() => setHoveredFirstCalendar(false)}
            >
              <div className={styles.form_name}>
                <span>
                  Wunschtermin <span className={styles.optional}>(optional)</span>
                </span>
              </div>
              <Controller
                control={control}
                name='Wunschtermin'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StyledReportDamageDatePicker
                      components={{
                        OpenPickerIcon: () => (
                          <CalendarWithCheckMarkIcon
                            color={theme.colors.darkBlue}
                            hovered={hoveredFirstCalendar}
                            id={`desiredDate${PrefixForId.FormDate}${PrefixForId.Open}`}
                          />
                        )
                      }}
                      PopperProps={{
                        id: `desiredDate${PrefixForId.FormDate}${PrefixForId.Calendar}`
                      }}
                      minDate={new Date()}
                      value={value ? convertUTCToLocalDate(value) : null}
                      onChange={date => {
                        onChange(convertLocalToUTCDate(date));
                      }}
                      renderInput={({ inputProps, ...restParams }) => (
                        <StyledTextFieldWithoutBorders
                          {...restParams}
                          error={false}
                          inputProps={{
                            ...inputProps,
                            id: `desiredDate${PrefixForId.FormDate}`,
                            placeholder: 'TT . MM . JJJJ'
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>

            {windowWidth <= 900 && <Distance top='15' />}

            <div
              className={windowWidth <= 900 ? styles.data_fields : styles.half_input}
              onMouseEnter={() => setHoveredSecondCalendar(true)}
              onMouseLeave={() => setHoveredSecondCalendar(false)}
            >
              <div className={styles.form_name}>
                <span>
                  Alternativer Termin <span className={styles.optional}>(optional)</span>
                </span>
              </div>
              <Controller
                control={control}
                name='AlternativerTermin'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StyledReportDamageDatePicker
                      components={{
                        OpenPickerIcon: () => (
                          <CalendarWithCheckMarkIcon
                            color={theme.colors.darkBlue}
                            hovered={hoveredSecondCalendar}
                            id={`alternativeAppointment${PrefixForId.FormDate}${PrefixForId.Open}`}
                          />
                        )
                      }}
                      PopperProps={{
                        id: `alternativeAppointment${PrefixForId.FormDate}${PrefixForId.Calendar}`
                      }}
                      minDate={new Date()}
                      value={value ? convertUTCToLocalDate(value) : null}
                      onChange={date => {
                        onChange(convertLocalToUTCDate(date));
                      }}
                      renderInput={({ inputProps, ...restParams }) => (
                        <StyledTextFieldWithoutBorders
                          {...restParams}
                          error={false}
                          inputProps={{
                            ...inputProps,
                            id: `alternativeAppointment${PrefixForId.FormDate}`,
                            placeholder: 'TT . MM . JJJJ'
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>
          <Distance top='30' />
          {showBlock && (
            <div className={styles.row_columns}>
              <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
                <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                  Zurück
                </button>
              </div>
              <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
                <button
                  id='moveToNextStepButton'
                  onClick={moveToNextStep}
                  className={buttonActive ? styles.button_active : styles.button_wait}
                >
                  {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
                </button>
              </div>
            </div>
          )}
          <Distance top='100' />
        </section>
      </CSSTransition>
    </div>
  );
};

export default DetailCarInfo;
