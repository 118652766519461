import React from 'react';
import { Skeleton as SkeletonMaterial } from '@mui/material';
import { SkeletonProps } from './skeleton.props';
import { useTheme } from 'styled-components';

export const SkeletonComponent = ({
  type = 'rectangular',
  children,
  color,
  className,
  ...restProps
}: SkeletonProps) => {
  const theme = useTheme();
  return (
    <SkeletonMaterial
      className={className}
      sx={{ bgcolor: theme.colors[color || 'grey50'], ...restProps }}
      variant={type}
      animation='wave'
    >
      {children}
    </SkeletonMaterial>
  );
};
