import { Text } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledNoteContent = styled(Text)<{ showWholeNote: boolean; defaultContentHeight: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 20px;
  text-overflow: ellipsis;
  ${({ showWholeNote }) => !showWholeNote && 'max-height: 60px;'};
  ${({ defaultContentHeight, showWholeNote }) =>
    !showWholeNote && defaultContentHeight > 60 && '-webkit-line-clamp: 3;'};
`;
