import React, { useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { FleetManagerDashboardContext } from 'components/dashboard-component-chart/dashboard.context';
import { useDashboardQuery } from 'hooks/react-query/dashboard';
import { PAGES } from 'common/enums';
import { CRM_VALUE as CRM_VALUE_MY_VEHICLE } from 'pages/my-vehicles/my-vehicles';
import { MY_VEHICLE_PRE_FILTER_FROM_AMOUNT_OF_ACTIVE_VEHICLE_BUTTON } from './utils';

export const AmountOfActiveVehicleButton = () => {
  const { activeCompany, openPage } = useContext(FleetManagerDashboardContext);
  const { cars } = useDashboardQuery(activeCompany.id);

  const clickHandler = openPage({
    page: PAGES.MY_VEHICLES,
    companyCrmName: CRM_VALUE_MY_VEHICLE.company,
    ...MY_VEHICLE_PRE_FILTER_FROM_AMOUNT_OF_ACTIVE_VEHICLE_BUTTON
  });

  return (
    <Flex onClick={clickHandler} align='center' justify='space-between' width='100%' padding='10px 10px 0' pointer>
      <Text color='white'>Fahrzeuge</Text>
      <Text color='white' size='24' bold>
        {cars?.data?.quantityTotalActive || '-'}
      </Text>
    </Flex>
  );
};
