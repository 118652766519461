import { EventProps } from 'components/product/product-view.props';
import { getEventsByType, takeOldestEvent } from 'utils/offer-events-functions';
import { VEHICLE_OFFER_EVENT_DESCRIPTIONS } from '../constants';
import { useMemo } from 'react';

const useOldestEvents = (events: EventProps[]) => {
  const configurationCreatedEvent = useMemo(
    () =>
      takeOldestEvent([
        ...getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationCreated),
        ...getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded)
      ]),
    [events]
  );

  const vehicleConfigurationBasedOnGRVCreatedEvent = useMemo(
    () =>
      takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleConfigurationBasedOnGRVCreated)),
    [events]
  );

  const offerPublishedForUsersEvent = useMemo(
    () => takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.offerPublishedForUsers)),
    [events]
  );

  const vehicleBasedOnBulkOrderCreatedEvent = useMemo(
    () => takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleBasedOnBulkOrderCreated)),
    [events]
  );

  const requestVehicleFromCarDealershipEvent = useMemo(
    () => takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.requestVehicleFromCarDealership)),
    [events]
  );

  const orderAcceptedByDealerEvent = useMemo(
    () => takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.orderAcceptedByDealer)),
    [events]
  );

  const orderRejectedByDealerEvent = useMemo(
    () => takeOldestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.orderRejectedByDealer)),
    [events]
  );

  return {
    configurationCreatedEvent,
    vehicleConfigurationBasedOnGRVCreatedEvent,
    offerPublishedForUsersEvent,
    vehicleBasedOnBulkOrderCreatedEvent,
    requestVehicleFromCarDealershipEvent,
    orderAcceptedByDealerEvent,
    orderRejectedByDealerEvent
  };
};

export default useOldestEvents;
