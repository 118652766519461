import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { OpenUserDoesNotHaveMainCarModalParams } from '../use-user-does-not-have-main-car.props';
import { MainCarModalSubmitButton } from '../../components/submit-button/submit-button';

export const UserDoesNotHaveMainCarModalContent = ({
  selectedCarNumber,
  driver,
  submitData
}: OpenUserDoesNotHaveMainCarModalParams) => {
  return (
    <div>
      <div>
        Das Fahrzeug <b> {selectedCarNumber} </b> wird jetzt als Hauptauto für den Fahrer <b> {driver} </b> angezeigt.
      </div>
      <Text top='30'>Bist du sicher, dass du Hauptfahrzeug definieren möchtest?</Text>

      <Flex top='20' justify='flex-end'>
        <Button component={ButtonComponentType.CloseModal}>Nein, abbrechen</Button>
        <MainCarModalSubmitButton submitData={{ ...submitData, isMainCar: true }}>
          Ja, Hauptauto definieren
        </MainCarModalSubmitButton>
      </Flex>
    </div>
  );
};
