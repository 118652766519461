import React, { useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import FormField, { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { CarDamageOptionSetProps } from './car-damage-option-set.props';
import { TrashIcon } from 'components/icons-new/trash';
import { StyledTrashIconHover } from './car-damage-option-set.styled';
import { objectUtility } from 'utils/object/object';
import { getCarDamageOptionSetFormField } from './form-field';
import { DamageItem } from '../car-damage-icon/car-damage-icon.props';
import { damage } from '../car-damage-icon/utils';

export const CarDamageOptionSet = ({ name, value }: CarDamageOptionSetProps) => {
  const { reactHookFormData } = useContext(formFieldProvider);
  const { unregister, getValues, setValue } = reactHookFormData;

  const registeredName = `damageType ${value}`;

  const removeFieldHandler = () => {
    let newDamageList: Record<string, DamageItem | DamageItem[]> = {};

    objectUtility.entries<Record<string, DamageItem | DamageItem[]>>(getValues('damageList')).map(([key, damage]) => {
      if (Array.isArray(damage)) {
        newDamageList[key] = damage.filter(item => {
          if (typeof item.index === 'number') {
            return `${item.value} ${item.index}` !== value;
          }

          return item.value !== value;
        });
        return;
      }

      if (damage.value === value) {
        return;
      }

      newDamageList[key] = damage;
    });

    setValue('damageList', newDamageList, { shouldDirty: true });
    unregister(registeredName);
  };

  const getDisableOptionsValue = () => {
    const disableOptionsValue: number[] = [];

    objectUtility.entries(getValues()).forEach(([fieldName, fieldValue]) => {
      if (
        value.toString().includes(damage.other.value.toString()) &&
        fieldName.includes(damage.other.value.toString())
      ) {
        disableOptionsValue.push(fieldValue);
      }
    });

    return disableOptionsValue;
  };

  return (
    <Flex position='relative' width='100%' align='center' justify='space-between' bottom='10'>
      <Text right='2'>{name}</Text>

      <Flex align='center'>
        <FormField
          reactHookFormData={reactHookFormData}
          field={getCarDamageOptionSetFormField(registeredName, getValues(registeredName), getDisableOptionsValue())}
        />

        <StyledTrashIconHover onClick={removeFieldHandler}>
          <TrashIcon />
        </StyledTrashIconHover>
      </Flex>
    </Flex>
  );
};
