import React from 'react';

const MsgFilelIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.33936 5.25C3.33936 4.00736 4.34672 3 5.58936 3H13.4131C13.9971 3 14.5581 3.22704 14.9778 3.63315L20.6541 9.12631C21.0921 9.55019 21.3394 10.1336 21.3394 10.7432V18.75C21.3394 19.9926 20.332 21 19.0894 21H5.58936C4.34672 21 3.33936 19.9926 3.33936 18.75V5.25ZM5.58936 4.8C5.34083 4.8 5.13936 5.00147 5.13936 5.25V18.75C5.13936 18.9985 5.34083 19.2 5.58936 19.2H19.0894C19.3379 19.2 19.5394 18.9985 19.5394 18.75V10.7432C19.5394 10.6213 19.4899 10.5046 19.4023 10.4198L13.726 4.92663C13.6421 4.84541 13.5299 4.8 13.4131 4.8H5.58936Z'
        fill='#FF9700'
      />
      <path
        d='M14.3046 14.2844C14.1356 14.4443 13.9785 14.5692 13.376 14.631H12.1335C12.0093 14.631 11.9085 14.5303 11.9085 14.406V13.9413C11.9085 13.8084 12.023 13.7039 12.1556 13.7114C12.3965 13.725 12.7649 13.735 13.0345 13.6934C13.1593 13.6742 13.2555 13.678 13.376 13.6405C13.4601 13.6142 13.5321 13.611 13.6408 13.4905C13.6916 13.4341 13.7271 13.346 13.7271 13.2197C13.7271 13.0989 13.6962 13.061 13.6408 12.9878C13.5994 12.933 13.5333 12.8945 13.4426 12.8675C13.3637 12.8441 13.319 12.8322 13.2745 12.8322C13.2745 12.8322 13.0667 12.8322 12.9403 12.8104C12.8139 12.7887 12.7651 12.7987 12.5477 12.7218C12.3282 12.6449 12.1529 12.5406 12.0217 12.4087C11.8884 12.2769 11.8086 12.1163 11.7511 11.9479C11.6917 11.7795 11.6619 11.6037 11.6619 11.4206C11.6619 11.0124 11.8045 10.6975 12.0895 10.4759C12.3725 10.2544 12.5632 10.184 13.0049 10.1491C13.4244 10.116 14.0763 10.1458 14.1405 10.1489L14.1513 10.1492C14.273 10.1518 14.3707 10.2407 14.3707 10.35V10.7422C14.3707 10.8665 14.2699 10.9672 14.1457 10.9672H13.1587C13.0345 10.9672 12.9043 11.0481 12.8202 11.1286C12.7361 11.2074 12.6941 11.3273 12.6941 11.4536C12.6941 11.5744 12.7218 11.6715 12.7772 11.7447C12.8305 11.8161 12.873 11.8413 12.9633 11.8742C13.0522 11.9049 13.1665 11.9013 13.2699 11.9012L13.279 11.9014C13.3072 11.9026 13.4592 11.9091 13.5432 11.9163C13.6359 11.9243 13.7747 11.9515 13.9139 12.0003C14.0693 12.0548 14.2476 12.1469 14.3789 12.2787C14.5122 12.4105 14.5809 12.6056 14.6322 12.8104C14.6814 13.0068 14.676 13.1434 14.676 13.3265C14.676 13.7348 14.5357 14.0657 14.3046 14.2844Z'
        fill='#FF9700'
      />
      <path
        d='M15.4528 13.6816C15.258 13.3326 15.1606 12.8948 15.1606 12.3682C15.1606 11.9015 15.2392 11.463 15.4156 11.1409C15.5899 10.8188 15.8204 10.5459 16.1568 10.3786C16.4931 10.2114 16.7549 10.1431 17.2388 10.1431C17.5534 10.1431 17.7855 10.2285 17.9095 10.2883C17.9756 10.3202 18.0116 10.389 18.0116 10.4624V11.0434C18.0116 11.1904 17.8728 11.2987 17.7289 11.2692C17.5759 11.2379 17.3882 11.2052 17.2388 11.1971C17.1009 11.1896 17.079 11.2015 16.9939 11.2223C16.8992 11.2454 16.8419 11.2903 16.7962 11.3182C16.6506 11.407 16.5948 11.5043 16.523 11.6861C16.4492 11.8657 16.4218 12.1369 16.4218 12.4116C16.4218 12.8556 16.5079 13.186 16.6802 13.4028C16.7935 13.5454 16.9827 13.6273 17.1415 13.6273H17.2069C17.3297 13.6273 17.4671 13.5282 17.4691 13.4054L17.4747 13.058C17.4762 12.9603 17.3975 12.8802 17.2997 12.8802C17.2033 12.8802 17.125 12.8022 17.1248 12.7057L17.1243 12.5328C17.124 12.4107 17.2211 12.3107 17.3431 12.3073L18.393 12.311C18.5194 12.3075 18.6239 12.4089 18.6242 12.5354L18.6246 12.683C18.6249 12.787 18.5406 12.8715 18.4366 12.8715C18.3333 12.8715 18.2494 12.9548 18.2486 13.058L18.2392 14.2244C18.2385 14.3086 18.1909 14.3852 18.1139 14.4191C17.8959 14.5147 17.5604 14.6308 17.3187 14.6308C16.8408 14.6308 16.6299 14.6418 16.2772 14.4684C15.9224 14.2928 15.6476 14.0306 15.4528 13.6816Z'
        fill='#FF9700'
      />
      <path
        d='M7.33275 12.3092C7.47426 12.4296 7.94187 14.1687 8.04284 14.3151C8.14259 14.5111 8.25953 14.631 8.38099 14.631L8.78165 14.631C8.90312 14.631 9.02006 14.5111 9.1198 14.3151C9.22078 14.1687 9.68839 12.4296 9.8299 12.3092C9.98179 12.18 9.9619 12.4099 9.9619 12.5342V14.406C9.9619 14.5303 10.0626 14.631 10.1869 14.631H10.952C11.0763 14.631 11.177 14.5303 11.177 14.406V10.3586C11.177 10.2344 11.0763 10.1336 10.952 10.1336H9.96207C9.89309 10.1336 9.82795 10.1653 9.78529 10.2194L8.94182 11.7996C8.84207 11.9956 8.7621 12.1155 8.64063 12.1155H8.52147C8.4 12.1155 8.29134 11.9956 8.19159 11.7996L7.38756 10.2389C7.38111 10.2264 7.37373 10.2143 7.36406 10.204C7.32178 10.1593 7.26273 10.1336 7.20058 10.1336H6.21066C6.0864 10.1336 5.98566 10.2344 5.98566 10.3586V14.406C5.98566 14.5303 6.0864 14.631 6.21066 14.631H6.97575C7.10001 14.631 7.20075 14.5303 7.20075 14.406V12.5342C7.20075 12.4099 7.18086 12.18 7.33275 12.3092Z'
        fill='#FF9700'
      />
    </svg>
  );
};

export default MsgFilelIcon;
