import { Duration } from 'common/enums';
import React from 'react';
import classes from './meeting-booking.module.scss';
import moment from 'moment';
import { Meetinginfo } from 'components/after-tour-pop-up/after-tour-pop-up';

interface Props {
  meetingInfo: Meetinginfo;
}

const getDurationText = (duration: string) => {
  return (
    <div>
      {duration && (
        <>
          {duration === Duration.Hour ? (
            <>
              <span style={{ fontWeight: 'bold' }}>Vorstellung Mobexo Fuhrparkmanagement</span>
              <span>{` (Dauer 1 Stunde)`}</span>
            </>
          ) : (
            <span style={{ fontWeight: 'bold' }}>Gespräch 30 Minuten</span>
          )}
        </>
      )}
    </div>
  );
};

const MeetingInfo = ({ meetingInfo }: Props) => {
  const duration = meetingInfo.duration === Duration.Hour ? 60 : 30;

  return (
    <div className={classes.meeting_info_container}>
      {getDurationText(meetingInfo.duration)}
      <span>
        am
        <span className={classes.meeting_date}>{moment(meetingInfo.startDate).format('DD.MM.yy')}</span>
      </span>
      <span>
        von
        <span className={classes.meeting_date}>
          {moment(meetingInfo.startDate).format('HH:mm')}
          {' - '}
          {moment(meetingInfo.startDate).add(duration, 'minutes').format('HH:mm')} Uhr
        </span>
      </span>
    </div>
  );
};

export default MeetingInfo;
