import React, { useContext } from 'react';
import { FormLabel } from '../common/label/label';
import { File } from './file';
import { formFieldProvider } from '../../form-field';
import { IFormFieldContext } from '../../form.props';

export const FileField = () => {
  const { field, value, error, onChange } = useContext<IFormFieldContext<File[]>>(formFieldProvider);

  return (
    <div className={field.className}>
      <FormLabel />
      <File value={value} onChange={onChange} error={error?.message?.toString()} {...field} />
    </div>
  );
};
