import React from 'react';
import { PAGES } from 'common/enums';
import { IMenuItem } from '../my-vehicles-menu.props';
import { CarPriceIcon } from 'components/icons-new/car-price';
import { FuelCardIcon } from 'components/icons-new/fuel-card';
import { NewCarIcon } from 'components/icons-new/new-car';
import { NumberedSquareIcon } from 'components/icons-new/numbered-square';
import { ShineIcon } from 'components/icons-new/shine';
import { CalculatorIcon } from 'components/icons-new/calculator';
import { useVehicleCostSheetModal } from 'hooks/modals/car/use-vehicle-cost-sheet/use-vehicle-cost-sheet';
import { useComponent } from 'hooks/use-component/use-component';

export const useRightMenuSetup = (carNumber: string) => {
  const { openVehicleCostSheetModal } = useVehicleCostSheetModal();
  const { getIsDisplayComponent, getIsPageAvailable } = useComponent();

  const getMyVehiclesMenuSetupRight = (isPoolManager: boolean, isContractAvailable: boolean): IMenuItem[] => [
    {
      name: 'Neuwagen anfragen',
      icon: <NewCarIcon width={20} height={20} />,
      isEnabled: false
    },
    {
      name: 'Fahrzeugkostenbogen',
      isEnabled: true,
      icon: <CalculatorIcon />,
      handleClick: () => openVehicleCostSheetModal(carNumber)
    },
    {
      name: 'Fahrzeug verkaufen',
      icon: <CarPriceIcon />,
      isEnabled: false
    },
    {
      name: 'Fahrzeug aufbereiten',
      icon: <ShineIcon />,
      isEnabled: false
    },
    {
      name: 'Kostenstellenwechsel',
      icon: <NumberedSquareIcon />,
      isEnabled: false
    },
    {
      name: 'Tankquittung einreichen',
      href: !isPoolManager && isContractAvailable ? PAGES.ASSIGNMENTS_FUEL_RECEIPT_SUBMIT : undefined,
      icon: <FuelCardIcon />,
      hidden:
        !getIsDisplayComponent('menuSubmitFuelReceiptButton') ||
        !getIsPageAvailable(PAGES.ASSIGNMENTS_FUEL_RECEIPT_SUBMIT),
      isEnabled: !isPoolManager && isContractAvailable
    }
  ];

  return { getMyVehiclesMenuSetupRight };
};
