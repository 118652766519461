import { UseFormReturn } from 'react-hook-form';
import { ExtendedDataType } from '../types';
import React from 'react';

export interface FormFieldProps {
  reactHookForm: UseFormReturn<FormFieldValuesType>;
  data: ExtendedDataType;
  inputValue?: string | null;
  setInputValue?: React.Dispatch<React.SetStateAction<string | null>>;
}

export type FormFieldValuesType = Record<IncomingInvoicesFormName, string>;

export enum IncomingInvoicesFormName {
  CreditorLookup = 'uds_serviceproviderid',
  CreditorTextInput = 'uds_creditor_name',
  InquiryNumber = 'uds_incidentid'
}
