import React from 'react';
import { TableComponentsProps } from './table-components.props';
import { silverDatVehicleTypes } from '../../../constants';
import { spinnerStyle } from 'components/table-component/cell/cell';
import Spinner from 'components/spinner';
import classNames from 'classnames';
import classes from 'components/table-component/cell/cell.module.scss';
import { VehicleStateCodeValue } from '../my-vehicles.props';

const getMappingPlaceholder = (prefix: '' | '_small' = '') => ({
  [silverDatVehicleTypes.personalCar.id]: `/assets/images/vehicle-placeholders/PKW${prefix}.svg`,
  [silverDatVehicleTypes.truck.id]: `/assets/images/vehicle-placeholders/LKW${prefix}.svg`,
  [silverDatVehicleTypes.trailer.id]: `/assets/images/vehicle-placeholders/Anhanger${prefix}.svg`,
  [silverDatVehicleTypes.bicycle.id]: `/assets/images/vehicle-placeholders/Fahrrad${prefix}.svg`,
  [silverDatVehicleTypes.tool.id]: `/assets/images/vehicle-placeholders/Werkzeug${prefix}.svg`,
  [silverDatVehicleTypes.transporter.id]: `/assets/images/vehicle-placeholders/Transporter${prefix}.svg`,
  [silverDatVehicleTypes.forklift.id]: `/assets/images/vehicle-placeholders/Gabelstapler${prefix}.svg`
});

const CarImage = ({ row, column }: TableComponentsProps) => {
  const getPlaceholder = () => {
    const mappingPlaceholder = getMappingPlaceholder(
      row?.statecode_value === VehicleStateCodeValue.Active ? '_small' : ''
    );

    if (row?.uds_silverdatvehicletypeoption && mappingPlaceholder[row.uds_silverdatvehicletypeoption_value]) {
      return mappingPlaceholder[row.uds_silverdatvehicletypeoption_value];
    }

    return mappingPlaceholder[silverDatVehicleTypes.personalCar.id];
  };

  return (
    <span style={{ width: column?.width }} className={classNames(classes.table_img, column?.className, classes.cell)}>
      {row?.imgSrc === undefined ? (
        <Spinner style={spinnerStyle} />
      ) : (
        <div style={{ backgroundImage: `url(${row?.imgSrc || getPlaceholder()})` }} />
      )}
    </span>
  );
};

export default CarImage;
