import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const TimingBeltIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.2261 12.3226C16.2726 12.3226 18.7422 9.85293 18.7422 6.80646C18.7422 3.75998 16.2726 1.29033 13.2261 1.29033C10.1796 1.29033 7.70996 3.75998 7.70996 6.80646C7.70996 9.85293 10.1796 12.3226 13.2261 12.3226Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M13.2263 10.5806C15.3108 10.5806 17.0005 8.89086 17.0005 6.80644C17.0005 4.72201 15.3108 3.03224 13.2263 3.03224C11.1419 3.03224 9.45215 4.72201 9.45215 6.80644C9.45215 8.89086 11.1419 10.5806 13.2263 10.5806Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />

      <path
        d='M10.9039 6.2258C10.5845 6.2258 10.3232 6.48709 10.3232 6.80644C10.3232 7.1258 10.5845 7.38709 10.9039 7.38709C11.2232 7.38709 11.4845 7.1258 11.4845 6.80644C11.4845 6.48709 11.2232 6.2258 10.9039 6.2258Z'
        fill={color}
      />
      <path
        d='M13.2264 3.90324C12.907 3.90324 12.6458 4.16453 12.6458 4.48389C12.6458 4.80324 12.907 5.06453 13.2264 5.06453C13.5458 5.06453 13.807 4.80324 13.807 4.48389C13.807 4.16453 13.5458 3.90324 13.2264 3.90324Z'
        fill={color}
      />
      <path
        d='M13.2264 8.5484C12.907 8.5484 12.6458 8.80969 12.6458 9.12905C12.6458 9.4484 12.907 9.70969 13.2264 9.70969C13.5458 9.70969 13.807 9.4484 13.807 9.12905C13.807 8.80969 13.5458 8.5484 13.2264 8.5484Z'
        fill={color}
      />
      <path
        d='M15.5489 6.2258C15.2296 6.2258 14.9683 6.48709 14.9683 6.80644C14.9683 7.1258 15.2296 7.38709 15.5489 7.38709C15.8683 7.38709 16.1296 7.1258 16.1296 6.80644C16.1296 6.48709 15.8683 6.2258 15.5489 6.2258Z'
        fill={color}
      />

      <path
        d='M13.2264 7.67742C13.7075 7.67742 14.0974 7.28748 14.0974 6.80645C14.0974 6.32543 13.7075 5.93549 13.2264 5.93549C12.7454 5.93549 12.3555 6.32543 12.3555 6.80645C12.3555 7.28748 12.7454 7.67742 13.2264 7.67742Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M3.64566 18.7097C5.24906 18.7097 6.54888 17.4099 6.54888 15.8065C6.54888 14.2031 5.24906 12.9032 3.64566 12.9032C2.04225 12.9032 0.742432 14.2031 0.742432 15.8065C0.742432 17.4099 2.04225 18.7097 3.64566 18.7097Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />

      <path
        d='M3.64587 16.6774C4.12689 16.6774 4.51684 16.2875 4.51684 15.8065C4.51684 15.3254 4.12689 14.9355 3.64587 14.9355C3.16485 14.9355 2.7749 15.3254 2.7749 15.8065C2.7749 16.2875 3.16485 16.6774 3.64587 16.6774Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />

      <path
        d='M13.2264 18.7097C14.5091 18.7097 15.549 17.6699 15.549 16.3871C15.549 15.1044 14.5091 14.0645 13.2264 14.0645C11.9437 14.0645 10.9038 15.1044 10.9038 16.3871C10.9038 17.6699 11.9437 18.7097 13.2264 18.7097Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />

      <path
        d='M13.2264 15.8065C12.907 15.8065 12.6458 16.0678 12.6458 16.3871C12.6458 16.7065 12.907 16.9678 13.2264 16.9678C13.5458 16.9678 13.807 16.7065 13.807 16.3871C13.807 16.0678 13.5458 15.8065 13.2264 15.8065Z'
        fill={hovered ? fill : 'none'}
      />
      <path d='M8.59582 3.80162L1.32324 14.0645' stroke={color} strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M3.64575 18.7097H13.2264' stroke={color} strokeMiterlimit='10' strokeLinecap='round' />
      <path d='M18.2561 9.07822L15.4473 17.0766' stroke={color} strokeMiterlimit='10' strokeLinecap='round' />
    </svg>
  );
};
