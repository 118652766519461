import { useQuery } from 'react-query';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { CrmResponse, ResponseModel, User } from 'common/interfaces';
import { getEntityById } from 'services/crm-entity-service';
import { RequestStatuses } from '../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { AxiosResponse } from 'axios';
import { useIsParentCompany } from 'hooks/react-query/use-is-parent-company';

interface ReferenceVehicleTypesResponse {
  uds_global_reference_vehicles_available: CrmResponse<boolean>;
  uds_isreference_vehicles_available: CrmResponse<boolean>;
  uds_isbulk_orders_available: CrmResponse<boolean>;
  uds_ispdfconfiguration_available: CrmResponse<boolean>;
  uds_isconfigurator_2_0_available: CrmResponse<boolean>;
}

type ReferenceVehicleTypes = Record<keyof ReferenceVehicleTypesResponse, boolean | null>;

export const useReferenceVehicleTypes = (isEnabled: boolean) => {
  const user = useTypedSelector<User>(state => state.app.user);
  const { isParentCompany, parentCompanyId } = useIsParentCompany(user.companyId);
  const companyId = isParentCompany !== undefined ? (isParentCompany ? user.companyId : parentCompanyId) : undefined;

  const modifyResponse = ({ data }: AxiosResponse<ResponseModel<ReferenceVehicleTypesResponse>>) => {
    if (data.level === RequestStatuses.Success) {
      const response = getResponseFormData(data, [{ name: 'attributes', type: 'object' }]) as ReferenceVehicleTypes;
      return {
        isGRVAvailable: !!response.uds_global_reference_vehicles_available,
        isReferenceVehiclesAvailable: !!response.uds_isreference_vehicles_available,
        isBulkOrdersAvailable: !!response.uds_isbulk_orders_available,
        isPdfConfigurationAvailable: !!response.uds_ispdfconfiguration_available,
        isCarConfiguratorAvailable: !!response.uds_isconfigurator_2_0_available
      };
    }

    return defaultReferenceVehicleTypes;
  };

  const { data: referenceVehicleTypes } = useQuery(
    ['show-change-view-button', companyId],
    () =>
      getEntityById({
        entityId: companyId,
        entityName: 'account',
        columns: [
          'uds_global_reference_vehicles_available',
          'uds_isreference_vehicles_available',
          'uds_isbulk_orders_available',
          'uds_ispdfconfiguration_available',
          'uds_isconfigurator_2_0_available'
        ]
      }),
    {
      select: modifyResponse,
      cacheTime: 30 * 60 * 1000,
      enabled: isEnabled && !!companyId
    }
  );

  return referenceVehicleTypes || defaultReferenceVehicleTypes;
};

const defaultReferenceVehicleTypes = {
  isGRVAvailable: false,
  isReferenceVehiclesAvailable: false,
  isBulkOrdersAvailable: false,
  isPdfConfigurationAvailable: false,
  isCarConfiguratorAvailable: false
};
