import React from 'react';
import { BigFilterProps } from './big-filter.props';
import { PrefixForId } from 'common/enums';
import { BigFilterStyledContainer, StyledFilterIconContainer, StyledSubtitle, StyledTitle } from './big-filter.styled';
import IconHover from 'components/icon-hover';

export const BigFilter = ({ img, title, subtitle, className, onClick, isActive, id }: BigFilterProps) => {
  return (
    <BigFilterStyledContainer id={id} className={className} isActive={isActive} onClick={onClick}>
      <StyledFilterIconContainer>
        <IconHover>{img}</IconHover>
      </StyledFilterIconContainer>
      <div>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle id={id + PrefixForId.SubTitle}>{subtitle}</StyledSubtitle>
      </div>
    </BigFilterStyledContainer>
  );
};
