import React from 'react';
import { Flex, Button, Text, Distance } from 'common/common-components.styled';
import DocumentsBlock from 'components/assignments-record/documents-block/documents-block';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { isEmpty } from 'lodash';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { extendWithDateFormFields } from './extend-with-date-form-fields';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { ExtendWithDateProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';

const ExtendWithDate = ({ files, onCancel, onSubmit, plateNumber }: ExtendWithDateProps) => {
  const reactHookFormData = useForm();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const submit = () => {
    handleSubmit(data => {
      onSubmit({
        nextPhase: LeaseExtentionPhases.Phase16,
        successMessage: SUCCESS_MESSAGE,
        months: data[LeasingContractFields.AddedMonths],
        silentExtend: false
      });
    })();
  };

  return (
    <Flex direction='column'>
      <Text id={'areYouSureContent'} bottom='10'>
        Bist du sicher, dass du den Leasingvertrag für <strong>{plateNumber.attributeValue?.name || ''}</strong>{' '}
        verlängern möchtest?{' '}
      </Text>
      <DocumentsBlock files={files} noMargin isDownloadIcon isWithoutFileViewer />
      <Distance top='10' />
      <FormHook reactHookFormData={reactHookFormData} formFields={extendWithDateFormFields} />
      <Flex top='10' justify='flex-end'>
        <Button secondary onClick={onCancel} right='10'>
          Abbrechen
        </Button>
        <Button id={'confirmButton'} onClick={submit} disabled={!isEmpty(errors)}>
          Bestätigen
        </Button>
      </Flex>
    </Flex>
  );
};

const SUCCESS_MESSAGE = 'Deine Entscheidung wurde erfolgreich gespeichert';

export default ExtendWithDate;
