import React from 'react';
import PropTypes from 'prop-types';

export const CarWithEuroIcon = ({ color = '#fff', width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M18 11.2922L17.5 10.0922C17.4 9.99219 17.3 9.79219 17.1 9.79219L12.9 8.89219L10.6 7.79219C10.2 7.59219 9.6 7.49219 9.4 7.49219H6.5C6 7.49219 5.3 7.89219 5 7.99219C4.7 8.19219 4 8.79219 3.7 8.99219L2.6 9.29219C2.4 9.39219 2.2 9.59219 2.2 9.79219V10.4922H2.4C2.8 10.4922 3 10.6922 3 10.9922C3 11.2922 2.8 11.4922 2.5 11.4922H2.2L2 12.5922C2 12.8922 2.1 13.0922 2.4 13.1922L4 13.4922C4 12.3922 4.9 11.4922 6 11.4922C7.1 11.4922 8 12.3922 8 13.4922H12C12 12.3922 12.9 11.4922 14 11.4922C15.1 11.4922 16 12.3922 16 13.4922H17.5C17.8 13.4922 18 13.2922 18 12.9922V11.5922C18 11.4922 18 11.3922 18 11.2922Z' fill={color} />
      <path d='M6 14.4922C6.55228 14.4922 7 14.0445 7 13.4922C7 12.9399 6.55228 12.4922 6 12.4922C5.44772 12.4922 5 12.9399 5 13.4922C5 14.0445 5.44772 14.4922 6 14.4922Z' fill={color} />
      <path d='M14 14.4922C14.5523 14.4922 15 14.0445 15 13.4922C15 12.9399 14.5523 12.4922 14 12.4922C13.4477 12.4922 13 12.9399 13 13.4922C13 14.0445 13.4477 14.4922 14 14.4922Z' fill={color} />
      <path fillRule='evenodd' clipRule='evenodd' d='M12.5137 5.88138C12.5137 4.53191 13.6501 3.39551 14.9995 3.39551C16.349 3.39551 17.4854 4.53191 17.4854 5.88138C17.4854 7.23086 16.349 8.36726 14.9995 8.36726C13.6501 8.36726 12.5137 7.23086 12.5137 5.88138ZM15.089 4.91124C15.2328 4.9051 15.3762 4.93101 15.5087 4.9871C15.5977 5.02476 15.7004 4.98313 15.7381 4.89412C15.7758 4.80511 15.7341 4.70242 15.6451 4.66476C15.4648 4.58846 15.2697 4.5532 15.0741 4.56155C14.8784 4.5699 14.6871 4.62164 14.5139 4.71304C14.3407 4.80443 14.19 4.93318 14.0726 5.08998C13.9694 5.22791 13.8943 5.3845 13.8514 5.55084H13.7161C13.6195 5.55084 13.5411 5.62919 13.5411 5.72584C13.5411 5.82249 13.6195 5.90084 13.7161 5.90084H13.8095C13.8106 5.97489 13.8179 6.04884 13.8314 6.12189H13.5999C13.5033 6.12189 13.4249 6.20024 13.4249 6.29689C13.4249 6.39354 13.5033 6.47189 13.5999 6.47189H13.9487C13.9924 6.55953 14.046 6.64229 14.1085 6.71854C14.2325 6.87003 14.3888 6.99204 14.5658 7.07572C14.7427 7.15936 14.936 7.20263 15.1317 7.20243L15.1314 7.20243L15.1319 7.02743L15.1321 7.20243L15.1317 7.20243C15.2774 7.20284 15.422 7.17827 15.5594 7.12979C15.8174 7.04218 16.0418 6.87631 16.2013 6.65525C16.2578 6.57686 16.2401 6.46748 16.1617 6.41094C16.0834 6.3544 15.974 6.37211 15.9174 6.45049C15.8004 6.61279 15.6356 6.7345 15.446 6.79865L15.446 6.79864L15.4437 6.79946C15.3437 6.83484 15.2385 6.85276 15.1324 6.85243L15.1317 6.85243C14.9877 6.85262 14.8455 6.82081 14.7154 6.75929C14.5853 6.69778 14.4704 6.6081 14.3792 6.49675C14.3725 6.48856 14.3659 6.48027 14.3595 6.47189H15.189C15.2857 6.47189 15.364 6.39354 15.364 6.29689C15.364 6.20024 15.2857 6.12189 15.189 6.12189H14.1896L14.188 6.11545C14.1705 6.04498 14.161 5.973 14.1595 5.90084H15.3052C15.4019 5.90084 15.4802 5.82249 15.4802 5.72584C15.4802 5.62919 15.4019 5.55084 15.3052 5.55084H14.2173C14.2498 5.46114 14.2954 5.37646 14.3529 5.29966C14.4391 5.1844 14.5499 5.08976 14.6772 5.02258C14.8045 4.95541 14.9452 4.91737 15.089 4.91124Z' fill={color} />
    </svg>
  );
};

CarWithEuroIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
