import React from 'react';
import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CustomPickerDayTooltip from 'components/cost-centers-settings/custom-picker-day-tooltip';
import { HistoryModalFieldNames } from '../history-modal.types';

export const changeVehicleHistoryFormFields: FormFieldModel<HistoryModalFieldNames>[] = [
  {
    name: HistoryModalFieldNames.ValidFrom,
    type: FormFieldTypes.Date,
    defaultValue: null,
    label: 'Fahrer zugeordnet ab:',
    renderDay: (day: unknown, _value: unknown[], DayComponentProps: PickersDayProps<unknown>) => (
      <CustomPickerDayTooltip DayComponentProps={DayComponentProps} tooltipText={TOOLTIP_TEXT} />
    ),
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: HistoryModalFieldNames.ValidTo,
    type: FormFieldTypes.Date,
    defaultValue: null,
    label: 'Fahrer zugeordnet bis (Optional):',
    renderDay: (day: unknown, _value: unknown[], DayComponentProps: PickersDayProps<unknown>) => (
      <CustomPickerDayTooltip DayComponentProps={DayComponentProps} tooltipText={TOOLTIP_TEXT} />
    ),
    validation: {}
  }
];

const TOOLTIP_TEXT = 'Fahrzeug Historien dürfen sich nicht überschneiden. Bitte wähle einen anderen Wert';
