import { useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { useQuery } from 'react-query';
import { SessionFilter } from '../use-session-filter';
import { getUserCompanyFuhrparks } from 'services/fuhrpark-service';
import { defaultTableData } from 'pages/my-vehicles/my-vehicles';
import { TableData } from 'pages/my-vehicles/my-vehicles.props';
import sharepointService from 'services/sharepoint-service';
import { useQueriesTyped } from 'hooks/use-queries-typed';
import { ResponseModel } from 'common/interfaces';
import { getDataForTable } from 'utils/get-response-data';

export const useMyVehicles = (tableParams: TableParams, sessionFilter: SessionFilter) => {
  const cancelTokenSource = Axios.CancelToken.source();
  const tableDataQueryKey = [PAGES.MY_VEHICLES, tableParams, sessionFilter?.filterForServer];

  const {
    data: tableDataQuery,
    isFetching: tableIsFetching,
    refetch
  } = useQuery<AxiosResponse<ResponseModel<TableData>>, unknown, TableData>(
    tableDataQueryKey,
    () => getUserCompanyFuhrparks(tableParams.pagination, tableParams.sort, sessionFilter.filterForServer),
    {
      select: ({ data }) => ({ ...data.data, fuhrparks: getDataForTable(data.data.fuhrparks) }),
      enabled: !isEmpty(sessionFilter?.filterForServer)
    }
  );

  const logoListQuery = useQueriesTyped(
    tableDataQuery?.fuhrparks.map((tableItem, queryIndex) => {
      return {
        queryKey: [PAGES.MY_VEHICLES, ReactQueryKeys.Logo, tableItem.new_fuhrparkid],
        queryFn: () =>
          sharepointService.getSharepointLogo(tableItem.new_fuhrparkid, 'Foto(s) Fahrzeug', cancelTokenSource.token),
        onSuccess: ({ data }: any) => (tableDataQuery.fuhrparks[queryIndex].imgSrc = data),
        onError: () => (tableDataQuery.fuhrparks[queryIndex].imgSrc = '')
      };
    }) || []
  );

  const tableDataWithCarLogo = tableDataQuery?.fuhrparks?.map((tableItem, tableIndex) => {
    if (logoListQuery[tableIndex].isError) {
      tableItem.imgSrc = '';
    } else {
      tableItem.imgSrc = logoListQuery[tableIndex].data?.data;
    }

    return tableItem;
  });

  const isFetching = tableIsFetching;

  return {
    table: {
      ...(tableDataQuery || defaultTableData),
      fuhrparks: tableDataWithCarLogo || []
    },
    logoList: logoListQuery,
    isFetching,
    refetch
  };
};

interface TableParams {
  pagination: { start: number; number: number };
  sort: { predicate?: string; reverse?: boolean };
}
