import { Children, cloneElement } from 'react';

export default function Modal({type, children, parameters}) {
  const modals = Children.toArray(children).reduce((map, child) => {

    const type = child.props.type;
    if(!type) throw new Error('Each modal should have a type property.');

    map.set(type,child);
    return map;
  }, new Map());
  const el = modals.get(type);
  return el?  cloneElement(el,{parameters}) : null;
}
