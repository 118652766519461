import { ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { productRequestConfig } from 'request-config/product/product.request-config';
import { ProductResponseProp } from './get-user-product.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useGetUserProductsQuery = ({
  options
}: QueryHookOptions<AxiosResponse<ResponseModel<ProductResponseProp>>, ResponseModel<ProductResponseProp>>) => {
  const { fetch } = useFetch<ResponseModel<ProductResponseProp>>({
    loadingKey: 'getUserProductQuery',
    ...productRequestConfig.getUserProduct
  });

  const { data, ...restQueryProps } = useQuery(ReactQueryKeys.GetUserProduct, () => fetch(), options);

  return { userProducts: data?.data?.data?.products || [], ...restQueryProps };
};
