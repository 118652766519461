import { useTypedSelector } from 'hooks/use-typed-selector';
import { useEffect, useState } from 'react';

export const usePhaseSwitch = (
  openDoNotExtendModal: () => void,
  isFifthPhase?: boolean,
  updateToFifthPhase?: () => void
) => {
  const [checked, setChecked] = useState(false);
  const modals = useTypedSelector(state => state.app.modals);

  const handleChange = (checked: boolean) => {
    if (checked) {
      setChecked(true);
      openDoNotExtendModal();
    } else {
      setChecked(false);
      updateToFifthPhase && updateToFifthPhase();
    }
  };

  useEffect(() => {
    if (!modals?.alert?.type) {
      setChecked(Boolean(isFifthPhase));
    }
  }, [modals, isFifthPhase]);

  return { checked, handleChange };
};
