import React, { useMemo } from 'react';
import { Text } from 'common/common-components.styled';
import { Booking } from 'components/pool-car-booking/edit-booking/edit-booking.types';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import useCarData from './react-query/use-car-data/use-car-data';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { EditBookingFieldNames } from 'components/pool-car-booking/form-fields/edit-booking.form-fields';
import { getPoolCarStatusColor } from 'components/pool-car-booking/booking-details-tooltip';

const useBookingInfoItems = (booking: Booking, reactHookFormData: UseFormReturn) => {
  const isFleetManagerOrAdminOrPoolManager = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);
  const { getValues } = reactHookFormData;
  const licensePlateValue: string | null = getValues(EditBookingFieldNames.LicensePlate);
  const { carData } = useCarData(licensePlateValue);

  return useMemo(
    () => [
      ...(!isFleetManagerOrAdminOrPoolManager
        ? [
            {
              key: 'Kennzeichen',
              label: <Text color='grey600'>Kennzeichen</Text>,
              value: (
                <Text color='grey900' size='16'>
                  {booking.new_name}
                </Text>
              )
            }
          ]
        : []),
      {
        key: 'Fahrzeug',
        label: <Text color='grey600'>Fahrzeug</Text>,
        value: (
          <Text wordBreak='break-word' color='grey900' size='16'>
            {carData?.new_hersteller || carData?.new_model
              ? `${carData.new_hersteller || ''} ${carData.new_model || ''}`
              : '-'}
          </Text>
        )
      },
      {
        key: 'Standort',
        label: <Text color='grey600'>Standort</Text>,
        value: (
          <Text wordBreak='break-word' color='grey900' size='16'>
            {carData?.new_standortid?.name || '-'}
          </Text>
        )
      },
      {
        key: 'Mitarbeiter',
        label: <Text color='grey600'>Mitarbeiter</Text>,
        value: (
          <Text wordBreak='break-word' color='grey900' size='16'>
            {booking.uds_requester}
          </Text>
        )
      },
      {
        key: 'Status',
        label: <Text color='grey600'>Status</Text>,
        value: (
          <Text color={getPoolCarStatusColor(booking.uds_status_code)} size='16'>
            {booking.uds_status}
          </Text>
        )
      },
      {
        key: 'Kommentar',
        label: <Text color='grey600'>Kommentar</Text>,
        value: (
          <Text color='grey900' size='16'>
            {booking.uds_comment || '-'}
          </Text>
        )
      }
    ],
    [booking, carData]
  );
};

export default useBookingInfoItems;
