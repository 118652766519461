import FormHook from 'components/form-hook/form-hook';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CreateNoteFormData, CreateNoteProps } from './create-note.props';
import { useCreateNoteFormFields } from './hooks/use-create-note.form-field';
import { CreateNoteSubmitButton } from './components/buttons/submit/submit';
import { Flex } from 'common/common-components.styled';
import { NoteItemContainer } from '../note-item-container/note-item-container';
import IconHover from 'components/icon-hover';
import { TrashIcon } from 'components/icons-new/trash';

export const CreateNote = ({ data, cancelEdit }: CreateNoteProps) => {
  const reactHookFormData = useForm<CreateNoteFormData>();

  const { formFields } = useCreateNoteFormFields(data);

  return (
    <NoteItemContainer>
      <FormHook reactHookFormData={reactHookFormData} formFields={formFields} />

      <Flex align='center' justify='space-between'>
        <IconHover onClick={cancelEdit}>
          <TrashIcon />
        </IconHover>

        <CreateNoteSubmitButton data={data} cancelEdit={cancelEdit} reactHookFormData={reactHookFormData} />
      </Flex>
    </NoteItemContainer>
  );
};
