import React, { useEffect } from 'react';
import { EditCostCenterFieldNames, EditCostCenterProps } from './edit-cost-center.types';
import useAlert from 'hooks/use-alert';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { Button, Flex } from 'common/common-components.styled';
import useEditCostCenterFormFields from './hooks/use-edit-cost-center-form-fields';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { costCentersRequestConfig } from 'request-config/cost-centers/cost-centers.request-config';

const EditCostCenter = ({ costCenterItem, refetchCostCenters }: EditCostCenterProps) => {
  const { closeAlert } = useAlert();
  const { editCostCenterFormFields } = useEditCostCenterFormFields();
  const reactHookFormData = useForm();
  const {
    formState: { isValid },
    setValue,
    handleSubmit
  } = reactHookFormData;
  const { fetch } = useFetch({ loadingKey: 'editCostCenter', isShowSuccessSnackbar: true });

  const submit = () => {
    handleSubmit(data => {
      closeAlert();
      const dataToSend = {
        kostenstelle: {
          id: costCenterItem.new_kostenstelleid,
          attributes: {
            new_name: data.new_name
          }
        }
      };
      fetch({
        requestConfig: costCentersRequestConfig.updateCostCenter(dataToSend),
        callback: refetchCostCenters
      });
    })();
  };

  useEffect(() => {
    setValue(EditCostCenterFieldNames.Name, costCenterItem.new_name || '', defaultOptions);
    setValue(EditCostCenterFieldNames.Company, costCenterItem.new_firmaid_id || '', defaultOptions);
  }, [costCenterItem]);

  return (
    <div>
      <FormHook reactHookFormData={reactHookFormData} formFields={editCostCenterFormFields} />

      <Flex justify='flex-end' top='20'>
        <Button secondary onClick={closeAlert} right='10'>
          Abbrechen
        </Button>
        <Button onClick={submit} disabled={!isValid}>
          Speichern
        </Button>
      </Flex>
    </div>
  );
};

export default EditCostCenter;
