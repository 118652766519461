export interface LeasingSectionReducerState {
  activeItems: LeasingSectionItem[];
  inactiveItems: LeasingSectionItem[];
  expanded: boolean;
}

export interface LeasingSectionItem {
  new_vertragstyp: string;
  new_vertragstyp_value: ContractTypeValue;
  itemExpand: boolean;
  mode?: 'create';
}

export enum ContractTypeValue {
  FinancialRate = 100000000,
  Insurance = 100000001,
  WinterWheels = 100000002,
  TireReplacement = 100000004,
  AccidentPreventionRegulation = 100000010
}
