import React from 'react';
import { ClearAllFiltersButtonProps } from './clear-all-filters-button.props';
import ExpandedClearAllFiltersButton from './components/expanded-button/expanded-button';
import { CompressedClearAllFiltersButton } from './components/compressed-button/compressed-button';
import { isFilterVisible } from './utils';

const ClearAllFiltersButton = (props: ClearAllFiltersButtonProps) => {
  const { isExpanded = true, filterState } = props;

  if (!isFilterVisible(filterState)) {
    return null;
  }

  if (!isExpanded) {
    return <CompressedClearAllFiltersButton {...props} />;
  }

  return <ExpandedClearAllFiltersButton {...props} />;
};

export default ClearAllFiltersButton;
