export enum FormSourceEnum {
  GetCompanyDrivers = 'getCompanyDrivers',
  GetContractPartnerCompanies = 'getContractPartnerCompanies',
  GetCarBrands = 'getCarBrands',
  GetCarBrandsForGrv = 'getCarBrandsForGrv',
  GetCompanyContracts = 'getCompanyContracts',
  GetUnusedUserCompanyFuhrparks = 'getUnusedUserCompanyFuhrparks',
  GetCarDealerships = 'getCarDealerships',
  GetUserCompanyCarPolicies = 'getUserCompanyCarPolicies',
  GetCarsListVR = 'getCarsListVR',
  GetServices = 'getDienstleistungen',
  GetUserCompanyCarDealerships = 'getUserCompanyCarDealerships',
  GetDamagePageDocumentTypes = 'getDamagePageDocumentTypes',
  GetDamageRecordMark = 'getDamageRecordMark',
  GetDamageRecordRepairPartner = 'getDamageRecordRepairPartner',
  GetDamageRecordWorkshop = 'getDamageRecordWorkshop',
  GetDamageRecordOwner = 'getDamageRecordOwner',
  GetDamageRecordInsurer = 'getDamageRecordInsurer',
  GetDamageRecordInsuranceBroker = 'getDamageRecordInsuranceBroker',
  GetDocumentTypes = 'getDocumentTypes',
  GetPersonsForLocations = 'getPersonsForStandorts',
  GetFuelReportsFilterName = 'getFuelReportsFilterName',
  GetFuelReportsFilterDriver = 'GetFuelReportsFilterDriver',
  GetFuelReportsFilterLocation = 'getFuelReportsFilterLocation',
  GetFuelReportsFilterBranch = 'getFuelReportsFilterBranch',
  GetFuelReportsFilterManufacturer = 'GetFuelReportsFilterManufacturer',
  GetFuelReportsFilterModel = 'getFuelReportsFilterModel',
  GetFuelReportsFilterCostCenterId = 'GetFuelReportsFilterCostCenterId',
  GetFuelReportsFilterCostCenter2 = 'getFuelReportsFilterCostCenter2',
  GetFuelReportsFilterCostCenter3 = 'getFuelReportsFilterCostCenter3',
  GetDriversForBooking = 'getDriversForBooking'
}
