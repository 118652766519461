import { useGetFleetNotesQuery } from 'hooks/react-query/fleet/get-notes/get-notes.query';
import React, { useState } from 'react';
import { Flex } from 'common/common-components.styled';
import { Grid } from '@mui/material';
import { VehicleDetailsNotesEmptyPlaceholder } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/notes/components/placeholder/empty';
import { CreateNote } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/notes/components/create-note/create-note';
import { NoteItem } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/notes/components/note-item/note-item';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import Spinner from 'components/spinner';
import { NotesEntityNames, UseNotesParams } from './use-notes.types';
import { NotesContext } from './notes.context';
import useWindowSize from 'hooks/use-window-size';

const useNotes = ({ entityName, id }: UseNotesParams) => {
  const { getFleetNotesQuery, isFetching } = useGetFleetNotesQuery({ entityName, id });

  const { windowWidth } = useWindowSize(500);
  const getColumns = () => {
    if (windowWidth <= 550) {
      return 1;
    }
    return entityName === NotesEntityNames.Contact ? 2 : 3;
  };

  const defaultSliceNumber = entityName === NotesEntityNames.Contact ? 2 : 3;
  const openMoreSliceNumber = defaultSliceNumber * 2;

  const [isDisplayCreateNewNotePlaceholder, setIsDisplayCreateNewNotePlaceholder] = useState(false);
  const [sliceNumber, setSliceNumber] = useState(defaultSliceNumber);

  const toggleIsDisplayCreateNewNote = (isDisplay: boolean) => {
    setIsDisplayCreateNewNotePlaceholder(isDisplay);
    setSliceNumber(prevState => (isDisplay ? prevState - 1 : prevState + 1));
  };

  const isExpandedMoreButton = sliceNumber >= getFleetNotesQuery.length;
  const isDisplayShowMoreButton =
    getFleetNotesQuery.length > (isDisplayCreateNewNotePlaceholder ? defaultSliceNumber - 1 : defaultSliceNumber);

  const toggleMoreButtonClick = () => {
    if (isExpandedMoreButton) {
      return setSliceNumber(isDisplayCreateNewNotePlaceholder ? defaultSliceNumber - 1 : defaultSliceNumber);
    }
    setSliceNumber(prev => prev + openMoreSliceNumber);
  };

  const toggleCreateNewNote = () => !isDisplayCreateNewNotePlaceholder && toggleIsDisplayCreateNewNote(true);

  const getContent = () => {
    const isDisplayEmptyPlaceholder = !getFleetNotesQuery.length && !isDisplayCreateNewNotePlaceholder;

    if (isFetching) {
      return (
        <Flex width='100%' height='300px' align='center' justify='center'>
          <Spinner />
        </Flex>
      );
    }

    return (
      <NotesContext.Provider value={{ entityName, id }}>
        <Grid container spacing={3.5} columns={getColumns()} alignItems='stretch' marginBottom='30px'>
          {isDisplayEmptyPlaceholder && (
            <VehicleDetailsNotesEmptyPlaceholder onClick={() => toggleIsDisplayCreateNewNote(true)} />
          )}
          {isDisplayCreateNewNotePlaceholder && (
            <CreateNote data={null} cancelEdit={() => toggleIsDisplayCreateNewNote(false)} />
          )}
          {getFleetNotesQuery.slice(0, sliceNumber).map(item => (
            <NoteItem key={item.uds_notizenid} {...item} />
          ))}
        </Grid>

        {isDisplayShowMoreButton && (
          <ShowMoreButton isExpanded={isExpandedMoreButton} onClick={toggleMoreButtonClick} />
        )}
      </NotesContext.Provider>
    );
  };

  return { getContent, toggleCreateNewNote };
};

export default useNotes;
