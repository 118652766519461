import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { Text } from 'common/common-components.styled';

export const useOverdueTaskWillBeCreatedModal = () => {
  const dispatch = useDispatch();

  const openOverdueTaskWillBeCreatedModal = (closeCallback: Function, submitCallback: Function) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Überfällige Aufgabe',
        children: (
          <div>
            <Text bottom='20'>
              Bei Hinzufügen eines Prüftermins mit dem ausgewählten Erstzulassungsdatum wird eine überfällige Aufgabe
              erstellt, da das Erstzulassungsdatum nach dem zuvor gewählten Prüfintervall liegt.
            </Text>
            <Text>Bist du sicher, dass du das Erstzulassungsdatum als Letzter Prüftermin festlegen willst?</Text>
          </div>
        ),
        buttons: [
          { type: 'cancel', action: closeCallback, title: 'Nein, anderes Datum auswählen' },
          { type: 'submit', action: submitCallback, title: 'Ja, festlegen' }
        ]
      })
    );
  };

  return { openOverdueTaskWillBeCreatedModal };
};
