import { SessionFilter, SetSessionFilter } from 'hooks/use-session-filter';

export enum BIG_FILTER_TITLES {
  LEASING_OFFER_REQUEST = 'Konfiguration',
  LEASING_VEHICLE_REQUEST = 'Freigabe',
  RELEASE_ORDER = 'Bestellung'
}

export enum FILTER_COLOR {
  ACTIVE = '#335566',
  DEFAULT = '#ACD2E5'
}

export interface BigFilterIconProps {
  title: BIG_FILTER_TITLES;
  color: FILTER_COLOR;
  isActive: boolean;
}

export interface EmployeeConfigurationsBigFiltersProps {
  setSessionFilter: SetSessionFilter;
  sessionFilter: SessionFilter;
  goToFirstPage: () => any;
}
