import React from 'react';
import { Circle, Flex, Text } from 'common/common-components.styled';
import { SegmentItemProps } from './segment-item.props';

export const SegmentItem = ({ label, color }: SegmentItemProps) => {
  return (
    <Flex align='center'>
      <Circle size='6px' color={color} />
      <Text color='grey500' size='12' left='8'>
        {label}
      </Text>
    </Flex>
  );
};
