import { FormFieldTypes } from 'common/enums';
import moment from 'moment';

export const askField = [
  {
    name: `ask`,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Frage',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const dueDateField = [
  {
    name: `dueDate`,
    type: FormFieldTypes.Date,
    defaultValue: '',
    minDate: moment().startOf('day'),
    label: 'Fälligkeit',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const descriptionField = [
  {
    name: `comment`,
    type: FormFieldTypes.TextArea,
    defaultValue: '',
    label: 'Beschreibung',
    textAreaProps: {
      maxHeight: 400,
      minRows: 4
    },
    validation: {}
  }
];
