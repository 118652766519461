import React from 'react';
import { DealerCompanyDetailsProps } from './dealer-company-details.props';
import { useCompanyDetails } from '../react-query/use-company-details';
import { Flex, Text } from 'common/common-components.styled';

const DealerCompanyDetails = ({ dealerCompanyId }: DealerCompanyDetailsProps) => {
  const { companyDetails: dealerCompanyDetails } = useCompanyDetails(dealerCompanyId, 'get-dealer-company-details');

  return (
    <Flex align='flex-end' direction='column'>
      <Text size='12'>{dealerCompanyDetails?.name || <i>Nicht angegeben</i>}</Text>
      <Text size='12'>{dealerCompanyDetails?.address1_line1 || <i>Nicht angegeben</i>}</Text>
      <Text size='12'>
        {dealerCompanyDetails?.address1_postalcode || dealerCompanyDetails?.address1_city ? (
          `${dealerCompanyDetails?.address1_postalcode || ''} ${dealerCompanyDetails?.address1_city || ''}`
        ) : (
          <i>Nicht angegeben</i>
        )}
      </Text>
    </Flex>
  );
};

export default DealerCompanyDetails;
