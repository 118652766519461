import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';
import { IRepairStatusWidget } from '../../repair-status.props';

export const StyledContainer = styled(Flex)<Pick<IRepairStatusWidget, 'isExtended'>>`
  position: relative;
  height: 350px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme, isExtended }) => (isExtended ? theme.colors.extraLightBlue : theme.colors.white)};
  box-shadow: ${({ isExtended }) =>
    isExtended
      ? '0px 7.59435px 17.869px rgba(171, 176, 178, 0.17), 0px 2.26103px 5.32008px rgba(95, 100, 102, 0.17)'
      : 'none'};
  border-radius: 2px;
  transition: all 0.15s;
  z-index: 2;
  padding: 20px;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    max-height: none !important;
  }
`;
