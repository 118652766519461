import React, { useContext, cloneElement } from 'react';
import classes from '../cell.module.scss';
import classNames from 'classnames';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import { CellContext } from 'components/table-component/table-component-contexts';
import { SkeletonComponent } from 'components/skeleton/skeleton';

export const ImageCell = ({ column, row }: Pick<CellProps, 'column' | 'row'>) => {
  const { cellId } = useContext<ICellContext>(CellContext);

  return (
    <span
      id={cellId}
      style={{ width: column.width }}
      className={classNames(classes.table_img, column.className, classes.cell)}
    >
      {row.imgSrc === undefined ? (
        <SkeletonComponent width='100%' height='60px' />
      ) : column.component ? (
        cloneElement(column.component, { row, column })
      ) : (
        <div style={{ backgroundImage: `url(${row[column.propName || ''] || column.defaultImage})` }} />
      )}
    </span>
  );
};
