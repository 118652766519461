import React from 'react';
import PropTypes from 'prop-types';
import classes from './equipment-tab.module.scss';

export function StandardEquipmentItem({ value, className }) {
  return (
    <div className={className ? `${classes.standard_equipment} ${className}` : classes.standard_equipment}>
      <div className={classes.standard_equipment_container}>
        <p className={classes.standard_equipment_item}>{value}</p>
      </div>
      <div className={classes.divider} />
    </div>
  );
}

StandardEquipmentItem.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};
