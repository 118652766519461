import { FormFieldModel } from 'common/interfaces';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface IAdditionalFormList {
  additionalForms: AdditionalFormsType;
  setAdditionalForms: Dispatch<SetStateAction<AdditionalFormsType>>;
  reactHookFormData: UseFormReturn<any>;
  buttonClassName?: string;
}

export enum FieldNames {
  OfferProvider = 'offerProvider',
  DocumentType = 'documentType',
  ConfirmCheckbox = 'confirmCheckbox'
}

export type AdditionalFormsType = Array<Array<FormFieldModel>>;
