import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

interface StyledBudgetPartProps {
  backgroundColor: string;
}

export const StyledBudgetPart = styled(Flex)<StyledBudgetPartProps>`
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
`;
