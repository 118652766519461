import React from 'react';
import {
  VehicleOrderRedirectProps,
  VehicleOrderRedirectTypes
} from 'components/vehicle-delivery-redirect/vehicle-order-redirect.types';
import useVehicleDeliveriesByVehicleOffer from 'hooks/react-query/vehicle-delivery/use-vehicle-deliveries';
import { Distance, Text } from 'common/common-components.styled';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import { decodeBase64, encodeBase64 } from 'utils/decode-encode-base64';
import { VehicleDelivery } from 'hooks/react-query/vehicle-delivery/vehicle-delivery.types';
import { useQuery } from 'hooks';
import InfoHint from 'components/info-hint/info-hint-view';
import { regexValue } from 'utils/regex';

const VehicleOrderRedirect = ({ type }: VehicleOrderRedirectProps) => {
  const query = useQuery();
  const history = useHistory();

  const vehicleOfferId = query.id
    ? (query.id as string).match(regexValue.guid)
      ? (query.id as string)
      : decodeBase64(query.id as string)
    : null;
  const { vehicleDeliveries } = useVehicleDeliveriesByVehicleOffer(vehicleOfferId);

  const firstVehicleDelivery: VehicleDelivery | undefined = vehicleDeliveries[0];
  const isShow: boolean = !!firstVehicleDelivery;

  const goToOrderDetails = () => {
    history.push(`${PAGES.ORDER_DETAILS}/?id=${encodeBase64(firstVehicleDelivery.uds_fahrzeugauslieferungid)}`);
  };

  if (!isShow) {
    return null;
  }

  switch (type) {
    case VehicleOrderRedirectTypes.Link:
      return (
        <Text underline pointer color='blue' onClick={goToOrderDetails}>
          Bestellung: {firstVehicleDelivery.uds_name}
        </Text>
      );
    case VehicleOrderRedirectTypes.InfoBox:
      return (
        <>
          <Distance top='10' />
          <InfoHint type='info'>
            Weitere Informationen zu diesem Vorgang findest Du in der{' '}
            <Text as='span' pointer underline color='grey900' onClick={goToOrderDetails}>
              Bestellung: {firstVehicleDelivery.uds_name}
            </Text>
            .
          </InfoHint>
          <Distance top='20' />
        </>
      );
    default:
      return null;
  }
};

export default VehicleOrderRedirect;
