import { SegmentItemProps } from 'components/containers/diagram/segment-item/segment-item.props';
import { BarDiagramData } from 'components/widgets/bar/bar.props';
import { useHorizontalPlugin } from 'components/widgets/bar/hooks/plugins/use-horizontal-line/use-horizontal-line';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { ConsumptionEstimateValue } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id.props';
import { useConsumptionWidgetVariant } from '../../../hooks/use-widget-variant';
import useWindowSize from 'hooks/use-window-size';

export const useConsumptionWidgetDiagramData = () => {
  const { fleetByIdQuery, isFetching } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isMobile } = useWindowSize();

  const { widgetVariant } = useConsumptionWidgetVariant();

  const isShowConsumptionPlaceholder = isFetching || !fuhrpark?.uds_consumption_estimate_value;

  const horizontalLineSegment: SegmentItemProps = { label: 'laut Hersteller', color: 'blue' };
  const horizontalLineValue = Number(fuhrpark?.uds_verbrauch?.replace(',', '.')) || 0;

  const diagramColumn = [
    {
      label: 'Aktuell',
      value: Number(fuhrpark?.uds_combined_consumption) || 0,
      color: fuhrpark?.uds_consumption_estimate_value_value === ConsumptionEstimateValue.Increased ? 'gold' : 'green'
    }
  ];

  const segments: SegmentItemProps[] = [...diagramColumn, horizontalLineSegment].map(({ label, color }) => ({
    label,
    color
  }));

  const diagramData: BarDiagramData = diagramColumn.map(({ value, color }) => ({ value, color }));

  const { horizontalLinePlugin } = useHorizontalPlugin(horizontalLineValue, {
    color: horizontalLineSegment.color,
    lineWidth: 4
  });

  return {
    diagramData,
    segments: (widgetVariant === 'small' || isMobile) && !isShowConsumptionPlaceholder ? segments : [],
    horizontalLineValue,
    horizontalLinePlugin,
    isShowConsumptionPlaceholder,
    isFetching
  };
};
