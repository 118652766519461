import React, { SyntheticEvent } from 'react';
import ButtonWithIcon from 'components/button-with-icon';
import { AskQuestionIcon } from 'components/icons/ask-qustion-icon';
import { TableComponentsProps } from '../table-components.props';
import { VehicleItemData } from 'pages/my-vehicles/my-vehicles.props';

export const AskQuestion = ({ row, column }: TableComponentsProps) => {
  const onClickHandler = (event: SyntheticEvent) => {
    event.stopPropagation();
    column!.action!(row![column?.actionParameter as keyof VehicleItemData]);
  };

  return <ButtonWithIcon tooltip='Frage stellen' icon={<AskQuestionIcon />} onClick={onClickHandler} />;
};
