import React from 'react';
import { FormSection } from 'components/sections/form-section/form-section';
import { FormInfoList } from 'components/form-hook/components/info/form-info-list';
import { useFormInfoListDataOrganization } from './hooks/use-form-info-list-data-organization';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailsOrganizationTabPanel = () => {
  const { formInfoListDataOrganization } = useFormInfoListDataOrganization();

  const { windowWidth } = useWindowSize(500);
  const columns = windowWidth <= 550 ? 1 : 2;

  return (
    <FormSection title='Organisation'>
      <FormInfoList columns={columns} type='editable' data={formInfoListDataOrganization} />
    </FormSection>
  );
};
