import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { get } from 'lodash';

import store from '../store';
import { toggleModal } from '../actions/app_action';
import { MODALS, MODAL_NAMES } from '../constants';
import { withRouter } from 'react-router-dom';

ReactGA.initialize('UA-165055377-1');

export function withModal(WrappedComponent) {
  const hoc = class extends Component {
    constructor(props) {
      super(props);
      this.openModal = this.openModal.bind(this);
    }

    openModal(message = '', title = 'Wir freuen uns auf Sie') {
      const modalParams = {
        location: window.location.href,
        message,
        title
      };
      const vehicleId = get(this.props.match, ['params', 'id']);
      if (vehicleId) {
        modalParams.vehicleId = atob(vehicleId);
      }

      ReactGA.event({ category: 'Lead form open', action: 'Click' });

      store.dispatch(toggleModal(MODALS.general_contact_form, MODAL_NAMES.lead_form, modalParams));
    }

    render() {
      return <WrappedComponent openModal={this.openModal} {...this.props} />;
    }
  };

  return withRouter(hoc);
}
