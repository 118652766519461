import React from 'react';
import { Flex } from 'common/common-components.styled';
import { DiagramWithBottomSegmentsContainer } from 'components/containers/diagram/bottom-segments/bottom-segments';
import { DrivingCarIcon } from 'components/icons-new/driving-car';
import { InfoHintAddingInformationToFinanceRate } from '../../../info-hint/adding-information-to-finance-rate/adding-information-to-finance-rate';
import { ChangeKilometerForm } from '../../../change-kilometer-form/change-kilometer-form';

export const MileageAndSomeOfLeasingDataIsNotAvailable = () => {
  return (
    <DiagramWithBottomSegmentsContainer styledFlex={{ align: 'center' }} segments={[]}>
      <Flex justify='center' flex='1'>
        <DrivingCarIcon />
      </Flex>

      <Flex direction='column' flex='2' left='13'>
        <ChangeKilometerForm top='0' bottom='10' />
        <InfoHintAddingInformationToFinanceRate text='Es fehlen Infos zum km-Stand, Leasingstart und Leasingende sowie zur Laufleistung. Bitte ergänze diese in der Finanzrate und gebe hiere den aktuellen km-Stand an, damit wir die Projektion berechnen können.' />
      </Flex>
    </DiagramWithBottomSegmentsContainer>
  );
};
