import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { ReactQueryKeys } from 'common/enums';
import { FleetByIdQueryData, FleetByIdResponse } from './use-get-by-id.props';
import { getDataForTable, getDataForTableItem } from 'utils/get-response-data';

export const useFleetByIdQuery = (vehicleIdProps?: string, isShowLoading: boolean = true) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch<ResponseModel<FleetByIdResponse>>({
    loadingKey: 'fleetByIdQuery',
    isShowLoading,
    ...fleetRequestConfig.getById(vehicleId as string)
  });

  const modifyData = (data: FleetByIdResponse): FleetByIdQueryData => ({
    ...data,
    fuhrpark: getDataForTableItem(data.fuhrpark || {}),
    tankkartes: getDataForTable(data.tankkartes || [])
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.FleetById, vehicleId], () => fetch(), {
    enabled: !!vehicleId,
    select: ({ data }) => modifyData(data.data)
  });

  return { fleetByIdQuery: data || {}, ...restQueryProps };
};
