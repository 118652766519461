import React, {FunctionComponent , ReactElement} from 'react';
import {Title} from '../../../common/interfaces';
import {
    FilterButton,
    FilterInput,
  } from '../../../components/filter-items';
import {useFilterByName} from '../hooks/use-filter-by-name';
import {TransformedFilterItem} from '../../../services/vehicle-costs-service';


interface FilterDataProviderProps{
    handleInput(title:any, value:any, filterData:TransformedFilterItem[]):void;
    title:string;
    withoutSearchButton?:boolean;
    filteredButton?:boolean;
    dropListDataWithId?:boolean;
    dropListDataValueName?:string;
    circleIndicator?:boolean;
};

export const FilterDataProvider:FunctionComponent<FilterDataProviderProps> = (
    {handleInput, title, filteredButton, circleIndicator, ...rest}
  ):ReactElement=>{
    const {isFetching, data} = useFilterByName(title as Title);

    return (
     <>
       {filteredButton && (
         data?.map((element, idx:number) => {
            if (idx <= 2) {
              const name = element.name;
              const id = element.id;
              return (
                <FilterButton
                  key={`${name}-${id}`}
                  title={title}
                  label={name}
                  value={circleIndicator ? element : name}
                  handleInput={handleInput}
                  itemsList={data}
                />
              );
            }
         }) ?? null)
        }
        <FilterInput
          title={title}
          handleInput={handleInput}
          dropListData={circleIndicator ? data : data.map( option => option.name)}
          isLoading={isFetching}
          itemsList={data}
          circleIndicator={circleIndicator}
          {...rest}
        />
     </>
    )
};