import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../constants';
import { useOverlay } from 'hooks';
import { useDispatch } from 'react-redux';
import { updateDriver } from 'services/drivers-check-service';
import { EnterEmailFormValuesProps, useEmailModal } from '../email.modal';
import { useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { ISubscribeAlertOptionParams } from './driver-check.props';

export const useBookDriverLicenseCheckModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [showOverlay, hideOverlay] = useOverlay();
  const { openModal: openEmailModal } = useEmailModal();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getAlertData = (contactId = '', isUserHaveEmail: boolean, optionalParams?: ISubscribeAlertOptionParams) => ({
    title: 'Führerscheinkontrolle buchen',
    children: (
      <>
        <p>Der Fahrer wird per E-Mail eingeladen die Führerscheinkontrolle zu absolvieren.</p>
        <p>Die Führerscheinkontrolle für diesen Fahrer kostenpflichtig buchen?</p>
      </>
    ),
    buttons: [
      {
        type: 'cancel',
        title: 'Abbrechen',
        action: closeAlert
      },
      {
        type: 'submit',
        title: 'Buchen',
        action: async () => {
          showOverlay();
          closeAlert();

          const submitHandler = async (emailFormValues?: EnterEmailFormValuesProps) => {
            await updateDriver(contactId, true);
            optionalParams?.callback && optionalParams?.callback(emailFormValues);

            queryClient.resetQueries([ReactQueryKeys.AccountIsBooked]);
            queryClient.resetQueries([PAGES.DRIVERS_CHECK]);
            queryClient.resetQueries([PAGES.DRIVERS_CHECK, ReactQueryKeys.Filter]);
          };

          try {
            if (!isUserHaveEmail) {
              openEmailModal(contactId, submitHandler);
              return;
            }

            await submitHandler();
          } catch (err) {
            console.error(err);
          } finally {
            hideOverlay();
          }
        }
      }
    ]
  });

  return {
    openModal: (contactId: string, isUserHaveEmail: boolean, optionalParams?: ISubscribeAlertOptionParams) =>
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(contactId, isUserHaveEmail, optionalParams)))
  };
};
