import styled from 'styled-components';

export const StyledPaymentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const StyledAddonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey50};
  width: 100%;
`;
