import React from 'react';
import styles from '../report-damage-component.module.scss';
import { useGetReportDamageLogoQuery } from 'hooks/react-query/portal-customization/get-report-damage-logo/get-report-damage-logo.query';
import { useGetReportDamageSettingsQuery } from 'hooks/react-query/portal-customization/get-report-damage-settings/get-report-damage-settings.query';
import UnauthorisedLogoMobexo from 'components/icons/unauthorised-logo-mobexo';
import { SkeletonComponent } from 'components/skeleton/skeleton';

export const SMHeaderLogo = () => {
  const { reportDamageSettingsQuery } = useGetReportDamageSettingsQuery();
  const { reportDamageLogoQuery, isFetching } = useGetReportDamageLogoQuery(
    reportDamageSettingsQuery.uds_portalcustomizationid || ''
  );

  if (isFetching) {
    return <SkeletonComponent className={styles.custom_logo} />;
  }

  if (reportDamageLogoQuery) {
    return <img className={styles.custom_logo} src={URL.createObjectURL(reportDamageLogoQuery)} />;
  }

  return (
    <a className={styles.logo_container} href='https://www.mobexo.de/' target='_blank' rel='noopener noreferrer'>
      <UnauthorisedLogoMobexo width='180' height='50' />
    </a>
  );
};
