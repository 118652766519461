import { PAGES } from 'common/enums';
import { useCarDealershipSettingGetListQuery } from 'hooks/react-query/car-dealership-setting/get-list/get-list.query';
import { useCompaniesByTypesQuery } from 'hooks/react-query/company/companies-by-types/companies-by-types.query';
import { useGetCarDealershipsQuery } from 'hooks/react-query/company/get-car-dealerships/get-car-dealerships.query';
import { useCrmEntityGetEntityByFieldQuery } from 'hooks/react-query/crm-entity/get-entity-by-field/get-entity-by-field.query';
import { useGetDamageRecordingFormDataQuery } from 'hooks/react-query/damage/get-damage-recording-form-data/get-damage-recording-form-data.query';
import { useDocumentTypeByAppQuery } from 'hooks/react-query/document-type/document-type-by-app/document-type-by-app';
import { useGetDriversWithCarQuery } from 'hooks/react-query/driver/get-drivers-with-car/get-drivers-with-car.query';
import { useGetVehicleListVRQuery } from 'hooks/react-query/fleet-lease-end/get-vehicle-list-vr/get-vehicle-list-vr.query';
import { useDescriptionChoicesQuery } from 'hooks/react-query/fleet/description-choices/description-choices.query';
import { useUnusedFleetQuery } from 'hooks/react-query/fleet/unused-fleet/unused-fleet.query';
import { usePolicyCompanyListQuery } from 'hooks/react-query/policy/company-list/company-list.query';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { FormSourceEnum } from '../use-set-options.props';
import { sourceFetchPayload } from './source-fetch-payload';
import { IS_TEST_COMPANY } from '../../../../../../constants';
import { useMemo } from 'react';
import { useFuelReportNameFilterQuery } from 'hooks/react-query/fuel-reports/fuel-reports-filter';
import { FuelReportsFilterWithId } from 'hooks/react-query/fuel-reports/fuel-reports-types';
import { useDriversForBookingQuery } from 'hooks/react-query/pool-calendar/use-drivers-for-booking';

export const useFormSourceMapping = (search: string, source?: FormSourceEnum) => {
  const user = useTypedSelector(state => state.app.user);
  const { getDriversWithCarQuery } = useGetDriversWithCarQuery(
    { sparteId: user.companyId, activeOnly: true },
    { options: { enabled: source === FormSourceEnum.GetCompanyDrivers } }
  );
  const { companiesByTypesQuery: getContractPartnerCompaniesQuery } = useCompaniesByTypesQuery(
    sourceFetchPayload.getContractPartnerCompanies,
    { options: { enabled: source === FormSourceEnum.GetContractPartnerCompanies } }
  );
  const { crmEntityGetEntityByFieldQuery: getCarBrandsQuery } = useCrmEntityGetEntityByFieldQuery(
    sourceFetchPayload.getCarBrands,
    { options: { enabled: source === FormSourceEnum.GetCarBrands } }
  );
  const { crmEntityGetEntityByFieldQuery: getCarBrandsForGrv } = useCrmEntityGetEntityByFieldQuery(
    sourceFetchPayload.getCarBrandsForGrv,
    { options: { enabled: source === FormSourceEnum.GetCarBrandsForGrv } }
  );
  const { crmEntityGetEntityByFieldQuery: getCompanyContractsQuery } = useCrmEntityGetEntityByFieldQuery(
    sourceFetchPayload.getCompanyContracts(user.companyId),
    { options: { enabled: source === FormSourceEnum.GetCompanyContracts } }
  );
  const { unusedFleetQuery } = useUnusedFleetQuery(undefined, {
    options: { enabled: source === FormSourceEnum.GetUnusedUserCompanyFuhrparks }
  });
  const { getCarDealershipsQuery } = useGetCarDealershipsQuery(['accountid', 'name'], {
    options: { enabled: source === FormSourceEnum.GetCarDealerships }
  });
  const { policyCompanyListQuery } = usePolicyCompanyListQuery(
    {},
    { options: { enabled: source === FormSourceEnum.GetUserCompanyCarPolicies } }
  );
  const { getVehicleListVRQuery } = useGetVehicleListVRQuery({
    options: { enabled: source === FormSourceEnum.GetCarsListVR }
  });
  const { companiesByTypesQuery } = useCompaniesByTypesQuery(sourceFetchPayload.getServices, {
    options: { enabled: source === FormSourceEnum.GetServices }
  });
  const { carDealershipSettingGetListQuery } = useCarDealershipSettingGetListQuery('', {
    options: { enabled: source === FormSourceEnum.GetUserCompanyCarDealerships }
  });
  const { descriptionChoicesQuery } = useDescriptionChoicesQuery({
    options: { enabled: source === FormSourceEnum.GetDocumentTypes }
  });
  const { query: damageRecordMarkQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'kennzeichen' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordMark } }
  );
  const { query: damageRecordRepairPartnerQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'reparaturpartner' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordRepairPartner } }
  );
  const { query: damageRecordWorkshopQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'werkstatt' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordWorkshop } }
  );
  const { query: damageRecordOwnerQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'eigentumer' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordOwner } }
  );
  const { query: damageRecordInsurerQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'versicherer' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordInsurer } }
  );
  const { query: damageRecordInsuranceBrokerQuery } = useGetDamageRecordingFormDataQuery(
    { search, formDataKey: 'versicherungsmarkler' },
    { options: { enabled: source === FormSourceEnum.GetDamageRecordInsuranceBroker } }
  );
  const { documentTypeByAppQuery } = useDocumentTypeByAppQuery(PAGES.DAMAGE, {
    options: { enabled: source === FormSourceEnum.GetDamagePageDocumentTypes }
  });
  const { driversForBooking } = useDriversForBookingQuery({
    options: { enabled: source === FormSourceEnum.GetDriversForBooking }
  });

  const { getFuelReportFilterQuery: getFuelReportFilterNameQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_name' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterName } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterDriverQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_fahrerid' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterDriver } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterLocationQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_standortid' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterLocation } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterBranchQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_sparteid' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterBranch } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterManufacturerQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_hersteller' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterManufacturer } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterModelQuery } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_model' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterModel } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterCostCenterId } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'new_kostenstelleid' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterCostCenterId } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterCostCenter2 } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'uds_kostenstelle2' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterCostCenter2 } }
  );

  const { getFuelReportFilterQuery: getFuelReportFilterCostCenter3 } = useFuelReportNameFilterQuery(
    { searchValue: search, columnName: 'uds_kostenstelle3' },
    { options: { enabled: source === FormSourceEnum.GetFuelReportsFilterCostCenter3 } }
  );

  const sourceMapping = useMemo((): { value: string; label: string }[] => {
    switch (source) {
      case FormSourceEnum.GetCarBrands:
        return getCarBrandsQuery.entityes.map(item => ({
          value: item.uds_carbrandid,
          label: item.uds_name
        }));
      case FormSourceEnum.GetCarBrandsForGrv:
        return getCarBrandsForGrv.entityes.map(item => ({
          value: item.uds_carbrandid,
          label: item.uds_name
        }));
      case FormSourceEnum.GetCompanyContracts:
        return getCompanyContractsQuery.entityes.map(item => ({
          value: item.uds_rahmenvertrgesid,
          label: item.uds_vertragsbezeichnung || '-Leere Vertragsbezeichnung-'
        }));
      case FormSourceEnum.GetCompanyDrivers:
        return getDriversWithCarQuery.map(item => ({ label: item.name, value: item.id }));
      case FormSourceEnum.GetContractPartnerCompanies:
        return getContractPartnerCompaniesQuery
          .filter(item => item.uds_testcompanycode !== IS_TEST_COMPANY.yes.value || user.isTestUser)
          .sort((a, b) => a.name?.localeCompare(b.name))
          .map(item => ({ value: item.accountid, label: item.name }));
      case FormSourceEnum.GetUnusedUserCompanyFuhrparks:
        return unusedFleetQuery.map(item => ({
          value: item.new_fuhrparkid || '-',
          label: `${item.new_name}${
            item.new_hersteller || item.new_model ? ` (${item.new_hersteller || ''} ${item.new_model || ''})` : ''
          }`
        }));
      case FormSourceEnum.GetCarDealerships:
        return getCarDealershipsQuery.map(item => ({
          value: item.accountid,
          label: item.name
        }));
      case FormSourceEnum.GetUserCompanyCarPolicies:
        return policyCompanyListQuery.items.map(item => ({
          value: item.uds_carpolicyid || '-',
          label: item.uds_name || '-'
        }));
      case FormSourceEnum.GetCarsListVR:
        return getVehicleListVRQuery.fuhrparkList.map(item => ({
          value: item.id || '-',
          label: item.name || '-'
        }));
      case FormSourceEnum.GetServices:
        return companiesByTypesQuery.map(item => ({
          value: item.accountid || '-',
          label: item.name || '-'
        }));
      case FormSourceEnum.GetDamageRecordMark:
        return damageRecordMarkQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamageRecordRepairPartner:
        return damageRecordRepairPartnerQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamageRecordWorkshop:
        return damageRecordWorkshopQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamageRecordOwner:
        return damageRecordOwnerQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamageRecordInsurer:
        return damageRecordInsurerQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamageRecordInsuranceBroker:
        return damageRecordInsuranceBrokerQuery.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDamagePageDocumentTypes:
        return documentTypeByAppQuery.documentTypes.map(item => ({
          value: item.id,
          label: item.name
        }));
      case FormSourceEnum.GetDocumentTypes:
        return descriptionChoicesQuery.sort().map(item => ({ value: item, label: item }));
      case FormSourceEnum.GetUserCompanyCarDealerships:
        return (
          carDealershipSettingGetListQuery?.map(item => ({
            value: item.uds_autohandlerid_id || '-',
            label: item.uds_autohandlerid || '-'
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterName:
        return (
          getFuelReportFilterNameQuery.new_name?.map(item => ({
            value: item.id,
            label: item.name,
            active: item?.active ?? null
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterDriver:
        return (
          getFuelReportFilterDriverQuery.new_fahrerid?.map(item => ({
            value: item.id,
            label: item.name
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterLocation:
        return (
          getFuelReportFilterLocationQuery.new_standortid?.map(item => ({
            value: item.id,
            label: item.name
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterBranch:
        return (
          getFuelReportFilterBranchQuery.new_sparteid?.map(item => ({
            value: item.id,
            label: item.name
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterManufacturer:
        return (
          getFuelReportFilterManufacturerQuery.new_hersteller?.map(item => ({
            value: item,
            label: item
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterModel:
        return (
          getFuelReportFilterModelQuery.new_model?.map(item => ({
            value: item,
            label: item
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterCostCenterId:
        return (
          getFuelReportFilterCostCenterId.new_kostenstelleid?.map(item => ({
            value: item.id,
            label: item.name
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterCostCenter2:
        return (
          getFuelReportFilterCostCenter2.uds_kostenstelle2?.map(item => ({
            value: item,
            label: item
          })) || []
        );

      case FormSourceEnum.GetFuelReportsFilterCostCenter3:
        return (
          getFuelReportFilterCostCenter3.uds_kostenstelle3?.map(item => ({
            value: item,
            label: item
          })) || []
        );
      case FormSourceEnum.GetDriversForBooking:
        return driversForBooking.map(item => ({ label: item.name, value: item.id }));

      default:
        return [];
    }
  }, [
    JSON.stringify(descriptionChoicesQuery),
    JSON.stringify(companiesByTypesQuery),
    JSON.stringify(damageRecordInsuranceBrokerQuery),
    JSON.stringify(damageRecordInsurerQuery),
    JSON.stringify(damageRecordMarkQuery),
    JSON.stringify(damageRecordOwnerQuery),
    JSON.stringify(damageRecordRepairPartnerQuery),
    JSON.stringify(damageRecordWorkshopQuery),
    JSON.stringify(documentTypeByAppQuery.documentTypes),
    JSON.stringify(getCarBrandsForGrv.entityes),
    JSON.stringify(getCarBrandsQuery.entityes),
    JSON.stringify(getCarDealershipsQuery),
    JSON.stringify(getCompanyContractsQuery.entityes),
    JSON.stringify(getContractPartnerCompaniesQuery),
    JSON.stringify(getDriversWithCarQuery),
    JSON.stringify(getVehicleListVRQuery.fuhrparkList),
    JSON.stringify(policyCompanyListQuery.items),
    JSON.stringify(unusedFleetQuery),
    JSON.stringify(carDealershipSettingGetListQuery),
    JSON.stringify(getFuelReportFilterNameQuery),
    JSON.stringify(getFuelReportFilterDriverQuery),
    JSON.stringify(getFuelReportFilterLocationQuery),
    JSON.stringify(getFuelReportFilterBranchQuery),
    JSON.stringify(getFuelReportFilterManufacturerQuery),
    JSON.stringify(getFuelReportFilterModelQuery),
    JSON.stringify(getFuelReportFilterCostCenterId),
    JSON.stringify(getFuelReportFilterCostCenter2),
    JSON.stringify(getFuelReportFilterCostCenter3),
    JSON.stringify(driversForBooking),
    source,
    user.isTestUser
  ]);

  return { sourceMapping };
};
