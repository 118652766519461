import styled from 'styled-components';
import { Tooltip } from '@mui/material';

export const StyledLeaseendContainer = styled.div`
  padding: 30px 0;
  height: auto;
  min-height: calc(100% - 36px);
  width: 100%;
  margin: 0 auto;
  overflow: auto;
`;

interface StyledTableWrapperProps {
  minWidth: number;
}

export const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
  min-width: ${({ minWidth }) => `${minWidth}px`};
`;

export const StyledPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 900px;
  margin: 60px auto;
`;

export const StyledTooltip = styled(Tooltip)`
  font-family: 'Open Sans';
  font-weight: 200;
`;

export const StyledTooltipText = styled.p`
  line-height: 22px;
  white-space: break-spaces;
`;
