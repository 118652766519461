import { connect } from 'react-redux';
import ComparisonForm from './comparison-form-view';
import { toggleModal } from '../../actions/app_action';
import { MODALS } from '../../constants';
import {deleteFromComparison} from "../../actions/comparison_action";

const mapStateToProps = state => ({
  comparisonCars: state.comparison.cars
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(toggleModal(MODALS.general_comparison_form, null));
  },
  deleteFromComparison(id) {
    dispatch(deleteFromComparison(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonForm);
