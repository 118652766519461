import styled from 'styled-components';

export const StyledCheckout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledCheckoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: inherit;
`;

export const StyledHeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 710px;
  margin-bottom: 40px;
`;

export const StyledHeaderRightContainer = styled.div``;

export const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`;
