import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const Car = ({ width = 20, height = 15, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered ? (
        <path
          d='M15.2748 12.6941H14.1027V14.1595C14.1027 14.3354 14.2199 14.4526 14.3957 14.4526H17.326C17.5018 14.4526 17.619 14.3354 17.619 14.1595V12.3424L17.8534 11.2287C17.8827 11.0235 17.912 10.8476 17.912 10.6425V7.77026C17.912 7.41856 17.8534 7.06686 17.7069 6.71516L17.4139 5.95313H18.7032C18.879 5.95313 19.0256 5.77728 18.9962 5.60143L18.7618 4.66356C18.7032 4.39979 18.4688 4.22394 18.2051 4.22394H16.8572L15.7437 1.96718C15.4506 1.38101 14.8353 1 14.1613 1H5.86869C5.19473 1 4.57937 1.38101 4.28635 1.96718L3.17285 4.22394H1.79563C1.53191 4.22394 1.29749 4.39979 1.23888 4.66356L1.00446 5.60143C0.975153 5.77728 1.09236 5.95313 1.29748 5.95313H2.5868L2.29378 6.71516C2.17657 7.03755 2.08865 7.38925 2.08865 7.77026V10.6425C2.08865 10.8476 2.11796 11.0528 2.14726 11.2287L2.38168 12.3424V14.1595C2.38168 14.3354 2.49889 14.4526 2.67471 14.4526H5.60496C5.78078 14.4526 5.89799 14.3354 5.89799 14.1595V12.6941H4.72589'
          fill='white'
        />
      ) : (
        <path
          d='M15.2748 12.6941H14.1027V14.1595C14.1027 14.3354 14.2199 14.4526 14.3957 14.4526H17.326C17.5018 14.4526 17.619 14.3354 17.619 14.1595V12.3424L17.8534 11.2287C17.8827 11.0235 17.912 10.8476 17.912 10.6425V7.77026C17.912 7.41856 17.8534 7.06686 17.7069 6.71516L17.4139 5.95313H18.7032C18.879 5.95313 19.0256 5.77728 18.9962 5.60143L18.7618 4.66356C18.7032 4.39979 18.4688 4.22394 18.2051 4.22394H16.8572L15.7437 1.96718C15.4506 1.38101 14.8353 1 14.1613 1H5.86869C5.19473 1 4.57937 1.38101 4.28635 1.96718L3.17285 4.22394H1.79563C1.53191 4.22394 1.29749 4.39979 1.23888 4.66356L1.00446 5.60143C0.975153 5.77728 1.09236 5.95313 1.29748 5.95313H2.5868L2.29378 6.71516C2.17657 7.03755 2.08865 7.38925 2.08865 7.77026V10.6425C2.08865 10.8476 2.11796 11.0528 2.14726 11.2287L2.38168 12.3424V14.1595C2.38168 14.3354 2.49889 14.4526 2.67471 14.4526H5.60496C5.78078 14.4526 5.89799 14.3354 5.89799 14.1595V12.6941H4.72589'
          stroke={color}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      )}
      {hovered && (
        <path
          d='M14.1027 14.1595V12.6941H5.89799V14.1595C5.89799 14.3354 5.78078 14.4526 5.60496 14.4526H2.67471C2.49889 14.4526 2.38168 14.3354 2.38168 14.1595V12.3424L2.14726 11.2287C2.11796 11.0528 2.08865 10.8477 2.08865 10.6425V7.77026C2.08865 7.38925 2.17657 7.03755 2.29378 6.71516L2.5868 5.95314H1.29748C1.09236 5.95314 0.975153 5.77729 1.00446 5.60144L1.23888 4.66357C1.29749 4.39979 1.53191 4.22394 1.79563 4.22394H3.17285L5 5H15L16.8572 4.22394H18.2051C18.4688 4.22394 18.7032 4.39979 18.7618 4.66357L18.9962 5.60144C19.0256 5.77729 18.879 5.95314 18.7032 5.95314H17.4139L17.7069 6.71516C17.8534 7.06686 17.912 7.41856 17.912 7.77026V10.6425C17.912 10.8477 17.8827 11.0235 17.8534 11.2287L17.619 12.3424V14.1595C17.619 14.3354 17.5018 14.4526 17.326 14.4526H14.3957C14.2199 14.4526 14.1027 14.3354 14.1027 14.1595Z'
          fill={fill}
        />
      )}
      {hovered && (
        <path
          d='M15.2748 12.6941H14.1027V14.1595C14.1027 14.3354 14.2199 14.4526 14.3957 14.4526H17.326C17.5018 14.4526 17.619 14.3354 17.619 14.1595V12.3424L17.8534 11.2287C17.8827 11.0235 17.912 10.8476 17.912 10.6425V7.77026C17.912 7.41856 17.8534 7.06686 17.7069 6.71516L17.4139 5.95313H18.7032C18.879 5.95313 19.0256 5.77728 18.9962 5.60143L18.7618 4.66356C18.7032 4.39979 18.4688 4.22394 18.2051 4.22394H16.8572L15.7437 1.96718C15.4506 1.38101 14.8353 1 14.1613 1H5.86869C5.19473 1 4.57937 1.38101 4.28635 1.96718L3.17285 4.22394H1.79563C1.53191 4.22394 1.29749 4.39979 1.23888 4.66356L1.00446 5.60143C0.975153 5.77728 1.09236 5.95313 1.29748 5.95313H2.5868L2.29378 6.71516C2.17657 7.03755 2.08865 7.38925 2.08865 7.77026V10.6425C2.08865 10.8476 2.11796 11.0528 2.14726 11.2287L2.38168 12.3424V14.1595C2.38168 14.3354 2.49889 14.4526 2.67471 14.4526H5.60496C5.78078 14.4526 5.89799 14.3354 5.89799 14.1595V12.6941H4.72589'
          stroke={color}
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      )}
      <path
        d='M5.60497 12.6941H14.6888'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.84678 8.59087H6.27889L5.86866 7.77023'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1539 8.59087H13.7218L14.132 7.77023'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.65614 10.6425H12.3446'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.01891 5.07387H14.9818'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
