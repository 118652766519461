import React from 'react';
import { useDispatch } from 'react-redux';
import Form from 'components/form';
import {
  deactivatingCarFormField,
  deactivatingCarDetailedDescriptionFormField
} from 'pages/existing-vehicle-page/form-field';
import { setForm, toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../constants';
import { getFormValues } from '../../utils/get-form-values';
import useLatestValue from '../use-latest-value';
import { useTypedSelector } from '../use-typed-selector';
import { AnyObject } from '../../common/interfaces';
import useContractStatus from 'hooks/react-query/use-contract-status';

export const useDeactivatingCarModal = (actionHandler: Function) => {
  const dispatch = useDispatch();
  const forms = useTypedSelector(state => state.app.forms);
  const formsLatest = useLatestValue(forms);
  const { isContractAvailable } = useContractStatus();

  return () => {
    const openModal = () => {
      const alertData = {
        title: 'Deaktivierung/Aussterung des Fahrzeugs anfragen',
        children: (
          <div>
            <div style={{ marginBottom: 20 }}>
              <p>Das Fahrzeug soll ausgesteuert oder deaktiviert werden?</p>
              <p>Nach deiner Anfrage setzten wir uns mit dir in Verbindung, dies kann bis zu 5 Werktagen dauern.</p>
            </div>

            <Form name='deactivatingCar' formFields={deactivatingCarFormField} />

            <p style={{ marginTop: 20, marginBottom: 20 }}>
              Bitte gibt uns noch ein paar weitere Informationen mit, um den Prozess zu beschleunigen (z. B. Wurde das
              Fahrzeug bereits zurückgegeben? Zu welchem Datum soll das Fahrzeug zurückbegeben werden? Benötigst du
              Unterstützung bei der Leasingrückgabe?).
            </p>
            <Form name='deactivatingCarDescription' formFields={deactivatingCarDetailedDescriptionFormField} />
          </div>
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert
          },
          {
            type: 'submit',
            title: 'Anfragen',
            disabled: !isContractAvailable,
            action: () => {
              getFormValues(
                formsLatest.current,
                (form: any) => dispatch(setForm(form)),
                async ({ reason, description }: AnyObject) => actionHandler(reason, description)
              );
            }
          }
        ]
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    };

    const closeAlert = () => {
      dispatch(toggleModal(MODALS.alert, null));
    };

    return openModal();
  };
};
