import { UNAUTHORIZED_PAGES } from 'common/enums';
import { ProductId } from 'hooks/react-query/product/get-user-product/get-user-product.props';
import { useIsProductAvailable } from 'hooks/use-is-product-available/use-is-product-available';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const useReportDamageCustomLink = () => {
  const user = useTypedSelector(state => state.app.user);

  const { isAvailable } = useIsProductAvailable(ProductId.CustomizeReportDamagePage, 'all');

  const getLink = () => {
    if (isAvailable && user.schadenmeldenLink) {
      return user.schadenmeldenLink;
    }

    return UNAUTHORIZED_PAGES.REPORT_DAMAGE;
  };

  return { reportDamageCustomLink: getLink() };
};
