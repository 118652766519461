import { IconProps } from 'common/interfaces';
import React from 'react';

export const DateBlueIcon = ({ width = 21, height = 20, color = '#102F3F', className, id }: IconProps) => (
  <svg
    id={id}
    width={width}
    height={height}
    viewBox='0 0 21 20'
    className={className}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9833 4.01063V2.31699C14.9833 1.97826 14.7575 1.75244 14.4188 1.75244C14.08 1.75244 13.8542 1.97826 13.8542 2.31699V4.01063H7.07966V2.31699C7.07966 1.97826 6.85385 1.75244 6.51512 1.75244C6.17639 1.75244 5.95057 1.97826 5.95057 2.31699V4.01063C4.82148 4.23645 3.9182 5.13972 3.69238 6.26882H17.2415C17.0157 5.13972 16.1124 4.23645 14.9833 4.01063Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.67188 7.44775H17.3395V15.9029C17.3395 17.2054 16.2837 18.2613 14.9812 18.2613H6.03025C4.72776 18.2613 3.67188 17.2054 3.67188 15.9029V7.44775ZM8.05489 11.1361C8.05489 11.7598 7.54931 12.2654 6.92564 12.2654C6.30197 12.2654 5.79639 11.7598 5.79639 11.1361C5.79639 10.5124 6.30197 10.0069 6.92564 10.0069C7.54931 10.0069 8.05489 10.5124 8.05489 11.1361ZM10.5057 12.2654C11.1294 12.2654 11.635 11.7598 11.635 11.1361C11.635 10.5124 11.1294 10.0069 10.5057 10.0069C9.88207 10.0069 9.37648 10.5124 9.37648 11.1361C9.37648 11.7598 9.88207 12.2654 10.5057 12.2654ZM15.2151 11.1361C15.2151 11.7598 14.7095 12.2654 14.0858 12.2654C13.4621 12.2654 12.9565 11.7598 12.9565 11.1361C12.9565 10.5124 13.4621 10.0069 14.0858 10.0069C14.7095 10.0069 15.2151 10.5124 15.2151 11.1361ZM6.92564 15.7038C7.54931 15.7038 8.05489 15.1982 8.05489 14.5745C8.05489 13.9508 7.54931 13.4453 6.92564 13.4453C6.30197 13.4453 5.79639 13.9508 5.79639 14.5745C5.79639 15.1982 6.30197 15.7038 6.92564 15.7038ZM11.635 14.5745C11.635 15.1982 11.1294 15.7038 10.5057 15.7038C9.88207 15.7038 9.37648 15.1982 9.37648 14.5745C9.37648 13.9509 9.88207 13.4453 10.5057 13.4453C11.1294 13.4453 11.635 13.9509 11.635 14.5745ZM14.0858 15.7038C14.7095 15.7038 15.2151 15.1982 15.2151 14.5745C15.2151 13.9509 14.7095 13.4453 14.0858 13.4453C13.4621 13.4453 12.9565 13.9509 12.9565 14.5745C12.9565 15.1982 13.4621 15.7038 14.0858 15.7038Z'
      fill={color}
    />
  </svg>
);
