import { AxiosRequestConfig } from 'axios';
import { InviteDriverProps, UpdateUserProps } from './user.types';

class UserRequestConfig {
  getUser = (id: string): AxiosRequestConfig => ({
    method: 'GET',
    url: 'user',
    params: { id }
  });

  updateUser = (data: UpdateUserProps): AxiosRequestConfig => ({
    method: 'PUT',
    url: 'user',
    data
  });

  inviteDriver = (data: InviteDriverProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'user',
    data
  });

  deleteUserDocument = (fileid: string): AxiosRequestConfig => ({
    method: 'DELETE',
    url: 'user/deleteUserDocument',
    params: { fileid }
  });
}

export const userRequestConfig = new UserRequestConfig();
