import { useQuery } from 'react-query';
import { TableParams } from 'components/vehicle-offers-list-component/vehicle-offers-list-component.props';
import {
  getCompanyReferenceVehicleList,
  getCompanyReferenceVehicleListFilterData,
  getGlobalVehicleListAsync,
  getReconfigurationsList,
  getReconfigurationsListFilterData,
  getReferenceVehicleList,
  getReferenceVehicleListFilterData
} from 'services/get-vehicle-list';
import { useDispatch } from 'react-redux';
import { modifyFilterDataResponse, modifyResponse } from 'components/vehicle-offers-list-component/utils';
import { isEmpty } from 'lodash';
import { ConfigurationTypes, ReactQueryKeys } from 'common/enums';
import { CONFIGURATION_TYPES } from '../../constants';

export const useVehicleListQuery = ({
  tableParams,
  basketId,
  isMitarbeiterkonfigurationenPage,
  isMeineKonfigurationenPage,
  isReferenzfahrzeugePage,
  configurationType
}: useVehicleListQueryProps) => {
  const dispatch = useDispatch();

  const isReconfigurationsListEnabled =
    configurationType !== ConfigurationTypes.Global &&
    (isMitarbeiterkonfigurationenPage || isMeineKonfigurationenPage) &&
    (isMitarbeiterkonfigurationenPage ? true : !!basketId) &&
    !isEmpty(tableParams.search);

  const isCompanyReferenceVehicleListEnabled =
    configurationType !== ConfigurationTypes.Global &&
    isReferenzfahrzeugePage &&
    !!basketId &&
    !isEmpty(tableParams.search);

  const isReferenceVehicleListEnabled =
    configurationType !== ConfigurationTypes.Global &&
    !(isMitarbeiterkonfigurationenPage || isMeineKonfigurationenPage) &&
    !isReferenzfahrzeugePage &&
    !!basketId &&
    !isEmpty(tableParams.search);

  const configurationTypeCode =
    configurationType === ConfigurationTypes.Reference
      ? CONFIGURATION_TYPES.referenceVehicle.value
      : configurationType === ConfigurationTypes.BulkOrders
      ? CONFIGURATION_TYPES.bulkOrder.value
      : undefined;

  const reconfigurationsListQuery = useQuery(
    [ReactQueryKeys.ReconfigurationsList, tableParams, basketId],
    () => getReconfigurationsList(tableParams, basketId),
    {
      enabled: isReconfigurationsListEnabled,
      select: res => modifyResponse(res)
    }
  );

  const reconfigurationsListFilterDataQuery = useQuery(
    [ReactQueryKeys.ReconfigurationsListFilterData, basketId],
    () => getReconfigurationsListFilterData(basketId),
    {
      enabled: isReconfigurationsListEnabled,
      select: res => modifyFilterDataResponse(res, dispatch)
    }
  );

  const companyReferenceVehicleListQuery = useQuery(
    [ReactQueryKeys.CompanyReferenceVehicleList, tableParams, basketId],
    () => getCompanyReferenceVehicleList(tableParams, basketId),
    {
      enabled: isCompanyReferenceVehicleListEnabled,
      select: res => modifyResponse(res)
    }
  );

  const companyReferenceVehicleListFilterDataQuery = useQuery(
    [ReactQueryKeys.CompanyReferenceVehicleListFilterData, basketId],
    () => getCompanyReferenceVehicleListFilterData(basketId),
    {
      enabled: isCompanyReferenceVehicleListEnabled,
      select: res => modifyFilterDataResponse(res, dispatch)
    }
  );

  const referenceVehicleListQuery = useQuery(
    [ReactQueryKeys.ReferenceVehicleList, tableParams, basketId, configurationTypeCode],
    () => getReferenceVehicleList(tableParams, basketId, configurationTypeCode),
    {
      enabled: isReferenceVehicleListEnabled,
      select: res => modifyResponse(res)
    }
  );

  const referenceVehicleListFilterDataQuery = useQuery(
    [ReactQueryKeys.ReferenceVehicleListFilterData, basketId, configurationTypeCode],
    () => getReferenceVehicleListFilterData(basketId, configurationTypeCode),
    {
      enabled: isReferenceVehicleListEnabled,
      select: res => modifyFilterDataResponse(res, dispatch)
    }
  );

  const globalVehicleListQuery = useQuery(
    [ReactQueryKeys.GlobalVehicleList, tableParams, basketId],
    () => getGlobalVehicleListAsync(tableParams, basketId),
    {
      enabled: configurationType === ConfigurationTypes.Global && !!basketId && !isEmpty(tableParams.search),
      select: res => modifyResponse(res)
    }
  );

  return {
    ...(configurationType === ConfigurationTypes.Global
      ? globalVehicleListQuery
      : isMeineKonfigurationenPage || isMitarbeiterkonfigurationenPage
      ? reconfigurationsListQuery
      : isReferenzfahrzeugePage
      ? companyReferenceVehicleListQuery
      : referenceVehicleListQuery)
  };
};

export interface useVehicleListQueryProps {
  tableParams: TableParams;
  basketId?: string;
  isMitarbeiterkonfigurationenPage: boolean;
  isMeineKonfigurationenPage: boolean;
  isReferenzfahrzeugePage: boolean;
  configurationType: ConfigurationTypes | null;
}
