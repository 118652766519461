import { VehicleStateCodeLabel, VehicleStatusesLabel } from 'pages/my-vehicles/my-vehicles.props';

export const statusColorMapping = {
  [VehicleStatusesLabel.Ordered]: 'green',
  [VehicleStatusesLabel.InOperation]: 'green',
  [VehicleStatusesLabel.ControlRequested]: 'darkGold',
  [VehicleStatusesLabel.InControl]: 'darkGold',
  [VehicleStatusesLabel.Controlled]: 'grey600',
  [VehicleStateCodeLabel.Inactive]: 'grey300',
  [VehicleStateCodeLabel.Active]: 'green'
};
