import { ResponseModel, UserCompany } from 'common/interfaces';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { RequestStatuses } from '../../constants';
import { ReactQueryKeys } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { companyRequestConfig } from 'request-config/company/company.request-config';

interface AllCompanyData {
  currentAccount: UserCompany;
  parentAccount: UserCompany | null;
  topParentAccount: UserCompany | null;
  childAccounts: UserCompany[];
}

export enum CompanyTypes {
  Parent = 'parent',
  Middle = 'middle',
  Child = 'child'
}

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<AllCompanyData>>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data;
  }

  return undefined;
};

export const useIsParentCompany = (companyId: string, isShowLoading: boolean = false) => {
  const { fetch } = useFetch<ResponseModel<AllCompanyData>>({
    isShowLoading,
    isShowErrorSnackbar: false,
    ...companyRequestConfig.getAllCompany(companyId)
  });

  const { data } = useQuery([ReactQueryKeys.GetAllCompany, companyId], () => fetch(), {
    select: modifyResponse,
    enabled: !!companyId
  });

  if (data === undefined) {
    return {};
  }

  const parentCompanyId = data.topParentAccount?.id || data.parentAccount?.id;
  const companyType = !parentCompanyId
    ? CompanyTypes.Parent
    : !!data.childAccounts.length
    ? CompanyTypes.Middle
    : CompanyTypes.Child;
  const isParentCompany = companyType === CompanyTypes.Parent;
  const isMiddleCompany = companyType === CompanyTypes.Middle;
  const isChildCompany = companyType === CompanyTypes.Child;
  const hasChildAccounts = !!data.childAccounts.length;

  return {
    allCompanyQuery: data,
    companyType,
    isParentCompany,
    isMiddleCompany,
    isChildCompany,
    parentCompanyId,
    hasChildAccounts
  };
};
