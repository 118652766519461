import React from 'react';
import { useReplacementVehicleOrderingButtons } from './use-replacement-vehicle-ordering-buttons';
import WidgetButton from '../../../components/widget-button';
import { Flex } from 'common/common-components.styled';

const ReplacementVehicleOrderingButtons = () => {
  const {
    button: { title, disabled, action }
  } = useReplacementVehicleOrderingButtons();

  return (
    <Flex justify='flex-end'>
      <WidgetButton title={title} handleClick={action} disabled={disabled} />
    </Flex>
  );
};

export default ReplacementVehicleOrderingButtons;
