import React, { useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { getIcon } from 'components/download-documents-component/download-documents-component';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { getFileName } from 'utils/file/get-file-name';
import ThreeStateCheckbox from 'components/three-state-checkbox';
import { SortingDocumentsTabsContext } from 'components/documents/sorting-tabs-documents/sorting-documents-tabs.context';
import { DocumentRowFileNameProps } from './file-name.props';
import { FileBriefInfoMobile } from './file-brief-info-mobile/file-brief-info-mobile';
import useWindowSize from 'hooks/use-window-size';

export const DocumentRowFileName = ({
  sectionName,
  isShowThreeStateCheckbox,
  changeCheckboxHandler,
  ...fileProps
}: DocumentRowFileNameProps) => {
  const { filePath } = fileProps;

  const { preparedFilesForDownload } = useContext(SortingDocumentsTabsContext);
  const isCheckedThreeStateCheckbox = preparedFilesForDownload[sectionName]?.some(
    preparedFile => preparedFile.filePath === filePath
  );

  const { windowWidth } = useWindowSize(500);

  const fileName = getFileName(filePath);

  return (
    <Flex width='100%' align='center'>
      {isShowThreeStateCheckbox && (
        <ThreeStateCheckbox
          checked={isCheckedThreeStateCheckbox}
          onChange={changeCheckboxHandler}
          indeterminate={false}
        />
      )}

      <Flex align='center' width='100%' left='10'>
        <Flex>{getIcon(fileName)}</Flex>

        <Flex direction='column' left='15' width={windowWidth <= 550 ? '70%' : '80%'}>
          <EllipsisWithTooltip tooltip={fileName}>
            <Text>{fileName}</Text>
          </EllipsisWithTooltip>

          <FileBriefInfoMobile {...fileProps} />
        </Flex>
      </Flex>
    </Flex>
  );
};
