import { crmDataTypes } from '../../../../constants';

export const setAdditionalValues = (values, userCompany) => {
  values = {
    ...values,
    new_finanzrate_dienstleister: {
      attributeTypeCode: crmDataTypes.Lookup,
      value: userCompany
    },
    new_finanzrate_gebucht: {
      attributeTypeCode: crmDataTypes.Boolean,
      value: true
    },
    new_finanzrate_inleasing: {
      attributeTypeCode: crmDataTypes.Boolean,
      value: true
    },
    new_finanzrate_zahlungsrhythmus: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000000
    },
    new_finanzrate_leistungserbringer: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000001
    },
    new_finanzrate_herkunft: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000001
    },
    new_wartungundverschlei_dienstleister: {
      attributeTypeCode: crmDataTypes.Lookup,
      value: userCompany
    },
    new_wartungundverschlei: {
      attributeTypeCode: crmDataTypes.Boolean,
      value: true
    },
    new_wartungundverschlei_inleasing: {
      attributeTypeCode: crmDataTypes.Boolean,
      value: true
    },
    new_wartungundverschlei_zahlungsrhythmus: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000000
    },
    new_wartungundverschlei_leistungserbringer: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000001
    },
    new_wartungundverschlei_herkunft: {
      attributeTypeCode: crmDataTypes.Picklist,
      value: 100000001
    }
  };
  if (values.new_versicherung_mobexo.value) {
    values = {
      ...values,
      new_versicherung_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_versicherung_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_versicherung_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_versicherung_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000002
      },
      new_versicherung_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.new_kfz_steuer_mobexo.value) {
    values = {
      ...values,
      new_kfzsteuer_dienstleister: {
        attributeTypeCode: crmDataTypes.Lookup,
        value: userCompany
      },
      new_kfzsteuer_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_kfzsteuer_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_kfzsteuer_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },

      new_kfzsteuer_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_kfzsteuer_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.uds_gap.value) {
    values = {
      ...values,
      new_gap_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_gap_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_gap_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },
      new_gap_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_gap_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.new_winterreifen_mobexo.value) {
    values = {
      ...values,
      new_winterreifen_dienstleister: {
        attributeTypeCode: crmDataTypes.Lookup,
        value: userCompany
      },
      new_winterreifen_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_winterreifen_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_winterreifen_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },
      new_winterreifen_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_winterreifen_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.new_gez_mobexo.value) {
    values = {
      ...values,
      new_gez_dienstleister: {
        attributeTypeCode: crmDataTypes.Lookup,
        value: userCompany
      },
      new_gez_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_gez_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_gez_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },
      new_gez_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_gez_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.new_sommerreifen_mobexo.value) {
    values = {
      ...values,
      new_reifenersatz_dienstleister: {
        attributeTypeCode: crmDataTypes.Lookup,
        value: userCompany
      },
      new_reifenersatz_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_reifenersatz_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_reifenersatz_zahlungsrhythmus: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },
      new_reifenersatz_leistungserbringer: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_reifenersatz_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }
  if (values.uds_radereinlagerungundwechsel.value) {
    values = {
      ...values,
      new_rdereinlagerungundwechsel_dienstleister: {
        attributeTypeCode: crmDataTypes.Lookup,
        value: userCompany
      },
      new_rdereinlagerungundwechsel_gebucht: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_rdereinlagerungundwechsel_inleasing: {
        attributeTypeCode: crmDataTypes.Boolean,
        value: true
      },
      new_rdereinlagerungundwechsel_zahlungsrhythmu: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000000
      },
      new_rdereinlagerungundwechsel_leistungserbrin: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      },
      new_rdereinlagerungundwechsel_herkunft: {
        attributeTypeCode: crmDataTypes.Picklist,
        value: 100000001
      }
    };
  }

  return values;
};
