import { useSubmitDamageParts } from './use-submit-damage-parts';
import { useSubmitDamageCommonForm } from './use-submit-damage-common-form';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { PAGES } from 'common/enums';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

export const useSubmitDamageEditForm = () => {
  const formType = useTypedSelector(state => state.damageDetail.editForm.type);

  const location = useLocation();

  const isGetDamageByIdQueryEnabled = () => {
    return location.pathname.includes(`${PAGES.DAMAGE_DETAILS}/`) && !!location.search;
  };

  const { submitDamagePartsHandler } = useSubmitDamageParts(isGetDamageByIdQueryEnabled());
  const { submitDamageCommonHandler } = useSubmitDamageCommonForm(isGetDamageByIdQueryEnabled());

  const getSubmitHandler = useCallback(() => {
    if (formType === 'damagePart') {
      return submitDamagePartsHandler;
    }

    return submitDamageCommonHandler;
  }, [formType, submitDamageCommonHandler, submitDamagePartsHandler]);

  return { submitDamageEditFormHandler: getSubmitHandler() };
};
