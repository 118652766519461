import React from 'react';
import { Flex } from 'common/common-components.styled';
import { RepairStatusWidget } from 'components/widgets/repair-status/repair-status';
import { RepairPartnerWidgetHeader } from './components/header';
import {
  RepairPartnerStageCode,
  RepairPartnerStatusCode,
  RepairStatusEnum
} from 'pages/repair-status/components/repair-status-item/repair-status-item.props';
import { useDashboardRepairStatusQuery } from 'hooks/react-query/dashboard/use-repair-status/use-repair-status';
import { useCloseOnClickOutside } from './hooks/use-close-on-click-outside/use-close-on-click-outside';

export const RepairPartnerWidgetContainer = () => {
  const { containerRef, isExtended, setIsExtended } = useCloseOnClickOutside();
  const { repairStatus, isFetching } = useDashboardRepairStatusQuery({ fetch: { isShowLoading: false } });

  const [singleIncident] = repairStatus.items || [];

  const isHideWidget =
    singleIncident?.Status === RepairStatusEnum.InvoiceSent ||
    singleIncident?.incidentstagecode_value === RepairPartnerStageCode.Closed ||
    singleIncident?.statecode_value === RepairPartnerStatusCode.Inactive;

  if (!singleIncident || isHideWidget) {
    return null;
  }

  return (
    <section ref={containerRef}>
      <RepairStatusWidget
        data={singleIncident}
        isLoading={isFetching}
        isExtended={isExtended}
        setIsExtended={setIsExtended}
        headerComponent={<RepairPartnerWidgetHeader />}
      />
    </section>
  );
};
