import cloneDeep from 'lodash/cloneDeep';
import { getFields } from 'components/form/form-view';
import { AnyObject } from 'common/interfaces';
import { getEntityById } from 'services/crm-entity-service';
import { setForm } from 'actions/app_action';
import { getUntouchedFields } from 'utils/get-untouched-fields';
import { useDispatch } from 'react-redux';
import { Dispatch, SetStateAction } from 'react';

const useFillResponsiblePerson = (
  responsiblePersonFields: AnyObject,
  setResponsiblePersonFields: Dispatch<SetStateAction<AnyObject>>,
  isPoolcar: boolean
) => {
  const dispatch = useDispatch();

  return (value: string | null) => {
    const newResponsiblePersonFields = cloneDeep(responsiblePersonFields);
    getFields(newResponsiblePersonFields, (field: AnyObject) => {
      field.disabled = !!value;
      field.validation = isPoolcar
        ? [
            {
              name: 'required'
            }
          ]
        : [];
    });
    setResponsiblePersonFields(newResponsiblePersonFields);

    if (value) {
      getEntityById({
        entityId: value,
        entityName: 'new_fuhrpark',
        columns: ['new_kstverantw']
      }).then(response => {
        const responsiblePersonId = response.data.data.attributes.new_kstverantw.attributeValue?.id;
        dispatch(
          setForm({
            name: 'uds_kstverantw',
            form: {
              values: {
                uds_kstverantw: responsiblePersonId || ''
              },
              errors: {},
              untouched: getUntouchedFields(newResponsiblePersonFields),
              isFormValid: true
            }
          })
        );
      });
    }
  };
};

export default useFillResponsiblePerson;
