import { useParams } from 'react-router-dom';
import { CarConfiguratorParams, CarConfiguratorUrlParams } from '../car-configurator.types';

const useUrlParams = (): CarConfiguratorUrlParams => {
  const { driver_id, brand_id, model_id, vehicle_id } = useParams<CarConfiguratorParams>();

  return { driverId: driver_id, brandId: brand_id, modelId: model_id, vehicleId: vehicle_id };
};

export default useUrlParams;
