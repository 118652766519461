import React, { Component, Fragment } from 'react';
import classes from './dashboard-item.module.scss';
import { Link } from 'react-router-dom';
import FahrzeugSelect from '../../../fahrzeug-select';
import { DownArrowIcon } from '../../../../components/icons/down-arrow-icon';
import { DashboardItemIds, PAGES } from '../../../../common/enums';
import { RequestStatuses, USER_ROLES } from '../../../../constants';
import { Button, Distance, Flex } from '../../../../common/common-components.styled';
import { UploadIcon } from '../../../icons/upload-icon';
import { PlusLargeIcon } from '../../../icons/plus-large-icon';
import ConfigurationFromPdf from '../../../configuration-from-pdf/configuration-from-pdf';
import { createVehicleOffer } from '../../../../services/fleet-vehicle-service';
import sharepointService from '../../../../services/sharepoint-service';

class DashboardItem extends Component {
  createVehicleOfferCallback = async (dataToSend, files, isAdditionalFieldsShown) => {
    this.props.closeAlert();
    this.props.showOverlay();
    let snackbarData = {
      type: 'success',
      message: isAdditionalFieldsShown ? 'Änderungen erfolgreich gespeichert.' : 'Fahrzeugkonfiguration hochgeladen.'
    };
    const response = await createVehicleOffer(dataToSend);
    if (response.data?.data) {
      const fileResponse = await sharepointService.uploadSharepointFile(
        files,
        'Hersteller-Konfiguration',
        response.data.data.fahrzeugangeboteId,
        'uds_fahrzeugangebote',
        null
      );
      if (fileResponse.data?.level !== RequestStatuses.Success) {
        snackbarData = {
          type: 'error',
          message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
        };
      }
    } else {
      snackbarData = {
        type: 'error',
        message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
      };
    }
    this.props.hideOverlay();
    this.props.setSnackbar({
      ...snackbarData,
      isOpen: true
    });
  };

  openNewConfigurationDialog = () => {
    const alertData = {
      title: 'Herstellerkonfiguration hochladen',
      children: (
        <ConfigurationFromPdf
          createVehicleOffer={(data, files, isAdditionalFieldsShown) =>
            this.createVehicleOfferCallback(data, files, isAdditionalFieldsShown)
          }
        />
      ),
      buttons: [
        {
          type: 'cancel',
          hide: true,
          title: 'Abbrechen',
          action: this.props.closeAlert
        }
      ]
    };

    this.props.openAlert(alertData);
  };

  render() {
    const { item, actions, user } = this.props;
    let alertData = {};
    const isDriver = user.mainRole?.id === USER_ROLES.driver.id;

    switch (item.id) {
      case DashboardItemIds.MyVehicle:
        alertData = {
          title: 'Hauptfahrzeug auswählen',
          children: (
            <FahrzeugSelect
              title={'Fahrzeug'}
              options={item.secondaryCars}
              onChange={e => actions.onChangeMainVehicle(e, item.id)}
            />
          ),
          buttons: [
            {
              type: 'cancel',
              title: 'Abbrechen',
              action: () => actions.closeAlert(item.id)
            },
            {
              type: 'submit',
              title: 'Speichern',
              action: () => actions.setMainVehicle(item.id)
            }
          ]
        };
        break;
      case DashboardItemIds.NewVehicle:
        alertData = {
          title: 'Vorgang auswählen',
          children: (
            <FahrzeugSelect
              title={'Vorgang'}
              options={item.secondaryCars}
              onChange={e => actions.onChangeMainVehicle(e, item.id)}
            />
          ),
          buttons: [
            {
              type: 'cancel',
              title: 'Abbrechen',
              action: () => actions.closeAlert(item.id)
            },
            {
              type: 'submit',
              title: 'Speichern',
              action: () => actions.setMainVehicle(item.id)
            }
          ]
        };
        break;
      default:
        break;
    }

    return (
      <div className={classes.dashboard_item}>
        <div className={classes.header}>
          <img src={item.imgSrc} alt='item_icon' />
          <div className={classes.text}>
            <h5>{item.title}</h5>
            <p>{item.subtitle}</p>
          </div>
        </div>

        {item.expandPanels.map(expandPanel => (
          <div
            key={expandPanel.title}
            className={expandPanel.expand ? `${classes.expand_item} ${classes.open}` : classes.expand_item}
          >
            <div
              onClick={() => actions.toggleExpandItem(item.id, expandPanel.title)}
              className={classes.expand_item_header}
            >
              <DownArrowIcon className={classes.icon} />
              <h6>{expandPanel.title}</h6>
            </div>
            <div className={classes.expand_item_body}>
              {expandPanel.bodyItems.map(bodyItem => {
                switch (bodyItem.name) {
                  case 'Hauptfahrzeug einrichten':
                  case 'Dies ist nicht der aktuelle Vorgang.':
                    return (
                      <div className={classes.choose_vehicle} key={bodyItem.name}>
                        <p onClick={() => actions.openAlert(alertData)} className={classes.name}>
                          <DownArrowIcon className={classes.icon} />
                          {bodyItem.name}
                        </p>
                      </div>
                    );

                  case 'Konfigurationen anzeigen':
                    return (
                      <div className={classes.choose_vehicle} key={bodyItem.name}>
                        <p className={classes.name}>
                          <Link to={PAGES.CONFIGURATIONS}>
                            <DownArrowIcon className={classes.icon} />
                            {bodyItem.name}
                          </Link>
                        </p>
                      </div>
                    );

                  case 'empty':
                    return (
                      <Fragment key={bodyItem.name}>
                        <p className={classes.value}>{bodyItem.value}</p>
                      </Fragment>
                    );

                  default:
                    return (
                      <Fragment key={bodyItem.name}>
                        <p className={classes.name}>{bodyItem.name}</p>
                        <p className={classes.value}>{bodyItem.value}</p>
                      </Fragment>
                    );
                }
              })}
            </div>
          </div>
        ))}

        {item.id === DashboardItemIds.NewVehicle && isDriver && (
          <div className={classes.driver_buttons_container}>
            <Link to={PAGES.NEW_CONFIGURATIONS}>
              <Button className='w-100'>
                <Flex align='center'>
                  <PlusLargeIcon />
                  <Distance side='10' />
                  Fahrzeug konfigurieren
                </Flex>
              </Button>
            </Link>
            <Button className='w-100' top='5' secondary onClick={this.openNewConfigurationDialog}>
              <Flex align='center'>
                <UploadIcon />
                <Distance side='10' />
                Konfiguration hochladen
              </Flex>
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardItem;
