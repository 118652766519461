import { DefaultTheme } from 'styled-components';
import { StringObject } from './interfaces.js';
import { createTheme } from '@mui/material';

export interface StyledTheme {
  colors: StringObject;
  media: StringObject;
}

export const mainTheme: DefaultTheme = {
  colors: {
    extraLightBlue: '#E5F7FF',
    lightBlue: '#ACD2E5',
    whiteBlue: '#E6F7FF',
    midBlue: '#8FAFBF',
    blue: '#335566',
    borderBlue: '#005E6E',
    darkBlue: '#102F3F',
    blackPearl: '#002633',
    blackPearl10: '#E5E9EB',
    blackPearl30: '#B2BEC2',
    extraLightGold: '#FFF9E5',
    lightGold: '#FFEFBF',
    gold: '#FFBF00',
    darkGold: '#FF9700',
    extraLightPink: '#FFE5E5',
    lightPink: '#FFBFBF',
    pink: '#BF0040',
    darkPink: '#7F002A',
    extraLightGreen: '#E6FFE6',
    lightGreen: '#BFFFBF',
    green: '#30BF30',
    darkGreen: '#208020',
    emeraldGreen: '#009383',
    white: '#FFF',
    grey25: '#FAFAFA',
    grey50: '#F0F1F2',
    grey100: '#E0E3E5',
    grey200: '#C6CACC',
    grey250: '#b4b4b4',
    grey300: '#ABB0B2',
    grey400: '#919699',
    grey500: '#777C7F',
    grey600: '#5F6466',
    grey700: '#464A4C',
    grey800: '#2E3133',
    grey900: '#171819',
    black: '#000',
    borderItem: '#22273A'
  },
  media: {
    tablet: '551px',
    tabletHorizontal: '768px',
    laptop: '993px',
    wideLaptop: '1200px',
    desktop: '1579px'
  }
};

export const materialTheme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif"
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#777777',
          letterSpacing: '0.04em',
          lineHeight: '18px',
          fontSize: '12px'
        }
      }
    }
  }
});
