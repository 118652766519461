import { useEffect, useState } from 'react';
import { FormFieldModel } from 'common/interfaces';
import { driverFormFields } from '../select-driver-component/driver.form-fields';
import { useGetDriverWithCarQuery } from 'hooks/react-query/driver/get-drivers-with-car/use-get-drivers-with-car';
import cloneDeep from 'lodash/cloneDeep';

const useDriverFields = () => {
  const [driverFields, setDriverFields] = useState<FormFieldModel[]>(driverFormFields);

  const { getDriverWithCarQuery } = useGetDriverWithCarQuery(undefined, undefined, {
    fetch: { isShowLoading: false }
  });

  useEffect(() => {
    if (getDriverWithCarQuery.length) {
      const newDriverFields = cloneDeep(driverFields);
      const driverField = newDriverFields.find(field => field.name === 'driver');
      if (driverField) {
        driverField.options = getDriverWithCarQuery.map(option => ({
          label: option.name || '',
          value: option.id || ''
        }));
      }
      setDriverFields(newDriverFields);
    }
  }, [getDriverWithCarQuery.length]);

  return { driverFields };
};

export default useDriverFields;
