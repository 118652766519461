import { setOpenVideoTutorials, toggleChatbot, toggleGreetingSideBar, toggleSidebarType } from 'actions/app_action';
import classNames from 'classnames';
import { SidebarType } from 'common/enums';
import { CloseIcon } from 'components/icons/close-icon';
import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './right-sidebar.module.scss';
import { RightSidebarProps } from './right-sidebar.props';

export const RightSidebar = ({ title, children, isOpen, className, isChatBot, ...restProps }: RightSidebarProps) => {
  const containerClassName = classNames(classes.container, { [classes.open]: isOpen });
  const dispatch = useDispatch();

  const openGreetingSidebar = () => {
    dispatch(toggleChatbot(false));
    dispatch(toggleGreetingSideBar(true));
    dispatch(toggleSidebarType(SidebarType.Greeting));
  };

  const closeSidebar = () => {
    dispatch(toggleChatbot(false));
    dispatch(toggleGreetingSideBar(false));
    dispatch(setOpenVideoTutorials(false));
  };

  return (
    <div className={classNames(containerClassName, className)} {...restProps}>
      <div className={classes.header}>
        {isChatBot ? (
          <div className={classes.header_wrapper}>
            <div className={classes.bot_header}>
              <img
                onClick={openGreetingSidebar}
                className={classes.back_icon}
                src='/assets/images/chevron-left.svg'
                alt='chevron-left'
              />
              <img className={classes.bot_avatar} src='/assets/images/chatbot_image.png' alt='bot avatar' />
              <p className={classes.bot_name}>Moe</p>
            </div>

            <div className={classes.close_button_wrapper}>
              <CloseIcon onClick={closeSidebar} color={'#fff'} width={'20px'} height={'20px'} />
            </div>
          </div>
        ) : (
          <div className={classes.header_wrapper}>
            <h3>{title}</h3>
            <div className={classes.close_button_wrapper}>
              <CloseIcon onClick={closeSidebar} color={'#fff'} width={'20px'} height={'20px'} />
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
