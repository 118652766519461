import { SET_FLATRATE, CHANGE_FLATRATE_SELECTION, SET_FINANZRATE, RESET_FLATRATE } from '../actions/flaterate_action';

const INITIAL_STATE = {
  insurance_checkbox: false,
  tax_checkbox: false,
  gez_checkbox: false,
  winterWheelsStorage_checkbox: false,
  wheelStorageReplacement_checkbox: false,
  tireReplacement_checkbox: false,
  maintenance_checkbox: false,
  wkrleasingenthalten_checkbox: false,
  finanzrate: 0,
  maintenance: 0,
  tireReplacement: 0,
  winterWheelsStorage: 0,
  wheelStorageReplacement: 0,
  insurance: 0,
  tax: 0,
  gez: 0,
  marge: 0,
  mobexoContribution: 0,
  mileagePerYear: 0,
  period: 0,
  max_nettolistenpreis: 0
};

export default function flaterateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FINANZRATE:
      return { ...state, finanzrate: action.payload };
    case SET_FLATRATE:
      return { ...state, ...action.payload };
    case CHANGE_FLATRATE_SELECTION:
      return { ...state, [action.payload.name]: action.payload.checked, price: action.payload.price };
    case RESET_FLATRATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
