import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompanyPackage, getAvailablePackages } from 'services/crm-checkout-service';
import {
  toggleModal,
  setIsSiteAvailable,
  setIsPackageExist,
  setIspackagesFetched,
  setShowExpiredTrialModal
} from '../../actions/app_action';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInHours from 'date-fns/differenceInHours';
import { MODALS, MODAL_NAMES, RequestStatuses, USER_ROLES } from '../../constants';
import { AnyObject } from 'common/interfaces';
import Checkout from 'components/checkout-component/checkout-component';
import { useTypedSelector } from '../use-typed-selector';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { isMobileWidth } from '../../constants';
import { PAGES } from 'common/enums';

export const useBlockerModal = (currentURL: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSiteAvailable = useTypedSelector(state => state.app.isSiteAvailable);
  const user = useTypedSelector(state => state['app'].user);
  const showGuidedTourFirst = useTypedSelector(state => state.app.showGuidedTourFirst);

  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isAdmin = useIsUserHaveRole(USER_ROLES.administrator.id);
  const isHaveAccess = isAdmin || isDriver || isFleetManager;

  const closePopup = useCallback(() => {
    dispatch(toggleModal(MODALS.checkout, null));
  }, [dispatch]);

  const openCheckoutModal = useCallback(
    (actualDate: Date, trialEndDate: Date, availablePackagesData: AnyObject, currentPackageName: string) => {
      const getModalTitle = () => {
        if (isSiteAvailable) {
          const daysLeft = differenceInCalendarDays(trialEndDate, actualDate);
          if (daysLeft > 1) {
            return `Ihr Testzeitraum läuft in ${daysLeft} Tagen ab. Wählen Sie jetzt Ihr Paket.`;
          }
          return 'Ihr Testzeitraum läuft in 1 Tag ab. Wählen Sie jetzt Ihr Paket.';
        }
        return 'Ihr Testzeitraum ist Abgelaufen. Wählen Sie jetzt ihr Paket.';
      };

      const checkoutData = {
        title: getModalTitle(),
        isCloseAvailable: !isAfter(actualDate, trialEndDate),
        closePopup: closePopup,
        children: (
          <Checkout
            parameters={{
              closePopup: closePopup,
              description: `${
                availablePackagesData?.items?.length || '0'
              } Preispakete, die genau zu Ihrer Flotte passen.`,
              packagesData: availablePackagesData.items,
              companyTarifName: currentPackageName,
              isBenefitsExist: false
            }}
          />
        )
      };
      dispatch(toggleModal(MODALS.checkout, MODAL_NAMES.checkout, checkoutData));
    },
    [closePopup, isSiteAvailable, dispatch]
  );

  useEffect(() => {
    if (isHaveAccess) {
      const packageData = async () => {
        getCompanyPackage().then(response => {
          dispatch(setIspackagesFetched(response.data.level === RequestStatuses.Success));
          dispatch(
            setIsPackageExist(Boolean(response.data.data.companytarifname || response.data.data.futurecompanytarifid))
          );
          if (!response.data.data.companytarifname && !response.data.data.futurecompanytarifid) {
            const actualDate = parseISO(response.data.data.actualdate);
            const trialEndDate = parseISO(response.data.data.trialenddate);
            const lastShown = localStorage.getItem('popupShown')
              ? parseISO(JSON.parse(localStorage.getItem('popupShown') as string))
              : 0;
            const hoursPassed = differenceInHours(actualDate, lastShown);
            const loginCounter = response.data.data.loginCounter;

            const availableRoutes = [
              PAGES.PACKAGES,
              PAGES.DECISION_SUPPORT,
              PAGES.CLIENTS_FLEETMANAGER,
              PAGES.CREATE_OFFER
            ];

            let shouldRedirect = true;

            for (let i = 0; i < availableRoutes.length; i++) {
              if (location.pathname.includes(availableRoutes[i])) {
                shouldRedirect = false;
                break;
              }
            }

            if (isAfter(actualDate, trialEndDate)) {
              dispatch(setIsSiteAvailable(false));
              dispatch(setShowExpiredTrialModal(true));
              if (isDriver) {
                history.push(PAGES.NO_ACCESS);
              } else if (shouldRedirect) {
                history.push(PAGES.PACKAGES);
              }
            } else if (isDriver && currentURL === PAGES.NO_ACCESS) {
              history.push(PAGES.DASHBOARD);
            } else {
              dispatch(setIsSiteAvailable(true));
            }

            if (
              hoursPassed > 24 &&
              currentURL !== PAGES.PACKAGES &&
              currentURL !== PAGES.PACKAGE_PAGE &&
              isFleetManager &&
              !isAfter(actualDate, trialEndDate) &&
              !isMobileWidth &&
              !showGuidedTourFirst &&
              loginCounter > 1
            ) {
              getAvailablePackages().then(packages => {
                localStorage.setItem('popupShown', JSON.stringify(parseISO(response.data.data.actualdate)));
                openCheckoutModal(actualDate, trialEndDate, packages.data.data, response.data.data.companytarifname);
              });
            }
          } else {
            if (!isSiteAvailable) {
              dispatch(setIsSiteAvailable(true));
            }
          }
        });
      };
      packageData();
    }
  }, [
    user.id,
    isFleetManager,
    currentURL,
    dispatch,
    history,
    isSiteAvailable,
    isDriver,
    openCheckoutModal,
    showGuidedTourFirst
  ]);
};
