import React from 'react';
import PropTypes from 'prop-types';
import toMoney from '../../../utils/toMoney';

function Price({ price, suffix }) {
  return (
    <>
      {toMoney(price)} {suffix}
    </>
  );
}

Price.defaultProps = {
  price: '0'
};

Price.propTypes = {
  price: PropTypes.number,
  suffix: PropTypes.string
};

export default Price;
