import React from 'react';
import { IconProps } from 'common/interfaces';

export const UploadIcon = ({ width = '10', height = '17', color = '#335566', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 10 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.64526 15.9337C9.64526 16.3845 9.27978 16.75 8.82894 16.75L1.16629 16.75C0.715443 16.75 0.349961 16.3845 0.349961 15.9337C0.349961 15.4829 0.715443 15.1174 1.16629 15.1174L8.82894 15.1174C9.27978 15.1174 9.64526 15.4829 9.64526 15.9337Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99764 12.1174C4.54679 12.1174 4.18131 11.7519 4.18131 11.3011L4.18131 1.72276C4.18131 1.27191 4.54679 0.906434 4.99764 0.906434C5.44848 0.906434 5.81397 1.27191 5.81397 1.72276L5.81396 11.3011C5.81396 11.7519 5.44848 12.1174 4.99764 12.1174Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.708811 6.01173C0.390016 5.69293 0.390016 5.17606 0.708812 4.85727L4.42041 1.14568C4.7392 0.82688 5.25607 0.82688 5.57487 1.14568C5.89366 1.46447 5.89366 1.98134 5.57487 2.30014L1.86327 6.01173C1.54448 6.33052 1.02761 6.33052 0.708811 6.01173Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.28642 6.01173C8.96762 6.33052 8.45075 6.33052 8.13196 6.01173L4.42036 2.30013C4.10157 1.98134 4.10157 1.46447 4.42036 1.14567C4.73916 0.826879 5.25603 0.826879 5.57482 1.14567L9.28642 4.85727C9.60521 5.17606 9.60521 5.69293 9.28642 6.01173Z'
        fill={color}
      />
    </svg>
  );
};
