import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { ApiResponse } from 'common/interfaces';
import { RequestStatuses } from '../../../constants';
import { MimeTypes, ReactQueryKeys } from 'common/enums';
import { letterModalRequestConfig } from 'request-config/letter-modal/letter-modal.request-config';

interface LetterResponse {
  file: {
    fileContent: string;
    fileName: string;
    mime: string;
  };
  fileContent: {
    from: string;
    to: string;
    date: string;
    subject: string;
    htmlContent: string | null;
    attachments: {
      name: string;
      content: string;
    }[];
  };
}

interface TransformedLetterResponse {
  file: {
    fileContent: string;
    fileName: string;
    mime: string;
  };
  fileContent: {
    from: string;
    to: string;
    date: string;
    subject: string;
    htmlContent: string | null;
    attachments: File[];
  };
}

const convertFile = (file: { name: string; content: string }) => {
  const binaryString = window.atob(file.content);
  const fileMime = file.name.split('.')[1].toLocaleUpperCase() as keyof typeof MimeTypes;
  const type = MimeTypes[fileMime];

  const binaryLen = binaryString.length;

  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type });
  const attachedFile = new File([blob], file.name, { type });

  return attachedFile;
};

const modifyResponse = ({ data }: ApiResponse<LetterResponse>) => {
  const { data: letterAssets } = data;

  if (data.level === RequestStatuses.Success) {
    return {
      ...letterAssets,
      fileContent: {
        ...letterAssets.fileContent,
        attachments: letterAssets.fileContent.attachments?.map(
          (attachment: { name: string; content: string }) => convertFile(attachment) || []
        )
      }
    };
  }
};

export const useLetter = (fileName: string, fileId: string) => {
  const { fetch } = useFetch({
    loadingKey: 'email',
    isShowLoading: false,
    ...letterModalRequestConfig.getLetter({ fileName, fileId })
  });

  const { data: email, isLoading } = useQuery<
    ApiResponse<LetterResponse>,
    unknown,
    TransformedLetterResponse | undefined
  >([ReactQueryKeys.LetterModal, fileId, fileName], fetch, {
    select: modifyResponse,
    enabled: Boolean(fileName && fileId)
  });

  return { email, isLoading };
};
