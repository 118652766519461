import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../../../constants';
import LeasingContract from '../../../leasing-contract/leasing-contract';
import { Text } from 'common/common-components.styled';
import { CrmLookupResponse } from 'common/interfaces';
import { setLeaseExtensionPhase, setLeaseExtensionPhaseUploadFile } from 'components/aufgaben-component/utils';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { RequestStatuses } from '../../../../../../constants';
import {
  LeaseExtentionPhases,
  UpdatePhaseProps,
  SpecificPhaseInfoProps
} from 'components/aufgaben-component/task.prop';
import { FileProps } from 'pages/vehicle-return-page/vehicle-return-page.props';
import LeaseNotExtended from '../../../leasing-not-extended/leasing-not-extended';
import LeaseExtensionOffer from '../../../lease-extension-offer/lease-extension-offer';
import ExtendLeaseAgreement from '../../../extend-lease-agreement/extend-lease-agreement';
import TacitlyExtendLeaseAgreement from '../../../tacitly-extend-lease-agreement/tacitly-extend-lease-agreement';
import OfferConfirmation from '../../../offer-confirmation/offer-confirmation';
import ExtendWithDate from '../../../extend-with-date/extend-with-date';
import { useOverlay } from 'hooks';

interface LeaseExtensionActionsProps {
  plateNumber: CrmLookupResponse;
  currentPhase: number;
  refresh?: () => void;
  leaseExtensionId: string;
  taskId: string;
  phaseInfo: SpecificPhaseInfoProps;
  files: FileProps[];
  leaseEndDate: string;
  // eslint-disable-next-line no-unused-vars
  setIsMenuOpen?: (isMenuOpen: boolean) => void;
}

export const useLeaseExtensionActions = ({
  plateNumber,
  setIsMenuOpen,
  currentPhase,
  refresh,
  leaseExtensionId,
  taskId,
  phaseInfo,
  files,
  leaseEndDate
}: LeaseExtensionActionsProps) => {
  const dispatch = useDispatch();

  const { showRequestSnackbar } = useRequestSnackbar();
  const [showOverlay, hideOverlay] = useOverlay();

  const closeAlert = () => {
    setIsMenuOpen && setIsMenuOpen(false);
    dispatch(toggleModal(MODALS.alert, null));
  };

  const handleUpdatePhase = ({
    nextPhase,
    successMessage,
    silentExtend,
    mileage,
    comment,
    months,
    silentExtendDate,
    currentMileage
  }: UpdatePhaseProps) => {
    closeAlert();
    showOverlay();
    setLeaseExtensionPhase({
      data: {
        FahrzeugId: plateNumber.attributeValue!.id,
        LvaId: leaseExtensionId,
        NewPhaseNo: nextPhase,
        PrevPhaseNo: currentPhase,
        TaskId: taskId,
        Mileage: mileage,
        Comment: comment,
        HiddenExtend: silentExtend,
        Months: months,
        HiddenExtendDate: silentExtendDate,
        RealMileage: currentMileage
      }
    }).then(response => {
      const message = response.data.messages[0]?.message;

      showRequestSnackbar(
        response.data.level === RequestStatuses.Success,
        refresh,
        successMessage,
        message,
        7000,
        response.data.level === RequestStatuses.Warning,
        `Die Aufgabe wurde erstellt, aber es gibt Kommentare. Fehlermeldung: ${message}`,
        refresh
      );

      hideOverlay();
    });
  };

  const handleUpdatePhaseWithFile = (nextPhase: number, successMessage: string, files: File[], comment?: string) => {
    closeAlert();
    showOverlay();
    setLeaseExtensionPhaseUploadFile({
      FahrzeugId: plateNumber.attributeValue!.id,
      LvaId: leaseExtensionId,
      files: files,
      NewPhaseNo: nextPhase,
      PrevPhaseNo: currentPhase,
      TaskId: taskId,
      Comment: comment
    }).then(response => {
      const message = response.data.messages[0]?.message;

      showRequestSnackbar(
        response.data.level === RequestStatuses.Success,
        refresh,
        successMessage,
        message,
        7000,
        response.data.level === RequestStatuses.Warning,
        `Die Aufgabe wurde erstellt, aber es gibt Kommentare. Fehlermeldung: ${message}`,
        refresh
      );

      hideOverlay();
    });
  };

  const openLeasingContractModal = (mileagePA?: number | null) => {
    setIsMenuOpen && setIsMenuOpen(true);
    const alertData = {
      title: 'Leasingvertrag anpassen',
      children: (
        <LeasingContract
          onCancel={closeAlert}
          onSubmit={handleUpdatePhase}
          mileage={mileagePA}
          plateNumber={plateNumber.attributeValue?.name}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const updateToFifthPhase = () => {
    handleUpdatePhase({
      nextPhase: LeaseExtentionPhases.Phase5,
      successMessage: SUCCESS_MESSAGE_NO_REPLACEMENT
    });
  };

  const openDoNotExtendModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);
    const alertData = {
      title: 'Bestätigung',
      children: (
        <Text>
          {`Bist du sicher, dass du den Leasingvertrag für ${
            plateNumber.attributeValue?.name || '-'
            // eslint-disable-next-line max-len
          } nicht verlängern möchtest? Wenn ja, wird der Prozess der Fahrzeugrückgabe 8 Wochen vor dem Leasingenddatum gestartet.`}
        </Text>
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Bestätigen',
          action: updateToFifthPhase
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openCanNotBeExtendedModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Leasingvertrag verlängern',
      children: (
        <LeaseNotExtended
          plateNumber={plateNumber}
          onSubmit={comment => {
            handleUpdatePhase({
              nextPhase: LeaseExtentionPhases.Phase6,
              successMessage: SUCCESS_MESSAGE_CAN_NOT_REPLACE,
              comment: comment
            });
          }}
          onCancel={closeAlert}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openOfferForLeaseExtensionModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Angebot für Leasingverlängerung',
      children: (
        <LeaseExtensionOffer
          onCancel={closeAlert}
          onSubmit={(files: File[], comment) =>
            handleUpdatePhaseWithFile(LeaseExtentionPhases.Phase7, SUCCESS_MESSAGE_OFFER_SENT, files, comment)
          }
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openExtendLeaseAgreementModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Leasingvertrag verlängern',
      children: (
        <ExtendLeaseAgreement
          plateNumber={plateNumber}
          additionalMileage={phaseInfo.mileage}
          amountOfMonths={phaseInfo.months}
          comment={phaseInfo.comment}
          onClose={closeAlert}
          onSubmit={handleUpdatePhase}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openTacitlyExtendAgreementModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Leasingvertrag verlängern',
      children: (
        <TacitlyExtendLeaseAgreement
          plateNumber={plateNumber}
          onCancel={closeAlert}
          onSubmit={handleUpdatePhase}
          monthsAmount={phaseInfo.months}
          leaseEndDate={leaseEndDate}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openOfferConfirmationModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Leasingvertrag verlängern',
      children: <OfferConfirmation files={files} onClose={closeAlert} onSubmit={handleUpdatePhase} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openExtendWithDateModal = () => {
    setIsMenuOpen && setIsMenuOpen(true);

    const alertData = {
      title: 'Leasingvertrag verlängern',
      children: (
        <ExtendWithDate files={files} plateNumber={plateNumber} onCancel={closeAlert} onSubmit={handleUpdatePhase} />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openDeativateModal = () => {
    const changePhase = () => {
      closeAlert();
      showOverlay();
      setLeaseExtensionPhase({
        data: {
          FahrzeugId: plateNumber.attributeValue!.id,
          LvaId: leaseExtensionId,
          PrevPhaseNo: 1,
          NewPhaseNo: 20,
          TaskId: taskId
        }
      }).then(() => {
        refresh && refresh();
      });
    };

    const alertData = {
      title: 'Bestätigung',
      children: 'Sind Sie sicher, dass Sie die Funktion für dieses Fahrzeug deaktivieren möchten?',
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },

        {
          type: 'submit',
          title: 'Bestätigen',
          action: changePhase
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return {
    closeAlert,
    openLeasingContractModal,
    openDoNotExtendModal,
    openCanNotBeExtendedModal,
    openOfferForLeaseExtensionModal,
    openExtendLeaseAgreementModal,
    openTacitlyExtendAgreementModal,
    openOfferConfirmationModal,
    openExtendWithDateModal,
    openDeativateModal,
    updateToFifthPhase
  };
};

const SUCCESS_MESSAGE_NO_REPLACEMENT = 'Deine Entscheidung wurde erfolgreich gespeichert';
const SUCCESS_MESSAGE_CAN_NOT_REPLACE = 'Deine Entscheidung wurde erfolgreich gespeichert';
const SUCCESS_MESSAGE_OFFER_SENT = 'Unterschriebene Angebot wurde erfolgreich gesendet';
