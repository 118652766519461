import { ChangedFieldTypes, PrimaryEntityValues } from 'components/cost-centers-settings/cost-centers-settings.props';
import { useChangeCostCenterModals } from 'components/cost-centers-settings/hooks/use-change-cost-center-modals';
import { StateCodeValueEnum } from 'components/driving-license-control-component/driving-license-control.props';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { USER_ROLES } from '../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { VehicleDetailsEditFormSubmitCallbackParams } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form.props';
import { useVehicleDetailsSubmitForm } from '../use-submit-form/use-submit-form';
import { useVehicleDetailsCostCenterPrimaryEntity } from '../use-cost-center-primary-entity';

export const useVehicleDetailLocationFieldSubmit = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { openChangeLocationOrDriverModal } = useChangeCostCenterModals();
  const { costCenterPrimaryEntity } = useVehicleDetailsCostCenterPrimaryEntity();

  const availableForRoles = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const isActiveStateCode = fuhrpark?.statecode_value === StateCodeValueEnum.Active;

  const { onSubmit } = useVehicleDetailsSubmitForm();

  const locationSubmitCallback = ({ values, formFields }: VehicleDetailsEditFormSubmitCallbackParams) => {
    if (
      values.new_standortid &&
      costCenterPrimaryEntity === PrimaryEntityValues.Location &&
      isActiveStateCode &&
      availableForRoles
    ) {
      openChangeLocationOrDriverModal(ChangedFieldTypes.Location, () => {
        onSubmit({ values, formFields });
      });

      return true;
    }
    return false;
  };

  return { locationSubmitCallback };
};
