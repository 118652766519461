import React, { useState } from 'react';
import WelcomeScreen from 'components/welcome-screen/welcome-screen';
import ReactJoyride, {
  CallBackProps,
  TooltipRenderProps,
  ACTIONS,
  EVENTS,
  STATUS,
  BeaconRenderProps
} from 'react-joyride';
import { useDispatch } from 'react-redux';
import { setDashboardTourIsStartted, setShowGuidedTourFirst, toggleAfterTourPopUp } from 'actions/app_action';
import GuidedTourTooltip from 'components/guided-tour-tooltip/goided-tour-tooltip';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { setFinishedGuidedTour } from 'pages/dashboard/dashboard-service';

interface Props {
  isSideMenuVisible: boolean;
}

const TooltipComponent = ({ index, step, closeProps, primaryProps, tooltipProps, size }: TooltipRenderProps) => {
  const getTooltip = () => {
    switch (index) {
      case 0:
        return (
          <WelcomeScreen
            isOpen={true}
            index={index}
            primaryProps={primaryProps}
            closeProps={closeProps}
            stepsLength={size}
          />
        );
      default:
        return (
          <GuidedTourTooltip
            tooltipProps={tooltipProps}
            primaryProps={primaryProps}
            closeProps={closeProps}
            title={step.title}
            content={step.content}
            index={index}
            stepsLength={size}
          />
        );
    }
  };
  return getTooltip();
};

const BeaconComponent = ({}: BeaconRenderProps) => {
  return <div></div>;
};

const DashboardGuidedTour = ({ isSideMenuVisible }: Props) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const dashboardTourIsStarted = useTypedSelector(state => state.app.dashboardTourIsStarted);
  const roles = useTypedSelector(state => state.app.user.roles);

  const setGuidedTourFinishedStatus = async () => {
    try {
      await setFinishedGuidedTour();
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoyCallback = (data: CallBackProps) => {
    const { action, type, index, status } = data;

    if ((type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) && action === ACTIONS.NEXT) {
      setActiveStep(index + 1);
    }

    if (status === STATUS.FINISHED || action === ACTIONS.CLOSE) {
      setActiveStep(0);
      dispatch(setDashboardTourIsStartted(false));
      dispatch(setShowGuidedTourFirst(false));
      setGuidedTourFinishedStatus();

      const scrollContaiener = document.querySelector<HTMLElement>('.scroll-container');
      if (scrollContaiener) {
        scrollContaiener.style.overflow = 'auto';
      }

      setTimeout(() => {
        dispatch(toggleAfterTourPopUp(true));
      }, 120000);
    }
  };

  const steps = [
    {
      content: <></>,
      target: '#root'
    },
    {
      title: 'Navigation',
      content: (
        <span>
          Über die <span style={{ fontWeight: 'bold' }}>Hauptnavigation</span> gelangst Du in alle Bereiche des Portals.
        </span>
      ),
      target: isSideMenuVisible ? '#DashboardMenuTitle' : '#burger_menu',
      placement: 'right-start'
    },
    {
      title: 'Rolle wechseln',
      content: 'Du kannst die Ansichten bequem zwischen Fahrer und Fuhrparkleiter über das Auswahlfeld wechseln.',
      target: '#breadcrumbsRoleSwitcher',
      placement: 'left'
    },
    {
      title: 'Fahrzeugübersicht',
      content: 'Dieses Fenster zeigt die Gesamtanzahl der Fahrzeuge an. Diese können hinzugefügt und verwaltet werden.',
      target: '#car_widget',
      placement: 'right-start'
    },
    {
      title: 'Aufgaben',
      content: 'Hier siehst Du anstehende Aufgaben, welche zeitnah erledigt werden sollten.',
      target: '#upcoming_tasks',
      placement: 'right-start'
    },
    {
      title: 'Entscheidungshilfen',
      content: 'Wir liefern Dir alle Argumente, die Du brauchst, um Dein Team vom Mobexo Fleetmanager zu überzeugen.',
      target: '#KundewerdenMenuTitle',
      placement: 'right-start'
    },
    {
      title: 'Tutorials & Hilfe',
      content:
        'Wenn Du Unterstützung benötigst, findest Du hier Videotutorials zu den einzelnen Themen oder kannst unser Support-Team erreichen.',
      target: '#video_button',
      placement: 'left-end'
    }
  ];

  const actualSteps = roles.length > 1 ? steps : [...steps].filter(step => step.target !== '#breadcrumbsRoleSwitcher');

  return (
    <ReactJoyride
      run={dashboardTourIsStarted}
      //@ts-ignore
      steps={actualSteps}
      beaconComponent={BeaconComponent as React.ElementType<BeaconRenderProps>}
      continuous
      stepIndex={activeStep}
      hideBackButton
      scrollOffset={100}
      scrollToFirstStep
      showSkipButton={false}
      callback={handleJoyCallback}
      hideFooter
      disableOverlayClose
      tooltipComponent={TooltipComponent as React.ElementType<TooltipRenderProps>}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1000
          }
        }
      }}
      styles={{
        options: {
          arrowColor: '#D8E5EC',
          zIndex: 1000
        }
      }}
    />
  );
};

export default DashboardGuidedTour;
