/* eslint-disable linebreak-style */
import React, { useState, useEffect, useRef, useContext } from 'react';
import classes from './driver-diagram.module.scss';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
  ChartData,
  ChartOptions
} from 'chart.js';
import { PAGES } from 'common/enums';
import {
  CRM_VALUE,
  CONTROL_STATUS
} from 'components/driving-license-control-component/driving-license-control-component-view';
import { useTheme } from 'styled-components';
import { DriverDiagramProps, Status } from './driver-diagram.props';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { CRM_VALUE as CRM_VALUE_DRIVER } from 'components/meine-fahrer-component/components/meine-fahrer-filters/meine-fahrer-filters';
import { DRIVER_LICENSE_CONTROL_FILTER_VALUES } from 'components/driving-license-control-component/components/driving-license-control-filters/utils';
import { AnyObject } from 'common/interfaces';

ChartJS.register(ArcElement, Tooltip, Legend);

const DriverDiagram = ({ driverRecords, circleData }: DriverDiagramProps) => {
  const [hoveredSegment, setHoveredSegment] = useState<ActiveElement | null>(null);
  const [choosenSegment, setChoosenSegment] = useState<ActiveElement | null>(null);
  const validDrivers = circleData?.statuses.find((item: Status) => item.label === 'Gültig')?.count || 0;
  const invalidDrivers =
    circleData?.statuses.find((item: Status) => item.label === 'Ohne gültige Kontrolle')?.count || 0;
  const [segmentOffsets, setSegmentOffsets] = useState<number[]>([20, -10]);
  const [activeSegment, setActiveSegment] = useState<number | null>(0);
  const chartRef = useRef<ChartJS<'doughnut', number[], string> | null>(null);
  const theme = useTheme();

  const { openPage } = useContext(FleetManagerDashboardContext);

  // @ts-ignore
  const handleRef = (instance: any) => {
    if (instance && instance.$context && instance.$context.chart) {
      chartRef.current = instance.$context.chart;
    }
  };

  useEffect(() => {
    const initialIndex = 0;
    setActiveSegment(initialIndex);
    const initialSegment = { index: initialIndex } as ActiveElement;
    setChoosenSegment(initialSegment);
    setHoveredSegment(initialSegment);
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const initialIndex = 0;
      const arc = chartRef.current.getDatasetMeta(0).data[initialIndex] as ArcElement;
      if (arc) {
        const initialSegment = { index: initialIndex } as ActiveElement;
        setChoosenSegment(initialSegment);
        setHoveredSegment(initialSegment);
      }
    }
  }, [circleData]);

  const activeIndex = activeSegment !== null ? activeSegment : 0;

  useEffect(() => {
    const newOffsets = [-10, -10];
    if (activeIndex != null) {
      newOffsets[activeIndex] = 20;
    }
    setSegmentOffsets(newOffsets);
  }, [activeIndex]);

  const activeColor = theme.colors.whiteBlue;
  const inactiveColor = theme.colors.gold;

  const getAdjustedSegmentValues = (valid: number, unvalid: number, minimumPercentage: number = 3) => {
    let total = valid + unvalid;
    if (total === 0) {
      return [0, 0];
    }
    let validPercentage = (valid / total) * 100;
    let unvalidPercentage = (unvalid / total) * 100;
    if (validPercentage < minimumPercentage) {
      unvalidPercentage = unvalidPercentage - (minimumPercentage - validPercentage);
      validPercentage = minimumPercentage;
    } else if (unvalidPercentage < minimumPercentage) {
      validPercentage = validPercentage - (minimumPercentage - unvalidPercentage);
      unvalidPercentage = minimumPercentage;
    }
    total = 100 / (validPercentage + unvalidPercentage);

    return [validPercentage * total, unvalidPercentage * total];
  };

  const [adjustedValidDrivers, adjustedUnvalidDrivers] = getAdjustedSegmentValues(validDrivers, invalidDrivers);

  const data: ChartData<'doughnut', number[], string> = {
    labels: ['validDrivers', 'invalidDrivers'],
    datasets: [
      {
        data: [adjustedUnvalidDrivers, adjustedValidDrivers],
        hoverBackgroundColor: [inactiveColor, activeColor],
        backgroundColor: [inactiveColor, activeColor],
        offset: segmentOffsets,
        borderWidth: 0
      }
    ]
  };

  const options: ChartOptions<'doughnut'> = {
    cutout: '85%', // option for margin inside the circle
    radius: 74,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onClick: (event: ChartEvent, segments: ActiveElement[]) => {
      if (segments.length > 0) {
        const segmentIndex = segments[0].index;
        setActiveSegment(segmentIndex);
        const newOffsets = [-10, -10];
        newOffsets[segmentIndex] = 20;
        setSegmentOffsets(newOffsets);
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const canvas = event.native && (event.native.target as HTMLCanvasElement);

      if (canvas && elements.length > 0) {
        const newSegment = elements[0] as ActiveElement;
        if (!hoveredSegment || newSegment.index !== hoveredSegment.index) {
          setHoveredSegment(newSegment);
          const newOffsets = [-10, -10];
          newOffsets[newSegment.index] = 20;
          setSegmentOffsets(newOffsets);
        }
        canvas.style.cursor = 'pointer';
      } else if (canvas) {
        canvas.style.cursor = 'default';
        if (activeSegment !== null && hoveredSegment?.index !== activeSegment) {
          setHoveredSegment(activeSegment !== null ? ({ index: activeSegment } as ActiveElement) : null);
        }
        const newOffsets = [-10, -10];
        if (activeSegment !== null) {
          newOffsets[activeSegment] = 20;
        }
        if (JSON.stringify(segmentOffsets) !== JSON.stringify(newOffsets)) {
          setSegmentOffsets(newOffsets);
        }
      }
    }
  };

  const openDriversCheck = (filterForServer: AnyObject, filterForClient: AnyObject) =>
    openPage({
      page: PAGES.DRIVERS_CHECK,
      companyCrmName: CRM_VALUE.company,
      filterForServer: { [CRM_VALUE.bookedService]: true, ...filterForServer },
      filterForClient: {
        [CRM_VALUE.bookedService]: DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.available,
        ...filterForClient
      }
    });

  const openDriversCheckWithoutValidControlStatus = openDriversCheck(
    { [CRM_VALUE.status]: 100000002 },
    { [CRM_VALUE.status]: CONTROL_STATUS.withoutValidControl }
  );

  const openDriversCheckValidStatus = openDriversCheck(
    { [CRM_VALUE.status]: 100000000 },
    { [CRM_VALUE.status]: CONTROL_STATUS.valid }
  );

  return (
    <div className={classes.widget_container}>
      <div className={classes.canvas_block}>
        {(validDrivers > 0 || invalidDrivers > 0) && <Doughnut ref={handleRef} data={data} options={options} />}
        {(validDrivers > 0 || invalidDrivers > 0) && choosenSegment && hoveredSegment && data?.labels ? (
          <div className={classes.text_block}>
            {hoveredSegment?.index === 0 ? (
              <div onClick={openDriversCheckWithoutValidControlStatus}>
                <span style={{ color: inactiveColor }}>{invalidDrivers}</span>
                <p>
                  ungültige <br /> Führerscheine
                </p>
              </div>
            ) : (
              <div onClick={openDriversCheckValidStatus}>
                <span style={{ color: activeColor }}>{validDrivers}</span>
                <p>
                  gültige <br /> Führerscheine
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.empty_data}>
            <EmptyDataIcon hovered={true} />
            <span>Es gibt keine Daten</span>
          </div>
        )}
      </div>

      <div
        className={classes.driver_block}
        onClick={openPage({ page: PAGES.MY_DRIVERS, companyCrmName: CRM_VALUE_DRIVER.company })}
      >
        Fahrer <span>{driverRecords ? driverRecords : 0}</span>
      </div>
    </div>
  );
};

export default DriverDiagram;
