import { PackageTypes } from 'common/enums';

export const buttonTypes = {
  trial: {
    [PackageTypes.Basic]: 'secondary',
    [PackageTypes.Professional]: 'primary',
    [PackageTypes.Comfort]: 'primary',
    [PackageTypes.Enterprise]: 'secondary'
  },
  basic: {
    [PackageTypes.Basic]: 'tertiary',
    [PackageTypes.Professional]: 'primary',
    [PackageTypes.Comfort]: 'primary',
    [PackageTypes.Enterprise]: 'secondary'
  },
  professional: {
    [PackageTypes.Basic]: 'disabled',
    [PackageTypes.Professional]: 'tertiary',
    [PackageTypes.Comfort]: 'tertiary',
    [PackageTypes.Enterprise]: 'primary'
  },
  enterprise: {
    [PackageTypes.Basic]: 'disabled',
    [PackageTypes.Professional]: 'disabled',
    [PackageTypes.Comfort]: 'disabled',
    [PackageTypes.Enterprise]: 'tertiary'
  }
};

export const cardsBackgrounds = {
  [PackageTypes.Basic]: 'blackPearl10',
  [PackageTypes.Professional]: 'blackPearl',
  [PackageTypes.Comfort]: 'blackPearl',
  [PackageTypes.Enterprise]: 'blackPearl10'
};

export const cardsColors = {
  [PackageTypes.Basic]: 'blackPearl',
  [PackageTypes.Professional]: 'white',
  [PackageTypes.Comfort]: 'white',
  [PackageTypes.Enterprise]: 'blackPearl'
};

export const cardsTitleColors = {
  [PackageTypes.Basic]: 'blackPearl',
  [PackageTypes.Professional]: 'white',
  [PackageTypes.Comfort]: 'white',
  [PackageTypes.Enterprise]: 'blackPearl'
};
