import { AxiosRequestConfig } from 'axios';

class FleetLeaseEndRequestConfig {
  getVehicleListVR = (): AxiosRequestConfig => ({
    method: 'post',
    url: 'FuhrparkLeaseEnd/GetFahrzeugListVR',
    data: {}
  });
}

export const fleetLeaseEndRequestConfig = new FleetLeaseEndRequestConfig();
