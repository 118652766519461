import React, { cloneElement } from 'react';
import { EditableComponentProps, FormInfoItemEditableProps } from './editable.props';
import { StyledContainer, StyledFormInfoItemEditable, StyledPenIcon } from './editable.styled';
import { FormInfoItemDefault } from '../default/default';
import { useFormInfoItemEdit } from './use-form-info-item-edit';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';

export const FormInfoItemEditable = (props: FormInfoItemEditableProps) => {
  const { isEdit, onEdit } = useFormInfoItemEdit(props.label);

  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const { editableComponent, isDisableEditMode, activeLabelStyle, activeValueStyle, containerProps } = props;
  const isCanEdit = !isDisableEditMode;

  const formInfoItemEditableValue =
    isEdit && !!editableComponent ? cloneElement<EditableComponentProps>(editableComponent, { onEdit }) : props.value;

  const editForm = () => {
    openUnsavedChangesModal(() => onEdit(true));
  };

  if (props.isHide) {
    return null;
  }

  if (isEdit || isDisableEditMode) {
    return (
      <FormInfoItemDefault
        labelStyle={activeLabelStyle}
        valueStyle={activeValueStyle}
        {...props}
        {...containerProps}
        value={formInfoItemEditableValue}
      />
    );
  }

  return (
    <StyledContainer {...containerProps}>
      <StyledFormInfoItemEditable {...props} value={formInfoItemEditableValue} isCanEdit={isCanEdit} />
      <StyledPenIcon id='openEditForm' width={30} height={30} onClick={editForm} isCanEdit={isCanEdit} />
    </StyledContainer>
  );
};
