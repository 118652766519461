import React from 'react';
import { DownloadOfferProps } from './download-offer.types';
import { Flex } from 'common/common-components.styled';
import DocumentsBlock from 'components/assignments-record/documents-block/documents-block';
import { WidgetContentText } from '../../../components/leasing-widget/leasing-widget.styled';

const DownloadOffer: React.FC<DownloadOfferProps> = ({ description, files }) => {
  return (
    <Flex direction='column' gap='10px'>
      <Flex>
        <WidgetContentText dangerouslySetInnerHTML={{ __html: description }} />
      </Flex>

      <DocumentsBlock files={files} noMargin isDownloadIcon isWithoutFileViewer isWithoutSize />
    </Flex>
  );
};

export default DownloadOffer;
