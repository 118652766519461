import React, { useContext } from 'react';
import { SingleTabContext } from '../../single.context';
import { Flex } from 'common/common-components.styled';
import { TabItemController } from './components/tab-item/tab-item.controller';

export const TabList = () => {
  const { data, activeTabIndex } = useContext(SingleTabContext);

  return (
    <Flex isHideScrollBar overflow='auto' bottom='10' align='flex-start' gap='10px'>
      {data.map((item, index) => (
        <TabItemController key={index} {...item} tabIndex={index} isActive={index === activeTabIndex}>
          {item.tab}
        </TabItemController>
      ))}
    </Flex>
  );
};
