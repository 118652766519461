import { AxiosRequestConfig } from 'axios';
import { ConfirmOrderByCarDealerData } from 'request-config/vehicle-offer/vehicle-offer.type';

class VehicleOfferRequestConfig {
  deactivate = (fahrzeugangeboteId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugangebote/deactivate',
    data: {
      fahrzeugangeboteId
    }
  });

  getDriversPersonalConfigurations = (userId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugangebote/getByUserId',
    data: {
      userId
    }
  });

  confirmOrderByCarDealer = (data: ConfirmOrderByCarDealerData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugangebote/confirmOrderByCarDealer',
    data
  });
}

export const vehicleOfferRequestConfig = new VehicleOfferRequestConfig();
