import { FormFieldModel, FormFieldOption } from 'common/interfaces';
import { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useUpdateLookupValue = (reactHookForm: UseFormReturn, formField: FormFieldModel) => {
  const [lookupValue, setLookupValue] = useState<null | FormFieldOption | FormFieldOption[]>(null);
  const isDefaultLookupSet = useRef(
    Array.isArray(formField.defaultValue) ? !!formField.defaultValue.length : !!formField.defaultValue
  );

  const { watch } = reactHookForm;

  const updateLookupValue = (fieldValue: string | string[]) => {
    if (formField.options) {
      const findValueInDropdown = formField.options.find(item => item.value === fieldValue);

      if (!fieldValue) {
        setLookupValue(formField.multiple ? [] : null);

        return;
      }

      if (findValueInDropdown && !Array.isArray(lookupValue) && findValueInDropdown.value !== lookupValue?.value) {
        setLookupValue(findValueInDropdown);

        return;
      }

      if (Array.isArray(fieldValue)) {
        const commonValues = formField.options.filter(item => fieldValue.includes(item.value as string));
        setLookupValue(commonValues);

        return;
      }

      if (
        findValueInDropdown &&
        Array.isArray(lookupValue) &&
        !Array.isArray(fieldValue) &&
        findValueInDropdown.value === fieldValue
      ) {
        setLookupValue(findValueInDropdown);

        return;
      }
    }
  };

  const commonDeps = [formField.name, JSON.stringify(formField.options)];

  useEffect(() => {
    if (!isDefaultLookupSet.current) {
      updateLookupValue(watch(formField.name));
    }
  }, [...commonDeps, watch(formField.name)]);

  useEffect(() => {
    if (typeof formField.defaultValue === 'string' && isDefaultLookupSet.current) {
      updateLookupValue(formField.defaultValue);
      isDefaultLookupSet.current = false;
    }
  }, [...commonDeps, formField.defaultValue]);

  return { lookupValue, setLookupValue };
};
