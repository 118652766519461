import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import UploadFile from '../setup-assistant/upload-document/upload-file';
import classes from './configuration-from-pdf.module.scss';
import Form from '../form/form-redux';
import {
  configurationFromPdfFormFields,
  oldCarFormFields,
  responsiblePersonFormFields
} from '../../models/forms/configuration-from-pdf-form-model';
import cloneDeep from 'lodash/cloneDeep';
import { getFields } from '../form/form-view';
import InfoHint from '../info-hint/info-hint-view';
import NumberFormat from 'react-number-format';
import { useForceUpdate } from '../../hooks/use-force-update';
import { getFormValues } from '../../utils/get-form-values';
import { setForm } from '../../actions/app_action';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestFormData, getRequestItem, getResponseFormData } from '../../utils/get-response-data';
import {
  BASKET_TYPES,
  crmDataTypes,
  MAX_DISTANCE,
  MIN_DISTANCE,
  USER_ROLES,
  VEHICLE_OFFER_ASSIGNMENT
} from '../../constants';
import { CircleExclamationMark } from '../icons/crcle_exclamation_mark';
import { useLocation } from 'react-router-dom';
import MonthsComponent from './months-component/months-component';
import DeliveryDateComponent from './delivery-date-component/delivery-date-component';
import { Flex, Text } from '../../common/common-components.styled';
import Switch from 'react-switch';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { getDriversActiveCars } from './get-drivers-active-cars';
import useFillResponsiblePerson from './use-fill-responsible-person';
import { PAGES } from '../../common/enums';
import { useIsUserHaveRole } from '../../hooks/use-is-user-have-role';
import useDriverFields from './hooks/use-driver-fields';

export const CustomInput = ({ value, onClick, id }) => (
  <button id={id} className={classes.custom_input} onClick={onClick}>
    {value ? (
      value.toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })
    ) : (
      <Text fontWeight='normal' color='grey500'>
        Monat auswählen
      </Text>
    )}
  </button>
);

export const ControlledInput = ({ children, onButtonClick, className, prefixId = '', style = {} }) => {
  return (
    <div style={style} className={className ? `${classes.controlled_input} ${className}` : classes.controlled_input}>
      <div
        id={prefixId + 'Subtraction'}
        onClick={() => onButtonClick('subtraction')}
        className={`${classes.button} ${classes.subtraction}`}
      >
        -
      </div>
      {children}
      <div
        id={prefixId + 'Addition'}
        onClick={() => onButtonClick('addition')}
        className={`${classes.button} ${classes.addition}`}
      >
        +
      </div>
    </div>
  );
};

ControlledInput.propTypes = {
  children: PropTypes.any.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  prefixId: PropTypes.string
};

export const MonthsControl = ({ value, onChange, disabled }) => {
  const monthsValues = [12, 24, 30, 36, 42, 48, 60];

  const getClassName = monthsValue => {
    let className = `${classes.value_container}`;
    if (monthsValue === value) {
      className += disabled ? ` ${classes.disabled}` : ` ${classes.active} pointer`;
    } else {
      className += disabled ? '' : ' pointer';
    }

    return className;
  };

  const clickHandler = monthsValue => {
    if (monthsValue !== value && !disabled) {
      onChange(monthsValue);
    }
  };

  return (
    <div className={classes.months_control}>
      {monthsValues.map(monthsValue => (
        <div
          id={'MonthsValue' + monthsValue}
          key={monthsValue}
          onClick={() => clickHandler(monthsValue)}
          className={getClassName(monthsValue)}
        >
          {monthsValue}
        </div>
      ))}
    </div>
  );
};

MonthsControl.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

const ConfigurationFromPdf = ({ createVehicleOffer, selectDriverData }) => {
  const user = useTypedSelector(state => state['app'].user);
  const [formFields, setFormFields] = useState(configurationFromPdfFormFields);
  const [months, setMonths] = useState({
    name: 'uds_wunschlaufzeitcode',
    crmFieldType: crmDataTypes.Picklist,
    valueFromCompany: false,
    value: 36
  });
  const [files, setFiles] = useState([]);
  const [isPoolcar, setIsPoolcar] = useState(selectDriverData?.isPoolCar || false);
  const [isReplacementCar, setIsReplacementCar] = useState(false);
  const [oldCarPrevValue, setOldCarPrevValue] = useState('');
  const [responsiblePersonFields, setResponsiblePersonFields] = useState(responsiblePersonFormFields);
  const [oldCarFields, setOldCarFields] = useState(null);
  const dispatch = useDispatch();
  const forms = useSelector(state => state['app'].forms);
  const location = useLocation();
  const isMitarbeiterkonfigurationenPage = location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATIONS);
  const [distance, setDistance] = useState({
    name: 'uds_laufleistungkm',
    crmFieldType: crmDataTypes.Integer,
    value: null
  });
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const firstDayCurrentMonth = useMemo(() => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }, []);
  const twoYearsFromNow = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 2);
    return date;
  }, []);
  const [deliveryDate, setDeliveryDate] = useState({
    name: 'uds_wunschlieferdatum',
    crmFieldType: crmDataTypes.DateTime,
    value: null
  });
  const [formErrors, setFormErrors] = useState(false);
  const forceUpdate = useForceUpdate();

  const fillResponsiblePerson = useFillResponsiblePerson(
    responsiblePersonFields,
    setResponsiblePersonFields,
    isPoolcar
  );

  const { driverFields, getErrorMessage, driverValueChanged } = useDriverFields(selectDriverData);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  const valueChanged = useCallback((name, value, oldValue, inputValue) => {
    const newFormFields = cloneDeep(formFields);
    if (name === 'uds_carbrandid') {
      getFields(newFormFields, field => {
        if (field.name === 'uds_carmodelid') {
          field.hidden = !value;
          field.sourceParameter = value || null;
        }
      });
    }
    getFields(newFormFields, field => {
      if (field.name === name) {
        field.errorMessage = getErrorMessage(inputValue);
      }
    });
    setFormFields(newFormFields);
  });

  useEffect(() => {
    if (isMitarbeiterkonfigurationenPage) {
      getFields(oldCarFormFields, field => {
        field.source = isPoolManager ? 'getCompanyActiveCarsForPoolManager' : 'getCompanyActiveCars';
      });
      setOldCarFields(oldCarFormFields);
      return;
    }

    getDriversActiveCars(user).then(response => {
      const driversCars = getResponseFormData(response.data, [{ name: 'entityes', type: 'array' }]).map(item => ({
        id: item.new_fuhrparkid,
        label: item.new_name
      }));
      const newOldCarFields = cloneDeep(oldCarFormFields);
      getFields(newOldCarFields, field => {
        field.source = null;
        field.data = driversCars;
        field.disabled = driversCars.length === 1;
        field.defaultValue = driversCars.length === 1 ? driversCars[0].id : '';
      });
      setOldCarFields(newOldCarFields);
    });
  }, []);

  useEffect(() => {
    if (isReplacementCar) {
      fillResponsiblePerson(null);
    }
  }, [isReplacementCar]);

  useEffect(() => {
    fillResponsiblePerson(null);
  }, [isPoolcar]);

  const onDistanceButtonClick = type => {
    let newDistance = null;
    switch (type) {
      case 'subtraction':
        newDistance = distance.value - 1000;
        if (newDistance >= 0) {
          setDistance({
            ...distance,
            value: newDistance
          });
        }
        break;
      case 'addition':
        newDistance = distance.value + 1000;
        setDistance({
          ...distance,
          value: newDistance
        });
        break;
      default:
        break;
    }
  };

  const onDropAccepted = files => {
    setFiles(files);
  };

  const distanceChange = value => {
    const newDistance =
      value.floatValue >= MIN_DISTANCE ? Math.round(value.floatValue / 1000) * 1000 : value.floatValue || null;

    setDistance({
      ...distance,
      value: newDistance
    });
    if (distance.value === newDistance) {
      forceUpdate();
    }
  };

  const oldCarValueChanged = (fieldName, value, oldValue, inputValue) => {
    if (fieldName === 'uds_altes_fahrzeugid' && isPoolcar && value !== oldCarPrevValue) {
      setOldCarPrevValue(value);

      fillResponsiblePerson(value);
    }

    const newOldCarFields = cloneDeep(oldCarFields);
    getFields(newOldCarFields, field => {
      field.errorMessage = getErrorMessage(inputValue);
    });
    setOldCarFields(newOldCarFields);
  };

  const responsiblePersonValueChanged = (fieldName, value, oldValue, inputValue) => {
    const newResponsiblePersonFields = cloneDeep(responsiblePersonFields);
    getFields(newResponsiblePersonFields, field => {
      field.errorMessage = getErrorMessage(inputValue);
    });
    setResponsiblePersonFields(newResponsiblePersonFields);
  };

  const submit = () => {
    if (!files.length || distance.value < MIN_DISTANCE || distance.value > MAX_DISTANCE) {
      setFormErrors(true);
    }
    getFormValues(
      forms,
      form => dispatch(setForm(form)),
      async values => {
        if (
          files.length &&
          (showAdditionalFields ? distance.value >= MIN_DISTANCE && distance.value <= MAX_DISTANCE : true)
        ) {
          const fields = [];
          getFields(formFields, field => {
            fields.push(field);
          });
          getFields(driverFields, field => {
            fields.push(field);
          });
          getFields(oldCarFields, field => {
            fields.push(field);
          });
          getFields(responsiblePersonFormFields, field => {
            fields.push(field);
          });

          const dataToSend = {
            entityName: 'uds_fahrzeugangebote',
            id: null,
            attributes: getRequestFormData(values, fields)
          };

          if (showAdditionalFields) {
            [months, distance, deliveryDate].forEach(field => {
              dataToSend.attributes[field.name] = getRequestItem(field.value, field.crmFieldType);
            });
          }

          if (dataToSend.attributes['driverId']) {
            dataToSend['driverId'] = dataToSend.attributes['driverId'].attributeValue.id;
            delete dataToSend.attributes['driverId'];
          }

          dataToSend.attributes['uds_zuordnung'] = getRequestItem(
            isPoolcar ? VEHICLE_OFFER_ASSIGNMENT.poolCar.value : VEHICLE_OFFER_ASSIGNMENT.employeeAssigned.value,
            crmDataTypes.Picklist
          );

          if (isPoolManager && !dataToSend.attributes['uds_kstverantw']) {
            dataToSend.attributes['uds_kstverantw'] = getRequestItem(user.id, crmDataTypes.Lookup);
          }

          dataToSend['uds_baskettypecode'] = isPoolcar ? BASKET_TYPES.poolcar.value : BASKET_TYPES.personal.value;

          createVehicleOffer(dataToSend, files, showAdditionalFields);
        }
      }
    );
  };

  return (
    <div className={classes.configuration_from_pdf}>
      <p className={classes.configuration_from_pdf_title}>
        Lade die PDF deiner Herstellerkonfiguration hoch und frage den Fahrzeugpreis an.
      </p>

      <UploadFile
        onDropAccepted={onDropAccepted}
        accept={['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']}
        acceptString={'pdf, png, jpg, jpeg'}
        required={true}
        className={formErrors && !files.length ? classes.error : null}
        multiple={false}
      />

      <div className='mt-30' />
      <div className='mobexo-divider' />
      <div className='mt-30' />

      <Form name={'configuration'} valueChanged={valueChanged} className={classes.form} formFields={formFields} />

      <div className='mb-2' />
      <div className='mobexo-divider' />
      <div className='mb-2' />

      {isMitarbeiterkonfigurationenPage && (
        <>
          {!isPoolcar && (
            <Form
              name={'driver'}
              className={classes.form}
              valueChanged={driverValueChanged}
              formFields={driverFields}
            />
          )}

          <Flex align='center' margin='20px 0'>
            <Switch
              id='IsPoolcarSwitcher'
              offColor={'#bfbfbf'}
              onColor={'#335566'}
              height={20}
              width={40}
              disabled={!!selectDriverData}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={event => setIsPoolcar(event)}
              checked={isPoolcar}
            />
            <Text left='10' color='black' pointer onClick={() => !selectDriverData && setIsPoolcar(flag => !flag)}>
              Poolcar
            </Text>
          </Flex>
        </>
      )}

      {!isReplacementCar && oldCarFields && (
        <Form
          name={'uds_altes_fahrzeugid'}
          valueChanged={oldCarValueChanged}
          className={classes.form}
          formFields={oldCarFields}
        />
      )}

      <Flex align='center' margin='20px 0'>
        <Switch
          id='IsReplacementCarSwitcher'
          offColor={'#bfbfbf'}
          onColor={'#335566'}
          height={20}
          width={40}
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={event => setIsReplacementCar(event)}
          checked={isReplacementCar}
        />
        <Text left='10' color='black' pointer onClick={() => setIsReplacementCar(flag => !flag)}>
          Dies ist kein Ersatzfahrzeug
        </Text>
      </Flex>

      {isMitarbeiterkonfigurationenPage && !isPoolManager && (
        <Form
          name={'uds_kstverantw'}
          valueChanged={responsiblePersonValueChanged}
          formFields={responsiblePersonFields}
        />
      )}

      {showAdditionalFields && (
        <>
          <div className='mt-30' />
          <div className='mobexo-divider' />
          <div className='mt-30' />

          <p className={classes.field_title}>
            Laufzeit (Monate) <span className={classes.required_mark}>*</span>
          </p>
          <MonthsComponent months={months} setMonths={setMonths} />
          <div className='mt-15' />
          {months.valueFromCompany && (
            <InfoHint>Die Laufzeit wird von deinem Fuhrparkleiter festgelegt und kann nicht geändert werden.</InfoHint>
          )}
          <div className='mt-15' />
          <p className={classes.field_title}>
            Jährliche Fahrleistung (in km/Jahr) <span className={classes.required_mark}>*</span>
          </p>
          <ControlledInput
            onButtonClick={onDistanceButtonClick}
            prefixId='Distance'
            className={
              formErrors && (distance.value < MIN_DISTANCE || distance.value > MAX_DISTANCE) ? classes.error : null
            }
          >
            <NumberFormat
              id='DistanceInput'
              value={distance.value}
              displayType='input'
              thousandSeparator={'.'}
              placeholder={'Wert angeben (z.B. 10.000) oder mit Hilfe von +/- auswählen'}
              decimalScale={0}
              decimalSeparator={','}
              fixedDecimalScale={true}
              className={classes.custom_input}
              onValueChange={distanceChange}
            />
          </ControlledInput>
          {formErrors && (distance.value < MIN_DISTANCE || distance.value > MAX_DISTANCE) && (
            <div className={classes.error_message}>
              <span className='d-flex a-i-center'>
                <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
                &nbsp;Der Wert sollte zwischen {MIN_DISTANCE.toLocaleString('de-DE')} und{' '}
                {MAX_DISTANCE.toLocaleString('de-DE')}
              </span>
            </div>
          )}
          <div className='mt-15' />
          <p className={classes.field_title}>
            Wunschlieferdatum <span className={classes.required_mark}>*</span>
          </p>
          <DeliveryDateComponent
            deliveryDate={deliveryDate}
            setDeliveryDate={setDeliveryDate}
            minDate={firstDayCurrentMonth}
            maxDate={twoYearsFromNow}
            formErrors={formErrors}
          />
        </>
      )}

      <div className='mt-30' />

      {showAdditionalFields ? (
        <div className='d-flex j-c-flex-end'>
          <button
            id='CloseAdditionalFieldsButton'
            onClick={() => setShowAdditionalFields(false)}
            className='button-mobexo button-mobexo__secondary'
          >
            Zurück
          </button>
          <div className='mr-1' />
          <button id='SaveExtendedConfigurationButton' onClick={submit} className='button-mobexo button-mobexo__main'>
            Jetzt anfragen
          </button>
        </div>
      ) : (
        <div className='d-flex j-c-flex-end'>
          <button id='SaveConfigurationButton' onClick={submit} className='button-mobexo button-mobexo__secondary'>
            Für später speichern
          </button>
          <div className='mr-1' />
          <button
            id='OpenAdditionalFieldsButton'
            onClick={() => setShowAdditionalFields(true)}
            className='button-mobexo button-mobexo__main'
          >
            Leasingangebot anfragen
          </button>
        </div>
      )}
    </div>
  );
};

ConfigurationFromPdf.propTypes = {
  createVehicleOffer: PropTypes.func.isRequired,
  selectDriverData: PropTypes.shape({ driverId: PropTypes.string | null, isPoolCar: PropTypes.bool | null })
};

export default ConfigurationFromPdf;
