import React from 'react';
import { useIsShowDataQualityWidget } from '../../widgets/data-quality/hooks/use-is-show-data-quality-widget';
import { VehicleDetailCarSection } from './components/car-section/car-section';
import { ConsumptionWidget } from '../../widgets/consumption/consumption';
import { DataQualityWidget } from '../../widgets/data-quality/data-quality';

export const CarOverviewSection = () => {
  const { isShowDataQualityWidget } = useIsShowDataQualityWidget();

  return (
    <>
      <VehicleDetailCarSection />

      {isShowDataQualityWidget && (
        <>
          <ConsumptionWidget />
          <DataQualityWidget />
        </>
      )}
    </>
  );
};
