import { ProductId } from 'hooks/react-query/product/get-user-product/get-user-product.props';
import { AvailabilityRollsForProductType } from 'hooks/use-is-product-available/use-is-product-available.props';
import { USER_ROLES } from '../../constants';

export const availabilityRollsForProduct: AvailabilityRollsForProductType = {
  [ProductId.SelfManagedClaims]: [USER_ROLES.fleetManager.id, USER_ROLES.driver.id, USER_ROLES.administrator.id],
  [ProductId.InspectionDates]: [USER_ROLES.fleetManager.id, USER_ROLES.administrator.id],
  [ProductId.ErComplaint]: [USER_ROLES.fleetManager.id, USER_ROLES.administrator.id]
};

export const damageTypes = [
  'Parkschaden',
  'Glasschaden',
  'Auffahrschaden',
  'Sachbesch./ Vandalismus',
  'Vorfahrt verletzt',
  'Fahrspurwechsel',
  'Wild-/ Tierschaden',
  'Hagelschaden',
  'Sturmschaden',
  'Einbruch/ Teildiebstahl',
  'Marderschaden',
  'Brandschaden',
  'Totaldiebstahl',
  'Sonstiges'
];

export const CREATE_DAMAGE_DROPDOWN_ITEM_HEIGHT = 48;

export const getCreateDamageFlatListHeight = (dataLength: number) => {
  if (dataLength > 4) {
    return CREATE_DAMAGE_DROPDOWN_ITEM_HEIGHT * 4;
  }

  return CREATE_DAMAGE_DROPDOWN_ITEM_HEIGHT * dataLength;
};
