import { StyledDroppableContentContainer } from 'components/draggable-list/draggable-list.styled';
import IconHover from 'components/icon-hover/icon-hover';
import styled from 'styled-components';

const ANIMATION_DURATION = '0.3s';

export const StyledInspectionDateDroppableContentContainer = styled(StyledDroppableContentContainer)<{
  isEditable: boolean;
}>`
  position: relative;

  ${({ theme, isEditable }) =>
    isEditable &&
    `
    transition: ${ANIMATION_DURATION};

    .sixDotsContainer {
      transition: ${ANIMATION_DURATION};
    }

    &:hover {
      background-color: ${theme.colors.grey50};

      .sixDotsContainer {
        visibility: hidden;
        opacity: 0;
      }
    }
  `}
`;

export const StyledInspectionDatePenIconHover = styled(IconHover)`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  transition: ${ANIMATION_DURATION};

  ${StyledInspectionDateDroppableContentContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;
