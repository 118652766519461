import React from 'react';
import styles from './document-classic-component.module.scss';
import Download from 'components/icons/download';
import { useOverlay } from 'hooks';
import sharepointService from 'services/sharepoint-service';
import { getIcon } from 'components/download-documents-component/download-documents-component';
import { AnyObject } from 'common/interfaces';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import Spinner from 'components/spinner';
import { NoFiles } from 'components/barpreisanfragen-component/components/dealerpage-files/dealerpage-files';
import { StyledContainer } from 'pages/details/leasingbank-info/leasingbank-info.styled';
import { FileModel } from 'common/interfaces';

interface DocumentClassicComponentProps {
  files: FileModel[];
  loading: boolean;
}

const DocumentClassicComponent = ({ files, loading }: DocumentClassicComponentProps) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const handleDownload = async (file: FileModel) => {
    showOverlay();
    await sharepointService
      .downloadFileById(file.id)
      .then(({ data }: AnyObject) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data]));
        a.download = file.name;
        a.click();
      })
      .catch(err => console.log(err))
      .finally(hideOverlay);
  };

  return (
    <section className={styles.container}>
      {loading ? (
        <StyledContainer direction='column'>
          <Spinner
            style={{
              width: '50px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        </StyledContainer>
      ) : files.length ? (
        files.map((file: FileModel) => (
          <div className={styles.file_block} key={file.id}>
            <div className={styles.download_links}>
              <div onClick={() => handleDownload(file)} className={styles.link__row}>
                {getIcon(file?.name)}
                <div className='mr-1' />
                <div className={styles.file_description}>
                  <span>{file?.name}</span>
                  <p>{`${file?.size / 1000}Kb · ${getDateString(file?.created)}`}</p>
                </div>
                <div className={styles.download_img}>
                  <Download width='18' height='22' fill='#335566' />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoFiles />
      )}
    </section>
  );
};

export default DocumentClassicComponent;
