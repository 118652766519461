import styled from 'styled-components';

export const WidgetButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  opacity: ${({ disabled }) => (disabled ? '.4' : '1')};
`;
