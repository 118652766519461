import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { portalCustomizationRequestConfig } from 'request-config/portal-customization/portal-customization.request-config';

export const useGetReportDamageLogoQuery = (settingsId: string) => {
  const { fetch } = useFetch<Blob>({
    loadingKey: 'getReportDamageLogoQuery',
    isShowLoading: false,
    isShowErrorSnackbar: false,
    isExcludeToken: true,
    ...portalCustomizationRequestConfig.getReportDamageLogo(settingsId)
  });

  const { data, ...restQueryProps } = useQuery(['getReportDamageLogoQuery', settingsId], () => fetch(), {
    enabled: !!settingsId
  });

  return { reportDamageLogoQuery: data?.data, ...restQueryProps };
};
