import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormFieldModel } from 'common/interfaces';
import FormField from 'components/form-hook/form-field/form-field';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useSaveFormValues } from 'hooks/form/use-save-values';
import { useOnUnmountForm } from './form-field/hooks/use-on-unmount-form';

export interface FormHookProps {
  reactHookFormData: UseFormReturn<any>;
  formFields: FormFieldModel<any>[];
  distanceBetweenFields?: string;
  marginLeft?: string;
  className?: string;
  children?: ReactNode;
  renderItem?: (field: FormFieldModel) => ReactNode;
  saveValueInGlobalState?: boolean;
}

const FormHook = ({
  reactHookFormData,
  formFields,
  distanceBetweenFields = '20',
  marginLeft = '0',
  className,
  children,
  renderItem,
  saveValueInGlobalState = false
}: FormHookProps) => {
  useSaveFormValues(reactHookFormData, saveValueInGlobalState);
  useOnUnmountForm();

  return (
    <div className={classNames('w-100', className)}>
      {formFields
        .filter(field => !field.hidden)
        .map(field => (
          <div key={field.name} className={field.containerClassName}>
            <FormField
              key={field.name}
              distanceBetweenFields={distanceBetweenFields}
              reactHookFormData={reactHookFormData}
              field={field}
              keepDataFromField={field.keepDataFromField}
              marginLeft={marginLeft}
            />
            {renderItem && renderItem(field)}
          </div>
        ))}

      {children}
    </div>
  );
};

export default FormHook;
