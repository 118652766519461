import React, { useContext } from 'react';
import { RepairStatusWidgetContext } from '../../repair-status.context';
import { StyledContainer } from './expanded.styled';
import { FormInfo } from './form-info/form-info';
import { getFormInfoData } from './utils';
import { RepairStatusEnum } from 'pages/repair-status/components/repair-status-item/repair-status-item.props';
import { EmptyPlaceholder } from './empty-placeholder/empty-placeholder';
import { PickValue } from 'common/interfaces';
import { IFormInfo } from './form-info/form-info.props';
import useWindowSize from 'hooks/use-window-size';

export const RepairPartnerExpanded = () => {
  const { data, isExtended } = useContext(RepairStatusWidgetContext);
  const { windowWidth } = useWindowSize();

  const isMobile = windowWidth <= 550;

  const getFormInfoFlexStyle = (isLastElement: boolean): PickValue<IFormInfo, 'flexStyle'> => {
    return {
      padding: '20px',
      borderBottomLeftRadius: isLastElement ? '2px' : '0',
      borderBottomRightRadius: isLastElement ? '2px' : '0'
    };
  };

  const getFormInfoDividerStyle = (isLastElement: boolean): PickValue<IFormInfo, 'dividerStyle'> => {
    return {
      width: '90%',
      opacity: '0.2',
      margin: '0 auto'
    };
  };

  if (data.Status === RepairStatusEnum.NewOrder) {
    return (
      <StyledContainer bottom={isMobile ? '-140px' : '-130px'} isExtended={isExtended}>
        <EmptyPlaceholder />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer bottom={isMobile ? '-318px' : '-295px'} isExtended={isExtended}>
      {getFormInfoData(data).map((dataItem, dataIndex) => (
        <FormInfo
          {...dataItem}
          target='_black'
          key={dataItem.title}
          isDivider={getFormInfoData(data).length - 1 !== dataIndex}
          flexStyle={getFormInfoFlexStyle(getFormInfoData(data).length - 1 === dataIndex)}
          dividerStyle={getFormInfoDividerStyle(getFormInfoData(data).length - 1 === dataIndex)}
        />
      ))}
    </StyledContainer>
  );
};
