import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DocumentApprovedIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M13.4018 5.34141V6.68032V17.9272H8.04614H1.08378C0.789222 17.9272 0.548218 17.6862 0.548218 17.3916V1.86023C0.548218 1.56567 0.789222 1.32466 1.08378 1.32466H9.65284L13.4018 5.34141Z'
          fill={fill}
        />
      )}
      <path d='M9.63074 5.20122L9.6308 1.52513L13.2049 5.20122H9.63074Z' fill={hovered ? 'white' : 'none'} />
      <path
        d='M13.4018 6.68032V5.34141L9.65284 1.32466H1.08378C0.789222 1.32466 0.548218 1.56567 0.548218 1.86023V17.3916C0.548218 17.6862 0.789222 17.9272 1.08378 17.9272H8.04614'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4019 5.34143H9.92074C9.77346 5.34143 9.65295 5.22093 9.65295 5.07365V3.46695'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.63623 7.89751H9.65298'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.63623 14.5538H7.24293'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.7618 8.16524C3.90969 8.16524 4.02958 8.04534 4.02958 7.89745C4.02958 7.74956 3.90969 7.62967 3.7618 7.62967C3.61391 7.62967 3.49402 7.74956 3.49402 7.89745C3.49402 8.04534 3.61391 8.16524 3.7618 8.16524Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M3.7618 14.8215C3.90969 14.8215 4.02958 14.7016 4.02958 14.5537C4.02958 14.4059 3.90969 14.286 3.7618 14.286C3.61391 14.286 3.49402 14.4059 3.49402 14.5537C3.49402 14.7016 3.61391 14.8215 3.7618 14.8215Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M5.63623 11.2256H7.77849'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.7618 11.4934C3.90969 11.4934 4.02958 11.3735 4.02958 11.2256C4.02958 11.0777 3.90969 10.9578 3.7618 10.9578C3.61391 10.9578 3.49402 11.0777 3.49402 11.2256C3.49402 11.3735 3.61391 11.4934 3.7618 11.4934Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M13.7282 18.6753C16.3902 18.6753 18.5483 16.5173 18.5483 13.8552C18.5483 11.1932 16.3902 9.03514 13.7282 9.03514C11.0661 9.03514 8.90808 11.1932 8.90808 13.8552C8.90808 16.5173 11.0661 18.6753 13.7282 18.6753Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8737 13.9155L13.5741 15.7632L15.5824 11.9473'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
