import {
  CO2Effizienz,
  crmDataTypes,
  emissioncategorycode,
  HUInterval,
  kauf,
  kraftstoff,
  statusCarcode,
  zuordnung,
  silverDatVehicleTypesOptions
} from '../../constants';
import { getFuelCardTitle } from '../../utils/get-fuel-card-title';
import { PAGES } from '../../common/enums';

export const neueFahrzeugFormFields = {
  columns: [
    {
      width: '39%',
      fields: [
        {
          name: 'new_fuhrparkid',
          type: 'text',
          crmFieldType: crmDataTypes.Uniqueidentifier,
          placeholder: '',
          hidden: true,
          defaultValue: '',
          validation: []
        },
        {
          name: 'new_name',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. TE-ST 1322',
          label: 'Kennzeichen',
          defaultValue: '',
          upperCase: true,
          errorMessage: 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'regexp',
              value: /^[A-Z,Ä,Ö,Ü]{1,3}-[A-Z,Ä,Ö,Ü]{1,2} \d{1,4}([H,E]{1})?$/
            }
          ]
        }
      ]
    },
    {
      width: '39%',
      fields: [
        {
          name: 'new_fin',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. AA1234567890ER123',
          label: 'Fahrgestellnummer',
          defaultValue: '',
          upperCase: true,
          errorMessage:
            'Die Fahrgestellnummer ist nicht korrekt. Sie darf maximal 17 Zeichen lang sein und keine Buchstaben O, I oder Q enthalten.',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'regexp',
              value: /^[^Q O I q o i \s]{1,17}$/
            }
          ]
        }
      ]
    },
    {
      width: '22%',
      fields: [
        {
          name: 'new_ezl',
          type: 'date',
          crmFieldType: crmDataTypes.DateTime,
          label: 'Erstzulassung',
          placeholder: 'DD / MM / YYYY',
          defaultValue: '',
          errorMessage: 'Bitte gib das Erstzulassung im richtigen Format ein (Beispiel: 15.11.2023).',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '39%',
      fields: [
        {
          name: 'new_sparteid',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getCompanies',
          placeholder: 'Bitte auswählen',
          label: 'Unternehmen',
          defaultValue: '',
          options: [],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '39%',
      fields: [
        {
          name: 'new_fahrerid',
          type: 'lookup',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getUserCompanyActiveDrivers',
          placeholder: 'Fahrer',
          label: 'Fahrer',
          link: PAGES.MY_DRIVERS_CREATE,
          defaultValue: '',
          validation: []
        }
      ]
    }
  ]
};

export const neueFahrzeugFormFieldsExtended = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'new_name',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. TE-ST 1322',
          label: 'Kennzeichen',
          defaultValue: '',
          upperCase: true,
          errorMessage: 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'regexp',
              value: /^[A-Z,Ä,Ö,Ü]{1,3}-[A-Z,Ä,Ö,Ü]{1,2} \d{1,4}([H,E]{1})?$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_fin',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. AA1234567890ER123',
          label: 'Fahrgestellnummer',
          defaultValue: '',
          upperCase: true,
          errorMessage:
            'Die Fahrgestellnummer ist nicht korrekt. Sie darf maximal 17 Zeichen lang sein und keine Buchstaben O, I oder Q enthalten.',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'regexp',
              value: /^[^Q O q o \s]{1,17}$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_ezl',
          type: 'date',
          crmFieldType: crmDataTypes.DateTime,
          label: 'Erstzulassung',
          placeholder: 'DD / MM / YYYY',
          defaultValue: '',
          errorMessage: 'Bitte gib das Erstzulassung im richtigen Format ein (Beispiel: 15.11.2023).',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_silverdatvehicletypeoption',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'Fahrzeugtyp',
          defaultValue: '-1',
          options: silverDatVehicleTypesOptions,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_fuhrparkid',
          type: 'text',
          crmFieldType: crmDataTypes.Uniqueidentifier,
          placeholder: '',
          hidden: true,
          defaultValue: '',
          validation: []
        },
        {
          name: 'new_hersteller',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. BMW',
          label: 'Hersteller',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_model',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'z.B. 3er',
          label: 'Modell',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_kauf_leasing',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'Kauf/Leasing',
          defaultValue: '',
          options: kauf,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_fahrerid',
          type: 'lookup',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getUserCompanyActiveDrivers',
          placeholder: 'Max Mustermann',
          label: 'Fahrer',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_standortid',
          type: 'lookup',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getStandorts',
          sourceParameter: null,
          placeholder: 'z.B. Berlin, Musterstr. 32',
          label: 'Standort',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_fahrzeugpreise',
          type: 'number',
          crmFieldType: crmDataTypes.Money,
          placeholder: 'z.B. 32.000 Euro',
          label: 'Brutto Listenpreis',
          defaultValue: '',
          validation: []
        }
      ]
    }
    // {
    //   width: '100%',
    //   fields: [
    //     {
    //       name: 'new_status_carcode',
    //       type: 'optionSet',
    //       crmFieldType: crmDataTypes.Picklist,
    //       placeholder: 'Bitte auswählen',
    //       label: 'Status Fahrzeug',
    //       defaultValue: '',
    //       options: statusCarcode,
    //       validation: []
    //     }
    //   ]
    // }
  ]
};

export const getriebeSectionFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_gearbox',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Getriebe',
          label: 'Getriebe',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_hubraum',
          type: 'text',
          crmFieldType: crmDataTypes.Integer,
          placeholder: 'Hubraum',
          label: 'Hubraum',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_bodyname',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Karosserie',
          label: 'Karosserie',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_leistung',
          type: 'text',
          crmFieldType: crmDataTypes.Integer,
          placeholder: 'Leistung',
          label: 'Leistung',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_enginetypecode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'Kraftstoff',
          defaultValue: '',
          options: kraftstoff,
          validation: []
        }
      ]
    }
  ]
};

export const umweltSectionFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_co2_efficiencycode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'CO2 Effizienz',
          defaultValue: '',
          options: CO2Effizienz,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_emissioncategorycode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'Schadstoffklasse',
          defaultValue: '',
          options: emissioncategorycode,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_co2_emission_combined',
          type: 'text',
          crmFieldType: crmDataTypes.Integer,
          placeholder: 'CO2-Emissionen Kombiniert (g/km)',
          label: 'CO2-Emissionen Kombiniert (g/km)',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_feinstaubplakette',
          type: 'text',
          crmFieldType: crmDataTypes.Integer,
          placeholder: 'Feinstaubplakette',
          label: 'Feinstaubplakette',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_co2_emission_uom',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'CO2-Emissionen (Maßeinheit)',
          label: 'CO2-Emissionen (Maßeinheit)',
          defaultValue: '',
          validation: []
        }
      ]
    }
  ]
};

export const kraftstoffverbrauchSectionFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_verbrauch',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Kraftstoffverbrauch kombiniert',
          label: 'Kraftstoffverbrauch kombiniert',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_fuelconsumption_out_of_city',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Kraftstoffverbrauch außerorts',
          label: 'Kraftstoffverbrauch außerorts',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_fuelconsumption_urban',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Kraftstoffverbrauch innerorts',
          label: 'Kraftstoffverbrauch innerorts',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_stromverbrauch',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Stromverbrauch',
          label: 'Stromverbrauch',
          defaultValue: '',
          validation: []
        }
      ]
    }
  ]
};

export const organisationSectionFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'new_sparteid',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getCompanies',
          placeholder: 'Bitte auswählen',
          label: 'Unternehmen',
          defaultValue: '',
          options: [],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      doubleField: true,
      fields: [
        {
          name: 'new_tuv',
          type: 'date',
          crmFieldType: crmDataTypes.DateTime,
          placeholder: 'Nächste HU',
          label: 'Nächste HU',
          defaultValue: '',
          validation: []
        },
        {
          name: 'new_uvv',
          type: 'date',
          crmFieldType: crmDataTypes.DateTime,
          placeholder: 'Nächste UVV',
          label: 'Nächste UVV',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_kostenstelleid',
          type: 'lookup',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getKostenstellen',
          allowCreateNew: true,
          addNewTitle: 'Neue Kostenstelle anlegen',
          placeholder: 'Kostenstelle',
          label: 'Kostenstelle',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_kstverantw',
          type: 'lookup',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getUserCompanyActiveDrivers',
          placeholder: 'KST-Verantwort.',
          label: 'KST-Verantwort.',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_kostenstelle2',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          data: [],
          source: 'getKostenstellen',
          allowCreateNew: true,
          addNewTitle: 'Neue Kostenstelle 2 anlegen',
          placeholder: 'Kostenstelle 2',
          label: 'Kostenstelle 2',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_interne_fahrzeugnummer_kunde',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Interne Fahrzeugnummer',
          label: 'Interne Fahrzeugnummer',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_kostenstelle3',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          data: [],
          source: 'getKostenstellen',
          allowCreateNew: true,
          addNewTitle: 'Neue Kostenstelle 3 anlegen',
          placeholder: 'Kostenstelle 3',
          label: 'Kostenstelle 3',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_hu_interval_monatecode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'HU Interval',
          label: 'HU Interval',
          defaultValue: '',
          options: HUInterval,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      doubleField: true,
      fields: [
        {
          name: 'new_abgangsdatum',
          type: 'date',
          crmFieldType: crmDataTypes.DateTime,
          placeholder: 'Abgangsdatum',
          label: 'Abgangsdatum',
          defaultValue: '',
          validation: []
        },
        {
          name: 'uds_mileage_on_return',
          type: 'text',
          crmFieldType: crmDataTypes.Integer,
          placeholder: 'Km-Stand bei Rückgabe',
          label: 'Km-Stand bei Rückgabe',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'new_zuordnung',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          label: 'Zuordnung',
          defaultValue: '',
          options: zuordnung,
          validation: []
        }
      ]
    },

    {
      width: '100%',
      fields: [
        {
          name: 'new_zuordnung_beschreibung',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Zuordnung Beschreibung',
          label: 'Zuordnung Beschreibung',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_ersatzbestellung',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Ersatzbeschaffung',
          label: 'Ersatzbeschaffung',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'uds_iserinnerungen_zulassen',
          type: 'toggle',
          crmFieldType: crmDataTypes.Boolean,
          placeholder: 'Erinnerungen',
          label: 'Erinnerungen',
          defaultValue: false,
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'tankkartes',
          type: 'text',
          defaultValue: '',
          disabled: true,
          placeholder: 'Tankkarten',
          label: 'Tankkarten',
          validation: []
        }
      ]
    }
  ]
};

export const additionalDocumentsTypeFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: '',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Dokumententyp',
          defaultValue: '',
          options: [],
          source: 'getFileDescriptions',
          validation: []
        }
      ]
    }
  ]
};

export const newKostenstelleFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'name',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'Text',
          defaultValue: '',
          label: 'Kostenstelle Name',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'maxLength',
              value: 100
            }
          ]
        },
        {
          name: 'new_sparteid',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Lookup,
          data: [],
          source: 'getCompanies',
          placeholder: 'Bitte auswählen',
          label: 'Unternehmen',
          defaultValue: '',
          options: [],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export class NeueFahrzeugValues {
  constructor(data) {
    this.new_fuhrparkid = data.new_fuhrparkid || '';
    this.new_name = data.new_name || '';
    this.new_sparteid = data.new_sparteid || '';
    this.new_hersteller = data.new_hersteller || '';
    this.new_model = data.new_model || '';
    this.new_ezl = data.new_ezl ? new Date(data.new_ezl) : null;
    this.new_fahrerid = data.new_fahrerid || '';
  }
}

export class NeueFahrzeugValuesExtended {
  constructor(data) {
    this.new_fuhrparkid = data.new_fuhrparkid || '';
    this.new_name = data.new_name || '';
    this.new_hersteller = data.new_hersteller || '';
    this.new_model = data.new_model || '';
    this.new_ezl = data.new_ezl ? new Date(data.new_ezl) : null;
    this.new_fahrerid = data.new_fahrerid || '';
    this.new_fin = data.new_fin || '';
    this.new_kauf_leasing = data.new_kauf_leasing || '';
    this.new_standortid = data.new_standortid || '';
    this.uds_fahrzeugpreise = data.uds_fahrzeugpreise || '';
    this.new_status_carcode = data.new_status_carcode || '';
    this.uds_silverdatvehicletypeoption = data.uds_silverdatvehicletypeoption || '';
  }
}

export class GetriebeSectionValues {
  constructor(data) {
    this.uds_gearbox = data.uds_gearbox || '';
    this.uds_hubraum = data.uds_hubraum || '';
    this.uds_bodyname = data.uds_bodyname || '';
    this.uds_leistung = data.uds_leistung || '';
    this.uds_enginetypecode = data.uds_enginetypecode || '';
  }
}

export class UmweltSectionValues {
  constructor(data) {
    this.uds_co2_efficiencycode = data.uds_co2_efficiencycode || '';
    this.uds_emissioncategorycode = data.uds_emissioncategorycode || '';
    this.uds_co2_emission_combined = data.uds_co2_emission_combined || '';
    this.uds_feinstaubplakette = data.uds_feinstaubplakette || '';
    this.uds_co2_emission_uom = data.uds_co2_emission_uom || '';
  }
}

export class KraftstoffverbrauchSectionValues {
  constructor(data) {
    this.uds_verbrauch = data.uds_verbrauch || '';
    this.uds_fuelconsumption_out_of_city = data.uds_fuelconsumption_out_of_city || '';
    this.uds_fuelconsumption_urban = data.uds_fuelconsumption_urban || '';
    this.uds_stromverbrauch = data.uds_stromverbrauch || '';
  }
}

export class OrganizationSectionValues {
  constructor(data) {
    this.new_sparteid = data.new_sparteid || '';
    this.new_tuv = data.new_tuv ? new Date(data.new_tuv) : null;
    this.new_uvv = data.new_uvv ? new Date(data.new_uvv) : null;
    this.new_zuordnung = data.new_zuordnung || '';
    this.uds_hu_interval_monatecode = data.uds_hu_interval_monatecode || '';
    this.new_kostenstelleid = data.new_kostenstelleid || '';
    this.uds_kostenstelle2 = data.uds_kostenstelle2 || '';
    this.uds_kostenstelle3 = data.uds_kostenstelle3 || '';
    this.new_zuordnung_beschreibung = data.new_zuordnung_beschreibung || '';
    this.new_kstverantw = data.new_kstverantw || '';
    this.uds_ersatzbestellung = data.uds_ersatzbestellung || '';
    this.new_abgangsdatum = data.new_abgangsdatum ? new Date(data.new_abgangsdatum) : null;
    this.tankkartes = data.tankkartes ? data.tankkartes.map(tankkart => getFuelCardTitle(tankkart)).join('; ') : '';
    this.uds_mileage_on_return = data.uds_mileage_on_return || '';
    this.new_versichererid = data.new_versichererid || '';
    this.uds_iserinnerungen_zulassen = !!data.uds_iserinnerungen_zulassen;
    this.uds_interne_fahrzeugnummer_kunde = data.uds_interne_fahrzeugnummer_kunde || null;
  }
}
