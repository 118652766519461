import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import classes from './checkbox.module.scss';

function Checkbox(props) {
  const changeHandler = () => {
    if (props.onChange && !props.inputProps.disabled) {
      props.onChange(props.inputProps.name, !props.inputProps.checked);
    }
  };

  return (
    <div className={classes.container}>
      <input className={classes.styled_checkbox} {...props.inputProps} type='checkbox' />
      <span
        className={classnames(classes.checkmark, { [classes.disabled]: props.inputProps.disabled })}
        onClick={changeHandler}
      />
    </div>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.object.isRequired
};

export default Checkbox;
