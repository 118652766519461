import React, { ReactElement } from 'react';
import { StyledPopup, StyledButtonContainer } from './checkout-popup.styled';
import { Button, Title, Flex } from 'common/common-components.styled';
import { useHistory } from 'react-router-dom';
import { PrefixForId } from 'common/enums';
import { PAGES } from 'common/enums';

interface CheckoutPopupProps {
  parameters: {
    children: ReactElement;
    isCloseAvailable: boolean;
    closePopup: () => void;
    title: string;
  };
}

const CheckoutPopup = ({ parameters }: CheckoutPopupProps) => {
  const history = useHistory();

  const handleButtonClick = () => {
    parameters.closePopup && parameters.closePopup();
    history.push(PAGES.PACKAGES);
  };

  return (
    <StyledPopup id={'CheckoutModal'}>
      <Flex justify='space-between' width='100%'>
        <Title>{parameters.title.toLocaleUpperCase()}</Title>
        {parameters.isCloseAvailable && (
          <img
            onClick={parameters.closePopup}
            src='/assets/images/close-menu-icon.svg'
            alt='close-menu'
            id={PrefixForId.Modal + PrefixForId.Button + PrefixForId.Close}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Flex>

      {parameters.children}

      <StyledButtonContainer>
        <Button onClick={handleButtonClick} id={PrefixForId.Modal + PrefixForId.Button + PrefixForId.Submit}>
          Zur Paket- und Preisübersicht →
        </Button>
      </StyledButtonContainer>
    </StyledPopup>
  );
};

export default CheckoutPopup;
