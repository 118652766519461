import React, { Children, useContext } from 'react';
import { FilterExpandContainerContext } from '../../filter-expand-container.context';
import { SliceChildrenContainer } from 'components/containers/slice-children-container/slice-children-container';

export const FilterChildren = () => {
  const { isFilterExpanded, abbreviatedLength, children } = useContext(FilterExpandContainerContext);

  return (
    <>
      <SliceChildrenContainer end={abbreviatedLength}>{children}</SliceChildrenContainer>

      {isFilterExpanded && (
        <SliceChildrenContainer start={abbreviatedLength} end={Children.toArray(children).length}>
          {children}
        </SliceChildrenContainer>
      )}
    </>
  );
};
