import React from 'react';
import { IconProps } from 'common/interfaces';

export const UploadedImageIcon = ({ width = 102, height = 60, className, color = '#808080' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 102 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        // eslint-disable-next-line max-len
        d='M38.253 18.0895C38.253 17.6349 38.0258 16.9531 38.0258 16.4986C38.0258 9.90763 43.4803 4.45306 50.0713 4.45306C54.2795 4.45306 58.0245 6.67672 60.1822 10'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        // eslint-disable-next-line max-len
        d='M38.2532 18.0897C37.1168 17.8624 35.9804 17.6351 34.8441 17.6351C28.2531 17.6351 22.7986 23.0897 22.7986 29.6806C22.7986 36.2716 28.2531 41.7261 34.8441 41.7261H65.4999'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M65.2986 41.726C71.8895 41.726 77.3441 36.2715 77.3441 29.6805C77.3441 24.3775 73.813 19.8102 69 18.2277'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        // eslint-disable-next-line max-len
        d='M49.8775 33.3416C49.5665 33.3416 49.3592 33.238 49.1519 33.0307L42.311 25.7751C41.8963 25.3605 41.8963 24.7386 42.311 24.324C42.7256 23.9094 43.3475 23.9094 43.7621 24.324L49.7738 30.6467L64.1813 13.026C64.5959 12.6114 65.2178 12.5078 65.6324 12.9224C66.047 13.337 66.1507 13.9589 65.7361 14.3735L50.7067 33.0307C50.4994 33.238 50.1884 33.3416 49.8775 33.3416Z'
        fill={color}
      />
    </svg>
  );
};