import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const FuelCardIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <>
          <path
            d='M3.31445 17.6233V1.98158C3.31445 1.47022 3.68864 1.07918 4.17795 1.07918H13.3886C13.8779 1.07918 14.2521 1.47022 14.2521 1.98158V17.6233'
            fill={fill}
          />
          <path
            d='M14.252 2.80583L17.1303 5.39575L18.2816 6.08639C18.4543 6.2015 18.5694 6.37416 18.5694 6.5756V10.5756H18.1377C17.8786 10.5756 17.6484 10.4029 17.5908 10.1439L16.8424 7.41013L16.0481 7.19301L15.8731 6.27938L14.252 4.53244'
            fill={fill}
          />
        </>
      )}
      <path
        d='M14.252 2.80583L17.1303 5.39575L18.2816 6.08639C18.4543 6.2015 18.5694 6.37416 18.5694 6.5756V10.5756H18.1377C17.8786 10.5756 17.6484 10.4029 17.5908 10.1439L16.8424 7.41013L16.0481 7.19301L15.8731 6.27938L14.252 4.53244'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.31445 7.51611V1.98158C3.31445 1.47022 3.68864 1.07918 4.17795 1.07918H13.3886C13.8779 1.07918 14.2521 1.47022 14.2521 1.98158V17.6233'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.16309 18.9208V17.7697H15.4034V18.9208'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.2366 3.09358H5.32861V6.5468H12.2366V3.09358Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.252 10.5756H15.2594C15.8062 10.5756 16.2668 11.036 16.2668 11.5828V15.6115C16.2668 16.1583 16.7273 16.6187 17.2742 16.6187C17.8211 16.6187 18.2816 16.1583 18.2816 15.6115V10.5756'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.18347 9.35125H9.47421C9.81198 9.35125 10.0883 9.62761 10.0883 9.96538V15.0319C10.0883 15.3697 9.81198 15.6461 9.47421 15.6461H8.49815H1.18347C0.845694 15.6461 0.569336 15.3697 0.569336 15.0319V9.96538C0.569336 9.62761 0.845694 9.35125 1.18347 9.35125Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='1.73145' y='10.6909' width='1.41336' height='1.69515' rx='0.3' fill={color} />
      <path d='M0.675781 13.8771H9.9817' stroke={color} strokeLinecap='round' />
    </svg>
  );
};
