import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useClearErrorOnValueChange = (
  reactHookFormData: UseFormReturn,
  watchedValue: string,
  clearErrorList: string[]
) => {
  const { watch, clearErrors } = reactHookFormData;

  useEffect(() => {
    clearErrors(clearErrorList);
  }, [watch(watchedValue)]);
};
