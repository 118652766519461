import React, { useEffect, useState } from 'react';
import { Flex, Text, Button } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { cloneDeep, isEmpty } from 'lodash';
import { tacitlyExtendLeaseAgreementFormFields } from './tacitly-extend-lease-agreement-form-fields';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import moment from 'moment';
// eslint-disable-next-line max-len
import { TacitlyExtendLeaseAgreementProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';
import { CustomPickerDay } from 'components/custom-colored-picker-day/custom-colored-picker-day';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';

const TacitlyExtendLeaseAgreement = ({
  plateNumber,
  onCancel,
  onSubmit,
  monthsAmount,
  leaseEndDate
}: TacitlyExtendLeaseAgreementProps) => {
  const theme = useTheme();

  const reactHookFormData = useForm();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const [currentAgreementFields, setCurrentAgreementFields] = useState(tacitlyExtendLeaseAgreementFormFields);

  useEffect(() => {
    const newAgreementFields = cloneDeep(tacitlyExtendLeaseAgreementFormFields);

    newAgreementFields[0] = {
      ...newAgreementFields[0],
      minDate: moment(leaseEndDate).startOf('day'),
      renderDay: (day: unknown, _value: unknown[], DayComponentProps: PickersDayProps<unknown>) => (
        <CustomPickerDay
          day={day}
          DayComponentProps={DayComponentProps}
          customDate={leaseEndDate}
          tooltipText={TOOLTIP_MESSAGE}
        />
      )
    };

    setCurrentAgreementFields(newAgreementFields);
  }, [leaseEndDate]);

  const submit = () => {
    handleSubmit(data => {
      onSubmit({
        nextPhase: LeaseExtentionPhases.Phase10,
        silentExtendDate: moment(data[LeasingContractFields.SilentExtendDate]).format('YYYY-MM-DDTHH:mm:ss'),
        successMessage: SUCCESS_MESSAGE
      });
    })();
  };
  return (
    <Flex direction='column'>
      <Text id={'areYouSureContent'} color={theme.colors.black} bottom='30'>
        Bist du sicher, dass du den Leasingvertrag für <strong>{plateNumber.attributeValue?.name || ''}</strong> um{' '}
        <strong>{monthsAmount || '-'} Monate </strong> stillschweigend verlängern möchtest?
      </Text>
      <FormHook reactHookFormData={reactHookFormData} formFields={currentAgreementFields} />
      <Flex justify='flex-end'>
        <Button right='10' secondary onClick={onCancel}>
          Abbrechen
        </Button>
        <Button id={'confirmButton'} disabled={!isEmpty(errors)} onClick={submit}>
          Bestätigen
        </Button>
      </Flex>
    </Flex>
  );
};

const SUCCESS_MESSAGE = 'Deine Entscheidung wurde erfolgreich gespeichert';

// eslint-disable-next-line max-len
const TOOLTIP_MESSAGE =
  'Dies ist das aktuelle Rückgabedatum des Fahrzeugs, das sich bereits im System befindet. Es ist nicht möglich, ein früheres Datum als dieses auszuwählen.';

export default TacitlyExtendLeaseAgreement;
