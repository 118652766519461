import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const LogoutIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.0508 12.9032L18.954 10L16.0508 7.09677'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M18.954 10H10.825' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M0.954102 17.8387L7.63152 19V1L0.954102 2.16129V17.8387Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.9541 18.129H13.7283V14.6452'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.9541 1.87099H13.7283V5.64518'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
