import React from "react";

export default function AllInclusive(props) {
  return (
    <div onClick={(e) => e.stopPropagation()} className="alles-inklusive-container">
      <div className='alles-inklusive-close-wrapper'>
        <img
          className='alles-inklusive-close-btn'
          onClick={props.close}
          src="/assets/images/close.png"
          alt="Close"/>
      </div>
      <h4>Das ist inklusive für Sie</h4>
      <div className='d-flex alles-inklusive-items-wrapper'>
        <ul className="item">
          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/guarantee-32.png" alt="Vollkasko-Versicherung" />
              Vollkasko-Versicherung
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/steuer-32.png" alt="Kfz-Steuer" />
              Kfz-Steuer
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/zoll-32.png" alt="GEZ Gebühr" />
              GEZ Gebühr
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/winter.png" alt="Winterräder inkl.Lagerung" />
              Winterräder inkl.Lagerung
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/rad-32.png" alt="Reifenersatz" />
              Reifenersatz
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/inspektion-32.png" alt="Wartung/Inspektion" />
              Wartung/Inspektion
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/tankstelle-32.png" alt="Tankkarte" />
              Tankkarte
            </span>
            <span></span>
          </li>
        </ul>
        <ul className="item">
          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/deutschlandkarte-32.png" alt="Bundesweite Partnerwerkstätten" />
              Bundesweite Partnerwerkstätten
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/letzte-24-stunden-32.png" alt="24 h Fahrerbetreuung und Pannenservice" />
              24 h Fahrerbetreuung und<br /> Pannenservice
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img
                src="/assets/images/autovermietung-32.png"
                alt="Schaden-Management kostenloser Kleinwagen im Eigenschadenfall"
              />
              Schaden-Management kostenloser <br />
              Kleinwagen im Eigenschadenfall
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/identitatsdiebstahl-32.png" alt="Führerscheinkontrolle per App" />
              Führerscheinkontrolle per App
            </span>
            <span />
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/alarm-an-32.png" alt="Terminwarner Leasing, HU/AU" />
              Terminwarner Leasing, HU/AU
            </span>
            <span />
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/polizei-abzeichen-32.png" alt="Knöllchenbearbeitung" />
              Knöllchenbearbeitung
            </span>
            <span></span>
          </li>

          <li>
            <span style={{color: '#373B4D'}} className="label">
              <img src="/assets/images/registrierkasse-32.png" alt="Bequeme Monatsrechnung" />
              Bequeme Monatsrechnung
            </span>
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  );
}
