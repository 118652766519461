import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './tooltip.module.scss';
import { CircleQuestionMarkIcon } from '../icons/circle-question-mark';
import { CloseIcon } from '../icons/close-icon';
import classNames from 'classnames';
import { removeSpaces } from '../../utils/remove-spaces';

export const Tooltip = ({
  title,
  description,
  icon,
  tooltipClassName,
  titleClassName,
  descriptionClassName,
  tooltipOpenByClick,
  closeIconOptions,
  closeIconClassName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef();

  const closeHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };

  const openHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    const handleClickOutside = e => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={classNames(classes.tooltip, tooltipClassName)} ref={wrapperRef}>
      <div
        onClick={tooltipOpenByClick ? e => openHandler(e) : null}
        onMouseEnter={tooltipOpenByClick ? null : openHandler}
        onMouseLeave={tooltipOpenByClick ? null : closeHandler}
        className={classes.header_wrapper}
      >
        <span id={removeSpaces(title)} className={classNames(classes.title, titleClassName)}>
          {title}
        </span>

        <div className={classes.tooltip_icon_wrapper}>{icon ? icon : <CircleQuestionMarkIcon />}</div>
      </div>

      {description && isOpen && (
        <div
          className={classNames(
            tooltipOpenByClick ? classes.description_on_click : classes.description,
            descriptionClassName
          )}
        >
          {description}

          {tooltipOpenByClick && (
            <div className={classNames(classes.close_icon, closeIconClassName)} onClick={e => closeHandler(e)}>
              <CloseIcon color='#102F3F' width={14} height={14} {...closeIconOptions} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.any, PropTypes.string]).isRequired,
  icon: PropTypes.any,
  tooltipClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  tooltipOpenByClick: PropTypes.bool,
  closeIconOptions: PropTypes.shape({
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  closeIconClassName: PropTypes.string
};
