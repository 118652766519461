import React from 'react';
import { ISingleTabContext, SingleTabProps } from './single.props';
import { SingleTabContext } from './single.context';
import { Flex } from 'common/common-components.styled';
import { TabList } from './components/tab-list/tab-list';
import { PanelList } from './components/panel-list/panel-list';
import { useSetActiveTabIndex } from './hooks/use-set-active-tab-index';

export const SingleTab = ({ data, ui, activeTab, ...restProps }: SingleTabProps) => {
  const { activeTabIndex, setActiveTabIndex } = useSetActiveTabIndex(activeTab?.value);
  const displayedData = data.filter(item => !item.isHide);

  const toggleTab = (index: number) => {
    if (activeTab) {
      return activeTab.setValue(index);
    }
    setActiveTabIndex(index);
  };

  const contextValue: ISingleTabContext = {
    activeTabIndex,
    toggleTab,
    data: displayedData,
    ui
  };

  return (
    <SingleTabContext.Provider value={contextValue}>
      <Flex {...restProps} direction='column' width='100%'>
        {displayedData.length > 1 && <TabList />}
        <PanelList />
      </Flex>
    </SingleTabContext.Provider>
  );
};
