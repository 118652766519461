import React from 'react';
import { FOCUS_BLOCK_HEIGHT } from '../../utils';
import { Text } from 'common/common-components.styled';
import { StyledFocusTimeBlock } from './focus-block.styled';

export const FocusBlock = () => {
  return (
    <StyledFocusTimeBlock height={`${FOCUS_BLOCK_HEIGHT}px`}>
      <Text color='black' size='18'>
        :
      </Text>
    </StyledFocusTimeBlock>
  );
};
