/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide.module.css';
import slideReverse from '../transition/damage-backward-slide.module.css';
import slideReturn from '../transition/return-slide.module.css';
import sideSlide from '../transition/side-slide.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import DamagePartChoose from '../damage-part-component/damage-part-choose';
import CarDamageIcon from '../icons/car-damage';
import PlusIcon from '../icons/plus';
import useWindowSize from 'hooks/use-window-size';
import { damage } from '../utils';
import CarIconMobile from '../car-icon-mobile-component/car-icon-mobile';
import ArrowLeftBigIcon from '../icons/arrow-left-big';
import { StepProps, StepStylesProps, DamagePartsItemProps, OtherDamagePartsItemProps } from '../report-damage.props';

const ChooseBurglaryDamage = ({ reactHookFormData, handleNext }: StepProps) => {
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showChooseDamage, setShowChooseDamage] = useState<boolean>(false);
  const [showChooseDamageSlider, setShowChooseDamageSlider] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const [showBlockEvidence, setShowBlockEvidence] = useState<boolean>(false);
  const { windowWidth } = useWindowSize();
  const {
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    watch('hasDamage') !== undefined &&
    (watch('hasDamage') === false ||
      (Array.isArray(watch('damageParts')) &&
        watch('damageParts').length > 0 &&
        watch('damageParts').filter((el: OtherDamagePartsItemProps) => el[1].type === true).length < 1)) &&
    anyEvidenceFields &&
    autoIsNotOkay &&
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    !!watch('whatWasStolen') &&
    watch('whatWasStolen').length > 0 &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    watch('hasDamage') === true ? setShowChooseDamage(true) : setShowChooseDamage(false);
    setTimeout(() =>
      watch('hasDamage') === true ? setShowChooseDamageSlider(true) : setShowChooseDamageSlider(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    setTimeout(() => (watch('anyEvidence') === true ? setShowBlockEvidence(true) : setShowBlockEvidence(false)), 100);
  }, [watch('anyEvidence')]);

  const anyEvidenceFields =
    (!!watch('anyEvidence') && !!watch('anyEvidenceClues') && watch('anyEvidenceClues').length > 0) ||
    watch('anyEvidence') === false;

  const autoIsNotOkay =
    (watch('autoIsOkay') === false &&
      !!watch('autoIsNotOkayFirma') &&
      !!watch('autoIsNotOkayAdress') &&
      !!watch('autoIsNotOkayTelephone')) ||
    !!watch('autoIsOkay');

  const songstigeValue = () => {
    const songstigeItem = { type: true, value: 100000041 };
    const songstige =
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      !!watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        ? watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        : [];
    if (
      (songstige.length === 0 && songstige.length <= 8) ||
      (songstige.length <= 8 && songstige.some((el: OtherDamagePartsItemProps) => el[1].type !== true))
    ) {
      const newSongstigeITem = ['Sonstiges', songstigeItem];
      const damagePartsArray = Array.isArray(watch('damageParts')) ? watch('damageParts') : [];
      damagePartsArray.push(newSongstigeITem);
      setValue('damageParts', damagePartsArray);
    }
  };

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  useEffect(() => {
    if (watch('hasDamage') === false) {
      setValue('carDamage', undefined);
      setValue('damageParts', undefined);
      setValue('damageList', damage);
    }
  }, [watch('hasDamage')]);

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const checkDamageParts = () => {
    if (
      !!watch('hasDamage') &&
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      watch('damageParts').length > 0
    ) {
      watch('damageParts').forEach((item: OtherDamagePartsItemProps) => {
        if (item[1].type === true) {
          setError(`damageParts.${item[0]}`, {
            type: 'manual',
            message: 'Error'
          });
        }
      });
    }
  };

  useEffect(() => {
    !watch('hasDamage') && clearErrors('damageParts');
  }, [watch('hasDamage')]);

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      !!watch('hasDamage') && checkDamageParts();
      checkButtons(['hasDamage', 'anyEvidence', 'damagePrivatOrBusinessTrip', 'autoIsOkay']);
    }
  };

  useEffect(() => {
    if (watch('autoIsOkay') === true) {
      clearErrors(['autoIsNotOkayAdress', 'autoIsNotOkayFirma', 'autoIsNotOkayTelephone']);
    }
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    if (!watch('anyEvidence')) {
      clearErrors('anyEvidenceClues');
    }
  }, [watch('anyEvidence')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <div style={showChooseDamage ? { display: 'flex' } : {}}>
          <div className={styles.buttons_damage_block}>
            <TwoButtonsComponent
              reactHookFormData={reactHookFormData}
              fieldName='Sind an deinem Fahrzeug Schäden entstanden?'
              fieldRegister='hasDamage'
              fieldRegisterSecond='hasDamage'
            />

            {showChooseDamage && windowWidth < 1400 && watch('damageList') !== undefined && (
              <div style={{ overflow: 'hidden' }}>
                <CSSTransition
                  in={showChooseDamageSlider}
                  timeout={400}
                  classNames={slideQuest}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={styles.songstige_block_small}>
                    {windowWidth < 900 ? (
                      <CarIconMobile reactHookFormData={reactHookFormData} />
                    ) : (
                      <CarDamageIcon reactHookFormData={reactHookFormData} />
                    )}
                    <div
                      className={
                        windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full'
                          ? styles.songstige_block_mobile
                          : styles.songstige_block
                      }
                    >
                      {windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full' && (
                        <div
                          id='returnCarImageButton'
                          className={styles.back_item}
                          onClick={() => setValue('carImageMobile', 'full')}
                        >
                          <ArrowLeftBigIcon />
                          <div className={styles.back_text}>Zurück</div>
                        </div>
                      )}
                      <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                        <PlusIcon />
                        <Distance side='10' />
                        Sonstige Teile
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            )}
            {showChooseDamage && <DamagePartChoose reactHookFormData={reactHookFormData} />}
          </div>
          {showChooseDamage && windowWidth >= 1400 && watch('damageList') !== undefined && (
            <div style={{ overflow: 'hidden' }}>
              <CSSTransition
                in={showChooseDamageSlider}
                timeout={600}
                classNames={sideSlide}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <CarDamageIcon reactHookFormData={reactHookFormData} />
                  <div className={styles.songstige_block}>
                    <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                      <PlusIcon />
                      <Distance side='10' />
                      Sonstige Teile
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>
        {watch('hasDamage') ? <Distance top='10' /> : <Distance top='20' />}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Gibt es Hinweise auf den/die Täter?'
          fieldRegister='anyEvidence'
          fieldRegisterSecond='anyEvidence'
        />

        <Distance top='20' />

        {!!watch('anyEvidence') && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockEvidence} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='anyEvidenceClues'
                  fieldName='Hinweise auf den/die Täter'
                />

                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        {watch('autoIsOkay') === false && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockAutoIsOk} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Wo kann das Fahrzeug besichtigt werden?</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayFirma'
                  fieldName='Firma/Name'
                  fieldPlaceholder='Muster GmbH'
                />

                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayAdress'
                  fieldName='Anschrift'
                  fieldPlaceholder='Musterstr. 100 12345 Berlin'
                />

                <Distance top='5' />

                <PhoneFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='autoIsNotOkayTelephone'
                  fieldName='Telefon'
                />

                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='whatWasStolen'
          fieldName='Was wurde gestohlen?'
        />

        <Distance top='15' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnToTypeButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ChooseBurglaryDamage;
