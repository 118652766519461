import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function clearCRMCache(): Promise<AxiosResponse<string>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/maintenance/clearCache`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
