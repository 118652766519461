import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const WalletIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M17.5345 17.8386V15.2257V4.85271L16.3734 3.87984L1.27969 3.75012V17.258C1.27969 18.2161 2.06341 18.9999 3.02128 18.9999H16.3734C17.012 18.9999 17.5345 18.4773 17.5345 17.8386Z'
          fill={fill}
        />
      )}
      <path
        d='M15.2124 1C15.851 1 16.3734 1.52258 16.3734 2.16129L16.3734 3.90323H2.73103C1.88926 3.90323 1.19263 3.17742 1.27971 2.30645C1.36679 1.55161 2.0344 1 2.78909 1H15.2124Z'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M17.5345 15.2257V17.8386C17.5345 18.4773 17.012 18.9999 16.3734 18.9999H3.02128C2.06341 18.9999 1.27969 18.2161 1.27969 17.258V2.45154M16.3734 2.16129C16.3734 1.52258 15.851 1 15.2124 1H2.78909C2.0344 1 1.36679 1.55161 1.27971 2.30645C1.19263 3.17742 1.88926 3.90323 2.73103 3.90323H16.3734C17.012 3.90323 17.5345 4.42581 17.5345 5.06452V7.67742'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7292 9.12897C10.3359 9.12897 9.20391 10.3774 9.43612 11.829C9.61028 12.9612 10.6552 13.7741 11.7873 13.7741C13.5869 13.7741 17.3313 13.7741 18.6955 13.7741C19.0148 13.7741 19.2761 13.5128 19.2761 13.1935V9.70961C19.2761 9.39026 19.0148 9.12897 18.6955 9.12897H11.7292Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7584 12.381C12.2554 12.381 12.6584 11.978 12.6584 11.4808C12.6584 10.9837 12.2554 10.5806 11.7584 10.5806C11.2613 10.5806 10.8584 10.9837 10.8584 11.4808C10.8584 11.978 11.2613 12.381 11.7584 12.381Z'
        fill={color}
      />
    </svg>
  );
};
