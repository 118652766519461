import React, { useContext } from 'react';
import ClearAllFiltersButton from 'components/filter-items/clear-all-filters-button/clear-all-filters-button';
import { ClearAllFiltersButtonProps } from 'components/filter-items/clear-all-filters-button/clear-all-filters-button.props';
import classNames from 'classnames';
import { FilterExpandContainerContext } from '../filter-expand-container.context';
import useWindowSize from 'hooks/use-window-size';

export const FilterExpandClearButton = (props: ClearAllFiltersButtonProps) => {
  const { minWindowWidthForCompressClearButton } = useContext(FilterExpandContainerContext);

  const { windowWidth } = useWindowSize(200);

  const getButtonIsExpanded = () => {
    if (minWindowWidthForCompressClearButton) {
      return windowWidth > minWindowWidthForCompressClearButton;
    }
  };

  return (
    <ClearAllFiltersButton
      className={classNames('nowrap-white-space', props.className)}
      isExpanded={getButtonIsExpanded()}
      {...props}
    />
  );
};
