export const ORGANIZATION_TOOLTIP = {
  new_leasinggeber:
    'Der Leasinggeber des Fahrzeugs wird durch den Dienstleister der verknüpften Finanzrate bestimmt. Die Finanzrate kannst du unter dem Abschnitt Leasing & Leistungen finden und editieren.',
  new_leasingstart:
    'Der Leasingstart des Fahrzeugs wird durch den Laufzeitbeginn der verknüpften Finanzrate bestimmt. Die Finanzrate kannst du unter dem Abschnitt Leasing & Leistungen finden und editieren.',
  uds_leasinglaufzeit:
    'Die Leasinglaufzeit wird einvernehmlich im Leasingvertrag bestimmt. Üblicherweise beträgt sie zwischen 12 und 60 Monaten. Bitte editiere die Leasinglaufzeit in der verknüpften Finanzrate. Diese findest du unter dem Abschnitt Leasing & Leistungen.',
  new_leasingende:
    'Das Leasingende des Fahrzeugs wird durch das Gültigkeitsende der verknüpften Finanzrate bestimmt. Die Finanzrate kannst du unter dem Abschnitt Leasing & Leistungen finden und editieren.',
  uds_leaseenddate_extended:
    'Das Datum, bis zu dem das Leasingende stillschweigend verlängert wurde, wird aus der Finanzrate übernommen. Die Finanzrate kannst du unter dem Abschnitt Leasing & Leistungen finden und editieren.',
  new_kostenstelleid:
    'Dieses Feld ist aktuell für Änderungen gesperrt, weil die primäre Zuordnung über eine andere Entität verläuft. Bitte nimm Deine Änderungen im Fahrer / Standort des Fahrzeugs vor. '
};
