import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { CompanyQueryKeys } from '../company.props';
import { companyRequestConfig } from 'request-config/company/company.request-config';
import { ResponseModel } from 'common/interfaces';
import { getDataForTable } from 'utils/get-response-data';
import { GetCarDealershipsItem } from './get-car-dealerships.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';

export const useGetCarDealershipsQuery = (
  columns: string[],
  { options }: QueryHookOptions<GetCarDealershipsItem[], ResponseModel<GetCarDealershipsItem[]>> = {}
) => {
  const { fetch } = useFetch<ResponseModel<GetCarDealershipsItem[]>>({
    loadingKey: `getCarDealershipsQuery${JSON.stringify(columns)}`,
    ...companyRequestConfig.getCarDealerships(columns)
  });

  const { data, ...restQueryProps } = useQuery([CompanyQueryKeys.CompaniesByTypes, columns], () => fetch(), {
    select: ({ data }) => getDataForTable(data.data),
    ...options
  });

  return { getCarDealershipsQuery: data || [], ...restQueryProps };
};
