import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { cloneElement, Component, createRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { newKostenstelleFormFields } from '../../../models/forms/fahrzeug-form-model';
import { setActiveIban } from '../../../services/company-service';
import { createKostenstelle } from '../../../services/cost-centers-service';
import { getFormValues } from '../../../utils/get-form-values';
import Form from '../form-redux';
import classes from './autocomplete.module.scss';
import { newIBANFormFields } from '../../../models/forms/unternehmen-form-model';
import { Text, Flex } from '../../../common/common-components.styled';
import { PrefixForId } from '../../../common/enums';
import Spinner from 'components/spinner';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.input = createRef();
  }

  state = {
    modalOpen: false,
    inputValue: '',
    autocompleteData: [],
    itemsCount: 5,
    activeAutocompleteValue: 0
  };

  handleClose = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  //show if moreitems eq true
  showMoreItems = () => {
    if (this.props.extendedShowMore) {
      this.props.pagination.current.Start++;
      const pagination = {
        ...this.props.pagination.current,
        Start: this.props.pagination.current.Start
      };
      this.props.request(this.state.inputValue, null, pagination);
      //send request to get more options. Like getKreditorSearch, just pass it here
      //setKreditorPagination(value => {...value, value.Start+1}), then filter autocompleteData(merge, not filter)
      //if this.state.inputValue !== this.state.inputValue then set start to 1
      this.setState({ itemsCount: this.state.itemsCount + 2 });
      this.input.current.focus();

      return;
    }
    if (this.props.withModal) {
      this.setState({ modalOpen: true });
    } else {
      this.input.current.focus();
      this.setState({ itemsCount: this.state.itemsCount + 5 });
    }
    //TODO: send request with more data. Pass data as props
  };

  componentDidMount() {
    this.filterAutocompleteData(this.state.inputValue);
    if (this.props.value) {
      this.setValue();
    }
    if (this.props.inputValue) {
      this.setState({ inputValue: this.props.inputValue });
      //this.clearInputValue();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputValue !== this.props.inputValue) {
      if (this.props.inputValue) {
        this.input.current.value = this.props.inputValue;
        this.setState({ inputValue: this.props.inputValue });
        this.props.onChange({ id: '', label: '', value: this.props.inputValue });
      }
    }
    if (prevProps.data.length !== this.props.data.length || prevProps.value !== this.props.value) {
      this.filterAutocompleteData(this.state.inputValue);
      //TODO: check if inp in props
      if (!this.props.inputValue) {
        this.setValue();
      }
    }
  }

  setValue = () => {
    const value = this.props.data.find(item => item.id === this.props.value);

    if (value) {
      this.setState({ inputValue: value.label }, () => {
        this.filterAutocompleteData(this.state.inputValue);
      });
      this.props.onChange(value);
    } else {
      if (this.props.shouldFilterAutocompleteData) {
        this.filterAutocompleteData(this.state.inputValue);
      }
    }
  };

  filterAutocompleteData = inputValue => {
    const autocompleteData = this.props.data
      .filter(item => item.label?.toLowerCase().includes(inputValue.toLowerCase()))
      .map(item => ({ ...item, active: false }));
    if (autocompleteData.length && !autocompleteData[0].isDisable) {
      autocompleteData[0].active = true;
    }
    this.setState({ autocompleteData });
  };

  setAutocompleteValue = (e, isDisable, item) => {
    if (isDisable) {
      this.input.current.focus();
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const value = item || this.state.autocompleteData.find(item => item.active);
    if (value) {
      this.setState({ inputValue: value.label }, () => {
        this.filterAutocompleteData(this.state.inputValue);
      });
      this.props.onChange(value);
    }

    if (this.state.modalOpen) {
      this.setState({ modalOpen: false });
    }
  };

  clearInputValue = e => {
    this.input.current.focus();

    this.setState({
      activeAutocompleteValue: 0
    });
    this.setState({ inputValue: '' }, () => {
      this.filterAutocompleteData(this.state.inputValue);
    });
    this.props.onChange({ id: '', label: '' });
  };

  onClearInputValue = () => {
    if (this.props.clearOnFocus) {
      this.clearInputValue();
    }
  };

  setInputValue = e => {
    this.setState({ inputValue: e.target.value }, () => {
      this.filterAutocompleteData(this.state.inputValue);
    });
    this.props.onChange({ id: '', label: '', value: e.target.value });
  };

  setActiveItem = vector => {
    const autocompleteData = cloneDeep(this.state.autocompleteData);

    autocompleteData.forEach(item => {
      item.active = false;
    });

    if (vector === VECTOR.down) {
      for (let i = 1; i <= autocompleteData.length - 1; i++) {
        if (!autocompleteData[i].isDisable && i > this.state.activeAutocompleteValue) {
          autocompleteData[i].active = true;
          this.setState({ activeAutocompleteValue: i });
          break;
        }
        autocompleteData[i].active = false;
      }
    } else {
      for (let i = autocompleteData.length - 1; i >= 0; i--) {
        if (!autocompleteData[i].isDisable && i < this.state.activeAutocompleteValue) {
          autocompleteData[i].active = true;
          this.setState({ activeAutocompleteValue: i });
          break;
        }
        autocompleteData[i].active = false;
      }
    }

    this.setState({ autocompleteData: autocompleteData });
  };

  onHoverHandler = e => {
    const newAutocompleteData = cloneDeep(this.state.autocompleteData);
    let onHoverElemActiveIndex = -1;

    newAutocompleteData.forEach(item => {
      item.active = false;
    });

    const onHoverElem = newAutocompleteData.find((item, index) => {
      if (item.id === e.target.dataset.id) {
        onHoverElemActiveIndex = index;
        return true;
      }
    });

    if (onHoverElem && !onHoverElem.isDisable) {
      onHoverElem.active = true;
      this.setState({ autocompleteData: newAutocompleteData, activeAutocompleteValue: onHoverElemActiveIndex });
    }
  };

  keyDown = e => {
    if (e.which === 13) {
      // Enter
      this.setAutocompleteValue(e);
      e.target.blur();
    }
    if (e.which === 40 || e.which === 38) {
      // arrow down, arrow up
      e.preventDefault();
      if (e.which === 40) {
        this.setActiveItem(VECTOR.down);
      } else {
        this.setActiveItem(VECTOR.up);
      }
    }
  };

  createNewItem = menuTitle => {
    let formName;
    let component;
    if (this.props.name === 'iban') {
      formName = 'newIban';
      component = <Form name={formName} formFields={newIBANFormFields} />;
    } else {
      formName = 'newKostenstelle';
      component = <Form name={formName} formFields={newKostenstelleFormFields} />;
    }
    const alertData = {
      title: menuTitle,
      children: component,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: this.props.closeAlert
        },
        {
          type: 'submit',
          title: 'Speichern',
          action: () => this.submitNewItem(formName)
        }
      ]
    };

    this.props.openAlert(alertData);
  };

  submitNewItem = formName => {
    const form = {
      [formName]: { ...this.props.forms[formName] }
    };
    getFormValues(form, this.props.setForm, async values => {
      this.props.showOverlay();
      this.props.closeAlert();
      let response;
      let newItem;
      switch (this.props.name) {
        case 'iban':
          response = await setActiveIban({
            uds_name: {
              attributeValue: values.name,
              attributeTypeCode: 14
            },
            uds_accountid: {
              attributeValue: {
                id: this.props.companyId || this.props.user?.companyId,
                logicalName: '',
                name: ''
              },
              attributeTypeCode: 6
            }
          });
          newItem = { id: response.data?.data, label: values.name };
          break;
        default:
          const dataToSend = {
            kostenstelle: {
              attributes: {
                new_name: values.name,
                new_firmaid: {
                  id: values.new_sparteid,
                  logicalName: 'account',
                  name: ''
                }
              }
            }
          };
          response = await createKostenstelle(dataToSend);
          newItem = { id: response.data?.data.kostenstelleId, label: response.data?.data.name };
          break;
      }
      this.props.hideOverlay();

      this.setState({ inputValue: newItem.label }, () => {
        this.filterAutocompleteData(this.state.inputValue);
      });
      this.props.onChange(newItem);
      this.props.updateLookupData(this.props.name);
    });
  };

  handleModalInput = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInput = e => {
    this.setState({ inputValue: e.target.value });
    if (this.props.onInput) {
      this.props.onInput(this.state.inputValue);
    }
  };

  render() {
    const {
      name,
      placeholder,
      error,
      allowCreateNew,
      addNewTitle,
      withModal,
      disabled,
      isWarning,
      loading,
      nothingFoundComponent
    } = this.props;
    const { inputValue, autocompleteData, itemsCount } = this.state;

    const spinnerStyle = {
      width: '15px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    };
    const getClassName = (error, disabled, warning) => {
      const classNames = [classes.autocomplete];
      if (error) {
        classNames.push(classes.error);
      }
      if (disabled) {
        classNames.push(classes.disabled);
      }
      if (warning) {
        classNames.push(classes.warning);
      }
      return classNames.join(' ');
    };

    return (
      <div className={getClassName(error, disabled, isWarning)}>
        {loading && (
          <div className={classes.loader_container}>
            <Spinner style={spinnerStyle} />
          </div>
        )}
        <input
          name={name}
          autoComplete='off'
          onInput={this.handleInput}
          onChange={this.setInputValue}
          onKeyDown={this.keyDown}
          disabled={disabled}
          onFocus={this.onClearInputValue}
          value={inputValue}
          placeholder={placeholder}
          type='text'
          ref={this.input}
          id={PrefixForId.Form + PrefixForId.Input + 'Autocomplete' + capitalize(name)}
        />
        {inputValue && !disabled && !loading && (
          <i
            onClick={this.clearInputValue}
            className={classes.delete}
            id={PrefixForId.Form + PrefixForId.Button + PrefixForId.Close + capitalize(name)}
          />
        )}
        <div className={classes.autocomplete_list}>
          {autocompleteData.length ? (
            <ul>
              {autocompleteData.map((item, indx) => {
                if (indx >= itemsCount && !this.props.extendedShowMore) {
                  return;
                }

                return (
                  <li
                    data-id={item.id}
                    onClick={event =>
                      this.setAutocompleteValue(event, typeof item.isDisable === 'boolean' && item.isDisable, item)
                    }
                    onMouseOver={this.onHoverHandler}
                    className={classNames({
                      [classes.active]: item.active,
                      [classes.disable]: item.isDisable
                    })}
                    id={PrefixForId.Form + PrefixForId.Button + PrefixForId.DropList + capitalize(name) + indx}
                    key={item.id}
                  >
                    {withModal ? (
                      <div className={classes.list_item}>
                        <div className={classes.list_label}>{item.label}</div>
                        <div className={classes.list_value}>{item.value}</div>
                      </div>
                    ) : (
                      item.label
                    )}
                    {item.description && <p className={classes.description}>{item.description}</p>}
                  </li>
                );
              })}
              {((autocompleteData.length > itemsCount && itemsCount < autocompleteData.length) ||
                this.props.hasmoreRecords) && (
                <li
                  onClick={() => {
                    this.showMoreItems();
                  }}
                  className={classes.load_more_btn}
                  id={PrefixForId.Form + PrefixForId.Button + PrefixForId.LoadMore + capitalize(name)}
                >
                  Mehr laden...
                </li>
              )}
            </ul>
          ) : nothingFoundComponent ? (
            cloneElement(nothingFoundComponent, { ...this.props })
          ) : (
            <Text
              padding='7px'
              color='grey600'
              id={PrefixForId.Form + PrefixForId.Button + PrefixForId.NothingFound + capitalize(name)}
            >
              Nichts gefunden.
            </Text>
          )}
          {allowCreateNew && (
            <div
              onClick={() => this.createNewItem(addNewTitle || 'Neue Bankverbindung hinterlegen')}
              className={classes.create_new}
            >
              <img src='/assets/images/more.png' alt='more' />
              {addNewTitle || 'Neue Bankverbindung hinterlegen'}
            </div>
          )}
          {withModal && this.state.modalOpen && (
            <Dialog
              open={open}
              fullWidth
              className={classes.modal}
              onClose={this.handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                <p className={classes.modal_title}>{'Sachkonto wählen'}</p>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  <TextField
                    id='outlined-basic'
                    label='Suchen durch Eingabe'
                    variant='standard'
                    value={this.state.inputValue}
                    onChange={this.handleModalInput}
                    InputProps={{
                      endAdornment: <SearchIcon />
                    }}
                  />
                  <br />
                  <Flex wrap='wrap'>
                    {autocompleteData.map(item => {
                      if (item.label.toLowerCase().includes(this.state.inputValue.toLowerCase())) {
                        return (
                          <Flex
                            direction='column'
                            key={item.id}
                            width={'30%'}
                            className={classes.list_item}
                            onMouseOver={this.onHoverHandler}
                            onClick={event =>
                              this.setAutocompleteValue(event, typeof item.isDisable === 'boolean' && item.isDisable)
                            }
                            data-id={item.id}
                          >
                            <div className={classes.list_label}>{item.label}</div>
                            <div className={classes.list_value}>{item.value}</div>
                          </Flex>
                        );
                      }
                    })}
                  </Flex>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

const VECTOR = {
  down: 'down',
  up: 'up'
};

Autocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  closeAlert: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  updateLookupData: PropTypes.func.isRequired,
  allowCreateNew: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool.isRequired
};

export default Autocomplete;
