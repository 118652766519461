import React, { useContext } from 'react';
import { Button } from 'common/common-components.styled';
import { CrmDataTypes } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';
import { ChangeVehicleHistoryContext } from './change-vehicle-history.context';
import { HistoryModalFieldNames } from './history-modal.types';
import { fleetHistoryRequestConfig } from 'request-config/fleet-history/fleet-history.request.config';
import { UseFormReturn } from 'react-hook-form';
import {
  CreateVehicleHistory,
  CreateVehicleHistoryAttributes,
  UpdateVehicleHistory,
  UpdateVehicleHistoryAttributes
} from 'request-config/fleet-history/fleet-history.types';

interface SubmitButtonProps {
  reactHookFormData: UseFormReturn;
  assignCar?: (carid: string) => Promise<void>;
}

const SubmitButton = ({ reactHookFormData, assignCar }: SubmitButtonProps) => {
  const { closeModal, userId, carHistoryId, id, callback } = useContext(ChangeVehicleHistoryContext);

  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;

  const { fetch: updateVehicleHistory } = useFetch({
    loadingKey: 'updateVehicleHistory',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich gespeichert',
    errorMessage:
      'Bitte warten Sie, wir bearbeiten derzeit Änderungen. Die Funktion wird in ein paar Minuten wieder verfügbar sein',
    successCallback: () => callback()
  });

  const { fetch: createVehicleHistory } = useFetch({
    loadingKey: 'createVehicleHistory',
    isShowSuccessSnackbar: true,
    successCallback: () => callback()
  });

  const saveCostCenter = () => {
    handleSubmit(async data => {
      closeModal();
      assignCar && (await assignCar(id));
      if (carHistoryId) {
        const attributes = {} as UpdateVehicleHistoryAttributes;
        Object.values(HistoryModalFieldNames).forEach(fieldName => {
          attributes[fieldName] = {
            attributeTypeCode: CrmDataTypes.DateTime,
            attributeValue: data[fieldName] ? convertUTCDateToLocalDate(data[fieldName].toISOString()) : null
          };
        });
        const dataToSend: UpdateVehicleHistory = {
          id: carHistoryId,
          attributes
        };

        await updateVehicleHistory({
          requestConfig: fleetHistoryRequestConfig.updateVehicleHistory(dataToSend)
        });
      } else {
        const attributes = {
          uds_kostentrager: {
            attributeValue: {
              id: userId!,
              logicalName: '',
              name: ''
            },
            attributeTypeCode: 6
          },
          uds_kennzeichen: {
            attributeValue: {
              id: id,
              logicalName: '',
              name: ''
            },
            attributeTypeCode: 6
          }
        } as CreateVehicleHistoryAttributes;
        Object.values(HistoryModalFieldNames).forEach(fieldName => {
          attributes[fieldName] = {
            attributeTypeCode: CrmDataTypes.DateTime,
            attributeValue: data[fieldName] ? convertUTCDateToLocalDate(data[fieldName].toISOString()) : null
          };
        });
        const dataToSend: CreateVehicleHistory = {
          entityName: 'uds_fuhrparkhistory',
          attributes: attributes
        };
        await createVehicleHistory({
          requestConfig: fleetHistoryRequestConfig.createVehicleHistory(dataToSend)
        });
      }
    })();
  };

  return (
    <Button id='modalButtonSubmit' disabled={!isValid} onClick={saveCostCenter}>
      Speichern
    </Button>
  );
};

export default SubmitButton;
