import { connect } from 'react-redux';
import OfferPage from './offer-view';
import { setStartLoading, toggleSidebar as toggleSidebarAction } from '../../actions/app_action';

const mapStateToProps = state => ({
  sidebarOpen: state.app.listSidebarOpen,
  user: state.app.user
});
const mapDispatchToProps = dispatch => ({
  showOverlay() {
    dispatch(setStartLoading());
  },
  toggleSidebar(flag) {
    dispatch(toggleSidebarAction(flag));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
