import React, { useContext, useEffect, useState } from 'react';
import { MODAL_NAMES, MODALS, USER_ROLES } from '../../../../../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useOverlay } from 'hooks';
import { useDispatch } from 'react-redux';
import { setSnakbar, toggleModal } from 'actions/app_action';
import NoReplacementVehicle from 'components/aufgaben-component/components/no-need-replacement/no-need-replacement';
import { noNeedReplacementVehicle } from 'components/aufgaben-component/utils';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';

export const usePhaseSwitch = (phaseReverse?: boolean) => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const carId = car.new_fuhrparkid.attributeValue;
  const plateNumber = car.new_name.attributeValue;
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const [checked, setChecked] = useState(false);
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const handleNoNeedReplacement = () => {
    if (phaseReverse) {
      setChecked(false);
    } else {
      closeAlert();
      setChecked(true);
    }

    showOverlay();
    noNeedReplacementVehicle(null, carId, phaseReverse).then(response => {
      refresh();
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess
            ? 'Deine Entscheidung wurde erfolgreich gespeichert'
            : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
      hideOverlay();
    });
  };

  const onCancel = () => {
    setChecked(false);
    closeAlert();
  };

  const openNoNeedReplacementModal = () => {
    const alertData = {
      title: 'Bestätigung',
      children: (
        <NoReplacementVehicle onSubmit={handleNoNeedReplacement} onCancel={onCancel} plateNumber={plateNumber} />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: () => {
            setChecked(false);
            closeAlert();
          },
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const handleChange = (checked: boolean) => {
    if (checked) {
      openNoNeedReplacementModal();
    } else {
      handleNoNeedReplacement();
    }
  };

  useEffect(() => {
    setChecked(Boolean(phaseReverse));
  }, [phaseReverse]);

  return { isFleetManager, checked, handleChange };
};
