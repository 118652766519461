import { IUrlTreeNode } from 'common/interfaces';
import { useDispatch } from 'react-redux';
import { setCustomUrlTree } from 'actions/app_action';
import { cloneDeep } from 'lodash';

export function useModifyURLTree(initialTree: IUrlTreeNode) {
  const dispatch = useDispatch();

  function getURLTreeChild(node: IUrlTreeNode, childPath: string[]): IUrlTreeNode | undefined {
    return childPath.reduce(
      (currentNode: IUrlTreeNode | undefined, childName) => currentNode?.children?.[childName],
      node
    );
  }

  function updateURLTreeChild(
    childPath: string[],
    newNodeValues: Partial<IUrlTreeNode>,
    noNeededDashboard: boolean = false
  ): void {
    const clonedTree = cloneDeep(initialTree);
    const childNode = getURLTreeChild(clonedTree, childPath);

    if (childNode) {
      const parentNode = getURLTreeChild(clonedTree, childPath.slice(0, -1));
      if (parentNode && parentNode.children) {
        parentNode.children[childPath[childPath.length - 1]] = {
          ...childNode,
          ...newNodeValues
        };
        if (noNeededDashboard) {
          clonedTree.breadcrumbTitle = '';
        }
        dispatch(setCustomUrlTree(clonedTree));
      }
    }
  }

  return {
    urlTree: initialTree,
    updateURLTreeChild
  };
}
