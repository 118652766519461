import React from 'react';
import classes from './employee-configurations.module.scss';
import { BigFilter } from 'components/filter-items/big-filter/big-filter';
import {
  BIG_FILTER_TITLES,
  EmployeeConfigurationsBigFiltersProps,
  FILTER_COLOR
} from './employee-configurations.props';
import { BigFilterIcon } from './big-filter-icon';
import { CRM_VALUE, EMTY_FILTER_STATE } from '../../fahrzeuge-der-car-policy-page';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { SessionFilter } from 'hooks/use-session-filter';
import { PrefixForId } from 'common/enums';

export const EmployeeConfigurationsBigFilters = ({
  setSessionFilter,
  sessionFilter,
  goToFirstPage
}: EmployeeConfigurationsBigFiltersProps) => {
  const filterOptions = useTypedSelector(state => state.filter.options);
  const user = useTypedSelector(state => state.app.user);

  const clearFilter = () => {
    setSessionFilter(
      { ...EMTY_FILTER_STATE, [CRM_VALUE.company]: user.companyId },
      { ...EMTY_FILTER_STATE, [CRM_VALUE.company]: user.companyName },
      ''
    );
    goToFirstPage();
  };

  const filterToggleHandler = (sessionFilter: SessionFilter, title: BIG_FILTER_TITLES, filterName: string) => {
    if (sessionFilter.bigActiveFilter === title) {
      return clearFilter();
    }

    filterOptions?.statuses?.map((status: { id: number; name: string }) => {
      if (status.name === title) {
        setSessionFilter(
          { ...EMTY_FILTER_STATE, [CRM_VALUE.status]: status.id, [CRM_VALUE.company]: user.companyId },
          { ...EMTY_FILTER_STATE, [CRM_VALUE.status]: status.name, [CRM_VALUE.company]: user.companyName },
          filterName
        );
        goToFirstPage();
      }
    });
  };

  const isActiveFilter = (filterName: string, sessionFilter: SessionFilter) => {
    return filterName === sessionFilter.bigActiveFilter;
  };

  const getNumberOfStatusesText = (numberOfStatuses: number) => {
    return [1, 101, 1001].includes(numberOfStatuses) ? 'Ergebnis' : 'Ergebnisse';
  };

  const getSubtitle = (filterTitle: BIG_FILTER_TITLES) => {
    if (!numberOfStatuses[filterTitle] && numberOfStatuses[filterTitle] !== 0) {
      return '';
    }
    return `${numberOfStatuses[filterTitle]} ${getNumberOfStatusesText(numberOfStatuses[filterTitle])}`;
  };

  const numberOfStatuses = {
    [BIG_FILTER_TITLES.LEASING_OFFER_REQUEST]: filterOptions.configurationCount,
    [BIG_FILTER_TITLES.LEASING_VEHICLE_REQUEST]: filterOptions.freigabeCount,
    [BIG_FILTER_TITLES.RELEASE_ORDER]: filterOptions.bestellungCount
  };

  return (
    <div className={classes.container}>
      {filtersData.map((filter, index) => (
        <BigFilter
          id={'OfferStatus' + PrefixForId.Filter + index}
          key={filter.id}
          img={
            <BigFilterIcon
              title={filter.title}
              color={isActiveFilter(filter.title, sessionFilter) ? FILTER_COLOR.ACTIVE : FILTER_COLOR.DEFAULT}
              isActive={isActiveFilter(filter.title, sessionFilter)}
            />
          }
          title={filter.title}
          subtitle={getSubtitle(filter.title)}
          isActive={isActiveFilter(filter.title, sessionFilter)}
          onClick={() => filterToggleHandler(sessionFilter, filter.title, filter.title)}
        />
      ))}
    </div>
  );
};

const filtersData = [
  {
    title: BIG_FILTER_TITLES.LEASING_OFFER_REQUEST,
    id: 1
  },
  {
    title: BIG_FILTER_TITLES.LEASING_VEHICLE_REQUEST,
    id: 2
  },
  {
    title: BIG_FILTER_TITLES.RELEASE_ORDER,
    id: 3
  }
];
