export const asyncInterval = (conditionFunc, ms, tries) => {
  return new Promise((resolve, reject) => {
    let condition = null;
    const interval = setInterval(() => {
      condition = conditionFunc();
      if (condition) {
        resolve(condition);
        clearInterval(interval);
      } else if (tries <= 1) {
        reject(new Error('asyncInterval error'));
        clearInterval(interval);
      }
      if (tries) {
        tries--;
      }
    }, ms);
  });
};
