import React from 'react';
import { BudgetScaleProps } from 'pages/details/budget-scale/budget-scale.props';
import { StyledBudgetScale } from 'pages/details/budget-scale/budget-scale.styled';
import BudgetPart from 'pages/details/budget-scale/budget-part/budget-part';
import { CarWithEuroIcon } from 'components/icons/offer-statuses/car-with-euro-icon';
import { GasStationIcon } from 'components/icons/gas-station-icon';

const BudgetScale = ({ leasingPrice, fuelCost, surcharge, budget, top = '50', bottom = '20' }: BudgetScaleProps) => {
  const rest = budget + surcharge - leasingPrice - fuelCost;

  return (
    <StyledBudgetScale top={top} bottom={bottom} borderColor={rest < 0 ? 'darkPink' : 'darkBlue'}>
      <BudgetPart
        backgroundColor={rest < 0 ? 'darkPink' : 'darkBlue'}
        color='white'
        type='leasingPrice'
        icon={<CarWithEuroIcon />}
        width={(leasingPrice * 100) / (budget + surcharge)}
        price={leasingPrice}
      />

      {fuelCost > 0 && (
        <BudgetPart
          backgroundColor={rest < 0 ? 'pink' : 'blue'}
          color='white'
          type='fuelCost'
          icon={<GasStationIcon />}
          width={(fuelCost * 100) / (budget + surcharge)}
          price={fuelCost}
        />
      )}

      {rest > 0 && (
        <BudgetPart
          backgroundColor='white'
          color='blue'
          type='rest'
          width={(rest * 100) / (budget + surcharge)}
          price={rest}
        />
      )}
    </StyledBudgetScale>
  );
};

export default BudgetScale;
