import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useConsumptionWidgetVariant } from '../../hooks/use-widget-variant';
import useWindowSize from 'hooks/use-window-size';

export const CurrentConsumption = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { widgetVariant } = useConsumptionWidgetVariant();
  const { windowWidth } = useWindowSize(500);

  return (
    <Flex direction='column' align='center' bottom={widgetVariant === 'normal' ? '20' : '0'}>
      <Text color='darkBlue' size='24' bold>
        {fuhrpark?.uds_combined_consumption?.toLocaleString('de-DE') || '-'}
      </Text>
      <Text size={windowWidth <= 550 ? '14' : '16'} color='grey400' textAlign='center'>
        Aktueller Verbrauch
      </Text>
    </Flex>
  );
};
