import { IconProps } from 'common/interfaces';
import React from 'react';

export const PlusLargeIcon = ({ width = 17, height = 16, className, color = '#fff' }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line
      d='M0.5 8C0.5 7.50908 0.897969 7.11111 1.38889 7.11111L15.6111 7.11111C16.102 7.11111 16.5 7.50908 16.5 8C16.5 8.49092 16.102 8.88889 15.6111 8.88889H1.38889C0.897969 8.88889 0.5 8.49092 0.5 8Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line
      d='M8.5 0C8.99092 -3.74638e-07 9.38889 0.397969 9.38889 0.888889V15.1111C9.38889 15.602 8.99092 16 8.5 16C8.00908 16 7.61111 15.602 7.61111 15.1111V0.888889C7.61111 0.397969 8.00908 -2.62247e-07 8.5 0Z'
      fill={color}
    />
  </svg>
);
