/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { StyledTimeItem } from './time-drop-down-item.styled';
import { NUMBER_OF_VALUES_ON_THE_SIDE } from '../../utils';
import { ITimeDropDownItem } from './time-drop-down-item.props';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { useTimeout } from 'hooks/use-timeout';
import { useTime } from 'hooks/use-time';

export const TimeDropDownItem = ({
  idPrefix,
  style,
  row,
  time,
  index,
  onClick,
  isScrolling,
  onScroll
}: ITimeDropDownItem) => {
  const { field, setError } = useContext(formFieldProvider);
  const { parseTime } = useTime();
  const { createTimeout } = useTimeout();

  const onScrollHandler = () => {
    if (!isScrolling && time === parseTime(row)) {
      createTimeout(() => onScroll(Number(index) - NUMBER_OF_VALUES_ON_THE_SIDE), 0);
    }
  };

  const getId = () => {
    if (typeof row === 'string' || typeof row === 'number') {
      return idPrefix + row;
    }
    return idPrefix + index;
  };

  const onClickHandler = () => {
    if (parseTime(row) < 0) {
      setError(field.name, {});
    }

    onClick(Number(index) - NUMBER_OF_VALUES_ON_THE_SIDE, row);
  };

  useEffect(() => {
    onScrollHandler();
  }, [isScrolling]);

  return (
    <StyledTimeItem id={getId()} style={style} isSelected={time === parseTime(row)} onClick={onClickHandler}>
      {row}
    </StyledTimeItem>
  );
};
