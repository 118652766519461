import React from 'react';
import { StyledSection, StyledSectionHeader } from '../sections.styled';
import { Tab } from 'components/tab/tab.controller';
import { FormSectionProps } from './form-section.props';
import { Flex } from 'common/common-components.styled';
import useWindowSize from 'hooks/use-window-size';

export const FormSection = ({
  title,
  titleRightComponent,
  data,
  children,
  tabProps,
  ...restProps
}: FormSectionProps) => {
  const { windowWidth } = useWindowSize(500);
  return (
    <StyledSection {...restProps} direction='column' width='100%' bottom='20'>
      <Flex justify='space-between' margin={windowWidth <= 550 ? '30px 0 20px' : '0 0 30px'}>
        <StyledSectionHeader bottom='0'>{title}</StyledSectionHeader>
        {titleRightComponent}
      </Flex>
      {data && <Tab type='accordion' ui='form' data={data} tabProps={tabProps} />}

      {children}
    </StyledSection>
  );
};
