import classNames from 'classnames';
import React from 'react';
import classes from './right-sidebar-open-button.module.scss';
import { RightSidebarOpenButtonProps } from './right-sidebar-open-button.props';

export const RightSidebarOpenButton = ({
  className,
  children,
  width = 50,
  height = 50,
  bottom = 20,
  ...restProps
}: RightSidebarOpenButtonProps) => {
  return (
    <button className={classNames(classes.button, className)} style={{ width, height, bottom }} {...restProps}>
      {children}
    </button>
  );
};
