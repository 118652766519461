import { FormFieldTypes } from 'common/enums';
import { FormFieldOption } from 'common/interfaces';
import { Dispatch, SetStateAction } from 'react';
import { FileProps } from '../file/file.props';

export interface OptionSetFileProps extends Pick<FileProps, 'dropzoneOptions' | 'placeholder'> {
  type: FormFieldTypes.OptionSetFile | FormFieldTypes.LookupFile;
  values: OptionSetFileValueItem[];
  error?: boolean;
  optionIndexesError?: number[];
  onChange: (value: OptionSetFileValueItem[]) => void;
}

export interface OptionSetFileContextProps extends OptionSetFileProps {
  options: FormFieldOption[];
  setOptions: Dispatch<SetStateAction<FormFieldOption[]>>;
}

export type OptionSetFileValueItem = {
  id: number;
  documentType: string | null;
  files: File[];
};

export enum OptionSetFileErrorEnum {
  Files = 'files',
  Option = 'option'
}
