import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const EnterpriceIcon = ({ width = 19, height = 12, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.25925 7.58968H4.78781C4.78781 7.58968 6.25925 10.2246 10.0234 10.4128C14.4719 10.6352 16.0289 8.59915 16.0289 8.59915H14.1469C14.1469 8.59915 13.4954 9.54381 10.0234 9.40331C7.77094 9.31216 6.25925 7.58968 6.25925 7.58968Z'
        fill={color}
      />
      <path
        d='M4.26696 7.58968H2.77976C2.77976 7.58968 4.26696 11.2541 9.11711 11.8035C16.2139 12.6074 18.2413 8.59915 18.2413 8.59915H16.6737C16.6737 8.59915 14.463 11.8035 8.4606 10.6245C5.56514 10.0557 4.26696 7.58968 4.26696 7.58968Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.944824 4.78121H2.62997C2.62997 4.78121 3.92466 0.226647 10.6961 0.0926831C17.4675 -0.0412804 18.7622 4.78121 18.7622 4.78121C18.7622 4.78121 19.1732 6.13755 18.7622 6.63076C18.3511 7.12397 17.4675 7.08287 17.4675 7.08287H0.944824V6.17865H16.2139C16.2139 6.17865 17.2573 6.17865 17.4675 5.87039C17.7071 5.51897 17.4675 4.78121 17.4675 4.78121C17.4675 4.78121 15.988 0.99991 10.6961 0.99991C5.38374 0.99991 4.00686 4.78121 4.00686 4.78121H4.52062C4.52062 4.78121 6.00026 1.41092 10.6961 1.41092C15.536 1.41092 16.9537 4.78121 16.9537 4.78121C16.9537 4.78121 17.1819 5.28959 17.0156 5.48984C16.8276 5.71628 16.2139 5.68544 16.2139 5.68544H0.944824V4.78121ZM14.8434 4.78121H5.95862C5.95862 4.78121 6.94545 2.49618 10.6961 2.37853C14.1224 2.27106 15.2797 4.16927 15.2797 4.16927C15.2797 4.16927 15.5064 4.4603 15.3973 4.6314C15.2824 4.8115 14.8434 4.78121 14.8434 4.78121Z'
        fill={color}
      />
    </svg>
  );
};
