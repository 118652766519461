import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import { UserDoesNotHaveMainCarModalContent } from './components/content';
import { OpenUserDoesNotHaveMainCarModalParams } from './use-user-does-not-have-main-car.props';

export const useUserDoesNotHaveMainCarModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openUserDoesNotHaveMainCarModal = (params: OpenUserDoesNotHaveMainCarModalParams) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Änderung Hauptauto',
        children: <UserDoesNotHaveMainCarModalContent {...params} />,
        hideCloseIcon: false,
        allButtonsHidden: true,
        buttons: [
          {
            type: 'cancel',
            title: 'Nein, abbrechen',
            action: closeAlert
          }
        ]
      })
    );
  };

  return { openUserDoesNotHaveMainCarModal };
};
