import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ISort, ResponseModel } from 'common/interfaces';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';
import { GetCarsListDataSearch } from 'request-config/pool-calendar/pool-calendar.types';
import {
  PoolCarWithBookings,
  PoolCarFilterData,
  PoolCarWithBookingsResponse
} from 'components/pool-car-booking/hooks/react-query/use-pool-cars-with-bookings/pool-cars-with-bookings.types';
import { transformCrmResponse } from 'utils/transform-crm-response';
import { FilterNames } from 'components/pool-car-booking/bookings-page';

const modifyResponse = ({ data }: ApiResponse<PoolCarWithBookingsResponse[]>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(poolCarWithBookingResponse => ({
      car: transformCrmResponse(poolCarWithBookingResponse.car),
      bookings: poolCarWithBookingResponse.bookings.map(booking => transformCrmResponse(booking))
    }));
  }
  return [];
};

const modifyFilterDataResponse = ({ data }: ApiResponse) => {
  if (data.level === RequestStatuses.Success) {
    return {
      [FilterNames.Booking]: data.data.bookings,
      [FilterNames.Mark]: data.data.cars,
      [FilterNames.Status]: data.data.statuses,
      [FilterNames.Driver]: data.data.drivers,
      [FilterNames.Location]: data.data.standorts
    } as PoolCarFilterData;
  }
  return undefined;
};

const usePoolCarsWithBookings = (
  navigationURL: string,
  sort: ISort,
  search: GetCarsListDataSearch,
  isEnabled: boolean
) => {
  const { fetch } = useFetch<ResponseModel<PoolCarWithBookingsResponse[]>>({
    isShowErrorSnackbar: false,
    loadingKey: 'getPoolCarsWithBookings',
    ...poolCalendarRequestConfig.getCarsList({
      sort,
      search
    })
  });

  const { fetch: fetchFilterData } = useFetch<ResponseModel<PoolCarFilterData>>({
    isShowErrorSnackbar: false,
    loadingKey: 'getPoolCarsWithBookings',
    ...poolCalendarRequestConfig.getCarsFilters()
  });

  const { data: poolCarsWithBookings } = useQuery<
    ApiResponse<PoolCarWithBookingsResponse[]>,
    unknown,
    PoolCarWithBookings[]
  >([ReactQueryKeys.GetPoolCarsWithBookings, navigationURL, sort, search], () => fetch(), {
    enabled: isEnabled,
    select: modifyResponse
  });

  const { data: poolCarsWithBookingsFilterData } = useQuery<ApiResponse, unknown, PoolCarFilterData | undefined>(
    [ReactQueryKeys.GetPoolCarsWithBookingsFilterData, navigationURL],
    () => fetchFilterData(),
    {
      enabled: isEnabled,
      select: modifyFilterDataResponse
    }
  );

  return {
    poolCarsWithBookings: poolCarsWithBookings || [],
    poolCarsWithBookingsFilterData
  };
};

export default usePoolCarsWithBookings;
