import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 35px;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-left: 20px;
  min-width: 360px;
  ouline: 1px solid red;
`;

export const StyledSecuredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
  }
`;

export const LeftSection = styled.section`
  flex: 5;
`;
