import React, { useState, useRef, KeyboardEvent, ChangeEvent, ClipboardEvent } from 'react';
import classNames from 'classnames';
import SearchIcon from '../icons/search-icon';
import classes from './license-plate-input.module.scss';
import { CircleExclamationMark } from '../icons/crcle_exclamation_mark';
import { useDispatch } from 'react-redux';
import { setLicensePlate as setLicensePlateAction } from '../../actions/app_action';
import { InputErrorMessages, InputName, LicensePlateInputProps, SwitchOptions } from './license-plate-input.props';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { usePreviousValue } from '../../hooks/use-previous-value';

export const LicensePlateInput = ({ className, formClassName }: LicensePlateInputProps) => {
  const licensePlateSelector = useTypedSelector(state => state.app.licensePlate);
  const dispatch = useDispatch();

  const licensePlateRefs = {
    [InputName.REGION]: useRef<HTMLInputElement | null>(null),
    [InputName.LETTERS]: useRef<HTMLInputElement | null>(null),
    [InputName.NUMBERS]: useRef<HTMLInputElement | null>(null)
  };

  const [licensePlate, setLicensePlate] = useState(clearLicensePlate);
  const [inputFocus, setInputFocus] = useState(inactiveInputFocus);

  const lastInputPlate = usePreviousValue(licensePlate);

  const switchInputFocus = (inputName: InputName, value: string, options: SwitchOptions = {}) => {
    const validationError = validationInput(inputName, value);

    if (validationError) {
      return;
    }

    switch (inputName) {
      case InputName.REGION:
        if ((value.length > 2 && !options.switchToPrevious && !options.isStayInPlace) || options.switchToNext) {
          if (licensePlateRefs[InputName.LETTERS]?.current) {
            // @ts-ignore
            licensePlateRefs[InputName.LETTERS].current.focus();
          }
          break;
        }
        break;

      case InputName.LETTERS:
        if ((value.length > 1 && !options.switchToPrevious && !options.isStayInPlace) || options.switchToNext) {
          if (licensePlateRefs[InputName.NUMBERS]?.current) {
            // @ts-ignore
            licensePlateRefs[InputName.NUMBERS].current.focus();
          }
          break;
        }
        if (value.length === 0 || options.switchToPrevious) {
          if (licensePlateRefs[InputName.REGION]?.current) {
            // @ts-ignore
            licensePlateRefs[InputName.REGION].current.focus();
          }
          break;
        }
        break;

      case InputName.NUMBERS:
        if (value.length > 5) {
          break;
        }
        if (value.length === 0 || options.switchToPrevious) {
          if (licensePlateRefs[InputName.LETTERS]?.current) {
            // @ts-ignore
            licensePlateRefs[InputName.LETTERS].current.focus();
          }
          break;
        }
        break;

      default:
        break;
    }
  };

  const validationInput = (inputName: InputName, value: string): boolean => {
    let error = true;
    const lastLetter = value && value[value.length - 1];
    const onlyLetters = /[a-zA-Z]+/;

    if (lastLetter === ' ' && inputName !== InputName.NUMBERS) {
      return error;
    }

    if (inputName === InputName.REGION) {
      if (value?.length <= 3) {
        error = writeValue(inputName, value, !!lastLetter?.match(onlyLetters));
      }
    }

    if (inputName === InputName.LETTERS) {
      if (value?.length <= 2) {
        error = writeValue(inputName, value, !!lastLetter?.match(onlyLetters));
      }
    }

    if (inputName === InputName.NUMBERS) {
      if (value?.length <= 5) {
        error = writeValue(inputName, value, isValidNumberInput(value).isValid);
      }
    }

    return error;
  };

  const writeValue = (inputName: InputName, value: string, condition: boolean) => {
    let error = true;

    if (condition || value === '') {
      setLicensePlate(prev => ({
        ...prev,
        [inputName]: value,
        error: ''
      }));
      error = false;
    } else {
      setLicensePlate(prev => ({
        ...prev,
        error: InputErrorMessages.WRONG_MARK
      }));
      error = true;
    }

    return error;
  };

  const save = () => {
    if (licensePlate[InputName.REGION] && licensePlate[InputName.LETTERS] && licensePlate[InputName.NUMBERS]) {
      dispatch(
        setLicensePlateAction({
          fullValue: `${licensePlate[InputName.REGION]}-${licensePlate[InputName.LETTERS]} ${
            licensePlate[InputName.NUMBERS]
          }`,
          region: licensePlate[InputName.REGION],
          letters: licensePlate[InputName.LETTERS],
          numbers: licensePlate[InputName.NUMBERS]
        })
      );
    } else {
      setLicensePlate(prev => ({
        ...prev,
        error: InputErrorMessages.ENTER_LICENSE_PLATE
      }));
    }
  };

  const isValidNumberInput = (value: string) => {
    let isValid = true;
    let numberCount = 0;
    let letterCount = 0;
    let filterValue = '';

    value.split('').filter(letter => {
      if (letter.toLowerCase() === 'h' || letter.toLowerCase() === 'e') {
        letterCount++;
        if (letterCount >= 2) {
          isValid = false;
        } else {
          filterValue = filterValue + letter;
        }

        return;
      }

      if (letter.match(/[0-9]/)) {
        numberCount++;
        if (numberCount >= 5) {
          isValid = false;
        } else {
          filterValue = filterValue + letter;
        }

        return;
      }
      isValid = false;
    });

    return { isValid, filterValue };
  };

  const clearInput = (value: string, inputName: InputName) => {
    if (value.length + 1 !== lastInputPlate[inputName].length && value === '') {
      setLicensePlate(clearLicensePlate);
    }
  };

  const onKeyUpHandler = (event: KeyboardEvent<HTMLInputElement>, inputName: InputName, value: string) => {
    const inputFocusIndx = licensePlateRefs[inputName].current?.selectionStart;

    if (inputFocus.name !== inputName && inputFocus.name) {
      inputFocus.indx = -1;
    }

    if (event.key === 'Enter') {
      save();
    }
    if (event.key === 'Backspace') {
      switchInputFocus(inputName, value, { isStayInPlace: true });
    }

    if (event.key === 'ArrowRight' && inputFocus.indx === value.length) {
      switchInputFocus(inputName, value, { switchToNext: true });
    }
    if (event.key === 'ArrowLeft' && inputFocus.indx === 0) {
      switchInputFocus(inputName, value, { switchToPrevious: true });
    }

    setInputFocus({
      indx: typeof inputFocusIndx === 'number' ? inputFocusIndx : -1,
      name: inputName
    });
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>, inputName: InputName) => {
    const value = event?.target?.value;

    clearInput(value, inputName);
    switchInputFocus(inputName, value);
    validationInput(inputName, value);
  };

  const onPastWriteValue = (region: string, letters: string, numbers: string) => {
    const NUMBERS_REGEX = /\d/g;

    const { isValid: isValidNumberLicense, filterValue: numberLicenseFilterValue } = isValidNumberInput(numbers);

    const isError = !(
      region.search(NUMBERS_REGEX) === -1 &&
      letters.search(NUMBERS_REGEX) === -1 &&
      isValidNumberLicense &&
      region.length <= 3 &&
      letters.length <= 2
    );

    writeValue(InputName.REGION, region.replaceAll(NUMBERS_REGEX, '').slice(0, 3), true);
    writeValue(InputName.LETTERS, letters.replaceAll(NUMBERS_REGEX, '').slice(0, 2), true);
    writeValue(InputName.NUMBERS, numberLicenseFilterValue, true);

    if (isError) {
      setLicensePlate(prev => ({
        ...prev,
        error: InputErrorMessages.ENTER_LICENSE_PLATE
      }));
    }
  };

  const onPastHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    event.clipboardData.items[0].getAsString(text => {
      const SPACES_REGEX = /\s/g;
      const textWithoutSpaces = text.replace(SPACES_REGEX, '');

      if (text.includes('-')) {
        const [region, lettersAndNumbers] = text.split('-');
        const [letters, numbers] = lettersAndNumbers.split(' ');

        return onPastWriteValue(region, letters, numbers);
      }

      if (text.includes(' ')) {
        const [region = '', letters = '', numbers = ''] = text.split(' ');
        return onPastWriteValue(region, letters, numbers);
      }

      const region = textWithoutSpaces.substring(0, 3);
      const letters = textWithoutSpaces.substring(3, 5);
      const numbers = textWithoutSpaces.substring(5, 10);

      onPastWriteValue(region, letters, numbers);
    });
  };

  const onCopyHandler = () => {
    navigator.clipboard.writeText(
      licensePlate[InputName.REGION] + licensePlate[InputName.LETTERS] + licensePlate[InputName.NUMBERS]
    );
  };

  return (
    <div className={classNames(classes.container, className)}>
      <form
        className={classNames(classes.input_form, formClassName)}
        style={{ border: licensePlateSelector.fullValue ? '2px solid #acd2e6' : '2px solid #c6cacc' }}
      >
        <div>
          <input
            className={classes.region}
            placeholder='XXX'
            value={licensePlate[InputName.REGION]}
            ref={licensePlateRefs[InputName.REGION]}
            onChange={event => onChangeHandler(event, InputName.REGION)}
            onKeyUp={event => onKeyUpHandler(event, InputName.REGION, licensePlate[InputName.REGION])}
            onPaste={onPastHandler}
            onCopy={onCopyHandler}
          />
          <span>-</span>
          <input
            className={classes.letters}
            placeholder='XX'
            value={licensePlate[InputName.LETTERS]}
            ref={licensePlateRefs[InputName.LETTERS]}
            onChange={event => onChangeHandler(event, InputName.LETTERS)}
            onKeyUp={event => onKeyUpHandler(event, InputName.LETTERS, licensePlate[InputName.LETTERS])}
            onPaste={onPastHandler}
            onCopy={onCopyHandler}
          />
          <input
            className={classes.numbers}
            placeholder='XXXXX'
            value={licensePlate[InputName.NUMBERS]}
            ref={licensePlateRefs[InputName.NUMBERS]}
            onChange={event => onChangeHandler(event, InputName.NUMBERS)}
            onKeyUp={event => onKeyUpHandler(event, InputName.NUMBERS, licensePlate[InputName.NUMBERS])}
            onPaste={onPastHandler}
            onCopy={onCopyHandler}
          />
        </div>

        <div onClick={save}>
          <SearchIcon color='#335566' className={classes.icon} width='20' height='20' />
        </div>
      </form>

      {licensePlate.error ? (
        <div className={classes.error_container}>
          <CircleExclamationMark width='14' height='14' />
          <span className={classes.error_message}>{licensePlate.error}</span>
        </div>
      ) : null}
    </div>
  );
};

const clearLicensePlate = {
  [InputName.REGION]: '',
  [InputName.LETTERS]: '',
  [InputName.NUMBERS]: '',
  error: ''
};

const inactiveInputFocus = {
  indx: -1,
  name: ''
};
