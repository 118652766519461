import { TimeMeasure } from 'common/enums';
import { IUseOutsideClickOptions } from 'hooks/use-outside-click/use-outside-click.props';
import { IDropDownData, TimeType } from './time.props';
import range from 'lodash/range';

export const NUMBER_OF_VALUES_ON_THE_SIDE = 2;
export const ITEM_SIZE = 28;
export const DROPDOWN_VISIBLE_ITEMS = 5;
export const FLAT_LIST_HEIGHT = ITEM_SIZE * DROPDOWN_VISIBLE_ITEMS;
export const FOCUS_BLOCK_HEIGHT = ITEM_SIZE + 2;

export const DEFAULT_TIME_VALUE: TimeType = {
  [TimeMeasure.Hour]: '00',
  [TimeMeasure.Minutes]: '00'
};

export const DEFAULT_DROPDOWN_DATA: IDropDownData = {
  [TimeMeasure.Hour]: [...[20, 21, 22, 23], ...range(0, 24)],
  [TimeMeasure.Minutes]: [...[56, 57, 58, 59], ...range(0, 60)]
};

export const useOutsideClickOptions: IUseOutsideClickOptions = {
  removePrevEventListener: true,
  disableStopPropagation: true
};
