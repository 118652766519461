import styled from 'styled-components';
import { Button, Distance, Flex, Panel } from '../../../common/common-components.styled';
import Download from 'components/icons/download';
import DocumentSectionFileComponent from 'pages/details/document-section-file-component/document-section-file-component';

export const StyledOrderWidget = styled(Panel)`
  border-top: 5px solid ${({ theme }) => theme.colors.blue};
  margin-bottom: 30px;
`;

interface StyledInfoCardProps {
  reference?: boolean;
}

export const StyledInfoCard = styled(Flex)<StyledInfoCardProps>`
  background: ${({ theme, reference }) => (reference ? theme.colors.grey100 : theme.colors.lightBlue)};
  border-radius: 5px;
  width: 100%;
  padding: 6px;
`;

export const StyledInfoBlockTitle = styled.div`
  width: 50%;
`;

export const StyledInfoBlockValue = styled(StyledInfoBlockTitle)<{ textAlign: 'right' | 'left' }>`
  text-align: ${({ textAlign }) => textAlign};
`;

export const StyledAllInclusiveBlock = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-top: 3px solid ${({ theme }) => theme.colors.blue};
  border-radius: 3px;
`;

export const StyledCheckMarkContainer = styled.div`
  min-width: 35px;
`;

export const StyledOneTimeCostsContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey50};
  padding: 10px;
  border-radius: 3px;
`;

export const StyledDownload = styled(Download).attrs(({ theme }) => ({
  fill: theme.colors.darkBlue
}))`
  width: 11px;
  height: 22px;
  cursor: pointer;
  margin-left: 10px;
`;

export const StyledIconContainer = styled(Flex)`
  min-width: 40px;
`;

export const StyledFileComponent = styled(DocumentSectionFileComponent)`
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  padding: 10px;
`;

export const StyledFilesContainer = styled(Flex)`
  position: relative;
  min-height: 80px;
`;

export const StyledInfoCardsContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
  }
  @media (min-width: 1440px) and (max-width: ${({ theme }) => theme.media.desktop}) {
    flex-direction: column;
  }
`;

export const StyledCostContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
    align-items: flex-end;
  }
  @media (min-width: 1440px) and (max-width: ${({ theme }) => theme.media.desktop}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledDistance = styled(Distance)`
  margin-top: 0;
  margin-left: 10px;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    margin-top: 10px;
    margin-left: 0;
  }
  @media (min-width: 1440px) and (max-width: ${({ theme }) => theme.media.desktop}) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

export const StyledButtonsContainer = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
  }
  @media (min-width: 1440px) and (max-width: 1800px) {
    flex-direction: column;
  }
`;

export const StyledSubmitButtonContainer = styled(Flex)`
  margin-left: 10px;
  margin-bottom: 0;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    order: 0;
  }
  @media (min-width: 1440px) and (max-width: 1800px) {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
    order: 0;
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
    order: 1;
  }
  @media (min-width: 1440px) and (max-width: 1800px) {
    width: 100%;
    order: 1;
  }
`;

export const StyledConfiguratorContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 100%;
    order: 1;
  }
  @media (min-width: 1440px) and (max-width: 1800px) {
    width: 100%;
    order: 1;
  }
`;

export const StyledPriceContainer = styled(Flex)`
  min-height: 50px;
  position: relative;
`;
