import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import useQueryLocation from 'hooks/use-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { ReactQueryKeys } from 'common/enums';
import { GetPoolCarClassesQueryOptionsType } from './use-get-pool-car-classes.props';
import { FormFieldOption } from 'common/interfaces';

export const useGetPoolCarClassesQuery = (vehicleIdProps?: string, options: GetPoolCarClassesQueryOptionsType = {}) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch({
    loadingKey: 'getPoolCarClassesQuery',
    ...fleetRequestConfig.getPoolCarClasses(vehicleId as string),
    ...options.fetch
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.GetPoolCarClasses, vehicleId], () => fetch(), {
    select: ({ data }) => data.data,
    ...options.options
  });

  const poolCarClassesForOptionSet: FormFieldOption[] =
    data?.map(item => ({
      label: item?.attributes?.uds_name || '-',
      value: item?.id || '-'
    })) || [];

  return { getPoolCarClasses: data, poolCarClassesForOptionSet, ...restQueryProps };
};
