import axios from 'axios';
import { stuffId } from '../../constants';
import authService from 'services/auth-service';
import { API_ENDPOINT } from 'endpoints';

export const getTimeSlots = async (Start: string, End: string, ServiceId: string) => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/proxy/OutlookBooking/GetStaffBookability`;
    const moment = require('moment-timezone');
    const TimeZone = moment.tz.guess();

    const response = axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        StaffList: [stuffId],
        Start,
        End,
        TimeZone,
        ServiceId
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createBooking = async (
  ServiceId: string,
  Start: string,
  CustomerName: string,
  CustomerEmail: string,
  CustomerNotes: string
) => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/proxy/OutlookBooking/CreateBooking`;
    const moment = require('moment-timezone');
    const CustomerTimeZone = moment.tz.guess();

    const response = await axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ServiceId,
        Start,
        StaffList: [stuffId],
        CustomerName,
        CustomerEmail,
        CustomerNotes,
        StartInCustomerTimeZone: Start,
        CustomerTimeZone
      }
    });

    const { data } = response;

    return data.Booking;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBooking = async (id: string) => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/proxy/OutlookBooking/DeleteBooking`;
    return await axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        id
      }
    });
  } catch (error) {
    console.log(error);
  }
};
