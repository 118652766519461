import { useFetch } from 'hooks/use-fetch/use-fetch';
import { costCentersHistoryRequestConfig } from 'request-config/cost-centers-history/cost-centers-history.request-config';
import { GetCostCentersHistoryListData } from 'request-config/cost-centers-history/cost-centers-history.types';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { ReactQueryKeys } from 'common/enums';
import { useQuery as useUrlQuery } from '../../../../../../../../../../../../hooks';
import { defaultGuid, RequestStatuses, USER_ROLES } from '../../../../../../../../../../../../constants';
import { CostCentersHistoryItem, CostCentersHistoryListResponse } from '../cost-centers-widget.types';
import { getResponseFormData } from 'utils/get-response-data';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<CostCentersHistoryListResponse>>) => {
  if (data.level === RequestStatuses.Success) {
    const items = getResponseFormData(data, [{ name: 'items', type: 'array' }], true) as CostCentersHistoryItem[];
    if (items.length) {
      items[0].isFirstItem = true;
    }
    return items;
  }
  return [];
};

const useCostCentersHistoryList = () => {
  const availableForRoles = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);
  const query = useUrlQuery();
  const carId = (query.id || defaultGuid) as string;
  const requestData: GetCostCentersHistoryListData = {
    carId
  };

  const { fetch } = useFetch<ResponseModel<CostCentersHistoryListResponse>>({
    loadingKey: 'getCostCentersList',
    isCancelRequestOnUnmount: true,
    ...costCentersHistoryRequestConfig.getCostCentersHistoryList(requestData)
  });

  const {
    data: costCentersHistoryItems,
    isLoading,
    refetch: refetchCostCentersHistoryItems
  } = useQuery<AxiosResponse<ResponseModel<CostCentersHistoryListResponse>>, unknown, CostCentersHistoryItem[]>(
    [ReactQueryKeys.GetCostCentersHistoryList, carId],
    () => fetch(),
    {
      enabled: !!carId && availableForRoles,
      select: modifyResponse
    }
  );

  return { costCentersHistoryItems: costCentersHistoryItems || [], isLoading, refetchCostCentersHistoryItems };
};

export default useCostCentersHistoryList;
