import React, { useContext } from 'react';
import { PanelItemFrameProps } from './panel-item-frame/panel-item-frame.props';
import { SingleTabContext } from 'components/tab/type/single/single.context';
import { PanelItemSection } from './ui/section/section';

export const PanelItemController = (props: PanelItemFrameProps) => {
  const { ui } = useContext(SingleTabContext);

  switch (ui) {
    case 'section':
      return <PanelItemSection {...props} />;

    default:
      return null;
  }
};
