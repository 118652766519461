import { createContext, Dispatch, SetStateAction } from 'react';
import { SortingState } from './sorting-documents.props';
import { SortingTitleEnum } from './components/title-row/title-row.props';

interface SortingDocumentsContextProps {
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
}

export const SortingDocumentsContext = createContext<SortingDocumentsContextProps>({
  sorting: { column: SortingTitleEnum.Name, isSortAlpha: true },
  setSorting: () => null
});
