import styled from 'styled-components';

export const StyledPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 30px;
  width: 1030px;
  background-color: ${({ theme }) => theme.colors.white };
  border-radius: 2px;
  box-shadow: 0.5px 3px 10px rgba(0, 0, 0, 0.25);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (max-width: 1579px) {
    width: 825px;

    h3 {
      font-size: 20px;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`

