import React from 'react';
import classes from './repair-partner.module.scss';
import { RepairStatusHorizontal } from 'components/widgets/repair-status-horizontal/repair-status-horizontal';
import { StyledRepairPartnerWidgetTile } from './repair-partner.styled';
import { useRepairPartnerHorizontalData } from './hooks/use-repair-partner-horizontal-data';
import useWindowSize from 'hooks/use-window-size';
import { Flex, Text } from 'common/common-components.styled';
import { SettingsIcon } from 'components/icons-new/settings';

export const VehicleDetailsRepairPartnerWidget = () => {
  const { getRepairStatusHorizontalData, table, isHideWidget } = useRepairPartnerHorizontalData();
  const { isMobile } = useWindowSize();

  const getDescription = (title: string) => {
    if (isMobile) {
      return (
        <Flex direction='column'>
          <Text color='blue'>{title}</Text>
          <Text color='midBlue' size='12'>
            Schadennummer
          </Text>
        </Flex>
      );
    }

    return `Schadenfall ${title}`;
  };

  const getTitle = () => {
    if (isMobile) {
      return (
        <Flex align='center' bottom='20'>
          <SettingsIcon height={18} width={18} />
          <Text size='18' left='10'>
            Reparaturstatus
          </Text>
        </Flex>
      );
    }

    return 'Status der Fahrzeugreparatur:';
  };

  if (isHideWidget) {
    return null;
  }

  return (
    <div className={classes.container}>
      {table.map((item, index) => (
        <StyledRepairPartnerWidgetTile key={item.incidentid || index}>
          <RepairStatusHorizontal
            data={getRepairStatusHorizontalData(index)}
            title={getTitle()}
            description={getDescription(item.title || '-')}
          />
        </StyledRepairPartnerWidgetTile>
      ))}
    </div>
  );
};
