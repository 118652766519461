import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DownloadZipIcon = ({ width = 20, height = 18, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M17.0178 16.8387H1.92106C1.28235 16.8387 0.759766 16.3161 0.759766 15.6774V6.67742C0.759766 6.03871 1.28235 5.51613 1.92106 5.51613H5.04202C5.27428 5.51613 5.49202 5.61048 5.65896 5.77016L6.89283 7.00403C7.05977 7.17097 7.27751 7.25806 7.50977 7.25806H14.1146C14.5422 7.25806 14.9177 7.49226 15.1193 7.83871C15.2188 8.00978 15.2759 8.20822 15.2759 8.41935V15.0968C15.2759 16.0621 16.0525 16.8387 17.0178 16.8387C17.9832 16.8387 18.7598 16.0621 18.7598 15.0968V7.83871'
          fill={fill}
        />
      )}
      {hovered && (
        <path
          d='M15.2759 8.41935V15.0968C15.2759 16.0621 16.0525 16.8387 17.0178 16.8387C17.9832 16.8387 18.7598 16.0621 18.7598 15.0968V7.83871H15.1193C15.2188 8.00978 15.2759 8.20822 15.2759 8.41935Z'
          fill={fill}
        />
      )}
      {hovered && (
        <path
          d='M17.0179 15.0968H15.276H7.14697V5.2258V1.16129H13.2945C13.4542 1.16129 13.5994 1.21935 13.7083 1.32822L16.851 4.47096C16.9599 4.57983 17.0179 4.73225 17.0179 4.88467V15.0968Z'
          fill='white'
        />
      )}
      <path
        d='M15.276 15.0968H17.0179V4.88467C17.0179 4.73225 16.9599 4.57983 16.851 4.47096L13.7083 1.32822C13.5994 1.21935 13.4542 1.16129 13.2945 1.16129H7.14697V5.2258'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path d='M16.96 4.64515H13.8245C13.6648 4.64515 13.5342 4.51451 13.5342 4.35483V3.77419' fill='white' />
      )}
      <path
        d='M16.96 4.64515H13.8245C13.6648 4.64515 13.5342 4.51451 13.5342 4.35483V3.77419'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M1.92106 16.8387H17.0178C16.0525 16.8387 15.2759 16.0621 15.2759 15.0968V8.41935C15.2759 8.20822 15.2188 8.00978 15.1193 7.83871C14.9177 7.49226 14.5422 7.25806 14.1146 7.25806H7.50977C7.27751 7.25806 7.05977 7.17097 6.89283 7.00403L5.65896 5.77016C5.49202 5.61048 5.27428 5.51613 5.04202 5.51613H1.92106C1.28235 5.51613 0.759766 6.03871 0.759766 6.67742V15.6774C0.759766 16.3161 1.28235 16.8387 1.92106 16.8387Z'
          fill={fill}
        />
      )}
      <path
        d='M17.0178 16.8387H1.92106C1.28235 16.8387 0.759766 16.3161 0.759766 15.6774V6.67742C0.759766 6.03871 1.28235 5.51613 1.92106 5.51613H5.04202C5.27428 5.51613 5.49202 5.61048 5.65896 5.77016L6.89283 7.00403C7.05977 7.17097 7.27751 7.25806 7.50977 7.25806H14.1146C14.7533 7.25806 15.2759 7.78064 15.2759 8.41935V15.0968C15.2759 16.0621 16.0525 16.8387 17.0178 16.8387ZM17.0178 16.8387C17.9832 16.8387 18.7598 16.0621 18.7598 15.0968V7.83871'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
