import React from 'react';
import { PhaseSwitchProps } from './phase-switch.types';
import { Flex, Text } from 'common/common-components.styled';
import { StyledSwitch } from 'components/form-hook/form-field/form-field.styled';
import { usePhaseSwitch } from './use-phase-switch';
import { WidgetContentText } from '../../../components/leasing-widget/leasing-widget.styled';

const PhaseSwitch: React.FC<PhaseSwitchProps> = ({ description, isFourthPhase }) => {
  const { checked, handleChange } = usePhaseSwitch(isFourthPhase);

  return (
    <Flex direction='column' gap='10px'>
      <Flex>
        <WidgetContentText>{description}</WidgetContentText>
      </Flex>

      <Flex align='center' left='-10'>
        <StyledSwitch checked={checked} onChange={e => handleChange(e.target.checked)} />

        <Text size='12'>Benötige ich nicht</Text>
      </Flex>
    </Flex>
  );
};

export default PhaseSwitch;
