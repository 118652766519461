/* eslint-disable no-extra-boolean-cast */
import React, { memo, useContext, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ActiveElement,
  ChartData,
  ChartOptions,
  ChartEvent
} from 'chart.js';
import { VerbrauchInterface } from './consumption.props';
import classes from './consumption.module.scss';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import { PercentColor, PercentSetupType } from 'components/expenses-per-year/expenses-per-year.props';
import { PAGES, Position } from 'common/enums';
import { Distance } from 'common/common-components.styled';
import { CRM_VALUE as VEHICLE_COST_CRM_VALUE } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { CRM_VALUE as MY_VEHICLE_CRM_VALUE } from 'pages/my-vehicles/my-vehicles';
import { AnyObject } from 'chart.js/dist/types/basic';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { useTheme } from 'styled-components';
import toMoney from 'utils/toMoney';
import { desiredKeysOrderForConsumptionChart } from './utils';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { openPageVehicleCostCurrentYearProps } from '../utils';
import { COST_TYPE_VALUE } from 'pages/fahrzeugkosten-page/utils';
import { ConsumptionLabelEnum, ConsumptionValueEnum } from 'pages/my-vehicles/my-vehicles.props';
import classNames from 'classnames';

ChartJS.register(ArcElement, Tooltip, Legend);

export const ConsumptionHalfCircle = memo(({ consumptionData }: VerbrauchInterface) => {
  const [hoveredLeftSegment, setHoveredLeftSegment] = useState<ActiveElement | null>(null);
  const [activeLeftSegment, setActiveLeftSegment] = useState<number | null>(0);
  const [leftSegmentOffsets, setLeftSegmentOffsets] = useState<number[]>([20, 0, 0]);
  const [hoveredRightSegment, setHoveredRightSegment] = useState<ActiveElement | null>(null);
  const [activeRightSegment, setActiveRightSegment] = useState<number | null>(0);
  const [rightSegmentOffsets, setRightSegmentOffsets] = useState<number[]>([20, 0]);
  const theme = useTheme();

  const { openPage } = useContext(FleetManagerDashboardContext);

  const leftColors: string[] = [];
  const rightColors: string[] = [];

  useEffect(() => {
    const initialSegment = { index: 0 } as ActiveElement;
    setHoveredLeftSegment(initialSegment);
    setHoveredRightSegment(initialSegment);
  }, []);

  let leftLabels: string[] = [];
  let leftData: number[] = [];
  let rightLabels: string[] = [];
  let rightData: number[] = [];

  desiredKeysOrderForConsumptionChart.sumFuelByYear.value.forEach((orderKey, orderKeyIndex) => {
    if (consumptionData?.verbrauch?.sumFuelByYear[orderKey]) {
      leftLabels.push(orderKey);
      leftData.push(consumptionData?.verbrauch?.sumFuelByYear[orderKey]);
      leftColors.push(theme.colors[desiredKeysOrderForConsumptionChart.sumFuelByYear.colors[orderKeyIndex]]);
    }
  });

  if (!!consumptionData?.consumptionEstimate?.erhohterVerbrauch) {
    rightLabels.push('erhöhter Verbrauch');
    rightData.push(consumptionData.consumptionEstimate?.erhohterVerbrauch);
    rightColors.push(theme.colors.pink);
  }
  if (!!consumptionData?.consumptionEstimate?.normalerVerbrauch) {
    rightLabels.push('normaler Verbrauch');
    rightData.push(consumptionData.consumptionEstimate?.normalerVerbrauch);
    rightColors.push(theme.colors.midBlue);
  }

  const adjustData = (data: number[]) => {
    let total = data.reduce((a, b) => a + b, 0);
    return data.map(value => {
      let percentage = (value / total) * 100;
      if (percentage < 6 && value !== 0) {
        return (total * 6) / 100;
      }
      return value;
    });
  };

  let adjustedLeftData = adjustData(leftData);
  let adjustedRightData = adjustData(rightData);

  const leftChartData: ChartData<'doughnut', number[], string> = {
    labels: leftLabels,
    datasets: [
      {
        data: adjustedLeftData,
        borderColor: (context: AnyObject) => {
          const segmentIndex = context.dataIndex;
          const activeIndex = activeLeftSegment !== null ? activeLeftSegment : 4;
          return segmentIndex === activeIndex
            ? context.dataset.hoverBackgroundColor[segmentIndex]
            : theme.colors.darkBlue;
        },
        circumference: 180,
        rotation: 270,
        offset: leftSegmentOffsets,
        hoverBackgroundColor: leftColors,
        hoverBorderColor: leftColors,
        borderWidth: 0,
        backgroundColor: leftColors,
        borderJoinStyle: 'round',
        spacing: 0
      }
    ]
  };

  const rightChartData: ChartData<'doughnut', number[], string> = {
    labels: rightLabels,
    datasets: [
      {
        data: adjustedRightData,
        circumference: 180,
        rotation: 270,
        offset: rightSegmentOffsets,
        hoverBackgroundColor: rightColors,
        hoverBorderColor: rightColors,
        borderWidth: 0,
        backgroundColor: rightColors,
        borderJoinStyle: 'round',
        spacing: 0
      }
    ]
  };

  const optionsLeftChart: ChartOptions<'doughnut'> = {
    cutout: '80%', // option for margin inside the circle,
    elements: {
      arc: {
        borderColor: (context: any) => {
          return context.dataset.hoverBackgroundColor[context.index];
        },
        borderJoinStyle: 'miter'
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onClick: (event: ChartEvent, segments: ActiveElement[]) => {
      if (segments.length > 0) {
        const segmentIndex = segments[0].index;
        setActiveLeftSegment(segmentIndex);
        const newOffsets = [0, 0, 0];
        newOffsets[segmentIndex] = 20;
        setLeftSegmentOffsets(newOffsets);
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const canvas = event.native && (event.native.target as HTMLCanvasElement);
      if (canvas && elements.length > 0) {
        const newSegment = elements[0] as ActiveElement;
        if (!hoveredLeftSegment || newSegment.index !== hoveredLeftSegment.index) {
          setHoveredLeftSegment(newSegment);
          const newOffsets = [0, 0, 0];
          newOffsets[newSegment.index] = 20;
          setLeftSegmentOffsets(newOffsets);
        }
        canvas.style.cursor = 'pointer';
      } else if (canvas) {
        canvas.style.cursor = 'default';
        if (activeLeftSegment !== null && hoveredLeftSegment?.index !== activeLeftSegment) {
          setHoveredLeftSegment(activeLeftSegment !== null ? ({ index: activeLeftSegment } as ActiveElement) : null);
        }
        const newOffsets = [0, 0, 0];
        if (activeLeftSegment !== null) {
          newOffsets[activeLeftSegment] = 20;
        }
        if (JSON.stringify(leftSegmentOffsets) !== JSON.stringify(newOffsets)) {
          setLeftSegmentOffsets(newOffsets);
        }
      }
    }
  };

  const optionsRightChart: ChartOptions<'doughnut'> = {
    cutout: '80%', // option for margin inside the circle,
    elements: {
      arc: {
        borderColor: (context: AnyObject) => {
          return context.dataset.hoverBackgroundColor[context.index];
        },
        borderJoinStyle: 'miter'
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onClick: (event: ChartEvent, segments: ActiveElement[]) => {
      if (segments.length > 0) {
        const segmentIndex = segments[0].index;
        setActiveRightSegment(segmentIndex);
        const newOffsets = [0, 0];
        newOffsets[segmentIndex] = 20;
        setRightSegmentOffsets(newOffsets);
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const canvas = event.native && (event.native.target as HTMLCanvasElement);
      if (canvas && elements.length > 0) {
        const newSegment = elements[0] as ActiveElement;
        if (!hoveredRightSegment || newSegment.index !== hoveredRightSegment.index) {
          setHoveredRightSegment(newSegment);
          const newOffsets = [0, 0];
          newOffsets[newSegment.index] = 20;
          setRightSegmentOffsets(newOffsets);
        }
        canvas.style.cursor = 'pointer';
      } else if (canvas) {
        canvas.style.cursor = 'default';
        if (activeRightSegment !== null && hoveredRightSegment?.index !== activeRightSegment) {
          setHoveredRightSegment(activeRightSegment !== null ? ({ index: activeRightSegment } as ActiveElement) : null);
        }
        const newOffsets = [0, 0];
        if (activeRightSegment !== null) {
          newOffsets[activeRightSegment] = 20;
        }
        if (JSON.stringify(rightSegmentOffsets) !== JSON.stringify(newOffsets)) {
          setRightSegmentOffsets(newOffsets);
        }
      }
    }
  };

  const currentYear = new Date().getFullYear();
  const percentageFromLastYearToCurrent = consumptionData?.verbrauch?.percent || 0;
  const percentSetup: PercentSetupType = {
    percent: Math.abs(percentageFromLastYearToCurrent),
    color: percentageFromLastYearToCurrent >= 0 ? PercentColor.Green : PercentColor.Red,
    arrowDirection: percentageFromLastYearToCurrent > 0 ? Position.Bottom : Position.Top
  };

  const getConsumptionFilter = (label: string) => {
    if (label === 'normaler Verbrauch') {
      return { label: ConsumptionLabelEnum.Normal, value: ConsumptionValueEnum.Normal };
    }

    return { label: ConsumptionLabelEnum.Increased, value: ConsumptionValueEnum.Increased };
  };

  const openPageUsageCostFuelCost = openPage(
    openPageVehicleCostCurrentYearProps(
      { [VEHICLE_COST_CRM_VALUE.costTypeGroup]: COST_TYPE_VALUE.fuelCost },
      { [VEHICLE_COST_CRM_VALUE.costTypeGroup]: COST_TYPE_VALUE.fuelCost }
    )
  );

  const openPageVehicle = (consumptionValue: string) =>
    openPage({
      page: PAGES.MY_VEHICLES,
      companyCrmName: MY_VEHICLE_CRM_VALUE.company,
      filterForClient: { [MY_VEHICLE_CRM_VALUE.consumption]: getConsumptionFilter(consumptionValue).label },
      filterForServer: { [MY_VEHICLE_CRM_VALUE.consumption]: getConsumptionFilter(consumptionValue).value }
    });

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <ExpensesPerYear
          name='Verbrauchskosten'
          year={currentYear}
          price={consumptionData?.verbrauch?.sumByYear[currentYear] || 0}
          percentSetup={percentSetup}
          tooltipText={`Nettosumme aller Treibstoffkosten mit Rechnungsdatum innerhalb ${currentYear}`}
          openPage={openPageUsageCostFuelCost}
        />
        <Distance top='10' />
      </div>

      <div className={classes.bottom}>
        {leftData.length === 0 && rightData.length === 0 ? (
          <div className={classes.empty_data}>
            <EmptyDataIcon hovered={true} />
            <span>
              Es gibt keine Daten
              <br /> zu Verbrauchskosten in {currentYear}
            </span>
          </div>
        ) : (
          <>
            <div className={classes.left}>
              {leftData.length > 0 ? (
                <>
                  {consumptionData?.verbrauch?.sumFuelByYear && (
                    <Doughnut data={leftChartData} options={optionsLeftChart} />
                  )}
                  {hoveredLeftSegment && leftChartData?.labels && (
                    <div className={classes.text_block}>
                      <span>
                        {typeof leftData[hoveredLeftSegment.index] === 'string'
                          ? `${leftData[hoveredLeftSegment.index]} €`
                          : toMoney(leftData[hoveredLeftSegment.index])}
                      </span>
                      <div className={classes.bottom_container_item}>
                        <span style={{ backgroundColor: leftColors[hoveredLeftSegment.index] }} />
                        <p>{leftChartData?.labels[hoveredLeftSegment.index]}</p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.empty_data}>
                  <EmptyDataIcon hovered />
                  <span>Es gibt keine Daten</span>
                </div>
              )}
            </div>
            <div className={classes.right}>
              {rightData.length > 0 ? (
                <>
                  {consumptionData?.consumptionEstimate && (
                    <Doughnut data={rightChartData} options={optionsRightChart} />
                  )}
                  {hoveredRightSegment && rightChartData?.labels && consumptionData?.consumptionEstimate && (
                    <div
                      className={classNames(classes.text_block, 'pointer')}
                      onClick={openPageVehicle(rightChartData?.labels[hoveredRightSegment.index])}
                    >
                      <span>{rightData[hoveredRightSegment.index]} Fahrzeuge</span>
                      <div className={classes.bottom_container_item}>
                        <div className={classes.link}>
                          <span style={{ backgroundColor: rightColors[hoveredRightSegment.index] }} />
                          <p>{rightChartData?.labels[hoveredRightSegment.index]}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.empty_data}>
                  <EmptyDataIcon hovered />
                  <span>Es gibt keine Daten</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});
