import React from 'react';

export const RightArrowIcon = ({ width = '7', height = '12', color = '#335566', stroke = '#777777' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.67036 0.563728C1.82519 0.563728 1.98003 0.614915 2.08326 0.768477L5.69613 5.68246C5.85097 5.8872 5.85097 6.09195 5.69613 6.2967L2.08326 11.2107C1.92842 11.4154 1.56713 11.4666 1.36068 11.3131C1.15423 11.1595 1.10262 10.8012 1.25746 10.5964L4.61227 5.98958L1.25746 1.38272C1.10262 1.12679 1.15423 0.819665 1.4123 0.666103C1.46391 0.563728 1.56713 0.563728 1.67036 0.563728Z'
        fill={color}
        stroke={stroke}
        strokeWidth='0.5'
      />
    </svg>
  );
};
