/* eslint-disable no-unused-vars */
export enum LeasingContractFields {
  AddedMonths = 'extendLease',
  IsChangeMileage = 'adjustMileage',
  Mileage = 'changeMilage',
  SilentlyExtend = 'silentlyExtend',
  Comment = 'comment',
  SilentExtendDate = 'silentExtendDate',
  silentExtendMonths = 'silentExtendMonths',
  CurrentMileage = 'currentMileage'
}
