import React from 'react';
import { User } from 'common/interfaces';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { userSelector } from 'selectors';
import { Flex, Text } from 'common/common-components.styled';
import { UserIcon } from 'components/icons-new/user';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import { UserInfoProps } from 'components/side-menu/user-info/user-info.props';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import IconHover from 'components/icon-hover';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

const UserInfo = ({ goToUserSettings }: UserInfoProps) => {
  const user: User = useTypedSelector(userSelector);
  const theme = useTheme();

  const isShowSettingButtons = !useIsUserHaveRole([USER_ROLES.dealer.id, USER_ROLES.leasingcompany.id]);
  const isSiteAvailable = useTypedSelector(state => state.app.isSiteAvailable);

  return (
    <div>
      <Flex align='center'>
        <EllipsisWithTooltip tooltip={user.name} maxWidth='200px'>
          <Text pointer onClick={goToUserSettings} height='26px' right='10' bold size='18' color='grey600'>
            {user.name}
          </Text>
        </EllipsisWithTooltip>

        {isShowSettingButtons && isSiteAvailable && (
          <Tooltip title='Kontoeinstellungen'>
            <div>
              <IconHover height='16px' id='moreMenuItemAccountSettings' pointer onClick={goToUserSettings}>
                <UserIcon color={theme.colors.grey300} width={16} height={16} />
              </IconHover>
            </div>
          </Tooltip>
        )}
      </Flex>

      <EllipsisWithTooltip tooltip={user.companyName} maxWidth='225px'>
        <Text size='10' color='grey600'>
          {user.companyName}
        </Text>
      </EllipsisWithTooltip>
    </div>
  );
};

export default UserInfo;
