import { AnyObject } from 'common/interfaces';

export const CLEAR_ALL_FILTERS_BUTTON_TEXT = 'Alle Filter entfernen';

export const isFilterVisible = (filterState: AnyObject) => {
  let isActive = false;

  const convertFilterState = () => {
    const convertedFilterState: AnyObject = {};

    Object.entries(filterState).map(([key, value]) => {
      if (Array.isArray(value)) {
        convertedFilterState[key] = value.length ? value : null;
        return;
      }

      convertedFilterState[key] = value;
    });

    return convertedFilterState;
  };

  Object.values(convertFilterState()).forEach(item => {
    if (item) {
      isActive = true;
    }
  });

  return isActive;
};
