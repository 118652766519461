import React, { useEffect, useRef, useState } from 'react';
import { TooltipComponentProps } from '../tooltip-component.props';

export const useTooltipOpenForMobile = ({ isHideTooltipForMobile }: TooltipComponentProps) => {
  const [open, setOpen] = useState(false);

  const isTouchDevice = (): boolean => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const isShowTooltipForMobile = isTouchDevice() && !isHideTooltipForMobile;

  const handleClick = (event: React.MouseEvent) => {
    if (isShowTooltipForMobile) {
      event.stopPropagation();
      setOpen(!open);
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (open && tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (isShowTooltipForMobile) {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [open]);

  return { open: isTouchDevice() ? open : undefined, tooltipRef, handleClick };
};
