import React from 'react';
import PropTypes from 'prop-types';

export const CircleExclamationMark = ({ width = '17', height = '17', color = '#BF0040', ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.48317 10.6773C8.68656 10.6773 8.82215 10.7451 8.95774 10.8807C9.09334 11.0163 9.16113 11.1518 9.16113 11.3552C9.16113 11.5586 9.09334 11.6942 8.95774 11.8298C8.82215 11.9654 8.68656 12.0332 8.48317 12.0332C8.27978 12.0332 8.14418 11.9654 8.00859 11.8298C7.873 11.6942 7.8052 11.5586 7.8052 11.3552C7.8052 11.1518 7.873 10.9485 8.00859 10.8807C8.14418 10.7451 8.34757 10.6773 8.48317 10.6773ZM7.8052 9.25354L7.8052 5.25354C7.8052 4.98236 7.873 4.77897 8.00859 4.64337C8.14418 4.50778 8.27978 4.43998 8.48317 4.43998C8.68656 4.43998 8.82215 4.50778 8.95774 4.64337C9.09334 4.77897 9.16113 4.98236 9.16113 5.25354L9.16113 9.18575C9.16113 9.45693 9.09334 9.66032 8.95774 9.79591C8.82215 9.93151 8.68656 9.9993 8.48317 9.9993C8.27978 9.9993 8.14418 9.93151 8.00859 9.79591C7.873 9.72812 7.8052 9.52473 7.8052 9.25354Z'
        fill={color}
      />
      <path
        d='M8.5 16.2363C6.39831 16.2363 4.36441 15.4228 2.87288 13.9312C1.31356 12.3719 0.5 10.338 0.5 8.23633C0.5 6.13463 1.31356 4.10073 2.87288 2.60921C4.36441 1.04989 6.33051 0.236328 8.5 0.236328C10.6017 0.236328 12.6356 1.04989 14.1271 2.54141C17.2458 5.66006 17.2458 10.7448 14.1271 13.8634C12.6356 15.355 10.6017 16.2363 8.5 16.2363ZM8.5 1.59226C6.73729 1.59226 5.04237 2.27023 3.82203 3.55836C2.5339 4.7787 1.85593 6.47362 1.85593 8.23633C1.85593 9.99904 2.5339 11.694 3.82203 12.9143C5.04237 14.1346 6.73729 14.8804 8.5 14.8804C10.2627 14.8804 11.9576 14.2024 13.178 12.9143C15.7542 10.338 15.7542 6.13463 13.178 3.55836C11.8898 2.27023 10.2627 1.59226 8.5 1.59226Z'
        fill={color}
      />
    </svg>
  );
};

CircleExclamationMark.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
