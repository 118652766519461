import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';
import { vehicleOfferRequestConfig } from 'request-config/vehicle-offer/vehicle-offer.request-config';
import { Configuration, DriversPersonalConfigurationsResponse } from './use-drivers-personal-configurations.types';

const modifyResponse = ({ data }: ApiResponse<DriversPersonalConfigurationsResponse[]>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(item => ({
      uds_fahrzeugangeboteid: item.fahrzeugangebote.id,
      uds_name: item.fahrzeugangebote.attributes.uds_name,
      uds_title: item.fahrzeugangebote.attributes.uds_title,
      createdon: item.fahrzeugangebote.attributes.createdon,
      uds_remove_request_date: item.fahrzeugangebote.attributes.uds_remove_request_date,
      canBeArchived: item.canBeArchived,
      parentcontactid: item.opportunity.attributes.parentcontactid
    }));
  }
  if (data.level === RequestStatuses.Warning && data.messages[0]?.message.includes(CANT_FIND_BASKET_MESSAGE)) {
    return [];
  }
  return undefined;
};

const useDriversPersonalConfigurations = (driverId: string | null) => {
  const { fetch } = useFetch<ResponseModel<DriversPersonalConfigurationsResponse[]>>({
    loadingKey: 'useDriversPersonalConfigurations',
    ...vehicleOfferRequestConfig.getDriversPersonalConfigurations(driverId || '')
  });

  const { data: configurations } = useQuery<
    ApiResponse<DriversPersonalConfigurationsResponse[]>,
    unknown,
    Configuration[] | undefined
  >([ReactQueryKeys.GetDriversPersonalConfigurations, driverId], () => fetch(), {
    enabled: !!driverId,
    select: modifyResponse,
    cacheTime: 0
  });

  return { configurations };
};

const CANT_FIND_BASKET_MESSAGE = 'Can`t found basket';

export default useDriversPersonalConfigurations;
