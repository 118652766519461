import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import Select from './select';
import store from '../../../store';
import { changeSearch } from '../../../actions/app_action';
import { uds_enginetypecode } from '../../../constants';

const engineTypeOptions = [
  { value: '', label: 'Treibstoffart' },
  { value: uds_enginetypecode.benzin.toString(), label: 'Benzin' },
  { value: uds_enginetypecode.diesel.toString(), label: 'Diesel' },
  { value: uds_enginetypecode.elektro.toString(), label: 'Elektro' },
  { value: uds_enginetypecode.hybrid_benzin.toString(), label: 'Hybrid/Benzin' },
  { value: uds_enginetypecode.hybrid_diesel.toString(), label: 'Hybrid/Diesel' },
  { value: uds_enginetypecode.wasserstoff.toString(), label: 'Wasserstoff' },
  { value: uds_enginetypecode.erdgas.toString(), label: 'Erdgas' }
];

const mapStateToProps = state => ({
  value: state.app.smartTable.search.predicateObject.engineType || '',
  options: engineTypeOptions
});

const mapDispatchToProps = dispatch => ({
  onChange(value, name) {
    const state = store.getState();
    const search = cloneDeep(state.app.smartTable.search);
    search.predicateObject.engineType = value || null;
    dispatch(changeSearch(search));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
