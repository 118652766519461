import { useEffect, useState } from 'react';
import { useVehicleHistoryList } from './use-vehicle-history-list';

const DEFAULT_NUMBER_OF_ITEMS = 5;

export const useFilteredHistoryItems = (isExpanded?: boolean) => {
  const { isLoading, vehicleHistoryList, refetchVehicleHistoryList } = useVehicleHistoryList('new_fuhrpark');
  const [showAllHistoryItems, setShowAllHistoryItems] = useState(false);

  useEffect(() => {
    if (typeof isExpanded === 'boolean') {
      setShowAllHistoryItems(isExpanded);
    }
  }, [isExpanded]);

  const filteredHistoryItems = vehicleHistoryList.slice(
    0,
    showAllHistoryItems ? vehicleHistoryList.length : DEFAULT_NUMBER_OF_ITEMS
  );

  const isToggleButtonAvailable = vehicleHistoryList.length > DEFAULT_NUMBER_OF_ITEMS;

  const toggleShowAllItems = () => {
    setShowAllHistoryItems(prev => !prev);
  };

  return {
    filteredHistoryItems,
    isHistoryLoading: isLoading,
    showAllHistoryItems,
    isToggleButtonAvailable,
    toggleShowAllItems,
    refetchVehicleHistoryList
  };
};
