import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../constants';
import { AlertData } from 'components/alert/alert-view';

const useAlert = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openAlert = (alertData: AlertData) => {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openAlert, closeAlert };
};

export default useAlert;
