import { toggleAfterTourPopUp } from 'actions/app_action';
import { Button } from 'common/common-components.styled';
import { PAGES } from 'common/enums';
import { CloseIcon } from 'components/icons/close-icon';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classes from './support-pop-up.module.scss';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  tooltipProps: any;
  closeProps: any;
  primaryProps: any;
}

const SupportPopUp = ({ title, content, tooltipProps, closeProps, primaryProps }: Props) => {
  const loginCounter = useTypedSelector(state => state['app'].loginCounter);
  const isOddLogin = loginCounter % 2 === 0;
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToDecisionSupportPage = () => {
    history.push(PAGES.DECISION_SUPPORT);
    dispatch(toggleAfterTourPopUp(false));
  };

  return (
    <div {...tooltipProps} className={classes.find_out_container}>
      <div>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.content_wrapper}>
          <div className={classes.photo_wrapper}>
            <img
              src={
                isOddLogin
                  ? '/assets/images/photos/decision_support_modal.png'
                  : '/assets/images/photos/sarah_adrian_meeting_modal.png'
              }
              alt={isOddLogin ? 'decision support' : 'support-team'}
            />
          </div>
          <div className={classes.content_container}>
            <p className={classes.content}>{content}</p>
          </div>
        </div>
        <div className={classes.actions_wrapper}>
          <div className={classes.buttons_wrapper}>
            {isOddLogin ? (
              <Button onClick={navigateToDecisionSupportPage}>Entscheidungshilfen ansehen</Button>
            ) : (
              <>
                <Button secondary {...closeProps}>
                  Später
                </Button>
                <Button margin='0 0 0 10px' {...primaryProps}>
                  Jetzt Termin buchen
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={classes.cross_icon_wrapper}>
        <CloseIcon {...closeProps} />
      </div>
    </div>
  );
};

export default SupportPopUp;
