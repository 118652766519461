import { Action } from 'store/store.props';
import { initialState } from './initial.state';
import { IMyRequestsState } from './my-requests.props';
import {
  ACTIVE_STEP,
  IS_EDIT_MODE,
  IS_ACTIVE_DAMAGE_PREVIEW,
  UPDATE_INCIDENT_ID,
  COMPLETED_INCIDENT_ID,
  OPEN_INCIDENT_DATA
} from 'actions/pages/my-requests.action';

export const myRequestReducer = (state: IMyRequestsState = initialState, action: Action<any>) => {
  switch (action.type) {
    case ACTIVE_STEP:
      return {
        ...state,
        activeIncidentStepList: action.payload
      };

    case IS_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload
      };

    case IS_ACTIVE_DAMAGE_PREVIEW:
      return {
        ...state,
        isActiveDamagePreview: action.payload
      };

    case UPDATE_INCIDENT_ID:
      return {
        ...state,
        updateIncidentId: action.payload
      };

    case COMPLETED_INCIDENT_ID:
      return {
        ...state,
        completedIncidentId: action.payload
      };

    case OPEN_INCIDENT_DATA:
      return {
        ...state,
        openIncidentData: action.payload
      };

    default:
      return state;
  }
};
