import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

export const getMoneyValue = (value: string | number): ReactElement => {
  return <span>
    <NumberFormat
      value={value}
      displayType='text'
      thousandSeparator='.'
      decimalSeparator=','
      fixedDecimalScale={true}
      decimalScale={2}
    />
    <span>&nbsp;EUR</span>
  </span>;
};
