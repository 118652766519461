import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../endpoints';
import authService from '../auth-service';
import {
  AcceptIncidentOptional,
  RejectionCode,
  IGetRepairPartnerList,
  IGetRepairPartnerListSearch,
  IPostponeIncident,
  IRepairPartnerFilterResponse,
  IRepairPartnerItemsResponse,
  IUpdateStep,
  StepType,
  UploadSettleUpDateType
} from './requests-service.props';
import { ResponseModel, TableRequest } from 'common/interfaces';
import { StatusDescriptionId } from 'pages/my-requests/components/table/expand/expand.props';
import { DateFieldName } from 'pages/my-requests/components/table/expand/steps-controller/completion-confirmed/form/form.props';

class RequestService {
  getRepairPartnerList = async ({
    pagination,
    search
  }: Omit<TableRequest<IGetRepairPartnerListSearch>, 'sort'>): Promise<
    AxiosResponse<ResponseModel<IGetRepairPartnerList>>
  > => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/repair-partner-list`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        Pagination: {
          Start: pagination.start,
          Number: pagination.number
        },
        Search: search
      }
    });
  };

  getRepairPartnerById = async (
    incidentId: string
  ): Promise<AxiosResponse<ResponseModel<Partial<IRepairPartnerItemsResponse>>>> => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/incidents/get-incident/${incidentId}`,
      headers: { Authorization: `Bearer ${token}` }
    });
  };

  getRepairPartnerFilters = async (): Promise<AxiosResponse<ResponseModel<IRepairPartnerFilterResponse>>> => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/incidents/repair-partner-list-filters`,
      headers: { Authorization: `Bearer ${token}` }
    });
  };

  postponeIncident = async (data: IPostponeIncident) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/postpone-incident`,
      headers: { Authorization: `Bearer ${token}` },
      data
    });
  };

  updateStep = async (data: IUpdateStep) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/updatestep-incident`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        StepModel: data
      }
    });
  };

  uploadSettleUpDate = async (incidentId: string, stepType: StepType, data: UploadSettleUpDateType) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/updatestepmodel`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        Incident: {
          id: incidentId,
          Attributes: {
            uds_rap_reparartur_ende: {
              attributeValue: data[DateFieldName.EndOfRepair],
              attributeTypeCode: 2
            },
            uds_rap_reparaturbeginn: {
              attributeValue: data[DateFieldName.StartOfRepair],
              attributeTypeCode: 2
            },
            new__rap_uebergabe_nutzer: {
              attributeValue: data[DateFieldName.HandoverToUser],
              attributeTypeCode: 2
            },
            uds_rap_abholtag: {
              attributeValue: data[DateFieldName.CollectionDay],
              attributeTypeCode: 2
            }
          }
        },
        StepType: stepType
      }
    });
  };

  acceptIncident = async (
    IncidentId: string,
    AuftragStatusDescriptionId: StatusDescriptionId,
    optional: Partial<AcceptIncidentOptional> = {}
  ) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/accept-incident`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        IncidentId,
        AuftragStatusDescriptionId,
        ...optional
      }
    });
  };

  switchProcessIncident = async (IncidentId: string, AuftragStatusDescriptionId: string) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/switch-incident-process`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        IncidentId,
        AuftragStatusDescriptionId
      }
    });
  };

  declineIncident = async (IncidentId: string, DeclinedCode: RejectionCode, StepType: StepType) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/incidents/decline-incident`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        StepModel: {
          IncidentId,
          DeclinedCode: +DeclinedCode,
          StepType
        }
      }
    });
  };

  sendEmailOnLoadFile = async (incidentId: string, stepType: StepType, stepSection: string, files: string[]) => {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/maineAnfragen/sendEmailOnLoadFile`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        incidentId,
        stepType,
        stepSection,
        files
      }
    });
  };
}

export const requestService = new RequestService();
