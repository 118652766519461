import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionType, EventType } from '@azure/msal-browser';
import { forgotPasswordRequest, loginRequest, resetPasswordPolicy, signInPolicy } from '../services/auth-service';
import Alert from '../components/alert';
import ComparisonForm from '../components/comparison-form';
import LastRouterLocation from '../components/last-router-location';
import Menu from '../components/menu';
import {
  MODALS,
  MODAL_NAMES,
  USER_ROLES,
  defaultGuid,
  SESSION_FILTER_KEY,
  ROLES_WITH_NO_ACCESS_TO_DASHBOARD_REDIRECT
} from '../constants';
import ModalOverlay from '../modal-overlay/modal-overlay';
import { head } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setSnakbar, setCustomUrlTree } from '../actions/app_action';
import { setUserLastSingIn } from '../services/user-service';
import DashboardTitle from '../components/dashboard-title';
import { useBlockerModal } from 'hooks/modals/use-blocker.modal';
import { INDICATOR_TYPE, useRefreshIndicator } from 'hooks/use-refresh-indicator';
import { cloneDeep } from 'lodash';
import { defaultUrlTree } from 'components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { ComposedFormContextList } from './index.composed-context';
import { getRoutes, useGetRoutes } from './get-routes';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { PAGES, UNAUTHORIZED_PAGES } from 'common/enums';
import { PageContainer } from './dashboard/dashboard';
import SnackbarComponent from 'components/snackbar-component';
import Snackbar from 'components/snackbar';
import LoadingComponent from 'components/loading-component';
import ConfiguratorModal from '../components/configurator-modal';
import LeadForm from '../components/lead-form';
import Modal from '../components/modal';
import ListPage from './list';
import OfferPage from './offer';
import FileViewerComponent from '../components/file-viewer';
import PageNotFound from '../components/page-not-found';
import InsuranceDialog from '../components/insurance-dialog/insurance-dialog-view';
import RepairStatusPageUnauthorized from './repair-status/unauthorized/repair-status-unauthorized';
import CheckoutPopup from '../components/checkout-popup/checkout-popup';
import QuizComponent from 'components/quiz-component/quiz-component';
import ReportDamagePage from './report-damage-page/report-damage-component';
import DashboardGuidedTour from 'components/dashboard-guided-tour/dashboard-guided-tour';
import AfterTourPopUp from 'components/after-tour-pop-up/after-tour-pop-up';
import NewVehicleRequestSurvey from '../components/new-vehicle-request-survey';
import SigningExternalComponent from './signing-external-page/signing-external-conponent';
import { useOnPageChange } from './hooks/use-on-page-change';
import ReleaseExternalPage from './release-external-page';
import useSetUserInfoToRedux from '../hooks/react-query/use-set-user-info-to-redux';
import useUserInfo from 'hooks/react-query/use-user-info';
import { useRedirectTo } from 'hooks/use-redirect-to';

const Pages = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(state => state['app'].user);
  const snackbar = useSelector(state => state['app'].snackbar);
  const snackbarComponent = useSelector(state => state['app'].snackbarComponent);

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isAdminOrFleetManager = useIsUserHaveRole([USER_ROLES.administrator.id, USER_ROLES.fleetManager.id]);
  const isNoNeededDashboardTitle = useIsUserHaveRole(Object.keys(ROLES_WITH_NO_ACCESS_TO_DASHBOARD_REDIRECT));
  const mainRoleId = user.mainRole?.id;

  const { dashboardRedirectTo } = useRedirectTo();
  const { getRoutes } = useGetRoutes();

  const routes = useMemo(() => getRoutes(dashboardRedirectTo, user.appPages), [mainRoleId]);

  useOnPageChange();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isNoNeededDashboardTitle) {
      const newUrlTree = cloneDeep(defaultUrlTree);
      newUrlTree.breadcrumbTitle = '';
      dispatch(setCustomUrlTree(newUrlTree));

      return () => {
        dispatch(setCustomUrlTree(null));
      };
    }
  }, [location.pathname, isNoNeededDashboardTitle]);

  const isAuthorizedPage = useMemo(
    () =>
      !Object.values(UNAUTHORIZED_PAGES)
        .map(url => url.replaceAll('/'))
        .includes(location.pathname.replaceAll('/')),
    [location.pathname]
  );

  const notFoundConfig = {
    pageTitle: '404 - Seite nicht gefunden',
    imageSrc: '/assets/images/page-not-found.png',
    buttonText: 'Zurück zum Dashboard',
    subTitle: 'Error 404',
    mainTitle: 'Seite nicht gefunden!',
    description: 'Die von dir gesuchte Seite konnte nicht gefunden werden.',
    action: () => history.push(PAGES.DASHBOARD)
  };

  const roleLessConfig = {
    pageTitle: DashboardTitle,
    imageSrc: '/assets/images/role-less-image.png',
    buttonText: 'Mobexo kontaktieren',
    subTitle: 'Error Rechte',
    mainTitle: 'Du hast keinen Zugriff.',
    description:
      'Du besitzt aktuell keine Rechte für die Inhalte dieser Seite. Bitte kontaktiere Mobexo damit sie deine Rechte prüfen.',
    action: () => (window.location.href = 'mailto:fuhrpark@mobexo.de')
  };

  const noCompanyConfig = {
    pageTitle: DashboardTitle,
    imageSrc: '/assets/images/role-less-image.png',
    buttonText: 'Mobexo kontaktieren',
    subTitle: 'Error Rechte',
    mainTitle: 'Du hast keinen Zugriff.',
    description: 'Ihnen ist keine Firma zugeordnet. Bitte kontaktieren Sie Mobexo.',
    action: () => (window.location.href = 'mailto:fuhrpark@mobexo.de')
  };

  const noPagesConfig = {
    pageTitle: DashboardTitle,
    imageSrc: '/assets/images/role-less-image.png',
    buttonText: 'Mobexo kontaktieren',
    subTitle: 'Error Rechte',
    mainTitle: 'Du hast keinen Zugriff.',
    description: 'Leider hast du keine Produkte. Bitte kontaktiere Mobexo.',
    action: () => (window.location.href = 'mailto:fuhrpark@mobexo.de')
  };

  const closeSnackbar = () => {
    dispatch(
      setSnakbar({
        ...snackbar,
        isOpen: false
      })
    );
  };

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthorizedPage) {
      return;
    }

    const callbackId = instance.addEventCallback(event => {
      instance.setActiveAccount(head(instance.getAllAccounts()));
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event.interactionType === InteractionType.Redirect) {
          if (event.payload.idTokenClaims['tfp'] === signInPolicy) {
            let account = event.payload.account;
            if (!account) {
              // event.payload.account is undefined, so we take first account from all available
              // there is no case when user can have several accounts
              account = head(instance.getAllAccounts());
            }
            instance.setActiveAccount(account);
            sessionStorage.removeItem(SESSION_FILTER_KEY);

            try {
              setUserLastSingIn();
            } catch (e) {
              console.log(e);
            }
          }
          if (event.payload.idTokenClaims['tfp'] === resetPasswordPolicy) {
            instance.logout();
          }
        }
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90077') > -1) {
          if (event.interactionType === InteractionType.Silent) {
            instance.logout();
            return;
          }
        }
        if (event.error && event.error.errorMessage.indexOf('AADB2C90080') > -1) {
          if (event.interactionType === InteractionType.Silent) {
            instance.logout();
            return;
          }
        }
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90091') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(loginRequest);
            return;
          }
        }
        if (event.error && event.error.errorMessage.indexOf('AADB2C') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(forgotPasswordRequest);
          }
        }
        if (event.error && event.error.errorMessage.indexOf('No cached authority found') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            window.location.reload();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useRefreshIndicator(INDICATOR_TYPE.freigabe, 120000, isAdminOrFleetManager);
  useRefreshIndicator(INDICATOR_TYPE.doctype, 120000, isAdminOrFleetManager);

  useUserInfo(!Object.values(UNAUTHORIZED_PAGES).includes(location.pathname) && isAuthenticated);
  useSetUserInfoToRedux(isAuthorizedPage);
  useBlockerModal(location.pathname);

  return (
    <ComposedFormContextList>
      <LastRouterLocation>
        <Menu />
        <Modal name={MODALS.general_contact_form}>
          <ModalOverlay type={MODAL_NAMES.lead_form}>
            <LeadForm />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.general_comparison_form}>
          <ModalOverlay type={MODAL_NAMES.comparison_form}>
            <ComparisonForm />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.general_configurator}>
          <ModalOverlay type={MODAL_NAMES.configurator}>
            <ConfiguratorModal />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.alert}>
          <ModalOverlay type={MODAL_NAMES.alert}>
            <Alert />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.insurance_dialog}>
          <ModalOverlay type={MODAL_NAMES.insurance_dialog}>
            <InsuranceDialog />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.file_viewer}>
          <ModalOverlay type={MODAL_NAMES.file_viewer}>
            <FileViewerComponent />
          </ModalOverlay>
        </Modal>
        <Modal name={MODALS.checkout}>
          <ModalOverlay type={MODAL_NAMES.checkout}>
            <CheckoutPopup />
          </ModalOverlay>
        </Modal>

        <Switch>
          {user.roles.length > 0 && (
            <Route path='/' exact>
              <Redirect to={isDriver ? PAGES.TASKS : PAGES.DASHBOARD} />
            </Route>
          )}
          <Route key='general-offer' path={UNAUTHORIZED_PAGES.LIST}>
            <ListPage />
          </Route>
          <Route key='private-offer' path={UNAUTHORIZED_PAGES.OFFER} exact>
            <OfferPage />
          </Route>
          <Route key='reparaturstatus' path={UNAUTHORIZED_PAGES.REPAIRED_STATUS}>
            <RepairStatusPageUnauthorized />
          </Route>
          <Route key='signing' path={UNAUTHORIZED_PAGES.SURVEY}>
            <NewVehicleRequestSurvey />
          </Route>
          <Route key='umfrage' path={UNAUTHORIZED_PAGES.OPINION_POLL}>
            <QuizComponent />
          </Route>
          <Route key='schaden-melden' path={UNAUTHORIZED_PAGES.REPORT_DAMAGE}>
            <ReportDamagePage />
          </Route>

          <Route key='signing' path={UNAUTHORIZED_PAGES.SIGNING}>
            <SigningExternalComponent />
          </Route>

          <Route key='release' path={UNAUTHORIZED_PAGES.RELEASE}>
            <ReleaseExternalPage />
          </Route>

          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ ...loginRequest }}
            errorComponent={() => <LoadingComponent />}
            loadingComponent={() => <LoadingComponent />}
          >
            <PageContainer>
              <Switch>
                {!user.appPages.length && (
                  <Route path='/'>
                    <PageNotFound config={noPagesConfig} />
                  </Route>
                )}
                {!user.roles.length && (
                  <Route path='/'>
                    <PageNotFound config={roleLessConfig} />
                  </Route>
                )}
                {user.companyId === defaultGuid && (
                  <Route path='/'>
                    <PageNotFound config={noCompanyConfig} />
                  </Route>
                )}
                {routes.map(route => (
                  <Route key={route.path} path={route.path} exact>
                    {(route.hasAccess ? route.hasAccess : false) ? route.component : <Redirect to={route.redirectTo} />}
                  </Route>
                ))}
                <Route path={PAGES.SIGN_IN}>
                  <Redirect to={PAGES.DASHBOARD} />
                </Route>
                <Route path='/'>
                  <PageNotFound config={notFoundConfig} />
                </Route>
              </Switch>
            </PageContainer>
          </MsalAuthenticationTemplate>
        </Switch>

        <Snackbar
          open={snackbar.isOpen}
          type={snackbar.type}
          time={typeof snackbar.time === 'number' ? snackbar.time : 6000}
          onClose={typeof snackbar.onClose === 'function' ? snackbar.onClose : closeSnackbar}
          message={snackbar.message}
        />
        <SnackbarComponent
          open={snackbarComponent.open}
          type={snackbarComponent.type}
          component={snackbarComponent.component}
        />

        <DashboardGuidedTour isSideMenuVisible={window.innerWidth > 1200} />
        <AfterTourPopUp />
      </LastRouterLocation>
    </ComposedFormContextList>
  );
};

export default Pages;
