import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';
import ChangeCostCenter from 'components/cost-centers-settings/change-cost-center';
import { Text } from 'common/common-components.styled';
import { ChangedFieldTypes, PrimaryEntityNames } from '../cost-centers-settings.props';
import { CostCentersHistoryItem } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import { ChangeCostCenterContext } from '../change-cost-center/change-cost-center.context';

export const useChangeCostCenterModals = () => {
  const dispatch = useDispatch();

  const openCostCenterHistoryModal = (
    callback: () => void,
    entityName: PrimaryEntityNames,
    id: string,
    item?: CostCentersHistoryItem,
    costCenterId?: string,
    showInfoHint?: boolean
  ) => {
    const alertData = {
      title: !!item ? 'Kostenstelle bearbeiten' : 'Kostenstelle ändern',
      children: (
        <ChangeCostCenterContext.Provider
          value={{ closeModal, callback, item, entityName, id, showInfoHint, costCenterId }}
        >
          <ChangeCostCenter />
        </ChangeCostCenterContext.Provider>
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openChangeLocationOrDriverModal = (type: ChangedFieldTypes, updateCallback: () => void) => {
    const alertData = {
      hideCloseIcon: true,
      title: 'Kostenstelle wurde angepasst',
      children: (
        <Text color='grey900'>
          {TEXTS_DATA[type].entity} sind als primäre Entität für Kostenstellen definiert. Die Kostenstelle des Fahrzeugs
          wurde angepasst.
        </Text>
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal,
          hide: true
        },
        {
          type: 'submit',
          title: 'Verstanden',
          action: () => {
            closeModal();
            updateCallback();
          }
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return { openCostCenterHistoryModal, openChangeLocationOrDriverModal };
};

const TEXTS_DATA = {
  [ChangedFieldTypes.Driver]: {
    entity: 'Fahrer'
  },
  [ChangedFieldTypes.Location]: {
    entity: 'Standorte'
  }
};
