import { useEffect, useRef, useState } from 'react';

const useExpand = (isOpen = false, smooth = false) => {
  const [height, setHeight] = useState('0px');

  const content = useRef(null);

  useEffect(() => {
    let resizeObserver = null;
    if (smooth && window.ResizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        setHeight(isOpen ? content.current.scrollHeight : '0px');
      });
      resizeObserver.observe(content.current.children[0]);
    }

    return () => {
      if (smooth && window.ResizeObserver) {
        resizeObserver.unobserve(content.current.children[0]);
      }
    };
  }, []);

  function toggleAccordion() {
    setHeight(height === '0px'
      ? smooth && window.ResizeObserver
        ? content.current.scrollHeight
        : 'auto'
      : '0px'
    );
  }

  useEffect(() => {
    setHeight(isOpen
      ? smooth && window.ResizeObserver
        ? content.current.scrollHeight
        : 'auto'
      : '0px'
    );
  }, [isOpen]);

  return { height, content, toggleAccordion, expand: height !== '0px' };
};

export default useExpand;
