import React from 'react';
import { withTheme } from 'styled-components';

const AddIcon = ({ theme, width = 16, height = 16, color = theme.colors.blue, ...props }) => {
  return <svg width={width} height={height} {...props} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8Z' fill={color} />
    <path d='M8 4.13281V11.6661' stroke='white' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.2002 8H11.8002' stroke='white' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
  </svg>;
};

export default withTheme(AddIcon);
