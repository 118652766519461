/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import { ComponentsProps } from './components.props';

const EmailFieldComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldRegisterToCheck = '',
  fieldName,
  fieldPlaceholder,
  optional = false,
  optionalText = '(optional)',
  readOnly = false
}: ComponentsProps) => {
  const {
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = reactHookFormData;

  const [emailValue, setEmailValue] = useState<string>('');
  const emailValidation = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z_\-\.]+)\.([a-zA-Z]{2,15})$/;
  const checkValue = watch(fieldRegister);
  const checkSecondValue = watch(fieldRegisterToCheck);

  const changeValue = (value: string) => {
    setEmailValue(value);
    setValue(fieldRegister, value);
  };

  useEffect(() => {
    if (!!watch(fieldRegister)) {
      if (watch(fieldRegister).search(emailValidation) === -1) {
        setError(fieldRegister, {
          type: 'manual',
          message:
            'Ungültige E-Mail Adresse. Eine gültige E-Mail darf nur lateinische Buchstaben, Zahlen, @ und . enthalten.'
        });
      } else {
        clearErrors(fieldRegister);
      }
    }
    if (watch(fieldRegister) === '') {
      clearErrors(fieldRegister);
    }
  }, [checkValue, watch(fieldRegister)]);

  useEffect(() => {
    if (
      !!fieldRegisterToCheck &&
      !!watch(fieldRegister) &&
      !!watch(fieldRegisterToCheck) &&
      watch(fieldRegister) !== '' &&
      watch(fieldRegisterToCheck) !== ''
    ) {
      if (watch(fieldRegister) !== watch(fieldRegisterToCheck)) {
        setError(fieldRegister, {
          type: 'manual',
          message: 'E-Mail Adresse sollte übereinstimmen!'
        });
        return;
      }
      if (watch(fieldRegister) === watch(fieldRegisterToCheck) && watch(fieldRegister).search(emailValidation) === 0) {
        clearErrors(fieldRegister);
      }
    }
  }, [checkSecondValue, checkValue]);

  return (
    <div className={styles.field_container}>
      <div className={readOnly ? styles.form_name_block : styles.form_name}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>{optionalText}</span>}
        </span>
      </div>
      <input
        placeholder={fieldPlaceholder}
        {...register(fieldRegister, {
          required: optional ? false : 'E-mail adresse erforderlich'
        })}
        type='text'
        onChange={e => changeValue(e.target.value)}
        value={!!watch(fieldRegister) ? watch(fieldRegister) : emailValue}
        maxLength={100}
        autoComplete='nope'
        readOnly={readOnly}
        className={
          errors[fieldRegister]
            ? styles.input__icons_err__text
            : readOnly
            ? styles.input_standart_block
            : styles.input_standart
        }
        id={`${fieldName}EmailInput`}
      />
      <div className={styles.err__row}>
        {errors[fieldRegister]?.message && typeof errors[fieldRegister]?.message === 'string' && (
          <>
            <img src='/assets/images/err-icon.svg' />
            <p>{String(errors[fieldRegister]?.message)}</p>
          </>
        )}
      </div>

      <Distance top='10' />
    </div>
  );
};

export default EmailFieldComponent;
