import React, { Component } from 'react';
import classnames from 'classnames';
import classes from './lead-form.module.scss';
import Spinner from '../spinner';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('AW-600353328');

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProtectionOpen: false,
      form: {
        name: '',
        company: '',
        email: '',
        tel: '',
        fahrzeuge: '',
        message: ''
      },
      formErrors: {
        name: { touched: false, hasError: false },
        email: { touched: false, hasError: false },
        tel: { touched: false, hasError: false },
        company: { touched: false, hasError: false },
        fahrzeuge: { touched: false, hasError: false }
      },
      formValid: false,
      triedSubmit: false
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.submitButtonHandler = this.submitButtonHandler.bind(this);
  }
  changeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          [name]: value
        }
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    if (fieldValidationErrors.hasOwnProperty(fieldName)) {
      switch (fieldName) {
        case 'email':
          fieldValidationErrors.email.hasError = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          break;
        default:
          fieldValidationErrors[fieldName].hasError = !!value;
          break;
      }
      fieldValidationErrors[fieldName].touched = true;
      this.setState({ formErrors: fieldValidationErrors }, this.validateForm);
    }
  }

  validateForm() {
    const formValid = Object.values(this.state.formErrors).every(value => value.hasError && value.touched);
    this.setState({ formValid: formValid });
  }

  async submitButtonHandler() {
    if (this.state.formValid) {
      const model = { ...this.state.form };
      await this.props.onSubmit(model);
      ReactGA.event({ category: 'Lead form submitted', action: 'conversion' });
    } else {
      this.setState({ triedSubmit: true });
    }
  }

  render() {
    const formErrors = this.state.formErrors;
    const triedSubmit = this.state.triedSubmit;
    return (
      <div className={classes.form}>
        <section className={classes.main_info}>
          <div className={classes.form_group}>
            <label htmlFor='name'>Vorname, Nachname</label>
            <input
              id='name'
              type='text'
              name='name'
              value={this.state.form.name}
              onChange={this.changeHandler}
              className={classnames(classes.form_control, {
                [classes.has_error]: !formErrors.name.hasError && triedSubmit
              })}
              placeholder='Vorname, Nachname'
              required=''
            />
          </div>
          <div className={classes.form_group}>
            <label htmlFor='email'>E-mail</label>
            <input
              id='email'
              type='email'
              name='email'
              value={this.state.form.email}
              onChange={this.changeHandler}
              required=''
              className={classnames(classes.form_control, {
                [classes.has_error]: !formErrors.email.hasError && triedSubmit
              })}
              placeholder='E-mail'
            />
          </div>
        </section>
        <section className={classes.main_info}>
          <div className={classes.form_group}>
            <label htmlFor='tel'>Telefon</label>
            <input
              id='tel'
              type='text'
              name='tel'
              value={this.state.form.tel}
              onChange={this.changeHandler}
              className={classnames(classes.form_control, {
                [classes.has_error]: !formErrors.tel.hasError && triedSubmit
              })}
              placeholder='Telefon'
            />
          </div>
          <div className={classes.form_group}>
            <label htmlFor='company'>Firma</label>
            <input
              id='company'
              type='text'
              name='company'
              value={this.state.form.company}
              onChange={this.changeHandler}
              className={classnames(classes.form_control, {
                [classes.has_error]: !formErrors.company.hasError && triedSubmit
              })}
              placeholder='Firma'
            />
          </div>
        </section>

        <div style={{ paddingBottom: '20px' }}>
          <label htmlFor='fahrzeuge'>Anzahl Fahrzeuge</label>
          <input
            id='fahrzeuge'
            type='number'
            name='fahrzeuge'
            value={this.state.form.fahrzeuge}
            onChange={this.changeHandler}
            className={classnames(classes.form_control, {
              [classes.has_error]: !formErrors.fahrzeuge.hasError && triedSubmit
            })}
            placeholder='Anzahl Fahrzeuge'
          />
        </div>

        <div>
          <label htmlFor='message'>Nachricht</label>
          <textarea
            id='message'
            name='message'
            placeholder='Hier schreiben'
            value={this.state.form.message}
            onChange={this.changeHandler}
            className={classnames(classes.form_control, classes.message)}
          />
        </div>

        <div className={classnames(classes.data_protection, { [classes.open]: this.state.dataProtectionOpen })}>
          <div className={classes.text}>
            Wir verwenden Ihre personenbezogenen Daten ausschließlich im Rahmen der Bearbeitung Ihrer Angebotsanfrage
            bzw. zur Kontaktaufnahme mit Ihnen (Art. 6 Abs. 1 lit. b DSGVO). Weitere datenschutzrechtliche Informationen
            entnehmen Sie bitte unserer{' '}
            <Link className={classes.link} to='/datenschutz'>
              Datenschutzerklärung
            </Link>
          </div>
          <div className={classes.more} onClick={() => this.setState({ dataProtectionOpen: true })}>
            Mehr...
          </div>
        </div>

        <button
          style={{ backgroundColor: this.props.title === 'Rückfrage stellen' ? '#F9A703' : '#335566' }}
          className={classes.btn}
          onClick={this.submitButtonHandler}
        >
          <Spinner style={{ width: '40px', display: this.props.submitting ? 'block' : 'none', margin: 'auto' }} />
          <span style={{ display: !this.props.submitting ? 'block' : 'none' }}>
            {this.props.title === 'Rückfrage stellen' ? 'Rückfrage stellen' : 'Jetzt anfragen'}
          </span>
        </button>
      </div>
    );
  }
}
