import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CheckedIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProp,
  hovered,
  ...restProps
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();

  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {hovered && <circle cx='10.29' cy='10' r='9' fill={fill} />}
      <path
        d='M13.1079 18.5501C12.2215 18.842 11.2742 19 10.29 19C5.31948 19 1.29004 14.9706 1.29004 10C1.29004 5.02944 5.31948 1 10.29 1C15.2606 1 19.29 5.02944 19.29 10C19.29 12.6738 18.1241 15.0753 16.2728 16.7237'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.79 6.37099L9.85456 13.6291L5.79004 9.56454'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
