import { ADD_CAR, DELETE_CAR, DELETE_ALL_CARS, TOGGLE_COMPARISON_PANEL } from '../actions/comparison_action';

const INITIAL_STATE = {
  cars: [],
  comparisonPanelOpen: false
};

export default function comparisonReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_CAR:
      return { ...state, cars: [...state.cars, action.payload] };
    case DELETE_CAR:
      return { ...state, cars: [...state.cars.filter(car => car.uds_fahrzeugangeboteid !== action.payload)] };
    case DELETE_ALL_CARS:
      return { ...state, cars: [] };
    case TOGGLE_COMPARISON_PANEL:
      return { ...state, comparisonPanelOpen: action.payload };
    default:
      return state;
  }
}
