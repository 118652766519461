import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledWidgetTileEmptyPlaceholderContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;
