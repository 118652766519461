import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const StyledIconHoverContainer = styled(Flex)`
  .icon-hovered {
    display: none;
    cursor: pointer;
  }
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &:hover {
    .icon-hovered {
      display: flex;
      align-items: center;
    }
    .icon {
      display: none;
    }
  }
`;
