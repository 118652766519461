import React, { cloneElement, useContext } from 'react';
import classes from '../cell.module.scss';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import classNames from 'classnames';
import get from 'lodash/get';
import { CellContext } from 'components/table-component/table-component-contexts';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import useMaxWidthForEllipsisWithTooltip from 'components/ellipsis-with-tooltip/hooks/use-max-width-for-ellipsis-with-tooltip';
import { TooltipComponentProps } from 'components/tooltip-component/tooltip-component.props';

export const TextCell = ({ column, row, openedItemId }: CellProps) => {
  const { cellId } = useContext<ICellContext>(CellContext);
  const { container, maxWidth } = useMaxWidthForEllipsisWithTooltip();

  const bezeichnungData = [
    {
      name: row.uds_serviceid,
      logo: '/assets/images/uberfuhrungen-icon.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/werkstattleistung-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/leasingruckgabe-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/leasingruckgabe-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/leasingvertragsverlangerung-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/mietwagen-bestellen-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/neuwagen-anfragen-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/fahrzeug-verkaufen-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/fahrzeug-aufbereiten-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/raderwechsel-terminieren-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: '/assets/images/kostenstellenwechsel-icon-blue.svg'
    },
    {
      name: row.uds_serviceid,
      logo: null
    }
  ];

  const text = column.component
    ? cloneElement(column.component, { row, openedItemId })
    : get(row, column.propName || '') || '-';
  const tooltipText = column.customTooltip ? column.customTooltip(row) : text;
  let isText = bezeichnungData.findIndex(dataItem => dataItem.name === text);
  let showImg = bezeichnungData[row.serviceCode];
  const isShowTextWithImg = !isText && showImg?.logo && showImg?.logo;

  return (
    <span
      id={cellId}
      style={{ width: column.width }}
      ref={container}
      className={classNames(classes.table_text, column.className, classes.cell)}
    >
      <EllipsisWithTooltip tooltip={tooltipText} maxWidth={`${maxWidth}px`} tooltipProps={tooltipProps}>
        <div>
          {isShowTextWithImg ? (
            <div className={classes.show_img}>
              <img src={showImg.logo as string} alt={showImg.name} />
              {text}
            </div>
          ) : (
            text
          )}
        </div>
      </EllipsisWithTooltip>
    </span>
  );
};

const tooltipProps: Partial<TooltipComponentProps> = {
  PopperProps: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -20]
        }
      }
    ]
  }
};
