import React from 'react';
import { RepeatChildrenProps } from './repeat-children.props';

export const RepeatChildren = ({ repeatNumber, item }: RepeatChildrenProps) => {
  return (
    <>
      {Array(repeatNumber)
        .fill(1)
        .map(() => item)}
    </>
  );
};
