import { IconProps } from 'common/interfaces';
import React from 'react';

export const AskQuestionIcon = ({ fill = 'none' }: IconProps) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.4065 18.8686C24.6747 16.6807 25.2748 14.067 24.9167 11.2887C24.2003 5.80948 19.6892 1.45322 14.1906 0.940153C6.63979 0.223791 0.366789 6.50647 1.07347 14.0573C1.59622 19.5655 5.94278 24.067 11.422 24.793C14.21 25.1609 16.8237 24.551 19.0115 23.2829L23.9486 24.6091C24.423 24.735 24.8683 24.2993 24.7327 23.825L23.4065 18.8686Z'
      fill={fill}
    />
    <path
      d='M13 17.7769C12.787 17.7769 12.6128 17.9512 12.6128 18.1641C12.6128 18.3771 12.787 18.5514 13 18.5514C13.213 18.5514 13.3872 18.3771 13.3872 18.1641C13.3872 17.9512 13.213 17.7769 13 17.7769Z'
      fill='#102F3F'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.51514 10.4197C9.51514 8.49326 11.0737 6.93469 13.0001 6.93469C14.9266 6.93469 16.4851 8.49326 16.4851 10.4197C16.4851 12.3461 14.9266 13.9047 13.0001 13.9047V15.8408'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.0115 23.2829L23.9486 24.6091C24.423 24.735 24.8683 24.2993 24.7327 23.825L23.4065 18.8686C24.6747 16.6807 25.2748 14.067 24.9167 11.2887C24.2003 5.80948 19.6892 1.45322 14.1906 0.940153C6.63979 0.223791 0.366789 6.50647 1.07347 14.0573C1.59622 19.5655 5.94278 24.067 11.422 24.793C12.8874 24.9864 14.3047 24.9096 15.6373 24.6091'
      stroke='#102F3F'
      strokeWidth='1.2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
