import { DeviceTypes } from './../constants';

export const getDeviceType = (): DeviceTypes => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DeviceTypes.Tablet;
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return DeviceTypes.Mobile;
  }
  return DeviceTypes.Desktop;
};
