import React from 'react';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { Flex, Text } from 'common/common-components.styled';
import { VehicleDetailCarSectionStatus } from './status';
import useWindowSize from 'hooks/use-window-size';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

export const VehicleDetailCarSectionTitle = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isMobile } = useWindowSize();

  const getTitle = () => {
    const manufacturer = fuhrpark?.new_hersteller;
    const model = fuhrpark?.new_model;

    if (manufacturer || model) {
      return `${manufacturer || '-'} ${model || '-'}`;
    }

    return '-';
  };

  return (
    <Flex align='center'>
      <EllipsisWithTooltip tooltip={getTitle()}>
        <Text color='black' size='20' bold maxWidth={isMobile ? '60%' : '100%'} right='10'>
          {getTitle()}
        </Text>
      </EllipsisWithTooltip>

      {!isMobile && <VehicleDetailCarSectionStatus />}
    </Flex>
  );
};
