import { useContext } from 'react';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../constants';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export const useVehicleCostWidgetIsShow = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const isWidgetAvailableForRole = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const isShowVehicleCostWidget =
    vehicle.fuhrpark?.new_status_carcode?.attributeValue?.value !== VehicleStatusesValue.Ordered &&
    isWidgetAvailableForRole;

  return { isShowVehicleCostWidget };
};
