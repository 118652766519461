import { FormFieldOption, LookupResponse } from 'common/interfaces';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const useUserCompaniesOptionSet = () => {
  const user = useTypedSelector(state => state.app.user);

  const userCompaniesOptionSet: FormFieldOption[] = user.childCompanies
    .concat([{ id: user?.companyId, name: user?.companyName }])
    .map((item: LookupResponse) => ({ label: item.name, value: item.id }));

  return { userCompaniesOptionSet };
};
