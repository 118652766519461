import React from 'react';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import { IconProps } from 'common/interfaces';

const TopDealsIcon = ({ width = 20, height = 18, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.6498 7.13558C17.7732 7.46203 17.8385 7.81025 17.8385 8.16572V8.57922V13.0625H5.93386V14.2232C5.93386 14.5424 5.6727 14.8036 5.3535 14.8036H3.03207C2.71287 14.8036 2.45171 14.5424 2.45171 14.2232V12.7143L2.21957 11.6043C2.1833 11.4157 2.16153 11.2126 2.16153 11.0167V8.16572C2.16153 7.8175 2.22683 7.46203 2.35016 7.13558L2.63307 6.38837H1.291C1.10239 6.38837 0.96455 6.21426 1.00808 6.0329L1.22571 5.09707C1.291 4.83591 1.52315 4.64729 1.79156 4.64729H3.21343L4.33063 2.42017C4.62807 1.8253 5.23019 1.45532 5.89034 1.45532H14.1097C14.7698 1.45532 15.372 1.83256 15.6694 2.42017L16.7866 4.64729H18.2085C18.4769 4.64729 18.7163 4.83591 18.7743 5.09707L18.9919 6.0329C19.0354 6.21426 18.8976 6.38837 18.709 6.38837H17.3669L17.6498 7.13558Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M17.6498 7.13557C17.7732 7.46202 17.8385 7.81023 17.8385 8.1657V8.57921V13.0625H5.93386V14.2232C5.93386 14.5424 5.6727 14.8035 5.3535 14.8035H3.03207C2.71287 14.8035 2.45171 14.5424 2.45171 14.2232V12.7143L2.21957 11.6043C2.1833 11.4157 2.16153 11.2126 2.16153 11.0167V8.1657C2.16153 7.81749 2.22683 7.46202 2.35016 7.13557L2.63307 6.38835H1.291C1.10239 6.38835 0.96455 6.21424 1.00808 6.03288L1.22571 5.09705C1.291 4.83589 1.52315 4.64728 1.79156 4.64728H3.21343L4.9722 5.82295H15.0299L16.7866 4.64728H18.2085C18.4769 4.64728 18.7163 4.83589 18.7743 5.09705L18.9919 6.03288C19.0354 6.21424 18.8976 6.38835 18.709 6.38835H17.3669L17.6498 7.13557Z'
          fill={fill}
        />
      )}
      <path
        d='M4.77314 13.0625H5.93386V14.2232C5.93386 14.5424 5.6727 14.8036 5.3535 14.8036H3.03207C2.71287 14.8036 2.45171 14.5424 2.45171 14.2232V12.7143L2.21957 11.6043C2.1833 11.4157 2.16153 11.2126 2.16153 11.0167V8.16572C2.16153 7.8175 2.22683 7.46203 2.35016 7.13558L2.63307 6.38837H1.291C1.10239 6.38837 0.96455 6.21426 1.00808 6.0329L1.22571 5.09707C1.291 4.83591 1.52315 4.64729 1.79156 4.64729H3.21343L4.33063 2.42017C4.62807 1.8253 5.23019 1.45532 5.89034 1.45532H14.1097C14.7698 1.45532 15.372 1.83256 15.6694 2.42017L16.7866 4.64729H18.2085C18.4769 4.64729 18.7163 4.83591 18.7743 5.09707L18.9919 6.0329C19.0355 6.21426 18.8976 6.38837 18.709 6.38837H17.3669L17.6498 7.13558C17.7732 7.46203 17.8385 7.81025 17.8385 8.16572V8.57922'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.90991 9.58038H6.32566L5.91216 8.76062'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.07068 5.80798H14.9368'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='13.7543' cy='12.4847' r='4.065' fill={hovered ? 'white' : 'none'} />
      <path
        d='M5.90491 13.0625H9.60329'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5653 13.6771L14.7392 14.4226C14.8168 14.4715 14.9164 14.4007 14.8927 14.313L14.5335 12.9872L15.6197 12.1219C15.6923 12.0645 15.6535 11.9498 15.5607 11.9447L14.1641 11.8705L13.6614 10.5903C13.6277 10.5043 13.5045 10.5043 13.4708 10.5903L12.9681 11.8705L11.5715 11.9447C11.4787 11.9498 11.4399 12.0645 11.5125 12.1219L12.5987 12.9872L12.2395 14.313C12.2158 14.4007 12.3154 14.4715 12.3929 14.4226L13.5653 13.6771Z'
        fill={color}
      />
      <path
        d='M9.79895 11.1879C10.3407 9.58183 11.8579 8.42212 13.6471 8.42212C15.8888 8.42212 17.7096 10.243 17.7096 12.4846C17.7096 14.7263 15.8888 16.5471 13.6471 16.5471C12.2512 16.5471 11.0173 15.8395 10.2857 14.7645'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TopDealsIcon;
