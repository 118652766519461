import { useContext, useMemo } from 'react';
import { REPLACEMENT_VEHICLE_PHASES, USER_ROLES } from '../../../../../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useHistory } from 'react-router-dom';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { encodeBase64 } from 'utils/decode-encode-base64';
import { PAGES } from 'common/enums';
import { cloneDeep } from 'lodash';
import { defaultUrlTree } from 'components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { setCustomUrlTree } from 'actions/app_action';

export const useReplacementVehicleOrderingButtons = () => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const phaseInfo = car.rvo.rvo_phaseinfo;
  const driver = car.new_fahrerid;
  const phase = car.rvo.rvo_state;
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const history = useHistory();
  const customUrlTree = useTypedSelector(state => state['app'].customUrlTree);
  const dispatch = useDispatch();

  const redirectToOrders = () => {
    isFleetManager
      ? phaseInfo.configurationId &&
        history.push(`${PAGES.ORDER_DETAILS}?id=${encodeBase64(phaseInfo?.configurationId?.id)}`)
      : phaseInfo.configurationId &&
        history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(phaseInfo?.configurationId?.id)}`);
  };

  const redirectToNewVehicle = () => {
    const newUrlTree = cloneDeep(customUrlTree ? customUrlTree : defaultUrlTree);

    newUrlTree.children['meine-fahrzeuge'].children['fahrzeuge'] = {
      breadcrumbTitle: '',
      title: '',
      url: ''
    };

    dispatch(setCustomUrlTree(newUrlTree));
    phaseInfo.orderedVehicle && history.push(`${PAGES.MY_VEHICLES_DETAILS}/?id=${phaseInfo?.orderedVehicle?.id}`);
    refresh();
  };

  const redirectToConfigureVehicle = () => {
    if (isFleetManager) {
      if (driver.attributeValue) {
        history.push(
          `${PAGES.NEW_REFERENCE_CONFIGURATIONS}?driverId=${encodeBase64(driver?.attributeValue?.id)}&redirect=${true}`
        );
      } else {
        history.push(`${PAGES.EMPLOYEE_CONFIGURATIONS}?newConfig=${true}`);
      }
    } else {
      history.push(`${PAGES.NEW_CONFIGURATIONS}?redirect=${true}`);
    }
  };

  const button = useMemo(() => {
    return {
      [REPLACEMENT_VEHICLE_PHASES.created]: {
        title: 'Jetzt konfigurieren',
        action: redirectToConfigureVehicle
      },
      [REPLACEMENT_VEHICLE_PHASES.open]: {
        title: 'Konfigurieren',
        action: redirectToConfigureVehicle
      },
      [REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle]: {
        title: 'Konfigurieren',
        action: redirectToConfigureVehicle,
        disabled: true
      },
      [REPLACEMENT_VEHICLE_PHASES.processing]: {
        title: 'Weitere Konfiguration starten',
        action: redirectToConfigureVehicle
      },
      [REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered]: {
        title: 'Zur Bestellung',
        action: redirectToOrders
      },
      [REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered]: {
        title: 'Zum Fahrzeug',
        action: redirectToNewVehicle
      }
    };
  }, []);

  return { button: button[phase] };
};
