import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DownloadIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProp,
  hovered,
  ...restProps
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg
      {...restProps}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5482 16.3871V17.8387C18.5482 18.4775 18.0256 19 17.3869 19H1.70951C1.0708 19 0.548218 18.4775 0.548218 17.8387V16.3871'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M3.68365 9.50645L9.10542 15.8718C9.33768 16.1403 9.75866 16.1403 9.99092 15.8718L15.4127 9.50645C15.732 9.12903 15.4635 8.54839 14.9699 8.54839H12.4514V1H6.64494V8.54839H4.1264C3.63285 8.54839 3.36429 9.12903 3.68365 9.50645Z'
          fill={fill}
        />
      )}
      <path
        d='M12.4514 8.54839H14.9699C15.4635 8.54839 15.732 9.12903 15.4127 9.50645L9.99092 15.8718C9.75866 16.1403 9.33768 16.1403 9.10542 15.8718L3.68365 9.50645C3.36429 9.12903 3.63285 8.54839 4.1264 8.54839H6.64494V1H12.4514V5.7017'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
