import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { LookupResponse, ResponseModel } from 'common/interfaces';
import { DriverReactQuery } from '../driver.props';
import { driverRequestConfig } from 'request-config/driver/driver.request-config';
import { GetDriversWithCarProps } from 'request-config/driver/driver.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useGetDriversWithCarQuery = (
  props: GetDriversWithCarProps,
  {
    options
  }: QueryHookOptions<
    AxiosResponse<ResponseModel<LookupResponse<'contact'>[]>>,
    ResponseModel<LookupResponse<'contact'>[]>
  > = {}
) => {
  const { fetch } = useFetch<ResponseModel<LookupResponse<'contact'>[]>>({
    loadingKey: `getDriversWithCarQuery${JSON.stringify(props)}`,
    ...driverRequestConfig.getDriversWithCar(props)
  });

  const { data, ...restQueryProps } = useQuery([DriverReactQuery.GetDriversWithCar, props], () => fetch(), options);

  return { getDriversWithCarQuery: data?.data?.data || [], ...restQueryProps };
};
