import React from 'react';
import { OptionalEquipmentItemProps } from './optional-equipment-item.types';
import {
  StyledCodeCell,
  StyledDescriptionCell,
  StyledListPriceCell,
  StyledNetListPriceCell
} from '../optional-equipment-items.styled';
import { Divider, Flex, Text } from 'common/common-components.styled';
import toMoney from 'utils/toMoney';

const OptionalEquipmentItem = ({ item }: OptionalEquipmentItemProps) => {
  return (
    <div>
      <Flex>
        <StyledCodeCell>{item.new_herstellercode}</StyledCodeCell>
        <StyledDescriptionCell>{item.new_beschreibung}</StyledDescriptionCell>
        <StyledNetListPriceCell>{item.new_preis_netto ? toMoney(item.new_preis_netto) : '-'}</StyledNetListPriceCell>
        <StyledListPriceCell>
          <Text bold color='grey800'>
            {item.uds_preis_brutto ? toMoney(item.uds_preis_brutto) : '-'}
          </Text>
        </StyledListPriceCell>
      </Flex>
      <Divider />
    </div>
  );
};

export default OptionalEquipmentItem;
