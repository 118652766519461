import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const SeatIcon = ({ width = 14, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M2.52604 8.90432V6.36159C2.52604 5.5495 2.88714 4.47335 3.85759 4.40564L9.62012 4.40607C10.5906 4.47377 10.9517 5.56179 10.9517 6.36159V8.90432C10.9517 9.20524 11.0043 9.51367 11.0946 9.80707L12.1929 13.2902C12.4863 14.223 11.8318 15.1784 10.8614 15.2461C10.8614 15.2461 8.34504 15.6239 6.72172 15.6227C5.11173 15.6215 2.61631 15.2461 2.61631 15.2461C1.64586 15.1784 0.991373 14.223 1.28477 13.2902L2.38311 9.80707C2.48091 9.51367 2.52604 9.21276 2.52604 8.90432Z'
          fill={fill}
        />
      )}
      <path
        d='M2.61631 15.2461C1.64586 15.1784 0.991373 14.223 1.28477 13.2901L2.38311 9.80705C2.48091 9.51366 2.52604 9.21274 2.52604 8.90431V6.36157C2.52604 5.54949 2.88714 4.47333 3.85759 4.40562M10.8614 15.2461C11.8318 15.1784 12.4863 14.223 12.1929 13.2901L11.0946 9.80705C11.0043 9.51366 10.9517 9.20522 10.9517 8.90431V6.36157C10.9517 5.56177 10.5906 4.47376 9.62012 4.40605'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3661 16.7582C12.6219 16.6303 12.9078 16.871 12.7949 17.1343C12.0953 18.7517 10.5907 19 6.73901 19C2.90234 19 1.39025 18.7517 0.690619 17.1343C0.577776 16.871 0.856111 16.6303 1.11189 16.7506C1.93941 17.1493 3.27096 16.2917 6.73901 16.2917C10.4327 16.2917 11.5988 17.1493 12.3661 16.7582Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.19727 1C5.22682 1.06771 4.86572 1.8298 4.86572 2.95595C4.86559 3.82601 5.22701 4.84419 6.19746 4.9119L7.2814 4.9119C8.25185 4.84419 8.61289 3.82601 8.61275 2.95595C8.61275 1.8298 8.25166 1.06771 7.28121 1H6.19727Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.72534 10.3114H8.61216'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.01807 12.7377H9.45962'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
