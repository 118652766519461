import { ApiResponse, BasketResponse } from 'common/interfaces';
import { useQuery } from 'react-query';
import { getUserBasket } from 'services/user-service';
import { ReactQueryKeys } from 'common/enums';

export const useUserBasketQuery = (id: string) => {
  const { data: basketData, ...restBasketQuery } = useQuery<ApiResponse<BasketResponse | null>>(
    [ReactQueryKeys.GetUserBasket, id],
    () => getUserBasket(id),
    { enabled: !!id }
  );

  return {
    userBasket: basketData?.data.data || null,
    ...restBasketQuery
  };
};
