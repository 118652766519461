/* eslint-disable max-len */
import React from 'react';
import styles from './success-page.module.scss';
import { successIcons } from '../utils';
import useWindowSize from 'hooks/use-window-size';
import { SuccessIconProps } from '../report-damage.props';

const SuccessPage = () => {
  const { windowWidth } = useWindowSize();

  return (
    <div className={styles.container}>
      <h5>Vielen Dank!</h5>
      <div className={styles.header}>
        Deine Schadenmeldung wurde erfolgreich angenommen.
        <br />
        Dies sind die nächsten Schritte:
      </div>

      <div className={windowWidth >= 1500 ? styles.icons_full : styles.icons}>
        {successIcons.map((el: SuccessIconProps, index: number) => (
          <div key={el.name} className={styles.icon}>
            {el.icon}
            <div className={styles.success_name}>
              {`${index + 1}. `}
              {windowWidth < 450 && windowWidth > 350 ? <br /> : null}
              {`${el.name}`}
            </div>
            <p>{el.description}</p>
          </div>
        ))}
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_block_left}>
          <h4>Du brauchst ein spezielles Ersatzfahrzeug?</h4>
          <span>
            Du brauchst einen Kombi oder Lieferwagen? <br />
            Kein Problem!
          </span>
          <a href='https://forms.office.com/pages/responsepage.aspx?id=-CgSSqcT7UeOMGvnUqJPYBzc2hmTHYRLgMxEJfw3aXFUMUM1OFg0OE9RQkFQQ1FSMVo4UkwxREFEQy4u'>
            <button>Ersatzfahrzeug anfordern</button>
          </a>
        </div>
        <div className={styles.info_block_right}>
          <img src='assets/images/car-rent-image.png' alt='car-rent' />
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
