import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { PAGES } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { GetNoteResponse, NoteModel } from './get-notes.props';
import { AxiosResponse } from 'axios';
import { notesRequestConfig } from 'request-config/notes/notes.request-config';
import { UseNotesParams } from 'hooks/use-notes/use-notes.types';
import { GetNotesRequestData } from 'request-config/notes/notes.types';

export const useGetFleetNotesQuery = ({ entityName, id }: UseNotesParams) => {
  const QUERY_KEY = 'getFleetNotes';
  const getNotesRequestData: GetNotesRequestData = {
    Notes: {
      attributes: {
        uds_regardingobjectid: {
          id,
          logicalName: entityName,
          name: ''
        }
      }
    }
  };

  const { fetch } = useFetch<ResponseModel<GetNoteResponse[]>>({
    loadingKey: `${QUERY_KEY}${id}`,
    isShowLoading: false,
    ...notesRequestConfig.getNotes(getNotesRequestData)
  });

  const { data, ...restQueryProps } = useQuery<AxiosResponse<ResponseModel<GetNoteResponse[]>>, unknown, NoteModel[]>(
    [PAGES.MY_VEHICLES_DETAILS, QUERY_KEY, id],
    () => fetch(),
    {
      enabled: !!id,
      select: ({ data }) => data.data?.map(noteResponse => noteResponse.attributes) || []
    }
  );

  return { getFleetNotesQuery: data || [], ...restQueryProps };
};
