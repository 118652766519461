import { AxiosRequestConfig } from 'axios';
import { ColumnsSettingNames } from 'common/enums';

class ColumnCustimizationRequestConfig {
  loadPreferences = (ContactId: string, SettingsName: ColumnsSettingNames): AxiosRequestConfig => ({
    method: 'POST',
    url: 'PortalCustomizationService/LoadContactPreferences',
    data: {
      ContactId,
      SettingsName
    }
  });
  savePreferences = (
    ContactId: string,
    SettingsName: ColumnsSettingNames,
    SettingsValue: string
  ): AxiosRequestConfig => ({
    method: 'POST',
    url: 'PortalCustomizationService/SaveContactPreferences',
    data: {
      ContactId,
      SettingsName,
      SettingsValue
    }
  });
}

export const columnCustomizationRequestConfig = new ColumnCustimizationRequestConfig();
