import { crmDataTypes, leasingDuration } from '../../constants';

export const firmennameFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Firmenname',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const adresseFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'address1_line1',
          type: 'text',
          placeholder: 'Straße, Nr.',
          label: 'Straße, Nr.',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '50%',
      fields: [
        {
          name: 'address1_postalcode',
          type: 'number',
          placeholder: 'PLZ',
          label: 'PLZ',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '50%',
      fields: [
        {
          name: 'address1_city',
          type: 'text',
          placeholder: 'Ort',
          label: 'Ort',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'address1_stateorprovince',
          type: 'text',
          placeholder: 'Bundesland/Kanton',
          label: 'Bundesland/Kanton',
          defaultValue: '',
          validation: []
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'address1_country',
          type: 'text',
          placeholder: 'Land',
          label: 'Land',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const brancheFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'industrycode',
          type: 'optionSet',
          placeholder: 'Branche',
          defaultValue: '',
          options: [
            {
              id: '-1',
              label: '---'
            },
            {
              id: '100000001',
              label: 'Gesundheit'
            },
            {
              id: '100000002',
              label: 'Mode'
            },
            {
              id: '33',
              label: 'Facility Management'
            },
            {
              id: '44',
              label: 'Finance & Banking'
            },
            {
              id: '47',
              label: 'Software & IT'
            },
            {
              id: '60',
              label: 'Versicherungen'
            },
            {
              id: '74',
              label: 'Industrie'
            },
            {
              id: '53',
              label: 'Baugewerbe'
            },
            {
              id: '100000000',
              label: 'Chemie'
            },
            {
              id: '72',
              label: 'Handel'
            },
            {
              id: '100000003',
              label: 'Automotive'
            },
            {
              id: '76',
              label: 'Energie'
            },
            {
              id: '75',
              label: 'Handwerk'
            }
          ],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const mitarbeiteranzahlFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'numberofemployees',
          type: 'text',
          placeholder: 'Mitarbeiteranzahl',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[0-9]*$/
            }
          ]
        }
      ]
    }
  ]
};

export const IBANFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'iban',
          type: 'lookup',
          placeholder: 'IBAN',
          allowCreateNew: true,
          defaultValue: '',
          data: [],
          source: 'getIBAN',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const newIBANFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'name',
          type: 'text',
          crmFieldType: crmDataTypes.String,
          placeholder: 'IBAN',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            },
            {
              name: 'regexp',
              value: /^[A-Za-z0-9]*$/
            }
          ]
        }
      ]
    }
  ]
};

export const umsatzsteuerFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_vatregistrationnumber',
          type: 'text',
          placeholder: 'Umsatzsteuer-ID',
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const wahrungFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'transactioncurrencyid',
          type: 'lookup',
          placeholder: 'Währung',
          defaultValue: '',
          data: [],
          source: 'getAllCurrencies',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const zahlungsbedingungenFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'paymenttermscode',
          type: 'optionSet',
          placeholder: 'Zahlungsbedingungen',
          defaultValue: '',
          options: [
            {
              id: '100000001',
              label: '45 Tage netto'
            },
            {
              id: '2',
              label: '30 Tage netto'
            },
            {
              id: '100000002',
              label: '8 Tage netto, 3% Skonto'
            },
            {
              id: '5',
              label: '7 Tage netto'
            },
            {
              id: '7',
              label: '14 Tage netto'
            },
            {
              id: '8',
              label: '21 Tage netto'
            },
            {
              id: '100000000',
              label: 'Lastschrift-Einzug'
            },
            {
              id: '100000003',
              label: 'Zahlbar nach Erhalt'
            }
          ],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const telefonFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'telephone1',
          type: 'text',
          placeholder: 'Telefon',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[+]?[0-9]*[\(]?[0-9]*[\)]?[0-9]*$/
            }
          ]
        }
      ]
    }
  ]
};

export const mobilFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'telephone2',
          type: 'text',
          placeholder: 'Mobil',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[+]?[0-9]*[\(]?[0-9]*[\)]?[0-9]*$/
            }
          ]
        }
      ]
    }
  ]
};

export const faxFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'fax',
          type: 'text',
          placeholder: 'Fax',
          defaultValue: '',
          validation: [
            {
              name: 'regexp',
              value: /^[+]?[0-9]*[\(]?[0-9]*[\)]?[0-9]*$/
            }
          ]
        }
      ]
    }
  ]
};

export const emailFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'emailaddress1',
          type: 'text',
          placeholder: 'E-Mail',
          defaultValue: '',
          validation: [
            {
              name: 'email'
            }
          ]
        }
      ]
    }
  ]
};

export const websiteFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'websiteurl',
          type: 'text',
          placeholder: 'Website',
          defaultValue: '',
          validation: []
        }
      ]
    }
  ]
};

export const fuhrparkmanagerFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'primarycontactid',
          type: 'lookup',
          placeholder: 'Fuhrparkmanager',
          defaultValue: '',
          data: [],
          source: 'getCompanyContacts',
          validation: []
        }
      ]
    }
  ]
};

export const kaskoFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_rechnungkaskocontactid',
          type: 'lookup',
          placeholder: 'Rechnung Kasko',
          defaultValue: '',
          data: [],
          source: 'getCompanyContacts',
          validation: []
        }
      ]
    }
  ]
};

export const buchhaltungFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_kontaktbuchhaltungid',
          type: 'lookup',
          placeholder: 'Buchhaltung',
          defaultValue: '',
          data: [],
          source: 'getCompanyContacts',
          validation: []
        }
      ]
    }
  ]
};

export const sachbezugsmeldungFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_sachbezugbearbeiterid',
          type: 'lookup',
          placeholder: 'Sachbezugsmeldung Personalabteilung',
          defaultValue: '',
          data: [],
          source: 'getCompanyContacts',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const schadenabwicklungFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_kundeschadenmeldungcontactid',
          type: 'lookup',
          placeholder: 'Schadenabwicklung',
          defaultValue: '',
          data: [],
          source: 'getCompanyContacts',
          validation: []
        }
      ]
    }
  ]
};

export const kontaktierenFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'donotemail',
          type: 'toggle',
          label: 'E-Mail',
          defaultValue: false,
          validation: []
        },
        {
          name: 'donotbulkemail',
          type: 'toggle',
          label: 'Newsletter',
          defaultValue: false,
          validation: []
        },
        {
          name: 'donotphone',
          type: 'toggle',
          label: 'Telefon',
          defaultValue: false,
          validation: []
        },
        {
          name: 'donotpostalmail',
          type: 'toggle',
          label: 'Post',
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const kontaktmethodeFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'preferredcontactmethodcode',
          type: 'optionSet',
          placeholder: 'Bevorzugte Kontaktmethode',
          defaultValue: '',
          options: [
            {
              id: '1',
              label: 'Beliebig'
            },
            {
              id: '2',
              label: 'E-Mail'
            },
            {
              id: '3',
              label: 'Telefon'
            },
            {
              id: '5',
              label: 'Post'
            }
          ],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const untersuchungenFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_iserinnerungen_fuhrparkleiter',
          type: 'toggle',
          label: 'Fuhrparkleiter',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_iserinnerungen_kst_verantw',
          type: 'toggle',
          label: 'Kostenstellenverantwortliche',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_iserinnerungen_fahrer',
          type: 'toggle',
          label: 'Fahrer',
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const poolcarsFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_ispoolcar_erinnerungen_fuhrparkleiter',
          type: 'toggle',
          label: 'Fuhrparkleiter',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_ispoolcar_erinnerungen_kst_verantw',
          type: 'toggle',
          label: 'Kostenstellenverantwortliche',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_ispoolcar_erinnerungen_fahrer',
          type: 'toggle',
          label: 'Fahrer',
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const tasksFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_iserinnerungen_weitereaufgaben_fuhrparkle',
          type: 'toggle',
          label: 'Fuhrparkleiter',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_iserinnerungen_weitereaufgaben_kst_verant',
          type: 'toggle',
          label: 'Kostenstellenverantwortliche',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_iserinnerungen_weitereaufgaben_fahrer',
          type: 'toggle',
          label: 'Fahrer',
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const tasksPoolcarsFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_ispoolcar_erinnerungen_weitereaufgab_fuhr',
          type: 'toggle',
          label: 'Fuhrparkleiter',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_ispoolcar_erinnerungen_weitereaufgab_kstw',
          type: 'toggle',
          label: 'Kostenstellenverantwortliche',
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_ispoolcar_erinnerungen_weitereaufgab_fahr',
          type: 'toggle',
          label: 'Fahrer',
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const driverLicenseChangeEmailCodeFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_driverlicense_change_emailcode',
          label: 'Änderung der Führerscheinnummer',
          crmFieldType: crmDataTypes.Picklist,
          tooltip: 'dsfdsfds',
          defaultValue: false,
          labelActiveValue: 'aktiv',
          labelInActiveValue: 'inaktiv',
          type: 'toggle',
          validation: []
        }
      ]
    }
  ]
};

export const driverLicenseFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_isorg_in_driverscheck',
          crmFieldType: crmDataTypes.Boolean,
          defaultValue: false,
          type: 'toggle',
          infoHint: {
            type: 'info',
            infoMessage:
              'Einstellung gilt für alle Fahrer des Unternehmens (ggf. für alle Mutter-und Tochtergesellschaften). Für die Führerscheinkontrolle fallen Kosten pro Fahrer an.'
          },
          validation: []
        }
      ]
    }
  ]
};

export const driverManualFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_isfahrerunterweisung_aktiv',
          crmFieldType: crmDataTypes.Boolean,
          defaultValue: false,
          type: 'toggle',
          validation: []
        }
      ]
    }
  ]
};

export const leasingDurationFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_laufzeitcode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          placeholder: 'Bitte auswählen',
          defaultValue: '',
          options: leasingDuration,
          validation: []
        }
      ]
    }
  ]
};

export const leasingDurationOptionsFormFields = {
  columns: [
    {
      width: '25%',
      fields: [
        {
          name: 'uds_lease_length_month_12',
          type: 'toggleWithTooltip',
          label: '12 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_lease_length_month_36',
          type: 'toggleWithTooltip',
          label: '36 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_lease_length_month_60',
          type: 'toggleWithTooltip',
          label: '60 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        }
      ]
    },
    {
      width: '25%',
      fields: [
        {
          name: 'uds_lease_length_month_18',
          type: 'toggleWithTooltip',
          label: '18 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_lease_length_month_42',
          type: 'toggleWithTooltip',
          label: '42 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        }
      ]
    },
    {
      width: '25%',
      fields: [
        {
          name: 'uds_lease_length_month_24',
          type: 'toggleWithTooltip',
          label: '24 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_lease_length_month_48',
          type: 'toggleWithTooltip',
          label: '48 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        }
      ]
    },
    {
      width: '25%',
      fields: [
        {
          name: 'uds_lease_length_month_30',
          type: 'toggleWithTooltip',
          label: '30 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        },
        {
          name: 'uds_lease_length_month_54',
          type: 'toggleWithTooltip',
          label: '54 Monate',
          valueChangedEvent: true,
          defaultValue: false,
          validation: []
        }
      ]
    }
  ]
};

export const referenceLeasingDurationOptionsFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_lease_reference_length_monthcode',
          label: 'Davon die Referenzlaufzeit (Monate)',
          type: 'radio',
          optionsInRow: true,
          defaultValue: '',
          options: [],
          validation: []
        }
      ]
    }
  ]
};
