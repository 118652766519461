import { useEffect, useState } from 'react';
import { FilterExpandContainerProps } from '../filter-expand-container.props';

export const useToggleFilterExpand = ({ isFilterExpended: isFilterExpandedProps }: FilterExpandContainerProps) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const toggleFilterExpand = () => {
    if (typeof isFilterExpandedProps === 'boolean') {
      setIsFilterExpanded(isFilterExpandedProps);
    }
  };

  useEffect(toggleFilterExpand, [isFilterExpandedProps]);

  return { isFilterExpanded, setIsFilterExpanded };
};
