import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarContractIcon = ({
  width = 22,
  height = 25,
  color: colorProp,
  fill: fillProp,
  strokeWidth = 1
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.34 8.107v-1.882L12.401 0.579H1.111c-0.388 0 -0.705 0.339 -0.705 0.753V23.16c0 0.414 0.317 0.753 0.706 0.753h9.173'
        fill={fill}
      />
      <path
        d='M17.34 8.107v-1.882L12.401 0.579H1.111c-0.388 0 -0.705 0.339 -0.705 0.753V23.16c0 0.414 0.317 0.753 0.706 0.753H17.34z'
        fill={fill}
      />
      <path d='M12.448 5.868V0.58l4.761 5.145z' fill='white' />
      <path
        d='M17.341 11.776V5.472L12.448 0.579H1.158a0.755 0.755 0 0 0 -0.753 0.753V23.16a0.755 0.755 0 0 0 0.753 0.753h4.528'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.34 5.472H12.824c-0.207 0 -0.376 -0.169 -0.376 -0.376v-1.505'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.545 8.107h4.893'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.545 10.741h8.656'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.545 13.376h3.129'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.545 15.791h0.866'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.545 18.426h1.29'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.319 23.048h-0.977v1.221c0 0.147 0.097 0.244 0.244 0.244h2.442c0.147 0 0.244 -0.098 0.244 -0.244v-1.514l0.195 -0.928c0.024 -0.171 0.049 -0.318 0.049 -0.488v-2.394c0 -0.293 -0.049 -0.586 -0.171 -0.879l-0.244 -0.635h1.075c0.146 0 0.268 -0.147 0.244 -0.293l-0.195 -0.781a0.47 0.47 0 0 0 -0.464 -0.366h-1.123l-0.928 -1.881c-0.244 -0.488 -0.757 -0.806 -1.319 -0.806H10.48c-0.562 0 -1.074 0.318 -1.319 0.806l-0.928 1.881h-1.148c-0.22 0 -0.415 0.147 -0.464 0.367l-0.196 0.781c-0.024 0.147 0.073 0.293 0.244 0.293h1.074l-0.244 0.635c-0.098 0.269 -0.171 0.562 -0.171 0.879v2.393c0 0.171 0.024 0.342 0.049 0.489l0.195 0.928v1.514c0 0.147 0.098 0.244 0.244 0.244h2.442c0.147 0 0.244 -0.098 0.244 -0.244v-1.221h-0.977'
        fill='white'
      />
      <path
        d='M17.342 24.269v-1.221H10.505v1.221c0 0.147 -0.098 0.244 -0.244 0.244h-2.442c-0.147 0 -0.244 -0.098 -0.244 -0.244v-1.514l-0.195 -0.928a3 3 0 0 1 -0.049 -0.489v-2.393c0 -0.318 0.073 -0.611 0.171 -0.879l0.244 -0.635h-1.074c-0.171 0 -0.269 -0.147 -0.244 -0.293l0.196 -0.781c0.049 -0.22 0.244 -0.366 0.464 -0.366H8.233l1.314 0.751h8.75l1.339 -0.751h1.123c0.22 0 0.415 0.147 0.464 0.367l0.195 0.781c0.024 0.147 -0.098 0.293 -0.244 0.293h-1.074l0.244 0.635c0.122 0.293 0.171 0.586 0.171 0.879v2.393c0 0.171 -0.025 0.318 -0.049 0.489l-0.195 0.928v1.514c0 0.147 -0.098 0.244 -0.244 0.244H17.587c-0.147 0 -0.244 -0.098 -0.244 -0.244'
        fill='#E6FFE6'
      />
      <path
        d='M18.319 23.048h-0.977v1.221c0 0.147 0.098 0.244 0.244 0.244h2.442c0.147 0 0.244 -0.098 0.244 -0.244v-1.514l0.195 -0.928c0.024 -0.171 0.049 -0.318 0.049 -0.488v-2.394c0 -0.293 -0.049 -0.586 -0.171 -0.879l-0.244 -0.635h1.075c0.146 0 0.268 -0.147 0.244 -0.293l-0.195 -0.781a0.47 0.47 0 0 0 -0.464 -0.366h-1.123l-0.928 -1.881c-0.244 -0.488 -0.757 -0.806 -1.319 -0.806H10.48c-0.562 0 -1.074 0.318 -1.319 0.806l-0.928 1.881h-1.148c-0.22 0 -0.415 0.147 -0.464 0.367l-0.196 0.781c-0.024 0.147 0.073 0.293 0.244 0.293h1.074l-0.244 0.635c-0.098 0.269 -0.171 0.562 -0.171 0.879v2.393c0 0.171 0.024 0.342 0.049 0.489l0.195 0.928v1.514c0 0.147 0.098 0.244 0.244 0.244h2.442c0.147 0 0.244 -0.098 0.244 -0.244v-1.221h-0.977'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.261 23.048h7.57'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.795 19.628h2.027l-0.342 -0.684'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.051 19.628h-2.027l0.342 -0.684'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.97 21.338h3.907'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.772 16.697h8.302'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
