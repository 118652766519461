import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getUserBasket(userId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/getUserBasket`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      userId
    }
  });
}

export async function getUsersCompanyBasket(userId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/getUserCompanyBasket`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      userId
    }
  });
}

export async function getRecentVehicles(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/user/recentvehicles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id
    },
    transformResponse(data) {
      return data ? JSON.parse(data) : null;
    }
  });
}

export async function getVertragsdocumente() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/GetVertragsdocumente`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {},
    transformResponse(data) {
      return data ? JSON.parse(data) : null;
    }
  });
}

export async function sendNotification(ConfigId, ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/sendnotification`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ConfigId
    }
  });
}

export async function uploadCompanyDocuments(files, Description, ContactId) {
  const data = new FormData();
  files.forEach(file => {
    data.append('Files', file);
  });
  data.append('Description', Description);

  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/uploaddocuments`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function uploadUserAvatar(files, ContactID) {
  const data = new FormData();
  files.forEach(file => {
    data.append('Avatar', file);
  });
  data.append('ContactID', ContactID);

  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/uploadavatar`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function deactivateUser(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'DELETE',
    url: `${API_ENDPOINT}/api/user`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getUser(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/user`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id
    }
  });
}

export async function updateUserFahrzeug(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/api/user/assigncar`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function updateUserPassword(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/api/user/changepassword`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getUserAvatar(Id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/user/getavatar`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      Id
    }
  });
}

export async function inviteDriverAgain(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/invite`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId
    }
  });
}

export async function removeAvatar(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/removeavatar`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId
    }
  });
}

export const getDocumentTemplates = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/documenttemplates`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId: params
    }
  });
};

export const uploadAnwaltsvollmachtDocuments = async files => {
  const data = new FormData();
  files.forEach(file => {
    data.append('Files', file);
  });

  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/UploadAnwaltsvollmachtDocuments`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const setUserLastSingIn = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/SetUserLastSingIn`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const createInviteUser = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/CreateInviteUser`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getContactsByCriteria = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/user/getContactsByCriteria`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getLocations = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/standort/GetLookupCompanyList`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getMobilityTypes = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/crmentity/GetOptionSetValues`,
    params: {
      entityName: 'contact',
      field: 'uds_mobilitatsart'
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getDriverLicenceClasses = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/crmentity/GetOptionSetValues`,
    params: {
      entityName: 'contact',
      field: 'uds_fuhrerscheinklassen'
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const archiveUserDocument = async id => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/user/deleteUserDocument`;
    await axios({
      method: 'DELETE',
      url,
      params: {
        fileid: id
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkEmail = async email => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/user/checkExisting`;

    return axios({
      method: 'GET',
      url,
      params: {
        email
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCarsHistory = async id => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/user/GetUserFuhrparkHistory`;

    return axios({
      method: 'GET',
      url,
      params: {
        id
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkIfCustomerExist = async companyId => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/crmentity/getentitybyid`;
    return axios({
      method: 'POST',
      url,
      data: {
        entityid: companyId,
        entityname: 'account',
        Columns: ['new_sap_kundennummer']
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};
