import { FormFieldModel } from 'common/interfaces';
import { CreateNoteFormData } from '../create-note.props';
import { FormFieldTypes } from 'common/enums';
import { errorMessages } from 'utils/error-messages';
import { NoteModel } from 'hooks/react-query/fleet/get-notes/get-notes.props';
import { RegisterOptions } from 'react-hook-form';

const commonValidation: RegisterOptions = {
  required: errorMessages.required,
  maxLength: { value: 1000, message: errorMessages.getMaxTextLength(1000) }
};

export const useCreateNoteFormFields = (data: NoteModel | null) => {
  const formFields: FormFieldModel<keyof CreateNoteFormData>[] = [
    {
      name: 'uds_titel',
      placeholder: 'Titel',
      type: FormFieldTypes.Text,
      defaultValue: data?.uds_titel || '',
      validation: commonValidation
    },
    {
      name: 'uds_notiz',
      placeholder: 'Beschreibung',
      type: FormFieldTypes.TextArea,
      defaultValue: data?.uds_notiz || '',
      validation: commonValidation
    }
  ];

  return { formFields };
};
