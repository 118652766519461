import Axios, { AxiosResponse } from 'axios';
import { AnyObject } from 'common/interfaces';
import { API_ENDPOINT } from '../constants';
import authService from './auth-service';

class Dashboard {
  currentYear = new Date().getFullYear();

  getDrivers = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetFahrer`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getCars = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetFahrzeuge`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getDataQuality = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetDatenqualitat`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getDamage = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetSchaden`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getTasks = async (AccountId: string | null, dashboardWidgetType: number): Promise<AxiosResponse<AnyObject>> => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetTopAufgaben`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId,
        dashboardWidgetType
      }
    });
  };
  getVehicleCosts = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetFahrzeugkosten`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getTopCosts = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetTopKosten`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId,
        StartDate: new Date(`01.01.${this.currentYear}`),
        EndDate: new Date(`12.31.${this.currentYear}`),
        PrevStartDate: new Date(`01.01.${this.currentYear - 1}`),
        PrevEndDate: new Date(`12.31.${this.currentYear - 1}`)
      }
    });
  };
  getConsumption = async (AccountId: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/GetVerbrauch`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  refreshDashboardCache = async (AccountId?: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/Dashboard/RefreshDashboardCaches`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
  getMeinFahrzeug = async () => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'post',
      url: `${API_ENDPOINT}/api/dashboard/getMeinFahrzeug`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {}
    });
  };
  getMeineBestellung = async () => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/dashboard/getMeineBestellung`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };
  uploadCarLogo = async (data: AnyObject) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/dashboard/uploadCarLogo`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: data
    });
  };
  getMileage = async (AccountId?: string | null) => {
    const token = await authService.acquireTokenAsync();
    return Axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/dashboard/getLaufleistung`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        AccountId
      }
    });
  };
}

const dashboardService = new Dashboard();

export default dashboardService;
