import React from 'react';
import classes from './widget-header.module.scss';

interface Props {
  icon: JSX.Element;
  title: string;
}

const WidgetHeader = ({ icon, title }: Props) => {
  return (
    <div className={classes.widget_header}>
      {icon}
      <h5>{title}</h5>
    </div>
  );
};

export default WidgetHeader;
