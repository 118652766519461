import { GasStationIcon } from '../components/icons-new/gas-station';
import { TimingBeltIcon } from '../components/icons-new/timing-belt';
import { EngineIcon } from '../components/icons-new/engine';
import { Car } from '../components/icons-new/car';
import { TransmissionIcon } from '../components/icons-new/transmission';
import { CarBrushIcon } from '../components/icons-new/car-brush';
import { EuroSignWithBorderIcon } from '../components/icons-new/euro-sign-with-border';
import { SeatIcon } from '../components/icons-new/seat';

export const vehicleDetailsFields = [
  [
    {
      name: 'Kraftstoff',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_enginetypecode',
      icon: <GasStationIcon />,
      tooltipText: 'Kraftstoff'
    },
    {
      name: 'Leistung',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_leistungkw',
      icon: <TimingBeltIcon />,
      tooltipText: 'Leistung'
    }
  ],
  [
    {
      name: 'Hubraum',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_hubraum',
      icon: <EngineIcon />,
      tooltipText: 'Hubraum'
    },
    {
      name: 'Fahrzeugaufbau',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_cartypecode',
      icon: <Car />,
      tooltipText: 'Fahrzeugaufbau'
    }
  ],
  [
    {
      name: 'Getriebe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_getriebe',
      icon: <TransmissionIcon />,
      tooltipText: 'Getriebe'
    },
    {
      name: 'Farbe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_color',
      icon: <CarBrushIcon />,
      tooltipText: 'Farbe'
    }
  ],
  [
    {
      name: 'Listenpreis (brutto)',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_listenpreis',
      icon: <EuroSignWithBorderIcon width={20} />,
      tooltipText: 'Listenpreis (brutto)'
    },
    {
      name: 'Innenfarbe',
      value: '',
      expand: false,
      disabled: true,
      fieldName: 'uds_interiorcolor',
      icon: <SeatIcon />,
      tooltipText: 'Innenfarbe'
    }
  ]
];
