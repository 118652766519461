/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import CarDamageIcon from '../icons/car-damage';
import DamagePartChoose from '../damage-part-component/damage-part-choose';
import { damage } from '../utils';
import PlusIcon from '../icons/plus';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide.module.css';
import slideReverse from '../transition/damage-backward-slide.module.css';
import slideReturn from '../transition/return-slide.module.css';
import sideSlide from '../transition/side-slide.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import useWindowSize from 'hooks/use-window-size';
import TrashIcon from '../icons/trash-icon';
import BigDamageIcon from '../icons/big-damage';
import BigDamageChoosenIcon from '../icons/big-damage-choosen';
import SmallDamageIcon from '../icons/small-damage';
import SmallDamageChoosenIcon from '../icons/small-damage-choosen';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import CarIconMobile from '../car-icon-mobile-component/car-icon-mobile';
import ArrowLeftBigIcon from '../icons/arrow-left-big';
import { StepProps, StepStylesProps, DamagePartsItemProps, OtherDamagePartsItemProps } from '../report-damage.props';

const ChooseAnimalDamage = ({ reactHookFormData, handleNext }: StepProps) => {
  const { windowWidth } = useWindowSize();
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showChooseDamage, setShowChooseDamage] = useState<boolean>(false);
  const [showChooseDamageSlider, setShowChooseDamageSlider] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockAutoIsOk, setShowBlockAutoIsOk] = useState<boolean>(false);
  const [showBlockNeedPolice, setShowBlockNeedPolice] = useState<boolean>(false);
  const [showBlockNeedPoliceGetCaseNumber, setShowBlockNeedPoliceGetCaseNumber] = useState<boolean>(false);
  const [showBlockNeedPoliceCriminalWarning, setShowBlockNeedPoliceCriminalWarning] = useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDriverWasTested, setShowBlockNeedPoliceDrunkDriverWasTested] =
    useState<boolean>(false);
  const [showBlockNeedPoliceDrunkDrugsTest, setShowBlockNeedPoliceDrunkDrugTest] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
    setValue('damageTypePrev', watch('damageType'));
  }, []);

  useEffect(() => {
    autoIsNotOkay &&
    watch('needPolice') !== undefined &&
    needPolice &&
    needPoliceCaseNumber &&
    needPoliceCriminalWarning &&
    needPoliceDrunkDriver &&
    watch('damageSize') !== undefined &&
    watch('damagePrivatOrBusinessTrip') !== undefined &&
    watch('hasDamage') !== undefined &&
    (watch('hasDamage') === false ||
      (Array.isArray(watch('damageParts')) &&
        watch('damageParts').length > 0 &&
        watch('damageParts').filter((el: OtherDamagePartsItemProps) => el[1].type === true).length < 1)) &&
    Object.keys(errors).length < 1
      ? setButtonActive(true)
      : setButtonActive(false);
    watch('hasDamage') === true ? setShowChooseDamage(true) : setShowChooseDamage(false);
    setTimeout(() =>
      watch('hasDamage') === true ? setShowChooseDamageSlider(true) : setShowChooseDamageSlider(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    setTimeout(() => (watch('autoIsOkay') === false ? setShowBlockAutoIsOk(true) : setShowBlockAutoIsOk(false)), 100);
  }, [watch('autoIsOkay')]);

  useEffect(() => {
    setTimeout(() => (watch('needPolice') ? setShowBlockNeedPolice(true) : setShowBlockNeedPolice(false)), 100);
  }, [watch('needPolice')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceGetCaseNumber')
          ? setShowBlockNeedPoliceGetCaseNumber(true)
          : setShowBlockNeedPoliceGetCaseNumber(false),
      100
    );
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceCriminalWarning')
          ? setShowBlockNeedPoliceCriminalWarning(true)
          : setShowBlockNeedPoliceCriminalWarning(false),
      100
    );
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceDrunkDriverWasTested')
          ? setShowBlockNeedPoliceDrunkDriverWasTested(true)
          : setShowBlockNeedPoliceDrunkDriverWasTested(false),
      100
    );
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    setTimeout(
      () =>
        watch('needPoliceDrunkDrugsTest')
          ? setShowBlockNeedPoliceDrunkDrugTest(true)
          : setShowBlockNeedPoliceDrunkDrugTest(false),
      100
    );
  }, [watch('needPoliceDrunkDrugsTest')]);

  const autoIsNotOkay =
    (watch('autoIsOkay') === false || !!watch('autoIsOkay')) &&
    !!watch('autoIsNotOkayFirma') &&
    !!watch('autoIsNotOkayAdress') &&
    !!watch('autoIsNotOkayTelephone');

  const needPolice =
    (!!watch('needPolice') &&
      !!watch('needPoliceDienststelle') &&
      watch('needPoliceDienststelle').length > 0 &&
      watch('needPoliceGetCaseNumber') !== undefined &&
      watch('needPoliceCriminalWarning') !== undefined &&
      watch('needPoliceDrunkDriverWasTested') !== undefined &&
      watch('needPoliceDrunkDrugsTest') !== undefined) ||
    watch('needPolice') === false;

  const needPoliceCaseNumber =
    (!!watch('needPolice') &&
      (watch('needPoliceGetCaseNumber') === false ||
        (!!watch('needPoliceGetCaseNumber') &&
          !!watch('needPoliceGetCaseNumberAktenzeichen') &&
          watch('needPoliceGetCaseNumberAktenzeichen').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceCriminalWarning =
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') &&
          !!watch('needPoliceCriminalWarningWho') &&
          watch('needPoliceCriminalWarningWho').length > 0))) ||
    watch('needPolice') === false;

  const needPoliceDrunkDriverWasTested =
    watch('needPoliceDrunkDriverWasTested') === false ||
    (!!watch('needPoliceDrunkDriverWasTested') &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonName') &&
      watch('needPoliceCriminalWarningWhoFirstPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoFirstPersonDrunkLevel').length > 0);

  const needPoliceDrunkDriverWasTestedSecondPerson =
    !watch('showSecondDrunkPerson') ||
    (!!watch('showSecondDrunkPerson') &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonName') &&
      watch('needPoliceCriminalWarningWhoSecondPersonName').length > 0 &&
      !!watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel') &&
      watch('needPoliceCriminalWarningWhoSecondPersonDrunkLevel').length > 0);

  const needPoliceDrunkDrugsTest =
    watch('needPoliceDrunkDrugsTest') === false ||
    (!!watch('needPoliceDrunkDrugsTest') &&
      !!watch('needPoliceDrunkDrugsTestWhoWasTestedFirst') &&
      watch('needPoliceDrunkDrugsTestWhoWasTestedFirst').length > 0 &&
      watch('needPoliceDrunkDrugsTestResultFirst') !== undefined &&
      (!watch('showSecondDrugPerson') ||
        (!!watch('showSecondDrugPerson') &&
          !!watch('needPoliceDrunkDrugsTestWhoWasTestedSecond') &&
          watch('needPoliceDrunkDrugsTestWhoWasTestedSecond').length > 0 &&
          watch('needPoliceDrunkDrugsTestResultSecond') !== undefined)));

  const needPoliceDrunkDriver =
    watch('needPolice') === false ||
    (!!watch('needPolice') &&
      (watch('needPoliceCriminalWarning') === false ||
        (!!watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      watch('needPoliceDrunkDriverWasTested') === false &&
      watch('needPoliceDrunkDrugsTest') === false) ||
    ((watch('needPoliceCriminalWarning') === false ||
      (watch('needPoliceCriminalWarning') && !!watch('needPoliceCriminalWarningWho'))) &&
      needPoliceDrunkDriverWasTested &&
      needPoliceDrunkDriverWasTestedSecondPerson &&
      needPoliceDrunkDrugsTest);

  const returnToType = () => {
    setValue('direction', 'back');
    setValue('previousStep', 3);
    setShowBlock(false);
    setTimeout(() => {
      setValue('step', 2);
      setValue('damageType', undefined);
    }, 500);
  };

  useEffect(() => {
    if (watch('hasDamage') === false) {
      setValue('carDamage', undefined);
      setValue('damageParts', undefined);
      setValue('damageList', damage);
    }
  }, [watch('hasDamage')]);

  const songstigeValue = () => {
    const songstigeItem = { type: true, value: 100000041 };
    const songstige =
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      !!watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        ? watch('damageParts').filter((el: DamagePartsItemProps) => el[0] === 'Sonstiges')
        : [];
    if (
      (songstige.length === 0 && songstige.length <= 8) ||
      (songstige.length <= 8 && songstige.some((el: OtherDamagePartsItemProps) => el[1].type !== true))
    ) {
      const newSongstigeITem = ['Sonstiges', songstigeItem];
      const damagePartsArray = Array.isArray(watch('damageParts')) ? watch('damageParts') : [];
      damagePartsArray.push(newSongstigeITem);
      setValue('damageParts', damagePartsArray);
    }
  };

  const setDamageSize = (size: string) => {
    setValue('damageSize', size);
  };

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const checkDamageParts = () => {
    if (
      !!watch('hasDamage') &&
      !!watch('damageParts') &&
      Array.isArray(watch('damageParts')) &&
      watch('damageParts').length > 0
    ) {
      watch('damageParts').forEach((item: OtherDamagePartsItemProps) => {
        if (item[1].type === true) {
          setError(`damageParts.${item[0]}`, {
            type: 'manual',
            message: 'Error'
          });
        }
      });
    }
  };

  useEffect(() => {
    !watch('hasDamage') && clearErrors('damageParts');
  }, [watch('hasDamage')]);

  let slideClassName: StepStylesProps = watch('previousStep') === 2 ? slide : slideReverse;

  const moveToNextStep = () => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 3), 500);
      slideClassName = slide;
    } else {
      handleSubmit(
        () => {},
        () => {}
      );
      !!watch('hasDamage') && checkDamageParts();
      checkButtons(['hasDamage', 'damagePrivatOrBusinessTrip', 'autoIsOkay', 'damageSize', 'needPolice']);
      !!watch('needPolice') &&
        checkButtons([
          'needPoliceGetCaseNumber',
          'needPoliceCriminalWarning',
          'needPoliceDrunkDriverWasTested',
          'needPoliceDrunkDrugsTest'
        ]);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        checkButtons(['needPoliceDrunkDrugsTestResultFirst']);
      !!watch('needPolice') &&
        !!watch('needPoliceDrunkDrugsTest') &&
        !!watch('showSecondDrugPerson') &&
        checkButtons(['needPoliceDrunkDrugsTestResultSecond']);
    }
  };

  useEffect(() => {
    if (watch('damageSize') !== undefined) {
      clearErrors('damageSize');
    }
  }, [watch('damageSize')]);

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') === false) {
      clearErrors('needPoliceGetCaseNumberAktenzeichen');
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') === false) {
      clearErrors('needPoliceCriminalWarningWho');
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') === false) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') === false) {
      clearErrors('needPoliceDrunkDrugsTestWhoWasTestedFirst');
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  useEffect(() => {
    if (watch('needPolice') === false) {
      clearErrors([
        'needPoliceDienststelle',
        'needPoliceCriminalWarningWho',
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDienststelle',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond',
        'needPoliceGetCaseNumberAktenzeichen',
        'needPoliceAdressAndOffice',
        'needPoliceCriminalWarning',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTest',
        'needPoliceGetCaseNumber'
      ]);
    }
  }, [watch('needPolice')]);

  useEffect(() => {
    if (watch('needPoliceGetCaseNumber') !== undefined) {
      clearErrors(['needPoliceGetCaseNumberAktenzeichen', 'needPoliceGetCaseNumber']);
    }
  }, [watch('needPoliceGetCaseNumber')]);

  useEffect(() => {
    if (watch('needPoliceCriminalWarning') !== undefined) {
      clearErrors(['needPoliceCriminalWarningWho', 'needPoliceCriminalWarning']);
    }
  }, [watch('needPoliceCriminalWarning')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDriverWasTested') !== undefined) {
      clearErrors([
        'needPoliceCriminalWarningWhoFirstPersonDrunkLevel',
        'needPoliceCriminalWarningWhoFirstPersonName',
        'needPoliceCriminalWarningWhoSecondPersonDrunkLevel',
        'needPoliceCriminalWarningWhoSecondPersonName',
        'needPoliceDrunkDriverWasTested',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDriverWasTested')]);

  useEffect(() => {
    if (watch('needPoliceDrunkDrugsTest') !== undefined) {
      clearErrors([
        'needPoliceDrunkDrugsTest',
        'needPoliceDrunkDrugsTestWhoWasTestedFirst',
        'needPoliceDrunkDrugsTestResultFirst',
        'needPoliceDrunkDrugsTestWhoWasTestedSecond',
        'needPoliceDrunkDrugsTestResultSecond'
      ]);
    }
  }, [watch('needPoliceDrunkDrugsTest')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 3 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <div style={showChooseDamage ? { display: 'flex' } : {}}>
          <div className={styles.buttons_damage_block}>
            <TwoButtonsComponent
              reactHookFormData={reactHookFormData}
              fieldName='Sind an deinem Fahrzeug Schäden entstanden?'
              fieldRegister='hasDamage'
              fieldRegisterSecond='hasDamage'
            />

            {showChooseDamage && windowWidth < 1400 && watch('damageList') !== undefined && (
              <div style={{ overflow: 'hidden' }}>
                <CSSTransition
                  in={showChooseDamageSlider}
                  timeout={400}
                  classNames={slideQuest}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={styles.songstige_block_small}>
                    {windowWidth < 900 ? (
                      <CarIconMobile reactHookFormData={reactHookFormData} />
                    ) : (
                      <CarDamageIcon reactHookFormData={reactHookFormData} />
                    )}
                    <div
                      className={
                        windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full'
                          ? styles.songstige_block_mobile
                          : styles.songstige_block
                      }
                    >
                      {windowWidth < 900 && !!watch('carImageMobile') && watch('carImageMobile') !== 'full' && (
                        <div
                          id='returnCarImageImageButton'
                          className={styles.back_item}
                          onClick={() => setValue('carImageMobile', 'full')}
                        >
                          <ArrowLeftBigIcon />
                          <div className={styles.back_text}>Zurück</div>
                        </div>
                      )}
                      <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                        <PlusIcon />
                        <Distance side='10' />
                        Sonstige Teile
                      </div>
                    </div>
                  </div>
                </CSSTransition>
              </div>
            )}
            {showChooseDamage && <DamagePartChoose reactHookFormData={reactHookFormData} />}
          </div>
          {showChooseDamage && windowWidth >= 1400 && watch('damageList') !== undefined && (
            <div style={{ overflow: 'hidden' }}>
              <CSSTransition
                in={showChooseDamageSlider}
                timeout={600}
                classNames={sideSlide}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <CarDamageIcon reactHookFormData={reactHookFormData} />
                  <div className={styles.songstige_block}>
                    <div id='songstigeButton' className={styles.songstige_block_item} onClick={songstigeValue}>
                      <PlusIcon />
                      <Distance side='10' />
                      Sonstige Teile
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>
        {watch('hasDamage') ? <Distance top='10' /> : <Distance top='20' />}

        <div className={styles.form_name}>
          <span className={errors?.damageSize ? styles.form_name_err : ''}>
            <p>
              Ist der Schaden größer als 10x15 Zentimeter? <br />
              Das entspricht ca. der Größe einer Postkarte
            </p>
            <div
              className={styles.tooltip}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <TooltipHint
                position={windowWidth > 900 ? 'top' : 'center'}
                text='Aufgrund deiner Auswahl wird die Reparatur an einen Spezial-Betrieb für Kleinst-Schäden ("SMART-Repair") vermittelt - bist Du sicher, dass der Schaden kleiner als 10*15cm ist?'
              >
                <InfoHintIcon color='#919699' width={16} height={16} hovered={isHovered} />
              </TooltipHint>
            </div>
          </span>
        </div>
        <Distance top='5' />
        <div className={styles.row_columns}>
          <div className={styles.half_input}>
            <div
              id='yesButton'
              className={watch('damageSize') === 'big' ? styles.damage_part_choosen : styles.damage_part}
              onClick={() => setDamageSize('big')}
            >
              {watch('damageSize') === 'big' ? <BigDamageChoosenIcon /> : <BigDamageIcon />}
              <p>Ja, größer</p>
            </div>
          </div>
          <div className={styles.half_input}>
            <div
              id='notButton'
              className={watch('damageSize') === 'small' ? styles.damage_part_choosen : styles.damage_part}
              onClick={() => setDamageSize('small')}
            >
              {watch('damageSize') === 'small' ? <SmallDamageChoosenIcon /> : <SmallDamageIcon />}
              <p>Nein, kleiner</p>
            </div>
          </div>
        </div>

        <Distance top='30' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Entstand der Schaden bei einer Privat- oder Dienstfahrt?'
          fieldRegister='damagePrivatOrBusinessTrip'
          fieldRegisterSecond='damagePrivatOrBusinessTrip'
          firstButtonText='Privatfahrt'
          secondButtonText='Dienstfahrt'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte gib an, ob sich der Schaden im Rahmen einer Privat- oder Dienstfahrt ereignet hat. Im Falle von Brand-/Hagel-/Marder- und Diebstahlschäden ist dafür maßgeblich, ob sich der Schaden außerhalb oder während deiner Dienstzeit ereignet hat.'
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Ist dein Fahrzeug verkehrssicher/fahrbereit?'
          fieldRegister='autoIsOkay'
          fieldRegisterSecond='autoIsOkay'
        />

        <Distance top='20' />

        <div>
          <h6>Wo kann das Fahrzeug besichtigt werden?</h6>

          <TextFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayFirma'
            fieldName='Firma/Name'
            fieldPlaceholder='Muster GmbH'
          />

          <Distance top='5' />

          <TextFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayAdress'
            fieldName='Anschrift'
            fieldPlaceholder='Musterstr. 100 12345 Berlin'
          />

          <Distance top='5' />

          <PhoneFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='autoIsNotOkayTelephone'
            fieldName='Telefon'
          />

          <Distance top='30' />
        </div>

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Brauchst du einen Mietwagen?'
          fieldRegister='needRent'
          fieldRegisterSecond='needRent'
          optional={true}
        />

        <Distance top='20' />

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Wurde ein Polizeibericht aufgenommen?'
          fieldRegister='needPolice'
          fieldRegisterSecond='needPolice'
        />

        <Distance top='20' />

        {watch('needPolice') === true && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockNeedPolice} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Details zur polizeilichen Unfallaufnahme:</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceDienststelle'
                  fieldName='Dienststelle'
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='needPoliceAdressAndOffice'
                  fieldName='Anschrift und Telefonnummer der Dienststelle'
                  fieldPlaceholder='Musterstr. 100, 12345 Berlin, Tel.'
                  optional={true}
                />

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Hast du ein Aktenzeichen erhalten?'
                  fieldRegister='needPoliceGetCaseNumber'
                  fieldRegisterSecond='needPoliceGetCaseNumber'
                />
                <Distance top='10' />

                {!!watch('needPoliceGetCaseNumber') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceGetCaseNumber}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <Distance top='10' />
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceGetCaseNumberAktenzeichen'
                          fieldName='Aktenzeichen/Tagebuchnummer'
                        />
                        <Distance top='-10' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <Distance top='20' />

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde eine gebührenpflichtige Verwarnung ausgesprochen?'
                  fieldRegister='needPoliceCriminalWarning'
                  fieldRegisterSecond='needPoliceCriminalWarning'
                />
                <Distance top='20' />

                {!!watch('needPoliceCriminalWarning') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceCriminalWarning}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWho'
                          fieldName='Wer hat die gebührenpflichtige Verwarnung erhalten?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Alkoholtest durchgeführt?'
                  fieldRegister='needPoliceDrunkDriverWasTested'
                  fieldRegisterSecond='needPoliceDrunkDriverWasTested'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDriverWasTested') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceDrunkDriverWasTested}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonName'
                          fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='5' />

                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceCriminalWarningWhoFirstPersonDrunkLevel'
                          fieldName='Wert Alkoholtest'
                          fieldPlaceholder='z.B. 1,1 Promille'
                        />

                        <div
                          style={{ width: '100%' }}
                          className={watch('showSecondDrunkPerson') ? '' : styles.hide__block}
                        >
                          <div>
                            <Distance top='20' />

                            <div className={styles.close_header}>
                              <h6>Weitere Person:</h6>
                              <div
                                id='removeSecondDrunkPersonButton'
                                className={styles.close_block}
                                onClick={() => {
                                  setValue('showSecondDrunkPerson', false);
                                  clearErrors([
                                    'needPoliceCriminalWarningWhoSecondPersonName',
                                    'needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                                  ]);
                                }}
                              >
                                <TrashIcon />
                              </div>
                            </div>

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonName'
                              fieldName='Bei wem wurde ein Alkoholtest durchgeführt?'
                              fieldPlaceholder='Max Mustermann'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                            <Distance top='5' />

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceCriminalWarningWhoSecondPersonDrunkLevel'
                              fieldName='Wert Alkoholtest'
                              fieldPlaceholder='z.B. 1,1 Promille'
                              optional={!watch('showSecondDrunkPerson')}
                              optionalText=''
                            />
                          </div>
                        </div>

                        <div
                          id='showSecondDrunkPersonButton'
                          style={{ width: '100%' }}
                          onClick={() => setValue('showSecondDrunkPerson', true)}
                          className={watch('showSecondDrunkPerson') ? styles.hide__block : styles.hide__block__show}
                        >
                          <Distance top='-5' />+ Weitere Person
                        </div>
                        <Distance top='20' />
                      </div>
                    </CSSTransition>
                  </div>
                )}

                <TwoButtonsComponent
                  reactHookFormData={reactHookFormData}
                  fieldName='Wurde ein Drogentest durchgeführt?'
                  fieldRegister='needPoliceDrunkDrugsTest'
                  fieldRegisterSecond='needPoliceDrunkDrugsTest'
                />
                <Distance top='20' />

                {!!watch('needPoliceDrunkDrugsTest') && (
                  <div style={{ overflow: 'hidden' }}>
                    <CSSTransition
                      in={showBlockNeedPoliceDrunkDrugsTest}
                      timeout={400}
                      classNames={slideQuest}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div>
                        <TextFieldComponent
                          reactHookFormData={reactHookFormData}
                          fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedFirst'
                          fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                          fieldPlaceholder='Max Mustermann'
                        />
                        <Distance top='5' />

                        <TwoButtonsComponent
                          reactHookFormData={reactHookFormData}
                          fieldName='Wert Drogentest'
                          fieldRegister='needPoliceDrunkDrugsTestResultFirst'
                          fieldRegisterSecond='needPoliceDrunkDrugsTestResultFirst'
                          firstButtonText='Positiv'
                          firstButtonValue='Positiv'
                          secondButtonText='Negativ'
                          secondButtonValue='Negativ'
                        />

                        <div
                          style={{ width: '100%' }}
                          className={watch('showSecondDrugPerson') ? '' : styles.hide__block}
                        >
                          <div>
                            <Distance top='20' />

                            <div className={styles.close_header}>
                              <h6>Weitere Person:</h6>
                              <div
                                className={styles.close_block}
                                onClick={() => {
                                  setValue('showSecondDrugPerson', false);
                                  clearErrors([
                                    'needPoliceDrunkDrugsTestWhoWasTestedSecond',
                                    'needPoliceDrunkDrugsTestResultSecond'
                                  ]);
                                }}
                              >
                                <TrashIcon />
                              </div>
                            </div>

                            <TextFieldComponent
                              reactHookFormData={reactHookFormData}
                              fieldRegister='needPoliceDrunkDrugsTestWhoWasTestedSecond'
                              fieldName='Bei wem wurde ein Drogentest durchgeführt?'
                              fieldPlaceholder='Max Mustermann'
                              optional={!watch('showSecondDrugPerson')}
                              optionalText=''
                            />
                            <Distance top='5' />

                            <TwoButtonsComponent
                              reactHookFormData={reactHookFormData}
                              fieldName='Wert Drogentest'
                              fieldRegister='needPoliceDrunkDrugsTestResultSecond'
                              fieldRegisterSecond='needPoliceDrunkDrugsTestResultSecond'
                              firstButtonText='Positiv'
                              firstButtonValue='Positiv'
                              secondButtonText='Negativ'
                              secondButtonValue='Negativ'
                              optional={!watch('showSecondDrugPerson')}
                              optionalText=''
                            />
                          </div>
                        </div>

                        <div
                          id='showSecondDrugPersonButton'
                          style={{ width: '100%' }}
                          onClick={() => setValue('showSecondDrugPerson', true)}
                          className={watch('showSecondDrugPerson') ? styles.hide__block : styles.hide__block__show}
                        >
                          <Distance top='-5' />+ Weitere Person
                        </div>
                        <Distance top='30' />
                      </div>
                    </CSSTransition>
                  </div>
                )}
              </div>
            </CSSTransition>
          </div>
        )}

        <Distance top={watch('needPolice') === true ? '10' : '0'} />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='returnToTypeButton' onClick={returnToType} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default ChooseAnimalDamage;
