import React, { useState, useEffect } from 'react';
import { Text, Button, Flex } from 'common/common-components.styled';
import UploadFile from 'components/setup-assistant/upload-document/upload-file/upload-file-view';
import { PrefixForId } from '../../../../common/enums';

interface ChecklistUploadProps {
  onSubmit: () => void;
  onFormComplete: (files: File[]) => void;
}

const ChecklistUpload = ({ onSubmit, onFormComplete }: ChecklistUploadProps) => {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (files.length) {
      onFormComplete(files);
    }
  }, [files, onFormComplete]);

  return (
    <Flex direction='column'>
      <Text bottom='30'>Bitte lade ausgefüllte und unterschriebene Checkliste hoch.</Text>
      <UploadFile
        onDropAccepted={files => setFiles(files)}
        accept={['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']}
        acceptString={'pdf, png, jpg, jpeg'}
        unfallreparatur={true}
        multiple={true}
        className={null}
        maxSize={10485760}
        maxFiles={10}
      />
      <Flex justify='flex-end' top='30'>
        <Button
          disabled={files.length === 0 || files.length > 10}
          onClick={onSubmit}
          id={PrefixForId.Modal + PrefixForId.Form + PrefixForId.Button + PrefixForId.Submit}
        >
          Absenden
        </Button>
      </Flex>
    </Flex>
  );
};

export default ChecklistUpload;
