import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { StyledContainerType } from './expanded.props';

export const StyledContainer = styled(Flex)<StyledContainerType>`
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  left: 0;
  flex-direction: column;
  width: 100%;
  max-height: unset !important;
  opacity: ${({ isExtended }) => (isExtended ? '1' : '0')};
  z-index: ${({ isExtended }) => (isExtended ? '1' : '-1')};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: all 0.15s;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    position: static;
    display: ${({ isExtended }) => (isExtended ? 'flex' : 'none')};
  }
`;
