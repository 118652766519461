import { useQuery, UseQueryResult } from 'react-query';
import dashboardService from 'services/dashboard-service';
import { getDataForTable } from 'utils/get-response-data';
import { AnyObject, ResponseModel } from 'common/interfaces';
import { getStatuses } from 'components/driving-license-control-component/utils';
import { EMTY_FILTER_STATE } from 'components/driving-license-control-component/driving-license-control-component-view';
import { AxiosResponse } from 'axios';
import { StatusesReq } from 'components/driving-license-control-component/driving-license-control.props';
import { TaskResponseItem } from 'components/aufgaben-component/task.prop';
import { MileageData } from 'components/dashboard-component-chart/mileage-chart/mileage-chart.props';

export const useDashboardQuery = (companyId?: string | null) => {
  const cars = useQuery(['car', companyId], () => dashboardService.getCars(companyId || null), {
    enabled: companyId !== undefined
  });
  const consumption = useQuery(['consumption', companyId], () => dashboardService.getConsumption(companyId || null), {
    enabled: companyId !== undefined
  });
  const damage = useQuery(['damage', companyId], () => dashboardService.getDamage(companyId || null), {
    enabled: companyId !== undefined
  });
  const dataQuality = useQuery(['data quality', companyId], () => dashboardService.getDataQuality(companyId || null), {
    enabled: companyId !== undefined
  });
  const drivers = useQuery(['drivers', companyId], () => dashboardService.getDrivers(companyId || null), {
    enabled: companyId !== undefined
  });
  const mileage = useQuery<AxiosResponse<ResponseModel<MileageData>>>(
    ['mileage', companyId],
    () => dashboardService.getMileage(companyId || null),
    {
      enabled: companyId !== undefined
    }
  );
  const driversCheck: UseQueryResult<AxiosResponse<ResponseModel<StatusesReq>>, unknown> = useQuery(
    ['drivers check', companyId],
    () => getStatuses({ ...EMTY_FILTER_STATE, FirmId: companyId || null }, true),
    {
      enabled: companyId !== undefined
    }
  );
  const vehicleCosts = useQuery(
    ['vehicle costs', companyId],
    () => dashboardService.getVehicleCosts(companyId || null),
    {
      enabled: companyId !== undefined
    }
  );

  const tasks = useQuery(['tasks', companyId], () => dashboardService.getTasks(companyId || null, 0), {
    select: (data: AnyObject) => {
      const items =
        data?.data?.data.tasks.map((task: TaskResponseItem) => ({
          ...task.task[0],
          ...(task.fuhrpark ? task.fuhrpark[0] : {})
        })) || [];

      return getDataForTable(items);
    },
    enabled: companyId !== undefined
  });
  const overdueTasks = useQuery(['overdueTasks', companyId], () => dashboardService.getTasks(companyId || null, 1), {
    select: (data: AnyObject) => {
      const items =
        data?.data?.data.tasks.map((task: TaskResponseItem) => ({
          ...task.task[0],
          ...(task.fuhrpark ? task.fuhrpark[0] : {})
        })) || [];

      return getDataForTable(items);
    },
    enabled: companyId !== undefined
  });

  const topCosts = useQuery(['top costs', companyId], () => dashboardService.getTopCosts(companyId || null), {
    enabled: companyId !== undefined
  });

  return {
    cars: {
      ...cars,
      data: cars.data?.data?.data
    },
    consumption: {
      ...consumption,
      data: consumption.data?.data?.data || undefined
    },
    damage: {
      ...damage,
      data: damage.data?.data?.data
    },
    dataQuality: {
      ...dataQuality,
      data: dataQuality.data?.data?.data
    },
    drivers: {
      ...drivers,
      data: drivers.data?.data?.data
    },
    mileage: {
      ...mileage,
      data: mileage.data?.data?.data || undefined
    },
    driversCheck: {
      ...driversCheck,
      data: driversCheck.data?.data?.data
    },
    tasks,
    overdueTasks,
    vehicleCosts: {
      ...vehicleCosts,
      data: vehicleCosts.data?.data?.data || undefined
    },
    topCosts: {
      ...topCosts,
      data: topCosts.data?.data?.data || undefined
    }
  };
};

export type TopCostsDataType = {
  startDate?: string;
  endDate?: string;
};
