import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormFieldOption } from 'common/interfaces';
import { OptionSetFileValueItem } from '../option-set-file.props';

export const useDisableOptionSet = (
  values: OptionSetFileValueItem[],
  setOptions: Dispatch<SetStateAction<FormFieldOption[]>>
) => {
  const disableOptionSet = (options: FormFieldOption[]) => {
    const optionsForDisable = values.map(valueItem => valueItem.documentType);

    return options.map(optionItem => {
      if (optionsForDisable.includes(optionItem.value as string)) {
        return { ...optionItem, disabled: true };
      }

      return { ...optionItem, disabled: false };
    });
  };

  useEffect(() => {
    setOptions(disableOptionSet);
  }, [JSON.stringify(values)]);
};
