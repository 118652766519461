import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import {
  EditBookingFieldNames,
  editBookingFormFields,
  licensePlateFormFields
} from '../form-fields/edit-booking.form-fields';
import { FormFieldModel } from 'common/interfaces';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import { Moment } from 'moment';
import useAvailablePoolCars from 'components/pool-car-booking/hooks/react-query/use-available-pool-cars/use-available-pool-cars';
import { GetAvailableCarsData } from 'request-config/pool-calendar/pool-calendar.types';
import cloneDeep from 'lodash/cloneDeep';
import { Booking } from 'components/pool-car-booking/edit-booking/edit-booking.types';
import debounce from 'lodash/debounce';

const getEditBookingFieldsDefault = (booking: Booking, isFleetManagerOrAdminOrPoolManager: boolean) => {
  licensePlateFormFields[0].options?.push({ label: booking.new_name, value: booking.new_fuhrparkid });
  return isFleetManagerOrAdminOrPoolManager
    ? [...editBookingFormFields, ...licensePlateFormFields]
    : editBookingFormFields;
};

const useEditBookingFields = (booking: Booking, reactHookFormData: UseFormReturn) => {
  const isFleetManagerOrAdminOrPoolManager = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);
  const [editBookingFields, setEditBookingFields] = useState<FormFieldModel<EditBookingFieldNames>[]>(
    getEditBookingFieldsDefault(booking, isFleetManagerOrAdminOrPoolManager)
  );
  const [getAvailableCarsData, setGetAvailableCarsData] = useState<GetAvailableCarsData | null>(null);

  const debounceSetGetAvailableCarsData = useCallback(debounce(setGetAvailableCarsData, 1000), []);

  const { availablePoolCars } = useAvailablePoolCars(
    getAvailableCarsData,
    !!getAvailableCarsData?.carId && isFleetManagerOrAdminOrPoolManager
  );

  const { getValues } = reactHookFormData;
  const startDateValue: Moment | null | undefined = getValues(EditBookingFieldNames.StartDate);
  const endDateValue: Moment | null | undefined = getValues(EditBookingFieldNames.EndDate);
  const licensePlateValue: string = getValues(EditBookingFieldNames.LicensePlate);

  useEffect(() => {
    if (availablePoolCars) {
      const newEditBookingFields = cloneDeep(editBookingFields);
      const licensePlateField = newEditBookingFields.find(field => field.name === EditBookingFieldNames.LicensePlate);
      if (licensePlateField) {
        licensePlateField.options = availablePoolCars.map(availablePoolCar => ({
          label: availablePoolCar.new_name || '',
          value: availablePoolCar.new_fuhrparkid
        }));

        setEditBookingFields(newEditBookingFields);
      }
    }
  }, [JSON.stringify(availablePoolCars?.length)]);

  useEffect(() => {
    if (startDateValue?.isValid() && endDateValue?.isValid()) {
      debounceSetGetAvailableCarsData({
        abholdatum: startDateValue.format(),
        ruckgabedatum: endDateValue.format(),
        carId: licensePlateValue
      });
    }
  }, [startDateValue?.format(), endDateValue?.format(), licensePlateValue]);

  useEffect(() => {
    const newEditBookingFields = cloneDeep(editBookingFields);
    const startDateField = newEditBookingFields.find(field => field.name === EditBookingFieldNames.StartDate);
    const endDateField = newEditBookingFields.find(field => field.name === EditBookingFieldNames.EndDate);
    if (!(startDateField && endDateField)) {
      return;
    }

    endDateField.minDate = startDateValue?.isValid() ? startDateValue : undefined;
    startDateField.maxDate = endDateValue?.isValid() ? endDateValue : undefined;

    setEditBookingFields(newEditBookingFields);
  }, [startDateValue?.format(), endDateValue?.format()]);

  return { editBookingFields };
};

export default useEditBookingFields;
