import React, { useEffect } from 'react';
import classes from './page-not-found.module.scss';
import { useOverlay } from '../../hooks';
import { setCustomUrlTree } from '../../actions/app_action';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { defaultUrlTree } from '../breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { withPageContainer } from '../../hoc/withPageContainer';
import PropTypes from 'prop-types';

const PageNotFound = ({ containerClassName, config }) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();

  useEffect(() => {
    hideOverlay();
    const newUrlTree = cloneDeep(defaultUrlTree);
    newUrlTree.children = {};
    newUrlTree.title = config.pageTitle;
    dispatch(setCustomUrlTree(newUrlTree));

    return () => {
      dispatch(setCustomUrlTree(null));
    };
  }, []);

  return (
    <div className={classes.page_not_found}>
      <div className={`${containerClassName} ${classes.wrapper}`}>
        <img src={config.imageSrc} alt='notFoundPage' />
        <div className={classes.container}>
          <h5>{config.subTitle}</h5>
          <h1>{config.mainTitle}</h1>
          <p>{config.description}</p>
          <button onClick={config.action} className='button-mobexo button-mobexo__main'>
            {config.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {
  containerClassName: PropTypes.string.isRequired,
  config: PropTypes.objectOf({
    pageTitle: PropTypes.string | PropTypes.any,
    imageSrc: PropTypes.string,
    buttonText: PropTypes.string,
    subTitle: PropTypes.string,
    mainTitle: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.func
  }).isRequired
};

export default withPageContainer(PageNotFound);
