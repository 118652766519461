export const getFileSize = (bytes: number) => {
  const sizes = ['B', 'KB', 'MB'];

  if (bytes === 0) {
    return '0 B';
  }

  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1);

  const sizeInUnit = bytes / Math.pow(1024, i);

  return `${sizeInUnit.toFixed(2)} ${sizes[i]}`;
};
