import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import Select from './select';
import store from '../../../store';
import { Transmission } from '../../../constants';
import { changeSearch } from '../../../actions/app_action';

const transmissionOptions = Object.keys(Transmission).reduce(
  (options, key, index) => {
    options[index + 1] = { value: key, label: Transmission[key] };
    return options;
  },
  [{ value: '', label: 'Getriebe' }]
);

const mapStateToProps = state => ({
  value: state.app.smartTable.search.predicateObject.transmission || '',
  options: transmissionOptions
});

const mapDispatchToProps = dispatch => ({
  onChange(value, name) {
    const state = store.getState();
    const search = cloneDeep(state.app.smartTable.search);
    search.predicateObject.transmission = value || null;
    dispatch(changeSearch(search));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
