import { UpdateDamageDataProp } from 'request-config/damage/damage.props';
import { damageRequestConfig } from 'request-config/damage/damage.request-config';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { DamageEditFormContext } from '../../../damage-edit-form.context';
import { useContext } from 'react';
import { useGetDamageByIdQuery } from 'hooks/react-query/damage/get-damage-by-id/get-damage-by-id';
import { submitFetchConfig } from './submit-button.fetch-config';
import { GetDamageOptionsType } from 'hooks/react-query/damage/get-damage-by-id/get-damage-by-id.props';
import { useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'common/enums';

export const useFetchDamageEditForm = (damageIdProps?: string, damageRequestOptions?: GetDamageOptionsType) => {
  const { damageData } = useGetDamageByIdQuery(damageIdProps, damageRequestOptions);
  const { onEdit } = useContext(DamageEditFormContext);
  const { fetch } = useFetch(submitFetchConfig);

  const queryClient = useQueryClient();

  const damageId = damageIdProps || damageData.incidentid;

  const fetchHandler = async (data: UpdateDamageDataProp) => {
    if (!damageData.incidentid || !damageData.uds_schadenaufnahmeid) {
      return;
    }

    await fetch({
      requestConfig: damageRequestConfig.update({
        updateIncident: {
          id: damageData.incidentid,
          attributes: {}
        },
        updateSchadenAufnahme: {
          id: damageData.uds_schadenaufnahmeid,
          attributes: {}
        },
        updateDamageParts: null,
        ...data
      }),
      callback: () => {
        onEdit && onEdit(false);
        queryClient.removeQueries([ReactQueryKeys.DamageGetById, damageId]);
      }
    });
  };

  return { fetchHandler };
};
