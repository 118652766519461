import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const EngineIcon = ({ width = 20, height = 14, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M8.27939 12.7586H14.0668H15.0313V5.41843H13.8738L12.9093 3.60603H5.86799L4.80697 4.78409H3.26367V10.765H6.0609L8.27939 12.7586Z'
          fill={fill}
        />
      )}
      {hovered && (
        <path
          d='M16.909 6.92789H15.0317V11.4954H16.909V12.7587H18.0626C18.0626 12.7587 18.9726 10.557 18.954 9.06586C18.9363 7.64801 18.0626 5.56737 18.0626 5.56737H16.909V6.92789Z'
          fill='white'
        />
      )}
      <path
        d='M11.7126 12.7586H8.27939L6.0609 10.765H3.26367V4.78409H4.80697L5.86799 3.60603H12.9093L13.8738 5.41843H15.0313V12.7586H14.0668'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0317 6.92789H16.909V5.56737H18.0626C18.0626 5.56737 18.9363 7.64801 18.954 9.06586C18.9726 10.557 18.0626 12.7587 18.0626 12.7587H16.909V11.4954'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.87493 3.51268V1.24132M9.63371 3.51268V1.24132M11.7628 1.24132H5.83844M3.24655 7.15504L0.954102 7.15505M0.954102 4.07305V10.1437'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
