import React from 'react';
import { MultipleSection } from 'components/sections/multiple-section/multiple-section';
import { VehicleDetailsFileList } from './components/file-list/file-list';
import { VehicleDetailsUploadDocuments } from './components/upload-documents/upload-documents';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailsVehicleDocumentsTabPanel = () => {
  const { windowWidth } = useWindowSize(500);
  return (
    <MultipleSection
      label='Fahrzeugdokumente'
      nodeList={[<VehicleDetailsFileList key='list' />, <VehicleDetailsUploadDocuments key='upload' />]}
      headerProps={{ bottom: '20', top: windowWidth <= 550 ? '20' : undefined }}
    />
  );
};
