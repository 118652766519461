import { GetFilterQueryByName } from '../../../common/enums';
import {Title} from '../../../common/interfaces';
import { useQuery } from 'react-query';
import {getFahrzeugkostenCostTypeFilterData, TransformedCostTypeFilterData} from '../../../services/vehicle-costs-service';
import { AxiosResponse } from 'axios';


export const useFilterByCostType = (title:Title)=>{
    const {
        data:costTypeFilterData,
        isFetching,
      } = useQuery<AxiosResponse<TransformedCostTypeFilterData>>(
        [GetFilterQueryByName[title]],
        () => getFahrzeugkostenCostTypeFilterData(title),
        {
          refetchOnWindowFocus:false
        }
      );

    return {
        isFetching,
        data:costTypeFilterData?.data
    };
};