import { Store } from 'redux';

let store: Store;

if (process.env.NODE_ENV === 'development') {
  store = require('./configure-store-development').default;
} else {
  store = require('./configure-store-production').default;
}

export default store;
