import { DispatchWithoutAction, useEffect } from 'react';
import { LeasingSectionReducerState } from '../leasing-section.props';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { setVehicleDetailsLeasingTab } from 'actions/pages/vehicle-details.action';
import { LEASING_CONTRACT_LABEL_MAPPING } from '../utils';

export const useOpenLeasingContract = (
  isDataLoaded: boolean,
  state: LeasingSectionReducerState,
  dispatchReducer: DispatchWithoutAction
) => {
  const leasingTab = useTypedSelector(state => state.vehicleDetails.leasingTab);

  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (leasingTab.openedContract && isDataLoaded) {
      const cloneState = cloneDeep(state);

      const findSectionInBookedList = cloneState.activeItems.find(
        item => item.new_vertragstyp_value === leasingTab.openedContract
      );

      const findSectionNotBookedList = cloneState.inactiveItems.find(
        item => item.new_vertragstyp_value === leasingTab.openedContract
      );

      const findSection = findSectionInBookedList || findSectionNotBookedList;

      cloneState.inactiveItems = cloneState.inactiveItems.filter(item => item.mode !== 'create');

      if (findSectionInBookedList) {
        cloneState.inactiveItems.forEach(item => (item.itemExpand = false));
        cloneState.activeItems.forEach(item => {
          item.itemExpand = item.new_vertragstyp_value === findSectionInBookedList.new_vertragstyp_value;
        });
      }
      if (findSectionNotBookedList) {
        cloneState.activeItems.forEach(item => (item.itemExpand = false));
        cloneState.inactiveItems.forEach(item => {
          item.itemExpand = item.new_vertragstyp_value === findSectionNotBookedList.new_vertragstyp_value;
        });
      }

      if (!findSection && leasingTab.openedContract) {
        cloneState.activeItems.forEach(item => (item.itemExpand = false));
        cloneState.inactiveItems.forEach(item => (item.itemExpand = false));

        cloneState.inactiveItems.unshift({
          new_vertragstyp: LEASING_CONTRACT_LABEL_MAPPING[leasingTab.openedContract],
          new_vertragstyp_value: leasingTab.openedContract,
          itemExpand: true,
          mode: 'create'
        });
      }

      setTimeout(() => {
        const sectionNode = document.querySelector(
          `[data-contract-type-id="${findSection?.new_vertragstyp_value || leasingTab.openedContract}"]`
        );
        sectionNode?.scrollIntoView();
      }, 10);

      //@ts-ignore
      dispatchReducer({ type: 'SET_EXPANDED', payload: true });
      //@ts-ignore
      dispatchReducer({ type: 'SET_INACTIVE_ITEMS', payload: cloneState.inactiveItems });
      //@ts-ignore
      dispatchReducer({ type: 'SET_ACTIVE_ITEMS', payload: cloneState.activeItems });
    }
  }, [isDataLoaded, leasingTab.openedContract]);

  useEffect(() => {
    return () => {
      const cloneState = cloneDeep(state);

      cloneState.activeItems.forEach(item => (item.itemExpand = false));
      cloneState.inactiveItems.forEach(item => (item.itemExpand = false));

      //@ts-ignore
      dispatchReducer({ type: 'SET_INACTIVE_ITEMS', payload: cloneState.inactiveItems });
      //@ts-ignore
      dispatchReducer({ type: 'SET_ACTIVE_ITEMS', payload: cloneState.activeItems });
      dispatchRedux(setVehicleDetailsLeasingTab({ openedContract: null }));
    };
  }, []);
};
