import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useBarData } from './hooks/use-bar-data';
import { BarDiagramProps } from './bar.props';
import { useBarOptions } from './hooks/use-bar-options';
import { Flex } from 'common/common-components.styled';
import { Chart } from 'chart.js';
import { HorizontalLinePluginTooltip } from './components/horizontal-line-plugin-tooltip/horizontal-line-plugin-tooltip';

export const BarDiagram = (props: BarDiagramProps) => {
  const barRef = useRef<Chart<'bar'>>(null);

  const { barData } = useBarData(props);
  const { barOptions } = useBarOptions(props);

  const { diagramPlugins = [] } = props;

  return (
    <Flex position='relative' {...props.flexProps}>
      <HorizontalLinePluginTooltip barRef={barRef} {...props} />

      <Bar
        ref={barRef}
        {...props}
        plugins={diagramPlugins.map(item => item.plugin)}
        options={barOptions}
        data={barData}
      />
    </Flex>
  );
};
