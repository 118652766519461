import React, { useMemo } from 'react';
import { setSnakbar, toggleModal } from 'actions/app_action';
import { PAGES } from 'common/enums';
import { MODAL_NAMES, MODALS, VEHICLE_RETURN_PHASES } from '../../../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOverlay } from 'hooks';
import { uploadProtocol } from 'components/aufgaben-component/utils';
import { UploadProtocol } from 'components/aufgaben-component/components/upload-protocol/upload-protocol';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

export const useVehicleReturnButtons = (activeWidgetIndex: number, dontHaveHandoverProtocol: boolean) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const { vr } = car;
  const phase = vr.vr_state;
  const vehicleId = vr.vr_phaseinfo.fuhrparkId;
  const orderId = vr.vr_phaseinfo.lafId;
  const reactHookFormData = useFormContext();
  const { handleSubmit } = reactHookFormData;

  const redirectToOrder = () => {
    history.push(`${PAGES.ASSIGNMENTS_LEASE_RETURN_PRO}?id=${vehicleId}`);
  };

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submit = (isWithoutProtocol: boolean, returnDate: string, files: File[] = []) => {
    closeAlert();
    showOverlay();
    uploadProtocol(vehicleId, orderId, files, isWithoutProtocol, returnDate).then(response => {
      refresh();
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess
            ? 'Deine Entscheidung wurde erfolgreich gespeichert'
            : 'Fehler: Datum wurde nicht gespeichert.',
          type: response.data.level.toLowerCase()
        })
      );
      hideOverlay();
    });
  };

  const onSubmit = () => {
    handleSubmit(data => {
      const returnDate = moment(data.dateVehicleReturned).format('YYYY-MM-DDTHH:mm:ss');
      submit(dontHaveHandoverProtocol, returnDate);
    })();
  };

  const openUploadProtocolModal = () => {
    const alertData = {
      title: 'Übergabeprotokoll hochladen',
      children: <UploadProtocol onSubmit={submit} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const button = useMemo(() => {
    const isFormShowed = activeWidgetIndex !== 0;

    switch (phase) {
      case VEHICLE_RETURN_PHASES.open:
      case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
      case VEHICLE_RETURN_PHASES.protocolUploadedByClient:
      case VEHICLE_RETURN_PHASES.protocolUploadedByEmployee:
        return {
          title: 'Auftrag erstellen',
          action: redirectToOrder,
          id: 'createOrder'
        };
      case VEHICLE_RETURN_PHASES.filledByClient:
      case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
        if (!isFormShowed) {
          return {
            title: 'Übergabeprotokoll hochladen',
            action: openUploadProtocolModal,
            id: 'createOrder'
          };
        } else {
          return {
            title: 'Speichern',
            action: () => dontHaveHandoverProtocol && onSubmit(),
            disabled: !dontHaveHandoverProtocol,
            id: 'createOrder'
          };
        }
      default:
        return null;
    }
  }, [phase, activeWidgetIndex, dontHaveHandoverProtocol]);

  return { button };
};
