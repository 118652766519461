import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import { INSTANCIES } from '../company-settings-component/approval-rules/approval-rules';
import { SetApproversAutomaticallyValues } from 'pages/company-settings-page/company-settings-component/approval-rules/approval-rules.types';
import { AnyObject } from 'common/interfaces';

const useFillInstanceFields = (reactHookFormData: UseFormReturn, companyData: AnyObject | null) => {
  const { setValue, getValues } = reactHookFormData;

  const setApproversAutomaticallyValue = getValues('uds_order_approve_type_value');

  const updateInstanceFields = () => {
    INSTANCIES.forEach(instance => {
      if (companyData?.[instance.instanceFieldName]?.attributeValue) {
        setValue(
          instance.instanceFieldName,
          String(companyData[instance.instanceFieldName].attributeValue.value),
          defaultOptions
        );
      }
      if (companyData?.[instance.contactFieldName]?.attributeValue) {
        setValue(instance.contactFieldName, companyData[instance.contactFieldName].attributeValue.id, defaultOptions);
      }
    });
  };

  useEffect(() => {
    if (companyData?.uds_order_approve_type_value?.attributeValue) {
      setValue(
        'uds_order_approve_type_value',
        String(companyData.uds_order_approve_type_value.attributeValue.value),
        defaultOptions
      );
    }
    updateInstanceFields();
  }, [JSON.stringify(companyData)]);

  useEffect(() => {
    if (setApproversAutomaticallyValue === SetApproversAutomaticallyValues.Manual) {
      updateInstanceFields();
    }
  }, [setApproversAutomaticallyValue]);
};

export default useFillInstanceFields;
