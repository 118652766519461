import React from 'react';
import classes from './insurance-dialog.module.scss';

const InsuranceDialog = ({ parameters }) => {
  const cancelButton = parameters.buttons.find(button => button.type === 'cancel');

  return (
    <div className={classes.insurance_dialog}>
      <div className={classes.insurance_dialog_header}>
        <span className={classes.insurance_dialog_header_title}>{parameters.title}</span>
        <img
          onClick={cancelButton?.action || (() => {})}
          src='/assets/images/insurance_dialog_close.svg'
          alt='insurance_dialog_close' />
      </div>
      <div className='mb-1' />
      {parameters.children}
      <div className='mb-1' />
      <div className={classes.insurance_dialog_footer}>
        {parameters.clear && <span onClick={parameters.clear} className={classes.reset}>Zurücksetzen x</span>}
        <div className={classes.buttons}>
          {parameters.buttons.map(button => <div className='d-flex' key={button.title}>
            <div className='mr-1' />
            <button
              onClick={button.action}
              className={`button-mobexo ${button.type === 'submit'
                ? 'button-mobexo__main'
                : `button-mobexo__secondary ${classes.transparent}`}`}>{button.title}</button>
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default InsuranceDialog;
