import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getMonatsrechnungen = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/monatsrechnungen/getMonatsrechnungen`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};

export const getMonatsrechnungenFilterData = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/monatsrechnungen/getMonatsrechnungenFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getMonatsrechnungenFile = async invoiceid => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/monatsrechnungen/getMonatsrechnungenFile`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      invoiceid
    }
  });
};

export const createNewTask = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/Create`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data
  });
};
