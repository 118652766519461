import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PriceOptions from './price-options';
import { requestOfferDetailsAsync, PriceItem } from '../../../services/price-calculator-service';
import { setFlatrate } from '../../../actions/flaterate_action';
import {setPriceItems} from "../../../actions/app_action";

class PriceOptionsContainer extends Component {
  constructor(props) {
    super(props);
    this.periodChangeHandler = this.periodChangeHandler.bind(this);
    this.mileageChangeHandler = this.mileageChangeHandler.bind(this);
    this.getVehicleAmountOptions = this.getVehicleAmountOptions.bind(this);
    this.setCheckboxesCheckedByDefault = this.setCheckboxesCheckedByDefault.bind(this);
  }
  state = {
    priceItems: [],
    mileages: [],
    periods: []
  };

  getVehicleAmountOptions() {
    return this.vehicleAmountValues.map(x => {
      return {
        value: x,
        label: x
      };
    });
  }

  vehicleAmountValues = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10+'
  ];



  async componentDidMount() {
    await this.loadOfferDetails();
  }

  componentWillUnmount() {
    this.props.setPriceItems([]);
  }

  static toPeriodOptions(items) {
    const options = items.map(x => ({ value: x, label: `${x} Monate` }));
    return options;
  }

  static toMileageOptions(items) {
    const options = items.map(x => ({ value: x, label: `${x.toLocaleString('de-DE')} km/Jahr` }));
    return options;
  }

  getDefaultPriceOptions() {
    const { item } = this.props;
    const priceItem = PriceItem.fromFahr(item);
    return [priceItem];
  }

  setCheckboxesCheckedByDefault(priceItem) {
    if (this.props.isPersonalOffer) {
      priceItem = {
        ...priceItem,
        insurance_checkbox: priceItem.insurance_checkbox_disabled,
        insurance_checkbox_disabled: false,
        tax_checkbox: priceItem.tax_checkbox_disabled,
        tax_checkbox_disabled: false,
        gez_checkbox: priceItem.gez_checkbox_disabled,
        gez_checkbox_disabled: false,
        winterWheelsStorage_checkbox: priceItem.winterWheelsStorage_checkbox_disabled,
        winterWheelsStorage_checkbox_disabled: false,
        wheelStorageReplacement_checkbox: priceItem.wheelStorageReplacement_checkbox_disabled,
        wheelStorageReplacement_checkbox_disabled: false,
        tireReplacement_checkbox: priceItem.tireReplacement_checkbox_disabled,
        tireReplacement_checkbox_disabled: false,
        maintenance_checkbox: priceItem.maintenance_checkbox_disabled,
        maintenance_checkbox_disabled: false,
      }
    }
    return priceItem;
  }

  mileageChangeHandler({target: { value }}) {
    const period = this.props.flatrate.period;
    const mileage = +value;

    let priceItem = PriceItem.find(this.state.priceItems, mileage, period);
    priceItem = this.setCheckboxesCheckedByDefault(priceItem);
    this.props.setFlatrate(priceItem);
  }

  periodChangeHandler({ target: { value } }) {
    const period = +value;
    let priceItem = PriceItem.getPriceItemWithLowestMileage(this.state.priceItems, period);
    priceItem = this.setCheckboxesCheckedByDefault(priceItem);
    const mileages = PriceItem.getMileagesForPeriod(this.state.priceItems, period);
    this.setState({ mileages }, () => {
      this.props.setFlatrate(priceItem);
    });
  }

  async loadOfferDetails() {
    try {
      let { data: priceItems } = await requestOfferDetailsAsync(this.props.id);
      this.props.setPriceItems(priceItems);
      priceItems = priceItems.length ? priceItems : this.getDefaultPriceOptions();
      let priceItem = priceItems[0];
      const mileages =PriceItem.getMileagesForPeriod(priceItems, priceItem.period);
      const periods = PriceItem.getPeriods(priceItems);
      priceItem = this.setCheckboxesCheckedByDefault(priceItem);
      this.props.setFlatrate(priceItem);
      this.setState({ priceItems, mileages, periods });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    let isHidePriceOptions = this.state.periods.every(item => item === 0) && this.state.mileages.every(item => item === 0);

    if (!this.props.priceItems.length || isHidePriceOptions) {
        return null;
    }

    return (
      <>
        <PriceOptions
          selectProps={{
            name: 'period',
            onChange: this.periodChangeHandler,
            value: this.props.flatrate.period,
            style: {fontSize: '14px', color: '#373B4D'}
          }}
          label='Laufzeit:'
          options={PriceOptionsContainer.toPeriodOptions(this.state.periods)}
        />
        <PriceOptions
          selectProps={{
            name: 'mileage',
            onChange: this.mileageChangeHandler,
            value: this.props.flatrate.mileagePerYear,
            style: {fontSize: '14px', color: '#373B4D'}
          }}
          label='Fahrleistung:'
          options={PriceOptionsContainer.toMileageOptions(this.state.mileages)}
        />
        <PriceOptions
          selectProps={{
            name: 'bestellmenge',
            onChange: ({ target: { value } }) => this.props.onCarAmountChange(value),
            defaultValue: '1',
            style: {fontSize: '14px', color: '#373B4D'}
          }}
          label='Bestellmenge:'
          options={this.getVehicleAmountOptions()}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  item: state.app.item,
  flatrate: state.flatrate,
  priceItems: state.app.priceItems
});

const mapDispatchToProps = dispatch => ({
  setFlatrate(obj) {
    dispatch(setFlatrate(obj));
  },
  setPriceItems(priceItems) {
    dispatch(setPriceItems(priceItems));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceOptionsContainer);

PriceOptionsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  offer: PropTypes.bool,
  onCarAmountChange: PropTypes.func.isRequired,
  flatrate: PropTypes.object,
  setFlatrate: PropTypes.func.isRequired
};
