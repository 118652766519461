import React from 'react';
import classes from './leasing-period.module.scss';
import { StyledDiagramContainer } from 'components/containers/diagram/diagram.styled';
import { LeasingPeriodWidgetDate } from './components/date-list/date';
import { useLeasingPeriodDateData } from './hooks/use-leasing-period-date-data';
import { ReturnLeaseTimeHasComePlaceholder } from './components/placeholder/return-lease-time-has-come/return-lease-time-has-come';
import { LeasingPeriodDiagram } from './components/diagram/diagram';
import { useFleetLeasingPeriodQuery } from 'hooks/react-query/fleet/use-leasing-period/use-leasing-period';
import moment from 'moment';
import { ReturnLeaseTimeIsNotExistPlaceholder } from './components/placeholder/return-lease-time-is-not-exist/return-lease-time-is-not-exist';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';

export const LeasingPeriodWidget = () => {
  const { fleetLeasingPeriodQuery } = useFleetLeasingPeriodQuery();
  const { leasingPeriodDateData } = useLeasingPeriodDateData();

  const { fleetByIdQuery } = useFleetByIdQuery();

  const isShowReturnLeaseTimeHasComePlaceholder = moment(fleetLeasingPeriodQuery.uds_lease_return_date).isSameOrBefore(
    moment(),
    'day'
  );

  return (
    <StyledDiagramContainer className={classes.container}>
      {fleetByIdQuery.fuhrpark?.uds_lease_return_date ? (
        <>
          {isShowReturnLeaseTimeHasComePlaceholder ? <ReturnLeaseTimeHasComePlaceholder /> : <LeasingPeriodDiagram />}

          <LeasingPeriodWidgetDate data={leasingPeriodDateData} />
        </>
      ) : (
        <ReturnLeaseTimeIsNotExistPlaceholder />
      )}
    </StyledDiagramContainer>
  );
};
