import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DamagedCarIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.08359 5.25804H11.3025L13.2135 9.83301L11.0146 16.0227H5.12395V17.0991C5.12395 17.3952 4.88174 17.6374 4.58571 17.6374H2.43279C2.13676 17.6374 1.89455 17.3952 1.89455 17.0991V15.6997L1.67926 14.6704C1.64562 14.4955 1.62544 14.3071 1.62544 14.1254V11.4813C1.62544 11.1584 1.686 10.8287 1.80037 10.526L2.06275 9.83301H0.818098C0.643172 9.83301 0.515341 9.67154 0.555708 9.50335L0.757545 8.63544C0.818096 8.39324 1.03339 8.21832 1.28232 8.21832H2.60098L3.63709 6.15286C3.91293 5.60117 4.47135 5.25804 5.08359 5.25804Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M1.27093 8.34622H2.52193L3.87284 9.26487H13.3744L10.9485 16.0401H4.9973V17.3712C4.9973 17.5309 4.89083 17.6374 4.73113 17.6374H2.06943C1.90973 17.6374 1.80327 17.5309 1.80327 17.3712V15.7206L1.59033 14.709C1.56372 14.5492 1.5371 14.3629 1.5371 14.1765V11.5675C1.5371 11.2214 1.61695 10.902 1.72342 10.6091L1.98959 9.91694H0.818435C0.632116 9.91694 0.525648 9.7572 0.552265 9.59747L0.765206 8.74556C0.81844 8.50596 1.03137 8.34622 1.27093 8.34622Z'
          fill={fill}
        />
      )}
      <path
        d='M8.73518 5.25804H5.08359C4.47135 5.25804 3.91293 5.60117 3.63709 6.15286L2.60098 8.21832H1.28232C1.03339 8.21832 0.818096 8.39324 0.757545 8.63544L0.555708 9.50334C0.515341 9.67154 0.643172 9.83301 0.818098 9.83301H2.06275L1.80037 10.526C1.686 10.8287 1.62544 11.1584 1.62544 11.4813V14.1254C1.62544 14.3071 1.64562 14.4954 1.67926 14.6704L1.89455 15.6997V17.0991C1.89455 17.3952 2.13676 17.6374 2.43279 17.6374H4.58571C4.88174 17.6374 5.12395 17.3952 5.12395 17.0991V16.0227H4.04748'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.24683 12.7937H5.48871L5.10497 12.033'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.32336 9.29466H8.88602'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.09735 16.0226L7.88169 16.0226'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.88162 5.67268L7.00028 6.71816M7.00028 6.71816L5.09729 7.44478M7.00028 6.71816L7.55405 7.44478'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1024 2.73928L13.5259 8.23127L17.4711 1.65797C17.7841 1.10283 18.3313 1.3235 18.2116 1.65797L15.48 9.14965L18.2478 8.63449C18.5801 8.55403 18.6227 8.87802 18.3062 9.14965L15.8143 11.4066L18.5058 14.6745C18.5115 14.6814 18.5163 14.689 18.5197 14.6972C18.6007 14.8899 18.5089 15.0035 18.2479 14.9434L14.9011 13.7289L15.48 18.5065C15.5129 18.7155 15.2494 18.7565 15.132 18.5178L12.9154 13.7289L8.9748 18.059C8.82365 18.2179 8.60217 17.9992 8.70779 17.7954L11.4548 12.1513H7.49146C7.47311 12.1513 7.45482 12.1467 7.4404 12.1353C7.31237 12.0347 7.2634 11.7911 7.50018 11.693L11.8703 9.83825L9.79782 2.91791C9.76156 2.70908 9.98343 2.57897 10.1024 2.73928Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
    </svg>
  );
};
