import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../constants';
import { inspectionDatesRequestConfig } from 'request-config/inspection-dates/inspection-dates.request-config';
import { DisconnectInspectionDateFromCarParams } from 'request-config/inspection-dates/inspection-dates.types';
import { OpenInspectionDateUnlinkCarModalParams } from './unlink-from-car.props';
import { useQueryClient } from 'react-query';
import { CAR_INSPECTION_DATE_QUERY_KEY } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspection-date.query';

export const InspectionDateUnlinkFromCarModalContent = (submitParams: OpenInspectionDateUnlinkCarModalParams) => {
  const { fetch } = useFetch({
    loadingKey: 'inspectionDateUnlinkCar',
    isShowSuccessSnackbar: true,
    successMessage: 'Der Prüftermin wurde erfolgreich zugeordnet',
    errorMessage: 'Ein Fehler ist aufgetreten. Der Prüftermin wurde nicht zugeordnet.'
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submitHandler = (
    params: Pick<DisconnectInspectionDateFromCarParams, 'isHistoryDeactivate' | 'isTaskDeactivate'>
  ) => {
    closeAlert();

    fetch({
      requestConfig: inspectionDatesRequestConfig.disconnectInspectionDateFromCar({ ...submitParams, ...params }),
      callback: () => {
        queryClient.resetQueries([CAR_INSPECTION_DATE_QUERY_KEY]);
      }
    });
  };

  return (
    <Flex direction='column'>
      <Text>
        Das Prüftermin wird nun unzugeordnet. Dies kann einen Moment dauern. Möchtest du dieser Prüftermin unverbunden
        und auch alle von dieser Prüftermin erstellte Aufgaben stornieren?
      </Text>

      <Flex justify='space-between' top='20'>
        <Button component={ButtonComponentType.CloseModal}>Nein, nicht löschen</Button>

        <Flex>
          <Button
            margin='0 10px 0 0'
            onClick={() => submitHandler({ isHistoryDeactivate: true, isTaskDeactivate: false })}
          >
            Alle behalten
          </Button>
          <Button onClick={() => submitHandler({ isHistoryDeactivate: true, isTaskDeactivate: true })}>
            Alle stornieren
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
