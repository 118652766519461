/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useContext, useEffect } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UseFormReturn } from 'react-hook-form';
import { UnSaveModalRefContext } from 'context/un-save-modal/un-save-modal-ref.context';

export const useSaveFormValues = (reactHookFormData: UseFormReturn, isSaveFormData: boolean) => {
  if (!isSaveFormData) {
    return;
  }

  const {
    setValue,
    getValues,
    watch,
    formState: { isValid: isValidHookForm }
  } = reactHookFormData;

  const isSetFormFromSavedValues = useTypedSelector(state => state.app.isSetFormFromSavedValues);

  const { isFormsValid, saveFormValues } = useContext(UnSaveModalRefContext);

  const setFormValuesHandler = useCallback(() => {
    saveFormValues.current = getValues();
  }, [watch()]);

  useEffect(() => {
    setFormValuesHandler();
  }, [setFormValuesHandler]);

  useEffect(() => {
    if (isSetFormFromSavedValues) {
      Object.entries(saveFormValues.current).map(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [isSetFormFromSavedValues]);

  useEffect(() => {
    isFormsValid.current = isValidHookForm;
  }, [isValidHookForm]);
};
