import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const PenIcon = ({ width = 25, height = 25, color: colorProp, hovered, fill, ...restProps }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M14.8441 12.5892L7.9453 19.3151L4.56398 20.6652L3.88293 19.9842L5.23307 16.6029L12.0555 9.78046'
        fill={fill || 'white'}
      />
      <path
        d='M15.1023 12.8272L11.7209 9.44587L17.468 3.69882C18.4 2.76686 19.9174 2.76686 20.8493 3.69882C21.7813 4.63077 21.7813 6.14817 20.8493 7.08013L15.1023 12.8272Z'
        fill={hovered ? defaultFill : fill || 'white'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path d='M4.56398 20.6652L7.9453 19.3151L5.23307 16.6029L3.88293 19.9842L4.56398 20.6652Z' fill={defaultFill} />
      )}
      <path
        d='M12.856 14.4402L7.9453 19.3151L4.56398 20.6652L3.88293 19.9842L5.23307 16.6029L12.0555 9.78046'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.92969 18.2995L7.94528 19.3151'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.22926 20.3188L3.54822 20.9999'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8373 3.71085L16.1058 8.44231'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1417 9.07552C15.9505 9.2667 15.6518 9.2667 15.4606 9.07552C15.2695 8.88435 15.2695 8.58564 15.4606 8.39447C15.6518 8.2033 15.9505 8.2033 16.1417 8.39447C16.3329 8.58564 16.3329 8.8963 16.1417 9.07552Z'
        stroke={color}
        strokeMiterlimit='10'
      />
    </svg>
  );
};
