import React, { useState, useCallback, useEffect, useMemo, ReactElement } from 'react';
import { withRouter, Route, useHistory, useLocation } from 'react-router-dom';
import EnvironmentDetailsTab from './environment-details-tab';
import { getVehicleDetailsAsync } from '../../services/get-vehicle-details';
import ProductLabels from '../../components/product-labels';
import VehicleGallery from './vehicle-gallery';
import Footer from '../../components/footer/footer-view';
import Breadcrumbs from '../../components/breadcrumbs';
import ConsumptionSmallText from '../../components/consumption-small-text';
import classes from './details.module.scss';
import { defaultUrlTree } from '../../components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import BarpreisanfrageDetails from '../../components/barpreisanfragen-component/components/details/details';
import cloneDeep from 'lodash/cloneDeep';
import { Section } from '../existing-vehicle-page/existing-vehicle';
import EditableFieldMain from '../existing-vehicle-page/editable-field-main';
import { vehicleDetailsFields } from '../../models/vehicle-offer-details-fields';
import CustomAccordion from '../../components/custom-accordion/custom-accordion';
import { getEquipmentItems } from '../../services/get-equipment-items';
import { StandardEquipmentItem } from './equipment-tab/equipment-tab-view';
import LeasingComparisonSection from './leasing-comparison-section/leasing-comparison-section';
import {
  CONFIGURATION_TYPES,
  REQUEST_STATUS,
  RequestStatuses,
  USER_ROLES,
  VEHICLE_OFFER_EVENT_DESCRIPTIONS
} from '../../constants';
import {
  getLeasingangebotes,
  getLeasingangebotesForGRV,
  getLeasingangebotesForLeasingcompany,
  getRecalculatedGlobalForDriver
} from '../../components/barpreisanfragen-component/utils';
import { getDataForTable, getResponseFormData, setValue } from '../../utils/get-response-data';
import { decodeBase64 } from '../../utils/decode-encode-base64';
import OrderWidget from './order-widget/order-widget';
import { Button, Distance, Flex, Text } from '../../common/common-components.styled';
import { useOverlay, useQuery } from 'hooks';
import { setItem } from '../../actions/app_action';
import { useDispatch } from 'react-redux';
import { AnyObject, LeasingOffer, ResponseModel } from 'common/interfaces';
import SideMenuRightContainer from 'components/side-menu-right-container/side-menu-right-container';
import { isArray } from 'lodash';
import InfoHint from 'components/info-hint/info-hint-view';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { PAGES } from 'common/enums';
import OrderStatusSection from 'components/order-status-section';
import PanelPlaceholder from 'components/panel-placeholder';
import { AxiosResponse } from 'axios';
import { EventProps, ItemProps } from 'components/product/product-view.props';
import { getSharepointLocation } from 'utils/get-sharepoint-location';
import { parseTags } from 'utils/dynamics-response-parsers';
import { useUserCompaniesReferenceValues } from 'hooks/use-user-companies-reference-values';
import { getReferenceLeasingOffer } from 'pages/details/utils';
import { useModifyURLTree } from 'hooks/use-modify-url-three';
import VehicleOrderRedirect from 'components/vehicle-delivery-redirect';
import { VehicleOrderRedirectTypes } from 'components/vehicle-delivery-redirect/vehicle-order-redirect.types';
import useLatestEvents from 'hooks/use-latest-events';
import useAllRelatedLeasingOffers from 'hooks/react-query/use-all-related-leasing-offers/use-all-related-leasing-offers';
import useOldestEvents from 'hooks/use-oldest-events';
import clsx from 'clsx';
import { regexValue } from 'utils/regex';
import SpinnerContainer from 'components/spinner-container';
import OptionalEquipmentItems from './optional-equipment-items';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import useExportDomElementAsPdf from 'hooks/use-export-dom-element-as-pdf';
import More, { MoreMenuItem } from 'components/more';

export interface EquipmentItemsValuesProps {
  new_herstellercode: string;
  new_name: string;
  uds_parentausstatungid: null;
  new_preis_netto: number | null;
  uds_preis_brutto: number | null;
  new_art: number;
  new_beschreibung: string;
  new_fahrzeugangeboteausstattungid: string;
}

export interface EquipmentItemsProps {
  data: EquipmentItemsValuesProps[];
}

interface FahrzeugSectionHeaderProps {
  data:
    | {
        open: boolean;
        title: string;
      }
    | AnyObject;
}

const StandardEquipmentItems = ({ data }: EquipmentItemsProps) => {
  return (
    <div className={classes.item_container}>
      {data.map((item: EquipmentItemsValuesProps) => (
        <StandardEquipmentItem key={item.new_name} value={item.new_beschreibung} />
      ))}
    </div>
  );
};

const FahrzeugSectionHeader = ({ data }: FahrzeugSectionHeaderProps) => {
  return (
    <div
      className={data.open ? `${classes.section_header} ${classes.section_header_bottom_line}` : classes.section_header}
    >
      <h5>{data.title}</h5>
      <img
        className={data.open ? classes.rotate : ''}
        src='/assets/images/dashboard-expand.svg'
        alt='dashboard-expand'
      />
    </div>
  );
};

export const defaultFahrzeugExpandSections = [
  {
    name: 'Sonderausstattungen',
    header: <FahrzeugSectionHeader data={{}} />,
    body: <OptionalEquipmentItems data={[]} />,
    headerData: {
      title: 'Sonderausstattungen'
    },
    bodyData: [],
    hidden: false,
    open: true
  },
  {
    name: 'Serienausstattungen',
    header: <FahrzeugSectionHeader data={{}} />,
    body: <StandardEquipmentItems data={[]} />,
    headerData: {
      title: 'Serienausstattungen'
    },
    bodyData: [],
    hidden: false,
    open: false
  },
  {
    name: 'Umwelt',
    header: <FahrzeugSectionHeader data={{}} />,
    body: <EnvironmentDetailsTab data={{}} />,
    headerData: {
      title: 'Verbrauch und Umwelt'
    },
    bodyData: {},
    hidden: false,
    open: false
  }
];

const getVehicleSections = (
  isGlobalReferenceVehicle: boolean,
  fahrzeugExpandSections: AnyObject[],
  isReferenzfahrzeugeDetailsPage: boolean,
  isKonfigurationenFahrzeugdetailsPage: boolean
) => {
  fahrzeugExpandSections.forEach(section => {
    if (section.name === 'Sonderausstattungen') {
      section.hidden =
        isGlobalReferenceVehicle && (isReferenzfahrzeugeDetailsPage || isKonfigurationenFahrzeugdetailsPage);
    }
  });

  return fahrzeugExpandSections;
};

const SectionHeader = ({ label, requestNumber, isGlobalReferenceVehicle, exportDomElementAsPdf }: any) => {
  const location = useLocation();
  const isKonfigurationenFahrzeugdetailsPage = location.pathname.includes(PAGES.CONFIGURATION_DETAILS);
  const isMitarbeiterkonfigurationenDetailsPage = location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATION_DETAILS);
  const isDealerDetailsPage = location.pathname.includes(PAGES.REQUESTS_DETAILS);

  const isShowExportAsPDFButton =
    isKonfigurationenFahrzeugdetailsPage || isMitarbeiterkonfigurationenDetailsPage || isDealerDetailsPage;

  return (
    <div className='row'>
      <div style={{ paddingBottom: '20px' }} className='col-md-12'>
        <Flex justify='space-between' align='flex-start'>
          <h3 id='VehicleTitle' className={classes.section_header}>
            {label}
          </h3>
          {isShowExportAsPDFButton && !!exportDomElementAsPdf && (
            <More>
              <MoreMenuItem label='Als PDF exportieren' img={false} onClick={exportDomElementAsPdf} />
            </More>
          )}
        </Flex>

        {requestNumber && (
          <Flex>
            <p id='VehicleNumber' className={classes.car_number}>
              <span>{isGlobalReferenceVehicle ? 'Referenzfahrzeug: ' : 'Angebotsnummer: '}</span>
              <span>{requestNumber}</span>
            </p>
            <Distance side='20' />
            {(isMitarbeiterkonfigurationenDetailsPage || isKonfigurationenFahrzeugdetailsPage) && (
              <VehicleOrderRedirect type={VehicleOrderRedirectTypes.Link} />
            )}
          </Flex>
        )}
      </div>
    </div>
  );
};

export const getLeasingOffersData = (
  leasingOffers: any,
  referenceDistance: number | null,
  referenceDuration: number | null,
  isGlobalReferenceVehicle: boolean,
  isBasedOnGlobalReferenceVehicle: boolean,
  approvedByFleetManagerEvent: EventProps | null
) => {
  const takeReferenceLeasingOffers = (leasingOffer: any) => {
    if (!isGlobalReferenceVehicle && !isBasedOnGlobalReferenceVehicle) {
      return true;
    }

    return (
      leasingOffer.uds_laufleistungpa.attributeValue === referenceDistance &&
      leasingOffer.uds_laufzeit.attributeValue === referenceDuration
    );
  };

  const lowestLeasingFactorOffer = cloneDeep(leasingOffers)
    .filter(takeReferenceLeasingOffers)
    .sort((a: any, b: any) => a.uds_leasingfaktor.attributeValue - b.uds_leasingfaktor.attributeValue)[0];

  const recommendationLeasingOffer =
    leasingOffers.find((offer: any) => offer.uds_isausgewahlt.attributeValue) ||
    cloneDeep(leasingOffers)
      .filter(takeReferenceLeasingOffers)
      .filter(
        (leasingOffer: any) =>
          leasingOffer.uds_leasingfaktor.attributeValue === lowestLeasingFactorOffer?.uds_leasingfaktor.attributeValue
      )
      .sort(
        (a: any, b: any) =>
          a.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value -
          b.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value
      )[0];

  return leasingOffers.map((item: any) => {
    const leasingOffer: any = {};
    if (
      recommendationLeasingOffer?.uds_leasingangeboteid.attributeValue === item.uds_leasingangeboteid.attributeValue
    ) {
      leasingOffer.active = !(isBasedOnGlobalReferenceVehicle && !approvedByFleetManagerEvent);
      leasingOffer.referenceLeasingOffer = true;
      leasingOffer.bestOffer = true;
    }
    Object.entries(item).forEach(([key, value]) => {
      setValue(item, key, value, leasingOffer, true);
    });
    return leasingOffer;
  });
};

interface LeasingDetailsPageProps {
  id: string;
  modalView?: boolean;
}

export const LeasingDetailsPage = ({ id, modalView }: LeasingDetailsPageProps) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const history = useHistory();
  const [vehicleMainFieldsData, setVehicleMainFieldsData] = useState(vehicleDetailsFields);
  const [choosenItem, setChoosenItem] = useState<ItemProps | null>(null);
  const location = useLocation();
  const pathname = location.pathname;
  const [decodedId, setDecodedId] = useState<string | null>(null);
  const isBarpreisanfrage =
    pathname.includes(PAGES.REQUESTS_DETAILS) || pathname.includes(PAGES.LEASING_REQUESTS_DETAILS);
  const isDealerDetailsPage = pathname.includes(PAGES.REQUESTS_DETAILS);
  const isKonfigurationenFahrzeugdetailsPage = pathname.includes(PAGES.CONFIGURATION_DETAILS);
  const isMitarbeiterkonfigurationenDetailsPage = pathname.includes(PAGES.EMPLOYEE_CONFIGURATION_DETAILS);
  const isReferenzfahrzeugeDetailsPage = location.pathname.includes(PAGES.REFERENCE_VEHICLES_DETAILS);
  const isCockpitPage =
    location.pathname.includes(PAGES.GLOBAL_REFERENCE_VEHICLES) || location.pathname.includes(PAGES.COCKPIT_2_0);
  const [fahrzeugExpandSections, setFahrzeugExpandSections] = useState<AnyObject[]>(defaultFahrzeugExpandSections);
  const [leasingOffers, setLeasingOffers] = useState<LeasingOffer[]>([]);
  const [filteredGRVLeasingOffers, setFilteredGRVLeasingOffers] = useState<LeasingOffer[]>([]);
  const [activeLeasingOffer, setActiveLeasingOffer] = useState<any>({});
  const [referenceLeasingOffer, setReferenceLeasingOffer] = useState<any>({});
  const {
    buildAbilityConfirmedEvent,
    approvalRejectedEvent,
    globalReferenceVehicleGeneratedViaAPIEvent,
    approvedByFleetManagerEvent,
    vehicleRequestedEvent
  } = useLatestEvents(choosenItem?.events || []);
  const {
    configurationCreatedEvent,
    vehicleConfigurationBasedOnGRVCreatedEvent,
    offerPublishedForUsersEvent,
    vehicleBasedOnBulkOrderCreatedEvent
  } = useOldestEvents(choosenItem?.events || []);
  const [refreshKey, setRefreshKey] = useState(0);
  const isAdminOrFleetManagerOrDealer = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.dealer.id
  ]);
  const isConfigurator2_0_GRV =
    choosenItem?.uds_configurationtypecode_value === CONFIGURATION_TYPES.configurator_2_0.value;
  const isGlobalReferenceVehicle = !!globalReferenceVehicleGeneratedViaAPIEvent || isConfigurator2_0_GRV;
  const isBulkOrderVehicle =
    !!offerPublishedForUsersEvent &&
    choosenItem?.uds_configurationtypecode_value === CONFIGURATION_TYPES.bulkOrder.value;
  const isBasedOnBulkOrderVehicle = !!vehicleBasedOnBulkOrderCreatedEvent;
  const isBasedOnGlobalReferenceVehicle = !!vehicleConfigurationBasedOnGRVCreatedEvent;
  const isShowLeasingComparisonSection = isBasedOnGlobalReferenceVehicle
    ? isAdminOrFleetManagerOrDealer &&
      !isKonfigurationenFahrzeugdetailsPage &&
      !isReferenzfahrzeugeDetailsPage &&
      (buildAbilityConfirmedEvent || vehicleConfigurationBasedOnGRVCreatedEvent || vehicleRequestedEvent)
    : isAdminOrFleetManagerOrDealer && !isKonfigurationenFahrzeugdetailsPage && !isReferenzfahrzeugeDetailsPage;
  const isLeasingcompanyDetailsPage = pathname.includes(PAGES.LEASING_REQUESTS_DETAILS);
  const isReferenceVehicle = choosenItem?.new_status_anfrage_value === REQUEST_STATUS.newConfiguration;
  const isNoNeededDashboardTitle = useIsUserHaveRole([USER_ROLES.dealer.id, USER_ROLES.leasingcompany.id]);
  const noDetails = useMemo(
    () =>
      configurationCreatedEvent?.uds_eventdescription.attributeValue.id ===
      VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded.id,
    [configurationCreatedEvent]
  );

  const { allRelatedLeasingOffers } = useAllRelatedLeasingOffers(decodedId);
  const { referenceDistance, referenceDuration } = useUserCompaniesReferenceValues();
  const { updateURLTreeChild } = useModifyURLTree(defaultUrlTree);
  const showWidgetContainer = decodedId !== null && !modalView;
  const leasingOffersForComparisonSection =
    isBasedOnGlobalReferenceVehicle &&
    !approvedByFleetManagerEvent &&
    isMitarbeiterkonfigurationenDetailsPage &&
    filteredGRVLeasingOffers.length
      ? filteredGRVLeasingOffers
      : leasingOffers;

  const { exportDomElementAsPdf, elementRef } = useExportDomElementAsPdf<HTMLDivElement>(
    `Fahrzeugdetails_${choosenItem?.uds_name || ''}_${getDateString(new Date())}`
  );

  const getVehicleMainFieldsValue = (
    field: { name: string; fieldName: keyof ItemProps; value: any; tooltipText: string; icon: ReactElement },
    data: ItemProps,
    isGlobalReferenceVehicle: boolean,
    isDealerDetailsPage: boolean,
    isBasedOnGlobalReferenceVehicle: boolean
  ) => {
    if (field.name === 'Leistung') {
      field.value = data[field.fieldName] ? `${data[field.fieldName]} kW` : null;
      return;
    }
    if (field.name === 'Hubraum') {
      field.value = data[field.fieldName] ? `${data[field.fieldName]} ccm` : null;
      return;
    }
    if (field.name === 'Listenpreis (brutto)') {
      if ((isGlobalReferenceVehicle || isBasedOnGlobalReferenceVehicle) && !isCockpitPage) {
        field.value = data[field.fieldName]?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) || null;
        return;
      }
      if (isCockpitPage) {
        field.fieldName = 'uds_listenpreis_value';
        field.value = data[field.fieldName]?.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) || null;
        return;
      }

      field.value =
        data[field.fieldName] &&
        ([100000002, 100000003].includes(data.uds_angebotsstatuscode_value) ||
          isBasedOnBulkOrderVehicle ||
          isBulkOrderVehicle)
          ? data[field.fieldName].toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
          : null;
      return;
    }
    field.value = data[field.fieldName];
  };
  useEffect(() => {
    if (id !== null && !isArray(id)) {
      setDecodedId(id.match(regexValue.guid) ? id : decodeBase64(id));
    }
  }, [id]);

  useEffect(() => {
    const loadData = async () => {
      if (choosenItem && referenceDistance !== undefined && referenceDuration !== undefined && decodedId) {
        const leasingOffersResponse: AxiosResponse<ResponseModel> = await (isLeasingcompanyDetailsPage
          ? getLeasingangebotesForLeasingcompany(decodedId)
          : isGlobalReferenceVehicle
          ? getLeasingangebotesForGRV(decodedId)
          : isBasedOnGlobalReferenceVehicle && !approvedByFleetManagerEvent
          ? getRecalculatedGlobalForDriver(decodedId)
          : getLeasingangebotes(decodedId));

        const anfrageTitle = `Anfrage ${choosenItem.uds_name || ''}`;

        updateURLTreeChild(
          ['fahrzeuganfragen', 'anfrage'],
          {
            breadcrumbTitle: anfrageTitle,
            title: anfrageTitle,
            url: `${PAGES.REQUESTS_DETAILS}/?id=${id}`
          },
          isNoNeededDashboardTitle
        );

        if (isGlobalReferenceVehicle) {
          const detailsTitle = `Referenzfahrzeug - ${choosenItem.uds_title || ''}`;

          updateURLTreeChild(['referenzfahrzeuge', 'fahrzeugdetails'], {
            breadcrumbTitle: choosenItem.uds_name || '',
            title: detailsTitle,
            url: `${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}/?id=${id}`
          });

          updateURLTreeChild(['konfigurationen', 'fahrzeugdetails'], {
            breadcrumbTitle: choosenItem.uds_name || '',
            title: detailsTitle,
            url: `${PAGES.CONFIGURATION_DETAILS}/?id=${id}`
          });
        }

        const newVehicleMainFields = cloneDeep(vehicleMainFieldsData);
        newVehicleMainFields.forEach(section => {
          section.forEach(field => {
            getVehicleMainFieldsValue(
              field as {
                name: string;
                fieldName: keyof ItemProps;
                value: any;
                tooltipText: string;
                icon: ReactElement;
              },
              choosenItem,
              isGlobalReferenceVehicle,
              isDealerDetailsPage,
              isBasedOnGlobalReferenceVehicle
            );
          });
        });

        const leasingOffers = leasingOffersResponse
          ? getLeasingOffersData(
              leasingOffersResponse.data.data,
              referenceDistance,
              referenceDuration,
              isGlobalReferenceVehicle,
              isBasedOnGlobalReferenceVehicle,
              approvedByFleetManagerEvent
            )
          : [];

        setVehicleMainFieldsData(newVehicleMainFields);
        setReferenceLeasingOffer(getReferenceLeasingOffer(leasingOffers));
        setLeasingOffers(leasingOffers);
      }
    };

    loadData();
  }, [choosenItem, referenceDistance, referenceDuration, decodedId]);

  useEffect(() => {
    setFahrzeugExpandSections(
      getVehicleSections(
        isGlobalReferenceVehicle,
        fahrzeugExpandSections,
        isReferenzfahrzeugeDetailsPage,
        isKonfigurationenFahrzeugdetailsPage
      )
    );
  }, [isGlobalReferenceVehicle]);

  const loadData = useCallback(async () => {
    showOverlay();
    if (decodedId && referenceDuration !== undefined && referenceDistance !== undefined) {
      const response: any = await Promise.allSettled([getVehicleDetailsAsync(decodedId), getEquipmentItems(decodedId)]);
      if (response[0].value?.data.level !== RequestStatuses.Success) {
        return;
      }
      const vehicleDetails = (
        getResponseFormData(response[0].value.data, [{ name: null, type: 'array' }], true) as ItemProps[]
      )[0];
      vehicleDetails.sharepointLocation = getSharepointLocation(vehicleDetails);
      vehicleDetails.tags = parseTags(vehicleDetails.uds_tags);
      dispatch(setItem(vehicleDetails));

      const newFahrzeugExpandSections: any = cloneDeep(fahrzeugExpandSections);
      const environmentSection: any = newFahrzeugExpandSections.find((section: any) => section.name === 'Umwelt');
      const optionalEquipmentSection: any = newFahrzeugExpandSections.find(
        (section: any) => section.name === 'Sonderausstattungen'
      );
      const standardEquipmentSection: any = newFahrzeugExpandSections.find(
        (section: any) => section.name === 'Serienausstattungen'
      );
      standardEquipmentSection.bodyData = response[1].value?.data.serienausstattungen || [];
      optionalEquipmentSection.bodyData = response[1].value?.data.sonderausstattungen || [];
      environmentSection.bodyData = vehicleDetails;
      setFahrzeugExpandSections(newFahrzeugExpandSections);
      setChoosenItem(vehicleDetails);
      hideOverlay();
    }
  }, [
    dispatch,
    fahrzeugExpandSections,
    hideOverlay,
    decodedId,
    showOverlay,
    vehicleMainFieldsData,
    referenceDistance,
    referenceDuration
  ]);

  useEffect(() => {
    loadData();
  }, [referenceDuration, referenceDistance, decodedId]);

  useEffect(() => {
    setActiveLeasingOffer(leasingOffers.find((offer: any) => offer.active));
  }, [refreshKey, fahrzeugExpandSections, leasingOffers]);

  const changeActiveLeasingOffer = (offerId: string) => {
    if (leasingOffers.find((offer: LeasingOffer) => offer.uds_isausgewahlt)) {
      return;
    }
    const newLeasingOffers = cloneDeep(leasingOffers);
    const clickedOffer = newLeasingOffers.find((offer: LeasingOffer) => offer.uds_leasingangeboteid === offerId);
    if (clickedOffer?.active) {
      return;
    }
    newLeasingOffers.forEach((offer: LeasingOffer) => {
      offer.active = false;
    });
    if (clickedOffer) {
      clickedOffer.active = true;
    }

    setLeasingOffers(newLeasingOffers);
  };

  const onToggleFahrzeugExpandSections = (sectionName: any) => {
    fahrzeugExpandSections.forEach((section: AnyObject) => {
      if (section.name === sectionName) {
        section.open = !section.open;
        return;
      }
      section.open = false;
    });
    const updatedRefreshKey: number = refreshKey + 1;
    setRefreshKey(updatedRefreshKey);
    setFahrzeugExpandSections(fahrzeugExpandSections);
  };

  const isShowBreadcrumbs =
    !history.location.search.includes('NGU2MGUzMjMtYTBjNi1lYTExLWE4MTItMDAwZDNhYjM5MzA3') &&
    !history.location.search.includes('MzRhZjgzZGEtNjlkMS1lYTExLWE4MTItMDAwZDNhYjhkNWI1') &&
    !history.location.search.includes('YjA0Njg1MDUtMWNjMC1lYTExLWE4MTItMDAwZDNhYjhkZTFl');

  if (modalView && !choosenItem) {
    return <SpinnerContainer minHeight='200px' />;
  }

  return choosenItem ? (
    <>
      <div
        className={
          modalView ? `${classes.details_wrapper} ${classes.details_wrapper_modal_view}` : classes.details_wrapper
        }
      >
        <Route path={PAGES.VEHICLE_OFFER_DETAILS}>{isShowBreadcrumbs && <Breadcrumbs prevLocation={''} />}</Route>
        <div ref={elementRef} className={classes.details_container}>
          <div className={clsx(classes.main_content, !showWidgetContainer && classes.full_width)}>
            <Section>
              <SectionHeader
                label={choosenItem.uds_title}
                requestNumber={choosenItem.uds_name}
                isGlobalReferenceVehicle={isGlobalReferenceVehicle}
                exportDomElementAsPdf={exportDomElementAsPdf}
              />
              {!isBarpreisanfrage && choosenItem.tags && <ProductLabels labels={choosenItem.tags} />}
              <div className={classes.car_container}>
                <div className={classes.gallery_container}>
                  {choosenItem.uds_fahrzeugangeboteid && (
                    <VehicleGallery
                      key={choosenItem.uds_fahrzeugangeboteid}
                      location={choosenItem.sharepointLocation}
                    />
                  )}
                  {!isBarpreisanfrage && <ConsumptionSmallText item={choosenItem} showFANumber={undefined} />}
                </div>
                <div className={classes.fields_container}>
                  {noDetails ? (
                    <>
                      {vehicleMainFieldsData
                        .flat()
                        .filter(field => field.fieldName === 'uds_listenpreis')
                        .map(field => (
                          <div className={classes.main_fields_section} key={field.name}>
                            <EditableFieldMain
                              disableHover={field.disabled}
                              edit={() => {}}
                              cancelEdit={() => {}}
                              save={() => {}}
                              data={field}
                            />
                            <div className='mobexo-divider' />
                          </div>
                        ))}
                      <Text bold color='grey900' margin='30px 0'>
                        Warum sehe ich hier keine Informationen zum Fahrzeug?
                      </Text>
                      <InfoHint type='info'>
                        <p>
                          Da diese Konfiguration nicht über die App erstellt wurde, stehen diese Informationen nicht zur
                          Verfügung.
                        </p>
                      </InfoHint>
                    </>
                  ) : (
                    vehicleMainFieldsData.map((section, i) => (
                      <section key={i} className={classes.fields_section}>
                        {section.map(field => (
                          <div className={classes.main_fields_section} key={field.name}>
                            <EditableFieldMain
                              disableHover={field.disabled}
                              edit={() => {}}
                              cancelEdit={() => {}}
                              save={() => {}}
                              data={field}
                            />
                            <div className='mobexo-divider' />
                          </div>
                        ))}
                      </section>
                    ))
                  )}
                </div>
              </div>
            </Section>

            {!isGlobalReferenceVehicle && !isReferenceVehicle && !isBulkOrderVehicle && !isBasedOnBulkOrderVehicle && (
              <OrderStatusSection events={getDataForTable(choosenItem?.events || [])} />
            )}

            {isShowLeasingComparisonSection && leasingOffersForComparisonSection.length ? (
              <Section id={'leasing-comparison-section'}>
                <SectionHeader label='Leasingvergleich' />
                <LeasingComparisonSection
                  leasingOffers={leasingOffersForComparisonSection}
                  isShowButtons={!!approvedByFleetManagerEvent || !!approvalRejectedEvent || isCockpitPage}
                  changeActiveLeasingOffer={changeActiveLeasingOffer}
                />
              </Section>
            ) : null}

            <Section>
              <SectionHeader label='Fahrzeug' />
              {noDetails ? (
                <PanelPlaceholder
                  title='Keine Informationen zu der Ausstattung vorhanden'
                  description='Da diese Konfiguration nicht über die App erstellt wurde, stehen diese Informationen nicht zur Verfügung.'
                  className={classes.panel_placeholder}
                />
              ) : (
                fahrzeugExpandSections.map(
                  (section: AnyObject, i: number) =>
                    !section.hidden && (
                      <>
                        <CustomAccordion
                          section={section}
                          key={section.name}
                          onToggle={onToggleFahrzeugExpandSections}
                        />
                        {i < fahrzeugExpandSections.length - 1 && (
                          <div className={`mobexo-divider ${classes.divider}`} key={i} />
                        )}
                      </>
                    )
                )
              )}
            </Section>
          </div>

          <div style={{ display: 'none' }} id='leasing-offers'>
            {allRelatedLeasingOffers?.map(leasingOffer => (
              <div
                key={leasingOffer.uds_leasingangeboteid}
                data-leasing-company-name={leasingOffer.new_leasingbank?.name}
                data-leasing-offer-id={leasingOffer.uds_leasingangeboteid}
              >
                {leasingOffer.uds_name}
              </div>
            ))}
          </div>

          {showWidgetContainer && (
            <div className={classes.widgets}>
              {isBarpreisanfrage ? (
                <BarpreisanfrageDetails
                  id={decodedId}
                  configurationCreatedEvent={configurationCreatedEvent}
                  leasingOffers={leasingOffers}
                  refreshLeasingOffers={loadData}
                  events={choosenItem?.events || []}
                />
              ) : (
                <OrderWidget
                  item={choosenItem}
                  refreshData={loadData}
                  leasingOffer={activeLeasingOffer || null}
                  referenceLeasingOffer={referenceLeasingOffer || null}
                  leasingOffers={leasingOffers}
                  changeActiveLeasingOffer={changeActiveLeasingOffer}
                  setFilteredGRVLeasingOffers={setFilteredGRVLeasingOffers}
                  filteredGRVLeasingOffers={filteredGRVLeasingOffers}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  ) : null;
};

const DetailsPage = (props: any) => {
  const query = useQuery();
  const id: string[] | string | null = query.id || null;

  return (
    <SideMenuRightContainer>
      <LeasingDetailsPage {...props} key={id} id={id} />
    </SideMenuRightContainer>
  );
};

export default withRouter(DetailsPage);
