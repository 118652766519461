import { CrmDataTypes } from 'common/enums';
import { useGetDamageByIdQuery } from 'hooks/react-query/damage/get-damage-by-id/get-damage-by-id';
import moment from 'moment';
import { getRequestFormData } from 'utils/get-response-data';
import { useFetchDamageEditForm } from './use-fetch-damage-edit-form/use-fetch-damage-edit-form';
import { AnyObject } from 'common/interfaces';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useCallback } from 'react';
import { SubmitCommonFormAttributesType } from '../../damage-edit-form.props';

export const useSubmitDamageCommonForm = (enabled: boolean) => {
  const formFields = useTypedSelector(state => state.damageDetail.editForm.formFields);

  const { damageData } = useGetDamageByIdQuery(undefined, { options: { enabled } });
  const { fetchHandler } = useFetchDamageEditForm(undefined, { options: { enabled } });

  const submitDamageCommonHandler = useCallback(
    (values: AnyObject) => {
      if (!damageData.incidentid || !damageData.uds_schadenaufnahmeid) {
        return;
      }

      const attributes: SubmitCommonFormAttributesType = {
        updateIncident: {},
        updateSchadenAufnahme: {}
      };

      Object.entries(values).forEach(([key, value]) => {
        const findFormFieldDependingOnName = formFields.find(formFieldItem => formFieldItem.name === key);
        const findFormFieldDependingOnCreateNewItemFormField = formFields.find(
          formFieldItem => formFieldItem?.createNewItemFormField?.name === key
        );

        const formField =
          findFormFieldDependingOnName || findFormFieldDependingOnCreateNewItemFormField?.createNewItemFormField;

        if (!formField) {
          return console.error('formField is not exist.');
        }

        formField.submitCallback && formField.submitCallback(attributes, values);

        const updateType = formField?.updateType || 'updateIncident';

        const modifiedData = { [key]: value };

        if (typeof formField?.isBooleanOptionSet === 'boolean' && formField?.crmFieldType === CrmDataTypes.Boolean) {
          modifiedData[key] = value === 'true';
        }

        if (formField?.crmFieldType === CrmDataTypes.DateTime) {
          modifiedData[key] = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
        }

        attributes[updateType] = {
          ...attributes[updateType],
          ...getRequestFormData(modifiedData, [formField])
        };
      });

      fetchHandler({
        updateIncident: {
          id: damageData.incidentid,
          attributes: attributes.updateIncident
        },
        updateSchadenAufnahme: {
          id: damageData.uds_schadenaufnahmeid,
          attributes: attributes.updateSchadenAufnahme
        }
      });
    },
    [damageData.incidentid, damageData.uds_schadenaufnahmeid, JSON.stringify(formFields), fetchHandler]
  );

  return { submitDamageCommonHandler };
};
