import React from 'react';
import { BarDiagram } from 'components/widgets/bar/bar';
import { useConsumptionWidgetDiagramData } from './hooks/use-diagram-data';

export const ConsumptionDiagram = () => {
  const { diagramData, horizontalLineValue, horizontalLinePlugin } = useConsumptionWidgetDiagramData();

  return (
    <BarDiagram
      flexProps={{ width: '100%', height: '100%', flex: 1 }}
      data={diagramData}
      minBarColumnSize={5}
      diagramPlugins={[{ value: horizontalLineValue, plugin: horizontalLinePlugin }]}
    />
  );
};
