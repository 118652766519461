import React from 'react';
import { IRepairPartnerTable } from 'pages/repair-status/components/repair-status-item/repair-status-item.props';
import { IFormInfo } from './form-info/form-info.props';
import { PhoneIcon } from 'components/icons-new/phone';
import { MailIcon } from 'components/icons-new/mail';
import { LocationIcon } from 'components/icons-new/location';

export const getFormInfoData = (data: Partial<IRepairPartnerTable>): IFormInfo[] => [
  { title: 'Firma', value: data.new_repairpartnerid },
  {
    title: 'Telefon',
    value: data.telephone1,
    href: `tel:${data.telephone1}`,
    isClickable: true,
    icon: <PhoneIcon />
  },
  {
    title: 'E-mail',
    value: data.emailaddress1,
    href: `mailto:${data.emailaddress1}`,
    isClickable: true,
    icon: <MailIcon />
  },
  {
    title: 'Adresse',
    value: data.composit_adress,
    href: `http://maps.google.com/?q=${data.composit_adress}`,
    isClickable: true,
    icon: <LocationIcon />
  }
];
