import { AxiosRequestConfig } from 'axios';
import {
  CompanyRequestProps,
  DeactivateContractData,
  GetChildCompaniesData,
  GetContractsPaginationListData,
  UpdateCompanyData,
  UpdateControlPauseInfoProps,
  CompaniesByTypesProps
} from './company.types';

class CompanyRequestConfig {
  getDriverReport = ({ pagination, searchParam, sortParam }: CompanyRequestProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/driverReport',
    responseType: 'blob',
    data: {
      pagination,
      searchParam,
      sortParam
    }
  });

  companiesByTypes = (data: CompaniesByTypesProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/companiesbytypes',
    data
  });

  getCarDealerships = (columns: string[]): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/getAutohausen',
    data: { columns }
  });

  updateCompany = (data: UpdateCompanyData): AxiosRequestConfig => ({
    method: 'PUT',
    url: 'company/update',
    data
  });

  getChildCompanies = (data: GetChildCompaniesData): AxiosRequestConfig => ({
    method: 'post',
    url: 'company/GetChildCompanies',
    data
  });

  getAllCompany = (accountId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/getAllCompany',
    data: {
      accountId
    }
  });

  getParentCompanyContactList = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/getParentCompanyContactList',
    data: {}
  });

  costCenterList: AxiosRequestConfig = {
    method: 'get',
    url: 'company/kostenstellelist'
  };

  updateControlPauseInfo = (props: UpdateControlPauseInfoProps): AxiosRequestConfig => {
    const { id, ...attributes } = props;
    return {
      method: 'post',
      url: 'company/UpdateSteueungpauseInfo',
      data: { steueungpauseInfo: { entityName: 'account', id, attributes } }
    };
  };

  getContractsPaginationList = (data: GetContractsPaginationListData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'company/CompanyRahmenvertrage',
    data
  });

  getContractsFilters: AxiosRequestConfig = {
    method: 'POST',
    url: 'company/CompanyRahmenvertrageFilters',
    data: {}
  };

  deactivateContract = (data: DeactivateContractData): AxiosRequestConfig => ({
    method: 'PATCH',
    url: 'company/RahmenvertrageDeactivate',
    data
  });
}

export const companyRequestConfig = new CompanyRequestConfig();
