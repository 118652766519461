import { IconProps } from 'common/interfaces';
import React from 'react';

export const StarCarIcon = ({ width = 25, height = 24, color = '#335566', className, hovered }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 25 24'
    className={className}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    {hovered && (
      <path
        d='M20.1981 10.1356C20.3214 10.462 20.3867 10.8103 20.3867 11.1657V11.5792V16.0625H8.48208V17.2232C8.48208 17.5424 8.22092 17.8036 7.90172 17.8036H5.58029C5.26109 17.8036 4.99993 17.5424 4.99993 17.2232V15.7143L4.76779 14.6044C4.73152 14.4157 4.70975 14.2126 4.70975 14.0167V11.1657C4.70975 10.8175 4.77505 10.462 4.89837 10.1356L5.18129 9.38838H3.83922C3.6506 9.38838 3.51277 9.21427 3.55629 9.03291L3.77393 8.09708C3.83922 7.83592 4.07137 7.64731 4.33978 7.64731H5.76165L6.87885 5.42019C7.17628 4.82532 7.7784 4.45534 8.43856 4.45534H16.6579C17.318 4.45534 17.9202 4.83257 18.2176 5.42019L19.3348 7.64731H20.7567C21.0251 7.64731 21.2645 7.83592 21.3225 8.09708L21.5401 9.03291C21.5837 9.21427 21.4458 9.38838 21.2572 9.38838H19.9151L20.1981 10.1356Z'
        fill='white'
      />
    )}
    {hovered && (
      <path
        d='M20.1981 10.1356C20.3214 10.462 20.3867 10.8103 20.3867 11.1657V11.5792V16.0625H8.48208V17.2232C8.48208 17.5424 8.22092 17.8036 7.90172 17.8036H5.58029C5.26109 17.8036 4.99993 17.5424 4.99993 17.2232V15.7143L4.76779 14.6044C4.73152 14.4157 4.70975 14.2126 4.70975 14.0167V11.1657C4.70975 10.8175 4.77505 10.462 4.89837 10.1356L5.18129 9.38838H3.83922C3.6506 9.38838 3.51277 9.21427 3.55629 9.03291L3.77393 8.09708C3.83922 7.83592 4.07137 7.64731 4.33978 7.64731H5.76165L7.52042 8.82298H17.5781L19.3348 7.64731H20.7567C21.0251 7.64731 21.2645 7.83592 21.3225 8.09708L21.5401 9.03291C21.5837 9.21427 21.4458 9.38838 21.2572 9.38838H19.9151L20.1981 10.1356Z'
        fill='#E6F7FF'
      />
    )}
    <path
      d='M7.32136 16.0625H8.48208V17.2232C8.48208 17.5424 8.22092 17.8036 7.90172 17.8036H5.58029C5.26109 17.8036 4.99993 17.5424 4.99993 17.2232V15.7143L4.76779 14.6044C4.73152 14.4157 4.70975 14.2126 4.70975 14.0167V11.1657C4.70975 10.8175 4.77505 10.462 4.89837 10.1356L5.18129 9.38838H3.83922C3.6506 9.38838 3.51277 9.21427 3.55629 9.03291L3.77393 8.09708C3.83922 7.83592 4.07137 7.64731 4.33978 7.64731H5.76165L6.87885 5.42019C7.17628 4.82532 7.7784 4.45534 8.43856 4.45534H16.6579C17.318 4.45534 17.9202 4.83257 18.2176 5.42019L19.3348 7.64731H20.7567C21.0251 7.64731 21.2645 7.83592 21.3225 8.09708L21.5401 9.03291C21.5837 9.21427 21.4458 9.38838 21.2572 9.38838H19.9151L20.1981 10.1356C20.3214 10.462 20.3867 10.8103 20.3867 11.1657V11.5792'
      stroke={color}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.45813 12.5804H8.87387L8.46037 11.7606'
      stroke={color}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.6189 8.80803H17.485'
      stroke={color}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    {hovered && <circle cx='16.3025' cy='15.4847' r='4.065' fill='white' />}
    <path
      d='M8.45312 16.0625H12.1515'
      stroke={color}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.1135 16.677L17.2875 17.4226C17.3651 17.4715 17.4646 17.4007 17.441 17.313L17.0817 15.9872L18.168 15.1218C18.2405 15.0645 18.2017 14.9498 18.1089 14.9447L16.7123 14.8705L16.2096 13.5903C16.1759 13.5042 16.0527 13.5042 16.019 13.5903L15.5164 14.8705L14.1197 14.9447C14.0269 14.9498 13.9881 15.0645 14.0607 15.1218L15.147 15.9872L14.7877 17.313C14.7641 17.4007 14.8636 17.4715 14.9412 17.4226L16.1135 16.677Z'
      fill={color}
    />
    <path
      d='M12.3472 14.1879C12.8889 12.5819 14.4062 11.4221 16.1953 11.4221C18.437 11.4221 20.2579 13.243 20.2579 15.4847C20.2579 17.7263 18.437 19.5472 16.1953 19.5472C14.7994 19.5472 13.5655 18.8395 12.8339 17.7645'
      stroke={color}
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
