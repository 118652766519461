import React from 'react';
import { Flex } from 'common/common-components.styled';
import { DiagramWithBottomSegmentsContainer } from 'components/containers/diagram/bottom-segments/bottom-segments';
import { DrivingCarIcon } from 'components/icons-new/driving-car';
import { InfoHintAddingInformationToFinanceRate } from '../../../info-hint/adding-information-to-finance-rate/adding-information-to-finance-rate';
import { CurrentKilometerReading } from '../../../current-kilometer-reading/current-kilometer-reading';

export const SomeOfLeasingDataIsNotAvailable = () => {
  return (
    <DiagramWithBottomSegmentsContainer styledFlex={{ align: 'center' }} segments={[]}>
      <Flex justify='center' flex='1'>
        <DrivingCarIcon />
      </Flex>

      <Flex direction='column' flex='2' left='13'>
        <CurrentKilometerReading editFormProps={{ bottom: '10' }} />
        <InfoHintAddingInformationToFinanceRate text='Es fehlen Infos über Leasingstart und Leasingende sowie über Laufleistung. Bitte ergänze diese in der Finanzrate, damit wir die Projektion berechnen können.' />
      </Flex>
    </DiagramWithBottomSegmentsContainer>
  );
};
