import { Flex } from 'common/common-components.styled';
import CircleInfoIcon from 'components/icons/warning-icon';
import React from 'react';
import { WidgetWrapperProps } from './widget-wrapper.types';

const WidgetWrapper: React.FC<WidgetWrapperProps> = ({ children, showInfoIcon = true }) => {
  return (
    <Flex direction='column' width='100%'>
      <Flex align='flex-start' gap='10px'>
        {showInfoIcon && (
          <Flex>
            <CircleInfoIcon />
          </Flex>
        )}

        <Flex width='100%'>{children}</Flex>
      </Flex>
    </Flex>
  );
};

export default WidgetWrapper;
