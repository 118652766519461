import React from 'react';
import { StyledDiagramContainer } from '../diagram.styled';
import { DiagramContainerWithBottomSegmentsProps } from './bottom-segments.props';
import { Flex } from 'common/common-components.styled';
import { SegmentItem } from '../segment-item/segment-item';

export const DiagramWithBottomSegmentsContainer = ({
  segments,
  children,
  styledFlex,
  segmentContainerFlex,
  ...restContainerProps
}: DiagramContainerWithBottomSegmentsProps) => {
  return (
    <StyledDiagramContainer {...restContainerProps}>
      <Flex {...styledFlex}>{children}</Flex>

      {!!segments.length && (
        <Flex justify='center' gap='0 15px' top='20' {...segmentContainerFlex}>
          {segments.map(item => (
            <SegmentItem key={item.label} {...item} />
          ))}
        </Flex>
      )}
    </StyledDiagramContainer>
  );
};
