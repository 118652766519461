function toTons(amount: number | null | undefined): string {
  if (!amount) {
    return '0 t';
  }

  const value = amount || 0;
  return (
    value.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    }) + ' t'
  );
}

export default toTons;
