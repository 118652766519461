import React from 'react';
import { IconProps } from 'common/interfaces';

const ArrowDown = ({ color = '#102F3F', className, width = '20', height = '21' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 8.10636L10.0778 15.3552L3 7.94346'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowDown;
