import React, { useState } from 'react';
import { Flex, Text, Button, Distance } from 'common/common-components.styled';
import ThreeStateCheckbox from 'components/three-state-checkbox/three-state-checkbox';
import { PrefixForId } from '../../../../common/enums';

interface CancelTaskProps {
  title: string;
  isTypeAppraise: boolean;
  onCancelTask: (isChecked: boolean) => void;
  onCloseAlert: () => void;
}

const CancelTask = ({ title, isTypeAppraise, onCancelTask, onCloseAlert }: CancelTaskProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const MODAL_BUTTON_ID_PREFIX = PrefixForId.Modal + PrefixForId.Button;

  const handleSubmit = () => {
    onCancelTask(isChecked);
  };

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Flex direction='column'>
      <Text bottom='5' color='black'>{`Bist du sicher, dass du die Aufgabe “${title}” stornieren möchtest?`}</Text>
      {!isTypeAppraise && (
        <Flex align='center'>
          <ThreeStateCheckbox
            indeterminate={false}
            checked={isChecked}
            onChange={handleCheckbox}
            prefixId={PrefixForId.Modal}
          />
          <Text left='5' color='black'>
            Zukünftig nicht mehr fragen
          </Text>
        </Flex>
      )}
      <Flex justify='flex-end'>
        <Button onClick={onCloseAlert} secondary id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Close}>
          Abbrechen
        </Button>
        <Distance side='10' />
        <Button onClick={handleSubmit} id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Submit}>
          Stornieren
        </Button>
      </Flex>
    </Flex>
  );
};

export default CancelTask;
