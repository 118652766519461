/* eslint-disable linebreak-style */
import React from 'react';
import { Text, Distance } from 'common/common-components.styled';

interface IsMainCarI {
  isMainCar: boolean;
  mainCarNumber: string | null;
  activeCarNumber: string;
}

const ChangeMainCarModal = ({ isMainCar, mainCarNumber, activeCarNumber }: IsMainCarI) => {
  return (
    <div>
      {mainCarNumber ? (
        isMainCar ? (
          <Text>
            Das Fahrzeug <b>{activeCarNumber}</b> wird nicht mehr als Hauptauto angezeigt.
          </Text>
        ) : (
          <Text>
            Dein Hauptfahrzeug wechselt von <b>{mainCarNumber}</b> zu <b>{activeCarNumber}</b>.
          </Text>
        )
      ) : (
        <Text>
          Das Fahrzeug <b>{activeCarNumber}</b> wird jetzt als Hauptauto angezeigt.
        </Text>
      )}

      <Distance top='30' />

      {mainCarNumber ? (
        isMainCar ? (
          <Text>Bist du sicher, dass du kein Hauptfahrzeug definieren möchtest?</Text>
        ) : (
          <Text>Willst du diese Änderung übernehmen?</Text>
        )
      ) : (
        <Text>Bist du sicher, dass du Hauptfahrzeug definieren möchtest?</Text>
      )}
    </div>
  );
};

export default ChangeMainCarModal;
