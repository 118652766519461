import React, { useMemo } from 'react';
import { useOverlay } from 'hooks';
import { RecordStatuses } from './bookings-page';
import More, { MoreMenuItem } from 'components/more';
import { MenuItem } from './pool-car-booking-types';
import { AnyObject } from 'common/interfaces';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { useDispatch } from 'react-redux';
import { setPoolCalendarEventTooltip } from 'actions/app_action';
import { PenIcon } from 'components/icons-new/pen';
import usePoolCarBookingModals from 'components/pool-car-booking/hooks/use-pool-car-booking-modals';
import { Booking } from 'components/pool-car-booking/edit-booking/edit-booking.types';
import cloneDeep from 'lodash/cloneDeep';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { UpdateBookingRequestData } from 'request-config/pool-calendar/pool-calendar.types';
import { getRequestAttributes } from 'utils/get-request-attributes';
import { CrmDataTypes } from 'common/enums';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../constants';

interface Props {
  row: AnyObject;
  refetch: () => void;
  isTooltipAction?: boolean;
}

const BookingMoreMenu = ({ row, refetch, isTooltipAction }: Props) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const { uds_status_code } = row;
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isActiveOrder = [
    RecordStatuses.APPROVED_RECORD,
    RecordStatuses.OPENED_RECORD,
    RecordStatuses.REJECTED_RECORD
  ].includes(uds_status_code);
  const dispatch = useDispatch();
  const { fetch } = useFetch({ loadingKey: 'updatePoolCarBooking', isShowSuccessSnackbar: true });

  const { openEditingModal } = usePoolCarBookingModals();

  const updateRecordStatus = async (id: string, statusCode: number) => {
    showOverlay();
    if (isTooltipAction) {
      dispatch(setPoolCalendarEventTooltip(null));
    }

    const requestData: UpdateBookingRequestData = {
      id,
      attributes: getRequestAttributes(updateBookingAttributes, { uds_status: statusCode })
    };

    await fetch({ requestConfig: poolCalendarRequestConfig.updatePoolCarBooking(requestData) });
    await refetch();
    hideOverlay();
  };

  const menuItems = useMemo(() => {
    const items: AnyObject = [
      {
        label: 'Freigeben',
        icon: <img src='/assets/images/success-arrow.svg' alt='success-arrow' />,
        onClick: async () => await updateRecordStatus(row.uds_poolkalenderid, RecordStatuses.APPROVED_RECORD)
      },
      {
        label: 'Ablehnen',
        icon: <img src='/assets/images/red-cross.svg' alt='red-cross' />,
        onClick: async () => await updateRecordStatus(row.uds_poolkalenderid, RecordStatuses.REJECTED_RECORD)
      },
      {
        label: 'Stornieren',
        icon: <img src='/assets/images/red-trash-bin.svg' alt='red-trash' />,
        onClick: async () => await updateRecordStatus(row.uds_poolkalenderid, RecordStatuses.CANCELED_RECORD)
      }
    ];

    if (row.isAllowEdit !== false) {
      items.splice(2, 0, {
        label: 'Bearbeiten',
        icon: <PenIcon width={20} height={20} />,
        onClick: () => openEditingModal(cloneDeep(row as Booking))
      });
    }

    return items;
  }, [row]);

  const getQuickActions = () => {
    if (!isDriver && uds_status_code === RecordStatuses.OPENED_RECORD) {
      return (
        <More>
          {menuItems.map((menuItem: MenuItem) => (
            <MoreMenuItem
              key={menuItem.label}
              label={menuItem.label}
              isLeftPositionImg
              img={menuItem.icon}
              onClick={menuItem.onClick}
            />
          ))}
        </More>
      );
    }

    return (
      <More>
        {menuItems.slice(row.isAllowEdit !== false ? -2 : -1).map((menuItem: MenuItem) => (
          <MoreMenuItem
            key={menuItem.label}
            label={menuItem.label}
            isLeftPositionImg
            img={menuItem.icon}
            onClick={menuItem.onClick}
          />
        ))}
      </More>
    );
  };

  return <>{isActiveOrder && getQuickActions()}</>;
};

export const updateBookingAttributes = {
  uds_abholdatum: {
    attributeTypeCode: CrmDataTypes.DateTime
  },
  uds_ruckgabedatum: {
    attributeTypeCode: CrmDataTypes.DateTime
  },
  uds_status: {
    attributeTypeCode: CrmDataTypes.Picklist
  },
  uds_kilometervoraussichtlich: {
    attributeTypeCode: CrmDataTypes.Integer
  },
  uds_fuhrpark: {
    attributeTypeCode: CrmDataTypes.Lookup
  }
};

export default BookingMoreMenu;
