import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';
import { useTheme } from 'styled-components';

export const DrivingCarIcon = ({ width = 72, height = 61, color: colorProp, fill: fillProp }: IconProps) => {
  const { defaultFill } = useDefaultIconsColor();

  const theme = useTheme();
  const color = colorProp || theme.colors.midBlue;
  const fill = fillProp || defaultFill;

  return (
    <svg
      id='drivingCarIcon'
      width={width}
      height={height}
      viewBox='0 0 72 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36 58.38V51.3288M36 45.7425V42.1062'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M51.811 36.8351H48.2976V41.2276C48.2976 41.7548 48.649 42.1062 49.176 42.1062H57.9594C58.4864 42.1062 58.8377 41.7548 58.8377 41.2276V35.7808L59.5404 32.4425C59.6282 31.8275 59.7161 31.3004 59.7161 30.6854V22.076C59.7161 21.0217 59.5404 19.9675 59.1012 18.9133L58.2229 16.6292H62.0876C62.6146 16.6292 63.0538 16.1021 62.9659 15.5749L62.2632 12.7637C62.0876 11.973 61.3849 11.4459 60.5944 11.4459H56.554L53.2163 4.68133C52.338 2.92429 50.4935 1.78223 48.4733 1.78223H23.6163C21.5961 1.78223 19.7516 2.92429 18.8732 4.68133L15.5356 11.4459H11.4073C10.6168 11.4459 9.91417 11.973 9.73851 12.7637L9.03582 15.5749C8.94798 16.1021 9.29932 16.6292 9.91416 16.6292H13.7789L12.9005 18.9133C12.5492 19.8797 12.2857 20.9339 12.2857 22.076V30.6854C12.2857 31.3004 12.3735 31.9154 12.4614 32.4425L13.164 35.7808V41.2276C13.164 41.7548 13.5154 42.1062 14.0424 42.1062H22.8258C23.3528 42.1062 23.7041 41.7548 23.7041 41.2276V36.8351H20.1907'
        fill='white'
      />
      <path
        d='M51.811 36.8351H48.2976V41.2276C48.2976 41.7548 48.649 42.1062 49.176 42.1062H57.9594C58.4864 42.1062 58.8377 41.7548 58.8377 41.2276V35.7808L59.5404 32.4425C59.6282 31.8275 59.7161 31.3004 59.7161 30.6854V22.076C59.7161 21.0217 59.5404 19.9675 59.1012 18.9133L58.2229 16.6292H62.0876C62.6146 16.6292 63.0538 16.1021 62.9659 15.5749L62.2632 12.7637C62.0876 11.973 61.3849 11.4459 60.5944 11.4459H56.554L53.2163 4.68133C52.338 2.92429 50.4935 1.78223 48.4733 1.78223H23.6163C21.5961 1.78223 19.7516 2.92429 18.8732 4.68133L15.5356 11.4459H11.4073C10.6168 11.4459 9.91417 11.973 9.73851 12.7637L9.03582 15.5749C8.94798 16.1021 9.29932 16.6292 9.91416 16.6292H13.7789L12.9005 18.9133C12.5492 19.8797 12.2857 20.9339 12.2857 22.076V30.6854C12.2857 31.3004 12.3735 31.9154 12.4614 32.4425L13.164 35.7808V41.2276C13.164 41.7548 13.5154 42.1062 14.0424 42.1062H22.8258C23.3528 42.1062 23.7041 41.7548 23.7041 41.2276V36.8351H20.1907'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M48.2976 41.2276V36.8351H23.7041V41.2276C23.7041 41.7548 23.3528 42.1062 22.8258 42.1062H14.0424C13.5154 42.1062 13.164 41.7548 13.164 41.2276V35.7808L12.4614 32.4425C12.3735 31.9154 12.2857 31.3004 12.2857 30.6854V22.076C12.2857 20.9339 12.5492 19.8797 12.9005 18.9133L13.7789 16.6292H9.91416C9.29932 16.6292 8.94798 16.1021 9.03582 15.5749L9.73851 12.7637C9.91417 11.973 10.6168 11.4459 11.4073 11.4459H15.5356L21.1233 13.9802H50.8587L56.554 11.4459H60.5944C61.3849 11.4459 62.0876 11.973 62.2632 12.7637L62.9659 15.5749C63.0538 16.1021 62.6146 16.6292 62.0876 16.6292H58.2229L59.1012 18.9133C59.5404 19.9675 59.7161 21.0218 59.7161 22.076V30.6854C59.7161 31.3004 59.6282 31.8275 59.5404 32.4425L58.8377 35.7808V41.2276C58.8377 41.7548 58.4864 42.1062 57.9594 42.1062H49.176C48.649 42.1062 48.2976 41.7548 48.2976 41.2276Z'
        fill={fill}
      />
      <path
        d='M51.8112 36.835H48.2978V41.2276C48.2978 41.7547 48.6491 42.1061 49.1762 42.1061H57.9596C58.4866 42.1061 58.8379 41.7547 58.8379 41.2276V35.7808L59.5406 32.4424C59.6284 31.8274 59.7163 31.3003 59.7163 30.6853V22.0758C59.7163 21.0216 59.5406 19.9674 59.1014 18.9131L58.2231 16.629H62.0878C62.6148 16.629 63.054 16.1019 62.9662 15.5748L62.2635 12.7635C62.0878 11.9728 61.3851 11.4457 60.5946 11.4457H56.5542L53.2165 4.6811C52.3382 2.92406 50.4937 1.78198 48.4735 1.78198H23.6163C21.5961 1.78198 19.7516 2.92406 18.8733 4.6811L15.5356 11.4457H11.4074C10.6168 11.4457 9.91418 11.9728 9.73851 12.7635L9.03582 15.5748C8.94798 16.1019 9.29932 16.629 9.91416 16.629H13.7789L12.9006 18.9131C12.5492 19.8795 12.2857 20.9337 12.2857 22.0758V30.6853C12.2857 31.3003 12.3735 31.9153 12.4614 32.4424L13.164 35.7808V41.2276C13.164 41.7547 13.5154 42.1061 14.0424 42.1061H22.8258C23.3528 42.1061 23.7042 41.7547 23.7042 41.2276V36.835H20.1908'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.8262 36.8351H50.0548'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.5566 24.5357H24.8469L23.6172 22.0758'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M54.4455 24.5357H47.1553L48.3849 22.0758'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M28.9766 30.6853H43.0301'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M21.0703 13.9934H50.934'
        stroke={color}
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1 59.218L7.55465 38.8641'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M71 59.218L64.4454 38.8641'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
