import { DialogTitle, IconButton } from '@mui/material';
import { Text } from 'common/common-components.styled';
import styled, { css } from 'styled-components';

export const LetterModalTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.blue};
  z-index: 110;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const HeaderToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;

  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const GeneralInfoWrapper = styled.div`
  display: flex;
  min-width: 600px;
  align-items: center;
  height: 62px;
  border-bottom: ${({ theme }) => `.5px solid ${theme.colors.grey500}`};
`;

export const OpenSansLabel = styled(Text)<{ ellipsis?: boolean }>`
  font-family: Open Sans;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;

export const CustomBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const LetterToggleButtonContainer = styled.div<{ isNextButton?: boolean }>`
  position: absolute !important;
  top: 50%;
  right: 30px;
  ${({ isNextButton }) =>
    isNextButton &&
    css`
      right: 30px;

      @media (max-width: ${({ theme }) => theme.media.laptop}) {
        right: 10px;
      }

      @media (max-width: 650px) {
        bottom: 15px;
        top: initial;
        right: 40%;
      }

      @media (max-width: 450px) {
        bottom: 15px;
        top: initial;
        right: 35%;
      }
    `};

  ${({ isNextButton }) =>
    !isNextButton &&
    css`
      left: 30px;

      @media (max-width: ${({ theme }) => theme.media.laptop}) {
        left: 10px;
      }

      @media (max-width: 650px) {
        bottom: 15px;
        top: initial;
        left: 40%;
      }

      @media (max-width: 450px) {
        bottom: 15px;
        top: initial;
        left: 35%;
      }
    `};
`;
