/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from './damage-type-choose.module.scss';
import { Distance } from 'common/common-components.styled';
import { damageTypes } from '../utils';
import ArrowLeftIcon from '../icons/arrow-left';
import ArrowDownIcon from '../icons/arrow-down';
import { CSSTransition } from 'react-transition-group';
import slideDamagePartChoose from '../transition/slide-choose-damagetype.module.css';
import slideReturn from '../transition/return-slide-damagetype.module.css';
import { savedObject } from '../utils';
import { UseFormProps } from '../report-damage.props';
import { DamagePartProps, DamageTypeItemProps } from './damage-type.props';

const DamagePart = ({ reactHookFormData, element, setShowBlock }: DamagePartProps) => {
  const { watch, setValue } = reactHookFormData;
  const resetObject = Object.keys(watch());

  const setDamageType = () => {
    setValue('direction', 'forward');
    setShowBlock(false);
    if (watch('damageTypePrev') !== element.name) {
      setValue('previousStep', 2);
      resetObject.forEach((el: string) => {
        savedObject.find((item: string) => item === el) ? el : setValue(el, undefined);
      });
      setValue('damageList', watch('staticDamageList'));
    }
    if (watch('previousStep') === 'detail' && watch('damageType') === element.name) {
      setValue('previousStep', 2);
      setTimeout(() => {
        setValue('step', 'detail');
      }, 500);
      return;
    }
    setValue('previousStep', 2);
    setTimeout(() => {
      setValue('damageType', element.name);
      setValue('step', undefined);
    }, 500);
  };

  return (
    <div
      className={watch('damageTypePrev') === element.name ? styles.damage_part_choosen : styles.damage_part}
      id={`${element.name}Button`}
      onClick={setDamageType}
    >
      {element.icon}
      <p>{element.name}</p>
    </div>
  );
};

const DamageTypeChoose = ({ reactHookFormData }: UseFormProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const { watch, setValue } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    setValue('neededStep', null);
  }, []);

  const moveToBackStep = () => {
    setShowBlock(false);
    setValue('previousStep', 2);
    setValue('direction', 'back');
    setTimeout(() => setValue('step', 1), 500);
  };

  let slideClassName =
    watch('previousStep') === 1 || watch('direction') === 'forward' ? slideDamagePartChoose : slideReturn;

  useEffect(() => {
    if (damageTypes.slice(7).find((item: DamageTypeItemProps) => item.name === watch('damageTypePrev'))) {
      setShowMore(true);
    }
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>
      <CSSTransition in={showBlock} timeout={800} classNames={slideClassName} mountOnEnter unmountOnExit>
        <section className={styles.container}>
          <div className={watch('previousStep') === 'detail' ? styles.header_small_center : styles.header_small}>
            {watch('previousStep') !== 'detail' && (
              <div id='moveToBackStepButton' className={styles.back_item} onClick={moveToBackStep}>
                <ArrowLeftIcon />
                <div className={styles.back_text}>Zurück</div>
              </div>
            )}
            <div className={styles.header_center}>Bitte wähle die Art des Schadens</div>
          </div>
          <Distance top='30' />
          <div className={styles.damage_row}>
            {damageTypes.slice(0, 7).map((el: DamageTypeItemProps) => (
              <DamagePart
                reactHookFormData={reactHookFormData}
                setShowBlock={setShowBlock}
                key={el.name}
                element={el}
              />
            ))}

            {!showMore && (
              <div id='showMoreDamagePartsButton' className={styles.more_options} onClick={() => setShowMore(true)}>
                <p>Weitere Schadenarten</p>
                <ArrowDownIcon />
              </div>
            )}

            {showMore &&
              damageTypes
                .slice(7)
                .map((el: DamageTypeItemProps) => (
                  <DamagePart
                    reactHookFormData={reactHookFormData}
                    setShowBlock={setShowBlock}
                    key={el.name}
                    element={el}
                  />
                ))}
          </div>
        </section>
      </CSSTransition>
    </div>
  );
};

export default DamageTypeChoose;
