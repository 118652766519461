import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CameraIcon = ({ width = 24, height = 24, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <>
          <path
            d='M17.0828 6.62784H20.7948C21.4335 6.62784 21.9561 7.15053 21.9561 7.78938V17.9529C21.9561 18.5917 21.4335 19.1144 20.7948 19.1144H20.1418'
            fill={fill}
          />
          <path
            d='M17.0828 6.62784H20.7948C21.4335 6.62784 21.9561 7.15053 21.9561 7.78938V17.9529C21.9561 18.5917 21.4335 19.1144 20.7948 19.1144H20.1418H5.11736C4.47864 19.1144 3.95605 18.5917 3.95605 17.9529V7.78938C3.95605 7.15053 4.47864 6.62784 5.11736 6.62784H17.0828Z'
            fill={fill}
          />
        </>
      )}
      <path
        d='M10.3438 6.62783L11.7373 4.76937C11.9696 4.47898 12.2889 4.30475 12.6664 4.30475H16.7309C17.1084 4.30475 17.4277 4.47898 17.66 4.76937L19.0536 6.62783'
        fill='white'
      />
      <path
        d='M10.3438 6.62783L11.7373 4.76937C11.9696 4.47898 12.2889 4.30475 12.6664 4.30475H16.7309C17.1084 4.30475 17.4277 4.47898 17.66 4.76937L19.0536 6.62783'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.0828 6.62784H20.7947C21.4335 6.62784 21.9561 7.15053 21.9561 7.78938V17.9529C21.9561 18.5917 21.4335 19.1144 20.7947 19.1144H20.1418M9.17014 19.1144H5.11736C4.47864 19.1144 3.95605 18.5917 3.95605 17.9529V7.78938C3.95605 7.15053 4.47864 6.62784 5.11736 6.62784H12.059'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.98828 5.46632H7.73024'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.35906 8.95095C6.35906 8.99565 6.32286 9.03172 6.27841 9.03172C6.23396 9.03172 6.19775 8.99565 6.19775 8.95095C6.19775 8.90625 6.23396 8.87018 6.27841 8.87018C6.32286 8.87018 6.35906 8.90625 6.35906 8.95095Z'
        fill={color}
        stroke={color}
      />
      <circle cx='14.6996' cy='14.0327' r='5.6625' fill='white' />
      <path
        d='M9.93712 10.9836C9.35647 11.8548 9.03711 12.9292 9.03711 14.0327C9.03711 17.1689 11.563 19.6952 14.6985 19.6952C17.834 19.6952 20.3599 17.1689 20.3599 14.0327C20.3599 10.8965 17.834 8.37018 14.6985 8.37018C13.6533 8.37018 12.6952 8.66056 11.8533 9.15422'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      {hovered && <circle cx='14.6997' cy='14.0327' r='3.3975' fill={fill} />}
      <path
        d='M13.334 10.9256C13.7405 10.7514 14.205 10.6352 14.6986 10.6352C16.5857 10.6352 18.0954 12.1452 18.0954 14.0327C18.0954 15.9202 16.5857 17.4302 14.6986 17.4302C12.8115 17.4302 11.3018 15.9202 11.3018 14.0327C11.3018 13.452 11.4469 12.9293 11.7082 12.4356'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
