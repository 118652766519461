import React from 'react';
import { DataQualityChartProps } from './data-quality-chart.types';
import { Text } from 'common/common-components.styled';
import { Doughnut } from 'react-chartjs-2';
import { useDataQualityChart } from './use-data-quality-chart';
import { QualityLabel, TextContainer, TextWrapper, WidgetContainer } from './data-quality-chart.styled';

const DataQualityChart = ({ dataQuality }: DataQualityChartProps) => {
  const { qualities, chartData, options, showSegmentDescription, hoveredSegment, handleRef, amounHandleClick } =
    useDataQualityChart(dataQuality);

  return (
    <WidgetContainer>
      <Doughnut ref={handleRef} data={chartData} options={options} />
      {showSegmentDescription && hoveredSegment && (
        <TextContainer>
          <TextWrapper onClick={() => amounHandleClick(qualities[hoveredSegment?.index].filterValue)}>
            <Text size='24' color='blue' bold>
              {qualities[hoveredSegment?.index].amount}
            </Text>
            <QualityLabel indicatorColor={qualities[hoveredSegment?.index].color}>
              {qualities[hoveredSegment?.index].label}
            </QualityLabel>
          </TextWrapper>
        </TextContainer>
      )}
    </WidgetContainer>
  );
};

export default DataQualityChart;
