import React, { useEffect, useState } from 'react';
import { getEntityById } from 'services/crm-entity-service';
import { AnyObject } from 'common/interfaces';
import { getResponseFormData} from 'utils/get-response-data';
import Spinner from 'components/spinner';
import { Text } from 'common/common-components.styled';
import { StyledContainer } from 'pages/details/leasingbank-info/leasingbank-info.styled';

interface LeasingbankInfoProps {
  companyId: string;
}

const LeasingbankInfo = ({ companyId }: LeasingbankInfoProps) => {
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState<AnyObject>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getEntityById({
          entityId: companyId,
          entityName: 'account',
          columns: ['name', 'address1_line1', 'address1_postalcode', 'address1_city']
        });
        response.data = response.data || null;
        const companyData = getResponseFormData(response.data, [{ type: 'object', name: 'attributes' }]) as AnyObject;
        setCompanyData(companyData);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [companyId]);

  return (
    <StyledContainer padding='0 0 0 60px'>
      {loading
        ? <Spinner
          style={{
            width: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
        : <div>
          <Text bottom='3' bold>{companyData.name || ''}</Text>
          <Text bottom='3'>{companyData.address1_line1 || ''}</Text>
          <Text>{`${companyData.address1_postalcode || ''} ${companyData.address1_city || ''}`}</Text>
        </div>}
    </StyledContainer>
  );
};

export default LeasingbankInfo;
