import { useEffect } from 'react';
import { UseScrollToUpcomingDateProps } from './use-scroll-to-upcoming-date.props';

export const useScrollToUpcomingDate = ({ dependencies, containerRef }: UseScrollToUpcomingDateProps) => {
  useEffect(() => {
    const nearestUpcomingDateNode = document.querySelector('[data-date-type="nearestUpcoming"]');

    if (nearestUpcomingDateNode && containerRef.current) {
      const { top: nearestUpcomingDateTop, height: nearestUpcomingDateHeight } =
        nearestUpcomingDateNode.getBoundingClientRect();
      const { top: containerTop } = containerRef.current.getBoundingClientRect();
      const nearestUpcomingDateTopPositionInContainer = nearestUpcomingDateTop - containerTop;
      const alignNumberToCenterPosition = nearestUpcomingDateHeight + nearestUpcomingDateHeight / 2;

      containerRef.current.scrollTo({
        top: nearestUpcomingDateTopPositionInContainer - alignNumberToCenterPosition
      });
    }
  }, dependencies);
};
