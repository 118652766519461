import React from 'react';
import classes from './footer-offer.module.scss';

const List = ({items}) => {
  return (
    <div className={classes.list}>
      <h6>{items.title}</h6>
      {items.links.map(link => <div className={classes.link_wrapper}>
        <a href={link.href}>{link.label}</a>
      </div>)}
    </div>
  );
};

export default function FooterOffer() {
  const fuhrparkmanagementItems = {
    title: 'Fuhrparkmanagement',
    links: [
      {
        label: 'Fuhrparkmanagement',
        href: 'https://www.mobexo.de/'
      }
    ]
  };

  const schadenmanagementItems = {
    title: 'Schadenmanagement',
    links: [
      {
        label: 'Schadenmanagement',
        href: 'https://www.mobexo.de/schadenmanagement/'
      },
      {
        label: 'Leistungen Schadenmanagement',
        href: 'https://www.mobexo.de/leistungen-schadenmanagement/'
      },
      {
        label: 'Flottenversicherung',
        href: 'https://www.mobexo.de/flottenversicherung/'
      }
    ]
  };

  const unternehmenItems = {
    title: 'Unternehmen',
    links: [
      {
        label: 'Werkstattpartner werden',
        href: 'https://www.mobexo.de/werkstattpartner-werden/'
      },
      {
        label: 'Über uns',
        href: 'https://www.mobexo.de/ueber-uns/'
      },
      {
        label: 'Mobexo Blog',
        href: 'https://www.mobexo.de/blog/'
      },
      {
        label: 'Häufig gestellte Fragen',
        href: 'https://www.mobexo.de/haeufige-fragen/'
      }
    ]
  };

  const ressourcenItems = {
    title: 'Beliebte Ressourcen',
    links: [
      {
        label: 'Car Policy Muster',
        href: 'https://www.mobexo.de/blog/car-policy-muster/'
      }
    ]
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <section>
            <img src="https://www.mobexo.de/app/uploads/2021/03/Logo_Mobexo_RGB_Horizontal_Negative.svg" alt="logo"/>
            <span>Mobexo hilft Unternehmen, Kosten und Aufwand für das Flottenmanagement drastisch zu senken. Unsere Lösungen im Bereich <a href="https://mobexo-wordpress.azurewebsites.net/">Fuhrparkmanagement</a> und <a href="https://mobexo-wordpress.azurewebsites.net/schadenmanagement/">Schadenmanagement</a> steigern die Effizienz und senken den Verwaltungsaufwand über digitale und maßgeschneiderte Lösungen.</span>
          </section>
          <section>
            <List items={fuhrparkmanagementItems} />
            <List items={schadenmanagementItems} />
          </section>
          <section>
            <List items={unternehmenItems} />
          </section>
          <section>
            <List items={ressourcenItems} />
          </section>
        </div>
        <div className={classes.copyright}>
          <a href="https://www.mobexo.de/">
            <img src="https://www.mobexo.de/app/uploads/2021/03/Signet_Mobexo_RGB.svg" alt="logo"/>
          </a>
          <p>Copyright © <span>{new Date().getFullYear()}</span> ·  Mobexo GmbH</p>
          <div className={classes.impressum}>
            <a href="https://www.mobexo.de/impressum/">Impressum</a>
            <span>&nbsp;-&nbsp;</span>
            <a href="https://www.mobexo.de/datenschutz/">Datenschutzerklärung</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
