import { useEffect, useState } from 'react';
import { OFFER_STATUSES } from '../../constants';
import useVehicleOffersEventDescriptions from 'hooks/react-query/use-vehicle-offers-event-descriptions';
import { getVehicleOfferStatus } from 'utils/offer-events-functions';
import { EventProps } from 'components/product/product-view.props';

const useOfferStatus = (events: EventProps[], isStatusForFleetManager: boolean, isDriverReferenzfahrzeuge: boolean) => {
  const { vehicleOffersEventDescriptions } = useVehicleOffersEventDescriptions();

  const [offerStatus, setOfferStatus] = useState(OFFER_STATUSES.configurationCreated);

  useEffect(() => {
    if (vehicleOffersEventDescriptions) {
      setOfferStatus(
        getVehicleOfferStatus(
          events,
          vehicleOffersEventDescriptions,
          isStatusForFleetManager,
          isDriverReferenzfahrzeuge
        )
      );
    }
  }, [vehicleOffersEventDescriptions, events]);

  return { offerStatus };
};

export default useOfferStatus;
