export interface PaginationProps {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  onPageChange: (page: number) => void;
  className?: string;
  noScroll?: boolean;
}

export enum PaginationButtonTypes {
  Page = 'page',
  PrevPage = 'prevPage',
  NextPage = 'nextPage',
  Gap = 'gap'
}

export enum GapSides {
  Left = 'left',
  Right = 'right'
}
