import { Entries, Values } from './object.props';

class ObjectUtility {
  entries = <T extends Object>(obj: T): Entries<T> => {
    return Object.entries(obj) as Entries<T>;
  };

  values = <T extends Object>(obj: T): Values<T> => {
    return Object.values(obj) as Values<T>;
  };
}

export const objectUtility = new ObjectUtility();
