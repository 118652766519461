import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Breadcrumbs({ prevLocation }) {
  return (
    <section id='page-title' className='single-bread' style={{ backgroundColor: 'transparent' }}>
      <div className='container clearfix'>
        <ol className='breadcrumb bread-left'>
          <li>
            <Link style={{textTransform: 'uppercase', color: '#373B4D', fontWeight: 'bold'}} to={prevLocation}>
              <i style={{color: '#373B4D'}} className='fas fa-angle-left' /> Zurück zur Suche
            </Link>
          </li>
        </ol>
      </div>
    </section>
  );
}

Breadcrumbs.propTypes = {
  prevLocation: PropTypes.string.isRequired
};
