import { useOutsideClick } from 'hooks';
import { useCallback, useRef, useState } from 'react';

export const useCloseOnClickOutside = () => {
  const [isExtended, setIsExtended] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const closeOnClickOutsideHandler = useCallback(() => setIsExtended(false), []);

  useOutsideClick(containerRef, closeOnClickOutsideHandler, { disableStopPropagation: true });

  return { containerRef, isExtended, setIsExtended };
};
