import { useSessionState } from 'hooks/use-session-state';
import { CarConfiguratorChosenValues } from './use-chosen-values.types';

const useChosenValues = () => {
  const { state: chosenValues, setSessionState: setChosenValues } = useSessionState<CarConfiguratorChosenValues>(
    'car-configurator-chosen-values',
    {}
  );

  return { chosenValues, setChosenValues };
};

export default useChosenValues;
