import styled from 'styled-components';

export const StyledTimeList = styled.div<Record<'isMobile', boolean>>`
  position: ${({ isMobile }) => (isMobile ? 'sticky' : 'absolute')};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 24px 40px rgba(51, 86, 102, 0.07), 0px 3px 9px rgba(51, 86, 102, 0.07),
    0px 1px 2.5px rgba(51, 86, 102, 0.07);
  border-radius: 2px;
  padding: 15px;
  z-index: 100;
`;
