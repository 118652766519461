import { useContext } from 'react';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../constants';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export const useIsShowKilometerWidget = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const isWidgetAvailableForRole = useIsUserHaveRole([
    USER_ROLES.driver.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const actualMileageIsAvailable = typeof vehicle?.fuhrpark?.uds_actualmileage?.attributeValue === 'number';
  const leasingData = [
    vehicle?.fuhrpark?.new_leasingstart?.attributeValue,
    vehicle?.fuhrpark?.new_leasingende?.attributeValue,
    vehicle?.fuhrpark?.uds_laufleistung?.attributeValue
  ];

  const isAllLeasingFieldsAvailable = leasingData.every(Boolean);

  const isShowKilometerWidget =
    vehicle.fuhrpark?.new_status_carcode?.attributeValue?.value !== VehicleStatusesValue.Ordered &&
    isWidgetAvailableForRole;

  return { isShowKilometerWidget, actualMileageIsAvailable, isAllLeasingFieldsAvailable };
};
