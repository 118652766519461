import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './my-order.module.scss';
import { AnyObject } from 'common/interfaces';
import { StarCarIcon } from 'components/icons-new/star-car';
import sharepointService from 'services/sharepoint-service';
import { DeviceTypes, MODALS, MODAL_NAMES, STATECODE_NAMES } from '../../../constants';
import { useTheme } from 'styled-components';
import { getDeviceType } from 'utils/get-device-type';
import { CarPlaceholderIcon } from 'components/icons/car-placeholder';
import { addTooltipToString } from 'components/aufgaben-component/components/aufgaben-item/aufgaben-item';
import { MyOrderDataInterface } from '../dashboard-driver.interface';
import { Tooltip } from '@mui/material';
import CircleInfoIcon from 'components/icons/warning-icon';
import { Flex, Text } from 'common/common-components.styled';
import { Link, useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import Spinner from 'components/spinner';
import ButtonWithIcon from 'components/button-with-icon';
import { AskQuestionIcon } from 'components/icons/ask-qustion-icon';
import Form from 'components/form';
import { createNewTaskHandler } from 'utils/create-new-task-handler';
import { setForm, toggleModal } from 'actions/app_action';
import { formFields } from 'components/monatsrechnungen/formFields/formFields';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'selectors';
import useOverlay from 'hooks/use-overlay';
import useLatestValue from 'hooks/use-latest-value';
import Snackbar from 'components/snackbar';
import { useTypedSelector } from 'hooks/use-typed-selector';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

const deviceType = getDeviceType();

export const MyOrder = ({ myOrderData, cancelToken, isLoading }: MyOrderDataInterface) => {
  const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(true);
  const [activeVehicle, setActiveVehicle] = useState<AnyObject>(myOrderData);
  const [vehicleOffers, setVehicleOffers] = useState<AnyObject | null>(null);
  const [vehicleDelivery, setVehicleDelivery] = useState<AnyObject | null>(null);
  const [vehicleFirm, setVehicleFirm] = useState<AnyObject | null>(null);
  const [vehicleStatus, setVehicleStatus] = useState<string>('');
  const [activeVehicleImg, setActiveVehicleImg] = useState<any>('');
  const blockRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const history = useHistory();
  const [statusColor, setStatusColor] = useState<string>(theme.colors.blue);
  const isMobile = deviceType === DeviceTypes.Mobile;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const forms = useTypedSelector(state => state['app'].forms);
  const formsLatest = useLatestValue(forms);

  useEffect(() => {
    if (myOrderData?.fahrzeugangebote && myOrderData?.fahrzeugangebote.length) {
      setVehicleOffers(myOrderData?.fahrzeugangebote[0]);
      if (myOrderData?.fahrzeugangebote[0].uds_fahrzeugangeboteid?.attributeValue) {
        getCarImages(myOrderData?.fahrzeugangebote[0].uds_fahrzeugangeboteid?.attributeValue);
      }
    }
    if (myOrderData?.fahrzeugauslieferung && myOrderData?.fahrzeugauslieferung.length) {
      setVehicleDelivery(myOrderData?.fahrzeugauslieferung[0]);
    }
    if (myOrderData?.firm && myOrderData?.firm.length) {
      setVehicleFirm(myOrderData?.firm[0]);
    }
    if (myOrderData?.fuhrpark && myOrderData?.fuhrpark.length) {
      setActiveVehicle(myOrderData?.fuhrpark[0]);
    }
    if (myOrderData?.status && myOrderData?.status?.attributeValue) {
      setVehicleStatus(myOrderData?.status?.attributeValue?.label);
      getStatusFromPhaseVehicleReturn(myOrderData?.status?.attributeValue?.label);
    }
  }, [myOrderData]);

  const getStatusFromPhaseVehicleReturn = (status: string) => {
    switch (status) {
      case STATECODE_NAMES.processing:
        setStatusColor(theme.colors.darkGold);
        break;
      case STATECODE_NAMES.open:
        setStatusColor(theme.colors.pink);
        break;
      case STATECODE_NAMES.completed:
        setStatusColor(theme.colors.green);
        break;
    }
  };

  const snackbarSetup = (message: string, type: string) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setOpenSnackbar(true);
  };

  const closeAlert = useCallback(() => {
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch]);

  const openCreateNewTaskModal = useCallback((id: string) => {
    const alertData = {
      title: 'Neue Frage',
      children: <Form name='frage' formFields={formFields} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Speichern',
          action: () =>
            createNewTaskHandler({
              itemId: id,
              userId: user.id,
              closeAlert: closeAlert,
              dispatchForm: form => {
                dispatch(setForm(form));
              },
              formsLatest: formsLatest.current,
              hideOverlay: hideOverlay,
              showOverlay: showOverlay,
              snackbarSetup: snackbarSetup,
              logicalName: 'new_fuhrpark'
            })
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, []);

  const getCarImages = async (carId: string) => {
    let res: any;
    try {
      res = await sharepointService.getSharepointLogo(carId, 'Foto(s) Fahrzeug', cancelToken.token);
    } catch (err) {
      console.error(err);
      res = '';
    } finally {
      setActiveVehicleImg(res?.data);
    }
  };

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const openMap = (adress: string) => {
    window.open(`https://www.google.com/maps/search/${adress}`, '_blank');
    setShowAdditionalFields(true);
  };

  const openEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
    setShowAdditionalFields(true);
  };

  useEffect(() => {
    if (isMobile) {
      setShowAdditionalFields(false);
    } else if (vehicleFirm) {
      setShowAdditionalFields(true);
    } else if (!vehicleFirm && !isMobile) {
      setShowAdditionalFields(false);
    }
  }, [isMobile, vehicleFirm]);

  const dialPhoneNumber = (phone: string) => {
    const cleanedPhoneNumber = phone.replace(/[^+\d]/g, '');
    window.location.href = `tel:${cleanedPhoneNumber}`;
  };

  const blockStyle = !showAdditionalFields
    ? isMobile
      ? classes.container_mobile
      : classes.container
    : isMobile
    ? classes.full_container_mobile
    : classes.full_container;
  const expandImgStyle = !showAdditionalFields ? classes.expand_right : classes.expand_left;

  const tooltipStyles = { fontSize: '12px', color: '#FFF' };

  const carModelString =
    !activeVehicle || (!activeVehicle?.new_hersteller?.attributeValue && !activeVehicle?.new_model?.attributeValue)
      ? '-'
      : `${activeVehicle?.new_hersteller?.attributeValue || '-'} ${activeVehicle?.new_model?.attributeValue || '-'}`;

  const spinnerStyle = {
    width: '50px',
    zIndex: 97
  };

  return isLoading ? (
    <div className={classes.spinner_block}>
      <Spinner style={spinnerStyle} />
    </div>
  ) : (
    <section ref={blockRef} className={`${blockStyle} ${showAdditionalFields ? 'showAdditionalFields' : ''}`}>
      {isMobile ? (
        <div className={classes.main_block}>
          <div className={classes.car_name}>
            <Flex
              onClick={() => history.push(PAGES.ORDERS)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              pointer
            >
              <StarCarIcon width={25} height={24} hovered={isHovered} />
              <div className={classes.block_name}>Meine Bestellung</div>
            </Flex>
            <div className={classes.service_info_item}>
              <span>{(vehicleDelivery && vehicleDelivery?.uds_name?.attributeValue) || '-'}</span>
            </div>
            <ButtonWithIcon
              tooltip='Frage stellen'
              icon={<AskQuestionIcon />}
              onClick={() => openCreateNewTaskModal(myOrderData?.fuhrpark[0]?.new_fuhrparkid.attributeValue)}
            />
          </div>
          <Flex direction='column'>
            <EllipsisWithTooltip tooltip={carModelString}>
              <span className={classes.car_model}>{carModelString}</span>
            </EllipsisWithTooltip>
            <div className={classes.block_status} style={{ color: statusColor }}>
              {vehicleStatus || '-'}
            </div>
          </Flex>

          <div className={classes.service_block}>
            <div className={classes.service_info}>
              <div className={classes.service_info_item}>
                <span>
                  {(vehicleOffers &&
                    addTooltipToString(
                      vehicleOffers?.voraussichtlicher?.attributeValue,
                      30,
                      classes.tooltip_string_long,
                      tooltipStyles
                    )) ||
                    '-'}
                </span>
                <Flex align='center'>
                  <p>Voraussichtlicher Liefertermin</p>
                  {vehicleOffers?.voraussichtlicherToolTip?.attributeValue && (
                    <Tooltip title={vehicleOffers.voraussichtlicherToolTip.attributeValue}>
                      <Text as='span' left='5'>
                        <CircleInfoIcon color={theme.colors.grey400} />
                      </Text>
                    </Tooltip>
                  )}
                </Flex>
              </div>
              <div className={classes.service_info_item}>
                <span>
                  {(activeVehicle &&
                    addTooltipToString(
                      activeVehicle?.uds_manufacturer_order_number?.attributeValue,
                      20,
                      classes.tooltip_string,
                      tooltipStyles
                    )) ||
                    '-'}
                </span>
                <p>Hersteller-Bestellnummer</p>
              </div>
              <div className={classes.show_carinfo} onClick={toggleAdditionalFields}>
                <span>Hersteller Kontaktdaten</span>
                <img src='/assets/images/dashboard-expand.svg' alt='dashboard-expand' className={expandImgStyle} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.main_block}>
          <div className={classes.car_name}>
            <Flex
              onClick={() => history.push(PAGES.ORDERS)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              pointer
            >
              <StarCarIcon width={25} height={24} hovered={isHovered} />
              <div className={classes.block_name}>Meine Bestellung</div>
            </Flex>
            <div className={classes.block_status} style={{ color: statusColor }}>
              {vehicleStatus || '-'}
            </div>
          </div>
          <div className={classes.car_title}>
            <EllipsisWithTooltip tooltip={carModelString}>
              <span className={classes.car_model}>{carModelString}</span>
            </EllipsisWithTooltip>
            <ButtonWithIcon
              tooltip='Frage stellen'
              icon={<AskQuestionIcon />}
              onClick={() => openCreateNewTaskModal(myOrderData?.fuhrpark[0]?.new_fuhrparkid.attributeValue)}
            />
          </div>
          <div className={classes.service_block}>
            {!activeVehicleImg ? (
              <>
                {showAdditionalFields ? (
                  <img
                    src='/assets/images/default-vehicle-white-placeholder.svg'
                    alt='vehicle'
                    className={classes.car_img}
                  />
                ) : (
                  <img src='/assets/images/default-vehicle-upload.svg' alt='vehicle' className={classes.car_img} />
                )}
              </>
            ) : (
              <img src={activeVehicleImg} alt='vehicle' className={classes.car_img} />
            )}
            <div className={classes.service_info}>
              <div className={classes.service_info_item}>
                <span>{(vehicleDelivery && vehicleDelivery?.uds_name?.attributeValue) || '-'}</span>
                <p>Bestellnummer</p>
              </div>
              <div className={classes.service_info_item}>
                <span>
                  {(vehicleOffers &&
                    addTooltipToString(
                      vehicleOffers?.voraussichtlicher?.attributeValue,
                      14,
                      classes.tooltip_string,
                      tooltipStyles
                    )) ||
                    '-'}
                </span>
                <Flex align='center'>
                  <p>Voraussichtlicher Liefertermin</p>
                  {vehicleOffers?.voraussichtlicherToolTip?.attributeValue && (
                    <Tooltip title={vehicleOffers.voraussichtlicherToolTip.attributeValue}>
                      <Text as='span' left='5'>
                        <CircleInfoIcon color={theme.colors.grey400} />
                      </Text>
                    </Tooltip>
                  )}
                </Flex>
              </div>
              <div className={classes.service_info_item}>
                <span>
                  {(activeVehicle &&
                    addTooltipToString(
                      activeVehicle?.uds_manufacturer_order_number?.attributeValue,
                      14,
                      classes.tooltip_string,
                      tooltipStyles
                    )) ||
                    '-'}
                </span>
                <p>Hersteller-Bestellnummer</p>
              </div>
            </div>
          </div>
          <Snackbar
            open={openSnackbar}
            type={snackbarType}
            onClose={() => setOpenSnackbar(false)}
            message={snackbarMessage}
          />
        </div>
      )}
      {showAdditionalFields && (
        <div className={classes.additional_block}>
          <div className={classes.additional_item}>
            <span>Firma</span>
            <p>
              {(vehicleFirm &&
                addTooltipToString(
                  vehicleFirm?.name?.attributeValue,
                  30,
                  classes.tooltip_string_long,
                  tooltipStyles
                )) ||
                '-'}
            </p>
            <div className={classes.line} />
          </div>
          <div className={classes.additional_item}>
            <span>Telefon</span>
            <div
              className={classes.additional_item_line}
              onClick={() =>
                vehicleFirm?.telephone1?.attributeValue
                  ? dialPhoneNumber(vehicleFirm?.telephone1?.attributeValue)
                  : dialPhoneNumber('-')
              }
            >
              <p>
                {(vehicleFirm &&
                  addTooltipToString(
                    vehicleFirm?.telephone1?.attributeValue,
                    30,
                    classes.tooltip_string_long,
                    tooltipStyles
                  )) ||
                  '-'}
              </p>
              <img src='/assets/images/new-phone-icon.svg' alt='phone-icon' />
            </div>
            <div className={classes.line} />
          </div>
          <div className={classes.additional_item}>
            <span>E-mail</span>
            <div
              className={classes.additional_item_line}
              onClick={() =>
                vehicleFirm?.emailaddress1?.attributeValue
                  ? openEmail(vehicleFirm?.emailaddress1?.attributeValue)
                  : openEmail('-')
              }
            >
              <p>
                {(vehicleFirm &&
                  addTooltipToString(
                    vehicleFirm?.emailaddress1?.attributeValue,
                    30,
                    classes.tooltip_string_long,
                    tooltipStyles
                  )) ||
                  '-'}
              </p>
              <img src='/assets/images/email-icon.svg' alt='email-icon' />
            </div>
            <div className={classes.line} />
          </div>
          <div className={classes.additional_item}>
            <span>Adresse</span>
            <div
              className={classes.additional_item_line}
              onClick={() =>
                vehicleFirm?.fulladdress?.attributeValue
                  ? openMap(vehicleFirm?.fulladdress?.attributeValue)
                  : openMap('-')
              }
            >
              <p>
                {(vehicleFirm &&
                  addTooltipToString(
                    vehicleFirm?.fulladdress?.attributeValue,
                    30,
                    classes.tooltip_string_long,
                    tooltipStyles
                  )) ||
                  '-'}
              </p>
              <img src='/assets/images/location-icon.svg' alt='map-icon' />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
