import React from 'react';

export default function SearchIcon({ color = '#fff', className, width = '19', height = '18' }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.2027 11.6016L12.2736 11.6724L17.1632 16.6329C17.5884 17.0581 17.5884 17.6958 17.1632 18.121L17.0923 18.1919C16.6672 18.6171 16.0294 18.6171 15.6042 18.1919L10.7146 13.2314L10.6437 13.1606'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3365 8.76575C12.8405 12.3089 9.58074 14.7892 6.10842 14.364C2.56523 13.9388 0.084997 10.6791 0.581043 7.13589C1.07709 3.5927 4.33682 1.11247 7.80915 1.53765C11.3523 1.96283 13.7617 5.22257 13.3365 8.76575Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.39195 12.0969C4.05345 11.8135 2.49444 9.68756 2.7779 7.41992'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
