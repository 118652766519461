import React, { Component } from 'react';
import classes from './dashboard-component.module.scss';
import DashboardItems from './dashboard-items';
import { withRouter } from 'react-router-dom';
import { withPageContainer } from '../../hoc/withPageContainer';
import { USER_ROLES } from '../../constants';
import ServiceCard from '../service-card/service-card';
import Button from '../button';
import { SetupAssistantIcon } from '../icons/setup-assistant-icon';
import { ButtonComponentType } from '../button/button.props';
import { PAGES } from '../../common/enums';

class DashboardComponent extends Component {
  state = {
    mainCar: null
  };

  setMainCar = mainCar => {
    this.setState({ mainCar });
  };

  render() {
    const isShowSetupAssistant = [USER_ROLES.fleetManager.id, USER_ROLES.administrator.id].includes(
      this.props.user.mainRole?.id
    );

    return (
      <div className={classes.dashboard}>
        <div className={this.props.containerClassName}>
          <div className={classes.panel}>
            <div className={classes.setup_assistant_container}>
              {this.state.mainCar && <ServiceCard car={this.state.mainCar} />}
              {isShowSetupAssistant && (
                <div className={classes.initial_setup_assistant}>
                  <SetupAssistantIcon />
                  <h3>Ersteinrichtungs Assistent</h3>
                  <h5>In nur wenigen Schritten die Konfiguration abschließen.</h5>
                  <Button component={ButtonComponentType.Link} to={PAGES.SETUP_ASSISTANT}>
                    Assistenten starten
                  </Button>
                </div>
              )}
            </div>
            <DashboardItems setMainCar={this.setMainCar} />
          </div>
        </div>
      </div>
    );
  }
}

export default withPageContainer(withRouter(DashboardComponent));
