/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import { ComponentsProps } from './components.props';

const ThreeButtonsComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldName,
  firstButtonText,
  firstButtonValue,
  secondButtonText,
  secondButtonValue,
  thirdButtonText,
  thirdButtonValue
}: ComponentsProps) => {
  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors }
  } = reactHookFormData;

  const setFirstValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, firstButtonValue);
    e.preventDefault();
  };

  const setSecondValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, secondButtonValue);
    e.preventDefault();
  };

  const setThirdValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, thirdButtonValue);
    e.preventDefault();
  };

  useEffect(() => {
    if (watch(fieldRegister) !== undefined) {
      clearErrors(fieldRegister);
    }
  }, [watch(fieldRegister)]);

  return (
    <div className={styles.data_fields}>
      <div className={errors[fieldRegister] ? styles.form_name_button_error : styles.form_name_button}>
        <span>{fieldName}</span>
      </div>
      <Distance top='-5' />
      <button
        id={`${fieldName}${firstButtonText}Button`}
        onClick={e => setFirstValue(e)}
        className={watch(fieldRegister) === firstButtonValue ? styles.button_option_true : styles.button_option_false}
      >
        {firstButtonText}
      </button>
      <Distance top='15' />
      <button
        id={`${fieldName}${secondButtonText}Button`}
        onClick={e => setSecondValue(e)}
        className={watch(fieldRegister) === secondButtonValue ? styles.button_option_true : styles.button_option_false}
      >
        {secondButtonText}
      </button>
      <Distance top='15' />
      <button
        id={`${fieldName}${thirdButtonText}Button`}
        onClick={e => setThirdValue(e)}
        className={watch(fieldRegister) === thirdButtonValue ? styles.button_option_true : styles.button_option_false}
      >
        {thirdButtonText}
      </button>

      <Distance top='10' />
    </div>
  );
};

export default ThreeButtonsComponent;
