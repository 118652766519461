import { IconProps } from 'common/interfaces';
import React from 'react';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const MailIcon = ({ width = 25, height = 25, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.9873 7.78125L12.225 11.7654C12.704 11.9977 13.2629 11.9977 13.7419 11.7654L21.9869 7.78125'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.22571 16.7539L10.7263 11.1141M15.0871 11.1141L15.8997 11.819'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.9869 14.6044L21.9873 10.3214L21.9869 6.34181C21.9869 5.70318 21.4644 5.18066 20.8257 5.18066H5.14894C4.51025 5.18066 3.98768 5.70318 3.98768 6.34181L3.9873 10.3214V16.1271C3.9873 16.7657 4.51034 17.2882 5.14903 17.2882H8.67305'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.63 15.4563L16.1255 15.4563L16.1255 13.6245L21.9873 17.2881L16.1255 20.9518L16.1255 19.12L13.9273 19.12'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
