import React from 'react';
import classes from './table-component-mobile.module.scss';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { TableComponentMobileProps } from 'components/driving-license-control-component/driving-license-control.props';
import { CONTROL_STATUSES_NAME } from '../items-component/control-item/control-item';
import { UserCardCrossedOutIcon } from 'components/icons/user-card-crossed-out';
import { UserCardIcon } from 'components/icons/user-card';
import { UserIcon } from 'components/icons/user-icon';
import { getIsAccountBooked } from 'services/drivers-check-service';
import { useBookDriverLicenseCheckModal } from 'hooks/modals/driver-check/book-driver-license-check.modal';
import { useUnsubscribeDriverLicenseCheckModal } from 'hooks/modals/driver-check/unsubscribe-driver-license-check.modal';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../constants';
import { ReactQueryKeys } from '../../../../common/enums';

export const TableComponentMobile = ({ row = {}, openSettingPageViaModal }: TableComponentMobileProps) => {
  const { data: accountBookedData } = useQuery([ReactQueryKeys.AccountIsBooked, row.parentcustomerid_id], () =>
    getIsAccountBooked(row.parentcustomerid_id)
  );

  const isAccountBooked = accountBookedData?.data?.data?.uds_isorg_in_driverscheck;

  const { openModal: openUnsubscribeDriverLicenseCheckModal } = useUnsubscribeDriverLicenseCheckModal();
  const { openModal: openBookDriverLicenseCheckModal } = useBookDriverLicenseCheckModal();

  const isUserHaveDriverRole = useIsUserHaveRole(USER_ROLES.driver.id);

  const containerStyle = {
    borderColor: statusColor[row.new_gueltigkeitsstatus_driverscheckneu?.toLowerCase() || '']
  };
  const menuButtonContainerClassName = classNames('d-flex', classes.menu_button_container, {
    [classes.inactive]: !isAccountBooked
  });
  const menuButtonProps = {
    className: classes.menu_button
  };

  const openSettingPageInTabDriversLicense = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (!isAccountBooked) {
      return;
    }

    openSettingPageViaModal(row, {
      formName: 'Funktion',
      formDataNames: ['uds_isdrivercheckneeded', 'uds_kontrolleur', 'new_manager_driverscheckeskalation']
    });
  };

  const driverLicenseCheckHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isBookDriverLicenseCheck: boolean
  ) => {
    event.stopPropagation();

    if (isBookDriverLicenseCheck) {
      openBookDriverLicenseCheckModal(row.contactid || '', !!row?.emailaddress1, {
        callback: () => openSettingPageViaModal(row)
      });
      return;
    }

    openUnsubscribeDriverLicenseCheckModal(row.contactid || '', {
      callback: () => openSettingPageViaModal(row)
    });
  };

  return (
    <li
      className={classes.container}
      style={containerStyle}
      data-parentcustomerid={row.parentcustomerid_id}
      data-contactid={row.contactid}
    >
      <div>
        <p className={classes.value}>{row.new_gueltigkeitsstatus_driverscheckneu || '-'}</p>
        <p className={classes.value_secondary}>{getDateString(row.uds_fuhrerscheinprufung)}</p>
      </div>

      {!isUserHaveDriverRole && (
        <div className='d-flex'>
          <div className={menuButtonContainerClassName}>
            {row.uds_isdrivercheckneeded ? (
              <button {...menuButtonProps} onClick={event => driverLicenseCheckHandler(event, false)}>
                <UserCardCrossedOutIcon {...commonIconProps} width='20' height='20' />
              </button>
            ) : (
              <button {...menuButtonProps} onClick={event => driverLicenseCheckHandler(event, true)}>
                <UserCardIcon {...commonIconProps} />
              </button>
            )}
          </div>
          <div className={menuButtonContainerClassName}>
            <button {...menuButtonProps} onClick={openSettingPageInTabDriversLicense}>
              <UserIcon {...commonIconProps} />
            </button>
          </div>
        </div>
      )}
    </li>
  );
};

const commonIconProps = {
  color: '#fff'
};

const statusColor = {
  [CONTROL_STATUSES_NAME.valid]: '#30bf30',
  [CONTROL_STATUSES_NAME.incompleteData]: '#ff9700',
  [CONTROL_STATUSES_NAME.daysOverdue]: '#bf0040',
  [CONTROL_STATUSES_NAME.invalid]: '#bf0040'
};
