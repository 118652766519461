import styled from 'styled-components';

interface UploadContainerProps {
  isUnfallreparatur?: boolean;
  isDisabled?: boolean;
}

export const StyledUploadContainer = styled.div<UploadContainerProps>`
  margin: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  // eslint-disable-next-line max-len
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const StyledText = styled.span<UploadContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;
  font-size: 12px;
  letter-spacing: 0.02em;
  padding-bottom: 5px;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.grey200 : theme.colors.grey500)};
`;

export const StyledLink = styled.span<UploadContainerProps>`
  font-size: 12px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ theme, isDisabled, isUnfallreparatur }) => {
    if (isDisabled) {
      return theme.colors.grey200;
    }

    if (isUnfallreparatur) {
      return theme.colors.blue;
    }

    return theme.colors.blue;
  }}; ;
`;
