import { SegmentItemProps } from 'components/containers/diagram/segment-item/segment-item.props';
import { BarDiagramData } from 'components/widgets/bar/bar.props';
import { sortBy } from 'lodash';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { useContext } from 'react';

export const useKilometerWidgetDiagramData = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const sortedDiagramColumn = sortBy(
    [
      { label: 'km Projektion', color: 'midBlue', value: vehicle?.fuhrpark?.uds_summeprojektion?.attributeValue },
      { label: 'Aktueller km-Stand', color: 'lightBlue', value: vehicle?.fuhrpark?.uds_actualmileage?.attributeValue }
    ],
    'value'
  );

  const horizontalLineSegment: SegmentItemProps = { label: 'km laut Vertrag', color: 'darkGold' };
  const horizontalLineValue = Number(vehicle?.fuhrpark?.new_leasingkmintotal?.attributeValue) || 0;

  const segments: SegmentItemProps[] = [...sortedDiagramColumn, horizontalLineSegment].map(({ label, color }) => ({
    label,
    color
  }));

  const diagramData: BarDiagramData = sortedDiagramColumn.map(({ value, color }) => ({ value, color }));

  return { diagramData, segments, horizontalLineValue };
};
