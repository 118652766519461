import { CSSProperties, useEffect, useRef, useState } from 'react';

export const useSetDefaultContainerStyle = (addNewItemButtonIsHide: boolean) => {
  const [containerStyle, setContainerStyle] = useState<CSSProperties>({});

  const buttonRef = useRef<HTMLDivElement>(null);

  const setDefaultContainerStyle = () => {
    setContainerStyle(prevState => ({
      ...prevState,
      paddingBottom: addNewItemButtonIsHide ? 0 : `${buttonRef.current?.offsetHeight || 0}px`
    }));
  };

  useEffect(setDefaultContainerStyle, []);

  return { buttonRef, containerStyle };
};
