import React from 'react';
import { Flex, Text, TextProps } from 'common/common-components.styled';
import { FuelIcon } from 'components/icons-new/fuel';
import { StyledInfoHintWithoutBackground } from 'components/info-hint/info-hint-view.styled';
import { ReplaceText } from 'components/replace-text/replace-text';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import { useConsumptionWidgetVariant } from '../../../hooks/use-widget-variant';

export const ConsumptionEmptyPlaceholder = () => {
  const { widgetVariant } = useConsumptionWidgetVariant();

  const history = useHistory();

  const infoHintTextProps: TextProps = {
    color: 'grey600',
    size: '12'
  };

  return (
    <Flex
      direction={widgetVariant === 'normal' ? 'row' : 'column'}
      justify='center'
      align='center'
      height='100%'
      width='100%'
      padding='15px 0'
    >
      <FuelIcon hovered />

      <StyledInfoHintWithoutBackground
        iconSize={12}
        top={widgetVariant === 'small' ? '20' : '0'}
        left={widgetVariant === 'normal' ? '20' : '0'}
      >
        {widgetVariant === 'normal' ? (
          <Text {...infoHintTextProps}>Keine Tankdaten vorhanden.</Text>
        ) : (
          <ReplaceText
            {...infoHintTextProps}
            text='Keine Tankdaten vorhanden. Lade einen Kraftstoffreport hoch oder kontaktiere uns via E-Mail.'
            replaceWord='Kraftstoffreport'
            replaceComponent={() => (
              <Text display='inline' color='blue' underline pointer onClick={() => history.push(PAGES.FUEL_REPORT)}>
                Kraftstoffreport
              </Text>
            )}
          />
        )}
      </StyledInfoHintWithoutBackground>
    </Flex>
  );
};
