import { useState } from 'react';

const useAcceptConditions = () => {
  const [acceptConditions, setAcceptConditions] = useState(false);

  const toggleAcceptConditions = () => {
    setAcceptConditions(acceptConditions => !acceptConditions);
  };

  return { acceptConditions, toggleAcceptConditions };
};

export default useAcceptConditions;
