import React, {FunctionComponent , ReactElement, useEffect, useState} from 'react';
import {Title} from '../../../common/interfaces';
import {
    FilterButton,
    FilterInput,
  } from '../../../components/filter-items';
import {useFilterByCostType} from '../hooks/use-filter-by-cost-type';
import {CostTypeFilterItem, FuelGroups} from '../../../services/vehicle-costs-service';


interface CostTypeFilterProviderProps{
    handleInput(title:any, value:any, filterData:CostTypeFilterItem[] | string[]):void;
    title:string;
    withoutSearchButton?:boolean;
    filteredButton?:boolean;
    dropListDataWithId?:boolean;
    dropListDataValueName?:string;
    circleIndicator?:boolean;
};

export const CostTypeFilterProvider:FunctionComponent<CostTypeFilterProviderProps> = (
    {handleInput, title, filteredButton, ...rest}
  ):ReactElement=>{
    const {isFetching, data} = useFilterByCostType(title as Title);
    const [costTypeGroupFilterData, setCostTypeGroupFilterData] = useState<string[]>([]);

    useEffect(() => {
        if (data?.groups) {
          const newKostenartGroup = Object.keys(data.groups).filter(
            key => data.groups[key as keyof FuelGroups]
          );
          setCostTypeGroupFilterData(newKostenartGroup);
        }
      }, [data?.groups]);

    return (
     <>
       {filteredButton && (
        costTypeGroupFilterData.length ?
          costTypeGroupFilterData.map((costType) => {
            return (
            <FilterButton
              key={costType}
              title='kostenartGroup'
              handleInput={handleInput}
              label={costType}
              value={costType}
              itemsList={costTypeGroupFilterData}
            />
            )
          }) :
          (data?.filteredList.map((item, index) => {
            if (index <= 2) {
              return (
                <FilterButton
                  key={item.value}
                  title={title}
                  handleInput={handleInput}
                  label={item.value}
                  value={item.value}
                  itemsList={data?.filteredList ?? []}
                />
              );
            }
          }) ?? null)
        )
        }
        <FilterInput
          title={title}
          handleInput={handleInput}
          dropListData={data?.fullList.map( item => item.value) ?? []}
          isLoading={isFetching}
          itemsList={data?.fullList ?? []}
          {...rest}
        />
     </>
    )
};