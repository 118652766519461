import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function fleetManagerReleaseOrder(
  approvers: string[],
  vehicleId: string,
  approved: boolean,
  notice?: string
): Promise<AxiosResponse<ResponseModel>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/event/FleetManagerReleaseOrder`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      approvers,
      vehicleId,
      approved,
      notice
    }
  });
}
