import { connect } from 'react-redux';

import VehicleList from './vehicle-list-view';
import { changePage, setEndLoading } from '../../actions/app_action';

const mapStateToProps = state => ({
  smartTable: state.app.smartTable
});

const mapDispatchToProps = dispatch => ({
  hideOverlay() {
    dispatch(setEndLoading());
  },
  changePage(page) {
    dispatch(changePage(page));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
