import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';

export const driverFormFields: FormFieldModel[] = [
  {
    name: 'driver',
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Fahrer',
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];
