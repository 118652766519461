import React from 'react';
import classNames from 'classnames';
import classes from '../my-vehicles-menu.module.scss';
import { MyVehicleMenuItemContentProps } from '../my-vehicles-menu.props';
import { Flex } from 'common/common-components.styled';

export const MyVehicleMenuItemContent = ({
  className,
  name,
  icon,
  isBeta,
  isEnabled
}: MyVehicleMenuItemContentProps) => {
  return (
    <li className={className}>
      <Flex justify='center' width='24px'>
        {icon}
      </Flex>

      {!isEnabled && <div className={classes.menu_disabled_panel} />}

      <div className='mr-15' />

      {name}
      {isBeta && <div className={classNames('info-chip_chip', classes.info_chip)}>beta</div>}
    </li>
  );
};
