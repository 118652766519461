import classNames from 'classnames';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React, { useEffect, useMemo, useState } from 'react';
import classes from './meeting-booking.module.scss';
import moment from 'moment';
import { createBooking, getTimeSlots } from './meeting-booking-service';
import { useOverlay } from 'hooks';
import { Duration } from 'common/enums';
import { useDispatch } from 'react-redux';
import { Meetinginfo } from 'components/after-tour-pop-up/after-tour-pop-up';
import TempForm from 'components/form/temp-form-redux';

interface Props {
  closeModal: () => void;
  setMeetingInfo: React.Dispatch<Meetinginfo>;
}

type AvailableTimes = {
  [key: string]: number[];
};

type TimeBlock = {
  Start: Date;
  End: Date;
};

type FreeTimes = {
  day: number;
  freeTime: number[];
};

const MeetingBooking = ({ closeModal, setMeetingInfo }: Props) => {
  const forms = useTypedSelector(state => state['app'].forms);
  const user = useTypedSelector(state => state['app'].user);
  const overlay = useTypedSelector(state => state['app'].overlay);
  const [startDate, setStartDate] = useState(moment().startOf('day').toISOString().split('.')[0]);
  const [endDate, setEndDate] = useState(moment().add(1, 'M').startOf('day').toISOString().split('.')[0]);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [availableTimes, setAvailableTimes] = useState<AvailableTimes>();
  let selectedDay: null | number = moment(forms?.meetingBooking?.values?.meeting_date).dayOfYear();
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [fetchedMonths, setFetchedMonths] = useState<number[]>([]);
  const [showOverlay, hideOverlay] = useOverlay();

  const getTimeIntervals = (start: Date, end: Date, duration: string) => {
    const startDate = moment(start);
    const endDate = moment(end);
    const dates = [];
    const timeInterval = duration === Duration.HalfHour ? 30 : 60;

    while (moment(startDate).valueOf() <= moment(endDate).subtract(timeInterval, 'minutes').valueOf()) {
      dates.push(startDate.valueOf());
      startDate.add(15, 'minutes');
    }

    return dates;
  };

  useEffect(() => {
    setFetchedMonths(prev => [...prev, selectedMonth]);
    if (fetchedMonths.length && !fetchedMonths.includes(selectedMonth)) {
      setStartDate(moment().month(selectedMonth).startOf('day').toISOString().split('.')[0]);
      setEndDate(moment().month(selectedMonth).add(1, 'M').startOf('day').toISOString().split('.')[0]);
    }
  }, [selectedMonth]);

  useEffect(() => {
    setAvailableDates([]);
    setAvailableTimes({});
    setStartDate(moment().startOf('day').toISOString().split('.')[0]);
    setEndDate(moment().add(1, 'M').startOf('day').toISOString().split('.')[0]);
  }, [forms?.meetingBooking?.values.duration]);

  useEffect(() => {
    const getAvailableDates = async () => {
      if (forms?.meetingBooking?.values.duration) {
        selectedDay = null;
        const response = await getTimeSlots(startDate, endDate, forms?.meetingBooking?.values.duration);
        const days = [
          //@ts-ignore
          ...new Set(
            response?.data?.StaffBookabilities[0]?.BookableTimeBlocks.map((item: TimeBlock) =>
              moment(item.Start).dayOfYear()
            )
          )
        ];

        const freeTimes = days.map((day: number) => {
          return {
            day,
            freeTime: []
          };
        });

        freeTimes.forEach((freeTime: FreeTimes) => {
          response?.data?.StaffBookabilities[0]?.BookableTimeBlocks.filter((item: TimeBlock) => {
            if (
              moment(item.Start).dayOfYear() === moment().dayOfYear() &&
              moment(item.Start).valueOf() >= moment().add(5, 'hours').valueOf()
            ) {
              return item;
            } else if (moment(item.Start).dayOfYear() !== moment().dayOfYear()) {
              return item;
            }
          }).forEach((item: TimeBlock) => {
            if (freeTime?.day === moment(item?.Start).dayOfYear()) {
              freeTime.freeTime?.push(
                ...getTimeIntervals(new Date(item.Start), new Date(item.End), forms?.meetingBooking?.values.duration)
              );
            }
          });
        });

        const freeDates = [
          //@ts-ignore
          ...new Set(
            response?.data?.StaffBookabilities[0]?.BookableTimeBlocks.filter((item: TimeBlock) => {
              const times = freeTimes.find(freeTime => freeTime.day === moment(item.Start).dayOfYear());
              if (times?.freeTime.length) {
                return item;
              }
            }).map((date: TimeBlock) => moment(date?.Start).format('yy-MM-DD'))
          )
        ];

        setAvailableDates([...availableDates, ...freeDates.map((date: string) => new Date(date))]);
        setAvailableTimes({
          ...availableTimes,
          ...freeTimes.reduce(
            (acc: any, curr: any) => ({
              ...acc,
              [curr.day]: curr.freeTime
            }),
            {}
          )
        });
      }
    };

    getAvailableDates();
  }, [startDate, endDate, forms?.meetingBooking?.values.duration]);

  const formFields = useMemo(() => {
    return {
      columns: [
        {
          width: '100%',
          fields: [
            {
              name: 'duration',
              label: '',
              type: 'radio',
              defaultValue: Duration.Hour,
              options: [
                {
                  label: 'Vorstellung Mobexo Fuhrparkmanagement (Dauer 1 Stunde)',
                  value: Duration.Hour,
                  disabled: overlay
                },
                {
                  label: 'Gespräch 30 Minuten',
                  value: Duration.HalfHour,
                  disabled: overlay
                }
              ],
              validation: [
                {
                  name: 'required'
                }
              ]
            },
            {
              name: 'meeting_date',
              type: 'date',
              data: [],
              disabled: overlay,
              placeholder: 'TT / MM / JJJJ',
              dateFormat: 'dd.MM.yyyy HH:mm',
              label: 'Termin auswählen',
              defaultValue: '',
              editDisabled: true,
              showTimeSelect: true,
              timeFormat: 'HH:mm',
              includeDates: availableDates,
              includeTimes: selectedDay ? availableTimes?.[selectedDay] : [],
              timeIntervals: 15,
              onMonthChange: setSelectedMonth,
              validation: [
                {
                  name: 'required'
                }
              ]
            },
            {
              name: 'comment',
              type: 'text',
              disabled: overlay,
              data: [],
              placeholder: 'Text',
              label: 'Ihre Nachricht an uns:',
              defaultValue: '',
              validation: []
            }
          ]
        }
      ]
    };
  }, [selectedDay, availableDates, availableTimes, overlay]);

  const handleSubmmitForm = async () => {
    try {
      showOverlay();
      const utcDate = moment.utc(forms?.meetingBooking?.values.meeting_date).toDate();
      const localDate = moment(utcDate).local().format('YYYY-MM-DDTHH:mm:ss');
      const response = await createBooking(
        forms?.meetingBooking?.values.duration,
        localDate,
        user.name,
        user.email,
        forms?.meetingBooking?.values.comment
      );
      setMeetingInfo({
        id: response?.Id,
        startDate: response?.StartInCustomerTimeZone,
        duration: response?.ServiceId
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      hideOverlay();
    }
  };

  return (
    <div>
      <div className={classes.description_container}>
        <p className={classes.description}>Buche einen Termin mit unserer Vertriebsleiterin Sarah Adrian.</p>
        <div className={classes.image_container}>
          <img src='/assets/images/photos/sarah_adrian_meeting_modal.png' alt='support-photo' />
        </div>
      </div>

      <div className={classes.form_container}>
        <h3 className={classes.form_title}>Wähle aus den folgenden Optionen:</h3>
        <TempForm name={'meetingBooking'} formFields={formFields} />
      </div>

      <div className={classes.action_container}>
        <button
          onClick={closeModal}
          disabled={overlay}
          className={classNames(classes.action_button, 'button-mobexo__secondary', overlay && classes.disabled_button)}
        >
          Abbrechen
        </button>
        <button
          onClick={handleSubmmitForm}
          disabled={!forms?.meetingBooking?.isFormValid || overlay}
          className={classNames(
            classes.action_button,
            'button-mobexo__main',
            classes.booking_button,
            (!forms?.meetingBooking?.isFormValid || overlay) && classes.disabled_button
          )}
        >
          Buchen
        </button>
      </div>
    </div>
  );
};

export default MeetingBooking;
