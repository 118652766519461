import { useQuery } from 'react-query';
import { ConditionOperator, ReactQueryKeys } from 'common/enums';
import { getCompanyCarModels, getCompanyData, getRemindersSettings } from 'services/company-service';
import { getServicesByAccount } from 'services/services-service';
import { useIsParentCompany } from 'hooks/react-query/use-is-parent-company';
import { crmDataTypes, RequestStatuses } from '../../constants';
import { useOverlay } from 'hooks/index';
import { useEffect, useState } from 'react';
import { getEntityByFieldQuery } from 'services/crm-entity-service';
import { getResponseData, getResponseFormData } from 'utils/get-response-data';
import { groupArrayBy } from 'utils/group-array-by';
import { AnyObject } from 'common/interfaces';

const modifyResponse = ({ data }: any) => {
  if (data.level === RequestStatuses.Success) {
    return data;
  }
  return undefined;
};

const useCompanyData = (companyId: string, enabled: boolean = true) => {
  const [showOverlay, hideOverlay] = useOverlay('useCompanyData');
  const [companyData, setCompanyData] = useState<AnyObject | null>(null);
  const { isParentCompany, parentCompanyId } = useIsParentCompany(companyId, true);

  const { data: companyResponse, isLoading: isCompanyResponseLoading } = useQuery(
    [ReactQueryKeys.CompanyDataResponse, companyId, ReactQueryKeys.CompanyDataCompanyResponse],
    () => getCompanyData(companyId),
    {
      enabled: !!companyId && isParentCompany !== undefined && enabled
    }
  );

  const { data: ibanResponse, isLoading: isIbanResponseLoading } = useQuery(
    [ReactQueryKeys.CompanyDataResponse, companyId, ReactQueryKeys.CompanyDataIbanResponse],
    () =>
      getEntityByFieldQuery({
        entityName: 'uds_iban',
        columns: ['uds_ibanid', 'uds_name', 'statecode'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.Lookup,
            attributeName: 'uds_accountid',
            conditionOperator: ConditionOperator.Equal,
            value: [
              {
                id: companyId,
                logicalName: '',
                name: ''
              }
            ]
          }
        ]
      }),
    {
      enabled: !!companyId && isParentCompany !== undefined && enabled,
      select: modifyResponse
    }
  );

  const { data: servicesResponse, isLoading: isServicesResponseLoading } = useQuery(
    [ReactQueryKeys.CompanyDataResponse, companyId, ReactQueryKeys.CompanyDataServicesResponse],
    () => getServicesByAccount(isParentCompany ? companyId : parentCompanyId),
    {
      enabled: !!companyId && isParentCompany !== undefined && enabled
    }
  );

  const { data: modelsResponse, isLoading: isModelsResponseLoading } = useQuery(
    [ReactQueryKeys.CompanyDataResponse, companyId, ReactQueryKeys.CompanyDataModelsResponse],
    () => getCompanyCarModels(isParentCompany ? companyId : parentCompanyId),
    {
      enabled: !!companyId && isParentCompany !== undefined && enabled
    }
  );

  const { data: reminderSettingsResponse, isLoading: isReminderSettingsResponseLoading } = useQuery(
    [ReactQueryKeys.CompanyDataResponse, companyId, ReactQueryKeys.CompanyDataReminderSettingsResponse],
    () => getRemindersSettings(companyId),
    {
      enabled: !!companyId && isParentCompany !== undefined && enabled
    }
  );

  useEffect(() => {
    if (
      isCompanyResponseLoading ||
      isIbanResponseLoading ||
      isServicesResponseLoading ||
      isModelsResponseLoading ||
      isReminderSettingsResponseLoading
    ) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [
    isCompanyResponseLoading,
    isIbanResponseLoading,
    isServicesResponseLoading,
    isModelsResponseLoading,
    isReminderSettingsResponseLoading
  ]);

  const getServicesValues = (servicesResponse: AnyObject) => {
    const result: AnyObject = {};
    servicesResponse.data.data.forEach((service: AnyObject) => {
      result[service.uds_serviceleistungid.attributeValue] = service.regelCount;
    });

    return result;
  };
  const getModelsValues = (modelsResponse: AnyObject) => {
    const result: AnyObject = {};
    const models = getResponseFormData(modelsResponse.data, [{ name: null, type: 'array' }]) as AnyObject[];

    result['groupedModels'] = groupArrayBy(models, 'uds_carbrandid');

    return result;
  };

  useEffect(() => {
    if (companyResponse && ibanResponse && servicesResponse && modelsResponse && reminderSettingsResponse) {
      const activeIban = (getResponseFormData(ibanResponse, [{ name: 'entityes', type: 'array' }]) as AnyObject[]).find(
        iban => !iban.statecode
      );

      const companyData = {
        ...getResponseData(companyResponse.data, [0]),
        iban: {
          attributeTypeCode: 6,
          attributeValue: activeIban
            ? {
                id: activeIban.uds_ibanid,
                logicalName: 'uds_iban',
                name: activeIban.uds_name
              }
            : null
        },
        ...getServicesValues(servicesResponse),
        ...getModelsValues(modelsResponse),
        uds_iserinnerungen_weitereaufgaben_fuhrparkle:
          reminderSettingsResponse.data.data.uds_iserinnerungen_weitereaufgaben_fuhrparkle,
        uds_iserinnerungen_weitereaufgaben_kst_verant:
          reminderSettingsResponse.data.data.uds_iserinnerungen_weitereaufgaben_kst_verant,
        uds_iserinnerungen_weitereaufgaben_fahrer:
          reminderSettingsResponse.data.data.uds_iserinnerungen_weitereaufgaben_fahrer,
        uds_ispoolcar_erinnerungen_weitereaufgab_fuhr:
          reminderSettingsResponse.data.data.uds_ispoolcar_erinnerungen_weitereaufgab_fuhr,
        uds_ispoolcar_erinnerungen_weitereaufgab_kstw:
          reminderSettingsResponse.data.data.uds_ispoolcar_erinnerungen_weitereaufgab_kstw,
        uds_ispoolcar_erinnerungen_weitereaufgab_fahr:
          reminderSettingsResponse.data.data.uds_ispoolcar_erinnerungen_weitereaufgab_fahr,
        ibans: ibanResponse.data?.entityes
      };
      setCompanyData(companyData);
    }
  }, [companyResponse, ibanResponse, servicesResponse, modelsResponse, reminderSettingsResponse]);

  return { companyData };
};

export default useCompanyData;
