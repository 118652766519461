import React from 'react';
import { UnauthorizedPageContainer } from 'components/unauthorized-page-container';
import { UNAUTHORIZED_PAGES } from 'common/enums';
import HelmetComponent from 'components/helmet-component/helmet-component';
import useReleaseData from './hooks/use-release-data';
import { ReleaseStatuses } from 'pages/release-external-page/hooks/use-release-data/use-release-data.types';
import ApproveComponent from './approve-component';
import ReleaseExternalPlaceholder from './release-external-placeholder';
import { ReleaseExternalPlaceholderProps } from './release-external-placeholder/release-external-placeholder.types';

const getProps = (status?: ReleaseStatuses, email?: string): ReleaseExternalPlaceholderProps => {
  switch (status) {
    case ReleaseStatuses.Signed:
      return { title: 'Hallo!', description: 'Sie haben die Freigabe bereits erteilt.' };
    case ReleaseStatuses.Decline:
      return { title: 'Hallo!', description: 'Sie haben die Freigabe bereits abgelehnt.' };
    case ReleaseStatuses.Expired:
      return {
        title: 'Der angefragte Link ist ungültig oder abgelaufen.',
        description: (
          <span>
            Sie können einen neuen Link anfordern. Dieser wird Ihnen sofort per E-Mail an <b>{email || ''}</b>{' '}
            zugeschickt.
          </span>
        ),
        isShowButton: true
      };
    default:
      return { title: 'Error 404', description: 'Seite nicht gefunden!' };
  }
};

const ReleaseExternalPage = () => {
  const { status, email, isReleaseDataLoading } = useReleaseData();

  return (
    <UnauthorizedPageContainer>
      <HelmetComponent title={UNAUTHORIZED_PAGES.RELEASE} />
      {!isReleaseDataLoading ? (
        status === ReleaseStatuses.Wait ? (
          <ApproveComponent />
        ) : (
          <ReleaseExternalPlaceholder {...getProps(status, email)} />
        )
      ) : null}
    </UnauthorizedPageContainer>
  );
};

export default ReleaseExternalPage;
