import styled from 'styled-components';
import { css } from 'styled-components';

export const WidgetsContainer = styled.div<{ amountOfWidgetsToShow: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 20px;
  grid-area: leasingWidgets;

  @media screen and (max-width: ${({ theme }) => theme.media.wideLaptop}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${({ theme }) => theme.media.tabletHorizontal}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const WidgetPlaceholder = styled.div<{ amountOfWidgetsToShow: number }>`
  background-color: ${({ theme }) => theme.colors.white};
  display: ${({ amountOfWidgetsToShow }) => (amountOfWidgetsToShow === 3 ? 'none' : 'block')};

  @media screen and (max-width: ${({ theme }) => theme.media.wideLaptop}) {
    display: ${({ amountOfWidgetsToShow }) => ([1, 3, 5].includes(amountOfWidgetsToShow) ? 'block' : 'none')};
  }

  @media screen and (min-width: ${({ theme }) => theme.media.wideLaptop}) {
    ${({ amountOfWidgetsToShow }) =>
      [1, 4].includes(amountOfWidgetsToShow) &&
      css`
        grid-column-start: 2;
        grid-column-end: 4;
      `}
  }

  @media screen and (max-width: ${({ theme }) => theme.media.tabletHorizontal}) {
    display: none;
  }
`;
