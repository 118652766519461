import React from 'react';

const ErsatzfahrzeugIcon = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='60' height='60' fill='white' />
      <path
        d='M43.187 42.4195H40.2568V46.083C40.2568 46.5227 40.5498 46.8157 40.9893 46.8157H48.315C48.7545 46.8157 49.0476 46.5227 49.0476 46.083V41.5402L49.6336 38.7559C49.7069 38.243 49.7801 37.8034 49.7801 37.2905V30.1099C49.7801 29.2307 49.6336 28.3514 49.2673 27.4722L48.5348 25.5671H51.7581C52.1976 25.5671 52.5639 25.1275 52.4906 24.6878L51.9046 22.3432C51.758 21.6837 51.172 21.2441 50.5127 21.2441H47.1429L44.3591 15.6022C43.6266 14.1368 42.0882 13.1843 40.4033 13.1843H19.6717C17.9868 13.1843 16.4484 14.1368 15.7159 15.6022L12.9321 21.2441H9.48907C8.82976 21.2441 8.24372 21.6837 8.0972 22.3432L7.51114 24.6878C7.43788 25.1275 7.73091 25.5671 8.2437 25.5671H11.467L10.7344 27.4722C10.4414 28.2781 10.2216 29.1574 10.2216 30.1099V37.2905C10.2216 37.8034 10.2949 38.3163 10.3682 38.7559L10.9542 41.5402V46.083C10.9542 46.5227 11.2472 46.8157 11.6868 46.8157H19.0124C19.452 46.8157 19.745 46.5227 19.745 46.083V42.4195H16.8147'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.0117 42.4186H41.7212'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6172 32.1619H20.6975L19.6719 30.1104'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45.385 32.1619H39.3047L40.3303 30.1104'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.1406 37.2912H35.8617'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5469 23.3689H42.4541'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ErsatzfahrzeugIcon;
