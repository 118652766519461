import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const PlumpLeftRightArrowIcon = ({
  width = 24,
  height = 24,
  color: colorProps,
  fill: fillProps,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProps || defaultColor;
  const fill = fillProps || defaultFill;
  return (
    <svg width={width} height={height} viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M7.17383 6.87826L12.6706 6.87826L12.6706 8.78947L18.5339 4.96706L12.6706 1.14465L12.6706 3.05585L10.4518 3.05585'
          fill='white'
        />
      )}
      {hovered && (
        <path
          d='M12.4297 7.12607L6.99989 7.12607L6.99989 5.23816L1.20811 9.01398L6.99989 12.7898L6.99989 10.9019L9.19171 10.9019'
          fill={fill}
        />
      )}
      <path
        d='M10.2 11H7V13L1 9L7 5V7H12.6V9L19 5L12.6 1V3H10.2'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
