import React from 'react';
import { Flex } from 'common/common-components.styled';
import { CalendarItemProps } from './calendar-item.props';
import { StyledContainer, StyledDate, StyledDescription } from './calendar-item.styled';
import moment from 'moment';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

export const CalendarWidgetItem = ({
  type,
  date,
  description,
  idPrefix,
  index,
  ...restDivProps
}: CalendarItemProps) => {
  const momentDate = moment(date).utc();

  return (
    <StyledContainer
      id={`${idPrefix}Item${index}`}
      data-date-type={type}
      pointer={!!restDivProps.onClick}
      type={type}
      {...restDivProps}
    >
      <Flex flex={1}>
        <StyledDate id={`${idPrefix}ItemDate${index}`} type={type}>
          {momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : '-'}
        </StyledDate>
      </Flex>
      <Flex flex={2} left='10' overflow='hidden'>
        <EllipsisWithTooltip tooltip={description || '-'}>
          <StyledDescription id={`${idPrefix}ItemDescription${index}`} type={type}>
            {description || '-'}
          </StyledDescription>
        </EllipsisWithTooltip>
      </Flex>
    </StyledContainer>
  );
};
