import React from 'react';

const StarFullBlue = (margin = { margin: 0 }) => {
  return (
    <svg
      style={{ marginRight: margin.margin, marginLeft: margin.margin }}
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.1741 20.688L18.1907 20.6975L18.1927 20.6981C18.1965 20.6991 18.2024 20.7 18.2096 20.7C18.2295 20.7 18.2427 20.6986 18.2502 20.6975C18.2576 20.6964 18.261 20.6953 18.261 20.6953C18.261 20.6953 18.2669 20.6922 18.2747 20.6861L18.3049 20.6626L18.3364 20.641C18.3462 20.6342 18.3518 20.6279 18.3574 20.6138C18.3642 20.5968 18.3724 20.562 18.3651 20.5104L17.1869 13.6641L22.1753 8.77168L22.1883 8.7596C22.2145 8.73525 22.2265 8.69407 22.2137 8.6557C22.201 8.61767 22.1828 8.59522 22.1672 8.58183C22.1528 8.56952 22.1317 8.55723 22.0981 8.55112L15.2183 7.56375L12.1331 1.32971L12.1309 1.3285C12.1083 1.31637 12.0601 1.3 11.9937 1.3C11.9274 1.3 11.8791 1.31637 11.8565 1.3285L11.8543 1.32971L8.76913 7.56375L1.89892 8.54975C1.87731 8.55459 1.84897 8.56688 1.82226 8.58929C1.80474 8.604 1.79262 8.61905 1.78451 8.63266C1.77863 8.67226 1.78114 8.70616 1.78677 8.72966C1.79021 8.74402 1.79416 8.75207 1.79602 8.75534C1.79759 8.75811 1.79918 8.7596 1.79918 8.7596L1.81219 8.77168L6.80053 13.6641L5.6225 20.5094C5.6187 20.5386 5.62263 20.5693 5.63466 20.5986C5.64758 20.6299 5.66649 20.6515 5.68336 20.6639L5.69463 20.6722C5.72649 20.6961 5.74597 20.6988 5.75522 20.6994C5.76661 20.7002 5.7923 20.6992 5.83151 20.6784L11.9937 17.4217L18.1741 20.688ZM4.33905 20.3022L5.4038 14.1151L0.91459 9.71223C0.511713 9.33813 0.396604 8.76259 0.540489 8.24461C0.713151 7.72662 1.17358 7.35252 1.69157 7.26619L7.9074 6.3741L10.6988 0.733813C11.188 -0.244604 12.7995 -0.244604 13.2887 0.733813L16.0801 6.3741L22.2959 7.26619C22.8426 7.35252 23.2743 7.72662 23.447 8.24461C23.6196 8.76259 23.4757 9.33813 23.0729 9.71223L18.5837 14.1151L19.6484 20.3022C19.7347 20.8489 19.5333 21.3957 19.0729 21.7122C18.8139 21.9137 18.5261 22 18.2096 22C17.9793 22 17.7491 21.9424 17.5477 21.8273L11.9937 18.8921L6.43977 21.8273C5.95056 22.0863 5.37502 22.0576 4.91459 21.7122C4.48294 21.3957 4.25272 20.8489 4.33905 20.3022Z'
        fill='#ACD2E6'
      />
      <path
        d='M18.1907 20.6975L18.1741 20.688L11.9937 17.4217L5.83151 20.6784C5.7923 20.6992 5.76661 20.7002 5.75522 20.6994C5.74597 20.6988 5.72649 20.6961 5.69463 20.6722L5.68336 20.6639C5.66649 20.6515 5.64758 20.6299 5.63466 20.5986C5.62263 20.5693 5.6187 20.5386 5.6225 20.5094L6.80053 13.6641L1.81219 8.77168L1.79918 8.7596C1.79918 8.7596 1.79759 8.75811 1.79602 8.75534C1.79416 8.75207 1.79021 8.74402 1.78677 8.72966C1.78114 8.70616 1.77863 8.67226 1.78451 8.63266C1.79262 8.61905 1.80474 8.604 1.82226 8.58929C1.84897 8.56688 1.87731 8.55459 1.89892 8.54975L8.76913 7.56375L11.8543 1.32971L11.8565 1.3285C11.8791 1.31637 11.9274 1.3 11.9937 1.3C12.0601 1.3 12.1083 1.31637 12.1309 1.3285L12.1331 1.32971L15.2183 7.56375L22.0981 8.55112C22.1317 8.55723 22.1528 8.56952 22.1672 8.58183C22.1828 8.59522 22.201 8.61767 22.2137 8.6557C22.2265 8.69407 22.2145 8.73525 22.1883 8.7596L22.1753 8.77168L17.1869 13.6641L18.3651 20.5104C18.3724 20.562 18.3642 20.5968 18.3574 20.6138C18.3518 20.6279 18.3462 20.6342 18.3364 20.641L18.3049 20.6626L18.2747 20.6861C18.2669 20.6922 18.261 20.6953 18.261 20.6953C18.261 20.6953 18.2576 20.6964 18.2502 20.6975C18.2427 20.6986 18.2295 20.7 18.2096 20.7C18.2024 20.7 18.1965 20.6991 18.1927 20.6981L18.1907 20.6975Z'
        fill='#ACD2E6'
      />
    </svg>
  );
};

export default StarFullBlue;
