import de from 'date-fns/locale/de';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types'; //
import React, { Component, createRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withFetchData } from '../../hoc/withFetchData';
import toMoney from '../../utils/toMoney';
import Autocomplete from './autocomplete';
import Chips from './chips';
import classes from './form.module.scss';
import Switch from 'react-switch';
import InfoHint from '../info-hint';
import { UploadTestReportIcon } from '../icons/upload-test-report-icon';
import { File } from './file/file';
import { Tooltip } from '../tooltip/tooltip';
import { MODALS, MODAL_NAMES } from '../../constants';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { PrefixForId } from '../../common/enums';
import { gapsIntoCamelCase } from '../../utils/convert-sting';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { regexValue } from '../../utils/regex';

registerLocale('de', de);

class TempForm extends Component {
  defaultItemsCount = 5;

  constructor(props) {
    super(props);
    this.multiplyCheckboxRef = createRef();
    this.closeMultiplyCheckbox = this.closeMultiplyCheckbox.bind(this);
  }

  state = {
    multiplyCheckboxValue: [],
    isCheckboxListVisible: false,
    itemsCount: this.defaultItemsCount
  };

  getForm = () => {
    let values = {};
    let untouched = {};

    getFields(this.props.formFields, field => {
      untouched[field.name] = true;
      if (field.type === 'divider' || (field.hidden && field.deleteFormValueIfHidden)) {
        return;
      }
      if (field.type === 'date') {
        values[field.name] = field.defaultValue ? field.defaultValue : null;
        return;
      }
      if (field.type === 'toggle') {
        values[field.name] = typeof field.defaultValue === 'boolean' ? field.defaultValue : null;
        return;
      }
      values[field.name] = field.defaultValue ? field.defaultValue : '';
    });
    return { values, untouched, errors: {}, isFormValid: true };
  };

  onInput = event => {};

  fieldChanged = (event, field) => {
    let value = null;
    switch (field.type) {
      case 'text':
      case 'number':
      case 'password':
      case 'optionSet':
      case 'range':
      case 'textarea':
      case 'radio':
        value = event.target.value;
        break;
      case 'chips':
      case 'toggle':
      case 'toggleWithTooltip':
      case 'checkbox':
        value = event;
        break;
      case 'lookup':
        value = event.id;
        break;
      case 'date':
        value = event || null;
        break;
      case 'file':
        value = event;
        break;
      case 'money':
        value = event.floatValue;
        break;

      case 'multipleCheckbox':
        value = this.state.multiplyCheckboxValue || [];
        break;

      default:
        return;
    }

    const handleChange = () => {
      if (field.valueChangedEvent) {
        this.props.valueChanged(field.name, value, newValues[field.name], event.value);
      }
      newValues[field.name] = value;
      if (newUntouchedFields[field.name]) {
        delete newUntouchedFields[field.name];
      }
    };

    const checkLimit = () => {
      if (typeof value === 'number') {
        return value < field.limit.max && value > field.limit.min;
      } else if (typeof value === 'string') {
        return true;
      }
    };

    const newValues = cloneDeep(this.props.form.values);
    const newUntouchedFields = cloneDeep(this.props.form.untouched);

    if ((field.limit && checkLimit()) || !field.limit) {
      handleChange();
    }

    if (field.limit && !value) {
      handleChange();
    }
    this.props.setForm({ ...this.props.form, values: newValues, untouched: newUntouchedFields });
    if (this.props.handleAfterChange) {
      this.props.handleAfterChange(newValues);
    }
  };

  validate = () => {
    if (!this.props.form) {
      return;
    }
    let errors = {};

    getFields(this.props.formFields, field => {
      if (
        field.validation?.length &&
        !field.hidden &&
        field.type !== 'divider' &&
        this.props.form.values.hasOwnProperty(field.name)
      ) {
        const validatorsValue = [];
        field.validation.forEach(validator => {
          switch (validator.name) {
            case 'required':
              if (
                [
                  'text',
                  'number',
                  'date',
                  'optionSet',
                  'lookup',
                  'password',
                  'file',
                  'checkbox',
                  'textarea',
                  'money'
                ].includes(field.type)
              ) {
                validatorsValue.push(!this.props.form.values[field.name] ? 'required' : false);
              }
              break;
            case 'minLength':
              validatorsValue.push(this.props.form.values[field.name].length < validator.value ? 'minLength' : false);
              break;
            case 'maxLength':
              validatorsValue.push(this.props.form.values[field.name].length > validator.value ? 'maxLength' : false);
              break;
            case 'maxValue':
              validatorsValue.push(this.props.form.values[field.name] > validator.value ? 'maxValue' : false);
              break;
            case 'minValue':
              validatorsValue.push(this.props.form.values[field.name] < validator.value ? 'minValue' : false);
              break;
            case 'email':
              validatorsValue.push(
                this.props.form.values[field.name]
                  ? this.props.form.values[field.name].match(regexValue.email)
                    ? false
                    : 'email'
                  : false
              );
              break;
            case 'equal':
              validatorsValue.push(
                this.props.form.values[field.name] === this.props.form.values[validator.value] ? false : 'equal'
              );
              break;
            case 'regexp':
              validatorsValue.push(
                String(this.props.form.values[field.name]).match(validator.value) ? false : 'regexp'
              );
              break;

            default:
              break;
          }
        });
        errors[field.name] = validatorsValue.some(value => value) ? validatorsValue.find(value => value) : false;
      }
    });

    if (!isEqual(this.props.form.errors, errors)) {
      const isFormValid = Object.values(errors).every(value => !value);
      this.props.setForm({ ...this.props.form, errors, isFormValid });
    }
  };

  closeMultiplyCheckbox(event) {
    const parentMultiplyCheckboxRef = event.target.closest('#multipleCheckbox');

    if (parentMultiplyCheckboxRef === this.multiplyCheckboxRef.current && this.multiplyCheckboxRef.current !== null) {
      return event.preventDefault();
    }
    this.setState({ isCheckboxListVisible: false });
  }

  componentDidMount() {
    if (!this.props.form) {
      const form = this.getForm();
      this.props.setForm(form);
    }

    document.addEventListener('click', this.closeMultiplyCheckbox);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.form, prevProps.form) || !isEqual(this.props.formFields, prevProps.formFields)) {
      this.validate();
      const form = this.getForm();
      if (Object.keys(form.values).length !== Object.keys(this.props.form.values).length) {
        const newForm = cloneDeep(this.props.form);
        Object.entries(form.values).forEach(prop => {
          if (!newForm.values.hasOwnProperty(prop[0])) {
            newForm.values[prop[0]] = prop[1];
          }
        });
        Object.entries(this.props.form.values).forEach(prop => {
          if (!form.values.hasOwnProperty(prop[0])) {
            delete newForm.values[prop[0]];
          }
        });

        this.props.setForm(newForm);
      }
    }
  }

  modal = ({ type = MODALS.alert, name = MODAL_NAMES.alert, title, children, button }, submitHandler) => {
    const modalData = {
      title: title,
      children: children,
      buttons: [
        {
          type: 'cancel',
          title: button.closeTitle || 'Abbrechen',
          action: () => this.props.closeAlert(type)
        },
        {
          type: 'submit',
          title: button.submitTitle,
          action: () => {
            button.submitHandler && button.submitHandler();
            submitHandler();
            this.props.closeAlert(type);
          }
        }
      ]
    };

    this.props.toggleModal(type, name, modalData);
  };

  switchHandler(event, field) {
    if (field.modal) {
      const valuesToHide = field?.modal?.valuesToHide;

      if (valuesToHide) {
        const isModalHidden = valuesToHide.some(valueToHide => valueToHide === event);

        if (isModalHidden) {
          return this.fieldChanged(event, field);
        }

        return this.modal(field.modal, () => this.fieldChanged(event, field));
      }

      return this.modal(field.modal, () => this.fieldChanged(event, field));
    }

    this.fieldChanged(event, field);
  }

  multiplyCheckboxHandler(name, field) {
    const value = this.state.multiplyCheckboxValue;

    if (value.indexOf(name) > -1) {
      this.setState(
        {
          multiplyCheckboxValue: value.filter(checkboxValue => checkboxValue !== name)
        },
        () => this.fieldChanged(undefined, field)
      );
      return;
    }

    this.setState(
      {
        multiplyCheckboxValue: [...value, name]
      },
      () => this.fieldChanged(undefined, field)
    );
  }

  switchMultiplyCheckbox(field) {
    this.setState(
      { isCheckboxListVisible: !this.state.isCheckboxListVisible, itemsCount: this.defaultItemsCount },
      () => this.fieldChanged(undefined, field)
    );
  }

  clearMultiplyCheckbox(field) {
    this.setState({ multiplyCheckboxValue: [] }, () => this.fieldChanged(undefined, field));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMultiplyCheckbox);
    this.props.deleteForm();
  }

  showMoreItems() {
    this.setState(prevState => ({ itemsCount: prevState.itemsCount * 2 }));
  }

  render() {
    const { isCheckboxListVisible, itemsCount } = this.state;
    const { formFields, form, className, name } = this.props;

    if (!form) {
      return null;
    }
    return (
      <form
        className={className ? `${classes.form} ${className}` : classes.form}
        onSubmit={event => event.preventDefault()}
        id={name + PrefixForId.Form}
      >
        {formFields.columns.map((column, i) => (
          <section
            key={i}
            style={{ width: column.width }}
            className={classNames({ [classes.form_column]: column.width })}
          >
            {column.fields.map(field => {
              if (field.hidden || !form.values.hasOwnProperty(field.name)) {
                return null;
              }
              const isRequired = field.validation?.some(item => item.name === 'required');
              if (field.type === 'text') {
                return (
                  <div
                    key={field.name}
                    className={
                      (field.customError || form.errors[field.name]) && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      {field.prefix && <span className={classes.field_prefix}>{field.prefix}</span>}
                      <input
                        style={{ width: field.prefix ? 'calc(100% - 42px)' : '100%' }}
                        className={classes.form_input}
                        name={field.name}
                        disabled={!!field.disabled}
                        placeholder={field.placeholder}
                        type='text'
                        value={field.upperCase ? form.values[field.name].toUpperCase() : form.values[field.name]}
                        onChange={event => this.fieldChanged(event, field)}
                        id={name + PrefixForId.FormText + capitalize(field.name)}
                      />
                    </div>
                    {(field.customError || form.errors[field.name]) &&
                    !form.untouched[field.name] &&
                    field.errorMessage ? (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    ) : null}
                  </div>
                );
              }
              if (field.type === 'number') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                        {field.tooltip && (
                          <Tooltip {...field.tooltip} descriptionClassName={classes.tooltip_description} />
                        )}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      {field.prefix && <span className={classes.field_prefix}>{field.prefix}</span>}
                      <input
                        style={{ width: field.prefix ? 'calc(100% - 42px)' : '100%' }}
                        className={classes.form_input}
                        name={field.name}
                        placeholder={field.placeholder}
                        type='number'
                        disabled={field.disabled}
                        value={form.values[field.name]}
                        onChange={event => this.fieldChanged(event, field)}
                      />
                    </div>
                    {form.errors[field.name] && !form.untouched[field.name] && field.errorMessage && (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    )}
                  </div>
                );
              }
              if (field.type === 'money') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                        {field.tooltip && (
                          <Tooltip {...field.tooltip} descriptionClassName={classes.tooltip_description} />
                        )}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      {field.prefix && <span className={classes.field_prefix}>{field.prefix}</span>}
                      <NumberFormat
                        style={{ width: field.prefix ? 'calc(100% - 42px)' : '100%' }}
                        value={form.values[field.name]}
                        className={classes.form_input}
                        displayType='input'
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix=' €'
                        placeholder={field.placeholder}
                        fixedDecimalScale={true}
                        decimalScale={field.decimalScale}
                        onValueChange={event => this.fieldChanged(event, field)}
                      />
                    </div>
                    {form.errors[field.name] && !form.untouched[field.name] && field.errorMessage ? (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    ) : null}
                  </div>
                );
              }
              if (field.type === 'password') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      {field.prefix && <span className={classes.field_prefix}>{field.prefix}</span>}
                      <input
                        style={{ width: field.prefix ? 'calc(100% - 42px)' : '100%' }}
                        className={classes.form_input}
                        name={field.name}
                        placeholder={field.placeholder}
                        type='password'
                        value={form.values[field.name]}
                        onChange={event => this.fieldChanged(event, field)}
                      />
                    </div>
                  </div>
                );
              }
              if (field.type === 'optionSet') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                        {field.tooltip && (
                          <Tooltip
                            {...field.tooltip}
                            descriptionClassName={`${field.tooltip.descriptionClassName} ${classes.tooltip_description}`}
                          />
                        )}
                        {field.action || null}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      <select
                        className={
                          !field.options.length
                            ? `${classes.form_input} ${classes.disabled_select}`
                            : classes.form_input
                        }
                        disabled={field.disabled}
                        value={form.values[field.name]}
                        onChange={event => this.fieldChanged(event, field)}
                        name={field.name}
                        id={name + PrefixForId.FormSelect + capitalize(field.name)}
                      >
                        <option
                          value={''}
                          hidden={field.options.length}
                          id={name + PrefixForId.FormOption + PrefixForId.Placeholder + capitalize(field.name)}
                        >
                          {field.options.length
                            ? field.placeholder
                            : field.emptyOptionsPlaceholder || 'Keine Auswahl möglich'}
                        </option>
                        {field.options.map(option => (
                          <option
                            key={option.id}
                            value={option.id}
                            id={
                              name +
                              PrefixForId.FormOption +
                              capitalize(field.name) +
                              (option.id || capitalize(gapsIntoCamelCase(option.label)))
                            }
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                );
              }
              if (field.type === 'chips') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && <span className={classes.label}>{field.label}</span>}
                    <Chips
                      chips={form.values[field.name]}
                      onChange={event => this.fieldChanged(event, field)}
                      name={field.name}
                      placeholder={field.placeholder}
                    />
                  </div>
                );
              }
              if (field.type === 'range') {
                return (
                  <div key={field.name} className={`${classes.form_field} ${classes.range_field}`}>
                    <h4>{toMoney(parseFloat(form.values[field.name]))}</h4>
                    <p>{field.label}</p>
                    <input
                      className={classes.form_input}
                      name={field.name}
                      min={field.min}
                      max={field.max}
                      step={field.step}
                      type='range'
                      value={form.values[field.name]}
                      onChange={event => this.fieldChanged(event, field)}
                    />
                  </div>
                );
              }
              if (field.type === 'lookup') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                        {field.link && (
                          <Link className={classes.field_link} to={field.link}>
                            Neue Fahrer anlegen
                          </Link>
                        )}
                        {field.tooltip && (
                          <Tooltip
                            {...field.tooltip}
                            descriptionClassName={`${field.tooltip.descriptionClassName} ${classes.tooltip_description}`}
                          />
                        )}
                      </span>
                    )}
                    {field.infoMessage && (
                      <>
                        <InfoHint type={field.infoType || undefined}>{field.infoMessage}</InfoHint>
                        <div className='mb-1' />
                      </>
                    )}
                    <div className={classes.input_container}>
                      <Autocomplete
                        extendedShowMore={field.extendedShowMore}
                        request={field.request}
                        pagination={field.pagination}
                        hasmoreRecords={field.hasmoreRecords}
                        inputValue={field.inputValue}
                        data={field.data}
                        shouldFilterAutocompleteData={field.shouldFilterAutocompleteData ?? true}
                        error={form.errors[field.name] && !form.untouched[field.name]}
                        allowCreateNew={field.allowCreateNew || false}
                        addNewTitle={field.addNewTitle}
                        updateLookupData={this.props.updateLookupData}
                        value={form.values[field.name]}
                        disabled={!!field.disabled}
                        isWarning={field.isWarning}
                        clearOnFocus={field.clearOnFocus ?? true}
                        onChange={event => this.fieldChanged(event, field)}
                        name={field.name}
                        loading={field.loading}
                        placeholder={field.placeholder}
                        companyId={this.props.companyId}
                        withModal={field.withModal}
                      />
                    </div>
                    {form.errors[field.name] &&
                    !form.untouched[field.name] &&
                    field.errorMessage &&
                    !field.isWarning ? (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    ) : !form.untouched[field.name] && field.warningMessage && field.isWarning ? (
                      <span className={classes.warning_message}>
                        <img src={'/assets/images/warning.svg'} alt='warning' />
                        {field.warningMessage}
                      </span>
                    ) : null}
                  </div>
                );
              }
              if (field.type === 'date') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                    onClick={event => event.stopPropagation()}
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                      </span>
                    )}
                    <div className={classes.input_container}>
                      {field.prefix ? (
                        <span className={classes.field_prefix}>{field.prefix}</span>
                      ) : (
                        <span className={classes.field_prefix}>
                          <img src='/assets/images/datepicker_icon.png' alt='datepicker_icon' />
                        </span>
                      )}

                      <DatePicker
                        selected={form.values[field.name]}
                        className={classes.form_input}
                        dateFormat={field.dateFormat ?? 'dd . MM . yyyy'}
                        locale='de'
                        popperClassName='mobexo-datepicker'
                        placeholderText={field.placeholder}
                        onChange={event => this.fieldChanged(event, field)}
                        minDate={field.minDate}
                        maxDate={field.maxDate}
                        portalId='root-portal'
                        id={name + PrefixForId.Form + PrefixForId.FormDate + capitalize(field.name)}
                        disabled={field.disabled}
                        required={isRequired}
                        showTimeSelect={field.showTimeSelect}
                        timeFormat={field.timeFormat}
                        onMonthChange={e => (field.onMonthChange ? field.onMonthChange(moment(e).month()) : null)}
                        timeIntervals={field.timeIntervals}
                        includeDates={field?.includeDates}
                        includeTimes={field.includeTimes}
                      />
                    </div>
                  </div>
                );
              }
              if (field.type === 'divider') {
                return <div key={field.name} className={classes.divider} />;
              }
              if (field.type === 'toggle') {
                return (
                  <div key={field.name}>
                    {field?.infoHint && (
                      <InfoHint type={field?.infoHint?.type}>{field?.infoHint?.infoMessage}</InfoHint>
                    )}
                    <div className={`${classes.form_field} ${classes.toggle_field}`}>
                      {field.label && (
                        <div className={classes.label_wrapper}>
                          <span>{field.label}</span>

                          {column.tooltip && (
                            <Tooltip {...column.tooltip} descriptionClassName={classes.tooltip_description} />
                          )}
                        </div>
                      )}

                      <Switch
                        offColor={'#bfbfbf'}
                        onColor={'#335566'}
                        height={20}
                        className={classes.toggle}
                        width={40}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onChange={event => this.switchHandler(event, field)}
                        checked={!!form.values[field.name]}
                        disabled={field.disabled}
                      />

                      <span className={classes.toggle_value}>
                        {form.values[field.name] ? field.labelActiveValue || 'ja' : field.labelInActiveValue || 'nein'}
                      </span>
                    </div>
                  </div>
                );
              }
              if (field.type === 'toggleWithTooltip') {
                return (
                  <div
                    key={field.name}
                    className={`${classes.form_field} ${classes.toggle_field} ${classes.toggleWithTooltip_field}`}
                    style={{ ...field.customStyles }}
                  >
                    <Switch
                      offColor={'#bfbfbf'}
                      onColor={'#335566'}
                      height={20}
                      className={classes.toggle}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onChange={event => this.switchHandler(event, field)}
                      checked={!!form.values[field.name]}
                      disabled={field.disabled}
                    />

                    {field.label && (
                      <div className={classes.label_wrapper}>
                        <span>{field.label}</span>

                        {column.tooltip && (
                          <Tooltip {...column.tooltip} descriptionClassName={classes.tooltip_description} />
                        )}
                      </div>
                    )}
                  </div>
                );
              }
              if (field.type === 'textarea') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                      </span>
                    )}
                    <textarea
                      style={{
                        width: field.prefix ? 'calc(100% - 42px)' : '100%',
                        resize: field.resizeType || 'vertical',
                        minHeight: field.minHeight || 'auto'
                      }}
                      className={classes.form_textarea}
                      name={field.name}
                      placeholder={field.placeholder}
                      type='text'
                      id={name + PrefixForId.FormTextArea + capitalize(field.name)}
                      value={form.values[field.name]}
                      onChange={event => this.fieldChanged(event, field)}
                    />
                  </div>
                );
              }
              if (field.type === 'file') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                  >
                    <div className={classes.input_container}>
                      <div className={classes.field_prefix}>
                        {field.prefix ? field.prefix : <UploadTestReportIcon />}
                      </div>

                      <File
                        placeholder={field.placeholder}
                        dropzoneSetup={field.dropzoneSetup}
                        onChange={event => this.fieldChanged(event, field)}
                        error={form.errors[field.name] && !form.untouched[field.name]}
                        maxNumberOfFiles={field.maxNumberOfFiles}
                      />
                    </div>
                  </div>
                );
              }
              if (field.type === 'checkbox') {
                return (
                  <div key={field.name} className={classes.form_field}>
                    <div className={classes.checkbox_container}>
                      <div className={classes.checkbox_wrapper}>
                        <input
                          className={classes.form_checkbox_input}
                          name={field.name}
                          disabled={!!field.disabled}
                          type='checkbox'
                          checked={form.values[field.name]}
                          style={{ width: field.width || '18px', height: field.height || '18px' }}
                          id='inputCheckbox'
                        />

                        <label
                          className={classNames(
                            classes.form_checkbox_shell_input,
                            form.errors[field.name] && !form.untouched[field.name] ? classes.error : null
                          )}
                          style={{ width: field.width || '18px', height: field.height || '18px' }}
                          htmlFor='inputCheckbox'
                          onClick={event => this.fieldChanged(!form.values[field.name], field)}
                        />
                      </div>

                      {field.label && <span className={classes.label}>{field.label}</span>}
                    </div>

                    {form.errors[field.name] && !form.untouched[field.name] && field.errorMessage ? (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    ) : null}
                  </div>
                );
              }
              if (field.type === 'radio') {
                return (
                  <div>
                    {field.label && (
                      <span className={classes.label}>
                        {field.label}
                        {isRequired && <span className={classes.required_field}>&nbsp;*</span>}
                      </span>
                    )}
                    {field.options.map(item => (
                      <div key={item.value}>
                        <div className={classes.radio}>
                          <input
                            type='radio'
                            value={item.value}
                            id={item.value}
                            className={item.disabled && classes.input_disabled}
                            onChange={event => this.fieldChanged(event, field)}
                            checked={item.value === form.values[field.name]}
                            name={item.value}
                            disabled={item.disabled}
                          />
                          <label className={item.disabled && classes.radio_disabled} htmlFor={item.value}>
                            {item.label}
                          </label>
                        </div>
                        {item.text ? item.text : null}
                      </div>
                    ))}
                  </div>
                );
              }
              if (field.type === 'multipleCheckbox') {
                return (
                  <div
                    key={field.name}
                    className={
                      form.errors[field.name] && !form.untouched[field.name]
                        ? `${classes.form_field} ${classes.error}`
                        : classes.form_field
                    }
                    ref={this.multiplyCheckboxRef}
                    id='multipleCheckbox'
                  >
                    <div className={classes.placeholder_container} onClick={() => this.switchMultiplyCheckbox(field)}>
                      <div
                        className={classes.delete_button}
                        style={{ opacity: form.values[field.name].length ? 1 : 0 }}
                        onClick={() => this.clearMultiplyCheckbox(field)}
                      />
                      <span
                        className={form.values[field.name]?.length ? classes.placeholder : classes.placeholder_active}
                      >
                        {form.values[field.name].length
                          ? form.values[field.name].map(value => value.label).join(', ')
                          : field.placeholder}
                      </span>
                    </div>

                    {isCheckboxListVisible ? (
                      <div className={classes.checkbox_names}>
                        <div className={classes.checkbox_names_container}>
                          <>
                            {field.checkboxNames.map((name, indx) => {
                              if (indx >= itemsCount) {
                                return;
                              }
                              return (
                                <div
                                  className={classes.checkbox_name}
                                  key={name.id}
                                  onClick={() => this.multiplyCheckboxHandler(name, field)}
                                >
                                  <div
                                    className={classes.checkbox_name_wrapper}
                                    style={{ width: field.width || '18px', height: field.height || '18px' }}
                                  >
                                    <input
                                      className={classes.form_checkbox_name_input}
                                      name={name.label}
                                      type='checkbox'
                                      checked={
                                        form.values[field.name] &&
                                        form.values[field.name].some(
                                          checkboxValue => checkboxValue?.label === name?.label
                                        )
                                      }
                                      onChange={() => this.multiplyCheckboxHandler(name, field)}
                                      id={name.id}
                                    />
                                    <label
                                      className={classes.form_checkbox_name_shell_input}
                                      style={{ width: field.width || '18px', height: field.height || '18px' }}
                                      htmlFor={name.id}
                                    />
                                  </div>

                                  <span className={classes.form_checkbox_name_value}>{name.label}</span>
                                </div>
                              );
                            })}
                            {field.checkboxNames.length > itemsCount && itemsCount < field.checkboxNames.length && (
                              <span
                                onClick={() => {
                                  this.showMoreItems();
                                }}
                                className={classes.load_more_btn}
                              >
                                Mehr laden...
                              </span>
                            )}
                          </>
                        </div>
                      </div>
                    ) : null}

                    {form.errors[field.name] && !form.untouched[field.name] && field.errorMessage ? (
                      <span className={classes.error_message}>{field.errorMessage}</span>
                    ) : null}
                  </div>
                );
              }
            })}
          </section>
        ))}
      </form>
    );
  }
}

export const getFields = (formFields, callback) => {
  formFields.columns.forEach(column => {
    column.fields.forEach(field => {
      callback(field, column);
    });
  });
};

TempForm.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string.isRequired,
  valueChanged: PropTypes.func,
  className: PropTypes.string,
  formFields: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired
      })
    ).isRequired
  }).isRequired
};

export default withFetchData(TempForm);
