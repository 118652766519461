import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getEntityByFieldQuery(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/getEntityByFieldQuery`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getEntityByFieldsMetadata(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/getEntityFieldsMetadata`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getEntityById(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/getEntityById`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function updateCrmEntity(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/update`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function setCrmEntityState(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/setState`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}
