import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VEHICLE_OFFER_ASSIGNMENT } from '../../../../constants';
import { VehicleDetailsEditFormSubmitCallbackParams } from '../../components/form/edit-form/edit-form.props';
import useChangeVehicleTypeModal from '../use-change-vehicle-type-modal';
import useIsFutureBookingExist from 'components/pool-car-booking/hooks/react-query/use-is-future-booking-exist';
import { useVehicleDetailsSubmitForm } from '../use-submit-form/use-submit-form';

export const useVehicleDetailAssignmentFieldSubmit = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { onSubmit } = useVehicleDetailsSubmitForm();

  const { fuhrpark } = fleetByIdQuery;
  const { openChangeVehicleTypeModal } = useChangeVehicleTypeModal();
  const { isFutureBookingExist, isFutureBookingExistRefetch } = useIsFutureBookingExist(fuhrpark?.new_fuhrparkid || '');

  const assignmentSubmitCallback = ({ values, formFields }: VehicleDetailsEditFormSubmitCallbackParams) => {
    if (
      values.hasOwnProperty('new_zuordnung') &&
      fuhrpark?.new_fuhrparkid &&
      fuhrpark?.new_zuordnung_value === VEHICLE_OFFER_ASSIGNMENT.poolCar.value &&
      Number(values['new_zuordnung']) !== VEHICLE_OFFER_ASSIGNMENT.poolCar.value &&
      isFutureBookingExist
    ) {
      openChangeVehicleTypeModal(fuhrpark.new_fuhrparkid, () => {
        onSubmit({ values, formFields });
        isFutureBookingExistRefetch();
      });

      return true;
    }

    return false;
  };

  return { assignmentSubmitCallback };
};
