import styled from 'styled-components';
import { Select } from '@mui/material';
import { Flex } from 'common/common-components.styled';
import { DropDownIcon } from 'components/icons/drop-down-icon';

interface StyledMainRoleSwitcherProps {
  textcolor: string;
}

export const StyledMainRoleSwitcher = styled(Select)<StyledMainRoleSwitcherProps>`
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiSelect-select {
    color: ${({ theme, textcolor }) => theme.colors[textcolor]};
    padding: 10px 15px;
  }
  & .MuiSelect-icon {
    path {
      stroke: ${({ theme, textcolor }) => theme.colors[textcolor]};
    }
  }
`;

export const StyledSpinnerContainer = styled(Flex)`
  min-width: 100px;
  min-height: 40px;
`;

export const StyledMainRoleDropDownIcon = styled(DropDownIcon)`
  position: absolute;
  right: 7px;
  top: calc(50% - 10px) !important;

  & path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;
