import React from 'react';
import classes from './main.module.scss';
import { CheckedIcon } from 'components/icons-new/checked-icon';
import { Text } from 'common/common-components.styled';

export const WallEBussinesPartnerPromotionMainSection = () => {
  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <h1 className={classes.header}>Schluss mit Lade-Problemen – starten Sie jetzt durch!</h1>
        <p>
          Elektroautos sind inzwischen aus modernen und zukunftssicheren Firmenflotten nicht mehr wegzudenken. Aber wie
          steht es um das Thema Laden in der Firma oder zu Hause beim Nutzer? Die Nutzung externer Ladeinfrastruktur ist
          häufig teuer und benötigt spezielle Autorisierung durch Apps oder Ladekarten.
        </p>

        <div className={classes.benefit_container}>
          <p>Deine Vorteile mit WALL-E:</p>
          {YOUR_BENEFITS.map(benefit => (
            <div key={benefit} className={classes.benefit_item_container}>
              <CheckedIcon />
              <span>{benefit}</span>
            </div>
          ))}
        </div>

        <p className='mb-1'>
          Haben wir dein Interesse geweckt? Dann kontaktiere uns gleich für weitere Infos oder schaue in die FAQ.
        </p>
        <Text margin='40px 0 20px'>In Kooperation mit unserem Partner</Text>
        <img className='mt-20' src='/assets/images/wall-e-bussines-partner.svg' />
      </div>
    </section>
  );
};

const YOUR_BENEFITS = [
  'Hochwerte und nutzerfreundliche Wallboxen',
  'Prüfung des Installationsortes (virtuell oder vor Ort)',
  'Fachgerechte Installation',
  'Mit dem Charging-Portal alle Kosten und Verbräuche im Blick',
  'Auch Installation von Photovoltaik (PV)-Lösungen'
];
