import React from 'react';
import { StyledShowMoreButtonContainer } from './show-more.styled';
import { ShowMoreButtonProps } from './show-more.props';

export const ShowMoreButton = ({ isExpanded, onClick, ...restFlexProps }: ShowMoreButtonProps) => {
  return (
    <StyledShowMoreButtonContainer {...restFlexProps} onClick={onClick}>
      {isExpanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
    </StyledShowMoreButtonContainer>
  );
};
