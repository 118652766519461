import React from 'react';
import WidgetButton from '../../../components/widget-button';
import { useChecklistButtons } from './use-checklist-buttons';
import { Flex } from 'common/common-components.styled';

const ChecklistButtons = () => {
  const { showButton, openUploadChecklistModal } = useChecklistButtons();

  if (!showButton) {
    return null;
  }

  return (
    <Flex justify='flex-end'>
      <WidgetButton title='Hochladen' handleClick={openUploadChecklistModal} />
    </Flex>
  );
};

export default ChecklistButtons;
