/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classes from './quiz-component.module.scss';
import { UnauthorizedPageContainer } from 'components/unauthorized-page-container';
import { useOverlay } from 'hooks';
import { Distance, Button } from 'common/common-components.styled';
import { useQuery } from 'hooks';
import { getUmfrageInfo, updateUmfrage } from 'services/quiz-service';
import { Rating } from 'react-simple-star-rating';
import StarEmptyBlue from '../icons/star-empty-blue';
import StarFullBlue from '../icons/star-full-blue';
import StarEmptyGray from '../icons/star-empty-gray';
import { AnyObject } from 'common/interfaces';
import SuccessQuiz from './success-page/success-quiz';

interface UmfrageInfoVAluesProps {
  attributeValue: string | null;
  attributeTypeCode: number;
}

interface UmfrageIncidentProps {
  title: UmfrageInfoVAluesProps;
}

interface UmfrageFuhrparkProps {
  new_name: UmfrageInfoVAluesProps;
  new_hersteller: UmfrageInfoVAluesProps;
  new_model: UmfrageInfoVAluesProps;
}

const InfoRating = () => {
  return (
    <div className={classes.max_min_rating}>
      <div className={classes.max_min_name}>furchtbar</div>
      <div className={classes.max_min_name}>wunderbar</div>
    </div>
  );
};

const NameRating = ({ name }: AnyObject) => {
  return <div className={classes.name_block}>{name}</div>;
};

const RatingBlock = ({ name, setValue, distance = '40' }: AnyObject) => {
  return (
    <>
      <Distance top={distance} />

      <NameRating name={name} />
      <div className={classes.star_block}>
        <Distance top='20' />
        <Rating
          iconsCount={5}
          size={35}
          emptyIcon={<StarEmptyBlue margin={7.5} />}
          fillIcon={<StarFullBlue margin={7.5} />}
          onClick={value => setValue(value)}
          initialValue={0}
          fillClassName={classes.rating_tooltip}
        />
        <InfoRating />
      </div>
    </>
  );
};

const QuizComponent = () => {
  const query = useQuery();
  const id: string[] | string | null = query.id || null;
  const [incident, setIncident] = useState<UmfrageIncidentProps | null>(null);
  const [fuhrpark, setFuhrpark] = useState<UmfrageFuhrparkProps | null>(null);
  const [showOverlay, hideOverlay] = useOverlay();
  const [uds_antwort1, setUdsAntwort1] = useState<number | null>(null);
  const [uds_antwort2, setUdsAntwort2] = useState<number | null>(null);
  const [uds_antwort2radio, setUdsAntwort2Radio] = useState<any>(null);
  const [uds_antwort3, setUdsAntwort3] = useState<number | null>(null);
  const [uds_antwort4, setUdsAntwort4] = useState<number | null>(null);
  const [uds_antwort5, setUdsAntwort5] = useState<number | null>(null);
  const [uds_antwort5radio, setUdsAntwort5Radio] = useState<any>(null);
  const [uds_antwort6, setUdsAntwort6] = useState<number | null>(null);
  const [uds_antwort7, setUdsAntwort7] = useState<number | null>(null);
  const [uds_antwort7radio, setUdsAntwort7Radio] = useState<any>(null);
  const [uds_antwort8radio, setUdsAntwort8Radio] = useState<any>(null);
  const [uds_antwort9, setUdsAntwort9] = useState<number | null>(null);
  const [uds_boolantwort1radio, setUdsBoolAntwort1Radio] = useState<any>(null);
  const [uds_boolantwort2radio, setUdsBoolAntwort2Radio] = useState<any>(null);
  const [uds_whatdidyoulike, setUdsWhatDidYouLike] = useState<string>('');
  const [uds_whatneedsimprovement, setUdsWhatNeedsImprovement] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [showGoogleButton, setShowGoogleButton] = useState<boolean>(false);

  const checkArr: AnyObject = [
    uds_antwort1,
    uds_antwort2 || uds_antwort2radio,
    uds_antwort3,
    uds_antwort4,
    uds_antwort5 || uds_antwort5radio,
    uds_antwort6,
    uds_antwort7 || uds_antwort7radio,
    uds_antwort9
  ];

  useEffect(() => {
    checkArr.filter((el: number) => el > 3).length === 8 ? setShowGoogleButton(true) : setShowGoogleButton(false);
  }, [checkArr]);

  const reverseValue = (value: number) => {
    switch (value) {
      case 1:
        return 5;
      case 2:
        return 4;
      case 3:
        return 3;
      case 4:
        return 2;
      case 5:
        return 1;

      default:
        return value;
    }
  };

  const feedback: AnyObject = {
    updateUmfrage: {
      entityName: 'uds_umfrage',
      id: id,
      attributes: {}
    }
  };

  if (uds_whatdidyoulike !== '') {
    feedback.updateUmfrage.attributes.uds_whatdidyoulike = {
      attributeValue: uds_whatdidyoulike,
      attributeTypeCode: 7
    };
  }
  if (uds_whatneedsimprovement !== '') {
    feedback.updateUmfrage.attributes.uds_whatneedsimprovement = {
      attributeValue: uds_whatneedsimprovement,
      attributeTypeCode: 7
    };
  }
  if (uds_antwort1) {
    feedback.updateUmfrage.attributes.uds_antwort1 = {
      attributeValue: {
        label: null,
        value: reverseValue(Math.floor(uds_antwort1))
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort2 || uds_antwort2radio) {
    feedback.updateUmfrage.attributes.uds_antwort2 = {
      attributeValue: {
        label: null,
        value: !uds_antwort2 ? uds_antwort2radio : reverseValue(uds_antwort2)
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort3) {
    feedback.updateUmfrage.attributes.uds_antwort3 = {
      attributeValue: {
        label: null,
        value: reverseValue(Math.floor(uds_antwort3))
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort4) {
    feedback.updateUmfrage.attributes.uds_antwort4 = {
      attributeValue: {
        label: null,
        value: reverseValue(Math.floor(uds_antwort4))
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort5 || uds_antwort5radio) {
    feedback.updateUmfrage.attributes.uds_antwort5 = {
      attributeValue: {
        label: null,
        value: !uds_antwort5 ? uds_antwort5radio : reverseValue(uds_antwort5)
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort6) {
    feedback.updateUmfrage.attributes.uds_antwort6 = {
      attributeValue: {
        label: null,
        value: reverseValue(Math.floor(uds_antwort6))
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort7 || uds_antwort7radio) {
    feedback.updateUmfrage.attributes.uds_antwort7 = {
      attributeValue: {
        label: null,
        value: !uds_antwort7 ? uds_antwort7radio : reverseValue(uds_antwort7)
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort8radio !== null) {
    feedback.updateUmfrage.attributes.uds_antwort8 = {
      attributeValue: {
        label: null,
        value: uds_antwort8radio
      },
      attributeTypeCode: 11
    };
  }
  if (uds_antwort9) {
    feedback.updateUmfrage.attributes.uds_antwort9 = {
      attributeValue: {
        label: null,
        value: reverseValue(Math.floor(uds_antwort9))
      },
      attributeTypeCode: 11
    };
  }
  if (uds_boolantwort1radio !== null) {
    feedback.updateUmfrage.attributes.uds_boolantwort1 = {
      attributeValue: uds_boolantwort1radio,
      attributeTypeCode: 0
    };
  }
  if (uds_boolantwort2radio !== null) {
    feedback.updateUmfrage.attributes.uds_boolantwort2 = {
      attributeValue: uds_boolantwort2radio,
      attributeTypeCode: 0
    };
  }

  const sendFeedback = async () => {
    showOverlay();
    let res;
    try {
      res = await updateUmfrage(feedback);
    } catch (err) {
      res = {};
      console.error(err);
    } finally {
      hideOverlay();
    }
    if (res.status === 200) {
      setSuccess(true);
    }
  };

  const asyncIncidetUmfrage = async (id: string) => {
    showOverlay();
    let res;
    try {
      res = await getUmfrageInfo(id);
    } catch (err) {
      res = {};
      console.error(err);
    } finally {
      hideOverlay();
    }
    setIncident(res?.data.data.incident[0] || {});
    setFuhrpark(res?.data.data.fuhrpark[0] || {});
  };

  useEffect(() => {
    if (id !== null && !Array.isArray(id)) {
      asyncIncidetUmfrage(id);
    }
  }, [id]);

  const manualData = (value: number, element: string, setRadio: Function, setValue: Function) => {
    const radioButton: AnyObject = document.getElementsByName(element);
    radioButton.forEach((el: HTMLInputElement) => (el.checked = false));
    setRadio(undefined);
    setValue(value);
  };

  const removeStarValue = (setValue: Function, setRadioValue: Function, value: string | number) => {
    setValue(null);
    setRadioValue(value);
  };

  return (
    <UnauthorizedPageContainer className={classes.main_container}>
      {!!incident && !!fuhrpark && (
        <section className={classes.container}>
          {!success ? (
            <div className={classes.main__block__form}>
              <div className={classes.header}>
                Anfrage: <span>{incident.title?.attributeValue ? incident.title?.attributeValue : '-'}</span>
              </div>
              <span>{`Auto: ${fuhrpark.new_name?.attributeValue ? fuhrpark.new_name.attributeValue : '- '}${
                (fuhrpark.new_name?.attributeValue && fuhrpark.new_hersteller?.attributeValue) ||
                fuhrpark.new_model.attributeValue
                  ? ','
                  : ''
              }${fuhrpark.new_hersteller?.attributeValue ? fuhrpark.new_hersteller.attributeValue : ''} ${
                fuhrpark.new_model?.attributeValue ? fuhrpark.new_model.attributeValue : ''
              }`}</span>
              <RatingBlock
                name={'Wie zufrieden warst du mit der telefonischen Erreichbarkeit unserer Mitarbeiter?'}
                setValue={setUdsAntwort1}
                distance='60'
              />
              <Distance top='40' />
              <NameRating name={'Wie zufrieden warst du mit der telefonischen Aufnahme Ihres Termins?'} />
              <div className={classes.star_block}>
                <Distance top='20' />
                <Rating
                  iconsCount={5}
                  size={35}
                  emptyIcon={!uds_antwort2radio ? <StarEmptyBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  fillIcon={!uds_antwort2radio ? <StarFullBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  onClick={value =>
                    manualData(Math.floor((value / 100) * 5), 'uds_antwort2radio', setUdsAntwort2Radio, setUdsAntwort2)
                  }
                  initialValue={0}
                  fillClassName={classes.rating_tooltip}
                />
                <InfoRating />
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort2radio}
                      id='uds_antwort21'
                      name='uds_antwort2radio'
                      onClick={() => removeStarValue(setUdsAntwort2, setUdsAntwort2Radio, 7)}
                    />
                    <label htmlFor='uds_antwort21'>Die Schadenmeldung wurde von mir selbst ausgefüllt</label>
                  </div>

                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort2radio}
                      id='uds_antwort22'
                      name='uds_antwort2radio'
                      onClick={() => removeStarValue(setUdsAntwort2, setUdsAntwort2Radio, 8)}
                    />
                    <label htmlFor='uds_antwort22'>Trifft nicht zu, da kein Schadenmanagement gebucht</label>
                  </div>
                </div>
              </div>
              <RatingBlock
                name={'Wie zufrieden warst du insgesamt mit der Betreuung durch unsere Mitarbeiter?'}
                setValue={setUdsAntwort3}
              />
              <RatingBlock
                name={'Wie zufrieden warst du mit der Freundlichkeit der Werkstattmitarbeiter?'}
                setValue={setUdsAntwort4}
              />
              <Distance top='40' />
              <NameRating name={'Wie zufrieden warst du mit dem Abhol- und Bringservice?'} />
              <div className={classes.star_block}>
                <Distance top='20' />
                <Rating
                  iconsCount={5}
                  size={35}
                  emptyIcon={!uds_antwort5radio ? <StarEmptyBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  fillIcon={!uds_antwort5radio ? <StarFullBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  onClick={value =>
                    manualData(Math.floor((value / 100) * 5), 'uds_antwort5radio', setUdsAntwort5Radio, setUdsAntwort5)
                  }
                  initialValue={0}
                  fillClassName={classes.rating_tooltip}
                />
                <InfoRating />
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort5radio}
                      id='uds_antwort51'
                      name='uds_antwort5radio'
                      onClick={() => removeStarValue(setUdsAntwort5, setUdsAntwort5Radio, 7)}
                    />
                    <label htmlFor='uds_antwort51' className={classes.short_label}>
                      Nicht beansprucht
                    </label>
                  </div>
                </div>
              </div>
              <RatingBlock
                name={'Wie zufrieden bist du mit der Qualität der Reparatur (soweit du selbst es einschätzen kannst)?'}
                setValue={setUdsAntwort6}
              />
              <Distance top='40' />

              <NameRating name={'Würdest du die Werkstatt Freunden oder Bekannten empfehlen?'} />
              <div className={classes.star_block}>
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_boolantwort1radio}
                      id='uds_boolantwort11'
                      name='uds_boolantwort1radio'
                      onClick={() => setUdsBoolAntwort1Radio(true)}
                    />
                    <label htmlFor='uds_boolantwort11' className={classes.short_label}>
                      Ja
                    </label>
                  </div>

                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_boolantwort1radio}
                      id='uds_boolantwort12'
                      name='uds_boolantwort1radio'
                      onClick={() => setUdsBoolAntwort1Radio(false)}
                    />
                    <label htmlFor='uds_boolantwort12' className={classes.short_label}>
                      Nein
                    </label>
                  </div>
                </div>
              </div>
              <Distance top='40' />
              <NameRating name={'Wie zufrieden warst du mit der Sauberkeit des gestellten Ersatzfahrzeugs?'} />
              <div className={classes.star_block}>
                <Distance top='20' />
                <Rating
                  iconsCount={5}
                  size={35}
                  emptyIcon={!uds_antwort7radio ? <StarEmptyBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  fillIcon={!uds_antwort7radio ? <StarFullBlue margin={7.5} /> : <StarEmptyGray margin={7.5} />}
                  onClick={value =>
                    manualData(Math.floor((value / 100) * 5), 'uds_antwort7radio', setUdsAntwort7Radio, setUdsAntwort7)
                  }
                  initialValue={0}
                  fillClassName={classes.rating_tooltip}
                />
                <InfoRating />
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort7radio}
                      id='uds_antwort71'
                      name='uds_antwort7radio'
                      onClick={() => removeStarValue(setUdsAntwort7, setUdsAntwort7Radio, 7)}
                    />
                    <label htmlFor='uds_antwort71' className={classes.short_label}>
                      Kein Ersatzfahrzeug genutzt
                    </label>
                  </div>
                </div>
              </div>
              <Distance top='40' />
              <NameRating
                name={
                  'Verfügte das Ersatzfahrzeug über das von dir bestellte Zubehör (Navigationssystem, Freisprecheinrichtung, Automatikgetriebe)?'
                }
              />
              <div className={classes.star_block}>
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort8radio}
                      id='uds_antwort81'
                      name='uds_antwort8radio'
                      onClick={() => setUdsAntwort8Radio(1)}
                    />
                    <label htmlFor='uds_antwort81' className={classes.short_label}>
                      Ja
                    </label>
                  </div>
                </div>
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort8radio}
                      id='uds_antwort82'
                      name='uds_antwort8radio'
                      onClick={() => setUdsAntwort8Radio(3)}
                    />
                    <label htmlFor='uds_antwort82' className={classes.short_label}>
                      Nein
                    </label>
                  </div>
                </div>
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_antwort8radio}
                      id='uds_antwort83'
                      name='uds_antwort8radio'
                      onClick={() => setUdsAntwort8Radio(2)}
                    />
                    <label htmlFor='uds_antwort83' className={classes.short_label}>
                      Kein Zubehör bestellt
                    </label>
                  </div>
                </div>
              </div>
              <RatingBlock
                name={'Wie zufrieden warst du insgesamt mit dem Ersatzfahrzeug?'}
                setValue={setUdsAntwort9}
              />
              <Distance top='40' />
              <NameRating name={'Was hat dir - insgesamt - besonders gut gefallen?'} />
              <textarea
                maxLength={500}
                placeholder='Text'
                value={uds_whatdidyoulike}
                onChange={e => setUdsWhatDidYouLike(e.target.value)}
                className={classes.textarea_block}
              />
              <Distance top='40' />
              <NameRating name={'Was sollten wir verbessern?'} />
              <textarea
                maxLength={500}
                placeholder='Text'
                value={uds_whatneedsimprovement}
                onChange={e => setUdsWhatNeedsImprovement(e.target.value)}
                className={classes.textarea_block}
              />
              <Distance top='40' />
              <NameRating name={'Möchtst du nach dem Versand des Fragebogens von uns kontaktiert werden?'} />
              <div className={classes.star_block}>
                <Distance top='20' />
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_boolantwort2radio}
                      id='uds_boolantwort21'
                      name='uds_boolantwort2radio'
                      onClick={() => setUdsBoolAntwort2Radio(false)}
                    />
                    <label htmlFor='uds_boolantwort21' className={classes.short_label}>
                      Nein, danke
                    </label>
                  </div>
                </div>
                <div className={classes.radio_block}>
                  <div className={classes.radio}>
                    <input
                      type='radio'
                      value={uds_boolantwort2radio}
                      id='uds_boolantwort22'
                      name='uds_boolantwort2radio'
                      onClick={() => setUdsBoolAntwort2Radio(true)}
                    />
                    <label htmlFor='uds_boolantwort22' className={classes.short_label}>
                      Ja, bitte
                    </label>
                  </div>
                </div>
              </div>
              <Distance top='40' />
              <Button onClick={sendFeedback}>Feedback absenden</Button>
            </div>
          ) : (
            <SuccessQuiz showLink={showGoogleButton} />
          )}
        </section>
      )}
    </UnauthorizedPageContainer>
  );
};

export default QuizComponent;
