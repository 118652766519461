import React from 'react';
import countries from 'helpers/country-codes.json';
import { CountyItem } from '../phone-number.props';
import { StyledPhoneSelect } from './phone-option-set.styled';
import { Divider, Flex } from 'common/common-components.styled';
import { PhoneNumbersOptionSetProps } from './phone-option-set.props';

export const PhoneNumbersOptionSet = ({ value, setValue }: PhoneNumbersOptionSetProps) => {
  return (
    <Flex>
      <StyledPhoneSelect value={value} onChange={event => setValue(event.target.value)}>
        <>
          {countries.map((item: CountyItem) => (
            <option key={item.name} value={item.dial_code}>
              {`${item.dial_code}`}
            </option>
          ))}
        </>
      </StyledPhoneSelect>

      <Divider vertical color='grey500' margin='0 5px' />
    </Flex>
  );
};
