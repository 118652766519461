/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import TextFieldComponent from '../components/text-field-component';
import PhoneFieldComponent from '../components/phone-field-component';
import EmailFieldComponent from '../components/email-field-component';
import ZipcodeFieldComponent from '../components/zipcode-field-component';
import DateFieldComponent from '../components/date-field-component';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-steps.module.css';
import slideReverse from '../transition/backward-slide-steps.module.css';
import slideReturn from '../transition/return-slide-steps.module.css';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import TextareaFieldComponent from '../components/textarea-field-component';
import ThreeButtonsComponent from '../components/three-buttons-component';
import { driverFields, otherFields } from '../utils';
import useWindowSize from 'hooks/use-window-size';
import { StepProps } from '../report-damage.props';
import { errorMessages } from 'utils/error-messages';

const StepFive = ({ reactHookFormData, handleBack, handleNext }: StepProps) => {
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [showBlockWhoDrive, setShowBlockWhoDrive] = useState<boolean>(false);
  const [showBlockLicense, setShowBlockLicense] = useState<boolean>(false);
  const { windowWidth } = useWindowSize();
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
  }, []);

  useEffect(() => {
    setTimeout(
      () =>
        (!watch('schadenAlsFahrzeugnutzer') && watch('whoDrive') === 'Fahrzeugnutzer') ||
        watch('whoDrive') === 'Andere Person'
          ? setShowBlockWhoDrive(true)
          : setShowBlockWhoDrive(false),
      100
    );
  }, [watch('whoDrive')]);

  useEffect(() => {
    setTimeout(() => (!!watch('doYouHaveLicense') ? setShowBlockLicense(true) : setShowBlockLicense(false)), 100);
  }, [watch('doYouHaveLicense')]);

  const emailValidation = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z_\-\.]+)\.([a-zA-Z]{2,15})$/;

  useEffect(() => {
    if (!!watch('whoDriveEmailEmpty') || (!!watch('whoDriveEmailEmpty') && errors.whoDriveEmail)) {
      clearErrors('whoDriveEmail');
    }
    if (
      !watch('whoDriveEmailEmpty') &&
      !!watch('whoDriveEmail') &&
      watch('whoDriveEmail').search(emailValidation) === -1
    ) {
      setError('whoDriveEmail', {
        type: 'manual',
        message:
          'Ungültige E-Mail Adresse. Eine gültige E-Mail darf nur lateinische Buchstaben, Zahlen, @ und . enthalten.'
      });
    }
  }, [watch('whoDriveEmailEmpty'), watch('whoDriveEmail'), errors]);

  const whoDriveValue = watch('whoDrive');
  const isFahrzeugnutzer = whoDriveValue === 'Fahrzeugnutzer';

  const whoDriveFields =
    whoDriveValue === 'Niemand' ||
    whoDriveValue === 'Andere Person' ||
    (isFahrzeugnutzer && watch('schadenAlsFahrzeugnutzer')) ||
    (isFahrzeugnutzer &&
      (!!watch('whoDriveName') || !!watch('fahrerInfo')?.fullname?.attributeValue) &&
      (!!watch('whoDrivePhone') || !!watch('fahrerInfo')?.mobilephone?.attributeValue) &&
      (!!watch('whoDriveEmail') ||
        !!watch('whoDriveEmailEmpty') ||
        !!watch('fahrerInfo')?.emailaddress1?.attributeValue));

  const otherWhoDriveFields =
    watch('whoDrive') === 'Niemand' ||
    watch('whoDrive') === 'Fahrzeugnutzer' ||
    (watch('whoDrive') === 'Andere Person' &&
      !!watch('whoDriveNameAndere') &&
      !!watch('whoDrivePhoneAndere') &&
      (!!watch('whoDriveEmailAndere') || !!watch('whoDriveEmailAndereEmpty')));

  const doYouHaveLicense = watch('doYouHaveLicense') !== undefined;

  useEffect(() => {
    watch('whoDrive') !== undefined &&
    watch('doYouHaveLicense') !== undefined &&
    watch('detailDamageComment').length > 0 &&
    whoDriveFields &&
    otherWhoDriveFields &&
    doYouHaveLicense &&
    (Object.keys(errors).length < 1 ||
      (Object.keys(errors).length <= 1 && (errors?.whoDrivePLZ || errors?.whoDrivePLZAndere)))
      ? setButtonActive(true)
      : setButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  useEffect(() => {
    if (!!watch('doYouHaveLicenseLicenseNumber')) {
      const newFin = watch('doYouHaveLicenseLicenseNumber').toUpperCase();
      setValue('doYouHaveLicenseLicenseNumber', newFin);
      if (newFin.search(finValidation) === -1) {
        setError('doYouHaveLicenseLicenseNumber', {
          type: 'manual',
          message: 'Es gibt eine Beschränkung für maximal zulässige Symbole.'
        });
      } else {
        clearErrors('doYouHaveLicenseLicenseNumber');
      }
    }
    if (watch('doYouHaveLicenseLicenseNumber') === '') {
      clearErrors('doYouHaveLicenseLicenseNumber');
    }
  }, [watch('doYouHaveLicenseLicenseNumber')]);

  let slideClassName = watch('previousStep') === 4 ? slide : slideReverse;

  const finValidation = /^[a-zA-Z0-9_\-\.]{1,100}$/;

  const checkButtons = (buttonsArray: Array<string>) => {
    buttonsArray.forEach((item: string) => {
      if (watch(item) === undefined) {
        setError(item, {
          type: 'manual',
          message: 'Error'
        });
      }
    });
  };

  const moveToNextStep = handleSubmit(() => {
    if (buttonActive && handleNext) {
      setShowBlock(false);
      watch('previousStep') === 'detail'
        ? setTimeout(() => {
            setValue('neededStep', null);
            setValue('step', 'detail');
          }, 500)
        : setTimeout(() => handleNext(), 500);
      setTimeout(() => setValue('previousStep', 5), 500);
    } else {
      checkButtons(['whoDrive', 'doYouHaveLicense']);
    }
  });

  const moveToPreviousStep = () => {
    if (handleBack) {
      setValue('previousStep', 5);
      setShowBlock(false);
      setTimeout(() => {
        handleBack();
      }, 500);
    }
  };

  useEffect(() => {
    if (watch('whoDrive') === 'Andere Person' || watch('whoDrive') === 'Niemand') {
      driverFields.forEach(el => clearErrors(el));
    }
    if (watch('whoDrive') === 'Fahrzeugnutzer' || watch('whoDrive') === 'Niemand') {
      otherFields.forEach(el => clearErrors(el));
    }
    if (watch('schadenAlsFahrzeugnutzer') === true && watch('whoDrive') === 'Fahrzeugnutzer') {
      setValue('whoDriveName', watch('fahrerDataFullname'));
      setValue('whoDrivePhone', watch('fahrerDataMobilephone'));
      setValue('whoDriveEmail', watch('fahrerDataEmailaddress1'));
    }
  }, [watch('whoDrive')]);

  useEffect(() => {
    if (!!watch('whoDriveEmailAndereEmpty')) {
      clearErrors('whoDriveEmailAndere');
    }
  }, [watch('whoDriveEmailAndereEmpty')]);

  useEffect(() => {
    if (!!watch('whoDriveEmailEmpty')) {
      clearErrors('whoDriveEmail');
    }
  }, [watch('whoDriveEmailEmpty')]);

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 5 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.container}>
        <ThreeButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='whoDrive'
          fieldName='Fahrer des Kundenfahrzeugs zum Zeitpunkt des Schadens'
          firstButtonText='Fahrzeugnutzer'
          firstButtonValue='Fahrzeugnutzer'
          secondButtonText='Andere Person'
          secondButtonValue='Andere Person'
          thirdButtonText='Niemand'
          thirdButtonValue='Niemand'
        />
        <Distance top='20' />

        {((!watch('schadenAlsFahrzeugnutzer') && watch('whoDrive') === 'Fahrzeugnutzer') ||
          watch('whoDrive') === 'Andere Person') && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockWhoDrive} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                {watch('whoDrive') === 'Andere Person' && (
                  <div>
                    <h6>Kontaktdaten:</h6>

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveNameAndere'
                      fieldName='Name'
                      fieldPlaceholder='Max Mustermann'
                    />
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDrivePhoneAndere'
                      fieldName='Telefon'
                    />
                    <Distance top='5' />

                    <EmailFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveEmailAndere'
                      optional={watch('whoDriveEmailAndereEmpty') === true}
                      optionalText=''
                      fieldName='E-mail adresse'
                      fieldPlaceholder='Muster@mail.com'
                      readOnly={!!watch('whoDriveEmailAndereEmpty')}
                    />
                    <Distance top='5' />

                    <div className={styles.checkbox}>
                      <input
                        {...register('whoDriveEmailAndereEmpty', { required: false })}
                        id='whoDriveEmailAndereEmpty'
                        type='checkbox'
                      />
                      <label htmlFor='whoDriveEmailAndereEmpty'>E-Mail unbekannt</label>
                    </div>
                    <Distance top='15' />

                    <div className={styles.row_columns_inputs}>
                      <ZipcodeFieldComponent
                        reactHookFormData={reactHookFormData}
                        smallField={windowWidth > 900 && true}
                        fieldRegister='whoDrivePLZAndere'
                        fieldName='Postleitzahl'
                        optional={true}
                      />

                      <TextFieldComponent
                        reactHookFormData={reactHookFormData}
                        fieldRegister='whoDriveStadtAndere'
                        fieldName='Stadt'
                        fieldPlaceholder='Muster Stadt'
                        optional={true}
                        doubleField={windowWidth > 900 && true}
                      />
                    </div>

                    <Distance top='5' />

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveAdressAndere'
                      fieldName='Straße, Hausnummer'
                      fieldPlaceholder='Musterstr. 100'
                      optional={true}
                    />
                    <Distance top='30' />
                  </div>
                )}

                {watch('whoDrive') === 'Fahrzeugnutzer' && (
                  <div>
                    <h6>Kontaktdaten:</h6>

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveName'
                      fieldName='Name'
                      fieldPlaceholder='Max Mustermann'
                    />
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDrivePhone'
                      fieldName='Telefon'
                    />
                    <Distance top='5' />

                    <EmailFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveEmail'
                      optional={watch('whoDriveEmailEmpty') === true}
                      optionalText=''
                      fieldName='E-mail adresse'
                      fieldPlaceholder='Muster@mail.com'
                      readOnly={!!watch('whoDriveEmailEmpty')}
                    />
                    <Distance top='5' />

                    <div className={styles.checkbox}>
                      <input
                        {...register('whoDriveEmailEmpty', { required: false })}
                        id='whoDriveEmailEmpty'
                        type='checkbox'
                      />
                      <label htmlFor='whoDriveEmailEmpty'>E-Mail unbekannt</label>
                    </div>
                    <Distance top='15' />

                    <div className={styles.row_columns_inputs}>
                      <ZipcodeFieldComponent
                        reactHookFormData={reactHookFormData}
                        smallField={!watch('fahrerInfo')?.address1_city?.attributeValue && windowWidth > 900 && true}
                        fieldRegister='whoDrivePLZ'
                        fieldName='Postleitzahl'
                        optional={true}
                      />

                      <TextFieldComponent
                        reactHookFormData={reactHookFormData}
                        fieldRegister='whoDriveStadt'
                        fieldName='Stadt'
                        fieldPlaceholder='Muster Stadt'
                        optional={true}
                        doubleField={
                          !watch('fahrerInfo')?.address1_postalcode?.attributeValue && windowWidth > 900 && true
                        }
                      />
                    </div>

                    <Distance top='5' />

                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='whoDriveAdress'
                      fieldName='Straße, Hausnummer'
                      fieldPlaceholder='Musterstr. 100'
                      optional={true}
                    />
                    <Distance top='30' />
                  </div>
                )}
              </div>
            </CSSTransition>
          </div>
        )}

        <TwoButtonsComponent
          reactHookFormData={reactHookFormData}
          fieldName='Gültiger Führerschein vorhanden?'
          fieldRegister='doYouHaveLicense'
          fieldRegisterSecond='doYouHaveLicense'
        />

        <Distance top='20' />

        {!!watch('doYouHaveLicense') && (
          <div style={{ overflow: 'hidden' }}>
            <CSSTransition in={showBlockLicense} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
              <div>
                <h6>Angaben zum Führerschein:</h6>

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='doYouHaveLicenseIssuingAuthority'
                  fieldName='Ausstellungsbehörde (4c.)'
                  fieldPlaceholder='Musterbehörde'
                  optional={true}
                />
                <Distance top='5' />

                <TextFieldComponent
                  reactHookFormData={reactHookFormData}
                  fieldRegister='doYouHaveLicenseLicenseNumber'
                  fieldName='Führerscheinnummer (5.)'
                  fieldPlaceholder='0000AAA000'
                  optional={true}
                />
                <Distance top='5' />

                <div className={styles.row_columns_inputs}>
                  <DateFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='doYouHaveLicenseDateIssue'
                    fieldName='Ausstellungsdatum (4a.)'
                    doubleField={windowWidth > 900 && true}
                    optional={true}
                  />

                  {windowWidth <= 900 && <Distance top='15' />}

                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='doYouHaveLicenseClass'
                    registerOptions={{ maxLength: { value: 50, message: errorMessages.getMaxTextLength(50) } }}
                    fieldName='Klasse (9.)'
                    fieldPlaceholder='B'
                    optional={true}
                    smallField={windowWidth > 900 && true}
                    upperCase={true}
                  />
                </div>
                <Distance top='20' />
              </div>
            </CSSTransition>
          </div>
        )}

        <TextareaFieldComponent
          reactHookFormData={reactHookFormData}
          fieldRegister='detailDamageComment'
          fieldName='Ausführliche Schadenschilderung'
          fieldPlaceholder='Bitte schreib, wie der Schaden aufgetreten ist.'
          needTooltip={true}
          tooltipPosition={windowWidth > 900 ? 'bottom' : 'center'}
          tooltipText='Bitte fertige die Schilderung ausführlich an. Aus deiner Beschreibung muss klar hervorgehen, wer den Schaden verschuldet hat oder ob du ggf. eine Mitschuld tragen musst oder der Unfall für dich - und aus welchen Gründen? - unabwendbar war. Bitte beachte, dass du zur wahrheitsgemäßen Unfallschilderung verpflichtet bist, da ansonsten der Versicherungsschutz erlischt. '
        />
        <Distance top='15' />

        {showBlock && (
          <div className={styles.row_columns}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_input}>
              <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_input}>
              <button
                id='moveToNextStepButton'
                onClick={moveToNextStep}
                className={buttonActive ? styles.button_active : styles.button_wait}
              >
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default StepFive;
