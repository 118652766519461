import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { RVOPhaseProps } from './configuration-status-render.types';
import OfferStatusSection from 'components/product/offer-status-section/offer-status-section';
import { ConfigurationContainer } from './configuration-status-render.styled';

const ConfigurationStatusRender: React.FC<RVOPhaseProps> = ({ progressInfo, redirect }) => {
  return (
    <Flex direction='column' top='5'>
      {progressInfo.map(item => {
        return (
          <ConfigurationContainer key={item.id}>
            <Text
              color='grey500'
              right='10'
              pointer
              onClick={() => redirect(item.id)}
            >{`Konfiguration ${item.name}`}</Text>
            <OfferStatusSection status={item.progressCode} />
          </ConfigurationContainer>
        );
      })}
    </Flex>
  );
};

export default ConfigurationStatusRender;
