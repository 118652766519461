import React from 'react';
import PropTypes from 'prop-types';
import classes from './progress-diagram.module.scss';
import ProgressBar from 'react-customizable-progressbar';
import classNames from 'classnames';
import { Flex } from 'common/common-components.styled';
import { TooltipIcon } from './tooltip-icon/tooltip-icon';

const ProgressDiagram = ({
  children,
  progressBarChildren,
  title = '',
  tooltipText = '',
  partOfTotalNumber,
  progressBarSetup,
  containerClassName,
  isActive,
  onClick
}) => {
  return (
    <div
      className={
        isActive
          ? classNames(classes.progress_diagram_container, classes.active, containerClassName)
          : classNames(classes.progress_diagram_container, containerClassName)
      }
      onClick={onClick}
    >
      <ProgressBar
        className={classes.progress_bar}
        {...progressBarSetup}
        progress={progressBarSetup.progress ? progressBarSetup.progress : 0.1}
      >
        <div className={classes.progress_bar_content}>{progressBarChildren}</div>
      </ProgressBar>

      {children ? (
        children
      ) : (
        <div className={classes.side_content}>
          <Flex gap='7px' align='center'>
            <h2 style={{ color: progressBarSetup?.strokeColor || '#000' }} className={classes.title}>
              {title}
            </h2>
            <TooltipIcon tooltipText={tooltipText} />
          </Flex>
          <div className={classes.side_content_bottom}>
            <span className={classes.lower_text}>{`${
              progressBarSetup?.progress > 0 ? progressBarSetup?.progress : '0'
            }%`}</span>
            <span className={classes.lower_text}>{partOfTotalNumber}</span>
          </div>
        </div>
      )}
    </div>
  );
};

ProgressDiagram.propTypes = {
  title: PropTypes.string,
  tooltipText: PropTypes.string,
  progressBarSetup: PropTypes.shape({
    radius: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    steps: PropTypes.number,
    cut: PropTypes.number,
    rotate: PropTypes.number,
    strokeWidth: PropTypes.number,
    strokeColor: PropTypes.string,
    strokeLinecap: PropTypes.string,
    transition: PropTypes.string,
    trackStrokeWidth: PropTypes.number,
    trackStrokeColor: PropTypes.string,
    trackStrokeLinecap: PropTypes.number,
    trackTransition: PropTypes.string,
    pointerRadius: PropTypes.number,
    pointerStrokeWidth: PropTypes.number,
    pointerStrokeColor: PropTypes.string,
    pointerFillColor: PropTypes.string,
    initialAnimation: PropTypes.bool,
    initialAnimationDelay: PropTypes.number,
    inverse: PropTypes.bool,
    counterClockwise: PropTypes.bool,
    className: PropTypes.string
  }),
  partOfTotalNumber: PropTypes.string,
  progressBarChildren: PropTypes.any,
  children: PropTypes.any,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func
};

export default ProgressDiagram;
