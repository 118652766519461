import { PublicClientApplication } from '@azure/msal-browser';
import { head } from 'lodash';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const instance = 'https://mobexocustomersdirectory.b2clogin.com';
const tenant = 'mobexocustomersdirectory.onmicrosoft.com';
export const signInPolicy =
  process.env.REACT_APP_CURRENT_ENV === 'prod' ? 'B2C_1A_SIGNIN_SSO_PROD' : 'B2C_1A_SIGNIN_SSO_DEV';

export const resetPasswordPolicy = 'B2C_1_password_reset';
const clientId = 'be94c0ef-acc0-45da-ad1c-4023f53c5822';

const redirectUri =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_CURRENT_ENV === 'prod'
      ? 'https://app.mobexo.de/signin-oidc'
      : process.env.REACT_APP_CURRENT_ENV === 'staging'
      ? 'https://app-mobexo-de-frontend-staging.azurewebsites.net/signin-oidc'
      : 'https://portal-mobexo-de-frontend-sandbox.azurewebsites.net/signin-oidc'
    : 'http://localhost:3000/signin-oidc';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId,
    redirectUri: redirectUri,
    authority: `${instance}/${tenant}/${signInPolicy}`,
    knownAuthorities: ['mobexocustomersdirectory.b2clogin.com'],
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['https://mobexocustomersdirectory.onmicrosoft.com/mobexo/read']
};

export const forgotPasswordRequest = {
  authority:
    'https://mobexocustomersdirectory.b2clogin.com/mobexocustomersdirectory.onmicrosoft.com/B2C_1_password_reset'
};

export const msalInstance = new PublicClientApplication(msalConfig);

class AuthService {
  getAccount() {
    let account = msalInstance.getActiveAccount();
    if (!account) {
      const accounts = msalInstance.getAllAccounts();
      account = head(accounts);
    }

    return account;
  }

  async acquireTokenAsync() {
    const account = this.getAccount();
    const request = { account: this.getAccount(), scopes: [...loginRequest.scopes] };
    if (!account) {
      return null;
    }

    const token = await msalInstance.acquireTokenSilent(request);
    return token.accessToken;
  }
}

const authService = new AuthService();

export default authService;
