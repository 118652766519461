import capitalize from 'lodash/capitalize';

export const gapsIntoCamelCase = (sentence: string, options?: GapsIntoCamelCaseOptions) => {
  const splitToGap = sentence.split(options?.split || ' ');

  const camelCaseArray = splitToGap.map((gapItem, index) => {
    if (index === 0) {
      return gapItem;
    }

    return capitalize(gapItem);
  });

  return camelCaseArray.join('');
};

export interface GapsIntoCamelCaseOptions {
  split: string;
}
