import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setEndLoading, setStartLoading } from '../actions/app_action';

const useOverlay = (loadingKeyProp: string = 'default', isLoading?: boolean) => {
  const dispatch = useDispatch();

  const show = useCallback(
    (loadingKey: string = loadingKeyProp, isExtendedHeight = false) => {
      dispatch(setStartLoading(loadingKey, isExtendedHeight));
    },
    [dispatch]
  );

  const hide = useCallback(
    (loadingKey: string = loadingKeyProp) => {
      dispatch(setEndLoading(loadingKey));
    },
    [dispatch]
  );

  useEffect(() => {
    if (typeof isLoading === 'boolean') {
      isLoading ? show() : hide();
    }

    return () => {
      if (typeof isLoading === 'boolean') {
        hide();
      }
    };
  }, [hide, isLoading, show]);

  return [show, hide];
};

export default useOverlay;
