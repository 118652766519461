import React, { useEffect, useMemo, useState } from 'react';
import classes from './fahrzeuge-der-car-policy-filters.module.scss';
import {
  ClearAllFiltersButton,
  Filter,
  FilterButton,
  FilterContainer,
  FilterInput,
  FilterToggle
} from '../../../components/filter-items';
import { getUserCompanyDriverLevels } from '../../../services/driver-level-service';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CRM_VALUE, EMTY_FILTER_STATE } from '../fahrzeuge-der-car-policy-page';
import { USER_ROLES } from '../../../constants';
import { useIsUserHaveRole } from '../../../hooks/use-is-user-have-role';
import { PAGES } from '../../../common/enums';

const FahrzeugeDerCarPolicyFilters = ({ sessionFilter, setSessionFilter, goToFirstPage }) => {
  const location = useLocation();
  const isEmployeeConfigurationsPage = location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATIONS);
  const isReferenzfahrzeuge =
    location.pathname.includes(PAGES.REFERENCE_VEHICLES) &&
    !location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  const user = useSelector(state => state['app'].user);
  const filterOptions = useSelector(state => state['filter'].options);
  const [isOpen, setOpen] = useState('');
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  const [filterDataDriverLevel, setDriverLevel] = useState(['']);

  const isKonfigurationenNeuesFahrzeugPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_CONFIGURATIONS);
  }, [location]);
  const isReferenzfahrzeugeNeuesFahrzeugPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  }, [location]);

  const toggleFilterIsOpen = (title = null) => {
    setOpen(title);
  };

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'car policy':
        return CRM_VALUE.carPolicy;

      case 'hersteller':
        return CRM_VALUE.manufacturer;

      case 'modell':
        return CRM_VALUE.model;

      case 'antrieb':
        return CRM_VALUE.engineType;

      case 'mobilitätsbudget':
        return CRM_VALUE.driverLevel;

      case 'unternehmen':
        return CRM_VALUE.company;

      case 'status':
        return CRM_VALUE.status;

      case 'fahrer':
        return CRM_VALUE.driver;

      case 'kostenverantwortlicher':
        return CRM_VALUE.responsiblePerson;

      case 'angebotsnummer':
        return CRM_VALUE.offerNumber;

      default:
        return '';
    }
  };

  const clearFilter = title => {
    toggleFilterIsOpen();
    goToFirstPage();
    if (!title) {
      return setSessionFilter(EMTY_FILTER_STATE, EMTY_FILTER_STATE);
    }

    saveFilter(title, null, null);
  };

  const saveFilter = (title, serverValue, clientValue) => {
    const newSessionFilterForClient = {
      ...sessionFilter.filterForClient,
      [convertHeaderToCrmName(title)]: clientValue
    };

    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue },
      !isNeedToClearBigFilter(newSessionFilterForClient) && newSessionFilterForClient[CRM_VALUE.status]
    );

    goToFirstPage();
  };

  const isNeedToClearBigFilter = sessionFilterForClient => {
    let isNeedToClear = false;
    const filtersData = Object.entries(sessionFilterForClient);

    for (let [key, value] of filtersData) {
      if (key === CRM_VALUE.company) {
        if (user.companyName !== value) {
          isNeedToClear = true;
          break;
        }
      }
      if (key !== CRM_VALUE.status && key !== CRM_VALUE.company) {
        if (value) {
          isNeedToClear = true;
          break;
        }
      }
    }
    return isNeedToClear;
  };

  const companyLabel = sessionFilter.filterForClient[CRM_VALUE.company];
  const carPolicyLabel = sessionFilter.filterForClient[CRM_VALUE.carPolicy];
  const manufacturerLabel = sessionFilter.filterForClient[CRM_VALUE.manufacturer];
  const modelLabel = sessionFilter.filterForClient[CRM_VALUE.model];
  const engineTypeLabel = sessionFilter.filterForClient[CRM_VALUE.engineType];
  const driverLevelLabel = sessionFilter.filterForClient[CRM_VALUE.driverLevel];
  const responsiblePersonLabel = sessionFilter.filterForClient[CRM_VALUE.responsiblePerson];
  const driverLabel = sessionFilter.filterForClient[CRM_VALUE.driver];
  const statusLabel = sessionFilter.filterForClient[CRM_VALUE.status];
  const offerNumber = sessionFilter.filterForClient[CRM_VALUE.offerNumber];

  const handleInput = (title, value) => {
    toggleFilterIsOpen();

    if (convertHeaderToCrmName(title) === CRM_VALUE.carPolicy) {
      const carPolicy = filterOptions?.carPolicies?.find(carPolicyItem => carPolicyItem.name === value);
      if (carPolicy) {
        saveFilter(title, carPolicy.id, carPolicy.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.manufacturer) {
      const manufacturer = filterOptions?.uds_carbrandid?.find(manufacturerItem => manufacturerItem.name === value);
      if (manufacturer) {
        saveFilter(title, manufacturer.id, manufacturer.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.model) {
      const model = filterOptions?.uds_carmodelid?.find(modelItem => modelItem.name === value);
      if (model) {
        saveFilter(title, model.id, model.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.engineType) {
      const engineType = filterOptions?.uds_enginetypecode?.find(engineItem => engineItem.name === value);
      if (engineType) {
        saveFilter(title, engineType.id, engineType.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.driverLevel) {
      const driverLevel = filterDataDriverLevel.find(engineItem => engineItem.value === value);
      if (driverLevel) {
        saveFilter(title, driverLevel.id, driverLevel.value);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.company) {
      const company = filterOptions?.accounts?.find(companyItem => companyItem.name === value);
      if (company) {
        saveFilter(title, company.id, company.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.driver) {
      const driver = filterOptions?.contacts?.find(driverItem => driverItem.fullname === value);
      if (driver) {
        saveFilter(title, driver.id, driver.fullname);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
      const status = filterOptions?.statuses?.find(statusItem => statusItem.id === value);
      if (status) {
        saveFilter(title, status.id, status.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.responsiblePerson) {
      const responsiblePerson = filterOptions?.uds_kstverantw?.find(
        responsiblePerson => responsiblePerson.name === value
      );
      if (responsiblePerson) {
        saveFilter(title, responsiblePerson.id, responsiblePerson.name);
      }
      return;
    }

    saveFilter(title, value, value);
  };

  useEffect(() => {
    if (isReferenzfahrzeuge) {
      getUserCompanyDriverLevels(user.id).then(data => {
        const driverLevel = data.data.items.map(driverLevelItem => ({
          id: driverLevelItem.uds_car_policy_nutzerid,
          value: driverLevelItem.uds_name
        }));
        setDriverLevel(driverLevel);
      });
    }
  }, []);

  return (
    <section className={classes.filters}>
      {isEmployeeConfigurationsPage && (
        <Filter
          title={'Unternehmen'}
          isOpen={isOpen}
          value={companyLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Unternehmen'
            onClose={() => {
              toggleFilterIsOpen();
            }}
          >
            <FilterInput
              title='Unternehmen'
              handleInput={handleInput}
              dropListData={filterOptions?.accounts?.map(e => e.name) || []}
            />
          </FilterContainer>
        </Filter>
      )}

      <Filter
        title={'Angebotsnummer'}
        isOpen={isOpen}
        value={offerNumber}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Angebotsnummer'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput
            title='Angebotsnummer'
            handleInput={handleInput}
            dropListData={filterOptions?.uds_name || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      {!(isKonfigurationenNeuesFahrzeugPage || isReferenzfahrzeugeNeuesFahrzeugPage) && (
        <Filter
          title={'Car Policy'}
          isOpen={isOpen}
          value={carPolicyLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Car Policy'
            onClose={() => {
              toggleFilterIsOpen();
            }}
          >
            <FilterInput
              title='Car Policy'
              handleInput={handleInput}
              dropListData={filterOptions?.carPolicies?.map(e => e.name) || []}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
      )}
      {isReferenzfahrzeuge ? (
        <Filter
          title={'Mobilitätsbudget'}
          isOpen={isOpen}
          value={driverLevelLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Mobilitätsbudget'
            onClose={() => {
              toggleFilterIsOpen();
            }}
          >
            <FilterInput
              title='Mobilitätsbudget'
              handleInput={handleInput}
              dropListData={filterDataDriverLevel.map(driverLevel => driverLevel.value) || []}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
      ) : null}

      <Filter
        title={'Hersteller'}
        isOpen={isOpen}
        value={manufacturerLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Hersteller'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput
            title='hersteller'
            handleInput={handleInput}
            dropListData={filterOptions?.uds_carbrandid?.map(e => e.name) || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Modell'}
        isOpen={isOpen}
        value={modelLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Modell'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput
            title='modell'
            handleInput={handleInput}
            dropListData={filterOptions?.uds_carmodelid?.map(e => e.name) || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Antrieb'}
        isOpen={isOpen}
        value={engineTypeLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Antrieb'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput
            title='antrieb'
            handleInput={handleInput}
            dropListData={filterOptions?.uds_enginetypecode?.map(e => e.name) || []}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      {isEmployeeConfigurationsPage && !isPoolManager && (
        <Filter
          title='Kostenverantwortlicher'
          isOpen={isOpen}
          value={responsiblePersonLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer header='Kostenverantwortlicher' onClose={toggleFilterIsOpen}>
            {filterOptions?.uds_kstverantw?.slice(0, 2).map(responsiblePerson => (
              <FilterButton
                handleInput={handleInput}
                title='Kostenverantwortlicher'
                label={responsiblePerson.name}
                value={responsiblePerson.name}
                key={responsiblePerson.id}
              />
            ))}
            <FilterInput
              title='Kostenverantwortlicher'
              handleInput={handleInput}
              dropListData={filterOptions?.uds_kstverantw?.map(responsiblePerson => responsiblePerson.name) || []}
              withoutSearchButton
            />
          </FilterContainer>
        </Filter>
      )}
      {isEmployeeConfigurationsPage && (
        <Filter
          title={'Fahrer'}
          isOpen={isOpen}
          value={driverLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Fahrer'
            onClose={() => {
              toggleFilterIsOpen();
            }}
          >
            <FilterInput
              title='Fahrer'
              handleInput={handleInput}
              dropListData={filterOptions?.contacts?.map(e => e.fullname) || []}
            />
          </FilterContainer>
        </Filter>
      )}
      {isEmployeeConfigurationsPage && (
        <Filter
          title={'Status'}
          isOpen={isOpen}
          value={statusLabel}
          toggleFilterIsOpen={toggleFilterIsOpen}
          clearFilter={clearFilter}
        >
          <FilterContainer
            header='Status'
            onClose={() => {
              toggleFilterIsOpen();
            }}
          >
            {filterOptions?.statuses?.map(status => (
              <FilterButton
                key={status.id}
                title='Status'
                label={status.name}
                value={status.id}
                handleInput={handleInput}
              />
            ))}
          </FilterContainer>
        </Filter>
      )}
      <ClearAllFiltersButton filterState={sessionFilter?.filterForClient} clearFilter={clearFilter} />
    </section>
  );
};

export default FahrzeugeDerCarPolicyFilters;
