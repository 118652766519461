import React, { useRef } from 'react';
import { MODAL_NAMES, MODALS, TASK_STATUS_CODES } from '../../../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ChecklistUpload from 'components/aufgaben-component/components/upload-checklist/upload-checklist';
import { setSnakbar, toggleModal } from 'actions/app_action';
import { useOverlay } from 'hooks';
import { closeChecklistTask } from 'components/aufgaben-component/utils';

export const useChecklistButtons = () => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const { checklist } = car;
  const status = checklist.widgetState.attributeValue.value;
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();
  const reportFormStateRef = useRef<{ files: File[] } | null>(null);

  const onReportFormComplete = (files: File[]) => {
    reportFormStateRef.current = {
      files
    };
  };

  const showButton = useMemo(() => {
    return [TASK_STATUS_CODES.open, TASK_STATUS_CODES.postponed].includes(status);
  }, [status]);

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submitChecklistReport = () => {
    closeAlert();
    showOverlay();
    reportFormStateRef.current &&
      closeChecklistTask(checklist.activityid.attributeValue, Object.values(reportFormStateRef.current.files)).then(
        response => {
          dispatch(
            setSnakbar({
              isOpen: true,
              message: response.data.isSuccess
                ? 'Die Checkliste wurde erfolgreich versendet'
                : response.data.messages[0].message,
              type: response.data.level.toLowerCase()
            })
          );
          refresh();
          hideOverlay();
        }
      );
  };

  const openUploadChecklistModal = () => {
    const alertData = {
      title: 'Checkliste für Fahrzeugrückgabe',
      children: <ChecklistUpload onFormComplete={onReportFormComplete} onSubmit={submitChecklistReport} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { showButton, openUploadChecklistModal };
};
