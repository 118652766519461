import React, { memo, useEffect, useState } from 'react';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import { DataDamageChartProps } from './damage-chart.props';
import { PercentColor, PercentSetupType } from 'components/expenses-per-year/expenses-per-year.props';
import { Position } from 'common/enums';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { options, data as chartDataModel } from 'models/chart/dashboard/damage.model';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const DamageChart = memo(({ data }: DataDamageChartProps) => {
  const [chartData, setChartData] = useState<ChartData<'bar', number[], string>>(chartDataModel);

  const currentYear = new Date().getFullYear();
  const percentageFromLastYearToCurrent = data?.percentage || 0;
  const percentSetup: PercentSetupType = {
    percent: Math.abs(percentageFromLastYearToCurrent),
    color: percentageFromLastYearToCurrent > 0 ? PercentColor.Red : PercentColor.Green,
    arrowDirection: percentageFromLastYearToCurrent > 0 ? Position.Bottom : Position.Top
  };

  useEffect(() => {
    if (data?.diagram) {
      setChartData(prevState => ({
        labels: Object.keys(data.diagram),
        datasets: prevState.datasets.map(dataset => {
          dataset.data = Object.values(data.diagram);
          dataset.data[1] = dataset.data[1] || 0;
          return dataset;
        })
      }));
    }
  }, [JSON.stringify(data?.diagram)]);

  return (
    <div className='d-flex a-i-center j-c-space-between'>
      <ExpensesPerYear
        name='Schadenfälle'
        year={currentYear}
        price={data?.diagram[currentYear] || 0}
        percentSetup={percentSetup}
      />
      <div>
        <Bar options={options} data={chartData} width='240px' />
      </div>
    </div>
  );
});
