import { useEffect, useRef } from 'react';

export const useTimeout = () => {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    return deleteTimeout;
  }, []);

  const createTimeout = (callback: Function, delay: number) => {
    timeoutRef.current && deleteTimeout();
    timeoutRef.current = setTimeout(callback, delay);
  };

  const deleteTimeout = () => {
    clearTimeout(timeoutRef.current);
  };

  return { createTimeout, deleteTimeout };
};
