import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { UnusedFleetQueryKeys } from '../fleet.props';
import { UnusedFleetResponseItem } from './unused-fleet.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useUnusedFleetQuery = (
  userIdProps?: string,
  {
    options
  }: QueryHookOptions<AxiosResponse<Partial<UnusedFleetResponseItem>[]>, Partial<UnusedFleetResponseItem>[]> = {}
) => {
  const user = useTypedSelector(state => state.app.user);
  const userId = userIdProps || user.id;

  const { fetch } = useFetch<Partial<UnusedFleetResponseItem>[]>({
    loadingKey: `unusedFleetQuery${userId}`,
    ...fleetRequestConfig.unusedFleet(userId)
  });

  const { data, ...restQueryProps } = useQuery([UnusedFleetQueryKeys.UnusedFleet, userId], () => fetch(), options);

  return { unusedFleetQuery: data?.data || [], ...restQueryProps };
};
