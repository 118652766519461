import React from 'react';

const InfoIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.98299 5.5593C7.7796 5.5593 7.64401 5.4915 7.50841 5.35591C7.37282 5.22032 7.30502 5.08473 7.30502 4.88134C7.30502 4.67795 7.37282 4.54235 7.50841 4.40676C7.64401 4.27117 7.7796 4.20337 7.98299 4.20337C8.18638 4.20337 8.32197 4.27117 8.45757 4.40676C8.59316 4.54235 8.66096 4.67795 8.66096 4.88134C8.66096 5.08473 8.59316 5.28811 8.45757 5.35591C8.32197 5.4915 8.11858 5.5593 7.98299 5.5593ZM8.66096 6.98303V10.983C8.66096 11.2542 8.59316 11.4576 8.45757 11.5932C8.32197 11.7288 8.18638 11.7966 7.98299 11.7966C7.7796 11.7966 7.64401 11.7288 7.50841 11.5932C7.37282 11.4576 7.30502 11.2542 7.30502 10.983V7.05083C7.30502 6.77964 7.37282 6.57625 7.50841 6.44066C7.64401 6.30506 7.7796 6.23727 7.98299 6.23727C8.18638 6.23727 8.32197 6.30506 8.45757 6.44066C8.59316 6.50845 8.66096 6.71184 8.66096 6.98303Z'
        fill='#919699'
      />
      <path
        d='M8 16C5.89831 16 3.86441 15.1864 2.37288 13.6949C0.813559 12.1356 0 10.1017 0 8C0 5.89831 0.813559 3.86441 2.37288 2.37288C3.86441 0.813559 5.83051 0 8 0C10.1017 0 12.1356 0.813559 13.6271 2.30508C16.7458 5.42373 16.7458 10.5085 13.6271 13.6271C12.1356 15.1186 10.1017 16 8 16ZM8 1.35593C6.23729 1.35593 4.54237 2.0339 3.32203 3.32203C2.0339 4.54237 1.35593 6.23729 1.35593 8C1.35593 9.76271 2.0339 11.4576 3.32203 12.678C4.54237 13.8983 6.23729 14.6441 8 14.6441C9.76271 14.6441 11.4576 13.9661 12.678 12.678C15.2542 10.1017 15.2542 5.89831 12.678 3.32203C11.3898 2.0339 9.76271 1.35593 8 1.35593Z'
        fill='#919699'
      />
    </svg>
  );
};

export default InfoIcon;
