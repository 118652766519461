import React from 'react';
import LeasingWidget from '../components/leasing-widget';
import { CarRepairIcon } from 'components/icons-new/car-repair';
import { useTheme } from 'styled-components';
import { useVehicleInitialReview } from './use-vehicle-initial-review';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import VehicleInitialReviewButtons from './components/vehicle-initial-review-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';

const VehicleInitialReview = () => {
  const theme = useTheme();
  const { statusColor, statusLabel, description } = useVehicleInitialReview();

  return (
    <LeasingWidget
      image={<CarRepairIcon color={theme.colors.midBlue} width={60} height={40} hovered />}
      statusColor={statusColor}
      statusLabel={statusLabel}
      buttons={<VehicleInitialReviewButtons />}
      id={'vehicleInitialReview'}
    >
      <WidgetWrapper>
        <DescriptionContainer>{description}</DescriptionContainer>
      </WidgetWrapper>
    </LeasingWidget>
  );
};

export default VehicleInitialReview;
