import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { PenIcon } from 'components/icons-new/pen';
import { NoteItemHeaderProps } from './header.props';
import { DeleteNotesButton } from '../../../buttons/delete-note/delete-note';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import IconHover from 'components/icon-hover';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const NoteItemHeader = ({ uds_notizenid, uds_createdbyb2c, title, onEdit }: NoteItemHeaderProps) => {
  const user = useTypedSelector(state => state.app.user);

  const isNotesCreatedByLoggedInUser = user.id === uds_createdbyb2c?.id;

  return (
    <Flex width='100%' align='center' justify='space-between'>
      <EllipsisWithTooltip tooltip={title}>
        <Text color='black' bold right='10'>
          {title}
        </Text>
      </EllipsisWithTooltip>

      {isNotesCreatedByLoggedInUser && (
        <Flex>
          {uds_notizenid && <DeleteNotesButton noteId={uds_notizenid} />}
          <IconHover pointer>
            <PenIcon width={22} height={22} onClick={onEdit} />
          </IconHover>
        </Flex>
      )}
    </Flex>
  );
};
