import React, { useMemo } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { SummaryItemData, SummaryComponentData, ProductFormProps } from '../../package-page.props';
import { StyledPaymentContainer, StyledAddonsContainer } from './summary-component.styled';
import { StyledAddonPrice, StyledNumberText } from '../check-order-component/check-order-component.styled';
import { formatDate } from 'utils/convert-date-to-server';
import { WidgetDocumnetContainer } from 'components/widget-documnet/widget-documnet-container';
import { WidgetType } from 'components/widget-documnet/widget-documnet-container.props';
import classes from 'pages/support-page/support-page.module.scss';
import { PaymentFrequency } from 'common/enums';
import WidgetHeader from 'components/widget-header/widget-header';
import moment from 'moment';

export const SummaryItem = (item: SummaryItemData) => {
  return (
    <Flex justify='space-between' bottom='20' width='100%'>
      <Flex direction='column' width='100%'>
        <Flex justify='space-between' align='center'>
          <Flex width='60%'>
            <Text bold color='grey800'>
              {item.title}
            </Text>
          </Flex>
          {Boolean(item.price) ? (
            <StyledNumberText
              color='grey800'
              bold={false}
              value={item.totalPrice}
              displayType='text'
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={' EUR'}
            />
          ) : (
            <Flex>
              <Text size='18' color='grey800'>
                Inklusive
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex>
          {(Boolean(item.price) || item.isSubCompanyCheckOutFlow) && (
            <Text color='grey800' fontWeight={300}>
              {item.lowerText}&nbsp;
            </Text>
          )}

          {Boolean(item.isExtraProvidersAddon) && (
            <Text color='grey800' fontWeight={300}>
              {item.chosenExtraProvidersLabel}
            </Text>
          )}

          {Boolean(item.quantity) && !item.isPerOperationAddon && !item.subtitle && (
            <Text color='grey800'>{item.quantity}</Text>
          )}
          {Boolean(item.quantity) && !item.isPerOperationAddon && Boolean(item.price) && item.subtitle && (
            <Text color='grey800'>
              {
                <span>
                  {`(${item.quantity} x `}
                  <StyledAddonPrice
                    color='grey800'
                    bold={false}
                    value={item.price}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={' EUR'}
                  />
                  {')'}
                </span>
              }
            </Text>
          )}
          {item.isPerOperationAddon && (
            <StyledAddonPrice
              color='grey800'
              bold={false}
              value={item.price}
              displayType='text'
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={' EUR'}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const getAddonsByType = (addons: ProductFormProps[], paymentType: number) => {
  return [...addons].filter(
    addon => addon.productInfo.paymentFrequency.attributeValue.value === paymentType && addon.isChecked
  );
};

const SummaryComponent = ({
  showAddons,
  totalMonthlyPayment,
  oneTimePayment,
  packagePrice,
  addons,
  packageName,
  quantity,
  startDate,
  isSubCompanyCheckOutFlow,
  extraProvidersLabel
}: SummaryComponentData) => {
  const allAddons = {
    perMonth: addons?.length ? getAddonsByType(addons, PaymentFrequency.PerMonth) : [],
    perOperation: addons?.length ? getAddonsByType(addons, PaymentFrequency.PerOperation) : [],
    unique: addons?.length ? getAddonsByType(addons, PaymentFrequency.Unique) : []
  };

  return (
    <WidgetDocumnetContainer className={`${classes.widget} ${classes.widget_download}`} type={WidgetType.Simple}>
      <>
        <WidgetHeader title={'Zusammenfassung'} icon={<img src='/assets/images/euro_blue.svg' alt='euro' />} />
        <hr className={classes.hr_widget} />
        <div className={classes.files_block}>
          <SummaryItem
            title={`${packageName} Paket`}
            lowerText='Anzahl Fahrzeuge: '
            quantity={quantity}
            totalPrice={packagePrice * quantity}
            price={packagePrice * quantity}
            subtitle={false}
            isSubCompanyCheckOutFlow={isSubCompanyCheckOutFlow}
          />
          {showAddons && (
            <StyledAddonsContainer>
              <Text bold bottom='20'>
                Zusatzpakete
              </Text>
              {allAddons.perMonth.map((addon: ProductFormProps) => (
                <SummaryItem
                  key={addon.productInfo.name.attributeValue}
                  title={addon.productInfo.name.attributeValue}
                  lowerText={`${addon.productDescription.pricelabel || 'Anzahl'}:`}
                  quantity={addon.quantity}
                  totalPrice={addon.productInfo.price.attributeValue.value * addon.quantity}
                  price={addon.productInfo.price.attributeValue.value}
                  subtitle={true}
                  isExtraProvidersAddon={Boolean(addon.productDescription.optionsetlist)}
                  chosenExtraProvidersLabel={extraProvidersLabel}
                />
              ))}
            </StyledAddonsContainer>
          )}
          <StyledPaymentContainer>
            <Text color='blue' bold size='16'>
              Monatlich
            </Text>
            <StyledNumberText
              color='blue'
              bold
              value={totalMonthlyPayment}
              displayType='text'
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={' EUR'}
            />
          </StyledPaymentContainer>

          {showAddons && (
            <>
              {allAddons.unique.map((addon: ProductFormProps) => (
                <SummaryItem
                  key={addon.productInfo.name.attributeValue}
                  title={addon.productInfo.name.attributeValue}
                  lowerText={`${addon.productDescription.pricelabel || 'Anzahl'}:`}
                  quantity={addon.quantity}
                  totalPrice={addon.productInfo.price.attributeValue.value * addon.quantity}
                  price={addon.productInfo.price.attributeValue.value}
                  subtitle={true}
                />
              ))}
              <StyledPaymentContainer>
                <Text color='blue' bold size='16'>
                  Einmalig
                </Text>
                <StyledNumberText
                  color='blue'
                  bold
                  value={oneTimePayment}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={' EUR'}
                />
              </StyledPaymentContainer>
            </>
          )}

          {showAddons && (
            <>
              {allAddons.perOperation.map((addon: ProductFormProps) => (
                <SummaryItem
                  key={addon.productInfo.name.attributeValue}
                  title={addon.productInfo.name.attributeValue}
                  lowerText={`${addon.productDescription.pricelabel || 'Anzahl'}:`}
                  quantity={addon.quantity}
                  totalPrice={0}
                  price={addon.productInfo.price.attributeValue.value}
                  subtitle={true}
                  isPerOperationAddon
                />
              ))}
              <StyledPaymentContainer>
                <Text color='blue' bold size='16'>
                  Vorgangsbezogen
                </Text>
                <StyledNumberText
                  color='blue'
                  bold
                  value={0}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={' EUR'}
                />
              </StyledPaymentContainer>
            </>
          )}
          {startDate && (
            <Flex justify='space-between' width='100%'>
              <Text color='grey600'>Startdatum</Text>
              <Text color='black'>{moment(startDate).isValid() && formatDate(startDate)}</Text>
            </Flex>
          )}

          <Flex top='25' style={{ lineHeight: '18px' }}>
            <Text size='10' color='grey600'>
              Alle Werte zzgl. der jeweiligen Umsatzsteuer, die jeweils gültige Umsatzsteuer auf steuerbare
              Dienstleistungen wird gesondert berechntet.
            </Text>
          </Flex>
        </div>
      </>
    </WidgetDocumnetContainer>
  );
};

export default SummaryComponent;
