import authService from 'services/auth-service';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../constants';
import { ResponseModel , Title} from '../common/interfaces';
import {GetFilterQueryByName} from '../common/enums';

interface FilterItemValue{
  attributes:{
    statecode?:{
      label: string;
      value: number;
    };
    [key:string]:any;
  };
  id:string;
  name:string;
};

export type TransformedFilterItem = Pick<FilterItemValue, 'id' | 'name'> & {statecode?:number};
export interface TransformedCostTypeFilterData extends Pick<CostTypeFilterData, 'fullList' | 'groups'>{
  filteredList:CostTypeFilterItem[];
};
export interface FilterDataResponse<T>{
  data:T;
  isError:boolean;
  isSuccess:boolean;
  isWarning:boolean;
  level:string;
  messages:unknown[];
};
export interface CostTypeFilterItem{
  key:string;
  value:string;
};

export type FuelGroups = {
  Tankkosten: boolean;
  Benzin: boolean;
  Diesel: boolean;
  Strom: boolean;
};
export interface CostTypeFilterData{
  fullList:CostTypeFilterItem[];
  fileredList:CostTypeFilterItem[];
  groups:FuelGroups
};

export const getFahrzeugkostenTable = async (
  tableParams: any,
  filterForServer: any
): Promise<AxiosResponse<ResponseModel>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/GetFahrzeugkostenTable`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...tableParams,
      search: filterForServer
    }
  });
};

export const getFahrzeugkostenFilterDataByName = async (filterQueryName:Title, params?:{name:string})
    :Promise<AxiosResponse<TransformedFilterItem[]>>=>
{
  const token = await authService.acquireTokenAsync();
  let data = {};
  if(params){
     data = {...params};
  }
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/${GetFilterQueryByName[filterQueryName]}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data,
    transformResponse(response:string){
      const result:FilterDataResponse<FilterItemValue[]> = JSON.parse(response);
      return result.data.map( item => {
        let element:TransformedFilterItem =  {
            name:item.name,
            id:item.id
        };
        if(item.attributes?.statecode){
          element.statecode = item.attributes.statecode.value;
        }
        return element;
      });
    }
  });
};

export const getFahrzeugkostenCostTypeFilterData = async (filterQueryName:Title, params?:{name:string})
    :Promise<AxiosResponse<TransformedCostTypeFilterData>>=>
{
  const token = await authService.acquireTokenAsync();
  let data = {};
  if(params){
     data = {...params};
  }
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/${GetFilterQueryByName[filterQueryName]}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data,
    transformResponse(response:string){
      const result:FilterDataResponse<CostTypeFilterData> = JSON.parse(response);
      return {
        fullList:[...result.data.fullList],
        filteredList:[...result.data.fileredList],
        groups:{...result.data.groups}
      };
    }
  });
};


export const getFahrzuegkostenFile = async (invoiceid: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Fahrzuegkosten/GetFahrzuegkostenFile`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      invoiceid
    }
  });
};

export const getFileForExcel = async (params: any) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzuegkosten/generateexcel`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};
