import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './driving-license-control.module.scss';
import { withPageContainer } from '../../hoc/withPageContainer';
import { DrivingLicenseControlFilters } from './components/driving-license-control-filters/driving-license-control-filters';
import PanelPlaceholder from '../panel-placeholder';
import ProgressDiagram from '../progress-diagram';
import CheckIcon from '../icons/check';
import { CloseIcon } from '../icons/close-icon';
import { QuestionIcon } from '../icons/question-icon';
import TableComponent from '../table-component';
import { setValue } from '../../utils/get-response-data';
import { ControlItem } from './components/items-component/control-item/control-item';
import { SmallDiagram } from './components/items-component/small-diagram/small-diagram';
import { DriverscheckMenu } from './components/items-component/driverscheck-menu/driverscheck-menu';
import { setContactSetup } from '../../actions/app_action';
import { useDispatch } from 'react-redux';
import { isMobileWidth, USER_ROLES } from '../../constants';
import { useHistory } from 'react-router';
import { cloneDeep } from 'lodash';
import { useSessionFilter } from 'hooks/use-session-filter';
import { useDriversCheckQuery } from 'hooks/react-query/drivers-check';
import { PAGES } from 'common/enums';
import { TableComponentMobile } from './components/table-component-mobile/table-component-mobile';
import { useIsUserHaveRole } from '../../hooks/use-is-user-have-role';
import { TABLE_MENU_WIDTH } from 'components/table-component/table-component';
import Pagination from '../pagination';
import { DrivingLicenseDriverStatus } from './components/items-component/status/status';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { useOverlay } from 'hooks';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { useSetFilterFromLocationState } from 'hooks/use-set-filter-from-location-state/use-set-filter-from-location-state';
import { useFileDownload } from 'hooks/useFileDownload';
import { driverCheckRequestConfig } from 'request-config/driver-check/driver-check.request-config';
import usePaginationState from '../../hooks/use-pagination-state/use-pagination-state';

const DrivingLicenseControlComponent = () => {
  const dispatch = useDispatch();
  const { defaultPage, setDefaultPage } = usePaginationState(PAGES.DRIVERS_CHECK);

  const [tableParams, setTableParams] = useState(getDefaultTableParams(defaultPage));

  const defaultFilterStateForServer = {
    ...EMTY_FILTER_STATE,
    [CRM_VALUE.bookedService]: true
  };

  const defaultFilterStateForClient = {
    ...EMTY_FILTER_STATE,
    [CRM_VALUE.bookedService]: 'ja'
  };

  const { sessionFilter, setSessionFilter } = useSessionFilter(
    PAGES.DRIVERS_CHECK,
    defaultFilterStateForServer,
    defaultFilterStateForClient
  );
  const { table, filters, statuses } = useDriversCheckQuery(tableParams, sessionFilter);
  const history = useHistory();

  const goToFirstPage = () => {
    setDefaultPage(1);
    setTableParams(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        start: 1
      }
    }));
  };

  useSetFilterFromLocationState(setSessionFilter, EMTY_FILTER_STATE, EMTY_FILTER_STATE, goToFirstPage);

  const statusLabel = sessionFilter?.filterForClient[CRM_VALUE.status] || '';

  const diagramColumn = useMemo(
    () => ({
      name: '',
      type: 'text',
      component: (
        <SmallDiagram
          row={{}}
          isDiagramVisible={statusLabel.toLowerCase() === CONTROL_STATUS.incompleteData.toLowerCase()}
        />
      ),
      nameToHide: 'diagram',
      sort: false,
      width: '6%',
      className: null
    }),
    [statusLabel]
  );

  const [columns, setColumns] = useState(defaultColumns);

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  const pageChangeHandler = page => {
    setDefaultPage(page);
    setTableParams(tableParams => ({ ...tableParams, pagination: { ...tableParams.pagination, start: page } }));
  };

  const sortHandler = fieldName => {
    goToFirstPage();
    setTableParams(prevState => ({
      ...prevState,
      sort: {
        predicate: fieldName,
        reverse: fieldName === prevState.sort.predicate ? !prevState.sort.reverse : false
      }
    }));
  };

  const saveFilter = (title, filterForServer, filterForClient) => {
    setSessionFilter({ [title]: filterForServer }, { [title]: filterForClient });
  };

  const progressDiagramHandler = (status, numberOfStatuses) => {
    if (sessionFilter.filterForClient[CRM_VALUE.status] === status) {
      goToFirstPage();
      return saveFilter(CRM_VALUE.status, null, null);
    }

    if (numberOfStatuses) {
      const statusInfo = filters.statuses.find(statusItem => statusItem.label === status);

      if (statusInfo) {
        setSessionFilter(
          { [CRM_VALUE.bookedService]: true, [CRM_VALUE.status]: statusInfo.value },
          { [CRM_VALUE.bookedService]: 'ja', [CRM_VALUE.status]: statusInfo.label }
        );
        goToFirstPage();
      }
    }
  };

  useEffect(() => {
    if (statusLabel === CONTROL_STATUS.incompleteData) {
      let newColumns = cloneDeep(columns);

      newColumns = newColumns.map(column => {
        const columnWidth = +column.width.replace('%', '');

        if (isNaN(columnWidth)) {
          column.width = column.width;
        } else {
          column.width = `${columnWidth - 1}%`;
        }

        return column;
      });

      newColumns.unshift(diagramColumn);

      setColumns(newColumns);
    } else {
      let newColumns = cloneDeep(columns);
      newColumns = newColumns.filter(column => {
        const columnWidth = +column.width.replace('%', '');

        if (isNaN(columnWidth)) {
          column.width = column.width;
        } else {
          column.width = `${columnWidth + 1}%`;
        }

        return column.nameToHide !== 'diagram';
      });
      setColumns(newColumns);
    }
  }, [statusLabel]);

  const downloadFile = useFileDownload({
    requestConfig: driverCheckRequestConfig.driverCheckDownloadExcel({
      search: sessionFilter?.filterForServer,
      sort: tableParams.sort
    }),
    fileName: 'Führerscheinkontrolle.xlsx',
    encodeConfig: {
      path: ['data'],
      encodeWay: 'b64Data',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    isSuccessSnackbar: true,
    isErrorSnackbar: true
  });

  const openSettingPageVia = (row, state = {}) => {
    const { parentcustomerid, contactid } = row;

    if (isDriver || isPoolManager) {
      return;
    }

    dispatch(
      setContactSetup({
        id: contactid,
        companyId: parentcustomerid
      })
    );

    history.push({
      pathname: `${PAGES.MY_DRIVERS_DETAILS}/`,
      search: `?id=${contactid}`,
      state: {
        tabName: 'führerschein',
        ...state
      }
    });
  };

  const getDataForTable = items =>
    items.map(item => {
      const sortData = {};
      Object.entries(item).forEach(([key, value]) => {
        setValue(item, key, value, sortData, true);
      });

      return sortData;
    });

  const openSettingPageViaModal = (row, state = {}) => {
    const { parentcustomerid = '', contactid = '' } = row;

    if (isDriver || isPoolManager) {
      return;
    }

    dispatch(
      setContactSetup({
        id: contactid,
        companyId: parentcustomerid
      })
    );

    history.push({
      pathname: `${PAGES.MY_DRIVERS_DETAILS}/`,
      search: `?id=${contactid}`,
      state: {
        tabName: 'führerschein',
        ...state
      }
    });
  };

  const tableItemHandler = event => {
    const elem = event.target.closest('li');

    const data = {
      parentcustomerid: elem.getAttribute('data-parentcustomerid'),
      contactid: elem.getAttribute('data-contactid')
    };

    openSettingPageViaModal(data);
  };

  const rowsForTable = getDataForTable(table.records);

  return (
    <div className={classes.driving_license_control}>
      <div className={classes.driving_license_control_wrapper}>
        <div className={classes.progress_diagram_wrapper}>
          {statuses.statuses.map(status => {
            const progress = status?.percent > 0 ? status?.percent : 0;

            if (status?.label?.toLowerCase() === CONTROL_STATUS.valid.toLowerCase()) {
              return (
                <div
                  style={{ cursor: status?.percent ? 'pointer' : 'default' }}
                  className={classes.big_filter_container}
                  key={status?.label}
                >
                  <ProgressDiagram
                    key={status.label}
                    title={PROGRESS_BAR_TITLE.validControl}
                    tooltipText='Gültige Führerscheinkontrolle ist aktuell'
                    progress={100}
                    partOfTotalNumber={`${status.count} von ${statuses?.allRecordsCount}`}
                    progressBarSetup={{
                      ...progressBarSetup,
                      strokeColor: '#30BF30',
                      progress
                    }}
                    progressBarChildren={<CheckIcon width='25' height='23' />}
                    isActive={statusLabel === CONTROL_STATUS.valid}
                    onClick={() => progressDiagramHandler(CONTROL_STATUS.valid, status.count)}
                  />
                </div>
              );
            }

            if (status?.label?.toLowerCase() === CONTROL_STATUS.withoutValidControl.toLowerCase()) {
              return (
                <div
                  style={{ cursor: status?.percent ? 'pointer' : 'default' }}
                  className={classes.big_filter_container}
                  key={status?.label}
                >
                  <ProgressDiagram
                    key={status.label}
                    title={PROGRESS_BAR_TITLE.withoutValidControl}
                    tooltipText='Führerscheinkontrolle im Status Warten auf Kontrolle, ist überfällig oder ungültig (>21 Tage überfällig)'
                    progress={100}
                    partOfTotalNumber={`${status.count} von ${statuses?.allRecordsCount}`}
                    progressBarSetup={{ ...progressBarSetup, strokeColor: '#BF0040', progress }}
                    progressBarChildren={<CloseIcon width='25' height='25' color='#BF0040' strokeWidth='1' />}
                    isActive={statusLabel === CONTROL_STATUS.withoutValidControl}
                    onClick={() => progressDiagramHandler(PROGRESS_BAR_TITLE.withoutValidControl, status.count)}
                  />
                </div>
              );
            }

            if (status?.label?.toLowerCase() === CONTROL_STATUS.incompleteData.toLowerCase()) {
              return (
                <div
                  className={classes.big_filter_container}
                  style={{ cursor: status?.percent ? 'pointer' : 'default' }}
                  key={status?.label}
                >
                  <ProgressDiagram
                    key={status.label}
                    title={PROGRESS_BAR_TITLE.incompleteData}
                    tooltipText='Führerscheinkontrolle ohne vollständige Prüfmerkmale, sodass die Kontrolle nicht durchgeführt werden kann'
                    progress={100}
                    partOfTotalNumber={`${status.count} von ${statuses?.allRecordsCount}`}
                    progressBarSetup={{ ...progressBarSetup, strokeColor: '#FF9700', progress }}
                    progressBarChildren={<QuestionIcon width='14' height='16' />}
                    isActive={statusLabel === CONTROL_STATUS.incompleteData}
                    onClick={() => progressDiagramHandler(CONTROL_STATUS.incompleteData, status.count)}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className={classes.filters_container}>
          <div className={classes.filters}>
            <DrivingLicenseControlFilters
              filterData={filters}
              sessionFilter={sessionFilter}
              setSessionFilter={setSessionFilter}
              goToFirstPage={goToFirstPage}
            />
          </div>
          <div className={classes.button_wrapper}>
            <Button
              component={ButtonComponentType.DownloadExcelIcon}
              onClick={downloadFile}
              iconWidth={32}
              tooltip='Download File Excel'
            />
          </div>
        </div>
        {table?.records?.length ? (
          <section className={classes.container}>
            {isMobileWidth ? (
              <ul className={classes.table_mobile} onClick={tableItemHandler}>
                {rowsForTable.map(data => (
                  <TableComponentMobile
                    key={data.contactid}
                    row={data}
                    openSettingPageViaModal={openSettingPageViaModal}
                  />
                ))}
              </ul>
            ) : (
              <TableComponent
                columns={columns}
                rows={rowsForTable}
                minWidth={800}
                rootTag={{
                  name: 'link',
                  propName: 'contactid',
                  onClick: row => openSettingPageVia(row)
                }}
                sort={sortHandler}
                sortParams={tableParams.sort}
              />
            )}
          </section>
        ) : (
          <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.' />
        )}
      </div>
      {table?.records?.length && table.totalRecordsCount > tableParams.pagination.number ? (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table.totalRecordsCount}
          onPageChange={pageChangeHandler}
        />
      ) : null}
    </div>
  );
};

export const CRM_VALUE = {
  company: 'FirmId',
  driver: 'Driver',
  bookedService: 'Booked',
  control: 'Control',
  status: 'Status',
  startInstructionDate: 'StartInstructionDate',
  endInstructionDate: 'EndInstructionDate',
  isExistInstructionDate: 'IsExistInstructionDate',
  contactStatus: 'contactstatus'
};

const getDefaultTableParams = defaultPage => ({
  pagination: {
    start: defaultPage,
    number: 10
  },
  sort: { predicate: 'lastname', reverse: false }
});

export const DEFAULT_STATUS_PARAMS = {
  statuses: [],
  totalRecordsBeforeStatusFilterCount: 0
};

export const DEFAULT_TABLE_DATA = { records: [], totalRecords: 0 };

export const EMPTY_FILTER_INSTRUCTION_DATE = {
  StartInstructionDate: null,
  EndInstructionDate: null,
  IsExistInstructionDate: null
};

export const EMTY_FILTER_STATE = {
  Driver: null,
  FirmId: null,
  Booked: null,
  Control: null,
  Status: null,
  contactstatus: null,
  ...EMPTY_FILTER_INSTRUCTION_DATE
};

export const PROGRESS_BAR_TITLE = {
  validControl: 'Gültige Kontrolle',
  withoutValidControl: 'Ohne gültige Kontrolle',
  incompleteData: 'Unvollständige Daten'
};

export const CONTROL_STATUS = {
  valid: 'Gültig',
  daysOverdue: 'XX Tage überfällig',
  invalid: 'Ungültig',
  incompleteData: 'Unvollständige Daten',
  withoutValidControl: 'Ohne gültige Kontrolle',
  waitingForControl: 'Warten auf Kontrolle'
};

const progressBarSetup = {
  radius: 26,
  strokeWidth: 3,
  trackStrokeWidth: 2,
  trackStrokeColor: '#f5f5f5',
  transition: '0.3s ease',
  initialAnimation: true
};

const defaultColumns = [
  {
    name: '',
    type: 'chip',
    component: <DrivingLicenseDriverStatus />,
    sort: false,
    width: '7px'
  },
  {
    name: 'Name',
    type: 'text',
    propName: 'fullname',
    sortName: 'lastname',
    sort: true,
    width: '13%',
    className: null
  },
  {
    name: 'Unternehmen',
    type: 'text',
    propName: 'parentcustomerid',
    sortName: 'parentcustomerid',
    sort: true,
    width: '15%',
    className: null
  },
  {
    name: 'Führerscheinkontrolle',
    type: 'chip',
    component: <ControlItem row={{}} />,
    sortName: 'new_gueltigkeitsstatus_driverscheckneu',
    sort: true,
    width: '15%',
    className: null
  },
  {
    name: 'Letzte Kontrolle',
    type: 'date',
    propName: 'uds_fuhrerscheinprufung',
    sortName: 'uds_fuhrerscheinprufung',
    sort: true,
    width: '12%',
    className: null
  },
  {
    name: 'Nächste Kontrolle',
    type: 'date',
    propName: 'uds_nextcheckupdate',
    sortName: 'uds_nextcheckupdate',
    sort: true,
    width: '13%',
    className: null
  },
  {
    name: 'letzte Unterweisung',
    type: 'date',
    propName: 'uds_letzte_online_unterweisung',
    sortName: 'uds_letzte_online_unterweisung',
    sort: true,
    width: '13%',
    className: null
  },
  {
    name: 'Funktion',
    type: 'text',
    propName: 'control',
    sort: false,
    width: '13%',
    className: null
  },
  {
    name: 'Gebucht',
    type: 'boolean',
    propName: 'uds_isdrivercheckneeded',
    sortName: 'uds_isdrivercheckneeded',
    sort: true,
    width: '5%',
    className: null
  },
  {
    name: '',
    type: 'menu',
    sort: false,
    component: <DriverscheckMenu row={{}} />,
    menuItems: [],
    width: TABLE_MENU_WIDTH
  }
];

export default withPageContainer(DrivingLicenseControlComponent);
