import { Flex, Text } from 'common/common-components.styled';
import { PAGES } from 'common/enums';
import { WrenchIcon } from 'components/icons-new/wrench';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const RepairPartnerWidgetHeader = () => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();

  const clickHandler = () => {
    history.push(PAGES.REPAIRED);
  };

  return (
    <Flex
      align='center'
      bottom='15'
      pointer
      onClick={clickHandler}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <WrenchIcon hovered={isHovered} />
      <Text size='18' left='10' bold color='blue'>
        Reparatur status
      </Text>
    </Flex>
  );
};
