import { Moment } from 'moment';
import { DateFieldName } from 'pages/my-requests/components/table/expand/steps-controller/completion-confirmed/form/form.props';
import { CrmPicklistResponse, CrmLookupResponse, CrmResponse, Range } from 'common/interfaces';

export interface IRepairPartnerItemsResponse {
  CurrentStep: StepType;
  Status: IStatus;
  StepStates: StepStatesType;
  StepCount: number;
  Steps: ISteps[];
  DriverDetails: IDriverDetails;
  FuhrparkDetails: IFleetDetails;
  SchadenAufnahmeDetails: IDamageRecordingDetails;
  entityName: string;
  getPartnerFurGlasschden: null;
  isMyClaimPartnerwerkstatt: boolean;
  description: CrmResponse;
  incidentid: CrmResponse;
  new_aitelefon2: CrmResponse;
  new_aktenzeichen: CrmResponse;
  new_entstand: CrmResponse;
  new_fahreremailaddress: CrmResponse;
  new_fahrerid: CrmLookupResponse;
  new_fdhersteller: CrmResponse;
  new_fdmodel?: CrmResponse;
  new_kennzeichen: CrmLookupResponse;
  new_partnerwerkstatt_abholauftrag_confirm: CrmPicklistResponse;
  new_phone: CrmResponse;
  new_schadenuhrzeit: CrmResponse;
  new_teile: CrmResponse;
  statuses: null;
  title: CrmResponse;
  isAbholungGeplant: CrmResponse<boolean>;
  uds_ablehnungsgrund: CrmResponse;
  uds_besichtigungstermin: CrmResponse;
  uds_pickupcaradress: CrmResponse;
  uds_pickupcardatetime: CrmResponse;
  uds_bereitschaft_bestaetigt_option: CrmPicklistResponse<PickupDateScheduledConfirmedOption>;
}

export interface IDriverDetails {
  mobilephone: CrmResponse;
  emailaddress1: CrmResponse;
  telephone1: CrmResponse;
}

export interface IFleetDetails {
  new_model: CrmResponse;
  new_hersteller: CrmResponse;
  new_name: CrmResponse;
  new_fin: CrmResponse;
  new_ezl: CrmResponse;
}

export interface IDamageRecordingDetails {
  uds_schadenort_city: CrmResponse;
  uds_schadenort_postalcode: CrmResponse;
  uds_schadenort_street: CrmResponse;
  uds_schadenuhrzeit: CrmResponse;
  uds_teile1: CrmResponse;
  uds_repairpartnerpostalcode: CrmResponse;
}

export interface IGetRepairPartnerList<TItem = Partial<IRepairPartnerItemsResponse>> {
  items: TItem[];
  totalRecord: 0;
}

export interface ISteps {
  incidentId?: string;
  rejectionReason?: string;
  status?: string;
  statusDescriptionId?: string;
  stepDate?: string;
  stepNumber?: number;
  stepType?: number;
  vehicleReadinessConfirmed?: boolean;
}

export interface IStatus {
  id: RepairPartnerStatusesId;
  status: string;
  date?: string;
}

export interface IPostponeIncident {
  [PostponeIncidentKeys.Id]: string;
  [PostponeIncidentKeys.Date]?: string;
  [PostponeIncidentKeys.Reason]?: string;
  [PostponeIncidentKeys.IsDeclined]: boolean;
}

export interface IUpdateStep {
  [UpdateStepKeys.IncidentId]: string;
  [UpdateStepKeys.StepDate]: string;
  [UpdateStepKeys.StepType]: StepType;
  [UpdateStepKeys.DeviationReason]?: string;
  [UpdateStepKeys.DateConfirmation]?: string;
  [UpdateStepKeys.VehicleReadinessConfirmed]?: number;
  [UpdateStepKeys.CarReadyToUse]?: number;
}

export interface IRepairPartnerFilterResponse {
  [FilterCrmName.AllOrgans]?: number;
  [FilterCrmName.NewOrgans]?: number;
  [FilterCrmName.DelayedIncident]?: number;
  [FilterCrmName.InProcess]?: number;
  [FilterCrmName.Mark]: CrmResponse[];
  [FilterCrmName.CompletedOperations]?: number;
}

export interface IRepairPartnerFilter extends Omit<IRepairPartnerFilterResponse, FilterCrmName.Mark> {
  [FilterCrmName.Mark]: string[];
}

export interface IGetRepairPartnerListSearch {
  [TableCrmName.Status]: string | null;
  [TableCrmName.Inquiry]: string | null;
  [TableCrmName.Model]: string | null;
  [TableCrmName.Manufacturer]: string | null;
  [TableCrmName.Mark]: string | null;
  [TableCrmName.QuickFilter]: QuickFiltersCode | null;
}

export interface AcceptIncidentOptional {
  createAngenommenDurchReparaturpartnerStatus: boolean;
}

export type UploadSettleUpDateType = {
  [keys in DateFieldName]: Moment;
};

export type StepType = Range<0, 7>;

export type StepStatesType = {
  [key in StepType]: MyRequestStepStatus;
};

export enum MyRequestStepStatus {
  Inactive = 0,
  Active = 1,
  Done = 2
}

export enum UpdateStepKeys {
  IncidentId = 'IncidentId',
  StepDate = 'StepDate',
  StepType = 'StepType',
  DateConfirmation = 'Reason',
  DeviationReason = 'DeviationReason',
  VehicleReadinessConfirmed = 'vehicleReadinessConfirmed',
  CarReadyToUse = 'uds_car_ready_to_usecode'
}

export enum PostponeIncidentKeys {
  Id = 'Id',
  IsDeclined = 'IsDeclined',
  Date = 'Date',
  Reason = 'Reason'
}

export enum FilterCrmName {
  Mark = 'kenzeichen',
  AllOrgans = 'alleVorgane',
  NewOrgans = 'neueVorgane',
  InProcess = 'inBearbiten',
  DelayedIncident = 'vidervorlagen',
  CompletedOperations = 'abgeschlossen'
}

export enum TableCrmName {
  Status = 'Status',
  Inquiry = 'Anfrage',
  Model = 'Modell',
  Manufacturer = 'Hersteller',
  Mark = 'Kennzeichen',
  QuickFilter = 'QuickFilter'
}

export enum RepairPartnerStatusesId {
  Rejected = 0,
  NewOrder = 1,
  OrderAccepted = 2,
  PreviewScheduled = 3,
  PickupDateScheduled = 4,
  QuotePhotos = 5,
  CompletionScheduled = 6,
  CompletionConfirmed = 7,
  DelayedIncident = 9
}

export enum PickupDateScheduledConfirmedOption {
  Confirmed = 100000000,
  NotConfirmed = 100000001
}

export enum QuickFiltersCode {
  AllOrgans = 0,
  NewOrgans = 1,
  DelayedIncident = 2,
  InProcess = 3,
  CompletedOperations = 4
}

export enum RejectionCode {
  NoCapacities = 752560000,
  NoSuitableRentalCar = 752560001,
  UserDoesNotWantRepairs = 752560002
}
