import React from 'react';
import { PhaseSwitchProps } from './phase-switch.types';
import { Flex, Text } from 'common/common-components.styled';
import { StyledSwitch } from 'components/form-hook/form-field/form-field.styled';
import { usePhaseSwitch } from './use-phase-switch';

const PhaseSwitch: React.FC<PhaseSwitchProps> = ({ phaseReverse }) => {
  const { isFleetManager, checked, handleChange } = usePhaseSwitch(phaseReverse);

  return (
    <Flex direction='column' gap='10px'>
      <Text size='12' color='grey900'>
        {phaseReverse
          ? 'Ein Ersatzfahrzeug wurde als nicht benötigt gekennzeichnet'
          : 'Ein Ersatzfahrzeug wurde noch nicht konfiguriert'}
      </Text>
      {isFleetManager && (
        <Flex align='center' left='-10'>
          <StyledSwitch checked={checked} onChange={e => handleChange(e.target.checked)} />

          <Text size='12'>Keine Ersatzbeschaffung</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default PhaseSwitch;
