import React from 'react';
import { FormFieldModel } from 'common/interfaces';
import { InspectionDateCheckboxRadioOptionSetValue, InspectionDateLinkToCarModalFormData } from './link-to-car.props';
import { FormFieldTypes } from 'common/enums';
import { errorMessages } from 'utils/error-messages';
import classes from './link-to-car.module.scss';
import { Flex, Text } from 'common/common-components.styled';
import moment from 'moment';

export const getInspectionDateCheckboxFormFields = (
  firstRegistrationDate: string | null | undefined
): FormFieldModel<keyof InspectionDateLinkToCarModalFormData> => ({
  name: 'inspectionDateCheckbox',
  label: 'Letzter Prüftermin',
  type: FormFieldTypes.Radio,
  options: [
    {
      label: 'Erstzulassungsdatum',
      bottomComponent: ({ isChecked }) => {
        if (firstRegistrationDate && !isChecked) {
          return null;
        }
        return (
          <Flex top='-10' left='28'>
            {firstRegistrationDate && <Text color='blue'>{moment(firstRegistrationDate).format('DD.MM.YYYY')}</Text>}
          </Flex>
        );
      },
      value: InspectionDateCheckboxRadioOptionSetValue.FirstRegistration
    },
    { label: 'individueller Prüftermin', value: InspectionDateCheckboxRadioOptionSetValue.IndividualDate }
  ],
  className: classes.inspection_date_radio_form_field,
  validation: { required: errorMessages.required }
});

export const inspectionDateFormFields: FormFieldModel<keyof InspectionDateLinkToCarModalFormData> = {
  name: 'inspectionDate',
  type: FormFieldTypes.Date,
  defaultValue: null,
  className: classes.inspection_date_form_field,
  validation: { required: true }
};
