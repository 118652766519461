import { useQuery } from 'react-query';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { userRequestConfig } from 'request-config/user/user.request-config';
import { RequestStatuses } from '../../../constants';
import { ReactQueryKeys } from 'common/enums';
import { UseGetUserResponse, UserDataResponse } from 'hooks/react-query/use-get-user/use-get-user.types';
import { transformCrmResponse } from 'utils/transform-crm-response';

const modifyResponse = ({ data }: ApiResponse<UserDataResponse[]>) => {
  if (data.level === RequestStatuses.Success) {
    return {
      userData: transformCrmResponse(data.data[0]),
      userDataResponse: data.data[0]
    };
  }

  return undefined;
};

export const useGetUser = (userId: string, isShowLoading: boolean = true, enabled: boolean = true) => {
  const { fetch } = useFetch<ResponseModel<UserDataResponse[]>>({
    loadingKey: `useGetUser_${userId}`,
    isShowLoading,
    ...userRequestConfig.getUser(userId)
  });

  const { data: userData, refetch: refetchUserData } = useQuery<
    ApiResponse<UserDataResponse[]>,
    unknown,
    UseGetUserResponse | undefined
  >([ReactQueryKeys.GetUserData, userId], () => fetch(), {
    select: modifyResponse,
    enabled: !!userId && enabled
  });

  return {
    ...userData,
    refetchUserData
  };
};
