import { API_ENDPOINT } from 'endpoints';
import authService from './auth-service';
import axios from 'axios';
import { PoolCalendarForm } from 'components/pool-car-booking/pool-car-booking-types';
import sharepointService from './sharepoint-service';
import { FilterNames } from 'components/pool-car-booking/bookings-page';
import moment from 'moment';
import { SessionFilter } from 'hooks/use-session-filter';
import { TableParams } from 'components/pool-car-booking/bookings-page';
import { CreateBookingFieldNames } from 'components/pool-car-booking/form-fields/create-booking.form-fields';
import { AnyObject } from 'common/interfaces';

export const getPoolCalendarList = async (tableParams: TableParams, sessionFilter: SessionFilter) => {
  try {
    const url = `${API_ENDPOINT}/api/Poolkalender/recordlist`;
    const token = await authService.acquireTokenAsync();

    const response = await axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        SearchParam: {
          [FilterNames.StartDate]: sessionFilter.filterForServer?.date?.from
            ? moment(sessionFilter.filterForServer?.date?.from).toISOString()
            : null,
          [FilterNames.EndDate]: sessionFilter.filterForServer?.date?.to
            ? moment(sessionFilter.filterForServer?.date?.to).toISOString()
            : null,
          [FilterNames.Status]: sessionFilter.filterForServer?.[FilterNames.Status],
          [FilterNames.Booking]: sessionFilter.filterForServer[FilterNames.Booking],
          [FilterNames.Mark]: sessionFilter.filterForServer[FilterNames.Mark],
          [FilterNames.Location]: sessionFilter.filterForServer[FilterNames.Location],
          [FilterNames.Driver]: sessionFilter.filterForServer[FilterNames.Driver]
        },
        PageNumber: tableParams.pagination?.start,
        CountOnPage: tableParams.pagination?.number,
        Sort: {
          Predicate: tableParams.sort.predicate,
          Reverse: tableParams.sort.reverse
        }
      }
    });

    const { data } = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getFilterData = async () => {
  try {
    const url = `${API_ENDPOINT}/api/Poolkalender/filterdata`;
    const token = await authService.acquireTokenAsync();
    const response = await axios({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const { data } = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCarGallery = async (id: string) => {
  try {
    const CancelToken = axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    const response = await sharepointService.getSharepointLogo(id, 'Foto(s) Fahrzeug', cancelTokenSource.token);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const bookingProcess = async (formData: PoolCalendarForm) => {
  const returnDate = new Date(`${formData[CreateBookingFieldNames.EndDate]}`);
  const pickUpDate = new Date(`${formData[CreateBookingFieldNames.StartDate]}`);
  const data: AnyObject = {
    ...formData,
    uds_abholdatum: pickUpDate,
    uds_ruckgabedatum: returnDate,
    uds_fuhrpark: formData?.selectedCar?.new_fuhrparkid
  };
  delete data.selectedCar;
  delete data.plannedKilometersOption;

  try {
    const url = `${API_ENDPOINT}/api/Poolkalender/createpoolkalendar`;
    const token = await authService.acquireTokenAsync();
    const response = await axios({
      method: 'POST',
      url,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.isSuccess;
  } catch (error) {
    console.log(error);
  }
};

export async function getPersonsForStandorts() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Poolkalender/GetPersonsForStandorts`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}

export async function getPoolCarClasses(companyId: string) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/poolkalender/GetClasses`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { id: companyId }
  });
}

export async function getDriversForBooking() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Poolkalender/GetDriversForBooking`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}
