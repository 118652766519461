import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ContractIcon = ({
  width = 22,
  height = 25,
  color: colorProp,
  fill: fillProp,
  strokeWidth = 1.2,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <>
          <path
            d='M17.9165 8.50385V6.62785L12.9922 0.99987H1.73667C1.34977 0.99987 1.0332 1.33755 1.0332 1.75027V23.5118C1.0332 23.9245 1.34977 24.2622 1.73667 24.2622H10.8818'
            fill={fill}
          />
          <path
            d='M17.9165 8.50385V6.62785L12.9922 0.99987H1.73667C1.34977 0.99987 1.0332 1.33755 1.0332 1.75027V23.5118C1.0332 23.9245 1.34977 24.2622 1.73667 24.2622H10.8818H17.9165V8.50385Z'
            fill={fill}
          />
        </>
      )}
      <path d='M13.0391 6.27258L13.0391 0.999887L17.786 6.12941L13.0391 6.27258Z' fill={hovered ? 'white' : 'none'} />
      <path
        d='M17.4624 15.6172L13.6377 22.2416L11.6096 23.7589L11.0336 23.4263L11.3336 20.9113L15.1644 14.2909'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M17.9171 6.65204V5.87745L13.0396 0.99987H1.7836C1.37088 0.99987 1.0332 1.33755 1.0332 1.75027V23.5118C1.0332 23.9245 1.37088 24.2622 1.7836 24.2622H8.57971M17.9171 18.4146V23.5118C17.9171 23.9245 17.5795 24.2622 17.1667 24.2622H15.7675H14.2057'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9166 5.87755H13.4143C13.2079 5.87755 13.0391 5.70871 13.0391 5.50235V4.00156'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.7497 15.7836L14.8695 14.1207L17.6964 9.22434C18.1537 8.43229 19.1759 8.15839 19.9679 8.61568C20.76 9.07298 21.0339 10.0952 20.5766 10.8872L17.7497 15.7836Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.4624 15.6172L13.6377 22.2416L11.6096 23.7589L11.0336 23.4263L11.3336 20.9113L14.1605 16.015'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3213 23.5927L10.9887 24.1688'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16016 8.50381H10.0377'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16016 11.1303H13.7897'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16016 13.7571L12.4614 13.7567'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16016 16.1652H11.3328'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.16016 18.7916H10.0503'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
