import React from 'react';
import PropTypes from 'prop-types';

export const CloseIcon = ({
  width = '12',
  height = '12',
  color = '#335566',
  strokeWidth = '2',
  className = '',
  id = '',
  ...props
}) => {
  return (
    <svg
      id={id}
      width={width}
      height={height}
      className={className}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.16187 3.34766L8.86038 8.69913'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.21228 8.79297L8.91079 3.44149'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
