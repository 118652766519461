import React, { useEffect, useState } from 'react';
import { FilterDataState, SERVICE_PROVIDER_TASK_CRM_NAME, TasksFiltersObject } from 'pages/tasks-page/tasks-page';
import { ClearAllFiltersButton, Filter, FilterContainer, PickersContainer } from 'components/filter-items';
import { FilterButton, FilterInput } from 'components/filter-items/filter-elements';
import { STATECODE } from '../../../constants';
import { Flex } from 'common/common-components.styled';
import { useSelector } from 'react-redux';
import { AnyObject } from 'common/interfaces';
import { useHistory, useLocation } from 'react-router-dom';
import { AutocompleteFilterFromState } from 'hooks/use-session-filter';
import { PAGES } from 'common/enums';

interface TasksFiltersProps {
  filterData: FilterDataState;
  onHandleFilter(data: TasksFiltersObject): void;
}

interface FiltersState {
  status: {
    label: string;
    value: number;
  } | null;
  bezeichnung: string | null;
  bezug: {
    id: string;
    logicalName: string;
    name: string;
  } | null;
  zustandigeUserId: string | null;
  fragestellerUserId: string | null;
  falligkeitsdatum: string | null;
  aufgabeIdName: string | null | undefined;
}

const getDate = (date: string) => {
  return [date.split('-')[0].trim(), date.split('-')[1].trim()];
};

const getFormatedDate = (date: string) => {
  return date.split('.').reverse().join('-');
};

const TasksFilters = ({ filterData, onHandleFilter }: TasksFiltersProps) => {
  const history = useHistory();
  const location = useLocation<Partial<AutocompleteFilterFromState> | undefined>();
  const filterForServer = location?.state?.filterForServer || {};

  const [filtersState, setFiltersState] = useState<FiltersState>({
    status: location.search || location.state ? null : { label: 'Offen', value: STATECODE.open },
    bezeichnung: null,
    bezug: null,
    zustandigeUserId: null,
    fragestellerUserId: null,
    falligkeitsdatum: null,
    aufgabeIdName: location.search ? location.search.split('=').pop() : '',
    ...filterForServer
  });
  const [isOpen, setOpen] = useState('');
  const userId = useSelector((state: AnyObject) => state['app'].user.id);

  useEffect(() => {
    const { status, bezeichnung, bezug, zustandigeUserId, fragestellerUserId, falligkeitsdatum, aufgabeIdName } =
      filtersState;
    const date = falligkeitsdatum ? getDate(falligkeitsdatum) : '';

    toggleFilterIsOpen();

    const filtersObject = {
      statecode: status ? status.value : null,
      subject: bezeichnung,
      regardingobjectid: bezug ? bezug.id : null,
      zustandigeUserId,
      fragestellerUserId,
      scheduledendStart: date.length > 0 ? getFormatedDate(date[0]) : '',
      scheduledendEnd: date.length > 0 ? getFormatedDate(date[1]) : '',
      id: filterData.id,
      aufgabeIdName: aufgabeIdName ? aufgabeIdName : null
    };

    onHandleFilter(filtersObject);
  }, [filtersState]);

  const handleInput = (title: string, value: number | string) => {
    history.replace(PAGES.SERVICE_PROVIDER_TASKS);

    if (title.toLowerCase() === 'status') {
      const status = filterData.statecode.find(status => status.value === value);
      if (status) {
        setFiltersState({
          ...filtersState,
          status
        });
      }
      return;
    }

    if (title.toLowerCase() === 'bezug') {
      const bezug = filterData.regardingobjectid.find(regardingobjectid => regardingobjectid.name === value);
      if (bezug) {
        setFiltersState({
          ...filtersState,
          bezug
        });
      }
      return;
    }

    if (title.toLowerCase() === 'zustandige') {
      setFiltersState({
        ...filtersState,
        zustandigeUserId: userId,
        fragestellerUserId: null
      });
      return;
    }

    if (title.toLowerCase() === 'fragesteller') {
      setFiltersState({
        ...filtersState,
        zustandigeUserId: null,
        fragestellerUserId: userId
      });
      return;
    }

    if (title.toLowerCase() === 'aufgaben id') {
      const aufgabeIdName = filterData.uds_aufgabeid_name.find(id => id === value);
      if (aufgabeIdName) {
        setFiltersState({
          ...filtersState,
          aufgabeIdName
        });
      }
      return;
    }

    setFiltersState({
      ...filtersState,
      [title.toLowerCase()]: value
    });
  };

  const clearFilter = (title = '') => {
    if (!title) {
      setFiltersState({
        bezeichnung: null,
        status: null,
        bezug: null,
        zustandigeUserId: null,
        fragestellerUserId: null,
        falligkeitsdatum: null,
        aufgabeIdName: null
      });
      return;
    }

    if (title === 'Richtung') {
      setFiltersState({
        ...filtersState,
        zustandigeUserId: null,
        fragestellerUserId: null
      });
      return;
    }

    if (title.toLocaleLowerCase() === 'fälligkeitsdatum') {
      setFiltersState({
        ...filtersState,
        falligkeitsdatum: null
      });
      return;
    }

    if (title.toLowerCase() === 'aufgaben id') {
      setFiltersState({
        ...filtersState,
        aufgabeIdName: null
      });
      return;
    }

    setFiltersState({
      ...filtersState,
      [title.toLowerCase()]: null
    });
  };

  const toggleFilterIsOpen = (title = '') => {
    setOpen(title);
  };

  return (
    <Flex align='center'>
      <Filter
        title='Aufgaben ID'
        isOpen={isOpen}
        value={filtersState.aufgabeIdName}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Aufgaben ID' onClose={toggleFilterIsOpen}>
          <FilterInput
            title='Aufgaben ID'
            handleInput={handleInput}
            dropListData={filterData.uds_aufgabeid_name.map(item => item || '')}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Bezeichnung'}
        isOpen={isOpen}
        value={filtersState.bezeichnung}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Bezeichnung'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput title='Bezeichnung' handleInput={handleInput} dropListData={[]} />
        </FilterContainer>
      </Filter>

      <Filter
        title='Bezug'
        isOpen={isOpen}
        value={filtersState.bezug?.name}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Bezug' onClose={toggleFilterIsOpen}>
          <FilterInput
            title='Bezug'
            handleInput={handleInput}
            dropListData={filterData.regardingobjectid.map(item => item.name || '')}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        title='Status'
        isOpen={isOpen}
        value={filtersState.status?.label}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Status' onClose={toggleFilterIsOpen} isNothingFound={!filterData.statecode.length}>
          {filterData.statecode.map(statecode => (
            <FilterButton
              key={statecode.value}
              handleInput={handleInput}
              title='status'
              label={statecode.label}
              value={statecode.value}
            />
          ))}
        </FilterContainer>
      </Filter>

      <Filter
        title='Richtung'
        isOpen={isOpen}
        value={filtersState.zustandigeUserId ? 'Zuständige' : filtersState.fragestellerUserId ? 'Fragesteller' : null}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Richtung' onClose={toggleFilterIsOpen}>
          <>
            <FilterButton key={userId} handleInput={handleInput} title='Zustandige' label='Zuständige' value={userId} />
            <FilterButton
              key={userId}
              handleInput={handleInput}
              title='Fragesteller'
              label='Fragesteller'
              value={userId}
            />
          </>
        </FilterContainer>
      </Filter>

      <Filter
        value={filtersState.falligkeitsdatum}
        title='Fälligkeitsdatum'
        isOpen={isOpen}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Fälligkeitsdatum'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <PickersContainer title='Falligkeitsdatum' value={filtersState.falligkeitsdatum} handleInput={handleInput} />
        </FilterContainer>
      </Filter>

      <ClearAllFiltersButton filterState={filtersState} clearFilter={clearFilter} />
    </Flex>
  );
};

export default TasksFilters;
