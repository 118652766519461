import { connect } from "react-redux";
import Manufacturer from "./manufacturer-view";
import { changeSearch, setVehicleModels } from "../../../../actions/app_action";

const mapStateToProps = (state) => {
  const search = state.app.smartTable.search;
  return {
    manufacturer: search.predicateObject.manufacturer || "",
    search,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSearch(search) {
    dispatch(changeSearch(search));
  },
  setModels(models) {
    dispatch(setVehicleModels(models));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);
