import authService from './auth-service';
import axios, { AxiosResponse } from 'axios';
import { AnyObject, ResponseModel } from 'common/interfaces';
import { DeviceTypes } from './../constants';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getVideos(pageUrl: string,
  deviceType: DeviceTypes): Promise<AxiosResponse<ResponseModel<AnyObject[]>>> {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/tutorial/getVideos`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      pageUrl,
      deviceType
    }
  });
}
