import { FormFieldModel } from 'common/interfaces';
import { Dispatch, SetStateAction, useEffect } from 'react';

export const useSetFieldLoading = (formField: FormFieldModel, setLoading: Dispatch<SetStateAction<boolean>>) => {
  const setFieldLoading = () => {
    if (!formField.source) {
      setLoading(Boolean(formField.isLoading));
    }
  };

  useEffect(setFieldLoading, [formField.isLoading]);
};
