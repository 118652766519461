import { combineReducers } from 'redux';
import app_reducer from './app_reducer';
import flatrate_reducer from './flatrate_reducer';
import { RESET_STATE } from '../actions/app_action';
import comparison_reducer from './comparison_reducer';
import { filterReducer } from './filter_reducer';
import { sessionStorageReducer } from './session_storage_reducer';
import { myRequestReducer } from './page/my-requests/my-request.reducer';
import { myDamageDetailReducer } from './page/damage-detail/damage-detail.reducer';
import { vehicleDetailsReducer } from './page/vehicle-details/vehicle-details.reducer';

const reducer = combineReducers({
  app: app_reducer,
  flatrate: flatrate_reducer,
  comparison: comparison_reducer,
  filter: filterReducer,
  sessionStorage: sessionStorageReducer,
  myRequest: myRequestReducer,
  damageDetail: myDamageDetailReducer,
  vehicleDetails: vehicleDetailsReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
