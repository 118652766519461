import { FormFieldModel } from 'common/interfaces';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { SetApproversAutomaticallyValues } from './approval-rules.types';

export const setApproversAutomaticallyFormFields: FormFieldModel[] = [
  generateFormField.optionSet({
    name: 'uds_order_approve_type_value',
    label: 'Freigeber automatisch festlegen?',
    defaultValue: SetApproversAutomaticallyValues.Manual,
    options: OPTION_SET.uds_order_approve_type_value,
    validation: {
      required: 'Bitte auswählen'
    }
  })
];
