import React, { useState } from 'react';
import { Text } from 'common/common-components.styled';
import { NoteItemHeader } from './components/header/header';
import { NoteModel } from 'hooks/react-query/fleet/get-notes/get-notes.props';
import moment from 'moment';
import { NoteContent } from './components/note-content/note-content';
import { CreateNote } from '../create-note/create-note';
import { NoteItemContainer } from '../note-item-container/note-item-container';

export const NoteItem = (props: NoteModel) => {
  const { uds_titel, createdon, uds_createdbyb2c, uds_notiz } = props;

  const [isEdit, setIsEdit] = useState(false);

  const getModifyInfo = () => {
    const momentDate = moment(createdon.value);
    const displayedDate = momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : '-';
    const createdBy = uds_createdbyb2c?.name || 'Mobexo Team';

    return `Geändert am ${displayedDate} von ${createdBy}`;
  };

  if (isEdit) {
    return <CreateNote data={props} cancelEdit={() => setIsEdit(false)} />;
  }

  return (
    <NoteItemContainer>
      <NoteItemHeader title={uds_titel || '-'} onEdit={() => setIsEdit(true)} {...props} />
      <Text color='grey600' size='12' margin='10px 0'>
        {getModifyInfo()}
      </Text>
      <NoteContent {...props} />
    </NoteItemContainer>
  );
};
