import { CrmDataTypes } from 'common/enums';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';
import { objectUtility } from 'utils/object/object';

type AttributeTypeCode = { attributeTypeCode: CrmDataTypes };
export type RequestAttribute = AttributeTypeCode & { attributeValue: any };
export type RequestAttributes<T> = Partial<Record<keyof T, RequestAttribute>>;

export const getRequestAttributes = <T extends Record<keyof T, AttributeTypeCode>>(
  fields: T,
  values: Partial<Record<keyof T, any>>
) => {
  const requestData: RequestAttributes<T> = {};

  objectUtility.entries(values).forEach(([key, value]) => {
    const field = fields[key];
    if (!field) {
      return;
    }
    requestData[key] = getRequestAttribute(field, value);
  });

  return requestData;
};

export const getRequestAttribute = (field: AttributeTypeCode, value: any): RequestAttribute => {
  const result: RequestAttribute = {
    attributeTypeCode: field.attributeTypeCode,
    attributeValue: null
  };
  if (
    field.attributeTypeCode === CrmDataTypes.String ||
    field.attributeTypeCode === CrmDataTypes.Integer ||
    field.attributeTypeCode === CrmDataTypes.Double ||
    field.attributeTypeCode === CrmDataTypes.UniqueIdentifier ||
    field.attributeTypeCode === CrmDataTypes.PicklistMultiple ||
    field.attributeTypeCode === CrmDataTypes.Memo
  ) {
    result.attributeValue = value ?? null;
  }
  if (field.attributeTypeCode === CrmDataTypes.DateTime) {
    result.attributeValue = value ? convertUTCDateToLocalDate(value) : null;
  }
  if (field.attributeTypeCode === CrmDataTypes.Boolean) {
    result.attributeValue = value;
  }
  if (field.attributeTypeCode === CrmDataTypes.Picklist || field.attributeTypeCode === CrmDataTypes.Money) {
    result.attributeValue =
      value || value === 0
        ? {
            value,
            label: ''
          }
        : null;
  }
  if (field.attributeTypeCode === CrmDataTypes.Lookup || field.attributeTypeCode === CrmDataTypes.Customer) {
    result.attributeValue = value
      ? {
          id: value,
          logicalName: '',
          name: ''
        }
      : null;
  }
  result.attributeTypeCode = field.attributeTypeCode;
  return result;
};
