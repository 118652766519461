import React from 'react';
import { MODAL_NAMES, MODALS } from '../../../constants';
import useModal from '../../../hooks/use-modal';
import classes from './gallery.module.scss';
import { GalleryItemProps } from './gallery.props';
import classNames from 'classnames';

export const GalleryItem = ({ image, index, allFiles, className }: GalleryItemProps) => {
  const [showFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, {
    file: allFiles[index],
    allFiles
  });

  return (
    <img
      src={image}
      className={classNames(className, classes.gallery_img)}
      onClick={() => (allFiles.length ? showFileViewer() : null)}
    />
  );
};
