import { connect } from "react-redux";

import BasicInformationFilter from "./basic-information-view";
import { toggleSidebar as toggleSidebarAction, resetFiltersAction } from "../../../actions/app_action";

const mapStateToProps = (state) => ({
  sidebarOpen: state.app.listSidebarOpen,
});

const mapDispatchToProps = (dispatch) => ({
  resetFilters() {
    dispatch(resetFiltersAction());
    dispatch(toggleSidebarAction(false));
  },

  toggleSidebar(flag) {
    dispatch(toggleSidebarAction(flag));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformationFilter);
