import React from 'react';
import { IconProps } from 'common/interfaces';

export const EmptyDataIcon = ({ width = 74, height = 74, hovered }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='analysis_x2F_search_x2F_research_x0A_'>
        <g id='Group'>
          {hovered && (
            <path
              id='Vector'
              d='M55.4665 26.1662V18.263L39.66 1.32751H3.53101C2.28908 1.32751 1.27295 2.34364 1.27295 3.58558V69.0695C1.27295 70.3114 2.28908 71.3275 3.53101 71.3275H55.4665'
              fill='#E6F7FF'
            />
          )}
          {hovered && (
            <path
              id='Vector_2'
              d='M55.4666 18.2631H40.7892C40.1682 18.2631 39.6602 17.755 39.6602 17.134V1.21972'
              fill='white'
            />
          )}
          <path
            id='Vector_3'
            d='M55.4665 26.1662V18.263L39.66 1.32751H3.53101C2.28908 1.32751 1.27295 2.34364 1.27295 3.58558V69.0694C1.27295 70.3114 2.28908 71.3275 3.53101 71.3275H39.66'
            stroke='#8FAFBF'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M55.4666 18.2631H40.7892C40.1682 18.2631 39.6602 17.755 39.6602 17.1341V10.3599'
            stroke='#8FAFBF'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <g id='search_2_'>
          <path
            id='Vector_5'
            d='M63.9076 56.6976L70.7086 63.3548C72.735 65.3812 72.735 68.7149 70.7086 70.7576C68.6823 72.784 65.3486 72.784 63.3059 70.7576L56.426 63.8778'
            fill='#E6F7FF'
          />
          <path
            id='Vector_6'
            d='M66.9991 59.6453L70.7086 63.3548C72.735 65.3812 72.735 68.7149 70.7086 70.7576C68.6823 72.784 65.3486 72.784 63.3059 70.7576L56.426 63.8778'
            stroke='#8FAFBF'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_7'
            d='M60.7097 60.7642C67.3468 54.1272 67.3468 43.3664 60.7097 36.7294C54.0727 30.0924 43.3119 30.0924 36.6749 36.7294C30.0379 43.3664 30.0379 54.1272 36.6749 60.7642C43.3119 67.4013 54.0727 67.4013 60.7097 60.7642Z'
            fill='white'
            stroke='#8FAFBF'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_8'
            d='M44.7743 59.8412C41.4243 58.6646 38.7769 56.0173 37.6003 52.6672'
            stroke='#8FAFBF'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
};
