import React from 'react';
import { TabControllerProps } from './tab.props';
import { SingleTab } from './type/single/single';
import { AccordionTab } from './type/accordion/accordion';

export const Tab = (props: TabControllerProps) => {
  switch (props.type) {
    case 'single':
      return <SingleTab {...props} />;
    case 'accordion':
      return <AccordionTab {...props} />;
  }
};
