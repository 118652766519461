import React, { Children, Fragment, MouseEvent, useState, isValidElement, ReactNode, cloneElement } from 'react';
import classes from './tile.module.scss';
import classNames from 'classnames';
import { TileProps } from './tile.props';
import Spinner from 'components/spinner';
import { Link } from 'react-router-dom';
import { WidgetTileEmptyPlaceholder } from './components/placeholder/empty/empty';

export const WidgetTile = ({
  title,
  titleIcon,
  children,
  emptyPlaceholder,
  className,
  onClick,
  onTitleClick,
  childrenClassName,
  isLoading,
  id,
  titleLink
}: TileProps) => {
  const [activeSliderIndx, setActiveSliderIndx] = useState(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const changeActiveSliderIndx = (event: MouseEvent) => {
    const elem = event.target as HTMLButtonElement;

    if (elem.getAttribute('data-index')) {
      setActiveSliderIndx(Number(elem.getAttribute('data-index')));
    }
  };

  const renderTitleIcon = (icon: ReactNode) => {
    if (isValidElement<{ hovered?: boolean }>(icon) && typeof icon.type !== 'string') {
      return cloneElement(icon, { hovered: isHovered });
    }
    return icon;
  };

  return (
    <div id={id || ''} className={classNames(classes.container, className, { pointer: onClick })} onClick={onClick}>
      {isLoading && (
        <div className={classes.spinner_container}>
          <Spinner style={spinnerStyle} />
        </div>
      )}

      <WidgetTileEmptyPlaceholder activeSliderIndx={activeSliderIndx} emptyPlaceholder={emptyPlaceholder} />

      {title && (
        <div className={classes.header}>
          <div
            className={classNames(classes.title_container, onTitleClick && 'pointer')}
            onClick={() =>
              Array.isArray(onTitleClick) ? onTitleClick[activeSliderIndx]() : onTitleClick && onTitleClick()
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {titleLink ? (
              <Link to={titleLink} className='d-flex a-i-center'>
                {Array.isArray(titleIcon) ? renderTitleIcon(titleIcon[activeSliderIndx]) : renderTitleIcon(titleIcon)}
                <span className={classes.title}>{Array.isArray(title) ? title[activeSliderIndx] : title}</span>
              </Link>
            ) : (
              <>
                {Array.isArray(titleIcon) ? renderTitleIcon(titleIcon[activeSliderIndx]) : renderTitleIcon(titleIcon)}
                <span className={classes.title}>{Array.isArray(title) ? title[activeSliderIndx] : title}</span>
              </>
            )}
          </div>
        </div>
      )}

      {Children.toArray(children).length > 1 && (
        <ul className={classes.switch_list} onClick={changeActiveSliderIndx}>
          {Children.toArray(children).map((_, indx) => (
            <Fragment key={indx}>
              {activeSliderIndx === indx ? (
                <li
                  id={`${id}SwitchCircle${indx}`}
                  className={classNames(classes.switch_circle, classes.switch_circle_active)}
                  data-index={indx}
                />
              ) : (
                <li id={`${id}SwitchCircle${indx}`} className={classes.switch_circle} data-index={indx} />
              )}
            </Fragment>
          ))}
        </ul>
      )}

      <div className={classNames(title ? classes.children_with_title : classes.children, childrenClassName)}>
        {Children.toArray(children)[activeSliderIndx]}
      </div>
    </div>
  );
};

const spinnerStyle = {
  width: '50px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 97
};
