import { ReactQueryKeys } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { companyRequestConfig } from 'request-config/company/company.request-config';
import { GetChildCompaniesData } from 'request-config/company/company.types';
import { useQuery as useQueryLocation } from 'hooks';
import { AnyObject, PickValue } from 'common/interfaces';
import {
  GetChildCompaniesDataModel,
  GetChildCompaniesQueryFunctionData,
  GetChildCompaniesTableData
} from './use-get-child-companies.props';
import { getDataForTable } from 'utils/get-response-data';

export const useGetChildCompaniesQuery = (
  companyIdProps?: string,
  pagination?: PickValue<GetChildCompaniesData, 'Pagination'>
) => {
  const locationQuery = useQueryLocation();
  const companyId = companyIdProps || locationQuery.id;

  const { fetch } = useFetch({
    loadingKey: 'getChildCompaniesQuery',
    ...companyRequestConfig.getChildCompanies({ AccountId: companyId as string, Pagination: pagination })
  });

  const modifyData = (data: GetChildCompaniesDataModel<AnyObject>): GetChildCompaniesTableData => ({
    ...data,
    result: getDataForTable(data.result) as GetChildCompaniesTableData['result']
  });

  const { data } = useQuery<GetChildCompaniesQueryFunctionData, unknown, GetChildCompaniesTableData>(
    [ReactQueryKeys.GetChildCompanies, companyId, pagination],
    () => fetch(),
    {
      enabled: !!companyId,
      select: ({ data }) => modifyData(data.data)
    }
  );

  return { childCompaniesQuery: data };
};
