import { PackageTypes } from 'common/enums';
import styled, { css } from 'styled-components';

interface CardProps {
  isHeader: boolean;
  width: number;
}

interface ContainerProps {
  backgroundColor: string;
  color: string;
  isHeader?: boolean;
}

interface PriceProps {
  isDiscount?: boolean;
}

export const StyledCardContainer = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}%`};
  min-width: 280px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }

  ${({ isHeader }) =>
    isHeader &&
    css`
      box-shadow: 0.5px 0px 5px rgba(0, 0, 0, 0.13);
    `};

  ${({ isHeader }) =>
    !isHeader &&
    css`
      margin-top: 25px;
    `};
  border-radius: 5px;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    width: 95%;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const StyledCardHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.emeraldGreen};
  display: flex;
  justify-content: center;
  padding: 3px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0.5px 0px 5px rgba(0, 0, 0, 0.13);
`;

export const StyledCardContent = styled.div<ContainerProps>`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  color: ${({ color, theme }) => theme.colors[color]};
  text-align: center;
  border-radius: ${({ isHeader }) => (isHeader ? '0 0 5px 5px' : '5px')};

  @media (min-width: ${({ theme }) => theme.media.desktop}) {
    padding: 15px 30px 30px;
  }
`;

export const StyledCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  min-height: 280px;
`;

export const StyledPriceWithText = styled.div`
  min-height: 140px;
`;

export const StyledPriceWrapper = styled.div<PriceProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDescription = styled.p`
  height: 50px;
  font-size: 14px;
`;

export const StyledOriginalPriceContainer = styled.span`
  font-size: 30px;
  margin-right: 7px;
`;

export const StyledLine = styled.hr<{ color: string }>`
  border-top: 1px solid ${({ color, theme }) => theme.colors[color]};
  margin-top: -21px;
  width: 80px;
`;

interface CheckoutButtonProps {
  secondary?: boolean;
  disabled?: boolean;
  tertiary?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary' | 'disabled';
}

export const StyledCheckoutButton = styled.button<CheckoutButtonProps>`
  padding: 5px 0;
  transition: box-shadow 0.3s;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  width: 65%;
  ${({ type }) =>
    type === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.colors.pink};
      color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.pink};
      &:hover {
        box-shadow: 2px 2px 7px ${({ theme }) => theme.colors.darkPink};
      }
    `}

  ${({ type }) =>
    type === 'secondary' &&
    css`
      background-color: ${({ theme }) => theme.colors.emeraldGreen};
      color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.emeraldGreen};
      &:hover {
        box-shadow: 2px 2px 7px ${({ theme }) => theme.colors.grey300};
      }
    `}

${({ type }) =>
    type === 'tertiary' &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blue};
    `}

${({ type }) =>
    type === 'disabled' &&
    css`
      color: ${({ theme }) => theme.colors.grey300};
      border: 2px solid ${({ theme }) => theme.colors.grey300};
      background-color: transparent;
      padding: 8px 18px;
    `}

    @media (max-width: 1500px) {
    padding: 7px 10px !important;
    font-size: 10px;
  }
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BenefitsTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
`;

export const BenefitsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 15px 0;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0;
`;

export const BenefitsWrapper = styled.div<{ packageName: string; extraSection?: boolean; minHeight?: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.emeraldGreen};

  ${({ extraSection, minHeight }) =>
    !extraSection &&
    css`
      min-height: ${minHeight};
      justify-content: space-around;
    `}
`;

export const BenefitContainer = styled.div`
  display: flex;
  font-size: 14px;
  align-items: flex-start;
  text-align: left;
  padding: 5px 0;
`;

export const SubBenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubBenefitWrapper = styled.div`
  padding: 5px 0 5px 35px;
  align-items: flex-start;
  display: flex;
  text-align: left;
`;

export const BenefitSpan = styled.span<{ isBold?: boolean }>`
  ${({ isBold }) =>
    isBold &&
    css`
      font-size: 16px;
      font-weight: bold;
    `};

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ExtraSectionWrapper = styled.div<{ minHeight: string }>`
  min-height: ${({ minHeight }) => minHeight};
`;
