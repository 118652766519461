import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel, TransformResponse } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';
import { PoolCarSettingsDataResponse } from './use-pool-car-settings-data.types';
import { transformCrmResponse } from 'utils/transform-crm-response';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { useContext } from 'react';
import { PoolCarSettingsContext } from '../../pool-car-booking-settings.context';

const modifyResponse = ({ data }: ApiResponse<PoolCarSettingsDataResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return {
      poolCarSettingsDataResponse: data.data,
      poolCarSettingsData: transformCrmResponse(data.data)
    };
  }
  return undefined;
};

const usePoolCarSettingsData = (companyId?: string) => {
  const { companyId: contextCompanyId } = useContext(PoolCarSettingsContext);
  const id = companyId || contextCompanyId;

  const { fetch } = useFetch<ResponseModel<PoolCarSettingsDataResponse>>({
    isShowLoading: false,
    isShowErrorSnackbar: false,
    ...poolCalendarRequestConfig.getPoolCarBookingSettings(id)
  });

  const { data, refetch, isLoading } = useQuery<
    ApiResponse<PoolCarSettingsDataResponse>,
    unknown,
    | {
        poolCarSettingsDataResponse: PoolCarSettingsDataResponse;
        poolCarSettingsData: TransformResponse<PoolCarSettingsDataResponse>;
      }
    | undefined
  >([ReactQueryKeys.GetPoolCarSettingsData, id], () => fetch(), {
    enabled: !!id,
    select: modifyResponse
  });

  return { ...data, refetch, isLoading };
};

export default usePoolCarSettingsData;
