import React, { useState } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { CurrentKilometerReadingHeader } from './components/header/header';
import { ChangeKilometerForm } from '../change-kilometer-form/change-kilometer-form';
import { CurrentKilometerReadingProps } from './current-kilometer-reading.props';
import useWindowSize from 'hooks/use-window-size';

export const CurrentKilometerReading = ({ editFormProps }: CurrentKilometerReadingProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const { windowWidth } = useWindowSize(500);

  if (isEdit) {
    return <ChangeKilometerForm {...editFormProps} onClose={() => setIsEdit(false)} />;
  }

  return (
    <Flex direction='column' align='center' bottom={windowWidth <= 550 ? '0' : '10'} width='100%'>
      <CurrentKilometerReadingHeader onClick={() => setIsEdit(true)} />
      <Text color='grey400' size={windowWidth <= 550 ? '14' : '16'}>
        Aktueller km-Stand
      </Text>
    </Flex>
  );
};
