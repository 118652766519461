import React, { cloneElement } from 'react';
import { DraggableListEmptyPlaceholderProps } from './empty-placeholder.props';
import { Flex, Text } from 'common/common-components.styled';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const DraggableListEmptyPlaceholder = ({ title, icon, link }: DraggableListEmptyPlaceholderProps) => {
  const history = useHistory();
  const theme = useTheme();

  const ICON_SIZE = 75;

  const onLinkClick = () => {
    if (link) {
      if ('push' in link) {
        history.push(link.push);
      }
      if ('onClick' in link) {
        link.onClick();
      }
    }
  };

  return (
    <Flex direction='column' align='center' top='40'>
      {icon ? (
        cloneElement(icon, {
          width: ICON_SIZE,
          height: ICON_SIZE,
          color: theme.colors.midBlue,
          hovered: true,
          strokeWidth: 0.7
        })
      ) : (
        <EmptyDataIcon hovered />
      )}
      <Text color='blue' bold margin='15px 0'>
        {title}
      </Text>

      {link && (
        <Text color='blue' underline pointer onClick={onLinkClick}>
          {link.label}
        </Text>
      )}
    </Flex>
  );
};
