import React, { useEffect, useState } from 'react';
import classes from './dashboard-driver.module.scss';
import { DriverVehicle } from './driver-vehicle/driver-vehicle';
import { MyOrder } from './my-order/my-order';
import { AnyObject, RoutesAccessRights } from 'common/interfaces';
import dashboardService from 'services/dashboard-service';
import { RequestStatuses } from '../../constants';
import axios from 'axios';
import { NewVehicle } from './new-vehicle/new-vehicle';
import { UpcomingTasksWidgets } from 'components/widgets/task/upcoming tasks/upcoming tasks';
import { WidgetTile } from 'components/widgets/tile/tile';
import { TileTitle } from 'components/dashboard-component-chart/dashboard.props';
import { PAGES } from 'common/enums';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { useTheme } from 'styled-components';
import { LoadingWidgetInterface } from './dashboard-driver.interface';
import { defaultLoadingWidget } from './utils';
import { RepairPartnerWidgetContainer } from './repair-partner/repair-partner';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const DashboardDriver = () => {
  const appPages = useTypedSelector<RoutesAccessRights[]>(state => state['app'].user.appPages);
  const [vehicleData, setVehicleData] = useState<AnyObject>({});
  const [showVehicleDataWidget, setShowVehicleDataWidget] = useState<boolean>(true);
  const [showMyOrderWidget, setShowMyOrderWidget] = useState<boolean>(true);
  const [myOrderData, setMyOrderData] = useState<AnyObject>({});
  const [loadingState, setLoadingState] = useState<LoadingWidgetInterface>(defaultLoadingWidget);
  const CancelToken = axios.CancelToken;
  const cancelTokenSource = CancelToken.source();
  const showUpcomingTasksWidget = appPages.some(appPage => appPage.url === PAGES.TASKS);

  const history = useHistory();
  const theme = useTheme();

  const toggleWidgetLoading = (name: keyof LoadingWidgetInterface, isLoading: boolean) => {
    setLoadingState(prevState => ({ ...prevState, [name]: isLoading }));
  };

  const getDriverDashboardData = async () => {
    let res;
    try {
      res = await Promise.all([dashboardService.getMeinFahrzeug(), dashboardService.getMeineBestellung()]);
    } catch (err) {
      console.error(err);
      res = {};
    } finally {
      if (res && res[0]?.data?.level === RequestStatuses.Success && res[0].data?.data?.fuhrparks.length) {
        setVehicleData(res[0].data.data.fuhrparks);
      } else {
        setShowVehicleDataWidget(false);
      }
      if (res && res[1]?.data?.level === RequestStatuses.Success && res[1]?.data?.data) {
        setMyOrderData(res[1].data.data);
        if (!res[1]?.data?.data?.fahrzeugauslieferung) {
          setShowMyOrderWidget(false);
        }
      }
      toggleWidgetLoading('vehicle', false);
      toggleWidgetLoading('myOrder', false);
    }
  };

  useEffect(() => {
    getDriverDashboardData();
  }, []);

  return (
    <main className={classes.driver_widgets}>
      <div className={classes.top_block}>
        {showVehicleDataWidget && (
          <DriverVehicle cancelToken={cancelTokenSource} vehicleData={vehicleData} isLoading={loadingState.vehicle} />
        )}

        {showUpcomingTasksWidget && (
          <WidgetTile
            className={classes.upcoming_task}
            onClick={() => history.push(PAGES.TASKS)}
            title={TileTitle.UpcomingTasks}
            titleLink={PAGES.TASKS}
            titleIcon={<CalendarWithCheckMarkIcon width='20' height='20' color={theme.colors.blue} />}
            isLoading={loadingState.upcomingTasks}
          >
            <UpcomingTasksWidgets toggleLoading={isLoading => toggleWidgetLoading('upcomingTasks', isLoading)} />
          </WidgetTile>
        )}
      </div>
      <div className={classes.bottom_block}>
        <RepairPartnerWidgetContainer />
        {showMyOrderWidget && (
          <MyOrder cancelToken={cancelTokenSource} myOrderData={myOrderData} isLoading={loadingState.myOrder} />
        )}
        <NewVehicle />
      </div>
    </main>
  );
};
