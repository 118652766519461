import React, { useEffect, useState } from 'react';
import classes from './new-vehicle.module.scss';
import { getReconfigurationsList } from '../../../services/get-vehicle-list';
import { getUserBasket } from '../../../services/user-service';
import {
  MODALS,
  MODAL_NAMES,
  RequestStatuses,
  defaultGuid,
  ENGINE_TYPE_LABELS,
  OFFER_STATUSES
} from '../../../constants';
import { getResponseFormData } from '../../../utils/get-response-data';
import { useOverlay } from 'hooks';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { AnyObject } from 'common/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { PAGES } from 'common/enums';
import { useHistory } from 'react-router-dom';
import { setSnakbar, toggleModal } from 'actions/app_action';
import ConfigurationFromPdf from 'components/configuration-from-pdf/configuration-from-pdf';
import sharepointService from 'services/sharepoint-service';
import { createVehicleOffer } from 'services/fleet-vehicle-service';
import { CarMehanicIcon } from 'components/icons/car-mehanic';
import Spinner from 'components/spinner';
import { NewCarIcon } from 'components/icons-new/new-car';
import { Button, Distance, Text } from 'common/common-components.styled';
import useOfferStatus from 'hooks/react-query/use-offer-status';
import { EventProps } from 'components/product/product-view.props';
import useOldestEvents from 'hooks/use-oldest-events';
import { useReferenceVehicleTypes } from 'hooks/react-query/show-change-view-button';

export const NewVehicle = () => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [cars, setCars] = useState<AnyObject | null>(null);
  const [fuelType, setFuelType] = useState<string>('-');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowWidget, setIsShowWidget] = useState(true);
  const user = useTypedSelector(state => state.app.user);
  const smartTable = useSelector((state: AnyObject) => state['app'].smartTable);
  const dispatch = useDispatch();
  const history = useHistory();
  const newVehicleEvents: EventProps[] = cars?.items[0]?.events || [];
  const { vehicleConfigurationBasedOnGRVCreatedEvent } = useOldestEvents(newVehicleEvents);
  const isBasedOnGlobalReferenceVehicle = !!vehicleConfigurationBasedOnGRVCreatedEvent;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { isPdfConfigurationAvailable } = useReferenceVehicleTypes(true);

  const getFuelType = (type: number) => {
    const label = (ENGINE_TYPE_LABELS as { [key: number]: string })[type];
    setFuelType(label ?? '-');
  };

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getData = async () => {
    let userBasket;
    try {
      userBasket = await getUserBasket(user?.id);

      const userBasketId = userBasket?.data?.data?.opportunityid
        ? btoa(userBasket.data.data.opportunityid)
        : btoa(defaultGuid);
      const res = await getReconfigurationsList({ ...smartTable, pagination: undefined }, atob(userBasketId));
      hideOverlay();
      const angebotes: AnyObject = res?.data?.data || {};
      if (angebotes?.count !== null) {
        angebotes.items = getResponseFormData(res?.data, [{ name: 'items', type: 'array' }], true);
        setCars(angebotes);
      }
      if (angebotes?.items[0]?.uds_enginetypecode_value) {
        getFuelType(angebotes.items[0].uds_enginetypecode_value);
      }

      setIsShowWidget(true);
    } catch (e) {
      userBasket = {};
      setIsShowWidget(false);
    } finally {
      setIsLoading(false);
    }
  };

  const createVehicleOfferCallback = async (dataToSend: AnyObject, files: any, isAdditionalFieldsShown: boolean) => {
    closeAlert();
    showOverlay();
    let snackbarData = {
      type: 'success',
      message: isAdditionalFieldsShown ? 'Änderungen erfolgreich gespeichert.' : 'Fahrzeugkonfiguration hochgeladen.'
    };
    const response = await createVehicleOffer(dataToSend);
    if (response.data?.data) {
      const fileResponse = await sharepointService.uploadSharepointFile(
        files,
        'Hersteller-Konfiguration',
        response.data.data.fahrzeugangeboteId,
        'uds_fahrzeugangebote',
        null
      );
      if (fileResponse.data?.level !== RequestStatuses.Success) {
        snackbarData = {
          type: 'error',
          message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
        };
      }
    } else {
      snackbarData = {
        type: 'error',
        message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
      };
    }
    hideOverlay();
    getData();
    dispatch(
      setSnakbar({
        ...snackbarData,
        isOpen: true
      })
    );
  };

  const openNewConfigurationDialog = () => {
    const alertData = {
      title: 'Herstellerkonfiguration hochladen',
      children: (
        <ConfigurationFromPdf
          createVehicleOffer={(data, files, isAdditionalFieldsShown) =>
            createVehicleOfferCallback(data, files, isAdditionalFieldsShown)
          }
        />
      ),
      buttons: [
        {
          type: 'cancel',
          hide: true,
          title: 'Abbrechen',
          action: closeAlert
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  useEffect(() => {
    getData();
  }, []);

  const blockStyle = cars?.count && cars?.count > 0 ? classes.dashboard_item_full : classes.dashboard_item;
  const { offerStatus: vehicleOfferStatus } = useOfferStatus(newVehicleEvents, false, true);

  const gesamtAdditionalString =
    isBasedOnGlobalReferenceVehicle &&
    cars?.items[0]?.uds_mobexo_gesamtrate &&
    vehicleOfferStatus !== OFFER_STATUSES.orderApproved
      ? 'ab '
      : '';

  const gesamtrateString = cars?.items[0]?.uds_mobexo_gesamtrate_value
    ? `${gesamtAdditionalString}${cars?.items[0]?.uds_mobexo_gesamtrate_value} / Monat`
    : '';

  if (!isShowWidget) {
    return null;
  }

  return (
    <div className={blockStyle}>
      {isLoading ? (
        <div className={classes.spinner_block}>
          <Spinner style={spinnerStyle} />
        </div>
      ) : (
        <>
          {cars?.count === 0 && (
            <>
              <div className={classes.icon_header}>
                <CarMehanicIcon />
              </div>

              <div className={classes.header_block}>
                <h6>Neues Fahrzeug</h6>
                <p>Konfigurieren & bestellen</p>
              </div>

              <div className={classes.driver_buttons_container}>
                <div onClick={() => history.push(PAGES.CONFIGURATIONS)}>
                  <Button minWidth='100%'>Fahrzeug konfigurieren</Button>
                </div>

                {isPdfConfigurationAvailable && (
                  <>
                    <Distance top='10' />

                    <Button onClick={openNewConfigurationDialog} secondary minWidth='100%'>
                      Konfiguration hochladen
                    </Button>
                  </>
                )}
              </div>
            </>
          )}

          {cars?.count === 1 && (
            <>
              <div className={classes.header}>
                <div
                  className={classes.link}
                  onClick={() => history.push(PAGES.CONFIGURATIONS)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <NewCarIcon width={20} height={18} hovered={isHovered} />
                  <h6>Konfigurierte Fahrzeuge</h6>
                </div>
              </div>

              <div className={classes.data_block}>
                <div className={classes.data_item}>
                  <h6>{cars?.items[0]?.uds_marke || '-'}</h6>
                  <p>Marke</p>
                </div>

                <div className={classes.data_item}>
                  <h6>{cars?.items[0]?.uds_modell || '-'}</h6>
                  <p>Model</p>
                </div>

                <div className={classes.data_item}>
                  <h6>{fuelType}</h6>
                  <p>Treibstoff</p>
                </div>

                <div className={classes.data_item}>
                  <h6>{gesamtrateString}</h6>
                </div>
              </div>

              <div onClick={() => history.push(PAGES.CONFIGURATIONS)} className={classes.driver_buttons_container}>
                <div className={classes.new_config}>
                  <Text pointer>Konfiguration anzeigen</Text>
                  <img src='/assets/images/right-long-arrow.svg' alt='right_arrow' />
                </div>
              </div>
            </>
          )}

          {cars?.count > 1 && (
            <>
              <div className={classes.header}>
                <div
                  className={classes.link}
                  onClick={() => history.push(PAGES.CONFIGURATIONS)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <NewCarIcon width={20} height={18} hovered={isHovered} />
                  <h6>Konfigurierte Fahrzeuge</h6>
                </div>
              </div>

              <div className={classes.configure_img}>
                <img src='/assets/images/config-car-big.svg' alt='config' />
              </div>

              <div className={classes.configure_info}>
                <h6>
                  Du hast {cars?.count || '0'}
                  <br />
                  Konfigurationen
                </h6>
              </div>

              <div onClick={() => history.push(PAGES.CONFIGURATIONS)} className={classes.driver_buttons_container}>
                <div className={classes.new_config}>
                  <Text pointer>Konfiguration anzeigen</Text>
                  <img src='/assets/images/right-long-arrow.svg' alt='right_arrow' />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const spinnerStyle = {
  width: '50px',
  zIndex: 97
};
