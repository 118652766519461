import React, { useMemo } from 'react';
import { Event } from './calendar-view';
import ReactJoyride, { CallBackProps, TooltipRenderProps, ACTIONS, STATUS, Step } from 'react-joyride';
import { setPoolCalendarEventTooltip } from 'actions/app_action';
import { useDispatch } from 'react-redux';
import { Flex, Text } from 'common/common-components.styled';
import { HiddenText, ToolTipContainer } from './calendar-view-styled';
import moment from 'moment';
import BookingMoreMenu from './booking-more-menu';
import { USER_ROLES } from '../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { RecordStatuses } from './bookings-page';
import { PenIcon } from 'components/icons-new/pen';
import IconHover from 'components/icon-hover';
import usePoolCarBookingModals from 'components/pool-car-booking/hooks/use-pool-car-booking-modals';
import { Booking } from 'components/pool-car-booking/edit-booking/edit-booking.types';
import cloneDeep from 'lodash/cloneDeep';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

interface Props {
  event: Event | null;
  showTooltip: boolean;
  refetch: () => void;
}

type TooltipContent = {
  title: string;
  content: string | undefined | number | JSX.Element;
  color?: string;
  component?: JSX.Element;
};

interface TooltipComponent extends TooltipRenderProps {
  step: Step & { booking: Booking };
}

export const getBooking = (event: Event) => {
  return {
    uds_name: event.uds_name,
    new_fuhrparkid: event.new_fuhrparkid,
    new_model: event.car_model,
    new_name: event.car_name,
    uds_abholdatum: event.startDate,
    uds_kilometervoraussichtlich: event.uds_kilometervoraussichtlich,
    uds_poolkalenderid: event.uds_poolkalenderid,
    uds_ruckgabedatum: event.endDate,
    uds_requester: event.requester,
    uds_requester_id: event.requester_id,
    uds_status: event.uds_status,
    uds_status_code: event.uds_status_code,
    new_standortid_id: event.new_standortid_id,
    new_standortid: event.new_standortid,
    isOwnBooking: event.isOwnBooking,
    isShowBooking: event.isShowBooking,
    uds_comment: event.uds_comment
  };
};

const TooltipComponent = ({ step, closeProps, tooltipProps }: TooltipComponent) => {
  const { openEditingModal } = usePoolCarBookingModals();

  return (
    <ToolTipContainer {...tooltipProps}>
      <Flex bottom='15' justify='space-between'>
        <Flex>
          <Text size='20' right='10' fontWeight={600}>
            {step.title}
          </Text>
          {step.booking.isShowBooking && (
            <div {...closeProps}>
              <IconHover onClick={() => openEditingModal(cloneDeep(step.booking))} height='25px'>
                <PenIcon />
              </IconHover>
            </div>
          )}
        </Flex>

        <Flex {...closeProps} pointer padding='0 6px'>
          <img src='/assets/images/cross.svg' alt='close' />
        </Flex>
      </Flex>

      <Flex>
        <Flex width='100%'>{step.content}</Flex>
      </Flex>
    </ToolTipContainer>
  );
};

const BookingDetailsTooltip = ({ event, showTooltip, refetch }: Props) => {
  const dispatch = useDispatch();
  const isDriverOrPoolManager = useIsUserHaveRole([USER_ROLES.driver.id, USER_ROLES.poolManager.id]);

  const eventContent = [
    { title: 'Fahrzeug', content: event?.car_model },
    { title: 'Kennzeichen', content: event?.car_name },
    { title: 'Standort', content: event?.new_standortid },
    { title: 'Mitarbeiter', content: event?.requester },
    { title: 'Geplante Kilometer', content: event?.uds_kilometervoraussichtlich },
    { title: 'Abholung', content: moment(event?.startDate).format('DD.MM.yy HH:mm') },
    { title: 'Rückgabe', content: moment(event?.endDate).format('DD.MM.yy HH:mm') },
    {
      title: 'Status',
      color: getPoolCarStatusColor(event?.uds_status_code),
      content: event?.uds_status
    },
    {
      title: 'Kommentar',
      content: event?.uds_comment || '-',
      component: event?.isShowBooking ? (
        <BookingMoreMenu row={event ? getBooking(event) : {}} refetch={refetch} isTooltipAction />
      ) : undefined
    }
  ];

  const steps = useMemo(() => {
    if (!event) {
      return [];
    }
    return [
      {
        title: event.uds_name,
        booking: getBooking(event),
        content: (
          <Flex direction='column' width='100%' maxWidth='400px'>
            {eventContent.map((item: TooltipContent) => (
              <Flex justify='space-between' bottom='15' width='100%'>
                <Flex width='50%'>
                  <HiddenText textAlign='left' color='grey600'>
                    {item.title}
                  </HiddenText>
                </Flex>

                <Flex width='50%' justify={item.component ? 'space-between' : 'flex-start'}>
                  <EllipsisWithTooltip tooltip={item.content ? String(item.content) : '-'}>
                    <Text
                      width={!item.component ? '90%' : '100%'}
                      textAlign='left'
                      size='16'
                      color={item.color || 'grey900'}
                    >
                      {item.content}
                    </Text>
                  </EllipsisWithTooltip>

                  {item.component && <Flex>{item.component}</Flex>}
                </Flex>
              </Flex>
            ))}
          </Flex>
        ),
        target: `#${event?.uds_name}`,
        placement: 'auto',
        disableBeacon: true,
        offset: -10
      }
    ];
  }, [event?.uds_name]);

  const handleJoyCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if (status === STATUS.FINISHED || action === ACTIONS.CLOSE) {
      dispatch(setPoolCalendarEventTooltip(null));
    }
  };

  return (
    <ReactJoyride
      //@ts-ignore
      steps={steps}
      run={showTooltip}
      disableOverlay
      debug={false}
      callback={handleJoyCallback}
      tooltipComponent={TooltipComponent as React.ElementType<TooltipRenderProps>}
      disableScrolling
    />
  );
};

export const getPoolCarStatusColor = (status?: RecordStatuses) => {
  return RecordStatuses.APPROVED_RECORD === status
    ? 'darkBlue'
    : RecordStatuses.REJECTED_RECORD === status
    ? 'pink'
    : RecordStatuses.CANCELED_RECORD === status
    ? 'grey400'
    : 'darkGold';
};

export default BookingDetailsTooltip;
