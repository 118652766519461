import React from 'react';

const SonstigesIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60.6406 57.1587C59.0059 57.1587 57.4249 56.462 56.3173 55.2561C56.094 55.0149 55.6652 55.0149 55.4419 55.2561C54.3254 56.4709 52.7532 57.1587 51.1186 57.1587C49.484 57.1587 47.9029 56.462 46.7953 55.2561C46.572 55.0149 46.1432 55.0149 45.9199 55.2561C44.8034 56.4709 43.2313 57.1587 41.5966 57.1587C39.9352 57.1587 38.3452 56.453 37.2287 55.2203C37.0054 54.9792 36.6302 54.9613 36.389 55.1757C36.1479 55.399 36.13 55.7742 36.3444 56.0153C37.6842 57.4981 39.5958 58.3467 41.5966 58.3467C43.3563 58.3467 45.0624 57.6768 46.3576 56.4888C47.6528 57.6768 49.3589 58.3467 51.1186 58.3467C52.8783 58.3467 54.5844 57.6768 55.8796 56.4888C57.1748 57.6768 58.8809 58.3467 60.6406 58.3467C60.9711 58.3467 61.239 58.0787 61.239 57.7482C61.239 57.4177 60.9711 57.1587 60.6406 57.1587Z'
        fill='#335566'
      />
      <path
        d='M65.4016 49.7805C63.7669 49.7805 62.1859 49.0838 61.0783 47.8779C60.8549 47.6367 60.4262 47.6367 60.2029 47.8779C59.0863 49.0927 57.5142 49.7805 55.8796 49.7805C54.245 49.7805 52.6639 49.0838 51.5563 47.8779C51.333 47.6367 50.9042 47.6367 50.6809 47.8779C49.5644 49.0927 47.9923 49.7805 46.3576 49.7805C44.723 49.7805 43.1419 49.0838 42.0343 47.8779C41.811 47.6367 41.3823 47.6367 41.1589 47.8779C40.0424 49.0927 38.4703 49.7805 36.8357 49.7805C35.1742 49.7805 33.5843 49.0748 32.4677 47.8422C32.2444 47.601 31.8692 47.5831 31.628 47.7975C31.3869 48.0208 31.369 48.396 31.5834 48.6371C32.9233 50.1199 34.8348 50.9685 36.8357 50.9685C38.5953 50.9685 40.3014 50.2986 41.5966 49.1106C42.8919 50.2986 44.5979 50.9685 46.3576 50.9685C48.1173 50.9685 49.8234 50.2986 51.1186 49.1106C52.4138 50.2986 54.1199 50.9685 55.8796 50.9685C57.6393 50.9685 59.3454 50.2986 60.6406 49.1106C61.9358 50.2986 63.6419 50.9685 65.4016 50.9685C65.7321 50.9685 66 50.7005 66 50.37C66 50.0485 65.7321 49.7805 65.4016 49.7805Z'
        fill='#335566'
      />
      <path
        d='M46.6614 28.9323C46.9115 28.9323 47.1437 28.7715 47.2241 28.5214C47.3224 28.2088 47.1526 27.8694 46.84 27.7711L36.139 24.3321L37.0858 20.7949C37.1751 20.4733 36.9786 20.1517 36.666 20.0624C36.3444 19.9731 36.0228 20.1696 35.9335 20.4822L34.9956 23.9659L25.0985 20.7859C24.7859 20.6877 24.4464 20.8574 24.3482 21.17C24.2499 21.4827 24.4196 21.8221 24.7323 21.9204L46.4649 28.8966C46.5452 28.9234 46.6078 28.9323 46.6614 28.9323Z'
        fill='#335566'
      />
      <path
        d='M58.6485 33.3359L51.976 29.1913L46.2146 23.4745L46.1699 23.4299C44.9283 22.1436 42.3468 19.4817 37.2196 18.1061C31.6815 16.6234 29.8504 16.5698 26.4382 17.1593L19.4977 18.3562L16.2105 17.8203C14.5759 17.5077 12.9681 18.526 12.5393 20.1249L11.6907 23.2334C10.5831 23.2959 9.6452 24.0551 9.35043 25.127L8.96633 26.5741C8.63583 27.7978 9.24324 29.0573 10.3955 29.5754L14.4062 31.3708C14.7009 31.5048 15.0582 31.3708 15.1922 31.076C15.3262 30.7723 15.1922 30.424 14.8975 30.29L10.8868 28.4946C10.2705 28.2176 9.94889 27.5388 10.1186 26.8867L10.5027 25.4397C10.6546 24.868 11.1637 24.4571 11.7532 24.4214C12.2803 24.3946 12.7269 24.0283 12.8609 23.5192L13.6916 20.4286C13.9596 19.4281 14.9689 18.7939 16.0051 18.9905L19.4083 19.5443C19.4709 19.5532 19.5423 19.5532 19.6049 19.5443L26.6436 18.3294C29.9039 17.7667 31.5564 17.8203 36.907 19.2584C41.7126 20.5447 44.1422 23.0547 45.3124 24.2606L51.1899 30.0845C51.2256 30.1203 51.2614 30.147 51.2971 30.1649L58.0232 34.3453C58.8003 34.8276 59.2559 35.6941 59.2023 36.6141L59.1398 37.6503C59.1308 37.8468 59.2202 38.0433 59.3809 38.1594L60.2117 38.7758C60.4618 38.9634 60.569 39.276 60.4886 39.5708L60.1313 40.9106C60.0152 41.3305 59.7472 41.6877 59.3631 41.9111C58.979 42.1344 58.5413 42.188 58.1215 42.0718L57.1657 41.8128C56.8442 41.7235 56.5226 41.92 56.4332 42.2326C56.3439 42.5453 56.5405 42.8758 56.8531 42.9651L57.8088 43.2241C58.05 43.2867 58.3001 43.3224 58.5413 43.3224C59.7918 43.3224 60.9352 42.4827 61.2746 41.2233L61.6319 39.8834C61.8374 39.1063 61.5605 38.2934 60.9084 37.82L60.3367 37.4002L60.3814 36.6856C60.4618 35.3279 59.7918 34.0416 58.6485 33.3359Z'
        fill='#335566'
      />
      <path
        d='M22.2757 27.3334C19.6853 26.6367 17.0056 28.182 16.3178 30.7724C15.6211 33.3628 17.1664 36.0426 19.7568 36.7303C20.1766 36.8465 20.6054 36.9001 21.0252 36.9001C21.8648 36.9001 22.7045 36.6768 23.4459 36.248C24.5714 35.5959 25.3753 34.5508 25.7147 33.2914C26.4115 30.701 24.8751 28.0302 22.2757 27.3334ZM24.5714 32.9877C24.3213 33.9345 23.7049 34.7295 22.8563 35.2208C22.0078 35.7121 21.0163 35.8461 20.0694 35.587C19.1226 35.3369 18.3276 34.7206 17.8363 33.872C17.345 33.0234 17.211 32.0319 17.4701 31.0851C17.7202 30.1382 18.3365 29.3432 19.1851 28.852C19.7479 28.5215 20.3821 28.3607 21.0163 28.3607C21.3378 28.3607 21.6594 28.4053 21.972 28.4857C23.9282 29.0038 25.0984 31.0225 24.5714 32.9877Z'
        fill='#335566'
      />
      <path
        d='M21.4985 32.7734C21.2841 32.9073 21.034 32.9431 20.7928 32.8805C20.3194 32.7555 20.0425 32.2731 20.1676 31.7997C20.2212 31.5943 20.3373 31.4245 20.5159 31.3084C20.7839 31.1208 20.8464 30.7457 20.6588 30.4777C20.4713 30.2097 20.0961 30.1383 19.8281 30.3348C19.4262 30.6117 19.1403 31.0226 19.0153 31.496C18.7205 32.6036 19.3815 33.7381 20.4802 34.0418C20.6588 34.0864 20.8375 34.1132 21.0161 34.1132C21.4092 34.1132 21.8022 33.9971 22.1416 33.7827C22.4185 33.6041 22.4989 33.2378 22.3203 32.9609C22.1416 32.6751 21.7754 32.5947 21.4985 32.7734Z'
        fill='#335566'
      />
      <path
        d='M49.2964 44.6444C49.7163 44.7605 50.145 44.8141 50.5648 44.8141C51.4045 44.8141 52.2441 44.5908 52.9855 44.1621C54.111 43.51 54.9149 42.4649 55.2544 41.2054C55.5938 39.946 55.4151 38.6418 54.772 37.5163C54.1199 36.3908 53.0748 35.5869 51.8154 35.2475C49.225 34.5508 46.5452 36.0961 45.8574 38.6865C45.518 39.946 45.6967 41.2501 46.3398 42.3756C46.9919 43.51 48.0459 44.3139 49.2964 44.6444ZM47.0097 38.9991C47.4474 37.3556 48.9391 36.2747 50.5648 36.2747C50.8775 36.2747 51.199 36.3194 51.5117 36.3998C53.4679 36.9268 54.638 38.9455 54.111 40.9017C53.8609 41.8486 53.2446 42.6436 52.396 43.1348C51.5474 43.6261 50.5559 43.7601 49.6091 43.5011C48.6622 43.2509 47.8672 42.6346 47.376 41.786C46.8847 40.9375 46.7507 39.946 47.0097 38.9991Z'
        fill='#335566'
      />
      <path
        d='M50.0199 41.9559C50.1985 42.0006 50.3772 42.0274 50.5558 42.0274C50.9488 42.0274 51.3418 41.9112 51.6813 41.6969C51.9582 41.5182 52.0386 41.152 51.8599 40.8751C51.6813 40.5982 51.3151 40.5178 51.0382 40.6964C50.8238 40.8304 50.5737 40.8751 50.3325 40.8036C49.8591 40.6786 49.5822 40.1962 49.7072 39.7228C49.7608 39.5173 49.8769 39.3476 50.0556 39.2315C50.3236 39.0439 50.3861 38.6688 50.1985 38.4008C50.0109 38.1328 49.6358 38.0614 49.3678 38.2579C48.9658 38.5348 48.68 38.9457 48.5549 39.4191C48.2602 40.5178 48.9122 41.6522 50.0199 41.9559Z'
        fill='#335566'
      />
      <path
        d='M27.8317 33.9613C27.5102 33.872 27.1886 34.0685 27.0993 34.3812C27.0099 34.7027 27.2065 35.0243 27.5191 35.1136L43.2223 39.3208C43.2759 39.3387 43.3295 39.3387 43.3741 39.3387C43.6332 39.3387 43.8744 39.16 43.9458 38.901C44.0352 38.5794 43.8386 38.2578 43.526 38.1685L27.8317 33.9613Z'
        fill='#335566'
      />
      <path
        d='M19.5961 14.7744C19.6497 14.7923 19.7033 14.7923 19.748 14.7923C20.007 14.7923 20.2482 14.6136 20.3196 14.3546C20.409 14.033 20.2124 13.7114 19.8998 13.6221L12.4323 11.6212C12.1197 11.5319 11.7892 11.7284 11.6999 12.0411C11.6105 12.3626 11.807 12.6842 12.1197 12.7735L19.5961 14.7744Z'
        fill='#335566'
      />
      <path
        d='M9.7882 12.1483C9.84179 12.1661 9.89538 12.1661 9.94004 12.1661C10.1991 12.1661 10.4403 11.9875 10.5117 11.7285C10.6011 11.4069 10.4045 11.0853 10.0919 10.996L9.71673 10.8977C9.39517 10.8084 9.0736 11.0049 8.98428 11.3176C8.89496 11.6391 9.09146 11.9607 9.4041 12.05L9.7882 12.1483Z'
        fill='#335566'
      />
      <path
        d='M4.02676 14.962C3.93744 15.2835 4.13394 15.6051 4.44658 15.6944L10.5742 17.338C10.6278 17.3559 10.6814 17.3559 10.7261 17.3559C10.9851 17.3559 11.2263 17.1772 11.2978 16.9182C11.3871 16.5966 11.1906 16.275 10.8779 16.1857L4.7503 14.5421C4.43766 14.4528 4.10715 14.6404 4.02676 14.962Z'
        fill='#335566'
      />
      <path
        d='M27.5548 39.4814H4.59849C4.26799 39.4814 4 39.7494 4 40.0799C4 40.4104 4.26799 40.6784 4.59849 40.6784H26.9563V58.5254C26.9563 58.8559 27.2243 59.1239 27.5548 59.1239C27.8853 59.1239 28.1533 58.8559 28.1533 58.5254V40.0799C28.1533 39.7494 27.8853 39.4814 27.5548 39.4814Z'
        fill='#335566'
      />
    </svg>
  );
};

export default SonstigesIcon;
