import React, { useRef, useState } from 'react';
import { UserInfoContainerProps } from 'components/side-menu/user-info-container/user-info-container.props';
import UserIcon from 'components/breadcrumbs-panel-dashboard/user-icon/index';
import UserInfo from 'components/side-menu/user-info';
import { Distance, Flex } from 'common/common-components.styled';
import { Popover } from '@mui/material';
import { useTheme } from 'styled-components';
import { getMenuItemsPaperProps } from 'components/side-menu/menu-item/menu-item';
import { PAGES } from 'common/enums';
import { useHistory } from 'react-router-dom';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import { useTypedSelector } from 'hooks/use-typed-selector';

const UserInfoContainer = ({ isMinimized }: UserInfoContainerProps) => {
  const theme = useTheme();
  const isSiteAvailable = useTypedSelector(state => state.app.isSiteAvailable);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const userIcon = useRef<HTMLDivElement | null>(null);
  const popover = useRef<HTMLDivElement | null>(null);

  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const isAllowSettings = !useIsUserHaveRole([USER_ROLES.dealer.id, USER_ROLES.leasingcompany.id]);

  const openPopover = () => {
    setOpen(true);
  };

  const closePopover = () => {
    setOpen(false);
  };

  const goToUserSettings = () => {
    if (isAllowSettings && isSiteAvailable) {
      openUnsavedChangesModal(() => {
        history.push(PAGES.ACCOUNT_SETTINGS);
      });
      closePopover();
    }
  };

  const defaultPaperProps = getMenuItemsPaperProps(theme, openPopover, closePopover);

  const userIconClickHandler = () => {
    isMinimized && openPopover();
  };

  return (
    <>
      <div onMouseEnter={userIconClickHandler} onMouseLeave={closePopover} ref={userIcon}>
        <UserIcon onClick={goToUserSettings} />
      </div>

      {isMinimized ? (
        <Popover
          ref={popover}
          sx={{
            pointerEvents: 'none'
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={open}
          anchorEl={userIcon.current}
          onClose={closePopover}
          PaperProps={{
            ...defaultPaperProps,
            sx: { ...defaultPaperProps.sx, ml: 2 }
          }}
        >
          <Flex padding='15px 20px'>
            <UserInfo goToUserSettings={goToUserSettings} />
          </Flex>
        </Popover>
      ) : (
        <>
          <Distance side='10' />
          <UserInfo goToUserSettings={goToUserSettings} />
        </>
      )}
    </>
  );
};

export default UserInfoContainer;
