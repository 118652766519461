import React from 'react';

const SachbeschVandalismusIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.455 26.1183L12.3637 27.5059L11.6254 29.0181C11.3941 29.4895 11.2162 30.2456 11.2162 30.7704V39.4964C11.2162 40.1635 11.6699 40.8751 12.2747 41.1509L12.9241 41.4533V45.9542C12.9241 46.9327 13.7157 47.7243 14.6942 47.7243H20.6983C21.6768 47.7243 22.4684 46.9327 22.4684 45.9542V43.8372C22.4684 43.5081 22.2016 43.2412 21.8725 43.2412C21.5434 43.2412 21.2765 43.5081 21.2765 43.8372V45.9542C21.2765 46.2744 21.0097 46.5413 20.6894 46.5413H14.7031C14.3829 46.5413 14.116 46.2744 14.116 45.9542V41.0708C14.116 40.8396 13.9826 40.6261 13.7691 40.5282L12.7729 40.0657C12.5861 39.9767 12.3993 39.6921 12.3993 39.4875V30.7615C12.3993 30.4146 12.5327 29.8364 12.6839 29.5251L13.6801 27.4792C13.7513 27.3369 13.7602 27.1768 13.7068 27.0256C13.6535 26.8743 13.5467 26.7498 13.4044 26.6875L9.96202 25.042C9.80191 24.9619 9.65959 24.7128 9.68627 24.5349L9.88196 23.005C9.89975 22.8716 10.0421 22.7382 10.1844 22.7382H15.6371C15.8594 22.7382 16.0729 22.6047 16.1708 22.409L19.3463 15.9156C19.8177 14.9461 21.1342 14.1277 22.2105 14.1277H43.3273C44.4036 14.1277 45.7201 14.9461 46.1915 15.9156C46.3339 16.2092 46.6897 16.3337 46.9832 16.1914C47.2767 16.0491 47.4013 15.6933 47.2589 15.3997C46.5829 14.021 44.8573 12.9447 43.3273 12.9447H22.2016C20.6716 12.9447 18.946 14.021 18.27 15.3997L15.2635 21.564H10.1844C9.455 21.564 8.80566 22.1333 8.70782 22.8627L8.51212 24.3926C8.42317 25.0775 8.83235 25.8158 9.455 26.1183Z'
        fill='#335566'
      />
      <path
        d='M18.1721 41.6668H35.4196C35.7488 41.6668 36.0156 41.3999 36.0156 41.0708C36.0156 40.7417 35.7488 40.4749 35.4196 40.4749H18.1721C17.843 40.4749 17.5762 40.7417 17.5762 41.0708C17.5762 41.3999 17.843 41.6668 18.1721 41.6668Z'
        fill='#335566'
      />
      <path
        d='M42.4735 24.7396H23.0556C22.4952 24.7396 21.5968 24.5261 21.0987 24.2681L19.1507 23.2719C18.8572 23.1207 18.5014 23.2363 18.3501 23.5298C18.1989 23.8234 18.3146 24.1792 18.6081 24.3304L20.5561 25.3266C21.2143 25.6647 22.3173 25.9315 23.0556 25.9315H42.4824C42.8115 25.9315 43.0784 25.6647 43.0784 25.3355C43.0695 25.0064 42.8026 24.7396 42.4735 24.7396Z'
        fill='#335566'
      />
      <path
        d='M21.063 33.1098C20.9651 33.1899 20.8139 33.2165 20.6538 33.1721L16.1173 32.1402C15.7971 32.0691 15.4769 32.2648 15.4057 32.585C15.3345 32.9052 15.5302 33.2254 15.8505 33.2966L20.3869 34.3284C20.5293 34.364 20.6627 34.3729 20.7961 34.3729C21.1697 34.3729 21.5255 34.2573 21.8012 34.0349C22.1837 33.7325 22.4061 33.2521 22.4061 32.7184V31.5087C22.4061 30.5035 21.6411 29.5251 20.6627 29.2938L18.2076 28.7067C17.8874 28.6267 17.5672 28.8313 17.496 29.1426C17.416 29.4628 17.6206 29.7831 17.9319 29.8542L20.378 30.4413C20.8228 30.548 21.2142 31.0461 21.2142 31.4998V32.7095C21.2142 32.8874 21.1608 33.0297 21.063 33.1098Z'
        fill='#335566'
      />
      <path
        d='M54.1258 41.3377C53.8412 41.4978 53.7433 41.8625 53.9123 42.1471L60.0677 52.8212C60.5658 53.684 60.2723 54.787 59.4095 55.2851C58.5466 55.7832 57.4437 55.4897 56.9455 54.6269L51.4751 45.1625C51.315 44.8779 50.9503 44.7801 50.6656 44.9491C50.381 45.1092 50.2832 45.4739 50.4522 45.7585L55.9226 55.2139C56.4741 56.1746 57.4792 56.7083 58.52 56.7083C59.027 56.7083 59.5429 56.5838 60.0054 56.308C61.4286 55.4808 61.9268 53.6484 61.0995 52.2252L54.9442 41.5601C54.7752 41.2754 54.4105 41.1776 54.1258 41.3377Z'
        fill='#335566'
      />
      <path
        d='M44.9018 30.2989C45.1331 30.0676 45.1331 29.694 44.9018 29.4628C44.6706 29.2315 44.297 29.2315 44.0657 29.4628L38.3373 35.1912C37.8659 35.6626 37.6079 36.2942 37.6079 36.9613C37.6079 37.6284 37.8659 38.26 38.3373 38.7314C38.8087 39.2028 39.4403 39.4608 40.1074 39.4608C40.7745 39.4608 41.4061 39.2028 41.8775 38.7314L44.9374 35.6715C45.1687 35.4402 45.1687 35.0666 44.9374 34.8354C44.7062 34.6041 44.3326 34.6041 44.1013 34.8354L41.0325 37.8864C40.7834 38.1354 40.4543 38.2689 40.1074 38.2689C39.7605 38.2689 39.4225 38.1354 39.1823 37.8864C38.9333 37.6373 38.7998 37.3082 38.7998 36.9613C38.7998 36.6144 38.9333 36.2764 39.1823 36.0362L44.9018 30.2989Z'
        fill='#335566'
      />
      <path
        d='M50.0786 26.4119C48.1395 26.4119 46.5561 27.9952 46.5561 29.9343V37.7797C46.5561 37.9754 46.4761 38.1622 46.3427 38.3045L41.3436 43.3035C40.8099 43.8372 40.4186 44.5133 40.2229 45.2427C39.7514 47.0039 38.7374 50.7843 38.0614 53.2482C37.8479 54.0221 37.9546 54.8315 38.3549 55.5253C38.7552 56.228 39.4134 56.7262 40.1962 56.9396L40.2584 56.9574C40.5164 57.0286 40.7744 57.0553 41.0234 57.0553C41.5393 57.0553 42.0374 56.9219 42.5 56.655C43.1849 56.2636 43.6652 55.6232 43.8698 54.8582L45.8445 47.3953C45.8801 47.2707 45.9424 47.164 46.0313 47.075L52.0355 41.0709C52.9072 40.1992 53.3875 39.0339 53.3875 37.8064V33.9015C53.3875 33.5724 53.1207 33.3055 52.7916 33.3055C52.4624 33.3055 52.1956 33.5724 52.1956 33.9015V37.8064C52.1956 38.7226 51.8398 39.5854 51.1905 40.2347L45.1863 46.2389C44.9461 46.4791 44.7771 46.7726 44.6971 47.0928L42.7224 54.5558C42.5978 55.0094 42.3132 55.3919 41.904 55.6321C41.4949 55.8722 41.0234 55.9345 40.5698 55.81L40.5075 55.7922C40.0272 55.6677 39.6358 55.3563 39.3867 54.9383C39.1466 54.5202 39.0843 54.031 39.2088 53.5595C39.8848 51.0867 40.8989 47.3063 41.3703 45.5451C41.5126 45.0114 41.7973 44.5222 42.1887 44.1308L47.1877 39.1318C47.5524 38.7671 47.7481 38.2867 47.7481 37.7708V29.9254C47.7481 28.6356 48.7977 27.586 50.0875 27.586H52.5692C53.6366 27.586 54.6862 28.0219 55.4423 28.7779L59.0804 32.4249C59.4895 32.8341 59.7119 33.3678 59.7119 33.946V38.8916C59.7119 39.6121 59.1248 40.2081 58.3954 40.2081C57.6749 40.2081 57.079 39.621 57.079 38.8916V34.6931C57.079 34.3373 56.9367 33.9993 56.6876 33.7503L53.2007 30.2545C52.9695 30.0232 52.5959 30.0232 52.3646 30.2545C52.1333 30.4858 52.1333 30.8594 52.3646 31.0906L55.8515 34.5864C55.8781 34.6131 55.8959 34.6487 55.8959 34.6931V38.8916C55.8959 40.2703 57.0167 41.3911 58.3954 41.3911C59.7742 41.3911 60.8949 40.2703 60.8949 38.8916V33.946C60.8949 33.0565 60.548 32.2203 59.9165 31.5888L56.2784 27.9418C55.2822 26.9456 53.9657 26.403 52.5603 26.403H50.0786V26.4119Z'
        fill='#335566'
      />
      <path
        d='M45.3464 21.6708C45.3464 23.8323 47.1077 25.5935 49.2691 25.5935C51.4306 25.5935 53.1919 23.8323 53.1919 21.6708C53.1919 19.5093 51.4306 17.748 49.2691 17.748C47.1077 17.7569 45.3464 19.5093 45.3464 21.6708ZM49.2691 18.94C50.7813 18.94 51.9999 20.1675 51.9999 21.6708C51.9999 23.1829 50.7724 24.4015 49.2691 24.4015C47.757 24.4015 46.5384 23.174 46.5384 21.6708C46.5295 20.1675 47.757 18.94 49.2691 18.94Z'
        fill='#335566'
      />
      <path
        d='M34.42 36.2394L35.8795 36.3236C36.2075 36.342 36.4912 36.0899 36.5096 35.762C36.528 35.4341 36.2759 35.1504 35.948 35.1319L34.1727 35.0258L30.7581 31.9961L30.7145 28.795C30.7129 28.4662 30.4399 28.2017 30.1111 28.2032C29.7823 28.2048 29.5177 28.4778 29.5193 28.8066L29.5554 31.3579L24.8757 29.3801C24.5687 29.2504 24.2216 29.3955 24.0962 29.6946C23.9664 30.0016 24.1115 30.3488 24.4107 30.4742L29.8618 32.7786L33.2841 35.8126C33.2841 35.8126 33.6973 36.2387 34.42 36.2394Z'
        fill='#335566'
      />
    </svg>
  );
};

export default SachbeschVandalismusIcon;
