import { setIndicator } from 'actions/app_action';
import { useQuery } from 'react-query';
import { getFreigabeCounter, getOcrCounter } from 'services/eingangsrechnung-service';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from './use-typed-selector';
import { IndicatorSelector } from 'selectors';
import { AnyObject } from 'common/interfaces';
import { useRef } from 'react';

export enum INDICATOR_TYPE {
  freigabe,
  doctype
}

export const useRefreshIndicator = (type: INDICATOR_TYPE, interval: number, condition: boolean) => {
  const dispatch = useDispatch();
  const indicator: AnyObject = useTypedSelector(IndicatorSelector);
  const ref = useRef<AnyObject>();
  ref.current = indicator;

  let queryName = '';
  let fetchData = null;

  switch (type) {
    case INDICATOR_TYPE.freigabe: {
      fetchData = async () => {
        const res = await getFreigabeCounter();
        if (res.data?.data) {
          const dataToSet = {
            ...ref.current,
            [INDICATOR_TYPE.freigabe]: res.data.data
          };

          dispatch(setIndicator(dataToSet));
        }
      };
      queryName = 'refreshFreigabeIndicator';
      break;
    }
    case INDICATOR_TYPE.doctype: {
      fetchData = async () => {
        const res = await getOcrCounter();

        if (res.data?.data) {
          const dataToSet = {
            ...ref.current,
            [INDICATOR_TYPE.doctype]: res.data.data
          };

          dispatch(setIndicator(dataToSet));
        }
      };
      queryName = 'refreshDocTypeIndicator';
      break;
    }
  }

  try {
    const { data } = useQuery(queryName, fetchData, { refetchInterval: interval, enabled: condition });
  } catch (e) {}
};
