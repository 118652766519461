import { UnavailableRange } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../constants';
import moment from 'moment';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { fleetHistoryRequestConfig } from '../../../request-config/fleet-history/fleet-history.request.config';
import { GetUnavailableRanges } from 'request-config/fleet-history/fleet-history.types';

interface UnavailableRangeResponse {
  from: string;
  to: string;
}

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<UnavailableRangeResponse[]>>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(item => ({
      from: moment(item.from).startOf('day'),
      to: moment(item.to).endOf('day')
    })) as UnavailableRange[];
  }
  return [];
};

export const useHistoryUnavailableRanges = (
  entityName: PrimaryEntityNames | string,
  id: string,
  historyId?: string
) => {
  let requestData: GetUnavailableRanges = {
    entityName,
    id,
    historyId
  };

  const { fetch } = useFetch<ResponseModel<UnavailableRangeResponse[]>>({
    loadingKey: 'useHistoryUnavailableRanges',
    isShowLoading: true,
    ...fleetHistoryRequestConfig.getUnavailableRange(requestData)
  });

  const { data: unavailableRanges } = useQuery<
    AxiosResponse<ResponseModel<UnavailableRangeResponse[]>>,
    unknown,
    UnavailableRange[]
  >([ReactQueryKeys.GetCostCenterAvailableRanges, requestData], () => fetch(), {
    select: modifyResponse,
    cacheTime: 0
  });

  return { unavailableRanges };
};
