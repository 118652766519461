import styled from 'styled-components';

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;

  canvas {
    z-index: 10;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
  cursor: pointer;
  z-index: 10;
`;

export const QualityLabel = styled.span<{ indicatorColor: string }>`
  position: relative;
  padding-left: 5px;
  text-align: center;
  width: 95px;
  font-size: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: ${({ theme, indicatorColor }) => theme.colors[indicatorColor]};
    border-radius: 50%;
    display: inline-block;
  }
`;
