import styled from 'styled-components';
import { Flex, Text } from 'common/common-components.styled';

export const StyledOrderStatus = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey50};
  width: 100%;
`;

export const StyledCircle = styled.div<{ isDone: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ theme, isDone }) => (isDone ? theme.colors.blue : theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.grey500};
  margin-right: 20px;
  min-width: 25px;
  z-index: 1;
`;

interface StyledIconContainerProps {
  disabled: boolean;
}

export const StyledIconContainer = styled(Flex)<StyledIconContainerProps>`
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightBlue : theme.colors.blue)};
  width: 50px;
  min-height: 50px;
  min-width: 50px;
`;

export const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding-right: 5px;
`;
