import React, { useContext } from 'react';
import CarDamageIcon from './car-damage-icon/car-damage-icon';
import { Direction, Flex, Rotate } from 'common/common-components.styled';
import { formFieldProvider } from '../../form-field';
import { objectUtility } from 'utils/object/object';
import { DamageItem } from './car-damage-icon/car-damage-icon.props';
import { CarDamageOptionSet } from './car-damage-option-set/car-damage-option-set';
import { OthersPartButton } from './others-part-button/others-part-button';

export const CarDamageField = () => {
  const { reactHookFormData } = useContext(formFieldProvider);
  const { getValues } = reactHookFormData;

  const damageList = getValues('damageList') || {};

  return (
    <Flex direction='column' align='center' width='100%'>
      <Rotate rotateDirection={Direction.Right} width='fit-content'>
        <CarDamageIcon />
      </Rotate>

      <OthersPartButton />

      {objectUtility.values<Record<string, DamageItem | DamageItem[]>>(damageList).map(damageItem => {
        if (Array.isArray(damageItem)) {
          return damageItem.map(item => (
            <CarDamageOptionSet key={item.index} {...item} value={`${item.value} ${item.index || 0}`} />
          ));
        }
        return <CarDamageOptionSet key={damageItem.value} {...damageItem} />;
      })}
    </Flex>
  );
};
