import { useEffect, useState } from 'react';
import { FormFieldModel } from 'common/interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { Configuration } from './react-query/use-drivers-personal-configurations/use-drivers-personal-configurations.types';
import { configurationFormFields } from '../select-configuration-to-deactivate/configuration.form-fields';

const useConfigurationsFields = (configurations: Configuration[]) => {
  const [configurationFields, setConfigurationFields] = useState<FormFieldModel[]>(configurationFormFields);

  useEffect(() => {
    const newConfigurationFields = cloneDeep(configurationFields);
    const configurationField = newConfigurationFields.find(field => field.name === 'configuration');
    if (configurationField) {
      configurationField.options = configurations.map(configuration => ({
        label: `${configuration.uds_name} ${configuration.uds_title}`,
        value: configuration.uds_fahrzeugangeboteid
      }));
    }
    setConfigurationFields(newConfigurationFields);
  }, [configurations.length]);

  return { configurationFields };
};

export default useConfigurationsFields;
