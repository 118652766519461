import { connect } from 'react-redux';
import Form from './form-view';
import { MODAL_NAMES, MODALS } from '../../constants';
import { deleteForm, setForm, toggleModal, setEndLoading, setStartLoading } from '../../actions/app_action';

const mapStateToProps = (state, ownProps) => ({
  form: state.app.forms[ownProps.name]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setForm(form) {
    dispatch(setForm({ name: ownProps.name, form }));
  },
  deleteForm() {
    dispatch(deleteForm(ownProps.name));
  },
  hideOverlay() {
    dispatch(setEndLoading());
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  toggleModal(modalType, modalName, modalData) {
    dispatch(toggleModal(modalType, modalName, modalData));
  },
  closeAlert(modalType) {
    dispatch(toggleModal(modalType, null));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
