import { ArcElement, Plugin } from 'chart.js';
import { useTheme } from 'styled-components';
import { DoughnutWarningPluginId } from '../plugin.enum';
import { SmoothTransitionBetweenSegmentsPluginProps } from './use-smooth-transition-between-segments.props';

export const useSmoothTransitionBetweenSegmentsPlugin = ({
  transitionElement,
  main
}: SmoothTransitionBetweenSegmentsPluginProps) => {
  const theme = useTheme();

  const smoothTransitionBetweenSegmentsPlugin: Plugin<'doughnut'> = {
    id: DoughnutWarningPluginId.SmoothTransitionBetweenSegments,
    beforeDraw: function (chart) {
      const datasetMeta = chart.getDatasetMeta(0);
      const { ctx } = chart;

      datasetMeta.data.forEach((item, index) => {
        const element = item as ArcElement;
        const transitionDataValue = chart.data.datasets[0].data[transitionElement.index || 0];

        if (index === (transitionElement.index || 0) && transitionDataValue >= transitionElement.value && main.value) {
          const { x, y, startAngle, endAngle, outerRadius } = element;

          const startCoordX = x + Math.cos(startAngle) * outerRadius;
          const startCoordY = y + Math.sin(startAngle) * outerRadius;
          const endCoordX = x + Math.cos(endAngle) * outerRadius;
          const endCoordY = y + Math.sin(endAngle) * outerRadius;

          const gradientSegment = chart.ctx.createLinearGradient(startCoordX, startCoordY, endCoordX, endCoordY);

          gradientSegment.addColorStop(0.5, theme.colors[transitionElement.color || 'darkGold']);
          gradientSegment.addColorStop(1, theme.colors[main.color]);

          element.options.backgroundColor = gradientSegment;
        }
      });

      ctx.save();
    }
  };

  return { smoothTransitionBetweenSegmentsPlugin };
};
