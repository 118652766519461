import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import moment from 'moment';

export const formFieldsProtocol: FormFieldModel[] = [
  {
    name: 'isNoProtocol',
    type: FormFieldTypes.Toggle,
    defaultValue: false,
    label: 'Ich habe kein Übergabeprotokoll',
    toggleLabels: [
      {
        label: 'Ich habe kein Übergabeprotokoll',
        value: true
      },
      {
        label: 'Ich habe kein Übergabeprotokoll',
        value: false
      }
    ],
    validation: {}
  }
];

export const formFieldsDate: FormFieldModel[] = [
  {
    name: 'dateVehicleReturned',
    type: FormFieldTypes.Date,
    defaultValue: null,
    label: 'Datum an dem das Fahrzeug abgegeben wurde',
    width: '350px',
    validation: {
      required: 'Bitte auswählen'
    },
    maxDate: moment()
  }
];
