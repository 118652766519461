import React, { useMemo } from 'react';
import styles from './download-documents-component.module.scss';
import ExcelFileIcon from 'components/icons/excel-file-icon';
import PictureFileIcon from 'components/icons//picture-file-icon';
import PdfFileIcon from 'components/icons/pdf-file-icon';
import TextFileIcon from 'components/icons/text-file-icon';
import { formatDate } from 'helpers';
import { AnyObject } from 'common/interfaces';

export const getIcon = (fileName: string) => {
  const extensionArr = fileName.split('.');
  const extension = extensionArr[extensionArr.length - 1];
  switch (extension.toLocaleLowerCase()) {
    case 'xls':
    case 'xlsx':
      return <ExcelFileIcon />;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return <PictureFileIcon />;
    case 'pdf':
      return <PdfFileIcon />;
    default:
      return <TextFileIcon />;
  }
};

const File = ({ file }: AnyObject) => {
  const fileName = file.name;

  const icon = useMemo(() => getIcon(fileName), [fileName]);

  return (
    <div className={styles.file}>
      <div className={styles.icon_container}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.description}>
          <span className={styles.file_name}>{fileName}</span>
          <span className={styles.file_size}>{`${(Number(file.size) / 1024).toFixed(1)} KB • ${formatDate(
            file?.lastModifiedDate
          )}`}</span>
        </div>
      </div>
    </div>
  );
};

export default File;
