import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

const ThinArrowRight = ({ width = 26, height = 20, color: colorProps }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProps || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.36 18.9966L21.7886 12.5681M21.7886 12.5681L15.36 6.13953M21.7886 12.5681L3.78857 12.5681'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.36 18.4285L21.7886 12M21.7886 12L15.36 5.57144M21.7886 12L3.78857 12'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ThinArrowRight;
