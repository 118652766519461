import { DependencyList, useEffect, useRef } from 'react';

export const useOnUnmount = (callback: () => void, deps: DependencyList) => {
  const ref = useRef(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (ref.current) {
        callback();
      }
    };
  }, deps);
};
