import { IconProps } from 'common/interfaces';
import React from 'react';

interface ICarCarReplacementIcon extends IconProps {
  arrowColor?: string;
}

export const CarReplacementIcon = ({
  width = 22,
  height = 22,
  color = '#335566',
  arrowColor = '#FFBF00'
}: ICarCarReplacementIcon) => (
  <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.5963 14.5377L19.0307 13.1803C18.9176 13.0672 18.8045 12.8409 18.5783 12.8409L13.8274 11.8229L11.2258 10.5786C10.7733 10.3524 10.0946 10.2393 9.86837 10.2393H6.58801C6.02243 10.2393 5.23062 10.6917 4.89128 10.8048C4.55193 11.0311 3.76012 11.7098 3.42077 11.936L2.1765 12.2753C1.95027 12.3885 1.72403 12.6147 1.72403 12.8409V13.6327H1.95027C2.40273 13.6327 2.62896 13.859 2.62896 14.1983C2.62896 14.5377 2.40273 14.7639 2.06338 14.7639H1.72403L1.4978 16.0082C1.4978 16.3475 1.61092 16.5737 1.95027 16.6869L3.76012 17.0262C3.76012 15.7819 4.77816 14.7639 6.02243 14.7639C7.26671 14.7639 8.28475 15.7819 8.28475 17.0262H12.8094C12.8094 15.7819 13.8274 14.7639 15.0717 14.7639C16.316 14.7639 17.334 15.7819 17.334 17.0262H19.0307C19.3701 17.0262 19.5963 16.8 19.5963 16.4606V14.877C19.5963 14.7639 19.5963 14.6508 19.5963 14.5377Z'
      fill={color}
    />
    <path
      d='M6.02252 18.1573C6.64724 18.1573 7.15367 17.6509 7.15367 17.0262C7.15367 16.4015 6.64724 15.895 6.02252 15.895C5.39779 15.895 4.89136 16.4015 4.89136 17.0262C4.89136 17.6509 5.39779 18.1573 6.02252 18.1573Z'
      fill={color}
    />
    <path
      d='M15.0718 18.1573C15.6966 18.1573 16.203 17.6509 16.203 17.0262C16.203 16.4015 15.6966 15.895 15.0718 15.895C14.4471 15.895 13.9407 16.4015 13.9407 17.0262C13.9407 17.6509 14.4471 18.1573 15.0718 18.1573Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.2977 5.10949C21.3239 5.08576 21.3448 5.05682 21.3591 5.02454C21.3734 4.99226 21.3807 4.95734 21.3807 4.92204C21.3807 4.88673 21.3734 4.85181 21.3591 4.81953C21.3448 4.78725 21.3239 4.75831 21.2977 4.73458L18.8633 2.6604C18.8278 2.6309 18.7848 2.61206 18.7391 2.60607C18.6934 2.60008 18.6469 2.60719 18.6051 2.62657C18.5633 2.64595 18.5278 2.67681 18.5028 2.71555C18.4779 2.75429 18.4644 2.79933 18.464 2.84542V3.70236H15.752C15.6878 3.70361 15.6266 3.72965 15.5812 3.77504C15.5358 3.82043 15.5098 3.88163 15.5085 3.94581V5.8934C15.5085 5.95796 15.5342 6.01988 15.5798 6.06554C15.6255 6.11119 15.6874 6.13684 15.752 6.13684H18.4494V6.98404C18.4498 7.03014 18.4632 7.07517 18.4882 7.11392C18.5132 7.15266 18.5487 7.18352 18.5905 7.2029C18.6323 7.22228 18.6788 7.22939 18.7245 7.2234C18.7702 7.21741 18.8132 7.19857 18.8487 7.16907L21.2977 5.10949Z'
      fill={arrowColor}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3436 7.73875C12.3161 7.76112 12.2939 7.78933 12.2787 7.82134C12.2635 7.85334 12.2556 7.88833 12.2556 7.92377C12.2556 7.9592 12.2635 7.9942 12.2787 8.0262C12.2939 8.0582 12.3161 8.08642 12.3436 8.10879L14.7781 10.1878C14.814 10.2155 14.8569 10.2327 14.9019 10.2377C14.947 10.2427 14.9926 10.2352 15.0337 10.2162C15.0748 10.1971 15.11 10.1672 15.1353 10.1295C15.1606 10.0919 15.1751 10.0481 15.1773 10.0028V9.14101H17.8699C17.9344 9.14101 17.9963 9.11536 18.042 9.06971C18.0877 9.02405 18.1133 8.96213 18.1133 8.89756V6.94997C18.1133 6.88541 18.0877 6.82349 18.042 6.77783C17.9963 6.73217 17.9344 6.70653 17.8699 6.70653H15.1773V5.84959C15.1777 5.80259 15.1645 5.75649 15.1393 5.71683C15.1141 5.67717 15.0779 5.64566 15.0352 5.6261C14.9925 5.60654 14.945 5.59976 14.8985 5.60659C14.852 5.61341 14.8085 5.63355 14.7732 5.66457L12.3436 7.73875Z'
      fill={arrowColor}
    />
  </svg>
);
