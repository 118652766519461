import { useFetch } from 'hooks/use-fetch/use-fetch';
import { IPagination } from 'hooks/use-table/use-table.props';
import { useQuery } from 'react-query';
import { policyConfig } from 'request-config/policy/policy.request-config';
import { PolicyQueryKeys } from '../policy.props';
import { PolicyCompanyListResponse } from './company-list.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { getDataForTable } from 'utils/get-response-data';

export const usePolicyCompanyListQuery = (
  pagination: IPagination | {},
  {
    options
  }: QueryHookOptions<
    AxiosResponse<ResponseModel<PolicyCompanyListResponse>>,
    ResponseModel<PolicyCompanyListResponse>
  > = {}
) => {
  const { fetch } = useFetch<ResponseModel<PolicyCompanyListResponse>>({
    loadingKey: `policyCompanyList${JSON.stringify(pagination)}`,
    ...policyConfig.companyList(pagination)
  });

  const { data, ...restQueryProps } = useQuery([PolicyQueryKeys.CompanyList, pagination], () => fetch(), options);

  const getModifiedData = (): PolicyCompanyListResponse => {
    if (!data) {
      return defaultQuery;
    }

    return {
      ...data.data.data,
      items: getDataForTable(data.data.data.items)
    };
  };

  return { policyCompanyListQuery: getModifiedData(), ...restQueryProps };
};

const defaultQuery: PolicyCompanyListResponse = {
  items: [],
  totalRecord: 0,
  numberOfPages: 0
};
