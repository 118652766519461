import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { FleetGetVehicleConstQueryType } from './use-get-vehicle-costs.props';
import { ReactQueryKeys } from 'common/enums';

export const useFleetGetVehicleConstQuery = (vehicleIdProps?: string) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch<ResponseModel<FleetGetVehicleConstQueryType>>({
    loadingKey: 'fleetGetVehicleConstQuery',
    ...fleetRequestConfig.getVehicleCosts(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.FleetGetVehicleConstQuery, vehicleId], () => fetch(), {
    enabled: !!vehicleId
  });

  return { fleetGetVehicleConstQuery: data?.data.data, ...restQueryProps };
};
