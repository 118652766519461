import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

interface TextProps {
  color: string;
  bold?: boolean;
}

interface PriceProps {
  color: string;
  bold: boolean;
}

interface PackageContainerProps {
  isProfessionalOrComfortPackage: boolean;
  disableMaxWidth?: boolean;
}

export const StyledPackageContainer = styled.div<PackageContainerProps>`
  max-width: ${({ disableMaxWidth }) =>
    !disableMaxWidth &&
    css`
      maxwidth: 800px;
    `};
  display: flex;
  flex-direction: column;
  padding: 25px 45px;
  border: 1px solid
    ${({ theme, isProfessionalOrComfortPackage }) =>
      isProfessionalOrComfortPackage ? theme.colors.blackPearl : theme.colors.blackPearl30};
  border-left-width: 12px;
`;

export const StyledAddonPrice = styled(NumberFormat)<PriceProps>`
  font-size: 14px !important;
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color] || theme.colors.black};
    `};
`;

export const StyledNumberText = styled(NumberFormat)<TextProps>`
  font-size: 18px !important;
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color] || theme.colors.black};
    `};
`;
