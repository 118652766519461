import styled from 'styled-components';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const StyledDatePicker = styled(DesktopDatePicker)`
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.grey200} !important;
  border-radius: 3px;
  margin-top: 10px !important;

  & .MuiOutlinedInput-root {
    padding: 10px 15px;
    flex-direction: row-reverse;
    letter-spacing: 0.01em;

    & .MuiIconButton-root {
      margin-right: 0;
      margin-left: -22px;
    }

    & .MuiInputBase-input {
      font-size: 12px;
    }
  }
`;

export const StyledDropdown = styled.div`
  width: 70%;
  min-width: 200px;
  min-height: 50px;
  max-height: 200px;
  overflow-x: hidden;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  z-index: 10;
  cursor: default;
  position: absolute;
  top: 100px;
`;
