import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import Select from './select';
import store from '../../../store';
import { changeSearch } from '../../../actions/app_action';

const deliveryOptions = [
  { value: '', label: 'Lieferzeit' },
  { value: '1', label: 'Sofort verfügbar' },
  { value: '2', label: 'Bestellauto' }
];

const mapStateToProps = state => ({
  value: state.app.smartTable.search.predicateObject.deliveryTime || '',
  options: deliveryOptions
});

const mapDispatchToProps = dispatch => ({
  onChange(value, name) {
    const state = store.getState();
    const search = cloneDeep(state.app.smartTable.search);
    search.predicateObject.deliveryTime = value || null;
    dispatch(changeSearch(search));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);
