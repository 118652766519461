import React from 'react';
import moment from 'moment';
import { VehicleCostHeader } from '../../../../components/header/header';
import toMoney from 'utils/toMoney';
import { useFleetGetVehicleConstQuery } from 'hooks/react-query/fleet/use-get-vehicle-costs/use-get-vehicle-costs';
import { useOpenPageViaVehicleDetailPage } from 'pages/existing-vehicle-page/hooks/use-open-page';
import { PAGES } from 'common/enums';
import { CRM_VALUE } from 'pages/fahrzeugkosten-page/fahrzeugkosten-page';
import { FILTER_LABEL } from '../../../../../../../../../constants';

export const CostByYearHeader = () => {
  const { fleetGetVehicleConstQuery } = useFleetGetVehicleConstQuery();
  const { openPage } = useOpenPageViaVehicleDetailPage();

  const descriptionClickHandler = openPage({
    page: PAGES.VEHICLE_COSTS,
    carCrmName: CRM_VALUE.car,
    filterForClient: { [CRM_VALUE.rgDatum]: FILTER_LABEL.year.current },
    filterForServer: {
      [CRM_VALUE.rgDatum]: {
        startdate: moment().startOf('year').format('YYYY-MM-DD'),
        enddate: moment().endOf('year').format('YYYY-MM-DD')
      }
    }
  });

  const getTitle = () => {
    if (typeof fleetGetVehicleConstQuery?.currentYear === 'number') {
      return toMoney(Math.round(fleetGetVehicleConstQuery?.currentYear), { minimumFractionDigits: 0 });
    }

    return '-';
  };

  return (
    <VehicleCostHeader
      idPrefix='costByYear'
      title={getTitle()}
      description={`Kosten ${new Date().getFullYear()}`}
      onDescriptionClick={descriptionClickHandler}
    />
  );
};
