import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery as useUrlQuery } from 'hooks';
import { useQuery } from 'react-query';
import { fleetHistoryRequestConfig } from 'request-config/fleet-history/fleet-history.request.config';
import { HistoryItem, VehicleHistoryListResponse } from '../vehicle-history-modal/history-modal.types';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses, USER_ROLES, defaultGuid } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useTypedSelector } from 'hooks/use-typed-selector';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<VehicleHistoryListResponse>>) => {
  if (data.level === RequestStatuses.Success) {
    return getResponseFormData(data, [{ name: 'items', type: 'array' }], true) as HistoryItem[];
  }
  return [];
};

export const useVehicleHistoryList = (entityName: string) => {
  const user = useTypedSelector(state => state['app'].user);
  const query = useUrlQuery();
  const id = (query.id || user.id) as string;
  const isAvailableRole = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const { fetch } = useFetch({
    loadingKey: 'getVehicleHistoryList',
    ...fleetHistoryRequestConfig.getHistoryList({ id, entityName: entityName }),
    isShowErrorSnackbar: false
  });

  const {
    data: vehicleHistoryList,
    isLoading,
    refetch: refetchVehicleHistoryList
  } = useQuery<AxiosResponse<ResponseModel<VehicleHistoryListResponse>>, unknown, HistoryItem[]>(
    [ReactQueryKeys.GetVehicleHistoryList, id],
    () => fetch(),
    {
      select: modifyResponse,
      enabled: isAvailableRole
    }
  );

  return { vehicleHistoryList: vehicleHistoryList || [], isLoading, refetchVehicleHistoryList };
};
