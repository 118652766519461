import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { BarDiagramProps } from '../bar.props';
import { defaultBarData } from '../utils';

export const useBarData = ({ data, diagramPlugins = [], minBarColumnSize = 2 }: BarDiagramProps) => {
  const [barData, setBarData] = useState<ChartData<'bar'>>(defaultBarData);

  const theme = useTheme();
  const values = data.map(item => item.value);

  const setDefaultBarData = () => {
    setBarData(prevState => ({
      labels: data.map(({ columnName }) => columnName || ''),
      datasets: prevState.datasets.map(dataset => {
        const maxValueInData = Math.max(...values, ...diagramPlugins.map(item => item.value));
        const minBarDisplayedValue = (maxValueInData / 100) * minBarColumnSize;

        dataset.data = values.map(dataItem => {
          if (dataItem === 0) {
            return 0;
          }

          return minBarDisplayedValue > dataItem ? minBarDisplayedValue : dataItem;
        });

        const colors = data.map(item => theme.colors[item.color]);

        dataset.backgroundColor = colors;
        dataset.borderColor = colors;
        dataset.barThickness = 25;
        dataset.borderRadius = 3;

        return dataset;
      })
    }));
  };

  useEffect(() => {
    setDefaultBarData();
  }, values);

  return { barData, setBarData };
};
