import { connect } from 'react-redux';
import { defaultTo } from 'lodash';
import Breadcrumbs from './breadcrumbs-view';
import { UNAUTHORIZED_PAGES } from '../../common/enums';

const mapStateToProps = state => ({
  prevLocation: defaultTo(state.app.previousLocation, UNAUTHORIZED_PAGES.LIST)
});

export default connect(mapStateToProps, null)(Breadcrumbs);
