import { useCallback, useEffect, useRef } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UnsavedChangesState } from 'hooks/use-unsaved-changes/use-unsaved-changes-types';
import { useDispatch } from 'react-redux';
import { setUnsavedChangesState } from 'actions/app_action';

const useUnsavedChangesLink = () => {
  const dispatch = useDispatch();
  const unsavedChangesState = useTypedSelector<UnsavedChangesState | null>(state => state.app.unsavedChangesState);
  const callbackFunc = useRef(() => {});

  useEffect(() => {
    if (unsavedChangesState === UnsavedChangesState.LeavePage) {
      dispatch(setUnsavedChangesState(null));
      callbackFunc.current();
      callbackFunc.current = () => {};
    }
    if (unsavedChangesState === UnsavedChangesState.CloseModal) {
      dispatch(setUnsavedChangesState(UnsavedChangesState.Block));
      callbackFunc.current = () => {};
    }
  }, [unsavedChangesState]);

  const unsavedChangesHandler = useCallback(
    (callback: () => void) => {
      callbackFunc.current = callback;
      if (unsavedChangesState === UnsavedChangesState.Block) {
        return dispatch(setUnsavedChangesState(UnsavedChangesState.Blocked));
      }
      return callback();
    },
    [dispatch, unsavedChangesState]
  );

  return { unsavedChangesHandler };
};

export default useUnsavedChangesLink;
