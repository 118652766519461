import { useQuery } from 'react-query';
import { AnyObject } from 'common/interfaces';
import { TaskResponseItem } from 'components/aufgaben-component/task.prop';
import dashboardService from 'services/dashboard-service';
import { getDataForTable } from 'utils/get-response-data';
import { TaskRecord } from 'components/widgets/task/task/task.props';

export const useDashboardTaskQuery = (companyId: string | null) => {
  const task = useQuery(['tasks', companyId], () => dashboardService.getTasks(companyId || null, 1), {
    select: (data: AnyObject): TaskRecord[] => {
      const items =
        data?.data?.data.tasks.map((task: TaskResponseItem) => ({
          ...task.task[0],
          ...(task.fuhrpark ? task.fuhrpark[0] : {})
        })) || [];

      return getDataForTable(items);
    },
    enabled: companyId !== undefined
  });

  return task;
};
