import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setContactSetup } from '../../../../../actions/app_action';
import { USER_ROLES } from '../../../../../constants';
import { UserCardIcon } from '../../../../icons/user-card';
import { UserCardCrossedOutIcon } from '../../../../icons/user-card-crossed-out';
import {GearIcon} from '../../../../icons-new/gear';
import More, { MoreMenuItem } from '../../../../more';
import {
  useUnsubscribeDriverLicenseCheckModal
} from '../../../../../hooks/modals/driver-check/unsubscribe-driver-license-check.modal';
import {
  useBookDriverLicenseCheckModal
} from '../../../../../hooks/modals/driver-check/book-driver-license-check.modal';
import { PAGES } from '../../../../../common/enums';
import { useIsUserHaveRole } from '../../../../../hooks/use-is-user-have-role';
import { useComponent } from 'hooks/use-component/use-component';

export const DriverscheckMenu = ({ row }) => {
  const { parentcustomerid, contactid, parentcustomerid_id, emailaddress1, uds_isdrivercheckneeded } = row;
  const { getIsDisplayComponent } = useComponent();
  const isAccessExist = getIsDisplayComponent('licenseCheckMoreButton');

  const dispatch = useDispatch();

  const history = useHistory();

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  const { openModal: openUnsubscribeDriverLicenseCheckModal } = useUnsubscribeDriverLicenseCheckModal();
  const { openModal: openBookDriverLicenseCheckModal } = useBookDriverLicenseCheckModal();

  const openSettingPageViaModal = (state = {}) => {
    if (isDriver || isPoolManager) {
      return;
    }

    dispatch(
      setContactSetup({
        id: contactid,
        companyId: parentcustomerid
      })
    );

    history.push({
      pathname: `${PAGES.MY_DRIVERS_DETAILS}/`,
      search: `?id=${contactid}`,
      state: {
        tabName: 'führerschein',
        ...state
      }
    });
  };

  return (
    <div>
      {
        !isAccessExist ? null
           :
        (
          (isDriver || isPoolManager) ? null : (
            <More spinnerStyle={spinnerStyle}>
              {uds_isdrivercheckneeded ? (
                <MoreMenuItem
                  img={<UserCardCrossedOutIcon width='20' height='20' />}
                  label='Führerscheinkontrolle abbestellen'
                  onClick={() =>
                    openUnsubscribeDriverLicenseCheckModal(contactid, {
                      callback: openSettingPageViaModal
                    })
                  }
                  isLeftPositionImg
                  minWidth='296px'
                />
              ) : (
                <MoreMenuItem
                  img={<UserCardIcon />}
                  label='Führerscheinkontrolle buchen'
                  onClick={() =>
                    openBookDriverLicenseCheckModal(contactid, !!emailaddress1, { callback: openSettingPageViaModal })
                  }
                  isLeftPositionImg
                  minWidth='296px'
                />
              )}
              <MoreMenuItem
                img={<GearIcon />}
                label='Funktion anpassen'
                onClick={() =>
                  openSettingPageViaModal({
                    formName: 'Funktion',
                    formDataNames: ['uds_isdrivercheckneeded', 'uds_kontrolleur', 'new_manager_driverscheckeskalation']
                  })
                }
                isLeftPositionImg
                minWidth='296px'
              />
            </More>
          )
        )
      }
    </div>
  );
};

const spinnerStyle = {
  width: '40px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  containerHeigth: '80px',
  containerWidth: '300px'
};
