import React from 'react';
import PropTypes from 'prop-types';

export const CartIcon = ({ color = '#fff', width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.9759 6.98956L16.4398 11.6594C16.3783 11.9052 16.1325 12.0896 15.8253 12.0896H7.34578C7.1 12.0896 6.85421 11.9667 6.79277 11.7209L4.88795 7.051C4.8265 6.98956 4.8265 6.92811 4.76506 6.86667L3.84337 4.59317H2.61446C2.30723 4.59317 2 4.28594 2 3.97872C2 3.67149 2.30723 3.36426 2.61446 3.36426H4.27349C4.51928 3.36426 4.76506 3.5486 4.8265 3.73293L5.80964 6.12932L17.3614 6.19076C17.5458 6.19076 17.7301 6.31365 17.853 6.43655C17.9759 6.62088 18.0373 6.80522 17.9759 6.98956Z'
        fill={color}
      />
      <path
        d='M8.20576 16.6361C9.18989 16.6361 9.98768 15.8383 9.98768 14.8542C9.98768 13.8701 9.18989 13.0723 8.20576 13.0723C7.22162 13.0723 6.42383 13.8701 6.42383 14.8542C6.42383 15.8383 7.22162 16.6361 8.20576 16.6361Z'
        fill={color}
      />
      <path
        d='M17.1156 14.8542C17.1156 15.8373 16.3168 16.6361 15.3337 16.6361C14.3506 16.6361 13.5518 15.8373 13.5518 14.8542C13.5518 13.8711 14.3506 13.0723 15.3337 13.0723C16.3168 13.0723 17.1156 13.8711 17.1156 14.8542Z'
        fill={color}
      />
    </svg>
  );
};

CartIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
