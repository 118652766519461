export const damage = {
  bonnet: {
    name: 'Motorhaube',
    color: '#C6CACC',
    value: 2
  },
  frontRightHeadlight: {
    name: 'Scheinwerfer vorne rechts',
    color: '#ABB0B2',
    value: 1
  },
  frontLeftHeadlight: {
    name: 'Scheinwerfer vorne links',
    color: '#ABB0B2',
    value: 3
  },
  windshield: {
    name: 'Windschutzscheibe',
    color: '#ABB0B2',
    value: 4
  },
  sunroofAndGlassRoof: {
    name: 'Schiebedach / Glasdach',
    color: '#C6CACC',
    value: 5
  },
  leftExteriorMirror: {
    name: 'Außenspiegel links',
    color: '#C6CACC',
    value: 6
  },
  frontLeftSideWindow: {
    name: 'Seitenfenster vorne links',
    color: '#ABB0B2',
    value: 7
  },
  rearLeftSideWindow: {
    name: 'Seitenfenster hinten links',
    color: '#ABB0B2',
    value: 8
  },
  'rearLeftC-pillar': {
    name: 'C-Säule hinten links',
    color: '#C6CACC',
    value: 9
  },
  fenderSidePanelRearLeft: {
    name: 'Kotflügel / Seitenteil hinten links',
    color: '#E0E3E5',
    value: 10
  },
  rearLightLeft: {
    name: 'Rücklicht links',
    color: '#ABB0B2',
    value: 11
  },
  rearNumberPlate: {
    name: 'Kennzeichen hinten',
    color: '#ABB0B2',
    value: 12
  },
  trunkLidAndTailgate: {
    name: 'Kofferraumklappe / Heckklappe',
    color: '#E0E3E5',
    value: 13
  },
  rearWindow: {
    name: 'Heckscheibe',
    color: '#ABB0B2',
    value: 14
  },
  roof: {
    name: 'Dach',
    color: '#E0E3E5',
    value: 15
  },
  'c-pillarRearRight': {
    name: 'C-Säule hinten rechts',
    color: '#C6CACC',
    value: 16
  },
  sideWindowRearRight: {
    name: 'Seitenfenster hinten rechts',
    color: '#ABB0B2',
    value: 17
  },
  sideWindowFrontRight: {
    name: 'Seitenfenster vorne rechts',
    color: '#ABB0B2',
    value: 18
  },
  frontRightDoor: {
    name: 'Tür vorne rechts',
    color: '#C6CACC',
    value: 19
  },
  exteriorMirrorRight: {
    name: 'Außenspiegel rechts',
    color: '#C6CACC',
    value: 20
  },
  frontLicensePlate: {
    name: 'Kennzeichen vorne',
    color: '#ABB0B2',
    value: 21
  },
  frontBumperPanel: {
    name: 'Stoßfängerverkleidung vorne',
    color: '#C6CACC',
    value: 22
  },
  frontLeftTyre: {
    name: 'Reifen vorne links',
    color: '#ABB0B2',
    value: 23
  },
  frontLeftRim: {
    name: 'Felge vorne links',
    color: '#C6CACC',
    value: 24
  },
  fenderSidePanelFrontLeft: {
    name: 'Kontflügel / Seitenteil vorne links',
    color: '#E0E3E5',
    value: 25
  },
  frontLeftDoor: {
    name: 'Tür vorne links',
    color: '#C6CACC',
    value: 26
  },
  sillLeft: {
    name: 'Schweller links',
    color: '',
    value: 27
  },
  rearLeftDoor: {
    name: 'Tür hinten links',
    color: '#C6CACC',
    value: 28
  },
  rearLeftRim: {
    name: 'Felge hinten links',
    color: '#C6CACC',
    value: 29
  },
  rearLeftTire: {
    name: 'Reifen hinten links',
    color: '#ABB0B2',
    value: 30
  },
  rearBumperCover: {
    name: 'Stoßfängerverkleidung hinten',
    color: '#C6CACC',
    value: 31
  },
  rearLightRight: {
    name: 'Rücklicht rechts',
    color: '#ABB0B2',
    value: 32
  },
  fenderSidePanelRearRight: {
    name: 'Kotflügel / Seitenteil hinten rechts',
    color: '#E0E3E5',
    value: 33
  },
  rearRightTire: {
    name: 'Reifen hinten rechts',
    color: '#ABB0B2',
    value: 34
  },
  rearWheelRimRearRight: {
    name: 'Felge hinten rechts',
    color: '#C6CACC',
    value: 35
  },
  rearRightDoor: {
    name: 'Tür hinten rechts',
    color: '#C6CACC',
    value: 36
  },
  rightSill: {
    name: 'Schweller rechts',
    color: '',
    value: 37
  },
  frontRightFenderSidePanel: {
    name: 'Kotflügel / Seitenteil vorne rechts',
    color: '#E0E3E5',
    value: 38
  },
  frontRightTire: {
    name: 'Reifen vorne rechts',
    color: '#ABB0B2',
    value: 39
  },
  frontRightRim: {
    name: 'Felge vorne rechts',
    color: '#C6CACC',
    value: 40
  },
  other: {
    name: 'Sonstiges',
    color: '#E0E3E5',
    value: 100000041
  }
};
