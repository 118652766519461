import { crmDataTypes, RequestStatuses } from '../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useQuery } from 'react-query';
import { getEntityByFieldQuery } from 'services/crm-entity-service';
import { ConditionOperator } from 'common/enums';
import { AppPage, ResponseModel } from 'common/interfaces';
import { AxiosResponse } from 'axios';

export const useAllAppPages = () => {
  const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
    if (data.level === RequestStatuses.Success) {
      return getResponseFormData(data, [{ name: 'entityes', type: 'array' }]) as AppPage[];
    }
    return [];
  };

  const { data: appPages } = useQuery(
    [ALL_APP_PAGES_QUERY_KEY],
    () =>
      getEntityByFieldQuery({
        entityName: 'uds_appage',
        columns: ['uds_appageid', 'uds_name', 'uds_page_url'],
        conditions: [
          {
            attributeTypeCode: crmDataTypes.Picklist,
            attributeName: 'statecode',
            conditionOperator: ConditionOperator.Equal,
            value: [{ value: '0' }]
          }
        ]
      }),
    {
      select: modifyResponse
    }
  );

  return {
    appPages
  };
};

export const ALL_APP_PAGES_QUERY_KEY = 'all-app-pages-query-key';
