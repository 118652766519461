import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, Text } from 'common/common-components.styled';
import PlusIcon from 'pages/report-damage-page/icons/plus';

export const CalendarViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  position: relative;
`;

export const DayContainer = styled.div<{
  isCalendarTool?: boolean;
  height?: number;
  disabled?: boolean;
  icon?: JSX.Element;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13%;
  height: ${({ height }) => (height ? `${height}px` : '100px')};
  background-color: ${({ theme }) => theme.colors.grey25};

  ${({ disabled, isCalendarTool, icon }) =>
    !disabled &&
    !isCalendarTool &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.colors.grey100};

        // &:after {
        //   content: ${() => ''};
        //   display: inline-block;
        //   transform: scale(3);
        //   color: ${({ theme }) => theme.colors.blue};
        //   font-weight: 10;
        // }
      }
    `}

  ${({ isCalendarTool }) =>
    isCalendarTool &&
    css`
      padding: 17px 0;
      height: 70px;
    `};
`;

export const CarContainer = styled.div<{ isOddCarInList: boolean; height: number }>`
  display: flex;
  width: 17%;
  align-items: center;
  padding: 20px 0 20px 10px;
  margin-right: 10px;
  height: ${({ height }) => `${height}px`};
  ${({ isOddCarInList }) =>
    isOddCarInList &&
    css`
      background-color: ${({ theme }) => theme.colors.grey25};
    `};
`;

export const CarEventContainer = styled.div<{
  isActiveEvent: boolean;
  isRejectedEvent: boolean;
  left: number;
  width: number;
  top: number;
  height: number;
  borderColor: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background-color: ${({ theme, isActiveEvent }) =>
    isActiveEvent ? theme.colors.extraLightBlue : theme.colors.grey100};
  border-left: 5px solid ${({ borderColor }) => borderColor};
  border-radius: 5px 0 0 5px;

  ${({ isActiveEvent, theme }) =>
    isActiveEvent &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.lightBlue};
      }
    `};
  ${({ isRejectedEvent, theme }) =>
    isRejectedEvent &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.grey200};
      }
    `};
`;

export const HiddenText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ToolTipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  min-width: 380px;
`;

export const StyledSortButton = styled(Flex)`
  top: 85px;
  left: 30px;
`;

export const HoveredTooltip = styled.div<{ width: number; height: number }>`
  dispay: flex;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  z-index: 300;
  top: ${({ height }) => `${height + 5}px`};
  width: ${({ width }) => (width < 150 ? '150px' : `${width + 50}px`)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey500};
  right: ${({ width }) => (width < 150 ? `${-(150 - width) / 2}px` : '-25px')};
`;
