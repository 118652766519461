import React from 'react';
import { ComposeChildren } from 'components/compose-children/compose-children';
import { ComposeChildrenProps } from 'components/compose-children/compose-children.interface';
import { UnSaveModalRefContextContainer } from 'context/un-save-modal/un-save-modal-ref.context';
import { UnSaveModalMyRequestRefContextContainer } from 'context/un-save-modal/my-request-ref.context';
import { UnSaveModalOrdersRefContextContainer } from 'context/un-save-modal/orders-ref.context';

export const ComposedFormContextList = ({ children }: Pick<ComposeChildrenProps, 'children'>) => {
  return (
    <ComposeChildren>
      <UnSaveModalRefContextContainer />
      <UnSaveModalMyRequestRefContextContainer />
      <UnSaveModalOrdersRefContextContainer />
      {children}
    </ComposeChildren>
  );
};
