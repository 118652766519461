import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import Overlay from '../../components/overlay';
import Footnotes from '../../components/footnote';
import VehicleList from '../../components/vehicle-list';
import { withLinkedInTag } from '../../hoc/withLinkedInTag';
import VehicleListHeader from './components/vehicle-list-header';
import Sidebar from '../../components/sidebar/sidebar-view';
import ComparisonPanel from '../../components/comparison-panel/comparison-panel-redux';
import { Link } from 'react-router-dom';
import HeaderOffer from '../../components/header-offer';
import FooterOffer from '../../components/footer-offer';
import { defaultGuid, USER_ROLES } from '../../constants';
import { PAGES } from '../../common/enums';

class OfferPage extends Component {
  componentDidMount() {
    this.props.showOverlay();
  }

  render() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id') || undefined;
    const isShowNewConfigButton = [USER_ROLES.fleetManager.id, USER_ROLES.administrator.id].includes(
      this.props.user.mainRole?.id
    );

    return id === defaultGuid ? (
      <Fragment>
        <HeaderOffer />
        <div className='container'>
          <h4 style={{ margin: '30px 0' }}>Sie haben noch keine Verkaufsschance. Bitte rufen Sie Ihren Manager an.</h4>
        </div>
        <FooterOffer />
      </Fragment>
    ) : (
      <Fragment>
        <Sidebar open={this.props.sidebarOpen} onSetOpen={() => this.props.toggleSidebar(!this.props.sidebarOpen)} />
        <ComparisonPanel />
        <HeaderOffer />
        <Overlay />
        <section
          style={{
            background: 'linear-gradient(167.08deg, rgba(32, 114, 128, 0) -9.15%, rgba(32, 114, 128, 0.15) 97.48%)'
          }}
        >
          <div style={{ paddingTop: '15px' }}>
            <div className='container clearfix vehicle-list-container'>
              {isShowNewConfigButton && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                  <button className='button-mobexo button-mobexo__main'>
                    <Link to={PAGES.NEW_CONFIGURATION}>Neues Fahrzeug definieren</Link>
                  </button>
                </div>
              )}
              <VehicleListHeader />
              <VehicleList />
            </div>

            <Footnotes />
          </div>
        </section>
        <FooterOffer />
      </Fragment>
    );
  }
}

OfferPage.propTypes = {
  showOverlay: PropTypes.func.isRequired
};

export default withLinkedInTag(OfferPage);
