import React, { useContext } from 'react';
import { StyledInfoHintWithoutBackground } from 'components/info-hint/info-hint-view.styled';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { Text } from 'common/common-components.styled';

export const InfoHintLeasingContractAdvise = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const leasingContractAdvise = vehicle?.fuhrpark?.uds_leasingcontractadvise?.attributeValue;

  if (!leasingContractAdvise) {
    return null;
  }

  return (
    <StyledInfoHintWithoutBackground type='warning' iconSize={12} top='20'>
      <Text id='leasingContractAdvise' size='12'>
        {leasingContractAdvise}
      </Text>
    </StyledInfoHintWithoutBackground>
  );
};
