import { IconProps } from 'common/interfaces';
import React from 'react';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarRepairIcon = ({ width = 20, height = 18, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5834 10.8567V7.8755C17.5834 7.52003 17.5181 7.17182 17.3948 6.84537L17.1119 6.09816H18.454C18.6426 6.09816 18.7804 5.92406 18.7369 5.7427L18.5193 4.80688C18.4612 4.54572 18.2218 4.3571 17.9534 4.3571H16.5315L15.4144 2.13C15.1169 1.54239 14.5148 1.16516 13.8547 1.16516H5.63542C4.97527 1.16516 4.37315 1.53514 4.07572 2.13L2.95854 4.3571H1.53667C1.26826 4.3571 1.03612 4.54572 0.970826 4.80688L0.753194 5.7427C0.709667 5.92406 0.847503 6.09816 1.03612 6.09816H2.37818L2.09526 6.84537C1.97194 7.17182 1.90665 7.52728 1.90665 7.8755V10.2157'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M17.5834 7.87551V10.8568V13.0244H1.90665V10.2157V7.87551C1.90665 7.5273 1.97194 7.17183 2.09526 6.84538L2.37818 6.09818H1.03612C0.847503 6.09818 0.709667 5.92407 0.753194 5.74271L0.970826 4.80689C1.03612 4.54573 1.26826 4.35712 1.53667 4.35712H2.95854L4.75679 5.50499H14.7028L16.5315 4.35712H17.9534C18.2218 4.35712 18.4612 4.54573 18.5193 4.80689L18.7369 5.74271C18.7804 5.92407 18.6426 6.09818 18.454 6.09818H17.1119L17.3948 6.84538C17.5182 7.17183 17.5834 7.52004 17.5834 7.87551Z'
          fill={fill}
        />
      )}
      <path
        d='M17.5753 10.0275V7.87557C17.5753 7.5201 17.51 7.17188 17.3867 6.84543L17.1037 6.09822H18.4458C18.6344 6.09822 18.7723 5.92411 18.7287 5.74275L18.5111 4.80692C18.4531 4.54576 18.2137 4.35715 17.9453 4.35715H16.5234L15.4062 2.13002C15.1088 1.54241 14.5066 1.16518 13.8465 1.16518H5.62716C4.967 1.16518 4.36488 1.53515 4.06745 2.13002L2.95026 4.35715H1.52838C1.25996 4.35715 1.02782 4.54576 0.962527 4.80692L0.744893 5.74275C0.701366 5.92411 0.839203 6.09822 1.02782 6.09822H2.3699L2.08697 6.84543C1.96364 7.17188 1.89836 7.52735 1.89836 7.87557V10.0275'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.64648 8.70985H6.06223L5.64872 7.89009'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8347 8.70985H13.4189L13.8325 7.89009'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.41895 10.7411H12.0618'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.80762 5.51787H14.6737'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.40967 15.384H14.0715'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.40234 13.0625H14.0642'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9482 13.3527C14.3037 12.3371 15.2758 11.6116 16.4148 11.6116C17.4231 11.6116 18.3009 12.221 18.7362 13.0625H16.9951C16.7702 13.2874 16.6397 13.418 16.4148 13.6429V14.8036C16.6397 15.0285 16.7702 15.1591 16.9951 15.3839H18.7362C18.446 16.2545 17.4231 16.8348 16.4148 16.8348C15.2758 16.8348 14.311 16.1094 13.9555 15.0938'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M5.53307 15.0938C5.1776 16.1094 4.2055 16.8348 3.06655 16.8348C2.04367 16.8348 1.0353 16.2545 0.745117 15.3839H2.77637L3.35673 14.8036V13.6429L2.77637 13.0625H0.745117C1.18039 12.221 2.05818 11.6116 3.06655 11.6116C4.2055 11.6116 5.17035 12.3371 5.52582 13.3527H13.9482C14.3037 12.3371 15.2758 11.6116 16.4148 11.6116C17.4231 11.6116 18.3009 12.221 18.7362 13.0625H16.9951L16.4148 13.6429V14.8036L16.9951 15.3839H18.7362C18.446 16.2545 17.4231 16.8348 16.4148 16.8348C15.2758 16.8348 14.311 16.1094 13.9555 15.0938H5.53307Z'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M5.53307 15.0938C5.1776 16.1094 4.2055 16.8348 3.06655 16.8348C2.04367 16.8348 1.0353 16.2545 0.745117 15.3839H2.77637C3.00126 15.1591 3.13184 15.0285 3.35673 14.8036V13.6429C3.13184 13.418 3.00126 13.2874 2.77637 13.0625H0.745117C1.18039 12.221 2.05818 11.6116 3.06655 11.6116C4.2055 11.6116 5.17035 12.3371 5.52582 13.3527'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9482 13.3527C14.3037 12.3371 15.2758 11.6116 16.4148 11.6116C17.4231 11.6116 18.3009 12.221 18.7362 13.0625H16.9951C16.7702 13.2874 16.6397 13.418 16.4148 13.6429V14.8036C16.6397 15.0285 16.7702 15.1591 16.9951 15.3839H18.7362C18.446 16.2545 17.4231 16.8348 16.4148 16.8348C15.2758 16.8348 14.311 16.1094 13.9555 15.0938'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
