import styled from 'styled-components';
import { IStyledTimeItem } from './time-drop-down-item.props';

export const StyledTimeItem = styled.button<IStyledTimeItem>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, isSelected }: any) => (isSelected ? theme.colors.black : theme.colors.grey600)};
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  padding: 5px 10px;
`;
