import React from 'react';
import { Text, Button, Flex } from 'common/common-components.styled';
import { NoReplacementProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';

const NoReplacementVehicle = ({ onSubmit, onCancel, plateNumber }: NoReplacementProps) => {
  return (
    <Flex direction='column'>
      <Text color='black'>{`Bist du sicher, dass du kein Ersatzfahrzeug für ${plateNumber} brauchst?`}</Text>
      <Text size='12' color='grey500' top='5'>
        Du kannst deine Wahl jederzeit in der Aufgabe oder auf der Fahrzeugseite ändern.
      </Text>
      <Flex justify='flex-end' top='30'>
        <Button secondary right='10' onClick={onCancel}>Abbrechen</Button>
        <Button onClick={onSubmit}>Bestätigen</Button>
      </Flex>
    </Flex>
  );
};

export default NoReplacementVehicle;