import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './tooltip-hint.module.scss';
import classNames from 'classnames';

const TooltipHint = ({
  children,
  text,
  position,
  tooltipStyles = {},
  styles = {},
  active = true,
  mouseEnterCallback,
  className
}) => {
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [isShowTip, setIsShowTip] = useState(false);
  const [style, setStyle] = useState({});
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => {
    const handleScroll = () => {
      setIsShowTip(false);
      setStyle({});
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isShowTip) {
      const containerPosition = containerRef.current.getBoundingClientRect();
      const tooltipPosition = active ? tooltipRef.current.getBoundingClientRect() : null;
      let style = { ...tooltipStyles, opacity: '1' };
      switch (position) {
        case 'bottom':
          style = {
            ...style,
            top: `${containerPosition.bottom + 5}px`,
            left: `${containerPosition.left}px`
          };
          break;
        case 'right':
          style = {
            ...style,
            top: `${containerPosition.top}px`,
            left: `${containerPosition.right + 5}px`
          };
          break;
        case 'top':
          style = {
            ...style,
            top: `${containerPosition.top - tooltipPosition.height - 5}px`,
            left: `${containerPosition.left}px`
          };
          break;
        case 'left':
          style = {
            ...style,
            top: `${containerPosition.top}px`,
            left: `${containerPosition.left - tooltipPosition.width - 5}px`
          };
          break;
        case 'center':
          style = {
            ...style,
            top: `${containerPosition.top}px`,
            left: `20%`
          };
          break;
      }
      setStyle(style);
    }
  }, [isShowTip]);

  const onMouseEnter = () => {
    if (typeof mouseEnterCallback === 'function') {
      return mouseEnterCallback({ containerRef, setIsShowTip, setTimeoutId });
    }

    timeoutId && clearTimeout(timeoutId);
    setIsShowTip(true);
  };

  const onMouseLeave = () => {
    if (typeof mouseEnterCallback === 'function') {
      setIsShowTip(false);
      setStyle({});
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      return;
    }

    const timeout = setTimeout(() => {
      setIsShowTip(false);
      setStyle({});
    }, 200);
    setTimeoutId(timeout);
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={styles}
      className={classNames(classes.tooltip_container, className)}
    >
      {children}
      {isShowTip && active && (
        <span ref={tooltipRef} style={style} className={classes.tooltip}>
          {text}
        </span>
      )}
    </div>
  );
};

TooltipHint.propTypes = {
  children: PropTypes.any.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'center']).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.any]).isRequired,
  styles: PropTypes.object,
  tooltipStyles: PropTypes.object,
  active: PropTypes.bool,
  mouseEnterCallback: PropTypes.func,
  className: PropTypes.string
};

export default TooltipHint;
