import { useHistory, useLocation } from 'react-router-dom';
import { PushParamsType } from 'common/interfaces';
import { CarConfiguratorUrlParams, ConfiguratorState } from '../car-configurator.types';
import { getConfiguratorLink } from '../utils';
import useUrlParams from 'pages/car-configurator/hooks/use-url-params';

interface ConfiguratorPushParams {
  urlParams: CarConfiguratorUrlParams;
  stateParam?: ConfiguratorState;
}

const useConfiguratorLink = () => {
  const history = useHistory();
  const { state } = useLocation<ConfiguratorState | undefined>();
  const prevUrlParams = useUrlParams();

  const getConfiguratorPushParams = (params: ConfiguratorPushParams): PushParamsType<ConfiguratorState | undefined> => {
    const { urlParams, stateParam } = params;
    const configuratorState: ConfiguratorState = {
      configurationData: stateParam?.configurationData || state?.configurationData || {},
      prevUrlParams
    };

    return {
      pathname: getConfiguratorLink(urlParams),
      state: configuratorState
    };
  };

  const goToConfiguratorPage = (params: ConfiguratorPushParams) => {
    history.push(getConfiguratorPushParams(params));
  };

  return { getConfiguratorPushParams, goToConfiguratorPage };
};

export default useConfiguratorLink;
