import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ChassisNumberIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1' y='1' width='18' height='18' rx='1' fill={fill} />}
      <path
        d='M3.89285 13.1141V6.68549H7.10714M3.89285 9.54263H6.57555'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.44049 6.68549V13.1141' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.25 13.1141V6.68549L16.1071 13.1141V6.68549'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6922 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7951 18.7951 18.6422 18.891 18.454C19 18.2401 19 17.9601 19 17.4V2.6C19 2.03995 19 1.75992 18.891 1.54601C18.7951 1.35785 18.6422 1.20487 18.454 1.10899C18.2401 1 17.9601 1 17.4 1H2.6C2.03995 1 1.75992 1 1.54601 1.10899C1.35785 1.20487 1.20487 1.35785 1.10899 1.54601C1 1.75992 1 2.03995 1 2.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H12.7461'
        stroke={color}
        strokeLinecap='round'
      />
    </svg>
  );
};
