import { Dispatch, SetStateAction, useEffect } from 'react';
import { FilterDataParams } from 'request-config/fleet/fleet.interface';

export const useOnCloseFilter = (isOpen: boolean, setFilterDataParams: Dispatch<SetStateAction<FilterDataParams>>) => {
  useEffect(() => {
    if (!isOpen) {
      setFilterDataParams({ columnName: null, searchValue: null });
    }
  }, [isOpen]);
};
