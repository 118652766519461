import React from 'react';
import classes from './inspection-dates.module.scss';
import Button from 'components/button';
import { TaskInspectionDatesActionButtonProps } from './inspection-dates.props';
import { useCompleteTaskModal } from 'hooks/modals/task/complete-task/use-complete-task.modal';
import { useInspectionDatesHistoryGetByIdQuery } from 'hooks/react-query/inspection-dates/get-history-by-task-id/get-history-by-task-id.query';
import { ProtocolCodeValue } from 'hooks/react-query/inspection-dates/get-history-by-task-id/get-history-by-task-id.props';
import { StatusId } from 'components/aufgaben-component/task.prop';
import { isEmpty } from 'lodash';
import { SkeletonComponent } from 'components/skeleton/skeleton';

export const TaskInspectionDatesActionButton = ({
  idButtonPrefix,
  rowData,
  openReportModal
}: TaskInspectionDatesActionButtonProps) => {
  const { openCompleteTaskModal } = useCompleteTaskModal({
    regardingobjectid: rowData?.regardingobjectid?.attributeValue?.id,
    activityid: rowData?.activityid?.attributeValue,
    uds_aufgabentyp_fuhrpark: rowData?.uds_aufgabentyp_fuhrpark?.attributeValue?.value
  });

  const { inspectionDatesHistoryGetByIdQuery, isFetching } = useInspectionDatesHistoryGetByIdQuery(
    rowData?.activityid?.attributeValue
  );

  const portalStatus = rowData?.uds_b2c_portal_status?.attributeValue?.value;
  const availableForStatus = portalStatus && [StatusId.Open, StatusId.InProgress].includes(portalStatus);

  if (isFetching) {
    return <SkeletonComponent className={classes.button} width='170px' height='35px' />;
  }

  if (isEmpty(inspectionDatesHistoryGetByIdQuery) || !portalStatus || !availableForStatus) {
    return null;
  }

  if (inspectionDatesHistoryGetByIdQuery.uds_protocolcode?.value === ProtocolCodeValue.Yes) {
    return (
      <div className={classes.button_container}>
        <Button id={idButtonPrefix + 'UploadTestReport'} className={classes.button} onClick={openReportModal}>
          Prüfbericht hochladen
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.button_container}>
      <Button id={idButtonPrefix + 'CompleteTask'} className={classes.button} onClick={openCompleteTaskModal}>
        Aufgabe erledigen
      </Button>
    </div>
  );
};
