import React from 'react';
import { DriverDashboardDetailItemProps } from '../../../../dashboard-component-chart/detail-item/detail-item.props';
import { NotLeasingServicesDetailInvoiceRecipient } from './components/detail-item/invoice-recipient';

export const notLeasingServiceDetailsList: DriverDashboardDetailItemProps[] = [
  { title: 'Kostenvoranschläge', description: 'fuhrpark@mobexo.de', type: 'email' },
  {
    title: 'Rechnungsempfänger',
    description: <NotLeasingServicesDetailInvoiceRecipient />
  },
  { title: 'Rechnungsversand', description: 'rechnungen-fuhrpark@mobexo.de', type: 'email' }
];
