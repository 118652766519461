import { AxiosRequestConfig } from 'axios';

class VehicleDeliveryRequestConfig {
  getVehicleDeliveryByVehicleOfferId = (vehicleOfferId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzeugauslieferung/getByVehicleOfferId',
    data: { vehicleOfferId }
  });
}

export const vehicleDeliveryRequestConfig = new VehicleDeliveryRequestConfig();
