import React, { isValidElement } from 'react';
import { FormInfoItemDefaultProps } from './default.props';
import { Divider, Flex, Text } from 'common/common-components.styled';
import styled from 'styled-components';
import { FormInfoItemDefaultLabel } from './components/label';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import moment from 'moment';
import { replaceGermanWordsToEnglish } from 'utils/replace-german-words-to-english';
import useWindowSize from 'hooks/use-window-size';
import { TooltipComponent } from 'components/tooltip-component/tooltip-component';

export const StyledDivider = styled(Divider)<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
`;

export const FormInfoItemDefault = (props: FormInfoItemDefaultProps) => {
  const { label, value, valueStyle = {}, className, valueType, tooltip, isShowDivider, containerProps } = props;
  const { width: valueWidth, ...restValueStyle } = valueStyle;

  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  const getValue = () => {
    if (isValidElement(value)) {
      return value;
    }

    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nein';
    }

    if (typeof value !== 'number' && !value) {
      return '-';
    }

    if (valueType === 'date' && typeof value === 'string') {
      const date = moment(value);
      return date.isUTC() ? date.utc().format('DD.MM.YYYY') : date.format('DD.MM.YYYY');
    }

    if (valueType === 'number') {
      return Number(value)?.toLocaleString('de-DE');
    }

    return value || '-';
  };

  return (
    <Flex direction='column' width='100%' height='100%' {...containerProps} className={className}>
      <Flex align='center' gap='0 10px' width='100%' height='100%' minHeight={isMobile ? '60px' : '80px'} padding='5px'>
        <FormInfoItemDefaultLabel {...props} />
        <Flex align='center' width={valueWidth || '65%'}>
          <Text
            id={`formValueOf${replaceGermanWordsToEnglish(label.split(' ').slice(0, 4).join(' '))}`}
            color='black'
            size='18'
            bold
            wordBreak='break-word'
            width={isValidElement(getValue()) ? '100%' : 'auto'}
            right='10'
            {...restValueStyle}
          >
            {getValue()}
          </Text>

          {tooltip && (
            <TooltipComponent title={tooltip}>
              <Flex align='center'>
                <InfoHintIcon width={16} />
              </Flex>
            </TooltipComponent>
          )}
        </Flex>
      </Flex>

      {isShowDivider && <Divider />}
    </Flex>
  );
};
