import { AnyObject } from 'chart.js/dist/types/basic';
import { useComponent } from 'hooks/use-component/use-component';
import { useMemo } from 'react';

export const useLeasingWidgetsLayout = (car: AnyObject) => {
  const { widgetSetings } = car;
  const { getIsDisplayComponent } = useComponent();

  const showVehicleReturnWidget = widgetSetings.showVrWidget && getIsDisplayComponent('leaseReturnWidget');
  const showReplacementVehicleOrderingWidget =
    widgetSetings.showRvoWidget && getIsDisplayComponent('replacementPurchase');
  const showCheckListWidget = widgetSetings.showChecklistWidget && getIsDisplayComponent('vehicleReturnChecklist');
  const showLeaseExtensionDecisionWidget =
    widgetSetings.showLvaWidget && getIsDisplayComponent('leaseExtensionDecision');
  const showVehicleInitialReviewWidget =
    widgetSetings.showVirWidget && getIsDisplayComponent('commissionAppraisalWidget');

  const amountOfWidgetsToShow = useMemo(() => {
    const widgetsRenderStateArray = [
      showVehicleReturnWidget,
      showReplacementVehicleOrderingWidget,
      showCheckListWidget,
      showLeaseExtensionDecisionWidget,
      showVehicleInitialReviewWidget
    ];

    return widgetsRenderStateArray.filter(widget => widget).length;
  }, [
    showVehicleReturnWidget,
    showReplacementVehicleOrderingWidget,
    showCheckListWidget,
    showLeaseExtensionDecisionWidget,
    showVehicleInitialReviewWidget
  ]);

  return {
    showVehicleReturnWidget,
    showReplacementVehicleOrderingWidget,
    showCheckListWidget,
    showLeaseExtensionDecisionWidget,
    showVehicleInitialReviewWidget,
    amountOfWidgetsToShow
  };
};
