import React from 'react';
import TooltipHint from '../../../../tooltip-hint/tooltip-hint';
import ProgressBar from 'react-customizable-progressbar';
import classes from './small-diagram.module.scss';

export const SmallDiagram = ({ row, isDiagramVisible }) => {
  const { incompleteCount, percent } = row?.incompleteFieldsPercent;

  return (
    <div style={{ opacity: percent === 100 ? 0 : 1 }}>
      <div style={{ opacity: isDiagramVisible ? 1 : 0 }}>
        <TooltipHint text={`${incompleteCount} fehlende Daten`} position='bottom'>
          <ProgressBar
            className={classes.progress_bar}
            {...progressBarSetup}
            strokeColor={incompleteCount < 3 ? '#FF9700' : '#BF0040'}
            progress={percent ? percent : 10}
          />
        </TooltipHint>
      </div>
    </div>
  );
};

const progressBarSetup = {
  radius: 12,
  strokeWidth: 2,
  trackStrokeWidth: 1,
  trackStrokeColor: '#f5f5f5',
  transition: '0.3s ease',
  initialAnimation: true
};
