import React from 'react';

function SigningExternalPageStepperIcon({ step = 0, fill = '#ACD2E6' }) {
  const getIcon = () => {
    switch (step) {
      case 0:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1736_34490)'>
              <circle cx='10' cy='10' r='8.85' stroke={fill} strokeWidth='2.3' />
              <path
                d='M11.5303 15H9.41797V9.2168C9.41797 9.05729 9.42025 8.86133 9.4248 8.62891C9.42936 8.39193 9.4362 8.15039 9.44531 7.9043C9.45443 7.65365 9.46354 7.42806 9.47266 7.22754C9.42253 7.28678 9.31999 7.38932 9.16504 7.53516C9.01465 7.67643 8.87337 7.80404 8.74121 7.91797L7.59277 8.84082L6.57422 7.56934L9.79395 5.00586H11.5303V15Z'
                fill={fill}
              />
            </g>
            <defs>
              <clipPath id='clip0_1736_34490'>
                <rect width='20' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
        );
      case 1:
        return (
          <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1736_34495)'>
              <circle cx='10.5' cy='10' r='8.85' stroke={fill} strokeWidth='2.3' />
              <path
                d='M13.7949 15H6.80859V13.5303L9.31738 10.9941C9.82324 10.4746 10.2288 10.0417 10.5342 9.69531C10.8441 9.3444 11.0674 9.02539 11.2041 8.73828C11.3454 8.45117 11.416 8.14355 11.416 7.81543C11.416 7.41895 11.3044 7.12272 11.0811 6.92676C10.8623 6.72624 10.5684 6.62598 10.1992 6.62598C9.81185 6.62598 9.43587 6.71484 9.07129 6.89258C8.70671 7.07031 8.32617 7.32324 7.92969 7.65137L6.78125 6.29102C7.06836 6.04492 7.37142 5.8125 7.69043 5.59375C8.014 5.375 8.3877 5.19954 8.81152 5.06738C9.23991 4.93066 9.7526 4.8623 10.3496 4.8623C11.0059 4.8623 11.5687 4.98079 12.0381 5.21777C12.512 5.45475 12.8766 5.77832 13.1318 6.18848C13.387 6.59408 13.5146 7.05436 13.5146 7.56934C13.5146 8.12077 13.4053 8.62435 13.1865 9.08008C12.9678 9.53581 12.6488 9.98698 12.2295 10.4336C11.8148 10.8802 11.3135 11.3747 10.7256 11.917L9.44043 13.127V13.2227H13.7949V15Z'
                fill={fill}
              />
            </g>
            <defs>
              <clipPath id='clip0_1736_34495'>
                <rect width='20' height='20' fill='white' transform='translate(0.5)' />
              </clipPath>
            </defs>
          </svg>
        );
      case 2:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1736_34500)'>
              <circle cx='10' cy='10' r='8.85' stroke={fill} strokeWidth='2.3' />
              <path
                d='M12.9053 7.24121C12.9053 7.7015 12.8096 8.10026 12.6182 8.4375C12.4268 8.77474 12.167 9.05046 11.8389 9.26465C11.5153 9.47884 11.1507 9.63607 10.7451 9.73633V9.77734C11.5472 9.8776 12.1556 10.1237 12.5703 10.5156C12.9896 10.9076 13.1992 11.4316 13.1992 12.0879C13.1992 12.6712 13.0557 13.193 12.7686 13.6533C12.486 14.1136 12.0485 14.4759 11.4561 14.7402C10.8636 15.0046 10.1003 15.1367 9.16602 15.1367C8.61458 15.1367 8.09961 15.0911 7.62109 15C7.14714 14.9134 6.70052 14.779 6.28125 14.5967V12.7988C6.70964 13.0176 7.15853 13.1839 7.62793 13.2979C8.09733 13.4072 8.53483 13.4619 8.94043 13.4619C9.69694 13.4619 10.2256 13.332 10.5264 13.0723C10.8317 12.8079 10.9844 12.4388 10.9844 11.9648C10.9844 11.6868 10.9137 11.4521 10.7725 11.2607C10.6312 11.0693 10.3851 10.9235 10.0342 10.8232C9.68783 10.723 9.20247 10.6729 8.57812 10.6729H7.81934V9.05273H8.5918C9.20703 9.05273 9.67415 8.99577 9.99316 8.88184C10.3167 8.76335 10.5355 8.60384 10.6494 8.40332C10.7679 8.19824 10.8271 7.96582 10.8271 7.70605C10.8271 7.35059 10.7178 7.07259 10.499 6.87207C10.2803 6.67155 9.91569 6.57129 9.40527 6.57129C9.08626 6.57129 8.7946 6.6123 8.53027 6.69434C8.27051 6.77181 8.03581 6.86751 7.82617 6.98145C7.61654 7.09082 7.43197 7.19792 7.27246 7.30273L6.29492 5.84668C6.68685 5.56413 7.14486 5.32943 7.66895 5.14258C8.19759 4.95573 8.8265 4.8623 9.55566 4.8623C10.5856 4.8623 11.4014 5.06966 12.0029 5.48438C12.6045 5.89909 12.9053 6.4847 12.9053 7.24121Z'
                fill={fill}
              />
            </g>
            <defs>
              <clipPath id='clip0_1736_34500'>
                <rect width='20' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
        );
      case 3:
        return (
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_1522_30016)'>
              <circle cx='9.99023' cy='10' r='8.85' stroke={fill} strokeWidth='2.3' />
              <path
                d='M13.5098 12.9287H12.3066V15H10.2422V12.9287H5.97656V11.459L10.3584 5.00586H12.3066V11.2881H13.5098V12.9287ZM10.2422 11.2881V9.59277C10.2422 9.43327 10.2445 9.24414 10.249 9.02539C10.2581 8.80664 10.2673 8.58789 10.2764 8.36914C10.2855 8.15039 10.2946 7.95671 10.3037 7.78809C10.3174 7.61491 10.3265 7.49414 10.3311 7.42578H10.2764C10.1898 7.61263 10.0986 7.79492 10.0029 7.97266C9.90723 8.14583 9.79557 8.32812 9.66797 8.51953L7.83594 11.2881H10.2422Z'
                fill={fill}
              />
            </g>
            <defs>
              <clipPath id='clip0_1522_30016'>
                <rect width='20' height='20' fill='white' transform='translate(-0.00976562)' />
              </clipPath>
            </defs>
          </svg>
        );
      default:
        null;
    }
  };

  return <>{getIcon()}</>;
}

export default SigningExternalPageStepperIcon;
