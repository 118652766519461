import { cloneDeep } from 'lodash';
import {
  CHANGE_PAGE,
  CHANGE_SEARCH,
  CHANGE_SORT,
  SET_ITEM,
  SET_PRICE_ITEMS,
  SET_LIST_ID,
  SET_USER,
  TOGGLE_MODAL,
  SET_VEHICLE_MODELS,
  TOGGLE_SIDEBAR,
  RESET_FILTERS,
  PREVIOUS_LOCATION_CHANGE,
  TOGGLE_MENU,
  DELETE_FORM,
  SET_FORM,
  SET_FAHRZEUGKOSTEN,
  SET_CAR_POLICIES,
  SET_CUSTOM_URL_TREE,
  SET_UPLOAD_DOCUMENTS,
  SET_SIDE_MENU_OPEN,
  SET_CONTACT_SETUP,
  DRIVER_LICENSE_TRIGGER,
  LICENSE_PLATE,
  REFRESH_KEY,
  IS_SITE_AVAILABLE,
  SNACKBAR,
  DATE_IMPORT_FILES,
  SET_SUCCESS,
  SHOW_DESCRIPTION_TRIGGER,
  VEHILE_LIST_QUERY_KEY,
  TOGGLE_CHATBOT,
  SET_OPEN_VIDEO_TUTORIALS,
  SET_SNACKBAR_COMPONENT,
  TOGGLE_GREETING_SIDEBAR,
  TOGGLE_SIDEBAR_TYPE,
  SET_DASHBOARD_TOUR_IS_STARTED,
  SET_SHOW_GUIDED_TOUR_FIRST,
  SET_IS_PACKAGE_EXIST,
  SET_IS_PACKAGES_FETCHED,
  SAVE_FORM_VALUES,
  IS_SET_FORM_SAVED_VALUES,
  IS_FORM_VALID,
  SET_LOGIN_COUNTER,
  TOGGLE_AFTER_TOUR_POP_UP,
  SET_INDICATOR,
  SET_START_LOADING,
  SET_END_LOADING,
  SET_POOL_CALENDAR_EVENT_TOOLTIP,
  SET_SHOW_EXPIRED_TRIAL_MODAL,
  SET_SIDE_MENU,
  SET_UNSAVED_MODAL_TYPE,
  SET_UNSAVED_CHANGES_STATE,
  SET_FORM_INFO,
  SET_HIGHLIGHTED_FORM,
  SET_ROLE_SWITCHED
} from '../actions/app_action';
import { SnackbarComponentTypes } from '../components/snackbar-component/snackbar-component.props';
import { EMTY_FILTER_STATE } from '../pages/fahrzeuge-der-car-policy-page/fahrzeuge-der-car-policy-page';

const defaultPagination = {
  start: 1,
  number: 30
};

const defaultSnackbar = {
  isOpen: false,
  message: '',
  type: '',
  time: null,
  onClose: null
};

const defaultSnackbarComponent = {
  open: false,
  component: null,
  type: SnackbarComponentTypes.Success
};

const INITIAL_STATE = {
  previousLocation: null,
  user: {
    id: null,
    name: null,
    email: null,
    roles: [],
    mainRole: null,
    companyId: null,
    childCompanies: [],
    appPages: [],
    avatar: null,
    driversCheckFunction: {
      isManager: false,
      isController: false
    }
  },
  customUrlTree: null,
  listSidebarOpen: false,
  modals: {},
  menu: {
    params: null,
    component: null
  },
  vehicleModels: [],
  listId: null,
  sideMenuOpen: false,
  forms: {},
  isFormsEmpty: true,
  isFormsDirty: false,
  item: null,
  priceItems: [],
  smartTable: {
    pagination: defaultPagination,
    sort: {
      predicate: 'createdon',
      reverse: true
    },
    search: { ...EMTY_FILTER_STATE }
  },
  fahrzeugkosten: '',
  carPolicies: '',
  uploadDocuments: [],
  contactSetup: {
    id: '',
    companyId: '',
    email: '',
    isServiceBooked: false,
    licenseExpirationDate: {
      checkbox: null,
      date: null
    }
  },
  driverLicenseTrigger: true,
  licensePlate: {
    fullValue: '',
    region: '',
    letters: '',
    numbers: ''
  },
  pagesRefreshKey: {
    myHarmDetailsPage: 0
  },
  isSiteAvailable: true,
  isOpenVideoTutorials: false,
  professionalPackagePrice: 0,
  snackbar: defaultSnackbar,
  snackbarComponent: defaultSnackbarComponent,
  dateImportFiles: {
    fromServer: [],
    local: []
  },
  vehicleListQueryKey: {
    tableParams: {},
    basketId: '',
    isMitarbeiterkonfigurationenPage: false,
    isMeineKonfigurationenPage: false,
    isReferenzfahrzeugePage: false,
    configurationType: null
  },
  success: false,
  showDescription: true,
  isOpenChatBot: false,
  isOpenHelpWindow: false,
  sidebarType: null,
  dashboardTourIsStarted: false,
  showMeetingBookingModal: false,
  saveFormValues: {},
  isFormsValid: false,
  isSetFormFromSavedValues: false,
  showAfterTourPopUp: false,
  showGuidedTourFirst: true,
  indicator: null,
  loginCounter: 0,
  loading: {
    state: {},
    isExtendedHeight: false
  },
  poolCalendarEventTooltip: null,
  showExpiredTrialModal: false,
  sideMenu: { isMinimized: false },
  unsavedModalType: null,
  unsavedChangesState: null,
  formInfo: { opened: '' },
  highlightedForm: { fields: [] },
  roleSwitched: false
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PREVIOUS_LOCATION_CHANGE:
      return {
        ...state,
        previousLocation: action.payload
      };

    case SET_VEHICLE_MODELS:
      return {
        ...state,
        vehicleModels: action.payload
      };

    case TOGGLE_SIDEBAR:
      return {
        ...state,
        listSidebarOpen: action.payload
      };

    case RESET_FILTERS:
      return {
        ...state,
        vehicleModels: [],
        smartTable: {
          ...state.smartTable,
          pagination: defaultPagination,
          search: EMTY_FILTER_STATE
        }
      };

    case CHANGE_SEARCH:
      return {
        ...state,
        smartTable: {
          ...state.smartTable,
          pagination: defaultPagination,
          search: action.payload
        }
      };

    case CHANGE_SORT:
      return {
        ...state,
        smartTable: {
          ...state.smartTable,
          sort: action.payload
        }
      };

    case CHANGE_PAGE:
      return {
        ...state,
        smartTable: {
          ...state.smartTable,
          pagination: action.payload
        }
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.name]: {
            type: action.payload.modalType,
            params: action.payload.params
          }
        }
      };

    case TOGGLE_MENU:
      return {
        ...state,
        menu: {
          params: action.payload.params,
          component: action.payload.component
        }
      };

    case SET_USER:
      return {
        ...state,
        user: action.payload
      };

    case SET_ITEM:
      return {
        ...state,
        item: action.payload
      };

    case SET_PRICE_ITEMS:
      return {
        ...state,
        priceItems: action.payload
      };

    case SET_LIST_ID:
      return {
        ...state,
        listId: action.payload
      };

    case SET_FORM:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.name]: action.payload.form
        }
      };
    case IS_SET_FORM_SAVED_VALUES:
      return {
        ...state,
        isSetFormFromSavedValues: action.payload
      };
    case SAVE_FORM_VALUES:
      return {
        ...state,
        saveFormValues: action.payload
      };
    case DELETE_FORM:
      const newForms = { ...state.forms };
      delete newForms[action.payload];
      return {
        ...state,
        forms: newForms
      };

    case SET_FAHRZEUGKOSTEN:
      return {
        ...state,
        fahrzeugkosten: action.payload
      };

    case SET_CAR_POLICIES:
      return {
        ...state,
        carPolicies: action.payload
      };

    case SET_CUSTOM_URL_TREE:
      return {
        ...state,
        customUrlTree: action.payload
      };

    case SET_UPLOAD_DOCUMENTS:
      return {
        ...state,
        uploadDocuments: action.payload
      };

    case SET_SIDE_MENU_OPEN:
      return {
        ...state,
        sideMenuOpen: action.payload
      };

    case SET_CONTACT_SETUP:
      return {
        ...state,
        contactSetup: action.payload
      };

    case DRIVER_LICENSE_TRIGGER:
      return {
        ...state,
        driverLicenseTrigger: action.payload
      };

    case LICENSE_PLATE:
      return {
        ...state,
        licensePlate: action.payload
      };

    case REFRESH_KEY:
      return {
        ...state,
        pagesRefreshKey: action.payload
      };

    case IS_SITE_AVAILABLE:
      return {
        ...state,
        isSiteAvailable: action.payload
      };

    case SNACKBAR:
      return {
        ...state,
        snackbar: action.payload
      };

    case DATE_IMPORT_FILES:
      return {
        ...state,
        dateImportFiles: {
          fromServer: state.dateImportFiles.fromServer,
          local: state.dateImportFiles.local,
          ...action.payload
        }
      };

    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload
      };

    case SHOW_DESCRIPTION_TRIGGER:
      return {
        ...state,
        showDescription: action.payload
      };

    case VEHILE_LIST_QUERY_KEY:
      return {
        ...state,
        vehicleListQueryKey: action.payload
      };

    case TOGGLE_CHATBOT:
      return {
        ...state,
        isOpenChatBot: action.payload
      };

    case TOGGLE_GREETING_SIDEBAR:
      return {
        ...state,
        isOpenHelpWindow: action.payload
      };

    case SET_OPEN_VIDEO_TUTORIALS:
      return {
        ...state,
        isOpenVideoTutorials: action.payload
      };

    case SET_SNACKBAR_COMPONENT:
      return {
        ...state,
        snackbarComponent: action.payload
      };

    case TOGGLE_SIDEBAR_TYPE:
      return {
        ...state,
        sidebarType: action.payload
      };

    case SET_DASHBOARD_TOUR_IS_STARTED:
      return {
        ...state,
        dashboardTourIsStarted: action.payload
      };

    case SET_IS_PACKAGE_EXIST:
      return {
        ...state,
        isPackageExist: action.payload
      };
    case TOGGLE_AFTER_TOUR_POP_UP:
      return {
        ...state,
        showAfterTourPopUp: action.payload
      };

    case SET_SHOW_GUIDED_TOUR_FIRST:
      return {
        ...state,
        showGuidedTourFirst: action.payload
      };
    case SET_INDICATOR:
      return {
        ...state,
        indicator: action.payload
      };

    case SET_IS_PACKAGES_FETCHED:
      return {
        ...state,
        isPackagesFetched: action.payload
      };
    case IS_FORM_VALID:
      return {
        ...state,
        isFormsValid: action.payload
      };
    case SET_LOGIN_COUNTER:
      return {
        ...state,
        loginCounter: action.payload
      };
    case SET_START_LOADING:
      return {
        ...state,
        loading: {
          state: {
            ...state.loading.state,
            [action.payload.loadingKey]: true
          },
          isExtendedHeight: action.payload.isExtendedHeight
        }
      };
    case SET_END_LOADING:
      const cloneLoading = cloneDeep(state.loading);
      delete cloneLoading.state[action.payload];

      return {
        ...state,
        loading: {
          state: cloneLoading.state,
          isExtendedHeight: false
        }
      };

    case SET_POOL_CALENDAR_EVENT_TOOLTIP:
      return {
        ...state,
        poolCalendarEventTooltip: action.payload
      };

    case SET_SHOW_EXPIRED_TRIAL_MODAL:
      return {
        ...state,
        showExpiredTrialModal: action.payload
      };
    case SET_UNSAVED_MODAL_TYPE:
      return {
        ...state,
        unsavedModalType: action.payload
      };

    case SET_UNSAVED_CHANGES_STATE:
      return {
        ...state,
        unsavedChangesState: action.payload
      };

    case SET_SIDE_MENU:
      return {
        ...state,
        sideMenu: { ...state.sideMenu, ...action.payload }
      };
    case SET_FORM_INFO:
      return {
        ...state,
        formInfo: { ...state.formInfo, ...action.payload }
      };
    case SET_HIGHLIGHTED_FORM:
      return {
        ...state,
        highlightedForm: { ...state.highlightedForm, ...action.payload }
      };
    case SET_ROLE_SWITCHED:
      return {
        ...state,
        roleSwitched: action.payload
      };

    default:
      return state;
  }
}
