import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useDataQuality } from '../../hooks/use-get-quality';

export const QualityDiagramRenderFocusedSegment = () => {
  const { filledQuality, amountOfQuality, filledQualityPercentColor } = useDataQuality();
  return (
    <Flex direction='column' align='center' width='100%'>
      <Flex align='flex-end' justify='center'>
        <Text color={filledQualityPercentColor} size='26' bold>
          {filledQuality}
        </Text>
        <Text color='grey400' size='16' lineHeight='26px'>
          /{amountOfQuality}
        </Text>
      </Flex>

      <Text color='grey400' size='10'>
        erf. Felder ausgefüllt
      </Text>
    </Flex>
  );
};
