import { Dispatch, ReactElement, SetStateAction } from 'react';

export interface GrvLeasingOffersSwitcherProps {
  data: GrvLeasingOffersSwitcherData;
  type: GrvLeasingOffersSwitcherTypes;
  value: SwitcherOption | null;
  setValue: Dispatch<SetStateAction<SwitcherOption | null>>;
  hasError: boolean;
}

export enum GrvLeasingOffersSwitcherTypes {
  Distance = 'distance',
  Duration = 'duration'
}

export interface SwitcherOption {
  label: string;
  value: number;
}

export interface GrvLeasingOffersSwitcherData {
  icon: ReactElement;
  units: string;
  options: SwitcherOption[];
}
