import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const LicencePlateIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M1 2.6C1 2.03995 1 1.75992 1.10899 1.54601C1.20487 1.35785 1.35785 1.20487 1.54601 1.10899C1.75992 1 2.03995 1 2.6 1H17.4C17.9601 1 18.2401 1 18.454 1.10899C18.6422 1.20487 18.7951 1.35785 18.891 1.54601C19 1.75992 19 2.03995 19 2.6V17.4C19 17.9601 19 18.2401 18.891 18.454C18.7951 18.6422 18.6422 18.7951 18.454 18.891C18.2401 19 17.9601 19 17.4 19H2.6C2.03995 19 1.75992 19 1.54601 18.891C1.35785 18.7951 1.20487 18.6422 1.10899 18.454C1 18.2401 1 17.9601 1 17.4V2.6Z'
          fill={fill}
        />
      )}
      <path
        d='M15.6922 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7951 18.7951 18.6421 18.891 18.454C19 18.2401 19 17.96 19 17.4V2.59998C19 2.03993 19 1.75991 18.891 1.54599C18.7951 1.35783 18.6422 1.20485 18.454 1.10898C18.2401 0.999985 17.9601 0.999985 17.4 0.999985H2.6C2.03995 0.999985 1.75992 0.999985 1.54601 1.10898C1.35785 1.20485 1.20487 1.35783 1.10899 1.54599C1 1.75991 1 2.03993 1 2.59998V17.4C1 17.96 1 18.2401 1.10899 18.454C1.20487 18.6421 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H12.7461'
        stroke={color}
        stroke-linecap='round'
      />
      <path
        d='M6.52629 14.5V10V5.5H9.29552C11.5896 5.5 13.4737 7.60193 13.4737 10C13.4737 12.3981 11.972 14.5 9.29552 14.5H6.52629Z'
        stroke={color}
        stroke-linejoin='round'
      />
    </svg>
  );
};
