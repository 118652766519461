import React, { useContext } from 'react';
import { SubmitButtonProps } from './submit-button.types';
import { Button } from 'common/common-components.styled';
import {
  CreateCostCenterHistoryAttributes,
  CreateCostCenterHistoryData,
  UpdateCostCenterHistoryAttributes,
  UpdateCostCenterHistoryData
} from 'request-config/cost-centers-history/cost-centers-history.types';
import { ChangeCostCenterFieldNames } from '../change-cost-center.types';
import { CrmDataTypes } from 'common/enums';
import { costCentersHistoryRequestConfig } from 'request-config/cost-centers-history/cost-centers-history.request-config';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ChangeCostCenterContext } from '../change-cost-center.context';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';

const SubmitButton = ({ reactHookFormData }: SubmitButtonProps) => {
  const { closeModal, item, costCenterId, entityName, id, callback } = useContext(ChangeCostCenterContext);
  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;

  const { fetch: updateCostCenterHistory } = useFetch({
    loadingKey: 'updateCostCenterHistory',
    isShowSuccessSnackbar: true,
    errorMessage:
      'Bitte warten Sie, wir bearbeiten derzeit Änderungen. Die Funktion wird in ein paar Minuten wieder verfügbar sein.'
  });

  const { fetch: createCostCenterHistory } = useFetch({
    loadingKey: 'createCostCenterHistory',
    isShowSuccessSnackbar: true
  });

  const saveCostCenter = () => {
    handleSubmit(async data => {
      closeModal();
      if (item) {
        const attributes = {} as UpdateCostCenterHistoryAttributes;
        Object.values(ChangeCostCenterFieldNames).forEach(fieldName => {
          attributes[fieldName] = {
            attributeTypeCode: CrmDataTypes.DateTime,
            attributeValue: data[fieldName] ? convertUTCDateToLocalDate(data[fieldName].toISOString()) : null
          };
        });
        const dataToSend: UpdateCostCenterHistoryData = {
          id: item.uds_cost_center_historyid!,
          attributes: attributes
        };

        await updateCostCenterHistory({
          requestConfig: costCentersHistoryRequestConfig.updateCostCentersHistory(dataToSend)
        });
      } else {
        const attributes = {
          uds_cost_centerid: {
            attributeValue: {
              id: costCenterId!,
              logicalName: '',
              name: ''
            },
            attributeTypeCode: 6
          }
        } as CreateCostCenterHistoryAttributes;
        Object.values(ChangeCostCenterFieldNames).forEach(fieldName => {
          attributes[fieldName] = {
            attributeTypeCode: CrmDataTypes.DateTime,
            attributeValue: data[fieldName] ? convertUTCDateToLocalDate(data[fieldName].toISOString()) : null
          };
        });
        const dataToSend: CreateCostCenterHistoryData = {
          entityName,
          id,
          attributes: attributes
        };
        await createCostCenterHistory({
          requestConfig: costCentersHistoryRequestConfig.createCostCentersHistory(dataToSend)
        });
      }

      callback();
    })();
  };

  return (
    <Button id='submitCostCenterModel' disabled={!isValid} onClick={saveCostCenter}>
      Speichern
    </Button>
  );
};

export default SubmitButton;
