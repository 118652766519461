import { useDispatch } from 'react-redux';
import { AnyObject } from './../common/interfaces';
import { setSessionSortingAction } from 'actions/session_storage_action';
import { PAGES } from 'common/enums';
import { SESSION_SORTING_KEY } from '../constants';
import { useTypedSelector } from './use-typed-selector';
import { useEffect } from 'react';

export interface ISorting {
  predicate: string;
  reverse: boolean;
}

export const useSessionSorting = (page: PAGES, defaultSorting: Partial<ISorting> = {}) => {
  const pagesSessionSorting: Record<PAGES, SessionSorting> = useTypedSelector(state => state.sessionStorage.sorting);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reqCount) {
      setSessionSorting(defaultSorting);
    } else {
      dispatch(setSessionSortingAction(getSessionSorting()));
    }
  }, []);

  const getSessionSorting = () => {
    const sessionSorting = sessionStorage.getItem(SESSION_SORTING_KEY);
    return sessionSorting ? JSON.parse(sessionSorting) : {};
  };

  const reqCount = getSessionSorting()[page]?.reqCount | 0;

  const setSessionSorting = (sortingData: AnyObject) => {
    sessionStorage.setItem(
      SESSION_SORTING_KEY,
      JSON.stringify({
        ...pagesSessionSorting,
        [page]: {
          reqCount: reqCount + 1,
          ...sortingData
        }
      })
    );

    dispatch(setSessionSortingAction(getSessionSorting()));
  };

  return {
    sessionSorting: {
      predicate: pagesSessionSorting[page]?.predicate,
      reverse: pagesSessionSorting[page]?.reverse
    },
    setSessionSorting
  };
};

export interface SessionSorting extends Partial<ISorting> {
  reqCount: number;
}
