import React from 'react';

export default function PdfFileIcon(props) {
  return (
    <svg width='24' height='24' {...props} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.5 3H13.193C13.5823 3 13.9564 3.15136 14.2362 3.4221L20.5431 9.52561C20.8351 9.8082 21 10.1972 21 10.6035V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5C3 3.67157 3.67157 3 4.5 3Z'
        stroke='#BF0040'
        strokeWidth='2'
      />
      <path
        d='M9.1665 11.5923C9.1665 12.1506 9.0013 12.5824 8.6709 12.8877C8.34277 13.1908 7.87565 13.3423 7.26953 13.3423H6.89014V15H5.54004V10.0029H7.26953C7.90072 10.0029 8.37467 10.1408 8.69141 10.4165C9.00814 10.6922 9.1665 11.0841 9.1665 11.5923ZM6.89014 12.2417H7.13623C7.33903 12.2417 7.49967 12.1847 7.61816 12.0708C7.73893 11.9569 7.79932 11.7996 7.79932 11.5991C7.79932 11.2619 7.61247 11.0933 7.23877 11.0933H6.89014V12.2417ZM14.3446 12.3955C14.3446 13.2295 14.1144 13.8721 13.6542 14.3232C13.1962 14.7744 12.5513 15 11.7196 15H10.1029V10.0029H11.8324C12.6345 10.0029 13.2531 10.208 13.6883 10.6182C14.1258 11.0283 14.3446 11.6208 14.3446 12.3955ZM12.9432 12.4434C12.9432 11.9854 12.8521 11.6458 12.6698 11.4248C12.4898 11.2038 12.2152 11.0933 11.8461 11.0933H11.453V13.8926H11.7538C12.1639 13.8926 12.4647 13.7741 12.6561 13.5371C12.8475 13.2979 12.9432 12.9333 12.9432 12.4434ZM16.7131 15H15.3835V10.0029H18.3366V11.0864H16.7131V12.04H18.2102V13.1235H16.7131V15Z'
        fill='#BF0040'
      />
    </svg>
  );
}
