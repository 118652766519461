import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { portalCustomizationRequestConfig } from 'request-config/portal-customization/portal-customization.request-config';
import { GetReportDamageSettingsQueryData } from './get-report-damage-settings.props';
import { useReportDamageIsCustomLink } from 'pages/report-damage-page/hooks/use-is-custom-link';

export const useGetReportDamageSettingsQuery = () => {
  const location = useLocation();

  const { fetch } = useFetch<ResponseModel<GetReportDamageSettingsQueryData | undefined>>({
    loadingKey: 'getReportDamageSettingsQuery',
    isShowLoading: false,
    isExcludeToken: true,
    ...portalCustomizationRequestConfig.getReportDamageSettings(location.pathname)
  });

  const { isReportDamageCustomLink } = useReportDamageIsCustomLink();

  const { data, ...restQueryProps } = useQuery(['getReportDamageSettingsQuery', location.pathname], () => fetch(), {
    enabled: isReportDamageCustomLink
  });

  return { reportDamageSettingsQuery: data?.data?.data?.attributes || {}, ...restQueryProps };
};
