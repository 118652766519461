import { SharePointTitle } from 'common/enums';
import { AnyObject } from 'common/interfaces';
import { FieldNames } from '../../../additional-form/additional-form.props';

export const ADDITIONAL_SHAREPOINT_OPTION_SET = {
  [SharePointTitle.CertificateOfAchievement]: 'Leistungsnachweis',
  [SharePointTitle.ProtocolVehicleAcceptance]: 'Protokoll Fahrzeugübernahme & Fahrzeugübergabe',
  [SharePointTitle.ErrorLog]: 'Fehlerspeicher Protokoll',
  [SharePointTitle.PhotosAfterRepair]: 'Fotos nach Reparatur',
  [SharePointTitle.DeliveryNote]: 'Lieferschein ',
  [SharePointTitle.RepairSchedule]: 'Reparaturablaufplan',
  [SharePointTitle.OtherInvoices]: 'Sonstige Rechnungen ',
  [SharePointTitle.MeasurementProtocol]: 'Vermessungsprotokoll'
};

export const getSharePointFilesForSubmit = (data: AnyObject) => {
  const sharePointFiles: AnyObject = {};

  Object.entries(data).map(([key, value]) => {
    if (Object.values(SharePointTitle).includes(key as SharePointTitle)) {
      sharePointFiles[key] = value;
    }
  });

  return sharePointFiles;
};

export const getAdditionalFilesForSubmit = (data: AnyObject) => {
  const additionalFiles: AnyObject = {};

  Object.keys(data).map(key => {
    if (key.includes(FieldNames.DocumentType)) {
      additionalFiles[data[key] as string] = data[key.replace(FieldNames.DocumentType, FieldNames.OfferProvider)];
    }
  });

  return additionalFiles;
};
