import { LookupResponse, ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { damageRequestConfig } from 'request-config/damage/damage.request-config';
import { DamageQueryKey } from '../damage.props';
import { GetDamageRecordFormData } from 'services/damage-service/damage-service.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useGetDamageRecordingFormDataQuery = (
  props: GetDamageRecordFormData,
  {
    options
  }: QueryHookOptions<
    AxiosResponse<ResponseModel<LookupResponse<'account'>[]>>,
    ResponseModel<LookupResponse<'account'>[]>
  > = {}
) => {
  const { fetch } = useFetch<ResponseModel<LookupResponse<'account'>[]>>({
    loadingKey: `getDamageRecordingFormData${JSON.stringify(props)}`,
    isCancelRequestOnUnmount: true,
    ...damageRequestConfig.getDamageRecordingFormData(props)
  });

  const { data, ...restQueryProps } = useQuery(
    [DamageQueryKey.GetDamageRecordingFormData, props],
    () => fetch(),
    options
  );

  return {
    query: data?.data.data || [],
    ...restQueryProps
  };
};
