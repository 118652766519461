import { ReactNode } from 'react';

export interface WidgetDocumnentContainerProps {
  title?: string;
  description?: string;
  img?: any;
  className?: string;
  children: ReactNode;
  type?: WidgetType;
}

export enum WidgetType {
  Normal = 'normal',
  Simple = 'simple',
  New = 'new'
}
