import React, { MouseEvent } from 'react';
import classes from './task.module.scss';
import { TaskTypeIcon } from 'components/aufgaben-component/components/task-type-icon/task-type-icon';
import { TaskProps } from './task.props';
import { getStatusColorForOverdueTaskPages } from 'utils/get-status-color-task-pages';
import { formatDate } from 'helpers';
import { PAGES } from 'common/enums';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { TooltipMouseEnterCallbackArgs } from 'components/tooltip-hint/tooltip-hint.props';
import { useTheme } from 'styled-components';
import { Button, Flex } from 'common/common-components.styled';
import { DoubleRightArrow } from 'components/icons/double-right-arrow';
import classNames from 'classnames';
import useWindowSize from 'hooks/use-window-size';
import { CRM_VALUE_TASK_PAGE } from 'components/aufgaben-component/aufgaben-component-view';

export const TaskWidget = ({
  records = [],
  openPage,
  emptyText = 'Es gibt keine anstehenden Aufgaben',
  openFilterState
}: TaskProps) => {
  const theme = useTheme();
  const { windowWidth } = useWindowSize();

  const isMobileWidth = windowWidth <= 550;

  const getStatusStyle = (date: string, stateCode: number) => ({
    color: theme.colors[getStatusColorForOverdueTaskPages({ scheduledend: date, statecode_value: stateCode })]
  });

  const mouseEnterCallback = ({ containerRef, setIsShowTip, setTimeoutId }: TooltipMouseEnterCallbackArgs) => {
    const timeoutIdCurrent = setTimeout(() => {
      setIsShowTip(containerRef.current.offsetWidth < containerRef.current.scrollWidth);
    }, 1000);

    setTimeoutId(timeoutIdCurrent);
  };

  const openTaskHandler = (event: MouseEvent, taskId: string, isDisable: boolean) => {
    event.stopPropagation();

    if (isDisable) {
      return;
    }

    openPage({
      page: PAGES.TASKS,
      search: `?id=${taskId}`,
      companyCrmName: CRM_VALUE_TASK_PAGE.company,
      ...openFilterState
    })();
  };

  return (
    <>
      {!records.length ? (
        <div className={classes.empty_container}>
          <img
            src='/assets/images/schedule_planning_big.svg'
            alt='schedule_planning_big'
            className={classes.empty_image}
          />
          <p className={classes.empty_placeholder}>{emptyText}</p>
        </div>
      ) : (
        <ul className={classes.list}>
          {records.map(recordItem => (
            <li
              key={recordItem.activityid}
              className={classes.item}
              onClick={event => openTaskHandler(event, recordItem.activityid, !isMobileWidth)}
            >
              <span className={classNames(classes.task_value, classes.column)}>
                <span className={classes.task_icon}>
                  <TaskTypeIcon type={recordItem.uds_aufgabentyp_fuhrpark_value} color={theme.colors.blue} />
                </span>
                <TooltipHint
                  text={recordItem.uds_aufgabentyp_fuhrpark}
                  position='bottom'
                  active
                  mouseEnterCallback={mouseEnterCallback}
                  className={classes.value}
                >
                  <span>{recordItem.uds_aufgabentyp_fuhrpark || '-'}</span>
                </TooltipHint>
              </span>

              <div className={classes.column} style={{ justifySelf: 'normal' }}>
                <TooltipHint
                  text={recordItem.regardingobjectid}
                  position='bottom'
                  active
                  mouseEnterCallback={mouseEnterCallback}
                  className={classes.value}
                >
                  <span>{recordItem.regardingobjectid || '-'}</span>
                </TooltipHint>
              </div>

              <div
                className={classes.column}
                style={{ ...getStatusStyle(recordItem.scheduledend, recordItem.statecode_value), justifySelf: 'end' }}
              >
                <TooltipHint
                  text={formatDate(recordItem.scheduledend) || ''}
                  position='bottom'
                  active
                  mouseEnterCallback={mouseEnterCallback}
                  className={classes.value}
                >
                  <span>{recordItem.scheduledend ? `bis ${formatDate(recordItem.scheduledend)}` : '-'}</span>
                </TooltipHint>
              </div>

              {/* <span
              className={classes.status}
              style={{ ...getStatusStyle(recordItem.scheduledend, recordItem.statecode_value), justifySelf: 'center' }}
            /> */}

              <Flex
                className={classes.column}
                justify='center'
                onClick={event => openTaskHandler(event, recordItem.activityid, isMobileWidth)}
              >
                <Button className={classes.to_task_button} secondary>
                  ERLEDIGEN
                </Button>
                <DoubleRightArrow className={classes.to_task_icon} />
              </Flex>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
