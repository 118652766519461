import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { EllipsisWithTooltipProps } from './ellipsis-with-tooltip.props';
import useWindowSize from 'hooks/use-window-size';
import { useForceUpdate } from 'hooks/use-force-update';
import { TooltipComponent } from 'components/tooltip-component/tooltip-component';

const EllipsisWithTooltip = ({ children, tooltip, maxWidth = '100%', tooltipProps }: EllipsisWithTooltipProps) => {
  const [isShowTip, setIsShowTip] = useState(false);

  const content = useRef<HTMLElement | null>(null);
  const forceUpdate = useForceUpdate();
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      forceUpdate();
    });
  }, []);

  useEffect(() => {
    if (content.current) {
      setIsShowTip(content.current.offsetWidth < content.current.scrollWidth);
    }
  }, [content.current?.offsetWidth, content.current?.scrollWidth, windowWidth]);

  const childrenStyleProps = children.props.style || {};

  const clonedElement = cloneElement(children, {
    ...children.props,
    style: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: maxWidth,
      ...childrenStyleProps
    },
    ref: content
  });

  return (
    <>
      {isShowTip ? (
        <TooltipComponent {...tooltipProps} title={tooltip}>
          {clonedElement}
        </TooltipComponent>
      ) : (
        clonedElement
      )}
    </>
  );
};

export default EllipsisWithTooltip;
