import React, { useEffect, useMemo, useState } from 'react';
import classes from './file-viewer.module.scss';
import './react-file-viewer.scss';
import { CloseIcon } from '../icons/close-icon';
import useModal from '../../hooks/use-modal';
import { MODAL_NAMES, MODALS } from '../../constants';
import { getIcon } from '../download-documents-component/download-documents-component';
import Download from '../icons/download';
import { getFileForDownload } from '../vertrage/vertrag-download/vertrag-download';
import RightArrow from '../icons/right_arrow';
import Spinner from '../spinner';
import { asyncInterval } from '../../utils/async-interval';
import sharepointService from 'services/sharepoint-service';
import { useLocation, useHistory } from 'react-router';
import { PAGES } from 'common/enums';
import heic2any from 'heic2any';
import { FileViewerController } from './file-viewer-controller/file-viewer-controller';

const zoomScalesList = [1, 1.5, 2];

const FileViewerComponent = ({ parameters, onCloserHandler }) => {
  const [showFileViewer, hideFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, null);
  const [file, setFile] = useState(parameters.file || {});
  const [downloading, setDownloading] = useState(false);
  const [objectURL, setObjectURL] = useState(null);
  const [blob, setBlob] = useState('');
  const [zoomScale, setZoomScale] = useState(zoomScalesList[0]);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialHeight, setInitialHeight] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const filePathArr = file.filePath ? file.filePath.split('/') : file.name;
  const fileName = file.name ? file.name : filePathArr[filePathArr.length - 1];
  const fileTypeArr = fileName.split('.');
  const fileType = fileTypeArr[fileTypeArr.length - 1].toLowerCase();
  const icon = useMemo(() => getIcon(fileName), [fileName]);
  const isFirstFile = useMemo(() => parameters.allFiles[0]?.fileId === file.fileId, [file]);
  const isLastFile = useMemo(() => parameters.allFiles[parameters.allFiles.length - 1]?.fileId === file.fileId, [file]);
  const allowZoom = useMemo(() => ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(fileType), [file]);

  const PAGES_DOWNLOAD_BY_ID = [
    PAGES.ASSIGNMENTS_VEHICLE_TRANSFER,
    PAGES.ASSIGNMENTS_ACCIDENT_REPAIR_RECORD,
    PAGES.DAMAGE_DETAILS,
    PAGES.ASSIGNMENTS_VEHICLE_TRANSFER_RECORD,
    PAGES.IMPORT,
    PAGES.MY_REQUESTS,
    PAGES.ASSIGNMENTS_LEASE_RETURN_PRO,
    PAGES.ASSIGNMENTS_LEASE_RETURN_RECORD,
    PAGES.GREENHOUSE_GAS_EMISSION_QUOTA
  ];

  useEffect(() => {
    if (!file.filePath && !file.fileId) {
      const blob = new Blob([file], { type: file.type });
      const objectURL = URL.createObjectURL(blob);
      setBlob(blob);
      setObjectURL(objectURL);
      return;
    }

    if (PAGES_DOWNLOAD_BY_ID.some(page => location.pathname.includes(page))) {
      sharepointService
        .downloadFileById(file.fileId)
        .then(({ data }) => {
          setBlob(new Blob([data], { type: 'application/pdf' }));
          setObjectURL(URL.createObjectURL(new Blob([data])));
        })
        .catch(err => console.log(err));
    } else {
      getFileForDownload(file.filePath)
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/octet-stream' });
          if (fileType === 'heic') {
            heic2any({ blob }).then(result => {
              setObjectURL(URL.createObjectURL(result));
              setBlob(new Blob([blob], { type: 'application/pdf' }));
            });
          } else {
            const objectURL = URL.createObjectURL(blob);
            setObjectURL(objectURL);
            setBlob(new Blob([blob], { type: 'application/pdf' }));
          }
        })
        .catch(err => console.log(err));
    }
  }, [file]);

  useEffect(() => {
    if (objectURL && ['xlsx', 'xls'].includes(fileType)) {
      asyncInterval(() => document.querySelector('.react-grid-HeaderRow'), 50, 40)
        .then(headerRow => {
          headerRow.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [objectURL]);

  const onError = e => {
    console.log(e, 'error in file-viewer');
  };

  const downloadFile = () => {
    if (!file.filePath && !file.fileId) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([file]));
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
      }, 100);
      setDownloading(false);
      return;
    }

    setDownloading(true);
    if (PAGES_DOWNLOAD_BY_ID.some(page => location.pathname.includes(page)) && !!file.fileId) {
      sharepointService
        .downloadFileById(file.fileId)
        .then(({ data }) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
          a.download = fileName;
          a.click();
          setDownloading(false);
        })
        .catch(err => console.log(err));
      return;
    }
    if (file.filePath) {
      getFileForDownload(file.filePath)
        .then(({ data }) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setDownloading(false);
        })
        .catch(error => console.error('Error during file download:', error));
      return;
    }
    if (!file.filePath && !file.fileId) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([file]));
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
      }, 100);
      setDownloading(false);
    }
  };

  const switchFile = direction => {
    if (!objectURL) {
      return;
    }
    const currentFileIndex = parameters.isEmailAttachmentPreview
      ? parameters.allFiles.findIndex(item => item.name === file.name)
      : parameters.allFiles.findIndex(item => item.fileId === file.fileId);
    const newFileIndex = direction === 'next' ? currentFileIndex + 1 : currentFileIndex - 1;
    const newFile = parameters.allFiles[newFileIndex];
    if (newFile) {
      setObjectURL(null);
      setZoomScale(1);
      setInitialWidth(null);
      setFile(newFile);
    }
  };

  const changeZoom = direction => {
    const getInitialDimensions = () => {
      let width;
      let height = null;
      switch (fileType) {
        case 'pdf':
          const canvases = document.querySelectorAll('.pdf-canvas canvas');
          width = canvases[0]?.width || null;
          break;
        case 'doc':
        case 'docx':
          const documentContainer = document.querySelector('.pg-viewer .document-container');
          width = documentContainer.clientWidth || null;
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
          const photoContainer = document.querySelector('.pg-viewer .photo-viewer-container .photo');
          width = photoContainer.clientWidth || null;
          height = photoContainer.clientHeight || null;
          break;
        default:
          width = null;
          break;
      }
      setInitialWidth(width);
      setInitialHeight(height);
      return { width, height };
    };
    const getNewZoomScale = () => {
      const currentIndex = zoomScalesList.indexOf(zoomScale);
      const nextIndex = direction === 'in' ? currentIndex + 1 : currentIndex - 1;
      return zoomScalesList[nextIndex];
    };

    if (
      !objectURL ||
      (zoomScale === zoomScalesList[0] && direction === 'out') ||
      (zoomScale === zoomScalesList[zoomScalesList.length - 1] && direction === 'in')
    ) {
      return;
    }

    let dimensions = {};
    if (!initialWidth) {
      dimensions = getInitialDimensions();
    }
    const containerInitWidth = initialWidth || dimensions.width;
    const containerInitHeight = initialHeight || dimensions.height;
    const newZoomScale = getNewZoomScale();
    const newContainerWidth = containerInitWidth * newZoomScale;
    const newContainerHeight = containerInitHeight * newZoomScale;
    setZoomScale(newZoomScale);

    switch (fileType) {
      case 'pdf':
        const canvases = document.querySelectorAll('.pdf-canvas canvas');
        canvases.forEach(canvas => {
          canvas.style.width = `${newContainerWidth}px`;
        });
        break;
      case 'doc':
      case 'docx':
        const documentContainer = document.querySelector('.pg-viewer .document-container');
        documentContainer.style.width = `${newContainerWidth}px`;
        documentContainer.style.fontSize = `${newZoomScale}em`;
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        const photoContainer = document.querySelector('.pg-viewer .photo-viewer-container .photo');
        const photoViewContainer = document.querySelector('.pg-viewer .photo-viewer-container');
        photoContainer.style.width = `${newContainerWidth}px`;
        photoContainer.style.height = `${newContainerHeight}px`;
        photoViewContainer.style.minWidth = `${newContainerWidth}px`;
        photoViewContainer.style.minHeight = `${newContainerHeight}px`;
        break;
      default:
        break;
    }
  };

  const closeFileViewer = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('file')) {
      queryParams.delete('file');
      history.replace({
        search: queryParams.toString()
      });
    }

    if (typeof onCloserHandler === 'function') {
      onCloserHandler();
    }

    hideFileViewer();
  };

  return (
    <div className={classes.file_viewer_wrapper}>
      <div className={classes.file_viewer_header}>
        <div className={classes.file_name_container}>
          <span>{file.description}</span>
          <div className={classes.file_name_with_icon}>
            {icon}
            <span>{fileName}</span>
          </div>
        </div>
        <div className={`${'d-flex a-i-center'} ${classes.download_wrapper}`}>
          <div onClick={downloadFile} className={classes.download_container}>
            {downloading ? (
              <Spinner
                style={{
                  width: '30px',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              />
            ) : file.filePath || file.fileId || parameters.isEmailAttachmentPreview ? (
              <>
                <Download className={classes.download_icon} fill={'#fff'} />
                <span>Herunterladen</span>
              </>
            ) : null}
          </div>
          <div className='d-flex a-i-center' onClick={closeFileViewer}>
            <CloseIcon className={classes.close_icon} color={'#fff'} />
          </div>
        </div>
      </div>
      <div className={classes.file_viewer_body}>
        <FileViewerController type={fileType} objectURL={objectURL} blob={blob} zoomScale={zoomScale} />
      </div>
      {parameters.allFiles.length > 1 && (
        <>
          <button
            onClick={() => switchFile('prev')}
            className={
              isFirstFile || !objectURL
                ? `${classes.button} ${classes.left_button} ${classes.disabled}`
                : `${classes.button} ${classes.left_button}`
            }
          >
            <RightArrow fill={'#fff'} />
          </button>
          <button
            onClick={() => switchFile('next')}
            className={
              isLastFile || !objectURL
                ? `${classes.button} ${classes.right_button} ${classes.disabled}`
                : `${classes.button} ${classes.right_button}`
            }
          >
            <RightArrow fill={'#fff'} />
          </button>
        </>
      )}
      {allowZoom && (
        <div className={classes.zoom_container}>
          <button
            style={{ alignItems: 'flex-start' }}
            onClick={() => changeZoom('out')}
            className={zoomScale === zoomScalesList[0] ? `${classes.button} ${classes.disabled}` : `${classes.button}`}
          >
            -
          </button>
          <button
            onClick={() => changeZoom('in')}
            className={
              zoomScale === zoomScalesList[zoomScalesList.length - 1]
                ? `${classes.button} ${classes.disabled}`
                : `${classes.button}`
            }
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

export default FileViewerComponent;
