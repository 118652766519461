import React from 'react';
import { Dialog } from '@mui/material';
import { ExplenationModalProps } from './explenation-modal.types';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from './explenation-modal.styled';
import { Button, Flex, Text } from 'common/common-components.styled';
import { PrefixForId } from 'common/enums';

const ExplenationModal: React.FC<ExplenationModalProps> = ({ open, onClose, submit }) => {
  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <Flex justify='space-between'>
          <Text size='20' color='white'>
            Neue Aufgaben
          </Text>

          <Flex pointer>
            <img
              onClick={onClose}
              src='/assets/images/alert_close.svg'
              id={PrefixForId.Modal + PrefixForId.Image + PrefixForId.Close}
              alt='alert_close'
            />
          </Flex>
        </Flex>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Text>
          Vielen Dank, wir erstellen die Aufgaben für deine Mitarbeiter. Dies kann je nach Anzahl der Aufgaben einige
          Minuten in Anspruch nehmen. Wir informieren dich via E-Mail, sobald alle Aufgaben erstellt worden sind.
        </Text>
      </StyledDialogContent>
      <StyledDialogActions>
        <Flex justify='flex-end'>
          <Button onClick={submit}>Alles klar</Button>
        </Flex>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ExplenationModal;
