import React from 'react';
import { CreateNoteSubmitButtonProps } from './submit.props';
import Button from 'components/button/button';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { PAGES } from 'common/enums';
import { useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import { useTypedSelector } from 'hooks/use-typed-selector';
import useNotesContext from 'hooks/use-notes/use-notes-context';
import { notesRequestConfig } from 'request-config/notes/notes.request-config';
import { UpdateCreateNoteRequestData } from 'request-config/notes/notes.types';

export const CreateNoteSubmitButton = ({ data, cancelEdit, reactHookFormData }: CreateNoteSubmitButtonProps) => {
  const { id, entityName } = useNotesContext();
  const user = useTypedSelector(state => state.app.user);

  const { fetch } = useFetch({ loadingKey: 'submitNote' });
  const queryClient = useQueryClient();

  const { handleSubmit } = reactHookFormData;

  const submitHandler = handleSubmit(values => {
    const submitModel: UpdateCreateNoteRequestData = {
      Notes: {
        entityName: 'uds_notizen',
        attributes: {
          uds_regardingobjectid: {
            id,
            logicalName: entityName,
            name: ''
          },
          uds_notiz: values.uds_notiz,
          uds_isshownonb2c: true,
          uds_createdbyb2c: user.id,
          uds_titel: values.uds_titel
        }
      }
    };

    if (!isEmpty(data)) {
      submitModel.Notes.attributes.uds_notizenid = data.uds_notizenid;
      submitModel.Notes.id = data.uds_notizenid;
    }

    fetch({
      requestConfig: notesRequestConfig.updateNote(submitModel),
      callback: () => {
        queryClient.resetQueries([PAGES.MY_VEHICLES_DETAILS, 'getFleetNotes']);
        cancelEdit();
      }
    });
  });

  return (
    <Button className='ml-10' onClick={submitHandler}>
      Speichern
    </Button>
  );
};
