import React from 'react';
import classes from './greeting-sidebar.module.scss';
import { useDispatch } from 'react-redux';
import { toggleChatbot, toggleGreetingSideBar, toggleSidebarType } from 'actions/app_action';
import { useTypedSelector } from 'hooks/use-typed-selector';
import classNames from 'classnames';
import { CloseIcon } from 'components/icons/close-icon';
import { SidebarType } from 'common/enums';
import { RightArrowIcon } from 'components/icons/right-arrow-icon';
import { useTheme } from 'styled-components';
import { Distance } from 'common/common-components.styled';
import { MobexoLogoIcon } from 'components/icons/mobexo-logo-icon';

const HelpSection = () => {
  const isOpenHelpWindow = useTypedSelector(state => state['app']?.isOpenHelpWindow);
  const containerClassName = classNames(classes.container, { [classes.open]: isOpenHelpWindow });
  const user = useTypedSelector(state => state['app'].user.name);
  const userName = user?.split(' ')[0] || '';
  const dispatch = useDispatch();
  const openChat = () => {
    dispatch(toggleGreetingSideBar(false));
    dispatch(toggleChatbot(true));
    dispatch(toggleSidebarType(SidebarType.Chat));
  };

  const closeSidebar = () => {
    dispatch(toggleGreetingSideBar(false));
  };

  const theme = useTheme();

  return (
    <div className={containerClassName}>
      <div className={classes.title_container}>
        <div className={classes.header}>
          <div className={classes.image_container}>
            <MobexoLogoIcon />
          </div>
          <div className={classes.close_button_wrapper}>
            <CloseIcon onClick={closeSidebar} color={'#fff'} width={'20px'} height={'20px'} />
          </div>
        </div>
        <div className={classes.help_description_container}>
          <p className={classes.greeting}>{`Hallo, ${userName}!`}</p>
          <p className={classes.description}>
            Hier kannst Du Deine Fragen vom Chatbot beantworten lassen oder Antworten durch das Community Forum
            erhalten.
          </p>
        </div>
      </div>
      <div className={classes.content_container}>
        <div className={classes.card}>
          <p className={classes.card_description}>Hast Du Fragen? Chatte mit Moe, unserem Hilfe-Bot.</p>
          <div className={classes.image_container}>
            <img src='/assets/images/chatbot_image.png' alt='bot avatar' />
            <p>Hi! Ich bin 24/7 für Dich da.</p>
          </div>
          <div className={classes.button_container}>
            <button onClick={openChat} className={classes.action_button}>
              Chat starten
            </button>
          </div>
        </div>
        <div className={classes.card}>
          <p className={classes.card_description}>Nach Hilfe suchen.</p>
          <div className={classes.button_container}>
            <a href='https://www.mobexo.de/faq/' target='_blank'>
              <button className={classes.action_button}>
                <span>Zur FAQ Seite</span>
                <Distance side='5' />
                <RightArrowIcon color={theme.colors.white} stroke={theme.colors.white} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
