import { Position, ShortNameOfMonths } from 'common/enums';
import { mainTheme } from 'common/global-styles';
import { PAGES } from 'common/enums';
import toMoney from 'utils/toMoney';
import { ChartOptions } from 'chart.js';
import { AnyObject } from 'common/interfaces';

const blueColor = {
  static: mainTheme.colors.midBlue,
  hover: mainTheme.colors.blue
};

export const createOptions = (history: any, sumByMonthByCurrentYear: AnyObject) => {
  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        backgroundColor: mainTheme.colors.darkBlue,
        yAlign: Position.Bottom as const,
        xAlign: Position.Center as const,
        callbacks: {
          title: () => '',
          label: context => `${toMoney(Object.values(sumByMonthByCurrentYear)[context.dataIndex])}`
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: '#ABB0B2',
          padding: 0
        }
      },
      y: {
        grid: {
          color: 'rgba(208, 208, 208, 0.4)'
        },
        ticks: {
          color: mainTheme.colors.grey300,
          padding: 10
        }
      }
    },
    onHover(elem: any, items: any) {
      changeColorOnHover(items, options);
      elem.chart.update();
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const link = PAGES.VEHICLE_COSTS;
        const linkState = filterMonthsStatusArray[index];

        history.push({
          pathname: link,
          state: { filterMonthsStatus: linkState }
        });
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  const changeColorOnHover = (items: any, options: any) => {
    if (items.length > 0) {
      const colorArray = [];
      const onHoverElemIndex = items[0].index;

      for (let key = 0; key < Object.values(ShortNameOfMonths).length; key++) {
        if (key === onHoverElemIndex) {
          colorArray.push(blueColor.hover);
        } else {
          colorArray.push(blueColor.static);
        }
      }

      options.scales.x.ticks.color = colorArray;
    }
    return items;
  };

  return options;
};

export const data = {
  labels: Object.values(ShortNameOfMonths),
  datasets: [
    {
      label: '',
      data: [0],
      backgroundColor: blueColor.static,
      hoverBackgroundColor: blueColor.hover,
      barThickness: 15
    }
  ]
};

const currentYear = new Date().getFullYear();

interface InvoicedateData {
  invoicedateServer: {
    startdate: string;
    enddate: string;
  };
  invoicedateClient: string;
}

interface FilterMonthsStatusContainer {
  filterMonthsStatus: InvoicedateData[];
}

const getEndDateOfMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

const linksState: FilterMonthsStatusContainer[] = [
  {
    filterMonthsStatus: []
  }
];

const filterMonthsStatusArray = linksState[0].filterMonthsStatus;

for (let month = 1; month <= 12; month++) {
  const monthStr = month.toString().padStart(2, '0');
  const startDate = `${currentYear}-${monthStr}-01`;
  const endDate = `${currentYear}-${monthStr}-${getEndDateOfMonth(currentYear, month)}`;

  const clientStartDate = `01.${monthStr}.${currentYear}`;
  const clientEndDate = `${getEndDateOfMonth(currentYear, month)}.${monthStr}.${currentYear}`;

  filterMonthsStatusArray.push({
    invoicedateServer: { startdate: startDate, enddate: endDate },
    invoicedateClient: `${clientStartDate} - ${clientEndDate}`
  });
}
