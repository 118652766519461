import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';


export const tacitlyExtendLeaseAgreementFormFields: FormFieldModel[] = [
  {
    name: LeasingContractFields.SilentExtendDate,
    type: FormFieldTypes.Date,
    defaultValue: '',
    label: 'Stillschweigend verlängert bis',
    validation: {
      required: 'Bitte auswählen'
    }
  }
];