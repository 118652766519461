import React, { ReactNode, cloneElement, isValidElement, useCallback, useRef, useState } from 'react';
import classes from './more.module.scss';
import { useOutsideClick } from '../../hooks';
import Spinner from 'components/spinner';
import classNames from 'classnames';
import { ChildProps, MenuItemContainer, MoreMenuItemProps, MoreMenuProps, MoreProps } from './more-view.types';

export const MoreMenu: React.FC<MoreMenuProps> = ({ onClick, ...prev }) => (
  <button className={classes.more_button} onClick={onClick} {...prev}>
    <span className={classes.more_dot} />
    <span className={classes.more_dot} />
    <span className={classes.more_dot} />
  </button>
);

export const MoreMenuItem: React.FC<MoreMenuItemProps> = ({
  onClick,
  label,
  onOutsideClick,
  img,
  minWidth = '190px',
  isLeftPositionImg,
  disable = false,
  id
}) => {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!disable) {
      onOutsideClick && onOutsideClick();

      if (typeof onClick === 'function') {
        onClick();
      }
    }
  };

  return (
    <button
      style={{ minWidth, justifyContent: isLeftPositionImg ? 'flex-start' : 'space-between' }}
      className={classNames(classes.delete__button, { 'button-mobexo__disable': disable })}
      onClick={clickHandler}
      id={id}
    >
      {isLeftPositionImg && img && img}

      {isLeftPositionImg && !img && img !== false ? (
        <img className={classes.delete__button_img} src='/assets/images/gears.svg' />
      ) : null}

      <span className={classes.delete__button_text}>{label}</span>
      {!isLeftPositionImg && img && img}

      {!isLeftPositionImg && !img && img !== false ? (
        <img className={classes.delete__button_img} src='/assets/images/gears.svg' />
      ) : null}
    </button>
  );
};

export const MoreMenuItemContainer: React.FC<MenuItemContainer> = ({
  children,
  onOutsideClick,
  top = '0',
  right = '0'
}) => {
  const containerRef = useRef(null);
  useOutsideClick(containerRef, onOutsideClick);

  const getChildrenWithProps = () => {
    const modifyChildProps = (child: ReactNode) => {
      if (isValidElement(child)) {
        const childProps: ChildProps = { onOutsideClick };

        return cloneElement(child, childProps);
      }
    };
    let result;
    if (Array.isArray(children)) {
      result = children.map(modifyChildProps);
    } else {
      result = modifyChildProps(children);
    }
    return result;
  };

  return (
    <div style={{ top, right }} ref={containerRef} className={classes.more_menu_items}>
      {getChildrenWithProps()}
    </div>
  );
};

const More: React.FC<MoreProps> = ({ className, children, spinnerStyle, isLoading, onClick, id }) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const moreButtonClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const spinnerContainerStyle = {
    height: spinnerStyle?.containerHeigth || 'auto',
    width: spinnerStyle?.containerWidth || 'auto'
  };

  return (
    <>
      <div id={id} ref={containerRef} className={className ? `${className} ${classes.more}` : classes.more}>
        {spinnerStyle && isLoading && open ? (
          <div className={classes.more_menu_items} style={spinnerContainerStyle}>
            <Spinner style={spinnerStyle} />
          </div>
        ) : (
          <>
            {open && <MoreMenuItemContainer onOutsideClick={closeHandler}>{children}</MoreMenuItemContainer>}
            <MoreMenu onClick={moreButtonClickHandler} />
          </>
        )}
      </div>
    </>
  );
};

export default More;
