import { UserRoleId } from './../common/interfaces';
import { useTypedSelector } from './use-typed-selector';
import { User } from 'common/interfaces';

export const useIsUserHaveRole = (roleId: UserRoleId | UserRoleId[]): boolean => {
  const user: User = useTypedSelector(state => state.app.user);

  if (typeof roleId === 'string') {
    return user.mainRole?.id === roleId;
  }

  return roleId.includes(user.mainRole?.id);
};
