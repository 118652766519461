import React from 'react';
import ThreeStateCheckbox from 'components/three-state-checkbox/three-state-checkbox';
import { SixDots } from 'components/icons/six-dots';
import { Flex, Text } from 'common/common-components.styled';
import { StyledItemContainer } from './column-item.styled';
import { Draggable } from 'react-beautiful-dnd';
import { CustomizedColumnInfo } from '../column-settings.types';

interface tempProps {
  column: CustomizedColumnInfo;
  onChange: () => void;
  index: number;
  disableCheckbox: boolean;
}

const ColumnItem = ({ column, index, disableCheckbox, onChange }: tempProps) => {
  const { title, isChecked } = column;

  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <StyledItemContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <Flex align='center'>
            <ThreeStateCheckbox
              indeterminate={false}
              checked={isChecked}
              onChange={onChange}
              disabled={disableCheckbox && isChecked}
            />
            <Text left='13' color='black'>
              {title}
            </Text>
          </Flex>
          <Flex>
            <SixDots />
          </Flex>
        </StyledItemContainer>
      )}
    </Draggable>
  );
};

export default ColumnItem;
