import cloneDeep from 'lodash/cloneDeep';

export function getFormValues(forms, setForm, callback, clearUntouched = true) {
  Object.entries(forms).forEach(form => {
    const newForm = cloneDeep(form[1]);
    clearUntouched && (newForm.untouched = {});
    setForm({ name: form[0], form: newForm });
  });
  const allForms = Object.values(forms);
  if (allForms.every(form => form.isFormValid)) {
    let data = allForms.reduce((accumulator, form) => {
      return { ...accumulator, ...form.values };
    }, {});

    for (let key in data) {
      if (data.hasOwnProperty(key) && data[key] instanceof Date) {
        data[key] = data[key].toISOString();
      }
    }
    callback(data);
  }
}
