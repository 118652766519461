import { useContext } from 'react';
import { VehicleDetailContext } from '../existing-vehicle.context';
import { useQuery as useQueryLocation } from 'hooks';
import { OpenPageProps } from 'common/interfaces';
import { useHistory } from 'react-router-dom';
import { AutocompleteFilterFromState } from 'hooks/use-session-filter';

export const useOpenPageViaVehicleDetailPage = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const history = useHistory<AutocompleteFilterFromState>();
  const query = useQueryLocation();

  const openPage = ({ page, filterForClient, filterForServer, carCrmName }: OpenPageProps<'carCrmName'>) => {
    return () => {
      history.push({
        pathname: page,
        state: {
          filterForServer: { [carCrmName]: query.id, ...filterForServer },
          filterForClient: { [carCrmName]: vehicle?.fuhrpark?.new_name?.attributeValue || '', ...filterForClient }
        }
      });
    };
  };

  return { openPage };
};
