import React, { MutableRefObject, createContext, useMemo, useRef } from 'react';
import { ContextProps } from '../context.props';

interface IUnSaveModalOrdersRefContext {
  orderId: MutableRefObject<string>;
  isSaveDate: MutableRefObject<boolean>;
  isSaveEVB: MutableRefObject<boolean>;
}

export const UnSaveModalOrdersRefContext = createContext<IUnSaveModalOrdersRefContext>({
  orderId: { current: '' },
  isSaveDate: { current: false },
  isSaveEVB: { current: false }
});

export const UnSaveModalOrdersRefContextContainer = ({ children }: ContextProps) => {
  const orderId = useRef('');
  const isSaveDate = useRef(false);
  const isSaveEVB = useRef(false);

  const value = useMemo<IUnSaveModalOrdersRefContext>(() => ({ orderId, isSaveDate, isSaveEVB }), []);

  return <UnSaveModalOrdersRefContext.Provider value={value}>{children}</UnSaveModalOrdersRefContext.Provider>;
};
