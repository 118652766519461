import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const TransmissionIcon = ({ width = 18, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M4.40944 7.09671H4.38042C3.37155 7.09671 2.50783 7.808 2.3409 8.78784L1.03444 16.2854C0.78767 17.7007 1.89816 18.9999 3.36429 18.9999H14.3095C15.7756 18.9999 16.8861 17.708 16.6393 16.2854L15.3328 8.78784C15.1659 7.808 14.3022 7.09671 13.2933 7.09671H13.2643'
          fill={fill}
        />
      )}
      <path
        d='M4.40944 7.09671H4.38042C3.37155 7.09671 2.50783 7.808 2.3409 8.78784L1.03444 16.2854C0.78767 17.7007 1.89816 18.9999 3.36429 18.9999H14.3095C15.7756 18.9999 16.8861 17.708 16.6393 16.2854L15.3328 8.78784C15.1659 7.808 14.3022 7.09671 13.2933 7.09671H13.2643'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9433 11.6548L11.7417 9.7901H7.21916L7.10221 12.6129H6.17317C5.71592 12.6129 5.33124 12.9685 5.30221 13.4258L5.1498 15.7266C5.10625 16.4016 5.63609 16.9677 6.31109 16.9677H6.81189C7.29092 16.9677 7.68286 16.5758 7.68286 16.0968V14.6452H9.13447C9.6135 14.6452 10.0054 14.2532 10.0054 13.7742V12.6129H11.0796C11.595 12.6129 11.9941 12.1701 11.9433 11.6548Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M9.71509 7.08226C11.3409 6.9371 12.6183 5.56532 12.6183 3.90323V3.32258C12.6183 2.0379 11.5804 1 10.2957 1H7.39251C6.10783 1 5.06993 2.0379 5.06993 3.32258V3.90323C5.06993 5.57258 6.34735 6.9371 7.97316 7.08226'
          fill={fill}
        />
      )}
      <path
        d='M10.0054 7.04602V10.5807C10.0054 10.5807 8.84414 11.1613 7.68285 10.5807V7.04602'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M9.71509 7.08226C11.3409 6.9371 12.6183 5.56532 12.6183 3.90323V3.32258C12.6183 2.0379 11.5804 1 10.2957 1H7.39251C6.10783 1 5.06993 2.0379 5.06993 3.32258V3.90323C5.06993 5.57258 6.34735 6.9371 7.97316 7.08226'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0054 7.04602V10.5807C10.0054 10.5807 8.84414 11.1613 7.68285 10.5807V9.00811'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.18607 4.77423H4.19896C3.87961 4.77423 3.61832 4.51294 3.61832 4.19359V3.32262C3.61832 3.00326 3.87961 2.74197 4.19896 2.74197H5.14251'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7417 9.7901C11.7595 9.95405 11.9433 11.6548 11.9433 11.6548C11.9941 12.1701 11.595 12.6129 11.0796 12.6129H10.0054V13.7742C10.0054 14.2532 9.6135 14.6452 9.13447 14.6452H7.68286V16.0968C7.68286 16.5758 7.29092 16.9677 6.81189 16.9677H6.31109C5.63609 16.9677 5.10625 16.4016 5.1498 15.7266L5.30221 13.4258C5.33124 12.9685 5.71592 12.6129 6.17317 12.6129H7.10221'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
