import { CrmResponse, FormFieldModel } from 'common/interfaces';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { FieldValues } from 'react-hook-form';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { getRequestItem } from 'utils/get-response-data';
import { useVehicleDetailsClearCash } from '../use-clear-cash';
import { UseVehicleDetailsSubmitFormParams } from './use-submit-form.props';

export const useVehicleDetailsSubmitForm = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { clearCash } = useVehicleDetailsClearCash();
  const { fetch } = useFetch({
    loadingKey: 'vehicleDetailsEditForm',
    isShowSuccessSnackbar: true
  });

  const getSubmitModel = (values: FieldValues, formFields: FormFieldModel[]) => {
    const submitModel: Record<string, CrmResponse<any>> = {};

    Object.entries(values).forEach(([key, value]) => {
      const formField = formFields.find(item => item.name === key);

      if (!formField) {
        return console.error('Can not find formField!!!');
      }

      submitModel[key] = getRequestItem(value, formField.crmFieldType);
    });

    return submitModel;
  };

  const onSubmit = ({ values, formFields, callbackBeforeRequest }: UseVehicleDetailsSubmitFormParams) => {
    if (!fuhrpark?.new_fuhrparkid) {
      return console.error('Can not submit form. new_fuhrparkid is empty!!!');
    }

    if (callbackBeforeRequest && callbackBeforeRequest()) {
      return;
    }

    fetch({
      requestConfig: fleetRequestConfig.updateFleet(fuhrpark?.new_fuhrparkid, getSubmitModel(values, formFields)),
      callback: clearCash
    });
  };

  return { onSubmit };
};
