import classNames from 'classnames';
import { Meetinginfo } from 'components/after-tour-pop-up/after-tour-pop-up';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import MeetingInfo from './meeting-info';
import classes from './meeting-booking.module.scss';

interface Props {
  meetingTitle: string;
  showDescription?: boolean;
  meetingInfo: Meetinginfo;
  mainButtonTitle: string;
  mainButtonAction: () => void | Promise<void>;
  secondaryButtonTitle: string;
  secondaryButtonAction: () => void;
}

const MeetingModal = ({
  meetingInfo,
  meetingTitle,
  showDescription,
  mainButtonTitle,
  mainButtonAction,
  secondaryButtonTitle,
  secondaryButtonAction
}: Props) => {
  const user = useTypedSelector(state => state['app'].user);
  const overlay = useTypedSelector(state => state['app'].overlay);

  return (
    <div>
      <div className={classes.title_container}>
        <h3>{meetingTitle}</h3>
      </div>
      {showDescription && (
        <div>
          <span>
            Du erhälst in den nächsten Minuten eine Bestätigungsemail an
            <span style={{ fontWeight: 'bold' }}>{` ${user.email} `}</span>
            für
          </span>
        </div>
      )}

      <MeetingInfo meetingInfo={meetingInfo} />

      <div className={classes.action_container}>
        <button
          disabled={overlay}
          onClick={secondaryButtonAction}
          className={classNames(classes.action_button, 'button-mobexo__secondary', overlay && classes.disabled_button)}
        >
          {secondaryButtonTitle}
        </button>
        <button
          disabled={overlay}
          onClick={mainButtonAction}
          className={classNames(
            classes.action_button,
            'button-mobexo__main',
            classes.booking_button,
            overlay && classes.disabled_button
          )}
        >
          {mainButtonTitle}
        </button>
      </div>
    </div>
  );
};

export default MeetingModal;
