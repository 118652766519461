import axios from 'axios';
import { get, minBy } from 'lodash';
import uniqueSortedArray from '../utils/unique-sorted-array';
import { sum } from '../utils/summ';
import authService from './auth-service';
import { getResponseFormData } from '../utils/get-response-data';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function requestOfferDetailsAsync(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/LeasingDetails`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_bezugid: id
    },
    transformResponse(data) {
      const entityCollection = JSON.parse(data);
      entityCollection.data = getResponseFormData(entityCollection, [{ name: null, type: 'array' }]);
      const priceItems = entityCollection.data.map(entity => new PriceItem(entity)).sort((a, b) => sum(a) - sum(b));
      return priceItems;
    }
  });
}

export class PriceItem {
  constructor(data) {
    this.finanzrate = get(data, 'new_finanzratemobexo') || 0;
    this.mileagePerYear = get(data, 'uds_laufleistungpa') || 0;
    this.period = get(data, 'uds_laufzeit') || 0;

    this.maintenance = get(data, 'new_wartung_und_verschless_imobexo') || 0;
    this.maintenance_checkbox = false;
    this.maintenance_checkbox_disabled = get(data, 'uds_wartungverschleichexbox', false);

    this.tireReplacement = get(data, 'new_sommerreifen_mobexo') || 0;
    this.tireReplacement_checkbox = false;
    this.tireReplacement_checkbox_disabled = get(data, 'uds_reifenersatzcheckbox', false);

    this.winterWheelsStorage = get(data, 'new_winterreifen_mobexo') || 0;
    this.winterWheelsStorage_checkbox = false;
    this.winterWheelsStorage_checkbox_disabled = get(data, 'uds_winterreifencheckbox', false);

    this.wheelStorageReplacement = get(data, 'uds_radereinlagerungundwechsel') || 0;
    this.wheelStorageReplacement_checkbox = false;
    this.wheelStorageReplacement_checkbox_disabled = get(data, 'uds_einlagerung', false);

    this.insurance = get(data, 'new_versicherung_mobexo') || 0;
    this.insurance_checkbox = false;
    this.insurance_checkbox_disabled = get(data, 'uds_versicherungcheckbox', false);

    this.tax = get(data, 'new_kfz_steuer_mobexo') || 0;
    this.tax_checkbox = false;
    this.tax_checkbox_disabled = get(data, 'uds_kfzsteuercheckbox', false);

    this.gez = get(data, 'new_gez_mobexo') || 0;
    this.gez_checkbox = false;
    this.gez_checkbox_disabled = get(data, 'uds_gezcheckbox', false);

    this.gap = get(data, 'uds_gap') || 0;
    this.gap_checkbox = false;
    this.gap_checkbox_disabled = get(data, 'uds_isgap_versicherung_anbieten', false);

    this.wkrleasingenthalten_checkbox = get(data, 'uds_wkrleasingenthalten', false);

    this.max_nettolistenpreis = get(data, 'uds_max_nettolistenpreis') || 0;
    this.marge = 0;
    this.mobexoContribution = 0;
  }

  static fromFahr(data) {
    return {
      finanzrate: get(data, 'uds_finanzrate') || 0,
      mileagePerYear: data.annualMileage,
      period: data.leasingPeriod,

      insurance: get(data, 'uds_comprehensive_insurance') || 0,
      insurance_checkbox: false,
      insurance_checkbox_disabled: get(data, 'uds_vollkaskoversicherungcheckbox', false),

      tax: get(data, 'uds_engine_tax') || 0,
      tax_checkbox: false,
      tax_checkbox_disabled: get(data, 'uds_kfzsteuercheckbox', false),

      gez: get(data, 'uds_gez_fee') || 0,
      gez_checkbox: false,
      gez_checkbox_disabled: get(data, 'uds_gezgebhrcheckbox', false),

      tireReplacement: get(data, 'uds_tire_replacement_cost') || 0,
      tireReplacement_checkbox: false,
      tireReplacement_checkbox_disabled: get(data, 'uds_reifenersatzcheckbox', false),

      winterWheelsStorage: get(data, 'uds_wheels_storage_cost') || 0,
      winterWheelsStorage_checkbox: false,
      winterWheelsStorage_checkbox_disabled: get(data, 'uds_winterrderinkllagerungcheckbox', false),

      wheelStorageReplacement: get(data, 'uds_radereinlagerungundwechsel') || 0,
      wheelStorageReplacement_checkbox: false,
      wheelStorageReplacement_checkbox_disabled: get(data, 'uds_einlagerung', false),

      maintenance: get(data, 'uds_maintenance_cost') || 0,
      maintenance_checkbox: false,
      maintenance_checkbox_disabled: get(data, 'uds_wartunginspektioncheckbox', false),

      wkrleasingenthalten: get(data, 'uds_wkrleasingenthalten', false),

      max_nettolistenpreis: get(data, 'uds_max_nettolistenpreis') || 0,
      marge: get(data, 'uds_marge') || 0,
      mobexoContribution: get(data, 'uds_mobexo_contribution') || 0
    };
  }

  static find(arr, mileage, period) {
    const item = arr.find(item => item.mileagePerYear === +mileage && item.period === +period);
    return item ? item : false;
  }

  static getPeriods(priceItems) {
    return uniqueSortedArray(priceItems.map(x => x.period));
  }

  static getMileagesForPeriod(priceItems, period) {
    return uniqueSortedArray(priceItems.filter(x => x.period === period).map(x => x.mileagePerYear));
  }

  static getPriceItemWithLowestMileage(priceItems, period) {
    const items = priceItems.filter(x => x.period === period);
    return minBy(items, x => x.mileagePerYear);
  }

  static getPriceItemByPeriod(priceItems, lastPriceItem, period) {
    return priceItems.find(
      priceItem => priceItem.period === period && priceItem.mileagePerYear === lastPriceItem.mileagePerYear
    );
  }
}
