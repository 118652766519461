export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date);
  if (!isValidDate(newDate)) {
    return null;
  }
  let offset = newDate.getTimezoneOffset() / 60;
  let hours = newDate.getHours();
  newDate.setHours(hours - offset);

  return newDate.toISOString().slice(0, -1);
}

export function convertLocalDateToUTC(date) {
  const newDate = new Date(date);
  let offset = newDate.getTimezoneOffset() / 60;
  let hours = newDate.getHours();
  newDate.setHours(hours + offset);

  return newDate.toISOString();
}

export function getDateString(value) {
  return value
    ? new Date(value).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
    : '-';
}
