import styled, { css } from 'styled-components';

export const StyledDroplistItem = styled.div`
  display: flex;
  min-height: 30px;
  align-items: center;
  padding-left: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey600};
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
  }
`;

interface StyledPlateNumberDroplistItemProps {
  isActive?: boolean;
}

export const StyledPlateNumberDroplistItem = styled.div<StyledPlateNumberDroplistItemProps>`
  display: flex;
  min-height: 30px;
  align-items: center;
  padding-left: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey600};

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        content: '';
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.green};
        margin-left: 16px;
      }
    `};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
  }
`;

export const StyledAddressLine = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 10px;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
  }
`;
