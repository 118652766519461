import React, { useMemo } from 'react';
import Proptypes from 'prop-types';
import { CircleCheckMark } from '../icons/circle-check-mark';
import classes from './steps-process.module.scss';
import classNames from 'classnames';
import useWindowSize from 'hooks/use-window-size';
import { useTheme } from 'styled-components';

export const StepIcon = ({
  status,
  isNeedUnderline,
  onStepClick,
  indx,
  id,
  iconColors: iconColorsProp,
  fieldClassName,
  distanceBetweenIconAndDivider,
  stepDividerHeight,
  vertical,
  stepIconStyle = {}
}) => {
  const { windowWidth } = useWindowSize();
  const theme = useTheme();

  const isMobile = windowWidth <= 550;

  const getDividerStyle = iconColor => ({
    background: `linear-gradient(${
      vertical || isMobile ? 0 : -90
    }deg, #FFF 0%, #FFF 0%, ${iconColor} 75%, ${iconColor} 100%)`,
    height: `${stepDividerHeight}px`
  });

  const getContainerStyle = () => ({
    padding: `${isMobile ? 0 : distanceBetweenIconAndDivider / 2}px 0`,
    ...stepIconStyle
  });

  const iconColors = useMemo(() => {
    if (iconColorsProp) {
      return iconColorsProp;
    }
    if (isMobile) {
      return {
        done: theme.colors.lightBlue,
        active: theme.colors.lightBlue,
        inactive: theme.colors.blue
      };
    }

    return {
      done: theme.colors.blue,
      active: theme.colors.blue,
      inactive: theme.colors.lightBlue
    };
  }, [windowWidth]);

  switch (status) {
    case 'done':
      return (
        <div id={id + 'Done'} className={classNames(classes.step_item, fieldClassName)} style={getContainerStyle()}>
          <CircleCheckMark
            onClick={onStepClick ? () => onStepClick(indx) : () => {}}
            className={classNames(classes.circle_checkmark__icon, onStepClick && 'cursor-pointer')}
            color={iconColors.done}
            width='24'
            height='24'
          />
          {isNeedUnderline ? (
            <div className={classes.underline_filled} style={getDividerStyle(iconColors.done)} />
          ) : null}
        </div>
      );

    case 'active':
      return (
        <div id={id + 'Active'} className={classNames(classes.step_item, fieldClassName)} style={getContainerStyle()}>
          <div className={classes.circle} style={{ borderColor: iconColors.active }} />
          {isNeedUnderline ? (
            <div className={classes.underline_filled} style={getDividerStyle(iconColors.active)} />
          ) : null}
        </div>
      );

    case 'inactive':
      return (
        <div id={id + 'Inactive'} className={classNames(classes.step_item, fieldClassName)} style={getContainerStyle()}>
          <div className={classes.circle} style={{ borderColor: iconColors.inactive }} />
          {isNeedUnderline ? (
            <div className={classes.underline_filled} style={getDividerStyle(iconColors.inactive)} />
          ) : null}
        </div>
      );

    default:
      return null;
  }
};
