import React from 'react';
import { damage } from '../utils';

const CarDamageTopIcon = ({ watch, chooseDamage }) => {
  return (
    <svg width='158' height='415' viewBox='0 0 158 415' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        onClick={() => chooseDamage(damage[3].color, 3)}
        d='M156.082 105.85C151.642 137.842 145.401 171.086 144.307 176.909C144.307 176.971 144.307 177.034 144.242 177.034C144.114 177.41 143.663 177.66 143.213 177.535C128.221 173.403 103.513 171.086 79.0624 170.836C54.6119 171.024 29.904 173.403 14.912 177.535C14.4616 177.66 14.0112 177.41 13.8825 176.971C13.8825 176.909 13.8825 176.909 13.8825 176.909C12.7887 171.086 6.54735 137.842 2.10765 105.85C1.91462 104.222 1.85028 102.657 2.04331 101.092C2.75109 93.3914 7.83424 86.5672 15.4911 83.6247C34.022 76.3623 56.3492 72.2303 78.8694 72.0425C78.9338 72.0425 79.0624 72.0425 79.1268 72.0425C79.1911 72.0425 79.3198 72.0425 79.3842 72.0425C101.904 72.2303 124.232 76.3623 142.762 83.6247C150.355 86.6298 155.438 93.454 156.21 101.092C156.339 102.657 156.339 104.285 156.082 105.85Z'
        fill={watch('damageList')[3].color}
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[14].color, 14)}
        d='M144.695 176.848C138.325 188.606 135.172 304.191 138.325 374.122V377.378C109.177 383.576 55.193 383.701 20.4475 377.19V374.06C23.536 304.128 20.4475 188.121 14.0775 176.786C14.0775 176.473 14.0132 176.222 14.0132 175.909C14.1419 176.348 14.5923 176.598 15.0427 176.473C29.9704 172.403 54.4852 170.087 78.8714 169.774C79.0644 169.774 79.2574 169.774 79.3861 169.774C79.5791 169.774 79.7722 169.774 79.9008 169.774C104.287 170.024 128.802 172.341 143.73 176.473C144.18 176.598 144.63 176.348 144.759 175.909C144.695 176.222 144.695 176.535 144.695 176.848Z'
        fill={watch('damageList')[14].color}
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[4].color, 4)}
        d='M53.5447 224.251H79.3858H105.227C123.59 224.251 124.092 224.899 126.678 212.199L127.772 206.195C128.41 201.798 128.93 199.992 126.678 199.066C111.879 193.14 91.1607 190.362 79.3858 190.362C67.6108 190.362 46.8923 193.14 32.0933 199.066C29.8413 199.992 30.3616 201.798 30.9995 206.195L32.0933 212.199C34.6792 224.899 35.1813 224.251 53.5447 224.251Z'
        fill={watch('damageList')[4].color}
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[13].color, 13)}
        d='M138.261 377.376V391.4C138.261 403.421 128.159 413.187 115.676 413.187H42.9679C30.4852 413.187 20.3833 403.421 20.3833 391.4V377.251C55.1288 383.699 109.113 383.574 138.261 377.376Z'
        fill={watch('damageList')[13].color}
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[0].color, 0)}
        d='M128.223 5.99505C131.955 6.62112 135.043 9.06278 136.394 12.3809C153.767 55.0161 156.276 100.719 156.276 100.782H156.148C155.376 93.1437 150.292 86.3195 142.7 83.3144C124.169 76.052 101.842 71.92 79.3216 71.7322H78.8068C56.2866 71.92 33.9594 76.052 15.4285 83.3144C7.77165 86.2569 2.68852 93.0811 1.98075 100.782H1.85205C1.85205 100.782 4.36144 55.0161 21.7342 12.3809C23.0854 9.00018 26.2382 6.62112 29.9058 5.99505C38.7852 4.49249 56.737 1.98822 78.2921 1.73779H79.0642H79.8363C101.391 1.98822 119.343 4.49249 128.223 5.99505Z'
        fill={watch('damageList')[0].color}
        stroke='white'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default CarDamageTopIcon;
