import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function createRahmenvertrage(ContactId, Rahmenvertrage) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/createrahmenvertrage`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      Rahmenvertrage
    }
  });
}

export async function createRahmenvertrageNew(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/createrahmenvertrageNew`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getCompaniesByType(CustomerTypeCode) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/companiesbytype`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      CustomerTypeCode
    }
  });
}

export async function getCompaniesByTypes(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/companiesbytypes`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getKostenstellen(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/kostenstellelist`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getRahmenvertragen(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/rahmenvertragelist`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getRahmenvertrageFiles(rahmenvertrageId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getRahmenvertrageFiles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      rahmenvertrageId
    }
  });
}

export async function getSparteCompanies(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/spartecompanieslist`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getUserCompanyDrivers(
  pagination = { start: 1, number: 1000 },
  searchParam,
  sortParam,
  isCompactList = false,
  onlyValidEmail = false
) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/driverlist`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      searchParam,
      pagination,
      sortParam,
      CompactListAllDriversMode: isCompactList,
      OnlyValidEmail: onlyValidEmail
    }
  });
}

export async function getDriversWithCar(sparteId, activeOnly) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/driver/getDriversWithCar`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      sparteId,
      activeOnly
    }
  });
}

export async function setActiveIban(Iban) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/setactiveiban`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Iban
    }
  });
}

export async function getRemindersSettings(EntityId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/GetRemindersSettings`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      EntityId
    }
  });
}

export async function getCompanyCarModels(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getCarModels`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}

export async function updateCompanyCarModels(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/updateCarModels`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getLeasinggesellschaften(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getLeasinggesellschaften`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}

export async function getAutohausesCompanies(columns) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getAutohausen`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      columns
    }
  });
}

export async function getLeaseData(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getLeaseData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}

export async function getAddressData(accountIds) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getAddressData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountIds
    }
  });
}

export async function getParentCompany(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/GetParentCompany`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      id: accountId
    }
  });
}

export async function getCompanyData(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getById`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}

export async function getCostCentersFilterData() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/company/GetKostenstelleFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getCostCentersList(tableParams) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/GetKostenstellePaginationList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: tableParams
  });
}

export async function getUserCars(fahrerId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getFahrerInfo`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      fahrerId
    }
  });
}

export async function getCompanyContactList() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getCompanyContactList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}

export async function getParentCompanyContactList() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/company/getParentCompanyContactList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
}
