import React, { useState } from 'react';
import {
  SnackbarComponentProps,
  SnackbarComponentTypes,
  SnackbarState
} from 'components/snackbar-component/snackbar-component.props';
import {
  StyledCloseIcon,
  StyledSnackbarComponent,
  StyledSnackbarComponentContainer
} from 'components/snackbar-component/snackbar-component.styled';
import { CircleCheckMark } from 'components/icons/circle-check-mark';
import { DefaultTheme, useTheme } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { useDispatch } from 'react-redux';
import { setSnackbarComponent } from 'actions/app_action';
import { useTypedSelector } from 'hooks/use-typed-selector';

const getState = (type: SnackbarComponentTypes, theme: DefaultTheme) => {
  switch (type) {
    case SnackbarComponentTypes.Success:
      return {
        backgroundColor: theme.colors.extraLightGreen,
        bottomBorderColor: theme.colors.green,
        image: <CircleCheckMark width={16} height={16} color={theme.colors.darkGreen} />
      };
    default:
      return {
        backgroundColor: theme.colors.extraLightGreen,
        bottomBorderColor: theme.colors.green,
        image: <CircleCheckMark width={16} height={16} color={theme.colors.darkGreen} />
      };
  }
};

const SnackbarComponent = ({ type, component, open }: SnackbarComponentProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const snackbarComponent = useTypedSelector(state => state['app'].snackbarComponent);

  const [snackbarState, setSnackbarState] = useState<SnackbarState>(getState(type, theme));

  const close = () => {
    dispatch(setSnackbarComponent({ ...snackbarComponent, open: false }));
  };

  return (
    <StyledSnackbarComponentContainer open={open}>
      <StyledSnackbarComponent
        id='SnackbarComponentContainer'
        backgroundColor={snackbarState.backgroundColor}
        bottomBorderColor={snackbarState.bottomBorderColor}
      >
        <Flex width='16px'>{snackbarState.image}</Flex>
        {component}
        <Flex width='20px'>
          <StyledCloseIcon strokeWidth='1' onClick={close} color={theme.colors.darkGreen} width='20' height='20' />
        </Flex>
      </StyledSnackbarComponent>
    </StyledSnackbarComponentContainer>
  );
};

export default SnackbarComponent;
