import React, { memo, useEffect, useMemo, useState } from 'react';
import { CompanyFilterItem, CompanyFilterProps } from './company-filter.props';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { User, UserCompany } from 'common/interfaces';
import { Flex, Text } from 'common/common-components.styled';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  StyledChildCompanySwitcher,
  StyledDropDownIconGreen
} from 'components/dashboard-component-chart/company-filter/company-filter.styled';
import { StyledDropDownIcon } from 'components/icons/drop-down-icon';
import { getDeviceType } from 'utils/get-device-type';
import { DeviceTypes } from '../../../constants';
import { PrefixForId } from 'common/enums';
import { removeSpaces } from 'utils/remove-spaces';
import { ALL_COMPANIES_LABEL } from './utils';
import useUnsavedChangesLink from 'hooks/use-unsaved-changes/use-unsaved-changes-link';
import { useGetChildCompaniesQuery } from 'hooks/react-query/company/get-child-companies/use-get-child-companies.query';

const deviceType = getDeviceType();

const CompanyFilter = ({ activeCompany, setActiveCompany, isShowAllCompanyOption = false }: CompanyFilterProps) => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const { unsavedChangesHandler } = useUnsavedChangesLink();

  const [childCompany, setChildCompany] = useState({ id: '', name: '' });

  const { childCompaniesQuery } = useGetChildCompaniesQuery(user.companyId);
  const childCompanies =
    childCompaniesQuery?.result?.map(item => ({ id: item.accountid || '', name: item.name || '' })) || [];

  const isMobile = deviceType === DeviceTypes.Mobile;
  const isChildCompanyActive = childCompany.id !== '';
  const numberOfChildCompaniesAllowed = isShowAllCompanyOption ? 1 : 2;

  const companies = useMemo(() => {
    const companiesArr: CompanyFilterItem[] = [
      {
        label: user.companyName,
        value: user.companyId,
        right: '65'
      }
    ];

    if (childCompanies.length && isShowAllCompanyOption) {
      companiesArr.push({
        label: null,
        value: null,
        right: childCompanies.length === numberOfChildCompaniesAllowed ? '65' : '50'
      });
    }
    if (childCompanies.length <= numberOfChildCompaniesAllowed) {
      childCompanies.forEach(childCompany => {
        companiesArr.push({ label: childCompany.name || '-', value: childCompany.id || '-', right: '65' });
      });
    }

    return companiesArr;
  }, [user, childCompanies.length]);

  useEffect(() => {
    if (activeCompany.id) {
      const { id, name } = getDefaultChildCompany(activeCompany.id, childCompanies);
      id && setChildCompany({ id, name });
    }
  }, [activeCompany.id]);

  const setActiveCompanyHandler = (value: { id: string | null; name: string | null }, isChild: boolean) => {
    unsavedChangesHandler(() => {
      if (value.id === activeCompany.id) {
        return;
      }

      if (!isChild) {
        setChildCompany({ id: '', name: '' });
      } else {
        value && setChildCompany({ id: value.id || '', name: value.name || '' });
      }

      setActiveCompany(value);
    });
  };

  const companySwitcherHandler = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    const company = childCompanies.find(company => company.id === value);

    company && setActiveCompanyHandler({ id: company.id || '-', name: company.name || '-' }, true);
  };

  return (
    <Flex minHeight='42px' align={isMobile ? 'flex-start' : 'center'} direction={isMobile ? 'column' : 'row'}>
      {companies.map(company => (
        <Text
          id={PrefixForId.Filter + removeSpaces(company.label || ALL_COMPANIES_LABEL)}
          key={company.value}
          pointer
          bold={company.value === activeCompany.id}
          color={company.value === activeCompany.id ? 'green' : 'blue'}
          onClick={() => setActiveCompanyHandler({ id: company.value as string, name: company.label }, false)}
          right={company.right}
          padding={isMobile ? '5px 15px' : '0'}
        >
          {company.label || ALL_COMPANIES_LABEL}
        </Text>
      ))}

      {childCompanies.length > numberOfChildCompaniesAllowed && (
        <StyledChildCompanySwitcher
          id={PrefixForId.Filter + PrefixForId.ChildCompanies}
          IconComponent={isChildCompanyActive ? StyledDropDownIconGreen : StyledDropDownIcon}
          value={childCompany}
          renderValue={() => (
            <Text as='span' bold={isChildCompanyActive} color={isChildCompanyActive ? 'green' : 'blue'}>
              {isChildCompanyActive
                ? childCompanies.find(company => company.id === childCompany.id)?.name
                : 'Tochterunternehmen'}
            </Text>
          )}
          onChange={companySwitcherHandler}
        >
          {childCompanies.map(option => (
            <MenuItem
              key={option.id}
              value={option.id}
              id={PrefixForId.Filter + PrefixForId.ChildCompanies + removeSpaces(option.name)}
            >
              {option.name}
            </MenuItem>
          ))}
        </StyledChildCompanySwitcher>
      )}
    </Flex>
  );
};

const getDefaultChildCompany = (activeCompanyId: string | null, childCompanies: UserCompany[]) => {
  return (
    childCompanies.find(company => company.id === activeCompanyId) || {
      id: '',
      name: ''
    }
  );
};

export default memo(CompanyFilter);
