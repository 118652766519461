import styled from 'styled-components';
import { DownArrowIcon } from 'components/icons/down-arrow-icon';
import { Button, Flex } from 'common/common-components.styled';

interface StyledDownArrowIconProps {
  rotate: boolean;
}

export const StyledDownArrowIcon = styled(DownArrowIcon)<StyledDownArrowIconProps>`
  transform: rotate(${({ rotate }) => (rotate ? '180deg' : '0')});
  transition: transform 0.2s ease-out;

  & path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledActionButton = styled(Button)`
  border-radius: 3px 0 0 3px;
  border-right: 1px solid ${({ theme }) => theme.colors.darkBlue} !important;
`;

export const StyledSwitcherButton = styled(Button)`
  border-radius: 0 3px 3px 0;
  padding: 6px 10px;

  &:hover {
    box-shadow: 1px 1px 5px ${({ theme }) => theme.colors.grey500};
    text-decoration: none;
  }
`;

interface StyledSpinnerContainerProps {
  minHeight: string;
}

export const StyledSpinnerContainer = styled.div<StyledSpinnerContainerProps>`
  position: relative;
  min-height: ${({ minHeight }) => minHeight};
`;

export const StyledLeasingPriceContainer = styled(Flex)`
  position: relative;
  min-height: 100px;
`;
