import React from 'react';
import { DealerContactInfoProps } from './dealer-contact-info.props';
import { Flex, Text } from 'common/common-components.styled';
import { useCompanyDetails } from 'pages/details/react-query/use-company-details';
import { useGetUser } from 'hooks/react-query/use-get-user/use-get-user';

const DealerContactInfo = ({ dealerCompanyId, dealerContactId }: DealerContactInfoProps) => {
  const { userData } = useGetUser(dealerContactId || '', false);
  const { companyDetails: dealerCompanyDetails } = useCompanyDetails(dealerCompanyId, 'get-dealer-company-details');

  return (
    <div style={{ fontSize: '14px' }}>
      <Flex justify='space-between'>
        <Text bold color='blue'>
          Händler
        </Text>
        {dealerCompanyId ? (
          <Flex align='flex-end' direction='column'>
            <Text>{dealerCompanyDetails?.name || <i>Nicht angegeben</i>}</Text>
            <Text>{dealerCompanyDetails?.address1_line1 || <i>Nicht angegeben</i>}</Text>
            <Text bottom='10'>
              {dealerCompanyDetails?.address1_postalcode || dealerCompanyDetails?.address1_city ? (
                `${dealerCompanyDetails?.address1_postalcode || ''} ${dealerCompanyDetails?.address1_city || ''}`
              ) : (
                <i>Nicht angegeben</i>
              )}
            </Text>

            {dealerCompanyDetails?.telephone1 ? (
              <a style={{ color: '#373B4D' }} href={`tel:${dealerCompanyDetails.telephone1}`}>
                <Text as='span'>{dealerCompanyDetails.telephone1}</Text>
              </a>
            ) : (
              <i>Nicht angegeben</i>
            )}
          </Flex>
        ) : (
          <i>Nicht angegeben</i>
        )}
      </Flex>

      <Flex top='20' justify='space-between'>
        <Text bold color='blue'>
          Ansprechpartner
        </Text>
        {dealerContactId ? (
          <Flex align='flex-end' direction='column'>
            <Text>{userData?.fullname || <i>Nicht angegeben</i>}</Text>

            {userData?.emailaddress1 ? (
              <a style={{ color: '#373B4D' }} href={`mailto:${userData.emailaddress1}`}>
                <Text as='span'>{userData.emailaddress1}</Text>
              </a>
            ) : (
              <i>Nicht angegeben</i>
            )}

            {userData?.telephone1 ? (
              <a style={{ color: '#373B4D' }} href={`tel:${userData.telephone1}`}>
                <Text as='span'>{userData.telephone1}</Text>
              </a>
            ) : (
              <i>Nicht angegeben</i>
            )}
          </Flex>
        ) : (
          <i>Nicht angegeben</i>
        )}
      </Flex>
    </div>
  );
};

export default DealerContactInfo;
