import { AxiosRequestConfig } from 'axios';
import { CheckDownloadFlagPayload, GeneratePdfProps, GetExcelFileProps, GetZipFileProps } from './vehicle-cost.types';
import { getRequestItem } from 'utils/get-response-data';
import { CrmDataTypes } from 'common/enums';

class VehicleCostRequestConfig {
  generatePdf = ({ User, Month, Year, Kennzeichen }: GeneratePdfProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzuegkosten/GeneratePdf',
    data: {
      User,
      Month,
      Year,
      Kennzeichen: getRequestItem(Kennzeichen, CrmDataTypes.String)
    }
  });

  getFileForExcel = (data: GetExcelFileProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzuegkosten/generateexcel',
    data
  });

  generateZipVehicleCosts = (data: GetZipFileProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fahrzuegkosten/generateZipFahrzeugkosten',
    responseType: 'blob',
    data
  });

  checkDownloadFlag = (data: CheckDownloadFlagPayload): AxiosRequestConfig => ({
    method: 'POST',
    url: 'Fahrzuegkosten/CheckFahrzeugkostenDownloadFlag',
    data
  });
}

export const vehicleCostRequestConfig = new VehicleCostRequestConfig();
