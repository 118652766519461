import React from 'react';

import classes from './comparison-card.module.scss';

export default function ComparisonCard(props) {
  return (
    <div className={classes.card_wrapper}>
      {props.car ? (
        <div className={classes.card}>
          <img
            onClick={() => props.deleteCar(props.car.uds_fahrzeugangeboteid)}
            src='/assets/images/close_comparison.png'
            alt='close_comparison'
          />
          <div className={classes.card_image} style={{ backgroundImage: `url(${props.car.image})` }} />
          <p>{props.car.uds_title}</p>
        </div>
      ) : (
        <div className={classes.empty_card}>{/*<img src="assets/images/more.png" alt="more"/>*/}</div>
      )}
    </div>
  );
}
