import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Flex, Text, Button } from 'common/common-components.styled';
import { StyledInput, StyledListItem } from '../delegate-task/delegate-task.styled';
import { StyledDatePicker, StyledDropdown } from './remind-later.styled';
import { differenceInDays, parseISO, addDays } from 'date-fns';
import { PrefixForId } from '../../../../common/enums';
import { DateBlueIcon } from 'components/icons/date-blue-icon';
import { StyledTextFieldWithoutBorders } from 'components/form-hook/form-field/form-field.styled';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DATE_ERROR_MESSAGE } from 'components/form-hook/form-field/utils';

interface DropdownOptionProps {
  label: string;
  daysCount: number | undefined;
  disabled: boolean;
}

interface RemindLaterProps {
  scheduleend: string;
  taskId: string;
  onSubmitPostpone: (taskId: string, date: string) => void;
  onCloseModal: () => void;
}

const isDropdownItemDisabled = (scheduleend: string, daysCount: number) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const scheduleEndParsed = parseISO(scheduleend);
  scheduleEndParsed.setHours(0, 0, 0, 0);

  return differenceInDays(scheduleEndParsed, today) < daysCount;
};

const RemindLater = ({ scheduleend, taskId, onSubmitPostpone, onCloseModal }: RemindLaterProps) => {
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    control,
    formState: { errors },
    setError
  } = useForm();
  const [visible, setVisible] = useState(false);
  const [currentOption, setCurrentOption] = useState<DropdownOptionProps>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dropdownRef = useRef<any>(null);
  const today = new Date().setHours(0, 0, 0, 0);
  const currentDateValue = watch('reminddate');

  const MODAL_BUTTON_ID_PREFIX = PrefixForId.Modal + PrefixForId.Button;

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  useEffect(() => {
    if (currentOption?.daysCount) {
      setIsButtonDisabled(false);
    } else {
      if (!currentDateValue || !isEmpty(errors)) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [currentOption, currentDateValue, errors.reminddate]);

  useEffect(() => {
    const minDateError = currentDateValue && currentDateValue.isBefore(addDays(today, 1));
    const maxDateError = currentDateValue && currentDateValue.isAfter(parseISO(scheduleend));

    if ((currentDateValue && !currentDateValue.isValid()) || minDateError || maxDateError) {
      setError('reminddate', {
        type: 'manual',
        message: DATE_ERROR_MESSAGE
      });
    } else {
      clearErrors('reminddate');
    }
  }, [currentDateValue]);

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'In 2 Tagen',
        daysCount: 2,
        disabled: isDropdownItemDisabled(scheduleend, 2)
      },
      {
        label: 'In 1 Woche',
        daysCount: 7,
        disabled: isDropdownItemDisabled(scheduleend, 7)
      },
      {
        label: 'In 2 Wochen',
        daysCount: 14,
        disabled: isDropdownItemDisabled(scheduleend, 14)
      },
      {
        label: 'In 1 Monat',
        daysCount: 30,
        disabled: isDropdownItemDisabled(scheduleend, 30)
      },
      {
        label: 'Datum auswählen',
        daysCount: undefined,
        disabled: false
      }
    ],
    []
  );

  const selectItem = useCallback(
    (item: DropdownOptionProps) => {
      if (!item.disabled) {
        clearErrors('remind');
        setValue('remind', item);
        setCurrentOption(item);
        setVisible(false);
      }
    },
    [clearErrors, setValue, setCurrentOption, setVisible]
  );

  const handleClick = useCallback(
    (e: any) => {
      if (dropdownRef.current.contains(e.target)) {
        return;
      }
      setVisible(false);
    },
    [setVisible]
  );

  const getPostponeDate = useCallback(() => {
    if (currentOption?.daysCount) {
      return moment().add(currentOption.daysCount, 'days');
    }
    return currentDateValue;
  }, [currentOption, currentDateValue]);

  return (
    <form style={{ margin: 0 }} onSubmit={handleSubmit(() => onSubmitPostpone(taskId, getPostponeDate()))}>
      <Flex direction='column' style={{ position: 'relative' }}>
        <Text bottom='30'>Bitte wähle, wann an die Aufgabe erinnert werden soll</Text>
        <StyledInput
          type='options'
          {...register('remind', { required: 'Bitte gib Kennzeichen ein.' })}
          placeholder='Bitte auswählen'
          value={currentOption?.label}
          onFocus={() => {
            setVisible(true);
          }}
          autoComplete='off'
          readOnly
          id={PrefixForId.Modal + PrefixForId.Input + PrefixForId.FormOption + PrefixForId.FormDate}
        />
        <StyledDropdown style={{ visibility: visible ? 'visible' : 'hidden' }} ref={dropdownRef}>
          {visible && (
            <ul id={PrefixForId.Modal + PrefixForId.FormSelect}>
              {dropdownOptions.map((el: DropdownOptionProps, index) => (
                <StyledListItem
                  key={el.label}
                  onClick={() => selectItem(el)}
                  disabled={el.disabled}
                  id={PrefixForId.Modal + PrefixForId.FormOption + index}
                >
                  <div className='item_text1'>{el.label}</div>
                </StyledListItem>
              ))}
            </ul>
          )}
        </StyledDropdown>
        {currentOption?.label === 'Datum auswählen' && (
          <Controller
            control={control}
            name='reminddate'
            rules={{ required: !currentDateValue }}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledDatePicker
                  components={{
                    OpenPickerIcon: () => <DateBlueIcon id={PrefixForId.DatePicker + PrefixForId.Open + 'Icon'} />
                  }}
                  minDate={addDays(today, 1)}
                  maxDate={parseISO(scheduleend)}
                  value={value || null}
                  onChange={onChange}
                  PopperProps={{
                    id: 'DatePicker' + 'PopperContainer'
                  }}
                  renderInput={({ inputProps, ...restParams }) => (
                    <StyledTextFieldWithoutBorders
                      {...restParams}
                      error={!!errors.reminddate}
                      inputProps={{
                        ...inputProps,
                        placeholder: 'TT . MM . JJJJ',
                        id: PrefixForId.Modal + PrefixForId.FormDate,
                        error: !!errors.reminddate
                      }}
                    />
                  )}
                />
                <Text left='5' as='span' size='12' color='pink' hidden={isEmpty(errors)}>
                  {errors.reminddate?.message as string}
                </Text>
              </LocalizationProvider>
            )}
          />
        )}
        <Flex justify='flex-end' top='30'>
          <Button secondary right='10' onClick={onCloseModal} id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Close}>
            Abbrechen
          </Button>
          <Button disabled={isButtonDisabled} type='submit' id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Submit}>
            Bestätigen
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default RemindLater;
