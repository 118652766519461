import { connect } from "react-redux";

import Header from "./header-view";

const mapStateToProps = (state) => ({
  user: state.app.user,
  isMenuOpen: !!state.app.menu.component
});

export default connect(mapStateToProps, null)(Header);
