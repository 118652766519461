import { AxiosRequestConfig } from 'axios';

class FuelCardRequestConfig {
  getTankkartenProductsByCompany = (provider: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'tankkarte/GetTankkartenProductsByCompany',
    data: {
      provider
    }
  });

  generatePdf = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'tankkarte/exportrecords',
    data: {}
  });

  checkContract = (): AxiosRequestConfig => ({
    method: 'GET',
    url: 'tankkarte/checkcontract'
  });
}

export const fuelCardRequestConfig = new FuelCardRequestConfig();
