import React, { useState } from 'react';
import { PreparedFilesForDownloadState, SortingDocumentsTabsProps } from './sorting-documents-tabs.props';
import { Tab } from 'components/tab/tab.controller';
import { Flex } from 'common/common-components.styled';
import Spinner from 'components/spinner';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import { useSortingDocumentsTabDataSetUp } from './hooks/use-tab-data-set-up';
import { useSortingDocumentsDownload } from './hooks/use-download';
import { SortingDocumentsTabsContext } from './sorting-documents-tabs.context';
import { FileFormatTypes } from 'common/enums';
import { getFileExtension } from 'utils/file/get-file-extension';
import { useLetterModal } from 'components/letter-modal/hooks/use-letter-modal';
import LetterModal from 'components/letter-modal';

export const SortingDocumentsTabs = ({ data, isLoading, zipArchiveName, ...restProps }: SortingDocumentsTabsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [preparedFilesForDownload, setPreparedFilesForDownload] = useState<PreparedFilesForDownloadState>({});

  const { downloadZip } = useSortingDocumentsDownload(
    zipArchiveName,
    preparedFilesForDownload,
    setPreparedFilesForDownload
  );
  const { tabData, tabProps, documentDataEntries } = useSortingDocumentsTabDataSetUp(data, isExpanded, downloadZip);

  const getDataForLatterModal = () => {
    return Object.values(data)
      .map(fileList =>
        fileList.filter(
          fileItem =>
            fileItem.filePath &&
            [FileFormatTypes.EML, FileFormatTypes.MSG].includes(getFileExtension(fileItem.filePath))
        )
      )
      .flat();
  };

  const {
    isLetterModalOpened,
    selectedLetter,
    showLetterModal,
    allLetters,
    selectedLetterIndex,
    handleClose,
    handleSwitchLetter
  } = useLetterModal(getDataForLatterModal());

  if (isLoading) {
    return (
      <Flex width='100%' height='100%' align='center' justify='center'>
        <Spinner />
      </Flex>
    );
  }

  return (
    <SortingDocumentsTabsContext.Provider
      value={{ ...restProps, preparedFilesForDownload, setPreparedFilesForDownload, showLetterModal }}
    >
      <Flex direction='column'>
        <Tab type='accordion' ui='form' data={tabData} isRemoveScrollOnToggle tabProps={tabProps} />
        {documentDataEntries.length > 5 && (
          <ShowMoreButton top='30' isExpanded={isExpanded} onClick={() => setIsExpanded(prev => !prev)} />
        )}

        <LetterModal
          isOpen={isLetterModalOpened}
          selectedLetter={selectedLetter}
          allLetters={allLetters}
          selectedLetterIndex={selectedLetterIndex}
          onClose={handleClose}
          handleSwitchLetter={handleSwitchLetter}
        />
      </Flex>
    </SortingDocumentsTabsContext.Provider>
  );
};
