import React from 'react';
import { HistorySectionItem } from '../../section-item/section-item';
import { useVehicleDamageHistoryQuery } from 'hooks/react-query/fleet/damage-history/use-damage-history.query';
import { StepsProcess } from 'components/steps-process';
import { StepStatus } from 'components/steps-process/steps-process.props';
import { VehicleDetailsHistoryEmptyPlaceholder } from '../../placeholder/empty/empty';
import { VehicleDetailsDamageHistoryStepDescription } from './components/step-description/step-description';
import { Flex } from 'common/common-components.styled';
import { VehicleDetailsDamageHistoryProps } from './damage.props';
import { useVehicleHistorySectionsConditions } from '../../../hooks/use-sections-conditions';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailsDamageHistory = ({ isExpanded, hideTitle }: VehicleDetailsDamageHistoryProps) => {
  const { isShowDamageHistory } = useVehicleHistorySectionsConditions();
  const { vehicleDamageHistoryQuery } = useVehicleDamageHistoryQuery(undefined, {
    options: { enabled: isShowDamageHistory }
  });

  const { isMobile } = useWindowSize();

  const getContent = () => {
    if (!vehicleDamageHistoryQuery.length) {
      return <VehicleDetailsHistoryEmptyPlaceholder id='vehicleDamageHistoryEmptyPlaceholder' title='Schaden' />;
    }

    const getStepsProcessProps = () => {
      if (isMobile) {
        return { stepIconStyle: { padding: '6px 0 0' } };
      }

      return { stepDividerHeight: 62, distanceBetweenIconAndDivider: 20 };
    };

    return (
      <Flex padding='0 25px'>
        <StepsProcess
          vertical
          {...getStepsProcessProps()}
          processSetup={vehicleDamageHistoryQuery
            .slice(0, isExpanded ? vehicleDamageHistoryQuery.length : 5)
            .map(item => ({
              status: StepStatus.Active,
              description: <VehicleDetailsDamageHistoryStepDescription {...item} />
            }))}
        />
      </Flex>
    );
  };

  return (
    <HistorySectionItem id='damageHistory' title={hideTitle ? '' : 'Schaden Historie'}>
      {getContent()}
    </HistorySectionItem>
  );
};
