import React from 'react';

const UncompletedStepIcon = ({ color = '#335566' }) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12.2857' r='10.85' stroke={color} strokeWidth='2.3' />
    </svg>
  );
};

export default UncompletedStepIcon;
