import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const UsedCar = ({ width = 20, height = 16, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;
  return (
    <svg width={width} height={height} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5986 6.26484C17.7219 6.59129 17.7872 6.9395 17.7872 7.29497V7.70848V12.1917H5.88259V13.3525C5.88259 13.6717 5.62143 13.9328 5.30223 13.9328H2.9808C2.6616 13.9328 2.40044 13.6717 2.40044 13.3525V11.8435L2.1683 10.7336C2.13203 10.545 2.11026 10.3419 2.11026 10.146V7.29497C2.11026 6.94676 2.17556 6.59129 2.29889 6.26484L2.5818 5.51763H1.23973C1.05112 5.51763 0.91328 5.34352 0.956807 5.16215L1.17444 4.22632C1.23973 3.96516 1.47188 3.77655 1.74029 3.77655H3.16216L4.27936 1.54943C4.5768 0.954561 5.17892 0.584579 5.83908 0.584579H14.0584C14.7186 0.584579 15.3207 0.961816 15.6181 1.54943L16.7353 3.77655H18.1572C18.4256 3.77655 18.665 3.96516 18.723 4.22632L18.9407 5.16215C18.9842 5.34352 18.8464 5.51763 18.6577 5.51763H17.3157L17.5986 6.26484Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M17.5986 6.26486C17.7219 6.59131 17.7872 6.93952 17.7872 7.29499V7.70849V12.1918H5.88259V13.3525C5.88259 13.6717 5.62143 13.9328 5.30223 13.9328H2.9808C2.6616 13.9328 2.40044 13.6717 2.40044 13.3525V11.8435L2.1683 10.7336C2.13203 10.545 2.11026 10.3419 2.11026 10.146V7.29499C2.11026 6.94677 2.17556 6.59131 2.29889 6.26486L2.5818 5.51764H1.23973C1.05112 5.51764 0.91328 5.34353 0.956807 5.16217L1.17444 4.22634C1.23973 3.96518 1.47188 3.77657 1.74029 3.77657H3.16216L4.77994 4.89322H15.079L16.7353 3.77657H18.1572C18.4256 3.77657 18.665 3.96518 18.723 4.22634L18.9407 5.16217C18.9842 5.34353 18.8464 5.51764 18.6577 5.51764H17.3157L17.5986 6.26486Z'
          fill={fill}
        />
      )}
      <circle cx='13.7032' cy='11.6114' r='4.065' fill={hovered ? 'white' : 'none'} />
      <path
        d='M4.72187 12.1917H5.88259V13.3525C5.88259 13.6717 5.62143 13.9328 5.30223 13.9328H2.9808C2.6616 13.9328 2.40044 13.6717 2.40044 13.3525V11.8435L2.1683 10.7336C2.13203 10.545 2.11026 10.3419 2.11026 10.146V7.29497C2.11026 6.94676 2.17556 6.59129 2.29889 6.26484L2.5818 5.51763H1.23973C1.05112 5.51763 0.91328 5.34352 0.956807 5.16215L1.17444 4.22632C1.23973 3.96516 1.47188 3.77655 1.74029 3.77655H3.16216L4.27936 1.54943C4.5768 0.954561 5.17892 0.584579 5.83907 0.584579H14.0584C14.7186 0.584579 15.3207 0.961816 15.6181 1.54943L16.7353 3.77655H18.1572C18.4256 3.77655 18.665 3.96516 18.723 4.22632L18.9407 5.16215C18.9842 5.34352 18.8464 5.51763 18.6577 5.51763H17.3157L17.5986 6.26484C17.7219 6.59129 17.7872 6.9395 17.7872 7.29497V7.70848'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.85864 8.70961H6.27439L5.86089 7.88985'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.01904 4.93727H14.8851'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.85352 12.1917H8.25972'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5216 9.52538C11.2027 8.49155 12.3715 7.80745 13.7029 7.80745C15.8042 7.80745 17.5068 9.51004 17.5068 11.6114C17.5068 13.7128 15.8042 15.4154 13.7029 15.4154C11.6015 15.4154 9.89887 13.7128 9.89887 11.6114M10.5124 7.80745V9.52538H12.2303'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
