import { useFormSourceMapping } from './use-source-mapping/use-source-mapping';
import { FormFieldModel } from 'common/interfaces';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useDebounce } from 'hooks/use-debounce';

export const useSetFormOptions = (
  search: string,
  initialFormField: FormFieldModel,
  formField: FormFieldModel,
  setFormField: Dispatch<SetStateAction<FormFieldModel>>
) => {
  const [loading, setLoading] = useState(false);
  const [isShowCreateNewItemForm, setIsShowCreateNewItemForm] = useState(
    typeof formField.showCreateNewItemFormCount === 'number'
  );

  const isFieldLoaded = useRef(false);

  const searchDebounce = useDebounce(search);
  const { sourceMapping } = useFormSourceMapping(search, formField.source);

  const setFormOptions = () => {
    if (formField.source && sourceMapping) {
      const cloneFormField = cloneDeep(formField);

      cloneFormField.options = sourceMapping;

      setFormField(() => cloneFormField);
      setLoading(false);

      isFieldLoaded.current = true;
    }
  };

  const showCreateNewItemForm = () => {
    if (formField.createNewItemFormField) {
      setFormField(formField.createNewItemFormField);
    }
  };

  useEffect(() => {
    setIsShowCreateNewItemForm(typeof initialFormField.showCreateNewItemFormCount === 'number');
  }, [initialFormField.showCreateNewItemFormCount]);

  useEffect(() => {
    if (isShowCreateNewItemForm) {
      return showCreateNewItemForm();
    }
    setFormOptions();
  }, [JSON.stringify(formField), sourceMapping, searchDebounce, isShowCreateNewItemForm]);

  return { loading, setLoading, setIsShowCreateNewItemForm };
};
