import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { DefaultExtensionModalProps } from '../extend-lease-agreement.props';

const DefaultExtensionModal = ({
  plateNumber,
  amountOfMonths,
  additionalMileage,
  comment
}: DefaultExtensionModalProps) => {

  const theme = useTheme();
  
  return (
    <Flex direction='column'>
      <Text as='span' bottom='10' color={theme.colors.blue}>
        Der Kunde mit dem Kennzeichen {<strong>{plateNumber.attributeValue?.name || '-'}</strong>} möchte den
        Leasingvertrag um {<strong>{amountOfMonths} Monate</strong>} verlängern{' '}
        {additionalMileage && <>und auch die Laufleistung auf {<strong>{additionalMileage} km</strong>}</>} erhöhen.
      </Text>
      {comment && (
        <>
          <Text bold>Ein Kommentar von Kunden:</Text>
          <Text>{comment}</Text>
        </>
      )}
    </Flex>
  );
};

export default DefaultExtensionModal;