import React from 'react';
import { AutocompleteEmptyPlaceholderProps } from './empty-placeholder.props';
import { AutocompleteAddNewItemButton } from '../add-new-item-button/add-new-item-button';

export const AutocompleteEmptyPlaceholder = ({ placeholder, addNewItemButton }: AutocompleteEmptyPlaceholderProps) => {
  return (
    <div>
      <p>{placeholder || 'Keine Optionen'}</p>
      <AutocompleteAddNewItemButton {...addNewItemButton} />
    </div>
  );
};
