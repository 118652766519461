import React from 'react';
import classes from './requestCar.module.scss';
import InfoHint from 'components/info-hint/info-hint-view';

const RequestAnOffer = ({ buttonText }) => {

  return (
    <>
      <div className={classes.offer__wrapper}>
        <p className={classes.offer__description}>
          Klicke auf <span style={{fontWeight: 'bold'}}>{buttonText}</span> und dein persönlicher Mobexo-Ansprechpartner wird in den nächsten Tagen ein verbindliches Angebot online stellen. Direkt danach kannst du den Bestell- und Genehmigungsprozess online abschließen.
        </p>
      </div>

      <InfoHint className={classes.offer__warning}>Über den Status deiner Bestellung informieren wir dich per E-Mail</InfoHint>
    </>

  );
};

export default RequestAnOffer;
