import styled, { css } from 'styled-components';

export const StyledIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 10px;
  height: 15px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.pink};
  border-radius: 8px;
  font-size: 12px;
  line-height: 15px;
  margin: 3px 0 2px;
`;

interface ActiveProps {
  active: boolean;
}

const subMenuTitleHoverMixin = css`
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blackPearl};
`;

const subMenuHoverMixin = css`
  .sub-menu-item-title {
    ${subMenuTitleHoverMixin}
  }
  .icon {
    display: none;
  }
  .icon-hovered {
    display: block;
  }
`;

const subMenuItem = css<ActiveProps>`
  display: flex;
  align-items: center;
  padding: 10px 23px 10px 23px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 10px;
  }
  &:first-child {
    margin-top: 10px;
  }
  &:hover {
    ${subMenuHoverMixin}
  }

  ${({ active }) =>
    active &&
    css`
      ${subMenuHoverMixin}
    `};
`;

export const StyledSubMenuItem = styled.div`
  ${subMenuItem}
`;

export const StyledSubMenuLinkItem = styled.a`
  ${subMenuItem}
`;

export const StyledMenuTitle = styled.div<ActiveProps>`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.grey600};
  &:hover,
  &:focus,
  &:active {
    ${subMenuTitleHoverMixin}
  }

  ${({ active }) => active && subMenuTitleHoverMixin};
`;

export const StyledIndicatorForCollapsedMenu = styled.span`
  position: absolute;
  width: 7px;
  height: 7px;
  background: ${({ theme }) => theme.colors.pink};
  border-radius: 50%;
  right: 0;
  bottom: -2px;
`;
