import { useEffect, useRef, useState } from 'react';
import { InspectionDateHistoryStatusValueEnum } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';
import { useMyVehicleInspectionDateQuery } from 'pages/existing-vehicle-page/hooks/query/use-my-vehicle-inspection-date.query';

export const useIsEditableItem = (inspectionId: string) => {
  const [isEditable, setIsEditable] = useState(false);

  const relatedInspectionDateHistoryIdRef = useRef('');

  const { carInspectionDateQuery } = useMyVehicleInspectionDateQuery();

  useEffect(() => {
    const relatedInspectionDateHistory = carInspectionDateQuery.pruftermineHistories.find(
      item => item.attributes.uds_inspection_datesid?.id === inspectionId
    );

    if (!relatedInspectionDateHistory) {
      return;
    }

    const { uds_statuscode, uds_inspection_historyid } = relatedInspectionDateHistory.attributes;

    const HISTORY_STATUS_CODE_VALUES_FOR_EDITING = [
      InspectionDateHistoryStatusValueEnum.Active,
      InspectionDateHistoryStatusValueEnum.Overdue,
      InspectionDateHistoryStatusValueEnum.Canceled
    ];

    if (uds_inspection_historyid) {
      relatedInspectionDateHistoryIdRef.current = uds_inspection_historyid;
    }

    if (!uds_statuscode || HISTORY_STATUS_CODE_VALUES_FOR_EDITING.includes(uds_statuscode.value)) {
      setIsEditable(true);
    }
  }, [JSON.stringify(carInspectionDateQuery.pruftermineHistories)]);

  return { isEditable, relatedInspectionDateHistoryId: relatedInspectionDateHistoryIdRef.current };
};
