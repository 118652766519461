import { USER_ROLES } from '../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import useQueryLocation from 'hooks/use-query';

export const useCreateDamageOtpLink = () => {
  const query = useQueryLocation();
  const createDamageOtpCode = query.otp;

  const isAccessCreateDamagePageByOtpLinkByRoles = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.driver.id
  ]);

  const isAccessCreateDamagePageByOtpLink = isAccessCreateDamagePageByOtpLinkByRoles && !!createDamageOtpCode;

  return { createDamageOtpCode, isAccessCreateDamagePageByOtpLink };
};
