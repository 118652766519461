import { IconProps } from 'common/interfaces';
import React from 'react';

export const OrderWidgetPeriodIcon = ({ width = 28, height = 28, color = '#8FAFBF' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.2746 5.61508V3.24398C20.2746 2.76976 19.9585 2.45361 19.4842 2.45361C19.01 2.45361 18.6939 2.76976 18.6939 3.24398V5.61508H9.20948V3.24398C9.20948 2.76976 8.89333 2.45361 8.41911 2.45361C7.94489 2.45361 7.62875 2.76976 7.62875 3.24398V5.61508C6.04802 5.93122 4.78343 7.19581 4.46729 8.77654H23.4361C23.1199 7.19581 21.8553 5.93122 20.2746 5.61508Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.44092 10.4203H23.5594V22.2474C23.5594 24.0694 22.0824 25.5464 20.2605 25.5464H7.73984C5.91789 25.5464 4.44092 24.0694 4.44092 22.2475V10.4203ZM10.5719 15.5796C10.5719 16.452 9.86471 17.1592 8.99231 17.1592C8.11992 17.1592 7.4127 16.452 7.4127 15.5796C7.4127 14.7072 8.11992 14 8.99231 14C9.86471 14 10.5719 14.7072 10.5719 15.5796ZM14.0002 17.1592C14.8726 17.1592 15.5798 16.452 15.5798 15.5796C15.5798 14.7072 14.8726 14 14.0002 14C13.1278 14 12.4206 14.7072 12.4206 15.5796C12.4206 16.452 13.1278 17.1592 14.0002 17.1592ZM20.5876 15.5796C20.5876 16.452 19.8804 17.1592 19.008 17.1592C18.1356 17.1592 17.4284 16.452 17.4284 15.5796C17.4284 14.7072 18.1356 14 19.008 14C19.8804 14 20.5876 14.7072 20.5876 15.5796ZM8.99231 21.9689C9.86471 21.9689 10.5719 21.2617 10.5719 20.3893C10.5719 19.5169 9.86471 18.8097 8.99231 18.8097C8.11992 18.8097 7.4127 19.5169 7.4127 20.3893C7.4127 21.2617 8.11992 21.9689 8.99231 21.9689ZM15.5798 20.3893C15.5798 21.2617 14.8726 21.9689 14.0002 21.9689C13.1278 21.9689 12.4206 21.2617 12.4206 20.3893C12.4206 19.5169 13.1278 18.8097 14.0002 18.8097C14.8726 18.8097 15.5798 19.5169 15.5798 20.3893ZM19.008 21.9689C19.8804 21.9689 20.5876 21.2617 20.5876 20.3893C20.5876 19.5169 19.8804 18.8097 19.008 18.8097C18.1356 18.8097 17.4284 19.5169 17.4284 20.3893C17.4284 21.2617 18.1356 21.9689 19.008 21.9689Z'
      fill={color}
    />
  </svg>
);
