import { AxiosRequestConfig } from 'axios';

class ContactRequestConfig {
  setMainRole = (uds_main_b2c_webrolleid: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'contact/SetMainRole',
    data: { uds_main_b2c_webrolleid }
  });
}

export const contactRequestConfig = new ContactRequestConfig();
