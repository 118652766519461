import { getDefaultFilterState } from '../utils';
import { SetSessionFilter } from 'hooks/use-session-filter';
import { useLocation } from 'react-router-dom';
import { MyVehicleLocation } from '../my-vehicles.props';
import { useOnUnmount } from 'hooks/use-on-unmount';

export const useOnUnmountSetDefaultFilters = (setSessionFilter: SetSessionFilter) => {
  const location = useLocation<MyVehicleLocation>();
  const { dataForServer, dataForClient } = getDefaultFilterState();

  const isFilterSelectedByLocationState = !!location.state?.filterForServer;

  const leavingPageHandler = () => {
    if (isFilterSelectedByLocationState) {
      setTimeout(() => setSessionFilter(dataForServer, dataForClient));
    }
  };

  useOnUnmount(leavingPageHandler, [isFilterSelectedByLocationState]);
};
