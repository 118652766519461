import React, { useState } from 'react';
import SideMenuRightContainer from '../../../components/side-menu-right-container';
import PanelPlaceholder from '../../../components/panel-placeholder';
import { RepairStatusItem } from '../components';
import classes from './repair-status.module.scss';
import { useRepairStatusQuery } from 'hooks/react-query/repair-partner/repair-status';
import Pagination from '../../../components/pagination';
import { PAGES } from '../../../common/enums';
import usePaginationState from '../../../hooks/use-pagination-state/use-pagination-state';

const RepairStatusPage = () => {
  const { defaultPage, setDefaultPage } = usePaginationState(PAGES.REPAIRED);
  const [tableParams, setTableParams] = useState({
    pagination: { start: defaultPage, number: 20 }
  });
  const [openedItem, setOpenedItem] = useState({
    indx: -1,
    isActive: false
  });

  const { table } = useRepairStatusQuery(tableParams);

  const pageChangeHandler = page => {
    setDefaultPage(page);
    setTableParams(tableParams => ({ ...tableParams, pagination: { ...tableParams.pagination, start: page } }));
  };

  const openCaseTaskHandler = indx => {
    setOpenedItem(() => ({ indx, isActive: true }));
  };

  const closeCases = () => {
    setOpenedItem({
      indx: -1,
      isActive: false
    });
  };

  return (
    <SideMenuRightContainer>
      {table?.count ? (
        <div>
          {table?.items.map((data, index) => (
            <RepairStatusItem
              key={data?.incidentid?.attributeValue}
              data={data}
              open={openedItem.isActive && openedItem.indx === index}
              openCase={() => {
                openCaseTaskHandler(index);
              }}
              closeCases={closeCases}
            />
          ))}
        </div>
      ) : (
        <PanelPlaceholder
          title='Schadenfrei!'
          img='/assets/images/gold-cup-with-car.png'
          className={classes.empty_state_panel}
        />
      )}
      {table?.count > tableParams.pagination.number ? (
        <Pagination
          activePage={tableParams.pagination.start}
          itemsCountPerPage={tableParams.pagination.number}
          totalItemsCount={table.count}
          onPageChange={pageChangeHandler}
        />
      ) : null}
    </SideMenuRightContainer>
  );
};

export default RepairStatusPage;

export const defaultTableData = { count: 0, items: [] };
