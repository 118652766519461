import { Flex, Text } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ModalContentSubmit } from './modal-content.submit';
import { FormData, VehicleCostSheetModalContentProps } from './modal-content.props';
import { formFields } from './modal-content.fields';

export const VehicleCostSheetModalContent = (props: VehicleCostSheetModalContentProps) => {
  const reactHookFormData = useForm<FormData>();

  return (
    <Flex direction='column'>
      <Text bottom='20'>
        Berechne den Fahrzeugkostenbogen neu und lade das PDF für den ausgewählten Monat und das ausgewählte Jahr
        herunter.
      </Text>

      <FormHook
        className='d-flex'
        distanceBetweenFields='0'
        reactHookFormData={reactHookFormData}
        formFields={formFields}
      />

      <ModalContentSubmit reactHookFormData={reactHookFormData} {...props} />
    </Flex>
  );
};
