import React from 'react';
import { GapSides, PaginationButtonTypes, PaginationProps } from 'components/pagination/pagination.props';
import PaginationButton from './pagination-button';
import { Flex } from 'common/common-components.styled';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';

const Pagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onPageChange,
  className,
  noScroll = false
}: PaginationProps) => {
  const { openUnsavedChangesModal } = useUnsavedChangesModal();

  const pagesCount = Math.ceil(totalItemsCount / itemsCountPerPage);
  const showLeftGap = activePage > 3;
  const showRightGap = activePage < pagesCount - 2;

  const changeHandler = (page: number) => {
    openUnsavedChangesModal(() => {
      if (page !== activePage) {
        onPageChange(page);
        if (!noScroll) {
          const scrollContainer = document.querySelector('.scroll-container');
          if (scrollContainer) {
            scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      }
    });
  };

  const getGapPages = (side: GapSides) => {
    const start = side === GapSides.Left ? 2 : activePage === 1 ? activePage + 3 : activePage + 2;
    const end = side === GapSides.Left ? (activePage === pagesCount ? activePage - 3 : activePage - 2) : pagesCount - 1;

    const result: number[] = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  };

  const getButtons = () => {
    if (pagesCount < 8) {
      return Array.from({ length: pagesCount }, (v, i) => i + 1).map(pageNumber => (
        <PaginationButton
          key={pageNumber}
          activePage={activePage}
          type={PaginationButtonTypes.Page}
          pageNumber={pageNumber}
          onPageChange={changeHandler}
        />
      ));
    }

    return (
      <>
        {showLeftGap ? (
          <>
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.PrevPage}
              onPageChange={changeHandler}
            />
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Page}
              pageNumber={1}
              onPageChange={changeHandler}
            />
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Gap}
              gapPages={getGapPages(GapSides.Left)}
              onPageChange={changeHandler}
            />
          </>
        ) : (
          activePage !== pagesCount &&
          activePage - 2 > 0 && (
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Page}
              pageNumber={activePage - 2}
              onPageChange={changeHandler}
            />
          )
        )}

        {activePage === pagesCount && activePage - 2 > 0 && (
          <PaginationButton
            activePage={activePage}
            type={PaginationButtonTypes.Page}
            pageNumber={activePage - 2}
            onPageChange={changeHandler}
          />
        )}

        {activePage - 1 > 0 && (
          <PaginationButton
            activePage={activePage}
            type={PaginationButtonTypes.Page}
            pageNumber={activePage - 1}
            onPageChange={changeHandler}
          />
        )}
        <PaginationButton
          activePage={activePage}
          type={PaginationButtonTypes.Page}
          pageNumber={activePage}
          onPageChange={changeHandler}
        />
        {activePage + 1 <= pagesCount && (
          <PaginationButton
            activePage={activePage}
            type={PaginationButtonTypes.Page}
            pageNumber={activePage + 1}
            onPageChange={changeHandler}
          />
        )}

        {activePage === 1 && activePage + 2 <= pagesCount && (
          <PaginationButton
            activePage={activePage}
            type={PaginationButtonTypes.Page}
            pageNumber={activePage + 2}
            onPageChange={changeHandler}
          />
        )}

        {showRightGap ? (
          <>
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Gap}
              gapPages={getGapPages(GapSides.Right)}
              onPageChange={changeHandler}
            />
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Page}
              pageNumber={pagesCount}
              onPageChange={changeHandler}
            />
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.NextPage}
              onPageChange={changeHandler}
            />
          </>
        ) : (
          activePage !== 1 &&
          activePage + 2 <= pagesCount && (
            <PaginationButton
              activePage={activePage}
              type={PaginationButtonTypes.Page}
              pageNumber={activePage + 2}
              onPageChange={changeHandler}
            />
          )
        )}
      </>
    );
  };

  return (
    <Flex justify='center' padding='20px 0 0' className={className}>
      {getButtons()}
    </Flex>
  );
};

export default Pagination;
