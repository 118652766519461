import React, { useContext, useState } from 'react';
import { Grid } from 'common/common-components.styled';
import { AddFormButton } from './components/add-form-button/add-form-button';
import { OptionSetFileProps } from './option-set-file.props';
import { OptionSetFileContext } from './option-set-file.context';
import { OptionSetFileItem } from './components/option-set-file-item/option-set-file-item';
import { useDisableOptionSet } from './hooks/use-disable-option-set';
import { useCreateFirstForm } from './hooks/use-create-first-form';
import { useValidateOptionSetFile } from './hooks/use-validate';
import { formFieldProvider } from '../../form-field';

export const OptionSetFile = (props: OptionSetFileProps) => {
  const { field } = useContext(formFieldProvider);

  const { className, options: optionsProp } = field;
  const { values, onChange, optionIndexesError } = props;

  const [options, setOptions] = useState(optionsProp || []);

  useCreateFirstForm(onChange);
  useDisableOptionSet(values, setOptions);
  useValidateOptionSetFile();

  return (
    <OptionSetFileContext.Provider value={{ ...props, options, setOptions }}>
      <Grid align='flex-start' gridTemplateColumns={3} gap='20px' className={className}>
        {values.map(valueItem => (
          <OptionSetFileItem
            key={valueItem.id}
            optionError={optionIndexesError?.includes(valueItem.id)}
            valueItem={valueItem}
          />
        ))}

        <AddFormButton />
      </Grid>
    </OptionSetFileContext.Provider>
  );
};
