import { ReactNode } from 'react';

export interface UnsavedModalContent {
  title: ReactNode;
  description: ReactNode;
  submitTitle?: string;
  cancelTitle?: string;
}

export enum UnsavedModalType {
  BeforeEditNextField = 'beforeEditNextField'
}
