import React, { MouseEvent, useContext } from 'react';
import { SortingDocumentsTabsContext } from 'components/documents/sorting-tabs-documents/sorting-documents-tabs.context';
import { useArchiveDocumentsModal } from 'hooks/modals/documents/use-archive/use-archive.modal';
import { useQueryClient } from 'react-query';
import { DocumentRowProps } from '../document-row.props';
import { Tooltip } from '@mui/material';

export const DocumentRowRightComponents = ({ file }: Pick<DocumentRowProps, 'file'>) => {
  const { documentsQueryKeyList, isHideDeleteButton } = useContext(SortingDocumentsTabsContext);
  const { openArchiveDocumentModal } = useArchiveDocumentsModal();

  const queryClient = useQueryClient();

  const archiveDocumentHandler = (event: MouseEvent<HTMLImageElement, globalThis.MouseEvent>) => {
    event.stopPropagation();

    openArchiveDocumentModal({
      fileId: file.fileId,
      callbackAfterSubmit: () => {
        documentsQueryKeyList.forEach(documentsQueryKey => {
          queryClient.resetQueries(documentsQueryKey);
        });
      }
    });
  };

  if (isHideDeleteButton) {
    return null;
  }

  return (
    <Tooltip title='Archivieren'>
      <img src='/assets/images/archive.svg' alt='archive' onClick={archiveDocumentHandler} />
    </Tooltip>
  );
};
