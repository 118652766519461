import { ChartOptions } from 'chart.js';
import { BarDiagramProps } from '../bar.props';
import { useTheme } from 'styled-components';
import { BarPluginId } from './plugins/plugin.enum';

export const useBarOptions = ({ options = {}, data, labelCallback, diagramPlugins }: BarDiagramProps) => {
  const theme = useTheme();

  const getMinScaleY = () => {
    const horizontalLine = diagramPlugins?.find(item => item.plugin.id === BarPluginId.HorizontalLine);

    if (horizontalLine && data.every(item => horizontalLine.value > item.value)) {
      return horizontalLine.value;
    }
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    ...options,
    plugins: {
      ...options.plugins,
      legend: { display: false, ...options.plugins?.legend },
      tooltip: {
        callbacks: {
          label: context => {
            const label = data[context.dataIndex].value;

            if (labelCallback) {
              return labelCallback(label.toString());
            }

            return label.toLocaleString('de-DE');
          },
          title: () => ''
        },
        displayColors: false,
        ...options.plugins?.tooltip
      }
    },
    scales: {
      y: {
        max: getMinScaleY(),
        ...options?.scales?.y,
        ticks: {
          callback: value => {
            if (Number(value) >= 1000) {
              let toFixedNumber = Number(Number(value) / 1000).toFixed(1);

              if (toFixedNumber[toFixedNumber.length - 1] === '0') {
                toFixedNumber = toFixedNumber.slice(0, toFixedNumber.length - 2);
              }

              return `${toFixedNumber}k`;
            }

            return Number(value).toLocaleString('de-DE');
          },
          color: theme.colors.grey300,
          padding: 10,
          ...options?.scales?.y?.ticks,
          font: { size: 11, ...options?.scales?.y?.ticks }
        },
        grid: { color: theme.colors.grey100, lineWidth: 0.5, ...options?.scales?.y?.grid },
        border: { display: false, ...options?.scales?.y?.border }
      },
      x: {
        ticks: {
          color: theme.colors.grey300,
          font: { size: 11 }
        },
        grid: { display: false },
        border: { display: false }
      }
    }
  };

  return { barOptions };
};
