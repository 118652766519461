import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import classes from './file-info.module.scss';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import useModal from 'hooks/use-modal';
import { getIcon } from 'components/download-documents-component/download-documents-component';
import ThreeStateCheckbox from '../../../three-state-checkbox';
import { useOverlay, useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import { FileInfoProps, FileInfoType } from './file-info.props';
import { formatDate } from 'helpers';
import { CloseIcon } from 'components/icons/close-icon';
import Download from 'components/icons/download';
import sharepointService from 'services/sharepoint-service';
import { useTheme } from 'styled-components';
import { FileFormatTypes } from 'common/enums';

export const FileInfo = ({
  file,
  allFiles,
  fileCheckboxChanged,
  showCheckboxes = true,
  className,
  isHideFileViewer = false,
  deleteHandler,
  type = FileInfoType.Checkbox,
  showLetterModal,
  onDownload
}: FileInfoProps) => {
  const query = useQuery();
  const history = useHistory();
  const [showOverlay, hideOverlay] = useOverlay();

  const [showFileViewer, hideFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, {
    file,
    allFiles: allFiles?.filter(file => {
      const fileNameArr = file.filePath.split('.');
      const fileFormat = fileNameArr[fileNameArr.length - 1] as FileFormatTypes;
      const isEmail = [FileFormatTypes.MSG, FileFormatTypes.EML].includes(fileFormat);

      if (!isEmail) {
        return file;
      }
    })
  });

  const theme = useTheme();

  const filePathArr = file.filePath.split('/');
  const fileName = filePathArr[filePathArr.length - 1];

  const isCheckboxVisible = (file.checked || showCheckboxes) && type === FileInfoType.Checkbox;

  const checkboxWrapperClassName = classNames(classes.file_checkbox, { [classes.visible]: isCheckboxVisible });
  const fileContainerClassName = classNames(classes.file, {
    className,
    [classes.file_download_type]: type === FileInfoType.Download
  });

  const FILE_OPEN_PATH_QUERY = 'file=open';
  const isFileOpen = history.location.search.includes(FILE_OPEN_PATH_QUERY);

  const fileSizeString = file.fileSize
    ? `${(file.fileSize / 1024).toFixed(1)} KB • ${formatDate(file.lastModified)}`
    : '';

  const icon = useMemo(() => getIcon(fileName), [fileName]);

  const openFileViewer = () => {
    if (isHideFileViewer) {
      return;
    }

    const path = query.id ? `?id=${query.id}&${FILE_OPEN_PATH_QUERY}` : `?${FILE_OPEN_PATH_QUERY}`;
    history.push(path);
    showFileViewer();
  };

  const downloadFile = async (fileId: string | undefined, fileName: string) => {
    try {
      if (fileId) {
        showOverlay();
        const { data } = await sharepointService.downloadFileById(fileId);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data]));
        a.download = fileName;
        a.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideOverlay();
    }
  };

  const onClickHandler = (fileType: FileInfoType, fileId: string | undefined, fileName: string) => {
    const fileNameArr = fileName.split('.');
    const isEmail = [FileFormatTypes.MSG, FileFormatTypes.EML].includes(
      fileNameArr[fileNameArr.length - 1] as FileFormatTypes
    );

    if (fileType === FileInfoType.Checkbox && !isEmail) {
      openFileViewer();
      return;
    }

    if (fileType === FileInfoType.Checkbox && isEmail && showLetterModal && fileId) {
      showLetterModal && showLetterModal(fileId);
      return;
    }

    if (fileType === FileInfoType.Download) {
      downloadFile(fileId, fileName);
      return;
    }
  };

  const downloadHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    onDownload && onDownload(file.filePath || '');
  };

  useEffect(() => {
    if (isHideFileViewer) {
      return;
    }

    if (isFileOpen) {
      showFileViewer();
    } else {
      hideFileViewer();
    }
  }, [history.location.search]);

  return (
    <div onClick={() => onClickHandler(type, file.fileId, file.filePath)} className={fileContainerClassName}>
      {fileCheckboxChanged && (
        <div className={checkboxWrapperClassName}>
          <ThreeStateCheckbox
            checked={!!file.checked}
            indeterminate={false}
            onChange={data => fileCheckboxChanged(file.fileId, data)}
          />
        </div>
      )}
      {deleteHandler && (
        <div className={classes.close_icon_container} onClick={() => deleteHandler(file.filePath)}>
          <CloseIcon width='15' height='15' />
        </div>
      )}

      <div className={classes.file_description_download_container}>
        <div className={classes.file_description_container}>
          {icon}
          <div className={classes.file_description}>
            <span>{fileName}</span>
            <span className={classes.file_modify_date}>{fileSizeString}</span>
          </div>
        </div>

        {onDownload && (
          <div className={classes.download_button} onClick={downloadHandler}>
            <Download fill={theme.colors.blue} width='14px' height='18px' className={classes.download_icon} />
          </div>
        )}
      </div>
    </div>
  );
};
