import React from 'react';

const EmlFileIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 5.25C3 4.00736 4.00736 3 5.25 3H13.0737C13.6577 3 14.2188 3.22704 14.6384 3.63315L20.3147 9.12631C20.7527 9.55019 21 10.1336 21 10.7432V18.75C21 19.9926 19.9926 21 18.75 21H5.25C4.00736 21 3 19.9926 3 18.75V5.25ZM5.25 4.8C5.00147 4.8 4.8 5.00147 4.8 5.25V18.75C4.8 18.9985 5.00147 19.2 5.25 19.2H18.75C18.9985 19.2 19.2 18.9985 19.2 18.75V10.7432C19.2 10.6213 19.1505 10.5046 19.0629 10.4198L13.3867 4.92663C13.3027 4.84541 13.1905 4.8 13.0737 4.8H5.25Z'
        fill='#FF9700'
      />
      <path
        d='M6.34516 14.7C6.17948 14.7 6.04516 14.5657 6.04516 14.4V10.5026C6.04516 10.337 6.17948 10.2026 6.34516 10.2026H8.40298C8.56866 10.2026 8.70298 10.337 8.70298 10.5026V10.8778C8.70298 11.0435 8.56866 11.1778 8.40298 11.1778H7.5418C7.37611 11.1778 7.2418 11.3121 7.2418 11.4778V11.736C7.2418 11.9017 7.37611 12.036 7.5418 12.036H8.28916C8.45484 12.036 8.58916 12.1704 8.58916 12.336V12.7112C8.58916 12.8769 8.45484 13.0112 8.28916 13.0112H7.5418C7.37611 13.0112 7.2418 13.1455 7.2418 13.3112V13.4249C7.2418 13.5905 7.37611 13.7249 7.5418 13.7249H8.47798C8.60224 13.7249 8.70298 13.8256 8.70298 13.9499V14.475C8.70298 14.5993 8.60224 14.7 8.47798 14.7H6.34516Z'
        fill='#FF9700'
      />
      <path
        d='M15.3944 14.475V10.4276C15.3944 10.3034 15.4951 10.2026 15.6194 10.2026H16.366C16.4902 10.2026 16.591 10.3034 16.591 10.4276V13.4999C16.591 13.6241 16.6917 13.7249 16.816 13.7249H17.8272C17.9514 13.7249 18.0522 13.8256 18.0522 13.9499V14.475C18.0522 14.5993 17.9514 14.7 17.8272 14.7H15.6194C15.4951 14.7 15.3944 14.5993 15.3944 14.475Z'
        fill='#FF9700'
      />
      <path
        d='M10.7942 12.3782C10.9357 12.4986 11.4033 14.2377 11.5043 14.3841C11.604 14.5801 11.721 14.7 11.8424 14.7H12.0428L12.2431 14.7C12.3646 14.7 12.4815 14.5801 12.5812 14.3841C12.6822 14.2377 13.1498 12.4986 13.2913 12.3782C13.4432 12.249 13.4233 12.4789 13.4233 12.6032V14.475C13.4233 14.5993 13.5241 14.7 13.6483 14.7H14.4134C14.5377 14.7 14.6384 14.5993 14.6384 14.475V10.4276C14.6384 10.3034 14.5377 10.2026 14.4134 10.2026H13.4235C13.3545 10.2026 13.2894 10.2343 13.2467 10.2884L12.4033 11.8686C12.3035 12.0646 12.2235 12.1845 12.1021 12.1845H12.0428H11.9829C11.8614 12.1845 11.7528 12.0646 11.653 11.8686L10.849 10.3079C10.8425 10.2954 10.8352 10.2833 10.8255 10.273C10.7832 10.2283 10.7242 10.2026 10.662 10.2026H9.6721C9.54784 10.2026 9.4471 10.3034 9.4471 10.4276V14.475C9.4471 14.5993 9.54784 14.7 9.6721 14.7H10.4372C10.5615 14.7 10.6622 14.5993 10.6622 14.475V12.6032C10.6622 12.4789 10.6423 12.249 10.7942 12.3782Z'
        fill='#FF9700'
      />
    </svg>
  );
};

export default EmlFileIcon;
