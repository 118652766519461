import { useEffect, useState } from 'react';
import { CustomizedColumnInfo } from './column-settings.types';
import { DropResult } from 'react-beautiful-dnd';
import { ColumnType } from 'components/table-component/table-component.props';

export const useColumnSettings = (defaultColumns: ColumnType[], customColumns: CustomizedColumnInfo[]) => {
  const [columns, setColumns] = useState(customColumns);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const reorder = (list: CustomizedColumnInfo[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const toggleItem = (item: CustomizedColumnInfo) => {
    const newColumns = columns.map(column => {
      if (column.columnId === item.columnId) {
        return {
          ...item,
          isChecked: !column.isChecked
        };
      }

      return column;
    });

    setColumns(newColumns);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newColumns = reorder(columns, result.source.index, result.destination.index);

    setColumns(newColumns);
  };

  const reset = () => {
    setColumns(
      defaultColumns
        .filter(column => column.name)
        .map(column => {
          return {
            title: column.name,
            propName: column.propName!,
            isChecked: true,
            columnId: column.columnId!
          };
        })
    );
  };

  useEffect(() => {
    const newColumns = columns.filter(column => column.isChecked);

    setCheckboxDisabled(newColumns.length < 2);
  }, [columns]);

  return { columns: columns.filter(column => Boolean(column.title)), checkboxDisabled, toggleItem, onDragEnd, reset };
};
