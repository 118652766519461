import { Flex, Text, TextProps } from 'common/common-components.styled';
import React from 'react';
import { LeasingPeriodWidgetDateProps } from './date.props';
import moment from 'moment';

export const LeasingPeriodWidgetDate = ({ data }: LeasingPeriodWidgetDateProps) => {
  const commonTextProps: TextProps = { color: 'blue', bottom: '5' };
  return (
    <Flex justify='center' top='25'>
      <Flex direction='column'>
        {data.map(({ title }, index) => (
          <Text key={title} id={`leasingPeriodWidgetDateTitle${index}`} {...commonTextProps}>
            {title}
          </Text>
        ))}
      </Flex>

      <Flex direction='column' left='10'>
        {data.map(({ date }, index) => (
          <Text key={index} id={`leasingPeriodWidgetDateValue${index}`} {...commonTextProps} bold>
            {moment(date).isValid() ? moment(date).format('DD.MM.YYYY') : '-'}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};
