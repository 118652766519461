import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { useDispatch } from 'react-redux';
import { setSnackbarComponent, toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';
import { useTypedSelector } from 'hooks/use-typed-selector';

const SuccessSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarComponent = useTypedSelector(state => state['app'].snackbarComponent);

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const closeSnackbar = () => {
    dispatch(setSnackbarComponent({ ...snackbarComponent, open: false }));
  };

  const openInfoMessage = () => {
    const alertData = {
      title: 'Deine Bestellung',
      children: (
        <span>
          Danke für deine Bestellung. Der Vorgang wird von uns umgehend bearbeitet. Nach Prüfung deiner Anfrage und
          verbindlich unterschriebener Bestellung wirst du diese im Menüpunkt Bestellungen sehen.
        </span>
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        },
        {
          type: 'submit',
          title: 'OK',
          action: closeAlert
        }
      ]
    };
    closeSnackbar();
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return (
    <Flex direction='column' margin='0 15px'>
      <Text bottom='20' color='grey900'>
        Änderungen erfolgreich gespeichert.
      </Text>
      <Text onClick={openInfoMessage} underline pointer color='darkGreen'>
        Was passiert als Nächstes?
      </Text>
    </Flex>
  );
};

export default SuccessSnackbar;
