import { AxiosRequestConfig } from 'axios';

class DashboardRequestConfig {
  repairStatus: AxiosRequestConfig = {
    method: 'post',
    url: 'dashboard/getReparaturStatus',
    data: {}
  };

  uploadCarLogo = (vehicleId: string, file: File): AxiosRequestConfig => {
    const data = new FormData();

    data.append('file', file);
    data.append('fuhrparkId', vehicleId);

    return {
      method: 'post',
      url: 'dashboard/uploadCarLogo',
      responseType: 'blob',
      data
    };
  };

  getMainVehicle = (fahrerId?: string): AxiosRequestConfig => ({
    method: 'post',
    url: 'dashboard/getMeinFahrzeug',
    data: { fahrerId }
  });
}

export const dashboardRequestConfig = new DashboardRequestConfig();
