import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const SettingsIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M17.6435 12.0915C17.7306 11.8737 17.9484 11.7358 18.1807 11.7358H18.5872C18.9065 11.7358 19.1679 11.4745 19.1679 11.1551V8.83225C19.1679 8.51286 18.9065 8.25155 18.5872 8.25155H18.1807C17.9484 8.25155 17.7306 8.11363 17.6435 7.89586L16.9394 6.1973C16.8523 5.97954 16.8959 5.73274 17.0628 5.56579L17.3532 5.27544C17.5782 5.05041 17.5782 4.68021 17.3532 4.45519L15.7127 2.8147C15.4876 2.58968 15.1175 2.58968 14.8924 2.8147L14.6021 3.10505C14.4351 3.27201 14.1883 3.32282 13.9705 3.22846L12.272 2.52435C12.0542 2.43724 11.9163 2.21948 11.9163 1.9872V1.5807C11.9163 1.26132 11.655 1 11.3356 1H9.0128C8.69341 1 8.4321 1.26132 8.4321 1.5807V1.9872C8.4321 2.21948 8.29417 2.43724 8.0764 2.52435L6.37785 3.22846C6.16009 3.31556 5.91329 3.27201 5.74634 3.10505L5.45599 2.8147C5.23096 2.58968 4.86076 2.58968 4.63574 2.8147L2.99524 4.45519C2.77022 4.68021 2.77022 5.05041 2.99524 5.27544L3.28559 5.56579C3.45255 5.73274 3.50336 5.97954 3.40899 6.1973L2.70489 7.89586C2.61778 8.11363 2.40003 8.25155 2.16775 8.25155H1.76125C1.44186 8.25155 1.18054 8.51286 1.18054 8.83225V11.1551C1.18054 11.4745 1.44186 11.7358 1.76125 11.7358H2.16775C2.40003 11.7358 2.61778 11.8737 2.70489 12.0915L3.40899 13.79C3.4961 14.0078 3.45255 14.2546 3.28559 14.4215L2.99524 14.7119C2.77022 14.9369 2.77022 15.3071 2.99524 15.5321L4.63574 17.1726C4.86076 17.3976 5.23096 17.3976 5.45599 17.1726L5.74634 16.8823C5.91329 16.7153 6.16009 16.6645 6.37785 16.7589L8.0764 17.463C8.29417 17.5501 8.4321 17.7678 8.4321 18.0001V18.4066C8.4321 18.726 8.69341 18.9873 9.0128 18.9873H11.3356C11.655 18.9873 11.9163 18.726 11.9163 18.4066V18.0001C11.9163 17.7678 12.0542 17.5501 12.272 17.463'
          fill={fill}
        />
      )}
      <path
        d='M17.6435 12.0915C17.7306 11.8737 17.9484 11.7358 18.1807 11.7358H18.5872C18.9065 11.7358 19.1679 11.4745 19.1679 11.1551V8.83225C19.1679 8.51286 18.9065 8.25155 18.5872 8.25155H18.1807C17.9484 8.25155 17.7306 8.11363 17.6435 7.89586L16.9394 6.1973C16.8523 5.97954 16.8959 5.73274 17.0628 5.56579L17.3532 5.27544C17.5782 5.05041 17.5782 4.68021 17.3532 4.45519L15.7127 2.8147C15.4876 2.58968 15.1175 2.58968 14.8924 2.8147L14.6021 3.10505C14.4351 3.27201 14.1883 3.32282 13.9705 3.22846L12.272 2.52435C12.0542 2.43724 11.9163 2.21948 11.9163 1.9872V1.5807C11.9163 1.26132 11.655 1 11.3356 1H9.0128C8.69341 1 8.4321 1.26132 8.4321 1.5807V1.9872C8.4321 2.21948 8.29417 2.43724 8.0764 2.52435L6.37785 3.22846C6.16009 3.31556 5.91329 3.27201 5.74634 3.10505L5.45599 2.8147C5.23096 2.58968 4.86076 2.58968 4.63574 2.8147L2.99524 4.45519C2.77022 4.68021 2.77022 5.05041 2.99524 5.27544L3.28559 5.56579C3.45255 5.73274 3.50336 5.97954 3.40899 6.1973L2.70489 7.89586C2.61778 8.11363 2.40003 8.25155 2.16775 8.25155H1.76125C1.44186 8.25155 1.18054 8.51286 1.18054 8.83225V11.1551C1.18054 11.4745 1.44186 11.7358 1.76125 11.7358H2.16775C2.40003 11.7358 2.61778 11.8737 2.70489 12.0915L3.40899 13.79C3.4961 14.0078 3.45255 14.2546 3.28559 14.4215L2.99524 14.7119C2.77022 14.9369 2.77022 15.3071 2.99524 15.5321L4.63574 17.1726C4.86076 17.3976 5.23096 17.3976 5.45599 17.1726L5.74634 16.8823C5.91329 16.7153 6.16009 16.6645 6.37785 16.7589L8.0764 17.463C8.29417 17.5501 8.4321 17.7678 8.4321 18.0001V18.4066C8.4321 18.726 8.69341 18.9873 9.0128 18.9873H11.3356C11.655 18.9873 11.9163 18.726 11.9163 18.4066V18.0001C11.9163 17.7678 12.0542 17.5501 12.272 17.463'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5043 14.3344C10.6388 15.3288 8.26519 15.0457 6.69003 13.4706C5.35441 12.135 4.94792 10.2332 5.45603 8.54185L8.03291 11.155C8.67894 11.155 8.93299 11.155 9.58629 11.155L11.3284 9.41291C11.3284 8.78865 11.3284 8.4983 11.3284 7.87404L8.74426 5.26087C10.4283 4.75276 12.3374 5.15925 13.673 6.49487C15.2482 8.07003 15.5385 10.4437 14.5368 12.3092'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M14.8996 11.4381L18.4855 15.0312C19.3928 15.9386 19.3928 17.4121 18.4855 18.3194C17.5781 19.2268 16.1046 19.2268 15.1973 18.3194L11.5996 14.7063'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M12.5043 14.3344C10.6388 15.3288 8.26519 15.0457 6.69003 13.4706C5.35441 12.135 4.94792 10.2332 5.45603 8.54185L8.03291 11.155C8.67894 11.155 8.93299 11.155 9.58629 11.155L11.3284 9.41291C11.3284 8.78865 11.3284 8.4983 11.3284 7.87404L8.74426 5.26087C10.4283 4.75276 12.3374 5.15925 13.673 6.49487C15.2482 8.07003 15.5385 10.4437 14.5368 12.3092M14.8996 11.4381L18.4855 15.0312C19.3928 15.9386 19.3928 17.4121 18.4855 18.3194C17.5781 19.2268 16.1046 19.2268 15.1973 18.3194L11.5996 14.7063'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6741 17.251C17.0883 17.251 17.4241 16.9152 17.4241 16.501C17.4241 16.0868 17.0883 15.751 16.6741 15.751C16.2599 15.751 15.9241 16.0868 15.9241 16.501C15.9241 16.9152 16.2599 17.251 16.6741 17.251Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
