import React, {Fragment, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';

import styles from './header.module.scss';
import { Link } from 'react-router-dom';
import { withModal } from '../../hoc/withModal';

let menuClasses = [styles.hidden];

/**
 * @return {null}
 */
function Header(props) {
  const [scrollWidth, setScrollWidth] = useState(0);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  function toggleMenu() {
    const body = document.querySelector('body');
    if (menuClasses.includes(styles.hidden)) {
      menuClasses = menuClasses.filter(className => className !== styles.hidden);
    } else {
      menuClasses.push(styles.hidden);
    }
    body.classList.toggle(styles['overflow-hidden']);
    forceUpdate();
  }
  const isShowListLink = !props.location.search.includes('NGU2MGUzMjMtYTBjNi1lYTExLWE4MTItMDAwZDNhYjM5MzA3')
    && !props.location.search.includes('MzRhZjgzZGEtNjlkMS1lYTExLWE4MTItMDAwZDNhYjhkNWI1')
    && !props.location.search.includes('YjA0Njg1MDUtMWNjMC1lYTExLWE4MTItMDAwZDNhYjhkZTFl');

  const isLogin = !!props.user?.id;
  const loginUrl = isLogin ? `https://www.mobexo.de/Account/Login/LogOff?returnUrl=${window.location.pathname}${window.location.search}` : `https://www.mobexo.de/SignIn?returnUrl=${window.location.pathname}${window.location.search}`;
  const loginName = isLogin ? 'Abmelden' : 'Anmelden';

  useEffect(() => {
    setTimeout(() => {
      setScrollWidth(window.innerWidth - document.documentElement.clientWidth);
    }, 1000);
  }, []);

  return (
    null
    // <Fragment>
    //   <header style={{paddingRight: props.isMenuOpen ? scrollWidth+'px' : '0'}}>
    //     <div className={`${styles.container} ${styles['space-between']} ${styles['a-i-center']}`}>
    //       <a href='https://www.mobexo.de/'>
    //         <img className={styles.logo} src='/assets/images/logo.png' alt='Logo' />
    //       </a>
    //       <nav className={menuClasses.join(' ')}>
    //         <div className={styles['close-wrapper']}>
    //           <img className={styles['close-btn']} onClick={toggleMenu} src='/assets/images/close.png' alt='Close' />
    //         </div>
    //         <ul>
    //           {isShowListLink && <li>
    //             <a href='https://www.mobexo.de/list'>Unsere Angebote</a>
    //           </li>}
    //           <li>
    //             <a href='https://www.mobexo.de/faq'>Häufige Fragen</a>
    //           </li>
    //           <li className={styles['expand-item']}>
    //             <a href='https://www.mobexo.de/leistungen'>Leistungen</a>
    //             <span className={styles.expand} />
    //             <ul className={styles['submenu']}>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#fuhrparkmanagement'>Fuhrparkmanagement</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#fahrzeugkonfigurator'>Fahrzeugkonfigurator</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#alInclusiveLeasingraten'>All-Inclusive Leasingraten</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#fuhrparkverwaltung'>Online Plattform</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#sammelrechnung'>Sammelrechnung</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#terminerinnerungen'>Terminerinnerungen</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#schadenmanagement'>Schadenmanagement</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#fuhrerscheinprufung'>Führerscheinprüfung</a>
    //               </li>
    //               <li>
    //                 <a href='https://www.mobexo.de/leistungen#reporting'>Reporting</a>
    //               </li>
    //             </ul>
    //           </li>
    //           <li>
    //             <a href='https://www.mobexo.de/uber-uns'>Über uns</a>
    //           </li>
    //         </ul>
    //       </nav>
    //       <div
    //         className={`${styles['icons-wrapper']} ${styles['space-between']} ${styles['d-flex']} ${styles['a-i-center']}`}
    //       >
    //         <button className={styles.login}><a href={loginUrl}>{loginName}</a></button>
    //         <button onClick={() => props.openModal()} className={styles.kontakt}>Kontakt</button>
    //         <img
    //           className={`${styles['menu-button']} ${styles.pointer}`}
    //           src='/assets/images/menu.png'
    //           onClick={toggleMenu}
    //           alt='Menu'
    //         />
    //       </div>
    //     </div>
    //   </header>
    //   <div className={styles.margin} />
    // </Fragment>
  );
}

Header.propTypes = {
  openModal: PropTypes.func.isRequired
};


export default withModal(Header);
