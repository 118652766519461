import React from 'react';

export const DocumentIcon = props => (
  <svg width='20' height='14' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H10C11.1 16 12 15.1 12 14V2C12 0.9 11.1 0 10 0ZM9.5 13H5.5C5.2 13 5 12.8 5 12.5C5 12.2 5.2 12 5.5 12H9.5C9.8 12 10 12.2 10 12.5C10 12.8 9.8 13 9.5 13ZM9.5 10H5.5C5.2 10 5 9.8 5 9.5C5 9.2 5.2 9 5.5 9H9.5C9.8 9 10 9.2 10 9.5C10 9.8 9.8 10 9.5 10ZM9.5 7H5.5C5.2 7 5 6.8 5 6.5C5 6.2 5.2 6 5.5 6H9.5C9.8 6 10 6.2 10 6.5C10 6.8 9.8 7 9.5 7ZM9.5 4H5.5C5.2 4 5 3.8 5 3.5C5 3.2 5.2 3 5.5 3H9.5C9.8 3 10 3.2 10 3.5C10 3.8 9.8 4 9.5 4ZM3.5 4H2.5C2.2 4 2 3.8 2 3.5C2 3.2 2.2 3 2.5 3H3.5C3.8 3 4 3.2 4 3.5C4 3.8 3.8 4 3.5 4ZM3.5 7H2.5C2.2 7 2 6.8 2 6.5C2 6.2 2.2 6 2.5 6H3.5C3.8 6 4 6.2 4 6.5C4 6.8 3.8 7 3.5 7ZM3.5 10H2.5C2.2 10 2 9.8 2 9.5C2 9.2 2.2 9 2.5 9H3.5C3.8 9 4 9.2 4 9.5C4 9.8 3.8 10 3.5 10ZM3.5 13H2.5C2.2 13 2 12.8 2 12.5C2 12.2 2.2 12 2.5 12H3.5C3.8 12 4 12.2 4 12.5C4 12.8 3.8 13 3.5 13Z'
      fill={props?.color || '#102F3F'}
    />
  </svg>
);
