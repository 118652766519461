import React from 'react';
import { AnyObject } from 'common/interfaces';
import { getStatusColorForTaskPages } from 'utils/get-status-color-task-pages';
import { Text } from 'common/common-components.styled';

interface TaskStatusProps {
  row: AnyObject;
}

const TaskStatus = ({ row }: TaskStatusProps) => {
  return <Text color={getStatusColorForTaskPages(row)}>{row.statecode}</Text>;
};

export default TaskStatus;
