import React from 'react';

const CloseIcon = ({ width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.33474 2.33474C2.78105 1.88842 3.50467 1.88842 3.95098 2.33474L17.6653 16.049C18.1116 16.4953 18.1116 17.219 17.6653 17.6653C17.219 18.1116 16.4953 18.1116 16.049 17.6653L2.33474 3.95098C1.88842 3.50467 1.88842 2.78105 2.33474 2.33474Z'
        fill='#102F3F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6653 2.33474C18.1116 2.78105 18.1116 3.50467 17.6653 3.95098L3.95098 17.6653C3.50467 18.1116 2.78105 18.1116 2.33474 17.6653C1.88842 17.219 1.88842 16.4953 2.33474 16.049L16.049 2.33474C16.4953 1.88842 17.219 1.88842 17.6653 2.33474Z'
        fill='#102F3F'
      />
    </svg>
  );
};

export default CloseIcon;
