import { RequestAttribute } from 'utils/get-request-attributes';
import { TransformResponse } from 'common/interfaces';
import { objectUtility } from 'utils/object/object';

export const transformCrmResponse = <T extends Record<keyof T, RequestAttribute>>(response: T) => {
  const result = {} as TransformResponse<T>;

  objectUtility.entries(response).forEach(([key, value]) => {
    result[key] = value.attributeValue;
  });

  return result;
};
