import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { ChangeVehicleHistoryContext } from '../vehicle-history-modal/change-vehicle-history.context';
import { ChangeVehicleHistory } from '../vehicle-history-modal/history-modal';
import { CostCentersHistoryItem } from '../components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';

const getTitle = (isDriver: boolean, isAssign: boolean = false) => {
  if (!isAssign) {
    return 'Fahrer zuordnen';
  }

  if (isDriver) {
    return 'Fahrzeug hinterlegen';
  } else {
    return 'Fahrer wechseln';
  }
};

export const useHistoryModals = () => {
  const dispatch = useDispatch();

  const openHistoryModal = (
    callback: () => void,
    entityName: PrimaryEntityNames | string,
    id: string,
    fieldType: 'Fahrer' | 'Fahrzeug',
    isAssign?: boolean,
    item?: CostCentersHistoryItem,
    userId?: string,
    carHistoryId?: string,
    showInfoHint?: boolean,
    from?: any,
    to?: any,
    assignCar?: (carid: string) => Promise<void>
  ) => {
    const alertData = {
      title: getTitle(fieldType === 'Fahrer', isAssign),
      children: (
        <ChangeVehicleHistoryContext.Provider
          value={{ closeModal, callback, item, entityName, id, showInfoHint, userId, carHistoryId, isAssign, from, to }}
        >
          <ChangeVehicleHistory fieldType={fieldType} assignCar={assignCar} />
        </ChangeVehicleHistoryContext.Provider>
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return { openHistoryModal };
};
