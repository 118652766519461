import { AxiosRequestConfig } from 'axios';
import { MobexoEmployeeSetTaskStatusPayload } from './mobexo-employee-lease-end.types';

class MobexoEmployeeLeaseEndRequestConfig {
  setLVATaskStatus = ({ taskId, statusCode }: MobexoEmployeeSetTaskStatusPayload): AxiosRequestConfig => ({
    method: 'post',
    url: 'FuhrparkLeaseEnd/SetLvaTaskStatus',
    data: {
      TaskId: taskId,
      StatusCode: statusCode
    }
  });
}

export const mobexoEmployeeLeaseEndRequestConfig = new MobexoEmployeeLeaseEndRequestConfig();
