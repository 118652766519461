import React from 'react';
import { LeasingWidgetsContext } from './leasing-widgets-context';
import { LeasingWidgetsProps } from './leasing-widgets-layout.types';
import ReplacementVehicleOrdering from '../../widgets/leasing/replacement-vehicle-ordering/replacement-vehicle-ordering';
import { useLeasingWidgetsLayout } from './use-leasing-widgets-layout';
import Checklist from '../../widgets/leasing/check-list/check-list';
import VehicleReturn from '../../widgets/leasing/vehicle-return';
import LeaseExtensionDecision from '../../widgets/leasing/lease-extension-decision';
import VehicleInitialReview from '../../widgets/leasing/vehicle-initial-review';
import { WidgetPlaceholder, WidgetsContainer } from './leasing-widgets-layout.styled';

const LeasingWidgetsLayout: React.FC<LeasingWidgetsProps> = ({ car, refresh }) => {
  const {
    showReplacementVehicleOrderingWidget,
    showCheckListWidget,
    showVehicleReturnWidget,
    showLeaseExtensionDecisionWidget,
    showVehicleInitialReviewWidget,
    amountOfWidgetsToShow
  } = useLeasingWidgetsLayout(car);

  return (
    <LeasingWidgetsContext.Provider value={{ car, refresh }}>
      <WidgetsContainer amountOfWidgetsToShow={amountOfWidgetsToShow}>
        {showVehicleReturnWidget && <VehicleReturn />}
        {showReplacementVehicleOrderingWidget && <ReplacementVehicleOrdering />}
        {showCheckListWidget && <Checklist />}
        {showLeaseExtensionDecisionWidget && <LeaseExtensionDecision />}
        {showVehicleInitialReviewWidget && <VehicleInitialReview />}

        <WidgetPlaceholder amountOfWidgetsToShow={amountOfWidgetsToShow} />
      </WidgetsContainer>
    </LeasingWidgetsContext.Provider>
  );
};

export default LeasingWidgetsLayout;
