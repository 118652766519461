import React from 'react';
import classes from './user-icon.module.scss';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../selectors';
import { getUserInitials } from '../../../utils/get-initials';

const UserIcon = ({ onClick }) => {
  const user = useSelector(userSelector);

  return user.name ? (
    <div
      style={{ backgroundImage: user.avatar ? `url("${user.avatar}")` : 'none' }}
      className={classes.user_icon}
      onClick={onClick}
      id='breadcrumbsUserAvatar'
    >
      {!user.avatar && (
        <span className={classes.initials} id='breadcrumbsUserInitials'>
          {getUserInitials(user.name)}
        </span>
      )}
    </div>
  ) : null;
};

export default UserIcon;
