import React from 'react';
import LeasingWidget from '../components/leasing-widget';
import { CarContractIcon } from 'components/icons-new/car-contract-icon';
import { useTheme } from 'styled-components';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import { useChecklist } from './use-checklist';
import ChecklistButtons from './components/checklist-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';

const Checklist = ({}) => {
  const theme = useTheme();
  const { statusColor, statusLabel, description } = useChecklist();
  return (
    <LeasingWidget
      image={<CarContractIcon color={theme.colors.midBlue} width={60} height={40} />}
      statusColor={statusColor}
      statusLabel={statusLabel}
      buttons={<ChecklistButtons />}
      id={'checklist'}
    >
      <WidgetWrapper>
        <DescriptionContainer>{description}</DescriptionContainer>
      </WidgetWrapper>
    </LeasingWidget>
  );
};

export default Checklist;
