import { DraggableList } from 'components/draggable-list/draggable-list';
import { GetCarInspectionDateResponseItem } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { CarDraggableItem } from './components/car-draggable-item/car-draggable-item';
import { MultipleSection } from 'components/sections/multiple-section/multiple-section';
import { InspectionDateDraggableEnum } from './inspection-date.props';
import { useInspectionDateOnDragEnd } from './hooks/use-on-drag-end';
import { PAGES } from 'common/enums';
import { ContractIcon } from 'components/icons-new/contract';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import { useInspectionDateExpand } from './hooks/use-expand';
import { CustomDroppableContentContainer } from './components/custom-droppable-content-container/custom-droppable-content-container';

export const InspectionDateSection = () => {
  const { dragEndHandler } = useInspectionDateOnDragEnd();
  const { getData, isFetching, isExpanded, setIsExpanded, getIsShowExpandButton } = useInspectionDateExpand();

  return (
    <DragDropContext onDragEnd={dragEndHandler}>
      <MultipleSection
        nodeList={[
          <DraggableList<GetCarInspectionDateResponseItem>
            key={InspectionDateDraggableEnum.AssignedToVehicle}
            isLoading={isFetching}
            title='Zugeordnet'
            droppableId={InspectionDateDraggableEnum.AssignedToVehicle}
            data={getData('assignedPruftermines')}
            itemComponent={props => <CarDraggableItem type='assignedToCar' {...props} />}
            droppableContentContainer={{ customComponent: props => <CustomDroppableContentContainer {...props} /> }}
            emptyPlaceholder={{ title: 'Das Auto hat keine zugeordnete Prüfermine.' }}
          />,
          <DraggableList<GetCarInspectionDateResponseItem>
            key={InspectionDateDraggableEnum.NotAssignedToVehicle}
            isLoading={isFetching}
            title='Nicht zugeordnet'
            droppableId={InspectionDateDraggableEnum.NotAssignedToVehicle}
            data={getData('allPruftermines')}
            itemComponent={CarDraggableItem}
            droppableContentContainer={{ customComponent: props => <CustomDroppableContentContainer {...props} /> }}
            emptyPlaceholder={{
              title: 'Am Unternehmen sind keine Prüftermine hinterlegt.',
              link: { label: 'Prüftermine erstellen', push: PAGES.FLEET_SETTINGS_NOTIFICATION },
              icon: <ContractIcon />
            }}
          />
        ]}
        headerProps={{ bottom: '20' }}
      >
        {getIsShowExpandButton() && (
          <ShowMoreButton top='30' isExpanded={isExpanded} onClick={() => setIsExpanded(prev => !prev)} />
        )}
      </MultipleSection>
    </DragDropContext>
  );
};
