import React from 'react';

const SearchReapirIcon = ({ color = '#335566', width = '24', height = '24' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6589 20.5097C5.20632 20.5097 0.745117 16.0485 0.745117 10.5959C0.745117 5.24246 5.20632 0.78125 10.6589 0.78125C16.1115 0.78125 20.5727 5.24246 20.5727 10.695C20.4736 16.1476 16.1115 20.5097 10.6589 20.5097ZM10.6589 2.76401C6.29684 2.76401 2.72788 6.33297 2.72788 10.695C2.72788 15.0571 6.29684 18.6261 10.6589 18.6261C15.021 18.6261 18.5899 15.0571 18.5899 10.695C18.4908 6.33297 15.021 2.76401 10.6589 2.76401Z'
        fill={color}
      />
      <path
        d='M22.3571 23.7812C22.0597 23.7812 21.8614 23.6821 21.6632 23.4838L16.1114 17.9321C15.7149 17.5356 15.7149 16.9407 16.1114 16.5442C16.508 16.1476 17.1028 16.1476 17.4994 16.5442L23.0511 22.0959C23.4476 22.4925 23.4476 23.0873 23.0511 23.4838C22.952 23.6821 22.6545 23.7812 22.3571 23.7812Z'
        fill={color}
      />
    </svg>
  );
};

export default SearchReapirIcon;
