import React from 'react';
import classes from '../info-hint.module.scss';
import { CircleCheckMark } from '../../icons/circle-check-mark';
import { useTheme } from 'styled-components';
import { ExclamationMarkIcon } from '../../icons/exclamation-mark';
import CircleInfoIcon from 'components/icons/warning-icon';
import { InfoHintProps } from '../info-hint.props';

export const Icon = ({ type, iconColor, iconSize = 16 }: Pick<InfoHintProps, 'type' | 'iconSize' | 'iconColor'>) => {
  const theme = useTheme();

  switch (type) {
    case 'warning':
      return <CircleInfoIcon height={iconSize} width={iconSize} color={theme.colors[iconColor || 'darkGold']} />;

    case 'info':
      return <CircleInfoIcon height={iconSize} width={iconSize} color={theme.colors.darkBlue} />;

    case 'danger':
    case 'error':
      return <ExclamationMarkIcon />;

    case 'success':
      return (
        <span className={classes.icon}>
          <CircleCheckMark color={theme.colors.darkGreen} width={iconSize} height={iconSize} />
        </span>
      );

    default:
      return null;
  }
};
