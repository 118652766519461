import styled from 'styled-components';

interface ItemProps {
  isDragging: boolean;
}

export const StyledItemContainer = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 5px;
  padding: 13px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.white};

  box-shadow: ${({ isDragging, theme }) => (isDragging ? `3px 4px 4px ${theme.colors.grey200}` : null)};
`;
