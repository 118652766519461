import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, LookupResponse, ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../constants';
import { companyRequestConfig } from 'request-config/company/company.request-config';

interface ContactsWithEmailResponse {
  id: string;
  attributes: {
    fullname: string;
    parentcustomerid: LookupResponse;
  };
}

interface ContactsWithEmail {
  id: string;
  name: string;
}

const modifyResponse = ({ data }: ApiResponse<ContactsWithEmailResponse[]>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(item => ({
      id: item.id,
      name: `${item.attributes.fullname} (${item.attributes.parentcustomerid?.name})`
    }));
  }
  return [];
};

const useContactsWithEmail = (enabled: boolean = true) => {
  const { fetch } = useFetch<ResponseModel<ContactsWithEmailResponse[]>>({
    isShowLoading: false,
    isShowErrorSnackbar: false,
    ...companyRequestConfig.getParentCompanyContactList()
  });

  const { data: driversWithEmail } = useQuery<ApiResponse<ContactsWithEmailResponse[]>, unknown, ContactsWithEmail[]>(
    [ReactQueryKeys.GetDriversForBooking],
    () => fetch(),
    {
      select: modifyResponse,
      enabled
    }
  );

  return { driversWithEmail: driversWithEmail || [] };
};

export default useContactsWithEmail;
