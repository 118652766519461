import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IFormFieldContext } from 'components/form-hook/form-field/form.props';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { Moment } from 'moment';
import { Distance, Flex, Text } from 'common/common-components.styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledDatePicker, StyledTextFieldWithoutBorders } from 'components/form-hook/form-field/form-field.styled';
import { PrefixForId } from 'common/enums';
import { useTheme } from 'styled-components';
import { TimeField } from 'components/form-hook/form-field/components/time-field/time';
import {
  DateTimeFieldContext,
  DateTimeFieldContextProps
} from 'components/form-hook/form-field/components/date-time-field/date-time-field.context';
import useChangeDateTimeField from './hooks/use-change-date-time-field';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import IconHover from 'components/icon-hover';
import debounce from 'lodash/debounce';

const DateTimeField = () => {
  const theme = useTheme();
  const { field, value, onChange, error } = useContext<IFormFieldContext<Moment | null>>(formFieldProvider);
  const [dateTime, setDateTime] = useState(value);
  const debounceOnChange = useCallback(debounce(onChange, 500), []);
  const isFirstOnChangeCall = useRef(true);

  const { timeValue, changeTime } = useChangeDateTimeField(dateTime, setDateTime);

  useEffect(() => {
    if (isFirstOnChangeCall.current) {
      isFirstOnChangeCall.current = false;
      return;
    }
    debounceOnChange(dateTime);
  }, [dateTime?.format()]);

  useEffect(() => {
    setDateTime(value);
  }, [value?.format()]);

  const contextValue: DateTimeFieldContextProps = {
    valueDateTime: timeValue,
    onChangeDateTime: changeTime
  };

  return (
    <Flex width={field.width || '100%'}>
      <Flex direction='column' width='100%'>
        {field.label && (
          <Flex bottom='5'>
            <Text as='span' color='darkBlue'>
              {field.label}
            </Text>
            {field.validation.required && (
              <Text left='5' as='span' color='pink'>
                *
              </Text>
            )}
          </Flex>
        )}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledDatePicker
            views={field.calendarViews}
            error={!!error}
            components={{
              OpenPickerIcon: () => (
                <IconHover id={field.name + PrefixForId.FormDate + PrefixForId.Open}>
                  <CalendarWithCheckMarkIcon color={field.disabled ? theme.colors.grey300 : theme.colors.darkBlue} />
                </IconHover>
              )
            }}
            PopperProps={{
              id: field.name + PrefixForId.FormDate + PrefixForId.Calendar
            }}
            disabled={field.disabled}
            mask={field.mask || '__.__.____'}
            minDate={field.minDate}
            maxDate={field.maxDate}
            value={dateTime}
            onChange={(pickerValue: unknown) => {
              setDateTime(
                (pickerValue as Moment)
                  ?.startOf('day')
                  .add(dateTime?.hour(), 'hours')
                  .add(dateTime?.minute(), 'minutes') || null
              );
            }}
            renderInput={({ inputProps, ...restParams }) => (
              <StyledTextFieldWithoutBorders
                {...restParams}
                error={!!error}
                inputProps={{
                  ...inputProps,
                  placeholder: field.placeholder || 'TT . MM . JJJJ',
                  id: field.name + PrefixForId.FormDate,
                  error: !!error
                }}
                helperText={field.helperText}
              />
            )}
            shouldDisableDate={field.shouldDisableDate}
            renderDay={field.renderDay}
          />
        </LocalizationProvider>
      </Flex>
      <Distance side='20' />
      <Flex direction='column' width='150px'>
        {field.label && (
          <Flex bottom='5'>
            <Text as='span' color='darkBlue'>
              Uhrzeit
            </Text>
            {field.validation.required && (
              <Text left='5' as='span' color='pink'>
                *
              </Text>
            )}
          </Flex>
        )}
        <DateTimeFieldContext.Provider value={contextValue}>
          <TimeField borderColor={theme.colors.grey500} disabled={field.disabled} />
        </DateTimeFieldContext.Provider>
      </Flex>
    </Flex>
  );
};

export default DateTimeField;
