import { ConditionOperator, CrmDataTypes } from 'common/enums';
import { CompaniesByTypesProps } from 'request-config/company/company.types';
import { GetEntityByFieldQueryProps } from 'request-config/crm-entity/crm-entity.types';

class SourceFetchPayload {
  getCarBrands: GetEntityByFieldQueryProps = {
    entityName: 'uds_carbrand',
    columns: ['uds_carbrandid', 'uds_name'],
    conditions: [
      {
        attributeTypeCode: CrmDataTypes.State,
        attributeName: 'statecode',
        conditionOperator: ConditionOperator.Equal,
        value: [{ value: '0' }]
      }
    ],
    order: { predicate: 'uds_name', reverse: false }
  };

  getCarBrandsForGrv: GetEntityByFieldQueryProps = {
    entityName: 'uds_carbrand',
    columns: ['uds_carbrandid', 'uds_name'],
    conditions: [
      {
        attributeTypeCode: CrmDataTypes.State,
        attributeName: 'statecode',
        conditionOperator: ConditionOperator.Equal,
        value: [{ value: '0' }]
      },
      {
        attributeTypeCode: CrmDataTypes.Boolean,
        attributeName: 'uds_available_for_grv',
        conditionOperator: ConditionOperator.Equal,
        value: [1]
      }
    ],
    order: { predicate: 'uds_name', reverse: false }
  };

  getCompanyContracts = (companyId: string): GetEntityByFieldQueryProps => ({
    entityName: 'uds_rahmenvertrges',
    columns: ['uds_vertragsbezeichnung', 'uds_rahmenvertrgesid'],
    conditions: [
      {
        attributeTypeCode: CrmDataTypes.Lookup,
        attributeName: 'new_kundeid',
        conditionOperator: ConditionOperator.Equal,
        value: [
          {
            id: companyId,
            logicalName: '',
            name: ''
          }
        ]
      },
      {
        attributeTypeCode: CrmDataTypes.State,
        attributeName: 'statecode',
        conditionOperator: ConditionOperator.Equal,
        value: [{ value: '0' }]
      }
    ]
  });

  getContractPartnerCompanies: CompaniesByTypesProps = {
    CustomerTypeCodes: [100000005, 11, 10, 100000000],
    Attributes: ['accountid', 'name', 'uds_testcompanycode']
  };

  getServices: CompaniesByTypesProps = {
    CustomerTypeCodes: [100000000, 100000005, 14, 2, 13, 1, 11, 10],
    Attributes: ['accountid', 'name']
  };
}

export const sourceFetchPayload = new SourceFetchPayload();
