/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import { ComponentsProps } from './components.props';

const TwoLongButtonsComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldName,
  firstButtonText = 'Ja',
  firstButtonValue = true,
  secondButtonText = 'Nein',
  secondButtonValue = false
}: ComponentsProps) => {
  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors }
  } = reactHookFormData;

  const setFirstValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, firstButtonValue);
    e.preventDefault();
  };

  const setSecondValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, secondButtonValue);
    e.preventDefault();
  };

  useEffect(() => {
    if (watch(fieldRegister) !== undefined) {
      clearErrors(fieldRegister);
    }
  }, [errors[fieldRegister], watch(fieldRegister)]);

  return (
    <div className={styles.field_container}>
      <div className={errors[fieldRegister] ? styles.form_name_button_error : styles.form_name_button}>
        <span>{fieldName}</span>
      </div>
      <Distance top='-5' />
      <button
        onClick={e => setFirstValue(e)}
        id={`longButton${firstButtonValue}`}
        className={watch(fieldRegister) === firstButtonValue ? styles.button_option_true : styles.button_option_false}
      >
        {firstButtonText}
      </button>
      <Distance top='10' />
      <button
        onClick={e => setSecondValue(e)}
        id={`longButton${secondButtonValue}`}
        className={watch(fieldRegister) === secondButtonValue ? styles.button_option_true : styles.button_option_false}
      >
        {secondButtonText}
      </button>

      <Distance top='10' />
    </div>
  );
};

export default TwoLongButtonsComponent;
