export const SET_FLATRATE = 'SET_FLATRATE';
export const RESET_FLATRATE = 'RESET_FLATRATE';
export const CHANGE_FLATRATE_SELECTION = 'CHANGE_SELECTION';
export const SET_FINANZRATE = 'SET_FINANZRATE';

export const setFinanzrate = finanzrate => ({
  type: SET_FINANZRATE,
  payload: finanzrate
});

export const setFlatrate = obj => ({
  type: SET_FLATRATE,
  payload: obj
});

export const resetFlatrate = () => ({
  type: RESET_FLATRATE
});

export const changeFlatrateSelection = (name, checked = true, price) => ({
  type: CHANGE_FLATRATE_SELECTION,
  payload: {
    name,
    checked,
    price
  }
});
