import { Position, ShortNameOfMonths } from 'common/enums';
import { mainTheme } from 'common/global-styles';
import { ChartCO2Labels } from 'components/dashboard-component-chart/dashboard.props';

export const options = {
  responsive: true,
  plugins: {
    legend: { display: false },
    tooltip: {
      backgroundColor: mainTheme.colors.darkBlue,
      yAlign: Position.Bottom as const,
      xAlign: Position.Center as const,
      callbacks: {
        title: () => '',
        label: (context: any) => `${context.formattedValue} t`
      }
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: '#ABB0B2',
        padding: 0
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        color: 'rgba(208, 208, 208, 0.4)'
      },
      ticks: {
        color: mainTheme.colors.grey300,
        padding: 10
      }
    }
  }
};

export const data = {
  labels: Object.values(ShortNameOfMonths),
  datasets: [
    {
      label: ChartCO2Labels.Diesel,
      data: [0],
      borderColor: mainTheme.colors.pink,
      backgroundColor: mainTheme.colors.pink
    },
    {
      label: ChartCO2Labels.Petrol,
      data: [0],
      borderColor: mainTheme.colors.gold,
      backgroundColor: mainTheme.colors.gold
    },
    {
      label: ChartCO2Labels.Electricity,
      data: [0],
      borderColor: mainTheme.colors.green,
      backgroundColor: mainTheme.colors.green
    }
  ]
};
