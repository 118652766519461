import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';

export const useFormInfoListDataFuelConsumption = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const formInfoListDataFuelConsumption: FormInfoItemEditableProps[] = [
    {
      value: fuhrpark?.uds_verbrauch,
      label: 'Kraftstoffverbrauch kombiniert',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.text({ name: 'uds_verbrauch', defaultValue: fuhrpark?.uds_verbrauch })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_fuelconsumption_out_of_city,
      label: 'Kraftstoffverbrauch außerorts',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'uds_fuelconsumption_out_of_city',
              defaultValue: fuhrpark?.uds_fuelconsumption_out_of_city
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_fuelconsumption_urban,
      label: 'Kraftstoffverbrauch innerorts	',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'uds_fuelconsumption_urban',
              defaultValue: fuhrpark?.uds_fuelconsumption_urban
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_stromverbrauch,
      label: 'Stromverbrauch',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({ name: 'uds_stromverbrauch', defaultValue: fuhrpark?.uds_stromverbrauch })
          ]}
        />
      )
    }
  ];

  return { formInfoListDataFuelConsumption };
};
