import { QueryClientConfig } from 'react-query';

export const reactQuerySetting: QueryClientConfig = {
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      retryOnMount: false
    }
  }
};
