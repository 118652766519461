import React, { useEffect, useMemo, useState } from 'react';
import Spinner from 'components/spinner';
import { defaultSpinnerStyle } from '../vehicle-gallery/utils';
import Slider from 'react-slick';
import clsx from 'clsx';
import { GalleryItem } from './gallery-item';
import { GalleryProps } from './gallery.props';
import classes from './gallery.module.scss';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { LoadingState } from 'components/overlay/overlay.props';
import { isEmpty } from 'lodash';
import { EmptyPlaceholder } from './components/empty-placeholder/empty-placeholder';
import ArrowLeft from 'components/icons-new/arrow-left';
import ArrowRight from 'components/icons-new/arrow-right';
import { useTheme } from 'styled-components';

const getSlidesNumb = (innerWidth: number) => {
  if (innerWidth <= 1599 && innerWidth >= 556) {
    return 3;
  } else if (innerWidth > 1599) {
    return 2;
  } else {
    return 2;
  }
};

export const Gallery = ({
  images,
  gallery,
  isLoading,
  thumbnailsImageClassName,
  thumbnailsImageContainerClassName,
  galleryItemProps,
  thumbnailsSettings: thumbnailsSettingsProps = {},
  ...restPlaceholderProps
}: GalleryProps) => {
  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();
  const [activeDot, setActiveDot] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const slidesNumb = useMemo(() => {
    return getSlidesNumb(window.innerWidth);
  }, [window.innerHeight]);
  const numDots = Math.ceil(images.length / slidesNumb);
  const loading = useTypedSelector<LoadingState>(state => state['app'].loading);

  const theme = useTheme();

  const mainGallerySettings = {
    slidesToShow: 1,
    dots: false,
    infinite: false,
    speed: 300,
    centralMode: true,
    centerPadding: '60px',
    arrows: false,
    afterChange: (index: number) => {
      setActiveSlide(index);
    }
  };

  const thumbnailsSettings = {
    slidesToShow: images.length >= 4 ? 4 : images.length,
    slidesToScroll: 4,
    className: 'center',
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    focusOnSelect: true,
    ...thumbnailsSettingsProps,
    responsive: [
      ...(thumbnailsSettingsProps?.responsive || []),
      {
        breakpoint: 1799,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  useEffect(() => {
    if (numDots >= 2) {
      setActiveDot(activeSlide !== 0 ? Math.floor(activeSlide / slidesNumb) : 0);
    }
  }, [activeSlide, numDots]);

  if (!images || !isEmpty(loading.state) || isLoading) {
    return <Spinner style={defaultSpinnerStyle} />;
  }

  if (!images.length) {
    return <EmptyPlaceholder {...restPlaceholderProps} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.main_slick_list_wrapper}>
        <Slider
          {...mainGallerySettings}
          asNavFor={nav2}
          ref={slider1 => setNav1(slider1 != null ? slider1 : undefined)}
          className={classes.slick_list}
        >
          {images.map((item, index) => (
            <div className={classes.container} key={index}>
              <GalleryItem key={index} index={index} image={item} allFiles={gallery} {...galleryItemProps} />

              <button
                className={clsx(classes.button_mobile, classes.nav_left_mobile)}
                onClick={() => nav1?.slickPrev()}
              >
                <ArrowLeft width={12} height={12} color={theme.colors.white} />
              </button>
              <button
                className={clsx(classes.button_mobile, classes.nav_right_mobile)}
                onClick={() => nav1?.slickNext()}
              >
                <ArrowRight width={12} height={12} color={theme.colors.white} />
              </button>
            </div>
          ))}
        </Slider>
      </div>
      {images.length >= 2 && (
        <div className={classes.thumbnail_container}>
          <button className={clsx(classes.button, classes.nav_left)} onClick={() => nav1?.slickPrev()}>
            <img src='/assets/images/gallery-arrow-left.svg' />
          </button>
          <div className={thumbnailsImageContainerClassName || classes.thumbnails_gallery}>
            <Slider
              {...thumbnailsSettings}
              swipeToSlide={true}
              asNavFor={nav1}
              ref={slider2 => setNav2(slider2 != null ? slider2 : undefined)}
            >
              {images.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  className={clsx(
                    thumbnailsImageClassName || classes.thumbnails_img_size,
                    classes.thumbnails_img,
                    index === activeSlide && classes.active_slide
                  )}
                />
              ))}
            </Slider>
          </div>
          <button className={clsx(classes.button, classes.nav_right)} onClick={() => nav1?.slickNext()}>
            <img src='/assets/images/gallery-arrow-right.svg' />
          </button>
        </div>
      )}
      {numDots >= 2 && (
        <div className={classes.custom_dots}>
          {Array.from({ length: numDots }, (_, index) => (
            <span
              key={index}
              className={clsx(classes.dot, index === activeDot && classes.active_dot)}
              onClick={() => {
                const newIndex = index * slidesNumb;
                setActiveDot(newIndex !== 0 ? Math.floor(newIndex / slidesNumb) : 0);
                nav2?.slickGoTo(newIndex);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
