import React from 'react';
import { Circle, Flex, Text } from 'common/common-components.styled';
import { RenderFocusedSegmentProps } from 'components/widgets/doughnut/doughnut.props';
import toMoney from 'utils/toMoney';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

export const TotalRunningTimeCostsDoughnutFocusedSegment = ({ data }: RenderFocusedSegmentProps) => {
  const { label, value, color } = data;

  return (
    <Flex width='100%' direction='column' align='center' zIndex={1}>
      <EllipsisWithTooltip tooltip={toMoney(value)}>
        <Text id='totalRunningTimeCostsDoughnutFocusedSegmentMoney' color='blue' bold>
          {toMoney(value)}
        </Text>
      </EllipsisWithTooltip>

      <Flex width='100%' align='center'>
        <Circle color={color} size='6px' />
        <EllipsisWithTooltip tooltip={label}>
          <Text id='totalRunningTimeCostsDoughnutFocusedSegmentLabel' size='12' left='5'>
            {label}
          </Text>
        </EllipsisWithTooltip>
      </Flex>
    </Flex>
  );
};
