
import { SET_OPTIONS } from '../actions/app_action';

const initialState = {
    options: {

    }
}

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPTIONS:
            return {...state, options: action.payload}


        default:
            return state;
    }
}
