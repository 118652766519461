import { VehicleOfferEventData } from 'components/order-status-section/order-status-section.props';
import { AppPage, ShowInAppValues, VehicleOfferEventDescription } from 'common/interfaces';

export const getMajorEventDescription = (
  eventDescriptions: VehicleOfferEventDescription[],
  events: VehicleOfferEventData[]
) => {
  if (!events.length || !eventDescriptions.length) {
    return null;
  }
  let majorEventDescription = eventDescriptions.sort((a, b) => a.uds_hierarchie - b.uds_hierarchie)[0];
  events.forEach(event => {
    const eventDescriptionId = event.uds_eventdescription_id;
    const eventDescription = eventDescriptions.find(
      eventDescription => eventDescription.uds_fahrzeugbestellungeneventdescriptionid === eventDescriptionId
    );

    if (eventDescription && eventDescription.uds_hierarchie > majorEventDescription.uds_hierarchie) {
      majorEventDescription = eventDescription;
    }
  });

  return majorEventDescription;
};

export const getMinorEventDescription = (
  eventDescriptions: VehicleOfferEventDescription[],
  events: VehicleOfferEventData[]
) => {
  if (!events.length || !eventDescriptions.length) {
    return null;
  }
  let minorEventDescription = eventDescriptions.sort((a, b) => b.uds_hierarchie - a.uds_hierarchie)[0];
  events.forEach(event => {
    const eventDescriptionId = event.uds_eventdescription_id;
    const eventDescription = eventDescriptions.find(
      eventDescription => eventDescription.uds_fahrzeugbestellungeneventdescriptionid === eventDescriptionId
    );

    if (eventDescription && eventDescription.uds_hierarchie < minorEventDescription.uds_hierarchie) {
      minorEventDescription = eventDescription;
    }
  });

  return minorEventDescription;
};

export const getStatusSectionEventDescriptions = (
  eventDescriptions: VehicleOfferEventDescription[],
  events: VehicleOfferEventData[],
  mainRoleId: string,
  appPage: AppPage | undefined,
  onlyDoneEvents?: boolean
) => {
  const eventDescriptionsWithHierarchy =
    eventDescriptions.filter(eventDescription => eventDescription.uds_hierarchie) || [];

  return eventDescriptionsWithHierarchy
    .filter(eventDescription => {
      const webrole = eventDescription.webroles.find(
        webrole => webrole.uds_apppageid_id === appPage?.uds_appageid && webrole.uds_b2c_webrollid_id === mainRoleId
      );
      if (!webrole) {
        return false;
      }
      const isDone = events.some(
        event => event.uds_eventdescription_id === eventDescription.uds_fahrzeugbestellungeneventdescriptionid
      );
      if (onlyDoneEvents) {
        return isDone;
      }
      return (
        webrole.uds_show_in_appcode_value === ShowInAppValues.Always ||
        (webrole.uds_show_in_appcode_value === ShowInAppValues.OnlyIfDone && isDone)
      );
    })
    .map(eventDescription => ({
      ...eventDescription,
      eventsCount: events.filter(
        event => event.uds_eventdescription_id === eventDescription.uds_fahrzeugbestellungeneventdescriptionid
      ).length
    }))
    .sort((a, b) => b.uds_hierarchie - a.uds_hierarchie);
};

export function takeStatusSectionLatestEvent(events: VehicleOfferEventData[]) {
  if (!events.length) {
    return undefined;
  }

  return events.sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime())[0];
}

export function takeStatusSectionOldestEvent(events: VehicleOfferEventData[]) {
  if (!events.length) {
    return undefined;
  }

  return events.sort((a, b) => new Date(a.createdon).getTime() - new Date(b.createdon).getTime())[0];
}
