import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { CrmDataTypes } from 'common/enums';
import { BOOLEAN_OPTION_SET } from 'components/form-hook/boolean-option-set-constants';
import { errorMessages } from 'utils/error-messages';
import { PickValue } from 'common/interfaces';
import { GenerateFormFieldPropsType } from 'utils/form/generate-form-fields/generate-form-fields.props';
import { ORGANIZATION_TOOLTIP } from './utils';
import { useVehicleDetailIsDisableEditMode } from 'pages/existing-vehicle-page/hooks/use-is-disable-edit-mode';
import { usePoolCarClassesField } from './fields/use-pool-car-classes';
import { useCostCenterField } from './fields/use-cost-center';
import { useCorporateTaxResponsibilityField } from './fields/use-corporate-tax-responsibility';
import { useVehicleDetailAssignmentFieldSubmit } from 'pages/existing-vehicle-page/hooks/fileds-submit/use-assignment';

export const useFormInfoListDataOrganization = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark, tankkartes } = fleetByIdQuery;

  const { isDisableEditMode } = useVehicleDetailIsDisableEditMode();

  const { corporateTaxResponsibilityField } = useCorporateTaxResponsibilityField();
  const { costCenterField } = useCostCenterField();
  const { poolCarClassesField } = usePoolCarClassesField();
  const { assignmentSubmitCallback } = useVehicleDetailAssignmentFieldSubmit();

  const getFuelCardTitle = () => {
    if (!tankkartes?.length) {
      return '-';
    }

    return tankkartes
      .map(fuelCard => {
        const providersList = fuelCard?.uds_providercode?.map(item => item?.label).join(', ');
        return `${providersList || '-'} (${fuelCard.uds_nummer || 'keine Tankkartennummer'})`;
      })
      .join('; ');
  };

  const COST_CENTER_VALIDATION: PickValue<GenerateFormFieldPropsType, 'validation'> = {
    min: { value: 0, message: errorMessages.getMaxNumberValue(0) },
    max: { value: 100, message: errorMessages.getMaxNumberValue(100) }
  };

  const formInfoListDataOrganization: FormInfoItemEditableProps[] = [
    {
      value: fuhrpark?.[ORGANIZATION_FORM_FIELDS.company],
      label: 'Unternehmen',
      isDisableEditMode: true
    },
    {
      value: fuhrpark?.uds_iserinnerungen_zulassen
        ? BOOLEAN_OPTION_SET.activeOrInactive.labels.true
        : BOOLEAN_OPTION_SET.activeOrInactive.labels.false,
      label: 'Erinnerungen',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.booleanOptionSet({
              name: 'uds_iserinnerungen_zulassen',
              defaultValue: fuhrpark?.uds_iserinnerungen_zulassen?.toString(),
              labels: BOOLEAN_OPTION_SET.activeOrInactive.labels
            })
          ]}
        />
      )
    },
    corporateTaxResponsibilityField,
    {
      value: fuhrpark?.new_tuv,
      label: 'Nächste HU',
      valueType: 'date',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.date({ name: 'new_tuv', defaultValue: fuhrpark?.new_tuv })]}
        />
      )
    },
    costCenterField,
    {
      value: fuhrpark?.new_uvv,
      label: 'Nächste UVV',
      valueType: 'date',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.date({ name: 'new_uvv', defaultValue: fuhrpark?.new_uvv })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_cost_center1_anteil,
      label: 'Kst. 1 Anteil',
      valueType: 'number',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({
              name: 'uds_cost_center1_anteil',
              defaultValue: fuhrpark?.uds_cost_center1_anteil,
              validation: COST_CENTER_VALIDATION
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.new_inspektion,
      label: 'Inspektion',
      valueType: 'date',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.date({ name: 'new_inspektion', defaultValue: fuhrpark?.new_inspektion })]}
        />
      )
    },
    {
      value: fuhrpark?.uds_kostenstelle2,
      label: 'Kostenstelle 2',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({ name: 'uds_kostenstelle2', defaultValue: fuhrpark?.uds_kostenstelle2 })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_hu_interval_monatecode && `${fuhrpark.uds_hu_interval_monatecode} Monate`,
      label: 'HU Interval',
      isDisableEditMode: true
    },
    {
      value: fuhrpark?.uds_cost_center2_anteil,
      label: 'Kst. 2 Anteil',
      valueType: 'number',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({
              name: 'uds_cost_center2_anteil',
              defaultValue: fuhrpark?.uds_cost_center2_anteil,
              validation: COST_CENTER_VALIDATION
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.new_leasinggeber,
      label: 'Leasinggeber',
      isDisableEditMode: true,
      tooltip: ORGANIZATION_TOOLTIP.new_leasinggeber
    },
    {
      value: fuhrpark?.uds_kostenstelle3,
      label: 'Kostenstelle 3',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({ name: 'uds_kostenstelle3', defaultValue: fuhrpark?.uds_kostenstelle3 })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.new_leasingstart,
      label: 'Leasingstart',
      valueType: 'date',
      isDisableEditMode: true,
      tooltip: ORGANIZATION_TOOLTIP.new_leasingstart
    },
    {
      value: fuhrpark?.uds_cost_center3_anteil,
      label: 'Kst. 3 Anteil',
      valueType: 'number',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({
              name: 'uds_cost_center3_anteil',
              defaultValue: fuhrpark?.uds_cost_center3_anteil,
              validation: COST_CENTER_VALIDATION
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_leasinglaufzeit && `${fuhrpark.uds_leasinglaufzeit} Monate`,
      label: 'Leasinglaufzeit',
      isDisableEditMode: true,
      tooltip: ORGANIZATION_TOOLTIP.uds_leasinglaufzeit
    },
    {
      value: fuhrpark?.uds_interne_fahrzeugnummer_kunde,
      label: 'Interne Fahzeugnummer',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'uds_interne_fahrzeugnummer_kunde',
              defaultValue: fuhrpark?.uds_interne_fahrzeugnummer_kunde,
              validation: { maxLength: { value: 50, message: errorMessages.getMaxTextLength(50) } }
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.new_leasingende,
      label: 'Leasingende',
      valueType: 'date',
      isDisableEditMode: true,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.date({ name: 'new_leasingende', defaultValue: fuhrpark?.new_leasingende })]}
        />
      ),
      tooltip: ORGANIZATION_TOOLTIP.new_leasingende
    },
    {
      value: fuhrpark?.new_zuordnung,
      label: 'Zuordnung',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          submitCallback={assignmentSubmitCallback}
          formFields={[
            generateFormField.optionSet({
              name: 'new_zuordnung',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.new_zuordnung_value,
              options: OPTION_SET.new_zuordnung
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_leaseenddate_extended,
      label: 'Leasingende stillschweigend verlängert bis',
      valueType: 'date',
      isDisableEditMode: true,
      tooltip: ORGANIZATION_TOOLTIP.uds_leaseenddate_extended
    },
    {
      value: fuhrpark?.new_privatnutzung,
      label: 'Privatnutzung',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.booleanOptionSet({
              name: 'new_privatnutzung',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.new_privatnutzung_value,
              values: { true: 100_000_000, false: 100_000_001 }
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.new_abgangsdatum,
      label: 'Abgangsdatum',
      valueType: 'date',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[generateFormField.date({ name: 'new_abgangsdatum', defaultValue: fuhrpark?.new_abgangsdatum })]}
        />
      )
    },
    {
      value: fuhrpark?.new_zuordnung_beschreibung,
      label: 'Zuordnung Beschreibung',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'new_zuordnung_beschreibung',
              defaultValue: fuhrpark?.new_zuordnung_beschreibung
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_lease_return_date,
      label: 'Leasingrückgabedatum',
      valueType: 'date',
      isDisableEditMode: true
    },
    {
      value: getFuelCardTitle(),
      label: 'Tankkarten',
      isDisableEditMode: true
    },
    {
      value: fuhrpark?.uds_ersatzbestellung,
      label: 'Ersatzbeschaffung',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({ name: 'uds_ersatzbestellung', defaultValue: fuhrpark?.uds_ersatzbestellung })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_mileage_on_return,
      label: 'Km-Stand bei Rückgabe',
      valueType: 'number',
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({ name: 'uds_mileage_on_return', defaultValue: fuhrpark?.uds_mileage_on_return })
          ]}
        />
      )
    },
    poolCarClassesField
  ];

  return { formInfoListDataOrganization };
};

export const ORGANIZATION_FORM_FIELDS = {
  company: 'new_sparteid'
} as const;
