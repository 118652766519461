import axios from 'axios';
import authService from "./auth-service";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function createLead(model) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/contact-us/info`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: model
  });
}
