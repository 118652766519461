import React, { CSSProperties, LegacyRef, cloneElement, useContext } from 'react';
import classes from '../cell.module.scss';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import { toLocaleDateStringSetup } from '../../../../constants';
import classNames from 'classnames';
import { CellContext } from 'components/table-component/table-component-contexts';
import useEllipsisTooltip from 'hooks/use-ellipsis-tooltip';
import get from 'lodash/get';

export const DateCell = ({ column, row, openedItemId }: CellProps) => {
  const { cellId } = useContext<ICellContext>(CellContext);
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(
    0,
    0,
    !!column.alwaysShowTooltip && !!row[column.propName as string],
    !!column.neverShowTooltip
  );

  const getDate = () => {
    const isDateInvalid = isNaN(Date.parse(row[column.propName || '']));

    return isDateInvalid && column.shouldShowTextOnInvalidDate
      ? row[column.propName || '']
      : new Date(row[column.propName || '']).toLocaleDateString('de-DE', toLocaleDateStringSetup);
  };

  const getText = () => {
    if (column.component) {
      return column.component;
    }

    if (row[column.propName || '']) {
      return getDate();
    }

    return '-';
  };

  const tooltipText = column.customTooltip ? column.customTooltip(row) : getText();

  return (
    <span
      id={cellId}
      style={{ width: column.width }}
      ref={content as LegacyRef<HTMLElement>}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={onMouseLeave}
      className={classNames(classes.table_text, column.className, classes.cell)}
    >
      {row[column.propName || ''] ? getDate() : '-'}
      {isShowTip && <span style={style as CSSProperties}>{tooltipText}</span>}
    </span>
  );
};
