import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CalendarWithCheckMarkIcon = ({
  color: colorProp,
  fill: fillProp,
  width = '20',
  height = '18',
  hovered,
  id
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg id={id} width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1' y='2.52542' width='18' height='4.70892' fill='white' />}
      <path
        d='M9.54237 2.52539H5.88135'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0339 2.52539H11.3729'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && <path d='M16.6742 17.1695H19L19 6.18649H1V17.1696L16.6742 17.1695Z' fill={fill} />}
      <path
        d='M17.0681 2.52539H19V17.1695H16.6742M4.05085 2.52548H1V17.1696H14.3825M4.66101 6.18649H19'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.44067 2.52542C3.44067 1.68644 3.98983 1 4.66101 1C5.3322 1 5.88135 1.68644 5.88135 2.52542C5.88135 3.36441 5.3322 4.05085 4.66101 4.05085'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.93219 2.52539C8.93219 1.68641 9.48134 0.999969 10.1525 0.999969C10.8237 0.999969 11.3729 1.68641 11.3729 2.52539C11.3729 3.36438 10.8237 4.05082 10.1525 4.05082'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.4237 2.52539C14.4237 1.68641 14.9729 0.999969 15.6441 0.999969C16.3153 0.999969 16.8644 1.68641 16.8644 2.52539C16.8644 3.36438 16.3153 4.05082 15.6441 4.05082'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88367 11.5385L4.11036 11.5385'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88367 8.70746L4.11036 8.70746'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88367 14.3695L4.11036 14.3695'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0392 11.5385L9.26591 11.5385'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0392 8.70746L9.26588 8.70746'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0392 14.3695L9.26591 14.3695'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1947 11.5385L14.4214 11.5385'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1947 8.70746L14.4214 8.70746'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1947 14.3695L14.4214 14.3695'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
