import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledQualitySectionContainer = styled(Flex)<{ isActive: boolean }>`
  height: 35px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 5px;
  ${({ isActive, theme }) => isActive && `background-color: ${theme.colors.extraLightBlue};`}

  :hover {
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
  }
`;
