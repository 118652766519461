import { useHistoryModals } from '../use-history-modal';
import { USER_ROLES } from '../../../../constants';
import {
  ChangedFieldTypes,
  PrimaryEntityNames,
  PrimaryEntityValues
} from 'components/cost-centers-settings/cost-centers-settings.props';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { StateCodeValueEnum } from 'components/driving-license-control-component/driving-license-control.props';
import { useVehicleDetailsClearCash } from '../use-clear-cash';
import { useChangeCostCenterModals } from 'components/cost-centers-settings/hooks/use-change-cost-center-modals';
import { VehicleDetailsEditFormSubmitCallbackParams } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form.props';
import { useVehicleDetailsCostCenterPrimaryEntity } from '../use-cost-center-primary-entity';

export const useVehicleDetailDriverFieldSubmit = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();

  const availableForRoles = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const { fuhrpark } = fleetByIdQuery;
  const isActiveStateCode = fuhrpark?.statecode_value === StateCodeValueEnum.Active;

  const { clearMainCash, clearVehicleHistoryCash } = useVehicleDetailsClearCash();

  const { costCenterPrimaryEntity } = useVehicleDetailsCostCenterPrimaryEntity();
  const { openChangeLocationOrDriverModal } = useChangeCostCenterModals();
  const { openHistoryModal } = useHistoryModals();

  const driverSubmitCallback = ({ values }: VehicleDetailsEditFormSubmitCallbackParams) => {
    if (values.new_fahrerid && isActiveStateCode && availableForRoles) {
      openHistoryModal(
        () => {
          if (costCenterPrimaryEntity === PrimaryEntityValues.Driver) {
            openChangeLocationOrDriverModal(ChangedFieldTypes.Driver, () => {
              clearVehicleHistoryCash();
              clearMainCash();
            });
          } else {
            clearVehicleHistoryCash();
            clearMainCash();
          }
        },
        PrimaryEntityNames.Vehicle,
        fuhrpark?.new_fuhrparkid || '',
        'Fahrzeug',
        true,
        undefined,
        values.new_fahrerid,
        undefined,
        false
      );

      return true;
    }

    return false;
  };

  return { driverSubmitCallback };
};
