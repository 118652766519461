import styled from 'styled-components';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const StyledLeasingRequestDatePicker = styled(DesktopDatePicker)`
  width: 100%;

  & .MuiOutlinedInput-root {
    padding: 8px 3px 10px 15px;
    flex-direction: row-reverse;
    letter-spacing: 0.01em;

    & .MuiIconButton-root {
      margin-right: 0;
      margin-left: -22px;
    }

    & .MuiOutlinedInput-input {
      text-align: right;
      color: ${({ theme }) => theme.colors.grey800};
    }
  }
`;
