import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { CrmDataTypes } from 'common/enums';

export const useFormInfoListDataEnvironment = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const formInfoListDataEnvironment: FormInfoItemEditableProps[] = [
    {
      value: fuhrpark?.uds_co2_efficiencycode,
      label: 'CO2 Effizienz',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'uds_co2_efficiencycode',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.uds_co2_efficiencycode_value,
              options: OPTION_SET.uds_co2_efficiencycode
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_emissioncategorycode,
      label: 'Schadstoffklasse',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'uds_emissioncategorycode',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.uds_emissioncategorycode_value,
              options: OPTION_SET.uds_emissioncategorycode
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_co2_emission_combined,
      label: 'CO2-Emissionen Kombiniert (g/km)',
      valueType: 'number',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({
              name: 'uds_co2_emission_combined',
              defaultValue: fuhrpark?.uds_co2_emission_combined
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_feinstaubplakette,
      label: 'Feinstaubplakette',
      valueType: 'number',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.number({ name: 'uds_feinstaubplakette', defaultValue: fuhrpark?.uds_feinstaubplakette })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_co2_emission_uom,
      label: 'CO2-Emissionen (Maßeinheit)',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({ name: 'uds_co2_emission_uom', defaultValue: fuhrpark?.uds_co2_emission_uom })
          ]}
        />
      )
    }
  ];

  return { formInfoListDataEnvironment };
};
