import React, { useEffect } from 'react';
import { UnauthorizedPageContainer } from 'components/unauthorized-page-container';
import { useQuery } from 'hooks';
import { decodeBase64 } from 'utils/decode-encode-base64';
import { StyledNewVehicleRequestSurvey } from './new-vehicle-request-survey.styled';

const NewVehicleRequestSurvey = () => {
  const query = useQuery();

  useEffect(() => {
    if (query.data) {
      const data = JSON.parse(decodeBase64(query.data as string));
      const email = data.email;

      renderSurvey('NewVehicleRequestSurveyId', '', '', 'de', email);
    }
  }, []);

  const renderSurvey = (
    parentElementId: string,
    FirstName: string,
    LastName: string,
    locale: string,
    email: string
  ) => {
    if ((window as any).SurveyEmbed) {
      const se = new (window as any).SurveyEmbed(
        '-CgSSqcT7UeOMGvnUqJPYFJPS_bgX7pIqtuYdpk1nI5URFNQSzRZWTZHWTVISUFYTzJYTktaTERJUy4u',
        'https://customervoice.microsoft.com/',
        'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/',
        'true'
      );
      const context = { 'First Name': FirstName, 'Last Name': LastName, locale: locale, email: email };
      se.renderInline(parentElementId, context);
    }
  };

  return (
    <UnauthorizedPageContainer>
      <StyledNewVehicleRequestSurvey id='NewVehicleRequestSurveyId' />
    </UnauthorizedPageContainer>
  );
};

export default NewVehicleRequestSurvey;
