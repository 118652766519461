import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';
import styled from 'styled-components';

export const ArrowDownIcon = ({ width = 25, height = 25, color: colorProp, strokeWidth, ...resetProps }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg
      {...resetProps}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.54822 7.4922L12.5638 16.5078M21.5482 7.4922L12.5639 16.4766'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width={strokeWidth}
      />
    </svg>
  );
};

export const StyledArrowDownIcon = styled(ArrowDownIcon)`
  position: absolute;
  right: 7px;
  top: calc(50% - 10px) !important;
  width: 18px;
  height: 18px;
`;
