import React, { Component } from 'react';
import classes from './breadcrumbs-panel.module.scss'

class BreadcrumbsPanel extends Component {
  state = {
    links: [],
    willkommenText: ''
  };

  parseLinks = () => {
    const links = [
      {
        name: 'Willkommen bei mobexo',
        url: window.location.origin
      }
    ];
    const paths = window.location.pathname.split('/');
    paths.forEach(path => {
      if (path) {
          links.push({
            name: path,
            url: links[links.length-1].url + '/' + path
          });
      }
    });

    return links;
  };

  componentDidMount() {
    const links = this.parseLinks();
    let willkommenText = links[links.length-1].name;
    if (willkommenText === 'dashboard' && this.props.user.name) {
      willkommenText = `herzlich Willkommen, ${this.props.user.name}!`;
    }

    this.setState({links, willkommenText});
  }

  render() {
    return (
      <div className={classes.breadcrumbs}>
        <div className={`container ${classes.container}`}>
          <div className={classes.links}>
            <a key={this.state.links[0]?.name} href={this.state.links[0]?.url}>{this.state.links[0]?.name}</a>
            {this.state.links.map((link, i) => i !== 0
              ? <span key={link.name}>
                  <img src="/assets/images/breadcrumbs_separator.png" alt="breadcrumbs_separator"/>
                  <a href={link.url}>{link.name}</a>
                </span>
              : null)}
          </div>
          <div className={classes.willkommen}>
            <h3>{this.state.willkommenText}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default BreadcrumbsPanel;
