import React from 'react';
import { PaginationGapProps } from './pagination-gap.props';
import { BottomTriangle, StyledPaginationGap, StyledPaginationGapContainer } from './pagination-gap.styled';
import { PaginationButtonTypes } from 'components/pagination/pagination.props';
import PaginationButton from 'components/pagination/pagination-button';

const PaginationGap = ({ gapPages, onPageChange, activePage }: PaginationGapProps) => {
  return (
    <div>
      <StyledPaginationGapContainer>
        <StyledPaginationGap wrap='wrap'>
          {gapPages.map(pageNumber => (
            <PaginationButton
              key={pageNumber}
              activePage={activePage}
              margin='0'
              shadow={false}
              type={PaginationButtonTypes.Page}
              pageNumber={pageNumber}
              onPageChange={onPageChange}
            />
          ))}
        </StyledPaginationGap>
      </StyledPaginationGapContainer>
      <BottomTriangle />
    </div>
  );
};

export default PaginationGap;
