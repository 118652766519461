import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ArrowIcon = ({ width = 15, height = 20, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.8737 7.36306L7.44516 0.93451M7.44516 0.93451L1.0166 7.36306M7.44516 0.93451L7.44516 18.9345'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
