import React from 'react';
import { Distance, Flex, Text, Title } from 'common/common-components.styled';
import Spinner from 'components/spinner';

const LoadingMessage = () => {
  return (
    <Flex direction='column' align='center'>
      <Title color='blue' size='18'>
        Einen Augenblick bitte
      </Title>
      <Text color='grey800' margin='10px 0 50px' size='14'>
        Dieser Vorgang dauert einige Momente.
      </Text>
      <div style={{ position: 'relative' }}>
        <Spinner
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)'
          }}
        />
      </div>
      <Distance top='20' />
    </Flex>
  );
};

export default LoadingMessage;
