import React from 'react';
import { Flex, Text } from 'common/common-components.styled';

export const NotLeasingServicesDetailInvoiceRecipient = () => (
  <Flex direction='column'>
    <Text size='14'>Mobexo GmbH</Text>
    <Text size='12'>Universitätsstraße 44–46</Text>
    <Text size='12'>44789 Bochum, Deutschland</Text>
  </Flex>
);
