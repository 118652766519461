import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useOverlay } from 'hooks/index';
import { useRef } from 'react';

const useExportDomElementAsPdf = <T>(pdfFileName: string) => {
  const [showOverlay, hideOverlay] = useOverlay('exportDomElementAsPdf');
  const elementRef = useRef<T | null>(null);

  const exportDomElementAsPdf = async () => {
    if (!(elementRef.current instanceof HTMLElement)) {
      return;
    }
    showOverlay();

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const padding = 5;

    const wrapper = document.createElement('div');
    const mobexoLogo = document.createElement('img');
    mobexoLogo.setAttribute('src', '/assets/images/mobexo-logo-new.png');
    const clonedElement = elementRef.current.cloneNode(true);
    wrapper.appendChild(mobexoLogo);
    wrapper.appendChild(clonedElement);
    document.body.appendChild(wrapper);
    const canvas = await html2canvas(wrapper, { scale: 1 });
    document.body.removeChild(wrapper);
    const totalCanvasHeight = canvas.height;
    const totalCanvasWidth = canvas.width;

    const imgWidth = pdfWidth - 2 * padding;
    const imgHeight = (totalCanvasHeight * imgWidth) / totalCanvasWidth;
    const pageHeightInPixels = ((pdfHeight - 2 * padding) * totalCanvasWidth) / imgWidth;

    let heightLeft = totalCanvasHeight;
    let position = 0;

    while (heightLeft > 0) {
      const canvasPage = document.createElement('canvas');
      canvasPage.width = totalCanvasWidth;
      canvasPage.height = Math.min(pageHeightInPixels, heightLeft);

      const ctx = canvasPage.getContext('2d');
      ctx?.drawImage(
        canvas,
        0,
        position,
        totalCanvasWidth,
        canvasPage.height,
        0,
        0,
        totalCanvasWidth,
        canvasPage.height
      );

      const imgData = canvasPage.toDataURL('image/jpeg');
      pdf.addImage(imgData, 'JPEG', padding, padding, imgWidth, (canvasPage.height * imgWidth) / totalCanvasWidth);

      heightLeft -= pageHeightInPixels;
      position += pageHeightInPixels;

      if (heightLeft > 0) pdf.addPage();
    }

    pdf.save(`${pdfFileName}.pdf`);
    hideOverlay();
  };

  return { exportDomElementAsPdf, elementRef };
};

export default useExportDomElementAsPdf;
