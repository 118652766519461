import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { OrderVehicleReportFields } from './order-vehicle-report.types';

export const orderVehicleReportFormFields: FormFieldModel[] = [
  {
    name: OrderVehicleReportFields.Name,
    type: FormFieldTypes.Text,
    label: 'Name',
    defaultValue: '',
    placeholder: 'Vor- und Nachname',
    keepDataFromField: true,
    validation: {
      required: 'Bitte eintragen',
      maxLength: {
        value: 100,
        message: 'Maximal 100 Zeichen möglich eingegeben werden.'
      }
    }
  },
  {
    name: OrderVehicleReportFields.Phone,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Telefon',
    prefix: '+49 |',
    placeholder: '0 00 00 00 00',
    keepDataFromField: true,
    validation: {
      required: 'Bitte eintragen',
      pattern: {
        // eslint-disable-next-line no-useless-escape
        value: /^[+]?[0-9]*[\(]?[0-9]*[\)]?[0-9]*$/,
        message: 'Bitte korrekte Telefonnummer eingeben'
      },
      maxLength: {
        value: 50,
        message: 'Maximal 50 Zeichen möglich eingegeben werden.'
      }
    }
  },
  {
    name: OrderVehicleReportFields.Address,
    type: FormFieldTypes.Text,
    label: 'Standort des Fahrzeugs',
    defaultValue: '',
    placeholder: 'Muster Straße',
    keepDataFromField: true,
    validation: {
      required: 'Bitte eintragen',
      maxLength: {
        value: 100,
        message: 'Maximal 100 Zeichen möglich eingegeben werden.'
      }
    }
  },
  {
    name: OrderVehicleReportFields.Comment,
    type: FormFieldTypes.TextArea,
    defaultValue: '',
    label: 'Bemerkungen',
    validation: {
      maxLength: {
        value: 200,
        message: 'Die maximale Anzahl der verfügbaren Zeichen beträgt 200 Zeichen'
      }
    },
    textAreaProps: {
      maxHeight: 140,
      minRows: 4
    }
  }
];
