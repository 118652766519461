import React from 'react';
import PropTypes from 'prop-types';

export const LocationIcon = ({ width = 19, height = 24, color = '#102F3', className }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 19 24'
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.54688 0C4.59688 0 0.546875 4.05 0.546875 9C0.546875 15.3 8.64687 23.4 8.94687 23.85C9.09687 24 9.39687 24 9.54688 24C9.69688 24 9.99688 24 10.1469 23.85C10.4469 23.4 18.5469 15.3 18.5469 9C18.5469 4.05 14.4969 0 9.54688 0ZM9.54688 12C7.44688 12 5.79688 10.35 5.79688 8.25C5.79688 6.15 7.44688 4.5 9.54688 4.5C11.6469 4.5 13.2969 6.15 13.2969 8.25C13.2969 10.35 11.6469 12 9.54688 12Z'
      fill={color}
    />
    <path
      d='M9.54688 10.5C10.7895 10.5 11.7969 9.49264 11.7969 8.25C11.7969 7.00736 10.7895 6 9.54688 6C8.30423 6 7.29688 7.00736 7.29688 8.25C7.29688 9.49264 8.30423 10.5 9.54688 10.5Z'
      fill={color}
    />
  </svg>
);

LocationIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
