import { CrmPicklistResponse, CrmResponse, DataWithIdAndName } from 'common/interfaces';

export interface CostCentersSettingsProps {
  companyId: string;
}

export interface CostCenterResponseItem {
  new_firmaid: CrmPicklistResponse;
  new_kostenstelleid: CrmResponse;
  new_name: CrmResponse;
  Standorte: CrmResponse<number>;
  Fahrzeuge: CrmResponse<number>;
  Fahrer: CrmResponse<number>;
}

export interface CostCenterFilterData {
  companies: DataWithIdAndName[];
  names: string[];
}

export interface CostCenterDataItem {
  new_firmaid_id: string;
  new_firmaid: string;
  new_kostenstelleid: string;
  new_name: string;
  Standorte: number;
  Fahrzeuge: number;
  Fahrer: number;
}

export interface CostCenterData {
  items: CostCenterDataItem[];
  count: number;
}

export interface CostCenterResponse {
  records: CostCenterResponseItem[];
  count: number;
}

export interface ValidateMainEntityResponseItem {
  name: CrmResponse;
}

export type ValidateMainEntityItem = Record<keyof ValidateMainEntityResponseItem, string>;

export interface ValidateMainEntityResponse {
  records: ValidateMainEntityResponseItem[];
  validate: boolean;
}

export enum PrimaryEntityValues {
  Vehicle = '100000000',
  Driver = '100000002',
  Location = '100000001'
}

export enum PrimaryEntityNames {
  Vehicle = 'new_fuhrpark',
  Driver = 'contact',
  Location = 'new_standort'
}

export enum CostCenterFormFieldsNames {
  PrimaryEntity = 'uds_main_entity_costcentercode'
}

export enum ChangedFieldTypes {
  Location = 'location',
  Driver = 'driver'
}

export interface CostCenterPrimaryEntity {
  uds_main_entity_costcentercode: number | null;
  uds_main_entity_costcenter_lastupdated: string | null;
}
