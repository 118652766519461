import React, {useState, useCallback, useEffect} from 'react';

import classes from './header-offer.module.scss';


let menuClasses = [classes.hidden];


function HeaderOffer(props) {
  const [scrollWidth, setScrollWidth] = useState(0);
  const [navClass, setNavClass] = useState(classes.hidden);

  function toggleMenu() {
    setNavClass(navClass => navClass === classes.hidden ? null : classes.hidden);
  }

  const navigation = [
    {
      label: 'Home',
      link: 'https://www.mobexo.de/',
      className: null
    },
    {
      label: 'Fuhrparkmanagement',
      link: 'https://www.mobexo.de/',
      className: null,
      dropdownMenu: [
        {
          label: 'Leistungen Fuhrparkmanagement',
          link: 'https://www.mobexo.de/leistungen-fuhrparkmanagement/'
        },
        {
          label: 'Schadenmanagement',
          link: 'https://www.mobexo.de/schadenmanagement/',
          subMenu: [
            {
              label: 'Leistungen Schadenmanagement',
              link: 'https://www.mobexo.de/leistungen-schadenmanagement/'
            }
          ]
        },
        {
          label: 'Flottenversicherung',
          link: 'https://www.mobexo.de/flottenversicherung/'
        }
      ]
    },
    {
      label: 'Ressourcen',
      link: '#',
      className: null,
      dropdownMenu: [
        {
          label: 'Blog',
          link: 'https://www.mobexo.de/blog/'
        },
        {
          label: 'Podcast',
          link: 'https://www.mobexo.de/podcast/'
        },
        {
          label: 'FAQ',
          link: 'https://www.mobexo.de/haeufige-fragen/'
        }
      ]
    },
    {
      label: 'Über uns',
      link: 'https://www.mobexo.de/ueber-uns/',
      className: null
    },
    {
      label: 'Login',
      link: 'https://portal.mobexo.de/SignIn?returnUrl=%2F',
      className: classes.login
    },
    {
      label: 'Kontakt aufnehmen',
      link: 'https://www.mobexo.de/kontakt/',
      className: classes.contact
    }
  ];

  useEffect(() => {
    setTimeout(() => {
      setScrollWidth(window.innerWidth - document.documentElement.clientWidth);
    }, 1000);
  }, []);

  return (
    <header className={classes.header}>
      <a href="https://www.mobexo.de/">
        <img src="https://www.mobexo.de/app/uploads/2021/03/Logo_Mobexo_RGB_Horizontal_Negative.svg" alt="logo"/>
      </a>
      <button onClick={toggleMenu}>
        <i className='fas fa-bars' />
        Menu
      </button>
      <nav className={navClass}>
        <ul className={classes.list}>
          {navigation.map(item => <li>
            <a className={item.className} href={item.link}>{item.label}</a>
            {item.dropdownMenu && <ul className={classes.dropdown_menu}>
              {item.dropdownMenu.map(dropdownMenuItem => <li>
                <a href={dropdownMenuItem.link}>{dropdownMenuItem.label}</a>
                {dropdownMenuItem.subMenu && <ul className={`${classes.dropdown_menu} ${classes.submenu}`}>
                  {dropdownMenuItem.subMenu.map(submenuItem => <li><a href={submenuItem.link}>{submenuItem.label}</a></li>)}
                </ul>}
              </li>)}
            </ul>}
          </li>)}
        </ul>
      </nav>
    </header>
  );
}

export default HeaderOffer;
