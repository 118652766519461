import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const FileUploadIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M15.0837 9.41936V5.35491L11.0192 5.45581V1.00014H1.72907C1.40972 1.00014 1.14844 1.26142 1.14844 1.58077V18.4192C1.14844 18.7386 1.40972 18.9999 1.72907 18.9999H15.0837'
          fill={fill}
        />
      )}
      <path
        d='M19.1485 17.8385V18.9998H10.439V17.8385'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3417 17.2579V12.9032H11.8901L14.7933 8.25808L17.6965 12.9032H16.2449V14.661'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M8.69671 18.9999H1.72907C1.40972 18.9999 1.14844 18.7386 1.14844 18.4192V1.58077C1.14844 1.26142 1.40972 1.00014 1.72907 1.00014H11.0192L15.0837 5.35491V6.55468'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3417 17.2579V12.9032H11.8901L14.7933 8.25808L17.6965 12.9032H16.2449V14.6451'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0835 5.3549H11.3094C11.1497 5.3549 11.019 5.22426 11.019 5.06459V3.32268'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
