import React from 'react';

const CompletedStepIcon = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.9239 12.56C9.45998 12.0961 8.70782 12.0961 8.24391 12.56C7.77999 13.0239 7.77999 13.7761 8.2439 14.24L11.3897 17.3858C12.1707 18.1668 13.4371 18.1668 14.2181 17.3858L23.9639 7.64001C24.4278 7.17608 24.4278 6.4239 23.9639 5.95998C23.5 5.49607 22.7478 5.49608 22.2839 5.95999L12.8039 15.44L9.9239 12.56ZM24.0531 14C23.805 14 23.605 14.2015 23.5934 14.4492C23.3567 19.5229 19.1334 23.6 14.0039 23.6C8.72389 23.6 4.40391 19.28 4.40391 14C4.40391 8.72002 8.72389 4.4 14.0039 4.4C14.8668 4.4 15.6327 4.49694 16.3888 4.69084C16.5468 4.73136 16.7154 4.68846 16.8308 4.57312L18.05 3.35391C18.2861 3.1178 18.1909 2.71642 17.8707 2.62179C16.6294 2.25494 15.3167 2 14.0039 2C7.40391 2 2.00391 7.39999 2.00391 14C2.00391 20.6 7.40391 26 14.0039 26C20.4533 26 25.7569 20.8436 25.9955 14.4497C26.0048 14.2018 25.8028 14 25.5547 14H24.0531Z'
        fill='#335566'
      />
    </svg>
  );
};

export default CompletedStepIcon;
