import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CalendarCheckedIcon = ({
  width = 26,
  height = 26,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.986298' y='3.03058' width='23.2002' height='7.99799' fill={hovered ? 'white' : 'none'} />
      {hovered && <rect x='0.999985' y='7.74399' width='23.1865' height='14.0845' fill={fill} />}
      <path
        d='M4.14391 2.96493C4.14391 1.8842 4.8513 0.999969 5.71588 0.999969C6.58046 0.999969 7.28785 1.8842 7.28785 2.96493M7.28785 2.96493C7.28785 4.04565 6.58046 4.92989 5.71588 4.92989M7.28785 2.96493L12.0038 2.96486M11.2178 2.96497C11.2178 1.88425 11.9252 1.00001 12.7897 1.00001C13.6543 1.00001 14.3617 1.88425 14.3617 2.96497M14.3617 2.96497C14.3617 4.0457 13.6543 4.92993 12.7897 4.92993M14.3617 2.96497L19.0776 2.96486M18.2916 2.96497C18.2916 1.88425 18.999 1.00001 19.8636 1.00001C20.7282 1.00001 21.4356 1.88425 21.4356 2.96497M21.4356 2.96497C21.4356 4.0457 20.7282 4.92993 19.8636 4.92993M21.4356 2.96497L24.1865 2.96486V11.6107M4.9299 2.96491H0.999985V21.8285H10.0388M5.71588 7.68081H24.1865'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.7107 25C22.1842 25 25 22.1841 25 18.7106C25 15.2371 22.1842 12.4213 18.7107 12.4213C15.2371 12.4213 12.4213 15.2371 12.4213 18.7106C12.4213 22.1841 15.2371 25 18.7107 25Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.291 18.7893L18.5097 21.2002L21.1303 16.2211'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
