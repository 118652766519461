import { CrmDataTypes } from 'common/enums';
import { useGetDamageByIdQuery } from 'hooks/react-query/damage/get-damage-by-id/get-damage-by-id';
import { DamagePartsRequestItem, DamageParts } from 'request-config/damage/damage.props';
import { getRequestFormData } from 'utils/get-response-data';
import { objectUtility } from 'utils/object/object';
import { useFetchDamageEditForm } from './use-fetch-damage-edit-form/use-fetch-damage-edit-form';
import { AnyObject } from 'common/interfaces';
import { useCallback } from 'react';

export const useSubmitDamageParts = (enabled: boolean) => {
  const { damageData } = useGetDamageByIdQuery(undefined, { options: { enabled } });
  const { fetchHandler } = useFetchDamageEditForm(undefined, { options: { enabled } });

  const submitDamagePartsHandler = useCallback(
    (values: AnyObject) => {
      if (!damageData.uds_schadenaufnahmeid || !damageData.incidentid) {
        return;
      }

      let valuesForUpload: DamagePartsRequestItem[] = [];
      let damagePartList: number[] = [];

      const damagePart: number[] = [];

      objectUtility.entries(values).map(([key, value]) => {
        if (key === 'damageList') {
          Object.values(value).map((valueItem: any) => {
            if (Array.isArray(valueItem)) {
              valueItem.forEach(item => damagePartList.push(item.value));
              return;
            }
            damagePartList.push(valueItem.value);
          });
        } else {
          damagePart.push(value);
        }
      });

      valuesForUpload = damagePartList.map((item, index) => ({
        attributes: {
          ...(getRequestFormData({ uds_parttypecode: item, uds_damagetypecode: damagePart[index] }, [
            { name: 'uds_parttypecode', crmFieldType: CrmDataTypes.Picklist },
            { name: 'uds_damagetypecode', crmFieldType: CrmDataTypes.Picklist }
          ]) as DamageParts)
        }
      }));

      fetchHandler({
        updateDamageParts: {
          schadenAufnahmeId: damageData.uds_schadenaufnahmeid,
          damageParts: valuesForUpload
        }
      });
    },
    [damageData.incidentid, damageData.uds_schadenaufnahmeid]
  );

  return { submitDamagePartsHandler };
};
