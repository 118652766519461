import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './filter-view.module.scss';
import Sorting from '../sorting';

const options = [
  { value: 'createdon-true', label: 'Neueste zuerst' },
  { value: 'uds_mobexoangebotgesamtrate-false', label: 'Leasingrate aufsteigend' },
  { value: 'uds_mobexoangebotgesamtrate-true', label: 'Leasingrate absteigend' }
];

export default function Filters({ open, toggleSidebar, filterCount }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '30px 0 20px' }}>
      <div
        className={classnames(classes.btn, 'pointer')}
        style={{ width: '45%', maxWidth: '60px' }}
        onClick={() => toggleSidebar(!open)}
      >
        <i className='fas fa-filter' /> Filter
        {filterCount > 0 && <span className={classnames(classes.filter_count)}>{filterCount}</span>}
      </div>
      <div id='sorting' style={{ width: '45%' }}>
        <Sorting options={options} />
      </div>
    </div>
  );
}

Filters.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  filterCount: PropTypes.number
};
