import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledDiagramContainer = styled(Flex)<Partial<{ mobilePadding: string }>>`
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  height: ${({ height }) => height || '100%'};
  padding: ${({ padding }) => padding || '20px'};

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    padding: ${({ mobilePadding }) => mobilePadding || '15px'};
  } ;
`;
