import React from 'react';
import { IconProps } from 'common/interfaces';

const CircleInfoIcon = ({ width = 17, height = 17, color = '#FF9700', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        // eslint-disable-next-line
        d='M7.9829 5.55936C7.77951 5.55936 7.64391 5.49157 7.50832 5.35597C7.37273 5.22038 7.30493 5.08479 7.30493 4.8814C7.30493 4.67801 7.37273 4.54241 7.50832 4.40682C7.64391 4.27123 7.77951 4.20343 7.9829 4.20343C8.18629 4.20343 8.32188 4.27123 8.45747 4.40682C8.59307 4.54241 8.66086 4.67801 8.66086 4.8814C8.66086 5.08479 8.59307 5.28818 8.45747 5.35597C8.32188 5.49157 8.11849 5.55936 7.9829 5.55936ZM8.66086 6.98309V10.9831C8.66086 11.2543 8.59307 11.4577 8.45747 11.5933C8.32188 11.7289 8.18629 11.7967 7.9829 11.7967C7.77951 11.7967 7.64391 11.7289 7.50832 11.5933C7.37273 11.4577 7.30493 11.2543 7.30493 10.9831V7.05089C7.30493 6.7797 7.37273 6.57631 7.50832 6.44072C7.64391 6.30513 7.77951 6.23733 7.9829 6.23733C8.18629 6.23733 8.32188 6.30513 8.45747 6.44072C8.59307 6.50852 8.66086 6.7119 8.66086 6.98309Z'
        fill={color}
      />
      <path
        // eslint-disable-next-line
        d='M8 16C5.89831 16 3.86441 15.1865 2.37288 13.6949C0.813559 12.1356 0 10.1017 0 8.00003C0 5.89834 0.813559 3.86444 2.37288 2.37291C3.86441 0.81359 5.83051 3.05176e-05 8 3.05176e-05C10.1017 3.05176e-05 12.1356 0.81359 13.6271 2.30512C16.7458 5.42376 16.7458 10.5085 13.6271 13.6271C12.1356 15.1187 10.1017 16 8 16ZM8 1.35596C6.23729 1.35596 4.54237 2.03393 3.32203 3.32206C2.0339 4.5424 1.35593 6.23732 1.35593 8.00003C1.35593 9.76274 2.0339 11.4577 3.32203 12.678C4.54237 13.8983 6.23729 14.6441 8 14.6441C9.76271 14.6441 11.4576 13.9661 12.678 12.678C15.2542 10.1017 15.2542 5.89834 12.678 3.32206C11.3898 2.03393 9.76271 1.35596 8 1.35596Z'
        fill={color}
      />
    </svg>
  );
};

export default CircleInfoIcon;
