import styled, { css } from 'styled-components';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';

interface SnackbarContainerProps {
  open: boolean;
}

export const StyledSnackbarContainer = styled.div<SnackbarContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1002;
  transition: all .3s ease-out;
  transform: translateY(100%);
  opacity: 0;

  ${({ open }) =>
    open &&
    css`
      transform: translateY(-20px);
      opacity: 1;
    `};
`;

interface SnackbarProps {
  backgroundColor: string;
}

export const StyledSnackbar = styled.div<SnackbarProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 17px 17px 20px 17px;
  border-radius: 3px;
  color: #171819;
  max-width: 520px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const StyledIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-top: 3px;
`;

interface ProgressBarProps extends SnackbarProps {
  timerValue?: number;
}

export const StyledProgressbarContainer = styled.div<ProgressBarProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  height: 3px;
  position: absolute;
  border-radius: 0 0 3px 3px;
  bottom: 0;
  left: 0;
  opacity: 0.4;

  transition: 0.4s linear;
  transform-origin: left;
  transition-property: width;
  animation: progressAnimationStrike ${({ timerValue }) => `${timerValue}ms`};
  animation-fill-mode: forwards;

  @keyframes progressAnimationStrike {
    from {
      width: 100%;
    }

    to {
      width: 0;
    }
  }
`;

export const StyledCloseIcon = styled(PlusLargeIcon)`
  opacity: 0.4;
  cursor: pointer;
  transform: rotate(45deg);
`;
