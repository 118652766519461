import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { SortingRowProps } from './sorting-row.props';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { Circle, Flex, Text } from 'common/common-components.styled';
import { StyledRightComponent } from './sorting-row.styled';
import useWindowSize from 'hooks/use-window-size';

export const SortingRow = ({
  name,
  size,
  createdOn,
  createdBy,
  rightComponent,
  hideColumnDivider = false
}: SortingRowProps) => {
  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  const getColumn = (node: ReactNode) => {
    if (typeof node === 'string') {
      return (
        <EllipsisWithTooltip tooltip={node}>
          <Text color='grey500' size='10' bold>
            {node}
          </Text>
        </EllipsisWithTooltip>
      );
    }

    return node;
  };
  return (
    <Grid alignItems='center' container columns={isMobile ? 3 : 6} columnSpacing={2} padding='8px 0' marginTop='5px'>
      <Grid item xs={3}>
        <Flex direction='column'>{getColumn(name)}</Flex>
      </Grid>
      {windowWidth > 550 && (
        <>
          <Grid position='relative' item xs={1} marginLeft={isMobile ? '20px' : '0'}>
            {getColumn(size)}
            {!hideColumnDivider && (
              <Circle
                position='absolute'
                positionTop='50%'
                positionRight='0'
                transform='translateY(-50%)'
                size='3px'
                color='grey500'
              />
            )}
          </Grid>
          <Grid position='relative' item xs={1}>
            {getColumn(createdOn)}
            {!hideColumnDivider && (
              <Circle
                position='absolute'
                positionTop='50%'
                positionRight='0'
                transform='translateY(-50%)'
                size='3px'
                color='grey500'
              />
            )}
          </Grid>
          <Grid position='relative' display='flex' alignItems='center' justifyContent='space-between' item xs={1}>
            {getColumn(createdBy)}
            {rightComponent && <StyledRightComponent>{rightComponent}</StyledRightComponent>}
          </Grid>
        </>
      )}
    </Grid>
  );
};
