import styled from 'styled-components';
import { AutocompleteAddNewItemButton } from '../add-new-item-button/add-new-item-button';

export const StyledAutocompleteAddNewItemButtonContainer = styled(AutocompleteAddNewItemButton)`
  position: absolute;
  bottom: 0;
  align-items: center;
  width: 100%;
  padding: 15px;
`;
