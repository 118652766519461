import React, { MouseEvent } from 'react';

import { objectUtility } from 'utils/object/object';
import { Flex, Text } from 'common/common-components.styled';
import { AccordionProps, TabControllerData } from 'components/tab/tab.props';
import { PickValue } from 'common/interfaces';
import { SortingDocuments } from '../components/sorting-documents/sorting-documents';
import { useTheme } from 'styled-components';
import {
  IDocumentsGroupedByDescription,
  IDocumentsGroupedByDescriptionItem
} from 'components/download-documents-component/download-documents-component.props';
import { DownloadOldStyleIcon } from 'components/icons-new/download-old-style';

export const useSortingDocumentsTabDataSetUp = (
  data: IDocumentsGroupedByDescription,
  isExpanded: boolean,
  downloadArchiveCallback: (sectionName: string, filesPathList: string[]) => void
) => {
  const theme = useTheme();

  const documentDataEntries = objectUtility.entries(data);
  const documentDataToTabDataEntries = isExpanded ? documentDataEntries : documentDataEntries.slice(0, 5);

  const downloadHandler = (event: MouseEvent, sectionName: string, value: IDocumentsGroupedByDescriptionItem[]) => {
    event.stopPropagation();
    downloadArchiveCallback(
      sectionName,
      value.map(file => file.filePath)
    );
  };

  const tabData: TabControllerData[] = documentDataToTabDataEntries.map(([sectionName, value]) => ({
    tab: (
      <Flex align='center'>
        <DownloadOldStyleIcon
          width={22}
          height={22}
          color={theme.colors.grey200}
          onClick={event => downloadHandler(event, sectionName, value)}
        />
        <Text size='14' color='grey900' left='10'>
          {sectionName}
        </Text>
      </Flex>
    ),
    panel: <SortingDocuments sectionName={sectionName} data={value} />
  }));

  const tabProps: PickValue<AccordionProps, 'tabProps'> = {
    container: { padding: '0 20px', margin: '0' },
    containerBorder: { isHideDivider: true, padding: '10px 0' },
    rotateIconProps: { color: theme.colors.darkBlue }
  };

  return { tabData, tabProps, documentDataEntries };
};
