import React from 'react';
import classes from './option-set-dropdown-item.module.scss';
import { OptionSetDropdownItemProps } from './option-set-dropdown-item.props';
import { get } from 'lodash';

export const OptionSetDropdownItem = ({ index, style, row, onClick, rowPath }: OptionSetDropdownItemProps) => {
  return (
    <li
      key={index}
      style={style}
      onClick={() => onClick(row)}
      className={classes.dropdown_li}
      id={`itemDropDown${index}`}
    >
      <div className={classes.item_text}>{rowPath ? get(row, rowPath) : row}</div>
    </li>
  );
};
