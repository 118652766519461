import styled from 'styled-components';
import PanelPlaceholder from 'components/panel-placeholder';

export const StyledOrderStatusSectionContainer = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
`;

export const StyledVerticalLine = styled.div`
  position: absolute;
  width: 113px;
  left: 0;
  top: 35px;
  bottom: 35px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey500};
`;

export const StyledPanelPlaceholder = styled(PanelPlaceholder)`
  padding: 20px 0;
`;
