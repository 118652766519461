import React, { memo, useMemo } from 'react';
import classes from './hilfecenter.module.scss';
import { RightSidebar } from 'components/right-sidebar/right-sidebar';
import { RightSidebarOpenButton } from 'components/right-sidebar/right-sidebar-open-button/right-sidebar-open-button';
import { VideoPlayer } from 'components/video-player/video-player';
import { useHistory, useLocation } from 'react-router-dom';
import { VideoTutorial } from 'components/hilfecenter/hilfecenter-props';
import { getVideos } from 'services/tutorial-service';
import { MODALS, MODAL_NAMES, RequestStatuses } from '../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useQuery } from 'react-query';
import Spinner from 'components/spinner';
import { Button, Flex, Text } from 'common/common-components.styled';
import { getDeviceType } from 'utils/get-device-type';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDashboardTourIsStartted,
  setOpenVideoTutorials,
  toggleAfterTourPopUp,
  toggleModal
} from 'actions/app_action';
import { AnyObject } from 'common/interfaces';
import { PAGES } from 'common/enums';
import { useSupportBoxQuery } from 'hooks/react-query/support-box';
import clsx from 'clsx';
import CreateTask from './create-task';

export const VideoTutorials = memo(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOpenVideoTutorials = useSelector((state: AnyObject): boolean => state['app'].isOpenVideoTutorials);
  const pageUrl = location.pathname.replace(/\/$/, '');
  const videoTemplates = useQuery(['video templates', pageUrl], () => getVideos(pageUrl, getDeviceType()));
  const history = useHistory();
  const { supportBox, isLoading } = useSupportBoxQuery(pageUrl);
  const isSupportBoxNeeded = Boolean(supportBox?.contacts?.length);

  const videoList = useMemo(() => {
    if (videoTemplates.data?.data.level === RequestStatuses.Success) {
      return getResponseFormData(videoTemplates.data.data, [{ name: null, type: 'array' }]) as VideoTutorial[];
    }
    return [];
  }, [videoTemplates.data?.data?.data]);

  const openVideoTutuorials = () => {
    dispatch(setOpenVideoTutorials(true));
  };

  const startGuidedTour = () => {
    dispatch(setOpenVideoTutorials(false));
    history.push(PAGES.DASHBOARD);
    dispatch(toggleAfterTourPopUp(false));
    dispatch(setDashboardTourIsStartted(true));
  };

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openCreateTaskModal = (contact: AnyObject) => {
    const alertData = {
      title: `Frage stellen`,
      children: <CreateTask contactId={contact.contactid.attributeValue} closeAlert={closeAlert} />,
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return (
    <>
      <RightSidebar title='Hilfecenter' isOpen={isOpenVideoTutorials} className={classes.right_sidebar_video_tutorials}>
        <div className={classes.container}>
          <div>
            {Boolean(videoList.length) && (
              <div className={classes.subtitle_container}>
                <p className={classes.subtitle}>Video-Tutorials</p>
              </div>
            )}
            {videoTemplates.isLoading || isLoading ? (
              <Spinner style={spinnerStyle} />
            ) : (
              videoList.map(videoItem => (
                <VideoPlayer
                  key={videoItem.msdyncrm_videourl}
                  src={videoItem.msdyncrm_videourl}
                  title={videoItem.msdyncrm_name}
                  isOpenVideoTutuorials={isOpenVideoTutorials}
                />
              ))
            )}
            {!videoList.length && !isSupportBoxNeeded && (
              <Flex direction='column' align='center' justify='center' height='100%' padding='30px 0'>
                <Flex width='170px' direction='column' align='center' justify='center'>
                  <img src='/assets/images/role-less-image.png' alt='no_videos' />
                  <Text textAlign='center' top='30' size='18' bold color='blue'>
                    Keine Videos zu diesem Thema.
                  </Text>
                </Flex>
              </Flex>
            )}
          </div>

          <div
            className={clsx(
              classes.tool_conttainer,
              !isSupportBoxNeeded && classes.tool_conttainer_wothout_support_box
            )}
          >
            {isSupportBoxNeeded && (
              <Flex width='100%' direction='column' top={videoList.length ? '30' : '0'}>
                <div className={classes.subtitle_container}>
                  <p className={classes.subtitle}>Support</p>
                </div>
                {supportBox?.contacts?.map((contact: AnyObject) => (
                  <div className={classes.support_box}>
                    <Flex>
                      <Flex>
                        <div className={classes.image_container}>
                          <img src={contact.image} alt='employee' />
                        </div>
                      </Flex>
                      <Flex direction='column' justify='center' left='20'>
                        <Text
                          fontWeight={600}
                        >{`${contact.firstname?.attributeValue} ${contact.lastname?.attributeValue}`}</Text>
                        <Text size='12' color='grey500'>
                          {contact.jobtitle?.attributeValue}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex top='15' justify='center'>
                      <Button onClick={() => openCreateTaskModal(contact)}>Frage stellen</Button>
                    </Flex>
                  </div>
                ))}
              </Flex>
            )}

            <Flex width='100%'>
              <button onClick={startGuidedTour} className={classes.restart_tour_button}>
                <img className={classes.button_icon} src='/assets/images/gift.svg' alt='gift' />
                <span>Produkttour starten</span>
              </button>
            </Flex>
          </div>
        </div>
      </RightSidebar>

      <RightSidebarOpenButton id={'video_button'} onClick={openVideoTutuorials} bottom={80}>
        <img src='/assets/images/question.svg' alt='question mark' />
      </RightSidebarOpenButton>
    </>
  );
});

const spinnerStyle = {
  width: '50px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 99
};
