import React, { ChangeEvent, KeyboardEvent, useContext, useRef, useState } from 'react';
import { TimeMeasure } from 'common/enums';
import { StyledTimeContainer, StyledTimeInput } from './time-input.styled';
import { Flex, Text } from 'common/common-components.styled';
import { ITimeChildrenProps, ITimeValue } from '../time.props';
import { ClockIcon } from 'components/icons-new/clock';
import { useTheme } from 'styled-components';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { IFormFieldContext } from 'components/form-hook/form-field/form.props';
import { NUMBER_OF_VALUES_ON_THE_SIDE } from '../utils';
import { useTime } from 'hooks/use-time';
import {
  DateTimeFieldContext,
  DateTimeFieldContextProps
} from 'components/form-hook/form-field/components/date-time-field/date-time-field.context';
import IconHover from 'components/icon-hover';

export const TimeInput = ({
  scrollToItemHandler,
  timeList,
  borderColor,
  disabled
}: Pick<ITimeChildrenProps, 'timeList' | 'scrollToItemHandler'> & { borderColor?: string; disabled?: boolean }) => {
  const hoursInputRef = useRef<HTMLInputElement>(null);
  const minutesInputRef = useRef<HTMLInputElement>(null);

  const {
    field,
    error,
    setError,
    value: valueTime,
    onChange: onChangeTime
  } = useContext<IFormFieldContext<ITimeValue>>(formFieldProvider);
  const { valueDateTime, onChangeDateTime } = useContext<DateTimeFieldContextProps>(DateTimeFieldContext);
  const value = valueDateTime || valueTime;
  const onChange = onChangeDateTime || onChangeTime;
  const { parseTime, getUpperTimeLimit } = useTime();

  const theme = useTheme();

  const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>, timeMeasure: TimeMeasure) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      if (timeMeasure === TimeMeasure.Hour) {
        minutesInputRef.current?.focus();
      } else {
        hoursInputRef.current?.focus();
      }
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>, timeMeasure: TimeMeasure) => {
    const inputValue = event.target.value;
    const timeNumber = parseTime(inputValue);

    const isError =
      isNaN(timeNumber) ||
      timeNumber < 0 ||
      timeNumber > getUpperTimeLimit(timeMeasure) + 1 ||
      inputValue.includes(' ');

    if (isError) {
      return setError(field.name, {});
    }

    if (inputValue.length === 2) {
      minutesInputRef.current?.focus();
    }

    onChange({ ...value, [timeMeasure]: inputValue });
    scrollToItemHandler(timeMeasure, timeList[timeMeasure].indexOf(timeNumber) - NUMBER_OF_VALUES_ON_THE_SIDE);
  };

  const onFocusHandler = (timeMeasure: TimeMeasure) => {
    if (!parseTime(value[timeMeasure])) {
      onChange({ ...value, [timeMeasure]: '' });
    }
  };

  return (
    <StyledTimeContainer error={error} borderColor={borderColor}>
      <Flex
        id={`${field.name}FormTimeOpenButton`}
        right='7'
        left='-5'
        pointer={!disabled}
        opacity={disabled ? '0.5' : '1'}
      >
        <IconHover>
          <ClockIcon color={theme.colors.darkBlue} />
        </IconHover>
      </Flex>

      <StyledTimeInput
        id='inputMinutes'
        placeholder='00'
        disabled={disabled}
        ref={hoursInputRef}
        value={value[TimeMeasure.Hour]}
        onChange={event => onChangeHandler(event, TimeMeasure.Hour)}
        onKeyDown={event => onKeyDownHandler(event, TimeMeasure.Hour)}
        onFocus={() => onFocusHandler(TimeMeasure.Hour)}
      />

      <Text left='5' right='5'>
        :
      </Text>

      <StyledTimeInput
        id='inputSeconds'
        placeholder='00'
        disabled={disabled}
        ref={minutesInputRef}
        value={value[TimeMeasure.Minutes]}
        onChange={event => onChangeHandler(event, TimeMeasure.Minutes)}
        onKeyDown={event => onKeyDownHandler(event, TimeMeasure.Minutes)}
        onFocus={() => onFocusHandler(TimeMeasure.Minutes)}
      />
    </StyledTimeContainer>
  );
};
