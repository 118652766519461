import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useQuery } from '../../hooks';
import { getFuhrparkById } from '../../services/fuhrpark-service';
import classes from './existing-vehicle.module.scss';
import cloneDeep from 'lodash/cloneDeep';
import { setCustomUrlTree } from '../../actions/app_action';
import useRequest from '../../hooks/use-request';
import { USER_ROLES, silverDatVehicleTypes } from '../../constants';
import { getResponseData } from '../../utils/get-response-data';
import authService from '../../services/auth-service';
import { defaultUrlTree } from '../../components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { useIsUserHaveRole } from '../../hooks/use-is-user-have-role';
import { Flex } from 'common/common-components.styled';
import { PAGES } from '../../common/enums';
import { VehicleDetailContext } from './existing-vehicle.context';
import { VehicleDetailTopWidgetLayout } from './components/layout/top-widget-layout/top-widget-layout';
import { VehicleDetailsMainTabs } from './components/layout/main-tabs/main-tabs';
import { useVehicleDetailsOnUnmount } from './hooks/use-un-unmount';
import { VehicleDetailsRepairPartnerWidget } from './components/widgets/repair-partner/repair-partner';
import LeasingWidgetsLayout from './components/layout/leasing-widgets-layout';
import { CarOverviewSection } from './components/layout/car-overview-section/car-overview-section';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import useWindowSize from 'hooks/use-window-size';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getAllFiles = async id => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fuhrpark/AllFiles?FuhrparkId=${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const VEHICLE_VIEW_FORM_LOADING_KEY = 'vehicleViewFormLoadingKey';

const VehicleViewFormContainer = () => {
  const query = useQuery();
  const [refreshKey, setRefreshKey] = useState(0);
  const { data: vehicle } = useRequest(
    () => getFuhrparkById(query.id),
    [refreshKey],
    false,
    VEHICLE_VIEW_FORM_LOADING_KEY
  );
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);

  const vehicleDetailContextValue = { vehicle: vehicle?.data || {}, setRefreshKey };

  return (
    <VehicleDetailContext.Provider value={vehicleDetailContextValue}>
      <VehicleViewForm isDriver={isDriver} id={query.id} vehicle={vehicle || {}} setRefreshKey={setRefreshKey} />
    </VehicleDetailContext.Provider>
  );
};

export const Section = props => {
  return (
    <section id={props.id ? props.id : null} className={classNames('mobexo-panel', classes.container)}>
      <div className='w-100'>{props.children}</div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string
};

const VehicleViewForm = ({ vehicle, setRefreshKey, isDriver }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const item = useMemo(() => getResponseData(vehicle, ['fuhrpark', 'task', 'leaseEndInfo', 'serverDate']), [vehicle]);
  useVehicleDetailsOnUnmount();

  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (item) {
      const newUrlTree = cloneDeep(defaultUrlTree);
      newUrlTree.children['meine-fahrzeuge'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.MY_VEHICLES_DETAILS}/?id=${query.id}`
      };

      newUrlTree.children['pool-car-buchungen'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.POOL_CALENDAR}/fahrzeuge/?id=${query.id}`
      };

      newUrlTree.children['meine-pool-car-buchungen'].children['fahrzeuge'] = {
        breadcrumbTitle: item.new_name?.attributeValue || '',
        title: item.new_name?.attributeValue || '',
        url: `${PAGES.MY_POOL_CALENDAR}/fahrzeuge/?id=${query.id}`
      };

      dispatch(setCustomUrlTree(newUrlTree));
    }
  }, [item]);

  const gridClassName = classNames(classes.grid, {
    [classes.grid_order_status]: fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered,
    [classes.grid_driver]: !isMobile && isDriver
  });

  return (
    <div className={classes.existing_vehicle}>
      <Flex className={gridClassName} maxWidth={isDriver ? '1250px' : '1400px'}>
        <VehicleDetailTopWidgetLayout />
        <CarOverviewSection />

        {item.widgetSetings && <LeasingWidgetsLayout car={item} refresh={() => setRefreshKey(oldKey => oldKey + 1)} />}

        <VehicleDetailsRepairPartnerWidget />
        <VehicleDetailsMainTabs />
      </Flex>
    </div>
  );
};

const ExistingVehiclePage = () => {
  return <VehicleViewFormContainer />;
};

export default ExistingVehiclePage;

const PLACEHOLDER_IMAGES = {
  [silverDatVehicleTypes.personalCar.id]: '/assets/images/vehicle-placeholders/PKW.svg',
  [silverDatVehicleTypes.truck.id]: '/assets/images/vehicle-placeholders/LKW.svg',
  [silverDatVehicleTypes.trailer.id]: '/assets/images/vehicle-placeholders/Anhanger.svg',
  [silverDatVehicleTypes.bicycle.id]: '/assets/images/vehicle-placeholders/Fahrrad.svg',
  [silverDatVehicleTypes.tool.id]: '/assets/images/vehicle-placeholders/Werkzeug.svg',
  [silverDatVehicleTypes.transporter.id]: '/assets/images/vehicle-placeholders/Transporter.svg'
};

export const FuhrparkGallery = ({ images, files, vehicleType = {} }) => {
  const placeholderImg =
    vehicleType?.value && PLACEHOLDER_IMAGES[vehicleType.value]
      ? PLACEHOLDER_IMAGES[vehicleType.value]
      : PLACEHOLDER_IMAGES[silverDatVehicleTypes.personalCar.id];

  if (!images) {
    return (
      <Spinner
        style={{
          width: '100px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      />
    );
  } else if (images.length || files.length) {
    return (
      <Gallery images={images.map(({ original }) => original.split(',')[1])} gallery={files['Foto(s) Fahrzeug']} />
    );
  }

  return <img src={placeholderImg} alt='Vehicle car placeholder' />;
};
