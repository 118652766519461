import React from 'react';
import { toggleModal } from 'actions/app_action';
import { CustomizedColumnInfo } from 'components/columns-settings/column-settings.types';
import ColumnsSettings from 'components/columns-settings/columns-settings';
import { ColumnType } from 'components/table-component/table-component.props';
import { MODAL_NAMES, MODALS } from '../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { columnCustomizationRequestConfig } from 'request-config/column-customization/column-customization.request-config';
import useOverlay from 'hooks/use-overlay';
import { PreferencesProps } from './use-preferences.types';

export const usePreferences = ({ settingsName, columns, successCallback, clearFiltersHandler }: PreferencesProps) => {
  const user = useTypedSelector(state => state.app.user);
  const [customizedColumnsInfo, setCustomizedColumnsInfo] = useState<CustomizedColumnInfo[] | null>(null);
  const dispatch = useDispatch();
  const [showOverlay, hideOverlay] = useOverlay();

  const { fetch: loadPreferences } = useFetch({
    loadingKey: 'loadPreferences',
    isShowErrorSnackbar: true,
    isShowLoading: false,
    ...columnCustomizationRequestConfig.loadPreferences(user.id, settingsName)
  });

  const { fetch } = useFetch({
    loadingKey: 'savePreferences',
    isShowErrorSnackbar: true,
    isShowLoading: false
  });

  const savePreferences = async (settings: string) => {
    await fetch({
      requestConfig: columnCustomizationRequestConfig.savePreferences(user.id, settingsName, settings)
    });
  };

  const getCustomizedOrder = (customizedColumns: CustomizedColumnInfo[], defaultColumns: ColumnType[]) => {
    const newSettingsArray = [] as ColumnType[];
    let currentColumn = {} as ColumnType;

    customizedColumns.forEach(({ columnId, isChecked }) => {
      const foundCheckedItem = defaultColumns.find(columnDefault => columnDefault.columnId === columnId && isChecked);

      if (foundCheckedItem) {
        newSettingsArray.push(foundCheckedItem);
      }
    });

    defaultColumns.forEach(columnDefault => {
      const foundExistingSetting = customizedColumns.find(columnCustomized => {
        currentColumn = columnDefault;
        return columnDefault.columnId === columnCustomized.columnId;
      });

      if (!foundExistingSetting) {
        newSettingsArray.push(currentColumn);
      }
    });

    return newSettingsArray;
  };

  const getPreferences = async () => {
    try {
      const response = await loadPreferences();

      if (response.data.data) {
        const actualSettings = JSON.parse(response.data.data);

        columns.forEach(columnDefault => {
          const currentColumn = columnDefault;
          const foundSetting = JSON.parse(response.data.data).find(
            (setting: CustomizedColumnInfo) => setting.columnId === columnDefault.columnId
          );

          if (!foundSetting) {
            actualSettings.push({
              title: currentColumn.name,
              propName: currentColumn.propName,
              isChecked: true,
              columnId: currentColumn.columnId
            });
          }
        });

        JSON.parse(response.data.data).forEach((setting: CustomizedColumnInfo) => {
          const existingColumn = columns.find(column => column.columnId === setting.columnId);

          if (!existingColumn) {
            const index = actualSettings.findIndex(
              (actualSetting: CustomizedColumnInfo) => actualSetting.columnId === setting.columnId
            );
            actualSettings.splice(index, 1);
          }
        });

        setCustomizedColumnsInfo(actualSettings);
      } else {
        const convertedDefaultColumns: CustomizedColumnInfo[] = columns.map(column => {
          return {
            title: column.name!,
            propName: column.propName!,
            isChecked: true,
            columnId: column.columnId!
          };
        });

        setCustomizedColumnsInfo(convertedDefaultColumns);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showFilter = useCallback(
    (id: string) => {
      if (customizedColumnsInfo) {
        return customizedColumnsInfo.some(({ columnId, isChecked }) => isChecked && columnId === id);
      } else {
        return true;
      }
    },
    [customizedColumnsInfo]
  );

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const saveColumnCustomizationSettings = async (settings: CustomizedColumnInfo[]) => {
    try {
      closeAlert();
      showOverlay();
      await savePreferences(JSON.stringify(settings));
      await getPreferences();
      const hiddenFields = settings.filter(setting => !setting.isChecked);

      successCallback && (await successCallback());

      clearFiltersHandler && clearFiltersHandler(hiddenFields);
      hideOverlay();
    } catch (error) {
      console.log(error);
    }
  };

  const openColumnsSettingsModal = () => {
    const alertData = {
      title: 'Spalten anpassen',
      children: (
        <ColumnsSettings
          saveSettings={saveColumnCustomizationSettings}
          defaultColumns={columns}
          customColumns={customizedColumnsInfo || []}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  useEffect(() => {
    getPreferences();
  }, []);

  return {
    customizedColumns: customizedColumnsInfo ? getCustomizedOrder(customizedColumnsInfo, columns) : columns,
    customizedColumnsInfo: customizedColumnsInfo || [],
    showFilter,
    openColumnsSettingsModal
  };
};
