import React, { useState } from 'react';
import styles from './components.module.scss';
import { Controller } from 'react-hook-form';
import { subDays } from 'date-fns';
import { convertUTCToLocalDate, convertLocalToUTCDate } from '../utils';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledReportDamageDatePicker } from 'pages/report-damage-page/report-damage-component.styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledTextFieldWithoutBorders } from 'components/form-hook/form-field/form-field.styled';
import { ComponentsProps } from './components.props';
import { PrefixForId } from 'common/enums';
import { useTheme } from 'styled-components';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';

const DateFieldComponent = ({
  fieldRegister,
  fieldName,
  optional = false,
  doubleField = false,
  halfField = false,
  tillToday = false,
  shouldTransformToUTC = true,
  needTooltip = false,
  tooltipText,
  tooltipPosition = 'top',
  reactHookFormData
}: ComponentsProps) => {
  const {
    control,
    formState: { errors }
  } = reactHookFormData;

  const theme = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isHoveredTooltip, setIsHoveredTooltip] = useState<boolean>(false);
  return (
    <div
      className={doubleField ? styles.double_field : halfField ? styles.half_input : styles.field_container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={!needTooltip ? styles.form_name : styles.form_name_tooltip}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>(optional)</span>}{' '}
          {needTooltip && tooltipText && (
            <div
              className={styles.tooltip}
              onMouseEnter={() => setIsHoveredTooltip(true)}
              onMouseLeave={() => setIsHoveredTooltip(false)}
            >
              <TooltipHint position={tooltipPosition} text={tooltipText}>
                <InfoHintIcon color='#919699' width={16} height={16} hovered={isHoveredTooltip} />
              </TooltipHint>
            </div>
          )}
        </span>
      </div>
      <Controller
        control={control}
        name={fieldRegister}
        rules={{ required: !optional }}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledReportDamageDatePicker
              components={{
                OpenPickerIcon: () => (
                  <CalendarWithCheckMarkIcon
                    color={theme.colors.darkBlue}
                    hovered={isHovered}
                    id={fieldName + PrefixForId.FormDate + PrefixForId.Open}
                  />
                )
              }}
              PopperProps={{
                id: fieldName + PrefixForId.FormDate + PrefixForId.Calendar
              }}
              minDate={subDays(new Date(), 27000)}
              maxDate={tillToday ? new Date() : undefined}
              value={value ? (shouldTransformToUTC ? convertUTCToLocalDate(value) : value) : null}
              onChange={date => {
                onChange(convertLocalToUTCDate(date));
              }}
              error={!!errors[fieldRegister]}
              renderInput={({ inputProps, ...restParams }) => (
                <StyledTextFieldWithoutBorders
                  {...restParams}
                  error={false}
                  inputProps={{
                    ...inputProps,
                    id: fieldName + PrefixForId.FormDate,
                    placeholder: 'TT . MM . JJJJ'
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      <div className={styles.err__row}>
        {errors[fieldRegister] && (
          <>
            <img src='/assets/images/err-icon.svg' />
            <p>{`${fieldName} erforderlich.`}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default DateFieldComponent;
