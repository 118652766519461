import React from 'react';

const EinbruchTeildiebstahlIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M35.8931 32.7689H30.6028C29.8128 32.7689 29.1127 33.3789 29.0127 34.1689L28.8127 35.769C28.7127 36.5091 29.1527 37.3091 29.8228 37.6292L32.823 39.0592L32.0729 40.5993C31.8329 41.0994 31.6429 41.8994 31.6429 42.4594V51.59C31.6429 52.3001 32.1329 53.0601 32.783 53.3601L33.443 53.6601V58.3404C33.443 59.3905 34.293 60.2405 35.3431 60.2405H41.6235C42.6736 60.2405 43.5236 59.3905 43.5236 58.3404V56.0503C43.5236 55.6803 43.2236 55.3803 42.8536 55.3803C42.4836 55.3803 42.1835 55.6803 42.1835 56.0503V58.3304C42.1835 58.6405 41.9335 58.9005 41.6135 58.9005H35.3331C35.0231 58.9005 34.7631 58.6505 34.7631 58.3304V53.2201C34.7631 52.9601 34.6131 52.7201 34.3731 52.6101L33.333 52.13C33.153 52.05 32.973 51.76 32.973 51.57V42.4494C32.973 42.0894 33.113 41.4994 33.263 41.1794L34.3131 39.0392C34.3931 38.8792 34.4031 38.6992 34.3431 38.5292C34.283 38.3592 34.1631 38.2192 34.003 38.1492L30.4028 36.4291C30.2528 36.3591 30.1128 36.1091 30.1428 35.9391L30.3428 34.339C30.3628 34.219 30.4928 34.0989 30.6128 34.0989H36.3132C36.5632 34.0989 36.8032 33.9489 36.9132 33.7289L38.2783 31.1015C38.2783 31.1015 38.6647 30.4751 38.0845 30.1135C37.5043 29.7519 37.1682 30.3314 37.1682 30.3314L35.8931 32.7689Z'
        fill='#335566'
      />
      <path
        d='M62.275 23.7583H43.1836C41.5635 23.7583 40.2921 24.1634 39.5821 25.6135C39.5821 25.6135 39.3091 26.1285 39.8408 26.4324C40.4309 26.7696 40.7821 26.1935 40.7821 26.1935C41.2722 25.1934 42.0735 25.0884 43.1836 25.0884L62.275 25.0979C62.645 25.0979 62.945 24.7981 62.945 24.4281C62.945 24.0581 62.645 23.7583 62.275 23.7583Z'
        fill='#335566'
      />
      <path
        d='M62.8019 53.2299C62.8019 52.8598 62.5019 52.5598 62.1318 52.5598L38.953 52.56C38.583 52.56 38.283 52.8601 38.283 53.2301C38.283 53.6001 38.583 53.9001 38.953 53.9001L62.1318 53.8999C62.5019 53.8899 62.8019 53.5999 62.8019 53.2299Z'
        fill='#335566'
      />
      <path
        d='M62.3851 36.0992H44.0638C43.4938 36.0992 42.5437 35.8692 42.0337 35.6091L39.9936 34.5591C39.6635 34.3891 39.2635 34.5191 39.0935 34.8491C38.9235 35.1791 39.0535 35.5791 39.3835 35.7492L41.4237 36.7992C42.1237 37.1592 43.2738 37.4393 44.0638 37.4393H62.3851C62.7551 37.4393 63.0551 37.1393 63.0551 36.7692C63.0551 36.3992 62.7551 36.0992 62.3851 36.0992Z'
        fill='#335566'
      />
      <path
        d='M47.0441 47.4803C46.674 47.4803 46.374 47.7804 46.374 48.1504C46.374 48.5204 46.674 48.8204 47.0441 48.8204H61.405C61.775 48.8204 62.075 48.5204 62.075 48.1504C62.075 47.7804 61.775 47.4803 61.405 47.4803H47.0441Z'
        fill='#335566'
      />
      <path
        d='M41.9536 44.8601C41.8636 44.9301 41.7236 44.9601 41.5636 44.9201L36.8233 43.8401C36.4633 43.7601 36.1032 43.9801 36.0232 44.3401C35.9432 44.7001 36.1632 45.0601 36.5233 45.1401L41.2636 46.2202C41.4136 46.2502 41.5636 46.2702 41.7036 46.2702C42.1036 46.2702 42.4836 46.1402 42.7837 45.9002C43.1937 45.5702 43.4337 45.0601 43.4337 44.4901V43.23C43.4337 42.16 42.6136 41.1199 41.5736 40.8699L39.0134 40.2598C38.6534 40.1798 38.2934 40.3998 38.2134 40.7499C38.1234 41.1099 38.3534 41.4699 38.7034 41.5499L41.2636 42.16C41.7136 42.27 42.1036 42.76 42.1036 43.22V44.4801C42.0936 44.6601 42.0436 44.7901 41.9536 44.8601Z'
        fill='#335566'
      />
      <path
        d='M15.1727 36.0801C15.4903 36.2587 15.5995 36.6656 15.4109 36.9832L8.54306 48.8928C7.98728 49.8555 8.3148 51.0862 9.27749 51.642C10.2402 52.1977 11.4708 51.8702 12.0266 50.9075L18.1303 40.3477C18.3089 40.0301 18.7158 39.9209 19.0334 40.1095C19.351 40.2881 19.4602 40.6951 19.2716 41.0126L13.168 51.5626C12.5526 52.6344 11.4311 53.2299 10.27 53.2299C9.70425 53.2299 9.12862 53.091 8.61254 52.7833C7.02459 51.8603 6.46881 49.8158 7.3918 48.2279L14.2597 36.3282C14.4482 36.0106 14.8552 35.9014 15.1727 36.0801Z'
        fill='#335566'
      />
      <path
        d='M25.4948 52.8908C25.2868 53.0956 24.9088 53.1155 24.5156 52.8908C23.7514 52.4541 23.2154 51.7395 22.9871 50.886L20.5755 42.2019L17.5041 35.7823C16.7866 34.5257 15.9956 33.5095 15.9956 32.1399C15.9956 31.7727 16.2933 31.475 16.6605 31.475C17.0277 31.475 17.3255 31.7727 17.3255 32.1399C17.3255 33.1622 17.9776 33.8409 18.447 34.8494L21.5183 41.269L24.2674 50.5485C24.4064 51.0547 24.724 51.4815 25.1805 51.7494C25.637 52.0174 25.8649 52.5262 25.4948 52.8908Z'
        fill='#335566'
      />
      <path
        d='M19.6876 19.4264C21.8511 19.4264 23.6177 21.193 23.6177 23.3566V24.6521C23.6177 24.6521 23.6177 25.3068 22.9488 25.3068C22.2798 25.3068 22.2878 24.6421 22.2878 24.6421V23.3467C22.2878 21.9076 21.1167 20.7365 19.6776 20.7365H16.9086H16.1881C14.7955 20.8616 14.1755 22.6621 14.6586 24.0176L15.5881 26.6259C15.9879 27.7478 16.8391 28.6877 17.917 29.1992L23.1918 31.0082C23.7751 31.285 24.4193 31.3188 25.027 31.1023L30.2249 29.2499C30.9822 28.98 31.3886 28.1398 31.1154 27.3732C30.8456 26.616 30.0053 26.2095 29.2387 26.4827L24.8261 28.0552C24.4522 28.1884 24.0436 28.1655 23.6886 27.997L18.6293 26.2902C18.2996 26.1338 18.1597 25.7411 18.3161 25.4114C18.4726 25.0818 18.8652 24.9418 19.1949 25.0983L24.2542 26.805C24.2922 26.8231 24.3363 26.8285 24.383 26.8118L28.7956 25.2393C30.2447 24.7229 31.8424 25.481 32.3588 26.9301C32.8752 28.3792 32.1171 29.9769 30.668 30.4933L25.4701 32.3457C24.5352 32.6788 23.5265 32.6274 22.6262 32.2002L17.3514 30.3911C15.9312 29.7172 14.8678 28.5368 14.3414 27.0597L13.4119 24.4514L13.4212 24.4481C12.695 22.41 13.9011 19.5568 15.804 19.4266L16.9186 19.4165H19.6876V19.4264Z'
        fill='#335566'
      />
      <path
        d='M26.1233 37.8377L27.7957 43.4637C27.7957 43.4637 28.0215 44.0348 27.3973 44.2132C26.7731 44.3916 26.6426 43.8071 26.6426 43.8071L25.1805 38.7607L23.6177 35.2502C23.6177 35.2502 23.2362 34.6326 23.8432 34.3173C24.5039 33.9742 24.7566 34.7393 24.7566 34.7393L26.1233 37.8377Z'
        fill='#335566'
      />
      <path
        d='M24.9688 14.1363C24.9688 16.548 23.0037 18.5131 20.592 18.5131C18.1803 18.5131 16.2152 16.548 16.2152 14.1363C16.2152 11.7246 18.1803 9.75952 20.592 9.75952C23.0037 9.76945 24.9688 11.7246 24.9688 14.1363ZM20.592 11.0894C18.9048 11.0894 17.5451 12.459 17.5451 14.1363C17.5451 15.8235 18.9147 17.1832 20.592 17.1832C22.2792 17.1832 23.6388 15.8136 23.6388 14.1363C23.6488 12.459 22.2792 11.0894 20.592 11.0894Z'
        fill='#335566'
      />
      <path
        d='M40.3748 33.3107C40.3765 33.789 40.6899 34.0032 41.2153 33.9846C41.7406 33.966 41.8345 33.3874 41.8409 33.1919L41.9924 29.6302C41.9968 29.5273 41.9481 29.4293 41.8634 29.3707L30.0273 21.1833C29.9356 21.1198 29.8867 21.0107 29.9002 20.9L30.1903 18.538C30.2834 17.7732 29.6988 17.7558 29.6988 17.7558C29.6988 17.7558 28.9433 17.5837 28.8728 18.4569L28.4856 21.5742C28.4718 21.685 28.5207 21.7944 28.6126 21.8579L40.41 30.0199C40.4958 30.0792 40.5446 30.1788 40.5389 30.2828L40.3748 33.3107Z'
        fill='#335566'
      />
    </svg>
  );
};

export default EinbruchTeildiebstahlIcon;
