import styled from 'styled-components';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface StyledFormViewDatePickerProps {
  error?: boolean;
}

export const StyledFormViewDatePicker = styled(DesktopDatePicker)<StyledFormViewDatePickerProps>`
  width: 100%;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.pink : theme.colors.grey500)} !important;
  border-radius: 3px;
  min-height: 43px;

  & .MuiOutlinedInput-root {
    padding: 10px 15px;
    flex-direction: row-reverse;
    letter-spacing: 0.05em;

    & .MuiIconButton-root {
      margin-right: 0;
      margin-left: -24px;
    }

    & .MuiInputBase-input {
      color: ${({ theme }) => theme.colors.grey800};

      &::placeholder {
        opacity: 0.7;
        letter-spacing: 0.01em;
      }
    }
  }
`;
