import React from 'react';
import classes from './faq.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Text } from 'common/common-components.styled';
import VehicleContainer from 'pages/existing-vehicle-page/vehicle-container/vehicle-container';
import { QuestionIcon } from 'components/icons-new/question';
import { ArrowDownIcon } from 'components/icons-new/arrow-down';

export const WallEBussinesPartnerPromotionFAQ = () => {
  return (
    <VehicleContainer img={<QuestionIcon />} title='FAQ' description='' className={classes.container}>
      <>
        {data.map(({ title, description }) => (
          <Accordion sx={{ border: 'none', boxShadow: 'none', '&::before': { display: 'none' } }}>
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              sx={{ '& .MuiAccordionSummary-content': { margin: '13px 10px 13px 0' } }}
            >
              <Text size='16' lineHeight='24px' bold>
                {title}
              </Text>
            </AccordionSummary>
            <AccordionDetails>
              <Text lineHeight='22px'>{description}</Text>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </VehicleContainer>
  );
};

export const data = [
  {
    title: 'Was ist ein virtueller Installationscheck?',
    description:
      'Ein virtueller Installationscheck wird ohne die physische Anwesenheit des Elektrikers ausgeführt, z.B. in Form eines Video-Anrufs oder auch telefonisch in Verbindung mit durch den Kunden aufgenommenen digitalen Fotos oder Videos vom Installationsort, Hausverteilerkasten etc. Der Installateur beschreibt dem Kunden dabei was er im Video oder auf den Fotos sehen muss um die Gegebenheiten beurteilen zu können und führt sachkundig durch den Termin. Durch die immense Zeitersparnis kann dieser Service wesentlich günstiger angeboten werden im Vergleich zum physischen Installationscheck.'
  },
  {
    title: 'Wie funktioniert die Installation der Wallbox?',
    description:
      'Nach erfolgreichem Installationscheck erhalten Sie von uns ein unverbindliches Angebot für Ihre Installation. Sollten Sie dieses annehmen, vereinbaren wir gemeinsam einen Installationstermin an Ihrem Wunschort. Unser erfahrenes Installations-Team übernimmt die Installtion, Anmeldung und Inbetriebnahme.'
  },
  {
    title: 'Kann ich meine Wallbox selbst installieren?',
    description:
      'Eine Wallbox ist ein Starkstromgerät, das nur von Fachpersonal installiert und gewartet werden darf. Sicherheit steht hier an erster Stelle, damit Sie von Anfang an und für lange Zeit Freude an Ihrer neuen Wallbox haben.'
  },
  {
    title: 'Wie lange dauert es, bis ich ein Installationsangebot bekomme?',
    description:
      'Idealerweise reicht der virtueller Installationscheck, damit der Installateur Ihnen ein individuelles Angebot erstellen kann. Falls der virtuelle Installationscheck nicht ausreicht, um die Installationsgegebenheiten zu beurteilen, wird er einen physischen Installationscheck vor Ort vorschlagen, um Ihnen im Nachgang ein Angebot zu unterbreiten. Nach einem erfolgreichen Installationscheck dauert es in der Regel 2 Werktage bis Sie das Installationsangebot erhalten.'
  }
];
