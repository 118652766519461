import React from 'react';

const VorfahrtVerletztIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.0773 28.5507L28.8712 26.9616C28.7767 26.2228 28.1238 25.6473 27.3851 25.6473H22.0766L18.9413 19.2135C18.2541 17.7962 16.476 16.6881 14.8955 16.6881H5.57551C5.25769 16.6881 5 16.9458 5 17.2636C5 17.5814 5.25769 17.8392 5.57551 17.8392H14.8955C16.0293 17.8392 17.4123 18.6981 17.9105 19.7203L21.209 26.4719C21.3035 26.6695 21.5011 26.7898 21.7244 26.7898H27.3937C27.5569 26.7898 27.7287 26.9358 27.7459 27.099L27.952 28.6881C27.9778 28.8943 27.8232 29.1777 27.6342 29.2636L24.0522 30.973C23.9148 31.0417 23.8117 31.1534 23.7602 31.2994C23.7087 31.4455 23.7172 31.6001 23.786 31.7375L24.8253 33.8592C24.9885 34.1856 25.126 34.7955 25.126 35.1649V44.2357C25.126 44.459 24.9198 44.7769 24.7137 44.8714L23.6743 45.3524C23.4681 45.4469 23.3393 45.653 23.3393 45.8764V50.953C23.3393 51.3138 23.0472 51.6058 22.6865 51.6058H16.4417C16.0809 51.6058 15.7888 51.3138 15.7888 50.953V48.6852C15.7888 48.3674 15.5311 48.1097 15.2133 48.1097C14.8955 48.1097 14.6378 48.3674 14.6378 48.6852V50.953C14.6378 51.9408 15.4452 52.7482 16.4417 52.7482H22.6865C23.6829 52.7482 24.4817 51.9408 24.4817 50.953V46.2457L25.1861 45.9193C25.796 45.6359 26.2598 44.9143 26.2598 44.2357V35.1649C26.2598 34.6237 26.0795 33.8506 25.8389 33.361L25.0573 31.7547L28.1152 30.2944C28.7509 30.0024 29.1632 29.2465 29.0773 28.5507Z'
        fill='#335566'
      />
      <path
        d='M19.0873 46.4605C19.4052 46.4605 19.6628 46.2028 19.6628 45.885C19.6628 45.5672 19.4052 45.3094 19.0873 45.3094H5.57551C5.25769 45.3094 5 45.5672 5 45.885C5 46.2028 5.25769 46.4605 5.57551 46.4605H19.0873Z'
        fill='#335566'
      />
      <path
        d='M18.0995 27.4254L16.0723 28.4648C15.5483 28.7311 14.6034 28.963 14.0193 28.963H5.58406C5.26624 28.963 5.00854 29.2207 5.00854 29.5385C5.00854 29.8563 5.26624 30.114 5.58406 30.114H14.0193C14.7838 30.114 15.9176 29.8391 16.5962 29.4956L18.6234 28.4562C18.9069 28.3101 19.0186 27.9666 18.8725 27.6831C18.7265 27.391 18.3829 27.2794 18.0995 27.4254Z'
        fill='#335566'
      />
      <path
        d='M5.57551 41.4183H11.0558C11.3737 41.4183 11.6313 41.1606 11.6313 40.8427C11.6313 40.5249 11.3737 40.2672 11.0558 40.2672H5.57551C5.25769 40.2672 5 40.5249 5 40.8427C5 41.1606 5.25769 41.4183 5.57551 41.4183Z'
        fill='#335566'
      />
      <path
        d='M16.7766 34.8127L19.3277 34.2028C19.637 34.1255 19.826 33.8163 19.7486 33.5156C19.6713 33.2064 19.3621 33.0174 19.0615 33.0947L16.5103 33.7046C15.5139 33.9451 14.7322 34.9329 14.7322 35.9637V37.2178C14.7322 37.759 14.9555 38.24 15.3421 38.5492C15.6255 38.7726 15.9863 38.8928 16.3642 38.8928C16.5017 38.8928 16.6391 38.8757 16.7852 38.8413L21.501 37.7676C21.8102 37.6989 21.9992 37.3896 21.9305 37.0804C21.8617 36.7712 21.5525 36.5822 21.2433 36.6509L16.5275 37.7246C16.3385 37.7676 16.1753 37.7418 16.0636 37.6473C15.9519 37.5528 15.8832 37.3982 15.8832 37.2092V35.9551C15.8746 35.4569 16.2955 34.9243 16.7766 34.8127Z'
        fill='#335566'
      />
      <path
        d='M64.3901 32.88L52.4932 33.4641C52.1754 33.4813 51.9349 33.7476 51.952 34.0654C51.9692 34.3746 52.2183 34.6065 52.5276 34.6065C52.5361 34.6065 52.5447 34.6065 52.5533 34.6065L64.4502 34.0224C64.7681 34.0053 65.0086 33.739 64.9914 33.4211C64.9742 33.1119 64.7165 32.8714 64.3901 32.88Z'
        fill='#335566'
      />
      <path
        d='M45.2777 33.5069L34.9355 36.6422C33.2434 37.1576 31.972 38.5492 31.6113 40.2757L31.3879 41.3752L30.3572 41.779C29.4209 42.1483 28.8196 43.0331 28.8196 44.0295V45.9279C28.8196 47.9379 30.4517 49.5699 32.4617 49.5699H33.8189C34.1367 49.5699 34.3944 49.3123 34.3944 48.9944C34.3944 48.6766 34.1367 48.4189 33.8189 48.4189H32.4617C31.0873 48.4189 29.962 47.3022 29.962 45.9193V44.0209C29.962 43.4883 30.2799 43.0245 30.7695 42.8355L32.0923 42.3201C32.2727 42.2514 32.4015 42.0968 32.4445 41.8992L32.7366 40.4991C33.0114 39.1762 33.9821 38.1197 35.2706 37.7246L45.6728 34.5721C45.7158 34.5635 45.7502 34.5463 45.7931 34.5206L55.5683 28.9372L55.6456 28.8942C57.1832 27.9751 60.0522 26.2571 64.5103 25.5442C64.8196 25.4926 65.0343 25.2006 64.9828 24.8913C64.9312 24.5821 64.6392 24.3674 64.33 24.4189C59.6485 25.1748 56.6592 26.9615 55.0615 27.9236L45.2777 33.5069Z'
        fill='#335566'
      />
      <path
        d='M36.7996 48.0495C36.7996 51.5971 39.6857 54.4833 43.2333 54.4833C46.781 54.4833 49.6671 51.5971 49.6671 48.0495C49.6671 44.5019 46.781 41.6157 43.2333 41.6157C39.6857 41.6157 36.7996 44.5019 36.7996 48.0495ZM48.5247 48.0495C48.5247 50.9701 46.1539 53.3408 43.2333 53.3408C40.3128 53.3408 37.942 50.9701 37.942 48.0495C37.942 45.129 40.3128 42.7582 43.2333 42.7582C46.1539 42.7582 48.5247 45.1376 48.5247 48.0495Z'
        fill='#335566'
      />
      <path
        d='M44.6937 48.0496C44.6937 48.8571 44.0409 49.5099 43.2335 49.5099C42.8211 49.5099 42.4346 49.3381 42.1511 49.0289C41.9364 48.7969 41.5756 48.7798 41.3437 48.9945C41.1118 49.2093 41.0946 49.57 41.3093 49.802C41.7989 50.3431 42.5033 50.6524 43.2335 50.6524C44.668 50.6524 45.8362 49.4841 45.8362 48.0496C45.8362 47.4398 45.6214 46.8471 45.2263 46.3746C45.0201 46.1341 44.6594 46.0997 44.4189 46.3059C44.1783 46.5121 44.144 46.8728 44.3501 47.1133C44.5735 47.3796 44.6937 47.706 44.6937 48.0496Z'
        fill='#335566'
      />
      <path
        d='M64.4244 48.4275H53.1717C52.8539 48.4275 52.5962 48.6852 52.5962 49.003C52.5962 49.3208 52.8539 49.5785 53.1717 49.5785H64.4244C64.7422 49.5785 64.9999 49.3208 64.9999 49.003C64.9913 48.6852 64.7336 48.4275 64.4244 48.4275Z'
        fill='#335566'
      />
      <path
        d='M44.8224 18.4147C44.6506 18.2171 44.3586 18.157 44.1267 18.2858L34.4116 23.4053L33.5612 16.0267C33.5354 15.7776 33.3464 15.58 33.1059 15.5285C32.8654 15.4769 32.6163 15.5972 32.496 15.8119L29.3006 21.5757L26.6635 18.492C26.5261 18.3288 26.3028 18.2515 26.088 18.3116C25.8733 18.3631 25.7101 18.5349 25.6671 18.7411L24.894 22.2801C24.8253 22.5893 25.0229 22.8986 25.3321 22.9587C25.6413 23.0274 25.9506 22.8298 26.0107 22.5206L26.5347 20.1155L28.957 22.9501C29.0773 23.0961 29.2577 23.1648 29.4466 23.1477C29.6356 23.1305 29.7988 23.0188 29.8933 22.8556L32.6249 17.925L33.3722 24.3588C33.398 24.5478 33.5096 24.711 33.6728 24.7969C33.836 24.8828 34.0422 24.8828 34.2054 24.7969L42.4774 20.4333L36.9112 28.0954C36.7995 28.25 36.7738 28.4562 36.8425 28.6366C36.9112 28.817 37.0658 28.9544 37.2548 28.9887L41.7559 29.968L37.1431 32.0124C36.8511 32.1412 36.7222 32.4762 36.8511 32.7683C36.9456 32.983 37.1517 33.1119 37.3751 33.1119C37.4524 33.1119 37.5297 33.0947 37.607 33.0603L43.826 30.303C44.0494 30.1999 44.1868 29.968 44.161 29.7189C44.1353 29.4698 43.9549 29.2722 43.7144 29.2207L38.3543 28.0525L44.8568 19.1104C45.0028 18.9129 44.9942 18.6208 44.8224 18.4147Z'
        fill='#335566'
      />
    </svg>
  );
};

export default VorfahrtVerletztIcon;
