import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../constants';
import { inspectionDatesRequestConfig } from 'request-config/inspection-dates/inspection-dates.request-config';
import { InspectionDateLinkToCarModalFormData, OpenInspectionDateLinkToCarModalParams } from './link-to-car.props';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { InspectionDateLinkToCarModalForm } from './link-to-car.form';
import moment from 'moment';
import { useOverdueTaskWillBeCreatedModal } from '../overdue-task-will-be-created/overdue-task-will-be-created.modal';
import { useInspectionDateLinkToCarModal } from './link-to-car.modal';

export const InspectionDateLinkToCarModalContent = (props: OpenInspectionDateLinkToCarModalParams) => {
  const {
    description,
    willOverdueTaskCreatedOnSelectingFirstRegistrationDate,
    editableHistoryId,
    firstRegistrationDate,
    ...submitParams
  } = props;

  const { fetch } = useFetch({
    loadingKey: 'inspectionDateLinkToCar',
    isShowSuccessSnackbar: true,
    successMessage: 'Der Prüftermin wurde erfolgreich zugeordnet',
    errorMessage: 'Ein Fehler ist aufgetreten. Der Prüftermin wurde nicht zugeordnet.'
  });

  const reactHookFormData = useForm<InspectionDateLinkToCarModalFormData>();
  const { handleSubmit } = reactHookFormData;

  const { openOverdueTaskWillBeCreatedModal } = useOverdueTaskWillBeCreatedModal();
  const { openInspectionDateLinkToCarModal } = useInspectionDateLinkToCarModal();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submitCallback = (lastInspection: string | null) => {
    closeAlert();

    if (editableHistoryId) {
      return fetch({
        requestConfig: inspectionDatesRequestConfig.updateHistory({
          ...submitParams,
          lastInspection,
          prufterminHistoryId: editableHistoryId
        }),
        callback: () => queryClient.clear()
      });
    }

    fetch({
      requestConfig: inspectionDatesRequestConfig.connectInspectionDateToCar({ ...submitParams, lastInspection }),
      callback: () => queryClient.clear()
    });
  };

  const submitHandler = handleSubmit(values => {
    const lastInspectionDate = values.inspectionDate
      ? moment(values.inspectionDate).local().format('YYYY-MM-DD')
      : moment(firstRegistrationDate).local().format('YYYY-MM-DD');

    if (!values.inspectionDate) {
      if (!firstRegistrationDate) {
        return console.error('"firstRegistrationDate" IS NOT EXIST.');
      }

      if (willOverdueTaskCreatedOnSelectingFirstRegistrationDate) {
        return openOverdueTaskWillBeCreatedModal(
          () => openInspectionDateLinkToCarModal(props),
          () => submitCallback(lastInspectionDate)
        );
      }
    }

    submitCallback(lastInspectionDate);
  });

  return (
    <Flex direction='column'>
      <Text bottom='20'>{description}</Text>

      <InspectionDateLinkToCarModalForm
        reactHookFormData={reactHookFormData}
        firstRegistrationDate={firstRegistrationDate}
      />

      <Flex justify='flex-end' top='20'>
        <Button component={ButtonComponentType.CloseModal}>Abbrechen</Button>
        <Button onClick={() => submitHandler()}>Speichern</Button>
      </Flex>
    </Flex>
  );
};
