import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { IFieldRow } from './field-row.props';

export const FieldRow = ({ data, ...textColor }: IFieldRow) => {
  return (
    <Flex justify='space-between'>
      {data.map((dataItem, index) => (
        <Text key={index} {...textColor}>
          {dataItem || '-'}
        </Text>
      ))}
    </Flex>
  );
};
