import { IconProps } from 'common/interfaces';
import React from 'react';
import { useTheme } from 'styled-components';

export const DownloadOldStyleIcon = ({ width = 16, height = 16, color: colorProps, ...restProps }: IconProps) => {
  const theme = useTheme();
  const color = colorProps || theme.colors.darkBlue;
  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox='0 0 10 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.349976 15.1839C0.349976 14.733 0.715457 14.3675 1.1663 14.3675H8.82895C9.2798 14.3675 9.64528 14.733 9.64528 15.1839C9.64528 15.6347 9.2798 16.0002 8.82895 16.0002H1.1663C0.715457 16.0002 0.349976 15.6347 0.349976 15.1839Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99766 0.000183105C5.44851 0.000183105 5.81399 0.365665 5.81399 0.81651V10.3948C5.81399 10.8457 5.44851 11.2112 4.99766 11.2112C4.54682 11.2112 4.18134 10.8457 4.18134 10.3948V0.81651C4.18134 0.365665 4.54682 0.000183105 4.99766 0.000183105Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.28649 6.10586C9.60528 6.42465 9.60528 6.94152 9.28649 7.26032L5.57489 10.9719C5.2561 11.2907 4.73923 11.2907 4.42043 10.9719C4.10164 10.6531 4.10164 10.1362 4.42043 9.81745L8.13203 6.10586C8.45082 5.78706 8.96769 5.78706 9.28649 6.10586Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.708823 6.10586C1.02762 5.78706 1.54449 5.78706 1.86328 6.10586L5.57488 9.81745C5.89367 10.1362 5.89367 10.6531 5.57488 10.9719C5.25608 11.2907 4.73921 11.2907 4.42042 10.9719L0.708823 7.26032C0.390028 6.94152 0.390028 6.42465 0.708823 6.10586Z'
        fill={color}
      />
    </svg>
  );
};
