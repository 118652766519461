import { SortingDocumentsTabsContext } from 'components/documents/sorting-tabs-documents/sorting-documents-tabs.context';
import { IDocumentsGroupedByDescriptionItem } from 'components/download-documents-component/download-documents-component.props';
import useWindowSize from 'hooks/use-window-size';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';

export const useIsShowThreeStateCheckbox = (file: IDocumentsGroupedByDescriptionItem, sectionName: string) => {
  const [isShowThreeStateCheckbox, setIsShowThreeStateCheckbox] = useState(false);

  const { preparedFilesForDownload, setPreparedFilesForDownload } = useContext(SortingDocumentsTabsContext);
  const isSomeCheckboxChecked = !!preparedFilesForDownload[sectionName]?.length;

  const { windowWidth } = useWindowSize(500);

  useEffect(() => {
    if (windowWidth <= 550) {
      return setIsShowThreeStateCheckbox(true);
    }

    setIsShowThreeStateCheckbox(isSomeCheckboxChecked);
  }, [isSomeCheckboxChecked, windowWidth]);

  const changeCheckboxHandler = (value: { checked: boolean }) => {
    const clonePreparedFilesForDownload = cloneDeep(preparedFilesForDownload);

    if (value.checked) {
      clonePreparedFilesForDownload[sectionName] = clonePreparedFilesForDownload[sectionName].filter(
        preparedFile => preparedFile.fileId !== file.fileId
      );
    } else {
      if (!clonePreparedFilesForDownload[sectionName]) {
        clonePreparedFilesForDownload[sectionName] = [];
      }
      clonePreparedFilesForDownload[sectionName].push(file);
    }

    setPreparedFilesForDownload(clonePreparedFilesForDownload);
  };

  const mouseMoveHandler = () => {
    setIsShowThreeStateCheckbox(true);
  };

  const mouseLeaveHandler = () => {
    if (!isSomeCheckboxChecked) {
      setIsShowThreeStateCheckbox(false);
    }
  };

  return { isShowThreeStateCheckbox, mouseMoveHandler, mouseLeaveHandler, changeCheckboxHandler };
};
