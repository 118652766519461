import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../../../../../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fleetLeaseEndRequestConfig } from 'request-config/freet-lease-end/fleet-lease-end.request-config';

export const usePhaseSwitch = (isFourthPhase?: boolean) => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const name = car.new_name.attributeValue;
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const fleetId = car.new_fuhrparkid?.attributeValue;
  const taskId = car.checklist?.activityid?.attributeValue;
  const phaseInfo = car.vir?.vir_phaseinfo;

  const { fetch } = useFetch({
    loadingKey: 'setting phase',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt',
    successCallback: refresh
  });

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const changePhase = async (action: 'Cancel' | 'Restore') => {
    await fetch({
      requestConfig: fleetLeaseEndRequestConfig.setVirAction({
        actionName: action,
        fuhrparkId: fleetId,
        taskId,
        address: phaseInfo?.phaseInfo?.address,
        name: phaseInfo?.phaseInfo?.person,
        phone: phaseInfo?.phaseInfo?.phone
      })
    });
  };

  const openConfirmationModal = () => {
    const alertData = {
      title: 'Bestätigung',
      children: `Sind Sie sicher, dass Sie ein Kfz-Gutachten für Fahrzeug ${name} nicht beauftragen wollen?`,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: () => {
            setChecked(false);
            closeAlert();
          }
        },
        {
          type: 'submit',
          title: 'Bestätigen',
          action: () => {
            closeAlert();
            changePhase('Cancel');
          }
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const handleChange = async (checked: boolean) => {
    if (checked) {
      setChecked(true);
      openConfirmationModal();
    } else {
      setChecked(false);
      changePhase('Restore');
    }
  };

  useEffect(() => {
    setChecked(Boolean(isFourthPhase));
  }, [isFourthPhase]);

  return { checked, handleChange };
};
