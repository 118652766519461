import { AxiosRequestConfig } from 'axios';
import { GetDriversWithCarProps } from './driver.props';

class DriverRequestConfig {
  getDriversWithCar = (data: GetDriversWithCarProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'driver/getDriversWithCar',
    data
  });
}

export const driverRequestConfig = new DriverRequestConfig();
