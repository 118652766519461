import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { PoolCarSettingsFieldNames } from '../pool-car-booking-settings.types';

export const BILLING_BASIS_OPTIONS = {
  forKm: { value: '752560000', label: 'pro Km' },
  forHour: { value: '752560001', label: 'pro Stunde' },
  forDay: { value: '752560002', label: 'pro Tag' }
};

export const TIME_GAP_OPTIONS = {
  60: { value: 752560000, label: '1 Stunde' },
  45: { value: 752560001, label: '45 Minuten' },
  30: { value: 752560002, label: '30 Minuten' },
  15: { value: 752560003, label: '15 Minuten' }
};

export const generalSettingsFormFields: FormFieldModel<PoolCarSettingsFieldNames>[] = [
  {
    name: PoolCarSettingsFieldNames.TimeGap,
    type: FormFieldTypes.OptionSet,
    width: '250px',
    label: 'Zeitspanne zwischen Buchungen',
    options: Object.values(TIME_GAP_OPTIONS),
    placeholder: 'Bitte auswählen',
    defaultValue: '',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: PoolCarSettingsFieldNames.BillingBasis,
    type: FormFieldTypes.Radio,
    defaultValue: BILLING_BASIS_OPTIONS.forKm.value,
    label: 'Abrechnungsbasis in Euro:',
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: Object.values(BILLING_BASIS_OPTIONS),
    validation: {}
  },
  {
    name: PoolCarSettingsFieldNames.IsShowBookingDetails,
    type: FormFieldTypes.Radio,
    defaultValue: 'false',
    label: 'Buchungsdetails (Name des Fahrers, Status der Buchung) im Poolkalender für andere Fahrer anzeigen?',
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: [
      { value: 'true', label: 'Ja, anzeigen' },
      { value: 'false', label: 'Nein, ausblenden' }
    ],
    validation: {}
  },
  {
    name: PoolCarSettingsFieldNames.IsBookingDifferentLocation,
    type: FormFieldTypes.Radio,
    defaultValue: 'true',
    label: 'Dürfen die Fahrer ein Fahrzeug von anderen Standorten buchen?',
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: [
      { value: 'true', label: 'Ja' },
      { value: 'false', label: 'Nein' }
    ],
    validation: {}
  },
  {
    name: PoolCarSettingsFieldNames.IsAbleToBookVirtualVehicles,
    type: FormFieldTypes.Radio,
    defaultValue: 'true',
    label: 'Dürfen virtuelle Fahrzeuge von mehreren Fahrern gleichzeitig gebucht werden?',
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: [
      { value: 'true', label: 'Ja' },
      { value: 'false', label: 'Nein' }
    ],
    validation: {}
  }
];
