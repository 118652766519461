import { AxiosRequestConfig } from 'axios';
import {
  CreateCostCenterHistoryData,
  GetCostCenterHistoryRangesData,
  GetCostCentersHistoryListData,
  UpdateCostCenterHistoryData
} from './cost-centers-history.types';

class CostCentersHistoryRequestConfig {
  private RESOURCE = 'kostenstellenHistory';

  getCostCentersHistoryList = (data: GetCostCentersHistoryListData): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/getList`,
    data
  });

  getUnavailableRange = (data: GetCostCenterHistoryRangesData): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/getUnavailableRange`,
    data
  });

  updateCostCentersHistory = (data: UpdateCostCenterHistoryData): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/update`,
    data
  });

  createCostCentersHistory = (data: CreateCostCenterHistoryData): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/create`,
    data
  });
}

export const costCentersHistoryRequestConfig = new CostCentersHistoryRequestConfig();
