import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MailWithRightArrow } from '../../../../components/icons/mail-with-right-arrow';
import { PhoneIcon } from '../../../../components/icons/phone-icon';
import { LocationIcon } from '../../../../components/icons/location';
import RightArrow from '../../../../components/icons/right_arrow';
import { StepsProcess } from '../../../../components/steps-process';
import { InfoItem } from '../info-item/info-item';
import { EmptyItem } from '../info-item/empty-item';
import { processSetup } from './process-setup';
import { formatDate } from '../../utils';
import classes from './repair-status-item.module.scss';

export const RepairStatusItem = ({ data, className, openCase, closeCases, open, unauthorized = false }) => {
  const {
    Status,
    composit_adress,
    emailaddress1,
    new_kennzeichen,
    new_repairpartnerid,
    new_schadenuhrzeit,
    telephone1,
    title,
    uds_besichtigungstermin,
    uds_geplanter_fertigstellungstermin_werkstatt,
    uds_pickupcardatetime
  } = data;

  const viewingAppointment = uds_besichtigungstermin?.attributeValue;
  const pickupDate = uds_pickupcardatetime?.attributeValue;
  const completionDate = uds_geplanter_fertigstellungstermin_werkstatt?.attributeValue;
  const hiddenContainerRef = useRef();
  const itemContainerRef = useRef();

  const toggleItem = () => {
    open ? closeCases() : openCase();
  };

  useEffect(() => {
    if (!hiddenContainerRef.current) {
      return;
    }

    const diff = hiddenContainerRef.current.getClientRects()[0].bottom - window.innerHeight;
    const remainedHeight = window.innerHeight - itemContainerRef.current.getClientRects()[0].height;

    if (diff > 0) {
      if (unauthorized) {
        window.scrollTo({
          top: window.scrollY + diff + remainedHeight / 2,
          behavior: 'smooth'
        });
      } else {
        const scrollContainer = document.querySelector('.scroll-container');
        scrollContainer.scrollTop = scrollContainer.scrollTop + diff + remainedHeight / 2;
      }
    }
  }, [open]);

  return (
    <div
      className={classNames(classes.container, open && classes.container_open_item, 'table', className)}
      ref={itemContainerRef}
    >
      <div className={classes.main_block}>
        <div className={classes.header}>
          <div>
            <div className={classes.field}>
              <p className={classes.field_value}>{formatDate(new_schadenuhrzeit?.attributeValue)}</p>
              <p className={classes.field_name}>Schadendatum</p>
              <p className={classNames(classes.cars_licence_plate, classes.hide_for_desktop)}>
                {new_kennzeichen?.attributeValue?.name}
              </p>
            </div>
            <div className={classes.field}>
              <p className={classNames(classes.field_value, classes.hide_for_mobile)}>{title?.attributeValue}</p>
              <p className={classes.field_name}>Schadennummer</p>
            </div>
          </div>

          <p className={classNames(classes.cars_licence_plate, classes.hide_for_mobile)}>
            {new_kennzeichen?.attributeValue?.name}
          </p>

          <div className={classNames(classes.field, classes.hide_for_desktop)}>
            <p className={classNames(classes.field_value, classes.field_value_claim_number)}>{title?.attributeValue}</p>
            <p className={classNames(classes.field_name, classes.field_name_claim_number)}>Schadennummer</p>
          </div>
        </div>
        <div className={classes.footer}>
          <StepsProcess
            processSetup={processSetup(viewingAppointment, pickupDate, completionDate, Status?.attributeValue).filter(
              Boolean
            )}
          />

          <div className={classes.toggle_item_button} onClick={toggleItem}>
            <span>Werkstatt kontaktieren</span>
            <RightArrow
              className={classNames(classes.toggle_item_button_icon, open && classes.toggle_item_button_icon_active)}
            />
          </div>
        </div>
      </div>
      {open && (
        <div className={classes.hide_block} ref={hiddenContainerRef}>
          {Status.attributeValue === 'Neuer Auftrag' || Status.attributeValue === 'Reparatur an Werkstatt gesteuert' ? (
            <EmptyItem />
          ) : (
            <>
              <div className={classes.info_list}>
                <div className={classes.info_item}>
                  <InfoItem name='Firma' value={new_repairpartnerid?.attributeValue?.name} />
                  <div className={classes.underline} />
                </div>

                <a className={classes.info_item} href={`tel:${telephone1?.attributeValue}`}>
                  <div className={classes.info_item_content}>
                    <InfoItem name='Telefon' value={telephone1?.attributeValue} />
                    <div className={classes.icon_container}>
                      <PhoneIcon className={classes.hide_for_desktop} color='#102F3F' width={16} height={16} />
                    </div>
                  </div>
                  <div className={classes.underline} />
                </a>

                <a
                  className={classes.info_item}
                  href={`http://maps.google.com/?q=${composit_adress?.attributeValue}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <div className={classes.info_item_content}>
                    <InfoItem name='Adresse' value={composit_adress?.attributeValue} />
                    <div className={classes.icon_container}>
                      <LocationIcon className={classes.hide_for_desktop} color='#102F3F' />
                    </div>
                  </div>
                </a>

                <a className={classes.info_item} href={`mailto:${emailaddress1?.attributeValue}`}>
                  <div className={classes.info_item_content}>
                    <InfoItem name='E-Mail' value={emailaddress1?.attributeValue} />
                    <div className={classes.icon_container}>
                      <MailWithRightArrow className={classes.hide_for_desktop} color='#102F3F' width={24} height={14} />
                    </div>
                  </div>

                  <div className={classNames(classes.underline, classes.hide_for_desktop)} />
                </a>
              </div>

              <div className={classes.button_container}>
                <a href={`mailto:${emailaddress1?.attributeValue}`} className={classes.button}>
                  Kontakt zur Werkstatt aufnehmen
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

RepairStatusItem.propTypes = {
  className: PropTypes.string
};
