import React, { CSSProperties, LegacyRef } from 'react';
import { EllipsisFieldTitleProps } from './ellipsis-field-title.props';
import useEllipsisTooltip from 'hooks/use-ellipsis-tooltip';

const EllipsisFieldTitle = ({ title, className }: EllipsisFieldTitleProps) => {
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(5);

  return (
    <div
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={onMouseLeave}
      className={className}
      ref={content as LegacyRef<HTMLDivElement>}
    >
      {title}
      {isShowTip && <span style={style as CSSProperties}>{title}</span>}
    </div>
  );
};

export default EllipsisFieldTitle;
