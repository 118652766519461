import React from 'react';
import { Flex } from 'common/common-components.styled';
import { PanelItemController } from './panel/panel.controller';
import { TabItemController } from './tab/tab.controller';
import { TabItemFrameProps } from './tab/tab-item-frame/tab-item-frame.props';
import { TabControllerData } from 'components/tab/tab.props';

export const AccordionItem = ({
  isActive,
  tabIndex,
  tab,
  panel,
  isHide
}: Pick<TabItemFrameProps, 'isActive' | 'tabIndex'> & TabControllerData) => {
  if (isHide) {
    return null;
  }

  return (
    <Flex direction='column'>
      <TabItemController tabIndex={tabIndex} isActive={isActive}>
        {tab}
      </TabItemController>
      <PanelItemController isActive={isActive}>{panel}</PanelItemController>
    </Flex>
  );
};
