import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { inspectionDatesRequestConfig } from 'request-config/inspection-dates/inspection-dates.request-config';
import { GetCarInspectionDateResponse, UseCarInspectionDateQueryParams } from './car-inspectation-date.props';

export const useCarInspectionDateQuery = ({
  fuhrparkId: fuhrparkIdProps,
  accountId
}: UseCarInspectionDateQueryParams) => {
  const queryLocation = useQueryLocation();
  const fuhrparkId = fuhrparkIdProps || (queryLocation.id as string);

  const { fetch } = useFetch<ResponseModel<GetCarInspectionDateResponse>>({
    loadingKey: 'getCarInspectionDateQuery',
    isShowLoading: false,
    ...inspectionDatesRequestConfig.getCarInspectionDate({ fuhrparkId, accountId })
  });

  const { data, ...restQueryProps } = useQuery([CAR_INSPECTION_DATE_QUERY_KEY, fuhrparkId, accountId], () => fetch(), {
    enabled: !!fuhrparkId && !!accountId
  });

  return { carInspectionDateQuery: data?.data.data || defaultData, ...restQueryProps };
};

const defaultData: GetCarInspectionDateResponse = {
  allPruftermines: [],
  assignedPruftermines: [],
  pruftermineHistories: []
};

export const CAR_INSPECTION_DATE_QUERY_KEY = 'getCarInspectionDateQuery';
