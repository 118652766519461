import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import classes from './expanded-button.module.scss';
import { CLEAR_ALL_FILTERS_BUTTON_TEXT } from '../../utils';

const ExpandedClearAllFiltersButton = ({ clearFilter, className }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <span
      className={classnames(className, classes.cancelContainer)}
      onClick={() => clearFilter()}
      id='clearFilterButton'
      onMouseLeave={() => setIsHovered(false)}
      onMouseMove={() => setIsHovered(true)}
    >
      <span>
        <CircleCloseIcon hovered={isHovered} />
      </span>
      {CLEAR_ALL_FILTERS_BUTTON_TEXT}
    </span>
  );
};

ExpandedClearAllFiltersButton.propTypes = {
  className: PropTypes.string
};

export default ExpandedClearAllFiltersButton;
