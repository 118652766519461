import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel, TransformResponse } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../constants';
import { LeasingOfferResponse } from './use-all-related-leasing-offers.types';
import { transformCrmResponse } from 'utils/transform-crm-response';
import { leasingOfferRequestConfig } from 'request-config/leasing-offer/leasing-offer.request-config';

const modifyResponse = ({ data }: ApiResponse<{ leasingangebotes: LeasingOfferResponse[] }>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.leasingangebotes.map(leasingOfferResponse => transformCrmResponse(leasingOfferResponse));
  }
  return [];
};

const useAllRelatedLeasingOffers = (vehicleOfferId: string | null) => {
  const { fetch } = useFetch<ResponseModel<LeasingOfferResponse[]>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...leasingOfferRequestConfig.getAllByVehicleOffer(vehicleOfferId || '')
  });

  const { data: allRelatedLeasingOffers } = useQuery<
    ApiResponse<{ leasingangebotes: LeasingOfferResponse[] }>,
    unknown,
    TransformResponse<LeasingOfferResponse>[]
  >([ReactQueryKeys.GetAllRelatedLeasingOffers, vehicleOfferId], () => fetch(), {
    enabled: !!vehicleOfferId,
    select: modifyResponse
  });

  return { allRelatedLeasingOffers };
};

export default useAllRelatedLeasingOffers;
