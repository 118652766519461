import React from 'react';
import { WidgetTileEmptyPlaceholderProps } from './empty.props';
import { StyledWidgetTileEmptyPlaceholderContainer } from './empty.styled';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { Text } from 'common/common-components.styled';
import { TileEmptyPlaceholderProps } from 'components/widgets/tile/tile.props';

export const WidgetTileEmptyPlaceholder = ({ emptyPlaceholder, activeSliderIndx }: WidgetTileEmptyPlaceholderProps) => {
  const getCurrentEmptyPlaceholder = (): Partial<TileEmptyPlaceholderProps> => {
    if (Array.isArray(emptyPlaceholder)) {
      return emptyPlaceholder[activeSliderIndx] || {};
    }

    return emptyPlaceholder || {};
  };

  const { text = 'Nichts gefunden', icon, isEmpty } = getCurrentEmptyPlaceholder();

  if (!isEmpty) {
    return null;
  }

  return (
    <StyledWidgetTileEmptyPlaceholderContainer>
      {icon || <EmptyDataIcon hovered />}
      <Text color='blue' size='18' bold top='20'>
        {text}
      </Text>
    </StyledWidgetTileEmptyPlaceholderContainer>
  );
};
