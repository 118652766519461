import React, { useEffect, useMemo, useState } from 'react';
import {
  Sort,
  TableParams,
  VehicleOffersListComponentProps
} from 'components/vehicle-offers-list-component/vehicle-offers-list-component.props';
import {
  StyledConfigurationsWrapper,
  StyledVehicleOffersListContainer
} from 'components/vehicle-offers-list-component/vehicle-offers-list-component.styled';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks';
import { decodeBase64, encodeBase64 } from 'utils/decode-encode-base64';
import Product from 'components/product';
import { MODAL_NAMES, MODALS, RequestStatuses, USER_ROLES, VEHICLE_OFFER_EVENT_DESCRIPTIONS } from '../../constants';
import { useOverlay } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AnyObject, ResponseModel, User } from 'common/interfaces';
import { setCustomUrlTree, setForm, setSnakbar, setVehicleListQueryKey, toggleModal } from 'actions/app_action';
import { AxiosResponse } from 'axios';
import { defaultUrlTree } from 'components/breadcrumbs-panel-dashboard/breadcrumbs-panel-dashboard';
import { usePreviousValue } from 'hooks/use-previous-value';
import { getFormValues } from 'utils/get-form-values';
import sharepointService from 'services/sharepoint-service';
import ConfigurationFromPdf from 'components/configuration-from-pdf/configuration-from-pdf';
import ChooseDriverDialog from 'components/vehicle-offers-list-component/choose-driver-dialog/choose-driver-dialog';
import { createVehicleOffer } from 'services/fleet-vehicle-service';
import { withPageContainer } from 'hoc/withPageContainer';
import { Button, Distance, Flex } from 'common/common-components.styled';
import useLatestValue from 'hooks/use-latest-value';
import { ItemProps } from 'components/product/product-view.props';
import { isEmpty, isEqual } from 'lodash';
import { useVehicleListQuery } from 'hooks/react-query/vehicle-list';
import { useCompanyBasketQuery } from 'hooks/react-query/company-basket';
import { useUserBasketQuery } from 'hooks/react-query/basket';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import { UploadIcon } from 'components/icons/upload-icon';
import { ConfigurationTypes, PAGES } from 'common/enums';
import Pagination from 'components/pagination';
import PlaceholderHint from './components/placeholder-hint';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { vehicleOfferPageLoadingKey } from 'pages/fahrzeuge-der-car-policy-page/fahrzeuge-der-car-policy-page';
import { useModifyURLTree } from 'hooks/use-modify-url-three';
import { useGetUser } from 'hooks/react-query/use-get-user/use-get-user';
import useSelectDriver from './hooks/use-select-driver';
import { CallbackTypes, SelectDriverData } from './select-driver-component/select-driver-component.types';
import { useReferenceVehicleTypes } from 'hooks/react-query/show-change-view-button';
import useConfiguratorLink from 'pages/car-configurator/hooks/use-configurator-link';

const VehicleOffersListComponent = ({
  filterState,
  containerClassName,
  tableParams,
  setTableParams,
  setDefaultPage,
  configurationType
}: VehicleOffersListComponentProps) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const [showOverlay, hideOverlay] = useOverlay(vehicleOfferPageLoadingKey);
  const user = useSelector((state: AnyObject): User => state['app'].user);
  const forms = useSelector((state: AnyObject): AnyObject => state['app'].forms);
  const sort = useSelector((state: AnyObject): Sort => state['app'].smartTable.sort);

  const [isLoading, setIsLoading] = useState(true);
  const [wasRequestSentAfterMount, setWasRequestSentAfterMount] = useState(false);

  const isNeuesFahrzeugKonfigurierenPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_CONFIGURATIONS);
  }, [location]);
  const isNeuesMitarbeiterkonfigurationenPage = useMemo(() => {
    return location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS);
  }, [location]);
  const isReferenzfahrzeugePage = useMemo(() => {
    return (
      location.pathname.includes(PAGES.REFERENCE_VEHICLES) &&
      !location.pathname.includes(PAGES.NEW_REFERENCE_CONFIGURATIONS)
    );
  }, [location]);
  const isMitarbeiterkonfigurationenPage = useMemo(() => {
    return location.pathname.includes(PAGES.EMPLOYEE_CONFIGURATIONS);
  }, [location]);
  const isMeineKonfigurationenPage = useMemo(() => {
    return location.pathname.includes(PAGES.CONFIGURATIONS) && !location.pathname.includes(PAGES.NEW_CONFIGURATIONS);
  }, [location]);
  const queryData = query.data ? JSON.parse(decodeBase64(query.data as string)) : null;
  const driverId: string | null = queryData?.driverId || null;
  const carPolicy: string | null = queryData?.uds_carpolicy || null;
  const driverLevel: string | null = queryData?.uds_carpolicynutzer || null;
  const previousFilterState = usePreviousValue(filterState);
  const previousSortState = usePreviousValue(sort);
  const formsLatest = useLatestValue(forms);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);
  const { goToConfiguratorPage } = useConfiguratorLink();

  const { companyBasket, isFetching: isFetchingCompanyBasket } = useCompanyBasketQuery(
    isMitarbeiterkonfigurationenPage || isMitarbeiterkonfigurationenPage ? user.id : driverId || user.id
  );
  const { userBasket, isFetching: isFetchingUserBasket } = useUserBasketQuery(user.id);
  const { isPdfConfigurationAvailable } = useReferenceVehicleTypes(true);

  const getUseVehicleListQueryBasket = () => {
    if (isMeineKonfigurationenPage) {
      return userBasket?.opportunityid;
    }
    if (isMitarbeiterkonfigurationenPage) {
      return undefined;
    }

    return companyBasket?.opportunityid;
  };

  const { userData } = useGetUser(driverId || user.id, false);

  const {
    data: table,
    refetch: reloadTable,
    isFetching: isFetchingVehicleList,
    isFetchedAfterMount: isFetchedVehicleListAfterMount
  } = useVehicleListQuery({
    tableParams,
    basketId: getUseVehicleListQueryBasket(),
    isMitarbeiterkonfigurationenPage: isMitarbeiterkonfigurationenPage,
    isMeineKonfigurationenPage: isMeineKonfigurationenPage,
    isReferenzfahrzeugePage: isReferenzfahrzeugePage,
    configurationType
  });

  const openNewConfigurationDialog = (selectDriverData: SelectDriverData) => {
    const alertData = {
      title: 'Herstellerkonfiguration hochladen',
      children: (
        <ConfigurationFromPdf
          createVehicleOffer={(data, files, isAdditionalFieldsShown) =>
            createVehicleOfferCallback(data, files, isAdditionalFieldsShown)
          }
          selectDriverData={selectDriverData}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          hide: true,
          title: 'Abbrechen',
          action: () => dispatch(toggleModal(MODALS.alert, null))
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openDriverDialog = (selectDriverData?: SelectDriverData) => {
    const alertData = {
      title: 'Neues Fahrzeug konfigurieren',
      children: <ChooseDriverDialog selectDriverData={selectDriverData} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Zurück',
          action: () => dispatch(toggleModal(MODALS.alert, null))
        },
        {
          type: 'submit',
          title: 'Weiter',
          action: setDriver
        }
      ]
    };

    if (isMeineKonfigurationenPage) {
      history.push(PAGES.NEW_CONFIGURATIONS);
    } else {
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    }
  };

  const setUseQueryKey = () => {
    dispatch(
      setVehicleListQueryKey({
        tableParams,
        basketId: getUseVehicleListQueryBasket(),
        isMitarbeiterkonfigurationenPage: isMitarbeiterkonfigurationenPage,
        isMeineKonfigurationenPage: isMeineKonfigurationenPage,
        isReferenzfahrzeugePage: isReferenzfahrzeugePage,
        configurationType
      })
    );
  };

  const isFetching = isFetchingUserBasket || isFetchingCompanyBasket || isFetchingVehicleList || isLoading;

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      if (query.redirect && table?.items.length === 0) {
        if (isNeuesMitarbeiterkonfigurationenPage) {
          history.push(PAGES.EMPLOYEE_CONFIGURATIONS);
        } else {
          history.push(PAGES.CONFIGURATIONS);
        }
      }
      hideOverlay();
    }
  }, [isFetching, showOverlay, hideOverlay, query, table, history, isNeuesMitarbeiterkonfigurationenPage]);

  const { updateURLTreeChild } = useModifyURLTree(defaultUrlTree);

  useEffect(() => {
    if (isNeuesMitarbeiterkonfigurationenPage && driverId && userData) {
      const fullName = userData.fullname;
      if (!fullName) {
        return;
      }
      const title = `Referenzfahrzeuge für ${fullName}`;

      updateURLTreeChild(['referenzfahrzeuge', 'neues-fahrzeug-konfigurieren'], {
        breadcrumbTitle: title,
        title: title
      });
    }
  }, [userData]);

  useEffect(() => {
    return () => {
      dispatch(setCustomUrlTree(null));
    };
  }, []);

  useEffect(() => {
    if (
      !wasRequestSentAfterMount &&
      !isFetchedVehicleListAfterMount &&
      !isEmpty(tableParams.search) &&
      (isMitarbeiterkonfigurationenPage ? true : !!getUseVehicleListQueryBasket())
    ) {
      reloadTable();
      setWasRequestSentAfterMount(true);
    }
  }, [tableParams, isFetchedVehicleListAfterMount]);

  useEffect(() => {
    if (!isEqual(previousFilterState, filterState) || !isEqual(previousSortState, sort) || userData) {
      getList();
    }
  }, [sort, filterState, userData]);

  useEffect(() => {
    if (configurationType === ConfigurationTypes.CarConfigurator) {
      goToConfiguratorPage({
        urlParams: { driverId: driverId || user.id },
        stateParam: { configurationData: queryData }
      });
    }
  }, [configurationType]);

  const getList = (deactivateCar?: boolean) => {
    if (!userData) {
      return;
    }
    const tableParamsNew: TableParams = {
      pagination: tableParams.pagination,
      sort: sort,
      search: {}
    };

    if (deactivateCar && table?.items.length === 1 && tableParamsNew.pagination.start > 1) {
      tableParamsNew.pagination = {
        ...tableParamsNew.pagination,
        start: tableParamsNew.pagination.start - 1
      };
    }

    try {
      if (isNeuesFahrzeugKonfigurierenPage || isNeuesMitarbeiterkonfigurationenPage) {
        setIsLoading(true);
        let carPolicyForFilter = carPolicy;
        let driverLevelForFilter = driverLevel;
        if (!carPolicy || !driverLevel) {
          carPolicyForFilter = userData.uds_carpolicyid?.id || null;
          driverLevelForFilter = userData.uds_carpolicynutzerid?.id || null;
        }

        if (carPolicyForFilter && driverLevelForFilter) {
          tableParamsNew.search = {
            ...filterState,
            carPolicy: carPolicyForFilter,
            driverLevel: driverLevelForFilter,
            status: 100000009,
            eventType: VEHICLE_OFFER_EVENT_DESCRIPTIONS.purchaseTriggered.id
          };
          setDefaultPage(tableParamsNew.pagination.start);
          setTableParams(tableParamsNew);
        } else {
          const snackbarText = getSnackbarText(carPolicyForFilter, driverLevelForFilter);
          dispatch(
            setSnakbar({
              isOpen: true,
              type: 'warning',
              message: snackbarText
            })
          );
        }
      } else {
        tableParamsNew.search = {
          ...filterState
        };
        if (isReferenzfahrzeugePage) {
          tableParamsNew.search.status = 100000009;
        }
        setDefaultPage(tableParamsNew.pagination.start);
        setTableParams(tableParamsNew);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }

    if (deactivateCar) {
      reloadTable();
    }
  };

  const { openSelectDriverDialog, startProcessForDriver } = useSelectDriver(
    openNewConfigurationDialog,
    openDriverDialog,
    () => getList(true)
  );

  const getSnackbarText = (carPolicyForFilter: string | null, driverLevelForFilter: string | null) => {
    if (!carPolicyForFilter && !driverLevelForFilter) {
      return 'Dir wurde weder eine Car-Policy noch ein Mobilitätsbudget zugewiesen. Bitte kontaktiere Deinen Fuhrparkleiter.';
    }
    if (carPolicyForFilter) {
      return 'Dir wurde noch kein Mobiliätsbudget zugewiesen. Bitte kontaktiere Deinen Fuhrparkleiter.';
    }
    if (driverLevelForFilter) {
      return 'Dir wurde noch keine Car-Policy zugewiesen. Bitte kontaktiere Deinen Fuhrparkleiter.';
    }
    return '';
  };

  const pageChangeHandler = (page: number) => {
    setDefaultPage(page);
    setTableParams(tableParams => ({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        start: page
      }
    }));
  };

  const setDriver = () => {
    getFormValues(
      formsLatest.current,
      (form: AnyObject) => dispatch(setForm(form)),
      async (values: AnyObject) => {
        const data = { ...values };
        if (isPoolManager && !data['uds_kstverantw']) {
          data['uds_kstverantw'] = user.id;
        }

        history.push(`${PAGES.NEW_REFERENCE_CONFIGURATIONS}?data=${encodeBase64(JSON.stringify(data))}`);
        dispatch(toggleModal(MODALS.alert, null));
      }
    );
  };

  const createVehicleOfferCallback = async (dataToSend: AnyObject, files: File[], isAdditionalFieldsShown: boolean) => {
    dispatch(toggleModal(MODALS.alert, null));
    showOverlay('createVehicleOfferCallback');
    let snackbarData = {
      type: 'success',
      message: isAdditionalFieldsShown ? 'Änderungen erfolgreich gespeichert.' : 'Fahrzeugkonfiguration hochgeladen.'
    };
    const response: AxiosResponse<ResponseModel> = await createVehicleOffer(dataToSend);
    if (response.data?.data) {
      const fileResponse: AxiosResponse = await sharepointService.uploadSharepointFile(
        files,
        'Hersteller-Konfiguration',
        response.data.data.fahrzeugangeboteId,
        'uds_fahrzeugangebote',
        null
      );
      if (fileResponse.data?.level !== RequestStatuses.Success) {
        snackbarData = {
          type: 'error',
          message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
        };
      }
    } else {
      snackbarData = {
        type: 'error',
        message: 'Fahrzeugkonfiguration wurde nicht hochgeladen.'
      };
    }
    hideOverlay('createVehicleOfferCallback');
    dispatch(
      setSnakbar({
        ...snackbarData,
        isOpen: true
      })
    );
    if (response.data?.data?.fahrzeugangeboteId) {
      history.push(
        `${
          isMeineKonfigurationenPage ? PAGES.CONFIGURATION_DETAILS : PAGES.EMPLOYEE_CONFIGURATION_DETAILS
        }?id=${encodeBase64(response.data?.data.fahrzeugangeboteId)}`
      );
    }
  };

  useEffect(() => {
    if (query.newConfig) {
      openDriverDialog();
      history.replace(PAGES.EMPLOYEE_CONFIGURATIONS);
    }
  }, [query.newConfig]);

  return (
    <StyledVehicleOffersListContainer>
      <div className={containerClassName}>
        {isReferenzfahrzeugePage && (
          <Flex justify='flex-end' margin='0 0 20px 25px' style={{ position: 'relative' }}>
            <Link to={PAGES.NEW_CONFIGURATION}>
              <Button>Neues Referenzfahrzeug</Button>
            </Link>
          </Flex>
        )}

        {(isMitarbeiterkonfigurationenPage || isMeineKonfigurationenPage) && (
          <Flex justify='flex-end' margin='0 0 20px 25px' style={{ position: 'relative' }}>
            {isPdfConfigurationAvailable && (
              <Button
                id='UploadConfigurationButton'
                secondary
                right='5'
                onClick={() =>
                  isMeineKonfigurationenPage
                    ? startProcessForDriver(CallbackTypes.PdfConfiguration, user.id)
                    : openSelectDriverDialog(CallbackTypes.PdfConfiguration)
                }
              >
                <Flex align='center'>
                  <UploadIcon />
                  <Distance side='10' />
                  Konfiguration hochladen
                </Flex>
              </Button>
            )}
            <Button
              onClick={() =>
                isMeineKonfigurationenPage
                  ? startProcessForDriver(CallbackTypes.GlobalConfiguration, user.id)
                  : openSelectDriverDialog(CallbackTypes.GlobalConfiguration)
              }
            >
              <Flex align='center'>
                <PlusLargeIcon />
                <Distance side='10' />
                Fahrzeug konfigurieren
              </Flex>
            </Button>
          </Flex>
        )}

        {table?.items?.length ? (
          <StyledConfigurationsWrapper>
            <div style={{ position: 'relative' }}>
              {table?.items.map((item: ItemProps) => {
                return (
                  <Product
                    reloadList={getList}
                    setUseQueryKey={setUseQueryKey}
                    item={item}
                    key={item.uds_fahrzeugangeboteid}
                  />
                );
              })}
            </div>

            {table?.count > tableParams.pagination.number && (
              <Pagination
                activePage={tableParams.pagination.start}
                itemsCountPerPage={tableParams.pagination.number}
                totalItemsCount={table.count}
                onPageChange={pageChangeHandler}
              />
            )}
          </StyledConfigurationsWrapper>
        ) : (
          <PlaceholderHint />
        )}
      </div>
    </StyledVehicleOffersListContainer>
  );
};

export default withPageContainer(VehicleOffersListComponent);
