import React from 'react';
import { useVehicleDetailGallery } from 'pages/existing-vehicle-page/components/layout/car-overview-section/components/car-section/hooks/use-vehicle-detail-gallery';
import { CASH_KEY_GET_MAIL_LIST_QUERY, useGetMailListQuery } from 'hooks/react-query/fleet/get-mail-list/get-mail-list';
import { cloneDeep } from 'lodash';
import { SharePointTitle } from 'common/enums';
import { IDocumentsGroupedByDescriptionItem } from 'components/download-documents-component/download-documents-component.props';
import { SortingDocumentsTabs } from 'components/documents/sorting-tabs-documents/sorting-documents-tabs';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { CASH_KEY_FILES_BY_ID_QUERY } from 'hooks/react-query/fleet/use-files-by-id/use-files-by-id';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../../../../../constants';

export const VehicleDetailsFileList = () => {
  const { activeFiles, isFetchingGallery } = useVehicleDetailGallery();
  const { getMailListQuery, isFetching: isFetchingGetMailListQuery } = useGetMailListQuery();
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const isVisibleDeleteDocumentButton = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const convertDataInCorrectFormat = () => {
    const cloneActiveFiles = cloneDeep(activeFiles);
    const description = cloneActiveFiles
      .filter(file => !!file.Description)
      .map(file => file.Description)
      .sort((a, b) => (a && b ? a.localeCompare(b) : 0)) as string[];

    const uniqueDescription = cloneDeep(description);

    const newFiles: Record<string, IDocumentsGroupedByDescriptionItem[]> = {};

    uniqueDescription.map(description => {
      newFiles[description] = [];
    });

    Object.keys(newFiles).map(key => {
      cloneActiveFiles.map(file => {
        if (key === file.Description) {
          const newFileItem: IDocumentsGroupedByDescriptionItem = {
            fileId: file.UniqueId || file.FileId || '',
            description: file.Description || '',
            filePath: file.FilePath || '',
            lastModified: file.CreatedOn,
            fileSize: file.Length ? Number(file.Length) : undefined,
            createdBy: file.CreatedBy
          };

          newFiles[key].push(newFileItem);
        }
      });
    });

    return newFiles;
  };

  const getFiles = () => {
    let convertedDocuments = convertDataInCorrectFormat();

    if (Array.isArray(getMailListQuery) && getMailListQuery.length) {
      convertedDocuments = {
        ...convertedDocuments,
        [SharePointTitle.OtherDocuments]: getMailListQuery.map(file => ({
          ...file,
          fileId: file.fileId || '',
          filePath: file.filePath || '',
          description: '',
          lastModified: file.created,
          fileSize: file.length
        }))
      };
    }

    return convertedDocuments;
  };

  return (
    <SortingDocumentsTabs
      isHideDeleteButton={!isVisibleDeleteDocumentButton}
      documentsQueryKeyList={[CASH_KEY_GET_MAIL_LIST_QUERY, CASH_KEY_FILES_BY_ID_QUERY]}
      zipArchiveName={fuhrpark?.new_name || ''}
      isLoading={isFetchingGetMailListQuery || isFetchingGallery}
      data={getFiles()}
    />
  );
};
