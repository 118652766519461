/* eslint-disable no-unused-vars */
export enum OrderVehicleReportFields {
  Name = 'person',
  Phone = 'phone',
  Address = 'address',
  Comment = 'remark'
}

export type OrderVehicleReportTypes = {
  fuhrparkId: string | null;
  actionName: string;
  taskId?: string | null;
  phaseInfo: {
    person: string;
    phone: string;
    address: string;
  };
  closeAlert: () => void;
  callback?: () => void;
};

export type OrderVehicleReportType = {
  [key in OrderVehicleReportFields]?: string;
};
