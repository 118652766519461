import React from 'react';
import { ConfigurationInfoProps } from './configuration-info.types';
import { Flex, Text } from 'common/common-components.styled';
import moment from 'moment';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

const ConfigurationInfo = ({ configuration }: ConfigurationInfoProps) => {
  if (!configuration) {
    return null;
  }

  return (
    <Flex top='20'>
      <Flex direction='column' width='20%'>
        <Text uppercase bold size='10' color='grey600'>
          FA-Nummer
        </Text>
        <Text top='5' color='grey900'>
          {configuration.uds_name || '-'}
        </Text>
      </Flex>
      <Flex direction='column' width='20%'>
        <Text uppercase bold size='10' color='grey600'>
          Konfiguriert am:
        </Text>
        <Text top='5' color='grey900'>
          {configuration.createdon ? moment(configuration.createdon.value).format('DD.MM.YYYY') : '-'}
        </Text>
      </Flex>
      <Flex direction='column' width='30%' padding='0 5px 0 0'>
        <Text uppercase bold size='10' color='grey600'>
          Konfiguriert Für:
        </Text>
        <EllipsisWithTooltip tooltip={configuration.parentcontactid?.name || ''}>
          <Text top='5' color='grey900'>
            {configuration.parentcontactid?.name || '-'}
          </Text>
        </EllipsisWithTooltip>
      </Flex>
      <Flex direction='column' width='30%' padding='0 5px 0 0'>
        <Text uppercase bold size='10' color='grey600'>
          Konfiguriert von:
        </Text>
        <EllipsisWithTooltip tooltip={configuration.parentcontactid?.name || ''}>
          <Text top='5' color='grey900'>
            {configuration.parentcontactid?.name || '-'}
          </Text>
        </EllipsisWithTooltip>
      </Flex>
    </Flex>
  );
};

export default ConfigurationInfo;
