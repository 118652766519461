import { FormFieldTypes, MimeTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { VehicleDetailsDocumentFormFormData } from './document-form.props';
import { errorMessages } from 'utils/error-messages';
import { FormSourceEnum } from 'components/form-hook/form-field/hooks/use-set-options/use-set-options.props';

export const formFields: FormFieldModel<keyof VehicleDetailsDocumentFormFormData>[] = [
  {
    name: 'documentType',
    label: 'Dokumenttyp',
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    source: FormSourceEnum.GetDocumentTypes,
    validation: { required: errorMessages.required }
  },
  {
    name: 'documents',
    type: FormFieldTypes.File,
    defaultValue: [],
    validation: { required: errorMessages.required },
    dropzoneOptions: {
      multiple: true,
      accept: [
        MimeTypes.PDF,
        MimeTypes.PNG,
        MimeTypes.JPG,
        MimeTypes.JPEG,
        MimeTypes.EML,
        MimeTypes.MSG,
        MimeTypes.HEIC
      ],
      maxSize: 1024 * 1024 * 10
    }
  }
];
