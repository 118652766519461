import React from 'react';
import { IconProps } from 'common/interfaces';

export const MarkIcon = ({ width = 12, height = 7, color = '#335566', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 7'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.25 1.0989L6.04722 5.5L1.75 1'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
