import { useEffect } from 'react';
import { DeviceTypes } from '../../../constants';
import { useSessionState } from 'hooks/use-session-state';
import useWindowSize from 'hooks/use-window-size';
import { useDispatch } from 'react-redux';
import { getDeviceType } from 'utils/get-device-type';
import { setSideMenu } from 'actions/app_action';

const deviceType = getDeviceType();

export const useIsMinimized = () => {
  const { windowWidth } = useWindowSize();
  const dispatch = useDispatch();

  const { state: isMinimized, setSessionState: setIsMinimized } = useSessionState<boolean>(
    'side-menu-minimized',
    deviceType === DeviceTypes.Desktop && windowWidth < 1600 && windowWidth > 1000
  );

  useEffect(() => {
    dispatch(setSideMenu({ isMinimized }));
  }, [isMinimized]);

  return { isMinimized, setIsMinimized };
};
