import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { InspectionDateLinkToCarModalContent } from './link-to-car.content';
import { OpenInspectionDateLinkToCarModalParams } from './link-to-car.props';

export const useInspectionDateLinkToCarModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openInspectionDateLinkToCarModal = (params: OpenInspectionDateLinkToCarModalParams) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Umweltaudit',
        children: <InspectionDateLinkToCarModalContent {...params} />,
        allButtonsHidden: true,
        buttons: [{ type: 'cancel', action: closeAlert }]
      })
    );
  };

  return { openInspectionDateLinkToCarModal };
};
