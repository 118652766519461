import React, { useRef, useState } from 'react';
import { PaginationButtonProps } from './pagination-button.props';
import { StyledArrowIcon, StyledPaginationButton, StyledPopover } from './pagination-button.styled';
import { PaginationButtonTypes } from '../pagination.props';
import PaginationGap from 'components/pagination/pagination-gap';
import { PrefixForId } from 'common/enums';
import { gapsIntoCamelCase } from 'utils/convert-sting';

const PaginationButton = ({
  type,
  activePage,
  onPageChange,
  pageNumber = 0,
  margin = '0 2px 0 0',
  gapPages = [],
  shadow = true
}: PaginationButtonProps) => {
  const [open, setOpen] = useState(false);
  const button = useRef<HTMLDivElement | null>(null);
  const popover = useRef<HTMLDivElement | null>(null);

  const handleClickAway = () => {
    setOpen(false);
  };

  const pageChanged = (page: number) => {
    onPageChange(page);
    handleClickAway();
  };

  const clickHandler = () => {
    switch (type) {
      case PaginationButtonTypes.Page:
        onPageChange(pageNumber);
        break;
      case PaginationButtonTypes.PrevPage:
        onPageChange(activePage - 1);
        break;
      case PaginationButtonTypes.NextPage:
        onPageChange(activePage + 1);
        break;
      case PaginationButtonTypes.Gap:
        setOpen(true);
        break;
    }
  };

  const getButtonContent = () => {
    switch (type) {
      case PaginationButtonTypes.Page:
        return pageNumber;
      case PaginationButtonTypes.PrevPage:
        return <StyledArrowIcon />;
      case PaginationButtonTypes.NextPage:
        return <StyledArrowIcon rotate='true' />;
      case PaginationButtonTypes.Gap:
        return '...';
      default:
        return;
    }
  };

  return (
    <>
      <StyledPaginationButton
        ref={button}
        active={activePage === pageNumber}
        align='center'
        justify='center'
        margin={margin}
        onClick={clickHandler}
        shadow={shadow}
        id={gapsIntoCamelCase(`${PrefixForId.Pagination} ${type} ${pageNumber || ''}`)}
      >
        {getButtonContent()}
      </StyledPaginationButton>

      {type === PaginationButtonTypes.Gap && (
        <StyledPopover
          ref={popover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={open}
          anchorEl={button.current}
          onClose={handleClickAway}
        >
          <PaginationGap gapPages={gapPages} activePage={activePage} onPageChange={pageChanged} />
        </StyledPopover>
      )}
    </>
  );
};

export default PaginationButton;
