import React, { Component } from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from './error-boundary.props';
import { Text } from 'common/common-components.styled';

export class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.props.hideOverlay();
    this.setState({ hasError: true });

    if (/loading chunk \d+ failed./i.test(error.message)) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.errorComponent || (
          <Text size='24' bold left='30'>
            Error
          </Text>
        )
      );
    }
    return this.props.children;
  }
}
