import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function updateByDienstleistung(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/dienstleistung/updatebydienstleistung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function getServicesByAccount(accountId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/serviceleistung/getByAccount`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      accountId
    }
  });
}

export async function updateServicesForAccount(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/serviceleistung/updateForAccount`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}
