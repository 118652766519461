/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { ComponentsProps } from './components.props';

const TwoButtonsComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldRegisterSecond,
  fieldName,
  firstButtonText = 'Ja',
  firstButtonValue = true,
  secondButtonText = 'Nein',
  secondButtonValue = false,
  optional = false,
  optionalText = '(optional)',
  needTooltip = false,
  tooltipText = '',
  tooltipPosition = 'top'
}: ComponentsProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors }
  } = reactHookFormData;

  const setFirstValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, firstButtonValue);
    e.preventDefault();
  };

  const setSecondValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (fieldRegisterSecond) {
      setValue(fieldRegisterSecond, secondButtonValue);
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (fieldRegisterSecond && (watch(fieldRegister) !== undefined || watch(fieldRegisterSecond) !== undefined)) {
      clearErrors(fieldRegister);
      clearErrors(fieldRegisterSecond);
    }
  }, [errors[fieldRegister], watch(fieldRegister), fieldRegisterSecond && watch(fieldRegisterSecond)]);

  const errorButtonStyle = needTooltip ? styles.form_name_button_error_tooltip : styles.form_name_button_error;
  const buttonStyle = needTooltip ? styles.form_name_button_tooltip : styles.form_name_button;

  return (
    <div className={styles.field_container}>
      <div className={errors[fieldRegister] ? errorButtonStyle : buttonStyle}>
        <span className={needTooltip ? styles.tooltip_name : styles.span_name}>
          <p>
            {fieldName} {optional && <span className={styles.optional}>{optionalText}</span>}
          </p>
          {needTooltip && (
            <div
              className={styles.tooltip}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <TooltipHint position={tooltipPosition} text={tooltipText}>
                <InfoHintIcon color='#919699' width={16} height={16} hovered={isHovered} />
              </TooltipHint>
            </div>
          )}
        </span>
      </div>
      <div className={styles.row_columns}>
        <div className={styles.half_input}>
          <button
            id={`${fieldName}${firstButtonText}Button`}
            onClick={e => setFirstValue(e)}
            className={watch(fieldRegister) === firstButtonValue ? styles.button_half_choosen : styles.button_half}
          >
            {firstButtonText}
          </button>
        </div>
        <div className={styles.half_input}>
          <button
            id={`${fieldName}${secondButtonText}Button`}
            onClick={e => setSecondValue(e)}
            className={
              fieldRegisterSecond && watch(fieldRegisterSecond) === secondButtonValue
                ? styles.button_half_choosen
                : styles.button_half
            }
          >
            {secondButtonText}
          </button>
        </div>
      </div>

      <Distance top='10' />
    </div>
  );
};

export default TwoButtonsComponent;
