import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import ArrowDown from 'components/icons/arrow-down';
import { Flex, Text, Rotate, Direction } from 'common/common-components.styled';
import { RepairStatusWidgetContext } from 'components/widgets/repair-status/repair-status.context';

export const ExtendedButton = () => {
  const theme = useTheme();

  const { isExtended, setIsExtended } = useContext(RepairStatusWidgetContext);

  return (
    <Flex justify='flex-end' top='25'>
      <Flex align='center' pointer onClick={() => setIsExtended(!isExtended)}>
        <Text color='blue' right='5'>
          Werkstatt kontaktieren
        </Text>

        <Rotate rotateDirection={isExtended ? Direction.Top : Direction.Bottom} transition='0.15s'>
          <ArrowDown color={theme.colors.blue} width='14' />
        </Rotate>
      </Flex>
    </Flex>
  );
};
