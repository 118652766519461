import React from 'react';
import { FilldPhaseContentProps } from './filled-phase-content.types';
import { Flex, Text } from 'common/common-components.styled';
import { useFormContext } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { StyledSwitch } from 'components/form-hook/form-field/form-field.styled';
import { ToggleContainer } from './filled-phase-content.styled';
import { WidgetContentText } from '../../../components/leasing-widget/leasing-widget.styled';
import { useFilledPhaseConttent } from './use-filled-phase-content';

const FilledPhaseContent: React.FC<FilldPhaseContentProps> = ({
  showForm,
  dontHaveHandoverProtocol,
  handleChangeDontHaveHandoverProtocol
}) => {
  const reactHookFormData = useFormContext();
  const { handoverDate, redirectToOrder } = useFilledPhaseConttent(dontHaveHandoverProtocol);

  return (
    <>
      {showForm ? (
        <Flex direction='column' width='100%'>
          <FormHook reactHookFormData={reactHookFormData} formFields={handoverDate} distanceBetweenFields='0' />

          <ToggleContainer>
            <StyledSwitch
              id={'isHandoverProtocolExist'}
              checked={dontHaveHandoverProtocol}
              onChange={e => handleChangeDontHaveHandoverProtocol(e.target.checked)}
            />

            <Text size='12'>Ich habe kein Übergabeprotokoll</Text>
          </ToggleContainer>
        </Flex>
      ) : (
        <WidgetContentText>
          Du hast den
          <Text color='darkBlue' as={'span'} bold pointer onClick={redirectToOrder}>
            {` Auftrag `}
          </Text>
          für die Rückführungsformular erfolgreich erstellt. Bitte lade das Protokoll der Übergabe hoch, sobald du das
          Fahrzeug abgegeben hast.
        </WidgetContentText>
      )}
    </>
  );
};

export default FilledPhaseContent;
