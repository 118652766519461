import React from "react";
import classes from "./lead-form.module.scss";
import ReactGA from "react-ga";

ReactGA.initialize('AW-600353328');

export function ThankYou() {
  const phoneClicked = () => {
    ReactGA.event({ category: 'phone_conversion_number', action: '023462209410' });
  };

  return (
    <div>
      <h3 className={classes.thanks_header}>Vielen Dank. Wir melden uns schnellstmöglich bei Ihnen.</h3>
      <p className="text-center">Möchten Sie sofort mit jemanden reden?</p>
      <p className="text-center">
        Rufen Sie uns an unter{" "}
        <a onClick={phoneClicked} href="tel:023462209410" className={classes.phone}>
          0234-6220-9410
        </a>
      </p>
    </div>
  );
}
