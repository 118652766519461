import styled, { css } from 'styled-components';

export const SugningStyledContainer = styled.div`
  disaply: flex;
  width: 85%;
  margin: 0 auto;
`;

export const TitileStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SigningContentWrapper = styled.div`
  flex: 5;
  margin-right: 20px;
`;

export const PrivacyStyledLink = styled.a`
  text-decoration: underline !important;
  color: #000;
  &:hover {
    color: #000;
    opacity: 0.9;
  }
`;

export const StyledErrorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;

  @media (max-width: 1600px) {
    width: 70%;
  }

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledErrorIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 47%;

  @media (max-width: 900px) {
    width: 70%;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const StyledErrorContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  justify-content: space-between;

  @media (max-width: 900px) {
    width: 70%;
    margin-top: 40px;
  }

  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const StyledMobexoLink = styled.a`
  ${({ theme }) =>
    css`
      color: ${theme.colors.gold};
      &:hover {
        color: ${theme.colors.gold};
        opacity: 0.9;
      }
    `};
`;
