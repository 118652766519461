import { IFormFieldContext } from 'components/form-hook/form-field/form.props';
import styled from 'styled-components';

interface StyledTimeContainerProps extends Pick<IFormFieldContext, 'error'> {
  borderColor?: string;
}

export const StyledTimeContainer = styled.div<StyledTimeContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, error, borderColor }) =>
      error ? theme.colors.pink : borderColor ? borderColor : theme.colors.lightBlue};
  border-radius: 2px;
  padding: 10px;
  height: 42px;
`;

export const StyledTimeInput = styled.input<{ disabled?: boolean }>`
  font-size: 14px;
  max-width: 22px;
  min-width: 20px;
  width: fit-content;
  background: transparent;
  border: none;
  padding: 0;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey200 : theme.colors.black)};
`;
