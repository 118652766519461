import { FormFieldModel, FormFieldOption } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { CostCenterFormFieldsNames, PrimaryEntityValues } from '../cost-centers-settings.props';

const primaryEntityOptions: FormFieldOption[] = [
  {
    label: 'Fahrzeug',
    value: PrimaryEntityValues.Vehicle
  },
  {
    label: 'Fahrer',
    value: PrimaryEntityValues.Driver
  },
  {
    label: 'Standort',
    value: PrimaryEntityValues.Location
  }
];

export const primaryEntityFormFields: FormFieldModel[] = [
  {
    name: CostCenterFormFieldsNames.PrimaryEntity,
    type: FormFieldTypes.Radio,
    defaultValue: PrimaryEntityValues.Vehicle,
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: primaryEntityOptions,
    validation: {}
  }
];
