import React from 'react';

const KVAIcon = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='60' height='60' fill='white' />
      <path
        d='M39.7388 24.0015V19.2537L30.3358 9.17911H8.84328C8.10448 9.17911 7.5 9.78359 7.5 10.5224V49.4776C7.5 50.2164 8.10448 50.8209 8.84328 50.8209H30.7748'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M39.7389 19.2545H31.0076C30.6382 19.2545 30.3359 18.9522 30.3359 18.5828V14.553'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.2617 25.6646H30.3363'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.2617 42.3607H24.2916'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5603 26.3355C15.9313 26.3355 16.232 26.0348 16.232 25.6639C16.232 25.293 15.9313 24.9922 15.5603 24.9922C15.1894 24.9922 14.8887 25.293 14.8887 25.6639C14.8887 26.0348 15.1894 26.3355 15.5603 26.3355Z'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M15.5603 43.0313C15.9313 43.0313 16.232 42.7306 16.232 42.3597C16.232 41.9887 15.9313 41.688 15.5603 41.688C15.1894 41.688 14.8887 41.9887 14.8887 42.3597C14.8887 42.7306 15.1894 43.0313 15.5603 43.0313Z'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M20.2617 36.7953H24.2916'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5603 37.4664C15.9313 37.4664 16.232 37.1657 16.232 36.7947C16.232 36.4238 15.9313 36.1231 15.5603 36.1231C15.1894 36.1231 14.8887 36.4238 14.8887 36.7947C14.8887 37.1657 15.1894 37.4664 15.5603 37.4664Z'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M20.2617 31.2299H25.6349'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5603 31.9011C15.9313 31.9011 16.232 31.6003 16.232 31.2294C16.232 30.8585 15.9313 30.5578 15.5603 30.5578C15.1894 30.5578 14.8887 30.8585 14.8887 31.2294C14.8887 31.6003 15.1894 31.9011 15.5603 31.9011Z'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M40.4099 50.7094C47.0867 50.7094 52.4994 45.2968 52.4994 38.6199C52.4994 31.943 47.0867 26.5303 40.4099 26.5303C33.733 26.5303 28.3203 31.943 28.3203 38.6199C28.3203 45.2968 33.733 50.7094 40.4099 50.7094Z'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.7598 38.7709L40.0247 43.4053L45.062 33.8344'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default KVAIcon;
