import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { CloseIcon } from 'components/icons/close-icon';
import React from 'react';
import classes from './goided-tour-tooltip.module.scss';

interface Props {
  tooltipProps: any;
  title: React.ReactNode;
  content: React.ReactNode;
  index: number;
  stepsLength: number;
  primaryProps: any;
  closeProps: any;
}

const GuidedTourTooltip = ({ tooltipProps, title, content, index, stepsLength, primaryProps, closeProps }: Props) => {
  return (
    <div {...tooltipProps} className={classes.tooltip}>
      <div>
        <div className={classes.guide_title_wrapper}>
          <div className={classes.guide_title_wrapper}>
            <h3 className={classes.guide_title}>{title}</h3>
          </div>
        </div>
        <div className={classes.guide_content_wrapper}>
          <p className={classes.guide_content}>{content}</p>
        </div>
        <div className={classes.action_wrapper}>
          <span className={classes.steps_count}>{`${index + 1} von ${stepsLength}`}</span>
          <button {...primaryProps} className={classNames('button-mobexo__secondary', classes.next_button)}>
            {index + 1 >= stepsLength ? 'Fertig' : 'weiter'}
          </button>
        </div>
      </div>
      <Tooltip title='Tour beenden'>
        <div className={classes.cross_icon_wrapper}>
          <CloseIcon {...closeProps} />
        </div>
      </Tooltip>
    </div>
  );
};

export default GuidedTourTooltip;
