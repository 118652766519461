import React, { useContext, useRef } from 'react';
import { StyledTimeList } from './time-drop-down.styled';
import { FlatList } from 'components/flat-list/flat-list';
import { TimeDropDownItem } from './time-drop-down-item/time-drop-down-item';
import { TimeMeasure } from 'common/enums';
import { IOnScrollParams, ScrollDirection } from 'components/flat-list/flat-list.props';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { IFormFieldContext } from 'components/form-hook/form-field/form.props';
import { ITimeChildrenProps, ITimeValue } from '../time.props';
import { FLAT_LIST_HEIGHT, ITEM_SIZE, NUMBER_OF_VALUES_ON_THE_SIDE } from '../utils';
import { useTime } from 'hooks/use-time';
import { FocusBlock } from './focus-block/focus-block';
import {
  DateTimeFieldContext,
  DateTimeFieldContextProps
} from 'components/form-hook/form-field/components/date-time-field/date-time-field.context';

export const TimeDropDown = ({
  scrollToItemHandler,
  hourRef,
  minutesRef,
  timeList,
  setHourList,
  isUserScrolling
}: ITimeChildrenProps) => {
  const prevSelectedTime = useRef<Record<TimeMeasure, number | null>>({
    [TimeMeasure.Hour]: null,
    [TimeMeasure.Minutes]: null
  });

  const { value: valueTime, onChange: onChangeTime } = useContext<IFormFieldContext<ITimeValue>>(formFieldProvider);
  const { valueDateTime, onChangeDateTime } = useContext<DateTimeFieldContextProps>(DateTimeFieldContext);
  const value = valueDateTime || valueTime;
  const onChange = onChangeDateTime || onChangeTime;
  const { parseTime, stringifyTimeList, addTimeBackward, addTimeForward } = useTime();

  const addDropdownItem = (scrollDirection: ScrollDirection, timeMeasure: TimeMeasure) => {
    if (scrollDirection === ScrollDirection.Forward) {
      setHourList(prevState => ({
        ...prevState,
        [timeMeasure]: addTimeForward(prevState[timeMeasure], timeMeasure)
      }));
    } else {
      setHourList(prevState => ({
        ...prevState,
        [timeMeasure]: addTimeBackward(prevState[timeMeasure], timeMeasure)
      }));
    }
  };

  function onScroll(timeMeasure: TimeMeasure, { scrollOffset, scrollDirection }: IOnScrollParams) {
    const timeDropdownList = timeList[timeMeasure];
    const selectedTime = Math.round(scrollOffset / ITEM_SIZE);
    const isAddDropdownItem =
      prevSelectedTime.current[timeMeasure] !== selectedTime && prevSelectedTime.current[timeMeasure] !== null;

    if (prevSelectedTime.current[timeMeasure] === selectedTime || !isUserScrolling?.current) {
      return;
    }

    if (isAddDropdownItem) {
      addDropdownItem(scrollDirection, timeMeasure);
      if (scrollDirection === ScrollDirection.Backward) {
        if (timeMeasure === TimeMeasure.Hour) {
          hourRef.current?.scrollTo(scrollOffset + ITEM_SIZE);
        }
        if (timeMeasure === TimeMeasure.Minutes) {
          minutesRef.current?.scrollTo(scrollOffset + ITEM_SIZE);
        }
      }
    }

    prevSelectedTime.current[timeMeasure] = selectedTime;
    onChange({ ...value, [timeMeasure]: timeDropdownList[selectedTime + NUMBER_OF_VALUES_ON_THE_SIDE] || '' });
  }

  const onClickHandler = (timeMeasure: TimeMeasure, index: number, timeValue: string) => {
    const direction =
      parseTime(value[timeMeasure]) < parseTime(timeValue) ? ScrollDirection.Forward : ScrollDirection.Backward;

    addDropdownItem(direction, timeMeasure);
    scrollToItemHandler(timeMeasure, index + (direction === ScrollDirection.Forward ? 0 : 1));

    onChange({ ...value, [timeMeasure]: timeValue });
  };

  return (
    <StyledTimeList isMobile={false}>
      <FocusBlock />

      <FlatList
        data={stringifyTimeList(timeList[TimeMeasure.Hour])}
        renderItem={
          <TimeDropDownItem
            idPrefix='minutesDropdownItem'
            time={parseTime(value[TimeMeasure.Hour])}
            onClick={(index, timeValue) => onClickHandler(TimeMeasure.Hour, index, timeValue)}
            onScroll={time => scrollToItemHandler(TimeMeasure.Hour, time)}
          />
        }
        height={FLAT_LIST_HEIGHT}
        itemSize={ITEM_SIZE}
        onScroll={props => onScroll(TimeMeasure.Hour, props)}
        ref={hourRef}
      />

      <FlatList
        data={stringifyTimeList(timeList[TimeMeasure.Minutes])}
        renderItem={
          <TimeDropDownItem
            idPrefix='secondsDropdownItem'
            time={parseTime(value[TimeMeasure.Minutes])}
            onClick={(index, timeValue) => onClickHandler(TimeMeasure.Minutes, index, timeValue)}
            onScroll={time => scrollToItemHandler(TimeMeasure.Minutes, time)}
          />
        }
        height={FLAT_LIST_HEIGHT}
        itemSize={ITEM_SIZE}
        onScroll={props => onScroll(TimeMeasure.Minutes, props)}
        ref={minutesRef}
      />
    </StyledTimeList>
  );
};
