import { AxiosRequestConfig } from 'axios';
import { AnyObject } from 'common/interfaces';

class ServiceRequestConfig {
  create = (data: AnyObject): AxiosRequestConfig => ({
    method: 'post',
    url: 'Dienstleistung/CreateDienstleistung',
    data
  });
}

export const serviceRequestConfig = new ServiceRequestConfig();
