import React from 'react';
import { useTheme } from 'styled-components';
import { useVehicleReturn } from './use-vehicle-return';
import LeasingWidget from '../components/leasing-widget';
import { CarInGarage } from 'components/icons-new/car-in-garage';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import VehicleReturnButtons from './components/vehicle-return-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';
import { FormProvider, useForm } from 'react-hook-form';

const VehicleReturn = () => {
  const theme = useTheme();
  const reactHookFormData = useForm();
  const {
    statusColor,
    statusLabel,
    description,
    bulletPointsActiveindex,
    showBulletPoints,
    bulletPoinstController,
    dontHaveHandoverProtocol
  } = useVehicleReturn();

  return (
    <FormProvider {...reactHookFormData}>
      <LeasingWidget
        image={<CarInGarage color={theme.colors.midBlue} width={60} height={40} />}
        statusColor={statusColor}
        statusLabel={statusLabel}
        showBulletPoints={showBulletPoints}
        bulletPoinstController={bulletPoinstController}
        buttons={
          <VehicleReturnButtons
            activeWidgetindex={bulletPointsActiveindex}
            dontHaveHandoverProtocol={dontHaveHandoverProtocol}
          />
        }
        id={'vehicleReturn'}
      >
        <WidgetWrapper>
          <DescriptionContainer>{description}</DescriptionContainer>
        </WidgetWrapper>
      </LeasingWidget>
    </FormProvider>
  );
};

export default VehicleReturn;
