import React, { useEffect, useState } from 'react';
import Form from 'components/form';
import {
  oldCarFormFields,
  policiesFormFields,
  responsiblePersonFormFields
} from 'models/forms/configuration-from-pdf-form-model.js';
import { Distance, Divider, Flex, Text } from 'common/common-components.styled';
import Switch from 'react-switch';
import { getFields } from 'components/form/form-view';
import { AnyObject } from 'common/interfaces';
import useFillResponsiblePerson from 'components/configuration-from-pdf/use-fill-responsible-person';
import cloneDeep from 'lodash/cloneDeep';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import { ChooseDriverDialogProps } from './choose-driver-dialog.types';
import useDriverFields from 'components/configuration-from-pdf/hooks/use-driver-fields';

const ChooseDriverDialog = ({ selectDriverData }: ChooseDriverDialogProps) => {
  const [oldCarPrevValue, setOldCarPrevValue] = useState('');
  const [responsiblePersonFields, setResponsiblePersonFields] = useState<AnyObject>(responsiblePersonFormFields);
  const [oldCarFields, setOldCarFields] = useState<AnyObject | null>(null);
  const [isReplacementCar, setIsReplacementCar] = useState(false);
  const [isPoolcar, setIsPoolcar] = useState(selectDriverData?.isPoolCar || false);

  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);
  const fillResponsiblePerson = useFillResponsiblePerson(
    responsiblePersonFields,
    setResponsiblePersonFields,
    isPoolcar
  );
  const { driverFields } = useDriverFields(selectDriverData);

  useEffect(() => {
    const newOldCarFields = cloneDeep(oldCarFormFields);
    getFields(newOldCarFields, (field: AnyObject) => {
      field.label = 'Zu ersetzendes Fahrzeug';
      field.source = isPoolManager ? 'getCompanyActiveCarsForPoolManager' : 'getCompanyActiveCars';
    });
    setOldCarFields(newOldCarFields);
  }, []);

  useEffect(() => {
    if (isReplacementCar) {
      fillResponsiblePerson(null);
    }
  }, [isReplacementCar]);

  useEffect(() => {
    fillResponsiblePerson(null);
  }, [isPoolcar]);

  const oldCarValueChanged = (fieldName: string, value: string) => {
    if (fieldName === 'uds_altes_fahrzeugid' && value !== oldCarPrevValue) {
      setOldCarPrevValue(value);

      fillResponsiblePerson(value);
    }
  };

  return (
    <div>
      <p>Konfigurere ein Mitarbeiter-Fahrzeug oder ein Pool-Car.</p>
      <Distance top='20' />
      {!isPoolManager && <Form name={'uds_kstverantw'} valueChanged={() => {}} formFields={responsiblePersonFields} />}

      {!isReplacementCar && oldCarFields && (
        <Form name={'uds_altes_fahrzeugid'} valueChanged={oldCarValueChanged} formFields={oldCarFields} />
      )}

      <Flex align='center' margin='20px 0'>
        <Switch
          offColor={'#bfbfbf'}
          onColor={'#335566'}
          height={20}
          width={40}
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={event => setIsReplacementCar(event)}
          checked={isReplacementCar}
        />
        <Text left='10' color='black' pointer onClick={() => setIsReplacementCar(flag => !flag)}>
          Dies ist kein Ersatzfahrzeug
        </Text>
      </Flex>

      <Divider margin='20px 0 15px' />

      {!isPoolcar && <Form name={'driver'} valueChanged={() => {}} formFields={driverFields} />}

      <Flex align='center' margin='20px 0'>
        <Switch
          offColor={'#bfbfbf'}
          onColor={'#335566'}
          height={20}
          width={40}
          uncheckedIcon={false}
          checkedIcon={false}
          disabled={!!selectDriverData}
          onChange={event => setIsPoolcar(event)}
          checked={isPoolcar}
        />
        <Text left='10' color='black' pointer onClick={() => !selectDriverData && setIsPoolcar(flag => !flag)}>
          Poolcar
        </Text>
      </Flex>

      {isPoolcar && <Form name={'policies'} formFields={policiesFormFields} />}
    </div>
  );
};

export default ChooseDriverDialog;
