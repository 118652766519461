import React from 'react';
import { USER_ROLES } from '../../../../../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { MyVehiclesMenu } from 'pages/my-vehicles/my-vehicles-menu/my-vehicles-menu';
import { useLeaseExtensionActions } from 'components/aufgaben-component/components/unfolded-task/components/buttons-for-lease-extension/utils';
import { CrmDataTypes } from 'common/enums';
import { useVehicleDetailsClearCash } from 'pages/existing-vehicle-page/hooks/use-clear-cash';

export const VehicleDetailCarSectionMenuModal = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const rolesDenyForModalMenu = useIsUserHaveRole(USER_ROLES.driver.id);

  const { fuhrpark, leaseEndInfo } = fleetByIdQuery;
  const isShowModalMenu = !rolesDenyForModalMenu && fuhrpark?.portalCarStatus;

  const { clearCash } = useVehicleDetailsClearCash();

  const { openLeasingContractModal, openOfferConfirmationModal } = useLeaseExtensionActions({
    plateNumber: {
      attributeTypeCode: CrmDataTypes.Lookup,
      attributeValue: {
        id: leaseEndInfo?.lva?.lva_phaseinfo?.fuhrparkId,
        logicalName: 'fuhrpark',
        name: fuhrpark?.new_name || ''
      }
    },
    currentPhase: leaseEndInfo?.lva?.lva_state,
    leaseExtensionId: leaseEndInfo?.lva?.lva_phaseinfo?.lvaId,
    taskId: leaseEndInfo?.lva?.lva_phaseinfo?.lvaId,
    phaseInfo: leaseEndInfo?.lva?.lva_phaseinfo?.specificPhaseInfo,
    files: leaseEndInfo?.lva?.lva_phaseinfo?.fileList?.Verlängerungangebot || [],
    leaseEndDate: fuhrpark?.uds_lease_return_date || '',
    refresh: clearCash
  });

  if (!isShowModalMenu) {
    return null;
  }

  return (
    <MyVehiclesMenu
      carStatus={fuhrpark.portalCarStatus}
      carId={fuhrpark.new_fuhrparkid}
      carNumber={fuhrpark.new_name}
      setStatus={clearCash}
      leasingReturnEnabled={fuhrpark.leasingruckgabeproEnable}
      phase={leaseEndInfo?.lva?.lva_state}
      openLeasingContractModal={openLeasingContractModal}
      openOfferConfirmationModal={openOfferConfirmationModal}
    />
  );
};
