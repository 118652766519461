import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { GetMailListResponseItem } from './get-mail-list.props';

export const useGetMailListQuery = (vehicleIdProps?: string) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch<ResponseModel<Array<GetMailListResponseItem>>>({
    loadingKey: `${QUERY_KEY}${vehicleId}`,
    isShowLoading: false,
    ...fleetRequestConfig.getEmailList(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery([...CASH_KEY_GET_MAIL_LIST_QUERY, vehicleId], () => fetch(), {
    enabled: !!vehicleId
  });

  return { getMailListQuery: data?.data.data || [], ...restQueryProps };
};

const QUERY_KEY = 'getMailList';

export const CASH_KEY_GET_MAIL_LIST_QUERY = [ReactQueryKeys.Logo, PAGES.MY_VEHICLES_DETAILS, QUERY_KEY];
