/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState } from 'react';
import styles from './car-info.module.scss';
import SearchReapirIcon from 'components/icons/search-repair-icon';
import { Distance } from 'common/common-components.styled';
import { getSchadenmeldenCar, getVersicherungFirms } from '../../../services/report-damage-service';
import { useOverlay, useQuery } from 'hooks';
import { Controller } from 'react-hook-form';
import { damage, carNumberValidation } from '../utils';
import { subDays } from 'date-fns';
import InfoIcon from '../icons/info-icon';
import { convertUTCToLocalDate, convertLocalToUTCDate } from '../utils';
import useWindowSize from 'hooks/use-window-size';
import TextFieldComponent from '../components/text-field-component';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledReportDamageDatePicker } from 'pages/report-damage-page/report-damage-component.styled';
import { DateBlueIcon } from 'components/icons/date-blue-icon';
import { StyledTextFieldWithoutBorders } from 'components/form-hook/form-field/form-field.styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { decodeBase64 } from 'utils/decode-encode-base64';
import { PrefixForId } from 'common/enums';
import { useHistory, useLocation } from 'react-router-dom';
import { CarInfoInterface, FirmItemProps } from './car-info.props';
import { UseFormProps } from '../report-damage.props';
import { useReportDamageIsCustomLink } from '../hooks/use-is-custom-link';

const StartCarInfo = ({ reactHookFormData }: UseFormProps) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [region, setRegion] = useState<string>('');
  const [letters, setLetters] = useState<string>('');
  const [numbers, setNumbers] = useState<string>('');
  const [carInfo, setCarInfo] = useState<[CarInfoInterface] | undefined | null>(undefined);
  const [firms, setFirms] = useState<FirmItemProps[] | null>(null);
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [showFirms, setShowFirms] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const insuranseRef = useRef<HTMLDivElement>(null);
  const { windowWidth } = useWindowSize();
  const query = useQuery();

  const history = useHistory();
  const location = useLocation();

  const {
    register,
    watch,
    control,
    clearErrors,
    setValue,
    setError,
    setFocus,
    reset,
    formState: { errors }
  } = reactHookFormData;

  useEffect(() => {
    region.length > 2 && letters === '' ? setFocus('letters') : null;
    letters.length > 1 && numbers === '' ? setFocus('numbers') : null;
  }, [watch('region'), region, watch('letters'), letters]);

  const herstellerBlock =
    (carInfo !== undefined && Array.isArray(carInfo) && !!carInfo[0].new_hersteller.attributeValue) ||
    (((Array.isArray(carInfo) && carInfo[0].new_hersteller.attributeValue === null) || carInfo === null) &&
      !!watch('new_hersteller') &&
      watch('new_hersteller').length > 0);

  const modelBlock =
    (carInfo !== undefined && Array.isArray(carInfo) && !!carInfo[0].new_model.attributeValue) ||
    (((Array.isArray(carInfo) && carInfo[0].new_model.attributeValue === null) || carInfo === null) &&
      !!watch('new_model') &&
      watch('new_model').length > 0);

  useEffect(() => {
    herstellerBlock && modelBlock && Object.keys(errors).length === 0 ? setButtonActive(true) : setButtonActive(false);
  }, [watch(), herstellerBlock, modelBlock]);

  useEffect(() => {
    if (Array.isArray(carInfo)) {
      if (carInfo[0]?.new_fuhrparkid?.attributeValue !== null) {
        setValue('new_fuhrparkid', carInfo[0]?.new_fuhrparkid?.attributeValue);
      }
    }
  }, [carInfo]);

  const searchFilter = (searchValue: string, firms: FirmItemProps[]) => {
    let lowerCaseQuery = searchValue.toLowerCase();
    let filteredList = searchValue
      ? firms.filter(
          (el: FirmItemProps) =>
            el?.name?.attributeValue && el.name.attributeValue.toLowerCase().includes(lowerCaseQuery)
        )
      : firms;
    return filteredList;
  };

  const getCarInfo = async (region: string, letters: string, numbers: string) => {
    if (region === '' && letters === '' && numbers === '') {
      setError('carNumber', {
        type: 'manual',
        message: 'Bitte gib Kennzeichen ein.'
      });
      return;
    }
    if ((region !== '' && letters !== '' && numbers !== '') || region === '' || letters === '' || numbers === '') {
      let carNumber = `${region}-${letters} ${numbers}`;
      carNumber.search(carNumberValidation) === -1
        ? setError('carNumber', {
            type: 'manual',
            message: 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).'
          })
        : clearErrors('carNumber');
    }
    if (region !== '' && letters !== '' && numbers !== '' && !errors.carNumber) {
      showOverlay('getCarInfo', true);
      let res;
      reset();
      setValue('step', 0);
      setValue('region', region);
      setValue('letters', letters);
      setValue('numbers', numbers);
      setValue('damageList', damage);
      setValue('staticDamageList', damage);
      setSearchValue('');
      try {
        res = await getSchadenmeldenCar(`${region}-${letters} ${numbers}`);
        setValue('kennzeichen', `${region}-${letters} ${numbers}`);
      } catch (err) {
        res = {};
        hideOverlay('getCarInfo');
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        const { data } = res?.data || null;

        if (!!data && data.fuhrpark !== null && Array.isArray(data?.fuhrpark)) {
          if (data?.fuhrpark[0]?.new_fuhrparkid?.attributeValue !== null) {
            setValue('new_fuhrparkid', data?.fuhrpark[0]?.new_fuhrparkid?.attributeValue);
          }
          if (data?.fuhrpark[0]?.new_fuhrparkid?.attributeValue !== null) {
            setValue('kennzeichen', data?.fuhrpark[0]?.new_name?.attributeValue);
            setValue('numbers', data?.fuhrpark[0]?.new_name?.attributeValue.split(' ')[1]);
            setNumbers(data?.fuhrpark[0]?.new_name?.attributeValue.split(' ')[1]);
          }
          if (data?.fuhrpark[0]?.new_hersteller?.attributeValue !== null) {
            setValue('new_hersteller', data?.fuhrpark[0]?.new_hersteller?.attributeValue);
          }
          if (data?.fuhrpark[0]?.new_model?.attributeValue !== null) {
            setValue('new_model', data?.fuhrpark[0]?.new_model?.attributeValue);
          }
          if (data?.fuhrpark[0]?.new_ezl?.attributeValue !== null) {
            setValue('new_ezl_value', data?.fuhrpark[0]?.new_ezl?.attributeValue);
          }
          if (data?.fuhrpark[0]?.new_fin?.attributeValue !== null) {
            setValue('new_fin', data?.fuhrpark[0]?.new_fin?.attributeValue);
          }
        }
        setCarInfo(data?.fuhrpark);
        setShowFirms(true);
        hideOverlay('getCarInfo');
        setValue('fahrerInfo', data?.fahrer !== null ? data?.fahrer[0] : null);
        setValue('fuhrparkInfo', data?.fuhrpark !== null ? data?.fuhrpark[0] : null);
        setValue('fahrerInsurance', data?.versicherer !== null ? data?.versicherer[0] : null);
        if (
          data?.fuhrpark !== null &&
          !!watch('fuhrparkInfo')?.new_hersteller?.attributeValue &&
          !!watch('fuhrparkInfo')?.new_model?.attributeValue &&
          !!watch('fuhrparkInfo')?.new_fin?.attributeValue &&
          !!watch('fuhrparkInfo')?.new_ezl?.attributeValue &&
          data?.versicherer !== null &&
          !!watch('fahrerInsurance')?.accountid?.attributeValue &&
          !!watch('fahrerInsurance')?.name?.attributeValue
        ) {
          moveToNextStep();
        }
      }
    }
  };

  const getFirms = async () => {
    showOverlay('getFirms', true);
    let res;
    try {
      res = await getVersicherungFirms();
    } catch (err) {
      res = {};
      hideOverlay('getFirms');
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setFirms(res?.data?.data?.versicherungFirms);
      setValue('allFirmsData', res?.data?.data?.versicherungFirms);
      hideOverlay('getFirms');
    }
  };

  const parseLicensePlate = () => {
    if (query.data) {
      const licensePlate = JSON.parse(decodeBase64(query.data as string)).carNumber;

      history.replace(location.pathname);
      if (!/^[A-Z,Ä,Ö,Ü]{1,3}-[A-Z,Ä,Ö,Ü]{1,2} \d{1,4}([H,E]{1})?$/.test(licensePlate)) {
        return;
      }
      const region = licensePlate.split('-')[0] || '';
      const letters = licensePlate.split('-')[1]?.split(' ')[0] || '';
      const numbers = licensePlate.split('-')[1]?.split(' ')[1] || '';
      setRegion(region);
      setNumbers(numbers);
      setLetters(letters);
    }
  };

  useEffect(() => {
    getFirms();
    parseLicensePlate();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (dropdownRef.current?.contains(e.target as Node)) {
      return;
    }
    setVisible(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    // eslint-disable-next-line max-len
    if (
      firms
        ?.map((el: FirmItemProps) => el?.name?.attributeValue)
        .find((el: string | null) => el !== null && el.toLowerCase() === searchValue.toLowerCase())
    ) {
      clearErrors('firma');
    }
    if (firms && searchFilter(e.target.value.toUpperCase(), firms).length === 0) {
      setError('firma', {
        type: 'manual',
        message: 'Firma erfordelich.'
      });
    }
    if (firms && searchFilter(e.target.value, firms).length > 0) {
      clearErrors('firma');
    }
    if (!visible) {
      setVisible(true);
    }
  };

  const selectItem = (item: string | FirmItemProps) => {
    if (item !== 'newFirm' && typeof item !== 'string' && item?.name) {
      setValue('firma', item);
      const newValue = (item as FirmItemProps)?.name?.attributeValue;
      setSearchValue(newValue ? newValue : '');
      setVisible(false);
    }
    if (item === 'newFirm') {
      setValue('firma', item);
      setVisible(false);
      setSearchValue('Neue firma');
    }
  };
  const symbolsValidation = /^[a-zA-Z0-9_\-\.]{1,100}$/;

  const { isReportDamageCustomLink } = useReportDamageIsCustomLink();

  useEffect(() => {
    if (watch('newFirmNumber')) {
      const newFin = watch('newFirmNumber').toUpperCase();
      setValue('newFirmNumber', newFin);
      if (newFin.search(symbolsValidation) === -1) {
        setError('newFirmNumber', {
          type: 'manual',
          message: 'Das Feld darf nur Zahlen und Buchstaben enthalten.'
        });
      } else {
        clearErrors('newFirmNumber');
      }
    }
    if (watch('newFirmNumber') === '') {
      clearErrors('newFirmNumber');
    }
  }, [watch('newFirmNumber')]);

  useEffect(() => {
    if (watch('new_fin')) {
      const newFin = watch('new_fin').toUpperCase();
      setValue('new_fin', newFin);
      if (newFin.search(symbolsValidation) === -1 && !!carInfo && Array.isArray(carInfo[0]) && !carInfo[0]?.new_fin) {
        setError('new_fin', {
          type: 'manual',
          message: 'Das Feld darf nur Zahlen und Buchstaben enthalten.'
        });
      } else {
        clearErrors('new_fin');
      }
    }
    if (watch('new_fin') === '') {
      clearErrors('new_fin');
    }
  }, [watch('new_fin')]);

  useEffect(() => {
    if (region !== '' && letters !== '' && numbers !== '') {
      let carNumber = `${region}-${letters} ${numbers}`;
      carNumber.search(carNumberValidation) === -1
        ? setError('carNumber', {
            type: 'manual',
            message: 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).'
          })
        : clearErrors('carNumber');
    }
  }, [watch('region'), watch('letters'), watch('numbers'), region, letters, numbers]);

  const insuranceClick = () => {
    windowWidth > 900 && executeScroll(insuranseRef);
    setSearchValue('');
  };

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => scrollToRef(ref);

  const moveToNextStep = () => {
    window?.scrollTo(0, 0);
    document.getElementById('unauthorizedPageContainer')?.scrollTo(0, 0);
    document.getElementById('SMform')?.scrollTo(0, 0);
    document.getElementById('UPcontentConatiner')?.scrollTo(0, 0);
    document.getElementById('detailBlockView')?.scrollTo(0, 0);
    setValue('step', 1);
    setValue('direction', 'forward');
  };

  return (
    <section className={!showFirms ? styles.container : styles.container_center}>
      <h5>Bitte gib dein Kennzeichen ein, um den Schaden zu melden.</h5>
      <span>z.B. BO - MO 1234 oder BO - MO 1234E für Elektro- und Hybridfahrzeuge</span>
      <div className={styles.kennzeichen_block}>
        <div className={styles.inputs_block}>
          <input
            className={styles.input_first}
            type='text'
            value={region}
            {...register('region', {
              required: true,
              maxLength: 3
            })}
            onChange={e => setRegion(e.target.value.toUpperCase().trim())}
            placeholder='XXX'
            autoComplete='nope'
            maxLength={3}
            id='licensePlateInput1'
          />{' '}
          -
          <input
            className={styles.input_second}
            type='text'
            value={letters}
            {...register('letters', {
              required: true,
              maxLength: 2
            })}
            onChange={e => setLetters(e.target.value.toUpperCase().trim())}
            placeholder='XX'
            autoComplete='nope'
            maxLength={2}
            id='licensePlateInput2'
          />{' '}
          <input
            className={styles.input_third}
            type='text'
            value={numbers}
            {...register('numbers', {
              required: true,
              maxLength: 5
            })}
            onChange={e => setNumbers(e.target.value.toUpperCase().trim())}
            placeholder='XXXXX'
            maxLength={5}
            autoComplete='nope'
            onKeyDown={ev => {
              if (ev.keyCode == 13) {
                getCarInfo(region, letters, numbers);
              }
            }}
            id='licensePlateInput3'
          />
          <div
            id='licensePlateButton'
            onClick={() => getCarInfo(region, letters, numbers)}
            className={styles.search_icon}
          >
            <SearchReapirIcon />
          </div>
        </div>
        <div className={styles.err__row}>
          {errors?.carNumber && typeof errors?.carNumber?.message === 'string' && (
            <>
              <img src='/assets/images/err-icon.svg' />
              <p>{errors?.carNumber?.message}</p>
            </>
          )}
        </div>
      </div>
      {carInfo === null && (
        <>
          <Distance top='-30' />
          <div className={styles.new_car_info}>
            <div className={styles.icon}>
              <InfoIcon />
            </div>

            <p>
              Hoppla! Leider ist uns das genannte Kennzeichen nicht bekannt. Bitte überprüfe es und gib es erneut sein.
              Ist dein Auto z.B. neu und uns deshalb nicht bekannt, kannst du es selbst in wenigen Schritten anlegen.
            </p>
          </div>
        </>
      )}
      {carInfo !== undefined && (Array.isArray(carInfo) || carInfo === null) && (
        <>
          <div className={styles.data_fields}>
            {(carInfo === null ||
              !carInfo[0]?.new_hersteller?.attributeValue ||
              !carInfo[0]?.new_model?.attributeValue) && (
              <>
                <h6>Fahrzeugdaten:</h6>

                {(carInfo === null || !carInfo[0]?.new_hersteller?.attributeValue) && (
                  <>
                    <div className={styles.form_name}>
                      <span>Hersteller</span>
                    </div>
                    <input
                      placeholder='z.B. BMW'
                      {...register('new_hersteller', {
                        required: 'Hersteller erforderlich',
                        maxLength: 100
                      })}
                      type='text'
                      maxLength={100}
                      autoComplete='nope'
                      className={errors?.new_hersteller ? styles.input__icons_err__text : styles.input_standart}
                      id='inputManufacturerRequired'
                    />
                    <div className={styles.err__row}>
                      {errors?.new_hersteller && typeof errors?.new_hersteller?.message === 'string' && (
                        <>
                          <img src='/assets/images/err-icon.svg' />
                          <p>{errors?.new_hersteller?.message}</p>
                        </>
                      )}
                    </div>

                    <Distance top='15' />
                  </>
                )}

                {(carInfo === null || !carInfo[0]?.new_model?.attributeValue) && (
                  <>
                    <div className={styles.form_name}>
                      <span>Modell</span>
                    </div>
                    <input
                      placeholder='z.B. 3er'
                      {...register('new_model', {
                        required: 'Model erforderlich',
                        maxLength: 100
                      })}
                      type='text'
                      maxLength={100}
                      autoComplete='nope'
                      className={errors?.new_hersteller ? styles.input__icons_err__text : styles.input_standart}
                      id='inputModel'
                    />
                    <div className={styles.err__row}>
                      {errors?.new_model && typeof errors?.new_model?.message === 'string' && (
                        <>
                          <img src='/assets/images/err-icon.svg' />
                          <p>{errors?.new_model?.message}</p>
                        </>
                      )}
                    </div>

                    <Distance top='15' />
                  </>
                )}
              </>
            )}

            {(carInfo === null || !carInfo[0]?.new_fin?.attributeValue || !carInfo[0]?.new_ezl?.attributeValue) &&
              windowWidth >= 900 && (
                <div className={styles.row_columns}>
                  {(carInfo === null || !carInfo[0]?.new_fin?.attributeValue) && (
                    <TextFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='new_fin'
                      fieldName='Fahrgestellnummer'
                      fieldPlaceholder='z.B. AA1234567890ER123'
                      optional={true}
                      upperCase={true}
                      doubleField={carInfo === null || (carInfo !== null && !carInfo[0]?.new_ezl?.attributeValue)}
                    />
                  )}

                  {(carInfo === null || !carInfo[0]?.new_ezl?.attributeValue) && (
                    <div
                      className={
                        carInfo !== null && !!carInfo[0]?.new_fin?.attributeValue ? styles.full_field : styles.one_field
                      }
                    >
                      <div className={styles.form_name}>
                        <span>
                          Erstzulassung <span className={styles.optional}>(optional)</span>
                        </span>
                      </div>
                      <Distance top='5' />
                      <Controller
                        control={control}
                        name='new_ezl'
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <StyledReportDamageDatePicker
                              components={{
                                OpenPickerIcon: () => (
                                  <DateBlueIcon id={`firstRegistration${PrefixForId.FormDate}${PrefixForId.Open}`} />
                                )
                              }}
                              PopperProps={{
                                id: `firstRegistration${PrefixForId.FormDate}${PrefixForId.Calendar}`
                              }}
                              minDate={subDays(new Date(), 26500)}
                              value={value ? convertUTCToLocalDate(value) : null}
                              onChange={date => {
                                onChange(convertLocalToUTCDate(date));
                              }}
                              renderInput={({ inputProps, ...restParams }) => (
                                <StyledTextFieldWithoutBorders
                                  {...restParams}
                                  error={false}
                                  inputProps={{
                                    ...inputProps,
                                    id: `firstRegistration${PrefixForId.FormDate}`,
                                    placeholder: 'TT . MM . JJJJ'
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

            {windowWidth < 900 && (carInfo === null || !carInfo[0]?.new_fin?.attributeValue) && (
              <TextFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='new_fin'
                fieldName='Fahrgestellnummer'
                fieldPlaceholder='z.B. AA1234567890ER123'
                optional={true}
                upperCase={true}
              />
            )}

            {windowWidth < 900 && (carInfo === null || !carInfo[0]?.new_ezl?.attributeValue) && (
              <div className={styles.full_field}>
                <div className={styles.form_name}>
                  <span>
                    Erstzulassung <span className={styles.optional}>(optional)</span>
                  </span>
                </div>
                <Distance top='5' />
                <Controller
                  control={control}
                  name='new_ezl'
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <StyledReportDamageDatePicker
                        components={{
                          OpenPickerIcon: () => (
                            <DateBlueIcon id={`firstRegistration${PrefixForId.FormDate}${PrefixForId.Open}`} />
                          )
                        }}
                        PopperProps={{
                          id: `firstRegistration${PrefixForId.FormDate}${PrefixForId.Calendar}`
                        }}
                        minDate={subDays(new Date(), 26500)}
                        value={value ? convertUTCToLocalDate(value) : null}
                        onChange={date => {
                          onChange(convertLocalToUTCDate(date));
                        }}
                        renderInput={({ inputProps, ...restParams }) => (
                          <StyledTextFieldWithoutBorders
                            {...restParams}
                            error={false}
                            inputProps={{
                              ...inputProps,
                              id: `firstRegistration${PrefixForId.FormDate}`,
                              placeholder: 'TT . MM . JJJJ'
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            )}
          </div>

          <Distance top='30' />
        </>
      )}

      {showFirms && (
        <>
          {(!watch('fahrerInsurance') || !watch('fahrerInsurance')?.accountid?.attributeValue) && (
            <div className={styles.data_fields}>
              {watch('firma') !== 'newFirm' && (
                <>
                  <h6>Versicherunsgdaten:</h6>

                  <div className={styles.form_name}>
                    <span>
                      Versicherungsname <span className={styles.optional}>(optional)</span>
                    </span>
                  </div>
                  <div className={styles.input__block} ref={insuranseRef}>
                    <div tabIndex={0}>
                      <input
                        className={errors.firma ? styles.input__icons__drop_err : styles.input__icons__drop}
                        type='text'
                        placeholder='Bitte auswählen'
                        value={watch('firma') === 'newFirm' ? '+ Neue Versicherungsname hinzufügen' : searchValue}
                        onChange={handleChange}
                        onClick={insuranceClick}
                        onFocus={() => {
                          setVisible(true);
                        }}
                        id='inputInsuranceData'
                      />
                    </div>
                    <div ref={dropdownRef} className={`${styles.dropdown} ${visible ? styles.v : ''}`}>
                      {visible && (
                        <ul id='inputDropdownInsuranceData'>
                          <li
                            key='newFirm'
                            id='inputDropdownAddNewFirmInsuranceData'
                            onClick={() => selectItem('newFirm')}
                            className={styles.dropdown_li_always}
                          >
                            <div className={styles.item_text1}>+ Neue Versicherungsname hinzufügen</div>
                          </li>
                          {firms &&
                            searchFilter(searchValue, firms).map((el: FirmItemProps, index: number) => (
                              <li
                                key={el?.accountid?.attributeValue}
                                onClick={() => selectItem(el)}
                                className={styles.dropdown_li}
                                id={`inputDropdownItemInsuranceData${index}`}
                              >
                                <div className={styles.item_text1}>{el?.name?.attributeValue}</div>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </>
              )}

              {watch('firma') === 'newFirm' && (
                <div className={styles.new_firma_block}>
                  <Distance top='30' />
                  <h6>Versicherunsgdaten:</h6>

                  <div className={styles.form_name}>
                    <span>
                      Versicherungsname <span className={styles.optional}>(optional)</span>
                    </span>
                  </div>
                  <div className={styles.input__block} ref={insuranseRef}>
                    <div tabIndex={0}>
                      <input
                        className={errors.firma ? styles.input__icons__drop_err : styles.input__icons__drop}
                        type='text'
                        placeholder='Bitte auswählen'
                        value={watch('firma') === 'newFirm' ? '+ Neue Versicherungsname hinzufügen' : searchValue}
                        onChange={handleChange}
                        onClick={insuranceClick}
                        onFocus={() => {
                          setVisible(true);
                        }}
                        id='inputInsuranceName'
                      />
                    </div>
                    <div ref={dropdownRef} className={`${styles.dropdown} ${visible ? styles.v : ''}`}>
                      {visible && (
                        <ul id='inputDropdownInsuranceName'>
                          <li
                            key='newFirm'
                            id='inputDropdownAddNewFirmInsuranceName'
                            onClick={() => selectItem('newFirm')}
                            className={styles.dropdown_li_always}
                          >
                            <div className={styles.item_text1}>+ Neue Versicherungsname hinzufügen</div>
                          </li>
                          {firms &&
                            searchFilter(searchValue, firms).map((el: FirmItemProps, index: number) => (
                              <li
                                key={el?.accountid?.attributeValue}
                                onClick={() => selectItem(el)}
                                className={styles.dropdown_li}
                                id={`inputDropdownItemInsuranceData${index}`}
                              >
                                <div className={styles.item_text1}>{el?.name?.attributeValue}</div>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <Distance top='30' />

                  <div className={styles.form_name}>
                    <span>
                      Versicherungsname <span className={styles.optional}>(optional)</span>
                    </span>
                  </div>
                  <input
                    placeholder='MusterVersicherung GmbH'
                    {...register('newFirmName', {
                      required: false
                    })}
                    type='text'
                    maxLength={100}
                    autoComplete='nope'
                    className={styles.input_standart}
                    id='inputInsuranceName'
                  />

                  <Distance top='15' />

                  <div className={styles.form_name}>
                    <span>
                      Versicherungsschein-Nr. <span className={styles.optional}>(optional)</span>
                    </span>
                  </div>
                  <input
                    placeholder='0000AAA000'
                    {...register('newFirmNumber', {
                      required: false
                    })}
                    type='text'
                    maxLength={100}
                    autoComplete='nope'
                    className={styles.input_standart}
                    id='inputInsuranceCertificateNumber'
                  />
                  <div className={styles.err__row}>
                    {errors?.newFirmNumber && typeof errors?.newFirmNumber?.message === 'string' && (
                      <>
                        <img src='/assets/images/err-icon.svg' />
                        <p>{errors?.newFirmNumber?.message}</p>
                      </>
                    )}
                  </div>

                  <Distance top='15' />
                </div>
              )}
            </div>
          )}

          <div className={styles.button_block}>
            <button
              onClick={() => buttonActive && moveToNextStep()}
              className={buttonActive ? styles.button_active : styles.button_wait}
              id='moveToNextStepButton'
            >
              Weiter
            </button>
          </div>
        </>
      )}

      {!isReportDamageCustomLink && (
        <footer className={styles.mobexo_link}>
          <a href='https://www.mobexo.de' target='blank' id='backToPortalButton'>
            Zurück zur Webseite
          </a>
        </footer>
      )}
    </section>
  );
};

export default StartCarInfo;
