import React, { useState } from 'react';
import { AccordionTabProps, IAccordionContext } from './accordion.props';
import { AccordionTabContext } from './accordion.context';
import { Flex } from 'common/common-components.styled';
import { AccordionItem } from './components/accordion-item';

export const AccordionTab = ({ data, ui, ...restProps }: AccordionTabProps) => {
  const [activeTabIndexList, setActiveTabIndexList] = useState<Array<number>>([]);

  const toggleTab = (index: number) => {
    if (activeTabIndexList.includes(index)) {
      return setActiveTabIndexList(prevState => prevState.filter(item => item !== index));
    }

    setActiveTabIndexList(prevState => {
      const clonePrevState = [...prevState];
      clonePrevState.push(index);
      return clonePrevState;
    });
  };

  const contextValue: IAccordionContext = {
    activeTabIndexList,
    toggleTab,
    data,
    ui,
    ...restProps
  };

  return (
    <AccordionTabContext.Provider value={contextValue}>
      <Flex direction='column' width='100%'>
        {data.map((item, index) => (
          <AccordionItem key={index} isActive={activeTabIndexList.includes(index)} tabIndex={index} {...item} />
        ))}
      </Flex>
    </AccordionTabContext.Provider>
  );
};
