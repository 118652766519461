import React from 'react';
import PropTypes from 'prop-types';

export const UserCardIcon = ({ width = '20', height = '21', color = '', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 16 12" fill={color ? color : 'none'} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.25049 4.0737H10.7505" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25049 5.93036H13.8755" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25049 8.00433H13.8755" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9942 10.9987H14.5323C15.0645 10.9987 15.5 10.5632 15.5 10.031V2.04711C15.5 1.51485 15.0645 1.07936 14.5323 1.07936H1.46774C0.935484 1.07936 0.5 1.51485 0.5 2.04711V10.031C0.5 10.5632 0.935484 10.9987 1.46774 10.9987H10.4965" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.12451 8.13984L3.09225 7.81726C3.09225 7.81726 3.64871 8.13984 4.38258 8.13984C5.11645 8.13984 5.6729 7.81726 5.6729 7.81726L6.64064 8.13984" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.05001 6.77112C5.32421 6.60983 5.52766 6.3657 5.58411 5.84958L5.67282 5.05926C5.77766 4.21248 4.87329 3.92767 4.43781 3.92767C4.00232 3.92767 3.10921 4.21248 3.21405 5.05926L3.30276 5.84958C3.35921 6.3657 3.52672 6.60983 3.80092 6.77112" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

UserCardIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
