export enum STEPS {
  InvoiceTable,
  InvoiceCreation,
  InvoiceEdit
}

export const DOC_MANAGEMENT_CRM_VALUE = {
  //rechnungNummer: 'Rechnungsnummer',
  name: 'Name',
  status: 'Status',
  createdon: 'createdon',
  modifiedon: 'ModifiedOn',
  documentType: 'DocumentType',
  fahrzeug: 'Fahrzeug',
  modifiedBy: 'ModifiedBy',
  bemerkung: 'Bemerkung'
};

export const emtyFilterState = {
  [DOC_MANAGEMENT_CRM_VALUE.documentType]: null,
  [DOC_MANAGEMENT_CRM_VALUE.fahrzeug]: null,
  [DOC_MANAGEMENT_CRM_VALUE.modifiedBy]: null,
  [DOC_MANAGEMENT_CRM_VALUE.modifiedon]: null,
  [DOC_MANAGEMENT_CRM_VALUE.status]: null,
  [DOC_MANAGEMENT_CRM_VALUE.name]: null,

  [DOC_MANAGEMENT_CRM_VALUE.createdon]: null
};
