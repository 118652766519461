import Form from 'components/form';
import React from 'react';
import { formFields } from 'components/monatsrechnungen/formFields/formFields';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../constants';

export const useCreateQuestionModal = () => {
  const dispatch = useDispatch();

  return (action: Function) => {
    const openCreateNewTaskModal = () => {
      const alertData = {
        title: 'Neue Frage',
        children: <Form name='frage' formFields={formFields} />,
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert
          },
          {
            type: 'submit',
            title: 'Speichern',
            action
          }
        ]
      };
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    };

    const closeAlert = () => {
      dispatch(toggleModal(MODALS.alert, null));
    };

    return openCreateNewTaskModal();
  };
};
