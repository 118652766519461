import { AxiosRequestConfig } from 'axios';
import { IPagination } from 'hooks/use-table/use-table.props';
import {
  CheckInspectionDateDataToSend,
  ConnectInspectionDateToCarParams,
  CreateInspectionDateDataToSend,
  DeactivateInspectionDateDataToSend,
  DisconnectInspectionDateFromCarParams,
  GetCarInspectionDateParams,
  UpdateHistoryInspectionDateParams,
  UpdateInspectionDateDataToSend
} from './inspection-dates.types';

export class InspectionDatesRequestConfig {
  getAllInspectionDates = (accountId: string, pagination: IPagination): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/getAllPruftermine',
    data: { accountId, pagination }
  });

  createInspectionDate = (data: CreateInspectionDateDataToSend): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/createPruftermine',
    data
  });

  updateInspectionDate = (data: UpdateInspectionDateDataToSend): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/updatePruftermine',
    data
  });

  hasChildrenInspectionDates = (data: CheckInspectionDateDataToSend): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/hasChildrenPruftermine',
    data
  });

  deactivateInspectionDates = (data: DeactivateInspectionDateDataToSend): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/deactivatePruftermine',
    data
  });

  getInspectionDateHistoryByTaskId = (taskId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/GetPruftermineHistoryByTaskId',
    data: { taskId }
  });

  getCarInspectionDate = (data: GetCarInspectionDateParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/GetCarPruftermine',
    data
  });
  connectInspectionDateToCar = (data: ConnectInspectionDateToCarParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/AssociatePruftermineToCar',
    data
  });

  disconnectInspectionDateFromCar = (data: DisconnectInspectionDateFromCarParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/DisassociatePruftermineToCar',
    data
  });

  updateHistory = (data: UpdateHistoryInspectionDateParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'pruftermine/UpdatePruftermineHistory',
    data
  });
}

export const inspectionDatesRequestConfig = new InspectionDatesRequestConfig();
