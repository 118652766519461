import { getInfoForCreateTask } from 'components/aufgaben-component/utils';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { RequestStatuses } from '../../../../constants';
import { useState, useEffect } from 'react';
import { ProductInfoProps } from 'components/product/product-view.props';

interface employeesProps {
  id: string;
  isAccount: boolean;
  isFahrerRole: boolean;
  isFuhrparkleiterRole: boolean;
  logicalName: string;
  name: string;
  isEmailValid: boolean;
  isCarDriver?: boolean;
}

const convertEmployees = (employees: employeesProps[]) => {
  return employees.map(employee => {
    return {
      label: employee.name,
      value: employee.id,
      showEndAdornment: !employee.isEmailValid,
      disabled: !employee.isEmailValid
    };
  });
};

export const useEmployeesQuery = (isTaskRVO: boolean, vehicleId?: string) => {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [availableEmployees, setAvailableEmployees] = useState<ProductInfoProps[]>([]);

  const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
    if (data.level === RequestStatuses.Success) {
      const responseArray = isTaskRVO
        ? data.data.contacts.filter((contact: employeesProps) => contact.isFuhrparkleiterRole || contact.isCarDriver)
        : data.data.contacts;
      return convertEmployees(responseArray);
    }
    return [];
  };

  const {
    data: employeesOptions,
    isLoading: isEmployeesLoading,
    refetch
  } = useQuery(['employees-for-tasks', vehicleId], () => getInfoForCreateTask(vehicleId), {
    select: modifyResponse,
    enabled: !isRequestSent || isTaskRVO
  });

  useEffect(() => {
    if (!isRequestSent && employeesOptions) {
      setAvailableEmployees(employeesOptions);
      setIsRequestSent(true);
    }
  }, [employeesOptions]);

  useEffect(() => {
    if (isTaskRVO) {
      refetch();
    }
  }, [isTaskRVO]);

  return {
    employeesOptions: employeesOptions || availableEmployees,
    isEmployeesLoading: isEmployeesLoading && !isTaskRVO
  };
};
