import { DeleteDocumentHandler } from '../upload-file/upload-file.props';

export interface FileProps {
  description?: string;
  fileId?: string;
  filePath: string;
  fileSize?: number;
  lastModified?: string | Date;
  checked?: boolean;
}

export interface FileInfoProps {
  file: FileProps;
  allFiles?: FileProps[];
  showCheckboxes?: boolean;
  fileCheckboxChanged?: Function;
  isHideFileViewer?: boolean;
  className?: string;
  deleteHandler?: DeleteDocumentHandler;
  type?: FileInfoType;
  onDownload?: (filePath: string) => void;
  showLetterModal?: (id: string) => void;
}

export enum FileInfoType {
  Checkbox = 'checkbox',
  Download = 'download',
  Simple = 'simple'
}
