import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../constants';
import { ConsumptionWidgetVariantType } from '../consumption.props';
import useWindowSize from 'hooks/use-window-size';

export const useConsumptionWidgetVariant = (): Record<'widgetVariant', ConsumptionWidgetVariantType> => {
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const { windowWidth } = useWindowSize(500);

  return { widgetVariant: isDriver || windowWidth <= 550 ? 'normal' : 'small' };
};
