import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DocumentIcon = ({ width = 15, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 15 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M13.8265 4.5129C13.9862 4.67984 14.0805 4.89758 14.0805 5.12984V16.0915V17.8182C14.0805 18.4569 13.5579 18.9795 12.9192 18.9795L8.12889 19H2.17728C1.53857 19 1.01599 18.4774 1.01599 17.8387V2.16129C1.01599 1.52258 1.53857 1 2.17728 1H9.22487C9.92164 1 10.5821 1.26855 11.0757 1.7621L13.8265 4.5129Z'
          fill={fill}
        />
      )}
      <path d='M10.0562 5.19866L10.0562 1.12353L14.2364 5.19866H10.0562Z' fill={hovered ? 'white' : 'none'} />
      <path
        d='M14.0805 13.001V5.12984C14.0805 4.89758 13.9862 4.67984 13.8265 4.5129L11.0757 1.7621C10.5821 1.26855 9.92164 1 9.22487 1H2.17728C1.53857 1 1.01599 1.52258 1.01599 2.16129V17.8387C1.01599 18.4774 1.53857 19 2.17728 19H8.12889L12.9192 18.9795C13.5579 18.9795 14.0805 18.4569 14.0805 17.8182V16.0915'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0805 5.34762H10.5966C10.2773 5.34762 10.016 5.08633 10.016 4.76697V3.60568'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.53198 7.96048H10.8868'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.53198 15.177H10.8868'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.4998 8.25072C4.66014 8.25072 4.79012 8.12074 4.79012 7.9604C4.79012 7.80006 4.66014 7.67007 4.4998 7.67007C4.33945 7.67007 4.20947 7.80006 4.20947 7.9604C4.20947 8.12074 4.33945 8.25072 4.4998 8.25072Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M4.4998 15.4674C4.66014 15.4674 4.79012 15.3374 4.79012 15.177C4.79012 15.0167 4.66014 14.8867 4.4998 14.8867C4.33945 14.8867 4.20947 15.0167 4.20947 15.177C4.20947 15.3374 4.33945 15.4674 4.4998 15.4674Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M6.53198 11.5688H10.8868'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.4998 11.8591C4.66014 11.8591 4.79012 11.7291 4.79012 11.5688C4.79012 11.4084 4.66014 11.2784 4.4998 11.2784C4.33945 11.2784 4.20947 11.4084 4.20947 11.5688C4.20947 11.7291 4.33945 11.8591 4.4998 11.8591Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );
};
