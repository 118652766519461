import React, { useState } from 'react';
import { DayContainer } from './calendar-view-styled';
import moment, { Moment } from 'moment';
import { AnyObject } from 'common/interfaces';
import { Car, Event } from './calendar-view';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { RecordStatuses } from './bookings-page';
import useNavigationUrl from 'components/pool-car-booking/hooks/use-navigation-url';

interface Props {
  car: Car;
  rowHeight: number;
  day: Moment;
  timeGap: number;
}

const DayOfWEek = ({ car, rowHeight, day, timeGap }: Props) => {
  const [showPlusIcon, setShowPlusIcon] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const navigationURL = useNavigationUrl();

  const getAbilityToBookCar = (day: Moment) => {
    if (moment(day).startOf('day').valueOf() >= moment().startOf('day').valueOf()) {
      const sortedCarEvents = car.events.sort(
        (a: Event, b: Event) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()
      );

      const eventForSpecificDay = sortedCarEvents.find((event: AnyObject) =>
        moment(day.startOf('day')).isBetween(
          moment(event.startDate).subtract(timeGap, 'minutes').startOf('day'),
          moment(event.endDate).add(timeGap, 'minutes').startOf('day'),
          undefined,
          '[]'
        )
      );

      return !eventForSpecificDay && !!car.isShowPlusButton;
    } else {
      return false;
    }
  };

  const detectHover = (day: Moment, mouseEnterEvent: boolean) => {
    if (!mouseEnterEvent) {
      setShowPlusIcon(false);
      return;
    }
    const isBookingavailable = getAbilityToBookCar(day);
    if (isBookingavailable && mouseEnterEvent) {
      setShowPlusIcon(true);
    }
  };

  const bookCar = () => {
    const isBookingavailable = getAbilityToBookCar(day);
    const nextEvent = car.events
      .filter((event: Event) => moment(event.startDate).startOf('day').valueOf() > moment(day).startOf('day').valueOf())
      .filter((event: Event) =>
        [RecordStatuses.APPROVED_RECORD, RecordStatuses.OPENED_RECORD].includes(event.uds_status_code)
      )?.[0];
    if (isBookingavailable) {
      const isToday = moment().isSame(moment(day), 'day');
      const startDate = isToday ? moment().valueOf() : day.startOf('day').valueOf();
      const maxDate = nextEvent ? moment(nextEvent.startDate).subtract(timeGap, 'minutes').valueOf() : null;
      history.push(`${navigationURL}/neue-buchung?startDate=${startDate}&maxDate=${maxDate}&carId=${car.id}`);
    }
  };

  return (
    <DayContainer
      height={rowHeight}
      disabled={!getAbilityToBookCar(day)}
      onMouseEnter={() => detectHover(day, true)}
      onMouseLeave={() => detectHover(day, false)}
      onClick={bookCar}
    >
      {showPlusIcon && <PlusLargeIcon color={theme.colors.blue} />}
    </DayContainer>
  );
};

export default DayOfWEek;
