import { PickValue } from 'common/interfaces';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { GetCarInspectionDateResponseItem } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';
import moment from 'moment';

export const useMyVehicleIsTaskOverdueOnSelectInitRegistration = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const getIsTaskOverdue = ({
    uds_inspection_intervalcode
  }: PickValue<GetCarInspectionDateResponseItem, 'attributes'>) => {
    if (fuhrpark?.new_ezl && uds_inspection_intervalcode?.label) {
      const firstRegistrationDate = moment(fuhrpark.new_ezl, 'YYYY-MM-DD');
      const testIntervalInMonth = Number(uds_inspection_intervalcode.label.replace(' Monate', ''));

      if (isNaN(testIntervalInMonth)) {
        console.error('"testIntervalInMonth" IS NOT A NUMBER - ', {
          testIntervalInMonth,
          uds_inspection_intervalcode
        });

        return false;
      }

      return firstRegistrationDate.add(testIntervalInMonth, 'month').isSameOrBefore(moment());
    }

    console.error('SOME DATA IS NOT EXIST - ', {
      new_ezl: fuhrpark?.new_ezl,
      uds_inspection_intervalcode
    });

    return false;
  };

  return { getIsTaskOverdue };
};
