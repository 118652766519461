import React from 'react';
import { EquipmentItemsProps, EquipmentItemsValuesProps } from '../details-view';
import OptionalEquipmentItem from './optional-equipment-item';
import { Flex, Text } from 'common/common-components.styled';
import {
  StyledCodeCell,
  StyledDescriptionCell,
  StyledListPriceCell,
  StyledNetListPriceCell
} from './optional-equipment-items.styled';
import toMoney from 'utils/toMoney';

const OptionalEquipmentItems = ({ data }: EquipmentItemsProps) => {
  const sumNetListPrice = data.reduce((accumulator, item) => accumulator + (item.new_preis_netto || 0), 0);
  const sumListPrice = data.reduce((accumulator, item) => accumulator + (item.uds_preis_brutto || 0), 0);

  return (
    <div>
      <Flex>
        <StyledCodeCell>
          <Text bold>Code</Text>
        </StyledCodeCell>
        <StyledDescriptionCell>
          <Text bold>Bezeichnung</Text>
        </StyledDescriptionCell>
        <StyledNetListPriceCell>
          <Text bold>Listenpreis (Netto)</Text>
        </StyledNetListPriceCell>
        <StyledListPriceCell>
          <Text bold>Listenpreis (Brutto)</Text>
        </StyledListPriceCell>
      </Flex>
      {data.map((item: EquipmentItemsValuesProps) => (
        <OptionalEquipmentItem key={item.new_fahrzeugangeboteausstattungid} item={item} />
      ))}
      <Flex bottom='20'>
        <StyledCodeCell>
          <Text bold color='grey800'>
            Gesamtsumme
          </Text>
        </StyledCodeCell>
        <StyledDescriptionCell />
        <StyledNetListPriceCell>{sumNetListPrice ? toMoney(sumNetListPrice) : '-'}</StyledNetListPriceCell>
        <StyledListPriceCell>
          <Text bold color='grey800'>
            {sumListPrice ? toMoney(sumListPrice) : '-'}
          </Text>
        </StyledListPriceCell>
      </Flex>
    </div>
  );
};

export default OptionalEquipmentItems;
