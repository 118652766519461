import {useEffect, useRef} from 'react';


const useLatestValue = (value, dependences = []) => {
  const changingValue = useRef(value);

  useEffect(() => {
    changingValue.current = value;
  }, [value, ...dependences]);

  return changingValue;
};

export default useLatestValue;
