import React from 'react';
import classes from './go-to-all-tasks.module.scss';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import DownArrow from 'components/icons/down_arrow';
import { useTheme } from 'styled-components';

export const GoToAllTasksButton = () => {
  const history = useHistory();
  const theme = useTheme();

  const onClickHandler = () => {
    history.push(PAGES.TASKS);
  };

  return (
    <button id='returnToTaskListButton' className={classes.button} onClick={onClickHandler}>
      <DownArrow color={theme.colors.blue} width={10} />
      <span>Alle Aufgaben</span>
    </button>
  );
};
