import React, { useCallback, useEffect, useState } from 'react';
import { MonthsControl } from '../configuration-from-pdf.js';
import { getEntityById } from '../../../services/crm-entity-service.js';
import { getResponseData } from '../../../utils/get-response-data.js';
import { AnyObject, User } from '../../../common/interfaces.js';
import { useSelector } from 'react-redux';
import Spinner from 'components/spinner';

export interface MonthsData {
  name: 'uds_wunschlaufzeitcode';
  crmFieldType: number;
  valueFromCompany: boolean;
  value: number;
}

interface MonthsComponentProps {
  months: MonthsData;
  setMonths(data: MonthsData): void;
}

const MonthsComponent = ({ months, setMonths }: MonthsComponentProps) => {
  const user = useSelector((state: AnyObject): User => state['app'].user);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (user.companyId) {
        try {
          const response = await getEntityById({
            entityId: user.companyId,
            entityName: 'account',
            columns: ['uds_laufzeitcode']
          });
          response.data = response.data || null;
          const companyData: AnyObject = getResponseData(response.data, ['attributes']);
          if (companyData.uds_laufzeitcode.attributeValue?.value > 0) {
            setMonths({
              ...months,
              value: companyData.uds_laufzeitcode.attributeValue.value,
              valueFromCompany: true
            });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCompanyDetails();
  }, []);

  const monthsChanged = useCallback(
    (value: number): void => {
      setMonths({
        ...months,
        value
      });
    },
    [setMonths]
  );

  return (
    <div style={{ position: 'relative', minHeight: '35px' }}>
      {loading ? (
        <Spinner
          id='MonthsComponentSpinner'
          style={{
            width: '35px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      ) : (
        <MonthsControl value={months.value} onChange={monthsChanged} disabled={months.valueFromCompany} />
      )}
    </div>
  );
};

export default MonthsComponent;
