import React from 'react';
import { OrderStatusTextProps } from './order-status-text.props';
import { StyledText } from '../order-status.styled';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

const OrderStatusText = ({ textWidth, color, text }: OrderStatusTextProps) => {
  return (
    <StyledText width={textWidth} color={color}>
      <EllipsisWithTooltip tooltip={text} maxWidth={textWidth}>
        <div>{text}</div>
      </EllipsisWithTooltip>
    </StyledText>
  );
};

export default OrderStatusText;
