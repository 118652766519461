import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CacheIcon = ({ width = 22, height = 27, color: colorProp, hovered }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 22 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.53806 9.62058L8.90532 9.61995L17.0145 9.61876C18.4731 9.61876 19.6666 10.8122 19.6666 12.2708L19.6957 14.0021H1.88605V12.2729C1.88605 10.8143 3.07942 9.62058 4.53806 9.62058Z'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M4.53806 9.62058L17.0145 9.61876M4.53806 9.62058C3.07942 9.62058 1.88605 10.8143 1.88605 12.2729V14.0021H19.6957M4.53806 9.62058L8.90532 9.61995M17.0145 9.61876C18.4731 9.61876 19.6666 10.8122 19.6666 12.2708L19.6957 14.0021M17.0145 9.61876L8.90532 9.61995M19.6957 14.0021L21.2003 25.5H18.5671M1.775 16.8008L0.799702 25.5H4.53806M4.53806 25.5L5.42176 16.8008M4.53806 25.5H7.03312M7.03312 25.5L7.37235 20.7868M7.03312 25.5H13.7683M18.5671 25.5L17.9477 20.2771M18.5671 25.5H16.2234M13.2304 7.78688V3.66253C13.2304 2.47046 12.2599 1.5 11.0679 1.5C9.87579 1.5 8.90532 2.47047 8.90532 3.66253V9.61995'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
