import styled, { css } from 'styled-components';

export const StyledList = styled.ul``;

export const StyledListItem = styled.li`
  color: ${({ theme }) => theme.colors.darkBlue};
  line-height: 20px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    margin: 0 10px;
  }
`;

export const StyledIcon = styled.img`
  height: 11px;
  width: 11px;
  margin-right: 8px;
`;

export const StyledDocumentContainer = styled.div<{ height?: string; isPreviewPopUp?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  height: ${({ height }) => (height ? height : '435px')};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 2px;

  ${({ isPreviewPopUp }) =>
    !isPreviewPopUp &&
    css`
      margin: 8px 0 15px 0;
    `};

  padding: 4px;
  position: relative;
`;

export const StyledScrollDownButton = styled.button`
  padding: 6px 16px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  text-align: center;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 380px;
  left: 400px;
  border: none;

  &:hover {
    box-shadow: 2px 2px 7px ${({ theme }) => theme.colors.grey300};
  }
`;

export const SignatoriesFormContainer = styled.div`
  padding: 5px 0 0 37px;
`;

export const StyledNotificationContainer = styled.div`
  margin-top: 25px;
`;

export const StyledPrivacyPolicyLink = styled.a`
  color: ${({ theme }) => theme.colors.grey600};
  text-decoration: underline !important;

  &:hover {
    opacity .9;
    color: ${({ theme }) => theme.colors.grey600};
  }
`;
