import React from 'react';

export const ClipIcon = ({ color = '#777777' }) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.9514 4.88378C11.4325 3.40266 13.8491 3.40266 15.3302 4.88378C16.8113 6.3649 16.8113 8.78147 15.3302 10.2626L11.9002 13.6925L8.15847 17.4343C7.22302 18.3698 5.66395 18.3698 4.7285 17.4343C4.26078 16.9666 4.02692 16.343 4.02692 15.7193C4.02692 15.0957 4.26078 14.4721 4.7285 14.0044L6.98916 11.7437L11.6664 7.06648C11.9782 6.75467 12.6018 6.75467 12.9136 7.06648C13.3034 7.45625 13.3034 8.00193 12.9136 8.31374L6.67735 14.628L7.7687 15.7193L13.927 9.561C14.8625 8.62556 14.8625 7.06649 13.927 6.05309C12.9916 5.11764 11.3546 5.11764 10.4191 6.05309L6.67735 9.87282L3.5592 12.991C2.77966 13.7705 2.38989 14.7059 2.38989 15.7973C2.38989 16.8887 2.85761 17.8241 3.5592 18.6036C4.33873 19.3832 5.27418 19.7729 6.36553 19.7729C7.45688 19.7729 8.39233 19.3832 9.17187 18.6036L12.5239 15.2516L16.4216 11.3539C18.5263 9.24919 18.5263 5.89718 16.4216 3.79243C14.3168 1.68768 10.9648 1.68768 8.86005 3.79243L2.00012 10.6524L3.09147 11.7437L9.9514 4.88378Z'
        fill={color}
      />
    </svg>
  );
};
