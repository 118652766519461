import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { IFetchParams, IUseFetch, RequestResponse } from './use-fetch.interface';
import { useQueryClient } from 'react-query';
import { API_ENDPOINT, RequestStatuses } from '../../constants';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { ResponseModel } from 'common/interfaces';
import authService from 'services/auth-service';
import Axios from 'axios';
import useOverlay from 'hooks/use-overlay';

const instance = axios.create({ baseURL: `${API_ENDPOINT}/api/` });

export const useFetch = <TDataProp extends Object = any>({
  isExcludeToken,
  isShowSuccessSnackbar = false,
  isShowErrorSnackbar = true,
  isShowWarningSnackbar = false,
  isCancelRequestOnUnmount = false,
  isShowLoading = true,
  loadingKey,
  successMessage: successMessageProp,
  successCallback,
  errorMessage: errorMessageProp,
  errorCallback: errorCallbackProp,
  warningMessage: warningMessageProp,
  resetQueries: resetQueriesProp,
  snackbarDuration: snackbarDurationProp = 3000,
  ...requestConfigProp
}: IUseFetch) => {
  const [showLoading, hideLoading] = useOverlay(loadingKey);
  const cancelTokenSourceRef = useRef(Axios.CancelToken.source());

  const { showRequestSnackbar } = useRequestSnackbar();

  const queryClient = useQueryClient();

  const isRequestHasStatus = (payload: unknown): payload is ResponseModel => {
    const isRequestStatusExist =
      !payload ||
      Array.isArray(payload) ||
      typeof payload === 'string' ||
      typeof payload === 'number' ||
      typeof payload === 'boolean' ||
      !Object.hasOwn(payload, 'level');

    return !isRequestStatusExist;
  };

  const fetch = async <TData extends Object = TDataProp>({
    loadingKey: loadingKeyProp,
    requestConfig = {},
    callback,
    successMessage,
    errorMessage,
    warningMessage,
    resetQueries = [],
    errorCallback = errorCallbackProp
  }: IFetchParams<TData> = {}) => {
    let response: RequestResponse<TData> = null;
    isShowLoading && showLoading(loadingKeyProp);

    try {
      if (!isExcludeToken) {
        const token = await authService.acquireTokenAsync();
        instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      }

      response = await instance({
        ...requestConfig,
        ...requestConfigProp,
        cancelToken: cancelTokenSourceRef.current.token
      });

      callback?.(response);

      if (isShowSuccessSnackbar && isShowErrorSnackbar && response?.data) {
        const isRequestSuccess = isRequestHasStatus(response.data)
          ? response.data.level === RequestStatuses.Success
          : true;

        showRequestSnackbar(
          isRequestSuccess,
          successCallback,
          successMessageProp || successMessage,
          errorMessageProp || errorMessage
        );
      }
    } catch (err) {
      if (!Axios.isCancel(err)) {
        console.error(err, 'error');
        errorCallback && errorCallback();

        if (isShowErrorSnackbar) {
          showRequestSnackbar(
            false,
            successCallback,
            successMessageProp || successMessage,
            errorMessageProp || errorMessage,
            snackbarDurationProp,
            isShowWarningSnackbar,
            warningMessageProp || warningMessage
          );
        }
      }
    } finally {
      isShowLoading && hideLoading(loadingKeyProp);

      (resetQueriesProp || resetQueries).map(resetQueryItem => queryClient.resetQueries(resetQueryItem));
    }

    return response as NonNullable<RequestResponse<TData>>;
  };

  const cancelFetching = useCallback(() => {
    if (isCancelRequestOnUnmount) {
      cancelTokenSourceRef.current.cancel();
    }
  }, [isCancelRequestOnUnmount]);

  useEffect(() => {
    return cancelFetching;
  }, [cancelFetching]);

  return {
    fetch,
    cancelFetching
  };
};
