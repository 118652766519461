import React from 'react';
import { useForceUpdate } from '../../../hooks/use-force-update.js';
import { AnyObject } from '../../../common/interfaces.js';
import { StyledControlledInput, StyledErrorMessage, StyledNumberFormat } from './distance-component.styled';
import { CircleExclamationMark } from '../../icons/crcle_exclamation_mark.js';

export interface DistanceData {
  name: 'uds_laufleistungkm';
  crmFieldType: number;
  value: number;
}

interface DistanceComponentProps {
  distance: DistanceData;
  setDistance(data: DistanceData): void;
  minDistance: number;
  maxDistance: number;
  formErrors: boolean;
}

const DistanceComponent = ({ distance, setDistance, maxDistance, minDistance, formErrors }: DistanceComponentProps) => {
  const forceUpdate = useForceUpdate();

  const onDistanceButtonClick = (type: 'subtraction' | 'addition') => {
    let newDistance = null;
    switch (type) {
      case 'subtraction':
        newDistance = distance.value - 1000;
        if (newDistance >= 0) {
          setDistance({
            ...distance,
            value: newDistance
          });
        }
        break;
      case 'addition':
        newDistance = distance.value + 1000;
        setDistance({
          ...distance,
          value: newDistance
        });
        break;
      default:
        break;
    }
  };

  const distanceChange = (value: AnyObject) => {
    const newDistance =
      value.floatValue >= minDistance ? Math.round(value.floatValue / 1000) * 1000 : value.floatValue || 0;

    setDistance({
      ...distance,
      value: newDistance
    });
    if (distance.value === newDistance) {
      forceUpdate();
    }
  };

  return (
    <>
      <StyledControlledInput
        onButtonClick={onDistanceButtonClick}
        className={null}
        styledProps={{ formErrors, value: distance.value, minDistance, maxDistance }}
      >
        <StyledNumberFormat
          value={distance.value}
          displayType='input'
          thousandSeparator={'.'}
          decimalSeparator={','}
          onValueChange={distanceChange}
        />
      </StyledControlledInput>
      {formErrors && (distance.value < minDistance || distance.value > maxDistance) && (
        <StyledErrorMessage>
          <span className='d-flex a-i-center'>
            <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
            &nbsp;Der Wert sollte zwischen {minDistance.toLocaleString('de-DE')} und{' '}
            {maxDistance.toLocaleString('de-DE')}
          </span>
        </StyledErrorMessage>
      )}
    </>
  );
};

export default DistanceComponent;
