import React, { useState } from 'react';
import { Flex } from 'common/common-components.styled';
import { DataWithIdAndName } from 'common/interfaces';
import { ClearAllFiltersButton, Filter, FilterButton, FilterContainer, FilterInput } from 'components/filter-items';
import { SessionFilter, SetSessionFilter } from 'hooks/use-session-filter';
import { FilterNames, emptyFilterState } from '../bookings-page';
import { PoolCarFilterData } from '../hooks/react-query/use-pool-cars-with-bookings/pool-cars-with-bookings.types';
import capitalize from 'lodash/capitalize';
import { StyledBookingFilters } from './filters-styled';

interface Props {
  filters?: PoolCarFilterData;
  sessionFilter: SessionFilter;
  setSessionFilter: SetSessionFilter;
  goToFirstPage: () => void;
  setFiltersWereCleared: React.Dispatch<React.SetStateAction<boolean>>;
}

const BookingFilters = ({ filters, setSessionFilter, sessionFilter, goToFirstPage, setFiltersWereCleared }: Props) => {
  const [isOpen, setOpen] = useState('');

  const saveFilter = (
    title: string,
    serverValue: string | DataWithIdAndName | null,
    clientValue: string | DataWithIdAndName | null
  ) => {
    setSessionFilter({ [title]: serverValue }, { [title]: clientValue });
  };

  const toggleFilterIsOpen = (title = '') => {
    setOpen(title);
  };

  const clearFilter = (title?: string) => {
    toggleFilterIsOpen();
    goToFirstPage();

    if (!title) {
      setFiltersWereCleared(true);
      return setSessionFilter(emptyFilterState, emptyFilterState);
    }

    if (title === 'Buchung') {
      saveFilter(FilterNames.Booking, null, null);
    }

    if (title === capitalize(FilterNames.Mark)) {
      saveFilter(FilterNames.Mark, null, null);
    }

    if (title === capitalize(FilterNames.Location)) {
      saveFilter(FilterNames.Location, null, null);
    }

    if (title === 'Mitarbeiter') {
      saveFilter(FilterNames.Driver, null, null);
    }
  };

  const handleInput = (title: string, value: string) => {
    toggleFilterIsOpen(title.toLowerCase());
    goToFirstPage();
    setFiltersWereCleared(false);

    if (title === 'buchung') {
      saveFilter(FilterNames.Booking, value, value);
    }

    if (title === capitalize(FilterNames.Mark)) {
      const markFilters = filters?.[FilterNames.Mark] || [];
      const specificMark = markFilters.find((mark: DataWithIdAndName) => mark.name === value);

      if (specificMark) {
        saveFilter(FilterNames.Mark, specificMark, specificMark);
      }
    }

    if (title === capitalize(FilterNames.Location)) {
      const locationFilters = filters?.[FilterNames.Location] || [];
      const specificLocation = locationFilters.find((location: DataWithIdAndName) => location.name === value);

      if (specificLocation) {
        saveFilter(FilterNames.Location, specificLocation, specificLocation);
      }
    }

    if (title === 'mitarbeiter') {
      const driverFilters = filters?.[FilterNames.Driver] || [];
      const specificDriver = driverFilters.find((location: DataWithIdAndName) => location.name === value);

      if (specificDriver) {
        saveFilter(FilterNames.Driver, specificDriver, specificDriver);
      }
    }
  };

  return (
    <StyledBookingFilters top='10'>
      <Filter
        title={'Buchung'}
        isOpen={isOpen}
        value={sessionFilter.filterForClient[FilterNames.Booking]}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header={'Buchung'} onClose={toggleFilterIsOpen} isNothingFound={false}>
          {filters?.name?.slice(0, 2).map((filter: string) => (
            <FilterButton key={filter} title={'buchung'} label={filter} value={filter} handleInput={handleInput} />
          ))}
          <FilterInput
            title={'buchung'}
            handleInput={handleInput}
            dropListData={filters?.name.map((item: string) => item)}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        title={capitalize(FilterNames.Mark)}
        isOpen={isOpen}
        value={sessionFilter.filterForClient[FilterNames.Mark]?.name}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header={capitalize(FilterNames.Mark)} onClose={toggleFilterIsOpen} isNothingFound={false}>
          {filters?.[FilterNames.Mark]?.slice(0, 2).map((filter: DataWithIdAndName) => (
            <FilterButton
              key={filter.id}
              title={capitalize(FilterNames.Mark)}
              label={filter.name}
              value={filter.name}
              handleInput={handleInput}
            />
          ))}
          <FilterInput
            title={capitalize(FilterNames.Mark)}
            handleInput={handleInput}
            dropListData={filters?.[FilterNames.Mark]?.map((item: DataWithIdAndName) => item.name)}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        title={capitalize(FilterNames.Location)}
        isOpen={isOpen}
        value={sessionFilter.filterForClient[FilterNames.Location]?.name}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header={capitalize(FilterNames.Location)} onClose={toggleFilterIsOpen} isNothingFound={false}>
          {filters?.[FilterNames.Location]?.slice(0, 2).map((filter: DataWithIdAndName) => (
            <FilterButton
              key={filter.id}
              title={capitalize(FilterNames.Location)}
              label={filter.name}
              value={filter.name}
              handleInput={handleInput}
            />
          ))}
          <FilterInput
            title={capitalize(FilterNames.Location)}
            handleInput={handleInput}
            dropListData={filters?.[FilterNames.Location]?.map((item: DataWithIdAndName) => item.name)}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>

      <Filter
        title={'Mitarbeiter'}
        isOpen={isOpen}
        value={sessionFilter.filterForClient[FilterNames.Driver]?.name}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header={'Mitarbeiter'} onClose={toggleFilterIsOpen} isNothingFound={false}>
          {filters?.[FilterNames.Driver]?.slice(0, 2).map((filter: DataWithIdAndName) => (
            <FilterButton
              key={filter.id}
              title={'mitarbeiter'}
              label={filter.name}
              value={filter.name}
              handleInput={handleInput}
            />
          ))}
          <FilterInput
            title={'mitarbeiter'}
            handleInput={handleInput}
            dropListData={filters?.[FilterNames.Driver]?.map((item: DataWithIdAndName) => item.name)}
            withoutSearchButton
          />
        </FilterContainer>
      </Filter>
      <ClearAllFiltersButton filterState={sessionFilter.filterForClient} clearFilter={clearFilter} />
    </StyledBookingFilters>
  );
};

export default BookingFilters;
