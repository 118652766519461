import React, { useEffect, useState } from 'react';
import { MODAL_NAMES, MODALS } from '../../../constants';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import SelectDriverComponent from '../select-driver-component';
import {
  CallbackTypes,
  SelectDriverCallback,
  SelectDriverData
} from '../select-driver-component/select-driver-component.types';
import useDriversPersonalConfigurations from './react-query/use-drivers-personal-configurations';
import SelectConfigurationToDeactivate from '../select-configuration-to-deactivate';
import { Configuration } from './react-query/use-drivers-personal-configurations/use-drivers-personal-configurations.types';
import { useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'common/enums';

const useSelectDriver = (
  openNewConfigurationDialog: SelectDriverCallback,
  openDriverDialog: SelectDriverCallback,
  refreshList: () => void
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [selectDriverData, setSelectDriverData] = useState<SelectDriverData>(DEFAULT_SELECT_DRIVER_DATA);
  const [callbackType, setCallbackType] = useState<CallbackTypes>(CallbackTypes.PdfConfiguration);

  const callbacks = {
    [CallbackTypes.PdfConfiguration]: openNewConfigurationDialog,
    [CallbackTypes.GlobalConfiguration]: openDriverDialog
  };

  const { configurations } = useDriversPersonalConfigurations(selectDriverData.driverId);

  useEffect(() => {
    if (!configurations) {
      return;
    }
    if (configurations.length >= MAX_NUMBER_OF_CONFIGURATIONS) {
      openSelectConfigurationToDeactivateDialog(configurations);
    } else {
      callbacks[callbackType](selectDriverData);
    }
  }, [configurations?.length]);

  useEffect(() => {
    if (selectDriverData.isPoolCar) {
      callbacks[callbackType](selectDriverData);
    }
  }, [selectDriverData.isPoolCar]);

  const closeDialog = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openSelectDriverDialog = (callbackType: CallbackTypes) => {
    const alertData = {
      title: 'Fahrer auswählen',
      children: <SelectDriverComponent closeDialog={closeDialog} setSelectDriverData={setSelectDriverData} />,
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeDialog
        }
      ]
    };

    setSelectDriverData(DEFAULT_SELECT_DRIVER_DATA);
    setCallbackType(callbackType);
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const openSelectConfigurationToDeactivateDialog = (configurations: Configuration[]) => {
    const alertData = {
      title: 'Max. Anzahl an Konfigurationen erreicht',
      children: (
        <SelectConfigurationToDeactivate
          closeDialog={closeDialog}
          configurations={configurations}
          openNewConfigDialog={() => callbacks[callbackType](selectDriverData)}
          refreshList={refreshList}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeDialog
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const startProcessForDriver = (callbackType: CallbackTypes, driverId: string) => {
    queryClient.resetQueries([ReactQueryKeys.GetDriversPersonalConfigurations]);
    setSelectDriverData({ ...DEFAULT_SELECT_DRIVER_DATA, driverId });
    setCallbackType(callbackType);
  };

  return { openSelectDriverDialog, startProcessForDriver };
};

export const DEFAULT_SELECT_DRIVER_DATA: SelectDriverData = { driverId: null, isPoolCar: null };
const MAX_NUMBER_OF_CONFIGURATIONS = 3;

export default useSelectDriver;
