import { useEffect } from 'react';
import { setOpenVideoTutorials, toggleGreetingSideBar } from 'actions/app_action';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const useCloseSidebarOnChangeLocation = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleGreetingSideBar(false));
    dispatch(setOpenVideoTutorials(false));
  }, [location.pathname]);
};

export default useCloseSidebarOnChangeLocation;
