import styled, { css } from 'styled-components';

interface StyledOverlayProps {
  height?: string;
}

export const StyledOverlay = styled.div<StyledOverlayProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.grey25};
  opacity: 0.9;
  z-index: 99;
  height: 100%;
  overflow-y: hidden;

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
`;
