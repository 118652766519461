import { StatusId, TaskTypeId } from 'components/aufgaben-component/task.prop';
import { useGetCalendarAppointmentQuery } from 'hooks/react-query/fleet/use-get-appointment-calendar/use-get-appointment-calendar';
import { CalendarItemProps } from 'components/widgets/calendar/components/calendar-item/calendar-item.props';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';
import { defaultGuid } from '../../../../../../constants';
import { GetAppointmentCalendarResponseDateItem } from 'hooks/react-query/fleet/use-get-appointment-calendar/use-get-appointment-calendar.props';
import { AutocompleteFilterFromState } from 'hooks/use-session-filter';
import { SERVICE_PROVIDER_TASK_CRM_NAME } from 'pages/tasks-page/tasks-page';

export const useCalendarAppointmentData = () => {
  const { getCalendarAppointmentQuery } = useGetCalendarAppointmentQuery();
  const history = useHistory<AutocompleteFilterFromState>();

  const isDisableClick = (dataItem?: Partial<GetAppointmentCalendarResponseDateItem>) => {
    return !dataItem?.activityid || dataItem.activityid === defaultGuid || !dataItem.uds_aufgabentyp_fuhrpark;
  };

  const openCurrentTaskPage = (dataItem?: Partial<GetAppointmentCalendarResponseDateItem>) => {
    if (isDisableClick(dataItem) || !dataItem) {
      return;
    }
    if (dataItem.uds_aufgabentyp_fuhrpark === TaskTypeId.ServiceProviderTasks) {
      const commonState = { [SERVICE_PROVIDER_TASK_CRM_NAME.taskId]: dataItem.uds_aufgabeid_name };

      return history.push({
        pathname: PAGES.SERVICE_PROVIDER_TASKS,
        state: { filterForClient: commonState, filterForServer: commonState }
      });
    }
    history.push({ pathname: PAGES.TASKS, search: `?id=${dataItem?.activityid}` });
  };

  const calendarWidgetData: CalendarItemProps[] = [];

  if (getCalendarAppointmentQuery?.overdue) {
    getCalendarAppointmentQuery?.overdue.forEach(item => {
      const overduePortalStatus = item.uds_b2c_portal_status;

      calendarWidgetData.push({
        type:
          overduePortalStatus && [StatusId.Open, StatusId.InProgress].includes(overduePortalStatus)
            ? 'overdue'
            : 'executed',
        description: item.subject,
        date: item.scheduledend,
        onClick: () => openCurrentTaskPage(item),
        pointer: !isDisableClick(item)
      });
    });
  }

  if (getCalendarAppointmentQuery?.upcoming) {
    getCalendarAppointmentQuery.upcoming.forEach((item, index) => {
      calendarWidgetData.push({
        type: index === 0 ? 'nearestUpcoming' : 'default',
        date: item.scheduledend,
        description: item.subject,
        onClick: () => openCurrentTaskPage(item),
        pointer: !isDisableClick(item)
      });
    });
  }

  return { calendarWidgetData };
};
