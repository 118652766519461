import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { CheckedIcon } from 'components/icons-new/checked-icon';
import { useTheme } from 'styled-components';
import { CarDraggableItemProps } from './car-draggable-item.props';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import { getInspectionDateItemDescriptionText } from '../../utils';

export const CarDraggableItem = ({ attributes, type }: CarDraggableItemProps) => {
  const theme = useTheme();

  const getIcon = () => {
    switch (type) {
      case 'assignedToCar':
        return <CheckedIcon color={theme.colors.green} />;
      default:
        return <CircleCloseIcon color={theme.colors.pink} />;
    }
  };

  return (
    <Flex align='center'>
      <Flex>{getIcon()}</Flex>

      <Flex direction='column' left='10'>
        <Text>{attributes.uds_name}</Text>
        <Text color='grey500' size='12'>
          {getInspectionDateItemDescriptionText(attributes)}
        </Text>
      </Flex>
    </Flex>
  );
};
