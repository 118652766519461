import { useEffect, useState } from 'react';
import { ChartData } from 'chart.js';
import { DoughnutDiagramProps } from '../doughnut.props';
import { PickValue } from 'common/interfaces';
import { useTheme } from 'styled-components';

export const useDoughnutData = (
  filteredData: PickValue<DoughnutDiagramProps, 'data'>,
  defaultActiveSegmentIndex: number
) => {
  const [doughnutData, setDoughnutData] = useState<ChartData<'doughnut'>>({ labels: [], datasets: [] });
  const theme = useTheme();

  const validatedData = () => {
    let total = filteredData.reduce((a, b) => a + b.value, 0);

    return filteredData.map(({ value }) => {
      let percentage = (value / total) * 100;
      if (percentage < 6 && value !== 0) {
        return (total * 6) / 100;
      }
      return value;
    });
  };

  const setDefaultData = () => {
    const filteredDataColors = filteredData.map(item => theme.colors[item.color]);

    setDoughnutData(() => ({
      labels: filteredData.map(() => ''),
      datasets: [
        {
          data: validatedData(),
          circumference: 180,
          rotation: 270,
          offset: filteredData.map((_, index) => (index === defaultActiveSegmentIndex ? 20 : 0)),
          hoverBackgroundColor: filteredDataColors,
          hoverBorderColor: filteredDataColors,
          borderWidth: 0,
          backgroundColor: filteredDataColors,
          borderJoinStyle: 'round',
          spacing: 0
        }
      ]
    }));
  };

  useEffect(setDefaultData, [JSON.stringify(filteredData)]);

  return { doughnutData, setDoughnutData };
};
