import React, { useEffect, useState, useRef } from 'react';
import classes from './option-set-field.module.scss';
import { OptionSetFieldProps } from './option-set-field.props';
import { FlatList } from 'components/flat-list/flat-list';
import { OptionSetDropdownItem } from 'pages/create-damage/components/option-set-dropdown-item/option-set-dropdown-item';
import { PicklistResponse } from 'common/interfaces';
import classNames from 'classnames';
import { Flex, Text } from 'common/common-components.styled';
import { CircleExclamationMark } from 'components/icons/crcle_exclamation_mark';
import { useTheme } from 'styled-components';

export const OptionSetField = ({
  fieldName,
  data,
  fieldRegister,
  reactHookFormData,
  registerOptions
}: OptionSetFieldProps) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors }
  } = reactHookFormData;

  const error = errors?.[fieldRegister];

  const [visible, setVisible] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const handleClick = (e: any) => {
    if (dropdownRef.current?.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  const selectItem = (item: PicklistResponse) => {
    setValue(fieldRegister, item);
    setVisible(false);
  };

  return (
    <div>
      <div className={classes.form_name}>{fieldName}</div>
      <div className={classes.input__block}>
        <div tabIndex={0}>
          <div
            {...register(fieldRegister, registerOptions)}
            id={`${fieldRegister}OptionSet`}
            className={classNames({
              [classes.input__icons__drop]: !errors[fieldRegister],
              [classes.input__icons__drop_err]: errors[fieldRegister],
              [classes.input__icons__drop__selected]: getValues(fieldRegister)
            })}
            onClick={() => setVisible(prevState => !prevState)}
          >
            {getValues(fieldRegister)?.label || 'Bitte auswählen'}
          </div>
        </div>
        <div ref={dropdownRef} className={`${classes.dropdown} ${visible ? classes.v : ''}`}>
          {visible && (
            <ul>
              <FlatList
                data={data}
                height={48 * 5}
                itemSize={48}
                renderItem={
                  <OptionSetDropdownItem rowPath='label' onClick={item => selectItem(item as PicklistResponse)} />
                }
              />
            </ul>
          )}
        </div>

        {error && (
          <Flex align='center' top='5'>
            <CircleExclamationMark color={theme.colors.pink} width={'12'} height={'12'} />
            <Text color='pink' size='12' left='5'>
              {error.message?.toString() || ''}
            </Text>
          </Flex>
        )}
      </div>
    </div>
  );
};
