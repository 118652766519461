import React, { useContext, useMemo } from 'react';
import { StyledAutocomplete, StyledTextFieldWithoutBorders } from '../../form-field.styled';
import { CloseIcon } from 'components/icons-new/close';
import { DropDownIcon } from 'components/icons/drop-down-icon';
import { AnyObject, FormFieldOption } from 'common/interfaces';
import { Checkbox } from '@mui/material';
import { AutocompleteProps } from './autocomplete.props';
import { formFieldProvider } from '../../form-field';
import { PrefixForId } from 'common/enums';

export const Autocomplete = ({ value, onChange, name, error }: AutocompleteProps) => {
  const { field } = useContext(formFieldProvider);
  const { options = [], multiple, disabled } = field;

  const displayedValue = useMemo(() => options.find(optionItem => optionItem.value === value)?.label, [value]);

  return (
    <StyledAutocomplete
      id={name}
      error={!!error}
      multiple={multiple}
      options={options as FormFieldOption[]}
      noOptionsText={'Keine Optionen'}
      popupIcon={<DropDownIcon id={field.name + PrefixForId.FormLookup + PrefixForId.Open} />}
      clearIcon={<CloseIcon width='15' height='15' id={field.name + PrefixForId.FormLookup + PrefixForId.Close} />}
      value={displayedValue}
      disabled={disabled}
      getOptionDisabled={option => !!(option as FormFieldOption).disabled}
      onChange={(_, value) => onChange(((value as FormFieldOption)?.value as string) || '')}
      renderOption={(props, option, { selected }) => (
        <li {...props} id={`${name}${(props as AnyObject)['data-option-index']}`} key={(option as any).id}>
          {multiple && <Checkbox style={{ marginRight: 8 }} checked={selected} />}
          {(option as FormFieldOption).label}
        </li>
      )}
      renderInput={params => (
        <StyledTextFieldWithoutBorders {...params} error={!!error} placeholder={'Bitte auswählen'} />
      )}
    />
  );
};
