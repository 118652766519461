import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ClockIcon = ({ width = 25, height = 24, color: colorProp, hovered }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <circle cx='12.4243' cy='12' r='9' fill='#E6F7FF' />}
      <path
        d='M15.2421 20.5501C14.3557 20.842 13.4085 21 12.4243 21C7.45375 21 3.42432 16.9706 3.42432 12C3.42432 7.02944 7.45375 3 12.4243 3C17.3949 3 21.4243 7.02944 21.4243 12C21.4243 14.6738 20.2583 17.0753 18.4071 18.7237'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4242 6.77422V12H8.06934'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
