import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import React, { useContext, useMemo } from 'react';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';
import { useLeaseExtensionActions } from 'components/aufgaben-component/components/unfolded-task/components/buttons-for-lease-extension/utils';
import PhaseSwitch from './components/phase-switch';
import DownloadOffer from './components/download-offer';

const PHASES_STATUS_OPEN = [
  LeaseExtentionPhases.Phase0,
  LeaseExtentionPhases.Phase1,
  LeaseExtentionPhases.Phase2,
  LeaseExtentionPhases.Phase7,
  LeaseExtentionPhases.Phase24,
  LeaseExtentionPhases.Phase25
];

export const useLeaseExtensionDecision = () => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const phase = car.lva?.lva_state;
  const phaseDescription = car.lva?.lva_phaseinfo?.phaseDescription;
  const phaseInfo = car.lva?.lva_phaseinfo?.specificPhaseInfo;
  const plateNumber = {
    ...car.new_name,
    attributeValue: {
      id: car.lva?.lva_phaseinfo?.fuhrparkId,
      logicalName: 'fuhrpark',
      name: car.new_name.attributeValue
    }
  };
  const files = car.lva?.lva_phaseinfo?.fileList?.Verlängerungangebot || [];
  const leaseEndDate = car.uds_lease_return_date.attributeValue;
  const leaseExtensionId = car.lva?.lva_phaseinfo?.lvaId;
  const taskId = car.lva?.lva_phaseinfo?.lvaId;

  const { openDoNotExtendModal, updateToFifthPhase } = useLeaseExtensionActions({
    currentPhase: phase,
    phaseInfo,
    plateNumber,
    files,
    leaseEndDate,
    leaseExtensionId,
    taskId,
    refresh
  });

  const description = useMemo(() => {
    switch (phase) {
      case LeaseExtentionPhases.Phase0:
      case LeaseExtentionPhases.Phase1:
      case LeaseExtentionPhases.Phase2:
        return <PhaseSwitch description={phaseDescription} openDoNotExtendModal={openDoNotExtendModal} />;

      case LeaseExtentionPhases.Phase3:
      case LeaseExtentionPhases.Phase4:
      case LeaseExtentionPhases.Phase6:
      case LeaseExtentionPhases.Phase8:
      case LeaseExtentionPhases.Phase9:
      case LeaseExtentionPhases.Phase10:
      case LeaseExtentionPhases.Phase14:
      case LeaseExtentionPhases.Phase15:
      case LeaseExtentionPhases.Phase16:
      case LeaseExtentionPhases.Phase24:
      case LeaseExtentionPhases.Phase25:
        return <WidgetContentText dangerouslySetInnerHTML={{ __html: phaseDescription }} />;

      case LeaseExtentionPhases.Phase5:
        return (
          <PhaseSwitch
            description={phaseDescription}
            openDoNotExtendModal={openDoNotExtendModal}
            isFifthPhase
            updateToFifthPhase={updateToFifthPhase}
          />
        );

      case LeaseExtentionPhases.Phase7:
        return <DownloadOffer description={phaseDescription} files={files} />;

      default:
        return null;
    }
  }, [phase, phaseDescription]);

  return {
    statusColor: PHASES_STATUS_OPEN.includes(car.lva?.lva_phaseinfo?.phaseNo) ? 'darkGold' : 'green',
    statusLabel: car.lva.lva_phaseinfo.phaseState,
    description
  };
};
