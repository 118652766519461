import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';
import { LookupResponse, ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { ReactQueryKeys } from 'common/enums';

export const useDriversForBookingQuery = ({
  options
}: QueryHookOptions<
  AxiosResponse<ResponseModel<LookupResponse<'contact'>[]>>,
  ResponseModel<LookupResponse<'contact'>[]>
> = {}) => {
  const { fetch } = useFetch<ResponseModel<LookupResponse<'contact'>[]>>({
    loadingKey: `getDriversForBookingQuery`,
    ...poolCalendarRequestConfig.getDriversForBooking()
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.GetDriversForBooking], () => fetch(), {
    enabled: options?.enabled
  });

  return { driversForBooking: data?.data?.data || [], ...restQueryProps };
};
