import React from 'react';
import { Flex } from 'common/common-components.styled';
import { FileFormatTypes } from 'common/enums';
import ExcelFileIcon from 'components/icons/excel-file-icon';
import PictureFileIcon from 'components/icons/picture-file-icon';
import PdfFileIcon from 'components/icons/pdf-file-icon';
import TextFileIcon from 'components/icons/text-file-icon';
import useModal from 'hooks/use-modal';
import { MODALS, MODAL_NAMES } from '../../constants';
import { OpenSansLabel } from './letter-modal-styled';

interface Props {
  file: File;
  allFiles: File[];
}

const getAttachmentIcon = (fileName: string) => {
  const extensionArr = fileName.split('.');
  const extension = extensionArr[extensionArr.length - 1];
  switch (extension.toLocaleLowerCase()) {
    case FileFormatTypes.XLSX:
    case FileFormatTypes.XLS:
    case FileFormatTypes.CSV:
      return <ExcelFileIcon />;
    case FileFormatTypes.JPEG:
    case FileFormatTypes.JPG:
    case FileFormatTypes.PNG:
    case FileFormatTypes.GIF:
      return <PictureFileIcon />;
    case FileFormatTypes.PDF:
      return <PdfFileIcon />;
    default:
      return <TextFileIcon />;
  }
};

const Attachment = ({ file, allFiles }: Props) => {
  const [showFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, {
    file,
    allFiles,
    isEmailAttachmentPreview: true
  });

  const openFileViewer = () => {
    showFileViewer();
  };

  return (
    <Flex width='600px' padding='13px 20px' align='center'>
      <Flex pointer width='100%' onClick={openFileViewer}>
        <Flex right='10'>{getAttachmentIcon(file.name)}</Flex>
        <OpenSansLabel ellipsis>{file.name}</OpenSansLabel>
      </Flex>
    </Flex>
  );
};

export default Attachment;
