import React, { cloneElement, useRef, useState } from 'react';
import { GrvLeasingOffersSwitcherProps, SwitcherOption } from './grv-leasing-offers-switcher.props';
import { Flex, Text } from 'common/common-components.styled';
import { StyledDownArrowIcon } from 'components/global-reference-vehicles/car-brand-expandable/car-brand-expandable.styled';
import { StyledGrvLeasingOffersSwitcher } from 'pages/details/grv-leasing-offers-switcher/grv-leasing-offers-switcher.styled';
import { Menu, MenuItem } from '@mui/material';
import { useTheme } from 'styled-components';

const GrvLeasingOffersSwitcher = ({ data, type, value, setValue, hasError }: GrvLeasingOffersSwitcherProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setOpen(false);
  };

  const openMenu = () => {
    setOpen(true);
  };

  const menuItemClickHandler = (option: SwitcherOption) => {
    setValue(option);
    closeMenu();
  };

  return (
    <>
      <StyledGrvLeasingOffersSwitcher
        onClick={openMenu}
        ref={anchorEl}
        justify='space-between'
        align='flex-start'
        direction='column'
        pointer
        open={open}
        hasError={hasError}
      >
        <Flex width='100%' justify='space-between'>
          <Text color='white' bold size='24'>
            {value?.label || ''}
          </Text>
          {cloneElement(data.icon, { color: hasError ? theme.colors.darkPink : theme.colors.midBlue })}
        </Flex>
        <Flex width='100%' justify='space-between' align='center' padding='0 8px 0 0'>
          <Text color={hasError ? 'darkPink' : 'blue'} size='12' bold uppercase>
            {data.units}
          </Text>
          <StyledDownArrowIcon color={hasError ? theme.colors.darkPink : theme.colors.blue} rotate={open} />
        </Flex>
      </StyledGrvLeasingOffersSwitcher>
      <Menu anchorEl={anchorEl.current} open={open} onClose={closeMenu}>
        {data.options.map(option => (
          <MenuItem sx={{ width: anchorEl.current?.offsetWidth || 100 }} onClick={() => menuItemClickHandler(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default GrvLeasingOffersSwitcher;
