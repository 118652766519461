import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import classNames from 'classnames';
import { CloseIcon } from 'components/icons/close-icon';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import classes from './welcome-screen.module.scss';
import { PrefixForId } from 'common/enums';

interface Props {
  isOpen: boolean;
  index: number;
  primaryProps: any;
  closeProps: any;
  stepsLength: number;
}

const WelcomeScreen = ({ isOpen, index, primaryProps, closeProps, stepsLength }: Props) => {
  const user = useTypedSelector(state => state['app'].user);
  const firstName = user.name?.split(' ').splice(0, 1);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen}>
      <DialogTitle className={classes.title_container}>
        <div>
          <img src={'/assets/images/welcome-screen-logo.svg'} alt={'mobexo-logo'} />
        </div>
        <div
          {...closeProps}
          className={classes.close_button_wrapper}
          id={PrefixForId.Modal + PrefixForId.Button + PrefixForId.Close}
        >
          <CloseIcon width='14px' height='14px' />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content_container}>
          <p className={classes.greeting}>{`Hallo ${firstName}.`}</p>
          <p className={classNames(classes.description, classes.description_bold)}>
            Wir freuen uns, dass Du Dich zur unverbindlichen und kostenlosen Demo-Version angemeldet hast.
          </p>
          <p className={classes.description}>
            Gerne möchten wir Dir Mobexo vorstellen. Damit du sehen kannst, wie die App funktioniert, haben wir einige
            Beispieldaten für Dich angelegt.
          </p>
          <p className={classes.description}>Folge dazu einfach unserer Tour.</p>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions_container}>
        <div className={classes.button_wrapper}>
          <button
            {...closeProps}
            className={classNames(classes.action_button, 'button-mobexo__secondary')}
            id={`${PrefixForId.Button}ExploreForYourself`}
          >
            Selbst erkunden
          </button>
          <button
            {...primaryProps}
            className={classNames(classes.action_button, 'button-mobexo__main', classes.start_tour_button)}
            id={`${PrefixForId.Button}TourStart`}
          >
            Tour starten
          </button>
        </div>
        <span className={classes.steps_count}>{`${index + 1} von ${stepsLength}`}</span>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeScreen;
