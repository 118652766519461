import React, { useMemo } from 'react';
import { Flex, Text, Button, Distance } from 'common/common-components.styled';
import { leasingNotExtendedFormFields } from './leasing-not-extended-form-fields';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { isEmpty } from 'lodash';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { LeaseNotExtendProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';

const LeaseNotExtended = ({ plateNumber, onSubmit, onCancel }: LeaseNotExtendProps) => {
  const reactHookFormData = useForm();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const submit = () => {
    handleSubmit(data => {
      onSubmit(data[LeasingContractFields.Comment]);
    })();
  };

  const modalText = useMemo(() => {
    return (
      <Flex>
        <span>
          Sind Sie sicher, dass der Leasingvertrag für Kunde mit dem Kennzeichen&nbsp;
          <span>
            <strong>{plateNumber.attributeValue?.name || '-'}</strong>
          </span>
          &nbsp;nicht verlängert werden kann? Bitte geben Sie den Grund an
        </span>
      </Flex>
    );
  }, [plateNumber.attributeValue?.name]);

  return (
    <Flex direction='column'>
      {modalText}
      <Distance top='30' />
      <FormHook reactHookFormData={reactHookFormData} formFields={leasingNotExtendedFormFields} />
      <Flex justify='space-between' align='center'>
        <Text pointer onClick={onCancel}>
          &larr; Abbrechen
        </Text>
        <Button id={'confirmButton'} disabled={!isEmpty(errors)} onClick={submit}>
          Bestätigen
        </Button>
      </Flex>
    </Flex>
  );
};

export default LeaseNotExtended;
