import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { Car } from 'components/icons-new/car';
import { LicencePlateIcon } from 'components/icons-new/licence-plate';
import { ChassisNumberIcon } from 'components/icons-new/chassis-number';
import { UserIcon } from 'components/icons-new/user';
import { ShoppingCart } from 'components/icons-new/shopping-cart';
import { errorMessages } from 'utils/error-messages';
import { useGetDriverWithCarQuery } from 'hooks/react-query/driver/get-drivers-with-car/use-get-drivers-with-car';
import { CrmDataTypes, FormFieldTypes } from 'common/enums';
import { useVehicleDetailIsDisableEditMode } from '../../../../../../../../hooks/use-is-disable-edit-mode';
import { useVehicleDetailDriverFieldSubmit } from 'pages/existing-vehicle-page/hooks/fileds-submit/use-driver-field-submit';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import { CarWithBorderIcon } from 'components/icons-new/car-with-border';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { WalletIcon } from 'components/icons-new/wallet';
import { CompanyIcon } from 'components/icons-new/company';
import { useGetLocationByCompanyCrmEntity } from 'hooks/react-query/crm-entity/get-location-by-company/get-location-by-company';
import { useUserCompaniesOptionSet } from 'components/form-hook/form-field/hooks/use-user-companies-option-set';
import { LocationIcon } from 'components/icons-new/location';
import { NumberedSquareIcon } from 'components/icons-new/numbered-square';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { useVehicleDetailLocationFieldSubmit } from 'pages/existing-vehicle-page/hooks/fileds-submit/use-location';
import { BOOLEAN_OPTION_SET } from 'components/form-hook/boolean-option-set-constants';
import { StarIcon } from 'components/icons-new/star';
import { useVehicleDetailMainCarFieldSubmit } from 'pages/existing-vehicle-page/hooks/fileds-submit/main-car';
import useWindowSize from 'hooks/use-window-size';

export const useAsideFormInfoList = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isDisableEditMode } = useVehicleDetailIsDisableEditMode();

  const { driverSubmitCallback } = useVehicleDetailDriverFieldSubmit();
  const { locationSubmitCallback } = useVehicleDetailLocationFieldSubmit();
  const { mainCarSubmitCallback } = useVehicleDetailMainCarFieldSubmit();

  const { userCompaniesOptionSet } = useUserCompaniesOptionSet();
  const { getDriverWithCarQuery } = useGetDriverWithCarQuery(undefined, undefined, { fetch: { isShowLoading: false } });
  const { getLocationByCompanyCrmEntity } = useGetLocationByCompanyCrmEntity(fuhrpark?.new_sparteid_id, {
    fetch: { isShowLoading: false }
  });

  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  const isOrderStatus = fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;

  const getExpectedDeliveryCrmName = () => {
    if (fuhrpark?.uds_vsl_liefertermin_laut_handler_main) {
      return 'uds_vsl_liefertermin_laut_handler_main';
    }

    return 'uds_vsl_liefertermin_laut_handler';
  };

  const asideFormInfoList: FormInfoItemEditableProps[] = [
    {
      containerProps: { order: isMobile ? 1 : undefined },
      value: fuhrpark?.new_hersteller,
      label: 'Hersteller',
      labelIcon: <Car />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'new_hersteller',
              defaultValue: fuhrpark?.new_hersteller,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 7 : undefined },
      value: fuhrpark?.new_model,
      label: 'Modell',
      labelIcon: <CarWithBorderIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'new_model',
              defaultValue: fuhrpark?.new_model,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 2 : undefined },
      value: fuhrpark?.new_name,
      label: 'Kennzeichen',
      labelIcon: <LicencePlateIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'new_name',
              defaultValue: fuhrpark?.new_name,
              validation: {
                required: errorMessages.required,
                pattern: {
                  value: /^[A-Z,Ä,Ö,Ü]{1,3}-[A-Z,Ä,Ö,Ü]{1,2} \d{1,4}([H,E]{1})?$/,
                  message: errorMessages.licencePlate
                }
              }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 8 : undefined },
      isHide: isOrderStatus,
      value: fuhrpark?.new_ezl,
      valueType: 'date',
      label: 'Erstzulassung',
      labelIcon: <CalendarWithCheckMarkIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.date({
              name: 'new_ezl',
              defaultValue: fuhrpark?.new_ezl,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 8 : undefined },
      isHide: !isOrderStatus,
      value: fuhrpark?.[getExpectedDeliveryCrmName()],
      valueType: getExpectedDeliveryCrmName() === 'uds_vsl_liefertermin_laut_handler_main' ? 'date' : undefined,
      label: 'Voraussichtlicher Liefertermin',
      labelIcon: <CalendarWithCheckMarkIcon />,
      isDisableEditMode: true
    },
    {
      containerProps: { order: isMobile ? 3 : undefined },
      value: fuhrpark?.new_fin,
      label: 'Fahrgestellnummer',
      labelIcon: <ChassisNumberIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.text({
              name: 'new_fin',
              defaultValue: fuhrpark?.new_fin,
              validation: {
                required: errorMessages.required,
                pattern: { value: /^[^Q O I q o i \s]{1,17}$/, message: errorMessages.chassisNumber }
              }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 9 : undefined },
      value: fuhrpark?.new_kauf_leasing,
      label: 'Kauf/Leasing',
      labelIcon: <WalletIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'new_kauf_leasing',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.new_kauf_leasing_value,
              options: OPTION_SET.new_kauf_leasing,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 4 : undefined },
      value: fuhrpark?.new_fahrerid,
      label: 'Fahrer',
      labelIcon: <UserIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          submitCallback={driverSubmitCallback}
          formFields={[
            generateFormField.optionSet({
              name: 'new_fahrerid',
              type: FormFieldTypes.Lookup,
              options: getDriverWithCarQuery?.map(item => ({ label: item.name || '-', value: item.id || '-' })) || [],
              defaultValue: fuhrpark?.new_fahrerid_id
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 12 : undefined },
      isHide: isOrderStatus,
      value: fuhrpark?.new_eigentmerid,
      label:
        'Eigentümer: füllt sich bei einem Eigentumsfahrzeug automatisch mit dem Unternehmen und bei einem Leasing-, Finanzierungs- oder Sale & Leaseback- Fahrzeug automatisch mit dem Leasinggeber.',
      labelIcon: <CompanyIcon />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'new_eigentmerid',
              type: FormFieldTypes.Lookup,
              defaultValue: fuhrpark?.new_eigentmerid_id,
              options: userCompaniesOptionSet,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 12 : undefined },
      isHide: !isOrderStatus,
      value: fuhrpark?.uds_anbietenterhandlerid,
      label: 'Händler',
      labelIcon: <CompanyIcon />,
      isDisableEditMode: true,
      isShowDivider: !isMobile
    },
    {
      containerProps: { order: isMobile ? 5 : undefined },
      value: fuhrpark?.uds_fahrzeugpreise,
      label: 'Brutto Listenpreis',
      labelIcon: <ShoppingCart />,
      isDisableEditMode,
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.money({
              name: 'uds_fahrzeugpreise',
              defaultValue: fuhrpark?.uds_fahrzeugpreise_value
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 12 : undefined },
      isHide: isOrderStatus,
      value: fuhrpark?.new_standortid,
      label: 'Standort',
      labelIcon: <LocationIcon />,
      isDisableEditMode,
      isShowDivider: false,
      editableComponent: (
        <VehicleDetailsEditForm
          submitCallback={locationSubmitCallback}
          formFields={[
            generateFormField.optionSet({
              name: 'new_standortid',
              type: FormFieldTypes.Lookup,
              defaultValue: fuhrpark?.new_standortid_id,
              options: getLocationByCompanyCrmEntity.map(item => ({
                label: item.new_name || '-',
                value: item.new_standortid || '-'
              })),
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 6 : undefined },
      isHide: isOrderStatus,
      value: fuhrpark?.uds_hauptauto
        ? BOOLEAN_OPTION_SET.uds_hauptauto.labels.true
        : BOOLEAN_OPTION_SET.uds_hauptauto.labels.false,
      label: 'Hauptauto',
      labelIcon: <StarIcon />,
      editableComponent: (
        <VehicleDetailsEditForm
          submitCallback={mainCarSubmitCallback}
          formFields={[
            generateFormField.booleanOptionSet({
              name: 'uds_hauptauto',
              defaultValue: fuhrpark?.uds_hauptauto?.toString(),
              labels: BOOLEAN_OPTION_SET.uds_hauptauto.labels,
              validation: { required: errorMessages.required }
            })
          ]}
        />
      )
    },
    {
      containerProps: { order: isMobile ? 10 : undefined },
      isHide: !isOrderStatus,
      value: fuhrpark?.uds_name,
      label: 'Bestellnummer',
      labelIcon: <NumberedSquareIcon />,
      isDisableEditMode: true
    }
  ];

  return { asideFormInfoList };
};
