import {
  UnavailableRange,
  UnavailableRangeResponse
} from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ResponseModel } from 'common/interfaces';
import { costCentersHistoryRequestConfig } from 'request-config/cost-centers-history/cost-centers-history.request-config';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { ReactQueryKeys } from 'common/enums';
import { GetCostCenterHistoryRangesData } from 'request-config/cost-centers-history/cost-centers-history.types';
import { RequestStatuses } from '../../../constants';
import moment from 'moment';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<UnavailableRangeResponse[]>>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(item => ({
      from: moment(item.from).startOf('day'),
      to: moment(item.to).endOf('day')
    })) as UnavailableRange[];
  }
  return [];
};

const useCostCenterUnavailableRanges = (entityName: PrimaryEntityNames, id: string, historyId?: string) => {
  let requestData: GetCostCenterHistoryRangesData = {
    entityName,
    id,
    historyId
  };

  const { fetch } = useFetch<ResponseModel<UnavailableRangeResponse[]>>({
    loadingKey: 'useCostCenterUnavailableRanges',
    isShowLoading: false,
    ...costCentersHistoryRequestConfig.getUnavailableRange(requestData)
  });

  const { data: unavailableRanges } = useQuery<
    AxiosResponse<ResponseModel<UnavailableRangeResponse[]>>,
    unknown,
    UnavailableRange[]
  >([ReactQueryKeys.GetCostCenterAvailableRanges, requestData], () => fetch(), {
    select: modifyResponse,
    cacheTime: 0
  });

  return { unavailableRanges };
};

export default useCostCenterUnavailableRanges;
