import React, { useContext, useMemo, useState } from 'react';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { STATECODE_NAMES, TASK_STATUS_CODES, VEHICLE_RETURN_PHASES } from '../../../../../../constants';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';
import moment from 'moment';
import FilledPhaseContent from './components/filled-phase-content';

export const useVehicleReturn = () => {
  const { car } = useContext(LeasingWidgetsContext);
  const { vr } = car;
  const phase = vr.vr_state;
  const returnDate = car.uds_lease_return_date.attributeValue;
  const vehicleId = vr.vr_phaseinfo.fuhrparkId;
  const orderId = vr.vr_phaseinfo.lafId;
  const exitDate = vr.vr_phaseinfo.abgangsdatum;
  const [bulletPointsActiveindex, setBulletPointActiveIndex] = useState(0);
  const [dontHaveHandoverProtocol, setIsDontHaveHandoverProtocol] = useState(false);

  const handleChangeDontHaveHandoverProtocol = (checked: boolean) => {
    setIsDontHaveHandoverProtocol(checked);
  };

  const showBulletPoints = useMemo(() => {
    return [VEHICLE_RETURN_PHASES.filledByClient, VEHICLE_RETURN_PHASES.filledByEmployee].includes(phase);
  }, [phase]);

  const updateBulletPointsIndex = (index: number) => {
    setBulletPointActiveIndex(index);
  };

  const bulletPoinstController = useMemo(() => {
    return {
      active: bulletPointsActiveindex,
      amountOfButtons: 2,
      onChange: updateBulletPointsIndex
    };
  }, [bulletPointsActiveindex]);

  const status = useMemo(() => {
    switch (phase) {
      case VEHICLE_RETURN_PHASES.open:
      case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
        return {
          label: STATECODE_NAMES.open,
          value: TASK_STATUS_CODES.open,
          color: 'darkGold'
        };
      case VEHICLE_RETURN_PHASES.filledByClient:
      case VEHICLE_RETURN_PHASES.filledByEmployee:
      case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
        return {
          label: STATECODE_NAMES.processing,
          value: TASK_STATUS_CODES.processing,
          color: 'darkGold'
        };
      case VEHICLE_RETURN_PHASES.protocolUploadedByClient:
      case VEHICLE_RETURN_PHASES.protocolUploadedByEmployee:
      case VEHICLE_RETURN_PHASES.mobexoTagThatProtocolIsNotNeeded:
        return {
          label: STATECODE_NAMES.completed,
          value: TASK_STATUS_CODES.completed,
          color: 'green'
        };
      default:
        return {
          label: '',
          value: '',
          color: ''
        };
    }
  }, []);

  const description = useMemo(() => {
    switch (phase) {
      case VEHICLE_RETURN_PHASES.open:
      case VEHICLE_RETURN_PHASES.filledAndCanceledByEmployee:
        return (
          <WidgetContentText>
            Der Leasingvertrag für dieses Fahrzeug endet spätestens am
            <strong>{` ${moment(returnDate).format('DD.MM.yyy')}`}</strong>. Bitte erstelle den Auftrag für die
            Leasingrückgabe und gebe das Fahrzeug bis zum Vertragsende zurück.
          </WidgetContentText>
        );
      case VEHICLE_RETURN_PHASES.filledByClient:
      case VEHICLE_RETURN_PHASES.filledByEmployee:
        return (
          <FilledPhaseContent
            showForm={bulletPointsActiveindex !== 0}
            dontHaveHandoverProtocol={dontHaveHandoverProtocol}
            handleChangeDontHaveHandoverProtocol={handleChangeDontHaveHandoverProtocol}
          />
        );
      case VEHICLE_RETURN_PHASES.protocolUploadedByClient:
      case VEHICLE_RETURN_PHASES.protocolUploadedByEmployee:
      case VEHICLE_RETURN_PHASES.carReturnedWithoutProtocol:
        return (
          <WidgetContentText>
            Das Übergabeprotokoll wurde erfolgreich hochgeladen. Das Fahrzeug wurde am
            <strong>{` ${moment(exitDate).format('DD.MM.yyy')} `}</strong>erfolgreich zurückgegeben.
          </WidgetContentText>
        );
      case VEHICLE_RETURN_PHASES.returnProtocolWasCanceled:
        return (
          <WidgetContentText>
            Der vorherige Übergabeprotokoll wurde annulliert. Bitte lade das Protokoll der Übergabe hoch.
          </WidgetContentText>
        );
      case VEHICLE_RETURN_PHASES.mobexoTagThatProtocolIsNotNeeded:
        return <WidgetContentText>Das Fahrzeug wurde erfolgreich übergeben.</WidgetContentText>;
      default:
        return null;
    }
  }, [phase, vehicleId, bulletPointsActiveindex, orderId, returnDate, exitDate, dontHaveHandoverProtocol]);

  return {
    statusColor: status.color,
    statusLabel: status.label,
    description,
    bulletPointsActiveindex,
    showBulletPoints,
    bulletPoinstController,
    dontHaveHandoverProtocol,
    handleChangeDontHaveHandoverProtocol
  };
};
