import React, { useState } from 'react';
import { Flex, Text, Button } from 'common/common-components.styled';
import DocumentsBlock from 'components/assignments-record/documents-block/documents-block';
import { leasingNotExtendedFormFields } from '../leasing-not-extended/leasing-not-extended-form-fields';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { isEmpty } from 'lodash';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { useTheme } from 'styled-components';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { OfferConfirmationProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';
import useContractStatus from 'hooks/react-query/use-contract-status';

const OfferConfirmation = ({ files, onSubmit, onClose }: OfferConfirmationProps) => {
  const theme = useTheme();

  const [isRejectModal, setIsRejectModal] = useState(false);

  const reactHookFormData = useForm();
  const { isContractAvailable } = useContractStatus();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const submit = () => {
    const nextPhase = isRejectModal ? LeaseExtentionPhases.Phase14 : LeaseExtentionPhases.Phase15;
    handleSubmit(data => {
      onSubmit({
        nextPhase,
        successMessage: SUCCESS_MESSAGE,
        comment: data[LeasingContractFields.Comment],
        silentExtend: false
      });
    })();
  };

  return (
    <>
      {isRejectModal ? (
        <Flex direction='column'>
          <Text>Sind Sie sicher, dass Sie das Angebot ablehnen wollen? Wenn ja, geben Sie bitte einen Grund an.</Text>
          <FormHook reactHookFormData={reactHookFormData} formFields={leasingNotExtendedFormFields} />
          <Flex justify='space-between' align='center'>
            <Text color={theme.colors.blue} pointer onClick={onClose}>
              &larr; Abbrechen
            </Text>
            <Button disabled={!isEmpty(errors)} onClick={submit}>
              Bestätigen
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex direction='column'>
          <Text bottom='20'>Leasinggeber hat ein Angebot für Leasingverlängerung gesendet:</Text>
          <DocumentsBlock files={files} noMargin isDownloadIcon isWithoutFileViewer />
          <Text top='20'>Willst du das Angebot annehmen und somit den Leasingvertrag verlängern?</Text>
          <Flex justify='flex-end' top='20'>
            <Button secondary right='10' onClick={() => setIsRejectModal(true)}>
              Ablehnen
            </Button>
            <Button disabled={!isContractAvailable} onClick={submit}>
              Annehmen &rarr;
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const SUCCESS_MESSAGE = 'Deine Entscheidung wurde erfolgreich gespeichert';

export default OfferConfirmation;
