import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { DownArrowIcon } from 'components/icons/down-arrow-icon';
import { Popover } from '@mui/material';

interface StyledPaginationButtonProps {
  active: boolean;
  shadow: boolean;
}

export const StyledPaginationButton = styled(Flex)<StyledPaginationButtonProps>`
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.extraLightBlue};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.blue} !important;
      color: ${({ theme }) => theme.colors.white};
    `};
  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
    `};
`;

interface StyledArrowIconProps {
  rotate: boolean;
}

export const StyledArrowIcon = styled(DownArrowIcon)<StyledArrowIconProps>`
  transform: rotate(${({ rotate }) => (rotate ? '270deg' : '90deg')});
`;

export const StyledPopover = styled(Popover)`
  & .MuiPopover-paper {
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    padding: 3px;
  }
`;
