import React, { useContext } from 'react';
import { TabItemFrame } from '../../tab-item-frame/tab-item-frame';
import { StyledTabItemContainer, StyledBorderContainer } from './form.styled';
import { ArrowDownIcon } from 'components/icons-new/arrow-down';
import { Direction, Rotate } from 'common/common-components.styled';
import { AccordionTabContext } from 'components/tab/type/accordion/accordion.context';
import { TabItemFrameProps } from '../../tab-item-frame/tab-item-frame.props';
import { replaceGermanWordsToEnglish } from 'utils/replace-german-words-to-english';

export const TabItemForm = (props: TabItemFrameProps) => {
  const { children, ...restProps } = props;
  const { isActive, tabIndex } = restProps;

  const { tabProps, isRemoveScrollOnToggle } = useContext(AccordionTabContext);

  const getId = () => {
    if (typeof children === 'string') {
      return replaceGermanWordsToEnglish(children);
    }
  };

  return (
    <TabItemFrame
      id={getId()}
      color='blue'
      size='18'
      bold
      isRemoveScrollOnToggle={isRemoveScrollOnToggle}
      {...restProps}
    >
      <StyledTabItemContainer isActive={isActive} {...tabProps?.container}>
        <StyledBorderContainer isActive={isActive} tabIndex={tabIndex} {...tabProps?.containerBorder}>
          {children}

          <Rotate rotateDirection={isActive ? Direction.Top : Direction.Bottom}>
            <ArrowDownIcon {...tabProps?.rotateIconProps} />
          </Rotate>
        </StyledBorderContainer>
      </StyledTabItemContainer>
    </TabItemFrame>
  );
};
