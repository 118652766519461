import React, { useContext } from 'react';
import { PanelItemFrameProps } from './panel-item-frame/panel-item-frame.props';
import { PanelItemForm } from './ui/form/form';
import { AccordionTabContext } from '../../accordion.context';

export const PanelItemController = (props: PanelItemFrameProps) => {
  const { ui } = useContext(AccordionTabContext);

  switch (ui) {
    case 'form':
      return <PanelItemForm {...props} />;

    default:
      return null;
  }
};
