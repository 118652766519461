import React from 'react';
import { ApproveStatusProps } from './approve-status.types';
import ApprovalRecord from './approval-record';
import { Text } from 'common/common-components.styled';

const ApproveStatus = ({ approvalRecords }: ApproveStatusProps) => {
  return (
    <div>
      <Text bottom='30' color='blue' size='20' bold>
        Freigabestatus:
      </Text>
      {approvalRecords.map(record => (
        <ApprovalRecord key={record.id} approvalRecord={record.attributes} />
      ))}
    </div>
  );
};

export default ApproveStatus;
