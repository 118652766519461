import React from 'react';
import { MobileBookingItemProps } from './mobile-booking-item.types';
import { StyledMobileBookingItem } from './mobile-booking-item.styled';
import { Text } from 'common/common-components.styled';
import { getPoolCarStatusColor } from '../booking-details-tooltip';
import BookingMoreMenu from '../booking-more-menu';

const MobileBookingItem = ({ row, refetchData }: MobileBookingItemProps) => {
  return (
    <StyledMobileBookingItem color={getPoolCarStatusColor(row.uds_status_code)}>
      <div>
        <Text bottom='5'>{row.uds_name}</Text>
        <Text bottom='10'>{row.new_name}</Text>
        <Text size='12'>{`${row.uds_abholdatum?.format('DD.MM.yyyy HH:mm')} - ${row.uds_ruckgabedatum?.format(
          'DD.MM.yyyy HH:mm'
        )}`}</Text>
      </div>
      <BookingMoreMenu row={row} refetch={refetchData} />
    </StyledMobileBookingItem>
  );
};

export default MobileBookingItem;
