/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'common/interfaces';

export const RecalculateAllCompaniesIcon = ({
  width = 25,
  height = 24,
  className,
  color = '#335566',
  fill = '#FFF'
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' transform='translate(0.616211)' fill='white' />
      <path
        d='M4.61621 3.89627V21H8.35759V16.8613H13.3714C13.3714 16.8613 13.4927 14.6112 14.4485 13.618C15.2832 12.7508 17.2829 12.4072 17.2829 12.4072V10.0127V5.90509C17.2829 5.3972 16.8728 5.11405 16.2829 5.00882L5.61621 3C5.04954 3 4.61621 3.38839 4.61621 3.89627Z'
        fill={fill}
      />
      <path
        d='M4.61621 21V3.89627C4.61621 3.38839 5.04954 3 5.61621 3L16.2829 5.00882C16.8728 5.11405 17.2829 5.3972 17.2829 5.90509V10.0127'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1655 16.8227L8.36137 16.8227L8.36137 21'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.46338 10.5162L7.26276 10.5162'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.46338 7.96698L7.26276 7.96698'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.46338 13.0655L7.26276 13.0655'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6162 10.5162L12.4156 10.5162'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6162 7.96698L12.4156 7.96698'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.2504 12.3333V14.2903H19.2935'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.24 14.2903C20.4642 13.1126 19.1327 12.3333 17.616 12.3333C15.2222 12.3333 13.2827 14.2729 13.2827 16.6667C13.2827 19.0605 15.2222 21 17.616 21C20.0099 21 21.9494 19.0605 21.9494 16.6667'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};


