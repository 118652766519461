import { IMyRequestsState } from './my-requests.props';

export const initialState: IMyRequestsState = {
  activeIncidentStepList: [],
  isEditMode: false,
  completedIncidentId: '',
  isActiveDamagePreview: false,
  updateIncidentId: '',
  openIncidentData: {}
};
