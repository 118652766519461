import React from 'react';
import PropTypes from 'prop-types';

export const CircleCheckMark = ({ color = '#fff', width = 12, height = 12, className = '', onClick = () => {} }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M4.09785 5.42409C3.88136 5.20759 3.53035 5.20759 3.31386 5.42409C3.09736 5.64058 3.09736 5.99159 3.31385 6.20808L4.73474 7.62898C5.12527 8.01951 5.75843 8.01951 6.14896 7.62899L10.6499 3.1281C10.8664 2.9116 10.8663 2.56058 10.6498 2.34409C10.4333 2.12759 10.0823 2.1276 9.86584 2.34409L5.44185 6.76809L4.09785 5.42409ZM10.6915 6.09609C10.5757 6.09609 10.4824 6.19012 10.477 6.30574C10.3665 8.67343 8.3956 10.5761 6.00185 10.5761C3.53785 10.5761 1.52186 8.56009 1.52186 6.09609C1.52186 3.6321 3.53785 1.61609 6.00185 1.61609C6.40452 1.61609 6.76195 1.66133 7.11482 1.75182C7.18856 1.77073 7.26723 1.75071 7.32105 1.69688L7.89005 1.12792C8.00022 1.01774 7.95581 0.830425 7.80637 0.786261C7.22711 0.615064 6.61447 0.496094 6.00185 0.496094C2.92186 0.496094 0.401855 3.01609 0.401855 6.09609C0.401855 9.17608 2.92186 11.6961 6.00185 11.6961C9.01159 11.6961 11.4866 9.28975 11.5979 6.30597C11.6023 6.19029 11.508 6.09609 11.3922 6.09609H10.6915Z'
      fill={color}
    />
  </svg>
);

CircleCheckMark.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
};
