import { Flex, Text } from 'common/common-components.styled';
import styled from 'styled-components';
import { StyledSectionProps, StyledSectionSubItemProps } from './sections.interface';

export const StyledSection = styled(Flex)<StyledSectionProps>`
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ padding }) => padding || '30px'};
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    padding: 15px;
    margin-bottom: 0;
  }
`;

export const StyledSectionHeader = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '10px')};
`;

export const StyledSectionContent = styled(Flex)`
  width: 100%;
  @media screen and (max-width: 994px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledGalleryWrapper = styled(Flex)<StyledSectionSubItemProps>`
  position: relative;
  width: ${({ isShowWidgetContainer, width }) => {
    if (width) {
      return width;
    }
    return isShowWidgetContainer ? '65%' : '50%';
  }};

  @media screen and (max-width: 994px) {
    width: 100%;
  }
`;

export const StyledCarGeneralInfo = styled(Flex)<StyledSectionSubItemProps>`
  width: ${({ isShowWidgetContainer, width }) => {
    if (width) {
      return width;
    }
    return isShowWidgetContainer ? '35%' : '50%';
  }};

  @media screen and (max-width: 994px) {
    width: 100%;
  }
`;
