import React, { useContext, useMemo, useState } from 'react';
import {
  REPLACEMENT_VEHICLE_PHASES,
  STATECODE_NAMES,
  TASK_STATUS_CODES,
  USER_ROLES
} from '../../../../../../constants';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { ReplacementVehicleOrderingPhaseProps } from './replacement-vehicle-ordering.types';
import { encodeBase64 } from 'utils/decode-encode-base64';
import { PAGES } from 'common/enums';
import { useHistory } from 'react-router-dom';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';
import { format, parseISO } from 'date-fns';
import PhaseSwitch from './components/phase-switch';
import ConfigurationStatusRender from './components/configuration-status-render/configuration-status-render';

const RECORDS_TO_SHOW = 3;

export const useReplacementVehicleOrdering = () => {
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const history = useHistory();
  const { car } = useContext(LeasingWidgetsContext);
  const { rvo } = car;
  const phase = rvo?.rvo_state;
  const carId = car.new_fuhrparkid.attributeValue;
  const plateNumber = car.new_name.attributeValue;
  const phaseInfo = rvo.rvo_phaseinfo;

  const [bulletPointsActiveindex, setBulletPointActiveIndex] = useState(0);

  const status = useMemo(() => {
    switch (phase) {
      case REPLACEMENT_VEHICLE_PHASES.created:
      case REPLACEMENT_VEHICLE_PHASES.open:
        return {
          label: STATECODE_NAMES.open,
          value: TASK_STATUS_CODES.open,
          color: 'darkGold'
        };
      case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
      case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
      case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
        return {
          label: STATECODE_NAMES.completed,
          value: TASK_STATUS_CODES.completed,
          color: 'green'
        };
      case REPLACEMENT_VEHICLE_PHASES.processing:
        return {
          label: STATECODE_NAMES.processing,
          value: TASK_STATUS_CODES.processing,
          color: 'darkGold'
        };
      case REPLACEMENT_VEHICLE_PHASES.noPhase:
      default:
        return {
          label: '',
          value: '',
          color: ''
        };
    }
  }, [phase]);

  const progressInfo = useMemo(() => {
    const phaseInfo: ReplacementVehicleOrderingPhaseProps = car.rvo.rvo_phaseinfo;

    return phaseInfo?.progressInfo?.slice(
      bulletPointsActiveindex * RECORDS_TO_SHOW,
      bulletPointsActiveindex === 0 ? RECORDS_TO_SHOW : (bulletPointsActiveindex + 1) * RECORDS_TO_SHOW
    );
  }, [bulletPointsActiveindex, car.rvo.rvo_phaseinfo]);

  const redirectToConfiguration = (itemId: string) => {
    isFleetManager
      ? history.push(`${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`)
      : history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`);
  };

  const description = useMemo(() => {
    switch (phase) {
      case REPLACEMENT_VEHICLE_PHASES.created:
        return (
          <WidgetContentText>
            Ein Ersatzfahrzeug muss erst ab
            <strong>
              {` ${phaseInfo.startTaskDate && format(parseISO(phaseInfo?.startTaskDate), 'dd.MM.yyyy')} `}
            </strong>
            konfiguriert werden
          </WidgetContentText>
        );
      case REPLACEMENT_VEHICLE_PHASES.open:
        return <PhaseSwitch />;
      case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
        return <PhaseSwitch phaseReverse />;
      case REPLACEMENT_VEHICLE_PHASES.processing:
        return <ConfigurationStatusRender progressInfo={progressInfo} redirect={redirectToConfiguration} />;
      case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
        return <WidgetContentText>Das Ersatzfahrzeug wurde bestellt</WidgetContentText>;
      case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
        return (
          <WidgetContentText>
            Ersatzfahrzeug mit Kennzeichen
            <strong>{` ${phaseInfo?.orderedVehicle?.name} `}</strong>
            ist in Betrieb
          </WidgetContentText>
        );
      default:
        return null;
    }
  }, [phase, carId, plateNumber, phaseInfo, progressInfo]);

  const updateBulletPointsIndex = (index: number) => {
    setBulletPointActiveIndex(index);
  };

  const showBulletPoints = useMemo(() => {
    return (
      REPLACEMENT_VEHICLE_PHASES.processing === car.rvo.rvo_state && car.rvo?.rvo_phaseinfo?.progressInfo?.length > 3
    );
  }, [car]);

  const bulletPoinstController = useMemo(() => {
    return {
      active: bulletPointsActiveindex,
      amountOfButtons: Math.ceil(car.rvo?.rvo_phaseinfo?.progressInfo?.length / 3),
      onChange: updateBulletPointsIndex
    };
  }, [car, bulletPointsActiveindex]);

  return {
    statusLabel: status.label,
    statusColor: status.color,
    description,
    showInfoIcon: phase !== REPLACEMENT_VEHICLE_PHASES.processing,
    bulletPointsActiveindex,
    showBulletPoints,
    bulletPoinstController
  };
};
