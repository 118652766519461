import React from 'react';

const SaveIcon = props => {
  return <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.9086 1.03125C12.165 1.03125 12.4007 1.05333 12.5861 1.23047L16.6738 4.81641C16.8704 5.00434 16.9688 5.26159 16.9688 5.53359V14.7773C16.9688 15.7676 16.166 16.5703 15.1758 16.5703H2.82422C1.83399 16.5703 1.03125 15.7676 1.03125 14.7773V2.82422C1.03125 1.83399 1.83399 1.03125 2.82422 1.03125H11.9086ZM4.21875 2.22577L2.82422 2.22656C2.49414 2.22656 2.22656 2.49414 2.22656 2.82422V14.7773C2.22656 15.1074 2.49414 15.375 2.82422 15.375H3.42188V10.793C3.42188 10.0613 3.98535 9.46124 4.70203 9.40306L4.81641 9.39844H13.2111C13.9428 9.39844 14.518 9.99077 14.5737 10.7103L14.5781 10.8249V15.375H15.1758C15.5059 15.375 15.7734 15.1074 15.7734 14.7773V5.6261L11.818 2.22656L11.7891 2.22577V4.81839C11.7891 5.58857 11.1647 6.21292 10.3945 6.21292H5.61328C4.8431 6.21292 4.21875 5.58857 4.21875 4.81839V2.22577ZM13.2111 10.5938H4.81641C4.7221 10.5938 4.6431 10.6593 4.62245 10.7473L4.61719 10.793V15.375H13.3828V10.793C13.3828 10.7013 13.34 10.6241 13.262 10.6009L13.2111 10.5938ZM10.5938 2.22577H5.41406V4.81839C5.41406 4.92842 5.50326 5.01761 5.61328 5.01761H10.3945C10.5046 5.01761 10.5938 4.92842 10.5938 4.81839V2.22577Z'
      fill="white"
    />
  </svg>;
};

export default SaveIcon;
