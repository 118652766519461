import Models from './model-view';
import { connect } from 'react-redux';
import { changeSearch } from '../../../../actions/app_action';



const mapStateToProps = state => {
  const search = state.app.smartTable.search;
  return {
    models: state.app.vehicleModels,
    search,
    value: search.predicateObject.model || ''
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
   changeHandler(search){
      dispatch(changeSearch(search));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Models);
