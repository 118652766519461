import { ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { companyRequestConfig } from 'request-config/company/company.request-config';
import {
  CostCenterItem,
  CostCenterResponseData,
  GetCostCenterListQueryOptionsType
} from './use-cost-center-list.props';
import { getDataForTable } from 'utils/get-response-data';

export const useGetCostCenterListQuery = (options: GetCostCenterListQueryOptionsType = {}) => {
  const { fetch } = useFetch<ResponseModel<CostCenterResponseData>>({
    loadingKey: 'getCostCenterListQuery',
    ...companyRequestConfig.costCenterList,
    ...options.fetch
  });

  const { data } = useQuery([ReactQueryKeys.GetCostCenterList], () => fetch(), {
    select: ({ data }) => ({ ...data.data, kostenstelles: getDataForTable(data.data.kostenstelles || []) }),
    ...options.options
  });

  const getSortedConstCenter = (key: keyof CostCenterItem) => {
    return data?.kostenstelles?.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (!aValue || !bValue) {
        return 0;
      }
      if (aValue.toLowerCase() < bValue.toLowerCase()) {
        return -1;
      }
      if (aValue.toLowerCase() > bValue.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  return { getCostCenterListQuery: data, costCenterListSortedByNameQuery: getSortedConstCenter('new_name') };
};
