import React from 'react';
import classes from './history-container.module.scss';
import {
  StyledCircle,
  StyledGradientLine,
  StyledHistoryItem,
  StyledPenIcon
} from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/history-item/history-item.styled';
import { Distance, Flex, Text } from 'common/common-components.styled';
import { useHistoryModals } from 'pages/existing-vehicle-page/hooks/use-history-modal';
import moment from 'moment';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { HistoryItem } from 'pages/existing-vehicle-page/vehicle-history-modal/history-modal.types';
import { useVehicleHistoryList } from 'pages/existing-vehicle-page/hooks/use-vehicle-history-list';
import { PAGES } from 'common/enums';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';

interface Props {
  data: HistoryItem[];
  hideGradientLine?: boolean;
  hideTitle?: boolean;
  fieldType: 'Fahrer' | 'Fahrzeug';
  entityName?: string;
  isAssign?: boolean;
  editable?: boolean;
  navigateToCarPage?: boolean;
}

function HistoryContainer({
  data,
  hideGradientLine,
  fieldType,
  entityName = 'contact',
  isAssign,
  editable = true,
  navigateToCarPage
}: Props) {
  const { openHistoryModal } = useHistoryModals();
  const { refetchVehicleHistoryList } = useVehicleHistoryList(entityName);
  const startInFuture = (validFromDate: string) => moment(validFromDate).isAfter(moment());

  const isHideGradientLine = (index: number) => {
    if (typeof hideGradientLine === 'boolean') {
      return hideGradientLine;
    }

    return data.length - 1 === index;
  };

  const getTitle = (fuhrpakr: HistoryItem['fuhrpakr'], fuhrparkHistory: HistoryItem['fuhrparkHistory']) => {
    if (fieldType === 'Fahrer') {
      return fuhrpakr.new_name.attributeValue;
    }

    return fuhrparkHistory.uds_kostentrager.attributeValue?.name;
  };

  const getDescription = (fuhrpakr: HistoryItem['fuhrpakr']) => {
    let mainDescription = fuhrpakr.uds_model_composite.attributeValue || '';

    if (fieldType !== 'Fahrer') {
      mainDescription = `${mainDescription} ${fuhrpakr.new_name.attributeValue}`;
    }

    return mainDescription;
  };

  return (
    <div className={classes.list_container}>
      {data.map(({ fuhrpakr, fuhrparkHistory }, index: number) => (
        <StyledHistoryItem padding='10px 25px' key={index} editable={editable}>
          <Flex direction='column' align='center' right='20'>
            <StyledCircle inFuture={startInFuture(fuhrparkHistory.uds_assignment_valid_from.attributeValue)} />
            <Distance top='10' />
            {!isHideGradientLine(index) && (
              <StyledGradientLine inFuture={startInFuture(fuhrparkHistory.uds_assignment_valid_from.attributeValue)} />
            )}
          </Flex>
          <Flex direction='column' width='90%'>
            <Flex
              onClick={() =>
                navigateToCarPage &&
                window.open(
                  `${PAGES.MY_VEHICLES_DETAILS}/?id=${fuhrparkHistory.uds_kennzeichen.attributeValue.id}`,
                  '_blank'
                )
              }
            >
              <EllipsisWithTooltip tooltip={getTitle(fuhrpakr, fuhrparkHistory)}>
                <Text
                  className={navigateToCarPage && classes.history_item_title}
                  bold
                  pointer={navigateToCarPage ? true : false}
                  size='20'
                  color={startInFuture(fuhrparkHistory.uds_assignment_valid_from.attributeValue) ? 'midBlue' : 'blue'}
                >
                  {getTitle(fuhrpakr, fuhrparkHistory)}
                </Text>
              </EllipsisWithTooltip>
            </Flex>

            <EllipsisWithTooltip tooltip={getDescription(fuhrpakr)}>
              <Text
                top='5'
                size='12'
                color={startInFuture(fuhrparkHistory.uds_assignment_valid_from.attributeValue) ? 'midBlue' : 'blue'}
              >
                {getDescription(fuhrpakr)}
              </Text>
            </EllipsisWithTooltip>
            <Text
              top='5'
              size='12'
              color={startInFuture(fuhrparkHistory.uds_assignment_valid_from.attributeValue) ? 'midBlue' : 'blue'}
            >
              Zuordnung:
              {moment(fuhrparkHistory.uds_assignment_valid_to.attributeValue).format('DD.MM.yy') ? ' ab ' : ''}
              {getDateString(fuhrparkHistory.uds_assignment_valid_from.attributeValue)}
              {fuhrparkHistory.uds_assignment_valid_to.attributeValue
                ? ` bis ${getDateString(fuhrparkHistory.uds_assignment_valid_to.attributeValue)}`
                : ''}
            </Text>
          </Flex>
          {editable && (
            <StyledPenIcon
              width={30}
              height={30}
              onClick={() =>
                openHistoryModal(
                  refetchVehicleHistoryList,
                  entityName,
                  fuhrparkHistory.uds_kennzeichen.attributeValue.id,
                  fieldType,
                  isAssign,
                  undefined,
                  fuhrparkHistory.uds_kostentrager.attributeValue.id,
                  fuhrparkHistory.uds_fuhrparkhistoryid.attributeValue,
                  false,
                  fuhrparkHistory.uds_assignment_valid_from.attributeValue,
                  fuhrparkHistory.uds_assignment_valid_to.attributeValue
                )
              }
            />
          )}
        </StyledHistoryItem>
      ))}
    </div>
  );
}

export default HistoryContainer;
