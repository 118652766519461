import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const QuestionIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <circle cx='9.54822' cy='10' r='9' fill={fill} />}
      <path
        d='M12.366 18.5501C11.4796 18.842 10.5324 19 9.54822 19C4.57766 19 0.548218 14.9706 0.548218 10C0.548218 5.02944 4.57766 1 9.54822 1C14.5188 1 18.5482 5.02944 18.5482 10C18.5482 12.6738 17.3822 15.0753 15.531 16.7237'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.93604 8.10309C6.93604 6.65915 8.10425 5.49094 9.54818 5.49094C10.9921 5.49094 12.1603 6.65915 12.1603 8.10309C12.1603 9.54702 10.9921 10.7152 9.54818 10.7152V12.1664'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.47625 15.0437C9.93188 15.0437 10.3012 14.6743 10.3012 14.2187C10.3012 13.763 9.93188 13.3937 9.47625 13.3937C9.02061 13.3937 8.65125 13.763 8.65125 14.2187C8.65125 14.6743 9.02061 15.0437 9.47625 15.0437Z'
        fill={color}
      />
    </svg>
  );
};
