import React from 'react';
import classes from './assignments-item-mobile.module.scss';
import { formatDate } from 'helpers';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';
import { AssignmentsItemMobileProps } from './assignments-item-mobile.props';

export const AssignmentsItemMobile = ({ data }: AssignmentsItemMobileProps) => {
  return (
    <li className={classes.container} data-incidentid={data.incidentid}>
      <header className={classes.header}>
        <span className={classes.header_left}>{data.title}</span>
        <span className={classes.header_right}>{data.new_fdkennzeichen}</span>
      </header>

      <main className={classes.main}>
        <h2 className={classes.title}>{data.uds_serviceid}</h2>
        <span className={data.status && classes.status}>{data.status || '-'}</span>
      </main>

      <footer className={classes.footer}>
        <div className={classes.column}>
          <p className={classes.column_value}>{data.new_ainame2 || '-'}</p>
          <p className={classes.column_name}>Fahrer</p>
        </div>
        <div className={classes.column}>
          <p className={classes.column_value}>{formatDate(convertUTCDateToLocalDate(data.createdon)) || '-'}</p>
          <p className={classes.column_name}>Erstellt am</p>
        </div>
      </footer>
    </li>
  );
};
