import React from 'react';
import { FormInfoList } from 'components/form-hook/components/info/form-info-list';
import { useAsideFormInfoList } from './hooks/use-aside-form-info-list';
import { Flex, TextProps } from 'common/common-components.styled';
import { PutVehicleIntoOperationButton } from './components/put-vehicle-into-operation-button/put-vehicle-into-operation-button';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailCarSectionAside = () => {
  const { asideFormInfoList } = useAsideFormInfoList();

  const { windowWidth } = useWindowSize(500);
  const VALUE_STYLE: TextProps = { size: '14', width: '100%' };

  return (
    <Flex direction='column' justify='space-between' width='100%'>
      <FormInfoList
        type='editable'
        columns={windowWidth <= 550 ? 1 : 2}
        valueStyle={VALUE_STYLE}
        data={asideFormInfoList}
      />
      <PutVehicleIntoOperationButton />
    </Flex>
  );
};
