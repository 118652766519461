import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { RepairStatusHorizontalProps } from './repair-status-horizontal.props';
import { StepProcessHorizontalItem } from './components/step-process-horizontal-item/step-process-horizontal-item';
import useWindowSize from 'hooks/use-window-size';

export const RepairStatusHorizontal = ({ data, title, description }: RepairStatusHorizontalProps) => {
  const { windowWidth } = useWindowSize(500);

  return (
    <Flex direction={windowWidth <= 550 ? 'column' : 'row'}>
      <Flex direction='column' bottom={windowWidth <= 550 ? '20' : '0'} right={windowWidth <= 550 ? '0' : '50'}>
        <Text color='blue' size='18' bold bottom='10px'>
          {title}
        </Text>
        <Text color='blue' size='12'>
          {description}
        </Text>
      </Flex>

      {data.map((item, index) => (
        <StepProcessHorizontalItem key={item.id} isHideUnderline={data.length - 1 === index} {...item} />
      ))}
    </Flex>
  );
};
