import { FormFieldModel } from 'common/interfaces';
import { FormData, VehicleStatusChangeModalContentData } from './modal-content.props';
import { FormFieldTypes } from 'common/enums';
import { errorMessages } from 'utils/error-messages';

export const getFormFields = (presetData: VehicleStatusChangeModalContentData): FormFieldModel<keyof FormData>[] => [
  {
    name: 'new_ezl',
    label: 'Ertzulassungdatum',
    type: FormFieldTypes.Date,
    defaultValue: presetData.new_ezl,
    validation: { required: errorMessages.required }
  }
];
