import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import {
  FilterCrmName,
  IRepairPartnerItemsResponse,
  IRepairPartnerFilter,
  IRepairPartnerFilterResponse,
  RepairPartnerStatusesId
} from 'services/requests-service/requests-service.props';
import { setValue } from 'utils/get-response-data';
import { IModifiedTableRow } from './my-requests.props';

export const modifyTable = (data: Partial<IRepairPartnerItemsResponse>): Partial<IModifiedTableRow> => {
  const sortData: Partial<IModifiedTableRow> = {};

  Object.entries(data).forEach(([key, value]) => {
    setValue(data, key, value, sortData, true);
  });

  sortData.isDisableRow = sortData?.Status?.id === RepairPartnerStatusesId.Rejected;

  return sortData;
};

export const modifyFilter = ({ data }: AxiosResponse<ResponseModel<IRepairPartnerFilterResponse>>) => {
  const modifyData: IRepairPartnerFilter = {
    ...data.data,
    [FilterCrmName.Mark]: data.data[FilterCrmName.Mark].map(markItem => markItem.attributeValue || '')
  };

  return modifyData;
};
