import { GetFilterQueryByName } from '../../../common/enums';
import {Title} from '../../../common/interfaces';
import { useQuery } from 'react-query';
import {getFahrzeugkostenFilterDataByName, TransformedFilterItem} from '../../../services/vehicle-costs-service';
import { AxiosResponse } from 'axios';


export const useFilterByName = (title:Title)=>{
    const {
        data:filterData,
        isFetching,
      } = useQuery<AxiosResponse<TransformedFilterItem[]>>(
        [GetFilterQueryByName[title]],
        () => getFahrzeugkostenFilterDataByName(title),
        {
          refetchOnWindowFocus:false
        }
      );

    return {
        isFetching,
        data:filterData?.data ?? []
    };
};