import { useEffect, useState } from 'react';
import { useOverlay } from './index';

const useRequest = (reqFunction, dependences = [], disableHidingOverlay, loadingKeyProp) => {
  const [response, setResponse] = useState({
    data: null,
    error: false,
    isFetched: false
  });
  const [showOverlay, hideOverlay] = useOverlay(loadingKeyProp || 'useRequestOverlay');

  useEffect(() => {
    (async () => {
      showOverlay();
      let response;
      try {
        response = await reqFunction();
        response.data = response.data || null;
        setResponse({
          data: response.data,
          error: false
        });
      } catch (e) {
        console.log(e);
        setResponse({
          data: null,
          error: e
        });
      } finally {
        if (!disableHidingOverlay) {
          hideOverlay();
        }
        setResponse(prevState => ({ ...prevState, isFetched: true }));
      }
    })();
  }, dependences);

  return response;
};

export default useRequest;
