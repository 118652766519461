import React from 'react';
import { Button, Flex, Text } from 'common/common-components.styled';
import { ApprovalRulesProps, SetApproversAutomaticallyValues } from './approval-rules.types';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { setApproversAutomaticallyFormFields } from './approval-rules.form-fields';
import InstanceComponent from '../instance-component';
import useFillInstanceFields from '../../hooks/use-fill-instance-fields';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { companyRequestConfig } from 'request-config/company/company.request-config';
import { useQueryClient } from 'react-query';
import { CrmDataTypes, ReactQueryKeys } from 'common/enums';
import { getRequestAttributes } from 'utils/get-request-attributes';

const ApprovalRules = ({ companyId, companyData }: ApprovalRulesProps) => {
  const reactHookFormData = useForm();
  const queryClient = useQueryClient();
  const { handleSubmit, getValues } = reactHookFormData;
  useFillInstanceFields(reactHookFormData, companyData);
  const { fetch } = useFetch({ loadingKey: 'updateCompany', isShowSuccessSnackbar: true });

  const setApproversAutomaticallyValue = getValues('uds_order_approve_type_value');

  const save = () => {
    handleSubmit(data => {
      const newCompanyData = {
        id: companyId,
        attributes: getRequestAttributes(FIELD_TYPES, data),
        setNull: true
      };

      fetch({
        requestConfig: companyRequestConfig.updateCompany(newCompanyData),
        callback: () => {
          queryClient.resetQueries([
            ReactQueryKeys.CompanyDataResponse,
            companyId,
            ReactQueryKeys.CompanyDataCompanyResponse
          ]);
        }
      });
    })();
  };

  return (
    <Flex top='20' direction='column'>
      <Text bottom='30' color='black'>
        Definiere die Freigabe Instanzen für den Bestellprozess, bei der Auswahl "Vorgesetzter" und "Bestellfreigeber"
        werden die entsprechend in den Fahrerakten hinterlegten Personen in den Freigabeprozess verwendet.
      </Text>

      <FormHook reactHookFormData={reactHookFormData} formFields={setApproversAutomaticallyFormFields} />
      {setApproversAutomaticallyValue &&
        setApproversAutomaticallyValue !== SetApproversAutomaticallyValues.Manual &&
        INSTANCIES.map(instance => (
          <div key={instance.instanceFieldName}>
            <InstanceComponent
              reactHookFormData={reactHookFormData}
              instanceFieldName={instance.instanceFieldName}
              instanceFieldLabel={instance.instanceFieldLabel}
              contactFieldName={instance.contactFieldName}
              contactFieldLabel={instance.contactFieldLabel}
            />
          </div>
        ))}

      <Flex justify='flex-end'>
        <Button onClick={save}>Speichern</Button>
      </Flex>
    </Flex>
  );
};

const FIELD_TYPES = {
  uds_order_approve_type_value: { attributeTypeCode: CrmDataTypes.Picklist },
  uds_order1_approver_type_value: { attributeTypeCode: CrmDataTypes.Picklist },
  uds_order1_approver_contactid: { attributeTypeCode: CrmDataTypes.Lookup },
  uds_order2_approver_type_value: { attributeTypeCode: CrmDataTypes.Picklist },
  uds_order2_approver_contactid: { attributeTypeCode: CrmDataTypes.Lookup },
  uds_order3_approver_type_value: { attributeTypeCode: CrmDataTypes.Picklist },
  uds_order3_approver_contactid: { attributeTypeCode: CrmDataTypes.Lookup }
};

export const INSTANCIES = [
  {
    instanceFieldName: 'uds_order1_approver_type_value',
    instanceFieldLabel: '1. Instanz',
    contactFieldName: 'uds_order1_approver_contactid',
    contactFieldLabel: '1. Instanz Kontakt'
  },
  {
    instanceFieldName: 'uds_order2_approver_type_value',
    instanceFieldLabel: '2. Instanz',
    contactFieldName: 'uds_order2_approver_contactid',
    contactFieldLabel: '2. Instanz Kontakt'
  },
  {
    instanceFieldName: 'uds_order3_approver_type_value',
    instanceFieldLabel: '3. Instanz',
    contactFieldName: 'uds_order3_approver_contactid',
    contactFieldLabel: '3. Instanz Kontakt'
  }
];

export default ApprovalRules;
