import { createContext, Dispatch, SetStateAction } from 'react';
import { PreparedFilesForDownloadState, SortingDocumentsTabsProps } from './sorting-documents-tabs.props';

interface SortingDocumentsTabsContextProps
  extends Pick<SortingDocumentsTabsProps, 'documentsQueryKeyList' | 'isHideDeleteButton'> {
  preparedFilesForDownload: PreparedFilesForDownloadState;
  setPreparedFilesForDownload: Dispatch<SetStateAction<PreparedFilesForDownloadState>>;
  showLetterModal: (fileId: string) => void;
}

export const SortingDocumentsTabsContext = createContext<SortingDocumentsTabsContextProps>({
  documentsQueryKeyList: [],
  preparedFilesForDownload: {},
  setPreparedFilesForDownload: () => null,
  showLetterModal: () => null
});
