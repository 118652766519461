import React from 'react';
import classes from './consumption.module.scss';
import { DiagramWithBottomSegmentsContainer } from 'components/containers/diagram/bottom-segments/bottom-segments';
import { ConsumptionDiagram } from './components/diagram/diagram';
import { useConsumptionWidgetDiagramData } from './components/diagram/hooks/use-diagram-data';
import { CurrentConsumption } from './components/current-consumption/current-consumption';
import { Grid } from '@mui/material';
import { ConsumptionInfoHint } from './components/info-hint/consumption/consumption';
import { Flex } from 'common/common-components.styled';
import { ConsumptionEmptyPlaceholder } from './components/placeholder/empty/empty';
import { useConsumptionWidgetVariant } from './hooks/use-widget-variant';
import useWindowSize from 'hooks/use-window-size';

export const ConsumptionWidget = () => {
  const { segments, isShowConsumptionPlaceholder } = useConsumptionWidgetDiagramData();

  const { widgetVariant } = useConsumptionWidgetVariant();
  const { windowWidth } = useWindowSize(500);

  const getContent = () => {
    if (isShowConsumptionPlaceholder) {
      return <ConsumptionEmptyPlaceholder />;
    }

    return (
      <Flex direction='column' align='center' width='100%'>
        <Grid container columns={2} columnSpacing='30px' display='flex' alignItems='center'>
          <Grid item xs={windowWidth <= 550 ? 1 : 1.3} height={windowWidth <= 550 ? '200px' : 'none'}>
            <ConsumptionDiagram />
          </Grid>
          <Grid item xs={windowWidth <= 550 ? 1 : 0.7}>
            <CurrentConsumption />
            {widgetVariant === 'normal' && <ConsumptionInfoHint />}
          </Grid>
        </Grid>

        {widgetVariant === 'small' && <ConsumptionInfoHint />}
      </Flex>
    );
  };

  return (
    <DiagramWithBottomSegmentsContainer className={classes.container} segments={segments}>
      {getContent()}
    </DiagramWithBottomSegmentsContainer>
  );
};
