import { setCustomUrlTree } from 'actions/app_action';
import { setVehicleDetailsMainTab } from 'actions/pages/vehicle-details.action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useVehicleDetailsOnUnmount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setVehicleDetailsMainTab({ activeIndex: 0 }));
      dispatch(setCustomUrlTree(null));
    };
  }, []);
};
