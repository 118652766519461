import { DoughnutDiagram } from 'components/widgets/doughnut/doughnut';
import React from 'react';
import { TotalRunningTimeCostsDoughnutFocusedSegment } from './components/doughnut-focused-segment/doughnut-focused-segment';
import { DoughnutDiagramData } from 'components/widgets/doughnut/doughnut.props';
import { useFleetGetVehicleConstQuery } from 'hooks/react-query/fleet/use-get-vehicle-costs/use-get-vehicle-costs';
import { totalRunningTimeDoughnutColorList } from './utils';

export const TotalRunningTimeDoughnut = () => {
  const { fleetGetVehicleConstQuery, isFetching } = useFleetGetVehicleConstQuery();

  const doughnutData: DoughnutDiagramData =
    fleetGetVehicleConstQuery?.byCategory?.map((item, index) => ({
      label: item.category || '-',
      value: item.value || 0,
      color: totalRunningTimeDoughnutColorList[index]
    })) || [];

  const getDefaultActiveSegmentIndex = () => {
    const doughnutValues = doughnutData.map(item => item.value);
    const maxValueIndex = doughnutValues.indexOf(Math.max(...doughnutValues));

    return maxValueIndex ? maxValueIndex - 1 : maxValueIndex;
  };

  if (isFetching) {
    return null;
  }

  return (
    <DoughnutDiagram
      justify='center'
      height='150px'
      top='10'
      data={doughnutData}
      defaultActiveSegmentIndex={getDefaultActiveSegmentIndex()}
      renderFocusedSegment={TotalRunningTimeCostsDoughnutFocusedSegment}
      renderFocusedSegmentContainerProps={{ positionBottom: '30px', maxWidth: '55%' }}
    />
  );
};
