import React, { Component } from 'react';
import axios, { CancelToken } from 'axios';
import { withRouter } from 'react-router-dom';

import classes from './proposal-for.module.scss';

class ProposalForContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }
  cancellationTokenSource = CancelToken.source();
  async componentDidMount() {
    try {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('id') || undefined;
      const { data } = await service(id, this.cancellationTokenSource.token);
      this.setState({ text: data });
    } catch (err) {
      // TODO: Handle error
    }
  }

  componentWillUnmount() {
    this.cancellationTokenSource.cancel('The componentWillUnmount cancelled request!');
  }

  render() {
    if (!this.state.text) {
      return null;
    }

    return (
      <div className={classes.proposal}>
            <span>{this.state.text}</span>
      </div>
    );
  }
}

export default withRouter(ProposalForContainer);

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

async function service(id, cancellationToken) {
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/proposal/for`,
    cancelToken: cancellationToken,
    data: {
      id
    }
  });
}
