import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { ReactQueryKeys } from 'common/enums';
import useReleaseData from 'pages/release-external-page/hooks/use-release-data';
import { useQueriesTyped } from 'hooks/use-queries-typed';
import { useEffect, useState } from 'react';
import { useQuery } from 'hooks';

const useCarPhotos = () => {
  const query = useQuery();
  const queryId = query.id as string;

  const [carPhotos, setCarPhotos] = useState<string[]>([]);

  const { fetch } = useFetch<Blob>({ isShowLoading: false, isExcludeToken: true });
  const { fotoData } = useReleaseData();

  const photoResponse = useQueriesTyped(
    fotoData?.map(item => ({
      queryKey: [ReactQueryKeys.DownloadExternalSigningFoto, item.storageFileId],
      queryFn: () =>
        fetch({
          loadingKey: `downloadExternalSigningFoto${item.storageFileId}`,
          requestConfig: vehicleRequestConfig.downloadExternalSigningFoto({
            id: queryId || '',
            storageFileId: item.storageFileId,
            size: 4
          })
        })
    })) || []
  );

  useEffect(() => {
    if (!photoResponse.every(photoRes => photoRes.isSuccess)) {
      return;
    }
    setCarPhotos(photoResponse.map(photoRes => URL.createObjectURL(new Blob([photoRes.data!.data]))));
  }, [photoResponse.every(photoRes => photoRes.isSuccess)]);

  return { carPhotos };
};

export default useCarPhotos;
