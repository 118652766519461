import React, {
  cloneElement,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormFieldModel, FormFieldOption, RenderComponentAboveOptionsProps } from 'common/interfaces';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  RadioGroup
} from '@mui/material';
import { Flex, Text } from 'common/common-components.styled';
import { styled } from '@mui/material/styles';
import { DefaultTheme, useTheme } from 'styled-components';
import { CircleExclamationMark } from 'components/icons/crcle_exclamation_mark';
import Spinner from 'components/spinner';
import {
  StyledAutocomplete,
  StyledDatePicker,
  StyledFormFieldContainer,
  StyledSelect,
  StyledSlider,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldWithoutBorders,
  StyledRadioButton,
  StyledFormControlLabel,
  StyledCheckbox,
  StyledInputAdornment,
  StyledRadioButtonLabel
} from 'components/form-hook/form-field/form-field.styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'moment/locale/de.js';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormFieldTypes, PrefixForId } from 'common/enums';
import {
  NumberFormatCustom,
  NumberFormatPercentages,
  NumberFormatMonths,
  NumberFormatKilometers,
  InputNumberFormats
} from 'components/form-hook/form-field/number-format-custom';
import isEqual from 'lodash/isEqual';
import { TimeField } from './components/time-field/time';
import CircleInfoIcon from 'components/icons/warning-icon';
import Tooltip from '@mui/material/Tooltip';
import { IFormFieldContext } from './form.props';
import { DATE_ERROR_MESSAGE, defaultFormFieldProviderState } from './utils';
import { Textarea } from './components/text-area/text-area';
import InfoHint from 'components/info-hint';
import { FileField } from './components/file/file.field';
import { Tip } from './components/common/tip';
import { CloseIcon } from 'components/icons-new/close';
import { useForceUpdate } from 'hooks/use-force-update';
import { UnSaveModalRefContext } from 'context/un-save-modal/un-save-modal-ref.context';
import { PhoneNumberField } from './components/phone-number/phone-number';
import { CarDamageField } from './components/car-damage/car-damage';
import { OptionSetFile } from './components/option-set-file/option-set-file';
import { OptionSetFileErrorEnum } from './components/option-set-file/option-set-file.props';
import { ArrowDownIcon, StyledArrowDownIcon } from 'components/icons-new/arrow-down';
import DateTimeField from 'components/form-hook/form-field/components/date-time-field';
import IconHover from 'components/icon-hover';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { useUpdateLookupValue } from './hooks/use-set-lookup-value';
import { AutocompleteListbox } from './components/autocomplete/components/listbox/listbox';
import { AutocompleteEmptyPlaceholder } from './components/autocomplete/components/empty-placeholder/empty-placeholder';
import { FormLabel } from './components/common/label/label';
import { useSetFormOptions } from './hooks/use-set-options/use-set-options';
import { useSetFieldLoading } from './hooks/use-set-field-loading';
import { AutocompleteAddNewItemButtonProps } from './components/autocomplete/components/add-new-item-button/add-new-item-button.props';
import { ReturnToInitialFieldButton } from '../components/buttons/return-to-initial-field/return-to-initial-field';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { isMoment } from 'moment';
import { getStatusColor } from '../../../components/driving-license-control-component/components/items-component/status/utils';
import { Circle } from '../../../common/common-components.styled';

interface StyledFormControlProps {
  styledtheme: DefaultTheme;
}

const StyledFormControl = styled(FormControl)<StyledFormControlProps>(({ styledtheme }) => ({
  '& .Mui-error': {
    color: `${styledtheme.colors.pink}!important`,
    borderColor: `${styledtheme.colors.pink}!important`
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${styledtheme.colors.grey500}`,
    borderRadius: '2px'
  }
}));

export const defaultOptions = { shouldValidate: true, shouldDirty: true, shouldTouch: true };

interface FormFieldProps {
  field: FormFieldModel;
  reactHookFormData: UseFormReturn<any>;
  distanceBetweenFields?: string;
  marginLeft?: string;
  keepDataFromField?: boolean;
  borderColor?: string;
}

export const formFieldProvider = createContext<IFormFieldContext>(defaultFormFieldProviderState);

const FormField = ({
  field,
  distanceBetweenFields = '20',
  marginLeft = '0',
  reactHookFormData,
  keepDataFromField = false,
  borderColor
}: FormFieldProps) => {
  const theme = useTheme();
  const forceUpdate = useForceUpdate();

  const [formField, setFormField] = useState(field);
  const [inputValue, setInputValue] = useState('');
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const { isFormsDirty } = useContext(UnSaveModalRefContext);

  const {
    register,
    unregister,
    formState: { errors, dirtyFields, isDirty },
    setValue,
    watch,
    getValues,
    setError,
    clearErrors
  } = reactHookFormData;

  isFormsDirty.current = isDirty;

  const { lookupValue, setLookupValue } = useUpdateLookupValue(reactHookFormData, formField);
  const { loading, setLoading, setIsShowCreateNewItemForm } = useSetFormOptions(
    inputValue,
    field,
    formField,
    setFormField
  );

  useSetFieldLoading(formField, setLoading);
  const isMultipleLookupField = field.type === FormFieldTypes.Lookup && field.multiple;

  useEffect(() => {
    if (!isEqual(field, formField)) {
      setFormField(field);
    }
  }, [field]);

  useEffect(() => {
    setTimeout(() => {
      if (formField.type === FormFieldTypes.Date || formField.type === FormFieldTypes.DateTime) {
        const value = getValues(formField.name);
        if (!isMoment(value)) {
          return;
        }
        const minDateError = value && formField.minDate && value.isBefore(formField.minDate);
        const maxDateError = value && formField.maxDate && value.isAfter(formField.maxDate);

        if ((value && !value.isValid()) || minDateError || maxDateError) {
          const message = minDateError
            ? `Mindestdatum ist ${formField.minDate?.format('DD.MM.yyyy HH:mm')}`
            : maxDateError
            ? `Maximales Datum ist ${formField.maxDate?.format('DD.MM.yyyy HH:mm')}`
            : DATE_ERROR_MESSAGE;
          setError(formField.name, {
            type: 'manual',
            message
          });
        }
      }
    });
  }, [getValues(formField.name)]);

  useEffect(() => {
    register(formField.name, { ...formField.validation, value: formField.defaultValue });
    if (!keepDataFromField) {
      return () => {
        setLookupValue(null);
        setInputValue('');
        unregister(formField.name);
      };
    }
  }, [register, unregister, formField.name]);

  useEffect(() => {
    register(formField.name, formField.validation);
  }, [register, JSON.stringify(formField.validation), formField.name]);

  useEffect(() => {
    if (
      [FormFieldTypes.Radio, FormFieldTypes.Money, FormFieldTypes.Number].includes(field.type) &&
      field.defaultValue
    ) {
      setValue(field.name, field.defaultValue, defaultOptions);
    }
  }, [field.defaultValue, formField.name]);

  useEffect(() => {
    const findSelectedOption = formField.options?.find(option => option.value === getValues(formField.name));
    setInputValue(findSelectedOption?.label || '');
  }, [watch(formField.name)]);

  const getIsShowAddNewItemButton = (): boolean => {
    if (field.isCanBeAddedNewItem === undefined) {
      return !!field.addNewItemCallback || !!field.createNewItemFormField;
    }

    return field.isCanBeAddedNewItem;
  };

  const returnToInitialFormField = () => {
    setIsShowCreateNewItemForm(false);
    setFormField(field);
  };

  const addNewButtonProps: AutocompleteAddNewItemButtonProps = useMemo(() => {
    return {
      fieldName: field.name,
      isHide: !getIsShowAddNewItemButton(),
      label: field.addNewItemLabel || '',
      onClick: () => {
        if (field.addNewItemCallback) {
          setIsShowDropDown(false);
          return field.addNewItemCallback();
        }
        setFormField(field.createNewItemFormField as FormFieldModel);
      }
    };
  }, []);

  const renderPaperComponent = useCallback((props: any) => {
    const { children, options, lookupValue, multiple, loading, onSelectAll, ...restPaperProps } = props;
    const value = (lookupValue as FormFieldOption[]) || [];
    const optionsLength = options.filter((option: FormFieldOption) => !option.disabled)?.length || 0;

    const handleSelectAll = (checked: boolean) => {
      const options = formField.options ? formField.options.filter(option => !option.disabled) : [];
      setLookupValue(checked ? options || [] : null);
      setValue(formField.name, checked ? options.map(item => item.value) : [], defaultOptions);
    };

    return (
      <Paper {...restPaperProps}>
        {multiple && Boolean(optionsLength) && (
          <Box
            onMouseDown={(e: any) => e.preventDefault()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: '7px',
              mt: '5px',
              borderBottom: `1px solid ${theme.colors.grey100}`
            }}
          >
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              label='Alle auswählen'
              control={
                <StyledCheckbox
                  disabled={loading}
                  checked={value?.length === optionsLength}
                  indeterminate={Boolean(value.length) && value.length < optionsLength}
                  onChange={e => handleSelectAll(e.target.checked)}
                />
              }
            />
          </Box>
        )}
        {children}
      </Paper>
    );
  }, []);

  const renderListboxComponent = useCallback(
    (props: any) => {
      return <AutocompleteListbox {...props} addNewItemButton={addNewButtonProps} />;
    },
    [addNewButtonProps]
  );

  const renderPopperComponent = useCallback((props: any) => {
    const { dropDownAlwaysOnTop } = props;

    return (
      <Popper
        {...props}
        placeholder=''
        onPointerEnterCapture
        onPointerLeaveCapture
        placement={dropDownAlwaysOnTop ? 'top' : undefined}
      />
    );
  }, []);

  const getFieldByType = (field: FormFieldModel) => {
    const value = getValues(field.name) ?? field.defaultValue;

    if (field.type === FormFieldTypes.Text || field.type === FormFieldTypes.Number) {
      return (
        <div>
          <FormLabel />
          <Flex width={field.width || '100%'}>
            <StyledTextField
              id={field.name + PrefixForId.FormText}
              error={!!errors[field.name]}
              isPrefix={!!field.prefix}
              placeholder={field.placeholder || 'Bitte eintragen'}
              disabled={field.disabled}
              inputProps={{ style: { textTransform: field?.uppercase ? 'uppercase' : 'none' } }}
              InputProps={{
                inputComponent: field.type === FormFieldTypes.Number ? (InputNumberFormats as any) : undefined,
                autoComplete: 'off',
                startAdornment: field.prefix && (
                  <StyledInputAdornment position='start'>{field.prefix}</StyledInputAdornment>
                ),
                endAdornment: getIsShowAddNewItemButton() && (
                  <ReturnToInitialFieldButton onClick={returnToInitialFormField} />
                )
              }}
              onChange={e => {
                const inputValue = e.target.value;

                setValue(field.name, inputValue?.toString(), defaultOptions);
                forceUpdate();
              }}
              value={
                field.type === FormFieldTypes.Number
                  ? getValues(field.name)
                    ? Number(getValues(field.name))
                    : undefined
                  : value
              }
            />
          </Flex>
        </div>
      );
    }
    if (field.type === FormFieldTypes.OptionSet) {
      const value = getValues(field.name) || field.defaultValue;
      const hidePlaceholder = Array.isArray(value) ? !!value.length : !!value;
      return (
        <div>
          <FormLabel />
          <Flex width={field.width || '100%'}>
            <StyledSelect
              error={!!errors[field.name]}
              disabled={field.disabled}
              multiple={field.multiple}
              value={value}
              id={field.name + PrefixForId.FormSelect}
              IconComponent={StyledArrowDownIcon}
              defaultValue={field.defaultValue}
              displayEmpty
              onChange={e => setValue(field.name, e.target.value, defaultOptions)}
              renderValue={
                hidePlaceholder
                  ? undefined
                  : () => (
                      <Text as='span' color='grey250' style={{ letterSpacing: '0.02em' }}>
                        {field.placeholder || 'Bitte auswählen'}
                      </Text>
                    )
              }
            >
              {field.componentAboveOptions &&
                cloneElement<RenderComponentAboveOptionsProps>(field.componentAboveOptions, { field, value })}
              {field.options?.length ? (
                field.options.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    id={`${field.name + PrefixForId.FormSelect}-option-${index}`}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.showEndAdornment ? (
                      <Flex justify='space-between' width='100%' align='center'>
                        {option.label}

                        {option.showEndAdornment && <Flex align='center'>{field.endAdormentIcon?.()}</Flex>}
                      </Flex>
                    ) : (
                      <>{option.label}</>
                    )}
                  </MenuItem>
                ))
              ) : (
                <Text color='grey600' margin='15px'>
                  {field.emptyOptionsPlaceholder || 'Keine Optionen vorhanden.'}
                </Text>
              )}
            </StyledSelect>
          </Flex>
        </div>
      );
    }
    if (field.type === FormFieldTypes.Lookup) {
      return (
        <div style={{ width: field.width || '100%' }}>
          <FormLabel />
          <StyledAutocomplete
            error={!!errors[field.name]}
            id={field.name + PrefixForId.FormLookup}
            multiple={field.multiple}
            options={field.options as FormFieldOption[]}
            disableCloseOnSelect={Boolean(field.multiple)}
            //@ts-ignore
            isOptionEqualToValue={(option, { value }) => option.value === value}
            noOptionsText={
              <AutocompleteEmptyPlaceholder placeholder={field.placeholder} addNewItemButton={addNewButtonProps} />
            }
            popupIcon={
              <ArrowDownIcon id={field.name + PrefixForId.FormLookup + 'OpenPopupButton'} width={18} height={18} />
            }
            clearIcon={
              <CloseIcon id={field.name + PrefixForId.FormLookup + PrefixForId.Close} width='11' height='11' />
            }
            open={isShowDropDown}
            onOpen={() => setIsShowDropDown(true)}
            onClose={() => setIsShowDropDown(false)}
            disabled={field.disabled}
            getOptionDisabled={option => !!(option as FormFieldOption).disabled}
            onChange={(e, value) => {
              const autocompleteValue = Array.isArray(value)
                ? value.map(item => item.value)
                : (value as FormFieldOption)?.value || '';
              setLookupValue(value as FormFieldOption);
              setValue(field.name, autocompleteValue || null, defaultOptions);
            }}
            onBlur={() => {
              setIsShowDropDown(false);
              setInputValue('');
            }}
            value={field.multiple ? lookupValue || [] : lookupValue}
            onInputChange={(event, value, reason) => {
              if (reason !== 'reset') {
                setInputValue(value);
              }

              field.onInputChange && field.onInputChange(event, value);
            }}
            renderOption={(props, option: any, { selected }) => {
              return (
                <li {...props} key={option.id} style={{ marginBottom: '5px', opacity: 1 }}>
                  {field.multiple && (
                    <StyledCheckbox
                      id={field.name + PrefixForId.Checkbox}
                      disabled={loading || option.disabled}
                      style={{ marginRight: 8, padding: 0 }}
                      checked={selected}
                    />
                  )}
                  <Flex direction='column' width='100%'>
                    {option.showEndAdornment ? (
                      <Flex justify='space-between' align='center'>
                        <EllipsisWithTooltip
                          tooltip={(option as FormFieldOption).label}
                          tooltipProps={{ isHideTooltipForMobile: true }}
                        >
                          <span style={{ opacity: option.disabled ? 0.4 : 1 }}>
                            {(option as FormFieldOption).label}
                          </span>
                        </EllipsisWithTooltip>

                        {option.showEndAdornment && <Flex align='center'>{field.endAdormentIcon?.()}</Flex>}
                      </Flex>
                    ) : (
                      <EllipsisWithTooltip
                        tooltip={(option as FormFieldOption).label}
                        tooltipProps={{ isHideTooltipForMobile: true }}
                      >
                        <span style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                          {typeof (option as FormFieldOption)?.active === 'boolean' && (
                            <Circle
                              size='7px'
                              marginRight='3px'
                              color={getStatusColor((option as FormFieldOption).active ? 0 : 1)}
                            />
                          )}
                          {(option as FormFieldOption).label}
                        </span>
                      </EllipsisWithTooltip>
                    )}
                    {(option as FormFieldOption).description && (
                      <Text color='grey500' size='10'>
                        {option.description}
                      </Text>
                    )}
                  </Flex>
                </li>
              );
            }}
            renderInput={params => (
              <StyledTextFieldWithoutBorders
                {...params}
                error={!!errors[field.name]}
                placeholder={field.emptyOptionsPlaceholder ? field.emptyOptionsPlaceholder : 'Bitte auswählen'}
              />
            )}
            ListboxComponent={renderListboxComponent}
            limitTags={field.multiple ? field.limitTags || 1 : undefined}
            PaperComponent={renderPaperComponent}
            disablePortal
            componentsProps={{
              paper: {
                options: field.options,
                lookupValue,
                multiple: field.multiple,
                loading
              } as PaperProps,
              popper: {
                open: isShowDropDown,
                dropDownAlwaysOnTop: field.dropDownAlwaysOnTop
              } as PopperProps
            }}
            renderTags={(value: any) => {
              return value.map((item: any, index: number) => (
                <EllipsisWithTooltip maxWidth={field?.valueMaxWidth || '70px'} tooltip={item.label} key={index}>
                  <Text margin='0 2px'>{item.label}</Text>
                </EllipsisWithTooltip>
              ));
            }}
            PopperComponent={renderPopperComponent}
          />
        </div>
      );
    }
    if (field.type === FormFieldTypes.Date) {
      return (
        <div className={field.className} style={{ width: field.width || '100%' }}>
          {field.label && (
            <Flex bottom='5'>
              <Text as='span' color='darkBlue'>
                {field.label}
              </Text>
              {(field.validation.required || field.alwaysShowAsRequiredField) && (
                <Text left='5' as='span' color='pink'>
                  *
                </Text>
              )}
            </Flex>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledDatePicker
              views={field.calendarViews}
              error={!!errors[field.name]}
              components={{
                OpenPickerIcon: () => (
                  <IconHover id={field.name + PrefixForId.FormDate + PrefixForId.Open}>
                    <CalendarWithCheckMarkIcon color={field.disabled ? theme.colors.grey300 : theme.colors.darkBlue} />
                  </IconHover>
                )
              }}
              PopperProps={{
                id: field.name + PrefixForId.FormDate + PrefixForId.Calendar
              }}
              disabled={field.disabled}
              mask={field.mask || '__.__.____'}
              minDate={field.minDate}
              maxDate={field.maxDate}
              value={getValues(field.name) ?? field.defaultValue}
              onChange={(value: unknown) => setValue(field.name, value || '', defaultOptions)}
              renderInput={({ inputProps, ...restParams }) => (
                <StyledTextFieldWithoutBorders
                  {...restParams}
                  error={!!errors[field.name]}
                  inputProps={{
                    ...inputProps,
                    placeholder: field.placeholder || 'TT . MM . JJJJ',
                    id: field.name + PrefixForId.FormDate,
                    error: !!errors[field.name]
                  }}
                  helperText={field.helperText}
                />
              )}
              shouldDisableDate={field.shouldDisableDate}
              renderDay={field.renderDay}
              disablePast={field.disablePast}
            />
          </LocalizationProvider>
        </div>
      );
    }
    if (field.type === FormFieldTypes.Checkbox) {
      return (
        <FormGroup>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                id={field.name + PrefixForId.Checkbox}
                onChange={e => setValue(field.name, e.target.checked, defaultOptions)}
                disabled={field.disabled}
                checked={getValues(field.name) || field.defaultValue}
                isError={!!errors[field.name]}
              />
            }
            label={field.label}
          />
        </FormGroup>
      );
    }
    if (field.type === FormFieldTypes.MulipleCheckoxes) {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const newOptions = (getValues(field.name) || field.options).map((item: any, index: number) => {
          if (index === currentIndex) {
            return {
              ...item,
              isChecked: e.target.checked
            };
          }
          return item;
        });
        setValue(field.name, newOptions, defaultOptions);
      };

      return (
        <FormControl>
          <Flex bottom='5'>
            <Text as='span' color='darkBlue'>
              {field.label}
            </Text>
            {field.validation.required && (
              <Text left='5' as='span' color='pink'>
                *
              </Text>
            )}
          </Flex>
          <FormGroup
            style={{ flexDirection: field.direction === 'row' ? 'row' : 'column', height: field.height || undefined }}
          >
            {field.options?.map((option, index) => (
              <>
                {!option.hidden && (
                  <StyledFormControlLabel
                    key={option.value}
                    control={
                      <StyledCheckbox
                        id={field.name + option.label.replace(/ /g, '_') + PrefixForId.Checkbox}
                        onChange={e => handleChange(e, index)}
                        disabled={option.disabled || field.disabled}
                        checked={getValues(field.name) ? getValues(field.name)[index].isChecked : option.isChecked}
                      />
                    }
                    label={option.italic ? <i>{option.label!}</i> : option.label}
                    sx={{
                      marginBottom: 0,
                      width: field.columnWidth ? field.columnWidth : 'auto'
                    }}
                  />
                )}
              </>
            ))}
          </FormGroup>
        </FormControl>
      );
    }
    if (field.type === FormFieldTypes.Radio) {
      return (
        <FormControl className={field.className}>
          <Flex bottom='5' style={!field.label ? { opacity: '0' } : {}} align='center'>
            <Text as='span' color='darkBlue'>
              {field.label}
            </Text>
            {field.validation.required && (
              <Text left='5' as='span' color='pink'>
                *
              </Text>
            )}
            {field.tooltipText && (
              <Tooltip title={field.tooltipText}>
                <Flex left='10' align='center'>
                  <CircleInfoIcon color={theme.colors.grey400} />
                </Flex>
              </Tooltip>
            )}
          </Flex>
          <RadioGroup
            name={field.name}
            row={field.radioButtonsInRow}
            defaultValue={field.defaultValue}
            value={getValues(field.name) || field.defaultValue}
            onChange={e =>
              field.radioButtonsOnChange
                ? field.radioButtonsOnChange(field.name, e.target.value)
                : setValue(field.name, e.target.value, defaultOptions)
            }
          >
            {field.options?.map(option => {
              const isChecked = getValues(field.name)
                ? getValues(field.name) === option.value
                : field.defaultValue === option.value;
              return (
                <div key={option.value}>
                  <StyledRadioButtonLabel
                    radiobuttonsrowdistance={field.radioButtonsRowDistance}
                    control={
                      <StyledRadioButton
                        id={field.name + option.label.replace(/ /g, '_') + PrefixForId.Radio}
                        disabled={option.disabled || field.disabled}
                        checked={isChecked}
                      />
                    }
                    label={option.label}
                    value={option.value}
                    disabled={option.disabled}
                    sx={{
                      marginBottom: 0
                    }}
                  />

                  {option.bottomComponent?.({ isChecked })}
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    }
    if (field.type === FormFieldTypes.Range) {
      return (
        <div>
          <FormLabel />
          <Flex align='flex-start' left={field.marginLeft} width={field.width || '100%'}>
            <StyledSlider
              id={field.name + PrefixForId.FormSlider}
              value={getValues(field.name) || field.defaultValue}
              marks={field.marks}
              min={field.min}
              max={field.max}
              step={field.step}
              onChange={(e, value) => setValue(field.name, value, defaultOptions)}
            />
            {field.rightLabel && (
              <Text color={theme.colors.grey500} left='10' top='5' size='12'>
                {field.rightLabel}
              </Text>
            )}
          </Flex>
        </div>
      );
    }
    if (field.type === FormFieldTypes.Toggle) {
      return (
        <Flex padding='10px 0 0' align='center'>
          <StyledSwitch
            id={field.name + PrefixForId.Toggle}
            checked={getValues(field.name) ?? field.defaultValue}
            onChange={e => setValue(field.name, e.target.checked, defaultOptions)}
            disabled={field.disabled}
          />
          {field.toggleLabels && (
            <Text
              pointer
              left='10'
              onClick={() =>
                !field.disabled && setValue(field.name, !(getValues(field.name) ?? field.defaultValue), defaultOptions)
              }
            >
              {field.toggleLabels.find(
                toggleLabel => toggleLabel.value === (getValues(field.name) ?? field.defaultValue)
              )?.label || ''}
            </Text>
          )}
        </Flex>
      );
    }
    if (field.type === FormFieldTypes.Money) {
      return (
        <div>
          <FormLabel />
          <StyledTextField
            id={field.name + PrefixForId.FormText}
            error={!!errors[field.name]}
            name={field.name}
            placeholder={field.placeholder || 'Bitte eintragen'}
            disabled={field.disabled}
            onChange={e => setValue(field.name, e.target.value?.toString(), defaultOptions)}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
            value={typeof getValues(field.name) === 'number' ? Number(getValues(field.name)) : undefined}
          />
        </div>
      );
    }
    if (field.type === FormFieldTypes.Percentages) {
      return (
        <div>
          <FormLabel />
          <StyledTextField
            id={field.name + PrefixForId.FormText}
            error={!!errors[field.name]}
            name={field.name}
            placeholder='Bitte eintragen'
            disabled={field.disabled}
            onChange={e => setValue(field.name, e.target.value ?? '', defaultOptions)}
            InputProps={{
              inputComponent: NumberFormatPercentages as any,
              inputProps: {
                decimalScale: field.decimalScale ?? 1
              }
            }}
            value={getValues(field.name) ?? field.defaultValue}
          />
        </div>
      );
    }
    if (field.type === FormFieldTypes.Months) {
      return (
        <div>
          <FormLabel />
          <StyledTextField
            id={field.name + PrefixForId.FormText}
            error={!!errors[field.name]}
            name={field.name}
            disabled={field.disabled}
            onChange={e => setValue(field.name, e.target.value ?? '', defaultOptions)}
            InputProps={{
              inputComponent: NumberFormatMonths as any
            }}
            value={getValues(field.name) ?? field.defaultValue}
          />
        </div>
      );
    }
    if (field.type === FormFieldTypes.Kilometers) {
      return (
        <div>
          <FormLabel />
          <StyledTextField
            id={field.name + PrefixForId.FormText}
            error={!!errors[field.name]}
            name={field.name}
            disabled={field.disabled}
            onChange={e => setValue(field.name, e.target.value ?? '', defaultOptions)}
            InputProps={{
              inputComponent: NumberFormatKilometers as any
            }}
            value={getValues(field.name) ?? field.defaultValue}
          />
        </div>
      );
    }
    if (field.type === FormFieldTypes.File) {
      return <FileField />;
    }
    if (field.type === FormFieldTypes.TextArea) {
      return <Textarea />;
    }
    if (field.type === FormFieldTypes.Phone) {
      return <PhoneNumberField />;
    }
    if (field.type === FormFieldTypes.Time) {
      return <TimeField borderColor={borderColor} />;
    }
    if (field.type === FormFieldTypes.DateTime) {
      return <DateTimeField />;
    }
    if (field.type === FormFieldTypes.CarDamage) {
      return <CarDamageField />;
    }
    if (field.type === FormFieldTypes.OptionSetFile) {
      return (
        <OptionSetFile
          type={FormFieldTypes.OptionSetFile}
          values={getValues(field.name) || []}
          onChange={value => setValue(field.name, value ?? '', defaultOptions)}
        />
      );
    }
    if (field.type === FormFieldTypes.LookupFile) {
      const optionIndexesErrorJSON = errors[field.name + OptionSetFileErrorEnum.Option]?.type;

      return (
        <OptionSetFile
          //@ts-ignore
          optionIndexesError={optionIndexesErrorJSON ? JSON.parse(optionIndexesErrorJSON) : []}
          type={FormFieldTypes.LookupFile}
          values={getValues(field.name) || []}
          onChange={value => setValue(field.name, value ?? '', defaultOptions)}
        />
      );
    }
  };

  const providerProps: IFormFieldContext = {
    field: formField,
    error: errors[field.name],
    setError,
    value: getValues(field.name) ?? (field.defaultValue || null),
    returnToInitialFormField,
    clearErrors,
    onChange: value => setValue(field.name, value, defaultOptions),
    forceUpdate,
    isShowAddNewItemButton: getIsShowAddNewItemButton(),
    reactHookFormData
  };

  return (
    <formFieldProvider.Provider value={providerProps}>
      <StyledFormFieldContainer
        className={field.formFieldContainerClassName}
        hasLabel={!!formField.label && formField.type !== FormFieldTypes.Checkbox}
        direction='column'
        bottom={distanceBetweenFields}
        left={marginLeft}
      >
        {loading && !isMultipleLookupField ? (
          <Spinner
            style={{
              width: '30px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        ) : (
          <>
            <StyledFormControl styledtheme={theme} error={!!errors[formField.name]} className={field.className}>
              {getFieldByType(formField)}

              {formField.infoHint ? <InfoHint {...formField.infoHint} className='mt-20' /> : null}
              <Tip />
            </StyledFormControl>

            {formField.infoNote && (
              <Text size='12' color='grey400'>
                {formField.infoNote}
              </Text>
            )}

            {errors[formField.name]?.message && !field.hideError && (
              <Flex id={`${field.name}Error`} top='5'>
                <CircleExclamationMark
                  color={theme.colors.pink}
                  width={'12'}
                  height={'12'}
                  style={{ marginTop: '3px', minWidth: '12px' }}
                />
                <Text left='5' as='span' size='12' color='pink'>
                  {errors[formField.name]?.message as ReactNode}
                </Text>
              </Flex>
            )}
          </>
        )}
      </StyledFormFieldContainer>
    </formFieldProvider.Provider>
  );
};

export default FormField;
