import { IconProps } from 'common/interfaces';
import React from 'react';

export const ExclamationMarkIcon = ({ width = '16', height = '16', color = '#7F002A', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.98304 10.4409C8.18643 10.4409 8.32203 10.5087 8.45762 10.6443C8.59321 10.7799 8.66101 10.9155 8.66101 11.1189C8.66101 11.3223 8.59321 11.4579 8.45762 11.5935C8.32203 11.7291 8.18643 11.7969 7.98304 11.7969C7.77965 11.7969 7.64406 11.7291 7.50847 11.5935C7.37288 11.4579 7.30508 11.3223 7.30508 11.1189C7.30508 10.9155 7.37288 10.7121 7.50847 10.6443C7.64406 10.5087 7.84745 10.4409 7.98304 10.4409ZM7.30508 9.01721L7.30508 5.01721C7.30508 4.74603 7.37288 4.54264 7.50847 4.40704C7.64406 4.27145 7.77966 4.20365 7.98305 4.20365C8.18644 4.20365 8.32203 4.27145 8.45762 4.40704C8.59321 4.54264 8.66101 4.74603 8.66101 5.01721L8.66101 8.94942C8.66101 9.2206 8.59321 9.42399 8.45762 9.55959C8.32203 9.69518 8.18643 9.76298 7.98304 9.76298C7.77965 9.76298 7.64406 9.69518 7.50847 9.55959C7.37288 9.49179 7.30508 9.2884 7.30508 9.01721Z'
        fill={color}
      />
      <path
        d='M8 16C5.89831 16 3.86441 15.1864 2.37288 13.6949C0.813559 12.1356 0 10.1017 0 8C0 5.89831 0.813559 3.86441 2.37288 2.37288C3.86441 0.813559 5.83051 0 8 0C10.1017 0 12.1356 0.813559 13.6271 2.30508C16.7458 5.42373 16.7458 10.5085 13.6271 13.6271C12.1356 15.1186 10.1017 16 8 16ZM8 1.35593C6.23729 1.35593 4.54237 2.0339 3.32203 3.32203C2.0339 4.54237 1.35593 6.23729 1.35593 8C1.35593 9.76271 2.0339 11.4576 3.32203 12.678C4.54237 13.8983 6.23729 14.6441 8 14.6441C9.76271 14.6441 11.4576 13.9661 12.678 12.678C15.2542 10.1017 15.2542 5.89831 12.678 3.32203C11.3898 2.0339 9.76271 1.35593 8 1.35593Z'
        fill={color}
      />
    </svg>
  );
};
