import { useContext, useEffect } from 'react';
import { ChangeCostCenterFieldNames } from '../change-cost-center/change-cost-center.types';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { ChangeCostCenterContext } from '../change-cost-center/change-cost-center.context';

const useFillHistoryFields = (reactHookFormData: UseFormReturn) => {
  const { item } = useContext(ChangeCostCenterContext);
  const { setValue } = reactHookFormData;

  useEffect(() => {
    if (item) {
      Object.values(ChangeCostCenterFieldNames).forEach(fieldName => {
        setValue(fieldName, item[fieldName] ? moment(item[fieldName]) : item[fieldName], defaultOptions);
      });
    }
  }, [item]);
};

export default useFillHistoryFields;
