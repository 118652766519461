import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { previousLocationChange } from '../../actions/app_action';
import { useDispatch } from 'react-redux';

const LastRouterLocation = ({ children, location }) => {
  const currentLocation = `${location.pathname}${location.search}`;
  const [previousLocation, setPreviousLocation] = useState(currentLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentLocation !== previousLocation) {
      dispatch(previousLocationChange(previousLocation));
      setPreviousLocation(currentLocation);
    }
  });

  return children;
};

LastRouterLocation.propTypes = {
  location: PropTypes.any,
  children: PropTypes.any
};

export default withRouter(LastRouterLocation);
