import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ActiveElement, ArcElement, Chart, ChartData, ChartOptions } from 'chart.js';
import { DataQuality, HandleAmountClickParams } from './data-quality-chart.types';
import { useTheme } from 'styled-components';
import { AnyObject } from 'common/interfaces';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { PAGES } from 'common/enums';
import { CRM_VALUE } from 'pages/my-vehicles/my-vehicles';
import {
  DataQualityLabel,
  DataQualityValue,
  VehicleStateCodeLabel,
  VehicleStateCodeValue,
  VehicleStatusesLabel,
  VehicleStatusesValue
} from 'pages/my-vehicles/my-vehicles.props';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

export const useDataQualityChart = (dataQuality: DataQuality) => {
  const [segmentOffsets, setSegmentOffsets] = useState<number[]>([20, 0, 0]);
  const [hoveredSegment, setHoveredSegment] = useState<ActiveElement | null>(null);
  const [choosenSegment, setChoosenSegment] = useState<ActiveElement | null>(null);
  const [activeSegment, setActiveSegment] = useState<number | null>(0);
  const chartRef = useRef<Chart | null>(null);
  const theme = useTheme();
  const { openPage } = useContext(FleetManagerDashboardContext);

  const qualities = useMemo(() => {
    return [
      {
        amount: dataQuality.good,
        color: 'green',
        label: 'Datenqualität ist gut',
        filterValue: { serverValue: DataQualityValue.Good, clientValue: DataQualityLabel.Good }
      },
      {
        amount: dataQuality.bad,
        color: 'pink',
        label: 'Datenqualität ist schlecht',
        filterValue: { serverValue: DataQualityValue.Bad, clientValue: DataQualityLabel.Bad }
      },
      {
        amount: dataQuality.average,
        color: 'gold',
        label: 'Datenqualität ist mittel',
        filterValue: { serverValue: DataQualityValue.Average, clientValue: DataQualityLabel.Average }
      }
    ];
  }, [dataQuality]);

  const totalSum = useMemo(() => {
    return qualities.map(quality => quality.amount).reduce((sum, segment) => sum + segment, 0);
  }, [qualities]);

  const colors = useMemo(() => {
    return qualities.map(quality => theme.colors[quality.color]);
  }, [qualities]);

  const percentages = useMemo(() => {
    const minPercentage = 3;

    return qualities.map(quality => (Math.max(quality.amount, minPercentage) / totalSum) * 100) || [];
  }, [qualities]);

  const options: ChartOptions<'doughnut'> = {
    cutout: '85%',
    elements: {
      arc: {
        borderColor: (context: AnyObject) => {
          return context.dataset.hoverBackgroundColor[context.index];
        },
        borderJoinStyle: 'miter'
      }
    },
    radius: 74,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onClick: (_, segments: ActiveElement[]) => {
      if (segments.length > 0) {
        const segmentIndex = segments[0].index;
        setActiveSegment(segmentIndex);
        const newOffsets = [0, 0, 0];
        newOffsets[segmentIndex] = 20;
        setSegmentOffsets(newOffsets);
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const canvas = event.native && (event.native.target as HTMLCanvasElement);
      if (canvas && elements.length > 0) {
        const newSegment = elements[0] as ActiveElement;
        if (!hoveredSegment || newSegment.index !== hoveredSegment.index) {
          setHoveredSegment(newSegment);
          const newOffsets = [0, 0, 0];
          newOffsets[newSegment.index] = 20;
          setSegmentOffsets(newOffsets);
        }
        canvas.style.cursor = 'pointer';
      } else if (canvas) {
        canvas.style.cursor = 'default';
        if (activeSegment !== null && hoveredSegment?.index !== activeSegment) {
          setHoveredSegment(activeSegment !== null ? ({ index: activeSegment } as ActiveElement) : null);
        }
        const newOffsets = [0, 0, 0];
        if (activeSegment !== null) {
          newOffsets[activeSegment] = 20;
        }
        if (JSON.stringify(segmentOffsets) !== JSON.stringify(newOffsets)) {
          setSegmentOffsets(newOffsets);
        }
      }
    }
  };

  const chartData: ChartData<'doughnut', number[], string> = useMemo(() => {
    return {
      labels: Object.keys(dataQuality)?.map((item: string) => item) || [],
      datasets: [
        {
          data: percentages,
          rotation: 240,
          offset: segmentOffsets,
          hoverBackgroundColor: colors,
          hoverBorderColor: colors,
          borderWidth: 0,
          backgroundColor: colors,
          borderJoinStyle: 'round',
          spacing: 0
        }
      ]
    };
  }, [dataQuality, percentages, segmentOffsets, colors]);

  const showSegmentDescription = useMemo(() => {
    return choosenSegment && chartData?.labels;
  }, [choosenSegment, chartData]);

  const handleRef = (instance: ChartJSOrUndefined<'doughnut', number[], string> | null) => {
    const context = instance?.getContext();

    if (instance && context && context.chart) {
      chartRef.current = context.chart;
    }
  };

  const amounHandleClick = ({ serverValue, clientValue }: HandleAmountClickParams) => {
    openPage({
      page: PAGES.MY_VEHICLES,
      companyCrmName: CRM_VALUE.company,
      filterForServer: {
        [CRM_VALUE.qualityData]: serverValue,
        [CRM_VALUE.stateCode]: [VehicleStateCodeValue.Active],
        [CRM_VALUE.status]: [
          VehicleStatusesValue.InOperation,
          VehicleStatusesValue.ControlRequested,
          VehicleStatusesValue.InControl
        ]
      },
      filterForClient: {
        [CRM_VALUE.qualityData]: clientValue,
        [CRM_VALUE.stateCode]: [VehicleStateCodeLabel.Active],
        [CRM_VALUE.status]: [
          VehicleStatusesLabel.InOperation,
          VehicleStatusesLabel.ControlRequested,
          VehicleStatusesLabel.InControl
        ]
      }
    })();
  };

  useEffect(() => {
    if (chartRef.current) {
      const initialIndex = 0;
      const arc = chartRef.current.getDatasetMeta(0).data[initialIndex] as ArcElement;

      if (arc) {
        const initialSegment = { index: initialIndex } as ActiveElement;
        setChoosenSegment(initialSegment);
        setHoveredSegment(initialSegment);
      }
    }
  }, [dataQuality]);

  return { qualities, chartData, options, showSegmentDescription, hoveredSegment, handleRef, amounHandleClick };
};
