import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSmoothTransitionBetweenSegmentsPlugin } from './hooks/plugins/use-smooth-transition-between-segments/use-smooth-transition-between-segments';
import { doughnutWarningDiagramOptions } from './utils';
import { DoughnutWarningDiagramProps } from './doughnut-warning.props';
import { useDoughnutWarningData } from './hooks/use-data';
import { AbsoluteCenterAlign, Flex } from 'common/common-components.styled';

export const DoughnutWarningDiagram = (props: DoughnutWarningDiagramProps) => {
  const { valueColor, warningColor, warningPercent, children } = props;

  const { doughnutWarningData, calmValue } = useDoughnutWarningData(props);
  const { smoothTransitionBetweenSegmentsPlugin } = useSmoothTransitionBetweenSegmentsPlugin({
    main: { color: valueColor, value: calmValue },
    transitionElement: { color: warningColor, value: warningPercent }
  });

  return (
    <Flex position='relative'>
      <Doughnut
        plugins={[smoothTransitionBetweenSegmentsPlugin]}
        data={doughnutWarningData}
        options={doughnutWarningDiagramOptions}
      />

      <AbsoluteCenterAlign>{children}</AbsoluteCenterAlign>
    </Flex>
  );
};
