import React from 'react';
import { MainCarModalSubmitButtonProps } from './submit-button.props';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import Button from 'components/button';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../../../constants';
import { useQueryClient } from 'react-query';
import { GET_MY_VEHICLE_QUERY_KEY } from 'hooks/react-query/dashboard/get-my-vehicle/get-my-vehicle.query';
import { ReactQueryKeys } from 'common/enums';

export const MainCarModalSubmitButton = ({ children, submitData }: MainCarModalSubmitButtonProps) => {
  const { fetch } = useFetch({
    loadingKey: 'updateMainCar',
    ...fleetRequestConfig.updateMainCar(submitData.fuhrparkId, submitData.isMainCar)
  });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submitHandler = () => {
    closeAlert();
    fetch({
      callback: () => {
        queryClient.resetQueries([GET_MY_VEHICLE_QUERY_KEY]);
        queryClient.resetQueries([ReactQueryKeys.FleetById]);
      }
    });
  };

  return <Button onClick={submitHandler}>{children}</Button>;
};
