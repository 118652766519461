import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { VehicleDamageHistoryResponseItem } from 'hooks/react-query/fleet/damage-history/use-damage-history.props';
import { DamageServiceId } from 'hooks/react-query/damage/get-damage-by-id/get-damage-by-id.props';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';

export const VehicleDetailsDamageHistoryStepDescription = ({ attributes, id }: VehicleDamageHistoryResponseItem) => {
  const history = useHistory();

  const serviceLabel = attributes?.uds_serviceid?.id === DamageServiceId.Breakdown ? 'Panne' : 'Schadenfall';

  const openDamageDetailsPage = () => {
    history.push({ pathname: PAGES.DAMAGE_DETAILS, search: `?id=${id}` });
  };

  return (
    <Flex direction='column'>
      <Text onClick={openDamageDetailsPage} color='blue' size='20' bold pointer>
        {`${serviceLabel} ${attributes?.title || '-'}`}
      </Text>

      <Text color='blue' size='12'>
        {attributes?.new_schadenuhrzeit?.label?.slice(0, 10)}
      </Text>
    </Flex>
  );
};
