/* eslint-disable no-unused-vars */
export enum RepairStatusEnum {
  NewOrder = 'Neuer Auftrag',
  RepairDirectedToWorkshop = 'Reparatur an Werkstatt gesteuert',
  PreviewPlanned = 'Vorbesichtigung Geplant',
  InvoiceSent = 'Rechnung versendet'
}

export enum RepairPartnerStatusCode {
  Active = 0,
  Inactive = 1
}

export enum RepairPartnerStatusLabel {
  Active = 'Aktiv',
  Inactive = 'Inaktiv'
}

export enum RepairPartnerStageLabel {
  ClaimReportRequestedEscalation = 'BC: Schadenmeldung angefordert: Eskalation',
  ClaimReportRequestedReminder = 'BC: Schadenmeldung angefordert - Erinnerung',
  DamageReportReceived = 'BC: Schadenmeldung eingegangen',
  ClaimReportRequested = 'BC: Schadenmeldung angefordert',
  HandedOverToVispironMobility = '10. An Vispiron Mobilty übergeben',
  CollectionDate = '1. Abholtermin',
  WaitForKVToCalculate = '2. Warte auf KV zu kalkulieren',
  WaitingForKVToBeCalculatedPreInspection = '2.2 Warte auf KV zu kalkulieren Vorbesichtigung',
  WaitingForCustomerInsuranceApproval = '3. Warte auf Freigabe von Kunde/Leasing Versicherung',
  WaitingForApprovalAfterPreview = '3.2 Warte auf Freigabe nach Vorbesichtigung',
  WaitForCompletion = 'Warte auf Fertigstellung',
  WaitingForBill = '5. Warte auf Rechnung',
  WaitingForOpinions = '6. Warte auf Gutachten',
  ReceivedFromRepairPartner = '7. Rg.von Reppartner erhalten',
  EightRoundCreated = '8. Rg. erstellt',
  FullyPaid = '8.1. Komplett bezahlt / keine OPs',
  DunningLevel = '8.2. Mahnstufe',
  Closed = 'Geschlossen',
  InProgress = 'In Bearbeitung',
  WaitingForRepairDate = 'Warten auf Reparaturtermin',
  RepairDateConfirmed = 'Reparaturtermin bestätigt',
  OrderRejected = 'Auftrag abgelehnt',
  SubmittedCostExtension = 'Kostenerweiterung eingereicht',
  OrderCanceledByCustomer = 'Auftrag durch Kunden storniert',
  KVAReceivedAfterPreview = 'KVA nach Vorbesichtigung erhalten',
  CommissionedPreview = 'Vorbesichtigung beauftragt',
  WaitingForKVToCalculatePreview = 'Warte auf KV zu kalkulieren Vorbesichtigung',
  ApprovalWaitingForCollectionDate = 'Freigabe erteilt/Warte auf Abholtermin',
  LAFInProgress = 'LAF: In Bearbeitung',
  LAFCompleted = 'LAF: Erledigt'
}

export enum RepairPartnerStageCode {
  ClaimReportRequestedEscalation = 100_000_013,
  ClaimReportRequestedReminder = 100_000_012,
  DamageReportReceived = 100_000_011,
  ClaimReportRequested = 100_000_010,
  HandedOverToVispironMobility = 100_000_001,
  CollectionDate = 400,
  WaitForKVToCalculate = 500,
  WaitingForKVToBeCalculatedPreInspection = 502,
  WaitingForCustomerInsuranceApproval = 600,
  WaitingForApprovalAfterPreview = 100_000_017,
  WaitForCompletion = 700,
  WaitingForBill = 800,
  WaitingForOpinions = 900,
  ReceivedFromRepairPartner = 950,
  EightRoundCreated = 1_000,
  FullyPaid = 1_001,
  DunningLevel = 100_000_000,
  Closed = 1_050,
  InProgress = 100_000_002,
  WaitingForRepairDate = 100_000_004,
  RepairDateConfirmed = 100_00_005,
  OrderRejected = 100_00_006,
  SubmittedCostExtension = 100_00_007,
  OrderCanceledByCustomer = 100_000_008,
  KVAReceivedAfterPreview = 100_000_009,
  CommissionedPreview = 100_000_014,
  WaitingForKVToCalculatePreview = 100_000_015,
  ApprovalWaitingForCollectionDate = 100_000_016,
  LAFInProgress = 100_001_010,
  LAFCompleted = 100_001_020
}

export interface IRepairPartnerTable {
  Status: RepairStatusEnum;
  composit_adress: string;
  emailaddress1: string;
  new_kennzeichen: string;
  new_repairpartnerid: string;
  new_schadenuhrzeit: string;
  telephone1: string;
  title: string;
  uds_besichtigungstermin: string;
  uds_geplanter_fertigstellungstermin_werkstatt: string;
  uds_pickupcardatetime: string;
  incidentid: string;
  statecode: RepairPartnerStatusLabel;
  statecode_value: RepairPartnerStatusCode;
  incidentstagecode: RepairPartnerStageLabel;
  incidentstagecode_value: RepairPartnerStageCode;
}
