import { ApprovalRecord } from './hooks/use-approvals.types';

export interface ApproveStatusProps {
  approvalRecords: ApprovalRecord[];
}

export enum ApproverType {
  Internal = 100000000,
  External = 100000001
}
