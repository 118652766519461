import React, { useEffect } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import isEmpty from 'lodash/isEmpty';
import LinearLoader from 'components/linear-loader';
import { LoadingState, OverlayProps } from './overlay.props';
import { PickValue } from 'common/interfaces';
import { StyledOverlay } from 'components/overlay/overlay.styled';

const Overlay = ({ show = false, noScrollToTop, className }: OverlayProps) => {
  const loading = useTypedSelector<LoadingState>(state => state['app'].loading);

  useEffect(() => {
    toggleOverflow(loading.state);

    return () => {
      toggleOverflow({});
    };
  }, [loading.state]);

  const toggleOverflow = (state: PickValue<LoadingState, 'state'>) => {
    if (isEmpty(state)) {
      document.body.style.overflow = 'hidden';
      if (!noScrollToTop) {
        window.scrollTo(0, 0);
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  if (isEmpty(loading.state) && !show) {
    return null;
  }

  return (
    <StyledOverlay className={className} height={loading.isExtendedHeight ? '700%' : undefined} id={'overlayContainer'}>
      <LinearLoader show={true} />
    </StyledOverlay>
  );
};

export default Overlay;
