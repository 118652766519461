import { crmDataTypes } from '../../constants';

export const enterEmailFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'emailaddress1',
          type: 'text',
          placeholder: 'muster@mail.com',
          label: 'E-mailadresse',
          crmFieldType: crmDataTypes.String,
          validation: [{ name: 'required'}, { name: 'email' }]
        }
      ]
    }
  ]
};
