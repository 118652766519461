import React from 'react';
import { CustomPickerDayTooltipProps } from './custom-picker-day-tooltip.types';
import { PickersDay } from '@mui/x-date-pickers';
import { Tooltip } from '@mui/material';

const CustomPickerDayTooltip = ({ DayComponentProps, tooltipText }: CustomPickerDayTooltipProps) => {
  const isShowTooltip =
    DayComponentProps.disabled &&
    (DayComponentProps.showDaysOutsideCurrentMonth ? true : !DayComponentProps.outsideCurrentMonth);

  return (
    <Tooltip placement='top' title={isShowTooltip ? tooltipText : ''} arrow>
      <div>
        <PickersDay {...DayComponentProps} />
      </div>
    </Tooltip>
  );
};

export default CustomPickerDayTooltip;
