import React from 'react';
import { crmDataTypes, ONE_MEGABYTE_IN_BYTES } from '../../../../constants';

const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
const MILLISECONDS_IN_SIXTY_DAYS = MILLISECONDS_IN_DAY * 60;

const formMaxDate = new Date();
const formMinDate = new Date() as any - (new Date(MILLISECONDS_IN_SIXTY_DAYS - MILLISECONDS_IN_DAY) as any);

export const formFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'scheduledend',
          type: 'date',
          prefix: <img alt='calendar' src='/assets/images/calendar-with-check-mark-icon.svg' />,
          placeholder: 'Erledigt am',
          maxDate: formMaxDate,
          minDate: formMinDate,
          crmFieldType: crmDataTypes.DateTime,
          validation: [{ name: 'required' }]
        }
      ]
    },
    {
      width: '100%',
      fields: [
        {
          name: 'regardingobjectid',
          type: 'file',
          placeholder: 'Prüfbericht hochladen',
          dropzoneSetup: {
            accept: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].toString(),
            multiple: true,
            maxSize: ONE_MEGABYTE_IN_BYTES * 10
          },
          maxNumberOfFiles: 5
        }
      ]
    }
  ]
};

export const dienstleisteraufgabenFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'scheduledend',
          type: 'date',
          prefix: <img alt='calendar' src='/assets/images/calendar-with-check-mark-icon.svg' />,
          placeholder: 'Erledigt am',
          //maxDate: formMaxDate,
          //minDate: formMinDate,
          crmFieldType: crmDataTypes.DateTime,
          validation: [{ name: 'required' }]
        }
      ]
    }
  ]
};
