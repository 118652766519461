/* eslint-disable linebreak-style */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MimeTypes } from 'common/enums';
import { Button, Distance, Flex } from 'common/common-components.styled';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../constants';
import UploadFile from 'components/setup-assistant/upload-document/upload-file';
import { UploadCarPhotoModalProps } from '../dashboard-driver.interface';

const UploadCarPhotoModal = ({ uploadNewLogo }: UploadCarPhotoModalProps) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const reactHookFormData = useForm();
  const {
    formState: { isValid }
  } = reactHookFormData;

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const submit = () => {
    uploadNewLogo(files[0]);
  };

  const changeFiles = useCallback((files: File[]) => {
    setFiles(files);
  }, []);

  return (
    <div>
      <UploadFile
        onDropAccepted={(files: File[]) => {
          changeFiles(files);
        }}
        accept={[MimeTypes.PNG, MimeTypes.JPEG, MimeTypes.JPG, MimeTypes.HEIC]}
        acceptString={'png, jpg, jpeg, heic'}
        multiple={false}
        unfallreparatur={true}
        maxSize={10485760}
      />
      <Distance top='10' />

      <Flex justify='flex-end'>
        <Button secondary onClick={closeModal}>
          Abbrechen
        </Button>
        <Distance side='10' />
        <Button onClick={submit} disabled={!isValid || !files.length}>
          Speichern
        </Button>
      </Flex>
    </div>
  );
};

export default UploadCarPhotoModal;
