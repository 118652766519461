import { useEffect } from 'react';
import { UseRegisterFieldProps } from './use-register-field.props';

export const useRegisterField = ({ reactHookFormData, name, options = {}, unMountCallback }: UseRegisterFieldProps) => {
  const { register } = reactHookFormData;

  useEffect(() => {
    register(name, options);
  }, [register, name, JSON.stringify(options)]);

  useEffect(() => unMountCallback, []);
};
