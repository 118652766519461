import React, { useContext } from 'react';
import { ExclamationMarkIcon } from 'components/icons/exclamation-mark';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { formFieldProvider } from '../../../form-field';
import { FormErrorProps } from './error.props';

export const FormError = ({ message, ...flexProps }: FormErrorProps) => {
  const { error } = useContext(formFieldProvider);
  const theme = useTheme();

  const getErrorMessage = () => {
    return error?.message || message;
  };

  if (!getErrorMessage()) {
    return null;
  }

  return (
    <Flex align='center' {...flexProps}>
      <ExclamationMarkIcon width={12} height={12} color={theme.colors.pink} />
      <Text color='pink' size='12' fontWeight={600} left='2'>
        {getErrorMessage() as string}
      </Text>
    </Flex>
  );
};
