import { getFields } from '../components/form/form-view';

export function getUntouchedFields(formFields) {
  let untouched = {};

  getFields(formFields, field => {
    untouched[field.name] = true;
  });

  return untouched;
}
