import React from 'react';
import { toggleModal } from 'actions/app_action';
import { PickValue } from 'common/interfaces';
import { AlertProps } from 'components/alert/alert-view';
import { MODAL_NAMES, MODALS } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { VehicleCostSheetModalContent } from './modal-content/modal-content';

export const useVehicleCostSheetModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getAlertData = (vehicleId: string): PickValue<AlertProps, 'parameters'> => ({
    title: 'Fahzeugkostenbogen',
    children: <VehicleCostSheetModalContent vehicleId={vehicleId} />,
    hideCloseIcon: false,
    allButtonsHidden: true,
    buttons: [
      {
        type: 'cancel',
        title: 'Abbrechen',
        action: closeAlert
      }
    ]
  });

  const openVehicleCostSheetModal = (vehicleId: string) => {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(vehicleId)));
  };

  return { openVehicleCostSheetModal };
};
