import React, { MutableRefObject, createContext, useMemo, useRef } from 'react';
import { ContextProps } from '../context.props';
import { IModifiedTableRow } from 'hooks/react-query/incidents/my-requests/my-requests.props';
import { Steps } from 'pages/my-requests/components/table/expand/steps-controller/steps.props';

interface IUnSaveModalMyRequestRefContext {
  activeIncident: MutableRefObject<Partial<IModifiedTableRow>>;
  activeStep: MutableRefObject<Steps>;
}

export const UnSaveModalMyRequestRefContext = createContext<IUnSaveModalMyRequestRefContext>({
  activeIncident: { current: {} },
  activeStep: { current: Steps.First }
});

export const UnSaveModalMyRequestRefContextContainer = ({ children }: ContextProps) => {
  const activeIncident = useRef({});
  const activeStep = useRef(Steps.First);

  const value = useMemo<IUnSaveModalMyRequestRefContext>(() => ({ activeIncident, activeStep }), []);

  return <UnSaveModalMyRequestRefContext.Provider value={value}>{children}</UnSaveModalMyRequestRefContext.Provider>;
};
