import axios from 'axios';
import authService from './auth-service';
import { getResponseFormData } from '../utils/get-response-data';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getEquipmentItems(vehicleId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/vehicle/getEquipment`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      vehicleId
    },
    transformResponse(data) {
      const response = JSON.parse(data);
      const equipment = getResponseFormData(response, [{ name: null, type: 'array' }]);
      const serienausstattungen = equipment.filter(item => item.new_art === 100000001);
      const sonderausstattungen = equipment.filter(item => item.new_art === 100000002);
      return {
        serienausstattungen,
        sonderausstattungen
      };
    }
  });
}
