import styled, { css } from 'styled-components';
import { FormInfoItemDefault } from '../default/default';
import { PenIcon } from 'components/icons-new/pen';
import { Flex } from 'common/common-components.styled';

export const StyledContainer = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledFormInfoItemEditable = styled(FormInfoItemDefault)<{ isCanEdit: boolean }>`
  ${({ isCanEdit }) =>
    isCanEdit &&
    css`
      ${StyledContainer}:hover & {
        background-color: ${({ theme }) => theme.colors.grey50};
      }
    `};
`;

export const StyledPenIcon = styled(PenIcon)<{ isCanEdit: boolean }>`
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2.2610347270965576px 5.32008171081543px 0px #5f64662b;
  border-radius: 5px;
  padding: 5px;

  ${({ isCanEdit }) =>
    isCanEdit &&
    css`
      ${StyledContainer}:hover & {
        visibility: visible;
      }
    `};
`;
