import { crmDataTypes } from '../../constants';
import React from 'react';
import { CircleExclamationMark } from '../../components/icons/crcle_exclamation_mark';

export const configurationFromPdfFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_carbrandid',
          type: 'lookup',
          data: [],
          source: 'getCarBrands',
          crmFieldType: crmDataTypes.Lookup,
          valueChangedEvent: true,
          placeholder: 'Hersteller',
          label: 'Hersteller',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_carmodelid',
          type: 'lookup',
          hidden: true,
          data: [],
          source: 'getCarModels',
          crmFieldType: crmDataTypes.Lookup,
          valueChangedEvent: true,
          sourceParameter: null,
          placeholder: 'Modell',
          label: 'Modell',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_modellspezifikation',
          type: 'text',
          placeholder: 'Variante',
          crmFieldType: crmDataTypes.String,
          label: 'Variante',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_angebotsstatuscode',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          hidden: true,
          defaultValue: '100000000',
          validation: []
        },
        {
          name: 'new_status_anfrage',
          type: 'optionSet',
          crmFieldType: crmDataTypes.Picklist,
          hidden: true,
          defaultValue: '100000004',
          validation: []
        }
      ]
    }
  ]
};

export const driverFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'driverId',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'Fahrer',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          disabled: true,
          defaultValue: '',
          valueChangedEvent: true,
          data: [],
          source: 'getUserCompanyActiveDrivers',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const oldCarFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_altes_fahrzeugid',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'Altes Fahrzeug',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          valueChangedEvent: true,
          data: [],
          source: 'getCompanyActiveCars',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const responsiblePersonFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_kstverantw',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'KST-Verantwortlicher',
          disabled: false,
          valueChangedEvent: true,
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          data: [],
          source: 'getFleetManagersAndPoolManagers',
          validation: []
        }
      ]
    }
  ]
};

export const policiesFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'uds_carpolicy',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'Car-Policy',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          data: [],
          source: 'getUserCompanyPoolcarCarPolicies',
          validation: [
            {
              name: 'required'
            }
          ]
        },
        {
          name: 'uds_carpolicynutzer',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'Mobilitätsbudget',
          disabled: false,
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte prüfe die Eingabe.
            </span>
          ),
          defaultValue: '',
          data: [],
          source: 'getUserCompanyDriverLevels',
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};
