import { PicklistResponse } from 'common/interfaces';

export const classificationDamageLocationCode: PicklistResponse[] = [
  { value: 100000000, label: 'Autobahn' },
  { value: 100000001, label: 'Firmengelände' },
  { value: 100000002, label: 'Landstraße' },
  { value: 100000003, label: 'Parkhaus / Tiefgarage' },
  { value: 100000004, label: 'Parkplatz' },
  { value: 100000005, label: 'Privatgrundstück' },
  { value: 100000006, label: 'Stadt' }
];
