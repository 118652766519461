/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ReturnKeys = ({ width = 20, height = 18, color: colorProp, className, hovered, fill: fillProp }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} className={className} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.640625 12.7208L2.38286 11.8495L5.28659 16.4964L3.83473 17.3677'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.19058 12.4304H11.6751C12.3139 12.4304 12.8366 11.9076 12.8366 11.2686C12.8366 10.6297 12.3139 10.1069 11.6751 10.1069H7.23235C6.70968 10.1069 6.21605 10.2521 5.78049 10.5135L2.96387 12.1399'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9654 12.4304L17.0469 10.194C17.6276 9.96169 18.3245 10.2231 18.5568 10.833C18.7891 11.4138 18.5278 12.1109 17.918 12.3432L12.7784 14.8119C12.43 14.9571 12.0234 15.0442 11.646 15.0442H7.0871C6.76769 15.0442 6.44828 15.1314 6.18695 15.3056L5.1416 15.9155'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M15.9316 3.8052C15.4621 2.05289 13.6609 1.013 11.9086 1.48253C10.4935 1.86169 9.56746 3.27356 9.50127 4.6625L7.98962 5.07833L6.20625 5.50483L5.93019 4.97087L4.72069 5.33006L4.51816 5.99226L3.82688 6.17749L3.21404 5.73376L2.17029 7.72325L3.59233 8.49269L10.2147 6.73447C11.0074 7.70322 12.3213 8.17319 13.6089 7.82818C15.3612 7.35866 16.4011 5.5575 15.9316 3.8052Z'
          fill={fill}
        />
      )}
      <path
        d='M7.99001 5.0783L6.20662 5.5048L5.93056 4.97083L4.72104 5.33002L4.51852 5.99223L3.82722 6.17746L3.21438 5.73373L2.17062 7.72324L3.59267 8.49269L10.2151 6.73446C11.0078 7.70321 12.3218 8.17319 13.6094 7.82818C15.3617 7.35864 16.4016 5.55747 15.9321 3.80515C15.4625 2.05283 13.6614 1.01292 11.909 1.48245C10.494 1.86162 9.54347 3.10917 9.47729 4.49813'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='13.9337' cy='4.34059' r='0.522166' transform='rotate(165 13.9337 4.34059)' stroke={color} />
    </svg>
  );
};

