/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState } from 'react';
import styles from './damage-part-choose.module.scss';
import { TrashIcon } from 'components/icons-new/trash';
import { CSSTransition } from 'react-transition-group';
import slideReverse from '../transition/backward-slide-damage.module.css';
import { StaticDamageListProps, DamagePartsItemProps, ErrorFieldsProps } from '../report-damage.props';
import { DamageItemProps, IDamagePartChoose } from './damage-part.props';
import { useTheme } from 'styled-components';
import { damage } from 'components/form-hook/form-field/components/car-damage/car-damage-icon/utils';
import { AnyObject } from 'common/interfaces';

const DamageItem = ({ reactHookFormData, item, borderColor }: DamageItemProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors }
  } = reactHookFormData;
  const damageType: Array<[string, number]> = [
    ['Delle', 1],
    ['Kratzer', 2],
    ['Lackschaden', 3],
    ['Riss', 4],
    ['Gebrochen', 5],
    ['Steinschlag', 6],
    ['Fehlteil', 7],
    ['Deformiert bzw. verformt', 8],
    ['Sonstiges', 9]
  ];

  const [otherDamageType, seOthertDamageType] = useState<Array<[string, number]>>(defaultDamageOtherPartsOptions);

  useEffect(() => {
    const damageParts: Array<[string, AnyObject]> = watch('damageParts');
    const othersDamageParts = damageParts.filter(item => item[1].value === damage.other.value);
    const otherDamagePartsTypeList = othersDamageParts.map(item => item[1].type);

    const filteredOtherDamageParts = defaultDamageOtherPartsOptions.filter(
      item => !otherDamagePartsTypeList.includes(item[1])
    );

    seOthertDamageType(filteredOtherDamageParts);
  }, [watch('damageParts')]);

  useEffect(() => {
    setShowBlock(true);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (dropdownRef.current?.contains(e.target as Node)) {
      return;
    }
    setVisible(false);
  };

  const selectItem = (damageType: [string, number]) => {
    const damageParts = watch('damageParts');
    const damageEl = damageParts.indexOf(item);
    damageParts[damageEl][1].type = damageType[1];
    damageParts[damageEl][1].name = damageType[0];
    setSearchValue(damageType[0]);
    setVisible(false);
    setValue('damageParts', damageParts);
    clearErrors(`damageParts.${item[0]}`);
  };

  const removeItem = () => {
    const damageParts = watch('damageParts');
    const damageEl = damageParts.indexOf(item);
    damageParts.splice(damageEl, 1);
    setValue('damageParts', damageParts);
    clearErrors(`damageParts.${item[0]}`);

    const removingStaticElement = watch('staticDamageList').find((el: StaticDamageListProps) => el.name === item[0]);
    const removingElement = watch('damageList').map((el: StaticDamageListProps) => {
      if (el.name === item[0]) {
        el = removingStaticElement;
      }
      return el;
    });
    setValue('damageList', removingElement);
  };

  const damagePartsErrors = errors?.damageParts as ErrorFieldsProps;

  return (
    <CSSTransition in={showBlock} timeout={500} classNames={slideReverse} mountOnEnter unmountOnExit>
      <div className={styles.item_block}>
        <div className={styles.row_columns}>
          <div className={styles.item_name}>
            {item[0]}
            <span className={styles.req}>*</span>
          </div>
          <div className={styles.half_input}>
            <div tabIndex={0}>
              <input
                id='inputTypeOfDamage'
                className={
                  damagePartsErrors && damagePartsErrors[item[0]]
                    ? styles.input_icons_drop_err
                    : styles.input__icons__drop
                }
                style={{ borderColor: borderColor ? borderColor : theme.colors.lightBlue }}
                type='text'
                placeholder='Art des Schadens'
                value={!!item[1].name ? item[1].name : searchValue}
                readOnly
                onFocus={() => {
                  setVisible(true);
                }}
              />
            </div>
            <div ref={dropdownRef} className={`${styles.dropdown} ${visible ? styles.v : ''}`}>
              {visible && (
                <ul id='inputTypeOfDamageDropDown'>
                  {(item[0] === 'Sonstiges' ? otherDamageType : damageType).map(el => (
                    <li
                      id={`inputTypeOfDamageDropDownItem${el[0]}`}
                      key={el[0]}
                      onClick={() => selectItem(el)}
                      className={styles.dropdown_li}
                    >
                      <div className={styles.item_text1}>{el[0]}</div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div
          id='deleteButton'
          className={styles.delete_item}
          onClick={removeItem}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <TrashIcon hovered={isHovered} color={theme.colors.grey400} />
        </div>
      </div>
    </CSSTransition>
  );
};

const DamagePartChoose = ({ reactHookFormData, borderColor }: IDamagePartChoose) => {
  const { watch } = reactHookFormData;
  const damageParts = watch('damageParts');

  return (
    <section className={styles.container}>
      <>
        {!!damageParts &&
          damageParts.length > 0 &&
          damageParts.map((item: DamagePartsItemProps, index: number) => (
            <DamageItem
              key={item[0] !== 'Sonstiges' ? item[0] : `${item[0]}${index}`}
              reactHookFormData={reactHookFormData}
              item={item}
              borderColor={borderColor}
            />
          ))}
      </>
    </section>
  );
};

export default DamagePartChoose;

const defaultDamageOtherPartsOptions: Array<[string, number]> = [
  ['Delle', 1],
  ['Kratzer', 2],
  ['Lackschaden', 3],
  ['Riss', 4],
  ['Gebrochen', 5],
  ['Steinschlag', 6],
  ['Fehlteil', 7],
  ['Deformiert bzw. verformt', 8],
  ['Sonstiges', 9]
];
