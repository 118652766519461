import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './filter-elements.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from '../../icons/search-icon';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { Button, Flex, Text } from '../../../common/common-components.styled';
import { endOfMonth } from 'date-fns';
import { PrefixForId } from '../../../common/enums';
import { useTheme } from 'styled-components';
import { gapsIntoCamelCase } from '../../../utils/convert-sting';
import capitalize from 'lodash/capitalize';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateBlueIcon } from '../../icons/date-blue-icon';
import { StyledDatePicker, StyledTextFieldWithoutBorders } from '../../form-hook/form-field/form-field.styled';
import moment from 'moment';
import { RepeatChildren } from 'components/repeat-children/repeat-children';
import { CircleIndicator } from '../../../components/circle-indicator/circle-indicator';
import { SkeletonComponent } from 'components/skeleton/skeleton';
import {statusData} from '../../../pages/fahrzeugkosten-page/utils';

export const FilterInput = ({
  title,
  handleInput,
  onChange,
  dropListData,
  dropListDataWithId = false,
  dropListDataValueName = 'name',
  dropListDataIdName = 'id',
  withoutSearchButton = false,
  placeholder = '',
  customDroplistItem = null,
  circleIndicator = false,
  isHideNothingFoundPlaceholder,
  isLoading,
  itemsList,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [fucusState, setFucusState] = useState(false);
  const [itemsCount, setItemsCount] = useState(5);
  const theme = useTheme();

  const onClick = () => {
    if (!inputValue.trim()) {
      return;
    }

    handleInput(title, inputValue, itemsList);
    setInputValue('');
  };

  const showMoreItems = () => {
    setItemsCount(itemsCount + 5);
  };

  const resultList = dropListData.filter(item => {
    if (!item) {
      return false;
    }
    if (dropListDataWithId) {
      return item[dropListDataValueName]?.toLowerCase().includes(inputValue.toLowerCase());
    }
    return item.toLowerCase().includes(inputValue.toLowerCase());
  });

  const isShowNothingFoundPlaceholder = !isLoading && resultList.length === 0 && !isHideNothingFoundPlaceholder;

  const changeHandler = event => {
    onChange && onChange(event.target.value);
    setInputValue(event.target.value);
  };

  const getCircleIndicatorColorForCar = (id) => {
    let color = '';

    for (const car of dropListData) {
      if (car.id === id) {
        if (car?.statecode === statusData.active.value) {
          color = theme.colors.green;
          break;
        }
        color = theme.colors.pink;
        break;
      }
    }

    return color;
  };

  const getCircleIndicator = (id)=>{
    return <CircleIndicator color={getCircleIndicatorColorForCar(id)} className='ml-10' />;
  };

  return (
    <>
      <section className={classes.filter_container}>
        <div className={classes.filter_input_wrapper}>
          <input
            className={withoutSearchButton ? classes.input_without_search : ''}
            type='text'
            onChange={changeHandler}
            onFocus={() => {
              setFucusState(true);
            }}
            value={inputValue}
            autoFocus
            placeholder={placeholder}
            id={PrefixForId.Filter + PrefixForId.Input + PrefixForId.Search}
          />

          {!withoutSearchButton ? (
            <button
              onClick={onClick}
              id={PrefixForId.Filter + PrefixForId.Input + PrefixForId.Button + PrefixForId.Search}
            >
              <SearchIcon />
            </button>
          ) : (
            <i className={classes.search_icon} />
          )}
        </div>
      </section>

      {isLoading && (
        <RepeatChildren repeatNumber={3} item={<SkeletonComponent type='text' width='216px' height='30px' />} />
      )}

      {dropListData && (fucusState || inputValue) && (
        <div className={classes.drop_list_container}>
          {dropListData
            .filter(item => {
              if (!item) {
                return false;
              }
              if (dropListDataWithId) {
                return item[dropListDataValueName].toLowerCase().includes(inputValue.toLowerCase());
              }
              return item.toLowerCase().includes(inputValue.toLowerCase());
            })
            .map((item, index) => {
              if (index >= itemsCount) {
                return;
              }
              return (
                <div key={item}>
                  {customDroplistItem ? (
                    <div
                      key={dropListDataWithId ? item[dropListDataIdName] : item}
                      onClick={() => {
                        handleInput(title, item, itemsList);
                      }}
                      id={PrefixForId.Filter + PrefixForId.DropList + 'Custom' + item}
                    >
                      {customDroplistItem(item)}
                    </div>
                  ) : (
                    <span
                      className={classes.drop_list_item}
                      key={dropListDataWithId ? item[dropListDataIdName] : item}
                      onClick={() => {
                        handleInput(title, item, itemsList);
                      }}
                      id={
                        PrefixForId.Filter +
                        PrefixForId.DropList +
                        (dropListDataWithId ? item[dropListDataValueName] : index)
                      }
                    >
                      {dropListDataWithId ? item[dropListDataValueName] : item}
                      {circleIndicator && getCircleIndicator(item[dropListDataIdName])}
                    </span>
                  )}
                </div>
              );
            })}

          {resultList.length > 5 && itemsCount < resultList.length && (
            <span
              className={classes.drop_list_item}
              onClick={() => {
                showMoreItems();
              }}
              id={PrefixForId.Filter + PrefixForId.Button + PrefixForId.LoadMore}
            >
              Mehr laden...
            </span>
          )}

          {isShowNothingFoundPlaceholder && (
            <span className='m-auto' id={PrefixForId.Filter + PrefixForId.Title + PrefixForId.NothingFound}>
              Nichts gefunden
            </span>
          )}
        </div>
      )}
    </>
  );
};

FilterInput.propTypes = {
  title: PropTypes.string,
  handleInput: PropTypes.func,
  dropListData: PropTypes.array,
  dropListDataWithId: PropTypes.bool,
  dropListDataValueName: PropTypes.string,
  dropListDataIdName: PropTypes.string,
  withoutSearchButton: PropTypes.bool,
  rightElemComponent: PropTypes.func,
  onChange: PropTypes.func,
  isHideNothingFoundPlaceholder: PropTypes.boolean,
  isLoading: PropTypes.boolean,
  itemsList:PropTypes.array
};

export const FilterButton = ({ label, value, title, handleInput, active = false, idEnding = '', isLoading = false, itemsList }) => {
  return (
    <button
      className={active ? classNames(classes.filter_button, classes.active) : classes.filter_button}
      onClick={() => {
        handleInput(title, value, itemsList);
      }}
      id={PrefixForId.Filter + PrefixForId.Button + capitalize(gapsIntoCamelCase(title) + idEnding)}
    >
      {label}
    </button>
  );
};

FilterButton.propTypes = {
  itemsList:PropTypes.array
};

const formatStartDate = (value, startDate) => {
  if (value && value.includes('-')) {
    return value.split('-')[0].trim();
  }
  return startDate;
};

const formatEndDate = (value, endDate) => {
  if (value && value.includes('-')) {
    return value.split('-')[1].trim();
  }
  return endDate;
};

export const PickersContainer = ({ title, value, handleInput, monthYearPicker = false, isLoading = false }) => {
  const formatDate = date => {
    return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const [startDate, setStartDate] = useState(value ? formatStartDate(value, '') : '');
  const [endDate, setEndDate] = useState(value ? formatEndDate(value, '') : '');

  const isDisabled = (!startDate && !endDate) || (startDate === 'Invalid Date' && endDate === 'Invalid Date');

  const onStartDateChange = val => {
    if (monthYearPicker) {
      setStartDate(String(formatDate(val.startOf('month').toDate())));
    } else {
      setStartDate(String(formatDate(val)));
    }
  };

  const onEndDateChange = val => {
    if (monthYearPicker) {
      setEndDate(String(formatDate(val.endOf('month').toDate())));
    } else {
      setEndDate(String(formatDate(val)));
    }
  };

  const getDataFromString = dataStr => {
    if (!dataStr) {
      return '';
    }
    let dataArr = dataStr.split('.');
    return new Date(dataArr[2], dataArr[1] - 1, dataArr[0]);
  };

  const saveDate = () => {
    if (startDate && endDate && startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
      handleInput(title, `${startDate} - ${endDate}`);
    } else if (!startDate || startDate === 'Invalid Date') {
      handleInput(title, `01.01.2000 - ${endDate}`);
    } else if (!endDate || endDate === 'Invalid Date') {
      handleInput(title, `${startDate} - 01.01.2100`);
    } else if (startDate && endDate === 'Invalid Date') {
      handleInput(title, `01.01.2000 - 01.01.2100`);
    }
  };

  return (
    <>
      <div style={{ paddingBottom: '5px', paddingTop: '15px' }}>
        <div style={{ marginBottom: '5px' }}>
          <Picker
            placeholder='Von'
            value={startDate || formatStartDate(value, startDate)}
            pickerHandler={onStartDateChange}
            maxDate={getDataFromString(endDate)}
            monthYearPicker={monthYearPicker}
          />
        </div>

        <Picker
          placeholder='Bis'
          value={endDate || formatEndDate(value, endDate)}
          pickerHandler={onEndDateChange}
          minDate={getDataFromString(startDate)}
          monthYearPicker={monthYearPicker}
        />
      </div>

      <Flex justify='flex-end'>
        <Button top='10' onClick={saveDate} disabled={isDisabled}>
          Anwenden
        </Button>
      </Flex>
    </>
  );
};

const Picker = ({
  placeholder,
  value,
  pickerHandler,
  minDate = '01.01.2000',
  maxDate = '01.01.2100',
  monthYearPicker = false
}) => {
  const formatDate = value => {
    if (value !== '' && value.includes('.')) {
      const dateArr = value.split('.');
      if (dateArr.length === 3) {
        const year = dateArr[2];
        const month = dateArr[1];
        const days = dateArr[0];

        return new Date(`${year}-${month}-${days}`);
      }
    }
    return null;
  };

  const handleDateChange = date => {
    if (!date || isNaN(date)) {
      pickerHandler('');
    } else {
      pickerHandler(date);
    }
  };

  return (
    <div className={classes.input_container}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledDatePicker
          views={monthYearPicker ? ['month', 'year'] : ['year', 'month', 'day']}
          components={{
            OpenPickerIcon: () => <DateBlueIcon id={'filterDatePickerPopper' + PrefixForId.Open + 'Icon'} />
          }}
          PopperProps={{
            id: 'filterDatePickerPopper'
          }}
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          value={value ? formatDate(value) : null}
          onChange={handleDateChange}
          renderInput={({ inputProps, ...restParams }) => (
            <StyledTextFieldWithoutBorders
              {...restParams}
              error={false}
              inputProps={{
                ...inputProps,
                placeholder: placeholder
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export const FilterToggle = ({ title, isChecked, handleInput, label = 'Aktiv' }) => {
  const onStatusChange = () => {
    handleInput(title, !isChecked);
  };

  return (
    <section className={classes.toggle_container}>
      <label htmlFor='toggle_button' className={classes.text}>
        {label}
      </label>
      <input
        onChange={() => {
          onStatusChange();
        }}
        checked={isChecked}
        type='checkbox'
        name='toggle'
        className={classes.toggle_button}
      />
    </section>
  );
};

export const PricePicker = ({ minValue, maxValue, pickerHandler, limits }) => {
  const [value, setValue] = useState({
    minValue,
    maxValue
  });

  const isDisabledBtn = value.maxValue === undefined || value.minValue === undefined;
  const decimalSeparator = ',';
  const groupSeparator = '.';

  const isAllowed = value => {
    if (!limits) {
      return true;
    }

    return limits.minLimit <= value.floatValue && value.floatValue <= limits.maxLimit;
  };

  const save = () => {
    pickerHandler(value);
  };

  const maxValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      maxValue: value.floatValue || 0
    }));
  };

  const minValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      minValue: value.floatValue || 0
    }));
  };

  return (
    <>
      <div style={{ paddingBottom: '5px', paddingTop: '15px' }}>
        <div style={{ marginBottom: '5px' }}>
          <div className={`${classes.input_container} ${classes.input_container_number_picker}`}>
            <span className={classes.field_prefix}>
              <img src='/assets/images/euro-sign-icon.svg' alt='currency_icon' />
            </span>
            <NumberFormat
              value={minValue}
              placeholder='Betrag von...'
              displayType='input'
              thousandSeparator={groupSeparator}
              decimalSeparator={decimalSeparator}
              thousandsGroupStyle='thousand'
              className={classes.form_input}
              onValueChange={value => minValueChangeHandler(value)}
              isAllowed={isAllowed}
            />
          </div>
        </div>
        <div className={`${classes.input_container} ${classes.input_container_number_picker}`}>
          <span className={classes.field_prefix}>
            <img src='/assets/images/euro-sign-icon.svg' alt='currency_icon' />
          </span>
          <NumberFormat
            value={maxValue}
            placeholder='Betrag bis...'
            displayType='input'
            thousandSeparator={groupSeparator}
            decimalSeparator={decimalSeparator}
            thousandsGroupStyle='thousand'
            className={classes.form_input}
            onValueChange={value => maxValueChangeHandler(value)}
            isAllowed={isAllowed}
          />
        </div>
      </div>
      <button
        onClick={save}
        className={classNames('button-mobexo button-mobexo__main', classes.save_button)}
        disabled={isDisabledBtn}
        style={{ opacity: isDisabledBtn ? 0.5 : 1 }}
      >
        Anwenden
      </button>
    </>
  );
};

export const NumberPicker = ({ minValue = undefined, maxValue = undefined, pickerHandler, helperText }) => {
  const [value, setValue] = useState({
    minValue,
    maxValue
  });

  const isDisabledBtn = value.maxValue === undefined || value.minValue === undefined;

  const save = () => {
    pickerHandler(value);
  };

  const maxValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      maxValue: value.floatValue || undefined
    }));
  };

  const minValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      minValue: value.floatValue || undefined
    }));
  };

  return (
    <>
      <div style={{ paddingBottom: '5px', paddingTop: '15px' }}>
        <div style={{ marginBottom: '5px' }}>
          <div className={`${classes.input_container} ${classes.input_container_number_picker}`}>
            <Flex direction='column' width='100%'>
              <Flex bottom='5'>
                <Text color='darkBlue' size='14'>
                  Von
                </Text>
                <Text color='pink' size='14'>
                  &nbsp;*
                </Text>
              </Flex>
              <NumberFormat
                value={value.minValue}
                displayType='input'
                className={classNames(classes.form_input, classes.digital_input)}
                onValueChange={value => minValueChangeHandler(value)}
              />
              {helperText && (
                <Text size='10' color='grey500'>
                  {helperText}
                </Text>
              )}
            </Flex>
          </div>
        </div>
        <div className={`${classes.input_container} ${classes.input_container_number_picker}`}>
          <Flex direction='column' width='100%'>
            <Flex bottom='5' top='10'>
              <Text color='darkBlue' size='14'>
                Bis
              </Text>
              <Text color='pink' size='14'>
                &nbsp;*
              </Text>
            </Flex>
            <NumberFormat
              value={value.maxValue}
              displayType='input'
              className={classNames(classes.form_input, classes.digital_input)}
              onValueChange={value => maxValueChangeHandler(value)}
            />
            {helperText && (
              <Text size='10' color='grey500'>
                {helperText}
              </Text>
            )}
          </Flex>
        </div>
      </div>
      <button
        onClick={save}
        className={classNames('button-mobexo button-mobexo__main', classes.save_button)}
        disabled={isDisabledBtn}
        style={{ opacity: isDisabledBtn ? 0.5 : 1 }}
      >
        Anwenden
      </button>
    </>
  );
};

export const KilometersFilter = ({ minValue = undefined, maxValue = undefined, pickerHandler }) => {
  const [value, setValue] = useState({
    minValue,
    maxValue
  });

  const isDisabledBtn = value.maxValue === undefined || value.minValue === undefined;

  const save = () => {
    pickerHandler(value);
  };

  const maxValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      maxValue: value.floatValue || 0
    }));
  };

  const minValueChangeHandler = value => {
    setValue(prev => ({
      ...prev,
      minValue: value.floatValue || 0
    }));
  };

  return (
    <>
      <div style={{ paddingBottom: '5px', paddingTop: '15px' }}>
        <div style={{ marginBottom: '5px' }}>
          <div>
            <Flex bottom='5'>
              <Text color='darkBlue' size='14'>
                Von
              </Text>
              <Text color='pink' size='14'>
                &nbsp;*
              </Text>
            </Flex>
            <NumberFormat
              value={value.minValue}
              displayType='input'
              className={classNames(classes.form_input, classes.digital_input)}
              onValueChange={value => minValueChangeHandler(value)}
              suffix=' km'
              thousandSeparator='.'
              decimalSeparator=','
              placeholder='XXX.XXX,XX km'
            />
          </div>
        </div>
        <div>
          <Flex bottom='5' top='10'>
            <Text color='darkBlue' size='14'>
              Bis
            </Text>
            <Text color='pink' size='14'>
              &nbsp;*
            </Text>
          </Flex>
          <NumberFormat
            value={value.maxValue}
            displayType='input'
            className={classNames(classes.form_input, classes.digital_input)}
            onValueChange={value => maxValueChangeHandler(value)}
            suffix=' km'
            thousandSeparator='.'
            decimalSeparator=','
            placeholder='XXX.XXX,XX km'
          />
        </div>
      </div>
      <button
        onClick={save}
        className={classNames('button-mobexo button-mobexo__main', classes.save_button)}
        disabled={isDisabledBtn}
        style={{ opacity: isDisabledBtn ? 0.5 : 1 }}
      >
        Anwenden
      </button>
    </>
  );
};
