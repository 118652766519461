import React from 'react';
import { Flex } from 'common/common-components.styled';
import { StyledDetailDescription, StyledDetailTitle } from './detail-item.styled';
import { DriverDashboardDetailItemProps } from './detail-item.props';

export const DriverDashboardDetailItem = ({
  title,
  description,
  type = 'default',
  isColumn
}: DriverDashboardDetailItemProps) => {
  const descriptionClickHandler = () => {
    if (type === 'email') {
      window.open(`mailto:${description}`);
    }
  };

  return (
    <Flex direction={isColumn ? 'column' : 'row'} gap='0 5px' top='15'>
      <StyledDetailTitle isColumn={isColumn}>{title}</StyledDetailTitle>
      <StyledDetailDescription type={type} onClick={descriptionClickHandler}>
        {description}
      </StyledDetailDescription>
    </Flex>
  );
};
