import { AnyObject } from 'common/interfaces';

export interface ApprovalRulesProps {
  companyId: string;
  companyData: AnyObject | null;
}

export enum SetApproversAutomaticallyValues {
  Automatic = '100000000',
  Prefilled = '100000001',
  Manual = '100000002'
}

export enum ApproversInstanceValues {
  Empty = '-1',
  Driver = '100000000',
  Supervisor = '100000001',
  OrderReleaser = '100000002',
  Contact = '100000003'
}
