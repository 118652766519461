import React, { useContext, useMemo } from 'react';
import { VehicleInitialReviewPhases } from 'components/aufgaben-component/task.prop';
import moment from 'moment';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { WidgetContentText } from '../components/leasing-widget/leasing-widget.styled';
import { Text } from 'common/common-components.styled';
import PhaseSwitch from './components/phase-switch';

export const useVehicleInitialReview = () => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const { vir } = car;
  const phase = vir?.vir_phaseinfo.phaseNo;
  const leasingreturnDate = car.uds_lease_return_date.attributeValue;
  const name = car.new_name.attributeValue;
  const phaseDate = vir?.vir_phaseinfo?.phaseDate;
  const phaseInfo = vir?.vir_phaseinfo;
  const fleetId = car.new_fuhrparkid?.attributeValue;
  const taskId = car.checklist?.activityid?.attributeValue;

  const description = useMemo(() => {
    const formattedDate = moment(leasingreturnDate).format('DD.MM.YYYY');

    switch (phase) {
      case VehicleInitialReviewPhases.Phase0:
      case VehicleInitialReviewPhases.Phase1:
      case VehicleInitialReviewPhases.Phase4:
        return (
          <PhaseSwitch
            description={
              <Text>
                Der Leasingvertrag für Fahrzeug {<strong>{name}</strong>} endet spätestens am{' '}
                {<strong>{formattedDate}</strong>}.
                {[VehicleInitialReviewPhases.Phase0, VehicleInitialReviewPhases.Phase1].includes(phase) ? (
                  <Text as={'span'}>Beauftrage jetzt das Gutachten vor der Rückgabe</Text>
                ) : (
                  <Text as={'span'}> Gutachten am {<strong>{phaseDate}</strong>} wurde als unnötig markiert.</Text>
                )}
              </Text>
            }
            taskId={taskId}
            fleetId={fleetId}
            vir_phaseinfo={phaseInfo}
            refresh={refresh}
            isFourthPhase={phase === VehicleInitialReviewPhases.Phase4}
          />
        );
      case VehicleInitialReviewPhases.Phase2:
      case VehicleInitialReviewPhases.Phase3:
      case VehicleInitialReviewPhases.Phase6:
        return (
          <WidgetContentText>
            Gutachten wurde am <strong>{phaseDate}</strong> erfolgreich beauftragt.
          </WidgetContentText>
        );

      default:
        return;
    }
  }, [phase, name, leasingreturnDate]);

  const statusColor = useMemo(() => {
    return [
      VehicleInitialReviewPhases.Phase0,
      VehicleInitialReviewPhases.Phase1,
      VehicleInitialReviewPhases.Phase6
    ].includes(phase)
      ? 'darkGold'
      : 'green';
  }, [phase]);

  const statusLabel = useMemo(() => {
    switch (phase) {
      case VehicleInitialReviewPhases.Phase0:
      case VehicleInitialReviewPhases.Phase1:
        return 'Offen';
      case VehicleInitialReviewPhases.Phase2:
      case VehicleInitialReviewPhases.Phase3:
      case VehicleInitialReviewPhases.Phase4:
        return 'Erledigt';
      case VehicleInitialReviewPhases.Phase6:
        return 'in Bearbeitung';
      default:
        return '';
    }
  }, [phase]);

  return { statusColor, statusLabel, description };
};
