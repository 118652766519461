import { connect } from "react-redux";
import Filters from "./filters-view";
import { toggleSidebar as toggleSidebarAction } from "../../actions/app_action";

const mapStateToProps = (state) => {
  const predicateObject = state.app.smartTable.search.predicateObject;
  const activeFiltersObjects = Object.keys(predicateObject).filter(x => x !== 'carTypes' && x !== 'priceRange').reduce((prev, curr) => {
    if (predicateObject[curr] !== null) {
      prev = prev + 1;
    }
    return prev;
  },0)
  const filterCount = predicateObject.carTypes.length + activeFiltersObjects;
  return {
    sidebarOpen: state.app.listSidebarOpen,
    filterCount
  }
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar(flag) {
    dispatch(toggleSidebarAction(flag));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
