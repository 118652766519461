import { GalleryProps } from '../image-gallery/gallery.props';

export interface VehicleGalleryProps extends GalleryProps {
  typeId?: VehicleTypeId;
}

export enum VehicleTypeId {
  PersonalCar = '1',
  Transporter = '2',
  Truck = '4',
  Trailer = '6',
  Bicycle = '7',
  Tool = '8',
  Forklift = '9'
}
