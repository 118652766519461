import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { getFahrzeugangeboteByConfigId } from '../../services/vehicle-service';
import { InfoConfig } from './info/info-config-view';
import Spinner from '../spinner';
import { getUsersCompanyBasket } from '../../services/user-service';
import { getCompanyReferenceVehicleList } from '../../services/get-vehicle-list';
import { defaultGuid } from '../../constants';
import { encodeBase64 } from '../../utils/decode-encode-base64';
import { asyncInterval } from '../../utils/async-interval';
import { getResponseFormData } from '../../utils/get-response-data';
import { ConfiguratorPriceTypes, PAGES } from '../../common/enums';

class ConfiguratorIframe extends Component {
  state = {
    iFrameHeight: null
  };

  spinnerStyle = {
    width: '50px',
    height: '50px',
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 40%)'
  };

  iFrameRef = createRef();

  redirectToNewCar = async (userId, configId) => {
    let intervalId = setInterval(async () => {
      let configResponse;
      try {
        configResponse = await getFahrzeugangeboteByConfigId(configId, true);
      } catch (e) {
        configResponse = {};
        console.log(e);
      }

      if (configResponse.data) {
        clearInterval(intervalId);
        const angebotIdBase64 = configResponse.data ? encodeBase64(configResponse.data) : null;
        if (angebotIdBase64) {
          this.props.setSnackbar({
            isOpen: true,
            message: 'Änderungen wurden übernommen.',
            type: 'success'
          });
          this.props.closeAlert();
          this.props.closeModal();
          this.props.history.push(
            this.props.driverId
              ? `${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${angebotIdBase64}`
              : `${PAGES.CONFIGURATION_DETAILS}?id=${angebotIdBase64}`
          );
        }
      }
    }, 2000);
  };

  redirectToCompanyBasket = async (userId, configId) => {
    let companyBasket;
    try {
      companyBasket = await getUsersCompanyBasket(this.props.userId);
    } catch (e) {
      companyBasket = {};
      console.log(e);
    }
    companyBasket.data = companyBasket.data || {};
    const companyBasketId = companyBasket.data.data?.opportunityid
      ? companyBasket.data.data.opportunityid
      : defaultGuid;

    let intervalId = setInterval(async () => {
      let response;
      try {
        response = await getCompanyReferenceVehicleList(
          { pagination: { start: 1, number: 10 }, sort: { predicate: 'createdon', reverse: true }, search: {} },
          companyBasketId
        );
      } catch (e) {
        response = {};
        console.log(e);
      }
      response.data = response.data || {};
      response.data.data.items = getResponseFormData(response.data, [{ name: 'items', type: 'array' }], true);

      if (response.data.data.items[0]?.uds_internalid === configId) {
        clearInterval(intervalId);
        this.props.setSnackbar({
          isOpen: true,
          message: 'Änderungen wurden übernommen.',
          type: 'success'
        });
        this.props.history.push(PAGES.REFERENCE_VEHICLES);
        this.props.closeAlert();
      }
    }, 2000);
  };

  initFrameInterceptor = context => {
    const self = this;
    let oldXHROpen = context.XMLHttpRequest.prototype.open;
    let oldXHRSend = context.XMLHttpRequest.prototype.send;
    let requestUrl = '';

    context.XMLHttpRequest.prototype.open = function (method, url) {
      requestUrl = url;

      return oldXHROpen.apply(this, arguments);
    };

    context.XMLHttpRequest.prototype.send = function (data) {
      if (requestUrl === 'https://api.cd-systeme.com/car_configurations/push_data') {
        this.addEventListener('load', function () {
          if (this.status === 200) {
            const requestData = JSON.parse(data);
            if (self.props.mode === 'new') {
              self.redirectToCompanyBasket(requestData.userId, requestData.configId);
            } else {
              self.redirectToNewCar(requestData.userId, requestData.configId);
            }
          }
        });
      }

      return oldXHRSend.apply(this, arguments);
    };
  };

  componentDidMount() {
    if (!this.iFrameRef.current) {
      return;
    }
    this.iFrameRef.current.onload = () => {
      this.initFrameInterceptor(this.iFrameRef.current.contentWindow);

      if (this.props.mode !== 'new') {
        asyncInterval(
          () => this.iFrameRef.current?.contentWindow.document.querySelector('strong.total-price.ng-binding'),
          50,
          100
        )
          .then(priceElement => {
            const getPriceType = () => {
              const priceTypeElement = this.iFrameRef.current?.contentWindow.document.querySelector(
                'div.btn-group.pricing-switch>button.active'
              );
              return priceTypeElement?.innerText;
            };

            const observer = new MutationObserver(mutationsList => {
              for (let mutation of mutationsList) {
                const oldPrice = parseFloat(mutation.oldValue.replace('.', '').replace(',', '.'));
                const newPrice = parseFloat(mutation.target.data.replace('.', '').replace(',', '.'));
                this.props.onPriceChange(oldPrice, newPrice, getPriceType());
              }
            });
            observer.observe(priceElement, { characterData: true, subtree: true, characterDataOldValue: true });
            const price = parseFloat(priceElement.innerText.replace('.', '').replace(',', '.'));
            setTimeout(() => {
              if (this.props.priceType === ConfiguratorPriceTypes.Brutto) {
                this.props.onPriceChange(price, price, ConfiguratorPriceTypes.Netto);
              }
            }, 1000);
          })
          .catch(e => {
            console.log(e);
          });

        asyncInterval(() => this.iFrameRef.current?.contentWindow.document.querySelector('div.extras'), 50, 100)
          .then(extras => {
            const observer = new MutationObserver(mutationsList => {
              const abschlussButtonArr = this.iFrameRef.current?.contentWindow.document.querySelectorAll(
                'button.standard-info.btn.btn-primary.btn-block.btn-proceed'
              );
              for (let mutation of mutationsList) {
                mutation.addedNodes.forEach(addedNode => {
                  if (addedNode.className === 'alert alert-info ng-scope') {
                    abschlussButtonArr.forEach(abschlussButton => {
                      abschlussButton.setAttribute('disabled', 'true');
                    });
                  }
                });
                mutation.removedNodes.forEach(addedNode => {
                  if (addedNode.className === 'alert alert-info ng-scope') {
                    abschlussButtonArr.forEach(abschlussButton => {
                      abschlussButton.removeAttribute('disabled');
                    });
                  }
                });
              }
            });
            observer.observe(extras, { subtree: false, childList: true });
          })
          .catch(e => {
            console.log(e);
          });
      }

      let abschlussButton = null;
      let intervalId = setInterval(() => {
        abschlussButton = this.iFrameRef.current?.contentWindow.document.querySelector(
          'button.standard-info.btn.btn-primary.btn-block.btn-proceed'
        );
        if (abschlussButton) {
          const creatingConfigAlertData = {
            title: 'Konfiguration erstellt',
            children: (
              <>
                <p>Die Konfiguration wurde erfolgreich erstellt, Du wirst nun zum neuen Auto weitergeleitet.</p>
                <Spinner style={this.spinnerStyle} />
              </>
            ),
            buttons: []
          };
          clearInterval(intervalId);
          if (this.props.mode === 'new') {
            let breadcrumb = this.iFrameRef.current.contentWindow.document.querySelector('.breadcrumb');
            breadcrumb.style.display = 'block';
            abschlussButton.addEventListener('click', () => {
              abschlussButton.setAttribute('disabled', 'true');
              this.props.openAlert(creatingConfigAlertData);
            });
            // setTimeout(() => {
            //   this.setState({ iFrameHeight: this.iFrameRef.current.contentWindow.document.body.scrollHeight + 50 });
            // }, 1000);
          } else {
            const newAbschlussButton = abschlussButton.cloneNode(true);
            abschlussButton.after(newAbschlussButton);
            abschlussButton.style.display = 'none';
            newAbschlussButton.addEventListener('click', () => {
              newAbschlussButton.setAttribute('disabled', 'true');
              if (this.props.outOfBudget) {
                const alertData = {
                  title: 'Mobilitäts-Budget überschritten',
                  children: <p>Mobilitäts-Budget überschritten</p>,
                  buttons: [
                    {
                      type: 'submit',
                      title: 'OK',
                      action: this.props.closeAlert
                    }
                  ]
                };
                this.props.openAlert(alertData);
              } else {
                abschlussButton.click();
                this.props.openAlert(creatingConfigAlertData);
              }
            });
          }
        }
      }, 50);
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outOfBudget !== this.props.outOfBudget) {
      const abschlussButtonArr = this.iFrameRef.current?.contentWindow.document.querySelectorAll(
        'button.standard-info.btn.btn-primary.btn-block.btn-proceed'
      );
      abschlussButtonArr.forEach(abschlussButton => {
        if (this.props.outOfBudget) {
          abschlussButton.setAttribute('disabled', this.props.outOfBudget);
        } else {
          abschlussButton.removeAttribute('disabled');
        }
      });
    }
    if (prevProps.priceType !== this.props.priceType) {
      const priceTypeElements = this.iFrameRef.current?.contentWindow.document.querySelectorAll(
        'div.btn-group.pricing-switch>button'
      );
      [...priceTypeElements].filter(elem => elem.textContent.includes(this.props.priceType))[0]?.click();
    }
  }

  render() {
    return (
      <iframe
        ref={this.iFrameRef}
        title={'Configurator'}
        src={this.props.iFrameSrc}
        width={'100%'}
        onLoad={this.props.iframeLoaded}
        height={
          this.state.iFrameHeight ? `${this.state.iFrameHeight}px` : this.props.height ? this.props.height : '100%'
        }
        style={this.props.style || { position: 'static' }}
        data-hj-allow-iframe={true}
      />
    );
  }
}

ConfiguratorIframe.propTypes = {
  mode: PropTypes.string,
  companyBasketId: PropTypes.string,
  height: PropTypes.string,
  userId: PropTypes.string,
  driverId: PropTypes.string,
  iFrameSrc: PropTypes.string.isRequired,
  outOfBudget: PropTypes.bool.isRequired,
  iframeLoaded: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  priceType: PropTypes.string
};

export default withRouter(ConfiguratorIframe);
