import { IconProps } from 'common/interfaces';
import React from 'react';

export const LogoMobexo = ({ width = 32, height = 17, color = '#fff' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 30 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_10460_4535)'>
      <path
        d='M29.9622 13.3142C29.9622 15.048 28.5566 16.4537 26.8227 16.4537C25.0887 16.4537 23.6832 15.048 23.6832 13.3142C23.6832 11.5803 25.0888 10.1746 26.8227 10.1746C28.5566 10.1746 29.9622 11.5803 29.9622 13.3142Z'
        fill='#E80032'
      />
      <path
        d='M16.4731 0.901245H21.0454V16.2149H17.1295V6.8736L12.2946 16.2149H8.72883L3.91595 6.91731V16.2149H0V0.901245H4.57221L10.5227 12.6708L16.4731 0.901245Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_10460_4535'>
        <rect width={width} height={height} fill={color} transform='translate(0 0.894653)' />
      </clipPath>
    </defs>
  </svg>
);
