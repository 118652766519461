import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const FinnIcon = ({ width = 19, height = 8, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 19 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3575 7.08607V0.913933H14.71L16.8001 4.01229V0.913933H18.3247V7.08607H17.0952L14.8329 3.7418V7.08607H13.3575Z'
        fill={color}
      />
      <path
        d='M7.55421 0.913933V7.08607H9.02962V3.7418L11.2919 7.08607H12.5214V0.913933H10.9968V4.01229L8.90667 0.913933H7.55421Z'
        fill={color}
      />
      <path d='M5.19356 0.913933V7.08607H6.66897V0.913933H5.19356Z' fill={color} />
      <path
        d='M0.324707 0.913933V7.08607H1.82471V4.99589H3.74274V3.49589H1.82471V2.38934H4.28372V0.913933H0.324707Z'
        fill={color}
      />
    </svg>
  );
};
