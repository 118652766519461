import React from 'react';
import { damage } from '../utils';

const CarDamageBackIcon = ({ watch, chooseDamage }) => {
  return (
    <svg width='252' height='162' viewBox='0 0 252 162' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3342_59477)'>
        <path
          onClick={() => chooseDamage(damage[13].color, 13)}
          d='M216.861 0.969899V22.5895C216.861 41.1206 201.288 56.1771 182.044 56.1771H69.9556C50.712 56.1771 35.1387 41.1206 35.1387 22.5895V0.776855C88.7031 10.718 171.926 10.525 216.861 0.969899Z'
          fill={watch('damageList')[13].color}
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          onClick={() => chooseDamage(damage[12].color, 12)}
          d='M0.62109 71.793C0.621091 68.1101 3.37386 63.339 7.33099 59.4887L14.557 52.1229C14.9872 51.7044 15.5033 51.3696 16.1055 51.2022L16.1055 56.9776C16.1055 67.811 24.8031 76.7211 35.7549 77.4382C36.2264 77.469 36.7021 77.4847 37.1815 77.4847L44.9237 77.4847L56.4361 57.4799L194.996 57.4799L207.158 77.3554L215.039 77.3554C221.662 77.3554 228.829 73.5224 232.769 66.8148C236.709 60.1072 235.024 51.2146 235.024 51.2146L236.93 52.1229L244.156 59.4887C248.113 63.3391 250.866 68.1101 250.866 71.793C250.709 75.1562 248.758 114.745 248.486 135.808L210.112 125.267L40.6744 125.267L2.82849 135.808C2.55679 114.792 0.621089 75.1551 0.62109 71.793Z'
          fill={watch('damageList')[12].color}
        />
        <path
          d='M215.039 77.3554C221.662 77.3554 228.829 73.5224 232.769 66.8148C236.709 60.1072 235.024 51.2146 235.024 51.2146L236.93 52.1229L244.156 59.4887C248.113 63.3391 250.866 68.1101 250.866 71.793C250.709 75.1562 248.758 114.745 248.486 135.808L210.112 125.267L40.6744 125.267L2.82849 135.808C2.55679 114.792 0.621089 75.1551 0.62109 71.793C0.621091 68.1101 3.37386 63.339 7.33099 59.4887L14.557 52.1229C14.9872 51.7044 15.5033 51.3696 16.1055 51.2022L16.1055 56.9776C16.1055 67.811 24.8031 76.7211 35.7549 77.4382M215.039 77.3554L207.158 77.3554L194.996 57.4799L56.4361 57.4799L44.9237 77.4847L37.1815 77.4847C36.7021 77.4847 36.2264 77.469 35.7549 77.4382M215.039 77.3554C215.039 77.3554 215.039 78.3844 215.039 79.2718M215.039 77.3554L215.039 79.2718M35.8397 78.8567C35.5805 91.6379 35.8397 105.409 37.2224 114.726L213.515 114.726C214.984 105.491 215.211 92.053 215.039 79.2718M35.8397 78.8567L35.7549 77.4382M35.8397 78.8567C35.7841 77.9269 35.7629 77.5729 35.7549 77.4382'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          onClick={() => chooseDamage(damage[30].color, 30)}
          d='M247.547 135.808L210.347 125.447L132.237 125.447L119.075 125.447L40.9957 125.447L3.242 135.808C3.242 137.733 3.24116 140.05 3.24116 141.557C3.16832 151.762 12.192 158.278 19.0335 160.037C20.2728 160.356 21.4405 160.518 22.4695 160.518L36.7496 160.518L39.1582 158.258C51.0296 147.377 66.7721 141.267 83.2027 141.267L118.989 141.267L132.151 141.267L167.937 141.267C184.281 141.267 200.024 147.377 211.981 158.258L214.39 160.518L228.67 160.518C235.38 160.518 247.634 152.652 247.547 140.599C247.634 139.092 247.461 137.733 247.547 135.808Z'
          fill={watch('damageList')[30].color}
        />
        <path
          onClick={() => chooseDamage(damage[30].color, 30)}
          d='M39.1582 158.258L36.7496 160.518L214.39 160.518L211.981 158.258C200.024 147.377 184.281 141.267 167.937 141.267L132.151 141.267L118.989 141.267L83.2027 141.267C66.7721 141.267 51.0296 147.377 39.1582 158.258Z'
          fill={watch('damageList')[30].color}
        />
        <path
          d='M214.39 160.518L211.981 158.258C200.024 147.377 184.281 141.267 167.937 141.267L132.151 141.267L118.989 141.267L83.2027 141.267C66.7721 141.267 51.0296 147.377 39.1582 158.258L36.7496 160.518M214.39 160.518L228.67 160.518C235.38 160.518 247.634 152.652 247.547 140.599C247.634 139.092 247.461 137.733 247.547 135.808L210.347 125.447L132.237 125.447L119.075 125.447L40.9957 125.447L3.242 135.808C3.242 137.733 3.24116 140.05 3.24116 141.557C3.16832 151.762 12.192 158.278 19.0335 160.037C20.2728 160.356 21.4405 160.518 22.4695 160.518L36.7496 160.518M214.39 160.518L36.7496 160.518'
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          onClick={() => chooseDamage(damage[10].color, 10)}
          d='M56.6216 57.48L44.8362 77.4849L37.094 77.4849C25.4807 77.4849 16.0181 68.2776 16.0181 56.9778L16.0181 51.2023C16.6202 51.0349 17.3944 50.9512 17.9966 51.1186L34.7714 55.1363C41.1372 56.643 47.7611 57.48 54.3849 57.48L56.6216 57.48Z'
          fill={watch('damageList')[10].color}
          stroke='white'
          strokeMiterlimit='10'
        />
        <path
          onClick={() => chooseDamage(damage[31].color, 31)}
          d='M194.779 57.48L206.565 77.4849L214.307 77.4849C225.92 77.4849 235.383 68.2776 235.383 56.9778L235.383 51.2023C234.781 51.0349 234.006 50.9512 233.404 51.1186L216.629 55.1363C210.264 56.643 203.64 57.48 197.016 57.48L194.779 57.48Z'
          fill={watch('damageList')[31].color}
          stroke='white'
          strokeMiterlimit='10'
        />
        <g clipPath='url(#clip1_3342_59477)' onClick={() => chooseDamage(damage[11].color, 11)}>
          <rect
            x='77.623'
            y='78.2437'
            width='93.5698'
            height='22.5674'
            rx='1.5'
            fill={watch('damageList')[11].color}
            stroke='white'
          />
          <path
            d='M86.9062 93.7703V85.3495H90.0682C90.6982 85.3495 91.2178 85.4509 91.627 85.6538C92.0363 85.8539 92.341 86.1239 92.5412 86.4638C92.7414 86.8009 92.8415 87.1751 92.8415 87.5863C92.8415 87.9481 92.7723 88.2469 92.6339 88.4826C92.4985 88.7184 92.3189 88.9048 92.0951 89.0418C91.8743 89.1789 91.6344 89.2803 91.3753 89.3461V89.4283C91.6521 89.4448 91.9303 89.5352 92.21 89.6997C92.4896 89.8642 92.7237 90.0999 92.9121 90.4069C93.1005 90.7139 93.1947 91.0895 93.1947 91.5336C93.1947 91.9557 93.0917 92.3353 92.8856 92.6725C92.6795 93.0097 92.3542 93.2769 91.9097 93.4743C91.4651 93.6717 90.8866 93.7703 90.1741 93.7703H86.9062ZM88.0014 92.8658H90.1741C90.8895 92.8658 91.3974 92.7369 91.6977 92.4793C92.0009 92.2188 92.1525 91.9036 92.1525 91.5336C92.1525 91.2485 92.0745 90.9853 91.9185 90.7441C91.7625 90.5001 91.5402 90.3055 91.2517 90.1602C90.9631 90.0122 90.6216 89.9382 90.2271 89.9382H88.0014V92.8658ZM88.0014 89.0501H90.0328C90.3626 89.0501 90.6599 88.9898 90.9249 88.8691C91.1928 88.7485 91.4048 88.5786 91.5608 88.3593C91.7198 88.14 91.7993 87.8823 91.7993 87.5863C91.7993 87.2162 91.6609 86.9024 91.3841 86.6447C91.1074 86.3843 90.6687 86.2541 90.0682 86.2541H88.0014V89.0501Z'
            fill='#E0E3E5'
          />
          <path
            d='M102.625 89.5599C102.625 90.4481 102.453 91.2156 102.109 91.8625C101.764 92.5094 101.292 93.0083 100.691 93.3592C100.09 93.71 99.4045 93.8855 98.6331 93.8855C97.8618 93.8855 97.1758 93.71 96.5752 93.3592C95.9747 93.0083 95.5021 92.5094 95.1577 91.8625C94.8132 91.2156 94.641 90.4481 94.641 89.5599C94.641 88.6718 94.8132 87.9043 95.1577 87.2573C95.5021 86.6104 95.9747 86.1115 96.5752 85.7607C97.1758 85.4098 97.8618 85.2344 98.6331 85.2344C99.4045 85.2344 100.09 85.4098 100.691 85.7607C101.292 86.1115 101.764 86.6104 102.109 87.2573C102.453 87.9043 102.625 88.6718 102.625 89.5599ZM101.565 89.5599C101.565 88.8308 101.434 88.2154 101.172 87.7138C100.913 87.2121 100.561 86.8325 100.117 86.5748C99.6753 86.3171 99.1807 86.1883 98.6331 86.1883C98.0855 86.1883 97.5895 86.3171 97.1449 86.5748C96.7033 86.8325 96.3515 87.2121 96.0895 87.7138C95.8304 88.2154 95.7009 88.8308 95.7009 89.5599C95.7009 90.2891 95.8304 90.9045 96.0895 91.4061C96.3515 91.9077 96.7033 92.2874 97.1449 92.545C97.5895 92.8027 98.0855 92.9315 98.6331 92.9315C99.1807 92.9315 99.6753 92.8027 100.117 92.545C100.561 92.2874 100.913 91.9077 101.172 91.4061C101.434 90.9045 101.565 90.2891 101.565 89.5599Z'
            fill='#E0E3E5'
          />
          <path d='M108.212 89.1076V90.0122H104.255V89.1076H108.212Z' fill='#E0E3E5' />
          <path
            d='M110.187 85.3495H111.494L114.567 92.3395H114.673L117.747 85.3495H119.054V93.7703H118.03V87.3725H117.941L115.115 93.7703H114.126L111.3 87.3725H111.211V93.7703H110.187V85.3495Z'
            fill='#E0E3E5'
          />
          <path
            d='M128.881 89.5599C128.881 90.4481 128.709 91.2156 128.364 91.8625C128.02 92.5094 127.547 93.0083 126.947 93.3592C126.346 93.71 125.66 93.8855 124.889 93.8855C124.118 93.8855 123.432 93.71 122.831 93.3592C122.23 93.0083 121.758 92.5094 121.413 91.8625C121.069 91.2156 120.897 90.4481 120.897 89.5599C120.897 88.6718 121.069 87.9043 121.413 87.2573C121.758 86.6104 122.23 86.1115 122.831 85.7607C123.432 85.4098 124.118 85.2344 124.889 85.2344C125.66 85.2344 126.346 85.4098 126.947 85.7607C127.547 86.1115 128.02 86.6104 128.364 87.2573C128.709 87.9043 128.881 88.6718 128.881 89.5599ZM127.821 89.5599C127.821 88.8308 127.69 88.2154 127.428 87.7138C127.169 87.2121 126.817 86.8325 126.373 86.5748C125.931 86.3171 125.436 86.1883 124.889 86.1883C124.341 86.1883 123.845 86.3171 123.401 86.5748C122.959 86.8325 122.607 87.2121 122.345 87.7138C122.086 88.2154 121.957 88.8308 121.957 89.5599C121.957 90.2891 122.086 90.9045 122.345 91.4061C122.607 91.9077 122.959 92.2874 123.401 92.545C123.845 92.8027 124.341 92.9315 124.889 92.9315C125.436 92.9315 125.931 92.8027 126.373 92.545C126.817 92.2874 127.169 91.9077 127.428 91.4061C127.69 90.9045 127.821 90.2891 127.821 89.5599Z'
            fill='#E0E3E5'
          />
          <path
            d='M137.241 85.3495V93.7703H136.145V86.4186H136.092L133.884 87.7837V86.7475L136.145 85.3495H137.241Z'
            fill='#E0E3E5'
          />
          <path
            d='M139.842 93.7703V93.0302L142.827 89.9875C143.177 89.6312 143.466 89.3214 143.693 89.0583C143.919 88.7924 144.087 88.543 144.196 88.31C144.308 88.0742 144.364 87.8275 144.364 87.5698C144.364 87.2738 144.287 87.0175 144.134 86.8009C143.984 86.5844 143.778 86.4172 143.516 86.2993C143.254 86.1814 142.959 86.1225 142.633 86.1225C142.285 86.1225 141.982 86.1897 141.723 86.324C141.467 86.4556 141.268 86.6406 141.127 86.8791C140.988 87.1175 140.919 87.3971 140.919 87.7179H139.877C139.877 87.2245 139.999 86.7914 140.244 86.4186C140.488 86.0458 140.821 85.7552 141.242 85.5469C141.666 85.3385 142.141 85.2344 142.668 85.2344C143.198 85.2344 143.667 85.3385 144.077 85.5469C144.486 85.7552 144.807 86.0362 145.039 86.3898C145.272 86.7434 145.388 87.1367 145.388 87.5698C145.388 87.8796 145.328 88.1825 145.207 88.4785C145.089 88.7718 144.883 89.0994 144.589 89.4612C144.298 89.8203 143.893 90.2589 143.375 90.777L141.343 92.8V92.8658H145.547V93.7703H139.842Z'
            fill='#E0E3E5'
          />
          <path
            d='M150.427 93.8855C149.844 93.8855 149.324 93.7923 148.868 93.6059C148.415 93.4195 148.054 93.1604 147.786 92.8287C147.521 92.4943 147.377 92.1065 147.353 91.6651H148.466C148.49 91.9365 148.59 92.1709 148.767 92.3682C148.943 92.5629 149.174 92.7136 149.46 92.8205C149.745 92.9274 150.062 92.9809 150.409 92.9809C150.798 92.9809 151.142 92.9178 151.443 92.7917C151.743 92.6656 151.979 92.4902 152.149 92.2654C152.32 92.0407 152.405 91.7803 152.405 91.4842C152.405 91.1745 152.323 90.9017 152.158 90.666C151.993 90.4275 151.752 90.2411 151.434 90.1068C151.116 89.9725 150.727 89.9053 150.268 89.9053H149.544V89.0007H150.268C150.627 89.0007 150.942 88.9404 151.213 88.8198C151.487 88.6992 151.7 88.5292 151.853 88.31C152.009 88.0907 152.087 87.833 152.087 87.5369C152.087 87.2519 152.02 87.0038 151.884 86.7927C151.749 86.5817 151.558 86.4172 151.31 86.2993C151.066 86.1814 150.777 86.1225 150.445 86.1225C150.133 86.1225 149.838 86.176 149.561 86.2829C149.288 86.387 149.064 86.5392 148.89 86.7393C148.717 86.9366 148.622 87.1751 148.608 87.4547H147.548C147.565 87.0134 147.708 86.6269 147.976 86.2952C148.244 85.9608 148.594 85.7004 149.027 85.514C149.463 85.3276 149.941 85.2344 150.462 85.2344C151.022 85.2344 151.502 85.3399 151.902 85.551C152.302 85.7593 152.61 86.0348 152.825 86.3774C153.04 86.7201 153.147 87.0901 153.147 87.4876C153.147 87.9618 153.013 88.3661 152.745 88.7006C152.48 89.035 152.12 89.2666 151.664 89.3955V89.4612C152.235 89.549 152.681 89.7751 153.002 90.1397C153.322 90.5015 153.483 90.9497 153.483 91.4842C153.483 91.942 153.349 92.3532 153.081 92.7177C152.816 93.0796 152.454 93.3646 151.995 93.573C151.535 93.7813 151.013 93.8855 150.427 93.8855Z'
            fill='#E0E3E5'
          />
          <path
            d='M155.077 92.0434V91.2046L159.052 85.3495H159.705V86.6488H159.264L156.261 91.073V91.1388H161.613V92.0434H155.077ZM159.334 93.7703V91.7885V91.3979V85.3495H160.376V93.7703H159.334Z'
            fill='#E0E3E5'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3342_59477'>
          <rect width='252' height='162' fill='white' />
        </clipPath>
        <clipPath id='clip1_3342_59477'>
          <rect width='94.5696' height='22.9976' fill='white' transform='translate(77.123 78.3135)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarDamageBackIcon;
