import React from 'react';
import { FilterExpandContainerProps } from './filter-expand-container.props';
import { Flex } from 'common/common-components.styled';
import { FilterExpandContainerContext } from './filter-expand-container.context';
import { useToggleFilterExpand } from './hooks/use-toggle-filter-expand';
import { FilterExpandContainerExpandButton } from './components/expand-button';
import { FilterChildren } from './components/filter-children/filter-children';

export const FilterExpandContainer = (props: FilterExpandContainerProps) => {
  const toggleFilterExpanded = useToggleFilterExpand(props);

  const { isFilterExpanded } = toggleFilterExpanded;
  const filterExpandContainerContextValue = { ...props, ...toggleFilterExpanded };

  return (
    <FilterExpandContainerContext.Provider value={filterExpandContainerContextValue}>
      <Flex gap='5px 0' align={isFilterExpanded ? 'flex-end' : 'center'} wrap='wrap' width='100%'>
        <Flex direction='column' width='100%'>
          <Flex wrap='wrap'>
            <FilterChildren />
            <FilterExpandContainerExpandButton bottom='10' />
          </Flex>
        </Flex>
      </Flex>
    </FilterExpandContainerContext.Provider>
  );
};
