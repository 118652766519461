import { connect } from "react-redux";

import {deleteAllFromComparison, deleteFromComparison, toggleComparisonPanel} from "../../actions/comparison_action";
import ComparisonPanel from "./comparison-panel-view";
import {toggleModal} from "../../actions/app_action";
import {MODALS, MODAL_NAMES} from "../../constants";

const mapStateToProps = (state) => ({
  comparisonPanelOpen: state.comparison.comparisonPanelOpen,
  comparisonCars: state.comparison.cars
});

const mapDispatchToState = (dispatch) => ({
  toggleComparisonPanel(flag) {
    dispatch(toggleComparisonPanel(flag));
  },
  deleteFromComparison(id) {
    dispatch(deleteFromComparison(id));
  },
  deleteAllCarsFromComparison() {
    dispatch(deleteAllFromComparison());
  },
  openComparisonModal() {
    dispatch(toggleModal(MODALS.general_comparison_form, MODAL_NAMES.comparison_form, null));
  }
});

export default connect(mapStateToProps, mapDispatchToState)(ComparisonPanel);
