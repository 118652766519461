import React from 'react';
import classes from './driver-cell.module.scss';
import { TableComponentsProps } from '../table-components.props';

export const DriverCell = ({ row }: TableComponentsProps) => {
  if (row && !row.new_fahrerid) {
    return <span>-</span>;
  }

  return (
    <span className={row && row.statecode_value ? classes.driver_inactive : classes.driver_active}>
      {row && row.new_fahrerid}
    </span>
  );
};
