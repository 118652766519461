import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import { getReconfigurationsList } from '../../../services/get-vehicle-list';
import { getRecentVehicles, getUserBasket, getUsersCompanyBasket } from '../../../services/user-service';
import { setMainAngebote, setMainVehicle } from '../../../services/vehicle-service';
import DashboardItem from './dashboard-item/dashboard-item-redux';
import { USER_ROLES, defaultGuid } from '../../../constants';
import { getResponseFormData } from '../../../utils/get-response-data';
import { DashboardItemIds } from '../../../common/enums';

class DashboardItems extends Component {
  state = {
    dashboardItems: [
      {
        title: 'Mein Fahrzeug',
        id: DashboardItemIds.MyVehicle,
        subtitle: 'Ihr Fahrzeug verwalten.',
        imgSrc: '/assets/images/car_icon.svg',
        expandPanels: [],
        opportunityId: null,
        secondaryCars: [],
        selectedSecondaryCarId: '0'
      },
      {
        title: 'Neues Fahrzeug',
        id: DashboardItemIds.NewVehicle,
        subtitle: 'Konfigurieren & bestellen',
        imgSrc: '/assets/images/nues_fahrzeug.svg',
        expandPanels: [],
        opportunityId: null,
        userBasketId: null,
        secondaryCars: [],
        selectedSecondaryCarId: '0'
      }
    ]
  };

  setMainVehicle = async dashboardItemId => {
    const newDashboardItems = cloneDeep(this.state.dashboardItems);
    const dashboardItem = newDashboardItems.find(item => item.id === dashboardItemId);

    if (dashboardItem.selectedSecondaryCarId !== '0') {
      this.props.showOverlay();
      this.props.closeAlert();

      switch (dashboardItemId) {
        case DashboardItemIds.MyVehicle:
          let fuhrparks;
          try {
            await setMainVehicle(this.props.user?.id, dashboardItem.selectedSecondaryCarId);
            fuhrparks = await getRecentVehicles(this.props.user?.id);
          } catch (e) {
            fuhrparks = {};
          }

          if (fuhrparks.data) {
            const mainCar = this.getMainFuhrpark(fuhrparks.data);
            this.props.setMainCar(mainCar);
            dashboardItem.secondaryCars = this.getSecondaryFuhrparks(fuhrparks.data, mainCar);
            dashboardItem.expandPanels = this.getExpandPanelsFuhrparks(mainCar);
          }

          break;

        case DashboardItemIds.NewVehicle:
          let angebotes;
          try {
            await setMainAngebote(dashboardItem.userBasketId, dashboardItem.selectedSecondaryCarId);
            angebotes = await getReconfigurationsList(this.props.smartTable, dashboardItem.userBasketId);
          } catch (e) {
            angebotes = {};
          }

          angebotes.data.data.items = getResponseFormData(angebotes.data, [{ name: 'items', type: 'array' }], true);
          const angebotesFilteredItems = angebotes.data?.data.items.filter(angebot =>
            [100000005, 100000006].includes(angebot.new_status_anfrage)
          );
          if (angebotesFilteredItems?.length) {
            const mainAngebote = this.getMainAngebote(angebotesFilteredItems);
            dashboardItem.secondaryCars = this.getSecondaryAngebotes(angebotesFilteredItems, mainAngebote);
            const meineBestellungIndex = dashboardItem.expandPanels.findIndex(
              expandPanel => expandPanel.title === 'Meine Bestellung'
            );
            dashboardItem.expandPanels[meineBestellungIndex] = this.getMeineBestellungExpandPanel(mainAngebote);
          }

          break;

        default:
          break;
      }

      this.setState({ dashboardItems: newDashboardItems });
      this.props.hideOverlay();
    }
  };

  closeAlert = dashboardItemId => {
    const newDashboardItems = cloneDeep(this.state.dashboardItems);
    const dashboardItem = newDashboardItems.find(item => item.id === dashboardItemId);
    dashboardItem.selectedSecondaryCarId = '0';

    this.setState({ dashboardItems: newDashboardItems });
    this.props.closeAlert();
  };

  onChangeMainVehicle = (event, dashboardItemId) => {
    const newDashboardItems = cloneDeep(this.state.dashboardItems);
    const dashboardItem = newDashboardItems.find(item => item.id === dashboardItemId);
    dashboardItem.selectedSecondaryCarId = event.target.value;

    this.setState({ dashboardItems: newDashboardItems });
  };

  getMainFuhrpark = fuhrparks => {
    let mainCar = fuhrparks.find(fuhrpark => fuhrpark.uds_hauptauto);
    if (!mainCar) {
      mainCar = fuhrparks.sort((a, b) => {
        let fuhrpark1 = new Date(a.modifiedon);
        let fuhrpark2 = new Date(b.modifiedon);
        return fuhrpark2 - fuhrpark1;
      })[0];
    }

    return mainCar;
  };

  getMainAngebote = angebotes => {
    let mainAngebote = angebotes.find(angebote => angebote.uds_hauptauto);
    if (!mainAngebote) {
      mainAngebote = angebotes.sort((a, b) => {
        let angebote1 = new Date(a.modifiedon);
        let angebote2 = new Date(b.modifiedon);
        return angebote2 - angebote1;
      })[0];
    }

    return mainAngebote;
  };

  getSecondaryFuhrparks = (fuhrparks, mainCar) => {
    return fuhrparks
      .filter(fuhrpark => fuhrpark.new_fuhrparkid !== mainCar.new_fuhrparkid)
      .map(fuhrpark => ({
        value: fuhrpark.new_fuhrparkid,
        name: fuhrpark.new_name,
        hersteller: fuhrpark.new_hersteller,
        model: fuhrpark.new_model
      }));
  };

  getSecondaryAngebotes = (angebotes, mainCar) => {
    return angebotes
      .filter(angebote => angebote.id !== mainCar.id)
      .map(angebote => ({
        value: angebote.id,
        name: angebote.name,
        hersteller: angebote.uds_carBrandName,
        model: angebote.uds_carModelName
      }));
  };

  getExpandPanelsFuhrparks = mainCar => {
    return [
      {
        title: 'Fahrzeug',
        bodyItems: [
          {
            name: 'Marke',
            value: mainCar.new_hersteller
          },
          {
            name: 'Modell',
            value: mainCar.new_model
          },
          {
            name: 'Hauptfahrzeug einrichten'
          }
        ],
        expand: false
      },
      {
        title: 'KFZ-Kennzeichen',
        bodyItems: [
          {
            name: 'KFZ-Kennzeichen',
            value: mainCar.new_name
          }
        ],
        expand: false
      },
      {
        title: 'Fahrgestellnummer',
        bodyItems: [
          {
            name: 'Fahrgestellnummer',
            value: mainCar.new_fin
          }
        ],
        expand: false
      }
    ];
  };

  getKonfigurierteFahrzeugeExpandPanel = (totalItems, userBasketId) => {
    return {
      title: 'Konfigurierte Fahrzeuge',
      bodyItems: [
        {
          name: 'Anzahl Konfigurationen',
          value: totalItems
        },
        {
          name: 'Konfigurationen anzeigen',
          userBasketId
        }
      ],
      expand: false
    };
  };

  defaultKonfigurierteFahrzeugeExpandPanel = {
    title: 'Konfigurierte Fahrzeuge',
    bodyItems: [
      {
        name: 'empty',
        value: 'Sie haben keine aktiven Fahrzeugkonfigurationen.'
      }
    ],
    expand: false
  };

  getMeineBestellungExpandPanel = mainCar => {
    return {
      title: 'Meine Bestellung',
      bodyItems: [
        {
          name: 'Nummer',
          value: mainCar.name
        },
        {
          name: 'Marke',
          value: mainCar.uds_carBrandName
        },
        {
          name: 'Modell',
          value: mainCar.uds_carModelName
        },
        {
          name: 'Status',
          value: mainCar.new_status_anfrage || '-'
        },
        {
          name: 'Dies ist nicht der aktuelle Vorgang.'
        }
      ],
      expand: false
    };
  };

  defaultMeineBestellungExpandPanel = {
    title: 'Meine Bestellung',
    bodyItems: [
      {
        name: 'empty',
        value: 'Sie haben keine offenen Bestellungen.'
      }
    ],
    expand: false
  };

  toggleExpandItem = (dashboardItemId, expandPanelTitle) => {
    const newDashboardItems = cloneDeep(this.state.dashboardItems);
    const dashboardItem = newDashboardItems.find(item => item.id === dashboardItemId);
    dashboardItem.expandPanels.forEach(panel => {
      if (panel.title !== expandPanelTitle) {
        panel.expand = false;
      }
    });
    const expandPanel = dashboardItem.expandPanels.find(panel => panel.title === expandPanelTitle);
    expandPanel.expand = !expandPanel.expand;

    this.setState({ dashboardItems: newDashboardItems });
  };

  async componentDidMount() {
    this.props.showOverlay();
    let userBasket;
    try {
      userBasket = await getUserBasket(this.props.user?.id);
    } catch (e) {
      userBasket = {};
    }
    const userBasketId = userBasket.data?.data?.opportunityid
      ? btoa(userBasket.data.data.opportunityid)
      : btoa(defaultGuid);
    // if (!userBasket.data?.data?.opportunityid) {
    //   this.props.setSnakbar({
    //     isOpen: true,
    //     type: 'warning',
    //     time: 5000,
    //     message: 'Sie haben keinen persönlichen Warenkorb, wenden Sie sich bitte an Mobexo.'
    //   });
    // }

    const allSettledPromiseArray = [getUsersCompanyBasket(this.props.user?.id), getRecentVehicles(this.props.user?.id)];

    if (this.props.user.mainRole.id !== USER_ROLES.mobexoEmployee.id) {
      allSettledPromiseArray.push(getReconfigurationsList(this.props.smartTable, atob(userBasketId)));
    }

    const data = await Promise.allSettled(allSettledPromiseArray);

    this.props.hideOverlay();

    const companyBasket = data[0].value || {};
    const fuhrparks = data[1].value || {};
    const angebotes = data[2]?.value || {};

    if (angebotes.data) {
      angebotes.data.data.items = getResponseFormData(angebotes.data, [{ name: 'items', type: 'array' }], true);
      const angebotesFilteredItems = angebotes.data?.data.items.filter(angebot =>
        [100000005, 100000006].includes(angebot.new_status_anfrage_id)
      );

      const neuesFahrzeugItem = newDashboardItems.find(item => item.id === DashboardItemIds.NewVehicle);
      if (angebotes.data?.data.items.length) {
        neuesFahrzeugItem.expandPanels.push(
          this.getKonfigurierteFahrzeugeExpandPanel(angebotes.data.data.count, userBasketId)
        );
      } else {
        neuesFahrzeugItem.expandPanels.push(this.defaultKonfigurierteFahrzeugeExpandPanel);
      }

      neuesFahrzeugItem.opportunityId = companyBasketId;
      neuesFahrzeugItem.userBasketId = userBasket.data?.data?.opportunityid;

      if (angebotesFilteredItems?.length) {
        const mainAngebote = this.getMainAngebote(angebotesFilteredItems);
        neuesFahrzeugItem.secondaryCars = this.getSecondaryAngebotes(angebotesFilteredItems, mainAngebote);
        neuesFahrzeugItem.expandPanels.push(this.getMeineBestellungExpandPanel(mainAngebote));
      } else {
        neuesFahrzeugItem.expandPanels.push(this.defaultMeineBestellungExpandPanel);
      }
    }

    const companyBasketId = companyBasket.data?.data?.opportunityid
      ? btoa(companyBasket.data.data.opportunityid)
      : defaultGuid;
    const newDashboardItems = cloneDeep(this.state.dashboardItems);

    const meinFahrzeugItem = newDashboardItems.find(item => item.id === DashboardItemIds.MyVehicle);
    if (fuhrparks.data) {
      const mainFuhrpark = this.getMainFuhrpark(fuhrparks.data);
      this.props.setMainCar(mainFuhrpark);
      meinFahrzeugItem.secondaryCars = this.getSecondaryFuhrparks(fuhrparks.data, mainFuhrpark);
      meinFahrzeugItem.expandPanels = this.getExpandPanelsFuhrparks(mainFuhrpark);
    }
    meinFahrzeugItem.opportunityId = companyBasketId;

    this.setState({ dashboardItems: newDashboardItems });
  }

  actions = {
    toggleExpandItem: this.toggleExpandItem,
    openAlert: this.props.openAlert,
    onChangeMainVehicle: this.onChangeMainVehicle,
    closeAlert: this.closeAlert,
    setMainVehicle: this.setMainVehicle
  };

  render() {
    return (
      <div>
        {this.state.dashboardItems.map(dashboardItem =>
          dashboardItem.expandPanels.length || dashboardItem.id === DashboardItemIds.NewVehicle ? (
            <DashboardItem key={dashboardItem.id} actions={this.actions} item={dashboardItem} />
          ) : null
        )}
      </div>
    );
  }
}

export default DashboardItems;
