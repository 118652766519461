export enum ProductId {
  SelfManagedClaims = '0bbd125e-fb31-ee11-bdf4-002248a02d09',
  CustomizeReportDamagePage = '8f9947af-eb18-ef11-9f8a-000d3ab42779',
  InspectionDates = '60a5f415-8edb-ee11-904c-000d3adccfe1',
  ErComplaint = '057f1107-ff05-ef11-9f89-000d3ada824b'
}

export enum ProductName {
  SelfManagedClaims = 'selbstverwaltete Schadenabwicklung (Kunde)',
  InspectionDates = 'Prüftermine',
  ErComplaint = 'ER Reklamationen'
}

export interface ProductResponseProp {
  products: ProductItemProp[];
}

interface ProductItemProp {
  name: ProductName;
  id: ProductId;
}
