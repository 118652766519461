import React, { useContext } from 'react';
import { StyledTitle } from './title.styled';
import { SortingDocumentsTitleProps } from './title.props';
import { SortingDocumentsContext } from '../../../../sorting-documents.context';
import { Direction, Flex, Rotate } from 'common/common-components.styled';
import { ArrowThinOblongRightIcon } from 'components/icons-new/arrow-thin-oblong-right';
import { useTheme } from 'styled-components';

export const SortingDocumentsTitle = ({ title }: SortingDocumentsTitleProps) => {
  const { sorting, setSorting } = useContext(SortingDocumentsContext);

  const theme = useTheme();

  const sortingHandler = () => {
    setSorting(prevState => ({
      column: title,
      isSortAlpha: sorting.column === title ? !prevState.isSortAlpha : true
    }));
  };

  return (
    <Flex onClick={sortingHandler} pointer>
      <StyledTitle>{title}</StyledTitle>
      {sorting.column === title && (
        <Rotate rotateDirection={sorting.isSortAlpha ? Direction.Left : Direction.Right}>
          <ArrowThinOblongRightIcon width={12} height={12} color={theme.colors.grey500} />
        </Rotate>
      )}
    </Flex>
  );
};
