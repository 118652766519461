import React from 'react';
import HistoryContainer from 'components/history-container/history-container';
import classes from './history-widget.module.scss';
import { StyledSpinnerContainer } from 'components/global-reference-vehicles/action-switcher/action-switcher.styled';
import Spinner from 'components/spinner';
import { useFilteredHistoryItems } from '../../../../../../../../../hooks/use-filtered-history-items';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { HistoryWidgetProps } from './history-widget.props';

const HistoryWidget = ({ isExpanded, isShowExpandButton = true }: HistoryWidgetProps) => {
  const { filteredHistoryItems, isHistoryLoading, isToggleButtonAvailable, showAllHistoryItems, toggleShowAllItems } =
    useFilteredHistoryItems(isExpanded);

  if (isHistoryLoading) {
    return (
      <StyledSpinnerContainer minHeight='200px'>
        <Spinner style={spinnerStyle} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <div>
      <HistoryContainer data={filteredHistoryItems} fieldType='Fahrzeug' entityName={PrimaryEntityNames.Vehicle} />

      {isToggleButtonAvailable && isShowExpandButton && (
        <div onClick={toggleShowAllItems} className={classes.action_wrapper}>
          <span>{showAllHistoryItems ? 'Weniger anzeigen' : 'Mehr anzeigen'}</span>
        </div>
      )}
    </div>
  );
};

export default HistoryWidget;

const spinnerStyle = {
  width: '25px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
