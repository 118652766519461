import styled from 'styled-components';

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.colors.grey100}`};
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
`;
