import React from 'react';
import classes from '../my-vehicles-menu.module.scss';
import { MyVehicleMenuItemProps } from '../my-vehicles-menu.props';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { MyVehicleMenuItemContent } from './my-vehicles-menu-item-content';

export const MyVehicleMenuItem = (props: MyVehicleMenuItemProps) => {
  const { tooltip, isEnabled, hidden } = props;

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <div>
          <MyVehicleMenuItemContent
            className={clsx({
              [classes.menu_enabled]: true,
              [classes.menu_disabled]: !isEnabled,
              [classes.menu_flex]: !isEnabled
            })}
            {...props}
          />
        </div>
      </Tooltip>
    );
  }
  return (
    <MyVehicleMenuItemContent
      className={clsx({
        [classes.menu_enabled]: true,
        [classes.menu_disabled]: !isEnabled,
        [classes.menu_flex]: !isEnabled,
        [classes.menu_hidden]: hidden,
        [classes.menu_flex]: !hidden
      })}
      {...props}
    />
  );
};
