import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import Product from '../product';
import { getReconfigurationsList } from '../../services/get-vehicle-list';
import { Pagination } from '../../models/smart-table';
import { default as PaginationComponent } from '../pagination';

class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.itemsCountPerPage = 30;
    this.loadVehicles = this.loadVehicles.bind(this);
    this.pageChangeHandler = this.pageChangeHandler.bind(this);
    this.changePage = this.changePage.bind(this);
    this.state = {
      loaded: false,
      paginationStart: 1,
      totalRecord: 0,
      items: []
    };
  }

  async pageChangeHandler(start) {
    this.props.changePage(start);
  }

  async loadVehicles() {
    try {
      this.setState({ loading: true });
      const params = new URLSearchParams(window.location.search);
      const id = params.get('id') || undefined;
      const { data } = await getReconfigurationsList(this.props.smartTable, id);
      this.setState({ loaded: true, loading: false, items: data.items, totalRecord: data.totalRecord });
    } catch (error) {
      console.log('error', error);
    }
  }

  async componentDidMount() {
    await this.loadVehicles();
    this.props.hideOverlay();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const shouldReloadTable = !isEqual(prevProps.smartTable, this.props.smartTable);
    if (shouldReloadTable) {
      await this.loadVehicles();
    }
  }

  changePage(page) {
    this.props.changePage(new Pagination(page, this.itemsCountPerPage));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    const emptyList = this.state.loaded && this.state.items.length === 0;
    return (
      <div className='mb-0 col_last'>
        <div id='shop' className='product-1 product-list clearfix'>
          <div id='listing-items__js-container' style={{ position: 'relative' }}>
            {this.state.loading && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  zIndex: 10
                }}
              />
            )}
            {emptyList && (
              <div
                className='t-c'
                style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold', padding: '20px 0 20px 0' }}
              >
                Nicht gefunden...
              </div>
            )}
            {this.state.items.map((item, i) => {
              return <Product reloadList={this.loadVehicles} item={item} key={item.id} />;
            })}
          </div>
          <PaginationComponent
            activePage={this.props.smartTable.pagination.start}
            itemsCountPerPage={this.itemsCountPerPage}
            totalItemsCount={this.state.totalRecord}
            onPageChange={this.changePage}
          />
        </div>
        <div className='clearfix' />
      </div>
    );
  }
}

export default withRouter(VehicleList);

VehicleList.propTypes = {
  smartTable: PropTypes.shape({
    pagination: PropTypes.shape({
      start: PropTypes.number.isRequired,
      number: PropTypes.number.isRequired
    }),
    search: PropTypes.object.isRequired,
    sort: PropTypes.object.isRequired
  })
};
