import React from 'react';
import { MultipleSectionProps } from './multiple-section.props';
import { StyledSection, StyledSectionHeader } from '../sections.styled';
import { Grid } from '@mui/material';
import { StyledDivider } from './multiple-section.styled';
import useWindowSize from 'hooks/use-window-size';

export const MultipleSection = ({ label, nodeList, hideNodeList, children, headerProps }: MultipleSectionProps) => {
  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  const filteredData = nodeList.filter((_, nodeIndex) => {
    if (!hideNodeList) {
      return true;
    }

    return !hideNodeList[nodeIndex];
  });

  return (
    <StyledSection>
      {label && <StyledSectionHeader {...headerProps}>{label}</StyledSectionHeader>}

      <Grid
        columns={isMobile ? 1 : filteredData.length}
        container
        columnSpacing={5}
        rowSpacing={isMobile ? 5 : undefined}
      >
        {filteredData.map((node, index) => (
          <Grid key={index} item xs={1} position='relative'>
            {node}
            {index !== filteredData.length - 1 && !isMobile && <StyledDivider color='lightBlue' vertical />}
          </Grid>
        ))}
      </Grid>

      {children}
    </StyledSection>
  );
};
