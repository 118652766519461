import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { userRequestConfig } from 'request-config/user/user.request-config';
import { OpenArchiveDocumentModalParams } from './use-archive.props';

export const useArchiveDocumentsModal = () => {
  const dispatch = useDispatch();

  const { fetch: archiveRequest } = useFetch({
    loadingKey: 'archiveDocuments',
    isShowSuccessSnackbar: true,
    successMessage: 'Dokument wurde erfolgreich archiviert.',
    errorMessage: 'Fehler: Dokument wurde nicht  archiviert.'
  });

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openArchiveDocumentModal = ({ fileId, callbackAfterSubmit }: OpenArchiveDocumentModalParams) => {
    const alertData = {
      title: 'Dokument archivieren',
      children: (
        <p>
          Möchtest Du dieses Dokument wirklich archivieren? Die weitere Verwendung und der Download des Dokuments sind
          dann nicht mehr möglich.
        </p>
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        },
        {
          type: 'submit',
          title: 'Archivieren',
          action: async () => {
            closeModal();

            archiveRequest({
              requestConfig: userRequestConfig.deleteUserDocument(fileId),
              callback: callbackAfterSubmit
            });
          }
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openArchiveDocumentModal };
};
