import { AxiosRequestConfig } from 'axios';
import { FilesByDocumentType, GetFilesByDescriptionsData } from './sharepoint.types';

class SharePointRequestConfig {
  getZipFilesByPath = ({ filesPath, zipArhiveName }: FilesByDocumentType): AxiosRequestConfig => ({
    method: 'POST',
    url: 'sharpoint/getZipFilesByPath',
    responseType: 'blob',
    data: {
      filesPath,
      zipArhiveName
    }
  });

  downloadFileById = (fileId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'sharepoint/downloadFileById',
    responseType: 'blob',
    data: {
      fileId
    }
  });

  getFilesByDescriptions = (data: GetFilesByDescriptionsData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'sharepoint/getFilesByDescriptions',
    data
  });
}

export const sharePointRequestConfig = new SharePointRequestConfig();
