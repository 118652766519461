import React, { useContext, useMemo } from 'react';
import classes from './step-process.module.scss';
import { Flex } from 'common/common-components.styled';
import { FieldRow } from './components/field-row/field-row';
import { StepsProcess } from 'components/steps-process';
import { RepairStatusWidgetContext } from '../../repair-status.context';
import { processSetup } from 'pages/repair-status/components/repair-status-item/process-setup';
import { ExtendedButton } from './components/extended-button/extended-button';
import { StyledContainer } from './item.styled';
import Spinner from 'components/spinner';
import { getFieldRow, getStepsProcessIconColor, spinnerStyle } from './utils';
import { useTheme } from 'styled-components';

export const RepairPartnerItem = () => {
  const { data, headerComponent, isLoading, isExtended } = useContext(RepairStatusWidgetContext);
  const { uds_besichtigungstermin, uds_pickupcardatetime, uds_geplanter_fertigstellungstermin_werkstatt, Status } =
    data;

  const theme = useTheme();

  const filteredProcessSetup = useMemo(
    () =>
      processSetup(
        uds_besichtigungstermin,
        uds_pickupcardatetime,
        uds_geplanter_fertigstellungstermin_werkstatt,
        Status
      ).filter(Boolean),
    [Status, uds_besichtigungstermin, uds_geplanter_fertigstellungstermin_werkstatt, uds_pickupcardatetime]
  );

  return (
    <StyledContainer isExtended={isExtended}>
      <header>
        {headerComponent}
        {getFieldRow(data).map((item, index) => (
          <FieldRow key={index} {...item} />
        ))}
      </header>

      {isLoading && <Spinner style={spinnerStyle} />}

      <StepsProcess
        processSetup={filteredProcessSetup}
        vertical
        iconColors={getStepsProcessIconColor(theme)}
        iconFieldClassName={filteredProcessSetup.length > 2 && classes.field_icon}
        descriptionFieldClassName={filteredProcessSetup.length > 2 && classes.field}
      />
      <ExtendedButton />
    </StyledContainer>
  );
};
