import { PAGES, ReactQueryKeys } from 'common/enums';
import { useQuery } from 'react-query';
import { requestService } from 'services/requests-service/requests-service';
import { modifyFilter } from './utils';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../constants';

export const useMyRequestsFilers = () => {
  const isWorkshopUser = useIsUserHaveRole(USER_ROLES.workshop.id);

  const {
    data: filters,
    isFetching: filterIsFetching,
    refetch
  } = useQuery([PAGES.MY_REQUESTS, ReactQueryKeys.Filter], () => requestService.getRepairPartnerFilters(), {
    select: modifyFilter,
    enabled: isWorkshopUser && window.location.pathname === PAGES.MY_REQUESTS
  });

  return {
    filters,
    filterIsFetching,
    refetch
  };
};
