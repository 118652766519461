import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CalculatorIcon = ({ width = 16, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M15.1789 2.36386C15.1789 1.61374 14.5651 1 13.815 1H8.31806H2.82187C2.07175 1 1.45801 1.61374 1.45801 2.36386V17.6361C1.45801 18.3863 2.07175 19 2.82187 19H8.31806H13.815C14.5651 19 15.1789 18.3863 15.1789 17.6361V2.36386Z'
          fill={fill}
        />
      )}
      <path
        d='M15.1789 17.6361C15.1789 18.3863 14.5651 19 13.815 19H8.31806H2.82187C2.07175 19 1.45801 18.3863 1.45801 17.6361V2.36386C1.45801 1.61374 2.07175 1 2.82187 1H8.31806H13.815C14.5651 1 15.1789 1.61374 15.1789 2.36386V15.1983'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7511 3.47545H3.88599V5.86221H12.7511V3.47545Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.68503 9.58339C5.04989 9.58339 5.34566 9.28762 5.34566 8.92277C5.34566 8.55792 5.04989 8.26215 4.68503 8.26215C4.32018 8.26215 4.02441 8.55792 4.02441 8.92277C4.02441 9.28762 4.32018 9.58339 4.68503 9.58339Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M8.31858 9.58339C8.68343 9.58339 8.9792 9.28762 8.9792 8.92277C8.9792 8.55792 8.68343 8.26215 8.31858 8.26215C7.95373 8.26215 7.65796 8.55792 7.65796 8.92277C7.65796 9.28762 7.95373 9.58339 8.31858 9.58339Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M11.9516 9.58339C12.3165 9.58339 12.6123 9.28762 12.6123 8.92277C12.6123 8.55792 12.3165 8.26215 11.9516 8.26215C11.5868 8.26215 11.291 8.55792 11.291 8.92277C11.291 9.28762 11.5868 9.58339 11.9516 9.58339Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M11.9516 13.2168C12.3165 13.2168 12.6123 12.921 12.6123 12.5562C12.6123 12.1913 12.3165 11.8956 11.9516 11.8956C11.5868 11.8956 11.291 12.1913 11.291 12.5562C11.291 12.921 11.5868 13.2168 11.9516 13.2168Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M8.31858 13.2168C8.68343 13.2168 8.9792 12.921 8.9792 12.5562C8.9792 12.1913 8.68343 11.8956 8.31858 11.8956C7.95373 11.8956 7.65796 12.1913 7.65796 12.5562C7.65796 12.921 7.95373 13.2168 8.31858 13.2168Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M4.68503 13.2168C5.04989 13.2168 5.34566 12.921 5.34566 12.5562C5.34566 12.1913 5.04989 11.8956 4.68503 11.8956C4.32018 11.8956 4.02441 12.1913 4.02441 12.5562C4.02441 12.921 4.32018 13.2168 4.68503 13.2168Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M4.68503 16.8502C5.04989 16.8502 5.34566 16.5544 5.34566 16.1896C5.34566 15.8247 5.04989 15.529 4.68503 15.529C4.32018 15.529 4.02441 15.8247 4.02441 16.1896C4.02441 16.5544 4.32018 16.8502 4.68503 16.8502Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M8.31858 16.8502C8.68343 16.8502 8.9792 16.5544 8.9792 16.1896C8.9792 15.8247 8.68343 15.529 8.31858 15.529C7.95373 15.529 7.65796 15.8247 7.65796 16.1896C7.65796 16.5544 7.95373 16.8502 8.31858 16.8502Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M11.9516 16.8502C12.3165 16.8502 12.6123 16.5544 12.6123 16.1896C12.6123 15.8247 12.3165 15.529 11.9516 15.529C11.5868 15.529 11.291 15.8247 11.291 16.1896C11.291 16.5544 11.5868 16.8502 11.9516 16.8502Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
      />
    </svg>
  );
};
