import {
  GenerateFormFieldPropsType,
  GenerateFormFieldReturn,
  GenerateOptionSetFormFieldPropsType,
  OptionSetFormFieldPropsType
} from './generate-form-fields.props';
import { CrmDataTypes, FormFieldTypes } from 'common/enums';
import { errorMessages } from 'utils/error-messages';

class GenerateFormField {
  text = ({ name = '', validation = {}, ...restProps }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Text,
    defaultValue: '',
    crmFieldType: CrmDataTypes.String,
    validation: {
      maxLength: { value: 100, message: errorMessages.getMaxTextLength(100) },
      ...validation
    },
    ...restProps
  });

  number = ({ name = '', validation = {}, ...restProps }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Number,
    defaultValue: '',
    crmFieldType: CrmDataTypes.Integer,
    validation: {
      min: { value: 0, message: errorMessages.getMinNumberValue(0) },
      maxLength: { value: 9, message: errorMessages.getMaxNumberLength(9) },
      ...validation
    },
    ...restProps
  });

  money = ({ name = '', validation = {}, ...restProps }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Money,
    defaultValue: '',
    crmFieldType: CrmDataTypes.Money,
    validation: {
      min: { value: 0, message: errorMessages.number },
      maxLength: { value: 9, message: errorMessages.getMaxNumberLength(9) },
      ...validation
    },
    ...restProps
  });

  date = ({ name = '', validation = {}, ...restProps }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Date,
    defaultValue: '',
    crmFieldType: CrmDataTypes.DateTime,
    validation,
    ...restProps
  });

  optionSet = ({
    name = '',
    validation = {},
    type = FormFieldTypes.OptionSet,
    ...restProps
  }: OptionSetFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type,
    defaultValue: '',
    crmFieldType: CrmDataTypes.Lookup,
    validation,
    ...restProps
  });

  booleanOptionSet = ({
    name = '',
    validation = {},
    values,
    labels,
    ...restProps
  }: GenerateOptionSetFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    crmFieldType: CrmDataTypes.Boolean,
    type: FormFieldTypes.OptionSet,
    isBooleanOptionSet: true,
    options: [
      { label: labels?.true || 'Ja', value: values?.true || 'true' },
      { label: labels?.false || 'Nein', value: values?.false || 'false' }
    ],
    defaultValue: '',
    validation,
    ...restProps
  });

  checkbox = ({
    name = '',
    label = '',
    defaultValue = false,
    ...restProps
  }: OptionSetFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    label,
    defaultValue,
    type: FormFieldTypes.Checkbox,
    crmFieldType: CrmDataTypes.Boolean,
    validation: {},
    ...restProps
  });

  textArea = ({
    name = '',
    defaultValue = '',
    validation = {},
    ...restProps
  }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    defaultValue,
    type: FormFieldTypes.TextArea,
    crmFieldType: CrmDataTypes.String,
    textAreaProps: {
      maxHeight: 400,
      minRows: 4
    },
    validation: {
      maxLength: { value: 1000, message: errorMessages.getMaxTextLength(1000) },
      ...validation
    },
    ...restProps
  });

  percentages = ({
    name = '',
    validation = {},
    ...restProps
  }: GenerateFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Percentages,
    defaultValue: '',
    crmFieldType: CrmDataTypes.Integer,
    validation: {
      min: { value: 0, message: errorMessages.number },
      max: { value: 100, message: errorMessages.getMaxNumberValue(100) },
      ...validation
    },
    ...restProps
  });

  lookup = ({ name = '', ...restProps }: OptionSetFormFieldPropsType): GenerateFormFieldReturn => ({
    name,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    crmFieldType: CrmDataTypes.Lookup,
    options: [],
    validation: {},
    ...restProps
  });
}

export const generateFormField = new GenerateFormField();
