import React from 'react';
import classes from './info-chip.module.scss';
import { InfoChipProps } from './info-chip.props';

const InfoChip = (props: InfoChipProps) => {
  const { color, bgcolor, text, className } = props;
  return (
    <div
      {...props}
      style={{ color, backgroundColor: bgcolor }}
      className={className ? `${classes.chip} ${className}` : classes.chip}
    >
      {text}
    </div>
  );
};

export default InfoChip;
