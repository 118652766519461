import { ResponseModel, User } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { getVehicleForCreateTask } from 'components/aufgaben-component/utils';
import { GetVehicleForCreateTaskResponse } from '../../../components/aufgaben-component/task.prop';
import { AxiosResponse } from 'axios';
import { useTypedSelector } from '../../use-typed-selector';

export const useVehicleForCreateTaskQuery = (companyList?: string[]) => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const userCompanies = [user.companyId, ...user.childCompanies.map(childCompany => childCompany.id)];

  const { data: vehicleForCreateTaskData, isFetching: isVehicleForCreateTaskFetching } = useQuery<
    AxiosResponse<ResponseModel<GetVehicleForCreateTaskResponse[]>>
  >([ReactQueryKeys.GetVehiclesForCreateTask, companyList || userCompanies], () =>
    getVehicleForCreateTask(companyList || userCompanies)
  );

  return {
    vehicleForCreateTaskData: vehicleForCreateTaskData?.data.data || [],
    isVehicleForCreateTaskFetching
  };
};
