import React, { MouseEvent, cloneElement, useContext } from 'react';
import classes from '../cell.module.scss';
import classNames from 'classnames';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import { CellContext } from 'components/table-component/table-component-contexts';

export const ChipCell = ({ column, row, toggle, openedItemId }: CellProps) => {
  const { cellId } = useContext<ICellContext>(CellContext);
  const isEnabled = column.isEnabled && column.isEnabled(row);

  const clickHandler = (event: MouseEvent<HTMLDivElement>) => {
    if (isEnabled && toggle) {
      toggle(event, false);
    }
  };

  return (
    <span
      id={cellId}
      style={{ width: column.width, zIndex: isEnabled ? 10 : 'auto' }}
      className={classNames(classes.table_chip, column.className, classes.cell)}
      onClick={clickHandler}
    >
      {column.component ? (
        cloneElement(column.component, { row, openedItemId })
      ) : (
        <i className={classes.chip}>{row[column.propName || ''] || '-'}</i>
      )}
    </span>
  );
};
