import React from 'react';
import { Flex, Text, Button } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { isEmpty } from 'lodash';
import { leaseExtensionOfferFormFields } from './lease-extension-offer-form-fields';
// eslint-disable-next-line max-len
import { LeaseExtensionOfferProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';

const LeaseExtensionOffer = ({ onSubmit, onCancel }: LeaseExtensionOfferProps) => {
  const theme = useTheme();

  const reactHookFormData = useForm();

  const {
    handleSubmit,
    formState: { errors }
  } = reactHookFormData;

  const submit = () => {
    handleSubmit(data => {
      onSubmit(data.offerFiles, data[LeasingContractFields.Comment]);
    })();
  };

  return (
    <Flex direction='column'>
      <Text color={theme.colors.black} bottom='30'>
        Bitte lade das Angebot hoch.
      </Text>
      <FormHook reactHookFormData={reactHookFormData} formFields={leaseExtensionOfferFormFields} />
      <Flex justify='space-between' align='center' width='100%'>
        <Text pointer onClick={onCancel}>
          &larr; Zurück
        </Text>
        <Button id={'submitButton'} disabled={!isEmpty(errors)} onClick={submit}>
          Absenden
        </Button>
      </Flex>
    </Flex>
  );
};

export default LeaseExtensionOffer;
