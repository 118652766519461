import React, { useState, useEffect } from 'react';
import ReactJoyride, { CallBackProps, TooltipRenderProps, ACTIONS, EVENTS, FloaterProps } from 'react-joyride';
import { toggleAfterTourPopUp, toggleModal } from 'actions/app_action';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import MeetingBooking from 'components/meeting-booking/meeting-booking';
import { MODALS, MODAL_NAMES } from '../../constants';
import { deleteBooking } from 'components/meeting-booking/meeting-booking-service';
import { useOverlay } from 'hooks';
import MeetingModal from 'components/meeting-booking/meeting-modal';
import SupportPopUp from 'components/support-pop-up/support-pop-up';

export type Meetinginfo = {
  id: string;
  startDate: Date;
  duration: string;
};

const TooltipComponent = ({ step, closeProps, primaryProps, tooltipProps }: TooltipRenderProps) => {
  return (
    <SupportPopUp
      tooltipProps={tooltipProps}
      primaryProps={primaryProps}
      closeProps={closeProps}
      title={step.title}
      content={step.content}
    />
  );
};

function AfterTourPopUp() {
  const dispatch = useDispatch();
  const showAfterTourPopUp = useTypedSelector(state => state.app.showAfterTourPopUp);
  const [meetingInfo, setMeetingInfo] = useState<Meetinginfo>({} as Meetinginfo);
  const [showOverlay, hideOverlay] = useOverlay();
  const loginCounter = useTypedSelector(state => state['app'].loginCounter);
  const isOddLogin = loginCounter % 2 === 0;

  const steps = [
    {
      title: isOddLogin ? 'So überzeugst Du Dein Team!' : 'Möchtest Du mehr erfahren?',
      content: isOddLogin ? (
        <span>
          Entdecke warum Mobexo das perfekte Portal für dein Unternehmen ist.
          <span style={{ fontWeight: 'bold' }}>
            <br />
            Unsere besten Argumente für Chef, Betriebsrat & IT zur Hilfe.
          </span>
        </span>
      ) : (
        <span>
          <span style={{ fontWeight: 'bold' }}>Buche einen Termin mit unserem Mobexo-Team</span> um eine geführte Tour
          durch das Portal zu erhalten und Deine Fragen persönlich beantwortet zu bekommen.
        </span>
      ),
      target: '#meeting_booking',
      placement: 'right-end',
      disableBeacon: true
    }
  ];

  const handleJoyCallback = (data: CallBackProps) => {
    const { action, type } = data;
    if ((type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) && action === ACTIONS.NEXT) {
      handleOpenForm();
    }
    if (type === EVENTS.TOUR_END) {
      dispatch(toggleAfterTourPopUp(false));
    }
  };

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
    setMeetingInfo({} as Meetinginfo);
  };

  const handleOpenForm = () => {
    const bookingAlert = {
      title: 'Termin buchen',
      children: <MeetingBooking closeModal={closeModal} setMeetingInfo={setMeetingInfo} />,
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, abbrechen',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, bookingAlert));
  };

  const handleOpenConfirmModal = () => {
    const confirmAlert = {
      title: 'Terminbestätigung',
      children: (
        <MeetingModal
          meetingInfo={meetingInfo}
          secondaryButtonAction={handleOpenAreYouSureDeactivateModal}
          secondaryButtonTitle={'Termin stornieren'}
          showDescription
          mainButtonTitle='Fertig'
          mainButtonAction={closeModal}
          meetingTitle='Vielen Dank für Dein Interesse.'
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Termin stornieren',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, confirmAlert));
  };

  const handleOpenAreYouSureDeactivateModal = () => {
    const confirmAlert = {
      title: 'Termin stornieren',
      children: (
        <MeetingModal
          meetingTitle='Möchtest Du folgenden Termin stornieren?'
          meetingInfo={meetingInfo}
          mainButtonTitle='Ja, bitte stornieren'
          mainButtonAction={handleDeactivateMeeting}
          secondaryButtonTitle='Nein, Termin behalten'
          secondaryButtonAction={closeModal}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, Termin behalten',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, confirmAlert));
  };

  const openSuccessModal = () => {
    const sucessAlert = {
      title: 'Termin stornieren',
      children: (
        <MeetingModal
          meetingTitle='Dein Termin wurde erfolgreich storniert.'
          meetingInfo={meetingInfo}
          mainButtonTitle='Neuen Termin vereinbaren'
          mainButtonAction={() => {
            setMeetingInfo({} as Meetinginfo);
            handleOpenForm();
          }}
          secondaryButtonTitle='Fertig'
          secondaryButtonAction={closeModal}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, Termin behalten',
          action: closeModal
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, sucessAlert));
  };

  const handleDeactivateMeeting = async () => {
    try {
      showOverlay();
      const response = await deleteBooking(meetingInfo.id);

      if (response?.status === 200) {
        openSuccessModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideOverlay();
    }
  };

  useEffect(() => {
    if (meetingInfo.id) {
      handleOpenConfirmModal();
    }
  }, [meetingInfo.id]);

  return (
    <ReactJoyride
      run={showAfterTourPopUp}
      //@ts-ignore
      steps={steps}
      continuous
      hideBackButton
      scrollOffset={100}
      scrollToFirstStep
      showSkipButton={false}
      callback={handleJoyCallback}
      hideFooter
      disableOverlay
      tooltipComponent={TooltipComponent as React.ElementType<TooltipRenderProps>}
      floaterProps={{ hideArrow: true } as unknown as FloaterProps}
    />
  );
}

export default AfterTourPopUp;
