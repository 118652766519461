import React from 'react';
import { crmDataTypes } from '../../../constants';

export const formFields = {
  columns: [
    {
      width: '50%',
      fields: [
        {
          name: 'subject',
          type: 'text',
          label: 'Frage',
          crmFieldType: crmDataTypes.String,
          validation: [{ name: 'required' }]
        }
      ]
    },
    {
      width: '50%',
      fields: [
        {
          name: 'scheduledend',
          type: 'date',
          label: 'Fälligkeit',
          prefix: <img alt='calendar' src='/assets/images/calendar-with-check-mark-icon.svg' />,
          placeholder: 'Datum',
          crmFieldType: crmDataTypes.DateTime,
          validation: [{ name: 'required' }],
          defaultValue: new Date(),
          disabled: true
        }
      ]
    },    
    {
      width: '100%',
      fields: [
        {
          name: 'description',
          type: 'textarea',
          label: 'Beschreibung',
          resizeType: 'vertical',
          minHeight: '120px',
          crmFieldType: crmDataTypes.String,
          validation: []
        }
      ]
    }
  ]
};
