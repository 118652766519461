import React, { useContext } from 'react';
import { KilometerWidget } from '../../widgets/kilometer/kilometer';
import { VehicleCostsWidget } from '../../widgets/vehicle-const/vehicle-costs';
import { CalendarAppointmentWidget } from '../../widgets/calendar-appointment/calendar-appointment';
import { useIsShowVehicleDetailTopWidgetLayout } from './hooks/use-is-show-top-widget-layout';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { isEmpty } from 'lodash';
import { LeasingPeriodWidget } from '../../widgets/leasing-period/leasing-period';
import { ConsumptionWidget } from '../../widgets/consumption/consumption';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailTopWidgetLayout = () => {
  const { vehicle } = useContext(VehicleDetailContext);
  const {
    isShowVehicleDetailTopWidgetLayout,
    isShowKilometerWidget,
    isShowVehicleCostWidget,
    calendarAppointmentWidgetIsShow,
    leasingPeriodWidgetIsShow,
    isShowConsumptionWidget
  } = useIsShowVehicleDetailTopWidgetLayout();

  const { windowWidth } = useWindowSize(500);

  if (!isShowVehicleDetailTopWidgetLayout || isEmpty(vehicle || {})) {
    return null;
  }

  return (
    <>
      {isShowKilometerWidget && <KilometerWidget />}
      {isShowVehicleCostWidget && <VehicleCostsWidget />}
      {calendarAppointmentWidgetIsShow && <CalendarAppointmentWidget />}
      {leasingPeriodWidgetIsShow && <LeasingPeriodWidget />}
      {isShowConsumptionWidget && windowWidth > 550 && <ConsumptionWidget />}
    </>
  );
};
