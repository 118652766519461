import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../../constants';
import { InactiveCarModalContent } from './modal-content';
import { OpenInactiveCarModalProps } from './use-inactive-car.props';
import { AlertProps } from 'components/alert/alert-view';
import { PickValue } from 'common/interfaces';

export const useInactiveCarModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getAlertData = ({ carId, callback }: OpenInactiveCarModalProps): PickValue<AlertProps, 'parameters'> => ({
    title: 'Inaktives Auto',
    children: <InactiveCarModalContent carId={carId} closeAlert={closeAlert} />,
    hideCloseIcon: true,
    buttons: [
      {
        type: 'cancel',
        title: 'Nein, abbrechen',
        action: closeAlert
      },
      {
        type: 'submit',
        title: 'Ja, weitermachen',
        action: () => {
          closeAlert();
          callback();
        }
      }
    ]
  });

  const openInactiveCarModal = (props: OpenInactiveCarModalProps) => {
    const { isCarInactive, callback } = props;
    if (isCarInactive) {
      return dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(props)));
    }

    callback();
    closeAlert();
  };

  return { openInactiveCarModal };
};
