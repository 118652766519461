import React, { Component } from 'react';

import { getVehicleImagesAsync } from '../../services/get-vehicle-details';
import Spinner from '../../components/spinner';
import { defaultCarImageUrl } from '../../constants';
import PropTypes from 'prop-types';
import { Gallery } from 'components/gallery/image-gallery/gallery';
import { Flex } from 'common/common-components.styled';

export default class VehicleGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: null
    };
  }

  async componentDidMount() {
    const { data } = await getVehicleImagesAsync(this.props.location);
    this.setState({ images: data.map(image => `data:image/jpeg;base64,${image.split(',')[1]}`) });
  }

  render() {
    const { images } = this.state;
    if (!images) {
      return (
        <Spinner
          style={{
            width: '100px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      );
    } else if (images.length) {
      return <Gallery images={this.state.images} gallery={[]} />;
    }

    return (
      <Flex padding={'0 15px 15px 0'}>
        <img src={defaultCarImageUrl} alt='Vehicle car placeholder' />
      </Flex>
    );
  }
}

VehicleGallery.propTypes = {
  location: PropTypes.string.isRequired
};
