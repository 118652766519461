import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './filter-container.module.scss';
import useOutsideClick from 'hooks/use-outside-click/use-outside-click';
import { PrefixForId } from '../../../common/enums';
import { Flex, Text } from 'common/common-components.styled';

const FilterContainer = ({ header, onClose, children, isNothingFound = false }) => {
  const containerRef = useRef(null);

  useOutsideClick(containerRef, onClose);

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.header} id={PrefixForId.Filter + PrefixForId.Title}>
        {header}
        <img
          onClick={onClose}
          src='/assets/images/close-icon2.svg'
          alt='close_icon'
          id={PrefixForId.Filter + PrefixForId.Button + PrefixForId.Close}
        />
      </div>
      {isNothingFound ? (
        <Flex justify='center' margin='10px'>
          <Text bottom='10'>Nichts gefunden</Text>
        </Flex>
      ) : (
        <div className={classes.content}>{children}</div>
      )}
    </div>
  );
};

FilterContainer.propTypes = {
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  isNothingFound: PropTypes.bool
};

export default FilterContainer;
