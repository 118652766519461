import React from 'react';
import { InstanceComponentProps } from './instance-component.types';
import FormHook from 'components/form-hook/form-hook';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { ApproversInstanceValues } from '../approval-rules/approval-rules.types';
import { FormFieldTypes } from 'common/enums';
import useContactsWithEmail from 'hooks/react-query/use-contacts-with-email';
import { Flex } from 'common/common-components.styled';

const InstanceComponent = (props: InstanceComponentProps) => {
  const { reactHookFormData, instanceFieldLabel, instanceFieldName, contactFieldName, contactFieldLabel } = props;
  const { getValues } = reactHookFormData;
  const { driversWithEmail } = useContactsWithEmail();

  const instanceFieldValue = getValues(instanceFieldName);

  return (
    <Flex>
      <Flex width='50%' right='10'>
        <FormHook
          reactHookFormData={reactHookFormData}
          formFields={[
            generateFormField.optionSet({
              label: instanceFieldLabel,
              name: instanceFieldName,
              defaultValue: '100000000',
              options: OPTION_SET.uds_order1_approver_type_value
            })
          ]}
        />
      </Flex>

      <Flex width='50%'>
        {instanceFieldValue === ApproversInstanceValues.Contact && (
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={[
              generateFormField.optionSet({
                label: contactFieldLabel,
                name: contactFieldName,
                defaultValue: '',
                type: FormFieldTypes.Lookup,
                options: driversWithEmail.map(driver => ({
                  value: driver.id,
                  label: driver.name
                })),
                validation: {
                  required: 'Bitte auswählen'
                }
              })
            ]}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default InstanceComponent;
