import { FuelReportsQueryOptions, GetFuelReportFilter } from './fuel-reports-types';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { FuelReportFiltersPayload, reportsRequestConfig } from 'request-config/reports/reports.request-config';
import { FuelReportsReactQuery } from './fuel-reports-props';
import { useQuery } from 'react-query';
import { ResponseModel } from 'common/interfaces';

export const useFuelReportNameFilterQuery = (props: FuelReportFiltersPayload, { options }: FuelReportsQueryOptions) => {
  const { fetch } = useFetch<ResponseModel<GetFuelReportFilter>>({
    loadingKey: 'fuelReportFilterName',
    ...reportsRequestConfig.getFuelReportFilters(props)
  });

  const { data, ...restQueryProps } = useQuery(
    [FuelReportsReactQuery.GetFuelReportsFilterName, props],
    () => fetch(),
    options
  );

  return { getFuelReportFilterQuery: data?.data?.data || {}, ...restQueryProps };
};
