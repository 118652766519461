import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const CarPlumpLeftRightArrow = ({
  width = 24,
  height = 24,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M20.2133 10.1579C20.3366 10.4843 20.4019 10.8326 20.4019 11.188V11.6015V15.3503L19.1709 16.0848H8.49734V17.2455C8.49734 17.5647 8.23618 17.8259 7.91698 17.8259H5.59555C5.27635 17.8259 5.01519 17.5647 5.01519 17.2455V15.7366L4.78305 14.6266C4.74678 14.438 4.72501 14.2349 4.72501 14.039V11.188C4.72501 10.8398 4.79031 10.4843 4.91363 10.1579L5.19655 9.41068H3.85448C3.66586 9.41068 3.52803 9.23657 3.57155 9.0552L3.78919 8.11938C3.85448 7.85821 4.08662 7.6696 4.35504 7.6696H5.77691L6.89411 5.44248C7.19154 4.84761 7.79366 4.47763 8.45382 4.47763H16.6731C17.3333 4.47763 17.9354 4.85487 18.2329 5.44248L19.35 7.6696H20.7719C21.0403 7.6696 21.2797 7.85821 21.3378 8.11938L21.5554 9.0552C21.5989 9.23657 21.4611 9.41068 21.2725 9.41068H19.9304L20.2133 10.1579Z'
          fill='white'
        />
      )}
      {hovered && (
        <path
          d='M20.2133 10.1579C20.3366 10.4844 20.4019 10.8326 20.4019 11.188V11.6015V15.2396L19.1893 16.0848H8.49734V17.2455C8.49734 17.5647 8.23618 17.8259 7.91698 17.8259H5.59555C5.27635 17.8259 5.01519 17.5647 5.01519 17.2455V15.7366L4.78305 14.6267C4.74678 14.438 4.72501 14.2349 4.72501 14.0391V11.188C4.72501 10.8398 4.79031 10.4844 4.91363 10.1579L5.19655 9.41069H3.85448C3.66586 9.41069 3.52803 9.23658 3.57155 9.05522L3.78919 8.11939C3.85448 7.85823 4.08662 7.66962 4.35504 7.66962H5.77691L7.39469 8.78627H17.6937L19.35 7.66962H20.7719C21.0403 7.66962 21.2797 7.85823 21.3378 8.11939L21.5554 9.05522C21.5989 9.23658 21.4611 9.41069 21.2725 9.41069H19.9304L20.2133 10.1579Z'
          fill={fill}
        />
      )}
      <path
        d='M8.49343 16.0848V17.2455C8.49343 17.5647 8.23227 17.8259 7.91307 17.8259H5.59164C5.27244 17.8259 5.01128 17.5647 5.01128 17.2455V15.7366L4.77914 14.6267C4.74287 14.4381 4.7211 14.2349 4.7211 14.0391V11.1881C4.7211 10.8398 4.7864 10.4844 4.90973 10.1579L5.19264 9.41071H3.85057C3.66196 9.41071 3.52412 9.2366 3.56765 9.05524L3.78528 8.11941C3.85057 7.85824 4.08272 7.66963 4.35113 7.66963H5.773L6.8902 5.44251C7.18764 4.84764 7.78976 4.47766 8.44991 4.47766H16.6692C17.3294 4.47766 17.9315 4.8549 18.229 5.44251L19.3461 7.66963H20.768C21.0364 7.66963 21.2758 7.85824 21.3339 8.11941L21.5515 9.05524C21.595 9.2366 21.4572 9.41071 21.2686 9.41071H19.9265L20.2094 10.1579C20.3327 10.4844 20.398 10.8326 20.398 11.1881V11.6016M8.49343 16.0848H7.33271M8.49343 16.0848H8.98104'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.46973 12.6027H8.88547L8.47197 11.7829'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.63037 8.83035H17.4965'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      {hovered && (
        <path
          d='M14.1558 15.589L17.5751 15.589L17.5751 16.7779L21.2224 14.4001L17.5751 12.0223L17.5751 13.2112L16.1948 13.2112'
          fill='white'
        />
      )}
      {hovered && (
        <path
          d='M17.4248 15.705L14.0471 15.705L14.0471 14.5306L10.4443 16.8794L14.0471 19.2282L14.0471 18.0538L15.4106 18.0538'
          fill={fill}
        />
      )}
      <path
        d='M15.9585 18.2723H13.9585V19.5223L10.2085 17.0223L13.9585 14.5223V15.7723H17.4585V17.0223L21.4585 14.5223L17.4585 12.0223V13.2723H15.9585'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
