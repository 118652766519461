import styled from 'styled-components';

export const StyledSettingsWrapper = styled.div`
  //position: relative;
`;

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  width: 100%;
  left: 0;
  position: absolute;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
