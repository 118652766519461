import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './dashboard.module.scss';
import { WidgetTile } from 'components/widgets/tile/tile';
import { RecordNumberMultiplyItem } from './records-number-multiply/records-number-multiply.props';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { DamageChart } from './damage-chart/damage-chart';
import { useDashboardQuery } from 'hooks/react-query/dashboard';
import { VehicleCostsChart } from './vehicle-costs-chart/vehicle-costs-chart';
import { ConsumptionHalfCircle } from './consumption/consumption-half-circle';
import { OpenPageProps, TileTitle } from './dashboard.props';
import { MileageChart } from './mileage-chart/mileage-chart';
import { CarbonDioxideEmission } from './carbon-dioxide-emission/carbon-dioxide-emission';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useHistory } from 'react-router-dom';
import { PAGES, PrefixForId } from 'common/enums';
import { useSessionFilter } from 'hooks/use-session-filter';
import {
  VehicleStateCodeLabel,
  VehicleStateCodeValue,
  VehicleStatusesLabel,
  VehicleStatusesValue
} from 'pages/my-vehicles/my-vehicles.props';
import { useTheme } from 'styled-components';
import CompanyFilter from 'components/dashboard-component-chart/company-filter';
import { removeSpaces } from 'utils/remove-spaces';
import { Flex, Distance, Text } from 'common/common-components.styled';
import { RecalculateAllCompaniesIcon } from 'components/icons/recalculate-all-companies-icon';
import { RecalculateCurrentCompanyDataIcon } from 'components/icons/recalculate-current-company-data-icon';
import { useOverlay } from 'hooks';
import dashboardService from 'services/dashboard-service';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { MODALS, MODAL_NAMES, RequestStatuses, USER_ROLES } from '../../constants';
import { toggleModal } from 'actions/app_action';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import DriverDiagram from './driver-diagram/driver-diagram';
import { TaskRecord } from '../widgets/task/task/task.props';
import DataQualityChart from './data-quality-chart/data-quality-chart';
import { TaskWidget } from 'components/widgets/task/task/task';
import VehicleDiagram from './vehicle-diagram/vehicle-diagram';
import { Car } from 'components/icons-new/car';
import { UsedCar } from 'components/icons-new/used-car';
import { ShoppingCart } from 'components/icons-new/shopping-cart';
import { NewCarIcon } from 'components/icons-new/new-car';
import { VehicleCostsCircleChart } from './vehicle-costs-chart/vehicle-costs-circle-chart';
import { ConsumptionTable } from './consumption/consumption-table';
import { DamagedCarIcon } from 'components/icons-new/damaged-car';
import { FleetManagerDashboardContext } from './dashboard.context';
import { QuickLinks } from './quick-links/quick-links';
import { CRM_VALUE as CRM_VALUE_MY_VEHICLE } from 'pages/my-vehicles/my-vehicles';
import { CRM_VALUE as CRM_VALUE_CAR_POLICY_PAGE } from 'pages/fahrzeuge-der-car-policy-page/fahrzeuge-der-car-policy-page';
import { useOpenPageViaDashBoard } from 'pages/dashboard/hooks/use-open-page';
import { CRM_VALUE_TASK_PAGE } from 'components/aufgaben-component/aufgaben-component-view';
import {
  DUE_DATE_NAMES,
  DUE_DATE_VALUES
} from 'components/aufgaben-component/components/aufgaben-filters/aufgaben-filters';
import { StatusId, StatusName } from 'components/aufgaben-component/task.prop';
import moment from 'moment';
import { ActiveCompany } from 'components/dashboard-component-chart/company-filter/company-filter.props';

export const DashboardChart = () => {
  const user = useTypedSelector(state => state.app.user);
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { dataForServer, dataForClient } = getDefaultDashboardFilterState(user.companyId, user.companyName);

  const { sessionFilter, setSessionFilter } = useSessionFilter(PAGES.DASHBOARD, dataForServer, dataForClient);

  const [showOverlay, hideOverlay] = useOverlay();
  const { showRequestSnackbar } = useRequestSnackbar();
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);

  const [activeCompany, setActiveCompany] = useState<{ id: string; name: string }>({
    id: sessionFilter?.filterForServer.activeCompany,
    name: sessionFilter?.filterForClient.activeCompany
  });

  const { drivers, cars, dataQuality, vehicleCosts, tasks, overdueTasks, consumption, damage, driversCheck, mileage } =
    useDashboardQuery(activeCompany?.id);

  const { openPage } = useOpenPageViaDashBoard(activeCompany);

  const reverseDateCompare = (a: TaskRecord, b: TaskRecord): number => {
    const dateA = a.scheduledend ? new Date(a.scheduledend) : new Date('1900-01-01T00:00:00+00:00');
    const dateB = b.scheduledend ? new Date(b.scheduledend) : new Date('1900-01-01T00:00:00+00:00');
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  const dateCompare = (a: TaskRecord, b: TaskRecord): number => {
    const dateA = a.scheduledend ? new Date(a.scheduledend) : new Date('1900-01-01T00:00:00+00:00');
    const dateB = b.scheduledend ? new Date(b.scheduledend) : new Date('1900-01-01T00:00:00+00:00');
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  };

  const [sortedTasks, setSortedTasks] = useState<TaskRecord[]>([]);
  const [overdueSortedTasks, setOverdueSortedTasks] = useState<TaskRecord[]>([]);

  useEffect(() => {
    if (tasks?.data?.length) {
      let newSortedTasksArray: TaskRecord[] = [...tasks.data].sort(dateCompare);
      setSortedTasks(newSortedTasksArray);
    } else {
      setSortedTasks([]);
    }
    if (overdueTasks?.data?.length) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let newOverdueTasksArray: TaskRecord[] = [...overdueTasks.data]
        .filter(task => !task.scheduledend || new Date(task.scheduledend) <= today)
        .sort(reverseDateCompare);
      setOverdueSortedTasks(newOverdueTasksArray);
    } else {
      setOverdueSortedTasks([]);
    }
  }, [tasks.data, overdueTasks.data]);

  const [isRefreshCacheAllCompaniesHovered, setIsRefreshCacheAllCompaniesHovered] = useState(false);
  const [isRereshCacheCurrentCompanyHovered, setIsRefreshCacheCurrentCompanyHovered] = useState(false);

  const isAdmin = useIsUserHaveRole(USER_ROLES.administrator.id);

  useEffect(() => {
    setActiveCompany({
      id: sessionFilter?.filterForServer.activeCompany,
      name: sessionFilter?.filterForClient.activeCompany
    });
  }, [sessionFilter?.filterForServer.activeCompany]);

  const setActiveCompanyHandler = useCallback(
    (activeCompany: ActiveCompany) => {
      setSessionFilter(
        { ...sessionFilter.filterForServer, activeCompany: activeCompany.id },
        { ...sessionFilter.filterForClient, activeCompany: activeCompany.name }
      );
    },
    [sessionFilter]
  );

  const closeModal = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const refreshCacheAllCompanies = () => {
    const alertData = {
      title: 'Nutzer vom Fahrzeug entfernen',
      children: <Text>{REFRESH_CACHE_MODAL_TEXT}</Text>,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeModal
        },
        {
          type: 'submit',
          title: 'Bestätigen',
          action: () => {
            handleRefreshCache();
            closeModal();
          }
        }
      ]
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const handleRefreshCache = (companyId?: string | null) => {
    showOverlay();
    dashboardService
      .refreshDashboardCache(companyId)
      .then(response => {
        showRequestSnackbar(
          response.data.level === RequestStatuses.Success,
          hideOverlay,
          SNACKBAR_SUCCESS_MESSAGE,
          SNACKBAR_ERROR_MESSAGE,
          5000
        );
      })
      .catch(error => {
        hideOverlay();
        throw new Error(error);
      });
    dashboardService
      .refreshDashboardCache(companyId)
      .then(response => {
        showRequestSnackbar(
          response.data.level === RequestStatuses.Success,
          hideOverlay,
          SNACKBAR_SUCCESS_MESSAGE,
          SNACKBAR_ERROR_MESSAGE,
          5000
        );
      })
      .catch(error => {
        hideOverlay();
        throw new Error(error);
      });
  };

  const multiplyRecords: RecordNumberMultiplyItem[] = useMemo(() => {
    return [
      {
        title: 'in Betrieb',
        amount: cars.data?.quantityInBetrieb,
        icon: <Car color={theme.colors.gold} />,
        onClick: openPage({
          page: PAGES.MY_VEHICLES,
          companyCrmName: CRM_VALUE_MY_VEHICLE.company,
          filterForServer: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesValue.InOperation],
            [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeValue.Active]
          },
          filterForClient: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesLabel.InOperation],
            [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeLabel.Active]
          }
        })
      },
      {
        title: 'ausgesteuert',
        amount: cars.data?.quantityAusgesteuert,
        icon: <UsedCar color={theme.colors.gold} />,
        onClick: openPage({
          page: PAGES.MY_VEHICLES,
          companyCrmName: CRM_VALUE_MY_VEHICLE.company,
          filterForServer: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesValue.Controlled]
          },
          filterForClient: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesLabel.Controlled]
          }
        })
      },
      {
        title: 'bestellt',
        amount: cars.data?.quantityBestellt,
        icon: <ShoppingCart color={theme.colors.gold} />,
        onClick: openPage({
          page: PAGES.MY_VEHICLES,
          companyCrmName: CRM_VALUE_MY_VEHICLE.company,
          filterForServer: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesValue.Ordered],
            [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeValue.Active]
          },
          filterForClient: {
            [CRM_VALUE_MY_VEHICLE.status]: [VehicleStatusesLabel.Ordered],
            [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeLabel.Active]
          }
        })
      },
      {
        title: 'konfiguriert',
        amount: cars.data?.quantityKonfiguriert,
        icon: <NewCarIcon color={theme.colors.gold} width={22} height={19} />,
        onClick: openPage({
          page: PAGES.EMPLOYEE_CONFIGURATIONS,
          companyCrmName: CRM_VALUE_CAR_POLICY_PAGE.company
        })
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars.data, history]);

  let tasksTitles = [TileTitle.UpcomingTasks, TileTitle.UpcomingTasksSorted];
  if (sortedTasks.length === 0 && overdueSortedTasks.length > 0) {
    tasksTitles = [TileTitle.UpcomingTasksSorted, TileTitle.UpcomingTasks];
  }

  const openTaskPage = (filterState?: Pick<OpenPageProps, 'filterForClient' | 'filterForServer'>) =>
    openPage({ page: PAGES.TASKS, companyCrmName: CRM_VALUE_TASK_PAGE.company, ...filterState });

  const openTaskPageOverdue = openTaskPage({
    filterForClient: { [CRM_VALUE_TASK_PAGE.scheduledendStatus]: DUE_DATE_NAMES.overdue },
    filterForServer: { [CRM_VALUE_TASK_PAGE.scheduledendStatus]: DUE_DATE_VALUES.overdue }
  });

  const currentDate = moment();
  const upcomingDate = moment().add(50, 'years');

  const openTaskPageUpcomingTasks = openTaskPage({
    filterForClient: {
      [CRM_VALUE_TASK_PAGE.status]: [StatusName.Open, StatusName.InProgress],
      [CRM_VALUE_TASK_PAGE.scheduledendDateOnClientSide]: `${currentDate.format('DD.MM.YYYY')} - ${upcomingDate.format(
        'DD.MM.YYYY'
      )}`
    },
    filterForServer: {
      [CRM_VALUE_TASK_PAGE.status]: [StatusId.Open, StatusId.InProgress],
      [CRM_VALUE_TASK_PAGE.scheduledendEnd]: upcomingDate.format('YYYY-MM-DD'),
      [CRM_VALUE_TASK_PAGE.scheduledendStart]: currentDate.format('YYYY-MM-DD')
    }
  });

  const firstTaskWidget =
    sortedTasks.length === 0 && overdueSortedTasks.length > 0 ? (
      <TaskWidget openPage={openPage} records={overdueSortedTasks} emptyText='Es gibt keine überfällige Aufgaben' />
    ) : (
      <TaskWidget openPage={openPage} records={sortedTasks} emptyText='Es gibt keine anstehenden Aufgaben' />
    );

  const secondTaskWidget =
    sortedTasks.length === 0 && overdueSortedTasks.length > 0 ? (
      <TaskWidget openPage={openPage} records={sortedTasks} emptyText='Es gibt keine anstehenden Aufgaben' />
    ) : (
      <TaskWidget openPage={openPage} records={overdueSortedTasks} emptyText='Es gibt keine überfällige Aufgaben' />
    );

  return (
    <FleetManagerDashboardContext.Provider value={{ activeCompany, openPage }}>
      <main>
        <Flex margin='0 20px 0' gap='10px'>
          <section className={classes.company_form}>
            <CompanyFilter
              activeCompany={activeCompany}
              setActiveCompany={setActiveCompanyHandler}
              isShowAllCompanyOption
            />
            {isAdmin && (
              <Flex align='center'>
                <Tooltip title='Für alle Firmen aktualisieren'>
                  <Flex
                    onClick={refreshCacheAllCompanies}
                    onMouseEnter={() => setIsRefreshCacheAllCompaniesHovered(true)}
                    onMouseLeave={() => setIsRefreshCacheAllCompaniesHovered(false)}
                  >
                    <RecalculateAllCompaniesIcon
                      fill={isRefreshCacheAllCompaniesHovered ? theme.colors.extraLightBlue : theme.colors.white}
                      className={classes.icon_with_pointer}
                    />
                  </Flex>
                </Tooltip>

                <Distance side='15' />
                <Tooltip title='Dashboard aktualisieren'>
                  <Flex
                    onClick={() => handleRefreshCache(activeCompany.id || user.companyId)}
                    onMouseEnter={() => setIsRefreshCacheCurrentCompanyHovered(true)}
                    onMouseLeave={() => setIsRefreshCacheCurrentCompanyHovered(false)}
                  >
                    <RecalculateCurrentCompanyDataIcon
                      hoverColor={isRereshCacheCurrentCompanyHovered ? theme.colors.extraLightBlue : theme.colors.white}
                      className={classes.icon_with_pointer}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            )}
          </section>
          {isFleetManager && <QuickLinks />}
        </Flex>
        <section id={PrefixForId.TileContainer} className={classes.tile_container}>
          <WidgetTile
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.Driver)}
            className={classes.tile_driver}
            isLoading={driversCheck.isFetching}
          >
            <DriverDiagram driverRecords={drivers.data?.quantityFahrer} circleData={driversCheck.data} />
          </WidgetTile>

          <WidgetTile
            className={classes.tile_car}
            childrenClassName={classes.children}
            isLoading={cars.isFetching}
            id={'car_widget'}
          >
            <VehicleDiagram carsData={multiplyRecords} records={cars.data} />
          </WidgetTile>

          <WidgetTile
            className={classes.tile_improve_data_quality}
            isLoading={dataQuality.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.FatQuality)}
          >
            {dataQuality?.data && <DataQualityChart dataQuality={dataQuality.data} />}
          </WidgetTile>

          <WidgetTile
            title={TileTitle.Damage}
            titleLink={PAGES.DAMAGE}
            titleIcon={<DamagedCarIcon color={theme.colors.blue} />}
            className={classes.tile_damage}
            isLoading={damage.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.Damage)}
          >
            <DamageChart data={damage?.data} />
          </WidgetTile>

          <WidgetTile
            title={tasksTitles}
            onTitleClick={[openTaskPageUpcomingTasks, openTaskPageOverdue]}
            titleIcon={<CalendarWithCheckMarkIcon width='20' height='20' color={theme.colors.blue} />}
            className={classes.tile_upcoming_tasks}
            isLoading={tasks.isFetching}
            id={'upcoming_tasks'}
          >
            {firstTaskWidget}
            {secondTaskWidget}
          </WidgetTile>

          <WidgetTile
            className={classes.tile_vehicle_costs}
            isLoading={vehicleCosts.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.VehicleCosts)}
          >
            <VehicleCostsCircleChart vehicleCostsData={vehicleCosts.data} />
            <VehicleCostsChart vehicleCostsData={vehicleCosts.data} />
          </WidgetTile>

          <WidgetTile
            className={classes.tile_driver_license}
            isLoading={mileage.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.DriverLicense)}
          >
            <MileageChart data={mileage.data} />
          </WidgetTile>

          <WidgetTile
            className={classes.tile_emissions}
            isLoading={consumption.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.CO2Emission)}
          >
            <CarbonDioxideEmission dataCO2={consumption?.data?.verbrauch} />
          </WidgetTile>

          <WidgetTile
            className={classes.tile_consumtion}
            isLoading={consumption.isFetching}
            id={PrefixForId.TileContainer + removeSpaces(TileTitle.Consumption)}
          >
            <ConsumptionHalfCircle consumptionData={consumption?.data} />
            <ConsumptionTable consumptionData={consumption?.data} />
          </WidgetTile>
        </section>
      </main>
    </FleetManagerDashboardContext.Provider>
  );
};

const getDefaultDashboardFilterState = (companyId: string, companyName: string) => {
  const dataForServer = { activeCompany: companyId };
  const dataForClient = { activeCompany: companyName };

  return { dataForServer, dataForClient };
};

export const diagramSetup = {
  strokeWidth: 3,
  trackStrokeWidth: 2,
  trackStrokeColor: '#f5f5f5',
  transition: '0.3s ease',
  initialAnimation: true,
  radius: 25
};

export const dataQualityDiagramSetup = {
  strokeWidth: 6,
  trackStrokeWidth: 6,
  trackStrokeColor: '#fff',
  transition: '0.3s ease',
  initialAnimation: true,
  radius: 35,
  strokeLinecap: 'butt' as const
};

const REFRESH_CACHE_MODAL_TEXT =
  // eslint-disable-next-line max-len
  'Da die Aktualisierung des Dashboards für alle Unternehmen einige Zeit in Anspruch nehmen wird, sind Sie sicher, dass Sie das Dashboard aktualisieren wollen?';

const SNACKBAR_SUCCESS_MESSAGE = 'Dashboard wurde erfolgreich aktualisiert ';
const SNACKBAR_ERROR_MESSAGE = 'Etwas ist fehlgeschlagen. Dashboard kann nicht aktualisiert werden.';
