import React, { ComponentProps, useContext } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { PencilIcon } from 'components/icons-new/pencil';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import useWindowSize from 'hooks/use-window-size';

export const CurrentKilometerReadingHeader = ({ onClick, ...restProps }: Omit<ComponentProps<'div'>, 'ref'>) => {
  const { vehicle } = useContext(VehicleDetailContext);

  const { windowWidth } = useWindowSize(500);

  return (
    <Flex align='center' {...restProps}>
      <Text id='actualMileageValue' color='darkBlue' size={windowWidth <= 550 ? '24' : '22'} bold right='10'>
        {`${(vehicle?.fuhrpark?.uds_actualmileage?.attributeValue || 0).toLocaleString('de-DE')} km`}
      </Text>
      <Flex id='openKilometerForm' pointer onClick={onClick}>
        <PencilIcon width='20' height='20' />
      </Flex>
    </Flex>
  );
};
