import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { GetAvailableCarsData } from 'request-config/pool-calendar/pool-calendar.types';
import { RequestStatuses } from '../../../../../constants';
import { AvailablePoolCar, AvailablePoolCarsResponse } from './use-available-pool-cars.types';
import { transformCrmResponse } from 'utils/transform-crm-response';

const modifyResponse = ({ data }: ApiResponse<AvailablePoolCarsResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.fuhrparkList.map(item => transformCrmResponse({ ...item.fuhrpark, ...item.kmstand }));
  }
  return [];
};

const useAvailablePoolCars = (requestData: GetAvailableCarsData | null, enabled = true) => {
  const { fetch } = useFetch<ResponseModel<AvailablePoolCarsResponse>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...poolCalendarRequestConfig.getAvailableCars(requestData as GetAvailableCarsData)
  });

  const { data: availablePoolCars, isLoading: isAvailablePoolCarsLoading } = useQuery<
    ApiResponse<AvailablePoolCarsResponse>,
    unknown,
    AvailablePoolCar[]
  >([ReactQueryKeys.GetAvailablePoolCars, requestData], () => fetch(), {
    enabled: !!requestData && enabled,
    select: modifyResponse
  });

  return { availablePoolCars, isAvailablePoolCarsLoading };
};

export default useAvailablePoolCars;
