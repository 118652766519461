import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../spinner';
import { getUser, getUserBasket } from '../../services/user-service';
import ConfiguratorIframe from '../configurator-iframe';
import { getCarPolicy } from '../../services/car-policy-service';
import { getFahrerLevel } from '../../services/driver-level-service';
import { BASKET_TYPES, defaultGuid, TAXES_COEFFICIENT, VEHICLE_OFFER_ASSIGNMENT } from '../../constants';
import { getResponseFormData } from '../../utils/get-response-data';
import { Divider, Flex, Text } from '../../common/common-components.styled';
import {
  StyledCardsContainer,
  StyledCarPolicyItem,
  StyledConfiguratorPanel,
  StyledInfoCardsContainer,
  StyledInfoContainer,
  StyledTitle
} from './configurator-modal.styled';
import { CartIcon } from '../icons/offer-statuses/cart-icon';
import { withTheme } from 'styled-components';
import InfoCard from './info-card/info-card';
import { CarWithEuroIcon } from '../icons/offer-statuses/car-with-euro-icon';
import { getLeasingangebotes, getLeasingangebotesForGRV } from '../barpreisanfragen-component/utils';
import { UserWithStarIcon } from '../icons/user-with-star-icon';
import { CloseIcon } from '../icons/close-icon';
import { getBudget } from '../../utils/get-budget';
import { CircleCheckMark } from '../icons/circle-check-mark';
import { CircleErrorMark } from '../icons/circle_error_mark';
import { decodeBase64 } from '../../utils/decode-encode-base64';
import { getConfiguratorData } from './get-configurator-data';
import { ConfiguratorPriceTypes } from '../../common/enums';
import Switch from 'react-switch';
import { getVehicleOfferDetailsForConfigurator } from '../../services/vehicle-offer-service';

export const BUDGET_STATUSES_COLORS = {
  success: {
    backgroundColor: 'lightBlue',
    priceColor: 'white',
    color: 'blue',
    iconColor: 'midBlue'
  },
  warning: {
    backgroundColor: 'extraLightGold',
    priceColor: 'darkGold',
    color: 'gold',
    iconColor: 'gold'
  },
  error: {
    backgroundColor: 'extraLightPink',
    priceColor: 'darkPink',
    color: 'pink',
    iconColor: 'lightPink'
  }
};

const getCarModel = (carBrand, carModel) => {
  switch (carBrand) {
    case 'Audi':
      return carModel.replace(' Avant', '');
    case 'BMW':
      return carModel.replace(' Touring', '');
    case 'Ford':
      return carModel.replace(' Turnier', '');
    case 'Mercedes-Benz':
      return carModel.replace(' T-Modell', '');
    case 'Opel':
      return carModel.replace(' Sports Tourer', '');
    case 'Seat':
      return carModel.replace(' Sports Tourer', '');
    case 'ŠKODA':
      return carModel.replace(' Combi', '');
    case 'Volkswagen':
      return carModel.replace(' Variant', '');

    default:
      return carModel;
  }
};

const getConfiguratorLink = vehicleOfferResponse => {
  if (vehicleOfferResponse.uds_car_configurator_url) {
    return vehicleOfferResponse.uds_car_configurator_url.split('/hersteller/')[1];
  } else {
    const carBrand = vehicleOfferResponse.uds_carbrandid || '';
    const carModel =
      vehicleOfferResponse.uds_model_tech_name || vehicleOfferResponse.uds_carmodelid
        ? getCarModel(carBrand, vehicleOfferResponse.uds_model_tech_name || vehicleOfferResponse.uds_carmodelid)
        : '';
    const bodyTypeDescription = vehicleOfferResponse.uds_body_type_description || '';
    const trimLevel = vehicleOfferResponse.uds_trim_level || '';
    const modelSpecification = vehicleOfferResponse.uds_modellspezifikation || '';

    return `${carBrand}/modelle/${carModel}/karosserieformen/${bodyTypeDescription}/ausstattungslinien/${trimLevel}/motoren/${modelSpecification}`;
  }
};

class ConfiguratorModal extends Component {
  constructor(props) {
    super(props);
    this.header = createRef();
    this.state = {
      showSpinner: true,
      iFrameSrc: null,
      headerHeight: 0,
      carPolicyItems: [],
      budget: 0,
      fuelCost: 0,
      surcharge: 0,
      isDriverSupplementMandatory: false,
      maxNettolistenpreis: 0,
      listenpreisNetto: 0,
      estimatedListenpreisBrutto: 0,
      estimatedListenpreisNetto: 0,
      estimatedBarpreis: 0,
      estimatedPrice: 0,
      lowestLeasingFactorAndPriceOffer: [],
      priceType: ConfiguratorPriceTypes.Netto
    };

    const queryData = new URLSearchParams(window.location.search).get('data');
    const decodedQueryData = queryData ? JSON.parse(decodeBase64(queryData)) : null;
    this.driverId = decodedQueryData?.driverId || null;
    this.driverLevel = decodedQueryData?.uds_carpolicynutzer || null;
    this.carPolicy = decodedQueryData?.uds_carpolicy || null;
    this.responsiblePerson = decodedQueryData?.uds_kstverantw || null;
    this.oldCar = decodedQueryData?.uds_altes_fahrzeugid || null;
    this.vehicleOfferAssignment =
      decodedQueryData && !this.driverId
        ? VEHICLE_OFFER_ASSIGNMENT.poolCar.value
        : VEHICLE_OFFER_ASSIGNMENT.employeeAssigned.value;
    this.basketTypeCode = decodedQueryData && !this.driverId ? BASKET_TYPES.poolcar.value : BASKET_TYPES.personal.value;
  }

  spinnerStyle = {
    width: '50px',
    height: '50px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
  };

  BUDGET_STATUS_ICONS = {
    success: <CircleCheckMark width={22} height={22} color={this.props.theme.colors.blue} />,
    warning: <CircleCheckMark width={22} height={22} color={this.props.theme.colors.gold} />,
    error: <CircleErrorMark width={22} height={22} color={this.props.theme.colors.pink} />
  };

  iframeLoaded = () => {
    this.setState({ showSpinner: false });

    let iterations = 0;
    const interval = setInterval(() => {
      document.querySelector('#mobexo-modal-overlay').scrollTo({ top: 0 });
      iterations++;
      if (iterations > 1) {
        clearInterval(interval);
      }
    }, 1000);
  };

  resizeHandler = () => {
    const height = this.header.current?.scrollHeight;
    this.setState({ headerHeight: height });
  };

  onPriceChange = (oldPrice, newPrice, priceType) => {
    const wholesaleDiscount =
      this.state.lowestLeasingFactorAndPriceOffer?.uds_grosskundennachlass_percent_float?.attributeValue || 0;
    const specialDiscount =
      this.state.lowestLeasingFactorAndPriceOffer?.uds_special_discount?.attributeValue?.value || 0;

    const estimatedListenpreisNetto =
      priceType === ConfiguratorPriceTypes.Netto ? newPrice : newPrice / TAXES_COEFFICIENT;
    const estimatedListenpreisBrutto =
      priceType === ConfiguratorPriceTypes.Netto ? newPrice * TAXES_COEFFICIENT : newPrice;
    const estimatedPrice = getEstimatedPrice(
      this.props.parameters.isGlobalReferenceVehicle,
      this.state.lowestLeasingFactorAndPriceOffer,
      estimatedListenpreisNetto,
      this.state.listenpreisNetto,
      specialDiscount
    );
    const estimatedBarpreis = ((newPrice - specialDiscount) / 100) * (100 - wholesaleDiscount);

    this.setState({
      estimatedPrice,
      estimatedBarpreis,
      estimatedListenpreisNetto,
      estimatedListenpreisBrutto,
      priceType
    });
  };

  getBudgetStatus = (budget, surcharge, price, isDriverSupplementMandatory) => {
    if (!budget || !price) {
      return 'success';
    }
    if (
      isDriverSupplementMandatory && surcharge
        ? price <= budget + surcharge
        : price > budget && price <= budget + surcharge
    ) {
      return 'warning';
    }
    if (price <= budget) {
      return 'success';
    }
    return 'error';
  };

  getEstimatedPriceText = (budgetStatus, price, budget, isDriverSupplementMandatory, surcharge) => {
    const supplement = isDriverSupplementMandatory ? (price >= surcharge ? surcharge : price) : price - budget;

    switch (budgetStatus) {
      case 'success':
        return 'VORAUSSICHTLICHE MOBILITÄTSKOSTEN';
      case 'warning':
        return `VORAUSSICHTLICHE MOBILITÄTSKOSTEN INKL. ${supplement.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR'
        })} EIGENZUZAHLUNG`;
      case 'error':
        return 'ZULÄSSIGE MOBILITÄTSKOSTEN ÜBERSCHRITTEN. BITTE KONFIGURATION ABÄNDERN.';
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  togglePriceType = () => {
    this.setState(prevState => ({
      priceType:
        prevState.priceType === ConfiguratorPriceTypes.Brutto
          ? ConfiguratorPriceTypes.Netto
          : ConfiguratorPriceTypes.Brutto
    }));
  };

  async componentDidMount() {
    const takeReferenceLeasingOffers = leasingOffer => {
      if (!this.props.parameters.isGlobalReferenceVehicle) {
        return true;
      }

      return (
        leasingOffer.uds_laufleistungpa.attributeValue === this.props.parameters.referenceDistance &&
        leasingOffer.uds_laufzeit.attributeValue === this.props.parameters.referenceDuration
      );
    };

    window.addEventListener('resize', this.resizeHandler);

    this.props.hideOverlay();
    let carPolicyId = this.carPolicy;
    let driverLevelId = this.driverLevel;
    if (!carPolicyId || !driverLevelId) {
      const { data } = await getUser(this.driverId || this.props.user.id);
      if (data.data) {
        carPolicyId = data.data[0].uds_carpolicyid.attributeValue?.id || null;
        driverLevelId = data.data[0].uds_carpolicynutzerid.attributeValue?.id || null;
      }
    }

    let leasingOffersResponse;
    try {
      leasingOffersResponse = await (this.props.parameters.isGlobalReferenceVehicle
        ? getLeasingangebotesForGRV(this.props.parameters.vehicleOfferId)
        : getLeasingangebotes(this.props.parameters.vehicleOfferId));
    } catch (e) {
      console.log(e);
      leasingOffersResponse = {};
    }

    const leasingOffers = leasingOffersResponse.data?.data || [];
    const lowestLeasingFactorOffer = leasingOffers
      .filter(takeReferenceLeasingOffers)
      .sort((a, b) => a.uds_leasingfaktor.attributeValue - b.uds_leasingfaktor.attributeValue)[0];

    const lowestLeasingFactorAndPriceOffer = leasingOffers
      .filter(takeReferenceLeasingOffers)
      .filter(
        leasingOffer =>
          leasingOffer.uds_leasingfaktor.attributeValue === lowestLeasingFactorOffer?.uds_leasingfaktor.attributeValue
      )
      .sort(
        (a, b) =>
          a.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value -
          b.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value
      )[0];

    if (!carPolicyId) {
      this.props.setSnackbar({
        isOpen: true,
        type: 'warning',
        time: 6000,
        message:
          'Du hast keine Car-Policy und kannst keine neue Konfiguration erstellen. Bitte wende dich an Deinen Fuhrparkleiter.'
      });
      // this.props.hideOverlay();
      return;
    }

    const requests = [
      getVehicleOfferDetailsForConfigurator(this.props.parameters.vehicleOfferId),
      getCarPolicy(carPolicyId),
      getUserBasket(this.driverId || this.props.user.id)
    ];
    if (driverLevelId) {
      requests.push(getFahrerLevel(driverLevelId));
    }

    let responseData;
    try {
      responseData = await Promise.allSettled(requests);
    } catch (e) {
      console.log(e);
    } finally {
      // this.props.hideOverlay();
    }

    const vehicleOfferResponse = responseData[0].value.data
      ? getResponseFormData(responseData[0].value.data, [{ name: null, type: 'array' }], true)[0]
      : {};

    const carPolicyResponse = responseData[1].value?.data?.data || {};
    const latestBasketResponse = responseData[2].value?.data || {};
    const driverLevelResponse = responseData[3]?.value.data || {};

    const carPolicyItems = carPolicyResponse.items || [];
    const budget = getBudget(
      vehicleOfferResponse.uds_enginetypecode_value,
      driverLevelResponse,
      this.props.user.companyId
    );
    const surcharge = driverLevelResponse.uds_zuzahlung
      ? driverLevelResponse.uds_zuzahlungtyp === 100000000
        ? budget * (driverLevelResponse.uds_zuzahlung / 100)
        : driverLevelResponse.uds_zuzahlung
      : 0;
    const isDriverSupplementMandatory = driverLevelResponse.uds_isdriver_supplement_mandatory || false;

    const listenpreisNetto = vehicleOfferResponse.uds_listenpreis_netto_value || 0;
    const fuelCost = getConfiguratorModalFuelCost(
      this.props.parameters.isGlobalReferenceVehicle,
      driverLevelResponse.uds_inklusivekraftstoffkosten,
      vehicleOfferResponse,
      lowestLeasingFactorAndPriceOffer
    );

    const configuratorLink = getConfiguratorLink(vehicleOfferResponse);

    const budgetWithSurcharge = budget + surcharge;

    const maxNettolistenpreis = getMaxNettoCost(
      this.props.parameters.isGlobalReferenceVehicle,
      budgetWithSurcharge,
      vehicleOfferResponse,
      lowestLeasingFactorAndPriceOffer
    );

    const latestBasket = latestBasketResponse.data?.opportunityid || defaultGuid;
    // if (!latestBasketResponse.opportunityid) {
    //   this.props.setSnackbar('Sie haben keinen persönlichen Warenkorb, wenden Sie sich bitte an Mobexo.');
    // }
    const configuratorId = vehicleOfferResponse.uds_internalid;
    const configuratorEntity = process.env.REACT_APP_CURRENT_ENV === 'prod' ? 'configurator' : 'configurator_dev';
    const configuratorData = getConfiguratorData(
      this.props.user.id,
      latestBasket,
      [carPolicyId],
      this.basketTypeCode,
      true,
      this.driverId || this.props.user.id,
      this.responsiblePerson,
      this.oldCar,
      this.vehicleOfferAssignment,
      this.props.parameters.distance,
      this.props.parameters.deliveryDate,
      this.props.parameters.isGlobalReferenceVehicle ? this.props.parameters.vehicleOfferId : null
    );

    this.setState({
      maxNettolistenpreis,
      listenpreisNetto,
      budget,
      fuelCost,
      surcharge,
      isDriverSupplementMandatory,
      carPolicyItems,
      lowestLeasingFactorAndPriceOffer,
      iFrameSrc: this.props.parameters.isGlobalReferenceVehicle
        ? `/assets/${configuratorEntity}/#l!/startext/user/${configuratorData}/hersteller/${configuratorLink}`
        : `/assets/${configuratorEntity}/#l!/${configuratorId}/user/${configuratorData}/sonderausstattungen`
    });

    this.resizeHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    const {
      carPolicyItems,
      budget,
      estimatedPrice,
      maxNettolistenpreis,
      fuelCost,
      surcharge,
      isDriverSupplementMandatory,
      priceType,
      estimatedListenpreisNetto,
      estimatedListenpreisBrutto,
      estimatedBarpreis
    } = this.state;
    const {
      parameters: { isGlobalReferenceVehicle }
    } = this.props;
    const budgetStatus = this.getBudgetStatus(
      budget,
      surcharge,
      estimatedPrice + fuelCost,
      isDriverSupplementMandatory
    );
    const estimatedPriceText = this.getEstimatedPriceText(
      budgetStatus,
      estimatedPrice + fuelCost,
      budget,
      isDriverSupplementMandatory,
      surcharge
    );

    return (
      <>
        <StyledConfiguratorPanel>
          <Flex bottom='10' align='flex-start' justify='space-between'>
            <StyledTitle color='white'>Mindestanforderungen</StyledTitle>
            <CloseIcon onClick={this.closeModal} style={{ cursor: 'pointer' }} width={35} height={35} strokeWidth={1} />
          </Flex>
          {this.state.iFrameSrc && (
            <div>
              <StyledCardsContainer>
                <StyledInfoCardsContainer width='70%'>
                  <StyledInfoContainer wrap='wrap' width='80%' align='flex-start' status='success'>
                    {carPolicyItems.map(item => (
                      <StyledCarPolicyItem color='blue' key={item.uds_carpolicyitemid.attributeValue}>
                        {item.uds_name.attributeValue}
                      </StyledCarPolicyItem>
                    ))}
                  </StyledInfoContainer>
                  <StyledInfoCardsContainer leftSpace width='20%'>
                    {isGlobalReferenceVehicle ? (
                      <InfoCard
                        price={estimatedBarpreis}
                        leftSpace
                        icon={<CartIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                        status='success'
                        text='VORAUSSICHTLICHER BARPREIS'
                      />
                    ) : (
                      <InfoCard
                        price={estimatedListenpreisNetto}
                        leftSpace
                        icon={<CartIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                        status='success'
                        text='VORAUSSICHTLICHER NETTO-LISTENPREIS'
                      />
                    )}
                  </StyledInfoCardsContainer>
                </StyledInfoCardsContainer>

                <StyledInfoCardsContainer width='30%'>
                  <StyledInfoCardsContainer leftSpace width='100%'>
                    {isGlobalReferenceVehicle ? (
                      <InfoCard
                        price={
                          priceType === ConfiguratorPriceTypes.Netto
                            ? estimatedListenpreisNetto
                            : estimatedListenpreisBrutto
                        }
                        leftSpace
                        icon={<CartIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                        status='success'
                        text='VORAUSSICHTLICHER LISTENPREIS'
                      />
                    ) : (
                      <InfoCard
                        price={estimatedListenpreisBrutto}
                        leftSpace
                        icon={<CartIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                        status='success'
                        text='VORAUSSICHTLICHER BRUTTO-LISTENPREIS'
                      />
                    )}
                  </StyledInfoCardsContainer>
                  <StyledInfoCardsContainer leftSpace width='100%'>
                    <InfoCard
                      price={
                        isGlobalReferenceVehicle && priceType === ConfiguratorPriceTypes.Brutto
                          ? maxNettolistenpreis * TAXES_COEFFICIENT
                          : maxNettolistenpreis
                      }
                      leftSpace
                      icon={<CartIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                      status='success'
                      text='MAXIMALER LISTENPREIS'
                    />
                  </StyledInfoCardsContainer>
                </StyledInfoCardsContainer>
              </StyledCardsContainer>

              <StyledCardsContainer>
                <StyledInfoCardsContainer width='70%'>
                  <StyledInfoCardsContainer width='80%'>
                    <InfoCard
                      price={
                        isGlobalReferenceVehicle && priceType === ConfiguratorPriceTypes.Brutto
                          ? budget * TAXES_COEFFICIENT
                          : budget
                      }
                      icon={<UserWithStarIcon />}
                      status='success'
                      text='MOBILITÄTS-BUDGET'
                    />
                  </StyledInfoCardsContainer>
                  <StyledInfoCardsContainer leftSpace width='20%'>
                    <InfoCard
                      price={
                        isGlobalReferenceVehicle && priceType === ConfiguratorPriceTypes.Brutto
                          ? estimatedPrice * TAXES_COEFFICIENT
                          : estimatedPrice
                      }
                      icon={<CarWithEuroIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                      status='success'
                      text={'VORAUSSICHTLICHE LEASINGRATE'}
                    />
                  </StyledInfoCardsContainer>
                </StyledInfoCardsContainer>

                <StyledInfoCardsContainer width='30%'>
                  <StyledInfoCardsContainer leftSpace width='100%'>
                    <InfoCard
                      price={
                        isGlobalReferenceVehicle && priceType === ConfiguratorPriceTypes.Brutto
                          ? fuelCost * TAXES_COEFFICIENT
                          : fuelCost
                      }
                      icon={<CarWithEuroIcon width={28} height={28} color={this.props.theme.colors.midBlue} />}
                      status='success'
                      text={'VORAUSSICHTLICHE KRAFTSTOFFKOSTEN'}
                    />
                  </StyledInfoCardsContainer>
                  <StyledInfoCardsContainer leftSpace width='100%'>
                    <InfoCard
                      price={
                        isGlobalReferenceVehicle && priceType === ConfiguratorPriceTypes.Brutto
                          ? (estimatedPrice + fuelCost) * TAXES_COEFFICIENT
                          : estimatedPrice + fuelCost
                      }
                      icon={
                        <CarWithEuroIcon
                          width={28}
                          height={28}
                          color={this.props.theme.colors[BUDGET_STATUSES_COLORS[budgetStatus].iconColor]}
                        />
                      }
                      statusIcon={this.BUDGET_STATUS_ICONS[budgetStatus]}
                      status={budgetStatus}
                      text={estimatedPriceText}
                    />
                  </StyledInfoCardsContainer>
                </StyledInfoCardsContainer>
              </StyledCardsContainer>

              {isGlobalReferenceVehicle && (
                <Flex top='10' align='center' justify='flex-end'>
                  <Switch
                    offColor={'#bfbfbf'}
                    onColor={'#335566'}
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.togglePriceType}
                    checked={this.state.priceType === ConfiguratorPriceTypes.Brutto}
                  />
                  <Text left='10' color='black' pointer onClick={this.togglePriceType}>
                    Alle Preise inkl. aktuell gültiger MwSt.
                  </Text>
                </Flex>
              )}
            </div>
          )}

          <Divider top='30' />

          {this.state.iFrameSrc ? (
            <ConfiguratorIframe
              style={{ position: 'static', height: 'calc(100vh - 350px)' }}
              iframeLoaded={this.iframeLoaded}
              closeModal={this.props.closeModal}
              openAlert={this.props.openAlert}
              closeAlert={this.props.closeAlert}
              setSnackbar={this.props.setSnackbar}
              iFrameSrc={this.state.iFrameSrc}
              driverId={this.driverId}
              outOfBudget={budget + surcharge < estimatedPrice + fuelCost}
              onPriceChange={this.onPriceChange}
              userId={this.props.user.id}
              priceType={this.state.priceType}
            />
          ) : (
            <Flex style={{ position: 'relative', height: '200px' }}>
              {this.state.showSpinner && <Spinner style={this.spinnerStyle} />}
            </Flex>
          )}
        </StyledConfiguratorPanel>
      </>
    );
  }
}

ConfiguratorModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    roles: PropTypes.array
  }).isRequired,
  theme: PropTypes.object.isRequired
};

const getConfiguratorModalFuelCost = (
  isGlobalReferenceVehicle,
  includeFuelCost,
  vehicleOfferData,
  leasingOfferData
) => {
  if (isGlobalReferenceVehicle) {
    return includeFuelCost && leasingOfferData.uds_calc_monthly_costs_incl_fuel?.attributeValue?.value
      ? leasingOfferData.uds_calc_monthly_costs_incl_fuel.attributeValue.value -
          (leasingOfferData.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value || 0)
      : 0;
  }

  return includeFuelCost && vehicleOfferData.uds_laufleistungkm
    ? (vehicleOfferData.uds_laufleistungkm / 12 / 100) *
        (vehicleOfferData.uds_kalkulatorischekraftstoffkosten_value || 0) // fuel cost per month
    : 0;
};

const getMaxNettoCost = (isGlobalReferenceVehicle, budgetWithSurcharge, vehicleOfferData, leasingOfferData) => {
  const leasingDuration = isGlobalReferenceVehicle
    ? leasingOfferData?.uds_laufzeit?.attributeValue || 0
    : vehicleOfferData.uds_wunschlaufzeitcode_value || 0;
  const specialPayment = isGlobalReferenceVehicle
    ? leasingOfferData?.uds_anzahlung?.attributeValue || 0
    : vehicleOfferData.uds_einmaligekosten_anzahlung_value || 0;
  const discountPercent = isGlobalReferenceVehicle
    ? leasingOfferData?.uds_grosskundennachlass_percent_float?.attributeValue || 0
    : vehicleOfferData.uds_grosskundennachlass_percent_float || 0;

  return (
    budgetWithSurcharge * leasingDuration +
    specialPayment +
    (budgetWithSurcharge * leasingDuration - specialPayment) +
    ((budgetWithSurcharge * leasingDuration +
      specialPayment +
      (budgetWithSurcharge * leasingDuration - specialPayment)) *
      discountPercent) /
      (100 - discountPercent)
  );
};

const getEstimatedPrice = (
  isGlobalReferenceVehicle,
  leasingOfferData,
  estimatedListenpreisNetto,
  listenpreisNetto,
  specialDiscount
) => {
  const lowestLeasingfactorOfferPrice =
    leasingOfferData.uds_gesamtkosten_monatlich_vertragsabschluss.attributeValue?.value || 0;
  const leasingFactor = leasingOfferData.uds_leasingfaktor.attributeValue || 0;

  return isGlobalReferenceVehicle
    ? ((estimatedListenpreisNetto - listenpreisNetto) * leasingFactor) / 100 + lowestLeasingfactorOfferPrice
    : (leasingFactor / 100) * estimatedListenpreisNetto;
};

export default withTheme(ConfiguratorModal);
