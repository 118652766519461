import { useState } from 'react';
import { GetCarInspectionDateResponse } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';
import { useMyVehicleInspectionDateQuery } from 'pages/existing-vehicle-page/hooks/query/use-my-vehicle-inspection-date.query';

export const useInspectionDateExpand = () => {
  const { carInspectionDateQuery, isFetching } = useMyVehicleInspectionDateQuery();

  const [isExpanded, setIsExpanded] = useState(false);

  const MAX_DISPLAY_ITEMS = 5;

  const getData = (dataKey: keyof Omit<GetCarInspectionDateResponse, 'pruftermineHistories'>) => {
    return isExpanded ? carInspectionDateQuery[dataKey] : carInspectionDateQuery[dataKey].slice(0, MAX_DISPLAY_ITEMS);
  };

  const getIsShowExpandButton = () => {
    if (isFetching) {
      return false;
    }

    return (
      carInspectionDateQuery.allPruftermines.length > MAX_DISPLAY_ITEMS ||
      carInspectionDateQuery.assignedPruftermines.length > MAX_DISPLAY_ITEMS
    );
  };

  return { isExpanded, setIsExpanded, getData, getIsShowExpandButton, isFetching };
};
