import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const PlumpRightArrowIcon = ({
  width = 24,
  height = 24,
  color: colorProp,
  fill: fillProps,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProps || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M3.84814 8.53846L12.8481 8.53846L12.8481 5.07693L21.8481 12L12.8481 18.9231L12.8481 15.4615L3.84814 15.4615L3.84814 8.53846Z'
          fill={fill}
        />
      )}
      <path
        d='M12.8481 15.4615L12.8481 18.9231L21.8481 12L12.8481 5.07691L12.8481 8.53845L3.84814 8.53845L3.84814 15.4615L10.8177 15.4615'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
