import styled from 'styled-components';
import { TextField } from '@mui/material';

export const StyledErrorMessageDate = styled.div`
  padding-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.pink};
`;

export const StyledTextField = styled(TextField)`
  width: 0;
  & .MuiInputAdornment-root {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  & .MuiInputBase-input {
    height: 0;
  }
`;
