import React from 'react';
import { FormData, VehicleStatusChangeModalContentProps } from './modal-content.props';
import { Flex, Text } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { getFormFields } from './use-modal-content.fields';
import Button from 'components/button';
import { ModalContentSubmit } from './modal-content.submit';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../../constants';
import { ButtonType } from 'components/button/button.props';

export const VehicleStatusChangeModalContent = ({
  vehicleId,
  presetFormData
}: VehicleStatusChangeModalContentProps) => {
  const reactHookFormData = useForm<FormData>();

  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  return (
    <div>
      <Text color='grey900'>
        Bitte gib das Erstzulassungsdatum an oder prüfe dieses noch einmal vor der finalen Inbetriebnahme.
      </Text>
      <Text color='grey900' top='20' bottom='20'>
        Allzeit gute Fahrt.
      </Text>

      <FormHook reactHookFormData={reactHookFormData} formFields={getFormFields(presetFormData)} />

      <Flex justify='flex-end'>
        <Button type={ButtonType.Secondary} onClick={closeAlert}>
          Abbrechen
        </Button>

        <ModalContentSubmit vehicleId={vehicleId} reactHookFormData={reactHookFormData} />
      </Flex>
    </div>
  );
};
