import styled from 'styled-components';

export const StyledVehicleOffersListContainer = styled.div`
  padding: 30px 0;
  height: auto;
  min-height: calc(100% - 36px);
`;

export const StyledConfigurationsWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 400px);
`;
