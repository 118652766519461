import React, { useState } from 'react';
import { SelectDriverComponentProps } from './select-driver-component.types';
import { Button, Flex, Text } from 'common/common-components.styled';
import ThreeStateCheckbox from 'components/three-state-checkbox';
import FormHook from 'components/form-hook/form-hook';
import { useForm } from 'react-hook-form';
import useDriverFields from '../hooks/use-driver-fields';

const SelectDriverComponent = ({ closeDialog, setSelectDriverData }: SelectDriverComponentProps) => {
  const [isPoolCar, setIsPoolCar] = useState(false);

  const { driverFields } = useDriverFields();
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { isValid }
  } = reactHookFormData;

  const toggleCheckbox = () => {
    setIsPoolCar(prevState => !prevState);
  };

  const submit = () => {
    handleSubmit(data => {
      setSelectDriverData({ driverId: data.driver || null, isPoolCar });
      closeDialog();
    })();
  };

  return (
    <div>
      <Text bottom='20' color='grey900'>
        Für welchen Fahrer möchtest Du eine Konfiguration erstellen?
      </Text>
      <Text bottom='20' color='grey900'>
        Wähle einen Fahrer aus:
      </Text>

      <Flex align='center' bottom='20'>
        <ThreeStateCheckbox indeterminate={false} checked={isPoolCar} onChange={toggleCheckbox} />
        <Text pointer left='5' color='grey900' onClick={toggleCheckbox}>
          Ich konfiguriere ein Pool-Car
        </Text>
      </Flex>

      {!isPoolCar && <FormHook reactHookFormData={reactHookFormData} formFields={driverFields} />}

      <Flex justify='flex-end' top='20'>
        <Button right='10' onClick={closeDialog} secondary>
          Abbrechen
        </Button>
        <Button disabled={!isValid} onClick={submit}>
          Fortfahren
        </Button>
      </Flex>
    </div>
  );
};

export default SelectDriverComponent;
