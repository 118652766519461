import { connect } from 'react-redux';
import { get } from 'lodash';

import Modal from './modal-view';

const mapStateToProps = (state, ownProps) => ({
  type: get(state, ['app', 'modals', ownProps.name, 'type'], null),
  parameters: get(state, ['app','modals',ownProps.name,'params'],'')
});

export default connect(mapStateToProps, null)(Modal);
