import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './service-card.module.scss';
import { getEntityByFieldQuery, getEntityById } from '../../services/crm-entity-service';
import { crmDataTypes, defaultGuid } from '../../constants';
import { getResponseFormData } from '../../utils/get-response-data';
import { ConditionOperator } from '../../common/enums';

const getServiceCardsRequestData = car => ({
  entityName: 'uds_servicekarte',
  columns: [
    'uds_winterrader',
    'uds_versicherung',
    'uds_wartung_verschleiss',
    'uds_reifenersatz',
    'createdon',
    'uds_rechnungsemfanger_name'
  ],
  conditions: [
    {
      attributeTypeCode: crmDataTypes.Lookup,
      attributeName: 'uds_fuhrparkid',
      conditionOperator: ConditionOperator.Equal,
      value: [
        {
          id: car.new_fuhrparkid,
          logicalName: '',
          name: ''
        }
      ]
    },
    {
      attributeTypeCode: 11,
      attributeName: 'statecode',
      conditionOperator: ConditionOperator.Equal,
      value: [{ value: '0' }]
    }
  ]
});

const ServiceCard = ({ car }) => {
  const [serviceCard, setServiceCard] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await Promise.allSettled([
        getEntityByFieldQuery(getServiceCardsRequestData(car)),
        getEntityById({
          entityId: car._new_leasinggeber_value || defaultGuid,
          entityName: 'account',
          columns: ['uds_servicename']
        })
      ]);

      const serviceCardsResponse = response[0].value;
      const lessorResponse = response[1].value;

      if (serviceCardsResponse) {
        const serviceCards = getResponseFormData(serviceCardsResponse.data, [{ name: 'entityes', type: 'array' }]);
        let lastServiceCard = serviceCards.sort((a, b) => new Date(b.createdon) - new Date(a.createdon))[0] || null;

        if (lastServiceCard && lessorResponse) {
          const lessorData = getResponseFormData(lessorResponse.data, [{ name: 'attributes', type: 'object' }]);
          lastServiceCard = {
            ...lastServiceCard,
            ...lessorData
          };
        }
        setServiceCard(lastServiceCard);
      }
    })();
  }, [car]);

  const fields = useMemo(() => {
    if (!serviceCard) {
      return [];
    }
    return [
      {
        title: 'Kennzeichen',
        value: car.new_name || '',
        className: classes.title
      },
      {
        title: 'Marke',
        value: car.new_hersteller || '',
        className: classes.text
      },
      {
        title: 'Modell',
        value: car.new_model || '',
        className: classes.text,
        marginBottom: '15px'
      },
      {
        title: 'Im Servicefall',
        value: '',
        className: classes.subtitle,
        marginBottom: '5px'
      },
      {
        title: 'Wartung / Verschleiß',
        value: serviceCard.uds_wartung_verschleiss || '',
        className: classes.text
      },
      {
        title: 'HU / UVV',
        value: serviceCard.uds_versicherung || '',
        className: classes.text
      },
      {
        title: 'Rechnungsempfänger',
        value: serviceCard.uds_servicename || '',
        className: classes.subtitle,
        marginBottom: '10px'
      },
      {
        title: 'Räder / Reifen',
        value: serviceCard.uds_winterrader || '',
        className: classes.text
      },
      {
        title: 'Rechnungsempfänger',
        value: serviceCard.uds_rechnungsemfanger_name || '',
        className: classes.subtitle,
        marginBottom: '10px'
      },
      {
        title: 'Unfall / Panne / Glas',
        value: serviceCard.uds_reifenersatz || '',
        className: classes.text
      }
    ];
  }, [serviceCard]);

  return (
    serviceCard && (
      <div className={classes.service_card}>
        {fields.map((field, i) => (
          <div key={`${field.title}${i}`} style={{ marginBottom: field.marginBottom || 0 }} className='d-flex'>
            <p className={field.className}>{field.title}</p>
            <p className={field.className}>{field.value}</p>
          </div>
        ))}
      </div>
    )
  );
};

ServiceCard.propTypes = {
  car: PropTypes.object.isRequired
};

export default ServiceCard;
