import { useTime } from 'hooks/use-time';
import { ITimeValue } from 'components/form-hook/form-field/components/time-field/time.props';
import { TimeMeasure } from 'common/enums';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { DEFAULT_TIME_VALUE } from 'components/form-hook/form-field/components/time-field/utils';
import { Moment } from 'moment';
import cloneDeep from 'lodash/cloneDeep';

const useChangeDateTimeField = (dateTime: Moment | null, setDateTime: Dispatch<SetStateAction<Moment | null>>) => {
  const { stringifyTime } = useTime();

  const changeTime = useCallback(
    (time: ITimeValue) => {
      if (dateTime) {
        const newDate = cloneDeep(dateTime)
          .startOf('day')
          .add(time[TimeMeasure.Hour], 'hours')
          .add(time[TimeMeasure.Minutes], 'minutes');
        if (newDate.isValid()) {
          setDateTime(cloneDeep(newDate));
        }
      }
    },
    [dateTime?.format()]
  );

  const timeValue = useMemo(
    () =>
      dateTime && dateTime.isValid()
        ? {
            [TimeMeasure.Hour]: stringifyTime(dateTime.hour()),
            [TimeMeasure.Minutes]: stringifyTime(dateTime.minute())
          }
        : DEFAULT_TIME_VALUE,
    [dateTime?.format()]
  );

  return { changeTime, timeValue };
};

export default useChangeDateTimeField;
