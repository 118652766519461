import React from 'react';
import PropTypes from 'prop-types';

export const CircleQuestionMarkIcon = ({ color = '#335566', width = '20', height = '20', className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
      // eslint-disable-next-line
        d='M10.0182 18.5725C7.76639 18.5725 5.58721 17.7009 3.98915 16.1028C2.31845 14.4321 1.44678 12.2529 1.44678 10.0011C1.44678 7.7493 2.31845 5.57012 3.98915 3.97206C5.58721 2.30136 7.69375 1.42969 10.0182 1.42969C12.27 1.42969 14.4492 2.30136 16.0473 3.89942C19.3887 7.24083 19.3887 12.6888 16.0473 16.0302C14.4492 17.6282 12.27 18.5725 10.0182 18.5725ZM10.0182 2.88247C8.12959 2.88247 6.31361 3.60886 5.0061 4.98901C3.62595 6.29652 2.89956 8.1125 2.89956 10.0011C2.89956 11.8897 3.62595 13.7057 5.0061 15.0132C6.31361 16.3207 8.12959 17.1198 10.0182 17.1198C11.9068 17.1198 13.7228 16.3934 15.0303 15.0132C17.7906 12.2529 17.7906 7.7493 15.0303 4.98901C13.6502 3.60886 11.9068 2.88247 10.0182 2.88247Z'
        fill={color}
      />
      <path
      // eslint-disable-next-line
        d='M9.11621 11.7607V11.4482C9.11621 11.0902 9.18132 10.7923 9.31152 10.5547C9.44173 10.3171 9.6696 10.0713 9.99512 9.81738C10.3825 9.51139 10.6315 9.27376 10.7422 9.10449C10.8561 8.93522 10.9131 8.7334 10.9131 8.49902C10.9131 8.22559 10.8219 8.01562 10.6396 7.86914C10.4574 7.72266 10.1953 7.64941 9.85352 7.64941C9.54427 7.64941 9.25781 7.69336 8.99414 7.78125C8.73047 7.86914 8.47331 7.97493 8.22266 8.09863L7.8125 7.23926C8.47331 6.87142 9.18132 6.6875 9.93652 6.6875C10.5745 6.6875 11.0807 6.84375 11.4551 7.15625C11.8294 7.46875 12.0166 7.90007 12.0166 8.4502C12.0166 8.69434 11.9808 8.91244 11.9092 9.10449C11.8376 9.29329 11.7285 9.47396 11.582 9.64648C11.4388 9.81901 11.1898 10.0436 10.835 10.3203C10.5322 10.5579 10.3288 10.7549 10.2246 10.9111C10.1237 11.0674 10.0732 11.2773 10.0732 11.541V11.7607H9.11621ZM8.91602 13.3184C8.91602 12.8268 9.15527 12.5811 9.63379 12.5811C9.86816 12.5811 10.0472 12.6462 10.1709 12.7764C10.2946 12.9033 10.3564 13.084 10.3564 13.3184C10.3564 13.5495 10.293 13.7334 10.166 13.8701C10.0423 14.0036 9.86491 14.0703 9.63379 14.0703C9.40267 14.0703 9.22526 14.0052 9.10156 13.875C8.97786 13.7415 8.91602 13.556 8.91602 13.3184Z'
        fill={color}
      />
    </svg>
  );
};

CircleQuestionMarkIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};
