import React from 'react';
import PropTypes from 'prop-types';

export const GasStationIcon = ({ color = '#fff', width = 15, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 3H4.5V6H7.5V3Z' fill={color} />
      <path d='M14.4 5.1L12.4 3.1C12.2 2.9 11.9 2.9 11.7 3.1C11.5 3.3 11.5 3.6 11.7 3.8L13.5 5.7V11.5C13.5 11.8 13.3 12 13 12C12.7 12 12.5 11.8 12.5 11.5V7.5C12.5 6.7 11.8 6 11 6H10.5V2C10.5 0.9 9.6 0 8.5 0H3.5C2.4 0 1.5 0.9 1.5 2V15H1C0.7 15 0.5 15.2 0.5 15.5C0.5 15.8 0.7 16 1 16H11C11.3 16 11.5 15.8 11.5 15.5C11.5 15.2 11.3 15 11 15H10.5V7H11C11.3 7 11.5 7.2 11.5 7.5V11.5C11.5 12.3 12.2 13 13 13C13.8 13 14.5 12.3 14.5 11.5V5.5C14.5 5.4 14.4 5.2 14.4 5.1ZM3.5 2.5C3.5 2.2 3.7 2 4 2H8C8.3 2 8.5 2.2 8.5 2.5V6.5C8.5 6.8 8.3 7 8 7H4C3.7 7 3.5 6.8 3.5 6.5V2.5Z' fill={color} />
    </svg>
  );
};

GasStationIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
