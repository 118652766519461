import React, { useEffect, useState } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { CustomFilterContainer, StyledCurrentWeek, StyledCustomFilters, StyledDivider } from './filters-styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { AnyObject } from 'common/interfaces';
import moment, { Moment } from 'moment';
import ThreeStateCheckbox from 'components/three-state-checkbox/three-state-checkbox';
import { SessionFilter, SetSessionFilter } from 'hooks/use-session-filter';
import { FilterNames, RecordStatuses, emptyFilterState } from '../bookings-page';
import { isEmpty } from 'lodash';
import { calenDarStartDateField, fromDateField, toDateField } from './form-fields';
moment.locale('de');

interface Props {
  sessionFilter: SessionFilter;
  setSessionFilter: SetSessionFilter;
  goToFirstPage: () => void;
  filtersWereCleared: boolean;
  setFiltersWereCleared: React.Dispatch<React.SetStateAction<boolean>>;
  calendarView: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Moment>>;
}

export type Status = {
  value: RecordStatuses;
  text: string;
};

const CustomFilters = ({
  setSessionFilter,
  sessionFilter,
  goToFirstPage,
  filtersWereCleared,
  setFiltersWereCleared,
  calendarView,
  setStartDate
}: Props) => {
  const reactHookFormData = useForm();
  const {
    getValues,
    setValue,
    formState: { errors }
  } = reactHookFormData;
  const { from, to } = getValues();
  const [statusFilters, setStatusFilters] = useState<AnyObject>({});
  const startDate = getValues('startDate');

  const EVENT_BORDER_COLORS = {
    [RecordStatuses.APPROVED_RECORD]: 'darkBlue',
    [RecordStatuses.OPENED_RECORD]: 'darkGold',
    [RecordStatuses.REJECTED_RECORD]: 'pink',
    [RecordStatuses.CANCELED_RECORD]: 'grey600'
  };

  const recordStatuses = [
    {
      value: RecordStatuses.CANCELED_RECORD,
      text: 'Storniert'
    },
    {
      value: RecordStatuses.OPENED_RECORD,
      text: 'Offen'
    },
    {
      value: RecordStatuses.APPROVED_RECORD,
      text: 'Bestätigt'
    },
    {
      value: RecordStatuses.REJECTED_RECORD,
      text: 'Abgelehnt'
    }
  ];

  const saveFilter = (
    title: string,
    serverValue: AnyObject | AnyObject[] | null,
    clientValue: string | AnyObject[] | null
  ) => {
    setSessionFilter({ [title]: serverValue }, { [title]: clientValue });
  };

  useEffect(() => {
    const isValidCustomDateFilter =
      sessionFilter.filterForClient.date === 'customDate' &&
      sessionFilter.filterForServer.date?.from &&
      sessionFilter.filterForServer.date?.to;

    if (isValidCustomDateFilter) {
      setValue('from', moment(sessionFilter.filterForServer.date?.from));
      setValue('to', moment(sessionFilter.filterForServer.date?.to));
    }
  }, []);

  const showCurrentWeekRecords = () => {
    if (!calendarView) {
      goToFirstPage();
      setFiltersWereCleared(false);

      if (sessionFilter.filterForClient.date === 'customDate' || sessionFilter.filterForClient.date !== 'weekDate') {
        setValue('from', null, { shouldDirty: true });
        setValue('to', null, { shouldDirty: true });
        const filteredDate = {
          from: moment().startOf('week'),
          to: moment().endOf('week')
        };
        saveFilter('date', filteredDate, 'weekDate');
      } else {
        saveFilter('date', null, null);
      }
    } else {
      setValue('startDate', null, { shouldDirty: true });
      setStartDate(moment().startOf('week'));
    }
  };

  useEffect(() => {
    setStatusFilters(
      recordStatuses.reduce((acc: Status, curr: Status) => {
        const checked = Boolean(
          sessionFilter.filterForClient[FilterNames.Status]?.find(
            (status: { checked: boolean; value: number }) => status.value === curr.value
          )?.value
        );
        return {
          ...acc,
          [curr.text]: {
            checked,
            value: curr.value
          }
        };
      }, {} as Status)
    );
  }, [sessionFilter.filterForClient]);

  useEffect(() => {
    if (filtersWereCleared) {
      setValue('from', null, { shouldDirty: true });
      setValue('to', null, { shouldDirty: true });
      setValue('startDate', null, { shouldDirty: true });
      setSessionFilter(emptyFilterState, emptyFilterState);
    }
  }, [filtersWereCleared]);

  useEffect(() => {
    goToFirstPage();
    setFiltersWereCleared(false);

    if (from && to && isEmpty(errors)) {
      const filteredDate = {
        from: moment(from).startOf('day'),
        to: moment(to).endOf('day')
      };
      saveFilter('date', filteredDate, 'customDate');
      return;
    }

    if (sessionFilter.filterForClient.date === 'customDate' && !from && !to) {
      const filteredDate = {
        from: null,
        to: null
      };
      saveFilter('date', filteredDate, 'customDate');
    }
  }, [from, to, sessionFilter.filterForClient.date]);

  const showByStatus = (status: { text: string; value: number }) => {
    goToFirstPage();
    const statuses = { ...statusFilters };

    const newStatuses = {
      ...statuses,
      [status.text]: {
        ...statuses[status.text],
        checked: !statuses[status.text].checked
      }
    };

    const clientStatuses = Object.keys(newStatuses || {})
      .map((key: string) => {
        if (newStatuses[key].checked) {
          return newStatuses[key];
        }
      })
      .filter(status => status);

    const serverValue = clientStatuses.map((status: { checked: boolean; value: number }) => ({
      value: status.value
    }));

    setFiltersWereCleared(false);
    setStatusFilters(newStatuses);
    saveFilter(FilterNames.Status, serverValue, clientStatuses);
  };

  useEffect(() => {
    const date = startDate || moment();
    const isValidDate = moment(date).startOf('week').isValid();
    if (isValidDate) {
      setStartDate(moment(date).startOf('week'));
    }
  }, [startDate]);

  return (
    <StyledCustomFilters bottom='30' top='15'>
      <CustomFilterContainer>
        {calendarView ? (
          <FormHook
            reactHookFormData={reactHookFormData}
            formFields={calenDarStartDateField}
            distanceBetweenFields='20'
          />
        ) : (
          <Flex maxWidth='375px' align='flex-start' height='90px'>
            <FormHook reactHookFormData={reactHookFormData} formFields={fromDateField} distanceBetweenFields='20' />
            <Flex align='center' height={'100%'}>
              <StyledDivider />
            </Flex>
            <FormHook reactHookFormData={reactHookFormData} formFields={toDateField} distanceBetweenFields='20' />
          </Flex>
        )}
      </CustomFilterContainer>

      <StyledCurrentWeek onClick={showCurrentWeekRecords} weekFilter>
        <Flex right='20'>
          <img
            width={33}
            height={25}
            src={
              sessionFilter.filterForClient.date === 'weekDate'
                ? '/assets/images/week-active.svg'
                : '/assets/images/week.svg'
            }
            alt='week'
          />
        </Flex>
        <Flex direction='column' padding=' 0 60px 0 0'>
          <Text size='20' fontWeight={600} color='blue'>
            Diese Woche
          </Text>
          <Text>{`${moment().startOf('week').format('ddd DD.MM.YY')} - ${moment()
            .endOf('week')
            .format('ddd DD.MM.YY')}`}</Text>
        </Flex>
      </StyledCurrentWeek>

      <CustomFilterContainer>
        <Flex width='300px' wrap='wrap' padding=' 0 60px 0 0' justify='space-between'>
          {recordStatuses.map((status: { text: string; value: RecordStatuses }) => (
            <Flex key={status.text} width='110px' align='center'>
              <ThreeStateCheckbox
                checked={!isEmpty(statusFilters) && statusFilters[status.text]?.checked}
                onChange={() => showByStatus(status)}
                indeterminate={false}
              />
              <Text color={EVENT_BORDER_COLORS[status.value as RecordStatuses]} left='13'>
                {status.text}
              </Text>
            </Flex>
          ))}
        </Flex>
      </CustomFilterContainer>
    </StyledCustomFilters>
  );
};

export default CustomFilters;
