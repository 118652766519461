import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleDetailsEditFormSubmitCallbackParams } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form.props';
import { useGetMyVehicleQuery } from 'hooks/react-query/dashboard/get-my-vehicle/get-my-vehicle.query';
import { useUserDoesNotHaveMainCarModal } from 'hooks/modals/car/main-car/use-user-does-not-have-main-car/use-user-does-not-have-main-car';
import { useUserAlreadyHasMainCarModal } from 'hooks/modals/car/main-car/user-already-has-main-car/user-already-has-main-car';
import { useChangeToSecondaryVehicleModal } from 'hooks/modals/car/main-car/use-change-to-secondary-vehicle/use-change-to-secondary-vehicle';

export const useVehicleDetailMainCarFieldSubmit = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { myVehicleQuery } = useGetMyVehicleQuery(fuhrpark?.new_fahrerid_id, { fetch: { isShowLoading: false } });

  const { openUserAlreadyHasMainCarModal } = useUserAlreadyHasMainCarModal();
  const { openUserDoesNotHaveMainCarModal } = useUserDoesNotHaveMainCarModal();
  const { openChangeToSecondaryVehicleModal } = useChangeToSecondaryVehicleModal();

  const mainCarSubmitCallback = ({ values }: VehicleDetailsEditFormSubmitCallbackParams) => {
    const currentMainCar = myVehicleQuery.find(item => item?.uds_hauptauto);

    const driver = fuhrpark?.new_fahrerid || '-';
    const selectedCarNumber = fuhrpark?.new_name || '';

    const submitData = { fuhrparkId: fuhrpark?.new_fuhrparkid || '' };

    if (values.uds_hauptauto === 'true') {
      if (currentMainCar) {
        openUserAlreadyHasMainCarModal({
          submitData,
          driver,
          carNumber: {
            current: currentMainCar?.new_name || '',
            new: selectedCarNumber
          }
        });
      } else {
        openUserDoesNotHaveMainCarModal({ submitData, driver, selectedCarNumber });
      }
    } else {
      openChangeToSecondaryVehicleModal({ submitData, driver, selectedCarNumber });
    }

    return true;
  };

  return { mainCarSubmitCallback };
};
