import React from 'react';
import { Button, Flex, Text } from '../../../common/common-components.styled';
import Form from '../../../components/form/form-redux.js';
import { declineFormFields } from '../../../models/forms/order-widget-form-model.js';

interface DeclineDialogProps {
  setShowDeclineDialog(flag: boolean): void;
  decline(): void;
}

const DeclineDialog = ({ setShowDeclineDialog, decline }: DeclineDialogProps) => {

  return (
    <Flex direction='column' top='20'>
      <Form name={'decline'} formFields={declineFormFields} />
      <Flex align='center' justify='space-between' top='20'>
        <Text size='12' color='grey500'>Bitte nochmal bestätigen.</Text>
        <Flex>
          <Button onClick={decline} secondary>Bestätigen</Button>
          <Button onClick={() => setShowDeclineDialog(false)} left='10'>Zurück</Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DeclineDialog;
