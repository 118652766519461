import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ReportIcon = ({ width = 21, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M7.13394 1H1.51855C0.96627 1 0.518555 1.44771 0.518555 2V3.19291V9C0.518555 9.55228 0.96627 10 1.51855 10H7.13394C7.68622 10 8.13394 9.55229 8.13394 9V2C8.13394 1.44772 7.68622 1 7.13394 1Z'
          fill={fill}
        />
      )}
      <path
        d='M0.518555 3.19291V1.8C0.518555 1.35817 0.876727 1 1.31855 1H7.33394C7.77577 1 8.13394 1.35817 8.13394 1.8V9.2C8.13394 9.64183 7.77577 10 7.33394 10H1.31855C0.876727 10 0.518555 9.64183 0.518555 9.2V5.95498'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6718 17.2483L17.3922 18.9632C17.572 19.0757 17.8026 18.9128 17.7479 18.711L16.9154 15.6615L19.4325 13.6712C19.6006 13.5393 19.5107 13.2755 19.2957 13.2638L16.0594 13.0931L14.8946 10.1484C14.8165 9.95053 14.5311 9.95053 14.4529 10.1484L13.2882 13.0931L10.0519 13.2638C9.83688 13.2755 9.74699 13.5393 9.91506 13.6712L12.4322 15.6615L11.5997 18.711C11.5449 18.9128 11.7756 19.0757 11.9553 18.9632L14.6718 17.2483Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='9.86621' y='1' width='7.61539' height='6.92308' rx='0.8' stroke={color} strokeLinejoin='round' />
      <rect
        x='0.518555'
        y='12.0769'
        width='7.61539'
        height='6.92308'
        rx='0.8'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
      <rect
        x='9.86621'
        y='1'
        width='7.61539'
        height='6.92308'
        rx='0.8'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
      <rect x='0.518555' y='12.0769' width='7.61539' height='6.92308' rx='0.8' stroke={color} strokeLinejoin='round' />
    </svg>
  );
};
