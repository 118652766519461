import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { StyledMenuContainer, StyledMenuDot, StyledMenuItem, StyledThreeDotMenu } from './more-menu.styled';
import { PrefixForId } from '../../common/enums';

export interface MenuItemProps {
  title: string;
  disabled?: boolean;
  onItemClick(): void;
  id?: string;
  hidden?: boolean;
  width?: number;
}

interface MenuItemsProps {
  menuItems: MenuItemProps[];
  hide?: boolean;
  dotsColor?: string;
  idEnding?: PrefixForId | string | number;
  width?: number;
}

const MenuItem = (menuItem: MenuItemProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      !menuItem.disabled && menuItem.onItemClick();
    },
    [menuItem]
  );

  return (
    <StyledMenuItem
      disabled={menuItem.disabled}
      onClick={e => handleClick(e)}
      style={{ width: menuItem.width ? `${menuItem.width}px` : '160x' }}
      id={PrefixForId.MenuItem + menuItem.id}
    >
      <Text>{menuItem.title}</Text>
    </StyledMenuItem>
  );
};

const MoreMenu = ({ menuItems, hide, dotsColor = 'grey600', width, idEnding = '' }: MenuItemsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleMenuOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (onItemClick: () => void) => {
    setIsOpen(false);
    onItemClick();
  };

  return (
    <StyledThreeDotMenu hide={hide} ref={wrapperRef}>
      <Flex
        direction='column'
        align='center'
        onClick={e => toggleMenuOpen(e)}
        width='20px'
        id={PrefixForId.DotsMenu + idEnding}
      >
        <StyledMenuDot color={dotsColor} />
        <StyledMenuDot color={dotsColor} />
        <StyledMenuDot color={dotsColor} />
      </Flex>
      {isOpen && (
        <StyledMenuContainer width={width}>
          {menuItems.map(
            item =>
              !item.hidden && (
                <MenuItem
                  title={item.title}
                  disabled={item.disabled}
                  key={item.title}
                  onItemClick={() => handleMenuClick(item.onItemClick)}
                  id={item.id}
                  width={width}
                />
              )
          )}
        </StyledMenuContainer>
      )}
    </StyledThreeDotMenu>
  );
};

export default MoreMenu;
