import styled, { css } from 'styled-components';
import { Flex, Title, Text, Panel } from 'common/common-components.styled';
import { BUDGET_STATUSES_COLORS } from 'components/configurator-modal/configurator-modal-view';

export const StyledConfiguratorPanel = styled(Panel)`
  min-height: 100vh;
`;

export const StyledTitle = styled(Title)`
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 7px 20px;
  font-weight: normal;
  font-size: 24px;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.media.desktop}) {
    font-size: 30px;
  }
`;

interface StyledInfoContainerProps {
  status: 'success' | 'warning' | 'error';
}

export const StyledInfoContainer = styled(Flex)<StyledInfoContainerProps>`
  background-color: ${({ theme, status }) => theme.colors[BUDGET_STATUSES_COLORS[status].backgroundColor]};
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 100% !important;
  }
`;

export const StyledCardsContainer = styled(Flex)`
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 100% !important;
  }
  @media (min-width: ${({ theme }) => theme.media.desktop}) {
    flex-direction: row;
  }
`;

interface StyledInfoCardsContainerProps {
  leftSpace?: boolean;
}

export const StyledInfoCardsContainer = styled(Flex)<StyledInfoCardsContainerProps>`
  flex-direction: column;
  margin-left: 0;

  @media (max-width: ${({ theme }) => theme.media.desktop}) {
    width: 100% !important;
  }
  @media (min-width: ${({ theme }) => theme.media.desktop}) {
    flex-direction: row;
    ${({ leftSpace }) =>
      leftSpace &&
      css`
        margin-left: 10px;
      `};
  }
`;

export const StyledCarPolicyItem = styled(Text)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const StyledInfoCard = styled(StyledInfoContainer)`
  width: 100%;
`;
