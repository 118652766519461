import { createContext } from 'react';
import { OptionSetFileContextProps } from './option-set-file.props';
import { FormFieldTypes } from 'common/enums';

export const OptionSetFileContext = createContext<OptionSetFileContextProps>({
  type: FormFieldTypes.LookupFile,
  options: [],
  setOptions: () => {},
  values: [],
  onChange: () => {}
});
