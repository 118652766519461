import React from 'react';
import { Text } from 'common/common-components.styled';
import { StyledNoteContent } from './note-content.styled';
import { NoteModel } from 'hooks/react-query/fleet/get-notes/get-notes.props';
import { useShowWholeNote } from './hooks/use-show-whole-note';

export const NoteContent = ({ uds_notiz = '', uds_notizenid = '' }: NoteModel) => {
  const { showWholeNote, defaultContentHeight, setShowWholeNote } = useShowWholeNote(uds_notiz || '', uds_notizenid);

  return (
    <div>
      <StyledNoteContent id={uds_notizenid} showWholeNote={showWholeNote} defaultContentHeight={defaultContentHeight}>
        {uds_notiz}
      </StyledNoteContent>

      {defaultContentHeight > 60 && (
        <Text onClick={() => setShowWholeNote(prev => !prev)} color='blue' underline pointer top='10'>
          {showWholeNote ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </Text>
      )}
    </div>
  );
};
