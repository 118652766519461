import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

export default function Models({ models, changeHandler, search, value }) {
  const onChange = ({ target: { value } }) => {
    const s = cloneDeep(search);
    s.predicateObject.model = value || null;
    changeHandler(s);
  };

  return (
    <div className='select filter-select'>
      <select
        value={value}
        onChange={onChange}
        className='form-control btn btn-default btn-block'
        // style={{ borderColor: "rgba(0, 0, 0, 0.75)" }}
        disabled={models.length === 0}
      >
        <option value=''>Alle Modelle</option>
        {models
          .sort((a, b) => {
            if(a.uds_name.toLowerCase() < b.uds_name.toLowerCase()) { return -1; }
            if(a.uds_name.toLowerCase() > b.uds_name.toLowerCase()) { return 1; }
            return 0;
          })
          .map(option => (
          <option value={option.uds_carmodelid} key={option.uds_carmodelid}>
            {option.uds_name}
          </option>
        ))}
      </select>
    </div>
  );
}

Models.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  models: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string
};
