import React from 'react';
import classes from './loading-component.module.scss';
import { MobexoLogoIcon } from '../icons/mobexo-logo-icon';

export default function LoadingComponent() {
  return <div className={classes.loading}>
    <MobexoLogoIcon color={'#102F3F'} />
    <div className={classes.loading_text}>WIRD GELADEN...</div>
  </div>
  ;
}
