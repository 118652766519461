import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { AxiosResponse } from 'axios';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { RequestStatuses } from '../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fuelCardRequestConfig } from 'request-config/fuel-card/fuel-card.request-config';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<boolean>>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data;
  }

  return false;
};

const useContractStatus = () => {
  const { fetch } = useFetch<ResponseModel<boolean>>({
    isShowLoading: false,
    isShowErrorSnackbar: false,
    ...fuelCardRequestConfig.checkContract()
  });

  const { data: isContractAvailable } = useQuery<ApiResponse<boolean>, unknown, boolean>(
    [ReactQueryKeys.CheckContract],
    () => fetch(),
    {
      select: modifyResponse
    }
  );

  return { isContractAvailable: isContractAvailable || false };
};

export default useContractStatus;
