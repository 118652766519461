import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { CrmName } from 'components/aufgaben-component/task.prop';
import moment from 'moment';

export const editDueDateFormFields: FormFieldModel[] = [
  {
    name: CrmName.DateOptions,
    type: FormFieldTypes.OptionSet,
    defaultValue: '',
    label: 'Fälligkeit',
    options: [
      {
        label: 'Heute',
        value: 'today'
      },
      {
        label: 'In 2 Tagen',
        value: 2
      },
      {
        label: 'In 1 Woche',
        value: 7
      },
      {
        label: 'In 2 Wochen',
        value: 14
      },
      {
        label: 'In 1 Monat',
        value: 30
      },
      {
        label: 'Auswählen',
        value: 'choose'
      }
    ],
    validation: {
      required: 'Bitte auswählen'
    }
  }
];

export const editDueDateCalendarFormFields: FormFieldModel[] = [
  {
    name: CrmName.DateChosen,
    type: FormFieldTypes.Date,
    defaultValue: null,
    validation: {
      required: 'Bitte auswählen'
    },
    minDate: moment().startOf('day')
  }
];

export const taskEmployeeFormFields: FormFieldModel[] = [
  {
    name: CrmName.Employee,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Mitarbeiter',
    options: [],
    validation: {
      required: 'Bitte auswählen'
    }
  }
];

export const taskVehicleFormFields: FormFieldModel[] = [
  {
    name: CrmName.Vehicle,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Fahrzeug',
    options: [],
    validation: {}
  }
];

export const taskDescriptionFormFields: FormFieldModel[] = [
  {
    name: CrmName.Description,
    type: FormFieldTypes.TextArea,
    label: 'Beschreibung',
    defaultValue: '',
    validation: {}
  }
];

export const taskTitleFormFields: FormFieldModel[] = [
  {
    name: CrmName.Title,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Titel',
    validation: {
      required: 'Bitte auswählen'
    }
  }
];
