import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { FormInfoItemDefaultProps } from 'components/form-hook/components/info/components/type/default/default.props';

export const useFormInfoListDataTechnicalInformation = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const formInfoListDataTechnicalInformation: FormInfoItemDefaultProps[] = [
    { label: 'Kategorie', value: fuhrpark?.uds_fahrzeugkategoriecode },
    { label: 'BaseModel', value: fuhrpark?.uds_basemodelname },
    { label: 'Verkaufsbezeichnung', value: fuhrpark?.uds_salesdescription },
    { label: 'Türen', value: fuhrpark?.uds_vehicledoors?.toLocaleString('de-DE') },
    { label: 'Sitze', value: fuhrpark?.uds_vehicleseats?.toLocaleString('de-DE') },
    { label: 'Fahrzeugtyp', value: fuhrpark?.new_kategorie },
    { label: 'Motortyp', value: fuhrpark?.uds_enginetypename },
    { label: 'Baujahr', value: fuhrpark?.new_baujahr },
    { label: 'Energie hp', value: fuhrpark?.uds_powerhp?.toLocaleString('de-DE') },
    { label: 'Energie kw', value: fuhrpark?.uds_powerkw?.toLocaleString('de-DE') },
    { label: 'Drehmoment', value: fuhrpark?.uds_torque?.toLocaleString('de-DE') },
    { label: 'Zylinder', value: fuhrpark?.uds_cylinders?.toLocaleString('de-DE') },
    { label: 'Zylinderanordnung', value: fuhrpark?.uds_cylinderarrangement },
    { label: 'Antrieb', value: fuhrpark?.uds_drive },
    { label: 'Drehzahl pro minute', value: fuhrpark?.uds_rotationsonmaxtorque?.toLocaleString('de-DE') },
    { label: 'Reifengröße Vorne', value: fuhrpark?.new_reifengroessevorne },
    { label: 'Reifengröße Hinten', value: fuhrpark?.new_reifengroessehinten },
    { label: 'Höchstgeschwindigkeit', value: fuhrpark?.uds_speedmax?.toLocaleString('de-DE') },
    { label: 'Achsen gesamt', value: fuhrpark?.uds_countofaxles?.toLocaleString('de-DE') },
    { label: 'Achsen angetrieben', value: fuhrpark?.uds_countofdrivedaxles?.toLocaleString('de-DE') },
    { label: 'Tank volumen', value: fuhrpark?.uds_tankvolume?.toLocaleString('de-DE') },
    { label: 'Airbags', value: fuhrpark?.uds_countofairbags?.toLocaleString('de-DE') },
    { label: 'Länge', value: fuhrpark?.uds_length?.toLocaleString('de-DE') },
    { label: 'Breite', value: fuhrpark?.uds_width?.toLocaleString('de-DE') },
    { label: 'Höhe', value: fuhrpark?.uds_height?.toLocaleString('de-DE') },
    { label: 'Radstand', value: fuhrpark?.uds_wheelbase?.toLocaleString('de-DE') },
    { label: 'Ladefläche', value: fuhrpark?.uds_loadingspace?.toLocaleString('de-DE') },
    { label: 'Nutzlast', value: fuhrpark?.uds_payload?.toLocaleString('de-DE') },
    { label: 'Gewicht', value: fuhrpark?.uds_unloadedweight?.toLocaleString('de-DE') },
    { label: 'zGM', value: fuhrpark?.uds_permissabletotalweight?.toLocaleString('de-DE') },
    { label: 'Anhägelast gebremst', value: fuhrpark?.uds_trailerloadbraked?.toLocaleString('de-DE') },
    { label: 'Anhägelast ungebremst', value: fuhrpark?.uds_trailerloadunbraked?.toLocaleString('de-DE') },
    { label: 'DATECode', value: fuhrpark?.uds_datecode },
    { label: 'Dachlast', value: fuhrpark?.uds_roofload?.toLocaleString('de-DE') },
    { label: 'Fahrzeugpreis', value: fuhrpark?.uds_fahrzeugpreise }
  ];

  return { formInfoListDataTechnicalInformation };
};
