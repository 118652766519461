import React, { Fragment } from "react";
import { connect } from "react-redux";
import Button from "../btn";
import { CAR_TYPE_CODES } from "../../../../constants";
import { changeSearch } from "../../../../actions/app_action";
import { cloneDeep } from "lodash";
import classes from "./car-type.module.scss";

function CarType({ search, setSearch }) {
  const changeHandler = ({ name, active }) => {
    const s = cloneDeep(search);
    if (!active) {
      s.predicateObject.carTypes = s.predicateObject.carTypes.filter((x) => x !== name);
    } else {
      s.predicateObject.carTypes.push(name);
    }
    setSearch(s);
  };

  const carTypes = search.predicateObject.carTypes;
  return (
    <Fragment>
      <div className={classes.wrap}>
        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.Kleinwagen) > -1}
          name={CAR_TYPE_CODES.Kleinwagen}
          label="Klein"
          icon={<img src="/assets/images/klein.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />

        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.Limousine) > -1}
          name={CAR_TYPE_CODES.Limousine}
          label="Limousine"
          icon={<img src="/assets/images/lim.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />

        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.Kombi) > -1}
          name={CAR_TYPE_CODES.Kombi}
          label="Kombi"
          icon={<img src="/assets/images/kombi.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />

        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.Van) > -1}
          name={CAR_TYPE_CODES.Van}
          label="Van"
          icon={<img src="/assets/images/van.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />

        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.SUV_Gelandewagen) > -1}
          name={CAR_TYPE_CODES.SUV_Gelandewagen}
          label="SUV / Gelän."
          icon={<img src="/assets/images/suv.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />

        <Button
          active={carTypes.indexOf(CAR_TYPE_CODES.Nutzfahrzeug) > -1}
          name={CAR_TYPE_CODES.Nutzfahrzeug}
          label="Nutz"
          icon={<img src="/assets/images/nutz.png" style={{height:'40px'}} alt='' />}
          onChange={changeHandler}
        />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  search: state.app.smartTable.search,
});

const mapDispatchToProps = (dispatch) => ({
  setSearch(search) {
    dispatch(changeSearch(search));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarType);
