import { ComponentType } from 'react';

export const lazyLoadingRetryOnFail = (
  fn: () => Promise<any>,
  retriesLeft = 5
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: unknown) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          lazyLoadingRetryOnFail(fn, retriesLeft - 1).then(resolve, reject);
        }, 500);
      });
  });
};
