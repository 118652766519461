import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { TabItemFrameProps } from '../../tab-item-frame/tab-item-frame.props';
import { PickValue } from 'common/interfaces';
import { TabProps } from 'components/tab/tab.props';

export const StyledTabItemContainer = styled(Flex)<Pick<TabItemFrameProps, 'isActive'>>`
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.extraLightBlue : theme.colors.white)};
  padding: ${({ padding }) => padding || '0 30px'};
  margin: ${({ margin }) => margin || '0 -30px'};
`;

export const StyledBorderContainer = styled(Flex)<
  Pick<TabItemFrameProps, 'isActive' | 'tabIndex'> & PickValue<TabProps, 'containerBorder'>
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ isActive, tabIndex, isHideDivider }) => {
    if (!isActive && tabIndex && !isHideDivider) {
      return css`
        border-top: ${({ theme }) => `1px solid ${theme.colors.grey200}`};
      `;
    }
  }}
  padding: ${({ padding }) => padding || '20px 0'};
`;
