import React from 'react';
import { HistoryItemProps } from './history-item.types';
import { Distance, Flex, Text } from 'common/common-components.styled';
import {
  StyledCircle,
  StyledDataContainer,
  StyledGradientLine,
  StyledHistoryItem,
  StyledPenIcon
} from './history-item.styled';
import useHistoryItemValues from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/hooks/use-history-item-values';
import { useChangeCostCenterModals } from 'components/cost-centers-settings/hooks/use-change-cost-center-modals';
import useCostCentersHistoryList from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/hooks/use-cost-centers-history-list';
import { PrimaryEntityNames } from 'components/cost-centers-settings/cost-centers-settings.props';
import { defaultGuid } from '../../../../../../../../../../../../constants';
import { useQuery } from 'hooks';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip/ellipsis-with-tooltip';

const HistoryItem = ({ item, hideGradientLine, editable = true, index }: HistoryItemProps) => {
  const { startInFuture, validToDate, firstDate, secondDate } = useHistoryItemValues(item);
  const { openCostCenterHistoryModal } = useChangeCostCenterModals();
  const { refetchCostCentersHistoryItems } = useCostCentersHistoryList();
  const query = useQuery();
  const carId = (query.id || defaultGuid) as string;

  return (
    <StyledHistoryItem id={`${index}CostCenter`} padding='10px 25px' editable={editable}>
      <Flex direction='column' align='center' right='20'>
        <StyledCircle inFuture={startInFuture} />
        <Distance top='10' />
        {!hideGradientLine && <StyledGradientLine inFuture={startInFuture} />}
      </Flex>
      <Flex direction='column' width='90%'>
        <EllipsisWithTooltip tooltip={item.uds_cost_centerid || ''}>
          <Text id={`${index}CostCenterId`} bold size='20' color={startInFuture ? 'midBlue' : 'blue'}>
            {item.uds_cost_centerid}
          </Text>
        </EllipsisWithTooltip>

        <StyledDataContainer startInFuture={startInFuture}>
          <span>Zuordnung: {validToDate ? '' : 'ab '}</span>
          <span id={`${index}CostCenterFistDate`}>{firstDate}</span>
          {secondDate && (
            <>
              <span> bis </span>
              <span id={`${index}CostCenterSecondDate`}>{secondDate}</span>
            </>
          )}
        </StyledDataContainer>
      </Flex>
      <StyledPenIcon
        id={`${index}CostCenterEditButton`}
        width={30}
        height={30}
        onClick={() =>
          openCostCenterHistoryModal(refetchCostCentersHistoryItems, PrimaryEntityNames.Vehicle, carId, item)
        }
      />
    </StyledHistoryItem>
  );
};

export default HistoryItem;
