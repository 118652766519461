import React from 'react';
import classes from './use-main-tabs.module.scss';
import { TabControllerData } from 'components/tab/tab.props';
import { VehicleDetailsVehicleTabPanel } from '../../components/tab-panels/vehicle/vehicle';
import { VehicleDetailsOrganizationTabPanel } from '../../components/tab-panels/organization/organization';
import { VehicleDetailsLeasingTabPanel } from '../../components/tab-panels/leasing/leasing';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import { VehicleDetailsVehicleHistoryTabPanel } from '../../components/tab-panels/vehicle-history/vehicle-history';
import { VehicleDetailsNotes } from '../../components/tab-panels/notes/notes';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../../constants';
import { VehicleDetailsVehicleDocumentsTabPanel } from '../../components/tab-panels/vehicle-documents/vehicle-documents';
import { VEHICLE_DETAILS_MAIN_TABS_ID } from './utils';

export const useVehicleDetailsMainTabsData = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isOrderedStatus = fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;

  const mainTabsData: TabControllerData[] = [
    {
      tab: 'Fahrzeug',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.vehicle,
      panel: <VehicleDetailsVehicleTabPanel />,
      isHide: isOrderedStatus && !fuhrpark?.new_fin
    },
    {
      tab: 'Organisation',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.organization,
      panel: <VehicleDetailsOrganizationTabPanel />
    },
    {
      tab: 'Leasing & Leistungen',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.leasing,
      panel: <VehicleDetailsLeasingTabPanel />,
      className: isDriver ? classes.leasing_driver : classes.leasing
    },
    {
      tab: 'Fahrzeug Historie',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.vehicleHistory,
      panel: <VehicleDetailsVehicleHistoryTabPanel />,
      isHide: isOrderedStatus,
      className: !isDriver && classes.history
    },
    {
      tab: 'Fahrzeugdokumente',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.vehicleDocuments,
      panel: <VehicleDetailsVehicleDocumentsTabPanel />
    },
    {
      tab: 'Notizen',
      id: VEHICLE_DETAILS_MAIN_TABS_ID.notes,
      panel: <VehicleDetailsNotes />
    }
  ];

  const getTabIndex = (tabIdKey: keyof typeof VEHICLE_DETAILS_MAIN_TABS_ID) => {
    return mainTabsData.findIndex(item => item.id === VEHICLE_DETAILS_MAIN_TABS_ID[tabIdKey]);
  };

  return { mainTabsData, getTabIndex };
};
