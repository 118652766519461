import { SessionFilter } from 'hooks/use-session-filter';
import { EXPANDED_FILTERS } from 'pages/my-vehicles/my-vehicles';
import { MyVehicleLocation } from 'pages/my-vehicles/my-vehicles.props';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useMyVehicleIsFilterExpanded = (sessionFilter: SessionFilter) => {
  const [isFilterExpended, setIsExpandedFilter] = useState(false);

  const location = useLocation<MyVehicleLocation>();

  const getIsExpandContainer = () => {
    if (!location.state?.filterForServer && !sessionFilter.filterForServer) {
      return false;
    }

    return Object.entries(location.state?.filterForServer || sessionFilter.filterForServer)
      .filter(([key]) => EXPANDED_FILTERS.includes(key))
      .some(([_, value]) => {
        if (Array.isArray(value)) {
          return !!value?.length;
        }
        if (typeof value === 'number') {
          return true;
        }
        return !!value;
      });
  };

  useEffect(() => {
    setIsExpandedFilter(getIsExpandContainer());
  }, [JSON.stringify(location.state?.filterForServer)]);

  return { isFilterExpended };
};
