import React, { Fragment } from "react";
import Footnote from "../../../components/footnote/footnote";

export default function Footnotes() {
  return (
    <div className="container">
      <div className="row">
        <Footnote
          className="col-md-12"
          text={
            <Fragment>
              * Die angegebenen Werte wurden nach dem vorgeschriebenen Messverfahren ermittelt. Es handelt sich um die
              "NEFZ-CO₂-Werte" i.S.v. Art. 2 Nr. 1 Durchführungsverordnung (EU) 2017/1153. Die Kraftstoffverbrauchswerte
              wurden auf Basis dieser Werte errechnet. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und
              sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen verschiedenen
              Fahrzeugtypen. Die Werte variieren in Abhängigkeit der gewählten Sonderausstattungen. Weitere
              Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer
              Personenkraftwagen können dem "Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den
              Stromverbrauch neuer Personenkraftwagen" entnommen werden, der an allen Verkaufsstellen und bei DAT
              Deutsche Automobil Treuhand GmbH unter
              <strong>
                <a href="https://www.dat.de" target="_blank" rel="noopener noreferrer">
                  &nbsp;www.dat.de&nbsp;
                </a>
              </strong>
              unentgeltlich erhältlich ist.
            </Fragment>
          }
        />
      </div>
    </div>
  );
}
