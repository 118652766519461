import React from 'react';
import { useTheme } from 'styled-components';

export default function TextFileIcon(props) {
  const theme = useTheme();
  const fill = props.fill || theme.colors.blue;
  return (
    <svg width='24' height='24' {...props} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.8 3H13.0716C13.5388 3 13.9877 3.18163 14.3234 3.50652L20.4518 9.43719C20.8022 9.77629 21 10.243 21 10.7307V19.2C21 20.1941 20.1941 21 19.2 21H4.8C3.80589 21 3 20.1941 3 19.2V4.8C3 3.80589 3.80589 3 4.8 3Z'
        stroke={fill}
        strokeWidth='2'
      />
      <path d='M6.6001 9H16.2001' stroke={fill} strokeWidth='2' strokeLinecap='round' />
      <path d='M6.6001 12.5996H16.2001' stroke={fill} strokeWidth='2' strokeLinecap='round' />
      <path d='M6.6001 16.2002H11.4001' stroke={fill} strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
}
