import { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface PoolCarBookingSettingsProps {
  companyId: string;
}

export interface PoolCarSettingsContextProps extends PoolCarBookingSettingsProps {
  reactHookFormData: UseFormReturn;
}

export enum PoolCarSettingsTabNames {
  General = 'Allgemein',
  Locations = 'Standorte',
  Classes = 'Klassen',
  Releases = 'Freigaben'
}

export enum PoolCarSettingsFieldNames {
  TimeGap = 'uds_bookings_timegapcode',
  ResponsibleInGeneral = 'uds_responsible_ingeneralid',
  BillingBasis = 'uds_billingbasis_eurocode',
  IsBookingDifferentLocation = 'uds_isbooking_different_standort',
  IsShowBookingDetails = 'uds_isshow_bookingdetails',
  IsAbleToBookVirtualVehicles = 'uds_isbookedbymultipledrivers'
}

export interface PoolCarSettingsTab {
  name: PoolCarSettingsTabNames;
  component: ReactElement;
  active: boolean;
}
