import React, { useState, useEffect } from 'react';
import { Flex, Text, Button, Distance } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { cloneDeep } from 'lodash';
import { extendLeaseAgreementFormFields } from './extend-lease-agreement-form-fields';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import DefaultExtensionModal from './default-extension-modal/default-extension-modal';
import { ExtendLeaseAgreementProps } from './extend-lease-agreement.props';
import ConfirmationModal from './confirmation-modal/confirmation-modal';

const ExtendLeaseAgreement = ({
  plateNumber,
  amountOfMonths,
  additionalMileage,
  comment,
  onClose,
  onSubmit
}: ExtendLeaseAgreementProps) => {
  const theme = useTheme();

  const reactHookFormData = useForm();

  const { handleSubmit, getValues, watch } = reactHookFormData;

  const [isDefaultModal, setIsDefaultModal] = useState(true);
  const [isChangeTerms, setIsChangeTerms] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const isChangeMileageCurrent = watch(LeasingContractFields.IsChangeMileage);
  const currentMonths = watch(LeasingContractFields.AddedMonths);
  const [currentExtendLeaseAgreementFormFields, setCurrentLeaseAgreementFormFields] =
    useState(extendLeaseAgreementFormFields);

  useEffect(() => {
    if (isChangeMileageCurrent) {
      const newExtendLeaseAgreementFormFields = cloneDeep(currentExtendLeaseAgreementFormFields);
      newExtendLeaseAgreementFormFields.forEach(field => {
        if (field.name === LeasingContractFields.Mileage) {
          field.hidden = false;
        }
      });
      newExtendLeaseAgreementFormFields[2].hidden = false;

      setCurrentLeaseAgreementFormFields(newExtendLeaseAgreementFormFields);
    } else {
      setCurrentLeaseAgreementFormFields(extendLeaseAgreementFormFields);
    }
  }, [currentExtendLeaseAgreementFormFields, isChangeMileageCurrent]);

  const submit = () => {
    const nextPhase = isChangeTerms ? LeaseExtentionPhases.Phase8 : LeaseExtentionPhases.Phase9;
    handleSubmit(data => {
      onSubmit({
        nextPhase: nextPhase,
        months: data[LeasingContractFields.AddedMonths],
        mileage: data[LeasingContractFields.Mileage],
        successMessage: SUCCESS_MESSAGE_AGREEMENT_EXTENDED,
        comment: data[LeasingContractFields.Comment],
        silentExtend: false
      });
    })();
  };

  const handleExtend = () => {
    setIsChangeTerms(true);
    setIsConfirmationModal(false);
  };

  const handleConfirm = () => {
    setIsDefaultModal(false);
    setIsConfirmationModal(true);
  };

  return (
    <>
      {isDefaultModal && (
        <>
          <DefaultExtensionModal
            plateNumber={plateNumber}
            amountOfMonths={amountOfMonths}
            additionalMileage={additionalMileage}
            comment={comment}
          />
          {isChangeTerms && (
            <FormHook reactHookFormData={reactHookFormData} formFields={currentExtendLeaseAgreementFormFields} />
          )}
        </>
      )}
      {isConfirmationModal && (
        <ConfirmationModal
          isChangeTerms={isChangeTerms}
          plateNumber={plateNumber}
          updatedMileage={getValues(LeasingContractFields.Mileage)}
          updatedMonths={getValues(LeasingContractFields.AddedMonths)}
          onSubmit={submit}
          onCancel={onClose}
        />
      )}
      <Distance top='30' />
      {isDefaultModal && (
        <Flex justify='space-between' width='100%' align='center'>
          <Text color={theme.colors.blue} onClick={onClose} pointer>
            &larr; Abbrechen
          </Text>
          <Flex>
            <Button id={'chaangeConditionsButton'} secondary onClick={handleExtend} right='10' disabled={isChangeTerms}>
              Bedingungen ändern
            </Button>
            <Button id={'extendButton'} onClick={handleConfirm} disabled={isChangeTerms && !currentMonths}>
              Verlängern
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
};

const SUCCESS_MESSAGE_AGREEMENT_EXTENDED = 'Deine Entscheidung wurde erfolgreich gespeichert';

export default ExtendLeaseAgreement;
