import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DriversLicenseIcon = ({
  width = 26,
  height = 18,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M2.29082 1.06458H23.194C24.0457 1.06458 24.7424 1.76135 24.7424 2.61297V15.3872C24.7424 16.2388 24.0457 16.9355 23.194 16.9355H20.7331H2.29082C1.43921 16.9355 0.742432 16.2388 0.742432 15.3872V2.61297C0.742432 1.76135 1.43921 1.06458 2.29082 1.06458Z'
          fill={fill}
        />
      )}
      <path
        d='M20.7331 16.9355H23.194C24.0457 16.9355 24.7424 16.2388 24.7424 15.3872V2.61297C24.7424 1.76135 24.0457 1.06458 23.194 1.06458H2.29082C1.43921 1.06458 0.742432 1.76135 0.742432 2.61297V15.3872C0.742432 16.2388 1.43921 16.9355 2.29082 16.9355H16.7368'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1431 5.85547H17.1431'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1431 8.82605H22.1431'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1431 12.1444H22.1431'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.3418 12.3612L4.89018 11.8451C4.89018 11.8451 5.78051 12.3612 6.9547 12.3612C8.12889 12.3612 9.01922 11.8451 9.01922 11.8451L10.5676 12.3612'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.01914 7.43224C9.18689 6.0774 7.73989 5.6217 7.04312 5.6217C6.34634 5.6217 4.91737 6.0774 5.08511 7.43224L5.22704 8.69675C5.31736 9.52255 5.58539 9.91315 6.0241 10.1712L8.02265 10.1712C8.46136 9.91315 8.78689 9.52255 8.87721 8.69675L9.01914 7.43224Z'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M8.02265 10.1712C8.46136 9.91315 8.78689 9.52255 8.87721 8.69675L9.01914 7.43224C9.18689 6.0774 7.73989 5.6217 7.04312 5.6217C6.34634 5.6217 4.91737 6.0774 5.08511 7.43224L5.22704 8.69675C5.31736 9.52255 5.58539 9.91315 6.0241 10.1712'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
