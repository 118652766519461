import { connect } from 'react-redux';

import { setSnakbar, toggleModal, setEndLoading, setStartLoading } from '../../../../actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../../constants';
import DashboardItem from './dashboard-item-view';

const mapStateToProps = state => ({
  user: state.app.user
});

const mapDispatchToProps = dispatch => ({
  openAlert(data) {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, data));
  },
  closeAlert() {
    dispatch(toggleModal(MODALS.alert, null));
  },
  hideOverlay() {
    dispatch(setEndLoading());
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  setSnackbar(data) {
    dispatch(setSnakbar(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardItem);
