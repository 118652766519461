import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const FileDownloadIcon = ({ width = 25, height = 24, color: colorProp, hovered }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Name=File download, Size=24x24, Hovered=Yes'>
        <g id='download'>
          {hovered && (
            <path
              id='Vector'
              d='M17.2478 11.4194V7.35491L13.1833 7.45581V3.00014H3.89314C3.57379 3.00014 3.3125 3.26142 3.3125 3.58077V20.4192C3.3125 20.7386 3.57379 20.9999 3.89314 20.9999H17.2478'
              fill='#E6F7FF'
            />
          )}
          <path
            id='Vector_2'
            d='M21.3126 19.8385V20.9998H12.603V19.8385'
            stroke={color}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          {hovered && (
            <path
              id='_x3C_Trazado_x3E__2_'
              d='M15.5058 10.2581V14.6129H14.0542L16.9574 19.2579L19.8606 14.6129H18.409V12.855'
              fill='white'
            />
          )}
          <path
            id='Vector_3'
            d='M17.2478 11.4194V7.35491L13.1833 3.00014H3.89314C3.57379 3.00014 3.3125 3.26142 3.3125 3.58077V20.4192C3.3125 20.7386 3.57379 20.9999 3.89314 20.9999H10.8608'
            stroke={color}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='_x3C_Trazado_x3E__2__2'
            d='M15.5058 10.2581V14.6129H14.0542L16.9574 19.2579L19.8606 14.6129H18.409V12.8709'
            stroke={color}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M17.2476 7.3549H13.4734C13.3137 7.3549 13.1831 7.22426 13.1831 7.06459V5.32268'
            stroke={color}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  );
};
