import { SetSessionFilter } from 'hooks/use-session-filter';
import { useEffect } from 'react';
import { defaultFilterValue } from '../fahrzeugkosten-page';

export const useOnLeavingPageReturnDefaultFilters = (setSessionFilter: SetSessionFilter) => {
  const leavingPageHandler = () => {
    setSessionFilter(defaultFilterValue, defaultFilterValue);
  };

  useEffect(() => leavingPageHandler, []);
};
