import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const VehicleIcon = ({ width = 20, height = 10, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M0.825248 6.76449C0.737839 7.1658 1.45609 7.72486 1.45609 7.72486H18.3386H18.731C18.731 7.72486 19.0719 7.76095 19.0719 7.20223L19.0719 5.24048C19.0719 4.37099 18.7299 3.96935 18.5766 3.54295L11.6361 3.68633H5.7571C5.7571 3.68633 2.20442 3.68889 1.67866 4.25409C1.15289 4.81929 0.932943 6.27004 0.825248 6.76449Z'
          fill={fill}
        />
      )}
      {hovered && (
        <path
          d='M8.73061 1.25456C7.30784 1.72978 5.75732 3.68632 5.75732 3.68632H11.6363L18.5768 3.54295C18.2691 2.68708 17.7987 1.25456 17.7987 1.25456C17.7987 1.25456 12.0676 0.139997 8.73061 1.25456Z'
          fill='white'
        />
      )}
      <path
        d='M1.57417 7.67638C1.57417 7.67638 0.865906 7.1251 0.952098 6.72938C1.05829 6.24181 1.27518 4.81126 1.79363 4.25392C2.31208 3.69659 5.8153 3.69406 5.8153 3.69406M16.9068 3.69406H11.6125M5.8153 3.69406C5.8153 3.69406 7.34424 1.76475 8.74721 1.29615C12.0377 0.197093 17.6891 1.29615 17.6891 1.29615C17.6891 1.29615 18.1529 2.70872 18.4564 3.55268C18.6076 3.97314 18.9448 4.3692 18.9448 5.22659C18.9448 6.08397 18.9448 6.44975 18.9448 7.16103C18.9448 7.71197 18.6087 7.67638 18.6087 7.67638H18.2217M5.8153 3.69406H11.6125M12.2248 7.67638H7.71669M11.6125 3.69406L12.838 2.12819'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <ellipse cx='4.6432' cy='7.51249' rx='1.67982' ry='1.67982' fill={hovered ? 'white' : 'none'} stroke={color} />
      <ellipse cx='15.2247' cy='7.51249' rx='1.67982' ry='1.67982' fill={hovered ? 'white' : 'none'} stroke={color} />
    </svg>
  );
};
