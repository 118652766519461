import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export async function getFileDescriptions() {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fuhrpark/descriptionchoices`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getFuhrparkById(id) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fuhrpark/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getUnusedUserCompanyFuhrparks(ContactId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/unusedfuhrapks`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId
    }
  });
}

export async function getUserCompanyFuhrparks(Pagination = { number: 100, start: 1 }, Sort = {}, Search = {}) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/getFuhrparkList`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Pagination,
      Sort,
      Search
    }
  });
}

export async function getExcelCompanyFuhrparks(
  ContactId,
  Pagination = { number: 100, start: 1 },
  Sort = {},
  Search = {}
) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/getexcelcompanylist`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: 'blob',
    data: {
      ContactId,
      Pagination,
      Sort,
      Search
    }
  });
}

export async function createFuhrpark(ContactId, Fuhrpark) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ContactId,
      Fuhrpark
    }
  });
}

export async function getDienstleistungByFuhrpark(FuhrparkId) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/GetDienstleistungByFuhrpark`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      FuhrparkId
    }
  });
}

export async function updateFuhrpark(Fuhrpark) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/api/fuhrpark`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Fuhrpark
    }
  });
}

export async function sendFuhrparkFiles(
  FuhrparkId,
  Pictures,
  PictureDescription,
  Leasingvertrages,
  LeasingvertrageDescription,
  Fahrzeugscheins,
  FahrzeugscheinDescription,
  CustomFiles,
  CustomDescription
) {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();
  data.append('FuhrparkId', FuhrparkId);
  Pictures.forEach(file => {
    data.append('Pictures', file);
  });
  data.append('PictureDescription', PictureDescription);
  Leasingvertrages.forEach(file => {
    data.append('Leasingvertrages', file);
  });
  data.append('LeasingvertrageDescription', LeasingvertrageDescription);
  Fahrzeugscheins.forEach(file => {
    data.append('Fahrzeugscheins', file);
  });
  data.append('FahrzeugscheinDescription', FahrzeugscheinDescription);
  CustomFiles.forEach(file => {
    data.append('CustomFiles', file);
  });
  CustomDescription.forEach(description => {
    data.append('CustomDescription', description);
  });

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/uploadfiles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export async function sendFile(id, files, documentType) {
  try {
    const token = await authService.acquireTokenAsync();
    const data = new FormData();

    data.append('FuhrparkId', id);
    files.forEach(file => {
      data.append('CustomFiles', file);
      data.append('CustomDescription', documentType);
    });

    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/fuhrpark/uploadfiles`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  } catch (error) {
    console.timeLog(error);
  }
}

export async function getLeaseendOverview(data) {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Fuhrpark/GetLeaseendOverview`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}

export const updateHauptauto = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/Fuhrpark/updateHauptauto`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};
