import authService from './auth-service';
import axios from 'axios';
import { crmDataTypes } from '../constants';
import { AnyObject } from 'common/interfaces';
import { CRM_VALUE } from 'pages/eingangsrechnung-page/utils';
import { DOC_MANAGEMENT_CRM_VALUE } from 'pages/documentmanager-page/utils';
import { IncomingInvoicesFormName } from 'pages/eingagnsrechnungs-ocr-page/form/form.interface';
import { InvoiceEnum } from 'pages/eingangsrechnung-page/invoice-upload/components/upload-invoice/types';
import { CrmDataTypes } from 'common/enums';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const uploadRechnungFiles = async (Files: File[]) => {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();

  Files.forEach(file => {
    data.append('files', file);
  });

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/CreateEingangsrechnung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const uploadDocumentManagerFiles = async (Files: File[]) => {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();

  Files.forEach(file => {
    data.append('Files', file);
  });

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/ocreingang`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getMarkUp = async (fileId: string, pageNumber?: number) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/GetRecognitionResult`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { fileId, pageNumber }
  });
};

export const getOcrMarkUp = async (id: string) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/ocreingang/fileRecognitionResult/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const recognizeFiles = async (eingangsrechnungid: string) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/RecognizeEingangsrechnung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: { id: eingangsrechnungid }
  });
};

export const recognizeOcrFiles = async (id: string) => {
  const token = await authService.acquireTokenAsync();
  const data = {
    id: id
  };
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/ocreingang/recognizeOcrEingang`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getOptionSetValues = async (entityName: string, field: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/crmentity/GetOptionSetValues`,
    params: {
      entityName: entityName,
      field: field
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

/**
 * Fetches Eingangsrechnungen table data
 * @param  {any} tableParams object contains filters, search, sort properties for table
 * @param  {any} sessionFilter session filter
 */
export const getEingangsrechnungen = async (tableParams: any, sessionFilter: any) => {
  const token = await authService.acquireTokenAsync();
  const createdon = sessionFilter.filterForServer[CRM_VALUE.createdon] && {
    start: sessionFilter.filterForServer[CRM_VALUE.createdon]?.split(' - ')[0],
    end: sessionFilter.filterForServer[CRM_VALUE.createdon]?.split(' - ')[1]
  };
  const rechnungDate = sessionFilter.filterForServer[CRM_VALUE.rechnungDate] && {
    start: sessionFilter.filterForServer[CRM_VALUE.rechnungDate]?.split(' - ')[0],
    end: sessionFilter.filterForServer[CRM_VALUE.rechnungDate]?.split(' - ')[1]
  };

  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/List`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Pagination: {
        Number: tableParams.pagination.number,
        Start: tableParams.pagination.start
      },
      Search: {
        [CRM_VALUE.status]: sessionFilter.filterForServer[CRM_VALUE.status],
        [CRM_VALUE.kreditor]: sessionFilter.filterForServer[CRM_VALUE.kreditor],
        [CRM_VALUE.name]: sessionFilter.filterForServer[CRM_VALUE.name],
        [CRM_VALUE.rechnungDate]: rechnungDate,
        ['rechnungNummer']: sessionFilter.filterForServer[CRM_VALUE.rechnungNummer],
        [CRM_VALUE.createdon]: createdon
      },
      Sort: {
        Predicate: tableParams.sort?.predicate,
        Reverse: tableParams.sort?.reverse
      }
    }
  });
};

export const getDocumentManagement = async (tableParams: any, sessionFilter: any) => {
  const token = await authService.acquireTokenAsync();
  const createdon = sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.createdon] && {
    start: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.createdon]?.split(' - ')[0],
    end: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.createdon]?.split(' - ')[1]
  };
  const modifiedon = sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.modifiedon] && {
    start: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.modifiedon]?.split(' - ')[0],
    end: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.modifiedon]?.split(' - ')[1]
  };
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/OcrEingang/list`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Pagination: {
        Number: tableParams.pagination.number,
        Start: tableParams.pagination.start
      },
      Search: {
        //DOC_MANAGEMENT_CRM_VALUE
        [DOC_MANAGEMENT_CRM_VALUE.modifiedon]: modifiedon,
        [DOC_MANAGEMENT_CRM_VALUE.status]: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.status],
        [DOC_MANAGEMENT_CRM_VALUE.documentType]: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.documentType],
        [DOC_MANAGEMENT_CRM_VALUE.name]: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.name],
        //['modifiedon']: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.modifiedon],
        [DOC_MANAGEMENT_CRM_VALUE.createdon]: createdon,
        [DOC_MANAGEMENT_CRM_VALUE.modifiedBy]: sessionFilter.filterForServer[DOC_MANAGEMENT_CRM_VALUE.modifiedBy]
      },
      Sort: {
        Predicate: tableParams.sort?.predicate,
        Reverse: tableParams.sort?.reverse
      }
    }
  });
};

/**
 * Sends filters request
 */
export const getEngangsrechnungFilters = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/Filters`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getDocumentManagerFilters = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/ocreingang/filters`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getDocumentmanagerData = async (id: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/OcrEingang/${id}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getNewDocType = async (id: string, docTypeId: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/OcrEingang/${id}?documenttypeid=${docTypeId}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const updateEngangsrechnungData = async (id: string | undefined, data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/UpdateEingangsrechnung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getDocumentManagementOptions = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/OcrEingang/prefillOptions`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const getDocumentTypes = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/ocreingang/documentTypes`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const createSplitbuchnung = async (incomingInvoiceId: string, data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/CreateSplitbuchung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      uds_rechnungsbezugid: {
        attributeTypeCode: CrmDataTypes.Lookup,
        attributeValue: { id: incomingInvoiceId }
      }
    }
  });
};

export const SumbitAccept = async (eingangsrechnungid: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/AcceptFreigabeInvoice`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      EingangsrechnungId: eingangsrechnungid
    }
  });
};

export const SubmitOcrComplain = async (id: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/OcrEingang/${id}/reject`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const SubmiSaveOcr = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'PUT',
    url: `${API_ENDPOINT}/api/ocreingang`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const FinishOrc = async (id: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/OcrEingang/${id}/finish`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const SubmitComplain = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/RejectFreigabeInvoice`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const duplicateCheck = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/CheckDuplicate`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};
export const checkSachkontoMapping = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/setupAssistant/checkSachkontoMapping`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};
export const updateSplitbuchnung = async (incomingInvoiceId: string, data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/UpdateSplitbuchung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...data,
      uds_rechnungsbezugid: {
        attributeTypeCode: CrmDataTypes.Lookup,
        attributeValue: { id: incomingInvoiceId }
      }
    }
  });
};

export const deleteSplitbuchnung = async (incomingInvoiceId: string, frameworkContractId: string) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/DeleteSplitbuchung`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_eingangsrechnungid: incomingInvoiceId,
      uds_rahmenvertrgeid: frameworkContractId
    }
  });
};

export const getFreigabeCounter = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/GetFreigabeCounter`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getOcrCounter = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/ocreingang/indicator`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
export const getKreditors = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/SearchKreditor`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data
  });
};

export const getinvoiceMailboxAddress = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/GetInvoiceMailboxAddress`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getDocumentManagementMailboxAddress = async () => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/ocreingang/ocrEingangMailboxAddress`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const formatFormValues = (data: AnyObject) => {
  const requestData: Partial<AnyObject> = {
    uds_rahmenvertrgeid: {
      attributeValue: data?.uds_rahmenvertrgeid,
      attributeTypeCode: crmDataTypes.Uniqueidentifier
    },

    uds_sachkonto_kunde: {
      attributeValue: data?.uds_sachkonto_kunde,
      attributeTypeCode: crmDataTypes.String
    },

    uds_fuhrparkid: {
      attributeValue: {
        id: data?.uds_fuhrparkid?.value,
        logicalName: 'new_fuhrpark',
        name: data?.uds_fuhrparkid?.label
      },
      attributeTypeCode: crmDataTypes.Lookup
    },

    uds_sachkontoid: {
      attributeValue: {
        id: data?.uds_sachkontoid?.value,
        logicalName: 'new_ledgeraccount',
        name: data?.uds_sachkontoid?.label
      },
      attributeTypeCode: crmDataTypes.Lookup
    },

    uds_mwst: {
      attributeValue: {
        id: data?.uds_mwst?.value,
        logicalName: 'msdyn_taxcode',
        name: data?.uds_mwst?.label
      },
      attributeTypeCode: crmDataTypes.Lookup
    },
    uds_dienstleistung_detail_info: {
      attributeValue: data.uds_dienstleistung_detail_info,
      attributeTypeCode: crmDataTypes.String
    },

    netto: {
      attributeValue: {
        value: +data.uds_netto
      },
      attributeTypeCode: crmDataTypes.Money
    },
    uds_betrag: {
      attributeValue: {
        value: +data.uds_betrag
      },
      attributeTypeCode: crmDataTypes.Money
    },
    uds_leistungdatum: {
      attributeValue: data.uds_leistungdatum,
      attributeTypeCode: crmDataTypes.DateTime
    },
    uds_kostenstelle: {
      attributeValue: data.uds_kostenstelle,
      attributeTypeCode: crmDataTypes.String
    }
  };

  return requestData;
};

export const prepareInvoiceFields = (data: AnyObject, invoiceType: InvoiceEnum) => {
  const requestData: Partial<AnyObject> = {
    uds_invoice_typecode: {
      attributeTypeCode: crmDataTypes.Picklist,
      attributeValue: { value: invoiceType }
    },
    uds_invoicenumber: {
      attributeValue: data.uds_name,
      attributeTypeCode: crmDataTypes.String
    },
    uds_eingangsrechnungid: {
      attributeValue: data?.id,
      attributeTypeCode: crmDataTypes.Uniqueidentifier
    },
    uds_invoicedate: {
      attributeValue: data?.uds_date,
      attributeTypeCode: crmDataTypes.DateTime
    },

    uds_bemerkungkunde: {
      attributeValue: data.uds_bemerkungkunde,
      attributeTypeCode: crmDataTypes.Memo
    },
    uds_statuskunde: {
      attributeValue: {
        value: data.uds_status,
        label: data.uds_status
      },
      attributeTypeCode: crmDataTypes.Picklist
    },
    uds_service_date: {
      attributeValue: data?.uds_service_date,
      attributeTypeCode: crmDataTypes.DateTime
    }
  };

  if (data[IncomingInvoicesFormName.CreditorLookup]) {
    requestData[IncomingInvoicesFormName.CreditorLookup] = {
      attributeValue: {
        id: data[IncomingInvoicesFormName.CreditorLookup],
        logicalName: 'account'
      },
      attributeTypeCode: crmDataTypes.Lookup
    };
  }

  if (data[IncomingInvoicesFormName.CreditorTextInput]) {
    requestData[IncomingInvoicesFormName.CreditorTextInput] = {
      attributeValue: data[IncomingInvoicesFormName.CreditorTextInput],
      attributeTypeCode: crmDataTypes.String
    };
  }

  if (data[IncomingInvoicesFormName.InquiryNumber]) {
    requestData[IncomingInvoicesFormName.InquiryNumber] = {
      attributeValue: {
        id: data[IncomingInvoicesFormName.InquiryNumber],
        logicalName: 'incident'
      },
      attributeTypeCode: crmDataTypes.Lookup
    };
  }

  return requestData;
};

export const updateSachkontoSada = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return await axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/eingangsrechnung/UpdateSachkontoMapping`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const prepareSachontoFields = (data: AnyObject, applyForChild: boolean, accountId: string) => {
  const formattedData = {
    applyForChildCompanies: applyForChild,
    mapping: data.map((record: any) => ({
      new_ledgeraccountid: record.new_ledgeraccountid,
      kundensachkonto: {
        attributeValue: getFormFieldData(record.field, 'kundensachkonto').value,
        attributeTypeCode: record.kundensachkonto.attributeTypeCode
      }
    })),
    accountId
  };
  return formattedData;
};

const getFormFieldData = (data: any, name: string) => {
  const formData = data.find((form: any[]) => {
    return form.some((item: { name: string }) => {
      return item.name === name;
    });
  });
  const field = formData.find((item: { name: string }) => {
    return item.name === name;
  });
  return field;
};
