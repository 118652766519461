import React from 'react';
import { ValueOf } from 'common/interfaces';
import { AlertProps } from 'components/alert/alert-view';
import { setPoolCalendarEventTooltip, toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';
import { useDispatch } from 'react-redux';
import EditBooking from 'components/pool-car-booking/edit-booking';
import { Booking } from '../edit-booking/edit-booking.types';

const usePoolCarBookingModals = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openEditingModal = (booking: Booking) => {
    const alertData: ValueOf<Pick<AlertProps, 'parameters'>> = {
      title: 'Buchung bearbeiten',
      children: <EditBooking booking={booking} closeAlert={closeAlert} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ],
      allButtonsHidden: true
    };
    dispatch(setPoolCalendarEventTooltip(null));
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openEditingModal };
};

export default usePoolCarBookingModals;
