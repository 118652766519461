import React, { useContext, useMemo } from 'react';
import { StyledSelect } from '../../form-field.styled';
import { MenuItem } from '@mui/material';
import { PrefixForId } from 'common/enums';
import { StyledDropDownIcon } from 'components/icons/drop-down-icon';
import { OptionSetProps } from './option-set.props';
import { Text } from 'common/common-components.styled';
import { formFieldProvider } from '../../form-field';

export const OptionSet = ({ name, value, ...selectProps }: OptionSetProps) => {
  const { field } = useContext(formFieldProvider);
  const { options = [] } = field;

  const displayedValue = useMemo(() => options.find(optionItem => optionItem.value === value)?.label, [value]);

  return (
    <StyledSelect
      id={name}
      displayEmpty
      IconComponent={StyledDropDownIcon}
      renderValue={() => (
        <Text as='span' color={displayedValue ? 'black' : 'grey250'}>
          {displayedValue || 'Bitte auswählen'}
        </Text>
      )}
      {...selectProps}
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          id={`${name}-${PrefixForId.FormSelect}-option`}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
