import React, { useContext, useMemo } from 'react';
import { toggleModal } from 'actions/app_action';
import { OrderVehicleReport } from 'components/aufgaben-component/components/order-vehicle-report/order-vehicle-report';
import { MODAL_NAMES, MODALS } from '../../../../../../../../constants';
import { useDispatch } from 'react-redux';
import { VehicleInitialReviewPhases } from 'components/aufgaben-component/task.prop';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';

export const useVehicleInitialReviewButtons = () => {
  const dispatch = useDispatch();
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const { vir } = car;
  const phase = vir?.vir_phaseinfo.phaseNo;
  const phaseInfo = vir?.vir_phaseinfo;
  const fleetId = car.new_fuhrparkid?.attributeValue;
  const taskId = car.checklist?.activityid?.attributeValue;

  const openVehicleReviewModal = () => {
    dispatch(toggleModal(MODALS.alert, null));

    const alertData = {
      title: 'Kfz-Gutachten beauftragen',
      children: (
        <OrderVehicleReport
          phaseInfo={phaseInfo?.phaseInfo}
          fuhrparkId={fleetId}
          actionName='FormFilled'
          taskId={taskId}
          closeAlert={() => dispatch(toggleModal(MODALS.alert, null))}
          callback={refresh}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: () => dispatch(toggleModal(MODALS.alert, null)),
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  const button = useMemo(() => {
    const buttonProps = {
      title: 'Gutachten buchen',
      action: openVehicleReviewModal,
      id: 'bookAppraisalButtton',
      disabled: false
    };

    switch (phase) {
      case VehicleInitialReviewPhases.Phase0:
      case VehicleInitialReviewPhases.Phase1:
        return buttonProps;

      case VehicleInitialReviewPhases.Phase4:
        return {
          ...buttonProps,
          disabled: true
        };
      default:
        null;
    }
  }, [phase]);

  return { button };
};
