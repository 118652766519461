/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import { carNumberValidation } from '../utils';
import { ComponentsProps } from './components.props';

const TextFieldComponent = ({
  reactHookFormData,
  registerOptions = {},
  fieldRegister,
  fieldName,
  fieldPlaceholder = 'Text',
  optional = false,
  optionalText = '(optional)',
  doubleField = false,
  halfField = false,
  carNumber = false,
  smallField = false,
  readOnly = false,
  upperCase = false
}: ComponentsProps) => {
  const [textValue, setTextValue] = useState('');
  const {
    register,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = reactHookFormData;

  const changeValue = (value: string) => {
    upperCase ? setTextValue(value.toUpperCase()) : setTextValue(value);
    upperCase ? setValue(fieldRegister, value.toUpperCase()) : setValue(fieldRegister, value);
  };

  useEffect(() => {
    if (!!carNumber) {
      if (
        !!watch(fieldRegister) &&
        watch(fieldRegister).search(carNumberValidation) === -1 &&
        watch(fieldRegister).length > 6
      ) {
        setError(fieldRegister, {
          type: 'manual',
          message: 'Bitte gib das Kennzeichen im richtigen Format ein (Beispiel: BO-CC 4500H).'
        });
      }
      if (textValue === '' || (!!watch(fieldRegister) && watch(fieldRegister).search(carNumberValidation) === 0)) {
        clearErrors(fieldRegister);
      }
    }
  }, [textValue, watch(fieldRegister)]);

  useEffect(() => {
    if (!carNumber && !!watch(fieldRegister) && watch(fieldRegister).length > 0) {
      clearErrors(fieldRegister);
    }
  }, [watch(fieldRegister)]);

  return (
    <div
      className={
        doubleField
          ? styles.double_field
          : halfField
          ? styles.half_input
          : smallField
          ? styles.one_field
          : styles.field_container
      }
    >
      <div className={readOnly ? styles.form_name_text_block : styles.form_name_text}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>{optionalText}</span>}
        </span>
      </div>
      <input
        placeholder={fieldPlaceholder}
        {...register(fieldRegister, {
          required: optional ? false : `${fieldName} erforderlich.`,
          maxLength: 100,
          ...registerOptions
        })}
        value={!!watch(fieldRegister) ? watch(fieldRegister) : textValue}
        onChange={e => changeValue(e.target.value)}
        type='text'
        maxLength={100}
        readOnly={readOnly}
        autoComplete='nope'
        className={
          errors[fieldRegister]
            ? styles.input__icons_err__text
            : readOnly
            ? styles.input_standart_block
            : styles.input_standart
        }
        id={`input${fieldName}`}
      />
      <div className={styles.err__row}>
        {errors[fieldRegister]?.message && typeof errors[fieldRegister]?.message === 'string' && (
          <>
            <img src='/assets/images/err-icon.svg' />
            <p>{String(errors[fieldRegister]?.message)}</p>
          </>
        )}
      </div>

      <Distance top='10' />
    </div>
  );
};

export default TextFieldComponent;
