import { AxiosRequestConfig } from 'axios';
import { FleetLeaseEndSetAction } from './fleet-lease-end.types';

class FleetLeaseEndRequestConfig {
  setVirAction = ({
    actionName,
    fuhrparkId,
    taskId,
    address,
    name,
    remarks,
    phone
  }: FleetLeaseEndSetAction): AxiosRequestConfig => ({
    method: 'post',
    url: `FuhrparkLeaseEnd/SetVirAction`,
    data: {
      ActionName: actionName,
      FuhrparkId: fuhrparkId,
      TaskId: taskId,
      Name: name || '',
      Phone: phone || '',
      Address: address || '',
      Remarks: remarks
    }
  });
}

export const fleetLeaseEndRequestConfig = new FleetLeaseEndRequestConfig();
