import React from 'react';
import classes from './info-item.module.scss';

export const EmptyItem = () => {
  return (
    <div className={classes.empty_container}>
      <span className={classes.empty_title}>Bitte haben Sie noch etwas Geduld.</span>
      <span className={classes.empty_description}>
        Sobald unsere Partnerwerkstatt die Auftragsannahme bestätigt hat,
        <br />
        werden Ihnen die Kontaktdaten angezeigt.
      </span>
    </div>
  );
};
