import React, { useState, useEffect } from 'react';
import { PdfContainer } from './pdf-viewer.styled';
import { AnyObject } from 'common/interfaces';
const pdfjsLib = require('pdfjs-dist/build/pdf');
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');

interface Props {
  file: Blob;
  scale?: number;
}

export interface PDF {
  getPage: (pageNum: number) => Promise<AnyObject>;
  _transport: {
    _numPages: number;
  };
}

const PdfViewer = ({ file, scale = 1.5 }: Props) => {
  const [pdfRef, setPdfRef] = useState<PDF | null>(null);
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const objectUrl = URL.createObjectURL(file);

  useEffect(() => {
    const loadingTask = pdfjsLib.getDocument(objectUrl);
    loadingTask.promise.then(
      (loadedPdf: PDF) => {
        setPdfRef(loadedPdf);
      },
      (reason: string) => {
        console.error(reason);
      }
    );
  }, [file]);

  const removeAllCanvas = () => {
    const canvasList = document.querySelectorAll('#pdf-viever-container canvas');

    canvasList.forEach(canvas => {
      canvas.remove();
    });
  };

  const renderPages = async () => {
    const renderPage = async (pageNum: number) => {
      if (pdfRef) {
        const page = await pdfRef.getPage(pageNum);
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', String(pageNum));
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
        page.render(renderContext);
        return canvas;
      }
    };

    //@ts-ignore
    const pagesAmout = pdfRef?._transport?._numPages || 1;
    const promiseArr = [];

    for (let i = 1; i <= pagesAmout; i++) {
      promiseArr.push(renderPage(i));
    }

    removeAllCanvas();

    const pdfContainer = document?.getElementById('pdf-viever-container');

    if (pdfContainer) {
      Promise.all(promiseArr).then(result => {
        result.forEach(canvas => {
          if (canvas) {
            pdfContainer.appendChild(canvas);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (!pdfRef) {
      return;
    }

    renderPages();
  }, [pdfRef, scale]);

  return <PdfContainer id='pdf-viever-container' />;
};

export default PdfViewer;
