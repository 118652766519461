import axios, { AxiosResponse } from 'axios';
import { AnyObject } from 'chart.js/dist/types/basic';
import authService from './auth-service';
import { CrmResponse, ResponseModel } from 'common/interfaces';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

interface DamageCarListResponseItem {
  new_fuhrparkid: CrmResponse;
  new_name: CrmResponse;
  new_hersteller: CrmResponse;
  new_model: CrmResponse;
}

export const getSchadenmeldenCar = async (kennzeichen: string) => {
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/schaden/getCars`,
    headers: {},
    data: {
      kennzeichen: kennzeichen
    }
  });
};

export const getDamageCarList = async (
  carName: string
): Promise<AxiosResponse<ResponseModel<{ fuhrparks: Array<DamageCarListResponseItem> }>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/schaden/getCarList`,
    headers: { Authorization: `Bearer ${token}` },
    data: { kennzeichen: carName }
  });
};

export const getVersicherungFirms = async () => {
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/schaden/getVersicherungFirms`,
    headers: {},
    data: {}
  });
};

export const createDamageCase = async (data: AnyObject, authorised: boolean = true) => {
  let token = null;
  if (authorised) {
    token = await authService.acquireTokenAsync();
  }

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/schaden/createSchadenAufnahme`,
    headers: authorised
      ? {
          Authorization: `Bearer ${token}`
        }
      : {},
    data
  });
};

export const uploadFilesOfDamage = async (
  files: File[],
  schadenAufnahmeId: string,
  documentTypeId: string
): Promise<AxiosResponse<ResponseModel> | undefined> => {
  try {
    const data = new FormData();

    files.forEach(file => {
      data.append('files', file, file.name);
    });

    data.append('schadenAufnahmeId', schadenAufnahmeId);
    data.append('documentTypeId', documentTypeId);

    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/schaden/uploadFiles`,
      data
    });
  } catch (error) {
    console.log(error);
  }
};

export const finishCreateDamageCase = async (id: string): Promise<AxiosResponse<ResponseModel> | undefined> => {
  try {
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/schaden/finishCreateSchadenAufnahmne`,
      data: {
        schadenAufnahmeId: id
      }
    });
  } catch (error) {
    console.log(error);
  }
};
