import { CostCenterDataItem } from '../cost-centers-settings.props';

export interface EditCostCenterProps {
  costCenterItem: CostCenterDataItem;
  refetchCostCenters: () => void;
}

export enum EditCostCenterFieldNames {
  Name = 'new_name',
  Company = 'new_sparteid'
}
