import React from 'react';
import { UnableToDeactivateProps } from './unable-to-deactivate.types';
import { Flex, Text } from 'common/common-components.styled';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import IconHover from 'components/icon-hover';
import { QuestionIcon } from 'components/icons-new/question';
import InfoHint from 'components/info-hint/info-hint-view';
import { InfoHintType } from 'components/info-hint/info-hint.props';

export const isShowInfoHint = (lastRequestDate?: string | null) => {
  if (!lastRequestDate) {
    return false;
  }

  return moment().diff(moment(lastRequestDate), 'days') < 3;
};

const UnableToDeactivate = ({ configuration }: UnableToDeactivateProps) => {
  if (!configuration) {
    return null;
  }

  return (
    <>
      {!configuration.canBeArchived && (
        <Flex top='20'>
          <Text color='blue' right='10'>
            Warum kann ich diese Konfiguration nicht selbst archivieren?
          </Text>
          <Tooltip
            title={
              <span>
                Es können nur Konfigurationen archiviert werden, die noch nicht bearbeitet wurden. Falls die gewünschte
                Konfiguration nicht zur Archivierung verfügbar sein sollte, klicke den Button{' '}
                <b>Archivierung anfragen</b>. In diesem Fall wird ein Mobexo-Mitarbeiter deine Anfrage prüfen.
              </span>
            }
          >
            <div>
              <IconHover>
                <QuestionIcon />
              </IconHover>
            </div>
          </Tooltip>
        </Flex>
      )}

      {isShowInfoHint(configuration?.uds_remove_request_date?.value) && (
        <Flex top='20'>
          <InfoHint type={InfoHintType.Info}>
            {`Die Archivierung wurde bereits am ${moment(configuration?.uds_remove_request_date?.value).format(
              'DD. MMMM YYYY'
            )} beim Support angefragt.`}
          </InfoHint>
        </Flex>
      )}
    </>
  );
};

export default UnableToDeactivate;
