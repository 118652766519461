import React from 'react';
import { WidgetButtonContainer } from './widget-button.styled';
import { WidgetButtonProps } from './widget-button.types';
import { Flex, Text } from 'common/common-components.styled';
import ThinArrowRight from 'components/icons-new/thin-arrow-right';
import { useTheme } from 'styled-components';

const WidgetButton: React.FC<WidgetButtonProps> = ({ title, disabled, handleClick }) => {
  const theme = useTheme();

  return (
    <WidgetButtonContainer disabled={disabled} onClick={handleClick}>
      <Text color='blue' bold>
        {title}
      </Text>
      <Flex bottom='2'>
        <ThinArrowRight color={theme.colors.blue} />
      </Flex>
    </WidgetButtonContainer>
  );
};

export default WidgetButton;
