import { PAGES, ReactQueryKeys } from 'common/enums';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { documentTypeRequestConfig } from 'request-config/document-type/document-type.request-config';
import { DocumentTypeByAppQueryOptions, GetDocumentTypeByApPageResponse } from './document-type-by-app.props';

export const useDocumentTypeByAppQuery = (
  page: PAGES,
  options: DocumentTypeByAppQueryOptions = {},
  enabled: boolean = true
) => {
  const { fetch } = useFetch({
    loadingKey: 'documentTypeByApp',
    ...options.fetch,
    ...documentTypeRequestConfig.getDocumentTypeByApPage(page)
  });

  const { data, ...restQueryProps } = useQuery([ReactQueryKeys.DocumentTypeByApp, page], () => fetch(), {
    enabled: !!page && enabled,
    ...options.options
  });

  return { documentTypeByAppQuery: data?.data.data || defaultQuery, ...restQueryProps };
};

const defaultQuery: GetDocumentTypeByApPageResponse = {
  documentTypes: []
};
