import { Flex, Text } from 'common/common-components.styled';
import InfoHint from 'components/info-hint';
import React from 'react';
import { InactiveCarModalContentProps } from './use-inactive-car.props';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'common/enums';

export const InactiveCarModalContent = ({ carId, closeAlert }: InactiveCarModalContentProps) => {
  const history = useHistory();

  const openCarDetailPage = () => {
    closeAlert();
    history.push({ pathname: PAGES.MY_VEHICLES_DETAILS, search: `?id=${carId}` });
  };

  return (
    <Flex direction='column'>
      <Text>Bitte beachte, dass die Reparatur dem inaktiven Fahrzeug zugewiesen wird.</Text>
      <Text margin='20px 0'>Willst du trotzdem weitermachen?</Text>

      <InfoHint type='info'>
        <Flex>
          Den Status des Fahrzeugs kannst du auf der
          <Text onClick={openCarDetailPage} margin='0 5px' underline pointer>
            Fahrzeuge
          </Text>
          Seite ändern.
        </Flex>
      </InfoHint>
    </Flex>
  );
};
