import { connect } from 'react-redux';
import { setForm, toggleModal, setEndLoading, setStartLoading } from '../../../actions/app_action';
import { MODALS, MODAL_NAMES } from '../../../constants';
import Autocomplete from './autocomplete-view';

const mapStateToProps = state => ({
  forms: state.app.forms,
  user: state.app.user
});

const mapDispatchToProps = dispatch => ({
  openAlert(data) {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, data));
  },
  closeAlert() {
    dispatch(toggleModal(MODALS.alert, null));
  },
  hideOverlay() {
    dispatch(setEndLoading());
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  setForm(form) {
    dispatch(setForm(form));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);
