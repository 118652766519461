import React from 'react';

const ReparaturIcon = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='60' height='60' fill='white' />
      <path
        d='M49.5962 33.6094V27.1892C49.5962 26.3005 49.4329 25.43 49.1246 24.6138L48.4173 22.7458H51.7725C52.244 22.7458 52.5886 22.3106 52.4798 21.8571L51.9357 19.5176C51.7906 18.8647 51.1921 18.3931 50.5211 18.3931H46.9664L44.1734 12.8253C43.4298 11.3563 41.9245 10.4132 40.2741 10.4132H19.7259C18.0755 10.4132 16.5702 11.3382 15.8266 12.8253L13.0336 18.3931H9.47891C8.80787 18.3931 8.2275 18.8647 8.06428 19.5176L7.52019 21.8571C7.41137 22.3106 7.75597 22.7458 8.22751 22.7458H11.5827L10.8754 24.6138C10.5671 25.43 10.4038 26.3186 10.4038 27.1892V33.6094'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7754 29.2756H20.8148L19.781 27.2262'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45.2444 29.2756H39.2051L40.2389 27.2262'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.2051 34.3529H35.8122'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6758 21.2949H42.341'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.1816 45.9596H40.8362'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.1621 40.1562H40.8167'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.4894 45.2341C18.6007 47.7732 16.1705 49.5868 13.3231 49.5868C10.7659 49.5868 8.24498 48.1359 7.51953 45.9596H12.5977C13.1599 45.3973 13.4863 45.0709 14.0486 44.5087V41.6069C13.4863 41.0447 13.1599 40.7182 12.5977 40.156H7.51953C8.6077 38.0522 10.8022 36.5287 13.3231 36.5287C16.1705 36.5287 18.5826 38.3424 19.4713 40.8814'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.5273 40.8812C41.416 38.3422 43.8463 36.5286 46.6936 36.5286C49.2146 36.5286 51.4091 38.052 52.4972 40.1558H48.1445C47.5823 40.718 47.2559 41.0445 46.6936 41.6067V44.5085C47.2559 45.0707 47.5823 45.3972 48.1445 45.9594H52.4972C51.7718 48.1357 49.2146 49.5866 46.6936 49.5866C43.8463 49.5866 41.4341 47.773 40.5455 45.2339'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReparaturIcon;
