import React from 'react';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { Text } from 'common/common-components.styled';
import { statusColorMapping } from './utils';
import useWindowSize from 'hooks/use-window-size';

export const VehicleDetailCarSectionStatus = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isMobile } = useWindowSize(500);

  const status = fuhrpark?.portalCarStatus;

  if (!status) {
    return null;
  }

  return (
    <Text color={statusColorMapping[status]} size={isMobile ? '14' : '16'}>
      {status}
    </Text>
  );
};
