import {
  ApproversInstanceValues,
  SetApproversAutomaticallyValues
} from 'pages/company-settings-page/company-settings-component/approval-rules/approval-rules.types';

export const OPTION_SET = {
  new_kauf_leasing: [
    { value: 100_000_005, label: 'Miete' },
    { value: 100_000_004, label: 'Finanzierung' },
    { value: 100_000_000, label: 'Eigentum' },
    { value: 100_000_007, label: 'Sale & Lease back' },
    { value: 100_000_001, label: 'Leasing' },
    { value: 100_000_002, label: 'Langzeitmiete' }
  ],
  uds_enginetypecode: [
    { value: '1', label: 'Benzin' },
    { value: '2', label: 'Diesel' },
    { value: '100000003', label: 'Hybrid' },
    { value: '100000007', label: 'Hybrid/Diesel' },
    { value: '100000001', label: 'Elektro' },
    { value: '100000009', label: 'Wasserstoff' },
    { value: '3', label: 'Erdgas (CNG)' },
    { value: '100000010', label: 'Flüssiggas (LNG)' },
    { value: '100000000', label: 'Kein Motor' },
    { value: '100000011', label: 'Andere' },
    { value: '100000004', label: 'Benzin/Erdgas' },
    { value: '100000002', label: 'unbekannt' },
    { value: '100000006', label: 'Plugin-Hybrid' }
  ],
  uds_emissioncategorycode: [
    { value: '100000000', label: 'Euro4' },
    { value: '100000001', label: 'Euro5' },
    { value: '100000002', label: 'Euro6' },
    { value: '100000003', label: 'Euro6b' },
    { value: '100000004', label: 'Euro6c' },
    { value: '100000005', label: 'Euro6d' },
    { value: '100000006', label: 'Euro6d-TEMP' }
  ],
  uds_co2_efficiencycode: [
    { value: '100000000', label: 'CO2-Effizienz a_plus' },
    { value: '100000001', label: 'CO2-Effizienz a' },
    { value: '100000002', label: 'CO2-Effizienz b' },
    { value: '100000003', label: 'CO2-Effizienz c' },
    { value: '100000004', label: 'CO2-Effizienz d' },
    { value: '100000005', label: 'CO2-Effizienz e' },
    { value: '100000006', label: 'CO2-Effizienz f' },
    { value: '100000007', label: 'CO2-Effizienz g' },
    { value: '100000008', label: 'A' },
    { value: '100000009', label: 'A_PLUS' },
    { value: '100000010', label: 'A+' },
    { value: '100000011', label: 'A+++' },
    { value: '100000012', label: 'AA' },
    { value: '100000013', label: 'Aplus' },
    { value: '100000014', label: 'B' },
    { value: '100000015', label: 'C' },
    { value: '100000016', label: 'D' },
    { value: '100000017', label: 'E' },
    { value: '100000019', label: 'G' },
    { value: '100000018', label: 'N/A' }
  ],
  uds_einbau_eigentuemer_code: [
    { value: '752560000', label: 'Kunde' },
    { value: '752560001', label: 'Leasing' }
  ],
  uds_einbau_vorhanden_code: [
    { value: '752560000', label: 'Ja' },
    { value: '752560001', label: 'Nein' }
  ],
  new_zuordnung: [
    { value: '100000000', label: 'Poolfahrzeug' },
    { value: '100000001', label: 'Frei' },
    { value: '100000002', label: 'Mitarbeiter zugeordnetes Fahrzeug' }
  ],
  uds_order_approve_type_value: [
    { value: SetApproversAutomaticallyValues.Automatic, label: 'Automatisch' },
    { value: SetApproversAutomaticallyValues.Prefilled, label: 'Vorausgefüllt' },
    { value: SetApproversAutomaticallyValues.Manual, label: 'Manuell' }
  ],
  uds_order1_approver_type_value: [
    { value: ApproversInstanceValues.Empty, label: '---' },
    { value: ApproversInstanceValues.Driver, label: 'Fahrer' },
    { value: ApproversInstanceValues.Supervisor, label: 'Vorgesetzter' },
    { value: ApproversInstanceValues.OrderReleaser, label: 'Bestellfreigeber' },
    { value: ApproversInstanceValues.Contact, label: 'Kontakt' }
  ]
};
