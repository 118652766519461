import React from 'react';
import { Flex } from 'common/common-components.styled';
import { DiagramWithBottomSegmentsContainer } from 'components/containers/diagram/bottom-segments/bottom-segments';
import { CurrentKilometerReading } from '../../../current-kilometer-reading/current-kilometer-reading';
import { KilometerDiagram } from '../../../diagram/diagram';
import { InfoHintLeasingContractAdvise } from '../../../info-hint/leasing-contract-advise/leasing-contract-advise';
import { useKilometerWidgetDiagramData } from '../../../../hooks/use-diagram-data';
import useWindowSize from 'hooks/use-window-size';

export const AllDataIsAvailable = () => {
  const { segments } = useKilometerWidgetDiagramData();
  const { windowWidth } = useWindowSize(500);
  const isMobile = windowWidth <= 550;

  return (
    <DiagramWithBottomSegmentsContainer segments={segments} segmentContainerFlex={{ top: '0' }}>
      <KilometerDiagram />

      <Flex direction='column' justify='center' flex={isMobile ? 1.5 : 2} left={isMobile ? '15' : '30'} bottom='20'>
        <CurrentKilometerReading />
        <InfoHintLeasingContractAdvise />
      </Flex>
    </DiagramWithBottomSegmentsContainer>
  );
};
