import { CSSProperties, Component, ReactElement } from 'react';
import { ValueOf } from 'common/interfaces';

export enum ScrollDirection {
  Forward = 'forward',
  Backward = 'backward'
}

export enum AlignScrollTo {
  Auto = 'auto',
  Smart = 'smart',
  Center = 'center',
  End = 'end',
  Start = 'start'
}

export interface FlatListElement extends Component {
  scrollTo(scrollOffset: number): void;
  scrollToItem(index: number, align?: AlignScrollTo): void;
}

export interface IFlatList {
  data: any[];
  renderItem: ReactElement;
  itemSize?: number;
  height: number;
  width?: ValueOf<Pick<CSSProperties, 'width'>>;
  hideScrollBar?: boolean;
  onScroll?: (props: IOnScrollParams) => any;
}

export interface IFlatListItem {
  data?: ValueOf<Pick<IFlatList, 'data'>>;
  row?: ValueOf<Pick<IFlatList, 'data'>>[number];
  index?: number;
  style?: CSSProperties;
  isScrolling?: boolean;
}

export interface IOnScrollParams {
  scrollDirection: ScrollDirection;
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
}
