import React from 'react';
import { Distance, Flex, Text } from 'common/common-components.styled';
import useWindowSize from 'hooks/use-window-size';

const ConfirmationAlert = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Flex direction='column' align='center'>
      <Text color='grey800' margin='10px 0 50px' size='14' textAlign='center'>
        Nachdem du die Schadenart geändert hast, werden alle zuvor
        {windowWidth > 900 && <br />}
        eingegebenen Daten ab Schritt 3 gelöscht. Willst du trotzdem die
        {windowWidth > 900 && <br />}
        Art des Schadens ändern?
      </Text>
      <Distance top='-40' />
    </Flex>
  );
};

export default ConfirmationAlert;
