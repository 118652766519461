import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const ShineIcon = ({ width = 24, height = 24, color: colorProps, fill: fillProps, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProps || defaultColor;
  const fill = defaultFill || fillProps;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.72563 5.00791C5.42259 5.31095 4.99224 5.55976 4.62801 5.73612C4.38248 5.855 4.36581 6.2454 4.60421 6.378C4.99545 6.59562 5.46934 6.8932 5.78053 7.20439C6.10203 7.52589 6.40899 8.02101 6.62826 8.41928C6.75694 8.653 7.13036 8.64364 7.25129 8.40582C7.44245 8.02989 7.71333 7.57206 8.02676 7.25863C8.36878 6.91661 8.92988 6.57812 9.36398 6.34349C9.59543 6.21838 9.58913 5.85701 9.35473 5.73753C8.94185 5.52709 8.41962 5.22357 8.09049 4.89444C7.7651 4.56905 7.46474 4.05493 7.25461 3.64432C7.13369 3.40806 6.76679 3.40435 6.64377 3.63952C6.41032 4.08578 6.07221 4.66133 5.72563 5.00791Z'
        fill='white'
        stroke={color}
      />
      <path
        d='M16.8389 16.9458C16.5359 17.2488 16.1055 17.4977 15.7413 17.674C15.4958 17.7929 15.4791 18.1833 15.7175 18.3159C16.1087 18.5335 16.5826 18.8311 16.8938 19.1423C17.2153 19.4638 17.5223 19.9589 17.7415 20.3572C17.8702 20.5909 18.2436 20.5815 18.3646 20.3437C18.5557 19.9678 18.8266 19.51 19.14 19.1965C19.4821 18.8545 20.0432 18.516 20.4773 18.2814C20.7087 18.1563 20.7024 17.7949 20.468 17.6754C20.0551 17.465 19.5329 17.1615 19.2038 16.8323C18.8784 16.5069 18.578 15.9928 18.3679 15.5822C18.247 15.346 17.8801 15.3422 17.757 15.5774C17.5236 16.0237 17.1855 16.5992 16.8389 16.9458Z'
        fill='white'
        stroke={color}
      />
      <path
        d='M14.4184 6.42927C13.8497 6.99797 12.987 7.44144 12.38 7.7079C12.1302 7.81755 12.1127 8.20126 12.3543 8.32788C12.9923 8.66217 13.9285 9.2055 14.5021 9.77918C15.0875 10.3645 15.6413 11.3273 15.9736 11.9656C16.0968 12.2022 16.465 12.1933 16.5787 11.9519C16.8667 11.3401 17.3498 10.44 17.9279 9.8619C18.5397 9.25013 19.611 8.64578 20.2905 8.29565C20.5244 8.17513 20.5181 7.81795 20.2809 7.70412C19.6265 7.39009 18.6194 6.85053 18.0251 6.25622C17.4351 5.66622 16.8991 4.66934 16.5841 4.01466C16.469 3.7755 16.1066 3.77186 15.9884 4.0095C15.6407 4.70862 15.0385 5.80922 14.4184 6.42927Z'
        fill={hovered ? fill : 'white'}
        stroke={color}
      />
      <path
        d='M6.82785 13.0334C6.08355 13.7777 4.9267 14.3463 4.18436 14.6612C3.93322 14.7677 3.91676 15.1486 4.15943 15.2733C4.93445 15.6713 6.18365 16.3756 6.93005 17.122C7.68935 17.8813 8.4051 19.1609 8.79913 19.9323C8.92049 20.1699 9.28646 20.1601 9.39764 19.9175C9.73823 19.1746 10.3588 17.9753 11.1112 17.2229C11.9002 16.434 13.3154 15.6551 14.1309 15.2417C14.3656 15.1228 14.3598 14.7672 14.1217 14.6552C13.3334 14.2848 11.9986 13.591 11.2298 12.8222C10.4656 12.058 9.77554 10.7345 9.40344 9.94449C9.29035 9.70439 8.92975 9.70139 8.81321 9.93984C8.40305 10.7791 7.62755 12.2337 6.82785 13.0334Z'
        fill={hovered ? fill : 'white'}
        stroke={color}
      />
    </svg>
  );
};
