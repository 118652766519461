import React from 'react';
import { HistorySectionItemProps } from './section-item.props';
import { Flex, Text } from 'common/common-components.styled';

export const HistorySectionItem = ({ title, children }: HistorySectionItemProps) => {
  return (
    <Flex direction='column'>
      {title && (
        <Text size='18' color='blue' bold bottom='30'>
          {title}
        </Text>
      )}

      {children}
    </Flex>
  );
};
