/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react';
import styles from './detail-car-info.module.scss';
import { Distance } from 'common/common-components.styled';
import PhoneFieldComponent from '../components/phone-field-component';
import TextFieldComponent from '../components/text-field-component';
import EmailFieldComponent from '../components/email-field-component';
import { CSSTransition } from 'react-transition-group';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import { UseFormProps } from '../report-damage.props';

const AnotherPersonComponent = ({ reactHookFormData }: UseFormProps) => {
  const { watch, setValue } = reactHookFormData;
  const fahrerData = watch('fahrerInfo');
  const fuhrparkData = watch('fuhrparkInfo');
  const [showAnderePersonBlock, setShowAnderePersonBlock] = useState<boolean>(false);
  const [showNewContactPerson, setShowNewContactPerson] = useState<boolean>(false);

  useEffect(() => {
    (watch('fuhrparkInfo') === null && watch('fahrerInfo') === null) || !!watch('schadenAlsAnderePerson')
      ? setShowAnderePersonBlock(true)
      : setShowAnderePersonBlock(false);
  }, [watch('fuhrparkInfo'), watch('fahrerInfo'), watch('schadenAlsAnderePerson')]);

  useEffect(() => {
    setShowNewContactPerson(watch('isContactPerson') === false);
  }, [watch('isContactPerson')]);

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <CSSTransition in={showAnderePersonBlock} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
        <div className={styles.data_fields}>
          {fuhrparkData !== null && fahrerData === null && (
            <>
              <h6>Deine Kontaktdaten:</h6>

              <TextFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerName'
                fieldName='Name'
                fieldPlaceholder='Max Mustermann'
              />
              <Distance top='10' />

              <EmailFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerEmail'
                fieldName='E-mail Adresse'
                fieldPlaceholder='muster@mail.com'
              />
              <Distance top='10' />

              <EmailFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerEmailRepeat'
                fieldRegisterToCheck='anotherPersonFahrerEmail'
                fieldName='E-mail Adresse wiederholen'
                fieldPlaceholder='muster@mail.com'
              />
              <Distance top='10' />

              <PhoneFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerTelefon'
                fieldName='Wie können wir dich am besten telefonisch erreichen?'
              />
              <Distance top='10' />

              {watch('showSecondFahrerAnother') && (
                <>
                  <Distance top='5' />
                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anotherPersonFahrerTelefon2'
                    fieldName='Weitere Telefonnummer'
                    optional={!watch('showSecondFahrerAnother')}
                    close={true}
                    closeValue='showSecondFahrerAnother'
                  />
                </>
              )}
              <Distance top='-5' />

              <div
                id='secondDriverButton'
                onClick={() => setValue('showSecondFahrerAnother', true)}
                className={watch('showSecondFahrerAnother') ? styles.hide__block : styles.hide__block__show}
              >
                + Weitere Telefonnummer
              </div>
            </>
          )}

          {fuhrparkData !== null && fahrerData === null && <Distance top='30' />}

          {((fuhrparkData === null && fahrerData === null) || watch('schadenAlsAnderePerson') === true) && (
            <>
              {fuhrparkData === null && fahrerData === null && (
                <>
                  <h6>Du meldest den Schaden als:</h6>
                </>
              )}

              <TextFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerName'
                fieldName='Name'
                fieldPlaceholder='Max Mustermann'
              />

              <Distance top='5' />

              <EmailFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerEmail'
                fieldName='E-mail Adresse'
                fieldPlaceholder='muster@mail.com'
              />

              <Distance top='5' />

              <EmailFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerEmailRepeat'
                fieldRegisterToCheck='anotherPersonFahrerEmail'
                fieldName='E-mail Adresse wiederholen'
                fieldPlaceholder='muster@mail.com'
              />

              <Distance top='5' />

              <PhoneFieldComponent
                reactHookFormData={reactHookFormData}
                fieldRegister='anotherPersonFahrerTelefon'
                fieldName='Wie können wir dich am besten telefonisch erreichen?'
              />

              {watch('showSecondFahrerAnother') && (
                <>
                  <Distance top='5' />
                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='anotherPersonFahrerTelefon2'
                    fieldName='Weitere Telefonnummer'
                    close={true}
                    closeValue='showSecondFahrerAnother'
                  />
                </>
              )}
              <Distance top='-5' />

              <div
                id='secondDriverButton'
                onClick={() => setValue('showSecondFahrerAnother', true)}
                className={watch('showSecondFahrerAnother') ? styles.hide__block : styles.hide__block__show}
              >
                + Weitere Telefonnummer
              </div>
              {watch('showSecondFahrerAnother') ? <Distance top='5' /> : <Distance top='15' />}
            </>
          )}

          <Distance top='20' />

          <TwoButtonsComponent
            reactHookFormData={reactHookFormData}
            fieldName='Ist diese Person ein Ansprechpartner?'
            fieldRegister='isContactPerson'
            fieldRegisterSecond='isContactPerson'
            secondButtonText='Nein, andere Person'
          />

          {watch('isContactPerson') === false && (
            <div style={{ overflow: 'hidden', width: '100%' }}>
              <CSSTransition in={showNewContactPerson} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
                <div className={styles.data_fields}>
                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonName'
                    fieldName='Name'
                    fieldPlaceholder='Max Mustermann'
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonEmail'
                    fieldName='E-mail Adresse'
                    fieldPlaceholder='muster@mail.com'
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonEmailRepeat'
                    fieldRegisterToCheck='isContactPersonEmail'
                    fieldName='E-mail Adresse wiederholen'
                    fieldPlaceholder='muster@mail.com'
                  />
                  <Distance top='5' />

                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonPhone'
                    fieldName='Wie können wir dich am besten telefonisch erreichen?'
                  />

                  <div className={watch('showSecondContactPersonPhone') ? '' : styles.hide__block}>
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='isContactPersonPhoneSecond'
                      fieldName='Weitere Telefonnummer'
                      optional={!watch('showSecondContactPersonPhone')}
                      close={true}
                      closeValue='showSecondContactPersonPhone'
                    />
                  </div>
                  <Distance top='-5' />

                  <div
                    id='secondContactPersonPhoneButton'
                    onClick={() => setValue('showSecondContactPersonPhone', true)}
                    className={watch('showSecondContactPersonPhone') ? styles.hide__block : styles.hide__block__show}
                  >
                    + Weitere Telefonnummer
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default AnotherPersonComponent;
