import { CRM_VALUE as CRM_VALUE_MY_VEHICLE } from 'pages/my-vehicles/my-vehicles';
import { VehicleStateCodeLabel, VehicleStateCodeValue } from 'pages/my-vehicles/my-vehicles.props';

export const MY_VEHICLE_PRE_FILTER_FROM_AMOUNT_OF_ACTIVE_VEHICLE_BUTTON = {
  filterForServer: {
    [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeValue.Active]
  },
  filterForClient: {
    [CRM_VALUE_MY_VEHICLE.stateCode]: [VehicleStateCodeLabel.Active]
  }
};
