import useOverlay from 'hooks/use-overlay';
import sharepointService from 'services/sharepoint-service';
import { useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys, SharePointTitle, UNAUTHORIZED_PAGES } from 'common/enums';
import { requestService } from 'services/requests-service/requests-service';
import {
  AcceptIncidentOptional,
  RejectionCode,
  PostponeIncidentKeys,
  StepType,
  UpdateStepKeys,
  UploadSettleUpDateType
} from 'services/requests-service/requests-service.props';
import { FormName, IFormData } from 'pages/my-requests/components/table/expand/date-form/date-form.props';
import {
  StatusDescriptionId,
  VehicleReadinessConfirmedId
} from 'pages/my-requests/components/table/expand/expand.props';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setCompletedIncidentId, setIsEditMode, setUpdateIncidentId } from 'actions/pages/my-requests.action';
import { Steps } from 'pages/my-requests/components/table/expand/steps-controller/steps.props';
import { useContext } from 'react';
import {
  getAdditionalFilesForSubmit,
  getSharePointFilesForSubmit
} from 'pages/my-requests/components/table/expand/steps-controller/completion-confirmed/form/utils';
import { AnyObject, ResponseModel } from 'common/interfaces';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { AxiosResponse } from 'axios';
import { RequestStatuses } from '../../../constants';
import { UnSaveModalMyRequestRefContext } from 'context/un-save-modal/my-request-ref.context';
import { useIncidentByIdQuery } from 'hooks/react-query/incidents/incident-by-id';
import { useMyRequestsFilers } from 'hooks/react-query/incidents/my-requests/my-requests-filters';

export const useSendRequest = (incidentIdProp: string | null) => {
  const { showRequestSnackbar } = useRequestSnackbar();

  const dispatch = useDispatch();

  const [showOverlay] = useOverlay();
  const queryClient = useQueryClient();

  const { activeStep, activeIncident } = useContext(UnSaveModalMyRequestRefContext);

  const { refetch } = useIncidentByIdQuery((activeIncident.current.incidentid || incidentIdProp) as string, {
    enabled: false
  });

  const { refetch: filtersRefetch } = useMyRequestsFilers();

  const clearSharePointCash = () => {
    queryClient.resetQueries([ReactQueryKeys.SharePoint, ReactQueryKeys.RetrieveRepairPartnerPhotos]);
    queryClient.resetQueries([ReactQueryKeys.SharePoint, ReactQueryKeys.RetrieveIncidentDetailedFiles]);
  };

  const clearCash = () => {
    refetch();

    queryClient.resetQueries([PAGES.REPAIRED]);
    queryClient.resetQueries([UNAUTHORIZED_PAGES.REPAIRED_STATUS]);
  };

  const finallyCallback = () => {
    clearCash();
    dispatch(setIsEditMode(false));
  };

  const convertDataToUploadSettleUpDate = (values: AnyObject) => {
    const data: Partial<UploadSettleUpDateType> = {};

    Object.entries(values).map(([key, value]) => {
      if (moment(value).isValid()) {
        // @ts-ignore
        data[key] = moment(value).format('YYYY-MM-DD');
      }
    });

    return data;
  };

  const sendEmailOnLoadFileHandler = (files: Record<SharePointTitle, File[]>, isCompletionConfirmedStep: boolean) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);

    if (isCompletionConfirmedStep) {
      if (Object.values(files).flat().length) {
        requestService.sendEmailOnLoadFile(
          incidentId,
          5,
          '',
          Object.values(files)
            .flat()
            .map(fileItem => fileItem.name)
        );
      }
    }
  };

  const uploadFilesToSharepoint = async (files: Record<SharePointTitle, File[]>, incidentId: string) => {
    for (const [key, fileList] of Object.entries(files)) {
      for (const file of fileList) {
        await sharepointService.uploadAsync(incidentId, file, key, 'incident');
      }
    }
  };

  const saveFiles = async (
    stepType: StepType,
    files: Record<SharePointTitle, File[]>,
    resetFiles: Function,
    isCompletionConfirmedStep = false,
    settleUpDate?: UploadSettleUpDateType,
    carReadyToUseCode?: number
  ) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let isErrorWithoutRequestStatus = false;
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();

      await uploadFilesToSharepoint(files, incidentId);

      if (isCompletionConfirmedStep) {
        response = await requestService.uploadSettleUpDate(
          incidentId,
          5,
          convertDataToUploadSettleUpDate(settleUpDate as AnyObject) as UploadSettleUpDateType
        );

        filtersRefetch();
        dispatch(setCompletedIncidentId(incidentId));
      } else {
        response = await requestService.updateStep({
          [UpdateStepKeys.IncidentId]: incidentId,
          [UpdateStepKeys.StepDate]: new Date().toISOString(),
          [UpdateStepKeys.StepType]: stepType,
          [UpdateStepKeys.CarReadyToUse]: carReadyToUseCode
        });
      }

      sendEmailOnLoadFileHandler(files, isCompletionConfirmedStep);
      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      isErrorWithoutRequestStatus = true;
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data?.level === RequestStatuses.Success && !isErrorWithoutRequestStatus, () => {
        finallyCallback();
        resetFiles();
        clearSharePointCash();
      });
    }
  };

  const updateStep = async (
    values: IFormData,
    stepType: StepType,
    vehicleReadinessConfirmed?: VehicleReadinessConfirmedId,
    requestReasonName: UpdateStepKeys = UpdateStepKeys.DateConfirmation
  ) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let mainResponse: AxiosResponse<ResponseModel> | null = null;
    let secondaryResponse: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();

      if (values[FormName.DelayedIncidentCheckBox]) {
        if (!values[FormName.ModalRadioValue]) {
          mainResponse = await requestService.postponeIncident({
            [PostponeIncidentKeys.Id]: incidentId,
            [PostponeIncidentKeys.IsDeclined]: false,
            [PostponeIncidentKeys.Date]: moment(values[FormName.DelayedIncidentDate]).format('YYYY-MM-DD'),
            [PostponeIncidentKeys.Reason]: values[FormName.Reason]
          });
          queryClient.resetQueries(PAGES.MY_REQUESTS);
        }
        if (
          values[FormName.OldDate]?.isBefore(values[FormName.DelayedIncidentDate]) &&
          values[FormName.DifferenceDate] &&
          values[FormName.ModalRadioValue] === 'option1'
        ) {
          mainResponse = await requestService.postponeIncident({
            [PostponeIncidentKeys.Id]: incidentId,
            [PostponeIncidentKeys.IsDeclined]: false,
            [PostponeIncidentKeys.Date]: moment(values[FormName.DelayedIncidentDate]).format('YYYY-MM-DD'),
            [PostponeIncidentKeys.Reason]: values[FormName.Reason]
          });
          queryClient.resetQueries(PAGES.MY_REQUESTS);

          if (mainResponse?.data?.level === RequestStatuses.Success) {
            secondaryResponse = await requestService.updateStep({
              [UpdateStepKeys.IncidentId]: incidentId,
              [UpdateStepKeys.StepDate]: moment(values[FormName.DifferenceDate]).format('YYYY-MM-DD'),
              [requestReasonName]: values[FormName.StepDateJustification],
              [UpdateStepKeys.StepType]: stepType,
              [UpdateStepKeys.VehicleReadinessConfirmed]: vehicleReadinessConfirmed
            });
          }
        }
        if (
          values[FormName.OldDate]?.isBefore(values[FormName.DelayedIncidentDate]) &&
          values[FormName.ModalRadioValue] === 'option2'
        ) {
          mainResponse = await requestService.postponeIncident({
            [PostponeIncidentKeys.Id]: incidentId,
            [PostponeIncidentKeys.IsDeclined]: false,
            [PostponeIncidentKeys.Date]: moment(values[FormName.DelayedIncidentDate]).format('YYYY-MM-DD'),
            [PostponeIncidentKeys.Reason]: values[FormName.Reason]
          });
          queryClient.resetQueries(PAGES.MY_REQUESTS);

          if (mainResponse?.data?.level === RequestStatuses.Success) {
            secondaryResponse = await requestService.updateStep({
              [UpdateStepKeys.IncidentId]: incidentId,
              [UpdateStepKeys.StepDate]: moment(values[FormName.DelayedIncidentDate]).format('YYYY-MM-DD'),
              [requestReasonName]: values[FormName.StepDateJustification],
              [UpdateStepKeys.StepType]: stepType,
              [UpdateStepKeys.VehicleReadinessConfirmed]: vehicleReadinessConfirmed
            });
          }
        }
      } else {
        mainResponse = await requestService.updateStep({
          [UpdateStepKeys.IncidentId]: incidentId,
          [UpdateStepKeys.StepDate]: moment(values[FormName.StepDate]).format('YYYY-MM-DD'),
          [requestReasonName]: values[FormName.StepDateJustification],
          [UpdateStepKeys.StepType]: stepType,
          [UpdateStepKeys.VehicleReadinessConfirmed]: vehicleReadinessConfirmed
        });
      }

      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      if (secondaryResponse && secondaryResponse.data.level !== RequestStatuses.Success) {
        showRequestSnackbar(false, finallyCallback);
      } else {
        showRequestSnackbar(mainResponse?.data.level === RequestStatuses.Success, finallyCallback);
      }
    }
  };

  const uploadSettleUpDate = async (stepType: StepType, values: UploadSettleUpDateType) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();

      response = await requestService.uploadSettleUpDate(
        incidentId,
        stepType,
        convertDataToUploadSettleUpDate(values) as UploadSettleUpDateType
      );

      filtersRefetch();
      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, finallyCallback);
    }
  };

  const declineIncident = async (rejectionCode: RejectionCode, stepType: StepType) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();

      response = await requestService.declineIncident(incidentId, rejectionCode, stepType);
      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, finallyCallback);
    }
  };

  const acceptIncident = async (
    statusDescriptionId: StatusDescriptionId,
    optional: Partial<AcceptIncidentOptional> = {}
  ) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();
      response = await requestService.acceptIncident(incidentId, statusDescriptionId, optional);

      queryClient.resetQueries(PAGES.MY_REQUESTS);
      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, finallyCallback);
    }
  };

  const postponeIncident = async (values: Partial<IFormData>, isDeclined: boolean = false) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();
      response = await requestService.postponeIncident({
        [PostponeIncidentKeys.Id]: incidentId,
        [PostponeIncidentKeys.IsDeclined]: isDeclined,
        [PostponeIncidentKeys.Date]: moment(values[FormName.DelayedIncidentDate]).format('YYYY-MM-DD'),
        [PostponeIncidentKeys.Reason]: values[FormName.Reason]
      });

      queryClient.resetQueries(PAGES.MY_REQUESTS);
      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, finallyCallback);
    }
  };

  const switchProcessIncident = async (orderStatusDescriptionId: string) => {
    const incidentId = incidentIdProp || (activeIncident.current.incidentid as string);
    let response: AxiosResponse<ResponseModel> | null = null;

    try {
      showOverlay();
      response = await requestService.switchProcessIncident(incidentId, orderStatusDescriptionId);

      dispatch(setUpdateIncidentId(incidentId));
    } catch (err) {
      showRequestSnackbar(false, () => console.error(err));
    } finally {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, finallyCallback);
    }
  };

  const lastStepUploadedHandler = (incidentId: string) => {
    queryClient.resetQueries([PAGES.MY_REQUESTS]);
    dispatch(setCompletedIncidentId(incidentId));
  };

  const sendRequestMyRequestPage = async (data: AnyObject) => {
    switch (activeStep.current) {
      case Steps.Second:
        return await updateStep(data as IFormData, activeIncident.current.isAbholungGeplant ? 2 : 1);
      case Steps.Third:
        return await saveFiles(4, data as Record<SharePointTitle, File[]>, () => {});
      case Steps.Fourth:
        return await updateStep(data as IFormData, 2);
      case Steps.Fifth:
        return await updateStep(data as IFormData, 3);
      case Steps.Sixth:
        await saveFiles(
          5,
          {
            ...getAdditionalFilesForSubmit(data),
            ...getSharePointFilesForSubmit(data)
          } as Record<SharePointTitle, File[]>,
          () => {},
          true,
          data as UploadSettleUpDateType
        );
        lastStepUploadedHandler(activeIncident.current?.incidentid as string);
        break;
      default:
        break;
    }
  };

  return {
    saveFiles,
    updateStep,
    uploadSettleUpDate,
    declineIncident,
    sendRequestMyRequestPage,
    acceptIncident,
    postponeIncident,
    switchProcessIncident,
    clearCash
  };
};
