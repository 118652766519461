import React from 'react';
import { QualitySectionProps } from './quality-section.props';
import { Flex, Text } from 'common/common-components.styled';
import { Tooltip } from '@mui/material';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { StyledQualitySectionContainer } from './quality-section.styled';
import AddIcon from 'components/icons-new/add-icon';
import { useTheme } from 'styled-components';

export const QualitySection = ({ label, amount, filled, tooltip, onClick, isHide, isActive }: QualitySectionProps) => {
  const theme = useTheme();
  const isDisplayCirclePlusIcon = typeof filled !== 'number' || typeof amount !== 'number';

  if (isHide) {
    return null;
  }

  return (
    <StyledQualitySectionContainer onClick={onClick} isActive={!!isActive}>
      <Flex width='25px' align='flex-end'>
        {isDisplayCirclePlusIcon ? (
          <Flex justify='center' width='100%'>
            <AddIcon color={theme.colors.darkGold} width={18} height={18} />
          </Flex>
        ) : (
          <>
            <Text color='blue' size='20' bold lineHeight='26px'>
              {filled}
            </Text>
            <Text color='grey400' size='12' lineHeight='21px'>
              /{amount}
            </Text>
          </>
        )}
      </Flex>

      <Flex align='flex-end' top='5' left='10'>
        <Text color='grey900' size='12' lineHeight='18px' right='7'>
          {label}
        </Text>

        {tooltip && (
          <Tooltip title={tooltip}>
            <Flex bottom='3'>
              <InfoHintIcon width={12} height={12} color={theme.colors.grey500} />
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </StyledQualitySectionContainer>
  );
};
