import React, { useEffect, useState } from 'react';
import { driverFormFields } from 'models/forms/configuration-from-pdf-form-model';
import { SelectDriverData } from 'components/vehicle-offers-list-component/select-driver-component/select-driver-component.types';
import cloneDeep from 'lodash/cloneDeep';
import { getFields } from 'components/form/form-view';
import { CircleExclamationMark } from 'components/icons/crcle_exclamation_mark';
import { AnyObject } from 'common/interfaces';
import { setForm } from 'actions/app_action';
import { getUntouchedFields } from 'utils/get-untouched-fields';
import { useDispatch } from 'react-redux';

const useDriverFields = (selectDriverData?: SelectDriverData) => {
  const dispatch = useDispatch();
  const [driverFields, setDriverFields] = useState(driverFormFields);

  const driverValueChanged = (fieldName: string, value: string, oldValue: string, inputValue: string) => {
    const newDriverFields = cloneDeep(driverFields);
    getFields(newDriverFields, (field: AnyObject) => {
      field.errorMessage = getErrorMessage(inputValue);
      field.disabled = !!selectDriverData?.driverId;
    });

    setDriverFields(newDriverFields);
  };

  useEffect(() => {
    if (selectDriverData?.driverId) {
      setTimeout(() =>
        dispatch(
          setForm({
            name: 'driver',
            form: {
              values: { driverId: selectDriverData.driverId },
              errors: {},
              untouched: getUntouchedFields(driverFields),
              isFormValid: true
            }
          })
        )
      );
    }
  }, [JSON.stringify(selectDriverData)]);

  const getErrorMessage = (inputValue: string) => {
    return inputValue ? (
      <span className='d-flex a-i-center'>
        <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
        &nbsp;Bitte wähle einen Wert aus der Dropdown-Liste aus.
      </span>
    ) : (
      <span className='d-flex a-i-center'>
        <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
        &nbsp;Bitte prüfe die Eingabe.
      </span>
    );
  };

  return { driverFields, getErrorMessage, driverValueChanged };
};

export default useDriverFields;
