import React from 'react';

import style from './footer.module.scss';
import FooterForm from "../footer-form";

export default function Footer() {
  return (
    null
    // <footer className={style.footer}>
    //   <div className='container'>
    //     <div className={style.container}>
    //       <div className={style.row}>
    //         <div>
    //           <div className='contact-details'>
    //             <h4>Unternehmen</h4>
    //             <h6 className="h6-white">Mobexo GmbH</h6>
    //             <h6 className="h6-white">Universitätsstr. 44-46 </h6>
    //             <h6 className="h6-white">44789 Bochum</h6>
    //           </div>
    //           <div className='contact-details'>
    //             <h4><a href="https://www.mobexo.de/impressum">Impressum</a></h4>
    //           </div>
    //           <div className='contact-details'>
    //             <h4><a href="https://www.mobexo.de/datenschutz">Datenschutz</a></h4>
    //           </div>
    //         </div>
    //
    //         <div className='col-md-3'/>
    //
    //
    //       </div>
    //       <FooterForm/>
    //     </div>
    //   </div>
    // </footer>
  );
}
