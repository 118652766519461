import authService from 'services/auth-service';
import { API_ENDPOINT } from '../../constants';
import axios from 'axios';

export const isGuideToutNeeded = async () => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/user/ShouldShowGuidedTour`;
    const response = await axios({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const { data } = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const setFinishedGuidedTour = async () => {
  try {
    const token = await authService.acquireTokenAsync();
    const url = `${API_ENDPOINT}/api/user/SetGuidedTourFinished`;
    await axios({
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (error) {
    console.log(error);
  }
};
