import { useState, useEffect } from 'react';

export const useSetActiveTabIndex = (activeTabIndexParam?: number | null) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (typeof activeTabIndexParam === 'number') {
      setActiveTabIndex(activeTabIndexParam);
    }
  }, [activeTabIndexParam]);

  return { activeTabIndex, setActiveTabIndex };
};
