import React, { Component, Fragment } from 'react';

import classnames from 'classnames';
import classes from './footer-form.module.scss';
import ReactGA from 'react-ga';
import { createLead } from '../../services/lead-service';

export default class FooterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      form: {
        name: '',
        email: '',
        tel: '',
        message: ''
      },
      formErrors: {
        name: { touched: false, hasError: false },
        email: { touched: false, hasError: false },
        tel: { touched: false, hasError: false }
      },
      formValid: false,
      triedSubmit: false
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.submitButtonHandler = this.submitButtonHandler.bind(this);
  }
  changeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          [name]: value
        }
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    if (fieldValidationErrors.hasOwnProperty(fieldName)) {
      switch (fieldName) {
        case 'email':
          fieldValidationErrors.email.hasError = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          break;
        default:
          fieldValidationErrors[fieldName].hasError = !!value;
          break;
      }
      fieldValidationErrors[fieldName].touched = true;
      this.setState({ formErrors: fieldValidationErrors }, this.validateForm);
    }
  }

  validateForm() {
    const formValid = Object.values(this.state.formErrors).every(value => value.hasError && value.touched);
    this.setState({ formValid: formValid });
  }

  async submitButtonHandler() {
    if (this.state.formValid) {
      const model = { ...this.state.form };
      await this.submit(model);
      ReactGA.event({ category: 'Lead form submitted', action: 'conversion' });
    } else {
      this.setState({ triedSubmit: true });
    }
  }

  async submit(form) {
    const createLeadCommand = {
      ...form,
      message: form.message,
      subject: 'Fuhrparkmanagement footer contact form'
    };
    try {
      await createLead(createLeadCommand);
      this.showThankYouMessage();
    } catch (e) {
      console.log(e);
    }
  }

  showThankYouMessage = () => {
    this.setState({ submitting: true });
    setTimeout(() => {
      this.setState({
        submitting: false,
        form: {
          name: '',
          email: '',
          tel: '',
          message: ''
        }
      });
    }, 5000);
  };

  render() {
    const formErrors = this.state.formErrors;
    const triedSubmit = this.state.triedSubmit;
    return (
      <div className={classes.form}>
        <div className={classes.header}>
          <h1 className={classes.form_header}>Kontakt</h1>
          <img
            src='https://mktdplp102neda.azureedge.net/org-2ad921512d504b369396eea2f67ca823/c9d76ce2-370a-eb11-a813-000d3ab8d5b5/GI5MN7zzOf575HPfhXWdbP5Y7lqQfxRNLfYMhzE0Duk!'
            alt='email'
          />
        </div>
        {this.state.submitting ? (
          <h2 className={classes.thanks_header}>Vielen Dank. Wir melden uns schnellstmöglich bei Ihnen.</h2>
        ) : (
          <Fragment>
            <div className={classes.form_group}>
              <label htmlFor='name'>Vorname und Nachname</label>
              <input
                id='name'
                type='text'
                name='name'
                value={this.state.form.name}
                onChange={this.changeHandler}
                className={classnames(classes.form_control, {
                  [classes.has_error]: !formErrors.name.hasError && triedSubmit
                })}
                placeholder='Ihr Vorname und Nachname...'
                required=''
              />
            </div>
            <div className={classes.form_group}>
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                name='email'
                value={this.state.form.email}
                onChange={this.changeHandler}
                required=''
                className={classnames(classes.form_control, {
                  [classes.has_error]: !formErrors.email.hasError && triedSubmit
                })}
                placeholder='Ihre Email...'
              />
            </div>
            <div className={classes.form_group}>
              <label htmlFor='tel'>Telefonnummer</label>
              <input
                id='tel'
                type='text'
                name='tel'
                value={this.state.form.tel}
                onChange={this.changeHandler}
                className={classnames(classes.form_control, {
                  [classes.has_error]: !formErrors.tel.hasError && triedSubmit
                })}
                placeholder='Ihre Telefonnummer...'
              />
            </div>

            <div>
              <label htmlFor='message'>Ihr Anliegen</label>
              <textarea
                id='message'
                name='message'
                placeholder='Ihr Anliegen...'
                value={this.state.form.message}
                onChange={this.changeHandler}
                className={classnames(classes.form_control, classes.message)}
              />
            </div>

            <button className={classes.btn} onClick={this.submitButtonHandler}>
              <span>Kontakt aufnehmen</span>
            </button>
          </Fragment>
        )}
      </div>
    );
  }
}
