import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../endpoints';
import authService from './auth-service';

// TODO: Think about migrating js to the d.ts files
class SharepointService {
  /**
   * Uploads document to the sharepoint with specific description
   * @param {string} id - entity id, folder location will be resolved by this id
   * @param {File | File[]} file - instance of native file object
   * @param {string} description - document description defined in Dynamics CRM
   * @param {string} userId
   * @returns {Promise<AxiosResponse<{id: string, name: string, description: string}>>} sharepointDocument
   */
  async uploadAsync(id, files, description, logicalName) {
    const data = new FormData();

    data.append('description', description);
    data.append('id', id);
    data.append('LogicalName', logicalName);

    if (Array.isArray(files)) {
      files.forEach(file => {
        data.append('file', file, file.name);
      });
    } else {
      data.append('file', files, files.name);
    }

    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharepoint-documents/uploadFile`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  }

  /**
   * Permanently deletes file
   * @param {string} id - file id
   * @param {string} userId
   * @returns {Promise<AxiosResponse<AnyObject>>}
   */
  async deleteAsync(id, userId) {
    const token = await authService.acquireTokenAsync();
    // send request
    return axios({
      method: 'DELETE',
      url: `${API_ENDPOINT}/api/sharepoint-documents/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Moves file to the bin
   * @param {string} id - file id
   * @param {string} userId
   * @returns {Promise<AxiosResponse<void>>}
   */
  async recycle(id, userId) {
    const token = await authService.acquireTokenAsync();
    // send request
    return axios({
      method: 'DELETE',
      url: `${API_ENDPOINT}/api/sharepoint-documents/recycle/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Retrieves files with special descriptions, as Foto Schaden and Vermessungsprotokoll and Kostenvoranschlag Werkstatt
   * @param {string} id - entity id
   * @returns {Promise<AxiosResponse<ISharePointRequest>>} - file list
   */
  async retrieveRepairPartnerPhotos(id) {
    const token = await authService.acquireTokenAsync();
    // send request
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/incidents/cost-estimate-files/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Retrieves files with special descriptions, as Bonusabrechnung Partnerwerkstatt and Protocoll and ER Sonstiges
   * @param {string} id - entity id
   * @param {string} userId
   * @returns {Promise<AxiosResponse<ISharePointRequest>>} - file list
   */
  async retrieveIncidentDetailedFiles(id) {
    const token = await authService.acquireTokenAsync();
    // send request
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/incidents/incident-detailed-files/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Retrieves sharepoint document location for entity
   * @param {string} id - entity id
   * @param {string} userId
   * @returns {Promise<AxiosResponse<{name: string, path: string}>>}
   */
  async retrieveDocumentLocation(id, userId) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/sharepoint-documents/location/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Retrieves sharepoint document location for entity
   * @param {array} Parameters
   * @returns {Promise<AxiosResponse<{name: string, path: string}>>}
   */
  async updateFileAttribute(Parameters) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharpoint/updateFileAttribute`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        Parameters
      }
    });
  }

  /**
   * Retrieves sharepoint document location for entity
   * @param {array} Files
   * @param {string} Description
   * @param {string} Guid
   * @param {string} EntityName
   * @returns {Promise<AxiosResponse>}
   */
  async uploadSharepointFile(Files, Description, Guid, EntityName, AccessRights) {
    const data = new FormData();
    Files.forEach(file => {
      data.append('Files', file);
    });
    data.append('Description', Description);
    data.append('Guid', Guid);
    data.append('EntityName', EntityName);

    if (AccessRights) {
      data.append('AccessRights', AccessRights);
    }

    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharpoint/uploadfile`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  }

  /**
   * Retrieves sharepoint document location for entity
   * @param {array} Files
   * @param {string} Description
   * @param {string} AccessRights
   * @param {string} Guid
   * @param {string} EntityName
   * @returns {Promise<AxiosResponse<{name: string, path: string}>>}
   */
  async uploadSharepointFileById(Files, Description, Guid, EntityName) {
    const data = new FormData();
    Files.forEach(file => {
      data.append('Files', file);
    });
    data.append('Description', Description);
    data.append('AccessRights', 'Kunde');
    data.append('Guid', Guid);
    data.append('EntityName', EntityName);
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharpoint/uploadfile`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  }

  /**
   * Retrieves sharepoint documents in zip archive
   * @param {array} filesPath
   * @param {string} zipArhiveName
   * @returns {Promise<AxiosResponse<{name: string, path: string}>>}
   */
  async getZipFilesByPath(filesPath, zipArhiveName) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharpoint/getZipFilesByPath`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        filesPath,
        zipArhiveName
      }
    });
  }

  /**
   * Downloads sharepoint document by id
   * @param {string} fileId
   * @returns {Promise<AxiosResponse<Blob>>}
   */
  async downloadFileById(fileId) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharepoint/downloadFileById`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        fileId
      }
    });
  }

  /**
   * Retrieves sharepoint documents by description
   * @param {{entityId: string, descriptions: string[]}} data
   * @returns {Promise<AxiosResponse<{data: FileModel[]}>>}
   */
  async getFilesByDescriptions(data) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharepoint/getFilesByDescriptions`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  }

  async getFileOrc(id) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/OcrEingang/${id}/file`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  async getFilesDescriptionsOrc(id) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'GET',
      url: `${API_ENDPOINT}/api/OcrEingang/${id}/fileInfo`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Returns sharepoint file
   * @param {string} entityId
   * @param {string} description
   * @param {object} cancelToken
   * @returns {Promise<AxiosResponse<string>>}
   */
  async getSharepointLogo(entityId, description, cancelToken) {
    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharepoint/getLogo`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      },
      cancelToken,
      data: {
        entityId,
        description
      },
      transformResponse: function (data) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(data);
      }
    });
  }

  /**
   * Retrieves sharepoint document location for entity
   * @param {array} files
   * @param {string} entityId
   * @param {string} documentTypeId
   * @returns {Promise<AxiosResponse>}
   */
  async uploadFilesByDocumentType(files, entityId, documentTypeId) {
    const data = new FormData();

    files.forEach(file => {
      data.append('files', file, file.name);
    });
    data.append('entityId', entityId);
    data.append('documentTypeId', documentTypeId);

    const token = await authService.acquireTokenAsync();
    return axios({
      method: 'POST',
      url: `${API_ENDPOINT}/api/sharepointdocuments/uploadFilesByDocumentType`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data
    });
  }
}

const sharepointService = new SharepointService();
export default sharepointService;
