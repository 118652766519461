import React, { ReactElement } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { StyledInfoCard } from 'components/configurator-modal/configurator-modal.styled';
import { BUDGET_STATUSES_COLORS } from 'components/configurator-modal/configurator-modal-view';

interface InfoCardProps {
  price: number;
  text: string;
  icon: ReactElement;
  statusIcon?: ReactElement;
  status: 'success' | 'warning' | 'error';
}

const InfoCard = ({ price, text, icon, statusIcon, status }: InfoCardProps) => {
  return (
    <StyledInfoCard direction='column' status={status}>
      <Flex justify='space-between' bottom='5' align='center'>
        <Text color={BUDGET_STATUSES_COLORS[status].priceColor} size='24' bold>
          {price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
        </Text>
        {icon}
      </Flex>
      <Flex align='flex-start'>
        {statusIcon ? <Flex width='25px'>{statusIcon}</Flex> : null}

        <Text left={statusIcon ? '10' : '0'} color={BUDGET_STATUSES_COLORS[status].color} size='12' bold>
          {text}
        </Text>
      </Flex>
    </StyledInfoCard>
  );
};

export default InfoCard;
