import { FormFieldModel, FormFieldOption } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { FormSourceEnum } from 'components/form-hook/form-field/hooks/use-set-options/use-set-options.props';

export enum CreateBookingFieldNames {
  StartDate = 'uds_abholdatum',
  EndDate = 'uds_ruckgabedatum',
  PlannedKilometers = 'uds_kilometervoraussichtlich',
  Requester = 'uds_requester',
  Comment = 'uds_comment',
  PickUpStreetHouseNumber = 'uds_street_homenumber_pickup',
  PickUpPostcode = 'uds_postcode_pickup',
  PickUpCity = 'uds_city_pickup',
  ReturnStreetHouseNumber = 'uds_street_homenumber_return',
  ReturnPostcode = 'uds_postcode_return',
  ReturnCity = 'uds_city_return',
  PlannedKilometersOption = 'plannedKilometersOption',
  PickUpLocation = 'uds_standort_pickupid',
  ReturnLocation = 'uds_standort_returnid'
}

export enum PlannedKilometersValues {
  Kilometers = 'kilometers',
  Locations = 'locations',
  Address = 'address'
}

const plannedKilometersOptions: FormFieldOption[] = [
  {
    label: 'Geplante Kilometer',
    value: PlannedKilometersValues.Kilometers
  },
  {
    label: 'Standorte',
    value: PlannedKilometersValues.Locations
  },
  {
    label: 'Adressen',
    value: PlannedKilometersValues.Address
  }
];

export const createBookingFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.StartDate,
    type: FormFieldTypes.DateTime,
    defaultValue: null,
    label: 'Abholdatum',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.EndDate,
    type: FormFieldTypes.DateTime,
    defaultValue: null,
    label: 'Rückgabedatum',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const plannedKilometersOptionFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.PlannedKilometersOption,
    type: FormFieldTypes.Radio,
    defaultValue: PlannedKilometersValues.Kilometers,
    radioButtonsInRow: true,
    radioButtonsRowDistance: '85px',
    options: plannedKilometersOptions,
    validation: {}
  },
  {
    name: CreateBookingFieldNames.PlannedKilometers,
    type: FormFieldTypes.Kilometers,
    defaultValue: '',
    label: 'Geplante Kilometer',
    validation: {
      required: 'Bitte prüfe die Eingabe.',
      min: {
        value: 0,
        message: 'Der Wert in diesem Feld sollte zwischen 0 und 1.000.000 liegen.'
      },
      max: {
        value: 1000000,
        message: 'Der Wert in diesem Feld sollte zwischen 0 und 1.000.000 liegen.'
      }
    }
  }
];

export const locationsFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.PickUpLocation,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Standort Abholung',
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.ReturnLocation,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Standort Rückgabe',
    options: [],
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const requesterFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.Requester,
    type: FormFieldTypes.Lookup,
    defaultValue: '',
    label: 'Mitarbeiter',
    emptyOptionsPlaceholder: 'Nach Mitarbeiter suchen',
    source: FormSourceEnum.GetDriversForBooking,
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const commentFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.Comment,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Kommentar',
    validation: {}
  }
];

export const pickUpAddressFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.PickUpStreetHouseNumber,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Straße, Hausnummer',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.PickUpPostcode,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'PLZ',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.PickUpCity,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Stadt',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];

export const returnAddressFormFields: FormFieldModel<CreateBookingFieldNames>[] = [
  {
    name: CreateBookingFieldNames.ReturnStreetHouseNumber,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Straße, Hausnummer',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.ReturnPostcode,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'PLZ',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: CreateBookingFieldNames.ReturnCity,
    type: FormFieldTypes.Text,
    defaultValue: '',
    label: 'Stadt',
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  }
];
