import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';
import { useTheme } from 'styled-components';

export const FuelIcon = ({
  width = 61,
  height = 72,
  color: colorProp,
  fill: fillProp,
  hovered,
  ...restProps
}: IconProps) => {
  const theme = useTheme();
  const { defaultFill } = useDefaultIconsColor();

  const color = colorProp || theme.colors.midBlue;
  const fill = fillProp || defaultFill;

  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox='0 0 61 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.7051 28.0968V18.3871C56.7051 16.6935 55.9146 15.1129 54.6724 13.9839L40.2176 2.24194C39.2013 1.45162 37.9591 1 36.6039 1H31.0704C29.3765 1 27.9084 1.67743 26.7792 2.91937L21.6974 8.90323H17.0906V1.51954H5.88744V8.90323C3.96766 8.90323 2.4996 10.371 2.4996 12.2903V31.8226V63.0968L2.16082 63.4355C1.59618 64 1.37032 64.6774 1.48325 65.3548L2.16082 69.0806C2.38668 70.2097 3.29011 71 4.41939 71H57.0439C58.1732 71 59.0766 70.2097 59.3025 69.0806L59.98 65.3548C60.093 64.6774 59.8671 63.8871 59.3025 63.4355L58.9637 63.0968V41.4193C58.9637 39.3507 58.1021 37.3605 56.7051 35.905V28.0968ZM49.875 19.4491V30.5125L27.3914 14.2581L31.966 8.02887L36.2518 7.80925L49.875 19.4491Z'
        fill='white'
      />
      {hovered && (
        <path
          d='M2.0527 63.0353V31.5181V16.3853V12.2043C2.0527 10.5474 3.39584 9.2043 5.05268 9.20429L21.291 9.20422C21.291 9.20422 31.5284 17.0679 33.1217 18.2058L55.7691 34.8177C57.7038 36.2969 58.9557 38.6862 58.9557 41.1894V63.0353L59.2971 63.3766C59.8661 63.8317 60.0937 64.6282 59.9799 65.3109L59.2971 69.0657C59.0695 70.2035 58.159 70.9999 57.021 70.9999H3.98741C2.84935 70.9999 1.93891 70.2035 1.71129 69.0657L1.02845 65.3109C0.914645 64.6282 1.14226 63.9455 1.71129 63.3766L2.0527 63.0353Z'
          fill={fill}
        />
      )}
      <path
        d='M38.6372 47.2903C38.6372 51.6935 35.1364 55.1935 30.7322 55.1935C26.328 55.1935 22.8273 51.6935 22.8273 47.2903C22.8273 42.887 30.7322 32.6129 30.7322 32.6129C30.7322 32.6129 38.6372 42.887 38.6372 47.2903Z'
        fill='white'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M56.7051 30.5L56.7051 18.3871C56.7051 16.6935 55.9147 15.1129 54.6724 13.9839L40.2176 2.24194C39.2013 1.45162 37.9591 1 36.6039 1H31.0704C29.3765 1 27.9084 1.67743 26.7792 2.91937L21.6974 8.90323H21.9233H5.88744C3.96766 8.90323 2.4996 10.371 2.4996 12.2903V31.8226V63.0968M2.4996 63.0968L2.16082 63.4355C1.59618 64 1.37032 64.6774 1.48325 65.3548L2.16082 69.0806C2.38668 70.2097 3.29011 71 4.41939 71H57.0439C58.1732 71 59.0766 70.2097 59.3025 69.0806L59.9801 65.3548C60.093 64.6774 59.8671 63.8871 59.3025 63.4355L58.9637 63.0968M2.4996 63.0968L18.3096 63.0967M58.9637 63.0968V41.4193C58.9637 38.9355 57.7215 36.5645 55.8017 35.0968L33.329 18.6129C31.748 17.4839 29.9412 16.8065 28.0214 16.8065H12.6631M58.9637 63.0968L43.1535 63.0967'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29 12L30.8449 9.24171C31.5225 8.33849 32.5389 7.77399 33.6681 7.77399H35.1362C35.9267 7.77399 36.7172 7.99981 37.2818 8.56432L48.6876 18.0482C49.4781 18.7256 49.9298 19.6288 49.9298 20.6449V26'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.88763 7V3.25806C5.88763 2.01613 6.90399 1 8.1462 1H14.9219C16.1641 1 17.1805 2.01613 17.1805 3.25806V7'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  );
};
