import React from 'react';
import LeasingWidget from '../components/leasing-widget';
import { ContractIcon } from 'components/icons-new/contract';
import { useTheme } from 'styled-components';
import WidgetWrapper from '../components/widget-wrapper/widget-wrapper';
import { useLeaseExtensionDecision } from './use-lease-extension-decision';
import LeaseExtensionDecisionButtons from './components/lease-extension-decision-buttons';
import { DescriptionContainer } from '../components/leasing-widget/leasing-widget.styled';

const LeaseExtensionDecision = () => {
  const theme = useTheme();
  const { statusColor, statusLabel, description } = useLeaseExtensionDecision();

  return (
    <LeasingWidget
      image={<ContractIcon color={theme.colors.midBlue} width={60} height={40} hovered />}
      statusColor={statusColor}
      statusLabel={statusLabel}
      buttons={<LeaseExtensionDecisionButtons />}
      id={'leaseExtensionDecision'}
    >
      <WidgetWrapper>
        <DescriptionContainer>{description}</DescriptionContainer>
      </WidgetWrapper>
    </LeasingWidget>
  );
};

export default LeaseExtensionDecision;
