import React from 'react';

const InfoIcon = () => {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0299 7.84055C9.8265 7.84055 9.69091 7.77275 9.55532 7.63716C9.41973 7.50157 9.35193 7.36598 9.35193 7.16259C9.35193 6.9592 9.41973 6.8236 9.55532 6.68801C9.69091 6.55242 9.8265 6.48462 10.0299 6.48462C10.2333 6.48462 10.3689 6.55242 10.5045 6.68801C10.6401 6.8236 10.7079 6.9592 10.7079 7.16259C10.7079 7.36598 10.6401 7.56936 10.5045 7.63716C10.3689 7.77275 10.1655 7.84055 10.0299 7.84055ZM10.7079 9.26428V13.2643C10.7079 13.5355 10.6401 13.7389 10.5045 13.8745C10.3689 14.01 10.2333 14.0778 10.0299 14.0778C9.8265 14.0778 9.69091 14.01 9.55532 13.8745C9.41973 13.7389 9.35193 13.5355 9.35193 13.2643V9.33208C9.35193 9.06089 9.41973 8.8575 9.55532 8.72191C9.69091 8.58631 9.8265 8.51852 10.0299 8.51852C10.2333 8.51852 10.3689 8.58631 10.5045 8.72191C10.6401 8.7897 10.7079 8.99309 10.7079 9.26428Z'
        fill='#102F3F'
      />
      <path
        d='M10.0469 18.2812C7.94518 18.2812 5.91128 17.4677 4.41976 15.9762C2.86043 14.4168 2.04688 12.3829 2.04688 10.2812C2.04688 8.17956 2.86043 6.14566 4.41976 4.65413C5.91128 3.09481 7.87738 2.28125 10.0469 2.28125C12.1486 2.28125 14.1825 3.09481 15.674 4.58633C18.7926 7.70498 18.7926 12.7897 15.674 15.9084C14.1825 17.3999 12.1486 18.2812 10.0469 18.2812ZM10.0469 3.63718C8.28416 3.63718 6.58925 4.31515 5.36891 5.60328C4.08077 6.82362 3.40281 8.51854 3.40281 10.2812C3.40281 12.044 4.08077 13.7389 5.36891 14.9592C6.58925 16.1796 8.28416 16.9253 10.0469 16.9253C11.8096 16.9253 13.5045 16.2474 14.7248 14.9592C17.3011 12.3829 17.3011 8.17956 14.7248 5.60328C13.4367 4.31515 11.8096 3.63718 10.0469 3.63718Z'
        fill='#102F3F'
      />
    </svg>
  );
};

export default InfoIcon;
