import { EventProps } from 'components/product/product-view.props';
import { getEventsByType, takeLatestEvent } from 'utils/offer-events-functions';
import { VEHICLE_OFFER_EVENT_DESCRIPTIONS } from '../constants';
import { useMemo } from 'react';

const useLatestEvents = (events: EventProps[]) => {
  const configurationCreatedEvent = useMemo(
    () =>
      takeLatestEvent([
        ...getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationCreated),
        ...getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.configurationUploaded)
      ]),
    [events]
  );
  const offerRequestedEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.offerRequested)),
    [events]
  );
  const vehicleRequestedEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleRequested)),
    [events]
  );
  const approvedByFleetManagerEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.approvedByFleetManager)),
    [events]
  );
  const orderApprovedEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.orderApproved)),
    [events]
  );
  const approvalRejectedEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.approvalRejected)),
    [events]
  );
  const vehicleConfigurationBasedOnGRVCreatedEvent = useMemo(
    () =>
      takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.vehicleConfigurationBasedOnGRVCreated)),
    [events]
  );
  const globalReferenceVehicleGeneratedViaAPIEvent = useMemo(
    () =>
      takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.globalReferenceVehicleGeneratedViaAPI)),
    [events]
  );
  const latestEvent = useMemo(() => takeLatestEvent(events), [events]);
  const buildAbilityConfirmedEvent = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.buildAbilityConfirmed)),
    [events]
  );
  const budgetCheckPositive = useMemo(
    () => takeLatestEvent(getEventsByType(events, VEHICLE_OFFER_EVENT_DESCRIPTIONS.budgetCheckPositive)),
    [events]
  );

  return {
    configurationCreatedEvent,
    offerRequestedEvent,
    vehicleRequestedEvent,
    approvedByFleetManagerEvent,
    orderApprovedEvent,
    approvalRejectedEvent,
    vehicleConfigurationBasedOnGRVCreatedEvent,
    globalReferenceVehicleGeneratedViaAPIEvent,
    latestEvent,
    buildAbilityConfirmedEvent,
    budgetCheckPositive
  };
};

export default useLatestEvents;
