import { Text } from 'common/common-components.styled';
import styled from 'styled-components';
import { DriverDashboardDetailItemProps } from './detail-item.props';
import { getDeviceType } from 'utils/get-device-type';
import { DeviceTypes } from '../../../constants';

export const StyledDetailTitle = styled(Text)<Pick<DriverDashboardDetailItemProps, 'isColumn'>>`
  flex: 1;
  color: ${({ theme }) => (getDeviceType() === DeviceTypes.Mobile ? theme.colors.white : theme.colors.lightBlue)};
  font-size: ${() => (getDeviceType() === DeviceTypes.Mobile ? '14px' : '12px')};
`;

export const StyledDetailDescription = styled(Text)<Pick<DriverDashboardDetailItemProps, 'type'>>`
  flex: 1;
  color: ${({ theme, type }) => (type === 'email' ? theme.colors.gold : theme.colors.white)};
  font-size: ${() => (getDeviceType() === DeviceTypes.Mobile ? '14px' : '12px')};
  font-weight: bold;
  cursor: ${({ type }) => (type === 'email' ? 'pointer' : 'default')};
`;
