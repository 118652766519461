import React from 'react';
import PropTypes from 'prop-types';

const ExpandIcon = ({ fill = '#102F3F', ...props }) => {
  return (
    <svg
      width='18'
      height='11'
      viewBox='0 0 18 11'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.2208 0.984294C16.8042 0.567735 16.1288 0.567735 15.7123 0.984294L8.99987 7.69671L2.28745 0.984294C1.87089 0.567735 1.19552 0.567735 0.778957 0.984294C0.362398 1.40085 0.362398 2.07623 0.778957 2.49279L8.24562 9.95945C8.66218 10.376 9.33756 10.376 9.75412 9.95945L17.2208 2.49279C17.6373 2.07623 17.6373 1.40085 17.2208 0.984294Z'
        fill={fill}
      />
    </svg>
  );
};

ExpandIcon.propTypes = {
  fill: PropTypes.string
};

export default ExpandIcon;
