import React from 'react';
import PropType from 'prop-types';

export const UsersIcon = ({ width = '18', height = '20', color = '#5F6466' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_14261_10572)'>
        <g clipPath='url(#clip1_14261_10572)'>
          <path
            d='M7.09961 2.59766C6.25961 2.59766 5.59961 3.25766 5.59961 4.09766C5.59961 4.93766 6.25961 5.59766 7.09961 5.59766C7.93961 5.59766 8.59961 4.93766 8.59961 4.09766C8.59961 3.25766 7.93961 2.59766 7.09961 2.59766Z'
            fill={color}
          />
          <path
            d='M7.09953 5.59766C6.73953 5.59766 6.37953 5.65766 6.01953 5.77766C6.49953 6.37766 6.79953 7.15766 6.79953 7.99766C6.79953 8.23766 6.73953 8.41766 6.61953 8.59766H9.19953C9.55953 8.59766 9.79953 8.35766 9.79953 7.99766C9.79953 6.67766 8.59953 5.59766 7.09953 5.59766Z'
            fill={color}
          />
          <path
            d='M5.47922 6.07805C4.93922 5.41805 4.09922 4.99805 3.19922 4.99805C1.51922 4.99805 0.199219 6.31805 0.199219 7.99805C0.199219 8.17805 0.259219 8.29805 0.379219 8.41805C0.499219 8.53805 0.619219 8.59805 0.799219 8.59805H4.99922H5.59922C5.95922 8.59805 6.19922 8.35805 6.19922 7.99805C6.19922 7.27805 5.89922 6.61805 5.47922 6.07805Z'
            fill={color}
          />
          <path
            d='M3.19844 4.99844C2.17844 4.99844 1.39844 4.21844 1.39844 3.19844C1.39844 2.17844 2.17844 1.39844 3.19844 1.39844C4.21844 1.39844 4.99844 2.17844 4.99844 3.19844C4.99844 4.21844 4.21844 4.99844 3.19844 4.99844Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_14261_10572'>
          <rect width='9.6' height='9.6' fill='white' transform='translate(0.199219 0.199219)' />
        </clipPath>
        <clipPath id='clip1_14261_10572'>
          <rect width='9.6' height='7.2' fill='white' transform='translate(0.199219 1.39844)' />
        </clipPath>
      </defs>
    </svg>
  );
};

UsersIcon.propTypes = {
  width: PropType.oneOfType([PropType.string, PropType.number]),
  height: PropType.oneOfType([PropType.string, PropType.number]),
  color: PropType.string
};
