import React from 'react';
import classes from './calendar-appointment.module.scss';
import { StyledDiagramContainer } from 'components/containers/diagram/diagram.styled';
import { CalendarWidget } from 'components/widgets/calendar/calendar';
import { useCalendarAppointmentData } from './hooks/use-calendar-appointment-data';

export const CalendarAppointmentWidget = () => {
  const { calendarWidgetData } = useCalendarAppointmentData();

  return (
    <div className={classes.container}>
      <StyledDiagramContainer>
        <CalendarWidget idPrefix='calendarAppointment' data={calendarWidgetData} itemHeight={48} maxLength={4} />
      </StyledDiagramContainer>
    </div>
  );
};
