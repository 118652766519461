import React, { cloneElement, useState } from 'react';
import { ButtonWithIconProps } from './button-with-icon.props';
import { Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import { IconProps } from 'common/interfaces';
import { Flex } from 'common/common-components.styled';

const ButtonWithIcon = ({ onClick, tooltip, icon }: ButtonWithIconProps) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Tooltip title={tooltip}>
      <Flex onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} pointer onClick={onClick}>
        {cloneElement<IconProps>(icon, {
          fill: isHovering ? theme.colors.extraLightBlue : 'none',
          hovered: isHovering
        })}
      </Flex>
    </Tooltip>
  );
};

export default ButtonWithIcon;
