import { IconProps } from 'common/interfaces';
import React from 'react';

export const GasStationIcon = ({ width = '15', height = '16', color = '#5F6466', className }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7.04688 3H4.04688V6H7.04688V3Z' fill={color} />
      <path
        d='M13.9469 5.1L11.9469 3.1C11.7469 2.9 11.4469 2.9 11.2469 3.1C11.0469 3.3 11.0469 3.6 11.2469 3.8L13.0469 5.7V11.5C13.0469 11.8 12.8469 12 12.5469 12C12.2469 12 12.0469 11.8 12.0469 11.5V7.5C12.0469 6.7 11.3469 6 10.5469 6H10.0469V2C10.0469 0.9 9.14688 0 8.04688 0H3.04688C1.94687 0 1.04688 0.9 1.04688 2V15H0.546875C0.246875 15 0.046875 15.2 0.046875 15.5C0.046875 15.8 0.246875 16 0.546875 16H10.5469C10.8469 16 11.0469 15.8 11.0469 15.5C11.0469 15.2 10.8469 15 10.5469 15H10.0469V7H10.5469C10.8469 7 11.0469 7.2 11.0469 7.5V11.5C11.0469 12.3 11.7469 13 12.5469 13C13.3469 13 14.0469 12.3 14.0469 11.5V5.5C14.0469 5.4 13.9469 5.2 13.9469 5.1ZM3.04688 2.5C3.04688 2.2 3.24688 2 3.54688 2H7.54688C7.84688 2 8.04688 2.2 8.04688 2.5V6.5C8.04688 6.8 7.84688 7 7.54688 7H3.54688C3.24688 7 3.04688 6.8 3.04688 6.5V2.5Z'
        fill={color}
      />
    </svg>
  );
};
