import React, {Component} from 'react';

export function withPageContainer(WrappedComponent) {
  return class extends Component {
    getClassName = () => {
      if (window.innerWidth >= 1200) {
        return 'mobexo-page-container';
      } else {
        return 'container';
      }
    };

    state = {
      className: this.getClassName()
    };

    setClassName = () => {
      this.setState({className: this.getClassName()});
    };

    componentDidMount() {
      window.addEventListener("resize", this.setClassName);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.setClassName);
    }

    render() {
      return <WrappedComponent containerClassName={this.state.className} {...this.props} />;
    }
  };
}
