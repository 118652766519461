import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Flex, Text, Button } from 'common/common-components.styled';
import { StyledInput, StyledDropdown, StyledListItem } from './delegate-task.styled';
import { UDS_AUFGABENTYP_FUHRPARK } from '../../../../constants';
import { SearchItemProps } from '../../task.prop';
import CarPoliciesForDriver from '../car-policies-for-driver/car-policies-for-driver';
import { getDelegateList } from 'components/aufgaben-component/utils';
import { PrefixForId } from '../../../../common/enums';
import Spinner from 'components/spinner';

interface DelegateTaskProps {
  taskId: string;
  taskTypeValue: number;
  onSubmitDelegation: (selectedUser: SearchItemProps, taskId: string) => void;
  onCloseModal: () => void;
}

const searchFilter = (searchValue: string, availableUsers: SearchItemProps[]) => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? availableUsers.filter((el: SearchItemProps) => el.name.toLowerCase().includes(lowerCaseQuery))
    : availableUsers;
  return filteredList;
};

const spinnerStyles = {
  width: '50px',
  height: '50px'
};

const DelegateTask = ({ taskId, onSubmitDelegation, onCloseModal, taskTypeValue }: DelegateTaskProps) => {
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [availableUsers, setAvailableUsers] = useState<SearchItemProps[]>([]);
  const [filteredItems, setFilteredItems] = useState<SearchItemProps[]>([]);
  const dropdownRef = useRef<any>(null);
  const [valueFromInput, setValueFromInput] = useState<SearchItemProps | null>();
  const [isDriverSelectedForRVO, setIsDriverSelectedForRVO] = useState(false);
  const [isCarPolicyModal, setIsCarPolicyModal] = useState(false);
  const [chosenDriver, setChosenDriver] = useState<SearchItemProps>();
  const [isDelegateListLoading, setIsDelegateListLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = useForm();

  const MODAL_BUTTON_ID_PREFIX = PrefixForId.Modal + PrefixForId.Button;

  useEffect(() => {
    setIsDelegateListLoading(true);
    getDelegateList({
      TaskId: taskId
    }).then(response => {
      setAvailableUsers(response.data.data);
      const defaultValue = response.data.data[0].isdriver && response.data.data[0].isValid ? response.data.data[0] : '';
      setSearchValue(defaultValue ? defaultValue.name : '');
      setValue('users', defaultValue);
      setIsDelegateListLoading(false);
    });
  }, [setValue, taskId]);

  useEffect(() => {
    if (availableUsers.length) {
      if (
        !availableUsers?.map((el: SearchItemProps) => el.name).find((el: string) => el === watch('users')) &&
        searchValue.length > 0
      ) {
        setError('users', {
          type: 'manual',
          message: 'User not found'
        });
      }
    }
  }, [availableUsers, searchValue.length, setError, visible, watch]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  useEffect(() => {
    const lowerCaseQuery = searchValue.toLowerCase();
    const filteredList = searchValue
      ? availableUsers.filter((el: SearchItemProps) => el.name.toLowerCase().includes(lowerCaseQuery))
      : availableUsers;

    setFilteredItems(filteredList);
  }, [searchValue, availableUsers]);

  useEffect(() => {
    watch('users')
      ? setIsDriverSelectedForRVO(
          (taskTypeValue === UDS_AUFGABENTYP_FUHRPARK.replacement &&
            watch('users').isFahrerRole &&
            !watch('users').isFuhrparkleiterRole) ||
            (valueFromInput && taskTypeValue === UDS_AUFGABENTYP_FUHRPARK.replacement && valueFromInput.isFahrerRole)
        )
      : setIsDriverSelectedForRVO(false);
  }, [taskTypeValue, searchValue, valueFromInput, watch]);

  useEffect(() => {
    setValue('users', chosenDriver);
  }, [chosenDriver, isCarPolicyModal, setValue]);

  const selectItem = useCallback(
    (item: SearchItemProps) => {
      if (item.isValid) {
        clearErrors('users');
        setValue('users', item);
        setSearchValue(item.name);
        setVisible(false);
      }
    },
    [clearErrors, setValue]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      const userFound = availableUsers.find(user => user.name === e.target.value);
      if (
        availableUsers
          ?.map((el: SearchItemProps) => {
            return el.name;
          })
          .find((el: string) => el.toLowerCase() === searchValue.toLowerCase())
      ) {
        clearErrors('users');
      }
      if (searchFilter(e.target.value, availableUsers).length === 0) {
        setError('users', {
          type: 'manual',
          message: 'User not found'
        });
      }
      if (searchFilter(e.target.value.toUpperCase(), availableUsers).length > 0) {
        clearErrors('users');
      }
      if (userFound) {
        selectItem(userFound);
        setValueFromInput(userFound);
      } else {
        setValue('users', null);
        setValueFromInput(null);
      }
      if (!visible) {
        setVisible(true);
      }
    },
    [availableUsers, clearErrors, searchValue, setError, setValue, visible, selectItem]
  );

  const handleClick = useCallback((e: any) => {
    if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      return;
    }
    setVisible(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (valueFromInput) {
      onSubmitDelegation(valueFromInput, taskId);
      return;
    }
    onSubmitDelegation(watch('users'), taskId);
  }, [onSubmitDelegation, taskId, valueFromInput, watch]);

  const goToPoliciesModal = useCallback(() => {
    setChosenDriver(watch('users'));
    setIsCarPolicyModal(true);
  }, [watch]);

  if (isDelegateListLoading) {
    return (
      <Flex width='100%' justify='center'>
        <Spinner style={spinnerStyles} />
      </Flex>
    );
  }

  return (
    <>
      {isCarPolicyModal ? (
        <CarPoliciesForDriver
          handleGoBack={() => setIsCarPolicyModal(false)}
          closeAlert={onCloseModal}
          driver={watch('users') || valueFromInput}
          taskId={taskId}
          onSubmitDelegation={onSubmit}
        />
      ) : (
        <form style={{ margin: 0 }} onSubmit={handleSubmit(onSubmit)}>
          <Flex direction='column' style={{ position: 'relative' }}>
            <Text bottom='30'>Bitte wähle die Person aus, an die diese Aufgabe delegiert werden soll</Text>
            <Flex>
              <Text color='darkBlue'>Delegieren an...</Text>
              <Text color='pink'>*</Text>
            </Flex>
            <StyledInput
              type='text'
              {...register('users', { required: 'Bitte gib Kennzeichen ein.' })}
              placeholder='Bitte auswählen'
              value={searchValue}
              onChange={handleChange}
              onClick={() => {
                setSearchValue('');
                setValue('users', null);
              }}
              onFocus={() => {
                setVisible(true);
              }}
              autoComplete='off'
              id={PrefixForId.Modal + PrefixForId.Form + PrefixForId.Input}
            />
            <StyledDropdown style={{ visibility: visible ? 'visible' : 'hidden' }} ref={dropdownRef} top={120}>
              {visible && (
                <ul id={PrefixForId.Modal + PrefixForId.FormSelect}>
                  {availableUsers.length > 0 &&
                    filteredItems.map((el: SearchItemProps, index) => (
                      <StyledListItem key={el.id} onClick={() => selectItem(el)} disabled={!el.isValid}>
                        <div className='item_text1' id={PrefixForId.Modal + PrefixForId.FormOption + index}>
                          {el.name}
                        </div>
                      </StyledListItem>
                    ))}
                </ul>
              )}
            </StyledDropdown>
            <Flex justify='flex-end' top='30'>
              <Button secondary right='10' onClick={onCloseModal} id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Close}>
                Abbrechen
              </Button>
              {isDriverSelectedForRVO ? (
                <Button onClick={goToPoliciesModal}>Weiter</Button>
              ) : (
                <Button disabled={!watch('users')} type='submit' id={MODAL_BUTTON_ID_PREFIX + PrefixForId.Submit}>
                  Delegieren
                </Button>
              )}
            </Flex>
          </Flex>
        </form>
      )}
    </>
  );
};

export default DelegateTask;
