import { useEffect, useState } from 'react';
import { AnyObject } from 'common/interfaces';
import { defaultFahrzeugExpandSections } from 'pages/details/details-view';
import useReleaseData from './use-release-data';
import cloneDeep from 'lodash/cloneDeep';

const useVehicleExpandSections = () => {
  const [vehicleExpandSections, setVehicleExpandSections] = useState<AnyObject[]>(defaultFahrzeugExpandSections);

  const { vehicleOfferData, equipment } = useReleaseData();

  const onToggleVehicleExpandSections = (sectionName: string) => {
    const newVehicleExpandSections = cloneDeep(vehicleExpandSections);
    newVehicleExpandSections.forEach((section: AnyObject) => {
      if (section.name === sectionName) {
        section.open = !section.open;
        return;
      }
      section.open = false;
    });

    setVehicleExpandSections(newVehicleExpandSections);
  };

  useEffect(() => {
    if (equipment?.length && vehicleOfferData) {
      const newVehicleExpandSections = cloneDeep(vehicleExpandSections);
      const environmentSection = newVehicleExpandSections.find((section: AnyObject) => section.name === 'Umwelt')!;
      const optionalEquipmentSection = newVehicleExpandSections.find(
        (section: AnyObject) => section.name === 'Sonderausstattungen'
      )!;
      const standardEquipmentSection = newVehicleExpandSections.find(
        (section: AnyObject) => section.name === 'Serienausstattungen'
      )!;
      standardEquipmentSection.bodyData = equipment.filter(item => item.new_art?.value === 100000001);
      optionalEquipmentSection.bodyData = equipment.filter(item => item.new_art?.value === 100000002);
      environmentSection.bodyData = vehicleOfferData;
      setVehicleExpandSections(newVehicleExpandSections);
    }
  }, [equipment, vehicleOfferData]);

  return { vehicleExpandSections, onToggleVehicleExpandSections };
};

export default useVehicleExpandSections;
