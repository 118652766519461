import { Dispatch, SetStateAction, useState } from 'react';
import { ActiveElement, ChartData, ChartEvent, ChartOptions } from 'chart.js';
import { DoughnutDiagramProps, SegmentIndexType } from '../doughnut.props';
import { PickValue } from 'common/interfaces';

export const useDoughnutOptions = (
  defaultOptions: PickValue<DoughnutDiagramProps, 'options'>,
  filteredData: PickValue<DoughnutDiagramProps, 'data'>,
  setDoughnutData: Dispatch<SetStateAction<ChartData<'doughnut'>>>,
  defaultActiveSegmentIndex: number
) => {
  const [segmentIndex, setSegmentIndex] = useState<SegmentIndexType>({
    active: defaultActiveSegmentIndex,
    hovered: defaultActiveSegmentIndex
  });

  const setActiveSegment = (type: keyof SegmentIndexType, segmentIndex: number, setValue: number) => {
    setDoughnutData(prevState => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          offset: filteredData.map((_, index) => (index === segmentIndex ? setValue : 0))
        }
      ]
    }));
    setSegmentIndex(prevState => ({ ...prevState, [type]: segmentIndex }));
  };

  const onClick = (_: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0 && !defaultOptions?.excludeActiveSegments) {
      setActiveSegment('active', elements[0].index, 20);
    }
  };

  const onHover = (event: ChartEvent, elements: ActiveElement[]) => {
    const canvas = event.native && (event.native.target as HTMLCanvasElement);

    if (canvas && !defaultOptions?.excludeActiveSegments) {
      if (elements.length > 0) {
        if (segmentIndex.hovered !== elements[0].index) {
          setActiveSegment('hovered', elements[0].index, 20);
          canvas.style.cursor = 'pointer';
        }
        return;
      }

      if (segmentIndex.hovered !== segmentIndex.active) {
        setActiveSegment('hovered', segmentIndex.active, 20);
        canvas.style.cursor = 'default';
      }
    }
  };

  const doughnutOptions: ChartOptions<'doughnut'> = {
    cutout: '80%',
    maintainAspectRatio: false,
    aspectRatio: 1,
    onClick,
    onHover,
    ...defaultOptions,
    elements: { ...defaultOptions?.elements, arc: { borderJoinStyle: 'miter', ...defaultOptions?.elements?.arc } },
    plugins: {
      ...defaultOptions?.plugins,
      legend: { display: false, ...defaultOptions?.plugins?.legend },
      tooltip: { enabled: false, ...defaultOptions?.plugins?.tooltip }
    }
  };

  return { doughnutOptions, segmentIndex };
};
