import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { CloseIcon } from 'components/icons/close-icon';

interface StyledSnackbarComponentContainerProps {
  open: boolean;
}

interface StyledSnackbarComponentProps {
  backgroundColor: string;
  bottomBorderColor: string;
}

export const StyledSnackbarComponentContainer = styled.div<StyledSnackbarComponentContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1002;
  transition: all 0.3s ease-out;
  transform: translateY(100%);
  opacity: 0;

  ${({ open }) =>
    open &&
    css`
      transform: translateY(-20px);
      opacity: 1;
    `};
`;

export const StyledSnackbarComponent = styled(Flex)<StyledSnackbarComponentProps>`
  border-bottom: 2px solid ${({ bottomBorderColor }) => bottomBorderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 15px;
  border-radius: 3px;
  max-width: 400px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: relative;
  top: -4px;
  right: -4px;
  cursor: pointer;
`;
