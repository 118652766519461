import { Divider, Flex, Text } from 'common/common-components.styled';
import React, { CSSProperties, MutableRefObject, cloneElement } from 'react';
import { IFormInfo } from './form-info.props';
import { useTheme } from 'styled-components';
import { StyledAnchor, StyledContainer, StyledValue } from './form-info.styled';
import useEllipsisTooltip from 'hooks/use-ellipsis-tooltip';

export const FormInfo = ({
  title,
  value,
  icon,
  onClick,
  isClickable = false,
  isDivider = true,
  flexStyle = {},
  dividerStyle = {},
  ...restAnchorProps
}: IFormInfo) => {
  const { isShowTip, style, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip(0, 0);

  const theme = useTheme();

  return (
    <>
      <StyledContainer isClickable={isClickable} {...flexStyle} onClick={onClick}>
        <StyledAnchor {...restAnchorProps}>
          <Flex direction='column'>
            <Text color='grey900' bold bottom='5'>
              {title}
            </Text>
            <Flex position='relative'>
              <StyledValue
                ref={content as MutableRefObject<HTMLParagraphElement>}
                onMouseEnter={() => onMouseEnter()}
                onMouseLeave={onMouseLeave}
              >
                {value || '-'}
              </StyledValue>
              {isShowTip && <Text style={style as CSSProperties}>{value || '-'}</Text>}
            </Flex>
          </Flex>

          <Flex right='5'>{icon && cloneElement(icon, { color: theme.colors.blue })}</Flex>
        </StyledAnchor>
      </StyledContainer>

      {isDivider && <Divider {...dividerStyle} />}
    </>
  );
};
