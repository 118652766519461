import React, { useContext } from 'react';
import { SingleTabContext } from '../../single.context';
import { PanelItemController } from './components/panel-item/panel-item.controller';

export const PanelList = () => {
  const { data, activeTabIndex } = useContext(SingleTabContext);
  return (
    <>
      {data.map((item, index) => (
        <PanelItemController key={index} isActive={index === activeTabIndex}>
          {item.panel}
        </PanelItemController>
      ))}
    </>
  );
};
