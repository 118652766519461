import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { ReactQueryKeys } from 'common/enums';
import { useQueriesTyped } from 'hooks/use-queries-typed';

export const useDownloadFileQuery = (fileUrlList: string[]) => {
  const { fetch } = useFetch<Blob>({ isShowLoading: false });

  const data = useQueriesTyped(
    fileUrlList?.map(paramItem => ({
      queryKey: [ReactQueryKeys.FleetById, ReactQueryKeys.Logo, paramItem],
      queryFn: () =>
        fetch({
          loadingKey: `downloadFileQuery${paramItem}`,
          requestConfig: fleetRequestConfig.getFileForDownload(paramItem)
        })
    }))
  );

  return {
    downloadFileQuery:
      data.map(item => {
        const blob = new Blob([item.data?.data as BlobPart], { type: 'application/octet-stream' });

        return URL.createObjectURL(blob);
      }) || [],
    downloadFileQueryIsFetching: data.some(item => item.isFetching)
  };
};
