import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../../constants';
import { UnavailableRange } from './use-unavailable-date-ranges.types';
import { UnavailableRangeResponse } from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';
import moment from 'moment';

const modifyResponse = ({ data }: ApiResponse<UnavailableRangeResponse[]>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.map(range => ({ from: moment(range.from), to: moment(range.to) })) as UnavailableRange[];
  }
  return [];
};

const useUnavailableDateRanges = (carId: string | null, uds_poolkalenderid: string) => {
  const { fetch } = useFetch<ResponseModel<UnavailableRangeResponse[]>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...poolCalendarRequestConfig.getUnavailableDates(carId as string, uds_poolkalenderid)
  });

  const { data: unavailableRanges } = useQuery<ApiResponse<UnavailableRangeResponse[]>, unknown, UnavailableRange[]>(
    [ReactQueryKeys.GetUnavailableDateRanges, carId, uds_poolkalenderid],
    () => fetch(),
    {
      enabled: !!carId,
      select: modifyResponse
    }
  );

  return { unavailableRanges: unavailableRanges || [] };
};

export default useUnavailableDateRanges;
