import { Flex } from 'common/common-components.styled';
import { BarDiagram } from 'components/widgets/bar/bar';
import { BarDiagramData } from 'components/widgets/bar/bar.props';
import { useFleetGetVehicleConstQuery } from 'hooks/react-query/fleet/use-get-vehicle-costs/use-get-vehicle-costs';
import React from 'react';
import toMoney from 'utils/toMoney';

export const CostByYearBar = () => {
  const { fleetGetVehicleConstQuery, isFetching } = useFleetGetVehicleConstQuery();

  const currentYear = new Date().getFullYear();

  const barData: BarDiagramData = [
    { columnName: (currentYear - 1).toString(), value: fleetGetVehicleConstQuery?.pastYear || 0, color: 'blue' },
    { columnName: currentYear.toString(), value: fleetGetVehicleConstQuery?.currentYear || 0, color: 'blue' }
  ];

  if (isFetching) {
    return null;
  }

  return (
    <Flex width='80%' margin='10px auto 0'>
      <BarDiagram
        flexProps={{ width: '100%', height: '150px' }}
        data={barData}
        labelCallback={data => toMoney(+data)}
      />
    </Flex>
  );
};
