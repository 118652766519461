import { Flex, Text } from 'common/common-components.styled';
import InfoHint from 'components/info-hint/info-hint-view';
import PanelPlaceholder from 'components/panel-placeholder/panel-placeholder';
import { useGetUser } from 'hooks/react-query/use-get-user/use-get-user';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';

const PlaceholderHint = () => {
  const user = useTypedSelector(state => state['app'].user);
  const { userData } = useGetUser(user.id, false);
  const showHint = userData && (!userData.uds_carpolicynutzerid?.id || !userData.uds_carpolicyid?.id);

  return (
    <PanelPlaceholder title='Nichts gefunden' description='Diese Seite enthält keine Ergebnisse.'>
      {showHint && (
        <Flex width='50%'>
          <InfoHint type='warning'>
            <Text textAlign='left'>
              Dir wurde weder eine Car-Policy noch ein Mobilitätsbudget zugewiesen. Bitte kontaktiere deinen
              Fuhrparkleiter.
            </Text>
          </InfoHint>
        </Flex>
      )}
    </PanelPlaceholder>
  );
};

export default PlaceholderHint;
