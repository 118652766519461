/* eslint-disable max-len */
import React from 'react';
import { IconProps } from 'common/interfaces';

interface RecalculateCurrentCompanyIconProps extends IconProps {
  hoverColor?: string
}

export const RecalculateCurrentCompanyDataIcon = ({
  width = 25,
  height = 24,
  className,
  color = '#335566',
  hoverColor = 'FFF'
}: RecalculateCurrentCompanyIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' transform='translate(0.984375)' fill='white' />
      <path
        d='M21.0689 3.78909V7.85361H17.0044'
        stroke={hoverColor}
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.0471 7.85361C19.4358 5.40764 16.6705 3.78909 13.5205 3.78909C8.54873 3.78909 4.52051 7.81732 4.52051 12.7891C4.52051 17.7609 8.54873 21.7891 13.5205 21.7891C18.4923 21.7891 22.5205 17.7609 22.5205 12.7891'
        stroke={hoverColor}
        strokeWidth='1.6'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5328 3V7.06452H16.4683'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.511 7.06452C18.8997 4.61855 16.1344 3 12.9844 3C8.0126 3 3.98438 7.02823 3.98438 12C3.98438 16.9718 8.0126 21 12.9844 21C17.9561 21 21.9844 16.9718 21.9844 12'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};


