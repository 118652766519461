import { Action } from 'store/store.props';
import { SORTING } from '../actions/session_storage_action';

const initialState = {
  sorting: {}
};

export const sessionStorageReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SORTING:
      return { ...state, sorting: action.payload };

    default:
      return state;
  }
};
