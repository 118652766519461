import React, { useEffect, useState } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { User } from 'common/interfaces';
import { MenuItem } from '@mui/material';
import { defaultOptions } from 'components/form-hook/form-field/form-field';
import {
  StyledMainRoleDropDownIcon,
  StyledMainRoleSwitcher,
  StyledSpinnerContainer
} from 'components/main-role-switcher/main-role-switcher.styled';
import { useForm } from 'react-hook-form';
import Spinner from 'components/spinner';
import { MainRoleSwitcherProps } from 'components/main-role-switcher/main-role-switcher.props';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import useUnsavedChangesLink from 'hooks/use-unsaved-changes/use-unsaved-changes-link';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { contactRequestConfig } from 'request-config/contact/contact.request-config';
import { useDispatch } from 'react-redux';
import { setRoleSwitched } from 'actions/app_action';
import { useQueryClient } from 'react-query';
import { ReactQueryKeys } from 'common/enums';

const MainRoleSwitcher = ({ textColor }: MainRoleSwitcherProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const user = useTypedSelector<User>(state => state['app'].user);
  const [loading, setLoading] = useState(false);

  const reactHookFormData = useForm();
  const { register, watch, setValue } = reactHookFormData;

  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const { unsavedChangesHandler } = useUnsavedChangesLink();

  const { fetch: fetchSetRole } = useFetch({ loadingKey: 'setRole' });

  const mainRoleValue: string = watch('mainRole');
  const value: string = mainRoleValue || user.mainRole.id;

  useEffect(() => {
    const updateMainRole = async () => {
      setLoading(true);

      fetchSetRole({
        requestConfig: contactRequestConfig.setMainRole(value),
        callback: () => {
          queryClient.resetQueries([ReactQueryKeys.GetUserInfo]);
          localStorage.removeItem('selectedDrivers');

          setLoading(false);
          dispatch(setRoleSwitched(true));
        }
      });
    };

    if (user.mainRole.id === value) {
      return;
    }

    updateMainRole();
  }, [value]);

  return loading ? (
    <StyledSpinnerContainer justify='center'>
      <Spinner style={spinnerStyle} />
    </StyledSpinnerContainer>
  ) : (
    <StyledMainRoleSwitcher
      {...register('mainRole')}
      value={value}
      IconComponent={StyledMainRoleDropDownIcon}
      id='breadcrumbsRoleSwitcher'
      textcolor={textColor}
      onChange={e =>
        unsavedChangesHandler(() => openUnsavedChangesModal(() => setValue('mainRole', e.target.value, defaultOptions)))
      }
    >
      {user.roles
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map(option => (
          <MenuItem key={option.id} value={option.id} id={'breadcrumbsRoleSwitcherMenuItem' + option.id}>
            {option.name}
          </MenuItem>
        ))}
    </StyledMainRoleSwitcher>
  );
};

const spinnerStyle = {
  width: '20px',
  zIndex: 99
};

export default MainRoleSwitcher;
