import React from 'react';
import { DeactivateComponentProps } from './deactivate-component.types';
import { Button, Flex, Text } from 'common/common-components.styled';
import UnableToDeactivate from 'components/vehicle-offers-list-component/select-configuration-to-deactivate/unable-to-deactivate';
import { isShowInfoHint } from 'components/vehicle-offers-list-component/select-configuration-to-deactivate/unable-to-deactivate/unable-to-deactivate';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleOfferRequestConfig } from 'request-config/vehicle-offer/vehicle-offer.request-config';
import useDeactivationSnackbar from '../hooks/use-deactivation-snackbar';

const DeactivateComponent = ({ closeDialog, configuration, refreshList }: DeactivateComponentProps) => {
  const { fetch: deactivate } = useFetch({
    loadingKey: 'deactivateConfiguration',
    ...vehicleOfferRequestConfig.deactivate(configuration.uds_fahrzeugangeboteid)
  });
  const { showDeactivationSnackbar } = useDeactivationSnackbar();

  const deactivateConfiguration = async () => {
    closeDialog();
    const response = await deactivate();
    showDeactivationSnackbar({ response, deactivatedCallback: refreshList, notDeactivatedCallback: refreshList });
  };

  return (
    <div>
      <Text color='grey900'>Möchtest Du die Konfiguration wirklich archivieren?</Text>
      <UnableToDeactivate configuration={configuration} />

      <Flex justify='flex-end' top='20'>
        <Button right='10' onClick={closeDialog} secondary>
          Abbrechen
        </Button>
        <Button
          disabled={isShowInfoHint(configuration.uds_remove_request_date?.value)}
          onClick={deactivateConfiguration}
        >
          {configuration.canBeArchived ? 'Konfiguration archivieren' : 'Archivierung anfragen'}
        </Button>
      </Flex>
    </div>
  );
};

export default DeactivateComponent;
