import React from 'react';
import classes from './employee-configurations.module.scss';
import { BigFilterIconProps, BIG_FILTER_TITLES, FILTER_COLOR } from './employee-configurations.props';
import { Car } from 'components/icons-new/car';
import { PenIcon } from 'components/icons-new/pen';
import { ShoppingCart } from 'components/icons-new/shopping-cart';

export const getIcon = (title: BIG_FILTER_TITLES, color: FILTER_COLOR, isActive: boolean) => {
  switch (title) {
    case BIG_FILTER_TITLES.LEASING_OFFER_REQUEST:
      return <Car hovered={isActive} color={color} width='38px' height='30px' />;

    case BIG_FILTER_TITLES.LEASING_VEHICLE_REQUEST:
      return <PenIcon hovered={isActive} color={color} width='38px' height='38px' />;

    case BIG_FILTER_TITLES.RELEASE_ORDER:
      return <ShoppingCart hovered={isActive} color={color} width='36px' height='36px' />;

    default:
      return <span>-</span>;
  }
};

export const BigFilterIcon = ({ title, color, isActive }: BigFilterIconProps) => (
  <div className={classes.filter_circle} style={{ borderColor: color }}>
    {getIcon(title, color, isActive)}
  </div>
);
