import React, { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ControlledInput, CustomInput } from '../configuration-from-pdf.js';
import cloneDeep from 'lodash/cloneDeep.js';
import { CircleExclamationMark } from 'components/icons/crcle_exclamation_mark';
import {
  StyledErrorMessageDate,
  StyledTextField
} from 'components/configuration-from-pdf/delivery-date-component/delivery-date-component.styled';
import { useTheme } from 'styled-components';
import { Flex, Text } from 'common/common-components.styled';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { PrefixForId } from 'common/enums';

export interface DeliveryDateData {
  name: 'uds_wunschlieferdatum';
  crmFieldType: number;
  value: Date | null;
}

interface DeliveryDateProps {
  deliveryDate: DeliveryDateData;
  setDeliveryDate(data: DeliveryDateData): void;
  minDate: Date;
  maxDate: Date;
  formErrors: boolean;
}

const DeliveryDateComponent = ({ deliveryDate, setDeliveryDate, minDate, maxDate, formErrors }: DeliveryDateProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const onDeliveryDateButtonClick = (type: 'subtraction' | 'addition') => {
    let newDeliveryDate = cloneDeep(deliveryDate.value || minDate);
    switch (type) {
      case 'subtraction':
        newDeliveryDate.setMonth(newDeliveryDate.getMonth() - 1);
        if (
          newDeliveryDate.getFullYear() > minDate.getFullYear() ||
          (newDeliveryDate.getFullYear() === minDate.getFullYear() && newDeliveryDate.getMonth() >= minDate.getMonth())
        ) {
          setDeliveryDate({
            ...deliveryDate,
            value: newDeliveryDate
          });
        }
        break;
      case 'addition':
        newDeliveryDate.setMonth(newDeliveryDate.getMonth() + 1);
        if (
          newDeliveryDate.getFullYear() < maxDate.getFullYear() ||
          (newDeliveryDate.getFullYear() <= maxDate.getFullYear() && newDeliveryDate.getMonth() <= maxDate.getMonth())
        ) {
          setDeliveryDate({
            ...deliveryDate,
            value: newDeliveryDate
          });
        }
        break;
      default:
        break;
    }
  };

  const datePickerChange = (value: Moment | null) => {
    setDeliveryDate({
      ...deliveryDate,
      value: value?.startOf('month').toDate() || null
    });
  };

  return (
    <>
      <ControlledInput
        onButtonClick={onDeliveryDateButtonClick}
        prefixId='DeliveryDate'
        style={
          formErrors && !deliveryDate.value
            ? {
                border: `1px solid ${theme.colors.pink}`,
                boxShadow: `0 0 3px 0 ${theme.colors.pink}`
              }
            : {}
        }
        className={null}
      >
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              open={open}
              onClose={() => setOpen(false)}
              views={['month', 'year']}
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              value={deliveryDate.value}
              onChange={datePickerChange}
              PopperProps={{
                id: 'DeliveryDate' + PrefixForId.FormDate + PrefixForId.Calendar
              }}
              renderInput={({ inputProps, ...restParams }) => (
                <Flex align='center'>
                  <StyledTextField
                    {...restParams}
                    inputProps={{
                      ...inputProps
                    }}
                  />
                  <CustomInput
                    id={'DeliveryDate' + PrefixForId.FormDate}
                    onClick={() => setOpen(true)}
                    value={deliveryDate.value}
                  />
                </Flex>
              )}
            />
          </LocalizationProvider>
        </div>
      </ControlledInput>
      {formErrors && !deliveryDate.value && (
        <StyledErrorMessageDate>
          <span className='d-flex a-i-center'>
            <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
            &nbsp;
            <Text as='span' padding='2px 0 0'>
              Monat auswählen
            </Text>
          </span>
        </StyledErrorMessageDate>
      )}
    </>
  );
};

export default DeliveryDateComponent;
