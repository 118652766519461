import React, { useCallback, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import {
  StyledSnackbarContainer,
  StyledSnackbar,
  StyledIcon,
  StyledProgressbarContainer,
  StyledCloseIcon
} from './snackbar-view.styled';
import { Icon } from 'components/info-hint/components/icon';
import { Flex, Text } from 'common/common-components.styled';
import { IconType } from 'components/info-hint/info-hint.props';

interface SnackbarProps {
  open: boolean;
  type: string;
  time?: number;
  onClose: () => void;
  message: string;
}

const Snackbar = ({ open, type, time = 6000, onClose, message }: SnackbarProps) => {
  const theme = useTheme();

  const getState = useCallback(() => {
    switch (type) {
      case 'success':
        return {
          color: theme.colors.extraLightGreen,
          iconColor: theme.colors.darkGreen
        };
      case 'warning':
        return {
          color: theme.colors.extraLightGold,
          iconColor: theme.colors.darkGold
        };
      case 'error':
        return {
          color: theme.colors.extraLightPink,
          iconColor: theme.colors.pink
        };
      default:
        return {
          color: '#373737',
          iconColor: '#373737'
        };
    }
  }, [type, theme]);

  const [state, setState] = useState(getState());

  useEffect(() => {
    setState(getState());

    if (open && time) {
      setTimeout(() => {
        onClose();
      }, time);
    }
  }, [type, open, getState, onClose, time]);

  return (
    <StyledSnackbarContainer open={open}>
      <StyledSnackbar id='SnackbarComponentContainer' backgroundColor={state.color}>
        <Flex>
          <Flex align='flex-start' right='50'>
            <StyledIcon>
              <Icon type={type as IconType} />
            </StyledIcon>
            <Text id='SnackbarComponentText' color={theme.colors.grey900}>
              {message}
            </Text>
          </Flex>
          <Flex id='SnackbarComponentCloseButton' onClick={onClose}>
            <StyledCloseIcon width='20' height='20' color={state.iconColor} />
          </Flex>
        </Flex>
        <Flex>{open && <StyledProgressbarContainer timerValue={time} backgroundColor={state.iconColor} />}</Flex>
      </StyledSnackbar>
    </StyledSnackbarContainer>
  );
};

export default Snackbar;
