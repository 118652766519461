import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { setFormInfo } from 'actions/app_action';

export const useFormInfoItemEdit = (fieldLabel: string) => {
  const formInfo = useTypedSelector(state => state.app.formInfo);
  const dispatch = useDispatch();

  const onEdit = (isEdit: boolean) => {
    dispatch(setFormInfo({ opened: isEdit ? fieldLabel : '' }));
  };

  return { isEdit: formInfo?.opened === fieldLabel, onEdit };
};
