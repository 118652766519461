import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const TrashIcon = ({ color: colorProp, width = 25, height = 24, hovered, ...restProps }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg
      {...restProps}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {hovered && (
        <path
          d='M18.3229 7.93549V19.8387C18.3229 20.4774 17.8003 21 17.1616 21H7.87125C7.23254 21 6.70996 20.4774 6.70996 19.8387V7.93549'
          fill='#E6F7FF'
        />
      )}
      {hovered && (
        <path
          d='M9.32275 5.03226V3.87097C9.32275 3.39194 9.71469 3 10.1937 3H14.8389C15.3179 3 15.7099 3.39194 15.7099 3.87097V5.03226'
          fill='white'
        />
      )}
      <path
        d='M9.32275 5.03226V3.87097C9.32275 3.39194 9.71469 3 10.1937 3H14.8389C15.3179 3 15.7099 3.39194 15.7099 3.87097V5.03226'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5166 10.8387V17.8065'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.61328 10.8387V17.8065'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4199 10.8387V17.8065'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M15.1292 5.03224H18.3228C18.9615 5.03224 19.4841 5.55482 19.4841 6.19353V7.93547H5.54858V6.19353C5.54858 5.55482 6.07116 5.03224 6.70987 5.03224H12.5163'
          fill='white'
        />
      )}
      <path
        d='M15.1292 5.03224H18.3228C18.9615 5.03224 19.4841 5.55482 19.4841 6.19353V7.93547H5.54858V6.19353C5.54858 5.55482 6.07116 5.03224 6.70987 5.03224H12.5163'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3229 9.67742V19.8387C18.3229 20.4774 17.8003 21 17.1616 21H7.87125C7.23254 21 6.70996 20.4774 6.70996 19.8387V7.93549'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
