import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGES } from 'common/enums';
import { useSendRequest as useSendRequestMyRequestPage } from './pages/my-requests/use-send-request';
import { AnyObject } from 'common/interfaces';
import { useSubmitDamageEditForm } from 'pages/damage-detail-page/components/damage-edit-form/hooks/submit-damage-form/use-submit-damage-edit-form';

export const useSendFormRequest = () => {
  const { sendRequestMyRequestPage } = useSendRequestMyRequestPage(null);
  const { submitDamageEditFormHandler: submitDamageDetailsPage } = useSubmitDamageEditForm();
  const location = useLocation();

  const pathNameWithoutLastSlash = () => {
    if (location.pathname[location.pathname.length - 1] === '/') {
      return location.pathname.substring(0, location.pathname.length - 1);
    }

    return location.pathname;
  };

  const sendRequest = useCallback(
    async (data: AnyObject) => {
      switch (pathNameWithoutLastSlash()) {
        case PAGES.MY_REQUESTS:
          return sendRequestMyRequestPage(data);
        case PAGES.DAMAGE_DETAILS:
          return submitDamageDetailsPage(data);
        default:
          console.error('Missing request handler.');
          break;
      }
    },
    [location.pathname, submitDamageDetailsPage]
  );

  return { sendRequest };
};
