import { Dispatch, SetStateAction } from 'react';

export interface SelectDriverComponentProps {
  closeDialog: () => void;
  setSelectDriverData: Dispatch<SetStateAction<SelectDriverData>>;
}

export interface SelectDriverData {
  driverId: string | null;
  isPoolCar: boolean | null;
}

export type SelectDriverCallback = (selectDriverData: SelectDriverData) => void;

export enum CallbackTypes {
  PdfConfiguration = 'PdfConfiguration',
  GlobalConfiguration = 'GlobalConfiguration'
}
