import { useUserCompaniesOptionSet } from 'components/form-hook/form-field/hooks/use-user-companies-option-set';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { EditCostCenterFieldNames } from '../edit-cost-center.types';
import { errorMessages } from 'utils/error-messages';
import { useMemo } from 'react';

const useEditCostCenterFormFields = () => {
  const { userCompaniesOptionSet } = useUserCompaniesOptionSet();

  const editCostCenterFormFields = useMemo(
    () => [
      generateFormField.text({
        name: EditCostCenterFieldNames.Name,
        label: 'Kostenstelle Name',
        validation: {
          required: errorMessages.required
        }
      }),
      generateFormField.lookup({
        name: EditCostCenterFieldNames.Company,
        label: 'Unternehmen',
        disabled: true,
        options: userCompaniesOptionSet,
        validation: {
          required: errorMessages.required
        }
      })
    ],
    [userCompaniesOptionSet.length]
  );

  return { editCostCenterFormFields };
};

export default useEditCostCenterFormFields;
