import React from 'react';
import { Flex } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import { formFields } from './document-form.field';
import { DocumentFormSubmitButton } from './document-form.submit-button';
import { useForm } from 'react-hook-form';
import { VehicleDetailsDocumentFormFormData } from './document-form.props';

export const VehicleDetailsDocumentForm = () => {
  const reactHookFormData = useForm<VehicleDetailsDocumentFormFormData>();

  return (
    <Flex direction='column' width='100%'>
      <FormHook reactHookFormData={reactHookFormData} formFields={formFields} />

      <DocumentFormSubmitButton reactHookFormData={reactHookFormData} />
    </Flex>
  );
};
