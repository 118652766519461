export enum STEPS {
  InvoiceTable,
  InvoiceCreation,
  InvoiceEdit
}

export const CRM_VALUE = {
  rechnungNummer: 'Rechnungsnummer',
  name: 'Name',
  kreditor: 'Kreditor',
  status: 'Status',
  rechnungDate: 'rechnungDate',
  createdon: 'createdon'
};

export const emtyFilterState = {
  [CRM_VALUE.status]: null,
  [CRM_VALUE.name]: null,
  [CRM_VALUE.kreditor]: null,
  [CRM_VALUE.status]: null,
  [CRM_VALUE.rechnungDate]: null,
  [CRM_VALUE.rechnungNummer]: null,
  createdon: null
};
