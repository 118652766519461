import { useContext } from 'react';
import { USER_ROLES } from '../../../../../../constants';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export const useLeasingPeriodWidgetIsShow = () => {
  const { vehicle } = useContext(VehicleDetailContext);

  const isWidgetAvailableForRole = useIsUserHaveRole([
    USER_ROLES.fleetManager.id,
    USER_ROLES.administrator.id,
    USER_ROLES.poolManager.id
  ]);

  const isWidgetAvailableForVehicleStatus =
    vehicle.fuhrpark?.new_status_carcode?.attributeValue?.value !== VehicleStatusesValue.Ordered;

  return { leasingPeriodWidgetIsShow: isWidgetAvailableForRole && isWidgetAvailableForVehicleStatus };
};
