import React from 'react';
import classes from './wall-e-bussines-partner-promotion.module.scss';
import SideMenuRightContainer from 'components/side-menu-right-container';
import ContactsWidget from 'pages/packages-and-prices-page/contacts-widget';
import { WallEBussinesPartnerPromotionMainSection } from './components/sections/main/main';
import { WallEBussinesPartnerPromotionFAQ } from './components/widgets/faq/faq';

export const WallEBussinesPartnerPromotion = () => {
  return (
    <SideMenuRightContainer className={classes.container}>
      <WallEBussinesPartnerPromotionMainSection />

      <div className={classes.widget_container}>
        <ContactsWidget flex={1} />
        <WallEBussinesPartnerPromotionFAQ />
      </div>
    </SideMenuRightContainer>
  );
};
