import { CrmDataTypes } from 'common/enums';
import { CrmName } from '../../task.prop';

export const allFieldsCreateTask = [
  {
    name: CrmName.TaskType,
    crmFieldType: CrmDataTypes.Picklist
  },
  {
    name: CrmName.Title,
    crmFieldType: CrmDataTypes.String
  },
  {
    name: CrmName.Description,
    crmFieldType: CrmDataTypes.String
  },
  {
    name: CrmName.Employee,
    crmFieldType: CrmDataTypes.Lookup
  },
  {
    name: CrmName.Vehicle,
    crmFieldType: CrmDataTypes.Lookup
  },
  {
    name: CrmName.DueDate,
    crmFieldType: CrmDataTypes.DateTime
  }
];
