import React, { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { StyledNoteItemContainer } from './note-item.styled';

export const NoteItemContainer = ({ children }: PropsWithChildren) => {
  return (
    <Grid item xs={1}>
      <StyledNoteItemContainer>{children}</StyledNoteItemContainer>
    </Grid>
  );
};
