import styled, { css } from 'styled-components';

interface StyledThreeDotMenuProps {
  hide?: boolean;
}

interface DotInterface {
  color: string;
}

export const StyledThreeDotMenu = styled.div<StyledThreeDotMenuProps>`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ hide }) =>
    hide &&
    css`
      cursor: default;
      opacity: 0;
    `};
`;

export const StyledMenuDot = styled.div<DotInterface>`
  width: 5px;
  height: 5px;
  background-color: ${({ color, theme }) => theme.colors[color]};
  border-radius: 50%;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const StyledMenuContainer = styled.div<Partial<Record<'width', number>>>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 4px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 1px 4px 20px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
  left: ${({ width }) => (width ? `-${width}px` : '-120px')};
  top: 20px;
`;

interface MenuItem {
  disabled?: boolean;
}

export const StyledMenuItem = styled.div<MenuItem>`
  cursor: pointer;
  padding: 11px 20px;
  display: flex;
  justify-content: flex-start;

  p {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.grey50};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;

      p {
        color: ${({ theme }) => theme.colors.grey300};
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.white};
      }
    `};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  }
`;
