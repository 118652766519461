import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const GasStationIcon = ({ width = 19, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M13.6626 2.74197L16.5665 5.35488L17.728 6.05165C17.9022 6.16778 18.0184 6.34198 18.0184 6.5452V10.5807H17.5828C17.3215 10.5807 17.0892 10.4065 17.0311 10.1452L16.2761 7.38714L15.4747 7.16809L15.2981 6.24635L13.6626 4.48391'
          fill={fill}
        />
      )}
      <path
        d='M13.6626 2.74197L16.5665 5.35488L17.728 6.05165C17.9022 6.16778 18.0184 6.34198 18.0184 6.5452V10.5807H17.5828C17.3215 10.5807 17.0892 10.4065 17.0311 10.1452L16.2761 7.38714L15.4747 7.16809L15.2981 6.24635L13.6626 4.48391'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M2.62793 17.8387V1.91847C2.62793 1.39801 3.00543 1 3.49909 1H12.7915C13.2851 1 13.6626 1.39801 13.6626 1.91847V17.8387'
          fill={fill}
        />
      )}
      <path
        d='M2.62793 17.8387V1.91847C2.62793 1.39801 3.00543 1 3.49909 1H12.7915C13.2851 1 13.6626 1.39801 13.6626 1.91847V17.8387'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M1.46631 19V17.8387H14.8241V19' fill={hovered ? 'white' : 'none'} />
      <path
        d='M1.46631 19V17.8387H14.8241V19'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6297 3.03229H4.6604V6.51616H11.6297V3.03229Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.6626 10.5807H14.679C15.2307 10.5807 15.6953 11.0452 15.6953 11.5968V15.6613C15.6953 16.2129 16.1599 16.6775 16.7117 16.6775C17.2634 16.6775 17.728 16.2129 17.728 15.6613V10.5807'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
