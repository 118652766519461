import { CostCentersHistoryItem } from '../cost-centers-widget.types';
import moment from 'moment';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { ChangeCostCenterFieldNames } from 'components/cost-centers-settings/change-cost-center/change-cost-center.types';

const useHistoryItemValues = (item: CostCentersHistoryItem) => {
  const validFromDate = item[ChangeCostCenterFieldNames.ValidFrom];
  const validToDate = item[ChangeCostCenterFieldNames.ValidTo];
  const startInFuture = moment(validFromDate).isAfter(moment());

  const firstDate = getDateString(validFromDate);
  const secondDate =
    validToDate &&
    new Date(validToDate).toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

  return { startInFuture, validToDate, firstDate, secondDate };
};

export default useHistoryItemValues;
