import React, { useState } from 'react';
import { MultipleSection } from 'components/sections/multiple-section/multiple-section';
import HistoryWidget from './components/sections/history-widget/history-widget';
import CostCentersWidget from 'pages/existing-vehicle-page/components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import { HistorySectionItem } from './components/section-item/section-item';
import { VehicleDetailsDamageHistory } from './components/sections/damage/damage';
import { useVehicleHistorySectionsConditions } from './hooks/use-sections-conditions';
import useWindowSize from 'hooks/use-window-size';
import { VehicleDetailsVehicleHistoryMobileVersion } from './components/mobile-version/mobile-version';

export const VehicleDetailsVehicleHistoryTabPanel = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isDisplayDriverHistory, isShowDamageHistory, isDisplayShoreMoreButton } =
    useVehicleHistorySectionsConditions();

  const { isMobile } = useWindowSize();

  if (isMobile) {
    return <VehicleDetailsVehicleHistoryMobileVersion />;
  }

  return (
    <MultipleSection
      label='Fahrzeug Historie'
      headerProps={{ bottom: '40', top: isMobile ? '30' : undefined }}
      hideNodeList={[!isDisplayDriverHistory, false, !isShowDamageHistory]}
      nodeList={[
        <HistorySectionItem key='driver' id='driverHistory' title='Fahrer Historie'>
          <HistoryWidget isExpanded={isExpanded} isShowExpandButton={false} />
        </HistorySectionItem>,
        <HistorySectionItem key='costCenter' id='costCenterHistory' title='Kostenstellen Historie'>
          <CostCentersWidget isExpanded={isExpanded} isShowExpandButton={false} />
        </HistorySectionItem>,
        <VehicleDetailsDamageHistory key='damage' isExpanded={isExpanded} />
      ]}
    >
      {isDisplayShoreMoreButton && (
        <ShowMoreButton isExpanded={isExpanded} onClick={() => setIsExpanded(prevState => !prevState)} top='30' />
      )}
    </MultipleSection>
  );
};
