import { AxiosRequestConfig } from 'axios';
import {
  CreatePoolCarClassData,
  GetAvailableCarsData,
  GetCarsListData,
  UpdateBookingRequestData,
  UpdatePoolCarClassData,
  UpdatePoolCarDriversData
} from 'request-config/pool-calendar/pool-calendar.types';
import { TableParams } from 'components/pool-car-booking/bookings-page';
import { SessionFilter } from 'hooks/use-session-filter';
import { FilterNames } from 'components/pool-car-booking/bookings-page';
import moment from 'moment';
import { IPagination } from 'hooks/use-table/use-table.props';

class PoolCalendarRequestConfig {
  getPoolCalendarReport = (tableParams: TableParams, sessionFilter: SessionFilter): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getPoolkalendarReport',
    responseType: 'blob',
    data: {
      SearchParam: {
        [FilterNames.StartDate]: sessionFilter.filterForServer?.date?.from
          ? moment(sessionFilter.filterForServer?.date?.from).toISOString()
          : null,
        [FilterNames.EndDate]: sessionFilter.filterForServer?.date?.to
          ? moment(sessionFilter.filterForServer?.date?.to).toISOString()
          : null,
        [FilterNames.Status]: sessionFilter.filterForServer?.[FilterNames.Status],
        [FilterNames.Booking]: sessionFilter.filterForServer?.[FilterNames.Booking],
        [FilterNames.Mark]: sessionFilter.filterForServer?.[FilterNames.Mark],
        [FilterNames.Location]: sessionFilter.filterForServer?.[FilterNames.Location],
        [FilterNames.Driver]: sessionFilter.filterForServer?.[FilterNames.Driver]
      },
      PageNumber: tableParams.pagination?.start,
      CountOnPage: tableParams.pagination?.number,
      Sort: {
        Predicate: tableParams.sort.predicate,
        Reverse: tableParams.sort.reverse
      }
    }
  });

  getAvailableCars = (data: GetAvailableCarsData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getavailablecars',
    data
  });

  getCarInfo = (carId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getCarInfo',
    data: {
      carId
    }
  });

  updatePoolCarBooking = (data: UpdateBookingRequestData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/update',
    data
  });

  getUnavailableDates = (carId: string, PoolKalenderId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getUnavailableDates',
    data: {
      carId,
      PoolKalenderId
    }
  });

  getPoolCarBookingSettings = (companyId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getSettings',
    data: {
      id: companyId
    }
  });

  updatePoolCarClass = (data: UpdatePoolCarClassData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/updateClass',
    data
  });

  createPoolCarClass = (data: CreatePoolCarClassData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/createClass',
    data
  });

  getPoolCarSettingsDrivers = (companyId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getApprovalContacts',
    data: {
      id: companyId
    }
  });

  updatePoolCarSettingsDrivers = (data: UpdatePoolCarDriversData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/updateApprovalContacts',
    data
  });

  getPersonsForStandorts = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/GetPersonsForStandorts',
    data: {}
  });

  getClasses = (companyId: string, pagination?: IPagination): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/GetClasses',
    data: { pagination, id: companyId }
  });

  getCarsList = (data: GetCarsListData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/GetCarsList',
    data
  });

  getCarsFilters = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/GetCarsFilters',
    data: {}
  });

  isFutureBookingExist = (carId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/IsFutureBookingExist',
    data: {
      id: carId
    }
  });

  cancelFutureBookings = (carId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/CancelFutureBookings',
    data: {
      id: carId
    }
  });

  getDriversForBooking = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/GetDriversForBooking',
    data: {}
  });

  getAvailableCarsFilterData = (): AxiosRequestConfig => ({
    method: 'POST',
    url: 'poolkalender/getavailablecarsfilters',
    data: {}
  });
}

export const poolCalendarRequestConfig = new PoolCalendarRequestConfig();
