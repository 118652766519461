import React from 'react';
import { FlagIcon } from 'components/icons-new/flag';
import { Flex, Text } from 'common/common-components.styled';

export const ReturnLeaseTimeHasComePlaceholder = () => {
  return (
    <Flex id='leasingReturnLeaseTimeHasComePlaceholder' direction='column' align='center' >
      <FlagIcon />
      <Text color='blue' size='16' textAlign='center' bold top='10'>
        Leasingvertrag ist abgelaufen
      </Text>
    </Flex>
  );
};
