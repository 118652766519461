import { FormFieldTypes, PAGES } from 'common/enums';
import moment from 'moment';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const useFilledPhaseConttent = (dontHaveHandoverProtocol: boolean) => {
  const history = useHistory();
  const { car } = useContext(LeasingWidgetsContext);
  const vehicleId = car.vr.vr_phaseinfo.fuhrparkId;

  const redirectToOrder = () => {
    history.push(`${PAGES.ASSIGNMENTS_LEASE_RETURN_PRO}?id=${vehicleId}`);
  };

  const handoverDate = useMemo(() => {
    return [
      {
        name: 'dateVehicleReturned',
        type: FormFieldTypes.Date,
        defaultValue: null,
        label: 'Datum der Fahrzeugübergabe',
        disabled: !dontHaveHandoverProtocol,
        validation: {
          required: 'Bitte auswählen'
        },
        maxDate: moment()
      }
    ];
  }, [dontHaveHandoverProtocol]);

  return {
    handoverDate,
    redirectToOrder
  };
};
