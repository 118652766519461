import { Flex, Text } from 'common/common-components.styled';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import { OptionSetFileContext } from '../../option-set-file.context';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';

export const AddFormButton = () => {
  const { values, onChange } = useContext(OptionSetFileContext);
  const { field } = useContext(formFieldProvider);
  const { options, maxEditionFormLength } = field;
  const theme = useTheme();

  const addNewForm = () => {
    onChange([...values, { documentType: null, files: [], id: values[values.length - 1].id + 1 }]);
  };

  const isHideButton = () => {
    if (values.length === options?.length) {
      return true;
    }

    return maxEditionFormLength && values.length === maxEditionFormLength;
  };

  if (isHideButton()) {
    return null;
  }

  return (
    <Flex
      id={`${field.name}AddNewFieldButton`}
      onClick={addNewForm}
      flex={1}
      align='center'
      justify='center'
      pointer
      top='145'
      bottom='60'
    >
      <PlusLargeIcon color={theme.colors.darkBlue} />
      <Text color='darkBlue' left='10'>
        Weitere Datei hochladen
      </Text>
    </Flex>
  );
};
