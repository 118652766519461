import React from 'react';
import { VehicleDetailsEditFormProps } from './edit-form.props';
import { EditForm } from 'components/form-hook/components/edit-form/edit-form';
import { useForm } from 'react-hook-form';
import { useVehicleDetailsSubmitForm } from 'pages/existing-vehicle-page/hooks/use-submit-form/use-submit-form';

export const VehicleDetailsEditForm = ({
  submitCallback,
  onEdit,
  formFields,
  ...restProps
}: VehicleDetailsEditFormProps) => {
  const reactHookFormData = useForm();
  const { onSubmit } = useVehicleDetailsSubmitForm();

  return (
    <EditForm<Record<string, string>>
      idPrefix='vehicleDetail'
      containerFlex={{ margin: '10px 0' }}
      formFields={formFields}
      {...restProps}
      reactHookFormData={reactHookFormData}
      onClose={() => onEdit && onEdit(false)}
      onSubmit={values =>
        onSubmit({
          values,
          formFields,
          callbackBeforeRequest: () => {
            if (submitCallback && submitCallback({ values, reactHookFormData, formFields })) {
              onEdit && onEdit(false);
              return true;
            }

            onEdit && onEdit(false);

            return false;
          }
        })
      }
    />
  );
};
