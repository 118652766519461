import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';

export const extendWithDateFormFields: FormFieldModel[] = [
  {
    name: LeasingContractFields.AddedMonths,
    type: FormFieldTypes.Months,
    defaultValue: null,
    label: 'Verlängern bis',
    validation: {
      required: 'Bitte auswählen'
    }
  }
];