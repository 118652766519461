import { RequestStatuses } from '../../../constants';
import { getResponseFormData } from 'utils/get-response-data';
import { useQuery } from 'react-query';
import { AnyObject } from 'common/interfaces';
import { useEffect, useState } from 'react';
import { getLeaseData } from 'services/company-service';
import { ReactQueryKeys } from 'common/enums';
import { durationOptions, mileageRangeOptions } from 'pages/company-settings-page/company-settings-component/utils';

export const useUserCompaniesDetails = (companyId: string) => {
  const [duration, setDuration] = useState('');
  const [mileage, setMileage] = useState('');

  const modifyResponse = ({ data }: any) => {
    if (data.level === RequestStatuses.Success) {
      const resultArray = getResponseFormData(data, [{ name: null, type: 'array' }], true) as AnyObject[];
      return resultArray[0];
    }
    return {};
  };

  const {
    data: leaseLengthMileageDetails,
    isFetching: isLeaseLengthMileageDetailsFetching,
    refetch: leaseLengthMileageDetailsRefetch
  } = useQuery([ReactQueryKeys.LeaseLengthMileageDetails, companyId], () => getLeaseData(companyId), {
    select: modifyResponse,
    enabled: !!companyId
  });

  useEffect(() => {
    if (leaseLengthMileageDetails) {
      const durationResultArray: string[] = [];
      const durationValues = Object.entries(durationOptions).map(([key, value]) => ({
        label: value.label,
        value: leaseLengthMileageDetails[key]
      }));
      durationValues.forEach(value => {
        if (value.value) {
          durationResultArray.push(value.label);
        }
      });

      const mileageResultArray: number[] = [];
      Object.entries(mileageRangeOptions).forEach(([key, value]) => {
        if (leaseLengthMileageDetails[key]) {
          mileageResultArray.push(value.value);
        }
      });

      setDuration(durationResultArray.join(', '));
      setMileage(mileageResultArray.map(item => item.toLocaleString('de-DE')).join(', '));
    }
  }, [!!leaseLengthMileageDetails]);

  return {
    duration,
    mileage,
    isLeaseLengthMileageDetailsFetching,
    leaseLengthMileageDetails,
    leaseLengthMileageDetailsRefetch
  };
};
