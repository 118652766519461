import React from 'react';
import { SelectConfigurationToDeactivateProps } from './select-configuration-to-deactivate.types';
import { Button, Flex, Text } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import useConfigurationsFields from '../hooks/use-configurations-fields';
import ConfigurationInfo from './configuration-info';
import UnableToDeactivate from './unable-to-deactivate';
import { isShowInfoHint } from './unable-to-deactivate/unable-to-deactivate';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleOfferRequestConfig } from 'request-config/vehicle-offer/vehicle-offer.request-config';
import useDeactivationSnackbar from 'components/product/hooks/use-deactivation-snackbar';

const SelectConfigurationToDeactivate = ({
  closeDialog,
  configurations,
  openNewConfigDialog,
  refreshList
}: SelectConfigurationToDeactivateProps) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { isValid },
    getValues
  } = reactHookFormData;
  const { configurationFields } = useConfigurationsFields(configurations);
  const { fetch: deactivate } = useFetch({
    loadingKey: 'deactivateConfiguration'
  });
  const { showDeactivationSnackbar } = useDeactivationSnackbar();

  const selectedConfigurationId: string | undefined = getValues('configuration');
  const selectedConfiguration = configurations.find(
    configuration => configuration.uds_fahrzeugangeboteid === selectedConfigurationId
  );

  const submit = () => {
    handleSubmit(async data => {
      closeDialog();
      const response = await deactivate({
        requestConfig: vehicleOfferRequestConfig.deactivate(data.configuration)
      });
      showDeactivationSnackbar({
        response,
        deactivatedCallback: () => {
          refreshList();
          openNewConfigDialog();
        },
        notDeactivatedCallback: refreshList
      });
    })();
  };

  return (
    <div>
      <Text color='grey900' bottom='20'>
        Du hast die maximale Anzahl an Konfigurationen erreicht. Bitte archiviere eine Konfiguration oder klicke den
        Button <b>Archivierung anfragen</b>. In diesem Fall wird ein Mobexo-Mitarbeiter deine Anfrage prüfen.
      </Text>
      <Text color='grey900' bottom='20'>
        Wähle eine Konfiguration zum Archivieren aus:
      </Text>

      <FormHook reactHookFormData={reactHookFormData} formFields={configurationFields} />

      <ConfigurationInfo configuration={selectedConfiguration} />

      <UnableToDeactivate configuration={selectedConfiguration} />

      <Flex justify='flex-end' top='25'>
        <Button right='10' onClick={closeDialog} secondary>
          Abbrechen
        </Button>
        <Button
          disabled={!isValid || isShowInfoHint(selectedConfiguration?.uds_remove_request_date?.value)}
          onClick={submit}
        >
          {selectedConfiguration?.canBeArchived ? 'Archivieren & fortfahren' : 'Archivierung anfragen'}
        </Button>
      </Flex>
    </div>
  );
};

export default SelectConfigurationToDeactivate;
