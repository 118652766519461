import { useFleetLeasingPeriodQuery } from 'hooks/react-query/fleet/use-leasing-period/use-leasing-period';
import { LeasingPeriodWidgetDateProps } from '../components/date-list/date.props';
import { PickValue } from 'common/interfaces';

export const useLeasingPeriodDateData = () => {
  const { fleetLeasingPeriodQuery } = useFleetLeasingPeriodQuery();

  const leasingPeriodDateData: PickValue<LeasingPeriodWidgetDateProps, 'data'> = [
    { title: 'Leasingende', date: fleetLeasingPeriodQuery.uds_lease_return_date },
    { title: 'Vertragsbeginn', date: fleetLeasingPeriodQuery.new_leasingstart }
  ];

  return { leasingPeriodDateData };
};
