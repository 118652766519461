import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import classes from './lead-form.module.scss';
import { createLead } from '../../services/lead-service';
import { ContactForm } from './contact-form';
import { ThankYou } from './thank-you';
import ReactGA from 'react-ga';
import cloneDeep from 'lodash/cloneDeep';

ReactGA.initialize('AW-600353328');

class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      submmited: false,
      checkboxes: [
        {
          name: 'Fuhrparkmanagement',
          checked: false
        },
        {
          name: 'Aktuelle Fahrzeugangebote',
          checked: false
        },
        {
          name: 'Flottenversicherung',
          checked: false
        },
        {
          name: 'Schadenmanagement',
          checked: false
        }
      ]
    };
    this.submit = this.submit.bind(this);
  }

  async submit(form) {
    this.setState({ submitting: true });
    const modalParameters = this.props.parameters;

    let themes = [];
    this.state.checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        themes.push(checkbox.name);
      }
    });
    let themesStr = themes.length ? `Themen: ${themes.join(', ')} | ` : '';

    let fahrzeugeStr = form.fahrzeuge ? `Anzahl Fahrzeuge: ${form.fahrzeuge} | ` : '';

    const createLeadCommand = {
      ...form,
      message: `${fahrzeugeStr}${themesStr}${form.message} (${modalParameters.message || ''}) (${
        modalParameters.location
      })`,
      subject: modalParameters.vehicleId || window.location.href,
      vehicleId: modalParameters.vehicleId
    };

    try {
      await createLead(createLeadCommand);
      this.setState({ submitted: true });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false });
    }
  }

  phoneClicked = () => {
    ReactGA.event({ category: 'phone_conversion_number', action: '023462209410' });
  };

  onCheckboxChange = e => {
    const newCheckboxes = cloneDeep(this.state.checkboxes);
    const target = newCheckboxes.find(checkbox => checkbox.name === e.target.name);
    target.checked = e.target.checked;

    this.setState({ checkboxes: newCheckboxes });
  };

  render() {
    return (
      <div className={classes.body}>
        <h1 className={classes.form_header}>{this.props.parameters.title}</h1>
        <img
          src='/assets/images/close-icon-configurator.png'
          className={classes.close_icon}
          alt='close'
          onClick={this.props.closeModal}
        />

        <div className={classes.form_wrap}>
          <div className={classes.left_column}>
            <div className={classes.contact}>
              <img className={classes.avatar} src='/assets/images/dennis.jpg' alt='author' />
              <div className={classes.info}>
                <p>Ihr Ansprechpartner:</p>
                <div className={classes.name}>Dennis Podlech</div>
                <a onClick={this.phoneClicked} href='tel:023462209410' className={classes.phone}>
                  <img src='/assets/images/phone_icon.png' alt='Phone' />
                  <span>0234-6220-9410</span>
                </a>
              </div>
            </div>
            <div className={classes.divider} />
            <div className={classes.data}>
              <p>Sie wünschen unverbindlich weitere Informationen zu folgenden Themen:</p>
              {this.state.checkboxes.map(checkbox => (
                <div key={checkbox.name}>
                  <label className={classes.checkbox_label}>
                    <input
                      name={checkbox.name}
                      checked={checkbox.checked}
                      onChange={this.onCheckboxChange}
                      type='checkbox'
                    />
                    {checkbox.name}
                  </label>
                </div>
              ))}
              <p>Wir melden uns schnellstmöglich bei Ihnen.</p>
              <p>
                Wir verwenden Ihre personenbezogenen Daten ausschließlich im Rahmen der Bearbeitung Ihrer
                Angebotsanfrage bzw. zur Kontaktaufnahme mit Ihnen (Art. 6 Abs. 1 lit. b DSGVO). Weitere
                datenschutzrechtliche Informationen entnehmen Sie bitte unserer{' '}
                <a href='https://portal.mobexo.de/datenschutz/' target='_blank'>
                  Datenschutzerklärung
                </a>
              </p>
            </div>
          </div>
          <div className={classes.right_column}>
            {this.state.submitted ? (
              <ThankYou />
            ) : (
              <ContactForm
                submitting={this.state.submitting}
                title={this.props.parameters.title}
                onSubmit={this.submit}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LeadForm.defaultProps = {
  parameters: {}
};

LeadForm.propTypes = {
  parameters: PropTypes.any
};

export default LeadForm;
