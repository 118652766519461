import React, { Children, forwardRef, useContext } from 'react';
import { Flex, FlexProps } from 'common/common-components.styled';
import IconHover from 'components/icon-hover';
import { FilterExpandIcon } from 'components/icons-new/filter-expand';
import { FilterExpandContainerContext } from '../filter-expand-container.context';
import classNames from 'classnames';
import useWindowSize from 'hooks/use-window-size';

export const FilterExpandContainerExpandButton = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const filterExpandContainerContextProps = useContext(FilterExpandContainerContext);
  const { setIsFilterExpanded, rightComponent, isHideExpandedButton, children } = filterExpandContainerContextProps;

  const { windowWidth } = useWindowSize(500);

  return (
    <Flex ref={ref} {...props} order={Children.toArray(children).length}>
      {!isHideExpandedButton && (
        <IconHover onClick={() => setIsFilterExpanded(prevState => !prevState)}>
          <FilterExpandIcon />
        </IconHover>
      )}

      {rightComponent &&
        rightComponent({
          className: classNames('mb-0', windowWidth > 550 && 'ml-10'),
          ...filterExpandContainerContextProps
        })}
    </Flex>
  );
});
