import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UnauthorizedPageContainer } from 'components/unauthorized-page-container';
import { useOverlay, useQuery } from 'hooks';
import { getContractPdf, getOrderData, reOrderLink, sign } from './signing-external-service';
import {
  PrivacyStyledLink,
  SigningContentWrapper,
  StyledErrorContainer,
  StyledErrorContentContainer,
  StyledErrorIconContainer,
  StyledMobexoLink,
  SugningStyledContainer,
  TitileStyledContainer
} from './signing-external.styled';
import { Button, Flex, Text } from 'common/common-components.styled';
import {
  StyledDocumentContainer,
  StyledList,
  StyledListItem,
  StyledScrollDownButton
} from 'pages/package-page/components/contract-conclusion-component/contract-conclusion-component.styled';
import { PackageTypes, UNAUTHORIZED_PAGES } from 'common/enums';
import { basicListItems, professionalListItems } from 'pages/package-page/constants';
import {
  StyledNumberText,
  StyledPackageContainer
} from 'pages/package-page/components/check-order-component/check-order-component.styled';
import FileViewer from 'react-file-viewer';
import ThreeStateCheckbox from 'components/three-state-checkbox/three-state-checkbox';
import { formatDate } from 'helpers';
import { WidgetDocumnetContainer } from 'components/widget-documnet/widget-documnet-container';
import { WidgetType } from 'components/widget-documnet/widget-documnet-container.props';
import { mobexoInfoEmail, mobexoPhoneNumber, RequestStatuses } from '../../constants';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { SummaryItem } from 'pages/package-page/components/summary-component/summary-component';
import {
  StyledAddonsContainer,
  StyledPaymentContainer
} from 'pages/package-page/components/summary-component/summary-component.styled';
import { StyledRightContainer } from 'pages/package-page/package-page.styled';
import classes from './signing-external-page.module.scss';
import DoneIcon from 'components/icons/done-icon';
import { cloneDeep } from 'lodash';
import SigningExternalPageStepperIcon from 'components/icons/signing_external_page_stepper_icon';
import HelmetComponent from 'components/helmet-component/helmet-component';
import WidgetHeader from 'components/widget-header/widget-header';
import Download from 'components/icons/download';
import { useFileDownload } from 'hooks/useFileDownload';
import { crmCheckoutRequestConfig } from 'request-config/crm-checkout/crm-checkout.request-config';

type StepperData = {
  isCurrentUser: boolean;
  isSigned: boolean;
  initials: string;
};

function SigningExternalCompopnent() {
  const query = useQuery();
  const [showOverlay, hideOverlay] = useOverlay();
  const [contract, setContract] = useState<any>(null);
  const [contractPdf, setContractPdf] = useState<string>();
  const isProfessionalOrComfortPackage =
    contract?.packageName === PackageTypes.Professional || contract?.packageName === PackageTypes.Comfort;
  const listItems = isProfessionalOrComfortPackage ? professionalListItems : basicListItems;
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isSignatoryChecked, setIsSignatoryChecked] = useState(false);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const isSubmitButtonDisabled = [isScrolledToBottom, isSignatoryChecked, isPrivacyPolicyChecked].includes(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [isContractExist, setIsContractExist] = useState(true);
  const [successNotification, setSuccessNotification] = useState<string | JSX.Element>('');
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const showStepper = contract && !contract?.isSigned && contract?.stepperData?.length > 1;

  const onScroll = () => {
    const el = scrollRef.current?.querySelector('.pg-viewer-wrapper') as HTMLElement;
    if (el.scrollTop + el.offsetHeight >= el.scrollHeight) {
      setIsScrolledToBottom(true);
    }
  };

  const handleScrollToBottom = useCallback(() => {
    const el = scrollRef.current?.querySelector('.pg-viewer-wrapper') as HTMLElement;
    el.scrollTop = el.scrollHeight;
    setIsScrolledToBottom(true);
  }, []);

  useEffect(() => {
    const el = scrollRef.current?.querySelector('.pg-viewer-wrapper') as HTMLElement;
    if (el) {
      el.addEventListener('scroll', onScroll);
    }

    return () => {
      if (el) {
        el.removeEventListener('scroll', onScroll);
      }
    };
  }, [contractPdf]);

  const getContractData = async () => {
    try {
      showOverlay();
      if (query.id) {
        const pdf = await getContractPdf(query.id as string);
        const contractResponse = await getOrderData(query.id as string);
        setContract(contractResponse.data);
        if (contractResponse.data?.stepperData) {
          setActiveStep(
            contractResponse?.data.stepperData.findIndex((participant: StepperData) => participant.isCurrentUser)
          );
        }
        setIsContractExist(contractResponse.level !== RequestStatuses.Error);
        let blob = new Blob([pdf], { type: 'application/pdf' });
        let url = window.URL.createObjectURL(blob);
        setContractPdf(url);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideOverlay();
    }
  };

  const date = Date();
  const downloadFile = useFileDownload({
    errorSnackbarMessage: 'Ein Fehler ist aufgetreten.',
    requestConfig: crmCheckoutRequestConfig.getExternalSigningZip(query.id as string),
    fileName: `Vertragsunterlagen Vereinbarung Flottenbetreuung und Schadenmanagement ${
      contract?.companyName
    } ${formatDate(date as unknown as Date)}.zip`,
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob',
      contentType: 'application/zip'
    }
  });

  useEffect(() => {
    getContractData();
  }, [query.id]);

  const handleSignatoryCheckboxChange = useCallback(() => {
    setIsSignatoryChecked(!isSignatoryChecked);
  }, [isSignatoryChecked]);

  const handlePrivacyPolicyCheckboxChange = useCallback(() => {
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);
  }, [isPrivacyPolicyChecked]);

  const signContract = async () => {
    try {
      const contentContainer = document.getElementsByTagName('main')[0];
      if (contentContainer) {
        contentContainer.scrollTo({ top: 0 });
      }
      showOverlay();
      const isLastSignee = await sign(query.id as string);
      setSuccessNotification(
        isLastSignee ? (
          'Eine E-Mail mit Ihrem Vertrag wurde soeben versendet.'
        ) : (
          <>
            <Text>Sie haben den Vertrag erfolgreich akzeptiert.</Text>
            <Text>Wir benachrichtigen Sie per E-Mail sobald der Vertrag wirksam ist.</Text>
          </>
        )
      );

      const updatedContract = cloneDeep(contract);
      updatedContract.stepperData.find((participant: StepperData) => participant.isCurrentUser).isSigned = true;
      setContract(updatedContract);

      setActiveStep(prev => prev + 1);
      setIsOrdered(true);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        hideOverlay();
      }, 500);
    }
  };

  const getStepLabel = (isCurrentUser: boolean, isSigned: boolean, initials: string) => {
    if (isCurrentUser) {
      if (isSigned) {
        return 'Vertrag akzeptiert';
      } else {
        return 'Vertrag akzeptieren';
      }
    } else {
      if (isSigned) {
        return `Vertrag wurde von ${initials} akzeptiert`;
      } else {
        return `Vertrag wird an ${initials} geschickt`;
      }
    }
  };

  const steps = useMemo(() => {
    if (contract?.stepperData) {
      const thirdStep = {
        label: 'Vertrag abgeschlossen',
        icon:
          activeStep === 2 ? (
            <DoneIcon fill={theme.colors.darkBlue} />
          ) : (
            <SigningExternalPageStepperIcon step={2} fill={theme.colors.lightBlue} />
          ),
        isSigned: activeStep === 2
      };

      const userSteps = contract?.stepperData.map((participant: StepperData, index: number) => {
        const { isSigned, isCurrentUser, initials } = participant;
        return {
          label: getStepLabel(isCurrentUser, isSigned, initials),
          icon: isSigned ? (
            <DoneIcon fill={theme.colors.darkBlue} />
          ) : (
            <SigningExternalPageStepperIcon
              step={index}
              fill={activeStep === index ? theme.colors.darkBlue : theme.colors.lightBlue}
            />
          ),
          isSigned
        };
      });

      return [...userSteps, thirdStep];
    }

    return [];
  }, [contract?.stepperData, isOrdered]);

  const orderNewLink = async () => {
    showOverlay();
    await reOrderLink(query.id as string);
    await getContractData();
    hideOverlay();
  };

  return (
    <UnauthorizedPageContainer className={classes.full_content} containerClassName={classes.container_style}>
      <HelmetComponent title={UNAUTHORIZED_PAGES.SIGNING} />
      {showStepper && (
        <Flex direction='column' align='center' margin='10px 0 50px 0'>
          <Flex justify='space-between' width='60%'>
            {steps.map(
              (
                step: { label: string; icon: JSX.Element; isSigned: boolean; isCurrentUser: boolean },
                index: number
              ) => (
                <Flex align='center' width={index + 1 < steps.length ? '100%' : 'initial'}>
                  <Flex position='relative' justify='center'>
                    {step.icon}
                    <Flex width='220px' position='absolute' justify='center' margin='25px 0 0 0 '>
                      <Text fontWeight={activeStep === index ? 600 : undefined} color={'darkBlue'}>
                        {step.label}
                      </Text>
                    </Flex>
                  </Flex>
                  {index + 1 < steps.length && (
                    <Flex
                      height='1px'
                      width='100%'
                      margin='0 10px'
                      style={{
                        border: `1px solid ${step.isSigned ? theme.colors.darkBlue : theme.colors.lightBlue}`
                      }}
                    />
                  )}
                </Flex>
              )
            )}
          </Flex>
        </Flex>
      )}

      {contract && !contract?.isSigned && !isOrdered && !contract.isExpired && (
        <SugningStyledContainer>
          <TitileStyledContainer>
            <Text color='blue' size='34' fontWeight={400}>
              Vertrag zur Flottenbetreuung durch Mobexo
            </Text>
            <Text color='blue' size='15' fontWeight={600} margin='15px 0'>
              {`Bitte akzeptieren sie folgenden Vertrag für ${contract?.companyName}.`}
            </Text>

            <Text color='blue' fontWeight={600}>
              Ihr gewähltes Paket für den Mobexo-Fleetmanager
            </Text>
          </TitileStyledContainer>
          <Flex justify='space-between' margin='20px 0 0 0'>
            <SigningContentWrapper>
              <StyledPackageContainer disableMaxWidth isProfessionalOrComfortPackage={isProfessionalOrComfortPackage}>
                <Flex justify='space-between'>
                  <Flex direction='column'>
                    <Text color='darkBlue' size='18' bold bottom='15'>
                      {contract.packageName}
                    </Text>
                    <StyledList>
                      {listItems.map((item: string, index: number) => (
                        <StyledListItem key={index}>{item}</StyledListItem>
                      ))}
                    </StyledList>
                  </Flex>
                </Flex>
              </StyledPackageContainer>
              {contractPdf && (
                <>
                  <Flex justify='flex-end' margin='20px 0 2px 0'>
                    <Button secondary onClick={() => downloadFile()}>
                      <Flex align='center'>
                        <Flex right='10'>
                          <Download fill={theme.colors.darkBlue} />
                        </Flex>
                        Download Vertrag
                      </Flex>
                    </Button>
                  </Flex>
                  <StyledDocumentContainer ref={scrollRef} onScroll={onScroll}>
                    <FileViewer fileType={'pdf'} filePath={contractPdf} />
                    <StyledScrollDownButton hidden={isScrolledToBottom} onClick={handleScrollToBottom}>
                      &darr; Bitte nach unten scrollen
                    </StyledScrollDownButton>
                  </StyledDocumentContainer>
                </>
              )}
              <Flex direction='column'>
                <Flex align='center' margin='10px 0'>
                  <ThreeStateCheckbox
                    key={'signatory'}
                    checked={isSignatoryChecked}
                    onChange={handleSignatoryCheckboxChange}
                    indeterminate={false}
                  />
                  <Text
                    color='black'
                    left='13'
                  >{`Ich bin Unterschriftsbrechtigter des Unternehmens ${contract.companyName}.`}</Text>
                </Flex>
                <Flex align='center'>
                  <ThreeStateCheckbox
                    key={'privacy'}
                    checked={isPrivacyPolicyChecked}
                    onChange={handlePrivacyPolicyCheckboxChange}
                    indeterminate={false}
                  />
                  <Text color='black' left='13'>
                    Ich habe die{' '}
                    <PrivacyStyledLink href='https://www.mobexo.de/datenschutz/' target='_blank'>
                      Datenschutzbestimmungen
                    </PrivacyStyledLink>{' '}
                    gelesen und akzeptiert.
                  </Text>
                </Flex>
              </Flex>
              <Flex margin='10px 0 0 0' justify='flex-end'>
                <Button onClick={signContract} disabled={isSubmitButtonDisabled}>
                  Jetzt verbindlich bestellen
                </Button>
              </Flex>
            </SigningContentWrapper>

            <StyledRightContainer>
              <WidgetDocumnetContainer type={WidgetType.Simple}>
                <>
                  <WidgetHeader
                    title={'Zusammenfassung'}
                    icon={<img src='/assets/images/euro_blue.svg' alt='euro' />}
                  />
                  <hr />
                  <Flex padding='0 20px 20px' direction='column'>
                    <SummaryItem
                      title={`${contract.packageName} Paket`}
                      lowerText='Anzahl Fahrzeuge: '
                      quantity={contract.packageData.uds_anzahlautos.attributeValue}
                      totalPrice={contract.packageData.uds_gesamtsumme.attributeValue}
                      price={
                        contract.packageData.uds_rabattpreis.attributeValue?.value ||
                        contract.packageData.uds_preis.attributeValue?.value
                      }
                      subtitle={false}
                    />
                    <StyledAddonsContainer>
                      <Text fontWeight={700} bottom='20'>
                        Zusatzpakete
                      </Text>
                      {contract.productsData.monthly.map((addon: any) => (
                        <SummaryItem
                          title={addon.uds_produktid.attributeValue?.name || ''}
                          lowerText={'pro Fahrzeug:'}
                          quantity={addon.uds_produktmenge.attributeValue}
                          totalPrice={addon.uds_gesamtpreis.attributeValue?.value}
                          price={addon.price.attributeValue?.value}
                          subtitle={true}
                        />
                      ))}
                    </StyledAddonsContainer>
                    <StyledPaymentContainer>
                      <Text color='blue' bold size='16'>
                        Monatlich
                      </Text>
                      <StyledNumberText
                        color='blue'
                        bold
                        value={contract.packageData.uds_monatlich.attributeValue}
                        displayType='text'
                        thousandSeparator='.'
                        decimalSeparator=','
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix={' EUR'}
                      />
                    </StyledPaymentContainer>
                    <>
                      {contract.productsData.oneTime.map((addon: any) => (
                        <SummaryItem
                          title={addon.uds_produktid.attributeValue?.name || ''}
                          lowerText={'pro Fahrzeug:'}
                          quantity={addon.uds_produktmenge.attributeValue}
                          totalPrice={addon.uds_gesamtpreis.attributeValue?.value}
                          price={addon.price.attributeValue?.value}
                          subtitle={true}
                        />
                      ))}
                      <StyledPaymentContainer>
                        <Text color='blue' bold size='16'>
                          Einmalig
                        </Text>
                        <StyledNumberText
                          color='blue'
                          bold
                          value={contract.packageData.uds_einmalig.attributeValue}
                          displayType='text'
                          thousandSeparator='.'
                          decimalSeparator=','
                          decimalScale={2}
                          fixedDecimalScale={true}
                          suffix={' EUR'}
                        />
                      </StyledPaymentContainer>

                      {Boolean(contract.productsData.perUse.length) &&
                        contract.productsData.perUse.map((addon: any) => (
                          <SummaryItem
                            title={addon.uds_produktid.attributeValue?.name || ''}
                            lowerText={'pro Vorgang:'}
                            quantity={addon.uds_produktmenge.attributeValue}
                            totalPrice={addon.uds_gesamtpreis.attributeValue?.value}
                            price={addon.price.attributeValue?.value}
                            subtitle={true}
                            isPerOperationAddon
                          />
                        ))}
                      <StyledPaymentContainer>
                        <Text color='blue' bold size='16'>
                          Vorgangsbezogen
                        </Text>
                        <StyledNumberText
                          color='blue'
                          bold
                          value={contract.packageData.uds_vorgangsbezogen.attributeValue}
                          displayType='text'
                          thousandSeparator='.'
                          decimalSeparator=','
                          decimalScale={2}
                          fixedDecimalScale={true}
                          suffix={' EUR'}
                        />
                      </StyledPaymentContainer>
                    </>
                    <Flex justify='space-between' width='100%'>
                      <Text color='grey600'>Startdatum</Text>
                      <Text color='black'>
                        {contract.packageData.uds_paketstart.attributeValue &&
                          formatDate(contract.packageData.uds_paketstart.attributeValue)}
                      </Text>
                    </Flex>

                    <Flex top='25' style={{ lineHeight: '18px' }}>
                      <Text size='10' color='grey600'>
                        Alle Werte zzgl. der jeweiligen Umsatzsteuer, die jeweils gültige Umsatzsteuer auf steuerbare
                        Dienstleistungen wird gesondert berechntet.
                      </Text>
                    </Flex>
                  </Flex>
                </>
              </WidgetDocumnetContainer>
            </StyledRightContainer>
          </Flex>
        </SugningStyledContainer>
      )}

      {isOrdered && (
        <Flex direction='column' align='center' justify='center' height='60vh'>
          <img src='/assets/images/documents_image.png' alt='documents_image' />
          <Flex direction='column' width='500px' style={{ textAlign: 'center' }}>
            <Text color='blue' size='20' fontWeight={600} margin='0 0 10px 0'>
              Vielen Dank!
            </Text>
            <Text color='blue'>{successNotification}</Text>
          </Flex>
        </Flex>
      )}

      {contract?.isSigned && !isOrdered && (
        <Flex direction='column' align='center' justify='center' height='60vh'>
          <img src='/assets/images/documents_image.png' alt='documents_image' />
          <Flex direction='column' width='270px' style={{ textAlign: 'center' }}>
            <Text color='blue' size='20' fontWeight={600} margin='0 0 5px 0'>
              Hallo!
            </Text>
            <Text color='blue'>Sie haben den Vertrag bereits akzeptiert.</Text>
            <Text margin='15px 0 25px 0' color='blue'>
              Wir haben Ihnen eine Bestätigungsmail am{' '}
              <span style={{ fontWeight: '700' }}>{moment(contract.signingDate).format('DD.MM.yy')}</span> geschickt.
            </Text>

            <Flex justify='center'>
              <Button
                onClick={() => {
                  window.open('https://www.mobexo.de/kontakt/', '_blank');
                }}
              >
                Mobexo kontaktieren
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      {!isContractExist && (
        <Flex align='center' justify='center' height='60vh'>
          <StyledErrorContainer>
            <StyledErrorIconContainer>
              <img src={'/assets/images/page-not-found.png'} alt='notFoundPage' />
            </StyledErrorIconContainer>
            <StyledErrorContentContainer>
              <Flex direction='column'>
                <Text color='black' size='18' fontWeight={600}>
                  Error 404
                </Text>
                <Text size='36' fontWeight={400}>
                  Seite nicht gefunden!
                </Text>
              </Flex>
              <Text fontWeight={400} margin='20px 0'>
                Die von Ihnen gesuchte Seite konnte nicht gefunden werden.
              </Text>

              <Flex
                direction='column'
                style={{ background: theme.colors.darkBlue, borderRadius: '4px' }}
                padding='10px'
              >
                <Text size='20' fontWeight={600} color='white' margin='0 0 10px 0'>
                  Wir helfen Ihnen gerne weiter.
                </Text>

                <Text color='white' fontWeight={400}>
                  Rufen Sie uns unter{' '}
                  <StyledMobexoLink href={`tel:${mobexoPhoneNumber}`}>{mobexoPhoneNumber}</StyledMobexoLink> an oder
                  senden Sie uns eine Mail an{' '}
                  <StyledMobexoLink href={`mailto:${mobexoInfoEmail}`}>{mobexoInfoEmail}</StyledMobexoLink>.
                </Text>
              </Flex>
            </StyledErrorContentContainer>
          </StyledErrorContainer>
        </Flex>
      )}

      {contract && contract.isExpired && contract.email && (
        <Flex direction='column' align='center' justify='center' height='60vh'>
          <img src='/assets/images/documents_image.png' alt='documents_image' />
          <Flex direction='column' maxWidth='500px' style={{ textAlign: 'center' }}>
            <Text color='blue' size='18' fontWeight={600} bottom='10'>
              Der angefragte Link ist ungültig oder abgelaufen.
            </Text>
            <Text bottom='60'>
              {`Sie können einen neuen Link zur Vertragsunterschrift anfordern. Dieser wird Ihnen sofort per E-Mail an
              ${contract.email} zugeschickt.`}
            </Text>
            <Flex justify='center'>
              <Button onClick={orderNewLink}>Neuen Link anfordern</Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </UnauthorizedPageContainer>
  );
}

export default SigningExternalCompopnent;
