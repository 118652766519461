import { JSXElementConstructor, ReactElement, MouseEvent } from 'react';
import { AnyObject } from 'common/interfaces';

export interface TableComponentProps {
  columns: ColumnType[];
  rows: RowType[];
  sort?: SortType;
  sortParams?: AnyObject;
  rootTag: RootTagType;
  minWidth?: number;
  className?: string;
  openedItem?: string;
  maxWidth?: number;
  loading?: boolean;
  substitutionElement?: ReactElement;
}

export interface RowProps extends Pick<TableComponentProps, 'columns' | 'rootTag'> {
  row: RowType;
  openedItemId: string;
}

export interface RowExpandableProps extends RowProps {
  toggleAllAccordions: (id: string) => any;
}

export interface CellProps extends Pick<RowProps, 'openedItemId'> {
  column: ColumnType;
  row: RowType;
  toggle?: (event: MouseEvent<HTMLDivElement>, isDisable: boolean) => void;
}

export type RootTagType = {
  name: RootTagName;
  keyName?: string;
  to?: string;
  onClick?: (row: RowType) => any;
  propName: string;
  encode?: boolean;
  expandComponent?: ReactElement<any, string | JSXElementConstructor<any>>;
};

export type SortType = (sortParameter: string) => any;

export type ColumnType<TRow = RowType> = {
  name: string;
  type: ColumnsVariant;
  propName?: string;
  sort?: boolean;
  sortName?: string;
  width: string;
  className?: string;
  defaultImage?: string;
  component?: ReactElement;
  hide?: boolean;
  customTooltip?: (row: RowType) => any;
  alwaysShowTooltip?: boolean;
  neverShowTooltip?: boolean;
  action?: (props: any) => any;
  actionParameter?: string;
  icon?: ReactElement;
  label?: string;
  menuItems?: MenuItemType[];
  columnId?: string;
  isEnabled?: (row: TRow) => boolean;
  shouldShowTextOnInvalidDate?: boolean;
  headerComponent?: ReactElement;
};

export type MenuItemType = {
  label: string;
  icon: ReactElement;
  action: (data: any) => any;
  actionParameter: string;
  minWidth?: string;
};

export enum RootTagName {
  Link = 'link',
  Div = 'div',
  Expand = 'expand'
}

export enum ColumnsVariant {
  Text = 'text',
  Boolean = 'boolean',
  Money = 'money',
  Menu = 'menu',
  Button = 'button',
  Chip = 'chip',
  Date = 'date',
  Image = 'image'
}

export interface RowType {
  imgSrc?: string;
  isDisableRow?: boolean;
  [key: string]: any;
}

export interface IRowContext {
  rowId: string;
}

export interface ICellContext {
  cellId: string;
}

export interface StyledRowIndicatorProps {
  color: string;
}
