import React, { memo, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CostListItem } from 'components/cost-list-item/cost-list-item';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import classes from './carbon-dioxide-emission.module.scss';
import { CostListItemProps } from 'components/cost-list-item/cost-list-item.props';
import { DataCO2Props } from './carbon-dioxide-emission.props';
import { data as chartDataModel, options } from 'models/chart/dashboard/carbon-dioxide-emission.model';
import { PercentColor, PercentSetupType } from 'components/expenses-per-year/expenses-per-year.props';
import { Position } from 'common/enums';
import { Distance } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { modifyFuelData } from '../utils';
import { ChartCO2Labels } from '../dashboard.props';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import toTons from 'utils/toTons';
import { FileDownloadIcon } from 'components/icons-new/file-download';
import { useFileDownload } from 'hooks/useFileDownload';
import { downloadFuelReportConfig } from './utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const CarbonDioxideEmission = memo(({ dataCO2 }: DataCO2Props) => {
  const [chartData, setChartData] = useState<ChartData<'line', number[], string>>(chartDataModel);
  const theme = useTheme();

  const currentYear = new Date().getFullYear();
  const percentageFromLastYearToCurrent = dataCO2?.percentCO2 || 0;

  const percentSetup: PercentSetupType = {
    percent: Math.abs(percentageFromLastYearToCurrent),
    color: percentageFromLastYearToCurrent >= 0 ? PercentColor.Green : PercentColor.Red,
    arrowDirection: percentageFromLastYearToCurrent >= 0 ? Position.Bottom : Position.Top
  };

  const costList: CostListItemProps[] = [
    {
      color: theme.colors.green,
      name: ChartCO2Labels.Electricity,
      price:
        typeof dataCO2?.sumCO2FuelByYear?.Strom === 'string'
          ? dataCO2?.sumCO2FuelByYear?.Strom
          : toTons(dataCO2?.sumCO2FuelByYear?.Strom),
      tooltipText: 'Produkt aus Verbrauchsmenge * Co2 Faktor (in kg) - 0,35'
    },
    {
      color: theme.colors.gold,
      name: ChartCO2Labels.Petrol,
      price:
        typeof dataCO2?.sumCO2FuelByYear?.Benzin === 'string'
          ? dataCO2?.sumCO2FuelByYear?.Benzin
          : toTons(dataCO2?.sumCO2FuelByYear?.Benzin),
      tooltipText: 'Produkt aus Verbrauchsmenge * Co2 Faktor (in kg) - 2,32'
    },
    {
      color: theme.colors.pink,
      name: ChartCO2Labels.Diesel,
      price:
        typeof dataCO2?.sumCO2FuelByYear?.Diesel === 'string'
          ? dataCO2?.sumCO2FuelByYear?.Diesel
          : toTons(dataCO2?.sumCO2FuelByYear?.Diesel),
      tooltipText: 'Produkt aus Verbrauchsmenge * Co2 Faktor (in kg) - 2,68'
    }
  ];

  const downloadFuelReport = useFileDownload(downloadFuelReportConfig);

  useEffect(() => {
    if (dataCO2 && dataCO2?.sumCO2ByMonthByYear[currentYear]) {
      const newChartData = modifyFuelData(dataCO2?.sumCO2ByMonthByYear[currentYear], chartData);
      setChartData(newChartData);
    }
  }, [dataCO2]);

  const weightString =
    typeof dataCO2?.sumCO2ByYear[currentYear] === 'string'
      ? dataCO2?.sumCO2ByYear[currentYear]
      : toTons(dataCO2?.sumCO2ByYear[currentYear]);

  return (
    <div className={classes.container}>
      <div className={classes.left_side}>
        <ExpensesPerYear name='CO2 Emissionen' year={currentYear} price={weightString} percentSetup={percentSetup} />
        <Distance top='30' />

        <div className={classes.cost_list}>
          {costList.map(costListItem => (
            <CostListItem {...costListItem} key={costListItem.name} />
          ))}
        </div>
      </div>

      <div className={classes.right_side}>
        <div className={classes.link} onClick={() => downloadFuelReport()}>
          <FileDownloadIcon width={18} height={18} /> Kraftstoffreport.excel
        </div>
        <Distance top='30' />
        {dataCO2 && dataCO2?.sumCO2ByMonthByYear[currentYear] ? (
          <Line options={options} data={chartData} height='190px' width='255px' />
        ) : (
          <div className={classes.empty_data}>
            <EmptyDataIcon hovered />
            <span>Es gibt keine Daten</span>
          </div>
        )}
      </div>
    </div>
  );
});
