import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Price from './price-view';
import { TAXES_COEFFICIENT } from '../../../constants';
import { UNAUTHORIZED_PAGES } from '../../../common/enums';

function PriceContainer({ item, type, location, fuelCost, priceWithoutFuelCost }) {
  const coeficient = type === '/ Monat' ? 1 : TAXES_COEFFICIENT;
  const isListPage = location.pathname.includes(UNAUTHORIZED_PAGES.LIST);

  return (
    <Fragment>
      {isListPage ? (
        item.uds_mobexoangebotgesamtrate ? (
          <Price price={item.uds_mobexoangebotgesamtrate_value * coeficient} suffix={type} />
        ) : null
      ) : priceWithoutFuelCost ? (
        <Price price={(priceWithoutFuelCost + fuelCost) * coeficient} suffix={type} />
      ) : null}
    </Fragment>
  );
}

PriceContainer.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['/ Monat', '(inkl. MwSt.)', 'brutto']),
  fuelCost: PropTypes.number.isRequired,
  isBasedOnGlobalReferenceVehicle: PropTypes.bool
};

export default withRouter(PriceContainer);
