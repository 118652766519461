import React, { Component } from 'react';
import LinkedInTag from 'react-linkedin-insight';

export function withLinkedInTag(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      LinkedInTag.init('2131153');
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
