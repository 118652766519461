import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { PAGES } from 'common/enums';
import { FleetDataQualityResponse } from './get-data-quality.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useFleetDataQualityQuery = (
  vehicleIdProps?: string,
  options: QueryHookOptions<
    AxiosResponse<ResponseModel<Partial<FleetDataQualityResponse>>>,
    ResponseModel<Partial<FleetDataQualityResponse>>
  > = {}
) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const QUERY_KEY = 'fleetGetQualityData';

  const { fetch } = useFetch<ResponseModel<FleetDataQualityResponse>>({
    loadingKey: QUERY_KEY,
    ...fleetRequestConfig.getDataQuality(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery([PAGES.MY_VEHICLES_DETAILS, QUERY_KEY, vehicleId], () => fetch(), {
    enabled: !!vehicleId,
    ...options.options
  });

  return { fleetDataQualityQuery: data?.data.data?.attributes || {}, ...restQueryProps };
};
