import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const CustomFilterContainer = styled.div<{ weekFilter?: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  padding: 18px;
  align-items: center;

  ${({ weekFilter }) =>
    weekFilter &&
    css`
      cursor: pointer;
      &:hover {
        background: ${({ theme }) => theme.colors.extraLightBlue};
      }
    `};
`;

export const StyledDivider = styled.div`
  min-width: 16px;
  height: 0;
  margin: 0 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.blue}`};
`;

export const StyledCustomFilters = styled(Flex)`
  @media (max-width: ${({ theme }) => theme.media.tabletHorizontal}) {
    flex-direction: column;
  }
`;

export const StyledCurrentWeek = styled(CustomFilterContainer)`
  margin: 0 20px;
  @media (max-width: ${({ theme }) => theme.media.tabletHorizontal}) {
    margin: 20px 0;
  }
`;

export const StyledBookingFilters = styled(Flex)`
  align-items: center;
  order: 0;
  @media (max-width: ${({ theme }) => theme.media.wideLaptop}) {
    width: 100%;
    order: 1;
  }
  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
