import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { SettingsIconOutlined } from 'components/icons/settings-icon-outlined';
import { ColumnSettingButtonProps } from './column-settings-button.types';
import { ColumnSettingButtonContainer } from './column-setting-button.styled';

const ColumnSettingsButton: React.FC<ColumnSettingButtonProps> = ({ openColumnsSettingsModal }) => {
  return (
    <ColumnSettingButtonContainer>
      <Flex pointer>
        <SettingsIconOutlined />
        <Text color='blue' fontWeight={500} left='10' onClick={openColumnsSettingsModal}>
          Spalten anpassen
        </Text>
      </Flex>
    </ColumnSettingButtonContainer>
  );
};

export default ColumnSettingsButton;
