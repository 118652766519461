import classNames from 'classnames';
import { ClipIcon } from 'components/icons/clip-icon';
import React from 'react';
import classes from './widget-documnet-container.module.scss';
import { WidgetDocumnentContainerProps, WidgetType } from './widget-documnet-container.props';

export const WidgetDocumnetContainer = ({
  title,
  description,
  img,
  children,
  className,
  type = WidgetType.Normal
}: WidgetDocumnentContainerProps) => {
  const containerClassName = classNames(className, {
    [classes.normal_type_container]: type === WidgetType.Normal,
    [classes.simple_type_container]: type === WidgetType.Simple,
    [classes.new_type_container]: type === WidgetType.New
  });
  return (
    <section className={containerClassName}>
      <div className={classes.head}>
        <div className={classes.icon}>{img || <ClipIcon color='#335566' />}</div>
        <div>
          <h3 className={classes.title}>{title}</h3>
          <p className={classes.description}>{description}</p>
        </div>
      </div>
      {children}
    </section>
  );
};
