import axios from 'axios';
import { RequestStatuses } from '../../constants';
import { API_ENDPOINT } from 'endpoints';

export const getOrderData = async (id: string) => {
  try {
    const url = `${API_ENDPOINT}/api/crmcheckout/externalsigning/${id}`;

    const response = await axios({
      method: 'GET',
      url
    });

    const { data } = response;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getContractPdf = async (id: string) => {
  try {
    const url = `${API_ENDPOINT}/api/crmcheckout/externalsigning/${id}/contractPdf`;

    const response = await axios({
      responseType: 'blob',
      method: 'GET',
      url
    });

    const { data } = response;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sign = async (id: string) => {
  try {
    const url = `${API_ENDPOINT}/api/crmcheckout/externalsigning/sign`;

    const response = await axios({
      method: 'POST',
      url,
      data: {
        id
      }
    });

    const { isLastSignee } = response.data.data;

    return isLastSignee;
  } catch (error) {
    console.log(error);
  }
};

export const reOrderLink = async (id: string) => {
  try {
    const url = `${API_ENDPOINT}/api/crmcheckout/externalSigning/requestNewLink`;

    const response = await axios({
      method: 'POST',
      url,
      data: {
        id
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};
