import { Position } from 'common/enums';

export interface ExpensesPerYearProps {
  name: string;
  price: string | number;
  year: string | number;
  percentSetup?: PercentSetupType;
  openPage?: () => void;
  tooltipText?: string;
  headerPercent?: boolean;
}

export type PercentSetupType = {
  percent: string | number;
  color: PercentColor;
  arrowDirection: Position.Top | Position.Bottom;
};

export enum PercentColor {
  Red = 'red',
  Green = 'green'
}
