import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import Button from 'components/button';
import { ButtonComponentType } from 'components/button/button.props';
import { OpenUserAlreadyHasMainCarModalParams } from '../user-already-has-main-car.props';
import { MainCarModalSubmitButton } from '../../components/submit-button/submit-button';

export const UserAlreadyHasMainCarModalContent = ({
  carNumber,
  driver,
  submitData
}: OpenUserAlreadyHasMainCarModalParams) => {
  return (
    <div>
      <div>
        Das Hauptfahrzeug für den Fahrer
        <b> {driver} </b>
        wechselt von
        <b> {carNumber.current} </b>
        zu <b> {carNumber.new}</b>.
      </div>
      <Text top='30'>Willst du diese Änderung übernehmen?</Text>

      <Flex top='20' justify='flex-end'>
        <Button component={ButtonComponentType.CloseModal}>Nein, abbrechen</Button>
        <MainCarModalSubmitButton submitData={{ ...submitData, isMainCar: true }}>
          Ja, Hauptauto ändern
        </MainCarModalSubmitButton>
      </Flex>
    </div>
  );
};
