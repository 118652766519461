import React from 'react';
import moment from 'moment';
import { PickersDayProps } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { useTheme } from 'styled-components';

interface CustomPickerDayProps {
  day: unknown;
  DayComponentProps: PickersDayProps<unknown>;
  customDate: string;
  tooltipText: string;
  overdueTooltipText?: string;
}

export const CustomPickerDay = ({
  day,
  DayComponentProps,
  customDate,
  tooltipText,
  overdueTooltipText
}: CustomPickerDayProps) => {
  const theme = useTheme();
  const isCustomDay = moment(customDate).isSame(day as string, 'day');
  const twoWeeksLeaseReturn = moment(customDate).add(2, 'weeks').endOf('day');
  const overdueLeaseReturn = moment(day as string, 'day').isAfter(twoWeeksLeaseReturn);
  const customTooltipText = isCustomDay
    ? tooltipText
    : overdueLeaseReturn && overdueTooltipText
    ? overdueTooltipText
    : '';

  return (
    <Tooltip title={customTooltipText} arrow>
      <div>
        <PickersDay
          {...DayComponentProps}
          sx={
            isCustomDay
              ? {
                  backgroundColor: theme.colors.gold,
                  border: `1px solid ${theme.colors.darkGold}`,
                  '&:hover': {
                    backgroundColor: `${theme.colors.gold} !important`,
                    border: `1px solid ${theme.colors.darkGold}`
                  }
                }
              : {}
          }
        />
      </div>
    </Tooltip>
  );
};
