export const STEP_NAMES = {
  bestellungPrufen: 'Bestellung prüfen',
  addons: 'Zusatzpakete',
  vertragsdaten: 'Vertragsdaten',
  angabenPrufen: 'Angaben prüfen',
  vertragsabschluss: 'Vertragsabschluss'
};

export const defaultSteps = [
  {
    title: STEP_NAMES.bestellungPrufen,
    active: true
  },
  {
    title: STEP_NAMES.addons,
    active: false
  },
  {
    title: STEP_NAMES.vertragsdaten,
    active: false
  },
  {
    title: STEP_NAMES.angabenPrufen,
    active: false
  },
  {
    title: STEP_NAMES.vertragsabschluss,
    active: false
  }
];

export const professionalListItems = [
  'Multi-Bid Leasingausschreibung',
  'Fahrzeugkonfigurator',
  'Monatliche Sammelrechnung',
  'Schadenabwicklung',
  'Digitale Fahrzeugakte',
  'Terminerinnerungen'
];

export const basicListItems = [
  'Digitale Fahrzeugakte',
  'Fahrerverwaltung',
  'Terminerfassung',
  'Standortzuordnung',
  'Dokumentenarchiv',
  'Rahmenvertragsverwaltung'
];
