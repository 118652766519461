import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getSEOItems } from './utils';
import { useLocation } from 'react-router-dom';

interface Props {
  title?: string;
}

const HelmetComponent = ({ title }: Props) => {
  const location = useLocation();

  const [seoItems, setSeoItems] = useState({
    title: 'Error'
  });

  useEffect(() => {
    setSeoItems(getSEOItems(title || location.pathname.split('/')[1]) as { title: string });
  }, [title, location.pathname]);

  return (
    <>
      <Helmet encodeSpecialCharacters>
        <html />
        <body />
        <meta charSet='utf-8' />
        <title>{`${seoItems.title} | Mobexo`}</title>
      </Helmet>
    </>
  );
};

export default HelmetComponent;
