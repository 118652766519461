import { AxiosRequestConfig } from 'axios';
import { PAGES } from 'common/enums';

class DocumentTypeRequestConfig {
  private RESOURCE = 'documenttype';

  getDocumentTypeByApPage = (pageUrl: PAGES): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/getDocumentTypeByApPage`,
    data: { pageUrl }
  });
}

export const documentTypeRequestConfig = new DocumentTypeRequestConfig();
