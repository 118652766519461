import React, { useEffect, useMemo, useState } from 'react';
import classes from './details.module.scss';
import { getDetails, submit, submitGlobalReferenceVehicle } from '../../utils';
import NumberFormat from 'react-number-format';
import { useOverlay } from '../../../../hooks';
import UploadFile from '../../../setup-assistant/upload-document/upload-file';
import Snackbar from '../../../snackbar';
import cloneDeep from 'lodash/cloneDeep';
import {
  crmDataTypes,
  LEASING_OFFER_STATUSES,
  RequestStatuses,
  USER_ROLES,
  VEHICLE_OFFER_EVENT_DESCRIPTIONS,
  VEHICLE_OFFER_STATUSES,
  PRICE_REQUEST_STATUS_CODES
} from '../../../../constants';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../selectors';
import LeasingRequestComponent, { LeasingInputControl } from '../leasing-request/leasing-request';
import useLatestValue from '../../../../hooks/use-latest-value';
import { getRequestItem, setValue } from '../../../../utils/get-response-data';
import { Distance, Flex, Text } from '../../../../common/common-components.styled';
import { StyledOneTimeCostsContainer } from '../../../../pages/details/order-widget/order-widget.styled';
import DealerPageFiles from '../dealerpage-files/dealerpage-files';
import { WidgetDocumnetContainer } from 'components/widget-documnet/widget-documnet-container';
import { WidgetType } from 'components/widget-documnet/widget-documnet-container.props';
import { useLocation } from 'react-router';
import { updateCrmEntity } from '../../../../services/crm-entity-service';
import sharepointService from '../../../../services/sharepoint-service';
import { createEvent } from '../../../../services/fleet-vehicle-service';
import moment from 'moment';
import { PAGES } from '../../../../common/enums';
import { CircleExclamationMark } from '../../../icons/crcle_exclamation_mark';
import ThreeStateCheckbox from '../../../three-state-checkbox';
import { useTheme } from 'styled-components';
import { useLeasingCompanies } from '../../../global-reference-vehicles/react-query/use-leasing-company';
import { useUserCompanyActiveServices } from '../../react-query/use-user-company-active-services';
import WidgetHeader from 'components/widget-header/widget-header';
import EllipsisFieldTitle from '../ellipsis-field-title';
import { useIsParentCompany } from '../../../../hooks/react-query/use-is-parent-company';
import { useGetUser } from '../../../../hooks/react-query/use-get-user/use-get-user';
import useOldestEvents from '../../../../hooks/use-oldest-events';
import RequestConfirmation from '../request-confirmation';

const CHECKBOXES = {
  buildAbilityConfirmed: 'buildAbilityConfirmed',
  priceConfirmed: 'priceConfirmed'
};

export const STEP_STATUSES = {
  completed: 'completed',
  active: 'active',
  closed: 'closed'
};

const STEP_NAMES = {
  priceOffer: 'Barpreisangebot',
  leasingRequest: 'Leasinganfrage'
};

const defaultSteps = [
  {
    name: STEP_NAMES.priceOffer,
    id: STEP_NAMES.priceOffer,
    status: STEP_STATUSES.active,
    visibleFor: [],
    open: true
  }
];

let carDealerFields = [
  {
    crmField: 'uds_listenpreis_netto',
    crmFieldType: crmDataTypes.Money,
    value: 'netListPrice'
  },
  {
    crmField: 'uds_barpreis_netto',
    crmFieldType: crmDataTypes.Money,
    value: 'netCashPrice'
  },
  {
    crmField: 'uds_einmaligekosten_uberfuhrungskosten',
    crmFieldType: crmDataTypes.Money,
    value: 'transportation'
  },
  {
    crmField: 'uds_einmaligekosten_zulassungskosten',
    crmFieldType: crmDataTypes.Money,
    value: 'licensing'
  },
  {
    crmField: 'new_haendlerangebotsnummer',
    crmFieldType: crmDataTypes.String,
    value: 'offerNumber'
  },
  {
    crmField: 'uds_einmaligekosten_anzahlung',
    crmFieldType: crmDataTypes.Money,
    value: 'deposit'
  },
  {
    crmField: 'uds_grosskundennachlass_percent_float',
    crmFieldType: crmDataTypes.Decimal,
    value: 'discountPercent'
  },
  {
    crmField: 'uds_car_dealer_major_client_discount_percent',
    crmFieldType: crmDataTypes.Decimal,
    value: 'dealerDiscountPercent'
  },
  {
    crmField: 'uds_vsl_liefertermin_laut_handler_main',
    crmFieldType: crmDataTypes.DateTime,
    value: 'uds_vsl_liefertermin_laut_handler_main'
  },
  {
    crmField: 'uds_kommentar_des_haendlers',
    crmFieldType: crmDataTypes.Memo,
    value: 'uds_kommentar_des_haendlers'
  }
];

const defaultValues = {
  new_finanzratemobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_leasingfaktor: {
    attributeTypeCode: crmDataTypes.Double,
    value: 0
  },
  new_wartung_und_verschless_imobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_versicherung_mobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_versicherung_sb_teilkasko: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_versicherung_dienstleister: {
    attributeTypeCode: crmDataTypes.Lookup,
    value: null
  },
  uds_versicherung_sb_vollkasko: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_kfz_steuer_mobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_gap: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_sommerreifen_mobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_gap_dienstleister: {
    attributeTypeCode: crmDataTypes.Lookup,
    value: null
  },
  new_winterreifen_mobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_radereinlagerungundwechsel: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  new_gez_mobexo: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_mehrkilometer: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_toleranzgrenzekm: {
    attributeTypeCode: crmDataTypes.Integer,
    value: 0
  },
  uds_minderkilometer: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_berechnungsfreie_minderkilometer_leasing: {
    attributeTypeCode: crmDataTypes.Integer,
    value: 0
  },
  uds_je_mehrkilometer_wv: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_berechnungsfreie_mehrkilometer_wv: {
    attributeTypeCode: crmDataTypes.Integer,
    value: 0
  },
  uds_je_minderkilometer_wv: {
    attributeTypeCode: crmDataTypes.Money,
    value: 0
  },
  uds_berechnungsfreie_minderkilometer_wv: {
    attributeTypeCode: crmDataTypes.Integer,
    value: 0
  },
  uds_kommentar_der_leasinggesellschaft: {
    attributeTypeCode: crmDataTypes.String,
    value: ''
  },
  uds_angebotnumber: {
    attributeTypeCode: crmDataTypes.String,
    value: ''
  }
};

const defaultAdditionalDataPanels = [
  {
    name: 'Dienstleistungen',
    expand: false,
    leasingInputs: [
      {
        label: 'Wartung und Verschleiß *',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_wartung_und_verschless_imobexo',
        required: true,
        active: true
      },
      {
        label: 'Versicherung',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_versicherung_mobexo',
        isShow: 'uds_versicherungcheckbox',
        required: false,
        active: false
      },
      {
        label: 'KFZ-Steuer',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_kfz_steuer_mobexo',
        isShow: 'uds_kfzsteuercheckbox',
        required: false,
        active: false
      },
      {
        label: 'Rundfunkbeiträge',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_gez_mobexo',
        isShow: 'uds_gezcheckbox',
        required: false,
        active: false
      },
      {
        label: 'Winterreifen mit Felge',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_winterreifen_mobexo',
        isShow: 'uds_winterreifencheckbox',
        required: false,
        active: false
      },
      {
        label: 'GAP-Versicherung',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'uds_gap',
        isShow: 'uds_isgap_versicherung_anbieten',
        required: false,
        active: false
      },
      {
        label: 'Sommerreifen extra',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'new_sommerreifen_mobexo',
        isShow: 'uds_reifenersatzcheckbox',
        required: false,
        active: false
      },
      {
        label: 'Reifen+Einlagerung',
        prefix: '+',
        suffix: 'EUR',
        decimalScale: 2,
        value: 'uds_radereinlagerungundwechsel',
        isShow: 'uds_isradereinlagerungundwech',
        required: false,
        active: false
      }
    ]
  },
  {
    name: 'Kilometerabrechnung Leasing',
    expand: false,
    leasingInputs: [
      {
        label: 'Je Mehrkilometer (cent)',
        suffix: 'CT',
        decimalScale: 2,
        value: 'uds_mehrkilometer'
      },
      {
        label: 'Berechnungsfreie Mehrkilometer',
        suffix: 'KM',
        decimalScale: 0,
        value: 'uds_toleranzgrenzekm'
      },
      {
        label: 'Je Minderkilometer (cent)',
        suffix: 'CT',
        decimalScale: 2,
        value: 'uds_minderkilometer'
      },
      {
        label: 'Berechnungsfreie Minderkilometer',
        suffix: 'KM',
        decimalScale: 0,
        value: 'uds_berechnungsfreie_minderkilometer_leasing'
      }
    ]
  },
  {
    name: 'Kilometerabrechnung Wartung und Verschleiß',
    expand: false,
    leasingInputs: [
      {
        label: 'Je Mehrkilometer (cent)',
        suffix: 'CT',
        decimalScale: 2,
        value: 'uds_je_mehrkilometer_wv'
      },
      {
        label: 'Berechnungsfreie Mehrkilometer',
        suffix: 'KM',
        decimalScale: 0,
        value: 'uds_berechnungsfreie_mehrkilometer_wv'
      },
      {
        label: 'Je Minderkilometer (cent)',
        suffix: 'CT',
        decimalScale: 2,
        value: 'uds_je_minderkilometer_wv'
      },
      {
        label: 'Berechnungsfreie Minderkilometer',
        suffix: 'KM',
        decimalScale: 0,
        value: 'uds_berechnungsfreie_minderkilometer_wv'
      }
    ]
  },
  {
    name: 'Kommentar',
    expand: true,
    leasingInputs: [
      {
        value: 'uds_kommentar_der_leasinggesellschaft'
      }
    ]
  }
];

const Details = props => {
  const user = useSelector(userSelector);
  const theme = useTheme();
  const mainRoleId = user.mainRole?.id;
  const location = useLocation();
  const [showOverlay, hideOverlay] = useOverlay();
  const [showNumberAndFile, setShowNumberAndFile] = useState(false);
  const [showOneTimeCosts, setShowOneTimeCosts] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [validationError, setValidationError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dontSendOffer, setDontSendOffer] = useState(false);
  const [dontSendOfferConfirmed, setDontSendOfferConfirmed] = useState(false);
  const [details, setDetails] = useState({});
  const [values, setValues] = useState({
    id: null,
    netListPrice: null,
    accountName: '',
    accountStreet: '',
    accountCity: '',
    accountPostalCode: '',
    discount: null,
    dealerDiscount: null,
    discountPercent: null,
    dealerDiscountPercent: null,
    netCashPrice: 0,
    transportation: null,
    licensing: null,
    sum: 0,
    offerNumber: '',
    deposit: null,
    uds_kommentar_des_haendlers: '',
    files: []
  });
  const { vehicleConfigurationBasedOnGRVCreatedEvent, requestVehicleFromCarDealershipEvent } = useOldestEvents(
    props.events || []
  );
  const [buildAbilityConfirmed, setBuildAbilityConfirmed] = useState(false);
  const [priceConfirmed, setPriceConfirmed] = useState(false);

  const { isParentCompany, parentCompanyId } = useIsParentCompany(details.uds_kundeid_id);
  const { leasingCompanies } = useLeasingCompanies(
    isParentCompany !== undefined ? (isParentCompany ? details.uds_kundeid_id : parentCompanyId) : undefined
  );
  const { userCompanyActiveServices } = useUserCompanyActiveServices(
    isParentCompany !== undefined ? (isParentCompany ? details.uds_kundeid_id : parentCompanyId) : undefined
  );

  const isGlobalReferenceVehicle = !!vehicleConfigurationBasedOnGRVCreatedEvent;
  const driverId =
    props.configurationCreatedEvent?.uds_erstelltfurid.attributeValue?.id ||
    vehicleConfigurationBasedOnGRVCreatedEvent?.uds_erstelltfurid.attributeValue?.id ||
    null;
  const { userData } = useGetUser(driverId, false);

  const isDealerAndLeasingCompany = [USER_ROLES.leasingcompany, USER_ROLES.dealer].every(role =>
    user.roles.some(userRole => userRole.id === role.id)
  );
  defaultSteps[0].visibleFor = isDealerAndLeasingCompany
    ? [USER_ROLES.administrator, USER_ROLES.dealer, USER_ROLES.leasingcompany]
    : [USER_ROLES.administrator, USER_ROLES.dealer];

  const [steps, setSteps] = useState(defaultSteps);
  const [refreshKey, setRefreshKey] = useState(0);

  const isLeasinganfragenDetailsPage = useMemo(() => {
    return location.pathname.includes(PAGES.LEASING_REQUESTS_DETAILS);
  }, [location]);

  const stepsLatest = useLatestValue(steps);

  useEffect(() => {
    setValues({
      ...values,
      sum:
        (values.netListPrice || 0) -
        (values.discount || 0) -
        (values.dealerDiscount || 0) -
        (values.deposit || 0) +
        (values.transportation || 0) +
        (values.licensing || 0),
      netCashPrice:
        (values.netListPrice || 0) - (values.discount || 0) - (values.dealerDiscount || 0) - (values.deposit || 0),
      discount:
        values.discountPercent && values.netListPrice ? (values.netListPrice * values.discountPercent) / 100 : '',
      dealerDiscount:
        values.dealerDiscountPercent && values.netListPrice
          ? (values.netListPrice * values.dealerDiscountPercent) / 100
          : ''
    });
  }, [
    values.netListPrice,
    values.discount,
    values.dealerDiscount,
    values.transportation,
    values.licensing,
    values.deposit,
    values.discountPercent,
    values.dealerDiscountPercent
  ]);

  useEffect(() => {
    showOverlay();
    getDetails({
      id: props.id
    })
      .then(({ data }) => {
        const status = data.data?.item[0]?.uds_angebotstatecode?.attributeValue?.value;
        const statusCode = data.data?.item[0]?.uds_angebotsstatuscode?.attributeValue?.value;
        setValues({
          ...values,
          id: data.data?.item[0]?.uds_fahrzeugangeboteid?.attributeValue,
          accountName: data.data?.account[0]?.name?.attributeValue || '',
          accountStreet: data.data?.account[0]?.address1_line1.attributeValue || '',
          accountCity: data.data?.account[0]?.address1_city.attributeValue || '',
          accountPostalCode: data.data?.account[0]?.address1_postalcode.attributeValue || '',
          desiredDeliveryDate: data.data?.item[0]?.uds_wunschlieferdatum?.attributeValue || '',
          customersizecode: data.data?.account[0]?.customersizecode?.attributeValue?.label || '',
          uds_kommentar_fuer_haendler: data.data?.item[0]?.uds_kommentar_fuer_haendler?.attributeValue || '',
          uds_vsl_liefertermin_laut_handler:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_vsl_liefertermin_laut_handler?.attributeValue
              : null,
          uds_kommentar_des_haendlers:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_kommentar_des_haendlers?.attributeValue
              : '',
          netListPrice:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived || isGlobalReferenceVehicle
              ? data.data?.item[0]?.uds_listenpreis_netto?.attributeValue?.value
              : null,
          discount:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_grokundennachlass?.attributeValue?.value
              : null,
          discountPercent: isGlobalReferenceVehicle
            ? data.data?.item[0]?.uds_grosskundennachlass_percent_float?.attributeValue
            : null,
          dealerDiscount:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_car_dealer_major_client_discount?.attributeValue?.value
              : null,
          dealerDiscountPercent:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_car_dealer_major_client_discount_percent?.attributeValue
              : null,
          transportation:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_einmaligekosten_uberfuhrungskosten?.attributeValue?.value
              : null,
          licensing:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_einmaligekosten_zulassungskosten?.attributeValue?.value
              : null,
          offerNumber:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.new_haendlerangebotsnummer?.attributeValue
              : '',
          deposit:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data?.item[0]?.uds_einmaligekosten_anzahlung?.attributeValue?.value
              : null,
          status,
          statusCode,
          uds_vsl_liefertermin_laut_handler_main:
            statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived
              ? data.data.item[0]?.uds_vsl_liefertermin_laut_handler_main?.attributeValue
              : null
        });

        setDetails(getDetailsData(data.data?.item[0]));

        if (statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived) {
          setWizardStep(2);
          setShowOneTimeCosts(true);
          completeStep(STEP_NAMES.priceOffer);
        }
        if (statusCode === PRICE_REQUEST_STATUS_CODES.offerRejected) {
          setDontSendOfferConfirmed(true);
          completeStep(STEP_NAMES.priceOffer);
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        hideOverlay();
      });
  }, [refreshKey, isGlobalReferenceVehicle]);

  useEffect(() => {
    if (isLeasinganfragenDetailsPage) {
      const newSteps = [
        steps[0],
        ...props.leasingOffers.map(leasingOffer => ({
          name: STEP_NAMES.leasingRequest,
          id: leasingOffer.uds_leasingangeboteid,
          status:
            leasingOffer.uds_angebotsstatuscode_value === LEASING_OFFER_STATUSES.priceRequested
              ? STEP_STATUSES.closed
              : STEP_STATUSES.completed,
          description: `${leasingOffer.uds_laufzeit} Monate • ${leasingOffer.uds_laufleistungpa.toLocaleString(
            'de-DE'
          )} km / Jahr`,
          badge: 'offen',
          visibleFor: [USER_ROLES.administrator, USER_ROLES.leasingcompany],
          open: false,
          data: leasingOffer,
          values: defaultValues,
          additionalDataPanels: defaultAdditionalDataPanels
        }))
      ];

      setActiveStep(newSteps);

      setSteps(newSteps);
    }
  }, [props.leasingOffers]);

  const setActiveStep = steps => {
    if (steps[0].status === STEP_STATUSES.completed) {
      for (let i = 1; i < steps.length; i++) {
        if (steps[i].status === STEP_STATUSES.closed) {
          steps[i].open = true;
          steps[i].status = STEP_STATUSES.active;
          break;
        }
      }
    }
  };

  const setNewSteps = (newStepData, id) => {
    const newSteps = cloneDeep(steps);
    let changedStepIndex = newSteps.findIndex(step => step.id === id);
    newSteps.splice(changedStepIndex, 1, newStepData);
    setSteps(newSteps);
  };

  const getDetailsData = details => {
    const detailsData = {};
    Object.entries(details).forEach(([key, value]) => {
      setValue(details, key, value, detailsData, true);
    });
    return detailsData;
  };

  const snackbarSetup = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setOpenSnackbar(true);
  };

  const checkboxChanged = (data, name) => {
    switch (name) {
      case CHECKBOXES.buildAbilityConfirmed:
        setBuildAbilityConfirmed(!data.checked);
        break;
      case CHECKBOXES.priceConfirmed:
        setPriceConfirmed(!data.checked);
        break;
      default:
        break;
    }
  };

  const submitData = () => {
    const successfullyUpdated = () => {
      setShowOneTimeCosts(false);
      setWizardStep(2);
      setRefreshKey(oldKey => oldKey + 1);
      props.refreshLeasingOffers();
      snackbarSetup('Angebot wurde übermittelt.', 'success');
      hideOverlay();
      const scrollContainer = document.querySelector('.scroll-container');
      if (scrollContainer) {
        scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    if (
      !values.netListPrice ||
      !values.offerNumber ||
      (!isDealerAndLeasingCompany && !isGlobalReferenceVehicle && !values.files?.length) ||
      !values.uds_vsl_liefertermin_laut_handler_main ||
      (isGlobalReferenceVehicle && !buildAbilityConfirmed) ||
      (isGlobalReferenceVehicle && !priceConfirmed)
    ) {
      setValidationError(true);
      return;
    }
    showOverlay();
    const dataToSend = {
      entityName: 'uds_fahrzeugangebote',
      id: values.id,
      attributes: {}
    };

    delete values.desiredDeliveryDate;
    delete values.customersizecode;
    delete values.uds_kommentar_fuer_haendler;
    delete values.status;
    delete values.statusCode;
    if (!isGlobalReferenceVehicle) {
      carDealerFields = carDealerFields.filter(
        field => field.crmField !== 'uds_car_dealer_major_client_discount_percent'
      );
    }
    carDealerFields.forEach(field => {
      if (field.crmField === 'uds_vsl_liefertermin_laut_handler_main') {
        dataToSend.attributes[field.crmField] = getRequestItem(
          moment(values[field.value]).startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
          field.crmFieldType
        );
        return;
      }
      dataToSend.attributes[field.crmField] = getRequestItem(values[field.value], field.crmFieldType);
    });

    const requestsArray = [isGlobalReferenceVehicle ? submitGlobalReferenceVehicle(dataToSend) : submit(dataToSend)];

    Promise.allSettled(requestsArray)
      .then(response => {
        if (response?.every(item => item.value?.data.level !== RequestStatuses.Success)) {
          snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
          return;
        }
        if (values.files?.length) {
          sharepointService
            .uploadSharepointFile(values.files, 'Angebot vom Händler', values.id, 'uds_fahrzeugangebote', null)
            .then(response => {
              if (response.data?.level !== RequestStatuses.Success) {
                snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
                return;
              }
              successfullyUpdated();
            });
          return;
        }
        successfullyUpdated();
      })
      .catch(() => {
        snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
      });
  };
  const confirmDontSendOffer = async () => {
    const dataToSend = {
      entityName: 'uds_fahrzeugangebote',
      id: values.id,
      attributes: {
        uds_angebotsstatuscode: getRequestItem(PRICE_REQUEST_STATUS_CODES.offerRejected, crmDataTypes.Picklist)
      }
    };
    const createEventData = {};
    const createEventDataFields = [
      { name: 'uds_fahrzeugangebotid', value: values.id, attributeTypeCode: crmDataTypes.Lookup },
      {
        name: 'uds_eventdescription',
        value: VEHICLE_OFFER_EVENT_DESCRIPTIONS.priceReceived.id,
        attributeTypeCode: crmDataTypes.Lookup
      },
      { name: 'uds_entscheidung', value: true, attributeTypeCode: crmDataTypes.Boolean }
    ];
    createEventDataFields.forEach(field => {
      createEventData[field.name] = getRequestItem(field.value, field.attributeTypeCode);
    });

    const requestArray = [updateCrmEntity(dataToSend), createEvent(createEventData)];
    let response = {};

    try {
      showOverlay();
      response = await Promise.allSettled(requestArray);
    } catch (e) {
      console.log(e);
    } finally {
      hideOverlay();
      const scrollContainer = document.querySelector('.scroll-container');
      if (scrollContainer) {
        scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }

    if (response?.every(item => item.value?.data.level === RequestStatuses.Success)) {
      setDontSendOfferConfirmed(true);
      setRefreshKey(oldKey => oldKey + 1);
      snackbarSetup('Änderungen wurden übernommen.', 'success');
    } else {
      snackbarSetup('Änderungen wurden nicht übernommen.', 'error');
    }
  };

  const getIcon = step => {
    switch (step.status) {
      case STEP_STATUSES.completed:
        return <img src='/assets/images/dealerpage_step_completed.svg' alt='dealerpage_step_completed' />;
      case STEP_STATUSES.active:
        return <img src='/assets/images/dealerpage_step_active.svg' alt='dealerpage_step_active' />;
      case STEP_STATUSES.closed:
        return <img src='/assets/images/dealerpage_step_closed.svg' alt='dealerpage_step_closed' />;
      default:
        return null;
    }
  };

  const completeStep = stepName => {
    const newSteps = cloneDeep(stepsLatest.current);
    const currentStep = newSteps.find(step => step.name === stepName);
    currentStep.status = STEP_STATUSES.completed;

    newSteps.forEach(step => {
      step.open = false;
    });

    setActiveStep(newSteps);
    setSteps(newSteps);
  };

  const getStepBody = step => {
    if ((step.status === STEP_STATUSES.active || step.status === STEP_STATUSES.completed) && step.open) {
      switch (step.name) {
        case STEP_NAMES.priceOffer:
          return (
            <>
              {dontSendOfferConfirmed && (
                <div className={classes.dealer_step}>
                  <div className={classes.divider} />
                  <div className='mb-2' />
                  <div className={classes.row}>
                    <span className={classes.label_blue}>Status</span>
                    <span className={classes.dont_send_confirmed_message}>
                      <img src='/assets/images/barpreisanfrage_details_cross_icon.svg' />
                      <span>Abgelehnt durch den Händler</span>
                    </span>
                  </div>
                </div>
              )}
              {!dontSendOfferConfirmed && (
                <div className={classes.dealer_step}>
                  <div className={classes.divider} />
                  <div className='mb-2' />
                  {wizardStep == 2 && (
                    <>
                      <div className={classes.row}>
                        <span className={classes.label_blue}>Angebot-Nr.</span>
                        <span className={classes.result_value}>{values.offerNumber}</span>
                      </div>
                    </>
                  )}
                  {values.desiredDeliveryDate && (
                    <div className={classes.row}>
                      <span className={classes.label_grey}>Wunschlieferdatum</span>
                      <span className={classes.result_value}>
                        {new Date(values.desiredDeliveryDate).toLocaleDateString('de-DE', {
                          month: 'long',
                          year: 'numeric'
                        })}
                      </span>
                    </div>
                  )}
                  {values.uds_kommentar_fuer_haendler && (
                    <>
                      <Text margin='20px 0 10px' color='grey600'>
                        Kommentar zur Anfrage
                      </Text>
                      <StyledOneTimeCostsContainer bottom='30'>
                        <Text color='grey600'>{values.uds_kommentar_fuer_haendler}</Text>
                      </StyledOneTimeCostsContainer>
                    </>
                  )}
                  <div className='mb-2' />
                  <div className={classes.row}>
                    <span className={wizardStep === 2 ? classes.label_grey : classes.label_blue}>
                      Listenpreis Netto{wizardStep === 2 ? '' : ' *'}
                    </span>
                    {wizardStep === 2 ? (
                      <span className={classes.result_value}>
                        <NumberFormat
                          value={values.netListPrice}
                          displayType='text'
                          thousandSeparator='.'
                          decimalSeparator=','
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                        <span>&nbsp;EUR</span>
                      </span>
                    ) : (
                      <LeasingInputControl
                        suffix={'EUR'}
                        decimalScale={2}
                        value={values.netListPrice || ''}
                        className={`${validationError && !values.netListPrice ? classes.error : ''}`}
                        onChange={value => {
                          setValues({
                            ...values,
                            netListPrice: value.floatValue || 0
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.row}>
                    <Flex align='center' width='calc(57% - 10px)' justify='space-between'>
                      <span className={classes.label_grey}>Rabatt</span>
                      {wizardStep !== 2 && (
                        <LeasingInputControl
                          suffix={'%'}
                          decimalScale={2}
                          value={values.discountPercent === null ? '' : values.discountPercent || 0}
                          className={classes.discount_percent}
                          allowNegative={false}
                          disabled={false}
                          onChange={value => {
                            setValues({
                              ...values,
                              discountPercent: value.floatValue || 0
                            });
                          }}
                        />
                      )}
                    </Flex>

                    {wizardStep === 2 ? (
                      <span className={classes.result_value}>
                        <span style={{ marginRight: '40px' }}>-</span>
                        <NumberFormat
                          value={values.discount}
                          displayType='text'
                          thousandSeparator='.'
                          decimalSeparator=','
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                        <span>&nbsp;EUR</span>
                      </span>
                    ) : (
                      <LeasingInputControl
                        suffix={'EUR'}
                        prefix={'-'}
                        disabled={true}
                        decimalScale={2}
                        value={values.discount || ''}
                        onChange={value => {
                          setValues({
                            ...values,
                            discount: value.floatValue || 0
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.row}>
                    <span className={classes.label_grey}>Anzahlung / BAFA</span>
                    {wizardStep === 2 ? (
                      <span className={classes.result_value}>
                        <span style={{ marginRight: '40px' }}>-</span>
                        <NumberFormat
                          value={values.deposit}
                          displayType='text'
                          thousandSeparator='.'
                          decimalSeparator=','
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                        <span>&nbsp;EUR</span>
                      </span>
                    ) : (
                      <LeasingInputControl
                        suffix={'EUR'}
                        prefix={'-'}
                        decimalScale={2}
                        value={values.deposit || ''}
                        onChange={value => {
                          setValues({
                            ...values,
                            deposit: value.floatValue || 0
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.divider} />
                  <div className='mb-2' />
                  <div className={classes.row}>
                    <span className={classes.label_blue}>Barpreis Netto</span>
                    <span className={classes.label_blue}>
                      <NumberFormat
                        value={values.netCashPrice}
                        displayType='text'
                        thousandSeparator='.'
                        decimalSeparator=','
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                      &nbsp;EUR
                    </span>
                  </div>
                  <div className='mb-2' />
                  {!showOneTimeCosts && (
                    <div className={classes.row}>
                      <div className={classes.show_onetime_costs_block} onClick={() => setShowOneTimeCosts(true)}>
                        <img src='/assets/images/plus-sign-icon.svg' alt='plus_icon' />
                        <span>einmalige Kosten eingeben</span>
                      </div>
                    </div>
                  )}

                  {showOneTimeCosts && (
                    <>
                      <div className={classes.row}>
                        <span className={classes.label_grey}>Überführung</span>
                        {wizardStep === 2 ? (
                          <span className={classes.result_value}>
                            <NumberFormat
                              value={values.transportation}
                              displayType='text'
                              thousandSeparator='.'
                              decimalSeparator=','
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                            <span>&nbsp;EUR</span>
                          </span>
                        ) : (
                          <LeasingInputControl
                            suffix={'EUR'}
                            prefix={'+'}
                            decimalScale={2}
                            value={values.transportation || ''}
                            onChange={value => {
                              setValues({
                                ...values,
                                transportation: value.floatValue || 0
                              });
                            }}
                          />
                        )}
                      </div>
                      <div className={classes.row}>
                        <span className={classes.label_grey}>Zulassungskosten</span>
                        {wizardStep === 2 ? (
                          <span className={classes.result_value}>
                            <NumberFormat
                              value={values.licensing}
                              displayType='text'
                              thousandSeparator='.'
                              decimalSeparator=','
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                            <span>&nbsp;EUR</span>
                          </span>
                        ) : (
                          <LeasingInputControl
                            suffix={'EUR'}
                            prefix={'+'}
                            decimalScale={2}
                            value={values.licensing || ''}
                            onChange={value => {
                              setValues({
                                ...values,
                                licensing: value.floatValue || 0
                              });
                            }}
                          />
                        )}
                      </div>
                      <hr />
                      <div className={classes.row}>
                        <span className={classes.label_blue}>Gesamtsumme</span>
                        <span className={classes.label_blue}>
                          <NumberFormat
                            value={values.sum}
                            displayType='text'
                            thousandSeparator='.'
                            decimalSeparator=','
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                          &nbsp;EUR
                        </span>
                      </div>
                      {wizardStep === 2 && values.uds_vsl_liefertermin_laut_handler_main && (
                        <>
                          <div className='mt-30' />
                          <div className={classes.row}>
                            <span className={classes.label_grey}>Vsl. Liefertermin</span>
                            <span className={classes.result_value}>
                              {moment(values.uds_vsl_liefertermin_laut_handler_main).format('MM.YYYY')}
                            </span>
                          </div>
                        </>
                      )}
                      {values.statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived &&
                        values.uds_kommentar_des_haendlers && (
                          <>
                            <Text margin='20px 0 10px' color='grey600'>
                              Kommentar
                            </Text>
                            <StyledOneTimeCostsContainer bottom='30'>
                              <Text color='grey600'>{values.uds_kommentar_des_haendlers}</Text>
                            </StyledOneTimeCostsContainer>
                          </>
                        )}
                    </>
                  )}
                  {showNumberAndFile && wizardStep != 2 && wizardStep != 3 && (
                    <div>
                      <div className='mb-2' />
                      <div className={classes.row}>
                        <span className={classes.label_grey}>Angebot-Nr. *</span>
                        <LeasingInputControl
                          decimalScale={2}
                          value={values.offerNumber}
                          suffix={''}
                          type={'text'}
                          className={`${validationError && !values.offerNumber ? classes.error : ''}`}
                          onChange={e => {
                            setValues({
                              ...values,
                              offerNumber: e.target.value
                            });
                          }}
                        />
                      </div>
                      <div className={classes.row}>
                        <span className={classes.label_grey}>Vsl. Liefertermin *</span>
                        <LeasingInputControl
                          value={values.uds_vsl_liefertermin_laut_handler_main}
                          decimalScale={2}
                          suffix={''}
                          minDate={moment().startOf('month').toDate()}
                          type={'date'}
                          className={`${
                            validationError && !values.uds_vsl_liefertermin_laut_handler_main ? classes.error : ''
                          }`}
                          onChange={e => {
                            setValues({
                              ...values,
                              uds_vsl_liefertermin_laut_handler_main: e
                            });
                          }}
                        />
                      </div>
                      <div>
                        <span className={classes.label_grey}>Kommentar</span>
                        <div className={classes.textarea_container}>
                          <textarea
                            className='w-100'
                            rows='3'
                            placeholder='Dein Kommentar...'
                            value={values.uds_kommentar_des_haendlers}
                            onChange={e => {
                              setValues({
                                ...values,
                                uds_kommentar_des_haendlers: e.target.value
                              });
                            }}
                          />
                          {values.uds_kommentar_des_haendlers && (
                            <img
                              onClick={() => {
                                setValues({
                                  ...values,
                                  uds_kommentar_des_haendlers: ''
                                });
                              }}
                              className={classes.clear_btn}
                              src='/assets/images/clear_icon.svg'
                              alt='clear_icon'
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <UploadFile
                          onDropAccepted={files => {
                            setValues({
                              ...values,
                              files: files
                            });
                          }}
                          accept={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']}
                          acceptString={'pdf, jpg, jpeg, png'}
                          multiple={true}
                          className={
                            validationError && !isDealerAndLeasingCompany && !values.files?.length ? classes.error : ''
                          }
                          required={true}
                        />
                      </div>
                    </div>
                  )}

                  {dontSendOffer && (
                    <div className={classes.row}>
                      <span className={classes.dont_send_offer_block_clicked}>Bitte nochmal bestätigen.</span>
                      {wizardStep == 0 && (
                        <div className={classes.button_block}>
                          <button
                            onClick={() => {
                              confirmDontSendOffer();
                            }}
                            className='button-mobexo button-mobexo__secondary'
                          >
                            Bestätigen
                          </button>
                          <div className='mr-1' />
                          <button
                            onClick={() => {
                              setDontSendOffer(false);
                            }}
                            className='button-mobexo button-mobexo__main'
                          >
                            Zurück
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {!dontSendOffer && (
                    <div className={classes.row}>
                      {wizardStep == 0 && (
                        <span
                          className={classes.dont_send_offer_block}
                          onClick={() => {
                            setDontSendOffer(true);
                          }}
                        >
                          Ich möchte kein Angebot abgeben x
                        </span>
                      )}
                      {wizardStep == 0 && (
                        <div className={classes.button_block}>
                          <button
                            onClick={() => {
                              setShowNumberAndFile(true);
                              setWizardStep(1);
                            }}
                            className='button-mobexo button-mobexo__main'
                          >
                            Angebot abgeben
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {wizardStep == 1 && (
                    <div className={classes.button_block}>
                      <button
                        className='button-mobexo button-mobexo__secondary'
                        onClick={() => {
                          setShowNumberAndFile(false);
                          setWizardStep(0);
                          setShowOneTimeCosts(false);
                          setDontSendOffer(false);
                        }}
                      >
                        Abbrechen
                      </button>
                      <div className='mr-1' />
                      <button
                        className='button-mobexo button-mobexo__main'
                        onClick={() => {
                          submitData();
                        }}
                      >
                        Absenden
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        case STEP_NAMES.leasingRequest:
          return (
            <LeasingRequestComponent
              step={step}
              details={details}
              setRefreshKey={setRefreshKey}
              setNewSteps={newSteps => setNewSteps(newSteps, step.id)}
              refreshLeasingOffers={props.refreshLeasingOffers}
              snackbarSetup={snackbarSetup}
            />
          );
      }
    }

    return null;
  };

  const toggleOpenStep = stepId => {
    const newSteps = cloneDeep(steps);
    const clickedStep = newSteps.find(step => step.id === stepId);
    if (clickedStep.status === STEP_STATUSES.closed) {
      return;
    }

    const isClickedStepOpen = clickedStep.open;
    newSteps.forEach(step => {
      step.open = false;
    });
    clickedStep.open = !isClickedStepOpen;

    setSteps(newSteps);
  };

  let globalReferenceVehicleWidget = (
    <>
      {dontSendOfferConfirmed && (
        <div className={classes.dealer_step}>
          <div className={classes.divider} />
          <div className='mb-2' />
          <div className={classes.row}>
            <span className={classes.label_blue}>Status</span>
            <span className={classes.dont_send_confirmed_message}>
              <img src='/assets/images/barpreisanfrage_details_cross_icon.svg' />
              <span>Abgelehnt durch den Händler</span>
            </span>
          </div>
        </div>
      )}
      {!dontSendOfferConfirmed && (
        <div className={classes.dealer_step}>
          <div className={classes.divider} />
          <div className='mb-2' />
          {values.uds_kommentar_fuer_haendler && (
            <>
              <Text margin='20px 0 10px' color='grey600'>
                Kommentar zur Anfrage
              </Text>
              <StyledOneTimeCostsContainer bottom='30'>
                <Text color='grey600'>{values.uds_kommentar_fuer_haendler}</Text>
              </StyledOneTimeCostsContainer>
            </>
          )}
          <div className='mb-2' />
          <div className={classes.row}>
            <span className={classes.label_blue}>Nettolistenpreis</span>
            {wizardStep === 2 ? (
              <span className={classes.label_blue}>
                <NumberFormat
                  value={values.netListPrice}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
                <span>&nbsp;EUR</span>
              </span>
            ) : (
              <LeasingInputControl
                suffix={'EUR'}
                decimalScale={2}
                value={values.netListPrice || ''}
                className={`${validationError && !values.netListPrice ? classes.error : ''}`}
                onChange={value => {
                  setValues({
                    ...values,
                    netListPrice: value.floatValue || 0
                  });
                }}
              />
            )}
          </div>
          <div className={wizardStep === 2 ? `${classes.row} ${classes.row_flex_start}` : classes.row}>
            <Flex align='center' width={wizardStep === 2 ? '40%' : 'calc(57% - 10px)'} justify='space-between'>
              <EllipsisFieldTitle
                title='Herstellerrabatt'
                className={wizardStep === 2 ? classes.label_grey : `${classes.label_grey} ${classes.ellipsis}`}
              />
              {wizardStep !== 2 && (
                <LeasingInputControl
                  suffix={'%'}
                  decimalScale={2}
                  value={values.discountPercent === null ? '' : values.discountPercent || 0}
                  className={classes.discount_percent}
                  allowNegative={false}
                  disabled={false}
                  onChange={value => {
                    setValues({
                      ...values,
                      discountPercent: value.floatValue || 0
                    });
                  }}
                />
              )}
            </Flex>

            {wizardStep === 2 ? (
              <>
                <span className={`${classes.label_grey} ${classes.percent_value}`}>
                  <NumberFormat
                    value={values.discountPercent}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;%</span>
                </span>
                <span className={`${classes.label_grey} ${classes.money_value}`}>
                  <span>-&nbsp;</span>
                  <NumberFormat
                    value={values.discount}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;EUR</span>
                </span>
              </>
            ) : (
              <LeasingInputControl
                suffix={'EUR'}
                prefix={'-'}
                disabled={true}
                decimalScale={2}
                value={values.discount || ''}
                onChange={value => {
                  setValues({
                    ...values,
                    discount: value.floatValue || 0
                  });
                }}
              />
            )}
          </div>

          <div className={wizardStep === 2 ? `${classes.row} ${classes.row_flex_start}` : classes.row}>
            <Flex align='center' width={wizardStep === 2 ? '40%' : 'calc(57% - 10px)'} justify='space-between'>
              <EllipsisFieldTitle
                title='Händlerrabatt'
                className={wizardStep === 2 ? classes.label_grey : `${classes.label_grey} ${classes.ellipsis}`}
              />
              {wizardStep !== 2 && (
                <LeasingInputControl
                  suffix={'%'}
                  decimalScale={2}
                  value={values.dealerDiscountPercent === null ? '' : values.dealerDiscountPercent || 0}
                  className={classes.discount_percent}
                  allowNegative={false}
                  onChange={value => {
                    setValues({
                      ...values,
                      dealerDiscountPercent: value.floatValue || 0
                    });
                  }}
                />
              )}
            </Flex>

            {wizardStep === 2 ? (
              <>
                <span className={`${classes.label_grey} ${classes.percent_value}`}>
                  <NumberFormat
                    value={values.dealerDiscountPercent || 0}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;%</span>
                </span>
                <span className={`${classes.label_grey} ${classes.money_value}`}>
                  <span>-&nbsp;</span>
                  <NumberFormat
                    value={values.dealerDiscount || 0}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;EUR</span>
                </span>
              </>
            ) : (
              <LeasingInputControl
                suffix={'EUR'}
                prefix={'-'}
                disabled={true}
                decimalScale={2}
                value={values.dealerDiscount || ''}
                onChange={value => {
                  setValues({
                    ...values,
                    dealerDiscount: value.floatValue || 0
                  });
                }}
              />
            )}
          </div>

          <div className={classes.row}>
            <span className={classes.label_grey}>Anzahlung</span>
            {wizardStep === 2 ? (
              <span className={classes.label_grey}>
                <span>-&nbsp;</span>
                <NumberFormat
                  value={values.deposit || 0}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
                <span>&nbsp;EUR</span>
              </span>
            ) : (
              <LeasingInputControl
                suffix={'EUR'}
                prefix={'-'}
                decimalScale={2}
                value={values.deposit || ''}
                onChange={value => {
                  setValues({
                    ...values,
                    deposit: value.floatValue || 0
                  });
                }}
              />
            )}
          </div>
          <div className={classes.divider} />
          <div className='mb-2' />
          <div className={classes.row}>
            <span className={classes.label_blue}>Nettobarpreis</span>
            <span className={classes.label_blue}>
              <NumberFormat
                value={values.netCashPrice || 0}
                displayType='text'
                thousandSeparator='.'
                decimalSeparator=','
                fixedDecimalScale={true}
                decimalScale={2}
              />
              &nbsp;EUR
            </span>
          </div>
          <div className='mb-2' />

          <>
            <div className={classes.row}>
              <span className={classes.label_grey}>Überführung</span>
              {wizardStep === 2 ? (
                <span className={classes.label_grey}>
                  <span>+&nbsp;</span>
                  <NumberFormat
                    value={values.transportation || 0}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;EUR</span>
                </span>
              ) : (
                <LeasingInputControl
                  suffix={'EUR'}
                  prefix={'+'}
                  decimalScale={2}
                  value={values.transportation || ''}
                  onChange={value => {
                    setValues({
                      ...values,
                      transportation: value.floatValue || 0
                    });
                  }}
                />
              )}
            </div>
            <div className={classes.row}>
              <span className={classes.label_grey}>Zulassungskosten</span>
              {wizardStep === 2 ? (
                <span className={classes.label_grey}>
                  <span>+&nbsp;</span>
                  <NumberFormat
                    value={values.licensing || 0}
                    displayType='text'
                    thousandSeparator='.'
                    decimalSeparator=','
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                  <span>&nbsp;EUR</span>
                </span>
              ) : (
                <LeasingInputControl
                  suffix={'EUR'}
                  prefix={'+'}
                  decimalScale={2}
                  value={values.licensing || ''}
                  onChange={value => {
                    setValues({
                      ...values,
                      licensing: value.floatValue || 0
                    });
                  }}
                />
              )}
            </div>
            <div className={classes.divider} />
            <div className='mb-2' />
            <div className={classes.row}>
              <span className={classes.label_blue}>Gesamtpreis</span>
              <span className={classes.label_blue}>
                <NumberFormat
                  value={values.sum}
                  displayType='text'
                  thousandSeparator='.'
                  decimalSeparator=','
                  fixedDecimalScale={true}
                  decimalScale={2}
                />
                &nbsp;EUR
              </span>
            </div>

            {wizardStep === 2 && (
              <>
                <Distance top='80' />
                <div className={classes.row}>
                  <span className={classes.label_grey}>Angebot-Nr.</span>
                  <span className={classes.label_grey}>{values.offerNumber}</span>
                </div>
              </>
            )}
            {wizardStep === 2 && values.uds_vsl_liefertermin_laut_handler_main && (
              <div className={classes.row}>
                <span className={classes.label_grey}>Vsl. Liefertermin</span>
                <span className={classes.label_grey}>
                  {moment(values.uds_vsl_liefertermin_laut_handler_main).format('MM.YYYY')}
                </span>
              </div>
            )}
            {wizardStep === 2 && (
              <>
                <div className={classes.row}>
                  <span className={classes.label_grey}>Baubarkeit bestätigt</span>
                  <span className={classes.label_grey}>Ja</span>
                </div>
                <div className={classes.row}>
                  <span className={classes.label_grey}>Gesamtpreis bestätigt</span>
                  <span className={classes.label_grey}>Ja</span>
                </div>
              </>
            )}
            {values.statusCode === PRICE_REQUEST_STATUS_CODES.offerReceived && values.uds_kommentar_des_haendlers && (
              <>
                <Text margin='20px 0 10px' color='grey600'>
                  Kommentar
                </Text>
                <StyledOneTimeCostsContainer bottom='30'>
                  <Text color='grey600'>{values.uds_kommentar_des_haendlers}</Text>
                </StyledOneTimeCostsContainer>
              </>
            )}
          </>

          {wizardStep != 2 && wizardStep != 3 && (
            <div>
              <Distance top='60' />
              <div className={classes.row}>
                <span className={classes.label_grey}>Angebot-Nr. *</span>
                <LeasingInputControl
                  decimalScale={2}
                  value={values.offerNumber}
                  suffix={''}
                  type={'text'}
                  className={`${validationError && !values.offerNumber ? classes.error : ''}`}
                  onChange={e => {
                    setValues({
                      ...values,
                      offerNumber: e.target.value
                    });
                  }}
                />
              </div>
              <div className={classes.row}>
                <span className={classes.label_grey}>Vsl. Liefertermin *</span>
                <LeasingInputControl
                  value={values.uds_vsl_liefertermin_laut_handler_main}
                  decimalScale={2}
                  suffix={''}
                  minDate={moment().startOf('month').toDate()}
                  type={'date'}
                  className={`${
                    validationError && !values.uds_vsl_liefertermin_laut_handler_main ? classes.error : ''
                  }`}
                  onChange={e => {
                    setValues({
                      ...values,
                      uds_vsl_liefertermin_laut_handler_main: e
                    });
                  }}
                />
              </div>
              <div>
                <span className={classes.label_grey}>Kommentar</span>
                <div className={classes.textarea_container}>
                  <textarea
                    className='w-100'
                    rows='3'
                    placeholder='Dein Kommentar...'
                    value={values.uds_kommentar_des_haendlers}
                    onChange={e => {
                      setValues({
                        ...values,
                        uds_kommentar_des_haendlers: e.target.value
                      });
                    }}
                  />
                  {values.uds_kommentar_des_haendlers && (
                    <img
                      onClick={() => {
                        setValues({
                          ...values,
                          uds_kommentar_des_haendlers: ''
                        });
                      }}
                      className={classes.clear_btn}
                      src='/assets/images/clear_icon.svg'
                      alt='clear_icon'
                    />
                  )}
                </div>
              </div>
              <div>
                <Text margin='10px 0' color='darkBlue'>
                  Angebot hochladen{' '}
                  <Text color='grey400' as='span'>
                    (optional)
                  </Text>
                </Text>
                <UploadFile
                  onDropAccepted={files => {
                    setValues({
                      ...values,
                      files: files
                    });
                  }}
                  accept={['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']}
                  acceptString={'pdf, jpg, jpeg, png'}
                  multiple={true}
                  required={true}
                />
              </div>
            </div>
          )}

          {wizardStep !== 2 && (
            <>
              <Distance top='60' />
              <div className={classes.divider} />
              <div className='mb-1' />
              <Flex top='10' align='center'>
                <ThreeStateCheckbox
                  indeterminate={false}
                  className={null}
                  onChange={data => checkboxChanged(data, CHECKBOXES.buildAbilityConfirmed)}
                  checked={buildAbilityConfirmed}
                />
                <Text
                  left='5'
                  pointer
                  onClick={() =>
                    checkboxChanged(
                      { checked: buildAbilityConfirmed, indeterminate: false },
                      CHECKBOXES.buildAbilityConfirmed
                    )
                  }
                >
                  Baubarkeit wird bestätigt
                </Text>
              </Flex>

              <Flex padding='0 0 0 30px' margin='5px 0 10px' align='center'>
                {validationError && !buildAbilityConfirmed && (
                  <>
                    <CircleExclamationMark color={theme.colors.pink} width={'12'} height={'12'} />
                    <Text left='10' size='12' color='pink'>
                      Dieses Feld wird benötigt.
                    </Text>
                  </>
                )}
              </Flex>

              <Flex top='10' align='center'>
                <ThreeStateCheckbox
                  indeterminate={false}
                  className={null}
                  onChange={data => checkboxChanged(data, CHECKBOXES.priceConfirmed)}
                  checked={priceConfirmed}
                />
                <Text
                  left='5'
                  pointer
                  onClick={() =>
                    checkboxChanged({ checked: priceConfirmed, indeterminate: false }, CHECKBOXES.priceConfirmed)
                  }
                >
                  Gesamtpreis wird bestätigt
                </Text>
              </Flex>

              <Flex padding='0 0 0 30px' margin='5px 0 10px' align='center'>
                {validationError && !priceConfirmed && (
                  <>
                    <CircleExclamationMark color={theme.colors.pink} width={'12'} height={'12'} />
                    <Text left='10' size='12' color='pink'>
                      Dieses Feld wird benötigt.
                    </Text>
                  </>
                )}
              </Flex>
            </>
          )}

          {dontSendOffer && (
            <div className={classes.row}>
              <span className={classes.dont_send_offer_block_clicked}>Bitte nochmal bestätigen.</span>
              {wizardStep == 0 && (
                <div className={classes.button_block}>
                  <button
                    onClick={() => {
                      confirmDontSendOffer();
                    }}
                    className='button-mobexo button-mobexo__secondary'
                  >
                    Bestätigen
                  </button>
                  <div className='mr-1' />
                  <button
                    onClick={() => {
                      setDontSendOffer(false);
                    }}
                    className='button-mobexo button-mobexo__main'
                  >
                    Zurück
                  </button>
                </div>
              )}
            </div>
          )}

          {!dontSendOffer && (
            <div className={classes.row}>
              {wizardStep == 0 && (
                <span
                  className={`${classes.dont_send_offer_block} ${classes.dont_send_offer_blockGRV}`}
                  onClick={() => {
                    setDontSendOffer(true);
                  }}
                >
                  Ich möchte kein Angebot abgeben x
                </span>
              )}
              {wizardStep == 0 && (
                <div className={`${classes.button_block} ${classes.button_block_GRV}`}>
                  <button
                    onClick={() => {
                      submitData();
                    }}
                    className='button-mobexo button-mobexo__main'
                  >
                    Preise und Baubarkeit bestätigen
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      {requestVehicleFromCarDealershipEvent && (
        <div className={classes.container}>
          <WidgetDocumnetContainer className={classes.widget} type={WidgetType.Simple}>
            <WidgetHeader title='Bestellung' icon={<img src='/assets/images/dokumente_icon_blue.svg' alt='icon' />} />
            <RequestConfirmation
              events={props.events}
              refreshLeasingOffers={props.refreshLeasingOffers}
              vehicleOfferId={values.id}
            />
          </WidgetDocumnetContainer>
        </div>
      )}
      <div className={classes.container}>
        <WidgetDocumnetContainer className={classes.widget} type={WidgetType.Simple}>
          <>
            <WidgetHeader
              title={isGlobalReferenceVehicle ? 'Preis und Baubarkeit bestätigen' : `Angebot für ${values.accountName}`}
              icon={
                <img
                  src={
                    isGlobalReferenceVehicle ? '/assets/images/dokumente_icon_blue.svg' : '/assets/images/euro_blue.svg'
                  }
                  alt='icon'
                />
              }
            />
            <hr className={classes.hr_widget} />
            <div className={classes.content_wrapper}>
              <div className={`${classes.row} ${classes.account_row}`}>
                <span className={classes.label_blue}>Kunde</span>
                <span className={classes.firm_block}>
                  <span>{values.accountName}</span>
                  <span>{values.accountStreet}</span>
                  <span>{`${values.accountPostalCode} ${values.accountCity}`}</span>
                </span>
              </div>

              {userData && (
                <div className={`${classes.row} ${classes.account_row}`}>
                  <span className={classes.label_blue}>Fahrer</span>
                  <span className={classes.firm_block}>
                    <span>
                      {userData.fullname || (
                        <i>
                          <Text as='span'>nicht angegeben</Text>
                        </i>
                      )}
                    </span>
                    <span>
                      {userData.telephone1 ? (
                        <a style={{ color: '#777' }} href={`tel:${userData.telephone1}`}>
                          <Text as='span'>{userData.telephone1}</Text>
                        </a>
                      ) : (
                        <i>nicht angegeben</i>
                      )}
                    </span>
                    <span>
                      {userData.emailaddress1 ? (
                        <a style={{ color: '#777' }} href={`mailto:${userData.emailaddress1}`}>
                          <Text as='span'>{userData.emailaddress1}</Text>
                        </a>
                      ) : (
                        <i>nicht angegeben</i>
                      )}
                    </span>
                  </span>
                </div>
              )}

              {values.customersizecode && (
                <div className={classes.row}>
                  <Text color='grey600'>Fuhrparkgröße</Text>
                  <span className={classes.firm_block}>
                    <span>{values.customersizecode}</span>
                  </span>
                </div>
              )}
              {isGlobalReferenceVehicle && details.uds_wunschlieferdatum && (
                <div className={classes.row}>
                  <Text color='grey600'>Wunschlieferdatum</Text>
                  <span className={classes.firm_block}>
                    <span>
                      {new Date(details.uds_wunschlieferdatum).toLocaleDateString('de-DE', {
                        month: 'long',
                        year: 'numeric'
                      })}
                    </span>
                  </span>
                </div>
              )}
            </div>

            {isGlobalReferenceVehicle
              ? globalReferenceVehicleWidget
              : steps.map((step, i) => {
                  if (!step.visibleFor.some(visibleForRole => visibleForRole.id === mainRoleId)) {
                    return null;
                  }
                  if (step.id === STEP_NAMES.priceOffer && details.uds_anbietenterhandlerid_id !== user.companyId) {
                    return null;
                  }
                  return (
                    <div key={i} className={classes.step}>
                      <div
                        onClick={() => toggleOpenStep(step.id)}
                        className={
                          step.status === STEP_STATUSES.active
                            ? `${classes.step_header} ${classes.active}`
                            : classes.step_header
                        }
                      >
                        <div className='d-flex a-i-center'>
                          {getIcon(step)}
                          <div className={classes.title_container}>
                            <h4>{step.name}</h4>
                            {step.description && <p>{step.description}</p>}
                          </div>
                        </div>
                        {step.badge && step.status === STEP_STATUSES.active && (
                          <span className={classes.badge}>{step.badge}</span>
                        )}
                      </div>
                      <div className={classes.step_body}>{getStepBody(step)}</div>
                    </div>
                  );
                })}

            <Snackbar
              open={openSnackbar}
              type={snackbarType}
              onClose={() => setOpenSnackbar(false)}
              message={snackbarMessage}
            />
          </>
        </WidgetDocumnetContainer>
      </div>

      <WidgetDocumnetContainer className={classes.widget} type={WidgetType.Simple}>
        <>
          <WidgetHeader title='Dokumente' icon={<img src='/assets/images/download_blue_small.svg' alt='download' />} />
          <hr className={classes.hr_widget} />
          {values.id && (
            <div className={classes.files_block}>
              <DealerPageFiles
                refreshKey={refreshKey}
                leasingOffers={props.leasingOffers}
                offerConfirmed={[VEHICLE_OFFER_STATUSES.priceReleased, VEHICLE_OFFER_STATUSES.offerReceived].includes(
                  details.uds_angebotsstatuscode_value
                )}
                offerId={values.id}
              />
            </div>
          )}
        </>
      </WidgetDocumnetContainer>
    </>
  );
};

export default Details;
