import styled, { css } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { TabItemFrameProps } from '../../tab-item-frame/tab-item-frame.props';

export const StyledTabItemSection = styled(Flex)<Pick<TabItemFrameProps, 'isActive'>>`
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.blue)};
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.blue : theme.colors.white)};
  transition: 0.3s;

  ${({ isActive }) =>
    css`
      &:hover {
        box-shadow: ${isActive ? 'none' : '0.5px 3px 10px rgba(0, 0, 0, 0.25)'};
      }
    `}
`;

export const StyledTabTriangle = styled.div`
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: ${({ theme }) => `10px solid ${theme.colors.blue}`};
  margin: 0 auto;
`;
