import axios from 'axios';
import authService from './auth-service';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const getUmfrageInfo = async (id: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/umfrage/getUmfrageInfo`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      umfrageId: id
    }
  });
};

export const updateUmfrage = async (data: any) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/umfrage/updateUmfrage`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data
  });
};
