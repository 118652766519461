import { connect } from 'react-redux';

import DashboardItems from './dashboard-items-view';
import { setSnakbar, toggleModal, setEndLoading, setStartLoading } from '../../../actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';

const mapStateToProps = state => ({
  user: state.app.user,
  smartTable: state.app.smartTable
});

const mapDispatchToProps = dispatch => ({
  openAlert(data) {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, data));
  },
  closeAlert() {
    dispatch(toggleModal(MODALS.alert, null));
  },
  hideOverlay() {
    dispatch(setEndLoading());
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  setSnakbar(data) {
    dispatch(setSnakbar(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardItems);
