import { Flex, Text } from 'common/common-components.styled';
import { StyledIconContainer } from 'pages/details/order-widget/order-widget.styled';
import { getIcon } from 'components/download-documents-component/download-documents-component';
import React from 'react';
import { FileModel } from 'common/interfaces';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { StyledFileName } from 'pages/details/document-section-file-component/document-section-file-component.styled';

interface DocumentSectionFileComponentProps {
  file: FileModel;
  download(id: string): void;
  className?: string;
}

const DocumentSectionFileComponent = ({ file, download, className }: DocumentSectionFileComponentProps) => {
  const filePathArr = file.path.split('/');
  const fileName = filePathArr[filePathArr.length - 1];

  return (
    <Flex
      onClick={() => download(file.id)}
      className={className}
      style={{ width: '100%', cursor: 'pointer' }}
      justify='space-between'
      align='center'
      bottom='20'
    >
      <Flex align='center'>
        <StyledIconContainer align='center' left='5'>
          {getIcon(fileName)}
        </StyledIconContainer>
        <Flex direction='column'>
          <StyledFileName bottom='3' color='darkBlue'>
            {fileName}
          </StyledFileName>
          <Text as='span' size='10' color='grey500'>
            {`${file.description} • ${(file.size / 1024).toFixed(1)} KB • ${getDateString(file.created)}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DocumentSectionFileComponent;
