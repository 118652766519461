import React, { useContext } from 'react';
import { FormInfoListContext } from '../form-info-list.context';
import { FormInfoItemDefault } from './type/default/default';
import { FormInfoItemEditable } from './type/editable/editable';
import { FormInfoItemDefaultProps } from './type/default/default.props';
import { FormInfoItemEditableProps } from './type/editable/editable.props';

export const FormInfoItemController = (props: FormInfoItemDefaultProps | FormInfoItemEditableProps) => {
  const { type } = useContext(FormInfoListContext);

  switch (type) {
    case 'editable':
      return <FormInfoItemEditable {...(props as FormInfoItemEditableProps)} />;

    default:
      return <FormInfoItemDefault {...props} />;
  }
};
