import React, { useState } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { ComponentsProps } from './components.props';

const TextareaFieldComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldName,
  fieldPlaceholder = 'Text',
  fieldLength = '1500',
  optional = false,
  needTooltip = false,
  tooltipText = '',
  tooltipPosition = 'top'
}: ComponentsProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    register,
    formState: { errors }
  } = reactHookFormData;

  return (
    <div className={styles.field_container_textarea}>
      <div className={!needTooltip ? styles.form_name : styles.form_name_tooltip}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>(optional)</span>}
          {needTooltip && (
            <div
              className={styles.tooltip}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <TooltipHint position={tooltipPosition} text={tooltipText}>
                <InfoHintIcon color='#919699' width={16} height={16} hovered={isHovered} />
              </TooltipHint>
            </div>
          )}
        </span>
      </div>
      <textarea
        id={`textarea${fieldName}`}
        {...register(fieldRegister, { required: !optional })}
        placeholder={fieldPlaceholder}
        maxLength={Number(fieldLength)}
        className={errors[fieldRegister] && !optional ? styles.input__icons_err__textarea : styles.text__input}
      />
      <div className={styles.placeholer}>max. 1500 Zeichen</div>
      <div className={styles.err__row}>
        {errors[fieldRegister] && (
          <>
            <img src='/assets/images/err-icon.svg' />
            <p>{fieldName} erforderlich.</p>
          </>
        )}
      </div>

      <Distance top='10' />
    </div>
  );
};

export default TextareaFieldComponent;
