import React, { useState } from 'react';
import classes from './fahrzeugkosten-filters.module.scss';
import { useDispatch } from 'react-redux';
import { setFahrzeugkosten } from '../../../actions/app_action';
import {
  ClearAllFiltersButton,
  Filter,
  FilterButton,
  FilterContainer,
  FilterInput,
  PickersContainer
} from '../../../components/filter-items';
import { emptyFilterForClient, emptyFilterForServer } from '../fahrzeugkosten-page';
import { CRM_VALUE } from '../fahrzeugkosten-page';
import { convertDateToServer } from 'utils/convert-date-to-server';
import {FilterDataProvider} from '../components/filter-data-provider';
import {CostTypeFilterProvider} from '../components/cost-type-filter-provider';
import {statusData} from '../utils';
import { FILTER_LABEL } from 'constants';

const FahrzeugkostenFilters = ({ sessionFilter, setSessionFilter, goToFirstPage }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState('');

  const toggleFilterIsOpen = (title = null) => {
    setOpen(title);
  };

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'rechnungsbezug':
        return CRM_VALUE.invoiceReference;

      case 'faktura':
        return CRM_VALUE.invoiceNumber;

      case 'fakturadatum':
        return CRM_VALUE.invoiceDate;

      case 'rg-empfänger':
        return CRM_VALUE.company;

      case 'fremdbeleg':
        return CRM_VALUE.secondInvoiceNumber;

      case 'rg-datum':
        return CRM_VALUE.rgDatum;

      case 'kostenart':
        return CRM_VALUE.costType;

      case 'kostenartgroup':
        return CRM_VALUE.costTypeGroup;

      case 'kennzeichen':
        return CRM_VALUE.car;

      case 'fahrzeugstatus':
        return CRM_VALUE.status;

      case 'bu.-nummer':
        return CRM_VALUE.businessNumber;

      default:
        return '';
    }
  };

  const saveFilter = (title, serverValue, clientValue) => {
    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue }
    );
  };

  const invoiceNameLabel = sessionFilter?.filterForClient[CRM_VALUE.invoiceReference];
  const invoiceNumber = sessionFilter?.filterForClient[CRM_VALUE.invoiceNumber];
  const invoiceDate = sessionFilter?.filterForClient[CRM_VALUE.invoiceDate];
  const companyLabel = sessionFilter?.filterForClient[CRM_VALUE.company];
  const businessNumber = sessionFilter?.filterForClient[CRM_VALUE.businessNumber];
  const secondInvoiceNumber = sessionFilter?.filterForClient[CRM_VALUE.secondInvoiceNumber];
  const rgDatumLabel = sessionFilter?.filterForClient[CRM_VALUE.rgDatum];
  const costTypeLabel = sessionFilter?.filterForClient[CRM_VALUE.costType];
  const costTypeGroupLabel = sessionFilter?.filterForClient[CRM_VALUE.costTypeGroup];
  const carLabel = sessionFilter?.filterForClient[CRM_VALUE.car];
  const statusLabel = sessionFilter?.filterForClient[CRM_VALUE.status];

  const isDateTitle = title => {
    return (
      convertHeaderToCrmName(title) === CRM_VALUE.invoiceDate || convertHeaderToCrmName(title) === CRM_VALUE.rgDatum
    );
  };

  const clearFilter = title => {
    toggleFilterIsOpen();
    goToFirstPage();

    if (!title) {
      return setSessionFilter(emptyFilterForServer, emptyFilterForClient);
    }

    if (isDateTitle(title)) {
      return saveFilter(title, { startdate: null, enddate: null }, null);
    }

    saveFilter(title, null, null);
  };

  const handleInput = (title, value, filterItemsList = []) => {
    toggleFilterIsOpen();
    goToFirstPage();

    if (convertHeaderToCrmName(title) === CRM_VALUE.invoiceNameLabel) {
      const invoice= filterItemsList?.find(invoiceItem => invoiceItem?.name === value);
      if (invoice) {
        saveFilter(title, invoice.name, invoice.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.invoiceNumber) {
      saveFilter(title, value, value);
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.company) {
      const company = filterItemsList.find( companyItem => companyItem?.name === value);
      if (company) {
        saveFilter(title, company.id, company.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.businessNumber) {
      saveFilter(title, value, value);
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.secondInvoiceNumber) {
      saveFilter(title, value, value);
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.costType) {
      const kostenart = filterItemsList.find(item => item.value === value);
      if (kostenart) {
        const newFilterDataServer = {
          [CRM_VALUE.costTypeGroup]: null,
          [CRM_VALUE.costType]: kostenart.key
        };
        const newFilterDataClient = {
          [CRM_VALUE.costTypeGroup]: null,
          [CRM_VALUE.costType]: kostenart.value
        };
        setSessionFilter(newFilterDataServer, newFilterDataClient);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.costTypeGroup) {
      const kostenartGroup = filterItemsList.find(itemName => itemName === value);
      if (kostenartGroup) {
        const newFilterData = {
          [CRM_VALUE.costTypeGroup]: kostenartGroup,
          [CRM_VALUE.costType]: null
        };
        setSessionFilter(newFilterData, newFilterData);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.car) {
      const car = filterItemsList?.find( carItem => carItem?.id === value?.id);
      if (car) {
        saveFilter(title, car.id, car.name);
      }
      return;
    }
    if (isDateTitle(title)) {
      return saveFilter(title, convertDateToServer(value), value);
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
      Object.values(statusData).forEach(status => {
        if (status.value === +value) {
          saveFilter(title, +value, status.label);
        }
      });
      return;
    }

    dispatch(setFahrzeugkosten(''));
    saveFilter(title, value, value);
  };

  return (
    <section className={classes.filters}>
      <Filter
        title={'Rechnungsbezug'}
        isOpen={isOpen}
        value={invoiceNameLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Rechnungsbezug'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterDataProvider
            handleInput={handleInput}
            title={'Rechnungsbezug'}
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Faktura'}
        isOpen={isOpen}
        value={invoiceNumber}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Faktura'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput title='Faktura' isHideNothingFoundPlaceholder handleInput={handleInput} dropListData={[]} />
        </FilterContainer>
      </Filter>
      <Filter
        value={invoiceDate}
        title='Fakturadatum'
        isOpen={isOpen}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Fakturadatum'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <div className={classes.filter_buttons_wrapper}>
            <FilterButton
              title='Fakturadatum'
              label={FILTER_LABEL.year.last}
              value={FILTER_LABEL.year.last}
              handleInput={handleInput}
            />
            <FilterButton
              title='Fakturadatum'
              label={FILTER_LABEL.year.current}
              value={FILTER_LABEL.year.current}
              handleInput={handleInput}
            />
          </div>
          <PickersContainer title='Fakturadatum' value={invoiceDate} handleInput={handleInput} />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Rg-Empfänger'}
        isOpen={isOpen}
        value={companyLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Rg-Empfänger'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterDataProvider
            handleInput={handleInput}
            title={'Rg-Empfänger'}
            withoutSearchButton
            filteredButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Bu.-Nummer'}
        isOpen={isOpen}
        value={businessNumber}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Bu.-Nummer'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterDataProvider
            handleInput={handleInput}
            title={'Bu.-Nummer'}
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Fremdbeleg'}
        isOpen={isOpen}
        value={secondInvoiceNumber}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Fremdbeleg'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterInput title='Fremdbeleg' isHideNothingFoundPlaceholder handleInput={handleInput} dropListData={[]} />
        </FilterContainer>
      </Filter>
      <Filter
        value={rgDatumLabel}
        title='Rg-Datum'
        isOpen={isOpen}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Rg-Datum'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <div className={classes.filter_buttons_wrapper}>
            <FilterButton
              title='Rg-Datum'
              label={FILTER_LABEL.year.last}
              value={FILTER_LABEL.year.last}
              handleInput={handleInput}
            />
            <FilterButton
              title='Rg-Datum'
              label={FILTER_LABEL.year.current}
              value={FILTER_LABEL.year.current}
              handleInput={handleInput}
            />
          </div>
          <PickersContainer title='Rg-Datum' value={rgDatumLabel} handleInput={handleInput} />
        </FilterContainer>
      </Filter>
      <Filter
        value={costTypeLabel || costTypeGroupLabel}
        title={costTypeGroupLabel ? 'KostenartGroup' : 'Kostenart'}
        isOpen={isOpen}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Kostenart'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <CostTypeFilterProvider
             title='Kostenart'
             handleInput={handleInput}
             withoutSearchButton
             filteredButton
          />
        </FilterContainer>
      </Filter>
      <Filter
        title={'Kennzeichen'}
        isOpen={isOpen}
        value={carLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Kennzeichen'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterDataProvider
            handleInput={handleInput}
            title={'Kennzeichen'}
            dropListDataWithId={true}
            dropListDataValueName='name'
            withoutSearchButton
            filteredButton
            circleIndicator
          />
        </FilterContainer>
      </Filter>

      <Filter
        title={'Fahrzeugstatus'}
        isOpen={isOpen}
        value={statusLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Fahrzeugstatus'
          onClose={() => {
            toggleFilterIsOpen();
          }}
        >
          <FilterButton title='Fahrzeugstatus' handleInput={handleInput} label='Aktiv' value={0} />
          <FilterButton title='Fahrzeugstatus' handleInput={handleInput} label='Inaktiv' value={1} />
          <FilterButton title='Fahrzeugstatus' handleInput={handleInput} label='Alle Fahrzeuge' value={2} />
        </FilterContainer>
      </Filter>

      <ClearAllFiltersButton filterState={sessionFilter.filterForClient} clearFilter={clearFilter} />
    </section>
  );
};

export default FahrzeugkostenFilters;
