import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export interface MarginProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  margin?: string;
}

export const marginMixin = css<MarginProps>`
  ${({ top }) =>
    top &&
    css`
      margin-top: ${top || '0'}px;
    `};
  ${({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom || '0'}px;
    `};
  ${({ left }) =>
    left &&
    css`
      margin-left: ${left || '0'}px;
    `};
  ${({ right }) =>
    right &&
    css`
      margin-right: ${right || '0'}px;
    `};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin || '0'};
    `};
`;

type PaddingProps = Pick<CSSProperties, 'padding' | 'paddingRight' | 'paddingBottom'>;

const paddingMixin = css<PaddingProps>`
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding || '0'};
    `};
  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight || '0'};
    `};
  ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom || '0'};
    `};
`;

type PositionProps = Partial<Record<'positionTop' | 'positionBottom' | 'positionLeft' | 'positionRight', string>>;

const positionMixin = css<PositionProps>`
  ${({ positionTop }) =>
    positionTop &&
    css`
      top: ${positionTop};
    `};
  ${({ positionBottom }) =>
    positionBottom &&
    css`
      bottom: ${positionBottom};
    `};
  ${({ positionLeft }) =>
    positionLeft &&
    css`
      left: ${positionLeft};
    `};
  ${({ positionRight }) =>
    positionRight &&
    css`
      right: ${positionRight};
    `};
`;

export interface FlexProps
  extends MarginProps,
    PaddingProps,
    PositionProps,
    Pick<
      CSSProperties,
      | 'position'
      | 'zIndex'
      | 'borderBottomLeftRadius'
      | 'borderBottomRightRadius'
      | 'flex'
      | 'minWidth'
      | 'borderWidth'
      | 'borderColor'
      | 'borderStyle'
      | 'cursor'
      | 'borderRadius'
      | 'visibility'
      | 'overflow'
      | 'transform'
      | 'maxHeight'
      | 'order'
      | 'gridArea'
    > {
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'stretch' | 'space-around';
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  direction?: 'row' | 'column' | 'column-reverse';
  wrap?: 'wrap' | 'nowrap';
  width?: string;
  height?: string;
  maxWidth?: string;
  pointer?: boolean;
  gap?: string;
  minHeight?: string;
  opacity?: string;
  isHideScrollBar?: boolean;
}

export type GridProps = MarginProps &
  Pick<CSSProperties, 'gridTemplateColumns' | 'gap' | 'width'> & {
    align?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  };

export const Grid = styled.div<GridProps>`
  ${marginMixin}
  display: grid;
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    css`
      grid-template-columns: repeat(${gridTemplateColumns}, minmax(100px, 1fr));
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `};
  ${({ align }) =>
    align &&
    css`
      align-items: ${align || 'stretch'};
    `};
  ${({ width }) => width && css`width ${width};`};
`;

export const Flex = styled.div<FlexProps>`
  ${marginMixin};
  ${paddingMixin};
  ${positionMixin};
  display: flex;
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify || 'stretch'};
    `};
  ${({ align }) =>
    align &&
    css`
      align-items: ${align || 'stretch'};
    `};
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction || 'row'};
    `};
  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: ${wrap || 'nowrap'};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `};

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `};
  ${({ theme, color }) =>
    color &&
    css`
      background-color: ${theme.colors[color] || theme.colors.white};
    `};
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `};
  ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius &&
    css`
      border-bottom-left-radius: ${borderBottomLeftRadius};
    `};
  ${({ borderBottomRightRadius }) =>
    borderBottomRightRadius &&
    css`
      border-bottom-right-radius: ${borderBottomRightRadius};
    `};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};
  ${({ borderWidth }) =>
    borderWidth &&
    css`
      border-width: ${borderWidth};
    `};
  ${({ borderColor, theme }) =>
    borderColor &&
    css`
      border-color: ${theme.colors[borderColor]};
    `};
  ${({ borderStyle }) =>
    borderStyle &&
    css`
      border-style: ${borderStyle};
    `};
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `};
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `};
  ${({ visibility }) =>
    visibility &&
    css`
      visibility: ${visibility};
    `};
  ${({ transform }) =>
    transform &&
    css`
      transform: ${transform};
    `};
  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `};
  ${({ isHideScrollBar }) =>
    isHideScrollBar &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;
    `};
  ${({ order }) =>
    order &&
    css`
      order: ${order};
    `};
  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `};
`;

interface DistanceProps {
  top?: string;
  side?: string;
}

export const Distance = styled.div<DistanceProps>`
  ${({ top }) =>
    top &&
    css`
      margin-top: ${top || '0'}px;
    `};
  ${({ side }) =>
    side &&
    css`
      margin-left: ${side || '0'}px;
    `};
`;

export const Panel = styled.section<Pick<CSSProperties, 'width'>>`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`;

interface DividerProps extends MarginProps {
  vertical?: boolean;
  color?: string;
  width?: string;
  opacity?: string;
}

export const Divider = styled.div<DividerProps>`
  ${marginMixin};
  border-bottom: 1px solid ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.grey300)};
  ${({ vertical, color }) =>
    vertical &&
    css`
      border-left: 1px solid ${({ theme }) => (color ? theme.colors[color] : theme.colors.grey300)};
      border-bottom: none;
    `};
  width: ${({ width }) => width};
  opacity: ${({ opacity }) => opacity};
`;

export interface TextProps
  extends MarginProps,
    PaddingProps,
    Pick<CSSProperties, 'wordBreak' | 'opacity' | 'flex' | 'lineHeight' | 'maxHeight' | 'display' | 'maxWidth' | 'minHeight'> {
  color?: string;
  size?: string;
  textAlign?: string;
  bold?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  pointer?: boolean;
  textOverflow?: string;
  overflow?: string;
  whiteSpace?: string;
  width?: string;
  fontWeight?: number;
  minWidth?: string;
  height?: string;
  italic?: boolean;
}

export const Text = styled.p<TextProps>`
   font-type: 'Open Sans';
  ${marginMixin};
  ${paddingMixin};
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color] || theme.colors.grey600};
    `};
  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `};
  ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
    `};
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `};
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `};
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};
  ${({ textOverflow }) =>
    textOverflow &&
    css`
      text-overflow: ${textOverflow};
    `};
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `};
  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `};
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
`;

interface TitleProps extends MarginProps {
  color?: string;
  size?: string;
}

export const Title = styled.h3<TitleProps>`
  margin: 0;
  ${marginMixin};
  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.colors[color] || theme.colors.grey600};
    `};
  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `};
`;

export const errorMixin = css`
  border: 1px solid ${({ theme }) => theme.colors.pink} !important;
  box-shadow: 0 0 3px 0 ${({ theme }) => theme.colors.pink};
`;

interface ButtonProps extends MarginProps {
  secondary?: boolean;
  disabled?: boolean;
  tertiary?: boolean;
  minWidth?: string;
}

export const Button = styled.button<ButtonProps>`
  ${marginMixin};
  padding: 6px 15px;
  border-radius: 2px;
  transition: box-shadow 0.3s;
  cursor: pointer;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border: none;

  &:hover {
    box-shadow: 2px 2px 7px ${({ theme }) => theme.colors.grey300};
    text-decoration: none;
  }
  ${({ secondary }) =>
    secondary &&
    css`
      color: ${({ theme }) => theme.colors.blue};
      border: 2px solid ${({ theme }) => theme.colors.blue};
      background-color: ${({ theme }) => theme.colors.white};
      padding: 4px 15px;
    `};

  ${({ disabled, secondary }) =>
    disabled &&
    !secondary &&
    css`
    background-color: ${({ theme }) => theme.colors.grey200};
    &:hover {
    box-shadow: none;
  `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.white};
      border: none;
      background-color: ${({ theme }) => theme.colors.grey200};
      padding: 6px 15px;

      &:hover {
        box-shadow: none;
        text-decoration: none;
      }
    `};

  ${({ disabled, secondary }) =>
    disabled &&
    secondary &&
    css`
    border: 2px solid ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.grey300};
    background-color: ${({ theme }) => theme.colors.white};
    padding: 4px 15px;
    &:hover {
    box-shadow: none;
  `};

  ${({ tertiary }) =>
    tertiary &&
    css`
      color: ${({ theme }) => theme.colors.white};
      border: none;
      background-color: ${({ theme }) => theme.colors.darkBlue};
      padding: 6px 15px;
    `};

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};
`;

interface ICircle {
  size: string;
  color: string;
  marginLeft?:string;
  marginRight?:string;
};

export const Circle = styled(Flex)<ICircle>`
  flex-shrink: 0;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-radius: 100%;
  margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
  margin-right: ${({ marginRight }) => marginRight ?? '0px'};
`;

export const CircleOutline = styled(Flex)<ICircle>`
  flex-shrink: 0;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({ theme, color }) => theme.colors[color]};
  background-color: transparent;
`;

interface IStyledRotate extends Pick<CSSProperties, 'transition'>, MarginProps {
  rotateDirection?: Direction;
}

export enum Direction {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}

export const Rotate = styled(Flex)<IStyledRotate>`
  display: flex;
  align-items: center;
  ${marginMixin};
  transition: ${({ transition }) => transition || '0.3s'};
  transform: rotate(
    ${({ rotateDirection }) => {
      switch (rotateDirection) {
        case Direction.Top:
          return '180deg';

        case Direction.Left:
          return '90deg';

        case Direction.Right:
          return '-90deg';

        default:
          return '0deg';
      }
    }}
  );
`;

export const AbsoluteCenterAlign = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export interface StyledStepUnderlineProps extends FlexProps {
  isActive: boolean;
  isVertical?: boolean;
}

export const StyledStepUnderline = styled(Flex)<StyledStepUnderlineProps>`
  background: ${({ isVertical, isActive }) => {
    const secondColor = isActive ? 'rgb(51, 85, 102)' : 'rgb(172, 210, 229)';
    return `linear-gradient(${
      isVertical ? 0 : -90
    }, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, ${secondColor} 75%, ${secondColor} 100%)`;
  }};
  border-radius: 4px;
  height: ${({ isVertical }) => (isVertical ? '17px' : '2px')};
  width: ${({ isVertical }) => (isVertical ? '2px' : '120px')};
`;
