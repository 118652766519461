import { FormFieldModel } from 'common/interfaces';
import { Moment } from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

export interface IDateForm {
  reactHookFormData: UseFormReturn<IFormData>;
  dateLabel: string;
  delayedIncidentDateLabel?: string;
  onValueChange?: (props: IOnValueChange) => void;
  watchFields?: FormName[];
  date?: string;
}

export interface IOnValueChange {
  formFields: FormFieldModel<FormName>[];
  setFormFields: Dispatch<SetStateAction<FormFieldModel<FormName>[]>>;
}

export enum FormName {
  StepDate = 'StepDate',
  DelayedIncidentCheckBox = 'delayIncidentCheckBox',
  DelayedIncidentDate = 'delayedIncidentDate',
  Reason = 'reason',
  StepDateJustification = 'stepDateJustification',
  DifferenceDate = 'differenceDate',
  OldDate = 'oldDate',
  ModalRadioValue = 'modalRadioValue'
}

export interface IFormData {
  [FormName.StepDate]: Moment;
  [FormName.StepDateJustification]: string;
  [FormName.DelayedIncidentDate]: Moment;
  [FormName.Reason]: string;
  [FormName.DelayedIncidentCheckBox]: boolean;
  [FormName.DifferenceDate]?: Moment;
  [FormName.OldDate]?: Moment;
  [FormName.ModalRadioValue]?: string;
}

export interface IModalDifferenceDateData {
  radioButtonValue?: string;
  [FormName.DifferenceDate]?: Moment;
}
