import { ChartData } from 'chart.js';
import { DoughnutWarningDiagramProps } from '../doughnut-warning.props';
import { useTheme } from 'styled-components';

export const useDoughnutWarningData = ({
  valuePercent,
  valueColor,
  warningPercent,
  warningColor
}: DoughnutWarningDiagramProps) => {
  const theme = useTheme();

  const backgroundColor = [theme.colors[warningColor], theme.colors[valueColor], theme.colors.grey25];

  const getWarningValue = () => {
    if (valuePercent > warningPercent) {
      return warningPercent;
    }

    return valuePercent;
  };

  const getCalmValue = () => {
    if (valuePercent > warningPercent) {
      return valuePercent - warningPercent;
    }

    return 0;
  };

  const doughnutWarningData: ChartData<'doughnut'> = {
    labels: ['', '', ''],
    datasets: [
      {
        label: '',
        data: [getWarningValue(), getCalmValue(), 100 - valuePercent],
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
        borderWidth: 0
      }
    ]
  };

  return { doughnutWarningData, calmValue: getCalmValue() };
};
