import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ width = 24, height = 25, color = '#30BF30' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.5 16.755L4.70711 12.9621C4.31658 12.5716 3.68342 12.5716 3.29289 12.9621C2.90237 13.3526 2.90237 13.9858 3.29289 14.3763L7.79289 18.8763C8.18342 19.2668 8.81658 19.2668 9.20711 18.8763L20.2071 7.8763C20.5976 7.48577 20.5976 6.85261 20.2071 6.46208C19.8166 6.07156 19.1834 6.07156 18.7929 6.46208L8.5 16.755Z'
        fill={color}
      />
    </svg>
  );
};

CheckIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string
};

export default CheckIcon;
