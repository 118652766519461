import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { getFilters, getRecords, getStatuses } from 'components/driving-license-control-component/utils';
import {
  DEFAULT_STATUS_PARAMS,
  DEFAULT_TABLE_DATA
} from 'components/driving-license-control-component/driving-license-control-component-view';
import { SessionFilter } from 'hooks/use-session-filter';
import useOverlay from 'hooks/use-overlay';
import isEmpty from 'lodash/isEmpty';

export const useDriversCheckQuery = (tableParams: TableParams, sessionFilter: SessionFilter) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const {
    data: tableDataQuery,
    isFetching: tableIsFetching,
    isFetched: tableIsFetched
  } = useQuery(
    [PAGES.DRIVERS_CHECK, tableParams, sessionFilter?.filterForServer],
    () => getRecords({ ...tableParams, search: sessionFilter?.filterForServer }),
    { enabled: !isEmpty(sessionFilter?.filterForServer) }
  );

  const {
    data: statusesDataQuery,
    isFetching: statusesIsFetching,
    isFetched: statusesIsFetched
  } = useQuery(
    [PAGES.DRIVERS_CHECK, ReactQueryKeys.Status, sessionFilter?.filterForServer],
    () => getStatuses(sessionFilter?.filterForServer),
    {
      enabled: !isEmpty(sessionFilter?.filterForServer)
    }
  );

  const { data: filtersDataQuery, isFetching: filtersIsFetching } = useQuery(
    [PAGES.DRIVERS_CHECK, ReactQueryKeys.Filter],
    getFilters
  );

  const isFetching = tableIsFetching || filtersIsFetching || statusesIsFetching;

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [hideOverlay, isFetching, showOverlay]);

  return {
    table: tableDataQuery?.data?.data || DEFAULT_TABLE_DATA,
    filters: filtersDataQuery?.data?.data || {},
    statuses: statusesDataQuery?.data?.data || DEFAULT_STATUS_PARAMS,
    statusesIsFetched,
    tableIsFetched
  };
};

interface TableParams {
  pagination: {
    pageNumber: number;
    countOnPage: number;
  };
  sorting: { fieldName?: string; reverse?: boolean };
}
