import { ChartData } from 'chart.js';

export const defaultBarData: ChartData<'bar'> = {
  labels: [''],
  datasets: [
    {
      label: 'Value 1',
      borderWidth: 1,
      barThickness: 15,
      data: []
    }
  ]
};
