import React from 'react';
import { FormFieldTypes } from 'common/enums';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useCostCenterModals } from 'components/cost-centers-settings/hooks/use-cost-center-modals';
import { PrimaryEntityValues } from 'components/cost-centers-settings/cost-centers-settings.props';
import { useGetCostCenterListQuery } from 'hooks/react-query/company/cost-center-list/use-cost-center-list.query';
import { useVehicleDetailCostCenterFieldSubmit } from 'pages/existing-vehicle-page/hooks/fileds-submit/use-cost-center';
import { useVehicleDetailIsDisableEditMode } from 'pages/existing-vehicle-page/hooks/use-is-disable-edit-mode';
import { ORGANIZATION_TOOLTIP } from '../utils';
import { useVehicleDetailsCostCenterPrimaryEntity } from 'pages/existing-vehicle-page/hooks/use-cost-center-primary-entity';

export const useCostCenterField = () => {
  const { costCenterPrimaryEntity } = useVehicleDetailsCostCenterPrimaryEntity();
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { isDisableEditMode } = useVehicleDetailIsDisableEditMode();
  const isDisableCostCenter = isDisableEditMode || PrimaryEntityValues.Vehicle !== costCenterPrimaryEntity;

  const { costCenterListSortedByNameQuery } = useGetCostCenterListQuery({
    options: { enabled: !isDisableCostCenter },
    fetch: { isShowLoading: false }
  });

  const { costCenterSubmitCallback } = useVehicleDetailCostCenterFieldSubmit();
  const { openCreateCostCenterModal } = useCostCenterModals();

  const costCenterField: FormInfoItemEditableProps = {
    value: fuhrpark?.new_kostenstelleid,
    label: 'Kostenstelle',
    isDisableEditMode: isDisableCostCenter,
    tooltip: isDisableCostCenter ? ORGANIZATION_TOOLTIP.new_kostenstelleid : undefined,
    editableComponent: (
      <VehicleDetailsEditForm
        submitCallback={costCenterSubmitCallback}
        formFields={[
          generateFormField.optionSet({
            name: 'new_kostenstelleid',
            type: FormFieldTypes.Lookup,
            defaultValue: fuhrpark?.new_kostenstelleid_id,
            options:
              costCenterListSortedByNameQuery?.map(item => ({
                label: item.new_name || '-',
                value: item.new_kostenstelleid || '-',
                description: item.new_firmaid
              })) || [],
            addNewItemLabel: 'Neue Kostenstelle anlegen',
            addNewItemCallback: openCreateCostCenterModal
          })
        ]}
      />
    )
  };
  return { costCenterField };
};
