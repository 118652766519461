import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Flex } from 'common/common-components.styled';
import { FlatListElement } from 'components/flat-list/flat-list.props';
import { TimeMeasure } from 'common/enums';
import { TimeDropDown } from './time-drop-down/time-drop-down';
import useOutsideClick from 'hooks/use-outside-click/use-outside-click';
import { TimeInput } from './time-input/time-input';
import { IDropDownData, ITimeValue } from './time.props';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { IFormFieldContext } from 'components/form-hook/form-field/form.props';
import { ITEM_SIZE, useOutsideClickOptions, NUMBER_OF_VALUES_ON_THE_SIDE, DEFAULT_DROPDOWN_DATA } from './utils';
import { useTime } from 'hooks/use-time';
import {
  DateTimeFieldContext,
  DateTimeFieldContextProps
} from 'components/form-hook/form-field/components/date-time-field/date-time-field.context';

export const TimeField = ({ borderColor, disabled = false }: { borderColor?: string; disabled?: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hourRef = useRef<FlatListElement>(null);
  const minutesRef = useRef<FlatListElement>(null);
  const isUserScrolling = useRef(false);

  const [isShowTimeDropDown, setIsShowTimeDropDown] = useState(false);
  const [timeList, setHourList] = useState<IDropDownData>(DEFAULT_DROPDOWN_DATA);

  const {
    field,
    clearErrors,
    value: valueTime,
    onChange: onChangeTime
  } = useContext<IFormFieldContext<ITimeValue>>(formFieldProvider);
  const { valueDateTime, onChangeDateTime } = useContext<DateTimeFieldContextProps>(DateTimeFieldContext);
  const value = valueDateTime || valueTime;
  const onChange = onChangeDateTime || onChangeTime;
  const { parseTime, stringifyTime, getUpperTimeLimit } = useTime();

  const setCorrectTimeList = (timeMeasure: TimeMeasure) => {
    const LENGTH_TO_LAST_ITEM = 4;
    const dropdownValueIndex = DEFAULT_DROPDOWN_DATA[timeMeasure].indexOf(parseTime(value[timeMeasure]));

    if (dropdownValueIndex <= LENGTH_TO_LAST_ITEM) {
      const cloneData = [...DEFAULT_DROPDOWN_DATA[timeMeasure]];

      return cloneData.map(item => {
        if (item < LENGTH_TO_LAST_ITEM) {
          return getUpperTimeLimit(timeMeasure) + 1 + item - LENGTH_TO_LAST_ITEM;
        }
        return item - LENGTH_TO_LAST_ITEM;
      });
    }

    return DEFAULT_DROPDOWN_DATA[timeMeasure];
  };

  const onOutsideClickHandler = useCallback(() => {
    if (isShowTimeDropDown) {
      const correctTimeList = {
        [TimeMeasure.Hour]: setCorrectTimeList(TimeMeasure.Hour),
        [TimeMeasure.Minutes]: setCorrectTimeList(TimeMeasure.Minutes)
      };

      const dropdownHourIndex = correctTimeList[TimeMeasure.Hour].indexOf(parseTime(value[TimeMeasure.Hour]));
      const dropdownMinutesIndex = correctTimeList[TimeMeasure.Minutes].indexOf(parseTime(value[TimeMeasure.Minutes]));

      isUserScrolling.current = false;

      setIsShowTimeDropDown(false);

      scrollToItemHandler(TimeMeasure.Hour, dropdownHourIndex - NUMBER_OF_VALUES_ON_THE_SIDE);
      scrollToItemHandler(TimeMeasure.Minutes, dropdownMinutesIndex - NUMBER_OF_VALUES_ON_THE_SIDE);

      setHourList(correctTimeList);
      onChange({
        [TimeMeasure.Hour]: parseTime(value[TimeMeasure.Hour]) ? stringifyTime(+value[TimeMeasure.Hour]) : '00',
        [TimeMeasure.Minutes]: parseTime(value[TimeMeasure.Minutes]) ? stringifyTime(+value[TimeMeasure.Minutes]) : '00'
      });
    }
  }, [isShowTimeDropDown, parseTime, value]);

  const scrollToItemHandler = useCallback((timeMeasure: TimeMeasure, index: number) => {
    if (hourRef?.current?.scrollToItem || minutesRef?.current?.scrollToItem) {
      if (timeMeasure === TimeMeasure.Hour) {
        hourRef?.current?.scrollTo(index * ITEM_SIZE);
      } else {
        minutesRef?.current?.scrollTo(index * ITEM_SIZE);
      }
    }
  }, []);

  const openTimeDropDown = () => {
    if (disabled) {
      return;
    }
    isUserScrolling.current = true;
    setIsShowTimeDropDown(true);
  };

  useOutsideClick(containerRef, onOutsideClickHandler, useOutsideClickOptions, isShowTimeDropDown);

  useEffect(() => {
    !valueDateTime && clearErrors(field.name);
  }, [clearErrors, field.name, value]);

  useEffect(() => {
    scrollToItemHandler(TimeMeasure.Hour, timeList[TimeMeasure.Hour].indexOf(0));
    scrollToItemHandler(TimeMeasure.Minutes, timeList[TimeMeasure.Hour].indexOf(0));
  }, []);

  useEffect(() => {
    if (isShowTimeDropDown) {
      const handleKeyDown = (event: { key: string }) => {
        if (event.key === 'Enter') {
          if (document.activeElement instanceof HTMLInputElement) {
            document.activeElement.blur();
          }
          onOutsideClickHandler();
        }
      };
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isShowTimeDropDown, parseTime, value]);

  return (
    <Flex
      direction='column'
      // width='140px'
      align='center'
      ref={containerRef}
      onClick={openTimeDropDown}
      position='relative'
    >
      <TimeInput
        borderColor={borderColor}
        scrollToItemHandler={scrollToItemHandler}
        timeList={timeList}
        disabled={disabled}
      />

      <div style={{ visibility: isShowTimeDropDown ? 'visible' : 'hidden' }}>
        <TimeDropDown
          scrollToItemHandler={scrollToItemHandler}
          minutesRef={minutesRef}
          hourRef={hourRef}
          isUserScrolling={isUserScrolling}
          timeList={timeList}
          setHourList={setHourList}
        />
      </div>
    </Flex>
  );
};
