import React from 'react';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useVehicleDetailsClearCash } from 'pages/existing-vehicle-page/hooks/use-clear-cash';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { DocumentFormSubmitButtonProps } from './document-form.props';
import { Button, Flex } from 'common/common-components.styled';
import { snackbarMessage } from 'utils/snackbar-messages';

export const DocumentFormSubmitButton = ({ reactHookFormData }: DocumentFormSubmitButtonProps) => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const { fetch } = useFetch({
    loadingKey: 'submitFile',
    isShowSuccessSnackbar: true,
    successMessage: snackbarMessage.uploadDocuments.success,
    errorMessage: snackbarMessage.uploadDocuments.error
  });
  const { clearImagesCash } = useVehicleDetailsClearCash();

  const { handleSubmit, setValue } = reactHookFormData;

  const submitHandler = handleSubmit(values => {
    fetch({
      requestConfig: fleetRequestConfig.uploadFiles({
        vehicleId: fuhrpark?.new_fuhrparkid || '',
        files: values.documents,
        documentType: values.documentType
      }),
      callback: () => {
        setValue('documents', []);
        setValue('documentType', '');
        clearImagesCash();
      }
    });
  });

  return (
    <Flex margin='0 0 0 auto'>
      <Button onClick={submitHandler}>Speichern</Button>
    </Flex>
  );
};
