import React, { useContext } from 'react';
import { EditForm } from 'components/form-hook/components/edit-form/edit-form';
import { EditFormProps } from 'components/form-hook/components/edit-form/edit-form.props';
import { useForm } from 'react-hook-form';
import { ChangeKilometerFormFieldValues } from './change-kilometer-form.props';
import { changeKilometerForm } from './form-field';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { VehicleDetailContext } from 'pages/existing-vehicle-page/existing-vehicle.context';
import { FlexProps } from 'common/common-components.styled';

export const ChangeKilometerForm = ({ onClose, ...flexProps }: Pick<EditFormProps, 'onClose'> & FlexProps) => {
  const { vehicle, setRefreshKey } = useContext(VehicleDetailContext);
  const reactHookFormData = useForm<ChangeKilometerFormFieldValues>();

  const { fetch } = useFetch({ loadingKey: 'changeKilometer' });

  const submitHandler = (values: ChangeKilometerFormFieldValues) => {
    const carId = vehicle.fuhrpark?.new_fuhrparkid?.attributeValue;

    if (carId) {
      fetch({
        requestConfig: fleetRequestConfig.setKilometers({ uds_markid: carId, uds_mileage: Number(values.uds_mileage) }),
        callback: () => {
          onClose && onClose();
          setRefreshKey(prevState => ++prevState);
        }
      });
    }
  };

  return (
    <EditForm<ChangeKilometerFormFieldValues>
      idPrefix='kilometerForm'
      containerFlex={{ top: '20', width: '100%', ...flexProps }}
      distanceBetweenFields='0'
      label='Aktueller km-Stand'
      reactHookFormData={reactHookFormData}
      formFields={changeKilometerForm}
      onSubmit={submitHandler}
      onClose={onClose}
    />
  );
};
