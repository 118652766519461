import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const StyledToggleButton = styled(Flex)`
  height: 35px;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease-out;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.grey100};
  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  }
`;
