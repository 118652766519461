import React from 'react';
import PropTypes from 'prop-types';
import classes from './editable-field-main.module.scss';
import TooltipHint from '../../../components/tooltip-hint/tooltip-hint';
import { removeSpaces } from '../../../utils/remove-spaces';
import { Flex } from '../../../common/common-components.styled';

function EditableFieldMain({ edit, cancelEdit, save, data, disableHover = false, isDisabled = false }) {
  return (
    <div className={data.expand || disableHover ? `${classes.container} ${classes.no_background}` : classes.container}>
      <div className={classes.field_title}>
        {data.expand && (
          <img
            alt='close_icon'
            src='/assets/images/close-icon-configurator.png'
            onClick={() => cancelEdit(data)}
            className={classes.cancel}
          />
        )}
      </div>
      {data.expand ? (
        <>
          <div className='mt-15' />
          {data.editingComponent}
          <button onClick={() => save(data)} style={{ width: '100%' }} className='button-mobexo button-mobexo__main'>
            Speichern
          </button>
        </>
      ) : (
        <>
          <div className={classes.value_container}>
            {data.tooltipText ? (
              <TooltipHint text={data.tooltipText} position={'bottom'}>
                <Flex minWidth='25px' minHeight='25px' justify='center' align='center'>
                  {data.icon}
                </Flex>
              </TooltipHint>
            ) : (
              <Flex minWidth='25px' minHeight='25px' justify='center' align='center'>
                {data.icon}
              </Flex>
            )}
            <p id={'EditableField' + removeSpaces(data.name) + 'Value'} className={classes.value}>
              {data.value || '-'}
            </p>
          </div>
          {!data.disabled && !isDisabled && (
            <button onClick={() => edit(data)} className={classes.edit_btn}>
              <img src='/assets/images/edit_icon.svg' alt='edit_icon' />
            </button>
          )}
        </>
      )}
    </div>
  );
}

EditableFieldMain.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    formName: PropTypes.string,
    editingComponent: PropTypes.any
  }).isRequired,
  edit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  disableHover: PropTypes.bool,
  cancelEdit: PropTypes.func.isRequired
};

export default EditableFieldMain;
