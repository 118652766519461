import React from "react";
import classnames from "classnames";

import classes from "./footnote.module.scss";

export default function Footnote(props) {
  return (
    <div id={props.id} className={classnames(classes.footnote, props.className)}>
      {props.text}
    </div>
  );
}
