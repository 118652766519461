import { DoughnutDiagramData } from 'components/widgets/doughnut/doughnut.props';
import { useDataQuality } from './use-get-quality';

export const useQualityDoughnutData = () => {
  const { filledQuality, amountOfQuality, filledQualityPercentColor } = useDataQuality();

  const filledQualityPercent = amountOfQuality ? (filledQuality / amountOfQuality) * 100 : 0;

  const doughnutData: DoughnutDiagramData = [
    { color: filledQualityPercentColor, value: filledQualityPercent, label: '' },
    { color: 'grey50', value: 100 - filledQualityPercent, label: '' }
  ];

  return { doughnutData };
};
