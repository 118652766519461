import React from 'react';
import { damage } from '../utils';

const CarDamageFrontIcon = ({ watch, chooseDamage }) => {
  return (
    <svg width='254' height='216' viewBox='0 0 254 216' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        onClick={() => chooseDamage(damage[0].color, 0)}
        d='M61.1596 133.186C56.2399 132.361 52.1685 129.142 50.3873 124.768C27.4856 68.5637 24.1775 8.31549 24.1775 8.23295L24.3472 8.23295C25.365 18.3018 32.0659 27.2978 42.0748 31.2593C66.5033 40.833 95.9363 46.2801 125.624 46.5277L126.302 46.5277C155.99 46.2801 185.423 40.833 209.851 31.2594C219.945 27.3804 226.646 18.3844 227.579 8.23297L227.749 8.23297C227.749 8.23297 224.441 68.5638 201.539 124.768C199.758 129.225 195.601 132.361 190.766 133.186C179.061 135.167 155.396 138.468 126.981 138.798L125.963 138.798L124.945 138.798C96.53 138.468 72.8649 135.167 61.1596 133.186Z'
        fill={watch('damageList')[0].color}
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[21].color, 21)}
        d='M1.93633 151.152C2.02115 149.501 2.95417 148.098 4.39612 147.19L21.6997 136.461C27.6372 141.165 34.8469 143.641 42.1415 143.641C45.1951 143.641 48.2487 143.229 51.3023 142.321L56.2219 140.918C58.2576 140.34 58.6817 137.534 57.0701 136.214C59.3603 136.379 61.5656 136.296 63.8558 135.966C71.1504 134.811 89.9652 140.916 126.875 140.916C163.786 139.971 182.775 134.811 190.07 135.966C192.275 136.296 194.396 136.379 196.601 136.214C195.074 137.534 195.498 140.258 197.449 140.918L202.369 142.321C205.422 143.229 208.476 143.641 211.53 143.641C218.909 143.641 226.119 141.083 232.056 136.379L249.529 147.19C250.971 148.098 251.904 149.501 251.989 151.152C252.753 162.046 248.681 194.316 246.815 207.686C246.306 211.07 243.338 213.628 239.775 213.628L203.132 213.628C202.284 213.628 201.521 213.463 200.757 213.216L197.619 212.06C196.686 211.73 195.668 211.565 194.65 211.565L59.4451 211.565C58.4272 211.565 57.4094 211.73 56.4764 212.06L53.338 213.216C52.5746 213.463 51.7264 213.628 50.8782 213.628L14.3202 213.628C10.7577 213.628 7.70418 211.07 7.19525 207.686C5.24436 194.316 1.17295 162.129 1.93633 151.152Z'
        fill={watch('damageList')[21].color}
      />
      <path
        onClick={() => chooseDamage(damage[21].color, 21)}
        d='M57.4068 157.588L62.3265 153.544C71.4872 146.033 82.938 141.824 94.8978 141.494L121.192 140.916L132.558 140.916L158.853 141.494C170.813 141.741 182.264 146.033 191.424 153.544L196.344 157.588C198.634 159.486 197.871 163.117 194.902 163.943C175.393 169.225 147.911 171.04 132.558 171.701C130.438 171.783 123.313 171.783 121.192 171.701C105.925 171.04 78.3577 169.225 58.8488 163.943C55.8801 163.117 55.1167 159.486 57.4068 157.588Z'
        fill={watch('damageList')[21].color}
      />
      <path
        d='M1.93633 151.152C2.02115 149.501 2.95417 148.098 4.39612 147.19L21.6997 136.461C27.6372 141.165 34.8469 143.641 42.1415 143.641C45.1951 143.641 48.2487 143.229 51.3023 142.321L56.2219 140.918C58.2576 140.34 58.6817 137.534 57.0701 136.214C59.3603 136.379 61.5656 136.296 63.8558 135.966C71.1504 134.811 89.9652 140.916 126.875 140.916C163.786 139.971 182.775 134.811 190.07 135.966C192.275 136.296 194.396 136.379 196.601 136.214C195.074 137.534 195.498 140.258 197.449 140.918L202.369 142.321C205.422 143.229 208.476 143.641 211.53 143.641C218.909 143.641 226.119 141.083 232.056 136.379L249.529 147.19C250.971 148.098 251.904 149.501 251.989 151.152C252.753 162.046 248.681 194.316 246.815 207.686C246.306 211.07 243.338 213.628 239.775 213.628L203.132 213.628C202.284 213.628 201.521 213.463 200.757 213.216L197.619 212.06C196.686 211.73 195.668 211.565 194.65 211.565L59.4451 211.565C58.4272 211.565 57.4094 211.73 56.4764 212.06L53.338 213.216C52.5746 213.463 51.7264 213.628 50.8782 213.628L14.3202 213.628C10.7577 213.628 7.70418 211.07 7.19525 207.686C5.24436 194.316 1.17295 162.129 1.93633 151.152Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M57.4068 157.588L62.3265 153.544C71.4872 146.033 82.938 141.824 94.8978 141.494L121.192 140.916L132.558 140.916L158.853 141.494C170.813 141.741 182.264 146.033 191.424 153.544L196.344 157.588C198.634 159.486 197.871 163.117 194.902 163.943C175.393 169.225 147.911 171.04 132.558 171.701C130.438 171.783 123.313 171.783 121.192 171.701C105.925 171.04 78.3577 169.225 58.8488 163.943C55.8801 163.117 55.1167 159.486 57.4068 157.588Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[2].color, 2)}
        d='M203.641 186.145C205.167 187.465 207.033 188.373 209.069 188.538L229.85 190.849C234.431 191.344 238.163 187.218 236.975 182.844L235.449 177.396C234.007 172.114 228.324 169.143 222.98 170.794L204.15 176.819C203.301 177.066 202.623 177.644 202.199 178.469C200.841 181.11 201.435 184.164 203.641 186.145Z'
        fill={watch('damageList')[2].color}
      />
      <path
        onClick={() => chooseDamage(damage[2].color, 2)}
        d='M226.289 174.504C222.354 174.504 219.164 177.608 219.164 181.437C219.164 185.266 222.354 188.37 226.289 188.37C230.224 188.37 233.414 185.266 233.414 181.437C233.414 177.608 230.224 174.504 226.289 174.504Z'
        fill={watch('damageList')[2].color}
      />
      <path
        onClick={() => chooseDamage(damage[2].color, 2)}
        d='M196.6 136.213C196.685 136.13 196.77 136.048 196.94 135.965L206.524 130.023C213.395 125.814 221.029 123.42 228.917 123.09L235.703 122.76C239.011 122.595 240.877 126.722 238.841 129.528C236.89 132.169 234.6 134.48 232.14 136.378C226.118 141.082 218.908 143.641 211.614 143.641C208.56 143.641 205.506 143.228 202.453 142.32L197.533 140.917C195.498 140.257 195.073 137.533 196.6 136.213Z'
        fill={watch('damageList')[2].color}
      />
      <path
        d='M203.641 186.145C205.167 187.465 207.033 188.373 209.069 188.538L229.85 190.849C234.431 191.344 238.163 187.218 236.975 182.844L235.449 177.396C234.007 172.114 228.324 169.143 222.98 170.794L204.15 176.819C203.301 177.066 202.623 177.644 202.199 178.469C200.841 181.11 201.435 184.164 203.641 186.145Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M226.289 174.504C222.354 174.504 219.164 177.608 219.164 181.437C219.164 185.266 222.354 188.37 226.289 188.37C230.224 188.37 233.414 185.266 233.414 181.437C233.414 177.608 230.224 174.504 226.289 174.504Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M196.6 136.213C196.685 136.13 196.77 136.048 196.94 135.965L206.524 130.023C213.395 125.814 221.029 123.42 228.917 123.09L235.703 122.76C239.011 122.595 240.877 126.722 238.841 129.528C236.89 132.169 234.6 134.48 232.14 136.378C226.118 141.082 218.908 143.641 211.614 143.641C208.56 143.641 205.506 143.228 202.453 142.32L197.533 140.917C195.498 140.257 195.073 137.533 196.6 136.213Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        onClick={() => chooseDamage(damage[1].color, 1)}
        d='M50.0291 186.145C48.5023 187.465 46.6362 188.373 44.6005 188.538L23.8194 190.849C19.239 191.344 15.5069 187.218 16.6944 182.844L18.2212 177.396C19.6632 172.114 25.3461 169.143 30.6898 170.794L49.5201 176.819C50.3683 177.066 51.0469 177.644 51.471 178.469C52.7433 181.11 52.2344 184.164 50.0291 186.145Z'
        fill={watch('damageList')[1].color}
      />
      <path
        onClick={() => chooseDamage(damage[1].color, 1)}
        d='M27.3808 174.504C23.4458 174.504 20.2558 177.608 20.2558 181.437C20.2558 185.266 23.4458 188.37 27.3808 188.37C31.3159 188.37 34.5058 185.266 34.5058 181.437C34.5058 177.608 31.3159 174.504 27.3808 174.504Z'
        fill={watch('damageList')[1].color}
      />
      <path
        onClick={() => chooseDamage(damage[1].color, 1)}
        d='M18.051 122.843L24.8367 123.173C32.7251 123.503 40.359 125.896 47.2295 130.105L56.8143 136.048C56.8991 136.13 56.9839 136.213 57.0687 136.213C58.6803 137.533 58.2562 140.257 56.2205 140.917L51.3009 142.32C48.2474 143.228 45.1938 143.641 42.1402 143.641C34.7608 143.641 27.6358 141.165 21.6983 136.46C19.2385 134.562 16.9483 132.169 14.9126 129.528C12.9618 126.804 14.8278 122.678 18.051 122.843Z'
        fill={watch('damageList')[1].color}
      />
      <path
        d='M50.0291 186.145C48.5023 187.465 46.6362 188.373 44.6005 188.538L23.8194 190.849C19.239 191.344 15.5069 187.218 16.6944 182.844L18.2212 177.396C19.6632 172.114 25.3461 169.143 30.6898 170.794L49.5201 176.819C50.3683 177.066 51.0469 177.644 51.471 178.469C52.7433 181.11 52.2344 184.164 50.0291 186.145Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M27.3808 174.504C23.4458 174.504 20.2558 177.608 20.2558 181.437C20.2558 185.266 23.4458 188.37 27.3808 188.37C31.3159 188.37 34.5058 185.266 34.5058 181.437C34.5058 177.608 31.3159 174.504 27.3808 174.504Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <path
        d='M18.051 122.843L24.8367 123.173C32.7251 123.503 40.359 125.896 47.2295 130.105L56.8143 136.048C56.8991 136.13 56.9839 136.213 57.0687 136.213C58.6803 137.533 58.2562 140.257 56.2205 140.917L51.3009 142.32C48.2474 143.228 45.1938 143.641 42.1402 143.641C34.7608 143.641 27.6358 141.165 21.6983 136.46C19.2385 134.562 16.9483 132.169 14.9126 129.528C12.9618 126.804 14.8278 122.678 18.051 122.843Z'
        stroke='white'
        strokeMiterlimit='10'
      />
      <g clipPath='url(#clip0_3342_59416)' onClick={() => chooseDamage(damage[20].color, 20)}>
        <rect
          x='174.859'
          y='203.063'
          width='94.1895'
          height='21.9092'
          rx='1.5'
          transform='rotate(-180 174.859 203.063)'
          fill={watch('damageList')[20].color}
          stroke='white'
        />
        <path
          d='M89.981 196.656L89.981 188.303L93.1173 188.303C93.7422 188.303 94.2576 188.404 94.6635 188.605C95.0694 188.803 95.3717 189.071 95.5702 189.408C95.7688 189.743 95.8681 190.114 95.8681 190.522C95.8681 190.881 95.7995 191.177 95.6622 191.411C95.5279 191.645 95.3498 191.83 95.1278 191.966C94.9088 192.102 94.6708 192.202 94.4138 192.267L94.4138 192.349C94.6883 192.365 94.9643 192.455 95.2417 192.618C95.5191 192.781 95.7513 193.015 95.9382 193.32C96.1251 193.624 96.2185 193.997 96.2185 194.437C96.2185 194.856 96.1163 195.232 95.9119 195.567C95.7075 195.901 95.3848 196.166 94.9438 196.362C94.5029 196.558 93.9291 196.656 93.2224 196.656L89.981 196.656ZM91.0673 195.759L93.2224 195.759C93.932 195.759 94.4357 195.631 94.7336 195.375C95.0344 195.117 95.1848 194.804 95.1848 194.437C95.1848 194.154 95.1074 193.893 94.9526 193.654C94.7978 193.412 94.5774 193.219 94.2912 193.075C94.005 192.928 93.6663 192.855 93.275 192.855L91.0673 192.855L91.0673 195.759ZM91.0673 191.974L93.0822 191.974C93.4093 191.974 93.7042 191.914 93.967 191.794C94.2328 191.675 94.443 191.506 94.5978 191.289C94.7555 191.071 94.8343 190.815 94.8343 190.522C94.8343 190.155 94.6971 189.843 94.4226 189.588C94.1481 189.33 93.713 189.2 93.1173 189.2L91.0673 189.2L91.0673 191.974Z'
          fill='#E0E3E5'
        />
        <path
          d='M105.573 192.479C105.573 193.36 105.402 194.122 105.06 194.763C104.719 195.405 104.25 195.9 103.654 196.248C103.058 196.596 102.378 196.77 101.613 196.77C100.848 196.77 100.167 196.596 99.5716 196.248C98.9759 195.9 98.5072 195.405 98.1656 194.763C97.8239 194.122 97.6531 193.36 97.6531 192.479C97.6531 191.599 97.8239 190.837 98.1656 190.196C98.5072 189.554 98.9759 189.059 99.5716 188.711C100.167 188.363 100.848 188.189 101.613 188.189C102.378 188.189 103.058 188.363 103.654 188.711C104.25 189.059 104.719 189.554 105.06 190.196C105.402 190.837 105.573 191.599 105.573 192.479ZM104.521 192.479C104.521 191.756 104.391 191.146 104.132 190.648C103.875 190.151 103.526 189.774 103.085 189.519C102.647 189.263 102.156 189.135 101.613 189.135C101.07 189.135 100.578 189.263 100.137 189.519C99.6987 189.774 99.3497 190.151 99.0898 190.648C98.8328 191.146 98.7043 191.756 98.7043 192.479C98.7043 193.203 98.8328 193.813 99.0898 194.311C99.3497 194.808 99.6987 195.185 100.137 195.44C100.578 195.696 101.07 195.824 101.613 195.824C102.156 195.824 102.647 195.696 103.085 195.44C103.526 195.185 103.875 194.808 104.132 194.311C104.391 193.813 104.521 193.203 104.521 192.479Z'
          fill='#E0E3E5'
        />
        <path d='M111.114 192.031L111.114 192.928L107.189 192.928L107.189 192.031L111.114 192.031Z' fill='#E0E3E5' />
        <path
          d='M113.073 188.303L114.369 188.303L117.418 195.237L117.523 195.237L120.572 188.303L121.869 188.303L121.869 196.656L120.852 196.656L120.852 190.31L120.765 190.31L117.961 196.656L116.98 196.656L114.177 190.31L114.089 190.31L114.089 196.656L113.073 196.656L113.073 188.303Z'
          fill='#E0E3E5'
        />
        <path
          d='M131.616 192.479C131.616 193.36 131.445 194.122 131.103 194.763C130.762 195.405 130.293 195.9 129.697 196.248C129.102 196.596 128.421 196.77 127.656 196.77C126.891 196.77 126.211 196.596 125.615 196.248C125.019 195.9 124.55 195.405 124.209 194.763C123.867 194.122 123.696 193.36 123.696 192.479C123.696 191.599 123.867 190.837 124.209 190.196C124.55 189.554 125.019 189.059 125.615 188.711C126.211 188.363 126.891 188.189 127.656 188.189C128.421 188.189 129.102 188.363 129.697 188.711C130.293 189.059 130.762 189.554 131.103 190.196C131.445 190.837 131.616 191.599 131.616 192.479ZM130.565 192.479C130.565 191.756 130.435 191.146 130.175 190.648C129.918 190.151 129.569 189.774 129.128 189.519C128.69 189.263 128.199 189.135 127.656 189.135C127.113 189.135 126.621 189.263 126.18 189.519C125.742 189.774 125.393 190.151 125.133 190.648C124.876 191.146 124.747 191.756 124.747 192.479C124.747 193.203 124.876 193.813 125.133 194.311C125.393 194.808 125.742 195.185 126.18 195.44C126.621 195.696 127.113 195.824 127.656 195.824C128.199 195.824 128.69 195.696 129.128 195.44C129.569 195.185 129.918 194.808 130.175 194.311C130.435 193.813 130.565 193.203 130.565 192.479Z'
          fill='#E0E3E5'
        />
        <path
          d='M139.908 188.303L139.908 196.656L138.821 196.656L138.821 189.364L138.769 189.364L136.579 190.718L136.579 189.69L138.821 188.303L139.908 188.303Z'
          fill='#E0E3E5'
        />
        <path
          d='M142.488 196.656L142.488 195.922L145.449 192.904C145.796 192.55 146.083 192.243 146.307 191.982C146.532 191.718 146.699 191.471 146.807 191.24C146.918 191.006 146.973 190.761 146.973 190.506C146.973 190.212 146.897 189.958 146.745 189.743C146.596 189.528 146.392 189.362 146.132 189.245C145.872 189.128 145.58 189.07 145.256 189.07C144.912 189.07 144.611 189.137 144.354 189.27C144.1 189.4 143.903 189.584 143.762 189.82C143.625 190.057 143.557 190.334 143.557 190.652L142.523 190.652C142.523 190.163 142.644 189.733 142.886 189.364C143.129 188.994 143.459 188.706 143.876 188.499C144.297 188.292 144.768 188.189 145.291 188.189C145.817 188.189 146.283 188.292 146.688 188.499C147.094 188.706 147.413 188.984 147.643 189.335C147.874 189.686 147.989 190.076 147.989 190.506C147.989 190.813 147.93 191.113 147.81 191.407C147.693 191.698 147.489 192.023 147.197 192.382C146.907 192.738 146.506 193.173 145.992 193.687L143.977 195.693L143.977 195.759L148.147 195.759L148.147 196.656L142.488 196.656Z'
          fill='#E0E3E5'
        />
        <path
          d='M152.987 196.77C152.409 196.77 151.894 196.678 151.441 196.493C150.991 196.308 150.634 196.051 150.368 195.722C150.105 195.39 149.962 195.005 149.939 194.568L151.042 194.568C151.066 194.837 151.165 195.069 151.34 195.265C151.516 195.458 151.745 195.608 152.028 195.714C152.311 195.82 152.625 195.873 152.97 195.873C153.355 195.873 153.697 195.81 153.995 195.685C154.293 195.56 154.526 195.386 154.696 195.163C154.865 194.94 154.95 194.682 154.95 194.388C154.95 194.081 154.868 193.81 154.704 193.577C154.541 193.34 154.301 193.155 153.986 193.022C153.671 192.889 153.285 192.822 152.83 192.822L152.111 192.822L152.111 191.925L152.83 191.925C153.186 191.925 153.498 191.865 153.767 191.745C154.039 191.626 154.25 191.457 154.402 191.24C154.557 191.022 154.634 190.767 154.634 190.473C154.634 190.19 154.567 189.944 154.433 189.735C154.299 189.525 154.109 189.362 153.863 189.245C153.621 189.128 153.335 189.07 153.005 189.07C152.695 189.07 152.403 189.123 152.129 189.229C151.857 189.332 151.635 189.483 151.463 189.682C151.291 189.877 151.197 190.114 151.183 190.391L150.131 190.391C150.149 189.954 150.291 189.57 150.556 189.241C150.822 188.909 151.17 188.651 151.599 188.466C152.031 188.281 152.506 188.189 153.022 188.189C153.577 188.189 154.053 188.294 154.45 188.503C154.848 188.71 155.153 188.983 155.366 189.323C155.579 189.663 155.686 190.03 155.686 190.424C155.686 190.894 155.553 191.295 155.287 191.627C155.024 191.959 154.666 192.189 154.214 192.316L154.214 192.382C154.78 192.469 155.223 192.693 155.541 193.055C155.859 193.413 156.019 193.858 156.019 194.388C156.019 194.842 155.886 195.25 155.62 195.612C155.357 195.971 154.998 196.253 154.542 196.46C154.087 196.667 153.568 196.77 152.987 196.77Z'
          fill='#E0E3E5'
        />
        <path
          d='M157.6 194.943L157.6 194.111L161.542 188.303L162.19 188.303L162.19 189.592L161.752 189.592L158.774 193.98L158.774 194.046L164.083 194.046L164.083 194.943L157.6 194.943ZM161.822 196.656L161.822 194.69L161.822 194.303L161.822 188.303L162.856 188.303L162.856 196.656L161.822 196.656Z'
          fill='#E0E3E5'
        />
      </g>
      <defs>
        <clipPath id='clip0_3342_59416'>
          <rect width='95.1896' height='22.6759' fill='white' transform='translate(175.359 203.33) rotate(-180)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarDamageFrontIcon;
