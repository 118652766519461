import React from 'react';
import styled from 'styled-components';
import { IconProps } from 'common/interfaces';

export const DropDownIcon = (props: IconProps) => (
  <svg width='21' {...props} height='21' viewBox='0 -5 12 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 4.48155L6.03333 7.5882L3 4.41173'
      stroke='#335566'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const StyledDropDownIcon = styled(DropDownIcon)`
  position: absolute;
  right: 7px;
  top: calc(50% - 10px) !important;
`;
