import { IconProps } from 'common/interfaces';
import React from 'react';

const UnauthorisedLogoMobexo = ({ width = '167', height = '36' }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 167 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_4381_483)'>
        <path
          d='M85.2053 29.1127L85.087 29.8934H82.8692L82.4966 32.1822L83.4488 32.3773L83.3542 32.9628H80.5686L80.6632 32.3773L81.5208 32.1822L82.3665 26.9245L81.5563 26.7293L81.645 26.1911L83.4547 26.1438H86.3881L86.1279 27.7998H85.5838L85.4892 26.9245H83.3364L82.9757 29.1127H85.2053Z'
          fill='#667D85'
        />
        <path
          d='M86.394 32.9628L87.4645 26.2443L86.6424 26.0491L86.7311 25.5109L88.535 25.4636L87.4645 32.1821L88.2925 32.33L88.2038 32.8682L86.394 32.9628Z'
          fill='#667D85'
        />
        <path
          d='M89.9958 30.8514C89.978 30.9992 89.9662 31.1411 89.9662 31.2713C89.9662 31.9632 90.3092 32.2826 91.0367 32.2826C91.7464 32.2826 92.6335 31.8449 92.6335 31.8449L92.9233 32.3417C92.9233 32.3417 91.8883 33.0633 90.741 33.0633C89.6705 33.0633 88.9904 32.3831 88.9904 31.3127C88.9904 29.2959 90.256 27.7109 91.8055 27.7109C92.7873 27.7109 93.4142 28.2077 93.4142 28.9766C93.4142 30.0884 92.0066 30.8514 89.9958 30.8514ZM92.4856 29.0653C92.4856 28.6395 92.1958 28.4206 91.8114 28.4206C91.1963 28.4206 90.3329 28.9943 90.0786 30.1653C91.4625 30.1653 92.4856 29.7099 92.4856 29.0653Z'
          fill='#667D85'
        />
        <path
          d='M94.8217 30.8514C94.8039 30.9992 94.7921 31.1411 94.7921 31.2713C94.7921 31.9632 95.1351 32.2826 95.8626 32.2826C96.5723 32.2826 97.4594 31.8449 97.4594 31.8449L97.7492 32.3417C97.7492 32.3417 96.7142 33.0633 95.5669 33.0633C94.4964 33.0633 93.8163 32.3831 93.8163 31.3127C93.8163 29.2959 95.0819 27.7109 96.6314 27.7109C97.6132 27.7109 98.2401 28.2077 98.2401 28.9766C98.2401 30.0884 96.8384 30.8514 94.8217 30.8514ZM97.3175 29.0653C97.3175 28.6395 97.0277 28.4206 96.6432 28.4206C96.0282 28.4206 95.1647 28.9943 94.9104 30.1653C96.2943 30.1653 97.3175 29.7099 97.3175 29.0653Z'
          fill='#667D85'
        />
        <path
          d='M100.245 33.0575C99.5826 33.0575 99.2632 32.6671 99.2632 31.8983C99.2632 31.7149 99.281 31.4961 99.3224 31.2773L99.7482 28.5804H98.7842L98.8906 27.9476L99.8665 27.853L100.062 26.6288L101.026 26.4336L100.789 27.853H102.132L102.013 28.5804H100.671L100.251 31.1827C100.209 31.437 100.192 31.6381 100.192 31.7977C100.192 32.1467 100.298 32.2827 100.57 32.2827C100.842 32.2827 101.357 32.0875 101.357 32.0875L101.511 32.6139C101.522 32.6139 100.795 33.0575 100.245 33.0575Z'
          fill='#667D85'
        />
        <path
          d='M112.127 32.3774L112.032 32.9629H109.471L109.566 32.3774L110.382 32.1822L111.121 27.5455L108.844 31.2064L108.046 31.2537L106.946 27.5041L106.195 32.1763L107.052 32.3715L106.958 32.957H104.338L104.432 32.3715L105.29 32.1763L106.136 26.9186L105.325 26.7234L105.414 26.1853L107.525 26.1379L108.685 30.0413L111.062 26.1853L113.097 26.1379L113.002 26.7234L112.174 26.9186L111.346 32.1763L112.127 32.3774Z'
          fill='#667D85'
        />
        <path
          d='M118.005 32.8623L116.196 32.9569L116.586 31.1649C116.527 31.2832 115.681 33.0515 114.404 33.0515C113.676 33.0515 113.126 32.3241 113.126 31.3483C113.126 28.9648 114.569 27.6992 116.03 27.6992C116.663 27.6992 117.118 27.989 117.118 27.989L117.408 27.7938H117.946L117.242 32.1762L118.088 32.3241L118.005 32.8623ZM116.03 28.4326C115.107 28.4326 114.102 29.2606 114.102 31.2063C114.102 31.8864 114.356 32.2295 114.693 32.2295C115.864 32.2295 116.71 29.5977 116.71 29.5977L116.905 28.5745C116.905 28.5804 116.539 28.4326 116.03 28.4326Z'
          fill='#667D85'
        />
        <path
          d='M123.02 29.1719C123.02 28.8111 122.902 28.5273 122.512 28.5273C121.246 28.5273 120.43 31.2064 120.43 31.2064L120.146 32.957H119.224L119.927 28.5746L119.111 28.3794L119.2 27.8412L120.98 27.7939L120.542 29.6805C120.59 29.5741 121.43 27.6934 122.908 27.6934C123.695 27.6934 123.99 28.1783 123.99 28.8939C123.99 29.0714 123.973 29.2547 123.943 29.4499L123.494 32.1763L124.322 32.3241L124.233 32.8623L122.441 32.957L122.985 29.5977C123.003 29.4558 123.02 29.3079 123.02 29.1719Z'
          fill='#667D85'
        />
        <path
          d='M129.958 32.8623L128.148 32.9569L128.538 31.1649C128.479 31.2832 127.634 33.0515 126.356 33.0515C125.629 33.0515 125.079 32.3241 125.079 31.3483C125.079 28.9648 126.522 27.6992 127.982 27.6992C128.615 27.6992 129.071 27.989 129.071 27.989L129.36 27.7938H129.899L129.195 32.1762L130.041 32.3241L129.958 32.8623ZM127.977 28.4326C127.054 28.4326 126.049 29.2606 126.049 31.2063C126.049 31.8864 126.303 32.2295 126.64 32.2295C127.811 32.2295 128.657 29.5977 128.657 29.5977L128.852 28.5745C128.852 28.5804 128.485 28.4326 127.977 28.4326Z'
          fill='#667D85'
        />
        <path
          d='M132.542 35.3462C131.697 35.3462 130.75 34.9441 130.75 34.9441L130.987 34.2403C130.987 34.2403 131.833 34.5419 132.448 34.5419C133.341 34.5419 133.755 34.0333 133.92 33.2467L134.358 31.1649C134.299 31.2832 133.453 33.0515 132.158 33.0515C131.436 33.0515 130.904 32.3418 130.904 31.3483C130.904 28.9648 132.335 27.6992 133.784 27.6992C134.447 27.6992 134.884 27.989 134.884 27.989L135.174 27.7938H135.712L134.843 33.1876C134.618 34.5774 133.808 35.3462 132.542 35.3462ZM133.784 28.4326C132.862 28.4326 131.874 29.2724 131.874 31.1886C131.874 31.8983 132.128 32.2295 132.465 32.2295C133.642 32.2295 134.47 29.5977 134.47 29.5977L134.654 28.5745C134.66 28.5804 134.305 28.4326 133.784 28.4326Z'
          fill='#667D85'
        />
        <path
          d='M137.279 30.8514C137.262 30.9992 137.25 31.1411 137.25 31.2713C137.25 31.9632 137.593 32.2826 138.32 32.2826C139.03 32.2826 139.917 31.8449 139.917 31.8449L140.207 32.3417C140.207 32.3417 139.172 33.0633 138.025 33.0633C136.954 33.0633 136.274 32.3831 136.274 31.3127C136.274 29.2959 137.54 27.7109 139.089 27.7109C140.071 27.7109 140.698 28.2077 140.698 28.9766C140.698 30.0884 139.29 30.8514 137.279 30.8514ZM139.769 29.0653C139.769 28.6395 139.48 28.4206 139.095 28.4206C138.48 28.4206 137.617 28.9943 137.362 30.1653C138.746 30.1653 139.769 29.7099 139.769 29.0653Z'
          fill='#667D85'
        />
        <path
          d='M144.542 32.9628L145.086 29.6036C145.104 29.4557 145.128 29.3138 145.128 29.1778C145.128 28.817 145.009 28.5331 144.619 28.5331C143.395 28.5331 142.644 31.2122 142.644 31.2122L142.36 32.9628H141.437L142.141 28.5804L141.325 28.3853L141.413 27.8471L143.188 27.7998L142.768 29.6864C142.809 29.5799 143.537 27.6992 145.015 27.6992C145.784 27.6992 146.056 28.1546 146.056 28.888C146.056 29.0713 146.038 29.2783 146.009 29.4912C146.263 28.8998 146.902 27.6992 148.132 27.6992C148.913 27.6992 149.202 28.1842 149.202 28.8998C149.202 29.0772 149.185 29.2606 149.155 29.4557L148.706 32.1822L149.534 32.33L149.445 32.8682L147.653 32.9628L148.209 29.6036C148.227 29.4557 148.25 29.3138 148.25 29.1837C148.25 28.8111 148.132 28.5331 147.742 28.5331C146.494 28.5331 145.737 31.2773 145.737 31.2773L145.476 32.9628H144.542Z'
          fill='#667D85'
        />
        <path
          d='M151.278 30.8514C151.26 30.9992 151.249 31.1411 151.249 31.2713C151.249 31.9632 151.592 32.2826 152.319 32.2826C153.029 32.2826 153.916 31.8449 153.916 31.8449L154.206 32.3417C154.206 32.3417 153.171 33.0633 152.023 33.0633C150.953 33.0633 150.273 32.3831 150.273 31.3127C150.273 29.2959 151.538 27.7109 153.088 27.7109C154.07 27.7109 154.697 28.2077 154.697 28.9766C154.697 30.0884 153.295 30.8514 151.278 30.8514ZM153.774 29.0653C153.774 28.6395 153.484 28.4206 153.1 28.4206C152.485 28.4206 151.621 28.9943 151.367 30.1653C152.751 30.1653 153.774 29.7099 153.774 29.0653Z'
          fill='#667D85'
        />
        <path
          d='M159.233 29.1719C159.233 28.8111 159.115 28.5273 158.724 28.5273C157.459 28.5273 156.642 31.2064 156.642 31.2064L156.359 32.957H155.436L156.14 28.5746L155.324 28.3794L155.412 27.8412L157.192 27.7939L156.755 29.6805C156.802 29.5741 157.642 27.6934 159.12 27.6934C159.907 27.6934 160.203 28.1783 160.203 28.8939C160.203 29.0714 160.185 29.2547 160.155 29.4499L159.706 32.1763L160.534 32.3241L160.445 32.8623L158.653 32.957L159.197 29.5977C159.215 29.4558 159.233 29.3079 159.233 29.1719Z'
          fill='#667D85'
        />
        <path
          d='M162.876 33.0575C162.214 33.0575 161.894 32.6671 161.894 31.8983C161.894 31.7149 161.912 31.4961 161.953 31.2773L162.379 28.5804H161.415L161.522 27.9476L162.497 27.853L162.693 26.6288L163.657 26.4336L163.42 27.853H164.763L164.644 28.5804H163.302L162.882 31.1827C162.84 31.437 162.823 31.6381 162.823 31.7977C162.823 32.1467 162.929 32.2827 163.201 32.2827C163.473 32.2827 163.988 32.0875 163.988 32.0875L164.142 32.6139C164.153 32.6139 163.42 33.0575 162.876 33.0575Z'
          fill='#667D85'
        />
        <path
          d='M166.235 16.5983C166.235 18.7451 164.491 20.4898 162.344 20.4898C160.197 20.4898 158.452 18.7451 158.452 16.5983C158.452 14.4515 160.197 12.7068 162.344 12.7068C164.496 12.7068 166.235 14.4456 166.235 16.5983Z'
          fill='#E80032'
        />
        <path
          d='M21.3501 20.0758V8.61413L21.1254 8.5609L15.1639 20.0758H10.888L4.95607 8.61413L4.73725 8.66735V20.0758H0.118286V1.32788H5.5948L13.0407 16.0601L20.4867 1.32788H25.9632V20.0758H21.3501Z'
          fill='white'
        />
        <path
          d='M43.0492 20.5372C39.2819 20.5372 36.2598 19.6087 34.0715 17.7694C31.8892 15.9419 30.7773 13.5644 30.7773 10.7019C30.7773 7.85724 31.8774 5.48566 34.042 3.64635C36.2125 1.80114 39.2464 0.866699 43.0492 0.866699C46.852 0.866699 49.8919 1.80114 52.0861 3.64635C54.2684 5.48566 55.3802 7.85724 55.3802 10.7019C55.3802 13.5644 54.2802 15.9419 52.1156 17.7694C49.9392 19.6028 46.8934 20.5372 43.0492 20.5372ZM43.0492 4.67542C40.6481 4.67542 38.7496 5.20178 37.4189 6.24858C36.0764 7.29539 35.3963 8.79759 35.3963 10.7079C35.3963 12.6181 36.0883 14.1203 37.4485 15.1671C38.8029 16.208 40.6836 16.7344 43.0492 16.7344C45.4859 16.7344 47.402 16.208 48.7327 15.179C50.0752 14.1381 50.7554 12.6359 50.7554 10.7079C50.7554 8.79759 50.0693 7.29539 48.715 6.24858C47.3784 5.20178 45.4681 4.67542 43.0492 4.67542Z'
          fill='white'
        />
        <path
          d='M60.1943 20.0758V1.32788H71.4667C73.655 1.32788 75.4174 1.78327 76.7008 2.68814C77.9723 3.58118 78.6169 4.79949 78.6169 6.3076C78.6169 7.1829 78.3981 7.95765 77.9723 8.6023C77.5465 9.24694 76.9373 9.7319 76.1685 10.0513L75.8728 10.1696L76.1744 10.2701C77.2153 10.6249 78.0374 11.1986 78.6288 11.9793C79.2143 12.76 79.5159 13.6885 79.5159 14.7412C79.5159 16.338 78.8417 17.6391 77.5051 18.6031C76.1567 19.579 74.3233 20.0699 72.0404 20.0699H60.1943V20.0758ZM64.8074 16.4031H71.1355C73.3711 16.4031 74.5066 15.717 74.5066 14.3627C74.5066 13.0083 73.3711 12.3164 71.1355 12.3164H64.8074V16.4031ZM64.8074 8.67918H71.1355C73.034 8.67918 73.9921 8.0582 73.9921 6.82805C73.9921 6.22481 73.7141 5.75759 73.1759 5.43822C72.6555 5.13069 71.928 4.97692 71.0291 4.97692H64.8074V8.67918Z'
          fill='white'
        />
        <path
          d='M84.9274 20.0758V1.32788H101.256V5.0538H89.5463V8.59638H100.281V12.3164H89.5463V16.3262H101.256V20.0758H84.9274Z'
          fill='white'
        />
        <path
          d='M122.577 20.0758L117.018 13.4164L111.435 20.0758H105.674L113.812 10.6486L106.183 1.32788H111.866L117.018 7.88077L122.175 1.32788H127.888L120.282 10.6486L128.367 20.0758H122.577Z'
          fill='white'
        />
        <path
          d='M142.685 20.5372C138.918 20.5372 135.896 19.6087 133.707 17.7694C131.525 15.9419 130.413 13.5644 130.413 10.7019C130.413 7.85724 131.513 5.48566 133.678 3.64635C135.848 1.80114 138.882 0.866699 142.685 0.866699C146.488 0.866699 149.528 1.80114 151.722 3.64635C153.904 5.48566 155.016 7.85724 155.016 10.7019C155.016 13.5644 153.916 15.9419 151.751 17.7694C149.569 19.6028 146.523 20.5372 142.685 20.5372ZM142.685 4.67542C140.284 4.67542 138.385 5.20178 137.055 6.24858C135.712 7.29539 135.032 8.79759 135.032 10.7079C135.032 12.6181 135.724 14.1203 137.084 15.1671C138.439 16.208 140.319 16.7344 142.685 16.7344C145.122 16.7344 147.038 16.208 148.368 15.179C149.711 14.1381 150.391 12.6359 150.391 10.7079C150.391 8.79759 149.705 7.29539 148.351 6.24858C147.008 5.20178 145.104 4.67542 142.685 4.67542Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_4381_483'>
          <rect width='166.49' height='35' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnauthorisedLogoMobexo;
