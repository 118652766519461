import React from 'react';
import PropTypes from 'prop-types';

export const QuestionIcon = ({ width = '16', height = '25', color = '#FF9700', ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.00041 19.3131C7.56041 19.3131 7.19041 19.1731 6.89041 18.8931C6.61041 18.5931 6.47041 18.2231 6.47041 17.7831C6.47041 17.0031 6.67041 16.1531 7.07041 15.2331C7.47041 14.2931 8.09041 13.4631 8.93041 12.7431C9.49041 12.2631 10.0004 11.7931 10.4604 11.3331C10.9404 10.8531 11.3204 10.3131 11.6004 9.71313C11.9004 9.09313 12.0504 8.34313 12.0504 7.46313C12.0504 6.82312 11.9004 6.22312 11.6004 5.66313C11.3004 5.10313 10.8704 4.65313 10.3104 4.31312C9.77041 3.95313 9.10041 3.77313 8.30041 3.77313C6.84041 3.77313 5.75041 4.17313 5.03041 4.97312C4.33041 5.75313 3.98041 6.78313 3.98041 8.06313C3.98041 8.50313 3.84041 8.87313 3.56041 9.17313C3.28041 9.45313 2.91041 9.59313 2.45041 9.59313C2.01041 9.59313 1.64041 9.45313 1.34041 9.17313C1.06041 8.87313 0.92041 8.50313 0.92041 8.06313C0.92041 6.66313 1.22041 5.43313 1.82041 4.37313C2.44041 3.31312 3.30041 2.48312 4.40041 1.88313C5.52041 1.26313 6.82041 0.953125 8.30041 0.953125C9.60041 0.953125 10.7504 1.23312 11.7504 1.79312C12.7704 2.33313 13.5704 3.09313 14.1504 4.07313C14.7504 5.03313 15.0504 6.16313 15.0504 7.46313C15.0504 8.58313 14.8704 9.55313 14.5104 10.3731C14.1504 11.1931 13.6604 11.9331 13.0404 12.5931C12.4204 13.2331 11.7104 13.8731 10.9104 14.5131C10.3904 14.9331 10.0304 15.4831 9.83041 16.1631C9.63041 16.8231 9.53041 17.3631 9.53041 17.7831C9.53041 18.2231 9.39041 18.5931 9.11041 18.8931C8.83041 19.1731 8.46041 19.3131 8.00041 19.3131ZM8.00041 24.8631C7.44041 24.8631 6.95041 24.6631 6.53041 24.2631C6.13041 23.8431 5.93041 23.3531 5.93041 22.7931C5.93041 22.2131 6.13041 21.7231 6.53041 21.3231C6.95041 20.9031 7.44041 20.6931 8.00041 20.6931C8.58041 20.6931 9.07041 20.9031 9.47041 21.3231C9.89041 21.7231 10.1004 22.2131 10.1004 22.7931C10.1004 23.3531 9.89041 23.8431 9.47041 24.2631C9.07041 24.6631 8.58041 24.8631 8.00041 24.8631Z'
        fill={color}
      />
    </svg>
  );
};

QuestionIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
