import React, { useRef } from 'react';
import { Divider, Flex } from 'common/common-components.styled';
import { CalendarWidgetItem } from './components/calendar-item/calendar-item';
import { CalendarWidgetProps } from './calendar.props';
import { CalendarItemProps } from './components/calendar-item/calendar-item.props';
import { CalendarWidgetPlaceholderEmpty } from './components/placeholder/empty/empty';
import { useScrollToUpcomingDate } from './hooks/use-scroll-to-upcoming-date/use-scroll-to-upcoming-date';

export const CalendarWidget = ({
  idPrefix,
  data,
  emptyPlaceholderText,
  maxLength,
  itemHeight
}: CalendarWidgetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useScrollToUpcomingDate({ dependencies: [data.length], containerRef });

  const isShowDivider = (item: CalendarItemProps, index: number) => {
    const isItemHasNearestUpcomingType = item.type === 'nearestUpcoming';
    const isNextItemHasNearestUpcomingType = data[index + 1]?.type === 'nearestUpcoming';
    const isNextItemExist = data.length > index + 1;

    return !isItemHasNearestUpcomingType && !isNextItemHasNearestUpcomingType && isNextItemExist;
  };

  if (!data.length) {
    return <CalendarWidgetPlaceholderEmpty id={`${idPrefix}Placeholder`} emptyPlaceholderText={emptyPlaceholderText} />;
  }

  return (
    <Flex
      ref={containerRef}
      direction='column'
      justify='flex-start'
      height={`${maxLength * itemHeight + 2}px`}
      overflow='scroll'
      isHideScrollBar
    >
      {data.map((item, index) => (
        <Flex key={index} direction='column'>
          <CalendarWidgetItem idPrefix={idPrefix} index={index} style={{ height: `${itemHeight}px` }} {...item} />
          {isShowDivider(item, index) && <Divider id={`${idPrefix}Divider${index}`} color='grey50' />}
        </Flex>
      ))}
    </Flex>
  );
};
