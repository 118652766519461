import styled from 'styled-components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)`
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 13px 15px !important;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 20px 20px 0 !important;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 20px !important;
`;
