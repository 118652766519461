import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../../../../../../../constants';
import { useVehicleDamageHistoryQuery } from 'hooks/react-query/fleet/damage-history/use-damage-history.query';
import { useVehicleHistoryList } from 'pages/existing-vehicle-page/hooks/use-vehicle-history-list';
import useCostCentersHistoryList from '../components/sections/cost-centers-widget/hooks/use-cost-centers-history-list';

export const useVehicleHistorySectionsConditions = () => {
  const isDisplayDriverHistory = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const isShowDamageHistory = useIsUserHaveRole([USER_ROLES.administrator.id, USER_ROLES.fleetManager.id]);

  const { vehicleHistoryList } = useVehicleHistoryList('new_fuhrpark');
  const { costCentersHistoryItems } = useCostCentersHistoryList();
  const { vehicleDamageHistoryQuery } = useVehicleDamageHistoryQuery(undefined, {
    options: { enabled: isShowDamageHistory }
  });

  const isDisplayDriverHistoryShoreMoreButton = isDisplayDriverHistory && vehicleHistoryList.length > 5;
  const isDisplayDamageHistoryShoreMoreButton = isShowDamageHistory && vehicleDamageHistoryQuery.length > 5;
  const isDisplayCostCenterHistoryShoreMoreButton = costCentersHistoryItems.length > 5;

  return {
    isDisplayDriverHistory,
    isShowDamageHistory,
    isDisplayDriverHistoryShoreMoreButton,
    isDisplayDamageHistoryShoreMoreButton,
    isDisplayCostCenterHistoryShoreMoreButton,
    isDisplayShoreMoreButton:
      isDisplayDriverHistoryShoreMoreButton ||
      isDisplayDamageHistoryShoreMoreButton ||
      isDisplayCostCenterHistoryShoreMoreButton
  };
};
