import { RequestStatuses } from '../../../constants';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { AxiosResponse } from 'axios';

interface ShowDeactivationSnackbarParams {
  response: AxiosResponse;
  deactivatedCallback?: () => void;
  notDeactivatedCallback?: () => void;
}

const useDeactivationSnackbar = () => {
  const { showRequestSnackbar } = useRequestSnackbar();

  const showDeactivationSnackbar = ({
    response,
    deactivatedCallback,
    notDeactivatedCallback
  }: ShowDeactivationSnackbarParams) => {
    if (response.data.level === RequestStatuses.Success) {
      let snackbarText = 'Konfiguration erfolgreich deaktiviert.';
      if (response.data.messages[0].message.includes('Not deactivated')) {
        snackbarText = 'Es wurde eine Anfrage zum Löschen der Konfiguration erstellt.';
        notDeactivatedCallback && notDeactivatedCallback();
      } else {
        deactivatedCallback && deactivatedCallback();
      }
      showRequestSnackbar(true, () => {}, snackbarText, '', 5000);
    }
  };

  return { showDeactivationSnackbar };
};

export default useDeactivationSnackbar;
