import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import DeactivateComponent from '../deactivate-component';
import { Configuration } from 'components/vehicle-offers-list-component/hooks/react-query/use-drivers-personal-configurations/use-drivers-personal-configurations.types';

interface UseDeactivateDialogParams {
  configuration: Configuration;
  refreshList: () => void;
}

const useDeactivateDialog = ({ configuration, refreshList }: UseDeactivateDialogParams) => {
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openDeactivateDialog = () => {
    const alertData = {
      title: 'Konfiguration archivieren',
      children: (
        <DeactivateComponent closeDialog={closeDialog} configuration={configuration} refreshList={refreshList} />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeDialog
        }
      ]
    };

    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  };

  return { openDeactivateDialog };
};

export default useDeactivateDialog;
