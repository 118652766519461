export enum StepStatus {
  Done = 'done',
  Active = 'active',
  Inactive = 'inactive'
}

export interface IconColorProps {
  done: string;
  active: string;
  inactive: string;
}
