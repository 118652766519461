import { AnyObject, CrmGuidResponse, CrmResponse } from 'common/interfaces';
import { Configurations } from 'components/vehicle-offers-list-component/vehicle-offers-list-component.props';
import { ReactNode } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

export interface ItemProps {
  annualMileage: number;
  engineType: {
    value: number;
    name: string;
  };
  events: EventProps[];
  fuelConsumption: number;
  isEco: boolean;
  kraftstoffcosten_value: number;
  kraftstoffcosten: number;
  kraftstoffcostenPlusGesamtrate: number;
  uds_calc_monthly_costs_incl_fuel_value: number;
  new_listenpreis_netto_value: number;
  new_listenpreis_brutto_value: number;
  leasingPeriod: number;
  modifiedon: string;
  uds_name: string;
  new_status_anfrage_value: number;
  new_status_anfrage_id: number;
  new_stromverbrauch: number;
  sharepointLocation: string;
  tags: any[];
  uds_ausstattungshighlights_shortlist: string;
  uds_bafa: boolean;
  uds_carBrandName: string;
  uds_carModelName: string;
  uds_co2_emission_combined: number;
  uds_color: string;
  uds_comprehensive_insurance: number;
  uds_engine_tax: number;
  uds_enginetypecode: string;
  uds_enginetypecode_value: number;
  uds_fahrzeugangeboteid: string;
  uds_finanzrate: number;
  uds_fuelconsumption_out_of_city: string;
  uds_fuelconsumption_urban: string;
  uds_getriebe: number;
  uds_getriebe_value: number;
  uds_gez_fee: number;
  uds_gezgebhrcheckbox: boolean;
  uds_hauptauto: boolean;
  uds_interiorcolor: string;
  uds_internalid: string;
  uds_kalkulatorischekraftstoffkosten_value: number;
  uds_kfzsteuercheckbox: boolean;
  uds_kommentar_des_haendlers: string;
  uds_lastModified: string;
  uds_laufleistungkm: number;
  uds_laufleistungpafahrzeugangebote: number;
  uds_laufzeitfahrzeugangebote: number;
  uds_leistung: string;
  uds_leistungkw: number;
  uds_listenpreis: number;
  uds_listenpreis_value: number;
  uds_listenpreis_netto: number;
  uds_listenpreis_netto_value: number;
  uds_maintenance_cost: number;
  uds_marge: number;
  uds_marke: string;
  uds_mobexo_contribution: number;
  uds_mobexo_gesamtrate: number;
  uds_mobexo_gesamtrate_value: number;
  uds_mobexoangebotgesamtrate: number;
  uds_modell: string;
  uds_reifenersatzcheckbox: boolean;
  uds_tire_replacement_cost: number;
  uds_title: string;
  uds_uds_fuelconsumption_combined: string;
  uds_vollkaskoversicherungcheckbox: boolean;
  uds_vsl_liefertermin_laut_handler: string;
  uds_vsl_liefertermin_laut_handler_main: string;
  uds_wartunginspektioncheckbox: boolean;
  uds_wheels_storage_cost: number;
  uds_winterrderinkllagerungcheckbox: boolean;
  uds_wunschlieferdatum: string;
  uds_wunschlaufzeitcode_value: string;
  uds_einmaligekosten_uberfuhrungskosten_value: number;
  uds_einmaligekosten_zulassungskosten_value: number;
  uds_einmaligekosten_anzahlung_value: number;
  uds_barpreis_netto_value: number;
  la_listenpreis_netto_value: number;
  la_barpreis_netto_value: number;
  includeFuelCosts: boolean;
  uds_gesamtkosten_monatlich_vertragsabschluss_value: number;
  uds_kalkulatorischekraftstoffkosten: number;
  uds_haendlerkontakt_id: string;
  uds_anbietenterhandlerid_id: string;
  uds_anbietenterhandlerid: string;
  uds_global_reference_vehicleid_id: string;
  uds_angebotsstatuscode_value: number;
  uds_tags: string;
  lowestLeasingprice_value: number;
  uds_zuordnung_value: number;
  canBeArchived: boolean;
  uds_remove_request_date: string;
  uds_configurationtypecode_value: number;
  carPolicyList: { uds_carpolicyid: CrmGuidResponse; uds_name: CrmResponse }[];
}

export interface DateInfoProps {
  icon: Element | ReactNode;
  title: string;
  value: string;
}

export interface TriangleProps {
  width: number;
  height: number;
  color: string;
  rotate?: number;
}

export interface InfoItemProps {
  children?: string;
}

export interface ProductInfoProps {
  label: string;
  value: string;
}

export interface ProductProps {
  reloadList: (deactivateCar?: boolean) => void;
  setUseQueryKey: () => any;
  item: ItemProps;
}

export interface RoleProps {
  id: string;
  name: string;
}

export interface EventProps {
  createdby: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  createdbyname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  createdbyyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  createdon: {
    attributeTypeCode: number;
    attributeValue: string;
  };
  createdonbehalfby: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  createdonbehalfbyname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  createdonbehalfbyyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  importsequencenumber: {
    attributeTypeCode: number;
    attributeValue: number | null;
  };
  modifiedby: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  modifiedbyname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  modifiedbyyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  modifiedon: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  modifiedonbehalfby: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  modifiedonbehalfbyname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  modifiedonbehalfbyyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  organizationid: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  organizationidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  overriddencreatedon: {
    attributeTypeCode: number;
    attributeValue: Date;
  };
  statecode: {
    attributeTypeCode: number;
    attributeValue: {
      label: string;
      value: number;
    };
  };
  statecodename: {
    attributeTypeCode: 17;
    attributeValue: unknown | null;
  };
  statuscode: {
    attributeTypeCode: 17;
    attributeValue: {
      label: string;
      value: number;
    };
  };
  statuscodename: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  timezoneruleversionnumber: {
    attributeTypeCode: number;
    attributeValue: number | null;
  };
  uds_dokumenturl: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_email: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_entscheidung: {
    attributeTypeCode: number;
    attributeValue: boolean;
  };
  uds_entscheidungname: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  uds_erstelltfurid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_erstelltfuridname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_erstelltfuridyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_erstelltvonportaluser: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  uds_erstelltvonportalusername: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_erstelltvonportaluseryominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_eventcode: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  uds_eventcodename: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  uds_eventdescription: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  uds_eventdescriptionname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugangebotid: {
    attributeTypeCode: number;
    attributeValue: {
      id: string;
      logicalName: string;
      name: string;
    };
  };
  uds_fahrzeugangebotidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellfreigeberid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_fahrzeugbestellfreigeberidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellfreigeberidyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungdrittfreigabe: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_fahrzeugbestellungdrittfreigabename: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungdrittfreigabeyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungeneventsid: {
    attributeTypeCode: number;
    attributeValue: string;
  };
  uds_fahrzeugbestellungerstfreigabeid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_fahrzeugbestellungerstfreigabeidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungerstfreigabeidyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungzweitfreigabeid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_fahrzeugbestellungzweitfreigabeidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_fahrzeugbestellungzweitfreigabeidyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_freigegeben: {
    attributeTypeCode: number;
    attributeValue: boolean;
  };
  uds_freigegeben_am: {
    attributeTypeCode: number;
    attributeValue: Date | null;
  };
  uds_freigegeben_vonid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_freigegeben_vonidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_freigegeben_vonidyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_freigegebenname: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  uds_handlerid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_handleridname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_handleridyominame: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_isbestellbedingungen_akzeptiert: {
    attributeTypeCode: number;
    attributeValue: boolean;
  };
  uds_isbestellbedingungen_akzeptiertname: {
    attributeTypeCode: number;
    attributeValue: unknown | null;
  };
  uds_leasingangebotid: {
    attributeTypeCode: number;
    attributeValue: AnyObject | null;
  };
  uds_leasingangebotidname: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  uds_name: {
    attributeTypeCode: number;
    attributeValue: string;
  };
  uds_notiz: {
    attributeTypeCode: number;
    attributeValue: string | null;
  };
  utcconversiontimezonecode: {
    attributeTypeCode: number;
    attributeValue: number | null;
  };
  versionnumber: {
    attributeTypeCode: number;
    attributeValue: number | null;
  };
}

export enum DriverWireframeIds {
  Dr01 = 'T-DR-01',
  Dr02 = 'T-DR-02',
  Dr03 = 'T-DR-03'
}

export enum FleetManagerWireframeIds {
  Fm01 = 'T-FM-01',
  Fm02 = 'T-FM-02',
  Fm03 = 'T-FM-03',
  Fm04 = 'T-FM-04',
  Fm05 = 'T-FM-05',
  Fm06 = 'T-FM-06',
  Fm07 = 'T-FM-07',
  Fm08 = 'T-FM-08'
}
