import React, { useContext } from 'react';
import { SingleTabContext } from 'components/tab/type/single/single.context';
import { TabItemFrameProps } from './tab-item-frame.props';
import { useUnsavedChangesModal } from 'hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { StyledTabItemFrame } from './tab-item-frame.styled';

export const TabItemFrame = ({ children, tabIndex, isHide, ...textProps }: TabItemFrameProps) => {
  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const { toggleTab } = useContext(SingleTabContext);

  const toggleTabHandler = () => {
    openUnsavedChangesModal(() => toggleTab(tabIndex));
  };

  if (isHide) {
    return null;
  }

  return (
    <StyledTabItemFrame {...textProps} onClick={toggleTabHandler}>
      {children}
    </StyledTabItemFrame>
  );
};
