import React, { useContext } from 'react';
import { Flex } from 'common/common-components.styled';
import { FormLabel } from 'components/form-hook/form-field/components/common/label/label';
import { useTheme } from 'styled-components';
import { CloseIcon } from 'components/icons/close-icon';
import { OptionSetFileItemProps } from '../option-set-file-item.props';
import { OptionSetFileContext } from '../../../option-set-file.context';

export const OptionSetFileTitle = ({ valueItem }: Pick<OptionSetFileItemProps, 'valueItem'>) => {
  const { values, onChange } = useContext(OptionSetFileContext);
  const theme = useTheme();

  const removeForm = () => {
    onChange(values.filter(item => item.id !== valueItem.id));
  };

  return (
    <Flex align='center' justify='space-between'>
      <FormLabel isRequired={!!values.find(item => item.id === valueItem.id)?.files?.length} />

      {values.length > 1 && (
        <Flex onClick={removeForm} pointer>
          <CloseIcon width='14' height='14' color={theme.colors.darkBlue} />
        </Flex>
      )}
    </Flex>
  );
};
