import { IconProps } from 'common/interfaces';
import React from 'react';

export const ComparisonArrowIcon = ({ width = '10', height = '10', color = '#BF0040', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.897385 9.10149L9.01154 0.987326M9.01154 0.987326L5.15428 0.987326M9.01154 0.987326L9.01155 4.84459'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
