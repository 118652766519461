import { useDispatch } from 'react-redux';
import { setSnakbar } from 'actions/app_action';

const useRequestSnackbar = () => {
  const dispatch = useDispatch();

  const showRequestSnackbar = (
    successCondition: boolean,
    successCallback?: () => void,
    successMessage: string = defaultSuccessMessage,
    errorMessage: string = defaultErrorMessage,
    duration: number = 3000,
    warningCondition: boolean = false,
    warningMessage: string = '',
    warningCallback?: () => void
  ) => {
    let snackbarData = {
      type: 'success',
      message: successMessage
    };
    if (successCondition) {
      successCallback && successCallback();
    } else if (warningCondition) {
      warningCallback && warningCallback();
      snackbarData = {
        type: 'warning',
        message: warningMessage
      };
    } else {
      snackbarData = {
        type: 'error',
        message: errorMessage
      };
    }
    dispatch(
      setSnakbar({
        isOpen: true,
        time: duration,
        ...snackbarData
      })
    );
  };

  return { showRequestSnackbar };
};

const defaultSuccessMessage = 'Änderungen wurden übernommen.';
const defaultErrorMessage = 'Änderungen wurden nicht übernommen.';

export default useRequestSnackbar;
