import React from 'react';
import classes from './mobile-my-vehicles-item.module.scss';
import { mainTheme } from 'common/global-styles';
import { getChipColor } from '../utils';
import { MobileMyVehiclesItemProps } from '../my-vehicles.props';
import { DriverStatus } from '../table-components';

export const MobileMyVehiclesItemComponent = ({ data }: MobileMyVehiclesItemProps) => {
  const containerStyle = {
    borderColor: mainTheme.colors[getChipColor(data.portalStatus)]
  };

  return (
    <li className={classes.container} style={containerStyle} data-car-id={data.new_fuhrparkid}>
      <div className='d-flex j-c-space-between'>
        <div>{data.new_name}</div>
        <DriverStatus row={data} />
      </div>
      <div className={classes.bottom_value}>{data.new_model || '-'}</div>
    </li>
  );
};
