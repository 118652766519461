export function sum(flatrate) {
  const finanzrate = flatrate.finanzrate || 0;
  const insurance = flatrate.insurance_checkbox ? flatrate.insurance : 0;
  const tax = flatrate.tax_checkbox ? flatrate.tax : 0;
  const gez = flatrate.gez_checkbox ? flatrate.gez : 0;
  const winterWheelsStorage = flatrate.winterWheelsStorage_checkbox ? flatrate.winterWheelsStorage : 0;
  const wheelStorageReplacement = flatrate.wheelStorageReplacement_checkbox ? flatrate.wheelStorageReplacement : 0;
  const tireReplacement = flatrate.tireReplacement_checkbox ? flatrate.tireReplacement : 0;
  const maintenance = flatrate.maintenance_checkbox ? flatrate.maintenance : 0;
  const mobexoContribution = flatrate.mobexoContribution || 0;
  const marge = flatrate.marge || 0;

  const servicerate =
    insurance + tax + gez + winterWheelsStorage + wheelStorageReplacement + tireReplacement + maintenance + mobexoContribution + marge;

  const sum = finanzrate + servicerate;

  return Math.ceil(sum);
}

export function sumFahr(data) {
  const finanzrate = data.uds_finanzrate || 0;
  const mobexoContribution = data.uds_mobexo_contribution || 0;
  const marge = data.uds_marge || 0;

  const sum = finanzrate + mobexoContribution + marge;
  return Math.ceil(sum);
}
