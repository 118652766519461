import classNames from 'classnames';
import { Position } from 'common/enums';
import { ComparisonArrowIcon } from 'components/icons';
import React from 'react';
import toMoney from 'utils/toMoney';
import classes from './expenses-per-year.module.scss';
import { ExpensesPerYearProps, PercentColor } from './expenses-per-year.props';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { useTheme } from 'styled-components';
import { Flex } from 'common/common-components.styled';

export const ExpensesPerYear = ({
  name,
  price,
  year,
  percentSetup,
  openPage,
  tooltipText = '',
  headerPercent = false
}: ExpensesPerYearProps) => {
  const theme = useTheme();
  const percentContainerClassName = classNames(classes.percent_container, {
    [classes.percent_container_red]: percentSetup?.color === PercentColor.Red,
    [classes.percent_container_green]: percentSetup?.color === PercentColor.Green
  });

  const arrowClassName = classNames({
    [classes.arrow_icon_bottom]: percentSetup?.arrowDirection === Position.Bottom,
    [classes.arrow_icon_red]: percentSetup?.color === PercentColor.Red,
    [classes.arrow_icon_green]: percentSetup?.color === PercentColor.Green
  });

  const isShowPercentContainer =
    percentSetup && (typeof percentSetup.percent === 'string' ? true : isFinite(percentSetup.percent));

  return (
    <div className={classes.container}>
      {headerPercent && isShowPercentContainer ? (
        <div className={classes.header_precent}>
          <h2 className={classes.price}>{typeof price === 'string' ? price : toMoney(price)}</h2>
          <div className={percentContainerClassName}>
            <span>
              {typeof percentSetup.percent === 'string' ? percentSetup.percent : `${Math.round(percentSetup.percent)}%`}
            </span>
            <ComparisonArrowIcon className={arrowClassName} width={11} height={10} />
          </div>
        </div>
      ) : (
        <h2 className={classes.price}>{typeof price === 'string' ? price : toMoney(price)}</h2>
      )}

      <div className={classes.footer}>
        <div className={classes.description}>
          {openPage ? (
            <Flex pointer onClick={openPage}>
              <span>
                {name} in {year}
              </span>
            </Flex>
          ) : (
            <span>
              {name} in {year}
            </span>
          )}
          {tooltipText && (
            <TooltipHint className={classes.inline_tooltip} position='top' text={tooltipText}>
              <InfoHintIcon width={16} height={15} color={theme.colors.grey400} />
            </TooltipHint>
          )}
        </div>

        {!headerPercent && isShowPercentContainer && (
          <div className={percentContainerClassName}>
            <span>
              {typeof percentSetup.percent === 'string' ? percentSetup.percent : `${Math.round(percentSetup.percent)}%`}
            </span>
            <ComparisonArrowIcon className={arrowClassName} width={11} height={10} />
          </div>
        )}
      </div>
    </div>
  );
};
