import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DraggableListProps, DraggableListDefaultType } from './draggable-list.props';
import { Flex, Text } from 'common/common-components.styled';
import { DraggableListDroppableContent } from './components/droppable-content/droppable-content';

export function DraggableList<TDataItem extends DraggableListDefaultType>({
  droppableId,
  title,
  ...restProps
}: DraggableListProps<TDataItem>) {
  return (
    <Flex direction='column' height='100%'>
      {title && (
        <Text color='blue' size='18' bold bottom='20'>
          {title}
        </Text>
      )}
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <DraggableListDroppableContent<TDataItem>
            {...restProps}
            droppableId={droppableId}
            provided={provided}
            snapshot={snapshot}
          />
        )}
      </Droppable>
    </Flex>
  );
}
