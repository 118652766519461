import React from 'react';
import { Flex } from 'common/common-components.styled';
import Button from 'components/button';
import { ModalContentSubmitProps } from './modal-content.props';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'actions/app_action';
import { MODALS } from '../../../../../constants';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import moment from 'moment';
import { useQueryClient } from 'react-query';

export const ModalContentSubmit = ({ reactHookFormData, vehicleId }: ModalContentSubmitProps) => {
  const { handleSubmit } = reactHookFormData;

  const dispatch = useDispatch();

  const { fetch } = useFetch({
    loadingKey: 'vehicleSetInProgressStatus',
    isShowSuccessSnackbar: true
  });

  const queryClient = useQueryClient();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const onSubmitHandler = handleSubmit(({ new_ezl }) => {
    closeAlert();

    fetch({
      requestConfig: fleetRequestConfig.setInProgress({
        fuhrparkId: vehicleId,
        new_ezl: moment(new_ezl).local().format('YYYY-MM-DD')
      }),
      callback: () => queryClient.clear()
    });
  });

  return (
    <Flex left='10'>
      <Button onClick={onSubmitHandler}>Speichern</Button>
    </Flex>
  );
};
