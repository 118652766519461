import React, { useCallback, useState } from 'react';
import { Flex, Text } from 'common/common-components.styled';
import moment from 'moment';
import { CarEventContainer, HiddenText, HoveredTooltip } from './calendar-view-styled';
import { useTheme } from 'styled-components';
import BookingMoreMenu from './booking-more-menu';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../constants';
import { Event } from './calendar-view';
import { RecordStatuses } from './bookings-page';
import { useDispatch } from 'react-redux';
import { setPoolCalendarEventTooltip } from 'actions/app_action';
import { useTypedSelector } from 'hooks/use-typed-selector';
import useEllipsisTooltip from 'hooks/use-ellipsis-tooltip';
import { getBooking } from 'components/pool-car-booking/booking-details-tooltip';

interface Props {
  event: Event;
  refetch: () => void;
  indexOfRow: number;
}

const CarEvent = ({ event, refetch }: Props) => {
  const isDriverOrPoolManager = useIsUserHaveRole([USER_ROLES.driver.id, USER_ROLES.poolManager.id]);
  const theme = useTheme();
  const overlappedEvent = event.height > 45;
  const showStatus = event.width > 120;
  const dispatch = useDispatch();
  const poolCalendarEventTooltip = useTypedSelector(state => state['app'].poolCalendarEventTooltip);
  const EVENT_BORDER_COLORS = {
    [RecordStatuses.APPROVED_RECORD]: theme.colors.blue,
    [RecordStatuses.OPENED_RECORD]: theme.colors.darkGold,
    [RecordStatuses.REJECTED_RECORD]: theme.colors.pink,
    [RecordStatuses.CANCELED_RECORD]: theme.colors.grey400
  };
  const { isShowTip, content, onMouseEnter, onMouseLeave } = useEllipsisTooltip();
  const [showTooltip, setShowTooltip] = useState(isShowTip);

  const handleEventTooltip = () => {
    dispatch(setPoolCalendarEventTooltip(null));
    if (
      [RecordStatuses.APPROVED_RECORD, RecordStatuses.OPENED_RECORD, RecordStatuses.REJECTED_RECORD].includes(
        event.uds_status_code
      ) &&
      event.isShowBooking
    ) {
      setTimeout(() => {
        dispatch(setPoolCalendarEventTooltip(event));
      }, 150);
    }
  };

  const handleEnter = useCallback(() => {
    setShowTooltip(event.width < 17);
  }, [event.width]);

  return (
    <CarEventContainer
      isActiveEvent={
        [RecordStatuses.APPROVED_RECORD, RecordStatuses.OPENED_RECORD].includes(event.uds_status_code) &&
        event.isShowBooking
      }
      isRejectedEvent={event.uds_status_code === RecordStatuses.REJECTED_RECORD && event.isShowBooking}
      width={event.width}
      height={event.height}
      left={event.left}
      top={event.top}
      id={event.uds_name}
      onClick={handleEventTooltip}
      borderColor={
        event.isShowBooking ? EVENT_BORDER_COLORS[event.uds_status_code] || theme.colors.grey400 : theme.colors.grey400
      }
      onMouseEnter={handleEnter}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Flex direction='column' style={{ overflow: 'hidden' }}>
        <HiddenText
          size={overlappedEvent ? '14' : '12'}
          onMouseEnter={() => onMouseEnter()}
          onMouseLeave={onMouseLeave}
          ref={content as React.RefObject<HTMLParagraphElement>}
        >
          {event.requester}
        </HiddenText>
        <HiddenText
          size={overlappedEvent ? '14' : '12'}
          onMouseEnter={() => onMouseEnter()}
          onMouseLeave={onMouseLeave}
          ref={content as React.RefObject<HTMLParagraphElement>}
        >
          {moment(event.startDate).format('DD.MM HH:mm')} - {moment(event.endDate).format('DD.MM HH:mm')}
        </HiddenText>
        {overlappedEvent && showStatus && <span>{event.uds_status || 'Nicht verfügbar'}</span>}
      </Flex>
      {event.width > 100 && event.isShowBooking && <BookingMoreMenu row={getBooking(event)} refetch={refetch} />}

      {!poolCalendarEventTooltip && (showTooltip || isShowTip) && (
        <HoveredTooltip width={event.width} height={event.height}>
          <Text size='12'>{event.requester}</Text>
          <Text size='12'>
            {moment(event.startDate).format('DD.MM HH:mm')} - {moment(event.endDate).format('DD.MM HH:mm')}
          </Text>
          <Text size='12'>{event.uds_status}</Text>
        </HoveredTooltip>
      )}
    </CarEventContainer>
  );
};

export default CarEvent;
