import React from 'react';
import { FormInfoItemEditableProps } from 'components/form-hook/components/info/components/type/editable/editable.props';
import { VehicleDetailsEditForm } from 'pages/existing-vehicle-page/components/form/edit-form/edit-form';
import { generateFormField } from 'utils/form/generate-form-fields/generate-form-fields';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { OPTION_SET } from 'components/form-hook/option-set-constants';
import { CrmDataTypes } from 'common/enums';

export const useFormInfoListDataVehicleEquipment = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const formInfoListDataVehicleEquipment: FormInfoItemEditableProps[] = [
    {
      value: fuhrpark?.uds_einbau_vorhanden_code,
      label: 'Einbau vorhanden',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'uds_einbau_vorhanden_code',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.uds_einbau_vorhanden_code_value,
              options: OPTION_SET.uds_einbau_vorhanden_code
            })
          ]}
        />
      )
    },
    {
      value: fuhrpark?.uds_einbau_eigentuemer_code,
      label: 'Einbau-Eigentümer',
      editableComponent: (
        <VehicleDetailsEditForm
          formFields={[
            generateFormField.optionSet({
              name: 'uds_einbau_eigentuemer_code',
              crmFieldType: CrmDataTypes.Picklist,
              defaultValue: fuhrpark?.uds_einbau_eigentuemer_code_value,
              options: OPTION_SET.uds_einbau_eigentuemer_code
            })
          ]}
        />
      )
    }
  ];

  return { formInfoListDataVehicleEquipment };
};
