import { ExclamationMarkIcon } from 'components/icons/exclamation-mark';
import React from 'react';
import { FileProps } from '../file.props';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { TooltipComponent } from 'components/tooltip-component/tooltip-component';

export const FileAcceptInfo = ({ dropzoneOptions }: Pick<FileProps, 'dropzoneOptions'>) => {
  const theme = useTheme();

  const fileFormats = dropzoneOptions?.accept;
  const fileMaxSize = dropzoneOptions?.maxSize;

  const getFileAcceptFormatString = () => {
    if (!fileFormats) {
      return '';
    }

    if (Array.isArray(fileFormats)) {
      const formatsArray = fileFormats.map(type => type.split('/')[1] || type.replace('.', ''));

      return formatsArray.join(', ').toUpperCase();
    }

    return fileFormats;
  };

  const getAcceptString = () => {
    const acceptFormatString = getFileAcceptFormatString();
    let acceptString = '';

    if (acceptFormatString) {
      acceptString = `Hier kannst Du Dateien mit dem Format ${acceptFormatString}`;
    }

    if (fileMaxSize) {
      acceptString = `${acceptString} mit einer max. Dateigröße von ${fileMaxSize / 1024 / 1024} MB hochladen`;
    }

    return `${acceptString}.`;
  };

  if (!dropzoneOptions?.accept) {
    return null;
  }

  return (
    <Flex justify='flex-end'>
      <TooltipComponent title={getAcceptString()}>
        <Flex align='center' top='10' pointer>
          <Text color='grey500' size='12' right='5'>
            Zulässige Formate
          </Text>

          <ExclamationMarkIcon color={theme.colors.grey500} />
        </Flex>
      </TooltipComponent>
    </Flex>
  );
};
