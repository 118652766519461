import React from 'react';
import { formatDate } from '../../utils';
import classes from './repair-status-item.module.scss';

const getStatus = status => {
  if (status === null) {
    return 'inactive';
  }
  if (new Date(status) > new Date()) {
    return 'active';
  }
  if (new Date(status) <= new Date()) {
    return 'done';
  }
  return 'inactive';
};

export const processSetup = (viewingAppointment, pickupDate, completionDate, status) => {
  return [
    status?.toLowerCase() === 'vorbesichtigung geplant'
      ? {
          status: getStatus(viewingAppointment),
          description: (
            <div className={classes.step_description}>
              <p className={viewingAppointment ? classes.step_description_value : classes.inactive_value}>
                {formatDate(viewingAppointment)}
              </p>
              <p className={classes.step_description_name}>Besichtigungstermin</p>
            </div>
          )
        }
      : undefined,
    {
      status: getStatus(pickupDate),
      description: (
        <div className={classes.step_description}>
          <p className={pickupDate ? classes.step_description_value : classes.inactive_value}>
            {formatDate(pickupDate)}
          </p>
          <p className={classes.step_description_name}>Abholdatum</p>
        </div>
      )
    },
    {
      status: getStatus(completionDate),
      description: (
        <div className={classes.step_description}>
          <p className={completionDate ? classes.step_description_value : classes.inactive_value}>
            {formatDate(completionDate)}
          </p>
          <p className={classes.step_description_name}>Geplanter Fertigstellungstermin</p>
        </div>
      )
    }
  ];
};
