export enum ButtonType {
  Main = 'main',
  Secondary = 'secondary'
}

export enum ButtonComponentType {
  Link = 'Link',
  Button = 'button',
  SaveButton = 'saveButton',
  DownloadExcel = 'downloadExcel',
  DownloadExcelIcon = 'downloadExcelIcon',
  CloseModal = 'closeModal'
}
