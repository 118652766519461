import React from 'react';
import { enterEmailFormFields } from '../../models/forms/enter-email-form-model';
import Form from '../../components/form';
import { getFormValues } from '../../utils/get-form-values';
import { AnyObject } from '../../common/interfaces';
import { setForm, toggleModal } from '../../actions/app_action';
import { updateCrmEntity } from '../../services/crm-entity-service';
import { crmDataTypes, MODAL_NAMES, MODALS } from '../../constants';
import { useTypedSelector } from '../use-typed-selector';
import useLatestValue from '../use-latest-value';
import { useOverlay } from '../index';
import { useDispatch } from 'react-redux';
import { PAGES, ReactQueryKeys } from '../../common/enums';
import { useQueryClient } from 'react-query';

export const useEmailModal = () => {
  const dispatch = useDispatch();
  const form = useTypedSelector(state => state.app.forms);

  const [showOverlay, hideOverlay] = useOverlay();
  const formsLatest = useLatestValue(form);

  const queryClient = useQueryClient();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getAlertData = (userId: string, callback: Function) => ({
    title: 'E-Mailadresse hinterlegen',
    children: (
      <>
        <p className='mb-2'>Bitte hinterlege zunächst die E-mailadresse des Mitarbeiters.</p>
        <Form name='enterEmail' formFields={enterEmailFormFields} />
      </>
    ),
    buttons: [
      {
        type: 'cancel',
        title: 'Zurück',
        action: closeAlert
      },
      {
        type: 'submit',
        title: 'Speichern',
        action: () => {
          getFormValues(
            formsLatest.current,
            (form: AnyObject) => {
              dispatch(setForm(form));
            },
            async (values: EnterEmailFormValuesProps) => {
              if (values.emailaddress1) {
                try {
                  showOverlay();
                  closeAlert();

                  await updateCrmEntity({
                    entityName: 'contact',
                    id: userId,
                    attributes: {
                      emailaddress1: {
                        attributeTypeCode: crmDataTypes.String,
                        attributeValue: values.emailaddress1
                      }
                    },
                    setNull: false
                  });
                  await callback(values);

                  queryClient.resetQueries([ReactQueryKeys.AccountIsBooked]);
                  queryClient.resetQueries([PAGES.DRIVERS_CHECK]);
                  queryClient.resetQueries([PAGES.DRIVERS_CHECK, ReactQueryKeys.Filter]);
                } catch (err) {
                  console.error(err);
                } finally {
                  hideOverlay();
                }
              }
            }
          );
        }
      }
    ]
  });

  return {
    openModal: (userId: string, callback: Function) =>
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(userId, callback)))
  };
};

export interface EnterEmailFormValuesProps {
  emailaddress1: string;
}
