import { createContext } from 'react';
import { ITimeValue } from 'components/form-hook/form-field/components/time-field/time.props';

export interface DateTimeFieldContextProps {
  valueDateTime: ITimeValue | null;
  onChangeDateTime: ((time: ITimeValue) => void) | null;
}

export const DateTimeFieldContext = createContext<DateTimeFieldContextProps>({
  valueDateTime: null,
  onChangeDateTime: null
});
