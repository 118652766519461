import React from 'react';
import classes from './circle-indicator.module.scss';
import classNames from 'classnames';
import { CircleIndicatorProps } from './circle-indicator.props';

export const CircleIndicator = ({ children, className, color, width = 6, height = 6 }: CircleIndicatorProps) => {
  const citcleStyle = {
    background: color,
    width,
    height
  };

  return (
    <div className={classNames(classes.circle, className)} style={citcleStyle}>
      {children}
    </div>
  );
};
