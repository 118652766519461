import styled from 'styled-components';
import { OfferStatusProps } from './offer-status-section.types';

export const StyledDeclinedState = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.pink};
  height: 24px;
  border-radius: 12px;
  padding: 0 10px;
`;

export const ItemContainer = styled.div<{
  color: string;
  isFirstItem: boolean;
  isLastItem: boolean;
}>`
  width: ${({ isFirstItem }) => (isFirstItem ? '40px' : '50px')};
  height: 35px;
  position: relative;
  padding: 0 10px;
  border-radius: ${({ isFirstItem, isLastItem }) => (isFirstItem ? '4px 0 0 4px' : isLastItem ? '0 4px 4px 0' : '3px')};
  display: flex;
  align-items: center;
  justify-content: ${({ isFirstItem }) => (isFirstItem ? 'center' : 'flex-end')};

  background-color: ${({ color }) => color};

  &:before {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 17px 0 17px 14px;
    border-color: transparent transparent transparent ${({ color }) => color};
    right: -13px;
    z-index: 25 !important;
    display: ${({ isLastItem }) => (isLastItem ? 'none' : 'inline-block')};
  }

  &:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 17px 0 17px 14px;
    border-color: transparent transparent transparent ${({ theme }) => theme.colors.white};
    right: -15px;
    z-index: 20 !important;
    display: ${({ isLastItem }) => (isLastItem ? 'none' : 'inline-block')};
  }
`;
