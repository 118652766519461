import React from 'react';
import { VehicleDetailsHistoryEmptyPlaceholderProps } from './empty.props';
import { Flex, Text } from 'common/common-components.styled';
import { CarWithManyStarsIcon } from 'components/icons-new/car-with-many-stars';

export const VehicleDetailsHistoryEmptyPlaceholder = ({ title, id }: VehicleDetailsHistoryEmptyPlaceholderProps) => {
  return (
    <Flex id={id} direction='column' width='100%' align='center' padding='20px 0'>
      <CarWithManyStarsIcon width='57' height='70' />

      <Text color='blue' bold top='15'>{`Das Auto hat keinen ${title}.`}</Text>
    </Flex>
  );
};
