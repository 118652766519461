import React, { useState } from 'react';
import classes from './driving-license-control-filters.module.scss';
import {
  ClearAllFiltersButton,
  Filter,
  FilterButton,
  FilterContainer,
  FilterInput,
  PickersContainer
} from '../../../filter-items';
import {
  EMTY_FILTER_STATE,
  CRM_VALUE,
  EMPTY_FILTER_INSTRUCTION_DATE
} from '../../driving-license-control-component-view';
import { DRIVER_LICENSE_CONTROL_FILTER_VALUES, FILTER_BUTTON_DRIVER_INSTRUCTION } from './utils';
import { convertDateToServer } from 'utils/convert-date-to-server';

export const DrivingLicenseControlFilters = ({ filterData, sessionFilter, setSessionFilter, goToFirstPage }) => {
  const [isOpen, setOpen] = useState('');

  const filterFunction = filterData?.controls || [];
  const filterFirm = filterData?.firms?.map(company => company.name) || [];
  const filterName = filterData?.fahrers?.map(driver => driver.name) || [];
  const filterBooked = filterData?.isBooked?.map(booked => (booked ? 'ja' : 'nein')) || [];
  const filterStatus = filterData?.statuses?.map(status => status.label) || [];

  const driverLabel = sessionFilter?.filterForClient[CRM_VALUE.driver];
  const companyLabel = sessionFilter?.filterForClient[CRM_VALUE.company];
  const statusLabel = sessionFilter?.filterForClient[CRM_VALUE.status];
  const controlLabel = sessionFilter?.filterForClient[CRM_VALUE.control];
  const bookedServiceLabel = sessionFilter?.filterForClient[CRM_VALUE.bookedService];
  const driverInstructionLabel = sessionFilter?.filterForClient[CRM_VALUE.startInstructionDate];

  const toggleFilterIsOpen = (title = null) => {
    setOpen(title);
  };

  const saveInstructionDate = value => {
    const convertedDate = convertDateToServer(value);
    const { startdate, enddate } = convertedDate;

    const sessionFilterData = {
      [CRM_VALUE.isExistInstructionDate]: startdate ? null : false,
      [CRM_VALUE.startInstructionDate]: startdate,
      [CRM_VALUE.endInstructionDate]: enddate
    };

    const sessionClientFilterData = {
      ...sessionFilterData,
      [CRM_VALUE.startInstructionDate]: value
    };

    goToFirstPage();
    setSessionFilter(sessionFilterData, sessionClientFilterData);
  };

  const saveFilter = (title, serverValue, clientValue) => {
    goToFirstPage();
    setSessionFilter(
      { [convertHeaderToCrmName(title)]: serverValue },
      { [convertHeaderToCrmName(title)]: clientValue }
    );
  };

  const convertHeaderToCrmName = (title = '') => {
    switch (title.toLowerCase()) {
      case 'name':
        return CRM_VALUE.driver;

      case 'unternehmen':
        return CRM_VALUE.company;

      case 'gebucht':
        return CRM_VALUE.bookedService;

      case 'fahrerunterweisung':
        return CRM_VALUE.startInstructionDate;

      case 'funktion':
        return CRM_VALUE.control;

      case 'führerscheinkontrolle':
        return CRM_VALUE.status;

      case 'status':
        return CRM_VALUE.contactStatus;

      default:
        return '';
    }
  };

  const clearFilter = (title = '') => {
    goToFirstPage();

    if (!title) {
      setSessionFilter(EMTY_FILTER_STATE, EMTY_FILTER_STATE);
      return;
    }

    if (title === 'Fahrerunterweisung') {
      return setSessionFilter(EMPTY_FILTER_INSTRUCTION_DATE, EMPTY_FILTER_INSTRUCTION_DATE);
    }

    saveFilter(title, null, null);
  };

  const handleInput = (title, value) => {
    toggleFilterIsOpen();

    if (convertHeaderToCrmName(title) === CRM_VALUE.company) {
      const firm = filterData?.firms.find(company => {
        return company?.name?.toLowerCase() === value?.toLowerCase();
      });
      if (firm) {
        saveFilter(title, firm.id, firm.name);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.status) {
      const status = filterData?.statuses.find(status => {
        return status?.label?.toLowerCase() === value?.toLowerCase();
      });
      if (status) {
        saveFilter(title, status.value, status.label);
      }
      return;
    }
    if (convertHeaderToCrmName(title) === CRM_VALUE.bookedService) {
      return saveFilter(title, value === 'ja', value);
    }

    if (convertHeaderToCrmName(title) === CRM_VALUE.startInstructionDate) {
      return saveInstructionDate(value);
    }

    saveFilter(title, value, value);
  };

  return (
    <section className={classes.filters}>
      <Filter
        title='Name'
        isOpen={isOpen}
        value={driverLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Name' onClose={toggleFilterIsOpen}>
          <FilterInput title='Name' handleInput={handleInput} dropListData={filterName} />
        </FilterContainer>
      </Filter>

      <Filter
        title='Unternehmen'
        isOpen={isOpen}
        value={companyLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Unternehmen' onClose={toggleFilterIsOpen}>
          {filterFirm.map((firm, indx) => {
            if (indx === 0) {
              return (
                <FilterButton handleInput={handleInput} title='Unternehmen' label={firm} value={firm} key={firm} />
              );
            }
            if (indx === 1) {
              return (
                <FilterButton handleInput={handleInput} title='Unternehmen' label={firm} value={firm} key={firm} />
              );
            }
          })}
          <FilterInput title='Unternehmen' handleInput={handleInput} dropListData={filterFirm} />
        </FilterContainer>
      </Filter>

      <Filter
        title='Führerscheinkontrolle'
        isOpen={isOpen}
        value={statusLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer
          header='Führerscheinkontrolle'
          onClose={toggleFilterIsOpen}
          isNothingFound={!filterStatus.length}
        >
          {filterStatus?.map(status => (
            <FilterButton
              handleInput={handleInput}
              title='Führerscheinkontrolle'
              label={status}
              value={status}
              key={status}
            />
          ))}
        </FilterContainer>
      </Filter>
      <Filter
        title='Fahrerunterweisung'
        isOpen={isOpen}
        value={driverInstructionLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Fahrerunterweisung' onClose={() => setOpen('')}>
          <div className={classes.filter_buttons_wrapper}>
            {FILTER_BUTTON_DRIVER_INSTRUCTION.map(buttonText => {
              return (
                <FilterButton
                  key={buttonText}
                  title='Fahrerunterweisung'
                  label={buttonText}
                  value={buttonText}
                  handleInput={handleInput}
                />
              );
            })}
          </div>
          <PickersContainer title='Fahrerunterweisung' value={driverInstructionLabel} handleInput={handleInput} />
        </FilterContainer>
      </Filter>
      <Filter
        title='Funktion'
        isOpen={isOpen}
        value={controlLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Funktion' onClose={toggleFilterIsOpen} isNothingFound={!filterFunction.length}>
          {filterFunction?.map(userFunction => (
            <FilterButton
              handleInput={handleInput}
              title={'Funktion'}
              label={userFunction}
              value={userFunction}
              key={userFunction}
            />
          ))}
        </FilterContainer>
      </Filter>

      <Filter
        title='Gebucht'
        isOpen={isOpen}
        value={bookedServiceLabel}
        toggleFilterIsOpen={toggleFilterIsOpen}
        clearFilter={clearFilter}
      >
        <FilterContainer header='Gebucht' onClose={toggleFilterIsOpen}>
          {filterBooked && filterBooked.includes(DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.available) ? (
            <FilterButton
              handleInput={handleInput}
              title='Gebucht'
              value={DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.available}
              label={DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.available}
            />
          ) : null}
          {filterBooked && filterBooked.includes(DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.notAvailable) ? (
            <FilterButton
              handleInput={handleInput}
              title='Gebucht'
              value={DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.notAvailable}
              label={DRIVER_LICENSE_CONTROL_FILTER_VALUES.isBooked.notAvailable}
            />
          ) : null}
        </FilterContainer>
      </Filter>

      <ClearAllFiltersButton filterState={sessionFilter.filterForClient} clearFilter={clearFilter} />
    </section>
  );
};
