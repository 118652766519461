import { connect } from 'react-redux';
import { setSnakbar, toggleModal, setEndLoading, setStartLoading } from '../../actions/app_action';
import { MODAL_NAMES, MODALS } from '../../constants';
import ConfiguratorModal from './configurator-modal-view';

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(toggleModal(MODALS.general_configurator, null));
  },
  openAlert(data) {
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, data));
  },
  closeAlert() {
    dispatch(toggleModal(MODALS.alert, null));
  },
  hideOverlay() {
    dispatch(setEndLoading());
  },
  showOverlay() {
    dispatch(setStartLoading());
  },
  setSnackbar(data) {
    dispatch(setSnakbar(data));
  }
});

const mapStateToProps = state => ({
  user: state.app.user
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguratorModal);
