import styled, { CSSProperties } from 'styled-components';

export const StyledFocusTimeBlock = styled.div<Pick<CSSProperties, 'height'>>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  background: #e6f7ff;
  transform: translateY(-50%);
  color: green;
`;
