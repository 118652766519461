import React from 'react';
import classes from './title-row.module.scss';
import { SortingRow } from '../sorting-row/sorting-row';
import { SortingTitleEnum } from './title-row.props';
import { SortingDocumentsTitle } from './components/title/title';

export const SortingDocumentsTitleRow = () => {
  return (
    <div className={classes.container}>
      <SortingRow
        hideColumnDivider
        name={<SortingDocumentsTitle title={SortingTitleEnum.Name} />}
        size={<SortingDocumentsTitle title={SortingTitleEnum.Size} />}
        createdOn={<SortingDocumentsTitle title={SortingTitleEnum.CreatedOn} />}
        createdBy={<SortingDocumentsTitle title={SortingTitleEnum.CreatedBy} />}
      />
    </div>
  );
};
