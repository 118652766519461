import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ArchiveIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.90332 9.36927V18.9499H18.9033V9.36927L17.4587 3.5623L14.1966 3.44261V1H5.62184V3.44261L2.34274 3.5623L0.90332 9.36927Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && <path d='M4.67749 5.64514V3.03229H15.1291V5.64514' fill={fill} />}
      <path
        d='M4.67749 3.90326V3.03229H15.1291V3.90326'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54858 1.58065V1H14.2583V1.58065'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.90332 19V9.41933H2.64526C2.96461 9.41933 3.2259 9.68062 3.2259 9.99997V15.2258H6.41945C6.7388 15.2258 7.00009 15.4871 7.00009 15.8064V16.0967C7.00009 16.4161 7.26138 16.6774 7.58074 16.6774H12.2259C12.5453 16.6774 12.8065 16.4161 12.8065 16.0967V15.8064C12.8065 15.4871 13.0678 15.2258 13.3872 15.2258H16.5807V9.99997C16.5807 9.68062 16.842 9.41933 17.1614 9.41933H18.9033V19H0.90332Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.35493 3.61292L0.90332 9.41937'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.9033 9.41937L17.4517 3.61292'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.52832 9.43745H12.861'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.52832 12.3891H9.69464'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.51611 7.67741V5.64516H16.2903V7.67741'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
