import React, { useEffect, useMemo, useState } from 'react';
import classes from './download-documents-component.module.scss';
import ExcelFileIcon from '../../../icons/excel-file-icon';
import PictureFileIcon from '../../../icons/picture-file-icon';
import PdfFileIcon from '../../../icons/pdf-file-icon';
import TextFileIcon from '../../../icons/text-file-icon';
import useModal from 'hooks/use-modal';
import { MODAL_NAMES, MODALS } from '../../../../constants';
import sharepointService from 'services/sharepoint-service';
import Spinner from 'components/spinner';
import { useTheme } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { DownloadIcon } from 'components/icons-new/download';

export const getIcon = fileName => {
  const extensionArr = fileName.split('.');
  const extension = extensionArr[extensionArr.length - 1];
  switch (extension.toLocaleLowerCase()) {
    case 'xls':
    case 'xlsx':
      return <ExcelFileIcon />;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return <PictureFileIcon />;
    case 'pdf':
      return <PdfFileIcon />;
    default:
      return <TextFileIcon />;
  }
};

export const File = ({ file, allFiles, isWithoutViewer = false, isDownloadIcon = false, isWithoutSize = false }) => {
  const [showFileViewer, hideFileViewer] = useModal(MODALS.file_viewer, MODAL_NAMES.file_viewer, {
    file,
    allFiles
  });
  const fileName = file.name;

  const icon = useMemo(() => getIcon(fileName), [fileName]);

  const [isDownloading, setIsDownloading] = useState(false);

  const theme = useTheme();

  const openFileViewer = () => {
    showFileViewer();
  };

  const onDownload = () => {
    setIsDownloading(true);
    sharepointService
      .downloadFileById(file.fileId)
      .then(({ data }) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data]));
        a.download = fileName;
        a.click();
        setIsDownloading(false);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    return () => {
      hideFileViewer();
    };
  }, []);

  return (
    <div className={classes.file} onClick={isWithoutViewer ? onDownload : openFileViewer}>
      <div className='d-flex'>
        <div className={classes.icon_container}>
          <div className={classes.icon}>{icon}</div>
          <div className={classes.description}>
            <span className={classes.file_name}>{fileName}</span>
            {!isWithoutSize && (
              <span className={classes.file_size}>{`${(Number(file.size) / 1024).toFixed(1)} KB`}</span>
            )}
          </div>
        </div>
      </div>
      {isDownloadIcon && (
        <>
          {isDownloading ? (
            <Spinner
              style={{
                width: '20px'
              }}
            />
          ) : (
            <Flex>
              <DownloadIcon id={'downloadIcon'} fill={theme.colors.darkBlue} />
            </Flex>
          )}
        </>
      )}
    </div>
  );
};
