import { AxiosRequestConfig } from 'axios';
import { GetFilesByDocumentTypeProps, UploadFilesByDocumentTypeProps } from './sharepoint-documents.props';

class SharePointDocumentsRequestConfig {
  private RESOURCE = 'sharepointdocuments';

  getFilesByDocumentType = (data: GetFilesByDocumentTypeProps): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/getFilesByDocumentType`,
    data
  });

  uploadFilesByDocumentType = ({
    files,
    entityId,
    documentTypeId
  }: UploadFilesByDocumentTypeProps): AxiosRequestConfig => {
    const data = new FormData();

    files.forEach(file => {
      data.append('Files', file, file.name);
    });
    data.append('entityId', entityId);
    data.append('documentTypeId', documentTypeId);

    return {
      method: 'POST',
      url: `${this.RESOURCE}/uploadFilesByDocumentType`,
      data
    };
  };
}

export const sharePointDocumentsRequestConfig = new SharePointDocumentsRequestConfig();
