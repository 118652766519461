import { IconProps } from 'common/interfaces';
import React from 'react';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const WrenchIcon = ({ width = 25, height = 25, color: colorProp, hovered }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M19.6435 14.0915C19.7306 13.8737 19.9484 13.7358 20.1807 13.7358H20.5872C20.9065 13.7358 21.1679 13.4745 21.1679 13.1551V10.8322C21.1679 10.5129 20.9065 10.2515 20.5872 10.2515H20.1807C19.9484 10.2515 19.7306 10.1136 19.6435 9.89586L18.9394 8.1973C18.8523 7.97954 18.8959 7.73274 19.0628 7.56579L19.3532 7.27544C19.5782 7.05041 19.5782 6.68021 19.3532 6.45519L17.7127 4.8147C17.4876 4.58968 17.1175 4.58968 16.8924 4.8147L16.6021 5.10505C16.4351 5.27201 16.1883 5.32282 15.9705 5.22846L14.272 4.52435C14.0542 4.43724 13.9163 4.21948 13.9163 3.9872V3.5807C13.9163 3.26132 13.655 3 13.3356 3H11.0128C10.6934 3 10.4321 3.26132 10.4321 3.5807V3.9872C10.4321 4.21948 10.2942 4.43724 10.0764 4.52435L8.37785 5.22846C8.16009 5.31556 7.91329 5.27201 7.74634 5.10505L7.45599 4.8147C7.23096 4.58968 6.86076 4.58968 6.63574 4.8147L4.99524 6.45519C4.77022 6.68021 4.77022 7.05041 4.99524 7.27544L5.28559 7.56579C5.45255 7.73274 5.50336 7.97954 5.40899 8.1973L4.70489 9.89586C4.61778 10.1136 4.40003 10.2515 4.16775 10.2515H3.76125C3.44186 10.2515 3.18054 10.5129 3.18054 10.8322V13.1551C3.18054 13.4745 3.44186 13.7358 3.76125 13.7358H4.16775C4.40003 13.7358 4.61778 13.8737 4.70489 14.0915L5.40899 15.79C5.4961 16.0078 5.45255 16.2546 5.28559 16.4215L4.99524 16.7119C4.77022 16.9369 4.77022 17.3071 4.99524 17.5321L6.63574 19.1726C6.86076 19.3976 7.23096 19.3976 7.45599 19.1726L7.74634 18.8823C7.91329 18.7153 8.16009 18.6645 8.37785 18.7589L10.0764 19.463C10.2942 19.5501 10.4321 19.7678 10.4321 20.0001V20.4066C10.4321 20.726 10.6934 20.9873 11.0128 20.9873H13.3356C13.655 20.9873 13.9163 20.726 13.9163 20.4066V20.0001C13.9163 19.7678 14.0542 19.5501 14.272 19.463'
          fill='#E6F7FF'
        />
      )}
      <path
        d='M19.6435 14.0915C19.7306 13.8737 19.9484 13.7358 20.1807 13.7358H20.5872C20.9065 13.7358 21.1679 13.4745 21.1679 13.1551V10.8322C21.1679 10.5129 20.9065 10.2515 20.5872 10.2515H20.1807C19.9484 10.2515 19.7306 10.1136 19.6435 9.89586L18.9394 8.1973C18.8523 7.97954 18.8959 7.73274 19.0628 7.56579L19.3532 7.27544C19.5782 7.05041 19.5782 6.68021 19.3532 6.45519L17.7127 4.8147C17.4876 4.58968 17.1175 4.58968 16.8924 4.8147L16.6021 5.10505C16.4351 5.27201 16.1883 5.32282 15.9705 5.22846L14.272 4.52435C14.0542 4.43724 13.9163 4.21948 13.9163 3.9872V3.5807C13.9163 3.26132 13.655 3 13.3356 3H11.0128C10.6934 3 10.4321 3.26132 10.4321 3.5807V3.9872C10.4321 4.21948 10.2942 4.43724 10.0764 4.52435L8.37785 5.22846C8.16009 5.31556 7.91329 5.27201 7.74634 5.10505L7.45599 4.8147C7.23096 4.58968 6.86076 4.58968 6.63574 4.8147L4.99524 6.45519C4.77022 6.68021 4.77022 7.05041 4.99524 7.27544L5.28559 7.56579C5.45255 7.73274 5.50336 7.97954 5.40899 8.1973L4.70489 9.89586C4.61778 10.1136 4.40003 10.2515 4.16775 10.2515H3.76125C3.44186 10.2515 3.18054 10.5129 3.18054 10.8322V13.1551C3.18054 13.4745 3.44186 13.7358 3.76125 13.7358H4.16775C4.40003 13.7358 4.61778 13.8737 4.70489 14.0915L5.40899 15.79C5.4961 16.0078 5.45255 16.2546 5.28559 16.4215L4.99524 16.7119C4.77022 16.9369 4.77022 17.3071 4.99524 17.5321L6.63574 19.1726C6.86076 19.3976 7.23096 19.3976 7.45599 19.1726L7.74634 18.8823C7.91329 18.7153 8.16009 18.6645 8.37785 18.7589L10.0764 19.463C10.2942 19.5501 10.4321 19.7678 10.4321 20.0001V20.4066C10.4321 20.726 10.6934 20.9873 11.0128 20.9873H13.3356C13.655 20.9873 13.9163 20.726 13.9163 20.4066V20.0001C13.9163 19.7678 14.0542 19.5501 14.272 19.463'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <>
          <path
            d='M14.5043 16.3344C12.6388 17.3288 10.2652 17.0457 8.69003 15.4706C7.35441 14.135 6.94792 12.2332 7.45603 10.5418L10.0329 13.155C10.6789 13.155 10.933 13.155 11.5863 13.155L13.3284 11.4129C13.3284 10.7886 13.3284 10.4983 13.3284 9.87404L10.7443 7.26087C12.4283 6.75276 14.3374 7.15925 15.673 8.49487C17.2482 10.07 17.5385 12.4437 16.5368 14.3092'
            fill='white'
          />
          <path
            d='M16.8996 13.4381L20.4855 17.0312C21.3928 17.9386 21.3928 19.4121 20.4855 20.3194C19.5781 21.2268 18.1046 21.2268 17.1973 20.3194L13.5996 16.7063'
            fill='white'
          />
        </>
      )}
      <path
        d='M14.5043 16.3344C12.6388 17.3288 10.2652 17.0457 8.69003 15.4706C7.35441 14.135 6.94792 12.2332 7.45603 10.5418L10.0329 13.155C10.6789 13.155 10.933 13.155 11.5863 13.155L13.3284 11.4129C13.3284 10.7886 13.3284 10.4983 13.3284 9.87404L10.7443 7.26087C12.4283 6.75276 14.3374 7.15925 15.673 8.49487C17.2482 10.07 17.5385 12.4437 16.5368 14.3092M16.8996 13.4381L20.4855 17.0312C21.3928 17.9386 21.3928 19.4121 20.4855 20.3194C19.5781 21.2268 18.1046 21.2268 17.1973 20.3194L13.5996 16.7063'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.6741 19.251C19.0883 19.251 19.4241 18.9152 19.4241 18.501C19.4241 18.0868 19.0883 17.751 18.6741 17.751C18.2599 17.751 17.9241 18.0868 17.9241 18.501C17.9241 18.9152 18.2599 19.251 18.6741 19.251Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
