import styled from 'styled-components';

export const StyledCoverImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: #e8f5fe;
  display: flex;
  margin-bottom: 20px;
`;

export const StyledLoadingContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  z-index: 2;
  display: flex;
  justify-content: center;
`;
