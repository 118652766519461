import { useEffect } from 'react';
import moment from 'moment';
import { UseFormReturn } from 'react-hook-form';
import { errorMessages } from 'utils/error-messages';

export const useValidateLeasEndField = (reactHookFormData: UseFormReturn) => {
  const { watch, setError, clearErrors } = reactHookFormData;

  useEffect(() => {
    if (watch('uds_gultigkeitvon') && watch('uds_gultigkeitbis')) {
      if (moment(watch('uds_gultigkeitvon')).isSameOrAfter(watch('uds_gultigkeitbis'))) {
        setTimeout(() => {
          setError('uds_gultigkeitbis', {
            type: 'manual',
            message: errorMessages.selectedDateNotEarlierThanFieldDate('Leasingstart')
          });
        });
      } else {
        clearErrors('uds_gultigkeitbis');
      }
    }
  }, [watch('uds_gultigkeitvon'), watch('uds_gultigkeitbis')]);
};
