import React from 'react';
import {
  LeasingWidgetContainer,
  LeasingWidgetContentContainer,
  StatusLabel,
  StyledBulletPoint,
  StyledBulletPointsList,
  StyledStatusTextWrapper
} from './leasing-widget.styled';
import { Flex } from 'common/common-components.styled';
import { range } from 'lodash';
import { LeasingWidgetProps } from './leasing-widget.types';

const LeasinggWidget: React.FC<LeasingWidgetProps> = ({
  image,
  children,
  buttons,
  isStatusHidden,
  statusLabel,
  statusColor,
  showBulletPoints,
  bulletPoinstController,
  id
}) => {
  const arrayOfBulletPointsAmmount = range(0, bulletPoinstController?.amountOfButtons);

  return (
    <LeasingWidgetContainer id={id}>
      <Flex align='center' justify={!isStatusHidden ? 'space-between' : 'flex-end'}>
        <StyledStatusTextWrapper isStatusHidden={isStatusHidden}>
          {statusLabel && (
            <StatusLabel id={'status'} statusColor={statusColor}>
              {statusLabel}
            </StatusLabel>
          )}
        </StyledStatusTextWrapper>
        {showBulletPoints && (
          <Flex justify='flex-end' bottom='5'>
            <StyledBulletPointsList>
              {arrayOfBulletPointsAmmount.map((bulletPoint, index) => (
                <StyledBulletPoint
                  isActive={bulletPoint === bulletPoinstController?.active}
                  onClick={() => bulletPoinstController?.onChange(index)}
                />
              ))}
            </StyledBulletPointsList>
          </Flex>
        )}
      </Flex>
      <LeasingWidgetContentContainer>
        <Flex>{image}</Flex>
        <Flex direction='column' width='100%'>
          {children}
        </Flex>
      </LeasingWidgetContentContainer>

      {buttons}
    </LeasingWidgetContainer>
  );
};

export default LeasinggWidget;
