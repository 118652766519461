import React, { cloneElement, useRef, useState } from 'react';
import { MenuItemProps } from 'components/side-menu/menu-item/menu-item.props';
import { Distance, Text } from 'common/common-components.styled';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { DefaultTheme, useTheme } from 'styled-components';
import {
  StyledExpandItem,
  StyledIconContainer,
  StyledInfoChip,
  StyledMenuItemContainer,
  StyledSubtitle
} from 'components/side-menu/menu-item/menu-item.styled';
import { Popover } from '@mui/material';
import { User } from 'common/interfaces';
import { userSelector } from 'selectors';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import SubMenuItemComponent from 'components/side-menu/sub-menu-item';
import { SubMenuItem } from 'components/side-menu/side-menu-interfaces';
import IconHover from 'components/icon-hover';

const MenuItem = ({ item, toggleExpandItem, isMinimized }: MenuItemProps) => {
  const dashboardTourIsStarted: boolean = useTypedSelector(state => state.app.dashboardTourIsStarted);
  const theme = useTheme();
  const user: User = useTypedSelector(userSelector);
  const isSiteAvailable: boolean = useTypedSelector(state => state.app.isSiteAvailable);

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const mainRoleId = user.mainRole?.id;

  const [open, setOpen] = useState(false);
  const menuItem = useRef<HTMLDivElement | null>(null);
  const popover = useRef<HTMLDivElement | null>(null);

  const onMouseEnter = () => {
    if (isMinimized && item.subMenuItems.length) {
      openPopover();
    }
  };

  const openPopover = () => {
    setOpen(true);
  };

  const closePopover = () => {
    setOpen(false);
  };

  const isMenuSubItemVisible = ({ roles, showWhenSiteUnavailable }: SubMenuItem) => {
    if (!isSiteAvailable) {
      return !isDriver && showWhenSiteUnavailable;
    }

    return roles.some(role => role.id === mainRoleId);
  };

  return (
    <StyledMenuItemContainer open={item.expand || open}>
      <StyledSubtitle
        ref={menuItem}
        onClick={() => toggleExpandItem(item.menuTitle)}
        id={item.menuTitle.replace(/\s/g, '') + 'MenuTitle'}
        open={item.expand || open}
        dashboardTourIsStarted={dashboardTourIsStarted}
        onMouseEnter={onMouseEnter}
        onMouseLeave={closePopover}
      >
        <StyledIconContainer align='center'>
          <IconHover height='25px'>
            {cloneElement(item.mainMenuImg, {
              width: 25,
              height: 25,
              color: theme.colors.grey600
            })}
          </IconHover>
        </StyledIconContainer>

        {!isMinimized && (
          <>
            <Distance side='20' />
            <span>{item.menuTitle}</span>
          </>
        )}
      </StyledSubtitle>
      {isMinimized && !item.expand ? (
        <Popover
          ref={popover}
          sx={{
            pointerEvents: 'none'
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={open}
          anchorEl={menuItem.current}
          onClose={closePopover}
          PaperProps={{
            ...getMenuItemsPaperProps(theme, openPopover, closePopover)
          }}
        >
          <div>
            <Text bold color='grey600' uppercase padding='15px 23px'>
              {item.menuTitle}
            </Text>
            {item.subMenuItems.filter(isMenuSubItemVisible).map(subMenuItem => (
              <SubMenuItemComponent
                key={subMenuItem.title}
                subMenuItem={subMenuItem}
                closePopover={() => setOpen(false)}
                showMenuTitle={true}
              />
            ))}
          </div>
        </Popover>
      ) : (
        <StyledExpandItem open={item.expand || open}>
          {item.subMenuItems.filter(isMenuSubItemVisible).map(subMenuItem => (
            <SubMenuItemComponent
              key={subMenuItem.title}
              subMenuItem={subMenuItem}
              closePopover={() => {}}
              showMenuTitle={!isMinimized}
            />
          ))}
        </StyledExpandItem>
      )}
    </StyledMenuItemContainer>
  );
};

export const getMenuItemsPaperProps = (theme: DefaultTheme, onMouseEnter: () => void, onMouseLeave: () => void) => ({
  elevation: 0,
  onMouseEnter: onMouseEnter,
  onMouseLeave: onMouseLeave,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: -3.7,
    bgcolor: theme.colors.grey50,
    pointerEvents: 'auto',
    minWidth: '270px'
  }
});

export default MenuItem;
