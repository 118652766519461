import { FileFormatTypes } from 'common/enums';
import { AnyObject } from 'common/interfaces';
import { useMemo, useState } from 'react';

export const useLetterModal = (allFiles: AnyObject[]) => {
  const [isLetterModalOpened, setIsLetterModalOpened] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState({ fileId: '', filePath: '' });
  const [selectedLetterIndex, setSelectedLetterIndex] = useState(0);
  const allLetters = useMemo(() => {
    return allFiles
      ?.map(file => {
        return {
          fileId: file.fileId,
          filePath: file.filePath
        };
      })
      .filter(file => {
        const fileNameArr = file.filePath.split('.');
        const fileFormat = fileNameArr[fileNameArr.length - 1] as FileFormatTypes;
        const isEmail = [FileFormatTypes.MSG, FileFormatTypes.EML].includes(fileFormat);

        if (isEmail) {
          return file;
        }
      });
  }, [allFiles]);

  const showLetterModal = (fileId: string) => {
    const selectedLetterIndex = allLetters.findIndex(file => file.fileId === fileId);

    setSelectedLetterIndex(selectedLetterIndex);
    setIsLetterModalOpened(true);
    setSelectedLetter(allLetters[selectedLetterIndex]);
  };

  const handleClose = () => {
    setSelectedLetterIndex(0);
    setIsLetterModalOpened(false);
    setSelectedLetter({
      fileId: '',
      filePath: ''
    });
  };

  const handleSwitchLetter = (switchToNextFile: boolean) => {
    const addedIndex = switchToNextFile ? 1 : -1;
    const newFile = allLetters[selectedLetterIndex + addedIndex];

    setSelectedLetterIndex(prev => prev + addedIndex);
    setSelectedLetter({
      fileId: newFile.fileId,
      filePath: newFile.filePath
    });
  };

  return {
    isLetterModalOpened,
    selectedLetter,
    allLetters,
    selectedLetterIndex,
    showLetterModal,
    handleClose,
    handleSwitchLetter
  };
};
