import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { GetFieldsCallback, IFormFieldContext } from './form.props';
import { UseFormReturn } from 'react-hook-form';

export const defaultFormField: FormFieldModel = {
  name: '',
  type: FormFieldTypes.Text,
  defaultValue: '',
  validation: {}
};

export const defaultFormFieldProviderState: IFormFieldContext = {
  field: defaultFormField,
  isShowAddNewItemButton: false,
  returnToInitialFormField: () => {},
  error: {},
  setError: () => {},
  clearErrors: () => {},
  onChange: () => {},
  forceUpdate: () => {},
  value: '',
  reactHookFormData: {} as UseFormReturn
};

export const getFields = (formFields: FormFieldModel[], callback: GetFieldsCallback) => {
  formFields.map(formField => {
    callback(formField);
  });
};

export const DATE_ERROR_MESSAGE = 'Bitte gib ein korrektes Datum an.';
