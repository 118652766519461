import React from 'react';
import { FormFieldModel } from 'common/interfaces';
import { FormFieldTypes } from 'common/enums';
import { ChangeCostCenterFieldNames } from '../change-cost-center/change-cost-center.types';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import CustomPickerDayTooltip from 'components/cost-centers-settings/custom-picker-day-tooltip';

export const changeCostCenterFormFields: FormFieldModel<ChangeCostCenterFieldNames>[] = [
  {
    name: ChangeCostCenterFieldNames.ValidFrom,
    type: FormFieldTypes.Date,
    defaultValue: null,
    label: 'Neue Kostenstelle gültig ab:',
    renderDay: (day: unknown, _value: unknown[], DayComponentProps: PickersDayProps<unknown>) => (
      <CustomPickerDayTooltip DayComponentProps={DayComponentProps} tooltipText={TOOLTIP_TEXT} />
    ),
    validation: {
      required: 'Bitte prüfe die Eingabe.'
    }
  },
  {
    name: ChangeCostCenterFieldNames.ValidTo,
    type: FormFieldTypes.Date,
    defaultValue: null,
    label: 'Neue Kostenstelle gültig bis (Optional):',
    renderDay: (day: unknown, _value: unknown[], DayComponentProps: PickersDayProps<unknown>) => (
      <CustomPickerDayTooltip DayComponentProps={DayComponentProps} tooltipText={TOOLTIP_TEXT} />
    ),
    validation: {}
  }
];

const TOOLTIP_TEXT = 'Kostenstellen Historien dürfen sich nicht überschneiden. Bitte wähle einen anderen Wert';
