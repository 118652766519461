import { PAGES, ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { FilterDataParams } from 'request-config/fleet/fleet.interface';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { GetFleetFilterResponse } from './use-get-fleet-filter-data.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useGetFleetFilterData = (
  props: FilterDataParams,
  options: QueryHookOptions<
    AxiosResponse<ResponseModel<Partial<GetFleetFilterResponse>>>,
    ResponseModel<Partial<GetFleetFilterResponse>>
  > = {}
) => {
  const { fetch } = useFetch<ResponseModel<Partial<GetFleetFilterResponse>>>({
    loadingKey: `fleetFilter${JSON.stringify(props)}`,
    isShowLoading: false,
    ...fleetRequestConfig.getFilterData(props),
    ...options.fetch
  });

  const { data, ...restQueryProps } = useQuery(
    [PAGES.MY_VEHICLES, ReactQueryKeys.Filter, props.columnName, props.searchValue],
    () => fetch(),
    options.options
  );

  return { filterData: data?.data.data || {}, ...restQueryProps };
};
