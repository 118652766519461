import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.5 1.48154L4.53333 4.5882L1.5 1.41173'
        stroke='#5F6466'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowDownIcon;
