import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DashboardIcon = ({ width = 19, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;
  return (
    <svg width={width} height={height} viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M7.68216 1H2.06677C1.51449 1 1.06677 1.44771 1.06677 2V3.19291V9C1.06677 9.55228 1.51449 10 2.06677 10H7.68216C8.23444 10 8.68216 9.55229 8.68216 9V2C8.68216 1.44772 8.23444 1 7.68216 1Z'
          fill={fill}
        />
      )}
      <path
        d='M1.06677 3.19291V1.8C1.06677 1.35817 1.42494 1 1.86677 1H7.88216C8.32399 1 8.68216 1.35817 8.68216 1.8V9.2C8.68216 9.64183 8.32399 10 7.88216 10H1.86677C1.42494 10 1.06677 9.64183 1.06677 9.2V5.95498'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M17.0299 10H11.4146C10.8623 10 10.4146 10.4477 10.4146 11V18C10.4146 18.5523 10.8623 19 11.4146 19H17.0299C17.5822 19 18.0299 18.5523 18.0299 18V11.463V11C18.0299 10.4477 17.5822 10 17.0299 10Z'
          fill={fill}
        />
      )}
      <path
        d='M18.0299 14.247V18.2C18.0299 18.6418 17.6718 19 17.2299 19H11.2146C10.7727 19 10.4146 18.6418 10.4146 18.2V10.8C10.4146 10.3582 10.7727 10 11.2146 10H17.2299C17.6718 10 18.0299 10.3582 18.0299 10.8V11.463'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='10.4146'
        y='1'
        width='7.61539'
        height='6.92308'
        rx='0.8'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
      <rect
        x='1.06677'
        y='12.0769'
        width='7.61539'
        height='6.92308'
        rx='0.8'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeLinejoin='round'
      />
    </svg>
  );
};
