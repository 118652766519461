import React from 'react';
import { MODALS, MODAL_NAMES } from '../../../constants';
import { toggleModal } from 'actions/app_action';
import { useOverlay } from 'hooks';
import { useDispatch } from 'react-redux';
import { updateDriver } from 'services/drivers-check-service';
import { EnterEmailFormValuesProps } from '../email.modal';
import { useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import { ISubscribeAlertOptionParams } from './driver-check.props';

export const useUnsubscribeDriverLicenseCheckModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [showOverlay, hideOverlay] = useOverlay();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const getAlertData = (contactId: string, optionalParams?: ISubscribeAlertOptionParams) => ({
    title: 'Führerscheinkontrolle abbestellen',
    children: (
      <span>
        Die Führerscheinkontrolle wird für diesen Fahrer abbestellt und wird ab dem nächsten Monat nicht mehr
        abgerechnet.
      </span>
    ),
    buttons: [
      {
        type: 'cancel',
        title: 'Abbrechen',
        action: closeAlert
      },
      {
        type: 'submit',
        title: 'Abbestellen',
        action: async () => {
          showOverlay();
          closeAlert();

          const submitHandler = async (emailFormValues?: EnterEmailFormValuesProps) => {
            await updateDriver(contactId, false);
            optionalParams?.callback && optionalParams?.callback(emailFormValues);

            queryClient.resetQueries([ReactQueryKeys.AccountIsBooked]);
            queryClient.resetQueries([PAGES.DRIVERS_CHECK]);
            queryClient.resetQueries([PAGES.DRIVERS_CHECK, ReactQueryKeys.Filter]);
          };

          try {
            await submitHandler();
          } catch (err) {
            console.error(err);
          } finally {
            hideOverlay();
          }
        }
      }
    ]
  });

  return {
    openModal: (contactId: string, optionalParams?: ISubscribeAlertOptionParams) =>
      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, getAlertData(contactId, optionalParams)))
  };
};
