import styled from 'styled-components';
import { Text } from 'common/common-components.styled';

export const StyledDealerPageFilesContainer = styled.div`
   min-height: 35px;
   position: relative;
`;

export const StyledNoFilesText = styled(Text)`
   text-align: center;
`;
