import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledFocusedSegmentContainer = styled(Flex)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 5px;
`;
