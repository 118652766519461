import { getLeasinggesellschaften } from 'services/company-service';
import { RequestStatuses } from '../../../constants';
import { useQuery } from 'react-query';
import {
  LeasingCompanyWithContacts,
  LeasingCompanyResponse
} from 'components/global-reference-vehicles/action-switcher/leasing-price-dialog/choose-leasing-company/choose-leasing-company.props';
import { ApiResponse } from 'common/interfaces';

export const useLeasingCompanies = (companyId?: string) => {
  const modifyResponse = ({ data }: ApiResponse<LeasingCompanyResponse[]>) => {
    if (data.level === RequestStatuses.Success) {
      return data.data.map(item => item.attributes);
    }
    return [];
  };

  const { data: leasingCompanies, isFetching: isLeasingCompaniesFetching } = useQuery<
    ApiResponse<LeasingCompanyResponse[]>,
    unknown,
    LeasingCompanyWithContacts[]
  >(['leasing-companies-global-reference', companyId], () => getLeasinggesellschaften(companyId), {
    select: modifyResponse,
    enabled: !!companyId
  });

  return {
    leasingCompanies,
    isLeasingCompaniesFetching
  };
};
