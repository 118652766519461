import { AxiosRequestConfig } from 'axios';
import { UpdateCostCenterDataToSend } from './cost-centers.types';

class CostCentersRequestConfig {
  updateCostCenter = (data: UpdateCostCenterDataToSend): AxiosRequestConfig => ({
    method: 'POST',
    url: 'kostenstelle/updateKostenstelle',
    data
  });

  deactivateCostCenter = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'kostenstelle/deactivate',
    data: {
      id
    }
  });
}

export const costCentersRequestConfig = new CostCentersRequestConfig();
