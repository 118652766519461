import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setForm } from 'actions/app_action';
import { RequestStatuses } from '../../../constants';
import { AxiosResponse } from 'axios';
import { ResponseModel } from 'common/interfaces';
import { useOverlay } from 'hooks';
import useRequestSnackbar from 'hooks/use-request-snackbar';
import { newKostenstelleFormFields } from 'models/forms/fahrzeug-form-model';
import Form from 'components/form/form-redux';
import { getFormValues } from 'utils/get-form-values';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { AnyObject } from 'chart.js/dist/types/basic';
import useLatestValue from 'hooks/use-latest-value';
import { primaryEntityFormFields } from 'components/cost-centers-settings/form-fields/cost-centers-primary-entity-form-fields';
import { useIsParentCompany } from 'hooks/react-query/use-is-parent-company';
import { getFields } from 'components/form/form-view';
import PrimaryEntityModal from 'components/cost-centers-settings/primary-entity-modal';
import { getResponseFormData } from 'utils/get-response-data';
import { changeMainEntity, validateMainEntity, createKostenstelle } from 'services/cost-centers-service';
import {
  CostCenterDataItem,
  CostCenterFormFieldsNames,
  PrimaryEntityValues,
  ValidateMainEntityItem,
  ValidateMainEntityResponse
} from 'components/cost-centers-settings/cost-centers-settings.props';
import PrimaryEntitySecondModal from 'components/cost-centers-settings/primary-entity-second-modal';
import { useQueryClient } from 'react-query';
import { PAGES, ReactQueryKeys } from 'common/enums';
import useAlert from 'hooks/use-alert';
import { AlertData } from 'components/alert/alert-view';
import { Text } from 'common/common-components.styled';
import EditCostCenter from 'components/cost-centers-settings/edit-cost-center';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { costCentersRequestConfig } from 'request-config/cost-centers/cost-centers.request-config';

export const useCostCenterModals = (companyIdParam?: string, disableChanging?: boolean) => {
  const forms = useTypedSelector(state => state['app'].forms);
  const user = useTypedSelector(state => state['app'].user);
  const formsLatest = useLatestValue(forms);
  const dispatch = useDispatch();
  const { openAlert, closeAlert } = useAlert();
  const { fetch } = useFetch({ loadingKey: 'deactivateCostCenter', isShowSuccessSnackbar: true });

  const companyId = companyIdParam || user.companyId;

  const { showRequestSnackbar } = useRequestSnackbar();
  const { isChildCompany } = useIsParentCompany(companyId);
  const [showOverlay, hideOverlay] = useOverlay();

  const [costCentersFields, setCostCentersFields] = useState(newKostenstelleFormFields);

  const queryClient = useQueryClient();

  const refetchCostCenters = () => {
    queryClient.resetQueries([PAGES.ACCOUNTING_SETTINGS_COST_CENTERS, 'cost-centers-data']);
    queryClient.resetQueries([ReactQueryKeys.GetCostCenterList]);
    queryClient.resetQueries([PAGES.ACCOUNTING_SETTINGS_COST_CENTERS, 'cost-centers-filter-data']);
  };

  const refetchCostCenterPrimaryEntity = () => {
    queryClient.resetQueries([PAGES.COMPANIES_BUSINESS_DETAILS, 'cost-centers-main-entity']);
  };

  useEffect(() => {
    if (isChildCompany !== undefined) {
      getFields(costCentersFields, (field: AnyObject) => {
        if (field.name === 'new_sparteid') {
          field.disabled = isChildCompany;
          field.defaultValue = isChildCompany ? companyId : '';
        }
      });

      setCostCentersFields(costCentersFields);
    }
  }, [isChildCompany]);

  const openCreateCostCenterModal = () => {
    const alertData: AlertData = {
      title: 'Neue Kostenstelle anlegen',
      children: <Form name='newCostCenter' formFields={costCentersFields} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Speichern',
          action: createCostCenter
        }
      ]
    };

    openAlert(alertData);
  };

  const openChangePrimaryEntityFirstModal = (fieldName: string, newValue: string) => {
    const alertData: AlertData = {
      title: 'Primäre Zuordnung ändern',
      children: (
        <PrimaryEntityModal
          companyId={companyId}
          validatePrimaryEntity={isChangeChildCompaniesPrimaryEntity =>
            validatePrimaryEntity(newValue as PrimaryEntityValues, isChangeChildCompaniesPrimaryEntity)
          }
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, abbrechen',
          action: closeAlert
        }
      ]
    };

    openAlert(alertData);
  };

  const changePrimaryEntity = async (
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    showOverlay();
    closeAlert();
    let response: AxiosResponse<ResponseModel<null>> | null = null;

    try {
      response = await changeMainEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity, companyId);
    } catch (e) {
      console.log(e);
    } finally {
      hideOverlay();
    }

    showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {
      refetchCostCenterPrimaryEntity();
      refetchCostCenters();
      queryClient.clear();
    });
  };

  const validatePrimaryEntity = async (
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    showOverlay();
    closeAlert();
    let response: AxiosResponse<ResponseModel<ValidateMainEntityResponse>> | null = null;

    try {
      response = await validateMainEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity, companyId);
    } catch (e) {
      console.log(e);
    } finally {
      hideOverlay();
    }

    if (response?.data.level !== RequestStatuses.Success) {
      showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {});
      return;
    }

    const records = getResponseFormData(response.data, [
      { name: 'records', type: 'array' }
    ]) as ValidateMainEntityItem[];
    if (records.length) {
      openChangePrimaryEntitySecondModal(records, newPrimaryEntity, isChangeChildCompaniesPrimaryEntity);
    } else {
      changePrimaryEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity);
    }
  };

  const primaryEntityFields = useMemo(() => {
    const primaryEntityField = primaryEntityFormFields.find(
      field => field.name === CostCenterFormFieldsNames.PrimaryEntity
    );
    if (primaryEntityField) {
      primaryEntityField.radioButtonsOnChange = openChangePrimaryEntityFirstModal;
      primaryEntityField.disabled = !!disableChanging;
    }

    return primaryEntityFormFields;
  }, [disableChanging]);

  const openChangePrimaryEntitySecondModal = (
    records: ValidateMainEntityItem[],
    newPrimaryEntity: PrimaryEntityValues,
    isChangeChildCompaniesPrimaryEntity: boolean
  ) => {
    const alertData: AlertData = {
      title: 'Zuordnung ändern',
      children: (
        <PrimaryEntitySecondModal
          changePrimaryEntity={() => changePrimaryEntity(newPrimaryEntity, isChangeChildCompaniesPrimaryEntity)}
          records={records}
          newPrimaryEntity={newPrimaryEntity}
        />
      ),
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Nein, abbrechen',
          action: closeAlert
        }
      ]
    };

    openAlert(alertData);
  };

  const createCostCenter = async () => {
    getFormValues(
      formsLatest.current,
      (form: AnyObject) => {
        dispatch(setForm(form));
      },
      async (values: AnyObject) => {
        showOverlay();
        closeAlert();
        let response: AxiosResponse<ResponseModel> | null = null;

        const dataToSend = {
          kostenstelle: {
            attributes: {
              new_name: values.name,
              new_firmaid: {
                id: values.new_sparteid,
                logicalName: 'account',
                name: ''
              }
            }
          }
        };
        try {
          response = await createKostenstelle(dataToSend);
        } catch (e) {
          console.log(e);
        } finally {
          hideOverlay();
        }

        showRequestSnackbar(response?.data.level === RequestStatuses.Success, () => {
          refetchCostCenters();
        });
      }
    );
  };

  const deactivateCostCenter = (costCenterItem: CostCenterDataItem) => {
    closeAlert();
    fetch({
      requestConfig: costCentersRequestConfig.deactivateCostCenter(costCenterItem.new_kostenstelleid),
      callback: refetchCostCenters
    });
  };

  const openDeactivateCostCenterModal = (costCenterItem: CostCenterDataItem) => {
    const alertData: AlertData = {
      title: 'Kostenstelle deaktivieren',
      children: <Text>Möchtest Du die Kostenstelle deaktivieren?</Text>,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        },
        {
          type: 'submit',
          title: 'Deaktivieren',
          action: () => deactivateCostCenter(costCenterItem)
        }
      ]
    };

    openAlert(alertData);
  };

  const openEditCostCenterModal = (costCenterItem: CostCenterDataItem) => {
    const alertData: AlertData = {
      title: 'Kostenstelle bearbeiten',
      children: <EditCostCenter costCenterItem={costCenterItem} refetchCostCenters={refetchCostCenters} />,
      allButtonsHidden: true,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert
        }
      ]
    };

    openAlert(alertData);
  };

  return { openCreateCostCenterModal, primaryEntityFields, openDeactivateCostCenterModal, openEditCostCenterModal };
};
