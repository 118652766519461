import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ResponseModel } from 'common/interfaces';
import { vehicleDeliveryRequestConfig } from 'request-config/vehicle-delivery/vehicle-delivery.request-config';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { AxiosResponse } from 'axios';
import { RequestStatuses } from '../../../constants';
import { VehicleDelivery, VehicleDeliveryResponse } from 'hooks/react-query/vehicle-delivery/vehicle-delivery.types';
import { getResponseFormData } from 'utils/get-response-data';

const modifyResponse = ({ data }: AxiosResponse<ResponseModel<VehicleDeliveryResponse[]>>) => {
  if (data.level === RequestStatuses.Success) {
    return getResponseFormData(data, [{ name: null, type: 'array' }]) as VehicleDelivery[];
  }
  return [];
};

const useVehicleDeliveriesByVehicleOffer = (vehicleOfferId: string | null) => {
  const { fetch } = useFetch<ResponseModel<VehicleDeliveryResponse[]>>({
    loadingKey: `useVehicleDeliveries_${vehicleOfferId}`,
    isCancelRequestOnUnmount: true,
    isShowLoading: false,
    isShowErrorSnackbar: false,
    ...vehicleDeliveryRequestConfig.getVehicleDeliveryByVehicleOfferId(vehicleOfferId || '')
  });

  const { data: vehicleDeliveries } = useQuery<
    AxiosResponse<ResponseModel<VehicleDeliveryResponse[]>>,
    unknown,
    VehicleDelivery[]
  >([ReactQueryKeys.GetVehicleDeliveriesByVehicleOffer, vehicleOfferId], () => fetch(), {
    enabled: !!vehicleOfferId,
    select: modifyResponse
  });

  return { vehicleDeliveries: vehicleDeliveries || [] };
};

export default useVehicleDeliveriesByVehicleOffer;
