import { AxiosRequestConfig } from 'axios';
import { GetNotesRequestData, UpdateCreateNoteRequestData } from './notes.types';

class NotesRequestConfig {
  getNotes = (data: GetNotesRequestData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'notizen/getNotes',
    data
  });

  updateNote = (data: UpdateCreateNoteRequestData): AxiosRequestConfig => ({
    method: 'POST',
    url: 'notizen/updateNote',
    data
  });

  deleteNote = (id: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'notizen/deleteNote',
    data: { id }
  });
}

export const notesRequestConfig = new NotesRequestConfig();
