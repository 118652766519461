import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { StyledVehicleCostHeaderContainer } from './header.styled';
import { VehicleCostHeaderProps } from './header.props';

export const VehicleCostHeader = ({ idPrefix, title, description, onDescriptionClick }: VehicleCostHeaderProps) => {
  return (
    <StyledVehicleCostHeaderContainer>
      <Flex width='80%' justify='center'>
        <Text id={`${idPrefix}Title`} color='darkBlue' size='24' bold>
          {title}
        </Text>
      </Flex>

      <Text id={`${idPrefix}Description`} color='blue' underline pointer onClick={() => onDescriptionClick()}>
        {description}
      </Text>
    </StyledVehicleCostHeaderContainer>
  );
};
