import { Circle, Flex, Text } from 'common/common-components.styled';
import { IDocumentsGroupedByDescriptionItem } from 'components/download-documents-component/download-documents-component.props';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import useWindowSize from 'hooks/use-window-size';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { getFileSize } from 'utils/file/get-file-size-string';

export const FileBriefInfoMobile = ({ fileSize, lastModified, createdBy }: IDocumentsGroupedByDescriptionItem) => {
  const { windowWidth } = useWindowSize(500);
  const createdOnMoment = moment(lastModified);

  const getColumn = (node: ReactNode, isHideDivider = false) => {
    if (typeof node === 'string') {
      return (
        <>
          <EllipsisWithTooltip tooltip={node}>
            <Text color='grey500' size='10' bold>
              {node}
            </Text>
          </EllipsisWithTooltip>

          {!isHideDivider && <Circle size='3px' color='grey500' margin='auto 5px' />}
        </>
      );
    }

    return node;
  };

  if (windowWidth > 550) {
    return null;
  }

  return (
    <Flex width='100%'>
      {getColumn(getFileSize(fileSize || 0))}
      {getColumn(createdOnMoment.isValid() ? createdOnMoment.format('DD.MM.YYYY') : '-')}
      {getColumn(createdBy, true)}
    </Flex>
  );
};
