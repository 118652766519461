import { USER_ROLES } from '../../../../../../constants';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export const useIsShowDataQualityWidget = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const isAvailableForRoles = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  const isOrderedStatus = fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;

  return { isShowDataQualityWidget: isAvailableForRoles && !isOrderedStatus };
};
