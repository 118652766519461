import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarCheckedIcon = ({ width = 26, height = 22, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.374 8.51413C23.5384 8.94939 23.6255 9.41368 23.6255 9.88764V10.439V16.4167H7.75268V17.9643C7.75268 18.3899 7.40446 18.7381 6.97886 18.7381H3.88362C3.45802 18.7381 3.10981 18.3899 3.10981 17.9643V15.9524L2.80029 14.4725C2.75193 14.221 2.7229 13.9501 2.7229 13.689V9.88764C2.7229 9.42335 2.80997 8.94939 2.9744 8.51413L3.35162 7.51784H1.5622C1.31071 7.51784 1.12693 7.28569 1.18496 7.04388L1.47514 5.7961C1.5622 5.44789 1.87172 5.19641 2.22961 5.19641H4.12544L5.61504 2.22691C6.01161 1.43375 6.81444 0.940445 7.69465 0.940445H18.6538C19.534 0.940445 20.3368 1.44343 20.7334 2.22691L22.223 5.19641H24.1188C24.4767 5.19641 24.7959 5.44789 24.8732 5.7961L25.1634 7.04388C25.2215 7.28569 25.0377 7.51784 24.7862 7.51784H22.9968L23.374 8.51413Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M23.374 8.51415C23.5384 8.94942 23.6255 9.4137 23.6255 9.88766V10.439V16.4167H7.75268V17.9643C7.75268 18.3899 7.40446 18.7381 6.97886 18.7381H3.88362C3.45802 18.7381 3.10981 18.3899 3.10981 17.9643V15.9524L2.80029 14.4725C2.75193 14.221 2.7229 13.9502 2.7229 13.689V9.88766C2.7229 9.42337 2.80997 8.94942 2.9744 8.51415L3.35162 7.51786H1.5622C1.31071 7.51786 1.12693 7.28571 1.18496 7.0439L1.47514 5.79613C1.5622 5.44791 1.87172 5.19643 2.22961 5.19643H4.12544L6.28247 6.6853H20.0145L22.223 5.19643H24.1188C24.4767 5.19643 24.7959 5.44791 24.8732 5.79613L25.1634 7.0439C25.2215 7.28571 25.0377 7.51786 24.7862 7.51786H22.9968L23.374 8.51415Z'
          fill={fill}
        />
      )}
      <path
        d='M6.20505 16.4167H7.75268V17.9643C7.75268 18.3899 7.40446 18.7381 6.97886 18.7381H3.88362C3.45802 18.7381 3.10981 18.3899 3.10981 17.9643V15.9524L2.80029 14.4725C2.75193 14.221 2.7229 13.9501 2.7229 13.689V9.88764C2.7229 9.42335 2.80997 8.94939 2.9744 8.51413L3.35162 7.51784H1.5622C1.31071 7.51784 1.12693 7.28569 1.18496 7.04388L1.47514 5.7961C1.5622 5.44789 1.87172 5.19641 2.22961 5.19641H4.12544L5.61504 2.22691C6.01161 1.43375 6.81444 0.940445 7.69465 0.940445H18.6538C19.534 0.940445 20.3368 1.44343 20.7334 2.22691L22.223 5.19641H24.1188C24.4767 5.19641 24.7959 5.44789 24.8732 5.7961L25.1634 7.04388C25.2215 7.28569 25.0377 7.51784 24.7862 7.51784H22.9968L23.374 8.51413C23.5384 8.94939 23.6255 9.41368 23.6255 9.88764V10.439'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.05408 11.7738H8.27507L7.72373 10.6808'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.60181 6.74403H19.7566'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='17.9814' cy='15.6396' r='5.42' fill={hovered ? 'white' : 'none'} />
      <path
        d='M7.71411 16.4166H12.6453'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7075 13.9106C13.4298 11.7692 15.4529 10.2229 17.8384 10.2229C20.8273 10.2229 23.2551 12.6507 23.2551 15.6396C23.2551 18.6284 20.8273 21.0563 17.8384 21.0563C15.9771 21.0563 14.3319 20.1128 13.3565 18.6794'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6614 15.6338L17.5745 17.7126L19.7566 13.5666'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
