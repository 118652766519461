import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarOnRoadIcon = ({ width = 25, height = 24, color: colorProp, hovered = false }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <path d='M6.16318 12.0477L3.6709 19.5231H21.9395L19.4122 12.0477H6.16318Z' fill='white' />}
      <path
        d='M12.8052 19.1691V17.356M12.8052 15.9195V14.9844'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.80518 19.3846L5.49066 14.1508' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.8052 19.3846L20.1197 14.1508' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      {hovered && (
        <path
          d='M16.9832 13.7349H16.0548V14.8956C16.0548 15.0349 16.1477 15.1278 16.2869 15.1278H18.608C18.7472 15.1278 18.8401 15.0349 18.8401 14.8956V13.4563L19.0257 12.5741C19.0489 12.4116 19.0722 12.2723 19.0722 12.1098V9.83474C19.0722 9.55616 19.0257 9.27758 18.9097 8.999L18.6776 8.39541H19.6988C19.8381 8.39541 19.9542 8.25612 19.9309 8.11683L19.7453 7.37395C19.6988 7.16502 19.5132 7.02573 19.3043 7.02573H18.2366L17.3546 5.23817C17.1225 4.77387 16.6351 4.47208 16.1012 4.47208H9.53272C8.99888 4.47208 8.51146 4.77387 8.27936 5.23817L7.39737 7.02573H6.30648C6.09759 7.02573 5.91191 7.16502 5.86549 7.37395L5.6798 8.11683C5.65659 8.25612 5.74943 8.39541 5.9119 8.39541H6.93316L6.70106 8.999C6.60822 9.25437 6.53858 9.53295 6.53858 9.83474V12.1098C6.53858 12.2723 6.5618 12.4348 6.58501 12.5741L6.77069 13.4563V14.8956C6.77069 15.0349 6.86353 15.1278 7.00279 15.1278H9.32382C9.46309 15.1278 9.55593 15.0349 9.55593 14.8956V13.7349H8.62751'
          fill='white'
        />
      )}
      {hovered && (
        <path
          d='M16.0548 14.8956V13.7349H9.55593V14.8956C9.55593 15.0349 9.46309 15.1278 9.32382 15.1278H7.00279C6.86353 15.1278 6.77069 15.0349 6.77069 14.8956V13.4563L6.58501 12.5741C6.5618 12.4348 6.53858 12.2723 6.53858 12.1098V9.83474C6.53858 9.53294 6.60822 9.25437 6.70106 8.999L6.93316 8.39541H5.9119C5.74943 8.39541 5.65659 8.25612 5.6798 8.11683L5.86549 7.37395C5.91191 7.16502 6.09759 7.02573 6.30648 7.02573H7.39737L8.84464 7.64044H16.7656L18.2366 7.02573H19.3043C19.5132 7.02573 19.6988 7.16502 19.7453 7.37395L19.9309 8.11683C19.9542 8.25612 19.8381 8.39541 19.6988 8.39541H18.6776L18.9097 8.999C19.0257 9.27758 19.0722 9.55616 19.0722 9.83474V12.1098C19.0722 12.2723 19.049 12.4116 19.0257 12.5741L18.8401 13.4563V14.8956C18.8401 15.0349 18.7472 15.1278 18.608 15.1278H16.2869C16.1477 15.1278 16.0548 15.0349 16.0548 14.8956Z'
          fill='#E6F7FF'
        />
      )}
      <path
        d='M16.871 13.629H15.9675V14.7585C15.9675 14.8941 16.0579 14.9844 16.1934 14.9844H18.452C18.5875 14.9844 18.6779 14.8941 18.6779 14.7585V13.3579L18.8585 12.4995C18.8811 12.3414 18.9037 12.2058 18.9037 12.0477V9.8338C18.9037 9.56271 18.8585 9.29163 18.7456 9.02054L18.5198 8.43319H19.5135C19.6491 8.43319 19.762 8.29765 19.7394 8.1621L19.5587 7.43921C19.5135 7.23589 19.3329 7.10035 19.1296 7.10035H18.0906L17.2324 5.36087C17.0065 4.90906 16.5322 4.61539 16.0127 4.61539H9.62087C9.1014 4.61539 8.62709 4.90906 8.40123 5.36087L7.54297 7.10035H6.48142C6.27815 7.10035 6.09746 7.23589 6.05229 7.43921L5.8716 8.1621C5.84901 8.29765 5.93936 8.43319 6.09746 8.43319H7.09125L6.86539 9.02054C6.77504 9.26904 6.70728 9.54012 6.70728 9.8338V12.0477C6.70728 12.2058 6.72987 12.3639 6.75246 12.4995L6.93314 13.3579V14.7585C6.93314 14.8941 7.02349 14.9844 7.159 14.9844H9.4176C9.55312 14.9844 9.64346 14.8941 9.64346 14.7585V13.629H8.74002'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.41748 13.629H16.4191'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.0625 10.4663H9.93713L9.62093 9.83379'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5485 10.4663H15.6738L15.99 9.83379'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.999 12.0477H14.6128'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.96631 7.75546H16.6455'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
