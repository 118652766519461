import React, { forwardRef } from 'react';
import { AutocompleteAddNewItemButtonProps } from './add-new-item-button.props';
import { Flex, Text } from 'common/common-components.styled';
import { PlusLargeIcon } from 'components/icons/plus-large-icon';
import { useTheme } from 'styled-components';
import { PrefixForId } from 'common/enums';

export const AutocompleteAddNewItemButton = forwardRef<HTMLDivElement, AutocompleteAddNewItemButtonProps>(
  (props, ref) => {
    const { fieldName, label, isHide, onClick, className } = props;
    const theme = useTheme();

    if (isHide) {
      return null;
    }

    return (
      <Flex
        id={fieldName + PrefixForId.FormLookup + 'CreateNewItem'}
        ref={ref}
        className={className}
        onClick={onClick}
        align='center'
        color='white'
        width='100%'
        top='5'
        pointer
      >
        <PlusLargeIcon width={17} height={17} color={theme.colors.blue} />
        <Text color='black' left='15' wordBreak='break-all'>
          {label}
        </Text>
      </Flex>
    );
  }
);
