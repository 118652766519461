import React from 'react';
import style from './linear-loader.module.scss';
import PropTypes from 'prop-types';

export default function LinearLoader({ show }) {
  if (show) {
    return <div className={style.linear_loader} id='linearLoader' />;
  }

  return null;
}

LinearLoader.propTypes = {
  show: PropTypes.bool
};
