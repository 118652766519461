import React from 'react';
import { Flex } from 'common/common-components.styled';
import Button from 'components/button';
import { USER_ROLES } from '../../../../../../../../../../../constants';
import { useVehicleStatusChangeModal } from 'hooks/modals/car/use-vehicle-status-chage/use-vehicle-status-chage';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import { ButtonType } from 'components/button/button.props';

export const PutVehicleIntoOperationButton = () => {
  const { openVehicleStatusChangeModal } = useVehicleStatusChangeModal();
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const displayForStatus = fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;
  const accessForRoles = useIsUserHaveRole([
    USER_ROLES.administrator.id,
    USER_ROLES.fleetManager.id,
    USER_ROLES.poolManager.id
  ]);

  if (!accessForRoles || !displayForStatus) {
    return null;
  }

  return (
    <Flex justify='flex-end' top='25'>
      <Button
        type={ButtonType.Secondary}
        width='fit-content'
        onClick={() => openVehicleStatusChangeModal(fuhrpark?.new_fuhrparkid || '', { new_ezl: fuhrpark?.new_ezl })}
      >
        Fahrzeug in Betrieb setzen
      </Button>
    </Flex>
  );
};
