import { crmDataTypes } from '../../constants';
import React from 'react';
import { CircleExclamationMark } from '../../components/icons/crcle_exclamation_mark';

export const approverFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'approver1',
          type: 'lookup',
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Lookup,
          label: 'Freigeber 1',
          errorMessage: (
            <span className='d-flex a-i-center'>
              <CircleExclamationMark color={'#BF0040'} width={'12'} height={'12'} />
              &nbsp;Bitte wähle eine Person aus.
            </span>
          ),
          defaultValue: '',
          data: [],
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};

export const declineFormFields = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'resonToDecline',
          type: 'textarea',
          placeholder: 'Nenne bitte einen Ablehnungsgrund...',
          resizeType: 'vertical',
          minHeight: '120px',
          crmFieldType: crmDataTypes.String,
          validation: [
            {
              name: 'required'
            }
          ]
        }
      ]
    }
  ]
};
