import React from 'react';

export default function PictureFileIcon(props) {
  return (
    <svg width='24' height='24' {...props} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.8 3H13.0716C13.5388 3 13.9877 3.18163 14.3234 3.50652L20.4518 9.43719C20.8022 9.77629 21 10.243 21 10.7307V19.2C21 20.1941 20.1941 21 19.2 21H4.8C3.80589 21 3 20.1941 3 19.2V4.8C3 3.80589 3.80589 3 4.8 3Z'
        stroke='#8FAFBF'
        strokeWidth='2'
      />
      <circle cx='8.4001' cy='8.39961' r='1.2' fill='#8FAFBF' stroke='#8FAFBF' strokeWidth='1.2' />
      <path
        d='M3 18.6004L8.4 13.2004L11.4 16.2004L16.2 11.4004L21 16.2004'
        stroke='#8FAFBF'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
}
