import React from 'react';
import { toggleModal } from 'actions/app_action';
import { MODAL_NAMES, MODALS } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import { OpenChangeToSecondaryVehicleModalParams } from './use-change-to-secondary-vehicle.props';
import { ChangeToSecondaryVehicleModalContent } from './components/content';

export const useChangeToSecondaryVehicleModal = () => {
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch(toggleModal(MODALS.alert, null));
  };

  const openChangeToSecondaryVehicleModal = (params: OpenChangeToSecondaryVehicleModalParams) => {
    dispatch(
      toggleModal(MODALS.alert, MODAL_NAMES.alert, {
        title: 'Änderung Hauptauto',
        children: <ChangeToSecondaryVehicleModalContent {...params} />,
        hideCloseIcon: false,
        allButtonsHidden: true,
        buttons: [
          {
            type: 'cancel',
            title: 'Nein, abbrechen',
            action: closeAlert
          }
        ]
      })
    );
  };

  return { openChangeToSecondaryVehicleModal };
};
