import React from 'react';
import { IconProps } from 'common/interfaces';

export const UploadIcon = ({ width = 58, height = 53, className, color = '#808080' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 58 53'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        // eslint-disable-next-line max-len
        d='M17.2531 15.3395C17.2531 14.885 17.0258 14.2032 17.0258 13.7486C17.0258 7.15769 22.4804 1.70312 29.0713 1.70312C35.6622 1.70312 41.1168 7.15769 41.1168 13.7486C41.1168 14.4304 41.1168 14.885 40.8895 15.3395'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        // eslint-disable-next-line max-len
        d='M13.8441 38.9762C7.25321 38.9762 1.79865 33.5217 1.79865 26.9308C1.79865 20.3398 7.25321 14.8853 13.8441 14.8853C14.9805 14.8853 16.1169 15.1125 17.2532 15.3398'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        // eslint-disable-next-line max-len
        d='M40.8895 15.3398C42.0259 15.1125 43.1623 14.8853 44.2986 14.8853C50.8896 14.8853 56.3441 20.3398 56.3441 26.9308C56.3441 33.5217 50.8896 38.9762 44.2986 38.9762'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.2535 38.9758H44.299'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.8443 38.9758H17.0261'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.2983 51.7032V29.4304'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.2983 28.5215L34.2983 37.8397'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.2985 28.5215L24.2985 37.8397'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};