import { LeasingOffer } from 'common/interfaces';
import { ItemProps } from 'components/product/product-view.props';

export const getCheapestLeasingOffer = (leasingOffers: LeasingOffer[]) => {
  if (!leasingOffers.length) {
    return null;
  }
  return leasingOffers.sort(
    (a: LeasingOffer, b: LeasingOffer) =>
      a.uds_gesamtkosten_monatlich_vertragsabschluss_value - b.uds_gesamtkosten_monatlich_vertragsabschluss_value
  )[0];
};

export const getLowestLeasingFactorLeasingOffer = (leasingOffers: LeasingOffer[]) => {
  if (!leasingOffers.length) {
    return null;
  }
  return leasingOffers.sort((a: LeasingOffer, b: LeasingOffer) => a.uds_leasingfaktor - b.uds_leasingfaktor)[0];
};

export const getOneTimeCost = (item: ItemProps) => {
  let oneTimeCost = 0;
  if (item.uds_einmaligekosten_uberfuhrungskosten_value >= 0) {
    oneTimeCost += item.uds_einmaligekosten_uberfuhrungskosten_value;
  }
  if (item.uds_einmaligekosten_zulassungskosten_value >= 0) {
    oneTimeCost += item.uds_einmaligekosten_zulassungskosten_value;
  }
  if (item.uds_einmaligekosten_anzahlung_value >= 0) {
    oneTimeCost += item.uds_einmaligekosten_anzahlung_value;
  }

  return oneTimeCost;
};
