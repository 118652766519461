import classes from './car-damage-option-set.module.scss';
import { FormFieldTypes } from 'common/enums';
import { FormFieldModel, FormFieldOption } from 'common/interfaces';

export const cardDamageOptions: FormFieldOption[] = [
  {
    label: 'Delle',
    value: 1
  },
  {
    label: 'Kratzer',
    value: 2
  },
  {
    label: 'Lackschaden',
    value: 3
  },
  {
    label: 'Riss',
    value: 4
  },
  {
    label: 'Gebrochen',
    value: 5
  },
  {
    label: 'Steinschlag',
    value: 6
  },
  {
    label: 'Fehlteil',
    value: 7
  },
  {
    label: 'Deformiert bzw. verformt',
    value: 8
  },
  {
    label: 'Sonstiges',
    value: 9
  }
];

export const getCarDamageOptionSetFormField = (
  name: string,
  value: number | undefined,
  disableOptionsValue: number[]
): FormFieldModel => ({
  name: name,
  type: FormFieldTypes.OptionSet,
  options: cardDamageOptions.map(option => ({
    ...option,
    disabled: value !== option.value && disableOptionsValue.includes(option.value as number)
  })),
  placeholder: 'Art des Schadens',
  formFieldContainerClassName: classes.field_container,
  defaultValue: '',
  validation: {
    required: 'Bitte prüfe die Eingabe.'
  }
});
