import React, { memo, useContext, useEffect, useState } from 'react';
import classes from './vehicle-costs-chart.module.scss';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ExpensesPerYear } from 'components/expenses-per-year/expenses-per-year';
import { data as chartDataModel, createOptions } from 'models/chart/dashboard/vehicle-costs.model';
import { VehicleCostsChartProps } from './vehicle-costs-chart.props';
import { PercentColor, PercentSetupType } from 'components/expenses-per-year/expenses-per-year.props';
import { Position } from 'common/enums';
import { Distance, Flex } from 'common/common-components.styled';
import { useHistory } from 'react-router-dom';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { openPageVehicleCostCurrentYearProps } from '../utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const VehicleCostsChart = memo(({ vehicleCostsData = defaultVehicleCostsData }: VehicleCostsChartProps) => {
  const currentYear = new Date().getFullYear();
  const percentageFromLastYearToCurrent = vehicleCostsData?.topKosten?.percent || 0;
  const percentSetup: PercentSetupType = {
    percent: Math.abs(percentageFromLastYearToCurrent),
    color: percentageFromLastYearToCurrent >= 0 ? PercentColor.Green : PercentColor.Red,
    arrowDirection: percentageFromLastYearToCurrent >= 0 ? Position.Bottom : Position.Top
  };

  const [chartData, setChartData] = useState<ChartData<'bar', number[], string>>(chartDataModel);
  const history = useHistory();
  const options = createOptions(history, vehicleCostsData.monthsKosten.sumByMonthByYear[currentYear]);

  const { openPage } = useContext(FleetManagerDashboardContext);

  useEffect(() => {
    setChartData(prevState => ({
      ...prevState,
      datasets: prevState.datasets.map(dataset => {
        const data = Object.values(vehicleCostsData.monthsKosten.sumByMonthByYear[currentYear] || {});

        const maxValueInData = Math.max(...data);
        const minBarDisplayedValue = (maxValueInData / 100) * 2;

        dataset.data = data.map(dataItem => {
          if (dataItem === 0) {
            return 0;
          }

          return minBarDisplayedValue > dataItem ? minBarDisplayedValue : dataItem;
        });

        return dataset;
      })
    }));
  }, [currentYear, vehicleCostsData.monthsKosten.sumByMonthByYear]);

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div>
          <ExpensesPerYear
            name='Gesamtkosten'
            year={currentYear}
            price={vehicleCostsData?.monthsKosten?.sumByYear[currentYear] || 0}
            percentSetup={percentSetup}
            tooltipText={`Nettosumme aller Kosten mit Rechnungsdatum innerhalb ${currentYear}`}
            openPage={openPage(openPageVehicleCostCurrentYearProps())}
            headerPercent
          />
          <Distance top='20' />
        </div>
      </div>

      <Flex width='550px' height='220px'>
        <Bar options={options} data={chartData} />
      </Flex>
    </div>
  );
});

const defaultVehicleCostsData = {
  monthsKosten: {
    averageSumByYear: {},
    percent: 0,
    sumByMonthByYear: {},
    sumByYear: {}
  },
  topKosten: {
    percent: 0,
    sumByYear: {},
    topKostenByYear: {}
  }
};
