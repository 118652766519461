import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useRepairStatusQuery } from 'hooks/react-query/repair-partner/repair-status';
import { StepProcessHorizontalItemProps } from 'components/widgets/repair-status-horizontal/components/step-process-horizontal-item/step-process-horizontal-item.props';
import { PickValue } from 'common/interfaces';
import { formatDate } from 'pages/repair-status/utils';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export const useRepairPartnerHorizontalData = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;
  const { table } = useRepairStatusQuery({ fuhrparkId: fuhrpark?.new_fuhrparkid || '' }, false, true, {
    options: {
      enabled: !!fuhrpark?.new_fuhrparkid
    }
  });

  const isHideWidget = !table.length || fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;

  const getStatus = (data: string): PickValue<StepProcessHorizontalItemProps, 'type'> => {
    if (data === null) {
      return 'inactive';
    }
    if (new Date(data) > new Date()) {
      return 'active';
    }
    if (new Date(data) <= new Date()) {
      return 'done';
    }
    return 'inactive';
  };

  const getRepairStatusHorizontalData = (index: number): StepProcessHorizontalItemProps[] => {
    const repairStatusHorizontalData: StepProcessHorizontalItemProps[] = [
      {
        title: formatDate(table[index].uds_pickupcardatetime),
        description: 'Abholdatum',
        type: getStatus(table[index].uds_pickupcardatetime || ''),
        id: 'pickupDate'
      },
      {
        title: formatDate(table[index].uds_geplanter_fertigstellungstermin_werkstatt),
        description: 'Geplanter Fertigstellungstermin',
        type: getStatus(table[index].uds_geplanter_fertigstellungstermin_werkstatt || ''),
        id: 'scheduledCompletionDate'
      }
    ];

    if (table[index]?.Status?.toLowerCase() === 'vorbesichtigung geplant') {
      repairStatusHorizontalData.unshift({
        title: formatDate(table[index].uds_besichtigungstermin),
        description: 'Besichtigungstermin',
        type: getStatus(table[index].uds_besichtigungstermin || ''),
        id: 'viewingAppointment'
      });
    }

    return repairStatusHorizontalData;
  };

  return { getRepairStatusHorizontalData, table, isHideWidget };
};
