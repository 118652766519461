import { RefObject, useEffect, useRef } from 'react';
import { HandlerType, IUseOutsideClickOptions } from './use-outside-click.props';

const useOutsideClick = (
  ref: RefObject<Element>,
  outsideClickHandler: () => any,
  {
    eventType = 'click',
    capture = true,
    disableStopPropagation = false,
    removePrevEventListener = false
  }: IUseOutsideClickOptions = {},
  enabled = true
) => {
  const prevHandleClickOutside = useRef<HandlerType>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!enabled) {
        return;
      }
      const datepicker = document.querySelector('#filterDatePickerPopper');
      const isDatePickerClicked = datepicker?.contains(event.target as HTMLElement) || false;

      if (
        ref.current &&
        !ref.current.contains(event.target as HTMLElement) &&
        !isDatePickerClicked &&
        !(event.target as HTMLElement).matches('input[type="file"]')
      ) {
        event.preventDefault();

        !disableStopPropagation && event.stopPropagation();
        !disableStopPropagation && event.stopImmediatePropagation();

        outsideClickHandler();
      }
    };

    if (removePrevEventListener) {
      if (prevHandleClickOutside.current) {
        document.removeEventListener(eventType, prevHandleClickOutside.current, { capture });
      }
      prevHandleClickOutside.current = handleClickOutside;
    }

    document.addEventListener(eventType, handleClickOutside, { capture });
    return () => {
      document.removeEventListener(eventType, handleClickOutside, { capture });
    };
  }, [ref, outsideClickHandler, capture, disableStopPropagation, removePrevEventListener]);
};

export default useOutsideClick;
