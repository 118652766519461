import React from 'react';
import { CircleCheckMark } from '../../../../icons/circle-check-mark';
import { CircleExclamationMark } from '../../../../icons/crcle_exclamation_mark';
import classes from './control-item.module.scss';
import { addTooltipToString } from 'components/aufgaben-component/components/aufgaben-item/aufgaben-item';

export const ControlItem = ({ row }) => {
  const {
    new_gueltigkeitsstatus_driverscheckneu: status,
    new_gueltigkeitsstatus_driverscheckneu_value: statusId,
    daysSinceLastCheck
  } = row;

  switch (statusId) {
    case STATUSES_ID.daysOverdue:
      return (
        <span className={classes.control_status_wrapper}>
          <CircleExclamationMark />
          <span className={classes.status_invalid}>{`${daysSinceLastCheck} Tage ${status?.toLowerCase()}`}</span>
        </span>
      );

    case STATUSES_ID.valid:
      return (
        <span className={classes.control_status_wrapper}>
          <CircleCheckMark color='#30bf30' width='16' height='16' />
          <span className={classes.status_success}>{addTooltipToString(status, 20)}</span>
        </span>
      );

    case STATUSES_ID.invalid:
      return (
        <span className={classes.control_status_wrapper}>
          <CircleExclamationMark />
          <span className={classes.status_invalid}>{addTooltipToString(status, 20)}</span>
        </span>
      );

    case STATUSES_ID.incompleteData:
      return (
        <span className={classes.control_status_wrapper}>
          <CircleExclamationMark className={classes.upside_down} color='#ff9700' />
          <span className={classes.status_warning}>{addTooltipToString(status, 20)}</span>
        </span>
      );

    case STATUSES_ID.waitingForControl:
      return (
        <span className={classes.control_status_wrapper}>
          <CircleExclamationMark className={classes.upside_down} color='#5f6466' />
          <span className={classes.status}>{addTooltipToString(status, 20)}</span>
        </span>
      );

    default:
      return (
        <span>
          <span className={classes.status}>-</span>
        </span>
      );
  }
};

export const STATUSES_ID = {
  valid: 100000000,
  invalid: 100000001,
  withoutValidControl: 100000002,
  incompleteData: 100000003,
  waitingForControl: 100000004,
  daysOverdue: 100000005
};

export const CONTROL_STATUSES_NAME = {
  valid: 'gültig',
  invalid: 'ungültig',
  daysOverdue: 'uberfallig',
  incompleteData: 'unvollständige daten',
  waitingForControl: 'warten auf kontrolle'
};
