import React from 'react';
import { ApprovalRecordProps } from './approval-record.types';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import { CheckedIcon } from 'components/icons-new/checked-icon';
import { CircleIcon } from 'components/icons-new/circle-icon';
import { Flex, Text } from 'common/common-components.styled';
import { ApproveStatuses } from 'pages/release-external-page/approve-component/approve-component';
import { getDateString } from 'utils/convert-UTC-date-to-local-date';
import { ApproverType } from '../approve-status.types';

const images = {
  [ApproveStatuses.Approved]: (
    <Flex padding='2px'>
      <CheckedIcon />
    </Flex>
  ),
  [ApproveStatuses.Declined]: <CircleCloseIcon width={26} height={26} />
};

const ApprovalRecord = ({ approvalRecord }: ApprovalRecordProps) => {
  const recordStatus = approvalRecord.uds_status?.value || 0;
  const isApprovedOrDeclined = [ApproveStatuses.Approved, ApproveStatuses.Declined].includes(recordStatus);

  return (
    <Flex bottom='20'>
      {isApprovedOrDeclined ? (
        images[recordStatus as ApproveStatuses.Approved | ApproveStatuses.Declined]
      ) : (
        <CircleIcon />
      )}

      <Flex left='10' width='100%' direction='column'>
        <Flex bottom='5' opacity={isApprovedOrDeclined ? '1' : '0.6'}>
          <Text color='blue' bold>
            {approvalRecord.uds_signeeid?.name || ''}
          </Text>
          {approvalRecord.uds_signeetypecode?.value === ApproverType.Internal && (
            <Flex left='10' borderColor='blue' borderRadius='10px' borderWidth='1px' borderStyle='solid' align='center'>
              <Text padding='1px 5px 0' size='9'>
                Portal-User
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex justify='space-between'>
          <Text color='grey300'>{approvalRecord.uds_status?.label || ''}</Text>
          {isApprovedOrDeclined && approvalRecord.uds_actualend?.value && (
            <Text color='grey300'>{getDateString(approvalRecord.uds_actualend.value)}</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ApprovalRecord;
