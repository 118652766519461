import { useCallback, useRef } from 'react';
import { HandlerCallbackType, IUseScroll } from './use-scroll.props';
import { PickValue } from 'common/interfaces';

export const useScroll = ({ animation = defaultAnimation }: IUseScroll = {}) => {
  const ref = useRef<Element>(null);

  const scroll = useCallback(() => {
    ref?.current?.scrollIntoView(animation);
  }, [animation]);

  const handler = useCallback(
    (callback: HandlerCallbackType) => {
      const rect = ref?.current?.getBoundingClientRect();

      if (rect && callback) {
        callback(scroll, rect);
      }
    },
    [scroll]
  );

  const handlerAsync = useCallback(
    (callback: HandlerCallbackType, delay: number = 0) => {
      setTimeout(() => {
        handler(callback);
      }, delay);
    },
    [handler]
  );

  return {
    ref,
    scroll,
    handler,
    handlerAsync
  };
};

const defaultAnimation: PickValue<IUseScroll, 'animation'> = {
  block: 'center',
  behavior: 'smooth'
};
