import { Flex } from 'common/common-components.styled';
import styled from 'styled-components';

export const StyledShowMoreButtonContainer = styled(Flex)`
  justify-content: center;
  height: 35px;
  line-height: 35px;
  color: #22273a;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  border-top: 1px solid #eee;
  &:hover {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  }
`;
