import { AxiosRequestConfig } from 'axios';
import { IPagination } from 'hooks/use-table/use-table.props';

class PolicyConfig {
  companyList = (Pagination: IPagination | {} = {}): AxiosRequestConfig => ({
    method: 'POST',
    url: 'policy/companylist',
    data: Pagination,
    transformResponse(data) {
      return data ? JSON.parse(data) : null;
    }
  });
}

export const policyConfig = new PolicyConfig();
