import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel, TransformResponse } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { poolCalendarRequestConfig } from 'request-config/pool-calendar/pool-calendar.request-config';
import { RequestStatuses } from '../../../../../constants';
import { transformCrmResponse } from 'utils/transform-crm-response';
import { CarDataResponse } from './use-car-data.types';

const modifyResponse = ({ data }: ApiResponse<CarDataResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return transformCrmResponse(data.data);
  }
  return undefined;
};

const useCarData = (carId: string | null) => {
  const { fetch } = useFetch<ResponseModel<CarDataResponse>>({
    isShowLoading: false,
    isCancelRequestOnUnmount: true,
    isShowErrorSnackbar: false,
    ...poolCalendarRequestConfig.getCarInfo(carId as string)
  });

  const { data: carData } = useQuery<
    ApiResponse<CarDataResponse>,
    unknown,
    TransformResponse<CarDataResponse> | undefined
  >([ReactQueryKeys.GetCarInfo, carId], () => fetch(), {
    enabled: !!carId,
    select: modifyResponse
  });

  return { carData };
};

export default useCarData;
