import React from 'react';

const SturmschadenIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.54929 40.4059C6.53368 40.7593 6.80153 41.0628 7.15501 41.0785L20.4412 41.7267C20.4901 41.7278 20.539 41.7289 20.5862 41.7206C20.8697 41.6706 21.0993 41.4255 21.1138 41.121C21.1294 40.7675 20.8615 40.464 20.5081 40.4484L7.22188 39.8001C6.8684 39.7845 6.5649 40.0524 6.54929 40.4059Z'
        fill='#335566'
      />
      <path
        d='M6.56627 30.8121C6.5112 31.1628 6.7446 31.4919 7.0953 31.5469C12.0779 32.3441 15.2806 34.2637 17.0018 35.2949L28.0095 41.5864C28.0523 41.6081 28.0951 41.6297 28.1363 41.642L39.7569 45.1657C41.198 45.6033 42.2833 46.7856 42.5827 48.2624L42.9065 49.8225C42.9542 50.0382 43.1009 50.2072 43.3005 50.2889L44.776 50.8666C45.3301 51.0807 45.6852 51.6026 45.6829 52.1973L45.687 54.3204C45.6925 55.0696 45.3961 55.7649 44.8664 56.2967C44.3367 56.8285 43.6374 57.1175 42.896 57.1118L41.377 57.1166C41.0269 57.1199 40.7361 57.4049 40.7394 57.7551C40.7427 58.1052 41.0278 58.396 41.378 58.3927L42.8969 58.3879C43.1398 58.384 43.381 58.3707 43.6172 58.3291C44.4298 58.1858 45.1802 57.8002 45.7756 57.1983C46.546 56.4292 46.9702 55.4094 46.9631 54.3195L46.959 52.1963C46.9564 51.0764 46.2845 50.0842 45.2397 49.6839L44.0887 49.2341L43.8343 48.0125C43.4344 46.076 42.0113 44.5246 40.1178 43.9524L28.5605 40.4565L17.7256 34.2612L17.6383 34.2084C15.841 33.1322 12.5082 31.1381 7.27445 30.2975C6.95043 30.228 6.62135 30.4614 6.56627 30.8121Z'
        fill='#335566'
      />
      <path
        d='M30.9201 63.8544C31.3492 63.8567 31.7716 63.8212 32.1874 63.7479C33.6236 63.4947 34.9531 62.8023 36.0048 61.7498C37.3628 60.39 38.1062 58.5832 38.1087 56.6635C38.1109 52.6979 34.8833 49.4772 30.9177 49.4749C26.9521 49.4727 23.7314 52.7003 23.7292 56.6659C23.7269 60.6315 26.9546 63.8522 30.9201 63.8544ZM25.0053 56.665C25.0096 53.7609 27.1147 51.3341 29.8832 50.8459C30.2139 50.7876 30.559 50.756 30.9092 50.7527C34.1634 50.7537 36.8225 53.4024 36.8215 56.6566C36.8174 58.2356 36.2019 59.7179 35.094 60.8387C33.9751 61.9518 32.4966 62.573 30.9175 62.5689C29.3385 62.5648 27.8563 61.9493 26.7354 60.8415C25.624 59.7319 25.0106 58.2423 25.0053 56.665Z'
        fill='#335566'
      />
      <path
        d='M30.9174 59.5803C31.083 59.5804 31.2564 59.5693 31.417 59.541C32.0501 59.4293 32.6276 59.1132 33.0601 58.6375C33.2974 58.3813 33.2842 57.9744 33.0185 57.7387C32.7623 57.5014 32.3555 57.5146 32.1198 57.7803C31.8091 58.1176 31.3755 58.311 30.9181 58.3137C30.0222 58.3158 29.2897 57.5876 29.2859 56.6822C29.2871 56.302 29.4161 55.9286 29.6674 55.6407C29.892 55.3673 29.8598 54.9638 29.5864 54.7392C29.3129 54.5146 28.9094 54.5468 28.6848 54.8202C28.2512 55.3448 28.0066 56.0017 28.0099 56.6831C28.0074 58.2716 29.3194 59.5796 30.9174 59.5803Z'
        fill='#335566'
      />
      <path
        d='M19.9304 58.4315C20.2328 58.3782 20.459 58.1143 20.4624 57.8019C20.4591 57.4517 20.174 57.161 19.8239 57.1643L7.2568 57.1687C6.90665 57.1719 6.61594 57.457 6.61923 57.8072C6.62251 58.1573 6.90758 58.4481 7.25774 58.4448L19.8248 58.4404C19.8643 58.4432 19.9021 58.4365 19.9304 58.4315Z'
        fill='#335566'
      />
      <path
        d='M49.3342 11.6221C48.1539 10.5721 45.475 8.0802 41.7073 9.12676L41.6723 9.15971C41.532 9.29152 41.3459 9.36063 41.1559 9.34108C40.9658 9.32153 40.799 9.21418 40.6882 9.05411C40.5321 8.81772 40.3555 8.58749 40.1709 8.39107C38.3519 6.45491 35.3043 6.35981 33.3681 8.1788C31.432 9.99779 31.3369 13.0455 33.1559 14.9816C33.3997 15.2412 33.387 15.6493 33.1274 15.8932C32.8679 16.137 32.4597 16.1243 32.2158 15.8648C29.9157 13.4165 30.0297 9.54548 32.485 7.23879C35.0174 4.85961 39.0693 5.06774 41.3346 7.76005C45.1593 6.77432 48.18 8.86112 50.2808 10.746C50.2808 10.746 50.8187 11.183 50.3114 11.7268C49.804 12.2707 49.3342 11.6221 49.3342 11.6221Z'
        fill='#335566'
      />
      <path
        d='M53.1593 35.1206C53.1082 35.0937 52.7277 34.8776 52.5959 34.7769C52.464 34.6762 51.9932 34.2363 51.9932 34.2363C51.157 33.366 50.6562 32.1685 50.5969 30.8348C50.5836 30.4469 50.8594 30.0883 51.2247 30.0349C51.59 29.9815 51.8987 30.2423 51.912 30.6302C51.975 32.0396 52.6917 33.2402 53.8415 33.8455C55.7153 34.832 58.138 33.9081 59.2472 31.8013C60.1336 30.1177 59.9547 28.1435 58.7872 26.8899C58.6643 26.7515 58.6024 26.5591 58.6204 26.3597C58.6383 26.1603 58.7396 25.9679 58.8854 25.8357C59.4768 25.3116 59.9607 24.6817 60.3406 23.9601C61.8905 21.0163 60.1047 16.8012 57.6237 15.2279C56.2887 14.3813 52.7975 13.1816 49.3676 15.7959L49.2708 15.8681C48.9702 16.0887 48.9396 16.1542 48.746 16.212C48.6979 16.2264 48.5172 16.2328 48.3386 16.1388C48.0873 16.0065 47.8703 15.7947 47.65 15.635C46.8287 15.0397 45.756 13.8178 43.3638 15.0724C43.3638 15.0724 42.7801 15.3963 42.4782 14.9416C42.0269 14.2618 42.6458 13.8889 42.6458 13.8889C45.1216 12.3934 47.1604 13.3568 48.6099 14.5491C52.1135 11.9738 55.8864 12.4968 58.311 13.9432C61.4628 15.8235 63.4101 20.9193 61.4904 24.5655C61.1409 25.2293 60.7113 25.8387 60.2117 26.3743C61.3688 28.0402 61.4572 30.3722 60.3885 32.4021C58.8959 35.2165 55.6548 36.4344 53.1593 35.1206Z'
        fill='#335566'
      />
      <path
        d='M35.8608 38.405C35.9128 38.3798 35.9648 38.3547 36.0212 38.3167C37.2245 39.7876 39.2457 40.2749 41.0038 39.4887C41.4411 39.9508 41.9743 40.3238 42.5783 40.5556C42.7496 40.6224 42.9391 40.6163 43.0951 40.5409C43.2337 40.4738 43.3472 40.3548 43.4096 40.1963C43.5388 39.8665 43.3801 39.4942 43.0503 39.365C42.4978 39.1511 42.0291 38.779 41.6824 38.3052C41.4837 38.0271 41.1059 37.9532 40.8193 38.1345C40.7282 38.1893 40.6371 38.2441 40.5418 38.2902C39.3373 38.8727 37.9293 38.57 37.0684 37.5857C37.9528 36.7838 38.5355 35.6894 38.6979 34.4775C38.7412 34.1251 38.5001 33.8033 38.1477 33.76C37.7952 33.7166 37.4735 33.9577 37.4301 34.3101C37.2582 35.5907 36.4645 36.6909 35.2948 37.2567C33.3884 38.1787 31.0959 37.3736 30.1781 35.4759C29.4448 33.9595 29.7741 32.1644 31.0119 31.0098C31.1427 30.8823 31.2182 30.7068 31.2163 30.526C31.2144 30.3452 31.1306 30.1719 30.9987 30.0539C30.4624 29.5862 30.0393 29.021 29.725 28.3711C28.4427 25.7196 29.6037 22.411 31.6329 20.2895C33.8274 17.9952 38.9959 16.212 40.8571 19.6403L40.878 19.6837C40.9619 19.857 41.1108 19.9881 41.2953 20.0379C41.4797 20.0877 41.674 20.0472 41.8347 19.9374C42.065 19.7725 42.3124 19.6208 42.555 19.5034C44.9466 18.3468 47.8263 19.3491 48.983 21.7406C50.1396 24.1322 49.1374 27.0119 46.7458 28.1685C46.4252 28.3236 46.2909 28.7093 46.446 29.0299C46.6011 29.3505 46.9867 29.4847 47.3073 29.3297C50.3315 27.8671 51.6108 24.2119 50.1441 21.1791C48.6312 18.051 44.7733 16.7947 41.6944 18.4977C39.3754 14.8523 33.7078 16.6204 31.0627 19.1326C28.4176 21.6448 26.967 25.6528 28.5552 28.9368C28.8444 29.5347 29.216 30.0821 29.6617 30.5615C28.4018 32.0903 28.1328 34.2091 29.017 36.0375C30.2536 38.5722 33.3219 39.6329 35.8608 38.405Z'
        fill='#335566'
      />
      <path
        d='M53.3156 62.3565C52.9357 62.362 52.623 62.0381 52.6171 61.6331L52.4942 53.1102C52.4942 53.1102 52.4795 52.5364 53.185 52.5285C53.898 52.5206 53.8702 53.1035 53.8702 53.1035L53.9928 61.6133C53.9987 62.0183 53.6954 62.3511 53.3156 62.3565Z'
        fill='#335566'
      />
      <path
        d='M57.4491 62.4726C57.0692 62.4781 56.7565 62.1542 56.7507 61.7492L56.7583 50.9878C56.7583 50.9878 56.7436 50.414 57.4491 50.4062C58.1621 50.3982 58.1342 50.9812 58.1342 50.9812L58.1264 61.7294C58.1322 62.1343 57.829 62.4671 57.4491 62.4726Z'
        fill='#335566'
      />
      <path
        d='M39.383 29.0994C39.2067 28.7347 39.341 28.305 39.683 28.1396C40.025 27.9742 40.436 28.1402 40.6216 28.5003L44.3053 32.8403L52.284 49.3376C52.284 49.3376 52.5269 49.7521 51.9035 50.0794C51.28 50.4067 51.049 49.9442 51.049 49.9442L43.0935 33.4946L39.383 29.0994Z'
        fill='#335566'
      />
      <path
        d='M47.7564 32.1074C47.4144 32.2728 47.2585 32.6581 47.4084 32.9679L53.564 45.6306C53.7138 45.9404 54.1126 46.0575 54.4546 45.8921C54.7966 45.7267 54.9524 45.3414 54.8026 45.0316L48.647 32.3688C48.4879 32.0635 48.0984 31.942 47.7564 32.1074Z'
        fill='#335566'
      />
    </svg>
  );
};

export default SturmschadenIcon;
