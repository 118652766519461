import React from 'react';

export const LeftArrowIcon = ({ width = '7', height = '12', color = '#005E6E', stroke = '#777777' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 5 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.32964 11.4285C4.17481 11.4285 4.01997 11.3773 3.91674 11.2237L0.303872 6.30973C0.149035 6.10498 0.149035 5.90023 0.303872 5.69548L3.91674 0.781506C4.07158 0.576757 4.43287 0.52557 4.63932 0.679131C4.84577 0.832693 4.89738 1.191 4.74254 1.39575L1.38773 6.00261L4.74254 10.6095C4.89738 10.8654 4.84577 11.1725 4.5877 11.3261C4.53609 11.4285 4.43287 11.4285 4.32964 11.4285Z'
        fill={color}
        stroke={stroke}
        strokeWidth='0.5'
      />
    </svg>
  );
};
