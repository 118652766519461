/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from './detail-car-info.module.scss';
import { Distance } from 'common/common-components.styled';
import PhoneFieldComponent from '../components/phone-field-component';
import TextFieldComponent from '../components/text-field-component';
import EmailFieldComponent from '../components/email-field-component';
import { CSSTransition } from 'react-transition-group';
import slideQuest from '../transition/backward-slide-quest.module.css';
import TwoButtonsComponent from '../components/two-buttons-component';
import { UseFormProps } from '../report-damage.props';

const DriverPersonComponent = ({ reactHookFormData }: UseFormProps) => {
  const { watch, setValue } = reactHookFormData;
  const [showDriverDataBlock, setShowDriverDataBlock] = useState<boolean>(false);
  const [showNewContactPerson, setShowNewContactPerson] = useState<boolean>(false);

  useEffect(() => {
    setShowDriverDataBlock(true);
  }, []);

  useEffect(() => {
    setShowNewContactPerson(watch('isContactPerson') === false);
  }, [watch('isContactPerson')]);

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <CSSTransition in={showDriverDataBlock} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
        <div className={styles.data_fields}>
          <TextFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='fahrerDataFullname'
            fieldName='Name'
            fieldPlaceholder='Max Mustermann'
          />
          <Distance top='5' />

          <EmailFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='fahrerDataEmailaddress1'
            fieldName='E-mail Adresse'
            fieldPlaceholder='muster@mail.com'
          />
          <Distance top='5' />

          <EmailFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='fahrerDataEmailaddressRepeat'
            fieldRegisterToCheck='fahrerDataEmailaddress1'
            fieldName='E-mail Adresse wiederholen'
            fieldPlaceholder='muster@mail.com'
          />
          <Distance top='5' />

          <PhoneFieldComponent
            reactHookFormData={reactHookFormData}
            fieldRegister='fahrerDataMobilephone'
            fieldName='Wie können wir dich am besten telefonisch erreichen?'
          />

          <div className={watch('showSecondFahrer') ? '' : styles.hide__block}>
            <Distance top='5' />

            <PhoneFieldComponent
              reactHookFormData={reactHookFormData}
              fieldRegister='fahrerDataMobilephone1'
              fieldName='Weitere Telefonnummer'
              optional={!watch('showSecondFahrer')}
              close={true}
              closeValue='showSecondFahrer'
            />
          </div>
          <Distance top='-5' />

          <div
            id='secondDriverButton'
            onClick={() => setValue('showSecondFahrer', true)}
            className={watch('showSecondFahrer') ? styles.hide__block : styles.hide__block__show}
          >
            + Weitere Telefonnummer
          </div>

          <Distance top='20' />

          <TwoButtonsComponent
            reactHookFormData={reactHookFormData}
            fieldName='Ist diese Person ein Ansprechpartner?'
            fieldRegister='isContactPerson'
            fieldRegisterSecond='isContactPerson'
            secondButtonText='Nein, andere Person'
          />

          {watch('isContactPerson') === false && (
            <div style={{ overflow: 'hidden', width: '100%' }}>
              <CSSTransition in={showNewContactPerson} timeout={400} classNames={slideQuest} mountOnEnter unmountOnExit>
                <div className={styles.data_fields}>
                  <TextFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonName'
                    fieldName='Name'
                    fieldPlaceholder='Max Mustermann'
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonEmail'
                    fieldName='E-mail Adresse'
                    fieldPlaceholder='muster@mail.com'
                  />
                  <Distance top='5' />

                  <EmailFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonEmailRepeat'
                    fieldRegisterToCheck='isContactPersonEmail'
                    fieldName='E-mail Adresse wiederholen'
                    fieldPlaceholder='muster@mail.com'
                  />
                  <Distance top='5' />

                  <PhoneFieldComponent
                    reactHookFormData={reactHookFormData}
                    fieldRegister='isContactPersonPhone'
                    fieldName='Wie können wir dich am besten telefonisch erreichen?'
                  />

                  <div className={watch('showSecondContactPersonPhone') ? '' : styles.hide__block}>
                    <Distance top='5' />

                    <PhoneFieldComponent
                      reactHookFormData={reactHookFormData}
                      fieldRegister='isContactPersonPhoneSecond'
                      fieldName='Weitere Telefonnummer'
                      optional={!watch('showSecondContactPersonPhone')}
                      close={true}
                      closeValue='showSecondContactPersonPhone'
                    />
                  </div>
                  <Distance top='-5' />

                  <div
                    id='secondContactPersonPhoneButton'
                    onClick={() => setValue('showSecondContactPersonPhone', true)}
                    className={watch('showSecondContactPersonPhone') ? styles.hide__block : styles.hide__block__show}
                  >
                    + Weitere Telefonnummer
                  </div>
                </div>
              </CSSTransition>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default DriverPersonComponent;
