import { useTypedSelector } from 'hooks/use-typed-selector';
import { useFileDownload } from 'hooks/useFileDownload';
import { useMemo, useState } from 'react';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { TableParams } from '../my-vehicles.props';
import { SessionFilter } from 'hooks/use-session-filter';
import { FieldOrderTypes } from './download-exel.types';

export const useDownloadExel = (tableParams: TableParams, sessionFilter: SessionFilter) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const user = useTypedSelector(state => state.app.user);

  const handleShowOptions = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHistoryList = () => {
    setAnchorEl(null);
  };

  const downloadFile = useFileDownload({
    fileName: 'Meine_fahrzeuge.xlsx',
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob'
    }
  });

  const handleDownloadFile = (fieldsOrderType: FieldOrderTypes) => {
    handleCloseHistoryList();

    downloadFile({
      requestConfig: fleetRequestConfig.getExcelCompany({
        ContactId: user?.id,
        Pagination: tableParams.pagination,
        Sort: tableParams.sort,
        Search: sessionFilter.filterForServer,
        FieldsOrderType: fieldsOrderType
      })
    });
  };

  const options = useMemo(() => {
    return [
      {
        label: 'Standardexport',
        fieldOrderType: FieldOrderTypes.Standart
      },
      {
        label: 'Optimierte Ansicht',
        fieldOrderType: FieldOrderTypes.Accounting
      }
    ];
  }, []);

  return {
    anchorEl,
    options,
    handleShowOptions,
    handleCloseHistoryList,
    handleDownloadFile
  };
};
