import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const ShoppingCart = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.80629 18.7097C8.60799 18.7097 9.2579 18.0598 9.2579 17.2581C9.2579 16.4564 8.60799 15.8065 7.80629 15.8065C7.00458 15.8065 6.35468 16.4564 6.35468 17.2581C6.35468 18.0598 7.00458 18.7097 7.80629 18.7097Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0321 18.7097C13.8338 18.7097 14.4837 18.0598 14.4837 17.2581C14.4837 16.4564 13.8338 15.8065 13.0321 15.8065C12.2304 15.8065 11.5805 16.4564 11.5805 17.2581C11.5805 18.0598 12.2304 18.7097 13.0321 18.7097Z'
        fill={hovered ? 'white' : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.83854 1.87097C0.998881 1.87097 1.12886 1.74099 1.12886 1.58065C1.12886 1.42031 0.998881 1.29033 0.83854 1.29033C0.6782 1.29033 0.548218 1.42031 0.548218 1.58065C0.548218 1.74099 0.6782 1.87097 0.83854 1.87097Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path
          d='M16.2184 10.9872L18.5482 4.77428H3.65634L5.17296 11.742H15.1297C15.616 11.742 16.0514 11.4372 16.2184 10.9872Z'
          fill={fill}
        />
      )}
      <path
        d='M1.12885 1.58064H2.07239C2.62401 1.58064 3.09578 1.96532 3.21191 2.50242L5.44014 13.1427C5.55627 13.6798 6.02804 14.0645 6.57965 14.0645H15.0716M6.35466 4.77428H18.5482L16.2184 10.9872C16.0514 11.4372 15.6159 11.742 15.1297 11.742H5.14681'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
