import { useFetch } from 'hooks/use-fetch/use-fetch';
import useQueryLocation from 'hooks/use-query';
import { useQuery } from 'react-query';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { PAGES } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { VehicleDamageHistoryResponseItem } from './use-damage-history.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useVehicleDamageHistoryQuery = (
  vehicleIdProps?: string,
  options: QueryHookOptions<
    AxiosResponse<ResponseModel<VehicleDamageHistoryResponseItem[]>>,
    ResponseModel<VehicleDamageHistoryResponseItem[]>
  > = {}
) => {
  const queryLocation = useQueryLocation();
  const vehicleId = vehicleIdProps || queryLocation.id;

  const { fetch } = useFetch<ResponseModel<VehicleDamageHistoryResponseItem[]>>({
    loadingKey: `vehicleDamageHistoryQuery${vehicleId}`,
    isShowLoading: false,
    ...fleetRequestConfig.getDamageHistory(vehicleId as string)
  });

  const { data, ...restQueryProps } = useQuery(
    [PAGES.MY_VEHICLES_DETAILS, 'damage-history', vehicleId],
    () => fetch(),
    { enabled: !!vehicleId, ...options.options }
  );

  return {
    vehicleDamageHistoryQuery: data?.data.data || [],
    ...restQueryProps
  };
};
