/* eslint-disable linebreak-style */
import React from 'react';
import styles from './report-damage-component.module.scss';
import { SaveIcon } from 'components/icons-new/save';
import useWindowSize from 'hooks/use-window-size';
import { SMHeaderProps } from './report-damage.props';
import { useTheme } from 'styled-components';
import { useGetReportDamageSettingsQuery } from 'hooks/react-query/portal-customization/get-report-damage-settings/get-report-damage-settings.query';
import { SMHeaderLogo } from './header-logo/header-logo';
import { SkeletonComponent } from 'components/skeleton/skeleton';

const SMHeader = ({ reactHookFormData, setShowSideBlock, showSideBlock }: SMHeaderProps) => {
  const { windowWidth } = useWindowSize();
  const { watch } = reactHookFormData;
  const theme = useTheme();

  const { reportDamageSettingsQuery, isFetching } = useGetReportDamageSettingsQuery();

  const saveButtonColor = reportDamageSettingsQuery.uds_secondary_color || theme.colors.white;

  let windowLeftSize = 200;

  if (windowWidth <= 1100) {
    windowLeftSize = 150;
  }
  if (windowWidth <= 1020) {
    windowLeftSize = 120;
  }
  if (windowWidth <= 950) {
    windowLeftSize = 80;
  }
  if (windowWidth <= 900) {
    windowLeftSize = 20;
  }

  if (isFetching) {
    return <SkeletonComponent className={styles.header} />;
  }

  return (
    <>
      <header
        className={styles.header}
        style={{ background: reportDamageSettingsQuery.uds_main_color || theme.colors.blackPearl }}
      >
        <div className={styles.container} style={{ padding: `0 ${windowLeftSize}px` }}>
          <SMHeaderLogo />
          {watch('step') !== 'success' && watch('step') !== 0 && (
            <div className={styles.save_button} onClick={() => setShowSideBlock(!showSideBlock)}>
              {windowWidth > 900 && <span style={{ color: saveButtonColor }}>Speichern</span>}
              <SaveIcon color={saveButtonColor} />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default SMHeader;
