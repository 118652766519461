import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { ChangeKilometerFormFieldValues } from './change-kilometer-form.props';
import { errorMessages } from 'utils/error-messages';
import { regexValue } from 'utils/regex';

export const changeKilometerForm: FormFieldModel<keyof ChangeKilometerFormFieldValues>[] = [
  {
    name: 'uds_mileage',
    placeholder: 'XXX.XXX km',
    type: FormFieldTypes.Number,
    validation: {
      required: errorMessages.number,
      min: { value: 2, message: errorMessages.getMinNumberValue(1) },
      maxLength: { value: 9, message: errorMessages.getMaxNumberLength(9) },
      pattern: { value: regexValue.onlyInteger, message: errorMessages.number }
    }
  }
];
