import React from 'react';
import { Tooltip } from '@mui/material';
import { ContractIcon } from 'components/icons-new/contract';
import IconHover from 'components/icon-hover/icon-hover';
import { IconHoverProps } from 'components/icon-hover/icon-hover.props';

export const CreateNewNotesIcon = (props: Omit<IconHoverProps, 'children'>) => {
  return (
    <Tooltip title='Notiz erstellen'>
      <div>
        <IconHover {...props} pointer>
          <ContractIcon width={24} height={24} />
        </IconHover>
      </div>
    </Tooltip>
  );
};
