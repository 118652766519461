import React from 'react';

export const MailWithRightArrow = props => (
  <svg
    {...props}
    width={props.width || '24'}
    height={props.height || '14'}
    viewBox='0 0 24 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.2779 13.2528H1.90571C1.27048 13.2528 0.714648 12.8558 0.55584 12.2999C0.476435 12.1411 0.55584 11.9823 0.635244 11.8235L6.19355 6.82099C6.35236 6.66218 6.59058 6.66218 6.74939 6.82099C6.90819 6.9798 6.90819 7.21801 6.74939 7.37682L1.34988 12.1411C1.50869 12.2999 1.6675 12.3793 1.82631 12.3793H16.2779C16.4367 12.3793 16.6749 12.2999 16.7544 12.1411L11.2754 7.29741C11.1166 7.1386 11.2391 7.01002 11.3979 6.85121C11.5567 6.6924 11.6725 6.58277 11.8313 6.74158L17.5484 11.8235C17.7072 11.9029 17.7072 12.1411 17.6278 12.2205C17.469 12.8558 16.9132 13.2528 16.2779 13.2528Z'
      fill={props.color || '#102F3F'}
    />
    <path
      d='M20.5658 9.83723C20.407 9.67842 20.407 9.51961 20.407 9.3608C20.407 9.20199 20.4864 9.04318 20.5658 8.96378L21.9157 7.61386H15.0869C14.7693 7.61386 14.4517 7.37565 14.4517 6.97863C14.4517 6.5816 14.7693 6.34339 15.0869 6.34339H21.9157L20.5658 4.99352C20.3276 4.7553 20.3276 4.35828 20.5658 4.12007C20.804 3.88185 21.201 3.88185 21.4392 4.12007L23.8214 6.5022C24.0596 6.74041 24.0596 7.13743 23.8214 7.37565L21.4392 9.75782C21.201 10.0754 20.804 10.0754 20.5658 9.83723Z'
      fill={props.color || '#102F3F'}
    />
    <path
      d='M9.05209 9.68068C8.97269 9.68068 8.89329 9.68068 8.81388 9.60127L0.63522 2.29603C0.476411 2.13722 0.476411 1.89901 0.63522 1.7402C0.794029 1.58139 1.03224 1.58139 1.19105 1.7402L9.1315 8.80723L17.0719 1.7402C17.2308 1.58139 17.469 1.58139 17.6278 1.7402C17.7866 1.89901 17.7866 2.13722 17.6278 2.29603L9.44912 9.60127C9.29031 9.68068 9.2109 9.68068 9.05209 9.68068Z'
      fill={props.color || '#102F3F'}
    />
    <path
      d='M18.1836 5.07421V2.37446C18.1836 1.3422 17.3102 0.46875 16.2779 0.46875H1.90571C0.873449 0.46875 0 1.3422 0 2.37446V11.8236C0 12.8559 0.873449 13.7293 1.90571 13.7293H16.3573C17.3896 13.7293 18.263 12.9353 18.263 11.8236V9.04447C18.263 8.56804 17.9454 8.25038 17.469 8.25038C16.9925 8.25038 16.6749 8.56804 16.6749 9.04447V11.8236C16.6749 11.9824 16.5161 12.1412 16.3573 12.1412H1.90571C1.7469 12.1412 1.58809 11.9824 1.58809 11.8236V2.37446C1.58809 2.21565 1.7469 2.05684 1.90571 2.05684H16.3573C16.5161 2.05684 16.6749 2.21565 16.6749 2.37446V5.15361C16.6749 5.63004 16.9925 5.94766 17.469 5.94766C17.7866 5.86825 18.1836 5.55063 18.1836 5.07421Z'
      fill={props.color || '#102F3F'}
    />
  </svg>
);
