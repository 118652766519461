import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CompanyIcon = ({ width = 25, height = 26, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1584 5.56265V24.9671H23.0245V17.068L23.5022 15.7473V10.3334L16.1584 5.56265Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M0.89502 25V2.19503C0.89502 1.51784 1.41298 0.999995 2.09032 0.999995L14.8401 3.67842C15.5453 3.81873 16.0354 4.19627 16.0354 4.87345V25'
          fill={fill}
        />
      )}
      <path
        d='M0.89502 25V2.19503C0.89502 1.51784 1.41298 0.999995 2.09032 0.999995L14.8401 3.67842C15.5453 3.81873 16.0354 4.19627 16.0354 4.87345V25'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.91248 10.9001L4.5971 10.9001'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.91248 7.47154L4.5971 7.47154'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.91248 14.3287L4.5971 14.3287'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3336 10.9001L10.0182 10.9001'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3336 7.47154L10.0182 7.47154'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3336 14.3287L10.0182 14.3287'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.8551 19.3818L6.07497 19.3818L6.07498 25L10.8551 25L10.8551 19.3818Z'
        fill={hovered ? 'white' : 'none'}
      />
      <path
        d='M6.07498 25L6.07497 19.3818L10.8551 19.3818L10.8551 25'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && <path d='M18.5048 14.2485V7.37021L23.4517 10.4352V14.2485H18.5048Z' fill={fill} />}
      <path
        d='M16.0355 5.4812L20.9808 9.06629L22.959 10.0223C23.2557 10.1817 23.4535 10.4207 23.4535 10.6995V16.2365H23.0048'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5082 9.4744V13.4481C18.5082 13.8863 18.9533 14.2448 19.4972 14.2448H21.7707'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.9586 24.9774V16.2365'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.4238 18.7624L18.5079 18.7624'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.4238 22.1908L18.5079 22.1908'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
