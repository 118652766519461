import React from 'react';
import PropTypes from 'prop-types';

export default function Download({ width = '8', height = '14', fill = '#335566', className = '' }) {
  return (
    <svg
      stroke={fill}
      className={className}
      width={width}
      height={height}
      viewBox='0 0 8 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.540527 13.4629H7.45945' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 0.539062V9.15445' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 9.15486L7.35135 5.81641' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.99954 9.15486L0.648193 5.81641' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}

Download.propTypes = {
  fill: PropTypes.string.isRequired,
  className: PropTypes.string
};
