import React from 'react';
import { Flex, Text } from 'common/common-components.styled';

export const EmptyPlaceholder = () => {
  return (
    <Flex direction='column' justify='space-between' align='center' padding='20px 5px'>
      <Text color='blue' size='18' bold textAlign='center'>
        Bitte haben Sie noch etwas Geduld.
      </Text>
      <Text color='blue' textAlign='center' top='10'>
        Sobald unsere Partnerwerkstatt die Auftragsannahme bestätigt hat,
        <br />
        werden Ihnen die Kontaktdaten angezeigt.
      </Text>
    </Flex>
  );
};
