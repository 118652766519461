/* eslint-disable linebreak-style */
import React, { useState, useEffect, useRef, useContext } from 'react';
import classes from './vehicle-diagram.module.scss';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
  ChartData,
  ChartOptions
} from 'chart.js';
import { PAGES } from '../../../common/enums';
import { RecordNumberMultiplyItem } from '../records-number-multiply/records-number-multiply.props';
import { VehicleDiagramProps } from './vehicle-diagram.props';
import { AnyObject } from 'chart.js/dist/types/basic';
import { useTheme } from 'styled-components';
import { FleetManagerDashboardContext } from '../dashboard.context';
import { CRM_VALUE } from 'pages/leasing-end-page/leasing-end-page';
import { AmountOfActiveVehicleButton } from './components/amount-of-active-vehicle';
import { Flex } from 'common/common-components.styled';
import { OpenLeftWidgetButton } from './components/open-left-widget-button/open-left-widget-button';

ChartJS.register(ArcElement, Tooltip, Legend);

const VehicleDiagram = ({ carsData, records }: VehicleDiagramProps) => {
  const [isExpendWidget, setIsExpendWidget] = useState(false);
  const [hoveredSegment, setHoveredSegment] = useState<ActiveElement | null>(null);
  const [choosenSegment, setChoosenSegment] = useState<ActiveElement | null>(null);
  const [activeSegment, setActiveSegment] = useState<number | null>(0);
  const chartRef = useRef<ChartJS<'doughnut', number[], string> | null>(null);
  const rightWidgetRef = useRef<HTMLDivElement>(null);
  const [segmentOffsets, setSegmentOffsets] = useState<number[]>([0, 20, 0]);
  const theme = useTheme();

  const { openPage } = useContext(FleetManagerDashboardContext);

  // @ts-ignore
  const handleRef = (instance: any) => {
    if (instance && instance.$context && instance.$context.chart) {
      chartRef.current = instance.$context.chart;
    }
  };

  const firstSegmentColor = theme.colors.lightBlue;
  const secondSegmentColor = theme.colors.gold;
  const thirdSegmentColor = theme.colors.pink;

  useEffect(() => {
    if (chartRef.current) {
      const initialIndex = 0;
      const arc = chartRef.current.getDatasetMeta(0).data[initialIndex] as ArcElement;
      if (arc) {
        const initialSegment = { index: initialIndex } as ActiveElement;
        setChoosenSegment(initialSegment);
        setHoveredSegment(initialSegment);
      }
    }
  }, [records]);

  const activeIndex = activeSegment !== null ? activeSegment : 0;

  useEffect(() => {
    const newOffsets = [0, 0, 0];
    if (activeIndex != null) {
      newOffsets[activeIndex] = 20;
    }
    setSegmentOffsets(newOffsets);
  }, [activeIndex]);

  const modifyData = (data: number[]) => {
    let modifiedData = [...data];
    let total = data.reduce((a: number, b: number) => a + b, 0);

    const MIN_SEGMENT_WIDTH_FOR_HOVER = 1;

    modifiedData = modifiedData.map(segment => {
      let percentage = (segment / total) * 100;
      if (percentage < 3 && segment !== 0) {
        total -= segment;
        segment = (total * 3) / 100;
        total += segment;
      }

      return segment || MIN_SEGMENT_WIDTH_FOR_HOVER;
    });

    return modifiedData;
  };

  const data: ChartData<'doughnut', number[], string> = {
    labels: ['quantityActive', 'quantityEndSoon', 'quantityOverdue'],
    datasets: [
      {
        data: modifyData([records?.quantityActive, records?.quantityEndSoon, records?.quantityOverdue]),
        borderColor: (context: AnyObject) => {
          const segmentIndex = context.dataIndex;
          const activeIndex = activeSegment !== null ? activeSegment : 1;
          return segmentIndex === activeIndex
            ? context.dataset.hoverBackgroundColor[segmentIndex]
            : theme.colors.darkBlue;
        },
        offset: segmentOffsets,
        hoverBackgroundColor: [firstSegmentColor, secondSegmentColor, thirdSegmentColor],
        hoverBorderColor: [firstSegmentColor, secondSegmentColor, thirdSegmentColor],
        borderWidth: 0,
        backgroundColor: [firstSegmentColor, secondSegmentColor, thirdSegmentColor],
        borderJoinStyle: 'round',
        spacing: 0
      }
    ]
  };

  const options: ChartOptions<'doughnut'> = {
    cutout: '85%', // option for margin inside the circle,
    elements: {
      arc: {
        borderColor: (context: AnyObject) => {
          return context.dataset.hoverBackgroundColor[context.index];
        },
        borderJoinStyle: 'miter'
      }
    },
    radius: 74,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onClick: (event: ChartEvent, segments: ActiveElement[]) => {
      if (segments.length > 0) {
        const segmentIndex = segments[0].index;
        setActiveSegment(segmentIndex);
        const newOffsets = [0, 0, 0];
        newOffsets[segmentIndex] = 20;
        setSegmentOffsets(newOffsets);
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const canvas = event.native && (event.native.target as HTMLCanvasElement);
      if (canvas && elements.length > 0) {
        const newSegment = elements[0] as ActiveElement;
        if (!hoveredSegment || newSegment.index !== hoveredSegment.index) {
          setHoveredSegment(newSegment);
          const newOffsets = [0, 0, 0];
          newOffsets[newSegment.index] = 20;
          setSegmentOffsets(newOffsets);
        }
        canvas.style.cursor = 'pointer';
      } else if (canvas) {
        canvas.style.cursor = 'default';
        if (activeSegment !== null && hoveredSegment?.index !== activeSegment) {
          setHoveredSegment(activeSegment !== null ? ({ index: activeSegment } as ActiveElement) : null);
        }
        const newOffsets = [0, 0, 0];
        if (activeSegment !== null) {
          newOffsets[activeSegment] = 20;
        }
        if (JSON.stringify(segmentOffsets) !== JSON.stringify(newOffsets)) {
          setSegmentOffsets(newOffsets);
        }
      }
    }
  };

  const openLeaseEndPage = openPage({ page: PAGES.LEASE_END, companyCrmName: CRM_VALUE.company });

  return (
    <Flex height='100%'>
      <div ref={rightWidgetRef} className={classes.widget_container}>
        <div className={classes.canvas_block} style={{ zIndex: records ? 2 : 0 }}>
          {records && <Doughnut ref={handleRef} data={data} options={options} />}
          {records && choosenSegment && hoveredSegment && data?.labels && (
            <div className={classes.text_block}>
              {hoveredSegment?.index === 0 ? (
                <div>
                  <span style={{ color: firstSegmentColor }}>{records?.quantityActive}</span>
                  <p>innerhalb der Laufzeit</p>
                </div>
              ) : hoveredSegment?.index === 1 ? (
                <div className='pointer' onClick={openLeaseEndPage}>
                  <span style={{ color: secondSegmentColor }}>{records?.quantityEndSoon}</span>
                  <p>Laufzeit endet bald</p>
                </div>
              ) : (
                <div>
                  <span style={{ color: thirdSegmentColor }}>{records?.quantityOverdue}</span>
                  <p>Laufzeit ist überfällig</p>
                </div>
              )}
            </div>
          )}
        </div>
        <AmountOfActiveVehicleButton />
        <OpenLeftWidgetButton isExpendWidget={isExpendWidget} setIsExpendWidget={setIsExpendWidget} />
      </div>

      <ul
        className={classes.cars_block}
        style={{ right: isExpendWidget ? -(rightWidgetRef.current?.offsetWidth || 0) + 8 : 0 }}
      >
        {carsData.map((item: RecordNumberMultiplyItem) => (
          <li key={item.title} onClick={item.onClick}>
            <div className={classes.item}>
              {item.icon}
              <span className={classes.item_count}>{item.amount}</span>
            </div>
            <p>{item.title}</p>
          </li>
        ))}
      </ul>
    </Flex>
  );
};

export default VehicleDiagram;
