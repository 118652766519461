class SnackbarMessage {
  uploadDocuments = {
    success: 'Dokument wurde erfolgreich gespeichert.',
    error: 'Fehler: Dokument wurde nicht gespeichert.'
  };
  downloadDocuments = {
    success: 'Dokument wurde erfolgreich heruntergeladen.',
    error: 'Fehler: Dokument wurde nicht heruntergeladen.'
  };
}

export const snackbarMessage = new SnackbarMessage();
