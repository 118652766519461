import styled, { css } from 'styled-components';
import { Flex, Text } from 'common/common-components.styled';

export const StyledWrapper = styled.div<{ isPackageExist?: boolean }>`
  display: flex;
  gap: 20px;
  padding-bottom: 120px;

  @media (max-width: 1800px) {
    flex-direction: ${({ isPackageExist }) => (isPackageExist ? 'row' : 'column')};
  }

  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  flex: 5;
  flex-direction: column;

  @media (max-width: 1400px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const StyledCheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.borderItem};
  font-weight: 600;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.borderItem};
  }
`;

export const StyledRightContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const StyledWidget = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 5px solid ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  min-width: 270px;

  a {
    margin-left: 10px;
  }

  @media (max-width: 1400px) {
    min-width: 400px;
  }
`;

export const StyledWidgetContent = styled(Flex)`
  flex-direction: column;
  padding: 40px 60px;
`;

export const StyledPhotosContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

interface ImageProps {
  height: string;
  border?: boolean;
  objectFit?: string;
}

export const StyledImage = styled.img<ImageProps>`
  border-radius: 50%;
  height: ${({ height }) => height};
  object-fit: ${({ objectFit }) => objectFit};

  ${({ border }) =>
    border &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.white};
    `};

  &:not(:first-child) {
    margin-left: -18px;
  }
`;

export const StyledIcon = styled.img`
  margin-right: 8px;
`;

export const ReviewerWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-right: 50px;
`;

export const ReviewBox = styled.div`
  display: flex;
  height: 515px;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 25px;
  padding: 40px;
  min-width: 270px;
  border-radius: 5px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    margin-left: -50px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }
`;

export const StyledDescription = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  text-aligh: center;
`;

export const ExistingOrderPageElementContainer = styled.div<{
  padding: number;
  marginBottom?: number;
  isClickable?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ padding }) => `${padding}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  min-width: 550px;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: 0px 4px 10px ${({ theme }) => theme.colors.grey100};
      }
    `};

  @media (max-width: 1400px) {
    min-width: 400px;
  }
`;

export const StyledPackageStatus = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  fustify-content: center;
  border-radius: 20px;
  background-color: ${({ theme, background }) => theme.colors[background]};
  padding: 3px 10px;
`;

export const GeneralInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.laptop}) {
    flex-direction: column-reverse;
    font-size: 10px;
  }
`;

export const GeneralInfoContainer = styled.div`
  display: flex;
  width: 75%;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.media.laptop}) {
    width: 100%;
    margin-bottom: 5px;
    justify-content: flex-start;
  }
`;

export const StyledCompanyName = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

export const DescriptionWrapper = styled.div<{ isClickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.media.laptop}) {
    flex-direction: column;
    margin-top: 20px;

    > * {
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media (max-width: ${({ theme }) => theme.media.laptop}) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div<{ margin: string; minWidth?: string }>`
  display: flex;
  justify-content: flex-end;
  margin: ${({ margin }) => margin};
  min-width: ${({ minWidth }) => minWidth};
  @media (max-width: ${({ theme }) => theme.media.laptop}) {
    justify-content: center;
  }
`;

export const PendingApprovalStatus = styled.span`
  width: 4px;
  height: 4px;
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.darkGold};
`;

export const ContractInfoItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    margin-left: 10px;
  }
`;
