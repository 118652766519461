import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import useExpand from '../../hooks/use-expand';

const CustomAccordion = ({ section, onToggle }) => {
  const { height, content, toggleAccordion, expand } = useExpand(section.open);

  const toggle = () => {
    toggleAccordion();
    onToggle(section.name);
  };

  return (
    <div>
      <div onClick={toggle}>
        {cloneElement(section.header, { data: { ...section.headerData, open: section.open } })}
      </div>
      <div ref={content} style={{ height: height, overflow: 'hidden', transition: 'all .5s' }}>
        {cloneElement(section.body, { data: section.bodyData })}
      </div>
    </div>
  );
};

CustomAccordion.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string.isRequired,
    header: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
    headerData: PropTypes.object.isRequired,
    bodyData: PropTypes.object.isRequired | PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired
  }).isRequired,
  onToggle: PropTypes.func.isRequired
};

export default CustomAccordion;
