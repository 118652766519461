import { FormFieldModel } from 'common/interfaces';

export enum Steps {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Fifth = 'Fifth',
  Sixth = 'Sixth'
}

export type CommonFileModel = Pick<
  FormFieldModel,
  'labelStyle' | 'type' | 'defaultValue' | 'placeholder' | 'validation'
>;
