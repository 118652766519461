import { PAGES } from 'common/enums';
import { ROLES_WITH_NO_ACCESS_TO_DASHBOARD_REDIRECT } from '../constants';
import { useTypedSelector } from './use-typed-selector';

export const useRedirectTo = () => {
  const user = useTypedSelector(state => state['app'].user);
  const mainRoleId = user.mainRole?.id;

  const dashboardRedirectTo = () => {
    //@ts-ignore
    return ROLES_WITH_NO_ACCESS_TO_DASHBOARD_REDIRECT[mainRoleId] || PAGES.MY_REQUESTS;
  };

  return { dashboardRedirectTo };
};
