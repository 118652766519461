import React, { useState } from 'react';
import { ReleaseExternalPlaceholderProps } from './release-external-placeholder.types';
import { Button, Flex, Text } from 'common/common-components.styled';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'hooks';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';

const ReleaseExternalPlaceholder = ({ title, description, isShowButton }: ReleaseExternalPlaceholderProps) => {
  const query = useQuery();
  const queryId = query.id as string;
  const [hideButtonAfterRequest, setHideButtonAfterRequest] = useState(false);

  const { fetch } = useFetch({
    loadingKey: 'requestNewLink',
    successMessage: 'Neuer Link versendet.',
    isShowSuccessSnackbar: true,
    isExcludeToken: true
  });

  const requestNewLink = () => {
    fetch({
      requestConfig: vehicleRequestConfig.requestNewLink(queryId),
      callback: () => {
        setHideButtonAfterRequest(true);
      }
    });
  };

  return (
    <Flex top='70' direction='column' align='center'>
      <img src='/assets/images/nothing_found_driver.svg' alt='nothing_found_driver' />
      <Text size='18' color='blue' bold>
        {title}
      </Text>
      <Text margin='10px 0 20px' color='darkBlue'>
        {description}
      </Text>
      {isShowButton && !hideButtonAfterRequest && <Button onClick={requestNewLink}>Neuen Link anfordern</Button>}
    </Flex>
  );
};

export default ReleaseExternalPlaceholder;
