import React from 'react';
import { useLeaseExtensionDecisionButtons } from './use-lease-extension-decision-buttons';
import WidgetButton from '../../../components/widget-button';
import { Flex } from 'common/common-components.styled';

const LeaseExtensionDecisionButtons = () => {
  const { button } = useLeaseExtensionDecisionButtons();

  if (!button) {
    return null;
  }

  return (
    <Flex justify='flex-end'>
      <WidgetButton id={button.id} title={button.title} handleClick={button.action} disabled={button.disabled} />
    </Flex>
  );
};

export default LeaseExtensionDecisionButtons;
