import { createStore } from 'redux';
import { applyMiddleware } from 'redux';
import promise from 'redux-promise';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

const enhancer = applyMiddleware(promise, thunk);
const configureStore = initialState => createStore(rootReducer, initialState, enhancer);

export default configureStore();
