import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';

export const leasingNotExtendedFormFields: FormFieldModel[] = [
  {
    name: LeasingContractFields.Comment,
    type: FormFieldTypes.TextArea,
    defaultValue: '',
    label: 'Kommentare',
    validation: {
      required: 'Bitte auswählen',
      maxLength: {
        value: 700,
        message: 'Die maximale Anzahl der verfügbaren Zeichen beträgt 700 Zeichen'
      }
    }
  }
];