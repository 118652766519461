import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const HomeIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.02246 10.871V18.4193C2.02246 18.7387 2.28375 19 2.60311 19H4.67684V11.9078H9.86117H15.0455V19H17.1192C17.4386 19 17.6999 18.7387 17.6999 18.4193V10.871'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && <path d='M4.6394 19V12.0886H15.0533V19H4.6394Z' fill={fill} />}
      <path
        d='M2.02246 10.871V18.4193C2.02246 18.7387 2.28375 19 2.60311 19H4.67684V11.9078H9.86117H15.0455V19H17.1192C17.4386 19 17.6999 18.7387 17.6999 18.4193V12.6129'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {hovered && (
        <path d='M3.47411 10.871H0.861206V8.54837L9.86121 0.999985L18.8612 8.54837V10.871H16.2483' fill={fill} />
      )}
      <path
        d='M3.47411 10.871H0.861206V8.54837L9.86121 0.999985L18.8612 8.54837V10.871H16.2483'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.69519 14.0155H13.0273'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.69519 15.9922H13.0273'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.69519 17.9686H13.0273'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
