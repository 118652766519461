import React from 'react';

const TotaldiebstahlIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M26.4371 39.7938L9.35313 38.9558C8.98839 38.946 8.68279 39.222 8.66307 39.5769C8.64336 39.9416 8.92924 40.2472 9.28413 40.2669L26.3681 41.1049C26.378 41.1049 26.3878 41.1049 26.3977 41.1049C26.7427 41.1049 27.0384 40.8288 27.0582 40.4838C27.0779 40.1191 26.792 39.8135 26.4371 39.7938Z'
        fill='#335566'
      />
      <path
        d='M50.6189 48.8731L49.5049 48.4394L49.2585 47.2564C48.8641 45.3538 47.4643 43.8258 45.611 43.2639L34.3235 39.8431L23.736 33.7903L23.6571 33.741C21.3799 32.3806 16.6382 29.5415 8.7518 29.5415C8.38705 29.5415 8.09131 29.8372 8.09131 30.202C8.09131 30.5667 8.38705 30.8625 8.7518 30.8625C16.2735 30.8625 20.7983 33.5734 22.9769 34.8747L33.7419 41.0261C33.7813 41.0557 33.8306 41.0655 33.8799 41.0852L45.2462 44.5257C46.6461 44.9496 47.691 46.103 47.9868 47.5324L48.3022 49.0604C48.3515 49.2773 48.4994 49.4547 48.7064 49.5434L50.1457 50.1053C50.6682 50.3124 51.0132 50.8053 51.0132 51.377V53.4472C51.0132 54.9358 49.8007 56.1385 48.3219 56.1385H46.8334C46.4686 56.1385 46.1729 56.4342 46.1729 56.799C46.1729 57.1637 46.4686 57.4595 46.8334 57.4595H48.3219C50.5301 57.4595 52.3342 55.6653 52.3342 53.4472V51.377C52.3243 50.2532 51.654 49.2773 50.6189 48.8731Z'
        fill='#335566'
      />
      <path
        d='M36.5415 48.6956C32.6476 48.6956 29.4832 51.86 29.4832 55.7539C29.4832 59.6478 32.6476 62.8123 36.5415 62.8123C40.4354 62.8123 43.5999 59.6478 43.5999 55.7539C43.5999 51.86 40.4354 48.6956 36.5415 48.6956ZM36.5415 61.4913C33.3771 61.4913 30.7943 58.9183 30.7943 55.7441C30.7943 52.5698 33.3672 49.9968 36.5415 49.9968C39.7158 49.9968 42.2887 52.5698 42.2887 55.7441C42.2887 58.9183 39.7059 61.4913 36.5415 61.4913Z'
        fill='#335566'
      />
      <path
        d='M37.6949 56.799C37.3992 57.1243 36.9753 57.3116 36.5415 57.3116C35.6839 57.3116 34.984 56.6117 34.984 55.754C34.984 55.3893 35.1121 55.0344 35.3487 54.7485C35.5853 54.4725 35.5459 54.0584 35.2699 53.8219C34.9938 53.5853 34.5798 53.6247 34.3432 53.9007C33.9094 54.4133 33.6729 55.0738 33.6729 55.7442C33.6729 57.3313 34.9643 58.6227 36.5514 58.6227C37.3598 58.6227 38.1287 58.2777 38.6807 57.6862C38.9272 57.4201 38.9075 57.006 38.6413 56.7596C38.3554 56.5131 37.9414 56.5328 37.6949 56.799Z'
        fill='#335566'
      />
      <path
        d='M25.688 56.1287H9.31381C8.94906 56.1287 8.65332 56.4244 8.65332 56.7891C8.65332 57.1539 8.94906 57.4496 9.31381 57.4496H25.688C26.0527 57.4496 26.3485 57.1539 26.3485 56.7891C26.3485 56.4244 26.0527 56.1287 25.688 56.1287Z'
        fill='#335566'
      />
      <path
        d='M44.3074 33.4196C44.3074 33.4196 40.0899 31.4431 39.2016 27.9165M39.2016 27.9165C37.9781 28.1818 34.9162 30.2439 35.093 32.508C35.1234 34.0558 41.1643 36.596 46.4493 39.7408M39.2016 27.9165C38.846 26.5049 38.7063 24.8446 38.4931 23.0884C36.8017 14.4409 38.5757 7.34435 48.4961 7.18772C58.4166 7.34435 60.1906 14.4408 58.4991 23.0883C58.2859 24.8445 58.1463 26.5048 57.7907 27.9164M52.6848 33.4195C52.6848 33.4195 56.9023 31.443 57.7907 27.9164M57.7907 27.9164C59.0141 28.1817 62.0761 30.2438 61.8992 32.5079C61.8689 34.0557 55.8279 36.5959 50.543 39.7407'
        stroke='#335566'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40.3315 19.6482C42.1161 19.5644 47.5478 20.9182 46.9428 21.7682C45.5537 23.7194 43.4586 24.4414 41.1736 21.4398'
        stroke='#335566'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M56.6479 19.6482C54.8634 19.5644 49.4317 20.9182 50.0367 21.7682C51.4258 23.7194 53.5209 24.4414 55.8059 21.4398'
        stroke='#335566'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M48.4961 26.9994C50.5779 26.9779 51.4378 27.9164 51.4378 29.4752C51.4378 29.8813 48.4961 29.9199 48.4961 29.9199C48.4961 29.9199 45.542 29.8233 45.542 29.4752C45.542 27.9164 46.4365 27.0206 48.4961 26.9994Z'
        stroke='#335566'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M50.3108 35.0003C50.3108 35.0003 48.5749 36.3604 46.6814 35.0005'
        stroke='#335566'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TotaldiebstahlIcon;
