import { useContext, useMemo } from 'react';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { LeasingWidgetsContext } from 'pages/existing-vehicle-page/components/layout/leasing-widgets-layout/leasing-widgets-context';
import { useLeaseExtensionActions } from 'components/aufgaben-component/components/unfolded-task/components/buttons-for-lease-extension/utils';

export const useLeaseExtensionDecisionButtons = () => {
  const { car, refresh } = useContext(LeasingWidgetsContext);
  const phase = car.lva?.lva_state;
  const phaseInfo = car.lva?.lva_phaseinfo?.specificPhaseInfo;
  const plateNumber = {
    ...car.new_name,
    attributeValue: {
      id: car.lva?.lva_phaseinfo?.fuhrparkId,
      logicalName: 'fuhrpark',
      name: car.new_name.attributeValue
    }
  };
  const files = car.lva?.lva_phaseinfo?.fileList?.Verlängerungangebot || [];
  const leaseEndDate = car.uds_lease_return_date.attributeValue;
  const leaseExtensionId = car.lva?.lva_phaseinfo?.lvaId;
  const taskId = car.lva?.lva_phaseinfo?.lvaId;
  const mileage = car.lva.lva_phaseinfo?.specificPhaseInfo?.mileagePA;

  const { openLeasingContractModal, openOfferConfirmationModal } = useLeaseExtensionActions({
    currentPhase: phase,
    phaseInfo,
    plateNumber,
    files,
    leaseEndDate,
    leaseExtensionId,
    taskId,
    refresh
  });

  const button = useMemo(() => {
    switch (phase) {
      case LeaseExtentionPhases.Phase0:
      case LeaseExtentionPhases.Phase1:
      case LeaseExtentionPhases.Phase2:
        return {
          title: 'Anpassen',
          id: 'extendButton',
          action: () => openLeasingContractModal(mileage),
          disabled: false
        };

      case LeaseExtentionPhases.Phase5:
        return {
          title: 'Anpassen',
          id: 'extendButton',
          action: () => openLeasingContractModal(mileage),
          disabled: true
        };

      case LeaseExtentionPhases.Phase7:
        return {
          title: 'Angebot anschauen',
          id: 'viewOfferButton',
          action: openOfferConfirmationModal
        };

      default:
        return null;
    }
  }, [phase]);

  return { button };
};
