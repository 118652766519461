import { useFleetGetVehicleConstQuery } from 'hooks/react-query/fleet/use-get-vehicle-costs/use-get-vehicle-costs';
import { TileProps } from 'components/widgets/tile/tile.props';
import { PickValue } from 'common/interfaces';

export const useVehicleCostWidgetEmptyPlaceholder = () => {
  const { fleetGetVehicleConstQuery } = useFleetGetVehicleConstQuery();

  const emptyPlaceholder: PickValue<TileProps, 'emptyPlaceholder'> = [
    { isEmpty: !!fleetGetVehicleConstQuery?.byCategory?.every(item => !item.value) },
    { isEmpty: !fleetGetVehicleConstQuery?.pastYear && !fleetGetVehicleConstQuery?.currentYear }
  ];

  return { emptyPlaceholder };
};
