import React, { useState, FormEvent , useCallback} from 'react';
import {StyledModal , StyledInnerWrapper, StyledModalTitle} from './task-handler.styled';
import { AnyObject } from 'common/interfaces';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { CircleCheckMark } from 'components/icons/circle-check-mark';
import { StyledButton } from 'pages/tasks-page/task-handler/task-handler.styled';
import { Button, Flex, Text } from 'common/common-components.styled';
import { useSelector } from 'react-redux';
import { uploadDate } from 'components/aufgaben-component/utils';
import { convertUTCDateToLocalDate } from 'utils/convert-UTC-date-to-local-date';
import { useOverlay } from 'hooks';
import { SnackbarState } from 'pages/tasks-page/tasks-page';
import { crmDataTypes, STATECODE } from '../../../constants';
import { useQueryClient } from 'react-query';
import { PAGES , FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import classes from './task-handler.module.scss';
import {Moment} from 'moment';

const defaultServiceProviderFields:FormFieldModel[] = [
  {
    name: 'uds_task_comment',
    type: FormFieldTypes.TextArea,
    defaultValue: '',
    label: '',
    placeholder:'Text',
    containerClassName: ``,
    validation: {
      required: 'Bitte auswählen',
    },
    textAreaProps: {
      maxHeight: 100,
      minRows: 4
    }
  },
  {
    name: 'uds_done_date',
    type: FormFieldTypes.Date,
    defaultValue: '',
    label: 'Erledigt am',
    placeholder: 'TT.MM.JJJJ',
    className:`${classes.done_date_field}`,
    containerClassName: `${classes.done_date_field_container}`,
    validation: {
      required: 'Bitte auswählen',
    }
  },
];

interface TaskHandlerProps {
  row: AnyObject;
  setSnackbarState(data: SnackbarState): void;
};

type RenderButtonsProps = {
  cancelHandler:(e:FormEvent)=> void;
  submitTaskHandler:(e:FormEvent)=>void;
} | {
  cancelHandler:()=> void;
  submitTaskHandler:()=>Promise<void>;
};

const TaskHandler = ({ row, setSnackbarState }: TaskHandlerProps) => {
  const {name} = useSelector((state: AnyObject): AnyObject => state['app'].user);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showOverlay, hideOverlay] = useOverlay();
  const reactHookFormData = useForm();
  const { watch, setError } = reactHookFormData;

  const queryClient = useQueryClient();

  const closeModalHandler = () => {
    setOpen(false);
  };

  const getCrmTask = useCallback(():AnyObject=>{
    return{
      regardingobjectid: {
        attributeTypeCode: crmDataTypes.Lookup,
        attributeValue: { id: row.regardingobjectid_id }
      },
      uds_aufgabentyp_fuhrpark: {
        attributeTypeCode: crmDataTypes.Picklist,
        attributeValue: { value: row.uds_aufgabentyp_fuhrpark_value }
      },
      activityid: {
        attributeTypeCode: crmDataTypes.Uniqueidentifier,
        attributeValue: row.activityid
      },
      statuscode: 2
    };
  }, [row]);

  const submitModalTaskHandler = async ()=>{
    showOverlay();
    try{
       const {uds_task_comment, uds_done_date} = watch();
       const commentError = !(uds_task_comment as string).trim().length;
       const dateError = !uds_done_date;
       if(commentError || dateError){
        commentError && setError('uds_task_comment',{message:'Bitte auswählen'});
        dateError && setError('uds_done_date',{message:'Bitte auswählen'});
        return;
       }
       closeModalHandler();
       let commentToTask:AnyObject= {};
       const {questioner , executor} = determineLoggedInPerson();
       if(questioner || executor){
        commentToTask[
          questioner ? 'uds_requester_comment_after_completion'
                     : 'uds_service_provider_response'
                    ] = {
          attributeTypeCode: crmDataTypes.String,
          attributeValue: uds_task_comment.trim()
        };
        const {data} = await uploadDate(
          convertUTCDateToLocalDate((uds_done_date as Moment).toDate().toISOString()),
          {
            ...getCrmTask(),
            ...commentToTask
          }
         );
         if(!data.isError){
           await queryClient.resetQueries([PAGES.SERVICE_PROVIDER_TASKS]);
           setSnackbarState({ message: 'Änderungen wurden übernommen.', open: true, type: 'success' });
           return;
         }
          setSnackbarState({ message: data.messages[0]?.message || 'Änderungen wurden übernommen.', open: true, type: 'error' });
        }
    }catch(error){
      console.log(error);
      setSnackbarState({ message: 'Änderungen wurden nicht übernommen.', open: true, type: 'error' });
    }finally{
      hideOverlay();
    }
  }

  const determineLoggedInPerson = useCallback(()=>{
    return {
      questioner:name === row?.uds_isfragesteller,
      executor:name === row?.uds_executor_contactid,
      description:row?.description ? row.description.trim() : ''
    };
  }, [row]);


  const toggleModalHandler = (e: FormEvent) => {
    e.stopPropagation();
    const {questioner , executor} = determineLoggedInPerson();
    if(questioner || executor){
      return setOpen(true);
    }
    setSnackbarState({ message: 'Leider haben Sie keinen Zugriff!', open: true, type: 'warning' });
  };

  const renderModalTitle = ():string=>{
   const {questioner} = determineLoggedInPerson();
   if(questioner){
     return 'Kommentar zum Abschluß';
   }
   return 'Antwort an den Fragesteller';
  }

  const renderButtons = (actions:RenderButtonsProps):JSX.Element=>{
    return (
      <Flex top='20' justify='flex-end'>
        <Button onClick={actions.cancelHandler} secondary>
          Abbrechen
        </Button>
        <Button onClick={actions.submitTaskHandler} left='10' id={`${row.activityid.slice(6, 12)}_1111`}>
          Speichern
        </Button>
      </Flex>
    )
  }

  const isOpenStateCode = row.statecode_value === STATECODE.open;

  return (
    <div className={isOpenStateCode ? 'w-100' : 'w-0'}>
        <StyledModal open={isOpen}>
          <div>
            <StyledModalTitle backgroundColor='#335566'>
              <h5>
                {renderModalTitle()}
              </h5>
              <img
                onClick={()=> setOpen(false)}
                src='../../../assets/images/alert_close.svg'
                alt='close_modal'
              />
            </StyledModalTitle>
            <StyledInnerWrapper>
              <FormHook
                reactHookFormData={reactHookFormData}
                formFields={defaultServiceProviderFields.map( field => {
                if(field.name === 'uds_task_comment'){
                  const {questioner , executor} = determineLoggedInPerson();
                  if(questioner){
                    field.label = 'Bitte hinterlasse uns einen Kommentar, bevor du die Aufgabe selbstständig schließt.';
                    return field;
                  }
                  if(executor){
                    field.label = 'Bitte gib eine Antwort ein, die wir an der Aufgabe speichern und dem Fragesteller anzeigen.';
                    return field;
                  }
                  return field;
                }
                  return field;
                })
                }
                distanceBetweenFields='20'
              />
              {renderButtons({
                cancelHandler:closeModalHandler,
                submitTaskHandler:submitModalTaskHandler
              })
              }
            </StyledInnerWrapper>
          </div>
        </StyledModal>
        {isOpenStateCode && (
          <Flex justify='flex-end'>
           <StyledButton onClick={toggleModalHandler} id={`${row.activityid.slice(2, 10)}_2222`}>
             <Flex align='center'>
               <CircleCheckMark width={15} height={15} />
               <Text as='span' color='white' left='10'>
                 Erledigen
               </Text>
             </Flex>
           </StyledButton>
         </Flex>
         )
        }
    </div>
  );
};

export default TaskHandler;
