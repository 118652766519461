import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '../select';
import { withRouter } from 'react-router-dom';
import { UNAUTHORIZED_PAGES } from '../../common/enums';

function Sorting({ reverse, changeHandler, predicate, options, location }) {
  const onChange = ({ target: { value } }) => {
    const keys = value.split('-');
    let predicate = '';
    let reverse = false;
    if (keys.length == 2) {
      predicate = keys[0];
      reverse = keys[1];
    }

    changeHandler({
      predicate,
      reverse
    });
  };

  useEffect(() => {
    return () => {
      changeHandler({
        predicate: 'createdon',
        reverse: true
      });
    };
  }, []);
  // quick-fix: Should be removed after own version for page was created;
  const style =
    location.pathname.indexOf(`${UNAUTHORIZED_PAGES.OFFER}/`) === 0 ? { display: 'none' } : { fontSize: '14px' };
  return (
    <>
      <span style={style}>Sortieren nach:</span>
      <div className='select' style={{ width: '100%', maxWidth: '300px' }}>
        <Select
          selectProps={{
            value: `${predicate}-${reverse}`,
            className: 'btn btn-default form-control',
            style: { color: '#373B4D', fontSize: '14px' },
            id: 'VehicleOffersSortingControl',
            onChange
          }}
          options={options}
        />
      </div>
    </>
  );
}

Sorting.propTypes = {
  reverse: PropTypes.bool.isRequired,
  predicate: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default withRouter(Sorting);
