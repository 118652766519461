import React, { useEffect, useRef, useState } from 'react';
import { BudgetPartProps } from 'pages/details/budget-scale/budget-part/budget-part.props';
import { Flex, Text } from 'common/common-components.styled';
import { StyledBudgetPart } from 'pages/details/budget-scale/budget-part/budget-part.styled';
import { Tooltip } from '@mui/material';

const BudgetPart = ({ backgroundColor, color, price, icon, width, type }: BudgetPartProps) => {
  const container = useRef<HTMLDivElement | null>(null);
  const content = useRef<HTMLDivElement | null>(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [showPrice, setShowPrice] = useState(true);

  const containerOffsetWidth = container.current?.offsetWidth || 0;
  const contentOffsetWidth = content.current?.offsetWidth || 0;

  const tooltipProps = {
    leasingPrice: {
      text: (
        <>
          Leasing-Rate inkl. Dienstleistungen{' '}
          <b>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</b>
        </>
      )
    },
    fuelCost: {
      text: (
        <>
          Kalkulatorische Kraftstoffkosten{' '}
          <b>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</b>
        </>
      )
    },
    rest: {
      text: (
        <>
          Nicht verbrauchtes Mobilitätsbudget inkl. möglicher Eigenzuzahlung{' '}
          <b>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</b>
        </>
      )
    }
  };

  useEffect(() => {
    if (contentOffsetWidth && contentOffsetWidth > contentWidth) {
      setContentWidth(contentOffsetWidth);
    }
  }, [contentOffsetWidth]);

  useEffect(() => {
    setShowPrice(containerOffsetWidth > contentWidth);
  }, [contentWidth, containerOffsetWidth]);

  return (
    <Tooltip title={tooltipProps[type].text}>
      <StyledBudgetPart
        ref={container}
        justify='center'
        align='center'
        width={`${width}%`}
        backgroundColor={backgroundColor}
      >
        <Flex ref={content} align='center'>
          {icon && <Flex>{icon}</Flex>}
          {showPrice && (
            <Text left={icon ? '10' : '0'} color={color} bold size='18'>
              {price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
            </Text>
          )}
        </Flex>
      </StyledBudgetPart>
    </Tooltip>
  );
};

export default BudgetPart;
