import useContactsWithEmail from 'hooks/react-query/use-contacts-with-email';
import { useEffect, useState } from 'react';
import { AnyObject } from 'common/interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { getFields } from 'components/form/form-view';
import {
  ApproversInstanceValues,
  SetApproversAutomaticallyValues
} from 'pages/company-settings-page/company-settings-component/approval-rules/approval-rules.types';
import { INSTANCIES } from 'pages/company-settings-page/company-settings-component/approval-rules/approval-rules';
import useCompanyData from 'hooks/use-company-data';
import { setForm } from 'actions/app_action';
import { useDispatch } from 'react-redux';
import { useGetUser } from 'hooks/react-query/use-get-user/use-get-user';
import { approverFormFields } from 'models/forms/order-widget-form-model';

const useApproverFormFields = (noApprovalsNeeded: boolean, enabled?: boolean, driverId?: string) => {
  const dispatch = useDispatch();
  const defaultApproverFields = approverFormFields;
  const [formFields, setFormFields] = useState<AnyObject>(defaultApproverFields);

  const { userData } = useGetUser(driverId || '', true, !!enabled);
  const { companyData } = useCompanyData(userData?.parentcustomerid?.id || '', !!enabled);
  const orderApproveTypeValue = String(companyData?.uds_order_approve_type_value.attributeValue?.value);

  const { driversWithEmail } = useContactsWithEmail(!!enabled);

  useEffect(() => {
    if (driversWithEmail.length && !noApprovalsNeeded) {
      const newFormFields = cloneDeep(formFields);
      getFields(newFormFields, (field: AnyObject) => {
        field.data = driversWithEmail.map(driver => ({ id: driver.id, label: driver.name }));
      });
      setFormFields(newFormFields);
    }
  }, [driversWithEmail.length, noApprovalsNeeded]);

  useEffect(() => {
    if (orderApproveTypeValue === SetApproversAutomaticallyValues.Prefilled && userData) {
      const newFormFields = cloneDeep(formFields);
      newFormFields.columns[0].fields.push({
        ...newFormFields.columns[0].fields[0],
        name: `approver${newFormFields.columns[0].fields.length + 1}`,
        label: `Freigeber ${newFormFields.columns[0].fields.length + 1}`
      });
      newFormFields.columns[0].fields.push({
        ...newFormFields.columns[0].fields[0],
        name: `approver${newFormFields.columns[0].fields.length + 1}`,
        label: `Freigeber ${newFormFields.columns[0].fields.length + 1}`
      });
      const values: AnyObject = {
        approver1: '',
        approver2: '',
        approver3: ''
      };

      INSTANCIES.forEach((instance, index) => {
        switch (String(companyData?.[instance.instanceFieldName]?.attributeValue?.value)) {
          case ApproversInstanceValues.Contact:
            values[`approver${index + 1}`] = companyData?.[instance.contactFieldName]?.attributeValue?.id || '';
            break;

          case ApproversInstanceValues.Supervisor:
            values[`approver${index + 1}`] = userData.uds_superiorcontactid?.id || '';
            break;

          case ApproversInstanceValues.OrderReleaser:
            values[`approver${index + 1}`] = userData.uds_ordercontactid?.id || '';
            break;

          case ApproversInstanceValues.Driver:
            values[`approver${index + 1}`] = userData.contactid;
            break;

          default:
            break;
        }
      });

      dispatch(
        setForm({
          name: 'approver',
          form: {
            values,
            errors: {},
            untouched: {},
            isFormValid: true
          }
        })
      );

      setFormFields(newFormFields);
    }
    if (orderApproveTypeValue === SetApproversAutomaticallyValues.Automatic) {
      const newFormFields = cloneDeep(formFields);
      newFormFields.columns[0].fields[0].validation = [];
      setFormFields(newFormFields);
    }
  }, [orderApproveTypeValue, JSON.stringify(userData)]);

  return { formFields, setFormFields, orderApproveTypeValue, defaultApproverFields };
};

export default useApproverFormFields;
