import React from 'react';
import { PanelItemFrameProps } from './panel-item-frame.props';
import { Flex } from 'common/common-components.styled';

export const PanelItemFrame = ({ children, isHide, isActive, ...flexProps }: PanelItemFrameProps) => {
  if (!isActive || isHide) {
    return null;
  }

  return (
    <Flex direction='column' {...flexProps}>
      {children}
    </Flex>
  );
};
