import React, { Component, createRef } from 'react';
import classes from './menu-overlay.module.scss';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import PropTypes from 'prop-types';

class MenuOverlay extends Component {
  outsideClick = event => {
    event.stopPropagation();
    this.props.closeMenu();
  };
  menuRef = createRef(null);

  componentDidMount() {
    disablePageScroll(this.menuRef.current);
  }

  componentWillUnmount() {
    enablePageScroll(this.menuRef.current);
  }

  render() {
    const { params, component } = this.props;

    return (
      <div ref={this.menuRef} onClick={event => this.outsideClick(event)} className={classes.layout}>
        <div className={classes.container} style={{ top: params.menuPosition.top, left: params.menuPosition.left }}>
          {component}
        </div>
      </div>
    );
  }
}

MenuOverlay.propTypes = {
  params: PropTypes.objectOf({
    menuPosition: PropTypes.objectOf({
      top: PropTypes.number,
      left: PropTypes.number
    })
  }),
  component: PropTypes.object,
  closeMenu: PropTypes.func.isRequired
};

export default MenuOverlay;
