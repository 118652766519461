import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const FinishIcon = ({ width = 18, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <path
          d='M0.823242 8.70516V5.17403V2.51905V2.38724C0.823242 2.1111 1.0471 1.88724 1.32324 1.88724H1.45506H4.90423H8.98521H13.0662H16.5154H16.6472C16.9233 1.88724 17.1472 2.1111 17.1472 2.38724V2.51905V5.17403V8.70516V11.6045V11.7363C17.1472 12.0124 16.9233 12.2363 16.6472 12.2363H16.5154H13.0662H8.98521H4.90423H1.45506H1.32324C1.0471 12.2363 0.823242 12.0124 0.823242 11.7363V11.6045V8.70516Z'
          fill={fill}
        />
      )}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.823242 8.70516V5.17403H4.90423V8.70516H0.823242ZM8.98521 8.70516H4.90423V12.2363H8.98521V8.70516ZM8.98521 5.17403V8.70516H13.0662V12.2363H16.5154C16.8643 12.2363 17.1472 11.9534 17.1472 11.6045V8.70516H13.0662V5.17403H17.1472V2.51905C17.1472 2.17011 16.8643 1.88724 16.5154 1.88724H13.0662V5.17403H8.98521ZM8.98521 5.17403V1.88724H4.90423V5.17403H8.98521Z'
        fill={color}
      />
      <path
        d='M0.822608 1.88723V5.17403V8.70517V12.2363M0.822608 1.88723H16.8465C17.0122 1.88723 17.1465 2.02155 17.1465 2.18723V5.17403V8.70517V11.9363C17.1465 12.102 17.0122 12.2363 16.8465 12.2363H13.0656H8.98458H4.90359H0.822608M0.822608 1.88723L0.821777 1M0.821777 19L0.822608 12.2363'
        stroke={color}
        strokeLinecap='round'
      />
    </svg>
  );
};
