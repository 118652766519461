import React, { MouseEvent, useContext } from 'react';
import { Text } from 'common/common-components.styled';
import { TabItemFrameProps } from './tab-item-frame.props';
import { AccordionTabContext } from '../../../accordion.context';

export const TabItemFrame = ({
  children,
  tabIndex,
  isHide,
  isRemoveScrollOnToggle,
  ...textProps
}: TabItemFrameProps) => {
  const { toggleTab } = useContext(AccordionTabContext);

  const clickHandler = (event: MouseEvent<HTMLParagraphElement>) => {
    !isRemoveScrollOnToggle && event.currentTarget.scrollIntoView({ behavior: 'smooth' });
    toggleTab(tabIndex);
  };

  if (isHide) {
    return null;
  }

  return (
    <Text pointer {...textProps} onClick={clickHandler}>
      {children}
    </Text>
  );
};
