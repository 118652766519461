import React from 'react';

const BringserviceIcon = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='60' height='60' fill='white' />
      <path
        d='M49.5962 32.6964V29.7316C49.5962 28.843 49.4329 27.9724 49.1246 27.1563L48.4173 25.2883H51.7725C52.2441 25.2883 52.5886 24.853 52.4798 24.3996L51.9357 22.06C51.7906 21.4071 51.1922 20.9356 50.5211 20.9356H46.9664L44.1735 15.3678C43.4299 13.8987 41.9246 12.9557 40.2742 12.9557H19.7259C18.0755 12.9557 16.5702 13.8806 15.8266 15.3678L13.0336 20.9356H9.47891C8.80787 20.9356 8.2275 21.4071 8.06428 22.06L7.52019 24.3996C7.41137 24.853 7.75597 25.2883 8.22751 25.2883H11.5827L10.8754 27.1563C10.5671 27.9724 10.4038 28.8611 10.4038 29.7316V36.8592C10.4038 37.3489 10.4582 37.8567 10.5489 38.3282L11.1293 41.103V44.8754C11.1293 45.6734 11.7822 46.3263 12.5802 46.3263H18.3838C19.1817 46.3263 19.8347 45.6734 19.8347 44.8754V41.9736H16.9329'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6758 23.8375H42.341'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7754 33.2684H20.8148L19.781 31.219'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.7617 41.9734H22.8274'
        stroke='#335566'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.0489 44.5125H33.9979V47.0443L26.4023 41.9806L33.9979 36.9169V39.4488H41.0871V41.9806L49.189 36.9169L41.0871 31.8532V34.3851H38.0489'
        stroke='#FF9700'
        strokeWidth='1.1'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BringserviceIcon;
