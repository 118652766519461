import React from 'react';

export const SetupAssistantIcon = () => (
  <svg width='88' height='89' viewBox='0 0 88 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M79.2966 54.3762C79.7082 53.3479 80.7373 52.6966 81.8349 52.6966H83.7559C85.2651 52.6966 86.5 51.4626 86.5 49.9543V38.9855C86.5 37.4772 85.2651 36.2432 83.7559 36.2432H81.8349C80.7373 36.2432 79.7082 35.592 79.2966 34.5636L75.9694 26.5426C75.5577 25.5143 75.7635 24.3489 76.5525 23.5605L77.9245 22.1894C78.9879 21.1268 78.9879 19.3786 77.9245 18.316L70.1723 10.5692C69.1089 9.50658 67.3596 9.50658 66.2962 10.5692L64.9242 11.9403C64.1352 12.7287 62.9689 12.9687 61.9398 12.5231L53.9132 9.19808C52.8842 8.78675 52.2325 7.75841 52.2325 6.66152V4.74198C52.2325 3.23375 50.9976 1.99976 49.4883 1.99976H38.5117C37.0025 1.99976 35.7676 3.23375 35.7676 4.74198V6.66152C35.7676 7.75841 35.1158 8.78675 34.0868 9.19808L26.0602 12.5231C25.0311 12.9344 23.8649 12.7287 23.0759 11.9403L21.7038 10.5692C20.6405 9.50658 18.8911 9.50658 17.8277 10.5692L10.0755 18.316C9.0121 19.3786 9.0121 21.1268 10.0755 22.1894L11.4475 23.5605C12.2365 24.3489 12.4766 25.5143 12.0307 26.5426L8.70339 34.5636C8.29177 35.592 7.26274 36.2432 6.16509 36.2432H4.24415C2.73487 36.2432 1.5 37.4772 1.5 38.9855V49.9543C1.5 51.4626 2.73487 52.6966 4.24415 52.6966H6.16509C7.26274 52.6966 8.29177 53.3479 8.70339 54.3762L12.0307 62.3972C12.4423 63.4255 12.2365 64.5909 11.4475 65.3793L10.0755 66.7504C9.0121 67.8131 9.0121 69.5612 10.0755 70.6238L17.8277 78.3706C18.8911 79.4332 20.6405 79.4332 21.7038 78.3706L23.0759 76.9995C23.8649 76.2111 25.0311 75.9711 26.0602 76.4168L34.0868 79.7417C35.1158 80.1531 35.7676 81.1814 35.7676 82.2783V84.1978C35.7676 85.706 37.0025 86.94 38.5117 86.94H49.4883C50.9976 86.94 52.2325 85.706 52.2325 84.1978V82.2783C52.2325 81.1814 52.8842 80.1531 53.9132 79.7417'
      fill='#E6F7FF'
    />
    <path
      d='M79.2966 54.3762C79.7082 53.3478 80.7373 52.6966 81.8349 52.6966H83.7559C85.2651 52.6966 86.5 51.4626 86.5 49.9543V38.9855C86.5 37.4772 85.2651 36.2432 83.7559 36.2432H81.8349C80.7373 36.2432 79.7082 35.592 79.2966 34.5636L75.9694 26.5426C75.5577 25.5143 75.7635 24.3489 76.5525 23.5605L77.9245 22.1894C78.9879 21.1268 78.9879 19.3786 77.9245 18.316L70.1723 10.5692C69.1089 9.50658 67.3596 9.50658 66.2962 10.5692L64.9242 11.9403C64.1352 12.7287 62.9689 12.9687 61.9398 12.5231L53.9132 9.19808C52.8842 8.78675 52.2325 7.75841 52.2325 6.66152V4.74198C52.2325 3.23376 50.9976 1.99976 49.4883 1.99976H38.5117C37.0025 1.99976 35.7676 3.23376 35.7676 4.74198V6.66152C35.7676 7.75841 35.1158 8.78675 34.0868 9.19808L26.0602 12.5231C25.0311 12.9344 23.8649 12.7287 23.0759 11.9403L21.7038 10.5692C20.6405 9.50658 18.8911 9.50658 17.8277 10.5692L10.0755 18.316C9.01211 19.3786 9.01211 21.1268 10.0755 22.1894L11.4475 23.5605C12.2365 24.3489 12.4766 25.5143 12.0307 26.5426L8.70339 34.5636C8.29177 35.592 7.26274 36.2432 6.16509 36.2432H4.24415C2.73487 36.2432 1.5 37.4772 1.5 38.9855V49.9543C1.5 51.4626 2.73487 52.6966 4.24415 52.6966H6.16509C7.26274 52.6966 8.29177 53.3478 8.70339 54.3762L12.0307 62.3972C12.4423 63.4255 12.2365 64.5909 11.4475 65.3793L10.0755 66.7504C9.01211 67.8131 9.01211 69.5612 10.0755 70.6238L17.8277 78.3706C18.8911 79.4332 20.6405 79.4332 21.7038 78.3706L23.0759 76.9995C23.8649 76.2111 25.0311 75.9711 26.0602 76.4168L34.0868 79.7417C35.1158 80.1531 35.7676 81.1814 35.7676 82.2783V84.1978C35.7676 85.7061 37.0025 86.94 38.5117 86.94H49.4883C50.9976 86.94 52.2325 85.7061 52.2325 84.1978V82.2783C52.2325 81.1814 52.8842 80.1531 53.9132 79.7417'
      stroke='#335566'
      strokeWidth='3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M66.3306 51.2915L83.2757 68.259C87.5635 72.5437 87.5635 79.5021 83.2757 83.7868C78.988 88.0715 72.0247 88.0715 67.737 83.7868L50.7576 66.7508'
      fill='white'
    />
    <path
      d='M66.3306 51.2915L83.2757 68.259C87.5635 72.5437 87.5635 79.5021 83.2757 83.7868C78.988 88.0715 72.0247 88.0715 67.737 83.7868L50.7576 66.7508'
      stroke='#335566'
      strokeWidth='3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M74.8374 80.119C77.4896 80.119 79.6397 77.9705 79.6397 75.3201C79.6397 72.6698 77.4896 70.5212 74.8374 70.5212C72.1852 70.5212 70.0352 72.6698 70.0352 75.3201C70.0352 77.9705 72.1852 80.119 74.8374 80.119Z'
      stroke='#335566'
      strokeWidth='3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M55.0109 64.9676C46.1953 69.6636 34.9787 68.3268 27.5352 60.8885C21.2236 54.5814 19.3027 45.6006 21.7038 37.6139L33.881 49.9539C36.9339 49.9539 38.1344 49.9539 41.2216 49.9539L49.454 41.7272C49.454 38.7793 49.454 37.4083 49.454 34.4604L37.2425 22.1204C45.2005 19.7209 54.222 21.6405 60.5335 27.9476C67.977 35.3859 69.3491 46.5947 64.6154 55.4041'
      fill='white'
    />
    <path
      d='M55.0109 64.9676C46.1953 69.6636 34.9787 68.3268 27.5352 60.8885C21.2236 54.5814 19.3027 45.6006 21.7038 37.6139L33.881 49.9539C36.9339 49.9539 38.1344 49.9539 41.2216 49.9539L49.454 41.7272C49.454 38.7793 49.454 37.4083 49.454 34.4604L37.2425 22.1204C45.2005 19.7209 54.222 21.6405 60.5335 27.9476C67.977 35.3859 69.3491 46.5947 64.6154 55.4041'
      stroke='#335566'
      strokeWidth='3'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
