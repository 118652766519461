import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CustomersIcon = ({ width = 21, height = 18, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <>
          <path
            d='M11.3022 14.0681C10.9888 13.8965 10.6744 13.7044 10.6744 13.7044C8.88766 12.654 8.07551 10.8132 8.07551 6.91483C8.07551 4.16434 9.97053 2.15021 12.8401 2.15021C15.7097 2.15021 17.6048 4.16434 17.6048 6.91483C17.6048 10.8132 16.7926 12.654 15.0059 13.7044C15.0059 13.7044 14.4976 13.9701 14.1401 14.0681M15.439 13.4231V14.6143C15.439 15.1882 15.818 15.6971 16.3703 15.8596L19.7705 16.8775M10.2413 13.4231V14.6143C10.2413 15.1882 9.86225 15.6971 9.30999 15.8596L5.90979 16.8775'
            stroke={fill}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.38531 12.007C6.11708 11.8601 5.84802 11.6957 5.84802 11.6957C4.31894 10.7968 3.6239 9.2214 3.6239 5.88522C3.6239 3.53137 5.24565 1.80768 7.70145 1.80768C8.0353 1.80768 8.35374 1.83953 8.65479 1.9005M5.47737 11.4546V12.474C5.47737 12.9652 5.15301 13.4007 4.68038 13.5397L1.77051 14.4108'
            stroke={fill}
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </>
      )}
      <path
        d='M10.7059 13.7256C10.3925 13.5539 10.0781 13.3619 10.0781 13.3619C8.29135 12.3115 7.4792 10.4706 7.4792 6.5723C7.4792 3.82182 9.37422 1.80768 12.2438 1.80768C15.1134 1.80768 17.0084 3.82182 17.0084 6.5723C17.0084 10.4706 16.1963 12.3115 14.4096 13.3619C14.4096 13.3619 13.9013 13.6275 13.5438 13.7256M14.8427 13.0806V14.2717C14.8427 14.8457 15.2217 15.3546 15.774 15.517L19.1742 16.5349M9.64495 13.0806V14.2717C9.64495 14.8457 9.26594 15.3546 8.71368 15.517L5.31348 16.5349'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.789 11.6644C5.52077 11.5175 5.2517 11.3532 5.2517 11.3532C3.72262 10.4543 3.02759 8.87884 3.02759 5.54267C3.02759 3.18881 4.64934 1.46512 7.10513 1.46512C7.43899 1.46512 7.75743 1.49697 8.05848 1.55794M4.88105 11.1121V12.1314C4.88105 12.6226 4.5567 13.0582 4.08407 13.1972L1.17419 14.0683'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
