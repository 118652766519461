import React from 'react';
import Proptypes from 'prop-types';
import { StepIcon } from './icon';
import classes from './steps-process.module.scss';
import { PrefixForId } from '../../common/enums';
import useWindowSize from 'hooks/use-window-size';

export const StepsProcess = ({
  processSetup,
  vertical = false,
  onStepClick,
  iconColors,
  stepDividerHeight,
  iconFieldClassName,
  distanceBetweenIconAndDivider = 12,
  descriptionFieldClassName,
  stepIconStyle
}) => {
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 550;

  const getDefaultStepDiverHeight = () => {
    if (vertical) {
      return 34;
    }

    return isMobile ? 34 : 2;
  };

  const getDescriptionHeight = () => {
    const ICON_SIZE = 24;
    const DIVIDER_MARGIN = isMobile ? 0 : 10;

    if (stepDividerHeight) {
      return `${stepDividerHeight + distanceBetweenIconAndDivider + ICON_SIZE + DIVIDER_MARGIN}px`;
    }

    return `${68 + distanceBetweenIconAndDivider}px`;
  };

  return (
    <div className={vertical ? `${classes.wrapper} ${classes.wrapper_vertical}` : classes.wrapper}>
      <div className={classes.steps_wrapper}>
        {processSetup.map((setup, indx) => (
          <StepIcon
            id={'StepsProcess' + PrefixForId.Icon + indx}
            vertical={vertical}
            onStepClick={onStepClick}
            distanceBetweenIconAndDivider={distanceBetweenIconAndDivider}
            status={setup?.status}
            isNeedUnderline={processSetup.length - 1 !== indx}
            indx={indx}
            iconColors={iconColors}
            fieldClassName={iconFieldClassName}
            stepDividerHeight={stepDividerHeight || getDefaultStepDiverHeight()}
            key={indx}
            stepIconStyle={stepIconStyle}
          />
        ))}
      </div>

      <div className={classes.description_wrapper}>
        {processSetup.map((setup, indx) => (
          <div
            id={'StepsProcess' + PrefixForId.Items + indx}
            key={indx}
            style={{
              height: getDescriptionHeight(),
              paddingTop: `${distanceBetweenIconAndDivider / 2}px`
            }}
            className={descriptionFieldClassName}
          >
            {setup?.description}
          </div>
        ))}
      </div>
    </div>
  );
};

StepsProcess.propTypes = {
  processSetup: Proptypes.arrayOf(
    Proptypes.shape({
      status: Proptypes.string.isRequired,
      description: Proptypes.oneOfType([Proptypes.any, Proptypes.string]).isRequired
    })
  ),
  vertical: Proptypes.bool,
  onStepClick: Proptypes.func,
  iconColors: Proptypes.shape({
    done: Proptypes.string.isRequired,
    active: Proptypes.string.isRequired,
    inactive: Proptypes.string.isRequired
  }),
  distanceBetweenIconAndDivider: Proptypes.number,
  stepDividerHeight: Proptypes.number,
  iconFieldClassName: Proptypes.string,
  descriptionFieldClassName: Proptypes.string,
  stepIconStyle: Proptypes.object
};
