import { AnyObject } from 'common/interfaces';
import { MILLISECONDS_IN_DAY, STATECODE } from '../constants';

export const getStatusColorForTaskPages = (row: AnyObject) => {
  const timeDiff = row.scheduledend ? new Date(row.scheduledend).getTime() - new Date().getTime() : 0;
  const daysDiff = Math.ceil(timeDiff / MILLISECONDS_IN_DAY);

  let statusBackground = 'green';

  if (daysDiff > 7) {
    statusBackground = 'darkGold';
  }
  if (daysDiff <= 7) {
    statusBackground = 'pink';
  }
  if (row.statecode_value === STATECODE.completed) {
    statusBackground = 'green';
  }
  if (row.statecode_value === STATECODE.canceled) {
    statusBackground = 'grey600';
  }

  return statusBackground;
};

export const getStatusColorForOverdueTaskPages = (row: AnyObject) => {
  const timeDiff = row.scheduledend ? new Date(row.scheduledend).getTime() - new Date().getTime() : 0;
  const daysDiff = Math.ceil(timeDiff / MILLISECONDS_IN_DAY);

  let statusBackground = 'green';

  if (daysDiff <= 7) {
    statusBackground = 'darkGold';
  }
  if (daysDiff <= -1) {
    statusBackground = 'pink';
  }
  if (row.statecode_value === STATECODE.completed) {
    statusBackground = 'green';
  }
  if (row.statecode_value === STATECODE.canceled) {
    statusBackground = 'grey600';
  }

  return statusBackground;
};
