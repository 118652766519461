import { Button, Flex } from 'common/common-components.styled';
import FormHook from 'components/form-hook/form-hook';
import React from 'react';
import { useForm } from 'react-hook-form';
import { askField, descriptionField, dueDateField } from './form-fields';
import { createTask } from './hilfecenter-service';
import { useOverlay } from 'hooks';
import moment from 'moment';

interface Props {
  contactId: string;
  closeAlert: () => void;
}

const CreateTask = ({ contactId, closeAlert }: Props) => {
  const reactHookFormData = useForm();
  const {
    formState: { isValid },
    getValues
  } = reactHookFormData;
  const [showOverlay, hideOverlay] = useOverlay();

  const handleCreateTask = async () => {
    closeAlert();
    showOverlay();
    const { comment, ask, dueDate } = getValues();

    const requestPayload = {
      contactid: contactId,
      frage: ask,
      dueDate: moment(dueDate).toISOString(),
      comment
    };

    await createTask(requestPayload);
    hideOverlay();
  };

  return (
    <Flex direction='column'>
      <Flex width='100%' justify='space-between'>
        <Flex width='60%'>
          <FormHook reactHookFormData={reactHookFormData} formFields={askField} />
        </Flex>

        <Flex width='35%'>
          <FormHook reactHookFormData={reactHookFormData} formFields={dueDateField} />
        </Flex>
      </Flex>

      <Flex>
        <FormHook reactHookFormData={reactHookFormData} formFields={descriptionField} />
      </Flex>

      <Flex justify='flex-end' top='30'>
        <Flex>
          <Button secondary onClick={closeAlert}>
            Abbrechen
          </Button>
        </Flex>
        <Flex left='5'>
          <Button disabled={!isValid} onClick={handleCreateTask}>
            Senden
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateTask;
