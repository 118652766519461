import React from 'react';
import { useMobileVersionHistoryTabData } from './hooks/use-mobile-version-history-data';
import { FormSection } from 'components/sections/form-section/form-section';

export const VehicleDetailsVehicleHistoryMobileVersion = () => {
  const { mobileVersionHistoryTabData } = useMobileVersionHistoryTabData();
  return (
    <FormSection
      title='Fahrzeug Historie'
      data={mobileVersionHistoryTabData}
      tabProps={{ containerBorder: { isHideDivider: true } }}
    />
  );
};
