import React, { Children, createElement, isValidElement } from 'react';
import { ComposeChildrenProps } from './compose-children.interface';

export const ComposeChildren = ({ children }: ComposeChildrenProps) => {
  const array = Children.toArray(children);
  const first = array.pop();
  return (
    <>
      {
        (array.reduceRight((child, element) =>
          isValidElement(element) ? createElement(element.type, element.props, child) : child
        ),
        first)
      }
    </>
  );
};
