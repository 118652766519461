import React from 'react';
import { DoughnutDiagram } from 'components/widgets/doughnut/doughnut';
import { Flex } from 'common/common-components.styled';
import { QualityDiagramRenderFocusedSegment } from './components/render-focused-segment/render-focused-segment';
import { useQualityDoughnutData } from './hooks/use-doughnut-data';
import { useFleetDataQualityQuery } from 'hooks/react-query/fleet/get-data-quality/get-data-quality.query';
import { TooltipComponent } from 'components/tooltip-component/tooltip-component';

export const QualityDiagram = () => {
  const { doughnutData } = useQualityDoughnutData();
  const { fleetDataQualityQuery } = useFleetDataQualityQuery();

  return (
    <Flex width='100%' bottom='10'>
      <TooltipComponent arrow placement='top' title={fleetDataQualityQuery?.uds_date_quality_statecode?.label || ''}>
        <Flex width='100%'>
          <DoughnutDiagram
            height='115px'
            width='100%'
            maxWidth='100%'
            justify='center'
            data={doughnutData}
            options={{ excludeActiveSegments: true }}
            renderFocusedSegment={QualityDiagramRenderFocusedSegment}
            renderFocusedSegmentContainerProps={{ positionBottom: '0', width: '100%' }}
          />
        </Flex>
      </TooltipComponent>
    </Flex>
  );
};
