export enum FileFormat {
  XLS = 'xls',
  XLSX = 'xlsx',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  PDF = 'pdf'
}

export interface IFile {
  filePath: string;
  name: string;
  fileId: string;
  description: string;
}

export interface FileInfoProps {
  icon?: string;
  fileName: string;
  fileSize: number;
  lastModified: string;
  onClick?: () => void;
  onDelete?: () => void;
  file: IFile;
  allFiles?: IFile[];
  hideCheckBox?: boolean;
  hideModifyDate?: boolean;
  withoutFileViewer?: boolean;
  isDownloadButton?: boolean;
  id?: string;
}

export interface FileIconProps {
  fileFormat: string;
}
