import { ControlledInput } from '../configuration-from-pdf.js';
import styled, { css } from 'styled-components';
import { errorMixin } from '../../../common/common-components.styled';
import NumberFormat from 'react-number-format';

interface StyledControlledInputProps {
  styledProps: {
    formErrors?: boolean;
    value: number;
    minDistance: number;
    maxDistance: number;
    isAddonCounter?: boolean;
  };
}

export const StyledControlledInput = styled(ControlledInput)<StyledControlledInputProps>`
  ${({ styledProps: { isAddonCounter } }) =>
    isAddonCounter &&
    css`
      background-color: ${({ theme }) => theme.colors.grey50};
    `}
  ${({ styledProps: { formErrors, value, minDistance, maxDistance } }) =>
    formErrors && (value < minDistance || value > maxDistance) && errorMixin};
`;

export const StyledNumberFormat = styled(NumberFormat)`
  background-color: transparent;
  height: 28px;
  border: none;
  font-size: 14px !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: bold;
`;

export const StyledErrorMessage = styled.div`
  padding-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.pink};
`;
