import React from 'react';

const ParkschadenIcon = () => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.3447 34.6201L5.66427 34.1468C5.32086 34.1282 5.03314 34.3974 5.01458 34.7315C4.99602 35.0749 5.26516 35.3626 5.59929 35.3812L15.2797 35.8545C15.289 35.8545 15.2983 35.8545 15.3076 35.8545C15.6324 35.8545 15.9109 35.5947 15.9294 35.2698C15.948 34.9264 15.6881 34.6387 15.3447 34.6201Z'
        fill='#335566'
      />
      <path
        d='M39.2721 43.5953L38.1583 43.159L37.917 41.9711C37.5272 40.1055 36.1536 38.5926 34.3251 38.045L23.1504 34.6573L12.6625 28.6709L12.5789 28.6245C11.261 27.8355 9.052 26.5083 5.79425 25.5616C5.4694 25.4688 5.12599 25.6544 5.02389 25.9793C4.93108 26.3041 5.11671 26.6475 5.44155 26.7496C8.54153 27.6499 10.667 28.9215 11.9292 29.6825L22.5749 35.7711C22.6121 35.7989 22.6585 35.8082 22.7049 35.8268L33.9446 39.2331C35.3368 39.6507 36.3856 40.8016 36.6826 42.2309L36.9982 43.7438C37.0446 43.948 37.1838 44.115 37.3787 44.1986L38.808 44.7555C39.3371 44.9596 39.6805 45.4701 39.6805 46.0363V48.0875C39.6805 49.5725 38.4739 50.7883 36.9796 50.7883H35.5131C35.1697 50.7883 34.8913 51.0668 34.8913 51.4102C34.8913 51.7536 35.1697 52.032 35.5131 52.032H36.9796C39.1514 52.032 40.9149 50.2686 40.9149 48.0967V46.0456C40.9335 44.9504 40.2838 43.9944 39.2721 43.5953Z'
        fill='#335566'
      />
      <path
        d='M25.3502 43.4188C21.517 43.4188 18.3984 46.5374 18.3984 50.3706C18.3984 54.2038 21.517 57.3223 25.3502 57.3223C29.1834 57.3223 32.3019 54.2038 32.3019 50.3706C32.3019 46.5374 29.1834 43.4188 25.3502 43.4188ZM25.3502 56.0879C22.1945 56.0879 19.6329 53.5262 19.6329 50.3706C19.6329 47.2149 22.1945 44.6532 25.3502 44.6532C28.5058 44.6532 31.0675 47.2149 31.0675 50.3706C31.0675 53.5262 28.5058 56.0879 25.3502 56.0879Z'
        fill='#335566'
      />
      <path
        d='M26.5195 51.438C26.2225 51.7628 25.7956 51.9577 25.3594 51.9577C24.4869 51.9577 23.7815 51.2523 23.7815 50.3799C23.7815 50.0086 23.9115 49.6559 24.1528 49.3682C24.3756 49.1083 24.3384 48.7185 24.0785 48.4957C23.8187 48.273 23.4289 48.3101 23.2061 48.57C22.7792 49.0712 22.5471 49.7209 22.5471 50.3799C22.5471 51.9299 23.8094 53.1921 25.3594 53.1921C26.1483 53.1921 26.9094 52.858 27.4384 52.2733C27.6704 52.0227 27.6519 51.6329 27.4013 51.4008C27.1414 51.1595 26.7516 51.1781 26.5195 51.438Z'
        fill='#335566'
      />
      <path
        d='M14.6116 50.7882H5.63649C5.29308 50.7882 5.01465 51.0666 5.01465 51.4101C5.01465 51.7535 5.29308 52.0319 5.63649 52.0319H14.6116C14.955 52.0319 15.2334 51.7535 15.2334 51.4101C15.2334 51.0574 14.955 50.7882 14.6116 50.7882Z'
        fill='#335566'
      />
      <path
        d='M54.0481 36.0587C53.7047 36.0494 53.4169 36.3093 53.3984 36.6434C53.3798 36.9869 53.649 37.2746 53.9831 37.2931L64.3411 37.8036C64.3504 37.8036 64.3597 37.8036 64.3689 37.8036C64.6938 37.8036 64.9722 37.5437 64.9908 37.2189C65.0094 36.8755 64.7402 36.5877 64.4061 36.5692L54.0481 36.0587Z'
        fill='#335566'
      />
      <path
        d='M42.3163 36.5692L46.6507 36.133C46.7156 36.1237 46.7899 36.1052 46.8456 36.0774L55.0503 32.2906C57.1386 31.3253 58.6979 30.7406 60.8883 30.4064C61.8814 30.2579 63.023 30.1558 64.3966 30.1001C64.74 30.0909 64.9999 29.7939 64.9906 29.4597C64.9813 29.1163 64.6843 28.8564 64.3502 28.8657C62.9302 28.9214 61.7422 29.0235 60.7026 29.1813C58.3545 29.534 56.7209 30.1466 54.5213 31.1582L46.4186 34.8986L42.2049 35.3163C41.2211 35.3905 40.2929 35.966 39.6711 36.8848C39.142 37.6737 38.9378 38.574 39.1513 39.233C39.1606 39.2609 39.1699 39.298 39.1885 39.3258L40.1537 41.2285C40.2001 41.312 40.2558 41.3863 40.3393 41.442L41.9636 42.6393L43.3465 45.2195L43.1237 48.0503L42.1121 51.9485C42.0657 52.1341 42.1028 52.329 42.2235 52.4868C42.3441 52.6353 42.5205 52.7281 42.7154 52.7281H45.045C45.3884 52.7281 45.6668 52.4497 45.6668 52.1062C45.6668 51.7628 45.3884 51.4844 45.045 51.4844H43.5136L44.3396 48.3009C44.3489 48.2638 44.3582 48.2266 44.3582 48.1988L44.5995 45.1359C44.6088 45.0153 44.5809 44.9039 44.5252 44.7925L42.9938 41.9246C42.9474 41.8411 42.8917 41.7761 42.8175 41.7204L41.2025 40.5231L40.3301 38.8061C40.2744 38.5462 40.3765 38.0543 40.7106 37.5624C40.8962 37.3118 41.4345 36.6342 42.3163 36.5692Z'
        fill='#335566'
      />
      <path
        d='M52.7301 44.9781C49.4074 44.9781 46.7065 47.679 46.7065 51.0017C46.7065 54.3245 49.4074 57.0254 52.7301 57.0254C56.0529 57.0254 58.7537 54.3245 58.7537 51.0017C58.7537 47.679 56.0529 44.9781 52.7301 44.9781ZM52.7301 55.7816C50.0942 55.7816 47.9502 53.6376 47.9502 51.0017C47.9502 48.3658 50.0942 46.2218 52.7301 46.2218C55.366 46.2218 57.51 48.3658 57.51 51.0017C57.5193 53.6376 55.3753 55.7816 52.7301 55.7816Z'
        fill='#335566'
      />
      <path
        d='M53.6582 51.837C53.4169 52.0969 53.092 52.2454 52.7301 52.2454C52.0433 52.2454 51.4771 51.6793 51.4771 50.9925C51.4771 50.6955 51.5792 50.417 51.7648 50.185C51.9876 49.9251 51.9504 49.5353 51.6906 49.3125C51.4307 49.0898 51.0409 49.1269 50.8181 49.3868C50.4469 49.8323 50.2334 50.3984 50.2334 50.9832C50.2334 52.3568 51.3472 53.4706 52.7208 53.4706C53.4169 53.4706 54.0944 53.1736 54.5585 52.6538C54.7905 52.4032 54.772 52.0134 54.5214 51.7814C54.2801 51.5679 53.8902 51.5864 53.6582 51.837Z'
        fill='#335566'
      />
      <path
        d='M64.3781 51.2523H61.6865C61.3431 51.2523 61.0647 51.5308 61.0647 51.8742C61.0647 52.2176 61.3431 52.496 61.6865 52.496H64.3781C64.7215 52.496 65 52.2176 65 51.8742C65 51.5308 64.7215 51.2523 64.3781 51.2523Z'
        fill='#335566'
      />
      <path
        d='M46.1497 31.5295C46.4653 31.6501 46.8272 31.4923 46.9479 31.1675L49.8437 23.5196C49.9365 23.269 49.8622 22.9906 49.6488 22.8142C49.4446 22.6472 49.1476 22.6286 48.9248 22.7771L43.3096 26.434L41.1192 13.1988C41.0728 12.9111 40.8315 12.6976 40.553 12.679C40.2653 12.6604 40.0054 12.8461 39.9219 13.1152L36.0701 25.8585L28.9513 20.2897C28.7379 20.1227 28.4408 20.1134 28.2274 20.2619C28.0046 20.4104 27.9025 20.6888 27.9768 20.9487L30.2414 28.6615L25.2759 28.2995C25.0346 28.281 24.8211 28.4016 24.7005 28.6058C24.5798 28.81 24.5891 29.0606 24.719 29.2555L27.0951 32.9031C27.2157 33.0887 27.4106 33.1815 27.6148 33.1815C27.7262 33.1815 27.8468 33.1537 27.9489 33.0794C28.2367 32.8938 28.3202 32.5133 28.1253 32.2256L26.4268 29.6268L31.0396 29.9609C31.2438 29.9702 31.4387 29.8866 31.5687 29.7289C31.6986 29.5711 31.7357 29.3576 31.68 29.1627L29.7124 22.443L36.0237 27.3807C36.1815 27.5106 36.395 27.5477 36.5899 27.4828C36.7848 27.4178 36.9333 27.2693 36.9983 27.0744L40.3395 16.011L42.2422 27.557C42.2794 27.7612 42.4093 27.9376 42.6042 28.0211C42.7991 28.1046 43.0126 28.0861 43.1889 27.9747L48.0152 24.8283L45.7877 30.7034C45.667 31.0561 45.8341 31.4088 46.1497 31.5295Z'
        fill='#335566'
      />
    </svg>
  );
};

export default ParkschadenIcon;
