import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const EmailIcon = ({
  width = 26,
  height = 18,
  color: colorProp,
  fill: fillProp,
  hovered,
  strokeWidth = '1.2'
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.0873 0.928329H2.18508C1.33349 0.928329 0.636736 1.62501 0.636736 2.47652L0.63623 7.78255V15.5235C0.63623 16.375 1.33361 17.0717 2.18519 17.0717H13.5312H23.0874C23.939 17.0717 24.6357 16.375 24.6357 15.5235L24.6362 10.2174L24.6361 7.78255L24.6356 2.47652C24.6356 1.62501 23.9389 0.928329 23.0873 0.928329Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <>
          <path
            d='M0.63623 4.39578L11.6198 9.708C12.2585 10.0176 13.0036 10.0176 13.6423 9.708L24.6355 4.39578'
            fill={fill}
          />
          <path
            d='M0.63623 4.39578L11.6198 9.708C12.2585 10.0176 13.0036 10.0176 13.6423 9.708L24.6355 4.39578V1.893L23.3453 0.950165H1.85864L0.63623 1.893V4.39578Z'
            fill={fill}
          />
        </>
      )}
      <path
        d='M2.18519 17.0717C1.33361 17.0717 0.63623 16.375 0.63623 15.5235V7.78255L0.636736 2.47652C0.636736 1.62501 1.33349 0.928329 2.18508 0.928329H23.0873C23.9389 0.928329 24.6356 1.62501 24.6356 2.47652L24.6361 7.78255L24.6362 10.2174L24.6357 15.5235C24.6357 16.375 23.939 17.0717 23.0874 17.0717H2.18519ZM2.18519 17.0717H13.5312'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.63623 4.39577L11.6198 9.70799C12.2585 10.0176 13.0036 10.0176 13.6423 9.70799L24.6355 4.39577'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.954346 16.3593L9.62172 8.83956M15.6711 8.83956L24.3384 16.3593'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
