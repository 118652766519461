import { connect } from 'react-redux';
import LeadForm from './lead-form-view';
import { toggleModal } from '../../actions/app_action';
import { MODALS } from '../../constants';

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(toggleModal(MODALS.general_contact_form, null));
  }
});

export default connect(null, mapDispatchToProps)(LeadForm);
