import React from 'react';
import PropTypes from 'prop-types';
import { removeSpaces } from '../utils/remove-spaces';

function Select({ options, selectProps }) {
  return (
    <select {...selectProps}>
      {options.map(option => (
        <option id={removeSpaces(option.value) + 'Option'} key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  selectProps: PropTypes.object
};

export default Select;
