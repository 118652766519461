import { UnSaveModalRefContext } from 'context/un-save-modal/un-save-modal-ref.context';
import { useContext, useEffect } from 'react';

export const useOnUnmountForm = () => {
  const { isFormsDirty, isFormsValid } = useContext(UnSaveModalRefContext);

  const unmountHandler = () => {
    isFormsDirty.current = false;
    isFormsValid.current = false;
  };

  useEffect(() => unmountHandler, []);
};
