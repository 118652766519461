import React, { useEffect, useState } from 'react';
import { PhaseSwitchProps } from './phase-switch.types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { Flex, Text } from 'common/common-components.styled';
import { StyledSwitch } from 'components/form-hook/form-field/form-field.styled';
import { WidgetContentText } from '../../../components/leasing-widget/leasing-widget.styled';
import { usePhaseSwitch } from './use-phase-switch';

const PhaseSwitch: React.FC<PhaseSwitchProps> = ({
  description,
  openDoNotExtendModal,
  isFifthPhase,
  updateToFifthPhase
}) => {
  const { checked, handleChange } = usePhaseSwitch(openDoNotExtendModal, isFifthPhase, updateToFifthPhase);

  return (
    <Flex direction='column' gap='10px'>
      <Flex>
        <WidgetContentText dangerouslySetInnerHTML={{ __html: description }} />
      </Flex>

      <Flex align='center' left='-10'>
        <StyledSwitch checked={checked} onChange={e => handleChange(e.target.checked)} />

        <Text size='12'>Nicht verlängern</Text>
      </Flex>
    </Flex>
  );
};

export default PhaseSwitch;
