import React from 'react';
import PropTypes from 'prop-types';

export const PhoneIcon = ({ color = '#335566', className, width = '20', height = '20' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.714288 0C0.327146 0 2.8263e-06 0.317143 2.8263e-06 0.704286C-0.00571146 11.3457 8.65429 20.0057 19.2957 20C19.6829 20 20 19.6729 20 19.2857V15.5957C20 14.9043 19.4871 14.2943 18.7971 14.2357C17.25 14.1057 15.7743 13.7257 14.4071 13.14C13.7929 12.8771 13.0786 13.0386 12.6986 13.5886C12.0571 14.5114 11.39 15.6143 11.39 15.6143C8.44143 13.9871 6.00143 11.5629 4.37143 8.61714C4.37143 8.61714 5.48429 7.94714 6.41143 7.30429C6.96143 6.92428 7.12286 6.20857 6.86 5.59429C6.27429 4.22571 5.89429 2.75 5.76429 1.20143C5.70714 0.512857 5.09715 0 4.40429 0H0.714288Z'
        fill={color}
      />
    </svg>
  );
};

PhoneIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
