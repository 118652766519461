import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

const useQuery = () => {
  const location = useLocation();
  const query = useMemo(() => parse(location.search), [location]);
  return query;
};

export default useQuery;
