import React from 'react';
import { crmDataTypes } from '../../constants';

export const deactivatingCarFormField = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'reason',
          label: 'Grund für die Deaktivierung/Aussteuerung',
          type: 'optionSet',
          prefix: <img alt='eco_budget' src='/assets/images/eco_budget_icon.svg' />,
          placeholder: 'Bitte auswählen',
          crmFieldType: crmDataTypes.Picklist,
          options: [
            {
              label: 'Leasingende'
            },
            {
              label: 'Totalschaden'
            },
            {
              label: 'Sonstiges'
            }
          ],
          validation: [{ name: 'required' }]
        }
      ]
    }
  ]
};

export const deactivatingCarDetailedDescriptionFormField = {
  columns: [
    {
      width: '100%',
      fields: [
        {
          name: 'description',
          label: 'Detaillierte Beschreibung',
          type: 'textarea',
          placeholder: 'Text',
          crmFieldType: crmDataTypes.String,
          validation: [{ name: 'required' }]
        }
      ]
    }
  ]
};
