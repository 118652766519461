import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { ResponseModel } from 'common/interfaces';
import { FleetLeaseEndQueryKeys } from '../fleet-lease-end.props';
import { fleetLeaseEndRequestConfig } from 'request-config/fleet-lease-end/fleet-lease-end.request-config';
import { GetVehicleListVResponse } from './get-vehicle-list-vr.props';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { AxiosResponse } from 'axios';

export const useGetVehicleListVRQuery = ({
  options
}: QueryHookOptions<
  AxiosResponse<ResponseModel<GetVehicleListVResponse>>,
  ResponseModel<GetVehicleListVResponse>
> = {}) => {
  const { fetch } = useFetch<ResponseModel<GetVehicleListVResponse>>({
    loadingKey: 'getVehicleListVR',
    ...fleetLeaseEndRequestConfig.getVehicleListVR()
  });

  const { data, ...restQueryProps } = useQuery(FleetLeaseEndQueryKeys.GetVehicleListVR, () => fetch(), options);

  return {
    getVehicleListVRQuery: data?.data?.data || defaultValue,
    ...restQueryProps
  };
};

const defaultValue: GetVehicleListVResponse = {
  fuhrparkList: []
};
