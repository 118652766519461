import React, { useEffect, useCallback, useState } from 'react';
import classes from './file.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadDocuments } from '../../../actions/app_action';
import { useDropzone } from 'react-dropzone/dist/es';
import { ONE_MEGABYTE_IN_BYTES } from '../../../constants';

export const File = ({
  placeholder,
  dropzoneSetup,
  onChange,
  error,
  maxNumberOfFiles = Infinity,
  className,
  containerClassName,
  icon
}) => {
  const documentFiles = useSelector(state => state?.app?.uploadDocuments) || [];
  const dispatch = useDispatch();

  const [fileError, setFileError] = useState('');

  const onDrop = useCallback(
    acceptedFiles => {
      if (documentFiles.length < maxNumberOfFiles) {
        dispatch(setUploadDocuments([...documentFiles, ...acceptedFiles]));
      }
    },
    [documentFiles.length]
  );

  const onDropRejected = file => {
    file[0].errors.map(error => {
      if (error.code === 'file-too-large') {
        setFileError(`die Datei wiegt mehr als ${dropzoneSetup.maxSize / ONE_MEGABYTE_IN_BYTES} mb`);
      }
    });
  };

  const { getInputProps } = useDropzone({
    ...dropzoneSetup,
    onDrop,
    onDropRejected
  });

  const getPaceholder = () => {
    const firstFileName = documentFiles[0]?.name;

    if (documentFiles.length === 0) {
      return placeholder ? placeholder : 'Datei hochladen';
    }
    if (documentFiles.length === 1) {
      return firstFileName;
    }

    if (documentFiles.length > 1) {
      return `${documentFiles.length} Dateien`;
    }

    return '';
  };

  useEffect(() => {
    if (documentFiles.length) {
      if (typeof onChange === 'function') {
        onChange(documentFiles);
      }
      setFileError('');
    }
  }, [documentFiles.length]);

  useEffect(() => {
    return () => {
      dispatch(setUploadDocuments([]));
    };
  }, []);

  return (
    <div className={classNames(classes.file_wrapper, containerClassName)}>
      {icon && icon}
      <input
        placeholder={getPaceholder()}
        value={fileError ? fileError : ''}
        className={classNames(
          documentFiles.length ? classes.active_input : classes.input,
          error ? classes.error : null,
          fileError ? classes.error : null,
          className
        )}
        readOnly
      />
      <input id='file' {...getInputProps()} style={uploadFiledStyle} />
    </div>
  );
};

const uploadFiledStyle = {
  margin: 0,
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 0,
  width: '100%',
  height: '100%'
};

File.propTypes = {
  placeholder: PropTypes.string | PropTypes.any,
  dropzoneSetup: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  maxNumberOfFiles: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  icon: PropTypes.any
};
