import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { HistoryModalFieldNames } from '../vehicle-history-modal/history-modal.types';
import { getNearestUnavailableDates, getShouldDisableDateFunc } from 'components/cost-centers-settings/utils';
import { changeVehicleHistoryFormFields } from '../vehicle-history-modal/form-fields/change-vehicle-history.form-fields';
import { UnavailableRange } from '../components/layout/main-tabs/components/tab-panels/vehicle-history/components/sections/cost-centers-widget/cost-centers-widget.types';

export const useHistoryModalFields = (
  reactHookFormData: UseFormReturn,
  unavailableRanges: UnavailableRange[] | undefined
) => {
  const { getValues } = reactHookFormData;
  const [changeVehicleHistoryFields, setChangeVehicleHistoryFields] = useState(changeVehicleHistoryFormFields);

  const validFromValue: Moment | null = getValues(HistoryModalFieldNames.ValidFrom) || null;
  const validToValue: Moment | null = getValues(HistoryModalFieldNames.ValidTo) || null;

  useEffect(() => {
    const newChangeVehicleHistoryFields = cloneDeep(changeVehicleHistoryFields);
    const validToField = newChangeVehicleHistoryFields.find(field => field.name === HistoryModalFieldNames.ValidTo);
    const validFromField = newChangeVehicleHistoryFields.find(field => field.name === HistoryModalFieldNames.ValidFrom);

    if (!(validToField && validFromField && unavailableRanges)) {
      return;
    }

    if (!validToField.shouldDisableDate && !validFromField.shouldDisableDate) {
      validToField.shouldDisableDate = getShouldDisableDateFunc(unavailableRanges);
      validFromField.shouldDisableDate = getShouldDisableDateFunc(unavailableRanges);
    }

    if (
      validFromValue &&
      validFromField.shouldDisableDate &&
      validFromField.shouldDisableDate(moment(validFromValue))
    ) {
      setTimeout(() => {
        reactHookFormData.setError(HistoryModalFieldNames.ValidFrom, {
          message: 'Fahrzeug Historien dürfen sich nicht überschneiden. Bitte wähle einen anderen Wert',
          type: 'manual'
        });
      });
    }

    validToField.maxDate = validFromValue?.isValid()
      ? getNearestUnavailableDates(unavailableRanges, validFromValue).inFuture
      : undefined;

    validToField.minDate = validFromValue?.isValid() ? validFromValue : undefined;

    validFromField.minDate = validToValue?.isValid()
      ? getNearestUnavailableDates(unavailableRanges, validToValue).inPast
      : unavailableRanges.length
      ? moment(
          unavailableRanges[unavailableRanges.length - 1].to || unavailableRanges[unavailableRanges.length - 1].from
        )
          .add(1, 'day')
          .startOf('day')
      : undefined;

    validFromField.maxDate = validToValue?.isValid() ? validToValue.endOf('day') : undefined;

    setChangeVehicleHistoryFields(newChangeVehicleHistoryFields);
  }, [validFromValue, validToValue, unavailableRanges]);

  return { changeVehicleHistoryFields };
};
