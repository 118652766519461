import { encodeBase64 } from 'utils/decode-encode-base64';

export const getConfiguratorData = (
  contactId: string,
  opportunityId: string,
  carPolicyIds: string[],
  uds_baskettypecode: number,
  portalRequest: boolean,
  driverId: string,
  uds_kstverantw: string,
  uds_altes_fahrzeugid: string,
  uds_zuordnung: number,
  uds_laufleistungkm: number | null = null,
  uds_wunschlieferdatum: string | null = null,
  globalReferenceVehicleId: string | null = null
) => {
  return encodeBase64(
    JSON.stringify({
      contactId,
      opportunityId,
      carPolicyIds,
      uds_baskettypecode,
      portalRequest,
      driverId,
      uds_kstverantw,
      uds_altes_fahrzeugid,
      uds_zuordnung,
      uds_laufleistungkm,
      uds_wunschlieferdatum,
      globalReferenceVehicleId
    })
  );
};
