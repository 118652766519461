import React from 'react';
import PropTypes from 'prop-types';

export default function Select({ value, onChange, options }) {
  return (
    <div className='select filter-select'>
      <select
        className='form-control btn btn-default btn-block'
        style={{paddingTop: 0, paddingBottom: 0}}
        value={value}
        onChange={({ target: { value, name } }) => onChange(value, name)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
};
