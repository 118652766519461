import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const CarPriceIcon = ({ width = 20, height = 18, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.3923 7.13558C17.5156 7.46203 17.5809 7.81025 17.5809 8.16572V8.57922V13.0625H5.67629V14.2232C5.67629 14.5424 5.41513 14.8036 5.09593 14.8036H2.7745C2.4553 14.8036 2.19414 14.5424 2.19414 14.2232V12.7143L1.962 11.6043C1.92573 11.4157 1.90396 11.2126 1.90396 11.0167V8.16572C1.90396 7.8175 1.96926 7.46203 2.09259 7.13558L2.3755 6.38837H1.03343C0.844818 6.38837 0.706981 6.21426 0.750508 6.0329L0.968142 5.09707C1.03343 4.83591 1.26558 4.64729 1.534 4.64729H2.95586L4.07306 2.42017C4.3705 1.8253 4.97262 1.45532 5.63278 1.45532H13.8521C14.5123 1.45532 15.1144 1.83256 15.4118 2.42017L16.529 4.64729H17.9509C18.2193 4.64729 18.4587 4.83591 18.5167 5.09707L18.7344 6.0329C18.7779 6.21426 18.6401 6.38837 18.4514 6.38837H17.1094L17.3923 7.13558Z'
        fill={hovered ? 'white' : 'none'}
      />
      {hovered && (
        <path
          d='M17.3923 7.1356C17.5156 7.46205 17.5809 7.81026 17.5809 8.16573V8.57924V13.0625H5.67629V14.2232C5.67629 14.5424 5.41513 14.8036 5.09593 14.8036H2.7745C2.4553 14.8036 2.19414 14.5424 2.19414 14.2232V12.7143L1.962 11.6044C1.92573 11.4157 1.90396 11.2126 1.90396 11.0167V8.16573C1.90396 7.81752 1.96926 7.46205 2.09259 7.1356L2.3755 6.38838H1.03343C0.844818 6.38838 0.706981 6.21427 0.750508 6.03291L0.968142 5.09708C1.03343 4.83592 1.26558 4.64731 1.534 4.64731H2.95586L4.57364 5.76396H14.8727L16.529 4.64731H17.9509C18.2193 4.64731 18.4587 4.83592 18.5167 5.09708L18.7344 6.03291C18.7779 6.21427 18.6401 6.38838 18.4514 6.38838H17.1094L17.3923 7.1356Z'
          fill={fill}
        />
      )}
      <path
        d='M4.51558 13.0625H5.67629V14.2232C5.67629 14.5424 5.41513 14.8036 5.09593 14.8036H2.7745C2.4553 14.8036 2.19414 14.5424 2.19414 14.2232V12.7143L1.962 11.6043C1.92573 11.4157 1.90396 11.2126 1.90396 11.0167V8.16572C1.90396 7.8175 1.96926 7.46203 2.09259 7.13558L2.3755 6.38837H1.03343C0.844818 6.38837 0.706981 6.21426 0.750508 6.0329L0.968142 5.09707C1.03343 4.83591 1.26558 4.64729 1.534 4.64729H2.95586L4.07306 2.42017C4.3705 1.8253 4.97262 1.45532 5.63278 1.45532H13.8521C14.5123 1.45532 15.1144 1.83256 15.4118 2.42017L16.529 4.64729H17.9509C18.2193 4.64729 18.4587 4.83591 18.5167 5.09707L18.7344 6.0329C18.7779 6.21426 18.6401 6.38837 18.4514 6.38837H17.1094L17.3923 7.13558C17.5156 7.46203 17.5809 7.81025 17.5809 8.16572V8.57922'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.65234 9.58035H6.06809L5.65459 8.76059'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.81299 5.80801H14.6791'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='13.3477' cy='12.4797' r='4.065' fill={hovered ? 'white' : 'none'} />
      <path
        d='M5.64697 13.0625H9.34536'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.39233 11.1804C9.93405 9.57437 11.4513 8.41466 13.2405 8.41466C15.4821 8.41466 17.303 10.2355 17.303 12.4772C17.303 14.7188 15.4821 16.5397 13.2405 16.5397C11.8445 16.5397 10.6106 15.832 9.87905 14.7571'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5298 11.244C13.3587 10.1906 12.4064 11.0249 12.1408 11.9622C12.0896 12.1426 12.0865 12.4393 12.0865 12.4393C12.0865 12.4393 12.082 12.7741 12.1408 12.9767C12.4126 13.9147 13.3587 14.7688 14.5298 13.7154'
        stroke={color}
        strokeLinecap='round'
      />
      <path d='M13.3086 12.4772L11.3491 12.4739' stroke={color} strokeLinecap='round' />
    </svg>
  );
};
