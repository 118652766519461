import { useFetch } from 'hooks/use-fetch/use-fetch';
import { sharePointRequestConfig } from 'request-config/sharepoint/sharepoint.request-config';
import { PreparedFilesForDownloadState } from '../sorting-documents-tabs.props';
import { fleetRequestConfig } from 'request-config/fleet/fleet.request-config';
import { b64toBlob, getFormat } from 'components/download-expansion-component/download-expansion-component';
import { snackbarMessage } from 'utils/snackbar-messages';
import { Dispatch, SetStateAction } from 'react';
import { cloneDeep } from 'lodash';

export const useSortingDocumentsDownload = (
  zipArchiveName: string,
  preparedFilesForDownload: PreparedFilesForDownloadState,
  setPreparedFilesForDownload: Dispatch<SetStateAction<PreparedFilesForDownloadState>>
) => {
  const { fetch } = useFetch({
    isShowSuccessSnackbar: true,
    successMessage: snackbarMessage.downloadDocuments.success,
    errorMessage: snackbarMessage.downloadDocuments.error
  });

  const downloadZip = (sectionName: string, filesPathList: string[]) => {
    const clonePreparedFilesForDownload = cloneDeep(preparedFilesForDownload);

    if (clonePreparedFilesForDownload[sectionName]?.length === 1) {
      const preparedFileOneFile = clonePreparedFilesForDownload[sectionName][0];

      fetch<BlobPart>({
        loadingKey: `downloadFile${preparedFileOneFile.fileId}`,
        requestConfig: fleetRequestConfig.getFileForDownload(preparedFileOneFile.filePath),
        callback: response => {
          if (response) {
            const filePathArr = preparedFileOneFile.filePath.split('/');
            const fileName = filePathArr[filePathArr.length - 1];
            const format = getFormat(fileName);
            const blob = b64toBlob(response.data, format);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.click();

            clonePreparedFilesForDownload[sectionName] = [];

            setPreparedFilesForDownload(clonePreparedFilesForDownload);
          }
        }
      });

      return;
    }

    fetch<BlobPart>({
      loadingKey: 'downloadZip',
      requestConfig: sharePointRequestConfig.getZipFilesByPath({
        filesPath: clonePreparedFilesForDownload[sectionName].length
          ? clonePreparedFilesForDownload[sectionName].map(item => item.filePath)
          : filesPathList,
        zipArhiveName: zipArchiveName
      }),
      callback: response => {
        if (response) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(new Blob([response.data]));
          a.download = `${zipArchiveName}.zip`;
          a.click();

          clonePreparedFilesForDownload[sectionName] = [];

          setPreparedFilesForDownload(clonePreparedFilesForDownload);
        }
      }
    });
  };

  return { downloadZip };
};
