import React, { useState } from 'react';
import { TabControllerData } from 'components/tab/tab.props';
import { useVehicleHistorySectionsConditions } from '../../../hooks/use-sections-conditions';
import HistoryWidget from '../../sections/history-widget/history-widget';
import { Flex } from 'common/common-components.styled';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import CostCentersWidget from '../../sections/cost-centers-widget';
import { VehicleDetailsDamageHistory } from '../../sections/damage/damage';

export const useMobileVersionHistoryTabData = () => {
  const [isExpandedHistory, setIsExpandedHistory] = useState({ costCenter: false });

  const { isDisplayDriverHistory, isShowDamageHistory, isDisplayDamageHistoryShoreMoreButton } =
    useVehicleHistorySectionsConditions();

  const mobileVersionHistoryTabData: TabControllerData[] = [
    {
      tab: 'Fahrer Historie',
      panel: <HistoryWidget />,
      isHide: !isDisplayDriverHistory,
      id: 'driverHistoryTab'
    },
    {
      tab: 'Schaden Historie',
      panel: (
        <Flex direction='column' top='5'>
          <VehicleDetailsDamageHistory hideTitle isExpanded={isExpandedHistory.costCenter} />
          {isDisplayDamageHistoryShoreMoreButton && (
            <ShowMoreButton
              isExpanded={isExpandedHistory.costCenter}
              onClick={() => setIsExpandedHistory(prevState => ({ ...prevState, costCenter: !prevState.costCenter }))}
              bottom='15'
            />
          )}
        </Flex>
      ),
      isHide: !isShowDamageHistory,
      id: 'damageHistoryTab'
    },
    {
      tab: 'Kostenstellen Historie',
      panel: <CostCentersWidget />,
      id: 'costCenterHistoryTab'
    }
  ];
  return { mobileVersionHistoryTabData };
};
