import { DriverWireframeIds, FleetManagerWireframeIds } from 'components/product/product-view.props';
import { OFFER_STATUSES } from '../../constants';

export const getTileNumber = (
  vehicleOfferStatus: number,
  isNewDriverConfigurationPage: boolean,
  isDriverConfigurationPage: boolean,
  isNewEmployeeConfigurationsPage: boolean,
  isEmployeeConfigurationsPage: boolean
) => {
  if (isNewDriverConfigurationPage) {
    return DriverWireframeIds.Dr01;
  }
  if (isDriverConfigurationPage && vehicleOfferStatus === OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated) {
    return DriverWireframeIds.Dr02;
  }
  if (isDriverConfigurationPage) {
    return DriverWireframeIds.Dr03;
  }
  if (isNewEmployeeConfigurationsPage) {
    return FleetManagerWireframeIds.Fm01;
  }
  if (isEmployeeConfigurationsPage && vehicleOfferStatus === OFFER_STATUSES.vehicleConfigurationBasedOnGRVCreated) {
    return FleetManagerWireframeIds.Fm02;
  }
  if (isEmployeeConfigurationsPage && vehicleOfferStatus === OFFER_STATUSES.vehicleRequested) {
    return FleetManagerWireframeIds.Fm03;
  }
  if (isEmployeeConfigurationsPage && vehicleOfferStatus === OFFER_STATUSES.dealershipSelected) {
    return FleetManagerWireframeIds.Fm04;
  }
  if (isEmployeeConfigurationsPage && vehicleOfferStatus === OFFER_STATUSES.buildAbilityConfirmed) {
    return FleetManagerWireframeIds.Fm05;
  }
  if (isEmployeeConfigurationsPage && vehicleOfferStatus === OFFER_STATUSES.approvedByFleetManager) {
    return FleetManagerWireframeIds.Fm06;
  }
  return FleetManagerWireframeIds.Fm07;
};
