import React, { cloneElement, MouseEvent, useContext } from 'react';
import classes from '../cell.module.scss';
import classNames from 'classnames';
import Button from 'components/button';
import { CellProps, ICellContext } from 'components/table-component/table-component.props';
import { CellContext } from 'components/table-component/table-component-contexts';

export const ButtonCell = ({ column, row }: Pick<CellProps, 'column' | 'row'>) => {
  const { cellId } = useContext<ICellContext>(CellContext);

  const buttonHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (typeof column.action === 'function' && column.actionParameter) {
      column.action(row[column.actionParameter]);
    }
  };

  return (
    <span
      id={cellId}
      style={{ width: column.width }}
      className={classNames(classes.table_button, column.className, classes.cell)}
    >
      {column.component ? (
        cloneElement(column.component, { row, column })
      ) : (
        <Button onClick={buttonHandler}>
          {column.icon && (
            <>
              {column.icon}
              <div className='mr-1' />
            </>
          )}
          {column.label}
        </Button>
      )}
    </span>
  );
};
