import { useContext, useEffect, useState } from 'react';
import { Moment } from 'moment';
import { ChangeCostCenterFieldNames } from '../change-cost-center/change-cost-center.types';
import cloneDeep from 'lodash/cloneDeep';
import useCostCenterUnavailableRanges from '../hooks/use-cost-center-available-ranges';
import { getChangeCostCenterFields, getNearestUnavailableDates, getShouldDisableDateFunc } from '../utils';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { ChangeCostCenterContext } from '../change-cost-center/change-cost-center.context';

const useChangeCostCenterFields = (reactHookFormData: UseFormReturn) => {
  const { entityName, id, item } = useContext(ChangeCostCenterContext);
  const { getValues } = reactHookFormData;
  const [changeCostCenterFields, setChangeCostCenterFields] = useState(
    getChangeCostCenterFields(!!item, !!item?.isFirstItem)
  );

  const { unavailableRanges } = useCostCenterUnavailableRanges(
    entityName,
    id,
    item?.uds_cost_center_historyid || undefined
  );

  const validFromValue: Moment | null = getValues(ChangeCostCenterFieldNames.ValidFrom) || null;
  const validToValue: Moment | null = getValues(ChangeCostCenterFieldNames.ValidTo) || null;

  useEffect(() => {
    const newChangeCostCenterFields = cloneDeep(changeCostCenterFields);
    const validToField = newChangeCostCenterFields.find(field => field.name === ChangeCostCenterFieldNames.ValidTo);
    const validFromField = newChangeCostCenterFields.find(field => field.name === ChangeCostCenterFieldNames.ValidFrom);
    if (!(validToField && validFromField && unavailableRanges)) {
      return;
    }

    if (!validToField.shouldDisableDate && !validFromField.shouldDisableDate) {
      validToField.shouldDisableDate = getShouldDisableDateFunc(unavailableRanges);
      validFromField.shouldDisableDate = getShouldDisableDateFunc(unavailableRanges);
    }

    validToField.maxDate = validFromValue?.isValid()
      ? getNearestUnavailableDates(unavailableRanges, validFromValue).inFuture
      : undefined;
    validToField.minDate = validFromValue?.isValid() ? validFromValue : undefined;

    validFromField.minDate = validToValue?.isValid()
      ? getNearestUnavailableDates(unavailableRanges, validToValue).inPast
      : undefined;
    validFromField.maxDate = validToValue?.isValid() ? validToValue.endOf('day') : undefined;

    setChangeCostCenterFields(newChangeCostCenterFields);
  }, [validFromValue, validToValue, JSON.stringify(unavailableRanges)]);

  return { changeCostCenterFields };
};

export default useChangeCostCenterFields;
