import { UNAUTHORIZED_PAGES } from 'common/enums';
import { useLocation } from 'react-router-dom';

export const useReportDamageIsCustomLink = () => {
  const location = useLocation();

  const getIsReportDamageIsCustomLink = () => {
    const replacedLocation = location.pathname.replace(UNAUTHORIZED_PAGES.REPORT_DAMAGE, '');
    return !!replacedLocation && replacedLocation !== '/';
  };

  return { isReportDamageCustomLink: getIsReportDamageIsCustomLink() };
};
