import React from 'react';
import { Gallery } from '../image-gallery/gallery';
import { VehicleGalleryProps, VehicleTypeId } from './vehicle-gallery.props';
import { PLACEHOLDER_IMAGES } from './utils';

export const VehicleGallery = ({ typeId = VehicleTypeId.PersonalCar, ...restProps }: VehicleGalleryProps) => {
  return (
    <Gallery
      {...restProps}
      placeholder={PLACEHOLDER_IMAGES[typeId] || PLACEHOLDER_IMAGES[VehicleTypeId.PersonalCar]}
      className='gallery_fahrzeug_class'
      fullScreenClassName='gallery_fahrzeug_class_fullscreen'
    />
  );
};
