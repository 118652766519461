import { WidgetTile } from 'components/widgets/tile/tile';
import styled from 'styled-components';

export const StyledVehicleCostWidgetTile = styled(WidgetTile)`
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 100%;
`;

export const StyledVehicleCostWidgetViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
