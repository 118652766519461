import { AxiosRequestConfig } from 'axios';

class ProductRequestConfig {
  getUserProduct: AxiosRequestConfig = {
    method: 'POST',
    url: 'product/getUserProduct',
    data: {}
  };

  getAccountProducts = (accountId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'product/getAccountProducts',
    data: { accountId }
  });
}

export const productRequestConfig = new ProductRequestConfig();
