import { setFilterOptions } from 'actions/app_action';
import { AxiosResponse } from 'axios';
import { ResponseModel, AnyObject } from 'common/interfaces';
import { get } from 'lodash';
import { Dispatch } from 'react';
import { parseTags } from 'utils/dynamics-response-parsers';
import { getResponseFormData } from 'utils/get-response-data';
import { getSharepointLocation } from 'utils/get-sharepoint-location';
import { Configurations } from './vehicle-offers-list-component.props';

export const modifyResponse = ({ data }: AxiosResponse<ResponseModel<Configurations>>): Configurations => {
  const response: Configurations = {
    items: [],
    count: 0
  };

  if (data.data) {
    const convertItems = getResponseFormData(data, [{ name: 'items', type: 'array' }], true);

    if (Array.isArray(convertItems)) {
      response.items = convertItems;
      response.count = data.data.count;
    }
  }

  if (response.items) {
    response.items.forEach((item: AnyObject) => {
      item.engineType = {
        name: item.uds_enginetypecode,
        value: item.uds_enginetypecode_value
      };
      item.tags = parseTags(get(item, 'uds_tags', ''));
      item.sharepointLocation = getSharepointLocation(item);
    });
  }

  return response;
};

export const modifyFilterDataResponse = (
  { data }: AxiosResponse<ResponseModel>,
  dispatch: Dispatch<AnyObject>
): AnyObject => {
  if (data.data) {
    dispatch(setFilterOptions(data.data));
  }

  return data.data;
};
