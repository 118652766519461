import { AxiosRequestConfig } from 'axios';
import {
  GetFileForDownloadParams,
  FilterDataParams,
  GetExcelCompanyProps,
  GetLeaseEndExcelProps,
  SetKilometersProps,
  UploadFilesProps,
  SetInProgressProps
} from './fleet.interface';
import { getRequestItem } from 'utils/get-response-data';
import { CrmDataTypes } from 'common/enums';
import { CrmResponse } from 'common/interfaces';

class FleetRequestConfig {
  getFileForDownload = (params: GetFileForDownloadParams): AxiosRequestConfig => ({
    method: 'get',
    url: `fuhrpark/filebyname?filerelativeurl=${encodeURIComponent(params)}`,
    responseType: 'arraybuffer'
  });

  getExcelCompany = ({
    ContactId,
    Pagination = { number: 100, start: 1 },
    Sort = {},
    Search = {},
    FieldsOrderType = 0
  }: GetExcelCompanyProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/getexcelcompanylist',
    data: {
      ContactId,
      Pagination,
      Sort,
      Search,
      FieldsOrderType
    },
    responseType: 'blob'
  });

  getFilterData = (params: FilterDataParams): AxiosRequestConfig => ({
    method: 'post',
    url: 'fuhrpark/getFuhrparkListFilterData',
    data: params
  });

  getLeaseEndExcel = (data: GetLeaseEndExcelProps): AxiosRequestConfig => ({
    method: 'post',
    url: 'fuhrpark/getLeaseEndExcel',
    responseType: 'blob',
    data: data
  });

  unusedFleet = (ContactId: string): AxiosRequestConfig => ({
    method: 'post',
    url: 'fuhrpark/unusedfuhrapks',
    data: { ContactId }
  });

  descriptionChoices: AxiosRequestConfig = {
    method: 'get',
    url: 'fuhrpark/descriptionchoices'
  };

  setKilometers = (data: SetKilometersProps): AxiosRequestConfig => ({
    method: 'post',
    url: 'fuhrpark/CreateKmStand',
    data: data
  });

  getVehicleCosts = (fuhrparkId: string): AxiosRequestConfig => ({
    method: 'post',
    url: 'fuhrpark/GetFahrzeugkosten',
    data: { fuhrparkId }
  });

  getCalendarAppointment = (vehicleId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/GetTerminkalender',
    data: { fuhrparkId: vehicleId }
  });

  getLeasingPeriod = (vehicleId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/GetLeasingdauer',
    data: { fuhrparkId: vehicleId }
  });

  getById = (vehicleId?: string): AxiosRequestConfig => ({
    method: 'GET',
    url: `fuhrpark/${vehicleId}`
  });

  updateFleet = (fleetId: string, submitModel: Record<string, CrmResponse<any>>): AxiosRequestConfig => ({
    method: 'PUT',
    url: 'fuhrpark',
    data: {
      Fuhrpark: {
        new_fuhrparkid: getRequestItem(fleetId, CrmDataTypes.UniqueIdentifier),
        ...submitModel
      }
    }
  });

  filesById = (vehicleId?: string): AxiosRequestConfig => ({
    method: 'GET',
    url: `fuhrpark/AllFiles?FuhrparkId=${vehicleId}`
  });

  getPoolCarClasses = (fuhrparkId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/GetPoolcarClasses',
    data: { fuhrparkId }
  });

  getDamageHistory = (fuhrparkId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/GetSchadenHistory',
    data: { fuhrparkId }
  });

  getEmailList = (fuhrparkid: string): AxiosRequestConfig => ({
    method: 'GET',
    url: 'fuhrpark/getemailslist',
    params: { fuhrparkid }
  });

  uploadFiles = ({ vehicleId, files, documentType }: UploadFilesProps) => {
    const data = new FormData();

    data.append('FuhrparkId', vehicleId);

    files.forEach(file => {
      data.append('CustomFiles', file);
      data.append('CustomDescription', documentType);
    });

    return { method: 'POST', url: 'fuhrpark/uploadfiles', data };
  };

  getDataQuality = (fuhrparkid: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/GetDatenqualitat',
    data: { fuhrparkid }
  });

  updateMainCar = (fuhrparkId: string, isMainCar: boolean): AxiosRequestConfig => ({
    method: 'POST',
    url: 'fuhrpark/updateHauptauto',
    data: { fuhrparkId, isMainCar }
  });
  setInProgress = (data: SetInProgressProps) => ({
    method: 'POST',
    url: 'fuhrpark/SetInBetrieb',
    data
  });
}

export const fleetRequestConfig = new FleetRequestConfig();
