import React, { useState, useEffect } from 'react';
import { Text, Button, Flex, Distance } from 'common/common-components.styled';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { formFieldsProtocol, formFieldsDate } from './upload-protocol-form-fields';
import UploadFile from 'components/setup-assistant/upload-document/upload-file/upload-file-view';
import { isEmpty } from 'lodash';
import moment from 'moment';
import InfoHint from 'components/info-hint/info-hint-view';

interface UploadProtocolProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (isWithoutProtocol: boolean, returnDate: string, files: File[]) => void;
}

export const UploadProtocol = ({ onSubmit }: UploadProtocolProps) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors
  } = reactHookFormData;

  const isNoProtocol = watch('isNoProtocol');
  const currentFiles = watch('protocol');

  const changeFiles = (files: File[]) => {
    setValue('protocol', files);
  };

  const submit = () => {
    handleSubmit(data => {
      const returnDate = moment(data.dateVehicleReturned).format('YYYY-MM-DDTHH:mm:ss');
      onSubmit(isNoProtocol, returnDate, data.protocol);
    })();
  };

  useEffect(() => {
    if (!currentFiles) {
      return;
    }

    if ((!isNoProtocol && !currentFiles.length) || currentFiles.length > 5) {
      setError('protocol', { type: 'custom', message: 'Bitte auswählen' });
    } else {
      clearErrors('protocol');
    }
  }, [clearErrors, currentFiles, errors, isNoProtocol, setError]);

  return (
    <Flex direction='column'>
      <Text color='black' bottom='30'>
        Bitte lade Dein Übergabeprotokoll hoch.
      </Text>
      <UploadFile
        onDropAccepted={files => changeFiles(files)}
        accept={['application/pdf']}
        acceptString={'pdf'}
        unfallreparatur={true}
        multiple={true}
        className={null}
        maxSize={10485760}
        maxFiles={5}
        maxSizeString='10 MB'
        disabled={isNoProtocol}
        clearAll={isNoProtocol}
      />
      <FormHook reactHookFormData={reactHookFormData} formFields={formFieldsProtocol} distanceBetweenFields='0' />
      {isNoProtocol && (
        <>
          <InfoHint type='info'>
            <Text>{HINT_TEXT}</Text>
          </InfoHint>
          <Distance top='30' />
        </>
      )}
      <FormHook reactHookFormData={reactHookFormData} formFields={formFieldsDate} />
      <Flex justify='flex-end'>
        <Button disabled={!isEmpty(errors)} onClick={submit} id={'saveProtocol'}>
          Speichern
        </Button>
      </Flex>
    </Flex>
  );
};

// eslint-disable-next-line max-len
const HINT_TEXT =
  'Bitte wählen Sie diese Option nur, wenn Sie sicher sind, dass Sie ein Übergabeprotokoll wirklich nicht haben.';
