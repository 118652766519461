import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import Select from './select';
import store from '../../../store';
import { changeSearch } from '../../../actions/app_action';
import { CAR_TYPE_CODES } from '../../../constants';

const carCategoryOptions = [
  { value: '', label: 'Alle Fahrzeugkategorien' },
  { value: CAR_TYPE_CODES.Kleinwagen, label: 'Kleinwagen' },
  { value: CAR_TYPE_CODES.Kompaktklasse, label: 'Kompaktklasse' },
  { value: CAR_TYPE_CODES.Mittelklasse, label: 'Mittelklasse' },
  { value: CAR_TYPE_CODES.Gehobene_Mittelklasse, label: 'Gehobene Mittelklasse' },
  { value: CAR_TYPE_CODES.Obere_Mittelklasse, label: 'Obere Mittelklasse' },
  { value: CAR_TYPE_CODES.Luxusklasse, label: 'Luxusklasse' },
  { value: CAR_TYPE_CODES.Bus, label: 'Bus' },
  { value: CAR_TYPE_CODES.Van, label: 'Van' },
  { value: CAR_TYPE_CODES.Kleintransporter, label: 'Kleintransporter' },
  { value: CAR_TYPE_CODES.Transporter, label: 'Transporter' }
];

const mapStateToProps = state => ({
  value: state.app.smartTable.search.predicateObject.carCategory || '',
  options: carCategoryOptions
});

const mapDispatchToProps = dispatch => ({
  onChange(value, name) {
    const state = store.getState();
    const search = cloneDeep(state.app.smartTable.search);
    search.predicateObject.carCategory = value || null;
    dispatch(changeSearch(search));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Select);

