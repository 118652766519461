import { User, UserRole } from 'common/interfaces';
import { MainMenuItem, SubMenuItem } from 'components/side-menu/side-menu-interfaces';

type MenuItem = Partial<Pick<MainMenuItem, 'roles' | 'links' | 'subMenuItems'> & Pick<SubMenuItem, 'external'>>;

const fillMenuItemsWithRoles = <T extends MenuItem>(menuItems: T[], user: User) => {
  menuItems.forEach(menuItem => {
    if (menuItem.roles && menuItem.links) {
      let roles: UserRole[] = [];
      menuItem.links.forEach(link => {
        if (user.appPages.some(appPage => appPage.url === link)) {
          roles = [...roles, user.mainRole];
        }
      });

      menuItem.roles = Array.from(new Set(roles));
      menuItem.links = menuItem.links.filter(link => user.appPages.some(appPage => appPage.url === link));
    }
    if (menuItem.subMenuItems) {
      menuItem.subMenuItems = fillMenuItemsWithRoles(menuItem.subMenuItems, user);
    }
  });

  return menuItems;
};

export default fillMenuItemsWithRoles;
