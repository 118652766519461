import React from 'react';
import axios from 'axios';
import CarImage, { CarManufacturer } from './table-components';
import { AnyObject } from 'common/interfaces';
import { API_ENDPOINT, isMobileWidth } from '../../constants';
import { emtyFilterState, emtyFilterStateForServer } from './my-vehicles';
import { VehicleStatusesLabel } from './my-vehicles.props';
import { DriverCell, DriverStatus, ModalMenu } from './table-components';
import authService from 'services/auth-service';
import { ColumnsVariant, ColumnType } from '../../components/table-component/table-component.props';
import { TABLE_MENU_WIDTH } from 'components/table-component/table-component';
import { CRM_VALUE } from 'pages/my-vehicles/my-vehicles';
import { AskQuestion } from 'pages/my-vehicles/table-components/ask-question/ask-question';
import {
  StateCodeLabelEnum,
  StateCodeValueEnum
} from 'components/driving-license-control-component/driving-license-control.props';
import VehicleCell from './table-components/vehicle-cell/vehicle-cell';

export const deactivatingCar = async (id: string, reason: string, description: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fuhrpark/deactivate`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      id,
      reason,
      description
    }
  });
};

export const getDefaultFilterState = () => {
  let dataForServer: AnyObject = {
    ...emtyFilterStateForServer,
    [CRM_VALUE.stateCode]: [StateCodeValueEnum.Active]
  };

  let dataForClient: AnyObject = {
    ...emtyFilterState,
    [CRM_VALUE.stateCode]: [StateCodeLabelEnum.Active]
  };

  if (isMobileWidth) {
    dataForServer = emtyFilterStateForServer;
    dataForClient = emtyFilterState;
  }

  return {
    dataForServer,
    dataForClient
  };
};

export const getChipColor = (status: VehicleStatusesLabel) => {
  if (status === VehicleStatusesLabel.Ordered || status === VehicleStatusesLabel.InOperation) {
    return 'green';
  }
  if (status === VehicleStatusesLabel.InControl || status === VehicleStatusesLabel.ControlRequested) {
    return 'darkGold';
  }

  if (status === VehicleStatusesLabel.Controlled) {
    return 'grey600';
  }

  return 'grey300';
};

export const getColums = (openCreateQuestionModal: (id: string) => any): ColumnType[] => [
  {
    name: 'Fahrzeugbild',
    type: ColumnsVariant.Image,
    component: <CarImage />,
    sort: false,
    width: '10%',
    columnId: 'carImage'
  },
  {
    name: 'Mobexo Car-ID',
    type: ColumnsVariant.Text,
    propName: 'uds_modexocarinternalid',
    sort: true,
    sortName: 'uds_modexocarinternalid',
    width: '6%',
    columnId: 'mobexoCarId'
  },
  {
    name: 'Unternehmen',
    type: ColumnsVariant.Text,
    propName: 'new_sparteid',
    sort: true,
    sortName: 'new_sparteid',
    width: '10%',
    columnId: 'companyId'
  },
  {
    name: 'Standort',
    type: ColumnsVariant.Text,
    propName: 'new_standortid',
    sort: true,
    sortName: 'new_standortid',
    width: '10%',
    columnId: 'location'
  },
  {
    name: 'Kennzeichen',
    type: ColumnsVariant.Text,
    component: <VehicleCell />,
    propName: 'new_name',
    sort: true,
    sortName: 'new_name',
    width: '8%',
    columnId: 'carNumber'
  },
  {
    name: 'Fahrzeug',
    type: ColumnsVariant.Text,
    component: <CarManufacturer />,
    sort: false,
    width: '10%',
    columnId: 'carManufacturer'
  },
  {
    name: 'Erstzulassung',
    type: ColumnsVariant.Date,
    propName: 'new_ezl',
    sort: true,
    sortName: 'new_ezl',
    width: '10%',
    columnId: 'firstRegistration'
  },
  {
    name: 'Kostenstelle',
    type: ColumnsVariant.Text,
    propName: 'new_kostenstelleid',
    sort: true,
    sortName: 'new_kostenstelleid',
    width: '10%',
    columnId: 'costCenter'
  },
  {
    name: 'Fahrer',
    type: ColumnsVariant.Text,
    propName: 'new_fahrerid',
    component: <DriverCell />,
    sort: false,
    width: '10%',
    columnId: 'driver'
  },
  {
    name: 'Leasingrückgabe',
    type: ColumnsVariant.Date,
    propName: 'uds_lease_return_date',
    sort: true,
    sortName: 'uds_lease_return_date',
    width: '10%',
    columnId: 'leaseReturn'
  },
  {
    name: 'LVN',
    type: ColumnsVariant.Text,
    propName: 'new_lvn',
    sort: true,
    sortName: 'new_lvn',
    width: '10%',
    columnId: 'lvn'
  },
  {
    name: 'Status',
    type: ColumnsVariant.Chip,
    component: <DriverStatus />,
    propName: 'new_status_carcode',
    sort: true,
    sortName: 'new_status_carcode',
    width: '7%',
    columnId: 'status'
  },
  {
    name: '',
    type: ColumnsVariant.Button,
    component: <AskQuestion />,
    actionParameter: 'new_fuhrparkid',
    action: (id: string) => openCreateQuestionModal(id),
    sort: false,
    width: '60px',
    columnId: 'askQuestinButton'
  },
  {
    name: '',
    type: ColumnsVariant.Menu,
    sort: false,
    component: <ModalMenu />,
    width: TABLE_MENU_WIDTH,
    columnId: 'moreMenuButton'
  }
];
