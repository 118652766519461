import React, { useContext } from 'react';
import { Distance, Flex } from 'common/common-components.styled';
import { File } from '../../../file/file';
import { OptionSet } from '../../../option-set/option-set';
import { cloneDeep } from 'lodash';
import { OptionSetFileErrorEnum, OptionSetFileValueItem } from '../../option-set-file.props';
import { OptionSetFileContext } from '../../option-set-file.context';
import { OptionSetFileItemProps } from './option-set-file-item.props';
import { OptionSetFileTitle } from './components/title';
import { Autocomplete } from '../../../autocomplete/autocomplete';
import { FormFieldTypes } from 'common/enums';
import { formFieldProvider } from 'components/form-hook/form-field/form-field';
import { FormError } from '../../../common/error/error';
import { useRegisterField } from 'components/form-hook/form-field/hooks/use-register-field/use-register-field';

export const OptionSetFileItem = ({ valueItem, optionError }: OptionSetFileItemProps) => {
  const { reactHookFormData, field } = useContext(formFieldProvider);
  const { values, onChange } = useContext(OptionSetFileContext);

  const {
    formState: { errors },
    clearErrors
  } = reactHookFormData;
  const { name, type, placeholder, dropzoneOptions } = field;

  const fileFieldName = `${name}-file-${valueItem.id}`;

  const changeHandler = (formValue: string | File[], changeKey: keyof OptionSetFileValueItem) => {
    const cloneValues = cloneDeep(values);

    const changedValues: OptionSetFileValueItem[] = cloneValues.map(item => {
      if (item.id === valueItem.id) {
        return { ...valueItem, [changeKey]: formValue };
      }

      return item;
    });

    clearErrors(name + OptionSetFileErrorEnum.Option);

    if (changeKey === 'files') {
      clearErrors(fileFieldName);
    }

    onChange(changedValues);
  };

  useRegisterField({ reactHookFormData, name: fileFieldName });

  return (
    <Flex direction='column' flex={1} minWidth={250}>
      <OptionSetFileTitle valueItem={valueItem} />

      {type === FormFieldTypes.OptionSetFile ? (
        <OptionSet
          name={`${name}-option-${valueItem.id}`}
          value={valueItem.documentType}
          onChange={event => changeHandler(event.target.value, 'documentType')}
        />
      ) : (
        <Autocomplete
          name={`${name}Autocomplete${valueItem.id}`}
          error={optionError}
          value={valueItem.documentType || ''}
          onChange={value => changeHandler(value, 'documentType')}
        />
      )}

      <Distance top='20' />
      <File
        name={fileFieldName}
        value={valueItem.files}
        onChange={value => changeHandler(value, 'files')}
        error={errors[fileFieldName]?.message as string}
        dropzoneOptions={dropzoneOptions}
        placeholder={placeholder}
      />

      <FormError message={errors[fileFieldName]?.message as string | undefined} />
    </Flex>
  );
};
