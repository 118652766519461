import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const FilterExpandIcon = ({ width = 24, height = 24, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.2113 5.61291C14.2113 6.89564 15.2511 7.93549 16.5339 7.93549C17.8166 7.93549 18.8564 6.89564 18.8564 5.61291C18.8564 4.33018 17.8166 3.29033 16.5339 3.29033C15.2511 3.29033 14.2113 4.33018 14.2113 5.61291Z'
        fill={hovered ? fill : 'white'}
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />

      <path
        d='M7.82408 12C7.82408 13.2827 8.86393 14.3226 10.1467 14.3226C11.4294 14.3226 12.4692 13.2827 12.4692 12C12.4692 10.7173 11.4294 9.67743 10.1467 9.67743C8.86393 9.67743 7.82408 10.7173 7.82408 12Z'
        fill='white'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />

      <path
        d='M12.7596 18.3871C12.7596 19.6698 13.7995 20.7097 15.0822 20.7097C16.3649 20.7097 17.4048 19.6698 17.4048 18.3871C17.4048 17.1044 16.3649 16.0645 15.0822 16.0645C13.7995 16.0645 12.7596 17.1044 12.7596 18.3871Z'
        fill={hovered ? fill : 'white'}
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />

      <path
        d='M21.7598 5.61292L20.5139 5.61291'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.4692 5.61292L3.75973 5.61291'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M21.7598 12L14.2114 12'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.17139 12L3.75998 12'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M21.7598 18.3871L19.2391 18.3871'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.0229 18.3871L3.75992 18.3871'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
