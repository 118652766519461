import { formatDate } from 'helpers';
import { IFieldRow } from './components/field-row/field-row.props';
import { IRepairPartnerTable } from 'pages/repair-status/components/repair-status-item/repair-status-item.props';
import { IconColorProps } from 'components/steps-process/steps-process.props';
import { DefaultTheme } from 'styled-components';

export const spinnerStyle = {
  width: '50px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 97
};

export const getStepsProcessIconColor = (theme: DefaultTheme): IconColorProps => ({
  done: theme.colors.blue,
  active: theme.colors.blue,
  inactive: theme.colors.lightBlue
});

export const getFieldRow = ({
  new_kennzeichen,
  title,
  new_schadenuhrzeit
}: Partial<IRepairPartnerTable>): IFieldRow[] => [
  { data: [new_kennzeichen, title], color: 'blue' },
  { data: [formatDate(new_schadenuhrzeit) || undefined, 'Schadennummer'], color: 'midBlue', size: '12', bottom: '25' }
];
