import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse } from 'common/interfaces';
import { carConfiguratorRequestConfig } from 'request-config/car-configurator/car-configurator.request-config';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../constants';
import { VehicleDetails, VehicleDetailsResponse } from './use-car-configurator-vehicle-details.types';

const modifyResponse = ({ data }: ApiResponse<VehicleDetailsResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return {
      ...data.data.fahrzeugangebote.attributes,
      ...data.data.leasingangebote.attributes,
      carPolicyItems: data.data.carPolicyItems.map(item => item.attributes)
    };
  }
  return undefined;
};

const useCarConfiguratorVehicleDetails = (driverId: string, vehicleId: string) => {
  const { fetch } = useFetch<ApiResponse<VehicleDetailsResponse>>({
    isShowLoading: false,
    ...carConfiguratorRequestConfig.getVehicleDetails(driverId, vehicleId)
  });

  const { data: vehicleDetails } = useQuery<ApiResponse<VehicleDetailsResponse>, unknown, VehicleDetails | undefined>(
    [ReactQueryKeys.GetCarConfiguratorVehicleDetails, driverId, vehicleId],
    fetch,
    {
      select: modifyResponse,
      enabled: !!driverId && !!vehicleId
    }
  );

  return { vehicleDetails };
};

export default useCarConfiguratorVehicleDetails;
