import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

const AddIcon = ({ width = 26, height = 26, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <circle cx='13.2124' cy='13' r='12' fill={fill} />}
      <path
        d='M16.9695 24.4001C15.7876 24.7894 14.5246 25 13.2124 25C6.58499 25 1.2124 19.6274 1.2124 13C1.2124 6.37258 6.58499 1 13.2124 1C19.8398 1 25.2124 6.37258 25.2124 13C25.2124 16.5651 23.6578 19.767 21.1895 21.9649'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2124 7.1875V18.8125'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.2124 13.1875H7.2124'
        stroke={color}
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AddIcon;
