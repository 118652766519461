import { AxiosResponse } from 'axios';
import { AnyObject } from 'common/interfaces';
import { getSupportBoxContacts } from 'components/hilfecenter/hilfecenter-service';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getUserAvatar } from 'services/user-service';
import { getResponseFormData } from 'utils/get-response-data';
import { getFileForDownload } from 'components/download-expansion-component/download-expansion-component';
import { cloneDeep } from 'lodash';

const converResponse = (response: AxiosResponse | undefined, pageUrl: string) => {
  if (response) {
    const updatedResponse = getResponseFormData(response.data, [{ name: 'items', type: 'array' }], true) as AnyObject[];
    const contact = updatedResponse
      .filter((item: AnyObject) => item.uds_page_url === pageUrl)
      .reduce((acc: AnyObject, curr: AnyObject) => {
        return {
          ...acc,
          ...curr
        };
      }, {});

    return contact;
  }
};

export const useSupportBoxQuery = (pageUrl: string) => {
  const [supportConttacts, setSupportContacts] = useState<AnyObject[]>([]);
  const { data, isLoading } = useQuery([pageUrl], () => getSupportBoxContacts(), {
    select: data => converResponse(data, pageUrl)
  });
  const [isAvatarsFetching, setIsAvatarsFetching] = useState(false);

  const getAvatar = async (id: string) => {
    const avatarResponse = await getUserAvatar(id);
    if (avatarResponse.data.filerelativepath) {
      const { data } = await getFileForDownload(avatarResponse.data.filerelativepath);
      const blob = new Blob([data], { type: 'application/octet-stream' });

      return {
        id,
        image: URL.createObjectURL(blob)
      };
    }
  };

  const getEmployeesAvatars = async () => {
    try {
      setIsAvatarsFetching(true);
      const employeesArr = cloneDeep(data?.contacts) as AnyObject[];
      const idArr = employeesArr.map((employee: AnyObject) => employee.contactid?.attributeValue);
      const promiseArr = idArr.map(async (id: string) => getAvatar(id));
      const response = await Promise.all(promiseArr);
      const updatedEmployees = employeesArr.map((employee: AnyObject) => {
        const image = response
          .filter(obj => obj)
          .find(
            (item: { id: string; image: string } | undefined) => item?.id === employee.contactid?.attributeValue
          )?.image;

        return {
          ...employee,
          image
        };
      });

      setSupportContacts(updatedEmployees.filter(employee => employee.image));
    } catch (error) {
      console.log(error);
    } finally {
      setIsAvatarsFetching(false);
    }
  };

  useEffect(() => {
    if (data?.contacts?.length) {
      getEmployeesAvatars();
    }
  }, [data?.contacts?.length]);

  return {
    supportBox: data?.contacts?.length ? { ...data, contacts: supportConttacts } : data,
    isLoading: isLoading || isAvatarsFetching
  };
};
