import { ReactQueryKeys } from 'common/enums';
import { ResponseModel } from 'common/interfaces';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useQuery } from 'react-query';
import { crmEntityRequestConfig } from 'request-config/crm-entity/crm-entity.request-config';
import {
  GetLocationByCompanyCrmEntityQuery,
  GetLocationByCompanyCrmEntityQueryHookOptions,
  GetLocationByCompanyCrmEntityResponse
} from './get-location-by-company.query';
import { getDataForTable } from 'utils/get-response-data';

export const useGetLocationByCompanyCrmEntity = (
  companyIdProps?: string,
  options: GetLocationByCompanyCrmEntityQueryHookOptions = {}
) => {
  const user = useTypedSelector(state => state.app.user);
  const companyId = companyIdProps || user.companyId;

  const { fetch } = useFetch<ResponseModel<GetLocationByCompanyCrmEntityResponse>>({
    loadingKey: `getLocationByCompanyCrmEntity${companyId}`,
    ...crmEntityRequestConfig.getLocationsByCompany(companyId),
    ...options.fetch
  });

  const modifyResponse = ({
    entityes = []
  }: GetLocationByCompanyCrmEntityResponse): GetLocationByCompanyCrmEntityQuery => getDataForTable(entityes);

  const { data, ...restQueryProps } = useQuery(
    [ReactQueryKeys.GetLocationByCompanyCrmEntity, companyId],
    () => fetch(),
    { enabled: !!companyId, select: ({ data }) => modifyResponse(data.data), ...options.options }
  );

  return { getLocationByCompanyCrmEntity: data || [], ...restQueryProps };
};
