import { useEffect, useState } from 'react';
import useLatestValue from 'hooks/use-latest-value';

export const useSessionState = <T>(sessionKey: string, defaultValue: T) => {
  const getSessionState = () => {
    const sessionState = sessionStorage.getItem(sessionKey);
    return sessionState ? (JSON.parse(sessionState) as T) : defaultValue;
  };

  const [state, setState] = useState<T>(getSessionState());
  const stateLatest = useLatestValue(state);

  useEffect(() => {
    const listenToStorageEvent = (event: StorageEvent) => {
      if (event.key === sessionKey) {
        try {
          if (JSON.stringify(stateLatest.current) !== event.newValue) {
            setState(JSON.parse(event.newValue!));
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    window.addEventListener('storage', listenToStorageEvent);

    return () => {
      window.removeEventListener('storage', listenToStorageEvent);
    };
  }, [sessionKey]);

  const setSessionState = (sessionState: T) => {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionState));
    setState(getSessionState());
    window.dispatchEvent(
      new StorageEvent('storage', {
        key: sessionKey,
        newValue: JSON.stringify(sessionState)
      })
    );
  };

  return { state, setSessionState };
};
