import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { CalendarWidgetPlaceholderEmptyProps } from './empty.props';

export const CalendarWidgetPlaceholderEmpty = ({
  emptyPlaceholderText,
  ...restProps
}: CalendarWidgetPlaceholderEmptyProps) => {
  return (
    <Flex height='100%' direction='column' justify='center' align='center' {...restProps}>
      <img src='/assets/images/schedule_planning_big.svg' alt='schedule_planning_big' />

      <Text color='grey800' top='10'>
        {emptyPlaceholderText || 'Es gibt keine anstehenden Termine'}
      </Text>
    </Flex>
  );
};
