import { AxiosRequestConfig } from 'axios';
import { ExcelIncedentsProps, UpdateDamageDataProp } from './damage.props';
import { GetDamageRecordFormData } from 'services/damage-service/damage-service.props';

class DamageRequestConfig {
  private RESOURCE = 'schaden';

  getCarById = (fleetId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/getCarById`,
    data: { fuhrparkId: fleetId }
  });

  getById = (id: string): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/getById`,
    data: { id }
  });

  update = (data: UpdateDamageDataProp): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/updateSchadenAufnahme`,
    data
  });

  getFilesById = (id: string): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/getFilesById`,
    data: { id }
  });

  getStatusesById = (id: string): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/getStatusesById`,
    data: { id }
  });

  getExcelIncidents = ({ contactId, searchParam, sortParam }: ExcelIncedentsProps) => ({
    method: 'POST',
    url: `${this.RESOURCE}/generateSchadenExcel`,
    responseType: 'blob',
    data: {
      contactId,
      searchParam,
      sortParam
    }
  });

  getRepairPartner = (name: string): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/getRepairPartner`,
    data: { name }
  });

  getInsuranceFirms = (name: string): AxiosRequestConfig => ({
    method: 'post',
    url: `${this.RESOURCE}/getVersicherungFirms`,
    data: { name }
  });

  getDamageRecordingFormData = (data: GetDamageRecordFormData): AxiosRequestConfig => ({
    method: 'post',
    url: 'schaden/getSchadenAufnahmeFormData',
    data
  });
}

export const damageRequestConfig = new DamageRequestConfig();
