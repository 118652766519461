import { Plugin } from 'chart.js';
import { BarPluginId } from '../plugin.enum';
import { HorizontalPluginStyleProps } from './use-horizontal-line.props';
import { useTheme } from 'styled-components';

export const useHorizontalPlugin = (value: number, options: HorizontalPluginStyleProps) => {
  const theme = useTheme();

  const horizontalLinePlugin: Plugin<'bar'> = {
    id: BarPluginId.HorizontalLine,
    afterDatasetsDraw: chart => {
      const {
        ctx,
        scales: { y }
      } = chart;
      const { color, lineWidth } = options;
      let yPos = y.getPixelForValue(value);

      const GRID_ROW_WIDTH_OUT_OF_FRAME = 8;

      if (yPos !== null) {
        ctx.save();
        ctx.beginPath();

        ctx.strokeStyle = theme.colors[color];
        ctx.lineWidth = lineWidth;
        ctx.lineCap = 'round';

        ctx.setLineDash([5.5, 5.5]);
        ctx.moveTo(chart.chartArea.left - GRID_ROW_WIDTH_OUT_OF_FRAME, yPos);
        ctx.lineTo(chart.chartArea.right, yPos);
        ctx.stroke();
        ctx.restore();
      }
    }
  };

  return { horizontalLinePlugin };
};
