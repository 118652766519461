import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { useCarInspectionDateQuery } from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspection-date.query';

export const useMyVehicleInspectionDateQuery = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const query = useCarInspectionDateQuery({ accountId: fuhrpark?.new_sparteid_id || '' });

  return { ...query, fuhrpark };
};
