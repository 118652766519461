import React, {Fragment} from "react";

import classes from "./comparison-panel.module.scss";
import ComparisonCard from "./comparison-card";

export default function ComparisonPanel(props) {
  const cars = [...props.comparisonCars];
  while (cars.length < 4) {
    cars.push(null);
  }

  let sidebarClasses = props.comparisonPanelOpen
    ? [classes.comparison_panel, classes.open]
    : [classes.comparison_panel, classes.close];

  const deleteAll = () => {
    props.deleteAllCarsFromComparison();
    props.toggleComparisonPanel(false);
  };

  return (
    props.comparisonCars.length ? <Fragment>
      <div className={sidebarClasses.join(' ')}>
        <div onClick={() => props.toggleComparisonPanel(!props.comparisonPanelOpen)} className={classes.header}>
          <img className={props.comparisonPanelOpen ? classes.rotate : null} src="/assets/images/expand.png" alt="expand" />
          <h5>Fahrzeuge Vergleichen {`(${props.comparisonCars.length}/4)`}</h5>
        </div>
        <div className={classes.cars_wrapper}>
          <div className={classes.cars_container}>
            {cars.map((car, i) => <ComparisonCard key={i} car={car} deleteCar={props.deleteFromComparison} />)}
          </div>
          <div className={classes.buttons_container}>
            <button onClick={deleteAll} className={classes.delete_all}>Alle entfernen</button>
            <button onClick={props.openComparisonModal}>Jetzt vergleichen</button>
          </div>
        </div>
      </div>
    </Fragment> : null
  )
}
