import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { dashboardRequestConfig } from 'request-config/dashboard/dashboard.request-config';
import { QueryHookOptions } from '../../react-query.props';
import { ResponseModel } from 'common/interfaces';
import { MyVehicleData, MyVehicleResponse } from './get-my-vehicle.props';
import { getDataForTableItem } from 'utils/get-response-data';

export const useGetMyVehicleQuery = (driverIdProps?: string, option: QueryHookOptions = {}) => {
  const { fetch } = useFetch<ResponseModel<MyVehicleResponse>>({
    loadingKey: `${GET_MY_VEHICLE_QUERY_KEY}${driverIdProps}`,
    ...option.fetch,
    ...dashboardRequestConfig.getMainVehicle(driverIdProps)
  });

  const { data: queryData, ...restProps } = useQuery([GET_MY_VEHICLE_QUERY_KEY, driverIdProps], () => fetch(), {
    enabled: !!driverIdProps
  });

  const modifiedFleetData = (): MyVehicleData => {
    const fleetData = queryData?.data?.data?.fuhrparks;

    if (Array.isArray(fleetData)) {
      return fleetData.map(
        fuhrparkItem => fuhrparkItem?.fuhrpark?.map(item => getDataForTableItem(item))[0]
      ) as MyVehicleData;
    }

    return [];
  };

  return { myVehicleQuery: modifiedFleetData(), ...restProps };
};

export const GET_MY_VEHICLE_QUERY_KEY = 'getMyVehicleQuery';
