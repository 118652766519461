import React from 'react';
import { StepProps } from './step.types';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { StyledStep } from '../release-external-page.styled';

const Step = ({ steps, currentStep, index, isActive }: StepProps) => {
  const theme = useTheme();

  return (
    <Flex align='center' width={index + 1 < steps.length ? '100%' : 'initial'}>
      <Flex position='relative' justify='center'>
        {currentStep.icon}
        <StyledStep width='90px' position='absolute' justify='center' margin='25px 0 0 0 '>
          <Text fontWeight={isActive ? 600 : undefined} color={'darkBlue'}>
            {currentStep.label}
          </Text>
        </StyledStep>
      </Flex>
      {index + 1 < steps.length && (
        <Flex
          height='1px'
          width='100%'
          margin='0 10px'
          style={{
            border: `1px solid ${currentStep.isSigned ? theme.colors.darkBlue : theme.colors.lightBlue}`
          }}
        />
      )}
    </Flex>
  );
};

export default Step;
