import { AnyObject } from 'chart.js/dist/types/basic';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import useOverlay from 'hooks/use-overlay';
import { PAGES } from 'common/enums';
import { getLeaseendOverview } from 'services/fuhrpark-service';
import { TableData, FilterDataProps, TableParamsData } from 'pages/leasing-end-page/lease-end-page.props';
import { setValue } from 'utils/get-response-data';

interface ModifyResponse {
  data: TableData;
}

interface ModifiedData {
  filtersData: any;
  items: AnyObject[];
  totalCount: number;
}

export const useLeaseEndQuery = (tableParams: TableParamsData, sessionFilter: any, sessionSorting: AnyObject) => {
  const [showOverlay, hideOverlay] = useOverlay();

  const modifyResponseFilter = (modifiedData: ModifiedData, filterData: FilterDataProps) => {
    let filterDataKey: keyof FilterDataProps;

    for (filterDataKey in filterData) {
      modifiedData.filtersData[filterDataKey] =
        Array.isArray(filterData[filterDataKey]) &&
        //@ts-ignore
        filterData[filterDataKey].map(filterItem => {
          if (
            typeof filterItem === 'object' &&
            filterDataKey !== 'new_name' &&
            filterDataKey !== 'uds_replacementstate' &&
            filterDataKey !== 'new_sparteid' &&
            filterDataKey !== 'uds_fahrzeugruckgabe' &&
            filterDataKey !== 'uds_preparationstate'
          ) {
            return {
              id: filterItem.id,
              value: filterItem.name
            };
          }
          if (filterDataKey === 'new_sparteid') {
            return {
              id: filterItem.id,
              value: filterItem.name,
              city: filterItem.city,
              postalcode: filterItem.postalcode
            };
          }
          return filterItem;
        });
    }
  };

  const modifyResponseTable = ({ data: leaseendResponse }: ModifyResponse) => {
    const modifiedData: ModifiedData = { filtersData: {}, items: [], totalCount: leaseendResponse.totalCount };

    modifyResponseFilter(modifiedData, leaseendResponse.filtersData);

    modifiedData.items = leaseendResponse.items.map(item => {
      const newItem: AnyObject = {};

      Object.entries(item).forEach(([key, value]) => {
        if (value && Object.prototype.hasOwnProperty.call(value, 'attributeTypeCode')) {
          setValue(item, key, value, newItem, true);
        } else {
          newItem[key] = value;
        }
      });
      return newItem;
    });

    return modifiedData;
  };

  const {
    data: tableDataQuery,
    refetch: tableRefetch,
    isFetching,
    isFetched
  } = useQuery(
    [PAGES.LEASE_END, tableParams, sessionFilter, sessionSorting],
    () =>
      getLeaseendOverview({
        ...tableParams,
        search: { predicateObject: { ...sessionFilter.filterForServer } },
        sort: sessionSorting
      }),
    {
      select: data => modifyResponseTable(data.data)
    }
  );

  useEffect(() => {
    if (isFetching) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [hideOverlay, isFetching, showOverlay]);

  return {
    table: tableDataQuery,
    tableRefetch,
    isFetched
  };
};
