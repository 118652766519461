import { PickValue } from 'common/interfaces';
import {
  GetCarInspectionDateProtocolRequiredEnum,
  GetCarInspectionDateResponseItem
} from 'hooks/react-query/inspection-dates/car-inspection-date/car-inspectation-date.props';

export const getInspectionDateItemDescriptionText = (
  attributes: PickValue<GetCarInspectionDateResponseItem, 'attributes'>
) => {
  const isProtocolRequired =
    attributes.uds_protocol_requiredcode?.value === GetCarInspectionDateProtocolRequiredEnum.Yes;

  return `alle ${attributes.uds_inspection_intervalcode?.label}, Erinnerungen 2 Monate vorher ${
    isProtocolRequired ? ', Protokoll erforderlich' : ''
  }`;
};
