/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import styles from '../steps.module.scss';
import { Distance } from 'common/common-components.styled';
import isEqual from 'lodash/isEqual';
import { CSSTransition } from 'react-transition-group';
import slide from '../transition/damage-slide-files.module.css';
import slideReverse from '../transition/backward-slide-files.module.css';
import slideReturn from '../transition/return-slide-files.module.css';
import { blobToBase64 } from 'utils/decode-encode-base64';
import UploadFile from 'components/setup-assistant/upload-document/upload-file/upload-file-view';
import { StepProps, StepStylesProps } from '../report-damage.props';

const StepSixFireDamage = ({ reactHookFormData, handleBack }: StepProps) => {
  const [showBlock, setShowBlock] = useState<boolean>(false);
  const { watch, clearErrors, setValue } = reactHookFormData;

  useEffect(() => {
    setShowBlock(true);
    clearErrors();
  }, []);

  const moveToNextStep = () => {
    setShowBlock(false);
    setTimeout(() => setValue('step', 'detail'), 500);
    setTimeout(() => setValue('previousStep', 6), 500);
    setValue('lastFlowStep', 5);
    setValue('neededStep', null);
  };

  const moveToPreviousStep = () => {
    if (handleBack) {
      setValue('previousStep', 6);
      setShowBlock(false);
      setTimeout(() => {
        handleBack();
      }, 500);
    }
  };

  const changeFiles = async (name: string, files: Blob[]) => {
    const base64 = await Promise.all(files.map(file => blobToBase64(file)));
    if (!isEqual(name, files)) {
      if (name === 'Foto Schaden') {
        setValue('FotoSchaden', {
          files: files,
          b64file: base64
        });
      }
      if (name === 'Skizze') {
        setValue('Skizze', {
          files: files,
          b64file: base64
        });
      }
      if (name === 'polizeiliche Unfallmitteilung') {
        setValue('polizei', {
          files: files,
          b64file: base64
        });
      }
      if (name === 'Sonstige Unterlagen zur Schadendokumentation') {
        setValue('SonstigeUnterlagenzurSchadendokumentation', {
          files: files,
          b64file: base64
        });
      }
    }
  };

  let slideClassName: StepStylesProps = watch('previousStep') === 5 ? slide : slideReverse;

  return (
    <CSSTransition
      in={showBlock}
      timeout={800}
      classNames={watch('previousStep') === 6 ? slideReturn : slideClassName}
      mountOnEnter
      unmountOnExit
    >
      <section className={styles.files_container}>
        <div className={styles.form_name_center}>
          <span>
            Bitte lade die Dateien in der entsprechenden Kategorie hoch.{' '}
            <span className={styles.optional}>(optional)</span>
          </span>
        </div>

        <Distance top='25' />

        <div className={styles.row_columns_files}>
          <div className={styles.half_input}>
            <div className={styles.upload_name}>
              <h5>Schadenfotos</h5>
            </div>
            <UploadFile
              onDropAccepted={files => changeFiles('Foto Schaden', files)}
              accept={['.pdf', '.png', '.jpeg', '.jpg', '.heic']}
              acceptString={'pdf, png, jpg, jpeg, heic'}
              unfallreparatur={true}
              multiple={true}
              className={null}
              maxSize={5242900}
              defaultFiles={
                !!watch('FotoSchaden') && watch('FotoSchaden').files.length > 0 ? watch('FotoSchaden').files : []
              }
              id='uploadFileInputDamagePhoto'
            />
          </div>
          <div className={styles.half_input}>
            <div className={styles.upload_name}>
              <h5>Polizeibericht</h5>
            </div>
            <UploadFile
              onDropAccepted={files => changeFiles('polizeiliche Unfallmitteilung', files)}
              accept={['.pdf', '.png', '.jpeg', '.jpg', '.heic']}
              unfallreparatur={true}
              multiple={true}
              className={null}
              maxSize={5242900}
              defaultFiles={!!watch('polizei') && watch('polizei').files.length > 0 ? watch('polizei').files : []}
              id='uploadFileInputPoliceReport'
            />
          </div>
        </div>

        <Distance top='15' />

        <div className={styles.row_columns_files}>
          <div className={styles.half_input}>
            <div className={styles.upload_name}>
              <h5>Unfallskizze</h5>
            </div>
            <UploadFile
              onDropAccepted={files => changeFiles('Skizze', files)}
              accept={['.pdf', '.png', '.jpeg', '.jpg', '.heic']}
              acceptString={'pdf, png, jpg, jpeg, heic'}
              unfallreparatur={true}
              multiple={true}
              className={null}
              maxSize={5242900}
              defaultFiles={!!watch('Skizze') && watch('Skizze').files.length > 0 ? watch('Skizze').files : []}
              id='uploadFileInputAccidentSketch'
            />
          </div>
          <div className={styles.half_input}>
            <div className={styles.upload_name}>
              <h5>Sonstige Dokumente</h5>
            </div>
            <UploadFile
              onDropAccepted={files => changeFiles('Sonstige Unterlagen zur Schadendokumentation', files)}
              accept={['.pdf', '.png', '.jpeg', '.jpg', '.heic']}
              acceptString={'pdf, png, jpg, jpeg, heic'}
              unfallreparatur={true}
              multiple={true}
              className={null}
              maxSize={5242900}
              defaultFiles={
                !!watch('SonstigeUnterlagenzurSchadendokumentation') &&
                watch('SonstigeUnterlagenzurSchadendokumentation').files.length > 0
                  ? watch('SonstigeUnterlagenzurSchadendokumentation').files
                  : []
              }
              id='uploadFileInputOthersDocuments'
            />
          </div>
        </div>

        <Distance top='30' />

        {showBlock && (
          <div className={styles.row_columns_files_buttons}>
            <div className={watch('previousStep') === 'detail' ? styles.button_hide : styles.half_buttons}>
              <button id='moveToPreviousStepButton' onClick={moveToPreviousStep} className={styles.button_back}>
                Zurück
              </button>
            </div>
            <div className={watch('previousStep') === 'detail' ? styles.full_input : styles.half_buttons}>
              <button id='moveToNextStepButton' onClick={moveToNextStep} className={styles.button_active}>
                {watch('previousStep') === 'detail' ? 'Änderungen speichern' : 'Weiter'}
              </button>
            </div>
          </div>
        )}
      </section>
    </CSSTransition>
  );
};

export default StepSixFireDamage;
