import React from 'react';
import PropTypes from 'prop-types';

export const UserCardCrossedOutIcon = ({ width = '20', height = '21', color = '', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill={color ? color : 'none'} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.25049 5.03595H10.7505" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25049 6.89258H13.8755" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25049 8.96655H13.8755" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.5323 2.04164C15.0645 2.04164 15.5 2.47713 15.5 3.00939V10.9933C15.5 11.5255 15.0645 11.961 14.5323 11.961H12.9942H4.83021M1.46774 11.961C0.935484 11.961 0.5 11.5255 0.5 10.9933V3.00939C0.5 2.47713 0.935484 2.04164 1.46774 2.04164H10.8285" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.12451 9.10218L3.09225 8.7796C3.09225 8.7796 3.64871 9.10218 4.38258 9.10218C5.11645 9.10218 5.6729 8.7796 5.6729 8.7796L6.64064 9.10218" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.98898 7.61944C5.26317 7.45815 5.46663 7.21403 5.52308 6.6979L5.61179 5.90759C5.71663 5.06081 4.81225 4.776 4.37677 4.776C3.94129 4.776 3.04818 5.06081 3.15302 5.90759L3.24172 6.6979C3.29818 7.21403 3.46569 7.45815 3.73988 7.61944" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.91699 13.1612L13.7426 0.91701" stroke="#335566" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

UserCardCrossedOutIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
