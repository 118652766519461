import React, { useCallback, useMemo } from 'react';
import classes from './aufgaben-item.module.scss';
import { useTheme } from 'styled-components';

import { formatDate } from '../../utils';
import { convertUTCDateToLocalDate } from '../../../../utils/convert-UTC-date-to-local-date';
import { TaskTypeIcon } from '../task-type-icon/task-type-icon';
import MoreMenu from 'components/more-menu/more-menu';
import { AufgabenItemProps } from 'components/aufgaben-component/task.prop';
import TooltipHint from 'components/tooltip-hint/tooltip-hint';
import { STATECODE, UDS_AUFGABENTYP_FUHRPARK } from '../../../../constants';
import { Distance, Flex, Text } from 'common/common-components.styled';
import { PrefixForId } from '../../../../common/enums';
import classNames from 'classnames';
import { AnyObject } from 'common/interfaces';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip/ellipsis-with-tooltip';
import { useMediaQuery } from '@mui/material';

export const addTooltipToString = (
  string: string,
  length: number = 10,
  stringStyle: string = classes.cut_text,
  tooltipStyles: AnyObject = {}
) => {
  if (!string) {
    return '-';
  }
  if (string.length <= length) {
    return string;
  }

  return (
    <TooltipHint position='bottom' text={string} tooltipStyles={tooltipStyles}>
      <p className={stringStyle}>{string}</p>
    </TooltipHint>
  );
};

export const AufgabenItem = ({
  aufgabenInfo,
  openUnfoldedTaskHandler,
  statusColor,
  menuItems,
  index
}: AufgabenItemProps) => {
  const {
    subject,
    uds_executor_accountid,
    uds_executor_contactid,
    scheduledend,
    uds_aufgabentyp_fuhrpark,
    regardingobjectid,
    uds_b2c_portal_status,
    uds_b2cpostponedate
  } = aufgabenInfo;

  const theme = useTheme();
  const isMobileWidth = useMediaQuery('(max-width:550px)');

  const containerStyle = {
    borderLeft: isMobileWidth ? `6px solid ${statusColor} ` : 'none'
  };

  const showMenu = useMemo(() => menuItems.find(item => !item.hidden), [menuItems]);

  const getIsDisplayMoreMenu = () => {
    const taskTypeValue = uds_aufgabentyp_fuhrpark.attributeValue?.value;

    if (taskTypeValue) {
      return ![UDS_AUFGABENTYP_FUHRPARK.reparaturfreigabe, UDS_AUFGABENTYP_FUHRPARK.inspectionDates].includes(
        taskTypeValue
      );
    }

    return true;
  };

  const getExecutor = useCallback(() => {
    return regardingobjectid?.attributeValue?.logicalName === 'new_fuhrpark'
      ? regardingobjectid?.attributeValue?.name
      : '';
  }, [regardingobjectid?.attributeValue?.logicalName, regardingobjectid?.attributeValue?.name]);

  const getSubjectLabel = () => {
    const label = subject?.attributeValue?.includes('Kostenvoranschlag')
      ? `KVA ${subject?.attributeValue?.slice(-5)}`
      : subject?.attributeValue || '';

    if (isMobileWidth) {
      return (
        <Flex direction='column' maxWidth='100%' gap='5px'>
          <EllipsisWithTooltip tooltip={label}>
            <Text as='span' size='16' bold>
              {label}
            </Text>
          </EllipsisWithTooltip>

          <EllipsisWithTooltip tooltip={label}>
            <span>{getExecutor()}</span>
          </EllipsisWithTooltip>
        </Flex>
      );
    } else {
      return (
        <EllipsisWithTooltip tooltip={label} maxWidth='100%'>
          <span>{label}</span>
        </EllipsisWithTooltip>
      );
    }
  };

  const suubjectLabel = subject?.attributeValue?.includes('Kostenvoranschlag')
    ? `KVA ${subject?.attributeValue?.slice(-5)}`
    : subject?.attributeValue || '';

  const scheduledDate = formatDate(convertUTCDateToLocalDate(scheduledend?.attributeValue) || '');

  const columns = useMemo(() => {
    return [
      {
        id: `${PrefixForId.Table}SubjectField${index}`,
        label: suubjectLabel,
        customComponent: getSubjectLabel(),
        hasIcon: true
      },
      {
        id: `${PrefixForId.Table}RegardingobjectidField${index}`,
        label: getExecutor()
      },
      {
        id: `${PrefixForId.Table}UdsExecutorContactidField${index}`,
        label: uds_executor_contactid?.attributeValue?.name || ''
      },
      {
        id: `${PrefixForId.Table}UdsExecutorAccountidField${index}`,
        label: uds_executor_accountid?.attributeValue?.name || ''
      },
      {
        id: `${PrefixForId.Table}ScheduledendField${index}`,
        label: scheduledDate
      },
      {
        id: `${PrefixForId.Table}UdsB2cPortalStatusField${index}`,
        label: uds_b2c_portal_status?.attributeValue?.label || '',
        isStatusField: true
      }
    ];
  }, [aufgabenInfo, isMobileWidth]);

  return (
    <div
      className={classNames(classes.container, 'table')}
      onClick={openUnfoldedTaskHandler}
      style={containerStyle}
      id={PrefixForId.Table + PrefixForId.Items + PrefixForId.Container + index}
    >
      <div className={classes.content_wrapper}>
        {columns.map(({ id, hasIcon, label, isStatusField, customComponent }) => (
          <span className={classes.column} key={id} id={id}>
            {hasIcon && !isMobileWidth && (
              <div className={classes.task_icon}>
                <TaskTypeIcon type={uds_aufgabentyp_fuhrpark?.attributeValue?.value} color={theme.colors.grey600} />
              </div>
            )}

            {isStatusField ? (
              <TooltipHint
                position='bottom'
                text={`Verschoben auf ${formatDate(uds_b2cpostponedate?.attributeValue || '')}`}
                active={uds_b2c_portal_status.attributeValue?.value === STATECODE.postponed}
              >
                <EllipsisWithTooltip tooltip={label} maxWidth='100%'>
                  <div className={classes.status} style={{ color: statusColor || 'transparent' }}>
                    {label}
                  </div>
                </EllipsisWithTooltip>
              </TooltipHint>
            ) : customComponent && isMobileWidth ? (
              customComponent
            ) : (
              <EllipsisWithTooltip tooltip={label} maxWidth='100%'>
                <span>{label}</span>
              </EllipsisWithTooltip>
            )}
          </span>
        ))}
        {getIsDisplayMoreMenu() ? (
          <Flex width='47px' justify='flex-end'>
            <MoreMenu menuItems={menuItems} hide={!showMenu} idEnding={index} />
          </Flex>
        ) : (
          <Distance side='20' />
        )}
      </div>
    </div>
  );
};
