import React from 'react';
import {
  StyledLink,
  StyledWidget,
  StyledPhotosContainer,
  StyledImage,
  StyledWidgetContent
} from './packages-and-prices.styled';
import { Text, Flex, FlexProps } from 'common/common-components.styled';
import { employeesImages } from './constants';
import { mobexoPhoneNumber } from '../../constants';
import { PhoneIcon } from 'components/icons-new/phone';
import { EmailIcon } from 'components/icons-new/email';

const ContactsWidget = (containerProps: FlexProps) => {
  return (
    <StyledWidget {...containerProps}>
      <StyledWidgetContent>
        <StyledPhotosContainer>
          {employeesImages.map((imageLink: string, index: number) => (
            <StyledImage key={index} src={imageLink} height='60px' border />
          ))}
        </StyledPhotosContainer>
        <Text size='18' bold bottom='32'>
          Wir freuen uns weiterzuhelfen
        </Text>
        <Flex bottom='15' style={{ minWidth: 'inherit' }}>
          <PhoneIcon width='24' />
          <StyledLink href={`tel:${mobexoPhoneNumber}`}>{mobexoPhoneNumber}</StyledLink>
        </Flex>
        <Flex>
          <EmailIcon strokeWidth={1} width='22' />
          <StyledLink href={'mailto:fuhrpark@mobexo.de'}>fuhrpark@mobexo.de</StyledLink>
        </Flex>
      </StyledWidgetContent>
    </StyledWidget>
  );
};

export default ContactsWidget;
