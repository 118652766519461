import { crmDataTypes, RequestStatuses } from '../constants';
import { convertUTCDateToLocalDate } from './convert-UTC-date-to-local-date';
import { getFuelCardTitle } from './get-fuel-card-title';

export function getResponseData(response, dataProperties) {
  if (response.level !== RequestStatuses.Success) {
    return {};
  }
  let responseData = {};
  dataProperties.forEach(dataPropertie => {
    response.data[dataPropertie] &&
      Object.entries(response.data[dataPropertie]).forEach(([key, value]) => {
        responseData[key] = value;
      });
  });
  if (response.data.hasOwnProperty('tankkartes')) {
    responseData.tankkartes = response.data.tankkartes || [];
  }
  if (response.data.hasOwnProperty('equipments')) {
    responseData.equipments = response.data.equipments || [];
  }

  return responseData;
}

export const setValue = (dataItem, key, value, resultObject, valuesAsResult) => {
  if (Array.isArray(value)) {
    resultObject[key] = value;
  }

  if (typeof dataItem[key] !== 'object' || dataItem[key] === null) {
    resultObject[key] = value;
    return;
  }

  if (typeof dataItem[key] === 'object' && !Object.hasOwn(dataItem[key], 'attributeTypeCode')) {
    resultObject[key] = value;
    return;
  }

  if (
    dataItem[key].attributeTypeCode === crmDataTypes.String ||
    dataItem[key].attributeTypeCode === crmDataTypes.Integer ||
    dataItem[key].attributeTypeCode === crmDataTypes.Double ||
    dataItem[key].attributeTypeCode === crmDataTypes.Uniqueidentifier ||
    dataItem[key].attributeTypeCode === crmDataTypes.Boolean ||
    dataItem[key].attributeTypeCode === crmDataTypes.Memo ||
    dataItem[key].attributeTypeCode === crmDataTypes.DateTime ||
    dataItem[key].attributeTypeCode === crmDataTypes.Decimal ||
    dataItem[key].attributeTypeCode === crmDataTypes.PicklistMultiple
  ) {
    resultObject[key] = value.attributeValue;
  }
  if (
    dataItem[key].attributeTypeCode === crmDataTypes.Picklist ||
    dataItem[key].attributeTypeCode === crmDataTypes.Money ||
    dataItem[key].attributeTypeCode === crmDataTypes.State
  ) {
    resultObject[key] = valuesAsResult ? value.attributeValue?.label || null : value.attributeValue?.value ?? null;

    if (valuesAsResult) {
      resultObject[`${key}_value`] = value.attributeValue?.value ?? null;
    }
  }
  if (
    dataItem[key].attributeTypeCode === crmDataTypes.Lookup ||
    dataItem[key].attributeTypeCode === crmDataTypes.Customer ||
    dataItem[key].attributeTypeCode === crmDataTypes.Owner
  ) {
    resultObject[key] = valuesAsResult ? value.attributeValue?.name || null : value.attributeValue?.id || null;
    if (valuesAsResult) {
      resultObject[`${key}_id`] = value.attributeValue?.id || null;
    }
  }
  if (dataItem[key].attributeTypeCode === 999) {
    return (resultObject[key] = value.attributeValue);
  }
};

/**
 * @returns {Object | Array}
 */
export function getResponseFormData(response, dataProperties, valuesAsResult) {
  if (response.level !== RequestStatuses.Success) {
    return {};
  }
  let responseData = {};
  let responseArray = [];
  let result = null;
  dataProperties.forEach(dataPropertie => {
    if (dataPropertie.type === 'object') {
      const dataObject = dataPropertie.name ? response.data[dataPropertie.name] : response.data;
      Object.entries(dataObject).forEach(([key, value]) => {
        setValue(dataObject, key, value, responseData, valuesAsResult);
      });
      result = responseData;
    }
    if (dataPropertie.type === 'array') {
      const dataArray = dataPropertie.name ? response.data[dataPropertie.name] : response.data;
      dataArray.forEach(item => {
        let object = {};
        Object.entries(item).forEach(([key, value]) => {
          setValue(item, key, value, object, valuesAsResult);
        });
        responseArray.push(object);
      });
      result = responseArray;
    }
  });
  return result;
}

export const getDataForTableItem = item => {
  const sortData = {};

  Object.entries(item).forEach(([key, value]) => {
    setValue(item, key, value, sortData, true);
  });

  return sortData;
};

export const getDataForTable = items => items.map(item => getDataForTableItem(item));

export const getFormFieldValue = (field, item) => {
  const data = item[field.fieldName];

  if (!data) {
    field.value = '';
    field.formData = {
      [field.fieldName]: null
    };
    return;
  }

  if (
    data.attributeTypeCode === crmDataTypes.String ||
    data.attributeTypeCode === crmDataTypes.Integer ||
    data.attributeTypeCode === crmDataTypes.Boolean
  ) {
    field.value = item[field.fieldName].attributeValue || '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue
    };
  }
  if (data.attributeTypeCode === crmDataTypes.DateTime) {
    field.value = item[field.fieldName].attributeValue
      ? new Date(item[field.fieldName].attributeValue).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
      : '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue ? new Date(item[field.fieldName].attributeValue) : null
    };
  }
  if (data.attributeTypeCode === crmDataTypes.Picklist || data.attributeTypeCode === crmDataTypes.Money) {
    field.value = item[field.fieldName].attributeValue?.label || '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue?.value || null
    };
  }
  if (data.attributeTypeCode === crmDataTypes.Lookup || data.attributeTypeCode === crmDataTypes.Owner) {
    field.value = item[field.fieldName].attributeValue?.name || '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue?.id || null
    };
  }

  if (data.attributeTypeCode === crmDataTypes.Money) {
    field.value = item[field.fieldName].attributeValue?.label || '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue?.value || null
    };
  }
  if (data.attributeTypeCode === crmDataTypes.Memo) {
    field.value = item[field.fieldName].attributeValue || '';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue || null
    };
  }
  if (data.attributeTypeCode === crmDataTypes.Boolean) {
    field.value = item[field.fieldName].attributeValue ? 'Ja' : 'Nein';
    field.formData = {
      [field.fieldName]: item[field.fieldName].attributeValue || false
    };
  }

  if (field.name === 'Tankkarten') {
    field.value = item.tankkartes ? item.tankkartes.map(tankkart => getFuelCardTitle(tankkart)).join('; ') : '';
    field.formData = {
      tankkartes: item.tankkartes
        ? item.tankkartes.map(tankkart => ({
            id: tankkart.uds_tankkarteid.attributeValue,
            label: tankkart.uds_name.attributeValue
          }))
        : []
    };
  }
  if (field.name === 'Adresse' && !field.convertFromCrmType) {
    field.value = [
      item.address1_line1?.attributeValue || '',
      item.address1_postalcode?.attributeValue || '',
      item.address1_city?.attributeValue || '',
      item.address1_stateorprovince?.attributeValue || '',
      item.address1_country?.attributeValue || ''
    ]
      .filter(item => item)
      .join(', ');
    field.formData = {
      address1_line1: item['address1_line1']?.attributeValue,
      address1_postalcode: item['address1_postalcode']?.attributeValue,
      address1_city: item['address1_city']?.attributeValue,
      address1_stateorprovince: item['address1_stateorprovince']?.attributeValue,
      address1_country: item['address1_country']?.attributeValue
    };
  }
};

export const getRequestItem = (value, attributeTypeCode, logicalName = '') => {
  const requestItem = {};
  if (
    attributeTypeCode === crmDataTypes.String ||
    attributeTypeCode === crmDataTypes.Integer ||
    attributeTypeCode === crmDataTypes.Uniqueidentifier ||
    attributeTypeCode === crmDataTypes.Memo ||
    attributeTypeCode === crmDataTypes.Decimal ||
    attributeTypeCode === crmDataTypes.Double
  ) {
    requestItem.attributeValue = value || null;
  }
  if (attributeTypeCode === crmDataTypes.DateTime) {
    requestItem.attributeValue = value ? convertUTCDateToLocalDate(value) : null;
  }
  if (attributeTypeCode === crmDataTypes.Boolean) {
    requestItem.attributeValue = value;

    if (typeof value === 'string') {
      requestItem.attributeValue = value === 'true';
    }
  }
  if (attributeTypeCode === crmDataTypes.Picklist || attributeTypeCode === crmDataTypes.Money) {
    requestItem.attributeValue = value
      ? {
          value,
          label: ''
        }
      : null;
  }
  if (attributeTypeCode === crmDataTypes.Lookup) {
    requestItem.attributeValue = value
      ? {
          id: value,
          logicalName,
          name: ''
        }
      : null;
  }
  if (attributeTypeCode === crmDataTypes.PicklistMultiple) {
    requestItem.attributeValue = value ? getCheckboxValues(value) : null;
  }
  requestItem.attributeTypeCode = attributeTypeCode;

  return requestItem;
};

export const getRequestData = (values, item) => {
  let requestData = {};

  Object.entries(values).forEach(([key, value]) => {
    requestData[key] = getRequestItem(value, item[key].attributeTypeCode);
  });

  return requestData;
};

export const getRequestFormData = (values, formFields) => {
  let requestData = {};

  Object.entries(values).forEach(([key, value]) => {
    const formField = formFields.find(field => field.name === key);
    if (!formField) {
      return;
    }
    if (
      formField.crmFieldType === crmDataTypes.String ||
      formField.crmFieldType === crmDataTypes.Integer ||
      formField.crmFieldType === crmDataTypes.Double ||
      formField.crmFieldType === crmDataTypes.Uniqueidentifier
    ) {
      requestData[key] = {
        attributeValue: formField.upperCase ? value.toUpperCase() || null : value ?? null
      };
    }
    if (formField.crmFieldType === crmDataTypes.DateTime) {
      requestData[key] = {
        attributeValue: value ? convertUTCDateToLocalDate(value) : null
      };
    }
    if (formField.crmFieldType === crmDataTypes.Boolean) {
      requestData[key] = {
        attributeValue: value
      };
    }
    if (formField.crmFieldType === crmDataTypes.Picklist || formField.crmFieldType === crmDataTypes.Money) {
      requestData[key] = {
        attributeValue: value
          ? {
              value,
              label: ''
            }
          : null
      };
    }
    if (formField.crmFieldType === crmDataTypes.Lookup || formField.crmFieldType === crmDataTypes.Customer) {
      requestData[key] = {
        attributeValue: value
          ? {
              id: value,
              logicalName: '',
              name: ''
            }
          : null
      };
    }
    requestData[key].attributeTypeCode = formField.crmFieldType;
  });

  return requestData;
};

const getCheckboxValues = options => {
  const convertedOptions = options
    .filter(item => item.isChecked)
    .map(option => {
      return {
        label: option.label,
        value: parseInt(option.value)
      };
    });

  return convertedOptions;
};
