import React from 'react';

const PlusIcon = () => {
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.70166 8.48682C0.70166 7.9959 1.09963 7.59793 1.59055 7.59793L15.8128 7.59793C16.3037 7.59793 16.7017 7.9959 16.7017 8.48682C16.7017 8.97774 16.3037 9.37571 15.8128 9.37571L1.59055 9.37571C1.09963 9.37571 0.70166 8.97774 0.70166 8.48682Z'
        fill='#102F3F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.70166 0.486816C9.19258 0.486816 9.59055 0.884785 9.59055 1.37571L9.59055 15.5979C9.59055 16.0888 9.19258 16.4868 8.70166 16.4868C8.21074 16.4868 7.81277 16.0888 7.81277 15.5979L7.81277 1.37571C7.81277 0.884786 8.21074 0.486816 8.70166 0.486816Z'
        fill='#102F3F'
      />
    </svg>
  );
};

export default PlusIcon;
