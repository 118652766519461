import { setFormInfo, setHighlightedForm } from 'actions/app_action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useOnPageChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFormInfo({ opened: '' }));
    dispatch(setHighlightedForm({ fields: [] }));

    document.querySelector('.scroll-container')?.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, [location.pathname]);
};
