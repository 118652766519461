import React from 'react';
import Model from './model';
import CarType from './car-type-btn-group/car-type';
import Manufacturer from './manufacturer';
import classes from './basic-information.module.scss';
import classnames from 'classnames';
import EngineType from './engine-type-container';
import DeliveryTime from './delivery-time-container';
import CarCategogies from './car-type-container';
import Transmission from './transmission-container';

export default function BasicInformationFilter({ sidebarOpen, resetFilters, toggleSidebar }) {
  return (
    <div className={classes.sidebar_container}>
      <SidebarHeader onBack={() => toggleSidebar(false)} />

      <div className={classes.body}>
        <Manufacturer />
        <Model />
        <EngineType />
        <Transmission />
        <DeliveryTime />
        <CarCategogies/>
        {/*<CarType />*/}
        {/*<h4 style={{margin: '15px 0'}}>Leasing:</h4>*/}
        {/*<Laufzeit />*/}
        {/*<Fahrleistung />*/}
      </div>

      <SidebarFooter onApply={() => toggleSidebar(false)} onReset={resetFilters} />
    </div>
  );
}

function SidebarFooter({ onReset, onApply }) {
  return (
    <div className={classes.footer}>
      <div className={classnames("btn", classes.btn_outlined)} onClick={onReset}>
        Zurücksetzen
      </div>
      <div className={classnames("btn", classes.btn_primary)} onClick={onApply}>
        Anwenden
      </div>
    </div>
  );
}

function SidebarHeader({ onBack }) {
  return (
    <div className={classes.header}>
      <div className={classes.filter}>Filter</div>
      <div style={{ position: 'relative', cursor: 'pointer' }} onClick={onBack}>
        Zurück <i className='fas fa-angle-double-right' />
      </div>
    </div>
  );
}
