import { AxiosRequestConfig } from 'axios';
import { ExcelRecordsRequestProps, SetDoneStatusCrmTaskParams } from './crm-task.types';
import { CrmDataTypes } from 'common/enums';

class CrmTaskRequestConfig {
  getRecordsExcel = ({
    tableParams,
    sessionSorting,
    search,
    caseId
  }: ExcelRecordsRequestProps): AxiosRequestConfig => ({
    method: 'POST',
    url: 'CrmTask/GenerateExcelAufgaben',
    data: {
      ...tableParams,
      sort: sessionSorting,
      search: search,
      SelectedTaskId: caseId
    }
  });

  setDoneStatus = (Date: string, crmTask: SetDoneStatusCrmTaskParams): AxiosRequestConfig => ({
    method: 'POST',
    url: 'CrmTask/SetTaskStatusDone',
    data: {
      Date,
      crmTask: {
        regardingobjectid: {
          attributeTypeCode: CrmDataTypes.Lookup,
          attributeValue: { logicalName: 'new_fuhrpark', id: crmTask.regardingobjectid }
        },
        uds_aufgabentyp_fuhrpark: {
          attributeTypeCode: CrmDataTypes.Picklist,
          attributeValue: { value: crmTask.uds_aufgabentyp_fuhrpark }
        },
        activityid: {
          attributeTypeCode: CrmDataTypes.UniqueIdentifier,
          attributeValue: crmTask.activityid
        },
        statuscode: 2
      }
    }
  });
}

export const crmTaskRequestConfig = new CrmTaskRequestConfig();
