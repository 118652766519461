import React from 'react';
import classes from './open-left-widget-button.module.scss';
import { Direction, Rotate } from 'common/common-components.styled';
import { DoubleRightArrow } from 'components/icons-new/double-right-arrow';
import { useTheme } from 'styled-components';
import { OpenLeftWidgetButtonProps } from './open-left-widget-button.props';

export const OpenLeftWidgetButton = ({ isExpendWidget, setIsExpendWidget }: OpenLeftWidgetButtonProps) => {
  const theme = useTheme();

  return (
    <div className={classes.open_left_widget} onClick={() => setIsExpendWidget(prev => !prev)}>
      <Rotate rotateDirection={isExpendWidget ? Direction.Top : Direction.Bottom}>
        <DoubleRightArrow width='20' height='20' color={theme.colors.grey100} />
      </Rotate>
    </div>
  );
};
