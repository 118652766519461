import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { DiagramWithBottomSegmentsContainer } from 'components/containers/diagram/bottom-segments/bottom-segments';
import { ChangeKilometerForm } from '../../../change-kilometer-form/change-kilometer-form';
import { DrivingCarIcon } from 'components/icons-new/driving-car';
import { InfoHintAddingInformationToFinanceRate } from '../../../info-hint/adding-information-to-finance-rate/adding-information-to-finance-rate';
import { StyledInfoHintWithoutBackground } from 'components/info-hint/info-hint-view.styled';

export const KilometerWidgetNoMilageData = () => {
  return (
    <DiagramWithBottomSegmentsContainer styledFlex={{ align: 'center' }} segments={[]}>
      <Flex justify='center' flex='1'>
        <DrivingCarIcon />
      </Flex>

      <Flex direction='column' flex='2' left='13'>
        <StyledInfoHintWithoutBackground type='warning' iconSize={12}>
          <Text id='infoHintNoMilageData' size='12'>
            Es gibt keine Informationen über den aktuellen km-Stand. Bitte füge den aktuellen km-Stand hinzu, um die
            Berechnung zur Projektion zu ermöglichen.
          </Text>
        </StyledInfoHintWithoutBackground>

        <ChangeKilometerForm />
      </Flex>
    </DiagramWithBottomSegmentsContainer>
  );
};
