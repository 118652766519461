import { AnyObject, PicklistResponse } from 'common/interfaces';
import { VehicleTypeId } from 'components/gallery/vehicle-gallery/vehicle-gallery.props';
import { VehicleStatusesLabel, VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';

export type FleetByIdResponse = Partial<{
  equipments: AnyObject[];
  fuhrpark: AnyObject;
  leaseEndInfo: AnyObject;
  serverDate: string;
  tankkartes: AnyObject[];
}>;

export type FleetByIdQueryData = Partial<{
  equipments: AnyObject[];
  fuhrpark: FleetType;
  leaseEndInfo: AnyObject;
  serverDate: string;
  tankkartes: FuelCardItemType[];
}>;

type FuelCardItemType = Partial<{
  entityName: 'uds_tankkarte';
  uds_tankkarteid: string;
  uds_name: string;
  uds_nummer: string;
  uds_kennzeichenid: string;
  uds_kennzeichenid_id: string;
  uds_providercode: PicklistResponse[];
  statecode: string;
  statecode_value: number;
}>;

export enum ConsumptionEstimateValue {
  Normal = 100000000,
  Increased = 100000001
}
export enum LeasingPurchaseValue {
  Leasing = 100000001
}

export type FleetType = Partial<{
  uds_consumption_estimate_value: string;
  uds_consumption_estimate_value_value: ConsumptionEstimateValue;
  uds_combined_consumption: number;
  uds_poolcar_classid: string;
  uds_poolcar_classid_id: string;
  new_inspektion: string;
  uds_fahrzeugkategoriecode: string;
  uds_fahrzeugkategoriecode_value: string;
  uds_anbietenterhandlerid: string;
  uds_anbietenterhandlerid_id: string;
  uds_vsl_liefertermin_laut_handler_main: string;
  uds_hauptauto: boolean;
  modifiedon: string;
  new_abgangsdatum: string;
  new_baujahr: string;
  new_eigentmerid: string;
  new_eigentmerid_id: string;
  new_ezl: string;
  new_fahrerid: string;
  new_fahrerid_id: string;
  new_fahrzeugpreis: string;
  new_fin: string;
  new_fuhrparkid: string;
  new_gueltig_bis: string;
  new_gueltig_von: string;
  new_hersteller: string;
  new_interne_fahrzeugnummer: string;
  new_kategorie: string;
  new_kategorie_value: number;
  new_kauf_leasing: string;
  new_kauf_leasing_value: LeasingPurchaseValue;
  new_kostenstelleid: string;
  new_kostenstelleid_id: string;
  new_kstverantw: string;
  new_kstverantw_id: string;
  new_leasingende: string;
  new_leasinggeber: string;
  new_leasinggeber_id: string;
  new_leasingkmintotal: string;
  new_leasingstart: string;
  new_model: string;
  uds_name: string;
  new_name: string;
  new_privatnutzung: string;
  new_privatnutzung_value: number;
  new_rahmenvertrage: string;
  new_rahmenvertrage_id: string;
  new_reifengroessehinten: string;
  new_reifengroessevorne: string;
  new_sparteid: string;
  new_sparteid_id: string;
  new_standortid: string;
  new_standortid_id: string;
  new_status_carcode: VehicleStatusesLabel;
  new_status_carcode_value: VehicleStatusesValue;
  uds_vsl_liefertermin_laut_handler: string;
  new_tuv: string;
  new_uvv: string;
  new_versichererid: string;
  new_versichererid_id: string;
  new_zuordnung: string;
  new_zuordnung_value: number;
  new_zuordnung_beschreibung: string;
  statecode: string;
  statecode_value: number;
  uds_actualmileage: number;
  uds_basemodelname: string;
  uds_bodyname: string;
  uds_co2_efficiencycode: string;
  uds_co2_efficiencycode_value: number;
  uds_co2_emission_combined: number;
  uds_co2_emission_uom: string;
  uds_cost_center1_anteil: number;
  uds_cost_center1_share: number;
  uds_cost_center2_anteil: number;
  uds_cost_center2_share: number;
  uds_cost_center3_anteil: number;
  uds_cost_center3_share: number;
  uds_countofairbags: number;
  uds_countofaxles: number;
  uds_countofdrivedaxles: number;
  uds_cylinderarrangement: string;
  uds_cylinders: number;
  uds_datecode: string;
  uds_drive: string;
  uds_einbau_eigentuemer_code: string;
  uds_einbau_eigentuemer_code_value: number;
  uds_einbau_vorhanden_code: string;
  uds_einbau_vorhanden_code_value: number;
  uds_emissioncategorycode: string;
  uds_emissioncategorycode_value: number;
  uds_emissioncategoryname: string;
  uds_enginecycle: number;
  uds_enginetypecode: string;
  uds_enginetypecode_value: number;
  uds_enginetypename: string;
  uds_ersatzbestellung: string;
  uds_fahrzeugart: string;
  uds_fahrzeugart_value: string;
  uds_fahrzeugpreise: string;
  uds_fahrzeugpreise_value: string;
  uds_feinstaubplakette: number;
  uds_fuelconsumption_out_of_city: string;
  uds_fuelconsumption_urban: string;
  uds_gearbox: string;
  uds_height: number;
  uds_hu_interval_monatecode: string;
  uds_hu_interval_monatecode_value: number;
  uds_hubraum: number;
  uds_interne_fahrzeugnummer_kunde: string;
  uds_iserinnerungen_zulassen: boolean;
  uds_isexportnachsap: boolean;
  uds_kostenstelle2: string;
  uds_kostenstelle3: string;
  uds_laufleistung: number;
  uds_lease_return_date: string;
  uds_leaseenddate_extended: string;
  uds_leasingcontractadvise: string;
  uds_leasinglaufzeit: string;
  uds_leistung: number;
  uds_length: number;
  uds_loadingspace: number;
  uds_mileage_on_return: number;
  uds_modexocarinternalid: string;
  uds_payload: number;
  uds_permissabletotalweight: number;
  uds_powerhp: number;
  uds_powerkw: number;
  uds_roofload: number;
  uds_rotationsonmaxtorque: number;
  uds_salesdescription: string;
  uds_silverdatvehicletypeoption: string;
  uds_silverdatvehicletypeoption_value: VehicleTypeId;
  uds_speedmax: number;
  uds_stromverbrauch: string;
  uds_summeprojektion: number;
  uds_tankvolume: number;
  uds_torque: number;
  uds_trailerloadbraked: number;
  uds_trailerloadunbraked: number;
  uds_unloadedweight: number;
  uds_vehicledoors: number;
  uds_vehicleseats: number;
  uds_verbrauch: string;
  uds_wheelbase: number;
  uds_width: number;
  portalCarStatus: VehicleStatusesLabel;
  serverDate: string;
  leasingruckgabeproEnable: boolean;
}>;
