import { RequestStatuses } from '../../../../constants';
import { ReactQueryKeys } from 'common/enums';
import { AnyObject, ApiResponse, ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { ApprovalRecord, ApprovalRecordResponse } from './use-approvals.types';

const modifyResponse = ({ data }: ApiResponse<ApprovalRecordResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return data.data.approvals.sort((a: AnyObject, b: AnyObject) =>
      a.attributes.uds_name?.localeCompare(b.attributes.uds_name)
    );
  }
  return [];
};

const useApprovalRecords = (vehicleOfferId: string, enabled: boolean = true) => {
  const { fetch } = useFetch<ResponseModel<ApprovalRecordResponse>>({
    isShowLoading: false,
    isShowErrorSnackbar: false,
    ...vehicleRequestConfig.getApprovalStatus(vehicleOfferId)
  });

  const { data: approvalRecords, refetch: refetchApprovalRecords } = useQuery<
    ApiResponse<ApprovalRecordResponse>,
    unknown,
    ApprovalRecord[]
  >([ReactQueryKeys.GetApprovalRecords, vehicleOfferId], fetch, {
    enabled: !!vehicleOfferId && enabled,
    select: modifyResponse
  });

  return { approvalRecords, refetchApprovalRecords };
};

export default useApprovalRecords;
