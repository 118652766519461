import { cloneDeep } from 'lodash';
import { SortingUtilsProps } from './sorting.props';
import { PickValue } from 'common/interfaces';

export class SortingUtils<TDataItem> {
  private data: PickValue<SortingUtilsProps<TDataItem>, 'data'>;
  private transformItemCallback?: PickValue<SortingUtilsProps<TDataItem>, 'transformItemCallback'>;

  constructor({ data, transformItemCallback }: SortingUtilsProps<TDataItem>) {
    this.data = data;
    this.transformItemCallback = transformItemCallback;
  }

  sortByTypes = (a: unknown, b: unknown) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    }
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    if (typeof a === 'boolean' && typeof b === 'boolean') {
      return a === b ? 0 : a ? -1 : 1;
    }

    return 0;
  };

  getSortedData = (isRevers: boolean = false) => {
    const cloneData = cloneDeep(this.data);

    return cloneData.sort((a, b) => {
      if (a && b) {
        const firstValue = this.transformItemCallback ? this.transformItemCallback(a) : a;
        const secondValue = this.transformItemCallback ? this.transformItemCallback(b) : b;

        return isRevers ? this.sortByTypes(secondValue, firstValue) : this.sortByTypes(firstValue, secondValue);
      }
      return 0;
    });
  };
}
