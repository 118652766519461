import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const Checked = ({ width = 20, height = 18, color: colorProp }: IconProps) => {
  const { defaultColor } = useDefaultIconsColor();
  const color = colorProp || defaultColor;

  return (
    <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.29003 10.6601L6.95371 16.5515L19.29 1.44852'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};
