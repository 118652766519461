import React, { useState } from 'react';
import classes from './table-component.module.scss';
import classNames from 'classnames';
import { ColumnType, RootTagName, TableComponentProps } from './table-component.props';
import { RowExpandable } from './row-expandable/row-expandable';
import { Row } from './row/row';
import { PrefixForId } from 'common/enums';
import { RowContext } from './table-component-contexts';
import HeaderCell from './header-cell';
import { SkeletonComponent } from 'components/skeleton/skeleton';
import { RepeatChildren } from 'components/repeat-children/repeat-children';

const TableComponent = ({
  columns,
  rows,
  sort,
  sortParams,
  rootTag,
  minWidth = 850,
  className,
  openedItem = '',
  maxWidth,
  loading,
  substitutionElement
}: TableComponentProps) => {
  const [openedItemId, setOpenedItemId] = useState(openedItem);

  const isSortNeeded = (column: ColumnType) => {
    return (
      column.sort &&
      (sortParams?.predicate === column.sortName ||
        sortParams?.fieldName === column.sortName ||
        (sortParams?.hasOwnProperty(column?.sortName || '') && sortParams[column.sortName || ''] !== null))
    );
  };

  const sortArrowClassName = (column: ColumnType) => {
    return classNames(classes.sort_arrow, {
      [classes.reverse]: sortParams?.reverse || (sortParams && sortParams[column.sortName || -1] === 1)
    });
  };

  const sortHandler = (column: ColumnType) => {
    if (column.sort && typeof sort === 'function') {
      sort(column.sortName || '');
    }
  };

  const toggleAllAccordions = (id: string) => {
    if (id === openedItemId) {
      setOpenedItemId('');
      return;
    }
    setOpenedItemId(id);
  };

  if(!loading && !rows.length && substitutionElement){
    return <div>{substitutionElement}</div>;
  }

  return (
    <div className={className} style={{ height: 'auto', margin: '0 -10px' }}>
      <div style={{ minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px` }} className={classes.table_container}>
        <div
          id={PrefixForId.Table + PrefixForId.Column + PrefixForId.Title + PrefixForId.Container}
          className={classes.table_header}
        >
          {columns?.map((column, index) => (
            <HeaderCell
              key={index}
              column={column}
              index={index}
              columnLength={columns.length}
              sortHandler={sortHandler}
              isSortNeeded={isSortNeeded}
              sortArrowClassName={sortArrowClassName}
            />
          ))}
        </div>

        {loading && (
          <RepeatChildren
            repeatNumber={20}
            item={<SkeletonComponent color='white' height='82px' marginBottom='10px' />}
          />
        )}

        {rows.map((row, index) => {
          return (
            <RowContext.Provider key={index} value={{ rowId: PrefixForId.Table + PrefixForId.Row + index }}>
              {rootTag.name === RootTagName.Expand ? (
                <RowExpandable
                  key={row[rootTag.propName]}
                  columns={columns}
                  row={row}
                  rootTag={rootTag}
                  openedItemId={openedItemId}
                  toggleAllAccordions={toggleAllAccordions}
                />
              ) : (
                <Row
                  key={row[rootTag.keyName || rootTag.propName]}
                  columns={columns}
                  row={row}
                  rootTag={rootTag}
                  openedItemId={openedItemId}
                />
              )}
            </RowContext.Provider>
          );
        })}
      </div>
    </div>
  );
};

export const HTML_COLUMN_MARGIN = '5px';
export const DEFAULT_COLUMN_MARGIN = '25px';
export const TABLE_MENU_WIDTH = '20px';

export default TableComponent;
