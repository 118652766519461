import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const EuroSignWithBorderIcon = ({
  width = 26,
  height = 26,
  color: colorProp,
  fill: fillProp,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1' y='1' width='24' height='24' rx='1' fill={fill} />}
      <path
        d='M20.5896 25H23.4C23.9601 25 24.2401 25 24.454 24.891C24.6422 24.7951 24.7951 24.6422 24.891 24.454C25 24.2401 25 23.9601 25 23.4V2.6C25 2.03995 25 1.75992 24.891 1.54601C24.7951 1.35785 24.6422 1.20487 24.454 1.10899C24.2401 1 23.9601 1 23.4 1H2.6C2.03995 1 1.75992 1 1.54601 1.10899C1.35785 1.20487 1.20487 1.35785 1.10899 1.54601C1 1.75992 1 2.03995 1 2.6V23.4C1 23.9601 1 24.2401 1.10899 24.454C1.20487 24.6422 1.35785 24.7951 1.54601 24.891C1.75992 25 2.03995 25 2.6 25H16.6615'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M17.1654 8.70677C13.0884 5.03965 9.77318 7.9442 8.84834 11.2073C8.67035 11.8353 8.65945 12.868 8.65945 12.868C8.65945 12.868 8.64392 14.0337 8.84834 14.7391C9.79462 18.0044 13.0884 20.9779 17.1654 17.3108'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path d='M13.6562 11.7557L6.83447 11.7443' stroke={color} strokeWidth='1.2' strokeLinecap='round' />
      <path d='M13.5915 14.2557H6.83447' stroke={color} strokeWidth='1.2' strokeLinecap='round' />
    </svg>
  );
};
