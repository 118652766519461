import React, { useContext } from 'react';
import { TabItemFrameProps } from './tab-item-frame/tab-item-frame.props';
import { AccordionTabContext } from '../../accordion.context';
import { TabItemForm } from './ui/form/form';

export const TabItemController = (props: TabItemFrameProps) => {
  const { ui } = useContext(AccordionTabContext);

  switch (ui) {
    case 'form':
      return <TabItemForm {...props} />;

    default:
      return null;
  }
};
