import React, {Fragment} from "react";

import classes from "./sidebar.module.scss";
import Backdrop from "../backdrop";
import BasicInformationFilter from "../filters/basic-information";

export default function Sidebar(props) {
  let sidebarClasses = props.open
    ? [classes.sidebar, classes.open]
    : [classes.sidebar, classes.close];

  if (props.open) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  return (
    <Fragment>
      <Backdrop zIndex={100} show={props.open} click={props.onSetOpen}/>
      <div className={sidebarClasses.join(' ')}>
        <BasicInformationFilter />
      </div>
    </Fragment>
  )
}
