import React from 'react';
import classes from 'classnames';
import { uds_enginetypecode } from '../../constants';

export default function ConsumptionSmallText({ item, styles = { fontSize: '9px', marginTop: '0' }, showFANumber }) {
  const engineCode = item.uds_enginetypecode_value;

  const isElectro = engineCode === uds_enginetypecode.elektro;
  const isHybrid = engineCode === uds_enginetypecode.hybrid_benzin || engineCode === uds_enginetypecode.hybrid_diesel;

  return (
    <div style={styles} className='product-minitext'>
      <i className={classes({ hidden: isElectro })}>
        {isHybrid ? 'Gewichteter ' : null}
        Kraftstoffverbr.*:{item.uds_uds_fuelconsumption_combined} (komb.),
      </i>
      <i className={classes({ hidden: !(isElectro || isHybrid) })}>
        Stromverbrauch: {item.new_stromverbrauch} (komb.) ,
      </i>
      <i className='d-block'>CO₂-Emissionen*: {item.uds_co2_emission_combined} g/km (komb.)</i>
      <i className='d-block'>Gelieferte Fahrzeuge können von der Abbildung abweichen</i>
      {showFANumber && <i className='d-block'>{item.uds_name}</i>}
    </div>
  );
}
