import React from 'react';
import { DraggableListDroppableContentProps } from './droppable-content.props';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { DraggableListDefaultType } from '../../draggable-list.props';
import { StyledDroppableContainer, StyledDroppableContentContainer } from '../../draggable-list.styled';
import { SixDots } from 'components/icons/six-dots';
import { Flex } from 'common/common-components.styled';
import { DraggableListEmptyPlaceholder } from '../../components/empty-placeholder/empty-placeholder';
import { RepeatChildren } from 'components/repeat-children/repeat-children';
import { SkeletonComponent } from 'components/skeleton/skeleton';
import useWindowSize from 'hooks/use-window-size';

export function DraggableListDroppableContent<TDataItem extends DraggableListDefaultType>({
  provided,
  snapshot,
  isLoading,
  data,
  itemComponent,
  droppableId,
  emptyPlaceholder,
  droppableContentContainer = {}
}: DraggableListDroppableContentProps<TDataItem>) {
  const renderContent = () => {
    const { customComponent: CustomContainer = StyledDroppableContentContainer } = droppableContentContainer;

    const { isMobile } = useWindowSize();

    const getContainerProviderProps = (provided: DraggableProvided) => {
      let containerProvider = { ...provided.draggableProps };

      if (!isMobile) {
        containerProvider = { ...containerProvider, ...provided.dragHandleProps };
      }

      return containerProvider;
    };

    if (isLoading) {
      return (
        <RepeatChildren
          repeatNumber={5}
          item={<SkeletonComponent type='rounded' height='57px' marginBottom='10px' />}
        />
      );
    }

    return (
      <PerfectScrollbar id={droppableId}>
        {!data.length && <DraggableListEmptyPlaceholder {...emptyPlaceholder} />}
        {data.map((item, index) => (
          <Draggable draggableId={item.id} index={index} key={item.id}>
            {(provided, snapshot) => (
              <Flex ref={provided.innerRef} width='100%'>
                <CustomContainer
                  item={item}
                  {...getContainerProviderProps(provided)}
                  isDragging={snapshot.isDragging}
                  className={droppableContentContainer?.className}
                >
                  {itemComponent(item)}
                  <Flex
                    {...provided.dragHandleProps}
                    className='sixDotsContainer'
                    visibility='visible'
                    opacity='1'
                    left='10'
                  >
                    <SixDots />
                  </Flex>
                </CustomContainer>
              </Flex>
            )}
          </Draggable>
        ))}
      </PerfectScrollbar>
    );
  };

  return (
    <StyledDroppableContainer
      isDraggingOver={snapshot.isDraggingOver}
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      {renderContent()}
    </StyledDroppableContainer>
  );
}
