import React from 'react';
import { Flex } from 'common/common-components.styled';
import { CameraIcon } from 'components/icons-new/camera';
import { StyledCameraIconContainer, StyledEmptyPlaceholderImage } from './empty-placeholder.styled';
import { EmptyPlaceholderProps } from './empty-placeholder.props';
import { Tooltip } from '@mui/material';

export const EmptyPlaceholder = ({ placeholder, createImage, placeholderContainerProps }: EmptyPlaceholderProps) => {
  return (
    <Flex position='relative' width='100%' height='100%' {...placeholderContainerProps}>
      <StyledEmptyPlaceholderImage src={placeholder} alt='Vehicle car placeholder' />

      {createImage && (
        <Tooltip title='Foto hohladen'>
          <StyledCameraIconContainer onClick={createImage}>
            <CameraIcon width={24} height={24} />
          </StyledCameraIconContainer>
        </Tooltip>
      )}
    </Flex>
  );
};
