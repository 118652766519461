import React, { useEffect, useMemo, useState } from 'react';
import DocumentSectionFileComponent from 'pages/details/document-section-file-component/document-section-file-component';
import { AnyObject, FileModel, LeasingOffer, User } from 'common/interfaces';
import sharepointService from 'services/sharepoint-service';
import { useOverlay } from 'hooks';
import Spinner from 'components/spinner';
import {
  StyledDealerPageFilesContainer,
  StyledNoFilesText
} from 'components/barpreisanfragen-component/components/dealerpage-files/dealerpage-files.styled';
import { USER_ROLES } from '../../../../constants';
import { Flex, Title } from 'common/common-components.styled';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { PAGES } from 'common/enums';

export const NoFiles = () => {
  return (
    <Flex direction='column' align='center'>
      <img src='/assets/images/new_config_icon.svg' alt='new_config_icon' />
      <Title margin='30px 0 10px' as='h5' color='blue'>
        Keine Daten
      </Title>
      <StyledNoFilesText color='darkBlue'>Zu diesem Fahrzeug fehlen die entsprechenden Daten.</StyledNoFilesText>
    </Flex>
  );
};

interface DealerPageFilesProps {
  offerId: string;
  offerConfirmed: boolean;
  refreshKey: number;
  leasingOffers: LeasingOffer[];
}

const DealerPageFiles = ({ offerId, offerConfirmed, refreshKey, leasingOffers }: DealerPageFilesProps) => {
  const [showOverlay, hideOverlay] = useOverlay();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<FileModel[]>([]);

  const isLeasingcompany = useIsUserHaveRole(USER_ROLES.leasingcompany.id);

  const allowedFileDescriptions = useMemo(() => {
    const descriptions = ['Hersteller-Konfiguration'];
    const isLeasinganfragenDetailsPage = location.pathname.includes(PAGES.LEASING_REQUESTS_DETAILS);
    if (isLeasinganfragenDetailsPage) {
      descriptions.push('Angebot vom Händler');
    }
    return descriptions;
  }, [location]);

  useEffect(() => {
    const descriptions = ['Hersteller-Konfiguration', 'Angebot vom Händler'];

    const requests = [sharepointService.getFilesByDescriptions({ entityId: offerId, descriptions })];
    if (isLeasingcompany) {
      leasingOffers.forEach(leasingOffer => {
        requests.push(
          sharepointService.getFilesByDescriptions({
            entityId: leasingOffer.uds_leasingangeboteid,
            descriptions: ['Leasingangebot an Kunde']
          })
        );
      });
    }

    Promise.allSettled(requests)
      .then(response => {
        let files: FileModel[] = [];
        response.forEach((item: AnyObject) => {
          if (item.value && item.value.data.data) {
            files = [...files, ...item.value.data.data];
          }
        });
        files = files.sort((a, b) => (new Date(b.created) as any) - (new Date(a.created) as any));
        setFiles(files);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [refreshKey]);

  const download = (fileId: string) => {
    const downloadedFile = files.find(file => file.id === fileId);
    if (downloadedFile) {
      showOverlay();
      sharepointService
        .downloadFileById(fileId)
        .then(({ data }: { data: Blob }) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(new Blob([data]));
          a.download = downloadedFile.name;
          a.click();
        })
        .catch(err => console.log(err))
        .finally(hideOverlay);
    }
  };

  return (
    <StyledDealerPageFilesContainer>
      {loading ? (
        <Spinner
          style={{
            width: '30px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      ) : files.filter(file => allowedFileDescriptions.includes(file.description) || offerConfirmed).length ? (
        files
          .filter(file => allowedFileDescriptions.includes(file.description) || offerConfirmed)
          .map(file => <DocumentSectionFileComponent key={file.id} file={file} download={download} />)
      ) : (
        <NoFiles />
      )}
    </StyledDealerPageFilesContainer>
  );
};

export default DealerPageFiles;
