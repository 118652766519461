import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DashboardComponent from '../../components/dashboard-component';
import BreadcrumbsPanelDashboard from '../../components/breadcrumbs-panel-dashboard';
import Overlay from '../../components/overlay';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from '../../services/auth-service';
import BreadcrumbsPanelLinks from '../../components/breadcrumbs-panel-links';
import LoadingComponent from '../../components/loading-component/loading-component-view';
import { useDispatch, useSelector } from 'react-redux';
import ChatBot from '../../components/chatbot/chatbot';
import { VideoTutorials } from 'components/hilfecenter/hilfecenter';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { DeviceTypes, USER_ROLES } from '../../constants';
import { DashboardChart } from 'components/dashboard-component-chart/dashboard';
import HelpSection from 'components/greeting-sidebar/greeting-sidebar';
import classes from './dashboard.module.scss';
import classNames from 'classnames';
import { isGuideToutNeeded } from './dashboard-service';
import {
  setDashboardTourIsStartted,
  setLoginCounter,
  setShowGuidedTourFirst,
  toggleAfterTourPopUp
} from 'actions/app_action';
import { PAGES } from 'common/enums';
import { DashboardDriver } from 'components/dashboard-driver/dashboard-driver';
import SideMenu from '../../components/side-menu';
import { ErrorBoundary } from 'components/error-boundary/error-boundary.redux';
import useCloseSidebarOnChangeLocation from '../../hooks/use-close-sidebar-on-change-location';
import { useLocation } from 'react-router-dom';

export const PageContainer = ({ children }) => {
  const location = useLocation();
  const user = useSelector(state => state['app'].user);
  const [height, setHeight] = useState(window.innerHeight);
  const authRequest = {
    ...loginRequest
  };
  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const dispatch = useDispatch();
  const shoudShowBookingModal = localStorage.getItem('bookingModal');
  const isCarConfiguratorPage = location.pathname.includes('/konfigurator/');

  useCloseSidebarOnChangeLocation();

  const checkIsGuidedTourNeeded = async () => {
    const { shouldShowGuidedTour, isDemoUser, loginCount } = await isGuideToutNeeded();
    dispatch(setLoginCounter(loginCount));
    if (location.pathname === PAGES.DASHBOARD) {
      dispatch(setDashboardTourIsStartted(shouldShowGuidedTour));
      dispatch(setShowGuidedTourFirst(shouldShowGuidedTour));
    }

    if (isDemoUser && !shouldShowGuidedTour && !shoudShowBookingModal) {
      setTimeout(() => {
        localStorage.setItem('bookingModal', 'true');
        dispatch(toggleAfterTourPopUp(true));
      }, 120000);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (isFleetManager) {
      if (window.innerWidth >= 768) {
        checkIsGuidedTourNeeded();
      } else {
        dispatch(setShowGuidedTourFirst(false));
      }
    } else {
      dispatch(setDashboardTourIsStartted(false));
      dispatch(toggleAfterTourPopUp(false));
    }
  }, [isFleetManager, window.innerWidth]);

  const resizeHandler = () => {
    setHeight(window.innerHeight);
  };

  return (
    <>
      {user.companyId ? (
        <div style={{ height }} className='d-flex'>
          {user.roles.length > 0 && <SideMenu key={user.mainRole.id} />}
          <div className='w-100 d-flex f-d-column' style={{ overflow: 'auto' }}>
            <BreadcrumbsPanelDashboard />
            <div className={classes.overlay_wrapper}>
              <Overlay noScrollToTop={true} />
              <div className={classNames('scroll-container', classes.breadscrums_wrapper)} style={{ overflow: 'auto' }}>
                <BreadcrumbsPanelLinks />
                <ErrorBoundary>
                  <Suspense fallback={<Overlay show />}>{children}</Suspense>
                </ErrorBoundary>
              </div>
            </div>
          </div>
          {!isCarConfiguratorPage && (
            <>
              <VideoTutorials />
              <ChatBot key={user.avatar ? '0' : '1'} />
            </>
          )}
          <HelpSection />
          <div id={'meeting_booking'} style={{ height: '99%' }} />
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

PageContainer.propTypes = {
  children: PropTypes.any
};

const DashboardPage = () => {
  const isFleetManagerOrAdmin = useIsUserHaveRole([USER_ROLES.fleetManager.id, USER_ROLES.administrator.id]);
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);

  if (isFleetManagerOrAdmin) {
    return <DashboardChart />;
  }

  if (isDriver) {
    return <DashboardDriver />;
  }

  return <DashboardComponent />;
};

export default DashboardPage;
