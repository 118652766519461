import { FileDownloadProps } from 'hooks/useFileDownload';
import { FuelReportPayload, reportsRequestConfig } from 'request-config/reports/reports.request-config';

export const downloadFuelReportConfig: FileDownloadProps = {
  requestConfig: reportsRequestConfig.getFuelReport({} as FuelReportPayload),
  fileName: 'Fuel_report.xlsx',
  encodeConfig: {
    path: [],
    encodeWay: 'newBlob'
  }
};
