import { useEffect, useState } from 'react';
import useCostCentersHistoryList from './use-cost-centers-history-list';

const useFilteredItems = (isExpanded?: boolean) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const { costCentersHistoryItems, isLoading } = useCostCentersHistoryList();

  useEffect(() => {
    if (typeof isExpanded === 'boolean') {
      setShowAllItems(isExpanded);
    }
  }, [isExpanded]);

  const filteredItems = costCentersHistoryItems.slice(
    0,
    showAllItems ? costCentersHistoryItems.length : DEFAULT_NUMBER_OF_ITEMS
  );

  const isToggleButtonAvailable = costCentersHistoryItems.length > DEFAULT_NUMBER_OF_ITEMS;

  const toggleShowAllItems = () => {
    setShowAllItems(showAllItems => !showAllItems);
  };

  return { showAllItems, isLoading, filteredItems, toggleShowAllItems, isToggleButtonAvailable };
};

const DEFAULT_NUMBER_OF_ITEMS = 5;

export default useFilteredItems;
