import React from 'react';
import { IconButton } from '@mui/material';
import { ReturnToInitialFieldButtonProps } from './return-to-initial-field.props';
import { Direction, Flex, Rotate } from 'common/common-components.styled';
import { ArrowIcon } from 'components/icons-new/arrow';

export const ReturnToInitialFieldButton = ({ className, onClick }: ReturnToInitialFieldButtonProps) => {
  return (
    <Flex className={className}>
      <IconButton onClick={onClick}>
        <Rotate rotateDirection={Direction.Right}>
          <ArrowIcon width={14} height={14} />
        </Rotate>
      </IconButton>
    </Flex>
  );
};
