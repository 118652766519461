import React, { ComponentProps } from 'react';
import { NoteItemContainer } from '../note-item-container/note-item-container';
import { ContractIcon } from 'components/icons-new/contract';
import { Flex, Text } from 'common/common-components.styled';
import { useTheme } from 'styled-components';

export const VehicleDetailsNotesEmptyPlaceholder = (linkProps: Omit<ComponentProps<'p'>, 'ref'>) => {
  const theme = useTheme();

  return (
    <NoteItemContainer>
      <Flex direction='column' align='center' padding='20px'>
        <ContractIcon width='70px' height='70px' color={theme.colors.lightBlue} hovered />
        <Text color='blue' bold top='10' bottom='10'>
          Du hast keine Notizen
        </Text>
        <Text color='blue' underline pointer {...linkProps}>
          Notiz erstellen
        </Text>
      </Flex>
    </NoteItemContainer>
  );
};
