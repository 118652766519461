import { ApiResponse, BasketResponse } from 'common/interfaces';
import { useQuery } from 'react-query';
import { getUsersCompanyBasket } from 'services/user-service';
import { ReactQueryKeys } from 'common/enums';

export const useCompanyBasketQuery = (id: string) => {
  const { data: companyBasketData, ...restCompanyBasketQuery } = useQuery<ApiResponse<BasketResponse | null>>(
    [ReactQueryKeys.GetUserCompanyBasket, id],
    () => getUsersCompanyBasket(id),
    { enabled: !!id }
  );

  return {
    companyBasket: companyBasketData?.data.data || null,
    ...restCompanyBasketQuery
  };
};
