import React from 'react';
import { Flex, Text } from 'common/common-components.styled';
import { VehicleDetailsDocumentForm } from './components/document-form/document-form';

export const VehicleDetailsUploadDocuments = () => {
  return (
    <Flex direction='column'>
      <Text color='blue' size='18' bold bottom='20'>
        Dokument hochladen
      </Text>

      <VehicleDetailsDocumentForm />
    </Flex>
  );
};
