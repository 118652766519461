import { useTypedSelector } from './use-typed-selector';
import { User } from 'common/interfaces';
import { useUserCompaniesDetails } from 'components/global-reference-vehicles/react-query/use-user-company-details';
import { REFERENCE_DISTANCE_OPTIONS, REFERENCE_MILEAGE_RANGE_OPTIONS } from '../constants';

export const useUserCompaniesReferenceValues = () => {
  const user = useTypedSelector<User>(state => state.app.user);

  const { leaseLengthMileageDetails } = useUserCompaniesDetails(user.companyId);

  const referenceDistance =
    Object.values(REFERENCE_MILEAGE_RANGE_OPTIONS).find(
      option => option.value === leaseLengthMileageDetails?.uds_lease_reference_annual_milleagecode_value
    )?.labelNumber || null;
  const referenceDuration =
    Object.values(REFERENCE_DISTANCE_OPTIONS).find(
      option => option.value === leaseLengthMileageDetails?.uds_lease_reference_length_monthcode_value
    )?.labelNumber || null;

  return {
    referenceDistance: leaseLengthMileageDetails ? referenceDistance : undefined,
    referenceDuration: leaseLengthMileageDetails ? referenceDuration : undefined
  };
};
