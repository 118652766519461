import { ResponseModel } from 'common/interfaces';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { RequestStatuses } from '../../../constants';
import { PAGES } from 'common/enums';
import { getResponseFormData } from 'utils/get-response-data';
import { getCompanyData } from 'services/company-service';
import { CostCenterPrimaryEntity, PrimaryEntityValues } from '../cost-centers-settings.props';
import moment from 'moment';

const BLOCK_CHANGING_MINUTES = 10;

const isChangingPrimaryEntityAvailable = (lastUpdate: string | null) => {
  if (!lastUpdate) {
    return true;
  }
  return moment().isAfter(moment(lastUpdate).add(BLOCK_CHANGING_MINUTES, 'minutes'));
};

const modifyResponse = ({ data }: AxiosResponse<ResponseModel>) => {
  if (data.level === RequestStatuses.Success) {
    const response = getResponseFormData(data, [{ name: '0', type: 'object' }]) as CostCenterPrimaryEntity;
    return {
      costCenterPrimaryEntity: response.uds_main_entity_costcentercode
        ? (String(response.uds_main_entity_costcentercode) as PrimaryEntityValues)
        : null,
      disableChanging: !isChangingPrimaryEntityAvailable(response.uds_main_entity_costcenter_lastupdated)
    };
  }

  return undefined;
};

export const useCostCenterPrimaryEntity = (companyId?: string) => {
  const { data: primaryEntityResponse, refetch: refetchCostCenterPrimaryEntity } = useQuery(
    [PAGES.COMPANIES_BUSINESS_DETAILS, 'cost-centers-main-entity', companyId],
    () => getCompanyData(companyId),
    {
      select: modifyResponse,
      enabled: !!companyId
    }
  );

  return {
    costCenterPrimaryEntity: primaryEntityResponse?.costCenterPrimaryEntity,
    disableChanging: primaryEntityResponse?.disableChanging,
    refetchCostCenterPrimaryEntity
  };
};
