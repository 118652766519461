import axios from 'axios';
import React, { useState } from 'react';
import { useOverlay } from '../../hooks';
import Button from '../button';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../expansion-panel';
import Download from '../icons/download';
import classes from './download-expansion-component.module.scss';
import authService from '../../services/auth-service';

export const getFileForDownload = async (params, cancelToken) => {
  const token = await authService.acquireTokenAsync();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/fuhrpark/filebyname?filerelativeurl=${encodeURIComponent(params)}`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    cancelToken,
    responseType: 'arraybuffer'
  });
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const getFormat = name => {
  const availableFormats = {
    jpg: 'image/jpg',
    png: 'image/png',
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml'
  };
  for (const [key, value] of Object.entries(availableFormats)) {
    if (name.toLowerCase().includes(key)) {
      return value;
    }
  }
};

export const DownloadExpansionComponent = ({ files }) => {
  const [openedItemId, setOpenedItemId] = useState('');
  const [expanded, setExpanded] = useState(false);

  const toggleAllAccordions = id => {
    id === openedItemId ? setOpenedItemId('') : setOpenedItemId(id);
  };

  const DownloadExpansionItem = ({ item, toggleAllAccordions, openedItemId }) => {
    const [showOverlay, hideOverlay] = useOverlay();
    const { FileId: id, Description: dokumententyp, FilePath: filePath } = item;
    const filePathArr = filePath.split('/');
    const fileName = filePathArr[filePathArr.length - 1];
    const isOpen = id === openedItemId;

    const onDownload = () => {
      showOverlay();
      getFileForDownload(filePath)
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/octet-stream' });
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = fileName;
          a.click();
        })
        .catch(err => console.log(err))
        .finally(hideOverlay);
    };

    if (!dokumententyp) {
      return null;
    }

    return (
      <div className={classes.fahrzeugdokumente_card}>
        <ExpansionPanel
          open={isOpen}
          id={id}
          toggleAllAccordions={toggleAllAccordions}
          disableAnimation={true}
          theme='fahrzeugdokumente_theme'
        >
          <ExpansionPanelSummary>
            <div className={classes.header}>{dokumententyp}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.fahrzeugdokumente_card_content}>
              <div>
                <span className={classes.item_header}>Dokumententyp</span>
                <span className={classes.item_content}>{dokumententyp}</span>
              </div>
              <div>
                <span className={classes.item_header}>Dateiname</span>
                <span className={classes.item_content}>{fileName}</span>
              </div>
              <Button
                className={classes.download_btn}
                component='button'
                onClick={() => {
                  onDownload();
                }}
              >
                <>
                  <Download fill={'white'} className={classes.button_img} />
                  Dokument herunterladen
                </>
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  return (
    <section>
      {files &&
        files.slice(0, expanded ? files.length : 2).map(item => {
          return (
            <DownloadExpansionItem
              key={item.FileId}
              item={item}
              toggleAllAccordions={toggleAllAccordions}
              openedItemId={openedItemId}
            />
          );
        })}
      {files.filter(item => item.Description).length > 2 ? (
        <div onClick={() => setExpanded(!expanded)} className={classes.mehr_anzeigen_btn}>
          {expanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </div>
      ) : null}
    </section>
  );
};
