import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const CircleCloseIcon = ({
  width = '24',
  height = '24',
  color: colorProp,
  fill: fillProp,
  hovered,
  ...restProps
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg
      width={width}
      height={height}
      {...restProps}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {hovered && <circle cx='12.5482' cy='12' r='9' fill={fill} />}
      <path
        d='M15.366 20.5501C14.4796 20.842 13.5324 21 12.5482 21C7.57766 21 3.54822 16.9706 3.54822 12C3.54822 7.02944 7.57766 3 12.5482 3C17.5188 3 21.5482 7.02944 21.5482 12C21.5482 14.6738 20.3822 17.0753 18.531 18.7237'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.77405 8.22578L16.3224 15.7742'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.3224 8.22578L8.77405 15.7742'
        stroke={color}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
