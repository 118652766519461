import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import classes from './leasing-section.module.scss';
import { getDienstleistungByFuhrpark } from '../../../../../../../../../services/fuhrpark-service';
import useRequest from '../../../../../../../../../hooks/use-request';
import toMoney from '../../../../../../../../../utils/toMoney';
import cloneDeep from 'lodash/cloneDeep';
import {
  betragFormFields,
  dienstleisterFormFields,
  gebuchtFormFields,
  inkludiertFormFields,
  laufleistungFormFields,
  laufleistungPaFormFields,
  leasingdauerFormFields,
  leasingfaktorFormFields,
  mehrkilometerFormFields,
  minderkilometerFormFields,
  selbstbeteiligungTeilkaskoFormFields,
  selbstbeteiligungVollkaskoFormFields,
  toleranzgrenzeFormFields,
  validFromFormFields,
  validToFormFields,
  vertragsNrFormFields,
  leasingstartFormFields,
  leasingendFormFields,
  zahlungsrythmusFormFields,
  leasingendExtendedFormFields,
  workshopFormFields,
  insuranceTypeFormFields,
  wheelStorageField
} from './leasing-section-form-fields';
import { useOverlay, useQuery as useQueryLocation } from '../../../../../../../../../hooks';
import { updateByDienstleistung } from '../../../../../../../../../services/services-service';
import {
  RequestStatuses,
  SERVICES_CONTRACT_TYPES,
  BOOKING_STATUS,
  USER_ROLES
} from '../../../../../../../../../constants';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { Button, Flex, Text } from 'common/common-components.styled';
import { isEmpty } from 'lodash';
import { Tooltip } from '@mui/material';
import CircleInfoIcon from 'components/icons/warning-icon';
import { useTheme } from 'styled-components';
import { getResponseFormData } from '../../../../../../../../../utils/get-response-data';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import PanelPlaceholder from 'components/panel-placeholder';
import { useVehicleDetailsClearCash } from 'pages/existing-vehicle-page/hooks/use-clear-cash';
import { CircleCloseIcon } from 'components/icons-new/circle-close-icon';
import { CheckedIcon } from 'components/icons-new/checked-icon';
import { PenIcon } from 'components/icons-new/pen';
import classNames from 'classnames';
import { CloseIcon } from 'components/icons-new/close';
import { Grid } from '@mui/material';
import { useValidateLeasEndField } from './hooks/use-validate-leas-end-field';
import { EmptyDataIcon } from 'components/icons-new/empty-data';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleStatusesValue } from 'pages/my-vehicles/my-vehicles.props';
import { ShowMoreButton } from 'components/button/components/show-more/show-more';
import { useOpenLeasingContract } from './hooks/use-open-contract';
import { useHighlightedFormFields } from 'hooks/use-highlighted-form-fields';
import { useDispatch } from 'react-redux';
import { setHighlightedForm } from 'actions/app_action';
import { setVehicleDetailsLeasingTab } from 'actions/pages/vehicle-details.action';
import { serviceRequestConfig } from 'request-config/service/service.request-config';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import useWindowSize from 'hooks/use-window-size';

const getLeasingRateText = (item, index) => {
  const leasingrateText = item.new_eigenleistung
    ? 'In Leasingrate enthalten bei: Mobexo GmbH'
    : item['uds_leasingbank']
    ? item.new_inkludiert_leasing
      ? `In Leasingrate enthalten bei: ${item['uds_leasingbank']}`
      : item['uds_leasingbank']
    : item.new_inkludiert_leasing
    ? 'In Leasingrate enthalten'
    : '';

  return leasingrateText ? <span id={`bookedLeasingRateText${index}`}>{leasingrateText}</span> : null;
};

const LeasingItem = ({ item, type, edit, cancelEdit, isEditing, isDriver, id, refreshSection, index }) => {
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    setValue,
    reset
  } = reactHookFormData;
  const [showOverlay, hideOverlay] = useOverlay(LEASING_SECTION_LOADING_KEY);
  const [isAutoValue, setIsAutoValue] = useState(false);
  const leaseStartDate = watch('uds_gultigkeitvon');
  const leaseDuration = watch('uds_leasingdauer');
  const [currentLeasingendFields, setCurrentLeasingendFields] = useState(leasingendFormFields);
  const theme = useTheme();

  const { fetch } = useFetch({ loadingKey: 'submitLeasingSection', isShowSuccessSnackbar: true });

  const { clearCash } = useVehicleDetailsClearCash();
  const { getHighlightedColorStringMemorization } = useHighlightedFormFields();

  const { windowWidth } = useWindowSize(500);

  const setDefaultValues = defaultFields => {
    Object.entries(defaultFields).forEach(([key, value]) => {
      setValue(key, item[value]);
    });
  };

  useEffect(() => {
    setValue('new_gebucht', item.new_gebucht_value === BOOKING_STATUS.ordered);
    setDefaultValues(defaultFieldsFirstGroup);

    if (
      item.new_vertragstyp_value === SERVICES_CONTRACT_TYPES.finance_rate ||
      item.new_vertragstyp_value === SERVICES_CONTRACT_TYPES.insurance
    ) {
      setValue('uds_vertragnr', item.uds_vertragnr);
      setValue('uds_gultigkeitvon', item.uds_gultigkeitvon ? moment.utc(item.uds_gultigkeitvon) : null);
      setValue('uds_gultigkeitbis', item.uds_gultigkeitbis ? moment.utc(item.uds_gultigkeitbis) : null);
    }

    if (item.new_vertragstyp_value === SERVICES_CONTRACT_TYPES.insurance) {
      setDefaultValues(defaultFieldsThirdGroup);
    }

    if (item.new_vertragstyp_value === SERVICES_CONTRACT_TYPES.finance_rate) {
      setDefaultValues(defaultFieldsSecondGroup);
      setValue(
        'uds_leaseenddate_extended_leasingvertraege',
        item.uds_leaseenddate_extended_leasingvertraege
          ? moment.utc(item.uds_leaseenddate_extended_leasingvertraege)
          : null
      );
    }
  }, [isEditing, JSON.stringify(item)]);

  useEffect(() => {
    if (
      item.new_vertragstyp_value === SERVICES_CONTRACT_TYPES.finance_rate &&
      (touchedFields.uds_leasingdauer || touchedFields.uds_gultigkeitvon) &&
      leaseStartDate?.isValid?.()
    ) {
      setValue('uds_gultigkeitbis', moment(leaseStartDate).add(leaseDuration, 'months').subtract(1, 'days'));
      setIsAutoValue(true);
    }
  }, [leaseStartDate, leaseDuration]);

  useEffect(() => {
    if (isAutoValue) {
      const newLeasingendFormFields = cloneDeep(leasingendFormFields);

      newLeasingendFormFields[0] = {
        ...newLeasingendFormFields[0],
        helperText: 'Leasingende wurde automatisch berechnet'
      };

      setCurrentLeasingendFields(newLeasingendFormFields);
      setIsAutoValue(false);
      delete touchedFields.uds_gultigkeitbis;

      return;
    }
    if (touchedFields.uds_gultigkeitbis) {
      setCurrentLeasingendFields(leasingendFormFields);
    }
  }, [leaseStartDate, leaseDuration, touchedFields.uds_gultigkeitbis, isAutoValue]);

  useValidateLeasEndField(reactHookFormData);

  const successSubmitHandler = data => {
    cancelEdit(data);
    reset();
    clearCash();
    refreshSection(oldKey => oldKey + 1);
  };

  const save = item => {
    handleSubmit(async data => {
      const dataToSend = {
        ...data,
        uds_leasingvertrgeid: item.uds_leasingvertrgeid,
        new_gebucht: data.new_gebucht ? BOOKING_STATUS.ordered : BOOKING_STATUS.notOrdered,
        _uds_leasingbank_value: data.uds_leasingbank || null,
        new_betrag: data.new_betrag,
        new_zahlungsrythmus: data.new_zahlungsrythmus || null,
        uds_fuhrparkid: id,
        uds_gultigkeitbis: data.uds_gultigkeitbis ? moment(data.uds_gultigkeitbis).format('YYYY-MM-DD') : null,
        uds_gultigkeitvon: data.uds_gultigkeitvon ? moment(data.uds_gultigkeitvon).format('YYYY-MM-DD') : null,
        uds_leaseenddate_extended_leasingvertraege: data.uds_leaseenddate_extended_leasingvertraege
          ? moment(data.uds_leaseenddate_extended_leasingvertraege).startOf('day').format('YYYY-MM-DD')
          : null,
        new_inkludiert_leasing: !!data.new_inkludiert_leasing
      };

      if (item.mode === 'create') {
        return fetch({
          requestConfig: serviceRequestConfig.create({ ...dataToSend, new_vertragstyp: item.new_vertragstyp_value }),
          callback: () => successSubmitHandler(data)
        });
      }

      let response;
      showOverlay();
      try {
        response = await updateByDienstleistung(dataToSend);
      } catch (e) {
        response = {};
        console.log(e);
      } finally {
        if (response.data?.level === RequestStatuses.Success) {
          successSubmitHandler(data);
        }
        hideOverlay();
      }
    })();
  };

  const formItems = [
    {
      title: 'Gebucht',
      titleStyle: { paddingTop: '10px' },
      formName: 'gebucht',
      formFields: gebuchtFormFields
    },
    {
      title: 'Inkludiert in der Leasingrate',
      titleStyle: { paddingTop: '10px' },
      formName: 'inkludiert',
      formFields: inkludiertFormFields
    },
    {
      formName: 'dienstleister',
      formFields: dienstleisterFormFields
    }
  ];

  const betragFormItems = [
    {
      title: 'Betrag',
      formName: 'betrag',
      formFields: betragFormFields
    },
    {
      title: 'Zahlungsrythmus',
      formName: 'zahlungsrythmus',
      formFields: zahlungsrythmusFormFields
    }
  ];

  const isShowContractNumberForm =
    item?.new_vertragstyp_value === 100000000 || item?.new_vertragstyp_value === 100000001;

  const finanzrateFormItems = [];
  const kilometersFormItems = [];
  const leasingFormItems = [];
  const deductibleFormItems = [];
  const validityFormItems = [];

  if (item?.new_vertragstyp_value == 100000001) {
    const newValidFromFormFields = cloneDeep(validFromFormFields);
    const newValidToFormFields = cloneDeep(validToFormFields);
    if (item.uds_gultigkeitbis) {
      newValidToFormFields[0].maxDate = new Date(item.uds_gultigkeitbis);
    }
    if (item.uds_gultigkeitvon) {
      newValidToFormFields[0].minDate = new Date(item.uds_gultigkeitvon);
    }

    deductibleFormItems.push({
      title: 'Selbstbeteiligung Vollkasko',
      formName: 'selbstbeteiligungvollkasko',
      formFields: selbstbeteiligungVollkaskoFormFields
    });
    deductibleFormItems.push({
      title: 'Selbstbeteiligung Teilkasko',
      formName: 'selbstbeteiligungteilkasko',
      formFields: selbstbeteiligungTeilkaskoFormFields
    });
    finanzrateFormItems.push({
      title: 'Werkstättenbindung',
      formName: 'werkstättenbindung',
      formFields: workshopFormFields,
      tooltipText:
        'Eine Werkstattbindung bedeutet, dass Ihr Versicherer bei einem Unfallschaden entscheidet, in welcher Werkstatt Ihr Fahrzeug repariert wird'
    });
    validityFormItems.push({
      title: 'Gültigkeit von',
      formName: 'gultigkeitvon',
      formFields: newValidFromFormFields
    });
    validityFormItems.push({
      title: 'Gültigkeit bis',
      formName: 'gultigkeitbis',
      formFields: newValidToFormFields
    });

    formItems.splice(2, 0, {
      formName: 'insuranceType',
      formFields: insuranceTypeFormFields
    });
  }

  if (item?.new_vertragstyp_value == 100000000) {
    kilometersFormItems.push({
      title: 'Mehrkilometer',
      formName: 'mehrkilometer',
      formFields: mehrkilometerFormFields
    });
    kilometersFormItems.push({
      title: 'Minderkilometer',
      formName: 'minderkilometer',
      formFields: minderkilometerFormFields
    });
    finanzrateFormItems.push({
      title: 'Toleranzgrenze +/- KM',
      formName: 'toleranzgrenze',
      formFields: toleranzgrenzeFormFields
    });
    finanzrateFormItems.push({
      title: 'Leasingfaktor',
      formName: 'leasingfaktor',
      formFields: leasingfaktorFormFields
    });
    leasingFormItems.push({
      title: 'Leasingstart',
      formName: 'leasingstart',
      formFields: leasingstartFormFields
    });
    leasingFormItems.push({
      title: 'Leasingdauer',
      formName: 'leasingdauer',
      formFields: leasingdauerFormFields
    });
    leasingFormItems.push({
      title: 'Leasingende',
      formName: 'leasingende',
      formFields: currentLeasingendFields
    });
    leasingFormItems.push({
      title: 'Stillschweigend verlängert bis',
      formName: 'leasingendeExtended',
      formFields: leasingendExtendedFormFields
    });
    leasingFormItems.push({
      title: 'Laufleistung',
      formName: 'laufleistung',
      formFields: laufleistungFormFields
    });
    leasingFormItems.push({
      title: 'Laufleistung p. a.',
      formName: 'laufleistungpa',
      formFields: laufleistungPaFormFields
    });
  }

  const handleCancelEdit = () => {
    reset();
    cancelEdit();
  };

  if (type === 'gebucht') {
    return (
      <div
        id={`bookedLeasingContainer${index}`}
        data-contract-type-id={item?.new_vertragstyp_value || 0}
        className={
          item.itemExpand || !item.uds_editierbar_auf_der_plattform || isEditing
            ? `${classes.leasing_item} ${classes.no_background}`
            : classes.leasing_item
        }
        style={{ flexDirection: item.itemExpand ? 'column' : 'row' }}
      >
        {item.itemExpand ? (
          <Flex direction='column' padding={windowWidth <= 550 ? '5px 10px' : '15px 20px'}>
            <Flex justify='space-between' width='100%'>
              <Text id={`bookedLeasingTitle${index}`} color='black' size='18' bold>
                {item['new_vertragstyp']}
              </Text>
              <Flex pointer onClick={cancelEdit}>
                <CloseIcon />
              </Flex>
            </Flex>
            {formItems.map(formItem => (
              <Flex key={formItem.title} width='100%' align='center' bottom='20'>
                {formItem.title && (
                  <div className={classes.form_container} style={formItem.titleStyle}>
                    <Text
                      color={getHighlightedColorStringMemorization()(
                        formItem.formName,
                        item[formItem.formFields[0].name]
                      )}
                    >
                      {formItem.title}
                    </Text>
                  </div>
                )}

                <FormHook
                  distanceBetweenFields={0}
                  reactHookFormData={reactHookFormData}
                  formFields={formItem.formFields}
                />
              </Flex>
            ))}
            {betragFormItems.length && (
              <div>
                <div className={classes.form_container}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      betragFormItems[0].formName,
                      item[betragFormItems[0].formFields[0].name]
                    )}
                  >
                    Betrag
                  </Text>
                  <Text left='5' as='span' color='pink'>
                    *
                  </Text>
                </div>
                <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
                  {betragFormItems.map(formItem => (
                    <Grid item xs={1} key={formItem.title}>
                      <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
              {kilometersFormItems.map(formItem => (
                <Grid item xs={1} key={formItem.title}>
                  <div className={classes.form_container}>
                    <Text
                      color={getHighlightedColorStringMemorization()(
                        formItem.formName,
                        item[formItem.formFields[0].name]
                      )}
                    >
                      {formItem.title}
                    </Text>
                    {formItem.tooltipText && (
                      <Tooltip title={formItem.tooltipText} arrow>
                        <Flex>
                          <CircleInfoIcon color={theme.colors.grey400} className={classes.info_icon} />
                        </Flex>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.form_container}>
                    <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                  </div>
                </Grid>
              ))}
            </Grid>
            {isShowContractNumberForm && (
              <FormHook reactHookFormData={reactHookFormData} formFields={vertragsNrFormFields} />
            )}
            <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
              {deductibleFormItems.map(formItem => {
                console.log(item[formItem.formFields[0].name]);
                return (
                  <Grid item xs={1} key={formItem.title}>
                    <div className={classes.form_container}>
                      <Text
                        color={getHighlightedColorStringMemorization()(
                          formItem.formName,
                          item[formItem.formFields[0].name]
                        )}
                      >
                        {formItem.title}
                      </Text>
                    </div>
                    <div className={classes.form_container}>
                      <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            {finanzrateFormItems.map(formItem => {
              const highlightedColor = getHighlightedColorStringMemorization()(
                formItem.formName,
                item[formItem.formFields[0].name]
              );
              return (
                <div key={formItem.title}>
                  <div className={classes.form_container}>
                    <Text color={highlightedColor}>{formItem.title}</Text>
                    {formItem.tooltipText && (
                      <Tooltip title={formItem.tooltipText} arrow>
                        <Flex>
                          <CircleInfoIcon
                            color={highlightedColor ? theme.colors[highlightedColor] : theme.colors.grey400}
                            className={classes.info_icon}
                          />
                        </Flex>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.form_container}>
                    <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                  </div>
                </div>
              );
            })}
            <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
              {leasingFormItems.map(formItem => (
                <Grid item xs={1} key={formItem.title}>
                  <div className={classes.form_container}>
                    <Text
                      color={getHighlightedColorStringMemorization()(
                        formItem.formName,
                        item[formItem.formFields[0].name]
                      )}
                    >
                      {formItem.title}
                    </Text>
                    {formItem.tooltipText && (
                      <Tooltip title={formItem.tooltipText} arrow>
                        <Flex>
                          <CircleInfoIcon color={theme.colors.grey400} className={classes.info_icon} />
                        </Flex>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.form_container}>
                    <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
              {validityFormItems.map(formItem => (
                <Grid item xs={1} key={formItem.title}>
                  <div className={classes.form_container}>
                    <Text
                      color={getHighlightedColorStringMemorization()(
                        formItem.formName,
                        item[formItem.formFields[0].name]
                      )}
                    >
                      {formItem.title}
                    </Text>
                  </div>
                  <div className={classes.form_container}>
                    <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                  </div>
                </Grid>
              ))}
            </Grid>

            {item['new_vertragstyp_value'] === SERVICES_CONTRACT_TYPES.wheelStorage && (
              <FormHook reactHookFormData={reactHookFormData} formFields={wheelStorageField} />
            )}

            <div className='mt-15' />
            <div className={classes.buttons_container}>
              <Button className={classes.button} onClick={handleCancelEdit} secondary>
                Abbrechen
              </Button>
              <Button
                className={classes.button}
                id='leasingSectionSubmit'
                disabled={!isEmpty(errors)}
                onClick={() => save(item)}
              >
                Speichern
              </Button>
            </div>
          </Flex>
        ) : (
          <>
            <Flex width={!isDriver ? (windowWidth <= 550 ? '65%' : '70%') : '100%'} align='center' right='5'>
              <Flex>
                <CheckedIcon id={`bookedLeasingCheckedIcon${index}`} color={theme.colors.green} />
              </Flex>
              <Flex direction='column' left='10'>
                <Text id={`bookedLeasingTitle${index}`} color='black' size='18' bold>
                  {item['new_vertragstyp']}
                </Text>
                <Text size='12' color='grey500' wordBreak='break-all'>
                  {getLeasingRateText(item)}
                </Text>
              </Flex>
            </Flex>
            {!isDriver && (
              <div className={classes.price}>
                <Text
                  id={`bookedLeasingAmountDescription${index}`}
                  size={windowWidth <= 550 ? '14' : '16'}
                  color='black'
                >
                  Betrag
                  {item['new_zahlungsrythmus'] ? ` (${item['new_zahlungsrythmus']})` : ''}
                </Text>
                <Text
                  id={`bookedLeasingAmount${index}`}
                  color='blue'
                  size={windowWidth <= 550 ? '16' : '18'}
                  bold
                  top='5'
                  wordBreak='break-all'
                >
                  {toMoney(item.new_betrag_value)}
                </Text>
              </div>
            )}
            {item.uds_editierbar_auf_der_plattform && !isEditing ? (
              <button onClick={() => edit(item)} className={classes.edit_btn}>
                <PenIcon id='leasingEditIcon' />
              </button>
            ) : null}
          </>
        )}
      </div>
    );
  }

  return (
    <div
      id={`notBookedLeasingContainer${index}`}
      data-contract-type-id={item?.new_vertragstyp_value || 0}
      className={
        item.itemExpand || !item.uds_editierbar_auf_der_plattform || isEditing
          ? `${classes.leasing_item} ${classes.no_background}`
          : classes.leasing_item
      }
      style={{ flexDirection: item.itemExpand ? 'column' : 'row' }}
    >
      {item.itemExpand ? (
        <Flex direction='column' padding={windowWidth <= 550 ? '5px 10px' : '15px 20px'}>
          <Flex justify='space-between' width='100%'>
            <Text color='black' size='18' bold>
              {item['new_vertragstyp']}
            </Text>
            <Flex pointer onClick={cancelEdit}>
              <CloseIcon />
            </Flex>
          </Flex>

          {formItems.map(formItem => (
            <Flex width='100%' align='center' bottom='20' key={formItem.title}>
              {formItem.title && (
                <div className={classes.form_container} style={formItem.titleStyle}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      formItem.formName,
                      item[formItem.formFields[0].name]
                    )}
                  >
                    {formItem.title}
                  </Text>
                </div>
              )}

              <FormHook
                distanceBetweenFields={0}
                reactHookFormData={reactHookFormData}
                formFields={formItem.formFields}
              />
            </Flex>
          ))}

          {betragFormItems.length && (
            <>
              <div className={classes.form_container}>
                <Text
                  color={getHighlightedColorStringMemorization()(
                    betragFormItems[0].formName,
                    item[betragFormItems[0].formFields[0].name]
                  )}
                >
                  Betrag
                </Text>
                <Text left='5' as='span' color='pink'>
                  *
                </Text>
              </div>
              <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
                {betragFormItems.map(formItem => (
                  <Grid key={formItem.title} item xs={1}>
                    <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
            {kilometersFormItems.map(formItem => (
              <Grid item xs={1} key={formItem.title}>
                <div className={classes.form_container}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      formItem.formName,
                      item[formItem.formFields[0].name]
                    )}
                  >
                    {formItem.title}
                  </Text>
                  {formItem.tooltipText && (
                    <Tooltip title={formItem.tooltipText} arrow>
                      <Flex>
                        <CircleInfoIcon color={theme.colors.grey400} className={classes.info_icon} />
                      </Flex>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.form_container}>
                  <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                </div>
              </Grid>
            ))}
          </Grid>
          {isShowContractNumberForm && (
            <FormHook reactHookFormData={reactHookFormData} formFields={vertragsNrFormFields} />
          )}
          <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
            {deductibleFormItems.map(formItem => (
              <Grid item xs={1} key={formItem.title}>
                <div className={classes.form_container}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      formItem.formName,
                      item[formItem.formFields[0].name]
                    )}
                  >
                    {formItem.title}
                  </Text>
                </div>
                <div className={classes.form_container}>
                  <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                </div>
              </Grid>
            ))}
          </Grid>
          {finanzrateFormItems.map(formItem => (
            <div key={formItem.title}>
              <div className={classes.form_container}>
                <Text
                  color={getHighlightedColorStringMemorization()(formItem.formName, item[formItem.formFields[0].name])}
                >
                  {formItem.title}
                </Text>
              </div>
              <div className={classes.form_container}>
                <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
              </div>
            </div>
          ))}
          <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
            {leasingFormItems.map(formItem => (
              <Grid item xs={1} key={formItem.title}>
                <div className={classes.form_container}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      formItem.formName,
                      item[formItem.formFields[0].name]
                    )}
                  >
                    {formItem.title}
                  </Text>
                  {formItem.tooltipText && (
                    <Tooltip title={formItem.tooltipText} arrow>
                      <Flex>
                        <CircleInfoIcon color={theme.colors.grey400} className={classes.info_icon} />
                      </Flex>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.form_container}>
                  <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid container columnSpacing={2} columns={windowWidth <= 550 ? 1 : 2}>
            {validityFormItems.map(formItem => (
              <Grid item xs={1} key={formItem.title}>
                <div className={classes.form_container}>
                  <Text
                    color={getHighlightedColorStringMemorization()(
                      formItem.formName,
                      item[formItem.formFields[0].name]
                    )}
                  >
                    {formItem.title}
                  </Text>
                </div>
                <div className={classes.form_container}>
                  <FormHook reactHookFormData={reactHookFormData} formFields={formItem.formFields} />
                </div>
              </Grid>
            ))}
          </Grid>

          {item['new_vertragstyp_value'] === SERVICES_CONTRACT_TYPES.wheelStorage && (
            <FormHook reactHookFormData={reactHookFormData} formFields={wheelStorageField} />
          )}

          <div className='mt-15' />
          <div className={classes.buttons_container}>
            <Button className={classes.button} onClick={handleCancelEdit} secondary>
              Abbrechen
            </Button>
            <Button
              className={classes.button}
              id='leasingSectionSubmit'
              disabled={!isEmpty(errors)}
              onClick={() => save(item)}
            >
              Speichern
            </Button>
          </div>
        </Flex>
      ) : (
        <>
          <Flex align='center'>
            <CircleCloseIcon id={`notBookedLeasingCircleCloseIcon${index}`} color={theme.colors.pink} />
            <Text id={`notBookedLeasingTitle${index}`} color='black' size='18' bold left='10'>
              {item['new_vertragstyp']}
            </Text>
          </Flex>

          {item.uds_editierbar_auf_der_plattform && !isEditing ? (
            <button
              onClick={() => edit(item)}
              className={classNames(classes.edit_btn, classes.edit_btn_right_position)}
            >
              <PenIcon id='leasingEditIcon' />
            </button>
          ) : null}
        </>
      )}
    </div>
  );
};

const initialState = {
  activeItems: [],
  inactiveItems: [],
  expanded: true
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ACTIVE_ITEMS':
      return { ...state, activeItems: action.payload };
    case 'SET_INACTIVE_ITEMS':
      return { ...state, inactiveItems: action.payload };
    case 'SET_EXPANDED':
      return { ...state, expanded: typeof action.payload === 'boolean' ? action.payload : !state.expanded };
    default:
      throw new Error();
  }
}

const LEASING_SECTION_LOADING_KEY = 'leasingSectionLoadingKey';

const LeasingSection = () => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  const [refreshKey, setRefreshKey] = useState(0);

  const dispatchRedux = useDispatch();

  const { id } = useQueryLocation();

  const { data: servicesResponse, isFetched } = useRequest(
    () => getDienstleistungByFuhrpark(id),
    [refreshKey],
    false,
    LEASING_SECTION_LOADING_KEY
  );

  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;

  const data = useMemo(() => {
    if (!servicesResponse) {
      return null;
    }
    return getResponseFormData(servicesResponse, [{ name: null, type: 'array' }], true);
  }, [servicesResponse]);

  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);

  const isInitializedLesingSection = useRef(false);

  useEffect(() => {
    if (data) {
      const activeItems = data
        .filter(item => item.new_gebucht_value === BOOKING_STATUS.ordered)
        .sort((a, b) => {
          if (!a['new_vertragstyp'] || !b['new_vertragstyp']) {
            return -1;
          }
          return a['new_vertragstyp'].localeCompare(b['new_vertragstyp']);
        })
        .map(item => ({ ...item, itemExpand: false }));
      const inactiveItems = data
        .filter(item => item.new_gebucht_value === BOOKING_STATUS.notOrdered)
        .sort((a, b) => {
          if (!a['new_vertragstyp'] || !b['new_vertragstyp']) {
            return -1;
          }
          return a['new_vertragstyp'].localeCompare(b['new_vertragstyp']);
        })
        .map(item => ({ ...item, itemExpand: false }));

      dispatchState({ type: 'SET_ACTIVE_ITEMS', payload: activeItems });
      dispatchState({ type: 'SET_INACTIVE_ITEMS', payload: inactiveItems });

      isInitializedLesingSection.current = true;
    }
  }, [data]);

  useOpenLeasingContract(isFetched && isInitializedLesingSection.current, state, dispatchState);

  if (!state.activeItems.length && !state.inactiveItems.length) {
    return (
      <PanelPlaceholder
        icon={<EmptyDataIcon />}
        title='Nichts gefunden'
        description='Diese Fahrzeug enthält keine Leasinginformationen & Dienstleistungen. Bitte kontaktiere den Kunden-Support.'
      />
    );
  }

  const edit = editingItem => {
    const newActiveItems = cloneDeep(state.activeItems);
    newActiveItems.forEach(item => {
      item.itemExpand = item.uds_leasingvertrgeid === editingItem.uds_leasingvertrgeid;
    });

    const newInactiveItems = cloneDeep(state.inactiveItems);
    newInactiveItems.forEach(item => {
      item.itemExpand = item.uds_leasingvertrgeid === editingItem.uds_leasingvertrgeid;
    });

    dispatchState({ type: 'SET_ACTIVE_ITEMS', payload: newActiveItems });
    dispatchState({ type: 'SET_INACTIVE_ITEMS', payload: newInactiveItems });
  };

  const cancelEdit = () => {
    const newActiveItems = cloneDeep(state.activeItems);
    newActiveItems.forEach(item => {
      item.itemExpand = false;
    });

    const newInactiveItems = cloneDeep(state.inactiveItems.filter(item => item.mode !== 'create'));
    newInactiveItems.forEach(item => {
      item.itemExpand = false;
    });

    dispatchRedux(setHighlightedForm({ fields: [] }));
    dispatchRedux(setVehicleDetailsLeasingTab({ openedContract: null }));

    dispatchState({ type: 'SET_ACTIVE_ITEMS', payload: newActiveItems });
    dispatchState({ type: 'SET_INACTIVE_ITEMS', payload: newInactiveItems });
  };

  const isEditing =
    state.activeItems.some(item => item.itemExpand) ||
    state.inactiveItems.some(item => item.itemExpand) ||
    isDriver ||
    fuhrpark?.new_status_carcode_value === VehicleStatusesValue.Ordered;

  return (
    <>
      <div className={classes.leasing_wrapper}>
        <div className={classes.leasing_container}>
          <Text color='blue' size='18' bold margin='20px 0'>
            Gebucht
          </Text>
          {state.activeItems.slice(0, state.expanded ? state.activeItems.length : 3).map((item, index) => (
            <LeasingItem
              key={item.uds_leasingvertrgeid}
              index={index}
              type={'gebucht'}
              item={item}
              edit={edit}
              cancelEdit={cancelEdit}
              isEditing={isEditing}
              isDriver={isDriver}
              id={id}
              refreshSection={setRefreshKey}
            />
          ))}
        </div>
        <div className={classes.leasing_container}>
          <Text color='blue' size='18' bold margin='20px 0'>
            Nicht gebucht
          </Text>
          {state.inactiveItems.slice(0, state.expanded ? state.inactiveItems.length : 3).map((item, index) => (
            <LeasingItem
              key={item.uds_leasingvertrgeid}
              index={index}
              type={'nicht_gebucht'}
              item={item}
              edit={edit}
              cancelEdit={cancelEdit}
              isEditing={isEditing}
              isDriver={isDriver}
              id={id}
              refreshSection={setRefreshKey}
            />
          ))}
        </div>
      </div>

      {state.activeItems.length > 3 || state.inactiveItems.length > 3 ? (
        <ShowMoreButton top='20' isExpanded={state.expanded} onClick={() => dispatchState({ type: 'SET_EXPANDED' })} />
      ) : null}
    </>
  );
};

const defaultFieldsFirstGroup = {
  new_inkludiert_leasing: 'new_inkludiert_leasing',
  uds_leasingbank: 'uds_leasingbank_id',
  new_betrag: 'new_betrag_value',
  new_zahlungsrythmus: 'new_zahlungsrythmus_value',
  uds_einlagerungsort: 'uds_einlagerungsort'
};
const defaultFieldsSecondGroup = {
  uds_mehrkilometer: 'uds_mehrkilometer',
  uds_minderkilometer: 'uds_minderkilometer',
  uds_toleranzgrenze: 'uds_toleranzgrenze',
  uds_leasingfaktor: 'uds_leasingfaktor',
  uds_leasingdauer: 'uds_leasingdauer',
  uds_laufleistung: 'uds_laufleistung',
  uds_laufleistungpa: 'uds_laufleistungpa'
};
const defaultFieldsThirdGroup = {
  uds_selbstbeteiligung_vollkasko: 'uds_selbstbeteiligung_vollkasko_value',
  uds_selbstbeteiligung_teilkasko: 'uds_selbstbeteiligung_teilkasko_value',
  uds_workshop_bindingcode: 'uds_workshop_bindingcode_value',
  uds_versicherungsartcode: 'uds_versicherungsartcode_value'
};

export default LeasingSection;
