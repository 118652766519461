/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './components.module.scss';
import { Distance } from 'common/common-components.styled';
import { ComponentsProps } from './components.props';

const TwoChooseButtonsComponent = ({
  reactHookFormData,
  fieldRegister,
  fieldRegisterSecond,
  fieldName,
  fieldErrorCheck = 'peopleInvolvedInCrashWhatHasDamage',
  firstButtonText = 'Ja',
  firstButtonValue = true,
  secondButtonText = 'Nein',
  secondButtonValue = false,
  optional = true,
  optionalText = '(Mehrfachauswahl möglich)'
}: ComponentsProps) => {
  const {
    watch,
    clearErrors,
    setValue,
    formState: { errors }
  } = reactHookFormData;

  const setFirstValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(fieldRegister, watch(fieldRegister) ? undefined : firstButtonValue);
    e.preventDefault();
  };

  const setSecondValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (fieldRegisterSecond) {
      setValue(fieldRegisterSecond, watch(fieldRegisterSecond) ? undefined : secondButtonValue);
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (fieldRegisterSecond && (watch(fieldRegister) !== undefined || watch(fieldRegisterSecond) !== undefined)) {
      clearErrors(fieldErrorCheck);
    }
  }, [errors[fieldErrorCheck], watch(fieldRegister), fieldRegisterSecond && watch(fieldRegisterSecond)]);

  return (
    <div className={styles.field_container}>
      <div className={errors[fieldErrorCheck] ? styles.form_name_button_error : styles.form_name_button}>
        <span>
          {fieldName} {optional && <span className={styles.optional}>{optionalText}</span>}
        </span>
      </div>
      <div className={styles.row_columns}>
        <div className={styles.half_input}>
          <button
            id={`${firstButtonText}ChooseButton`}
            onClick={e => setFirstValue(e)}
            className={watch(fieldRegister) === firstButtonValue ? styles.button_half_choosen : styles.button_half}
          >
            {firstButtonText}
          </button>
        </div>
        <div className={styles.half_input}>
          <button
            id={`${secondButtonText}ChooseButton`}
            onClick={e => setSecondValue(e)}
            className={
              fieldRegisterSecond && watch(fieldRegisterSecond) === secondButtonValue
                ? styles.button_half_choosen
                : styles.button_half
            }
          >
            {secondButtonText}
          </button>
        </div>
      </div>

      <Distance top='10' />
    </div>
  );
};

export default TwoChooseButtonsComponent;
