import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const GreenEuroIcon = ({ width = 20, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && (
        <>
          <ellipse cx='10.492' cy='11.0278' rx='7.56667' ry='7.6284' fill='white' />
          <path
            d='M7.78311 18.132C9.59982 16.339 8.18622 14.0879 6.19821 13.3944C4.2102 12.701 1.44827 12.4167 1.25401 12.602C1.05975 12.7873 2.5201 13.482 2.994 16.1336C3.32 17.9576 4.60403 19.0662 5.96515 18.9969C7.32626 18.9276 7.78311 18.132 7.78311 18.132Z'
            fill={fill}
          />
          <path
            d='M17.8107 11.2136L16.4715 7.51125L14.6072 9.27053L15.1587 4.49159L16.0777 0.894257L16.9179 5.46314L18.8085 4.09772L17.8107 11.2136Z'
            fill={fill}
          />
        </>
      )}
      <path
        d='M2.86868 10.9505C2.86868 6.75792 6.26743 3.35917 10.46 3.35917C12.2192 3.35917 13.7113 3.78582 14.9985 4.79016L16.0926 0.65377L16.8709 5.51835L18.8791 3.96522L17.7645 11.1824L16.4799 7.26005L14.5622 9.24555L14.9985 6.61638M7.99307 18.132C9.80978 16.339 8.39618 14.0879 6.40817 13.3944C4.42016 12.701 1.65823 12.4166 1.46397 12.602C1.26971 12.7873 2.73006 13.482 3.20397 16.1336C3.52996 17.9576 4.814 19.0662 6.17511 18.9969M7.99307 18.132C9.02453 18.4777 9.61599 18.5418 10.46 18.5418C13.9335 18.5418 16.8621 16.209 17.7645 13.0246M7.99307 18.132C6.96161 17.7862 6.1581 16.6643 5.81256 15.9473C5.65422 15.6187 5.41493 15.3365 5.1402 15.1053'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.033 12.6729C11.0312 14.4735 9.41395 13.0135 8.94932 11.4102C8.84895 11.0638 8.85658 10.4915 8.85658 10.4915C8.85658 10.4915 8.86193 9.98442 8.94932 9.67607C9.32244 8.35963 10.4879 7.16204 12.0022 7.77554'
        stroke={color}
        stroke-linecap='round'
      />
      <path d='M11.3099 9.94535L7.96045 9.93977' stroke={color} stroke-linecap='round' />
      <path d='M11.2782 11.1729H7.96045' stroke={color} stroke-linecap='round' />
    </svg>
  );
};
