import React from 'react';
import { USER_ROLES } from '../../../constants';
import { ModalMenuProps } from './table-components.props';
import { MyVehiclesMenu } from '../my-vehicles-menu/my-vehicles-menu';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { useLeaseExtensionActions } from 'components/aufgaben-component/components/unfolded-task/components/buttons-for-lease-extension/utils';
import { useQueryClient } from 'react-query';
import { PAGES } from 'common/enums';

export const ModalMenu = ({ row }: ModalMenuProps) => {
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const queryClient = useQueryClient();
  const { openLeasingContractModal, openOfferConfirmationModal } = useLeaseExtensionActions({
    plateNumber: {
      attributeTypeCode: 6,
      attributeValue: {
        id: row?.leaseEndInfo?.lva?.lva_phaseinfo?.fuhrparkId || '',
        logicalName: 'fuhrpark',
        name: row?.new_name as string | ''
      }
    },
    currentPhase: row?.leaseEndInfo?.lva?.lva_state || 0,
    leaseExtensionId: row?.leaseEndInfo?.lva?.lva_phaseinfo?.lvaId || '',
    taskId: row?.leaseEndInfo?.lva?.lva_phaseinfo?.lvaId || '',
    phaseInfo: row?.leaseEndInfo?.lva?.lva_phaseinfo?.specificPhaseInfo || {
      comment: null,
      mileage: null,
      months: 0,
      phaseNo: 0,
      mileagePA: null
    },
    files: row?.leaseEndInfo?.lva?.lva_phaseinfo?.fileList?.Verlängerungangebot || [],
    leaseEndDate: row?.uds_lease_return_date || '',
    refresh: () => queryClient.resetQueries([PAGES.MY_VEHICLES])
  });

  return (
    <>
      {!isDriver && (
        <MyVehiclesMenu
          carStatus={row?.portalStatus}
          carId={row?.new_fuhrparkid}
          carNumber={row?.new_name}
          leasingReturnEnabled={row?.leasingruckgabeproEnable}
          phase={row?.leaseEndInfo?.lva.lva_state}
          openLeasingContractModal={
            row?.leaseEndInfo?.lva?.lva_phaseinfo
              ? () => openLeasingContractModal(row?.leaseEndInfo?.lva?.lva_phaseinfo.specificPhaseInfo?.mileagePA)
              : undefined
          }
          openOfferConfirmationModal={row?.leaseEndInfo?.lva?.lva_phaseinfo && openOfferConfirmationModal}
        />
      )}
    </>
  );
};
