import { AxiosRequestConfig } from 'axios';
import {
  CreateVehicleHistory,
  GetUnavailableRanges,
  GetVehicleHistoryList,
  UpdateVehicleHistory
} from './fleet-history.types';

class FleetHistoryRequestConfig {
  private RESOURCE = 'FuhrparkHistory';

  getUnavailableRange = (data: GetUnavailableRanges): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/GetUnavailableRange`,
    data
  });

  updateVehicleHistory = (data: UpdateVehicleHistory): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/Update`,
    data
  });

  createVehicleHistory = (data: CreateVehicleHistory): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/Create`,
    data
  });

  getHistoryList = (data: GetVehicleHistoryList): AxiosRequestConfig => ({
    method: 'POST',
    url: `${this.RESOURCE}/GetList`,
    data
  });
}

export const fleetHistoryRequestConfig = new FleetHistoryRequestConfig();
