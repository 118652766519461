import React, { useState } from 'react';
import classes from './car-section.module.scss';
import { CarSection } from 'components/sections/car-section/car-section';
import { useFleetByIdQuery } from 'hooks/react-query/fleet/use-get-by-id/use-get-by-id';
import { VehicleDetailCarSectionAside } from './components/aside/aside';
import { useVehicleDetailGallery } from './hooks/use-vehicle-detail-gallery';
import { VehicleDetailCarSectionMenuModal } from './components/menu-modal';
import { VehicleDetailCarSectionTitle } from './components/title';
import { useCreateCarImageModal } from 'hooks/modals/create-car-image/use-create-car-image';
import { useVehicleDetailsClearCash } from 'pages/existing-vehicle-page/hooks/use-clear-cash';
import { VehicleDetailCarSectionDescription } from './components/description';

export const VehicleDetailCarSection = () => {
  const { fleetByIdQuery } = useFleetByIdQuery();
  const { fuhrpark } = fleetByIdQuery;
  const vehicleId = fuhrpark?.new_fuhrparkid;

  const { files, images, isFetchingGallery } = useVehicleDetailGallery();
  const { openCreateCarImageModal } = useCreateCarImageModal();
  const { clearImagesCash } = useVehicleDetailsClearCash();

  const [isCreatingImage, setIsCreatingImage] = useState(false);

  const createImageHandler = () => {
    if (!vehicleId) {
      return console.error('vehicleId is not exist');
    }
    openCreateCarImageModal({
      vehicleId,
      callbackBeforeRequest: () => setIsCreatingImage(true),
      callbackAfterRequest: () => {
        clearImagesCash();
        setIsCreatingImage(false);
      }
    });
  };

  return (
    <CarSection
      images={images}
      gallery={files}
      galleryItemProps={{ className: classes.gallery_item }}
      thumbnailsSettings={{
        responsive: [
          {
            breakpoint: 551,
            settings: { slidesToShow: 3, slidesToScroll: 3 }
          }
        ]
      }}
      thumbnailsImageClassName={classes.thumbnails_image}
      thumbnailsImageContainerClassName={classes.thumbnails_image_container}
      isLoading={isCreatingImage || isFetchingGallery}
      containerProps={{ gridArea: 'carOverview', height: '100%', borderRadius: '5px' }}
      galleryContainerProps={{ height: '100%', width: '40%' }}
      asideContainerProps={{ height: 'max-content', width: '60%' }}
      sectionContentProps={{ height: '100%' }}
      placeholderContainerProps={{ height: '100%' }}
      createImage={createImageHandler}
      title={<VehicleDetailCarSectionTitle />}
      description={<VehicleDetailCarSectionDescription />}
      headerRightComponent={<VehicleDetailCarSectionMenuModal />}
      typeId={fuhrpark?.uds_silverdatvehicletypeoption_value}
      asideComponent={<VehicleDetailCarSectionAside />}
    />
  );
};
