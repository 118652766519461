import React, { useEffect, useMemo } from 'react';
import { Flex, Text, Button } from 'common/common-components.styled';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import FormHook from 'components/form-hook/form-hook';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';
import { isEmpty } from 'lodash';
import { LeaseExtentionPhases } from 'components/aufgaben-component/task.prop';
import { LeasingContractProps } from '../unfolded-task/components/buttons-for-lease-extension/lease-extension.props';
import { useLeaseingContactForm } from './use-leasing-contaact-form';
import useContractStatus from 'hooks/react-query/use-contract-status';

const LeasingContract = ({ mileage, plateNumber, onSubmit, onCancel }: LeasingContractProps) => {
  const theme = useTheme();
  const reactHookFormData = useForm();
  const {
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    formState: { errors, touchedFields }
  } = reactHookFormData;
  const currentMileageToggleValue = watch(LeasingContractFields.IsChangeMileage);
  const currentSilentExtendToggleValue = watch(LeasingContractFields.SilentlyExtend);
  const mileageFieldValue = watch(LeasingContractFields.Mileage);
  const monthFieldValue = watch(LeasingContractFields.AddedMonths);
  const { leasingContractFormFields } = useLeaseingContactForm(
    currentMileageToggleValue,
    currentSilentExtendToggleValue
  );

  const isSubmitButtonDisabled = useMemo(() => {
    if (currentSilentExtendToggleValue) {
      return false;
    }

    if (monthFieldValue === 0 && !currentMileageToggleValue) {
      return true;
    } else if (monthFieldValue === 0 && currentMileageToggleValue) {
      return mileage
        ? mileage === mileageFieldValue
        : !touchedFields[LeasingContractFields.Mileage] || !mileageFieldValue;
    } else {
      return !isEmpty(errors);
    }
  }, [
    mileage,
    monthFieldValue,
    mileageFieldValue,
    currentMileageToggleValue,
    touchedFields[LeasingContractFields.Mileage],
    currentSilentExtendToggleValue
  ]);

  const { isContractAvailable } = useContractStatus();

  useEffect(() => {
    setValue(LeasingContractFields.AddedMonths, 0);
  }, []);

  useEffect(() => {
    if (currentMileageToggleValue && mileage) {
      setValue(LeasingContractFields.Mileage, mileage);
    }
  }, [currentMileageToggleValue, mileage]);

  useEffect(() => {
    if (currentSilentExtendToggleValue) {
      setTimeout(() => {
        clearErrors();
      });
    }
  }, [currentSilentExtendToggleValue]);

  const submit = () => {
    handleSubmit(data => {
      const nextPhase = data[LeasingContractFields.SilentlyExtend]
        ? LeaseExtentionPhases.Phase4
        : LeaseExtentionPhases.Phase3;
      onSubmit({
        nextPhase: nextPhase,
        silentExtend: data[LeasingContractFields.SilentlyExtend],
        mileage: currentMileageToggleValue ? data[LeasingContractFields.Mileage] : mileage,
        comment: data[LeasingContractFields.Comment],
        months: currentSilentExtendToggleValue
          ? data[LeasingContractFields.silentExtendMonths]
          : data[LeasingContractFields.AddedMonths],
        successMessage: SUCCESS_MESSAGE_OFFER_SENT,
        currentMileage: data[LeasingContractFields.CurrentMileage] ? data[LeasingContractFields.CurrentMileage] : null
      });
    })();
  };

  return (
    <Flex direction='column'>
      <Text size='14' color={theme.colors.black} bottom='30'>
        {`Bitte wähle aus, um wie viele Monate du den Leasingvertrag für ${plateNumber} verlängern möchtest. Wir senden danach deine Anfrage an Leasinggeber und geben Bescheid, sobald der eine Rückmeldung mit Angebot sendet.`}
      </Text>
      <FormHook reactHookFormData={reactHookFormData} formFields={leasingContractFormFields} />
      <Flex justify='flex-end'>
        <Button secondary onClick={onCancel} right='10'>
          Abbrechen
        </Button>
        <Button id={'sendAnInquiryButton'} disabled={isSubmitButtonDisabled || !isContractAvailable} onClick={submit}>
          Anfrage absenden
        </Button>
      </Flex>
    </Flex>
  );
};

const SUCCESS_MESSAGE_OFFER_SENT = 'Anfrage an Leasinggeber wurde erfolgreich gesendet';

export default LeasingContract;
