import { AnyObject } from 'common/interfaces';
import { uds_enginetypecode } from '../constants';

const TRINKS_COMPANIES = [
  'a229432b-33c7-ed11-b597-000d3adf7433',
  '9a8b3e78-2e05-ee11-8f6e-000d3adf717d',
  '343a0212-3205-ee11-8f6e-000d3adf717d'
];

export const getBudget = (enginetypecode: number, driverLevelData: AnyObject, companyId: string): number => {
  let nonEcoEngines = [uds_enginetypecode.benzin, uds_enginetypecode.diesel];
  if (TRINKS_COMPANIES.includes(companyId)) {
    nonEcoEngines = [...nonEcoEngines, uds_enginetypecode.hybrid_benzin, uds_enginetypecode.hybrid_diesel];
  }
  const isEcoCar = enginetypecode && !nonEcoEngines.includes(enginetypecode);
  return isEcoCar
    ? driverLevelData.uds_budget_eco || driverLevelData.uds_budget_eur || 0
    : driverLevelData.uds_budget_eur || 0;
};
