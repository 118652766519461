import ExcelFileIcon from 'components/icons/excel-file-icon';
import PdfFileIcon from 'components/icons/pdf-file-icon';
import PictureFileIcon from 'components/icons/picture-file-icon';
import TextFileIcon from 'components/icons/text-file-icon';
import React from 'react';
import classes from './file-info.module.scss';
import { FileFormat, FileIconProps } from './file-info.props';

export const FileFormatIcon = ({ fileFormat }: FileIconProps) => {
  switch (fileFormat) {
    case FileFormat.XLS:
    case FileFormat.XLSX:
      return <ExcelFileIcon className={classes.icon} />;

    case FileFormat.PNG:
    case FileFormat.JPEG:
    case FileFormat.JPG:
      return <PictureFileIcon className={classes.icon} />;

    case FileFormat.PDF:
      return <PdfFileIcon className={classes.icon} />;

    default:
      return <TextFileIcon className={classes.icon} />;
  }
};
