import styled from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { DownArrowIcon } from 'components/icons/down-arrow-icon';

export const StyledCarBrandHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 5px solid ${({ theme }) => theme.colors.darkBlue};
  padding: 25px;
  margin-bottom: 10px;
  cursor: pointer;
`;

interface StyledDownArrowIconProps {
  rotate: boolean;
}

export const StyledDownArrowIcon = styled(DownArrowIcon)<StyledDownArrowIconProps>`
  transform: rotate(${({ rotate }) => (rotate ? '180deg' : '0')});
  transition: transform 0.2s ease-out;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
  word-break: break-word;
`;

export const StyledCheckboxContainer = styled(StyledContainer)`
  width: 3%;
`;

export const StyledArrowContainer = styled(StyledContainer)`
  width: 2%;
`;

export const StyledTitleContainer = styled(StyledContainer)`
  width: 11%;
`;

export const StyledReferenceVehicleContainer = styled(StyledContainer)`
  width: 12%;
`;

export const StyledBodyTypeContainer = styled(StyledContainer)`
  width: 9%;
`;

export const StyledFuelContainer = styled(StyledContainer)`
  width: 7%;
`;

export const StyledPowerContainer = styled(StyledContainer)`
  width: 6%;
`;

export const StyledCarPolicyContainer = styled(StyledContainer)`
  width: 7%;
`;

export const StyledContractContainer = styled(StyledContainer)`
  width: 11%;
`;

export const StyledLeasingPriceContainer = styled(StyledContainer)`
  width: 11%;
`;

export const StyledVisibilityContainer = styled(StyledContainer)`
  width: 7%;
`;

export const StyledAvailabilityContainer = styled(StyledContainer)`
  width: 9%;
`;

export const StyledStatusContainer = styled(StyledContainer)`
  width: 6%;
`;

export const RowContainer = styled.div`
  min-width: 1200px;
`;
