import React, { useState } from 'react';
import { DownloadChecklistFileProps } from './download-checklist-file.types';
import { useFileDownload } from 'hooks/useFileDownload';
import { sharePointRequestConfig } from 'request-config/sharepoint/sharepoint.request-config';
import { Flex, Text } from 'common/common-components.styled';
import { WidgetContentText } from '../../../components/leasing-widget/leasing-widget.styled';
import { DownloadContainer } from './download-checklist-file.styled';
import PdfFileIcon from 'components/icons/pdf-file-icon';
import Spinner from 'components/spinner';
import { DownloadIcon } from 'components/icons-new/download';

const DownloadChecklistFile = ({ fileId, fileName }: DownloadChecklistFileProps) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = useFileDownload({
    requestConfig: sharePointRequestConfig.downloadFileById(fileId),
    fileName: fileName,
    encodeConfig: {
      path: [],
      encodeWay: 'newBlob'
    },
    isShowLoading: false
  });

  const handleDownload = async () => {
    setLoading(true);
    await downloadFile();
    setLoading(false);
  };

  return (
    <Flex direction='column' gap='10px' width='100%'>
      <WidgetContentText>Prüfe anhand der Checkliste, ob dein Fahrzeug für die Rückgabe bereit ist.</WidgetContentText>

      <DownloadContainer onClick={handleDownload}>
        <Flex align='center' gap='10px'>
          <PdfFileIcon />

          <Text color='darkBlue'>Checkliste.pdf</Text>
        </Flex>

        {loading ? (
          <Spinner
            style={{
              width: '20px'
            }}
          />
        ) : (
          <Flex>
            <DownloadIcon />
          </Flex>
        )}
      </DownloadContainer>
    </Flex>
  );
};

export default DownloadChecklistFile;
