import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import { USER_ROLES } from '../../../constants';
import { PAGES } from 'common/enums';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { User } from 'common/interfaces';

const useNavigationUrl = () => {
  const user = useTypedSelector<User>(state => state['app'].user);
  const isDriver = useIsUserHaveRole(USER_ROLES.driver.id);
  const isPoolManager = useIsUserHaveRole(USER_ROLES.poolManager.id);

  let navigationUrl = PAGES.POOL_CALENDAR;
  if (isDriver) {
    navigationUrl = PAGES.MY_POOL_CALENDAR;
  }
  if (isPoolManager) {
    navigationUrl = user.appPages.some(appPage => appPage.url === PAGES.POOL_CALENDAR)
      ? PAGES.POOL_CALENDAR
      : PAGES.MY_POOL_CALENDAR;
  }

  return navigationUrl;
};

export default useNavigationUrl;
