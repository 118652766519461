import React, { useState } from 'react';
import classnames from 'classnames';
import classes from './vehicle-container.module.scss';
import PropTypes from 'prop-types';
import { LeftArrowIcon } from '../../../components/icons/left-arrow-icon';
import { RightArrowIcon } from '../../../components/icons/right-arrow-icon';
import { WidgetDocumnetContainer } from 'components/widget-documnet/widget-documnet-container';
import { WidgetType } from 'components/widget-documnet/widget-documnet-container.props';
import PlusIcon from 'pages/report-damage-page/icons/plus';
import Spinner from 'components/spinner';
import { Flex } from 'common/common-components.styled';

const WidgetHeader = ({ icon, title, description, isNoteWidget, setShowNoteForm }) => {
  const handleShowNoteForm = () => {
    if (setShowNoteForm) {
      setShowNoteForm(prev => !prev);
    }
  };

  return (
    <>
      <div className={classes.widget_header}>
        <div className={classes.widget_header_icon}>{icon}</div>
        <div className={classes.widget_header_column}>
          <h5>{title}</h5>
          {description && <div className={classes.widget_header_description}>{description}</div>}
        </div>
        {isNoteWidget && (
          <div className={classes.widget_plus_icon_coontainer} onClick={handleShowNoteForm}>
            <PlusIcon />
          </div>
        )}
      </div>
      <hr className={classes.hr_widget} />
    </>
  );
};

WidgetHeader.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

const VehicleContent = ({ isLoading, showArrows, children }) => {
  const [isFirstPage, setFirstPage] = useState(true);

  const childArray = React.Children.toArray(children);

  if (isLoading) {
    return (
      <Flex width='100%' height='250px' align='center' justify='center'>
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {childArray.length > 1 && showArrows && (
        <div className={classes.buttons_wrapper}>
          <button
            className={isFirstPage ? classes.button_disable : ''}
            onClick={() => {
              setFirstPage(true);
            }}
          >
            <LeftArrowIcon />
          </button>
          <button
            className={!isFirstPage ? classes.button_disable : ''}
            onClick={() => {
              setFirstPage(false);
            }}
          >
            <RightArrowIcon />
          </button>
        </div>
      )}
      <div hidden={!isFirstPage}>{childArray[0]}</div>
      <div hidden={isFirstPage}>{childArray[1]}</div>
    </>
  );
};

const VehicleContainer = ({
  img,
  title,
  description,
  children,
  className = '',
  isLoading = false,
  showArrows = true,
  isNoteWidget = false,
  setShowNoteForm = null
}) => {
  return (
    <WidgetDocumnetContainer className={classnames(classes.container, className)} type={WidgetType.Simple}>
      <WidgetHeader
        title={title}
        icon={img}
        description={description}
        isNoteWidget={isNoteWidget}
        setShowNoteForm={setShowNoteForm}
      />
      <VehicleContent isLoading={isLoading} showArrows={showArrows}>
        {children}
      </VehicleContent>
    </WidgetDocumnetContainer>
  );
};

export default VehicleContainer;
