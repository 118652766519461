import React from 'react';
import IconHover from 'components/icon-hover';
import { useTheme } from 'styled-components';
import { Flex } from 'common/common-components.styled';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { InfoHintIcon } from 'components/icons-new/info-hint';
import { Tooltip } from '@mui/material';

type TooltipIconProps = {
  tooltipText: string;
};

export const TooltipIcon = ({ tooltipText }: TooltipIconProps) => {
  const theme = useTheme();
  const isSiteAvailable = useTypedSelector(state => state.app.isSiteAvailable);
  return (
    <>
      <Tooltip
        title={tooltipText}
        placement='top'
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -10]
              }
            }
          ]
        }}
      >
        {isSiteAvailable && (
          <Flex align='center' justify='center'>
            <IconHover>
              <InfoHintIcon color={theme.colors.grey300} width={16} height={16} />
            </IconHover>
          </Flex>
        )}
      </Tooltip>
    </>
  );
};
