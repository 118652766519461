import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';
import React from 'react';

export const NumberedSquareIcon = ({
  width = 20,
  height = 20,
  color: colorProp,
  fill: fillProps,
  hovered
}: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProps || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {hovered && <rect x='1.32153' y='1' width='18' height='18' rx='1' fill={fill} />}
      <path
        d='M16.0137 19H17.7215C18.2816 19 18.5616 19 18.7755 18.891C18.9637 18.7951 19.1167 18.6422 19.2125 18.454C19.3215 18.2401 19.3215 17.9601 19.3215 17.4V2.6C19.3215 2.03995 19.3215 1.75992 19.2125 1.54601C19.1167 1.35785 18.9637 1.20487 18.7755 1.10899C18.5616 1 18.2816 1 17.7215 1H2.92153C2.36148 1 2.08145 1 1.86754 1.10899C1.67938 1.20487 1.5264 1.35785 1.43053 1.54601C1.32153 1.75992 1.32153 2.03995 1.32153 2.6V17.4C1.32153 17.9601 1.32153 18.2401 1.43053 18.454C1.5264 18.6422 1.67938 18.7951 1.86754 18.891C2.08145 19 2.36148 19 2.92153 19H13.0676'
        stroke={color}
        strokeLinecap='round'
      />
      <path d='M3.71436 8.27416L5.80009 6.68669V13.3167' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.16406 7.41628C8.16406 7.41628 8.74993 6.68669 9.66979 6.68669C10.9582 6.68669 11.5569 7.69941 11.4455 8.63499C11.3082 9.78861 10.5379 10.071 9.89329 10.6094C8.38756 11.8667 8.16417 13.3133 8.16417 13.3133H11.7824'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.6339 12.5755C13.6339 12.5755 14.2198 13.305 15.1397 13.305C16.4281 13.305 17.0268 12.2923 16.9154 11.3567C16.9154 11.3567 16.9151 10.0064 15.1395 10.0064M13.6338 7.4129C13.6338 7.4129 14.2197 6.68332 15.1395 6.68332C16.4279 6.68332 17.0266 7.69604 16.9152 8.63162C16.9152 8.63162 16.9152 10.0064 15.1395 10.0064M15.1395 10.0064C14.8627 10.0064 14.786 10.0064 14.786 10.0064C14.786 10.0064 14.8587 10.0064 15.1395 10.0064Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
