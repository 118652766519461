import React from 'react';

export default function DownArrow({ color = '#fff', width = '8', height = '10', className }) {
  return (
    <svg className={className} width={width} height={height} viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3.98462 0.538448V9.15383' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.98462 9.1538L7.32308 5.81534' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.98458 9.1538L0.646118 5.81534' stroke={color} strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
