import React from 'react';
import WidgetButton from '../../../components/widget-button';
import { useVehicleReturnButtons } from './use-vehicle-return-buttons';
import { VehicleReturnButtonsProps } from './vehicle-return-buttons.types';
import { Flex } from 'common/common-components.styled';

const VehicleReturnButtons: React.FC<VehicleReturnButtonsProps> = ({ activeWidgetindex, dontHaveHandoverProtocol }) => {
  const { button } = useVehicleReturnButtons(activeWidgetindex, dontHaveHandoverProtocol);

  if (!button) {
    return null;
  }

  return (
    <Flex justify='flex-end'>
      <WidgetButton id={button.id} title={button.title} handleClick={button.action} disabled={button.disabled} />
    </Flex>
  );
};

export default VehicleReturnButtons;
