import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useQuery } from 'react-query';
import { CarDealershipSettingQueryKeys } from '../car-dealership-setting.props';
import { carDealershipSettingRequestConfig } from 'request-config/car-dealership-setting/car-dealership-setting.request-config';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ResponseModel } from 'common/interfaces';
import { QueryHookOptions } from 'hooks/react-query/react-query.props';
import { CarDealershipSettingGetList, CarDealershipSettingGetListResponse } from './get-list.props';
import { AxiosResponse } from 'axios';
import { getDataForTable, getDataForTableItem, getResponseFormData, setValue } from 'utils/get-response-data';
import { objectUtility } from 'utils/object/object';

export const useCarDealershipSettingGetListQuery = (
  companyIdProps?: string,
  { options }: QueryHookOptions<CarDealershipSettingGetList, ResponseModel<CarDealershipSettingGetListResponse>> = {}
) => {
  const user = useTypedSelector(state => state.app.user);

  const companyId = companyIdProps || user.companyId;

  const { fetch } = useFetch<ResponseModel<CarDealershipSettingGetListResponse>>({
    loadingKey: `carDealershipSettingGetListQuery${companyId}`,
    ...carDealershipSettingRequestConfig.getList(companyId)
  });

  const modifyData = (data: CarDealershipSettingGetListResponse): CarDealershipSettingGetList => {
    const modifiedData: CarDealershipSettingGetList = [];

    data.map(item => {
      const modifiedDataItem: CarDealershipSettingGetList[number] = {};
      console.log(item, 'item');

      objectUtility.entries(item).map(([key, value]) => {
        if (Array.isArray(value)) {
          modifiedDataItem[key as keyof CarDealershipSettingGetList[number]] = value.map(item => setValue(item)) as any;
          return;
        }
        modifiedDataItem[key as keyof CarDealershipSettingGetList[number]] = setValue(value);
      });

      modifiedData.push(modifiedDataItem);
    });

    return modifiedData;
  };

  const { data, ...restQueryProps } = useQuery<
    AxiosResponse<ResponseModel<CarDealershipSettingGetListResponse>>,
    unknown,
    CarDealershipSettingGetList
  >([CarDealershipSettingQueryKeys.GetList, companyId], () => fetch(), {
    ...options,
    select: ({ data }) => getDataForTable(data.data)
  });

  return {
    carDealershipSettingGetListQuery: data || [],
    ...restQueryProps
  };
};
