import React from 'react';
import { BarDiagram } from 'components/widgets/bar/bar';
import { useHorizontalPlugin } from 'components/widgets/bar/hooks/plugins/use-horizontal-line/use-horizontal-line';
import { useKilometerWidgetDiagramData } from '../../hooks/use-diagram-data';

export const KilometerDiagram = () => {
  const { diagramData, horizontalLineValue } = useKilometerWidgetDiagramData();

  const { horizontalLinePlugin } = useHorizontalPlugin(horizontalLineValue, {
    color: 'darkGold',
    lineWidth: 4
  });

  return (
    <BarDiagram
      flexProps={{ maxWidth: '150px', height: '200px', flex: 1, margin: 'auto 0 0' }}
      data={diagramData}
      minBarColumnSize={5}
      diagramPlugins={[{ value: horizontalLineValue, plugin: horizontalLinePlugin }]}
      labelCallback={label => `${Number(label).toLocaleString('de-DE')} km`}
    />
  );
};
