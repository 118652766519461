export const encodeBase64 = (data: any) => {
  return window.btoa(unescape(encodeURIComponent(data)));
};

export const decodeBase64 = (data: string) => {
  return decodeURIComponent(escape(window.atob(data)));
};

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
