import React from 'react';
import { IconProps } from 'common/interfaces';
import useDefaultIconsColor from 'hooks/use-default-icons-color';

export const DriverLevelIcon = ({ width = 17, height = 20, color: colorProp, fill: fillProp, hovered }: IconProps) => {
  const { defaultColor, defaultFill } = useDefaultIconsColor();
  const color = colorProp || defaultColor;
  const fill = fillProp || defaultFill;

  return (
    <svg width={width} height={height} viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.05529 10.8816C4.79541 10.7393 4.53473 10.5801 4.53473 10.5801C3.05328 9.70914 2.37989 8.1828 2.37989 4.95054C2.37989 2.67 3.95112 1 6.33043 1C8.70973 1 10.281 2.67 10.281 4.95054C10.281 8.1828 9.60758 9.70914 8.12613 10.5801C8.12613 10.5801 7.70469 10.8003 7.40828 10.8816M4.17563 10.3467V11.3344C4.17563 11.8102 3.86137 12.2322 3.40347 12.3669L0.584229 13.2109M8.48537 10.3467V11.3343C8.48537 11.8102 8.79962 12.2322 9.25753 12.3668'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8743 17.5945L14.057 18.9704C14.2013 19.0607 14.3863 18.93 14.3424 18.7681L13.6744 16.3213L15.6941 14.7244C15.8289 14.6186 15.7568 14.4069 15.5843 14.3975L12.9876 14.2606L12.0531 11.8978C11.9903 11.7391 11.7614 11.7391 11.6987 11.8978L10.7641 14.2606L8.16745 14.3975C7.99497 14.4069 7.92284 14.6186 8.05769 14.7244L10.0773 16.3213L9.40934 18.7681C9.36543 18.93 9.55047 19.0607 9.69473 18.9704L11.8743 17.5945Z'
        fill={hovered ? fill : 'none'}
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
