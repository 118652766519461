import { getFormValues } from './get-form-values';
import { createNewTask } from 'services/crm-invoices-service';
import { getFields } from 'components/form/form-view';
import { formFields } from 'components/monatsrechnungen/formFields/formFields';
import { getRequestFormData } from './get-response-data';
import { AnyObject } from 'common/interfaces';
import { AxiosResponse } from 'axios';

interface ValuesTypes {
  description: string;
  schedulend: string;
  subject: string;
  uds_aufgabentyp_fuhrpark: string;
}

interface CreateNewTaskProps {
  itemId: string;
  userId: string;
  logicalName: string;
  showOverlay: () => void;
  hideOverlay: () => void;
  formsLatest: AnyObject;
  closeAlert: () => void;
  dispatchForm: (form: AnyObject) => void;
  snackbarSetup: (message: string, type: string) => void;
  successCallback?: () => void;
}

interface ResponseData {
  data: string;
  isSuccess: boolean;
  level: string;
  messages: string[];
}

export const createNewTaskHandler = (props: CreateNewTaskProps) => {
  getFormValues(props.formsLatest, props.dispatchForm, async (values: ValuesTypes) => {
    props.closeAlert();

    const fields: any[] = [];

    getFields(formFields, (field: AnyObject) => {
      fields.push(field);
    });

    const newTaskData = getRequestFormData(values, fields);
    props.showOverlay();

    try {
      const result: AxiosResponse<ResponseData> = await createNewTask({
        createTask: {
          entityName: 'task',
          attributes: {
            ...newTaskData,
            regardingobjectid: {
              attributeTypeCode: 6,
              attributeValue: {
                id: props.itemId,
                logicalName: props.logicalName,
                name: ''
              }
            },
            uds_isfragesteller: {
              attributeTypeCode: 6,
              attributeValue: {
                id: props.userId,
                logicalName: '',
                name: ''
              }
            },
            ownerid: {
              attributeTypeCode: 6,
              attributeValue: {
                id: '6c0711bd-248c-e711-8123-e0071b6611e1',
                logicalName: 'team',
                name: ''
              }
            },
            uds_aufgabentyp_fuhrpark: {
              attributeTypeCode: 11,
              attributeValue: {
                label: '',
                value: 752560003
              }
            }
          }
        },
        executorContactOrFirm: null
      }).finally(() => {
        props.hideOverlay();
      });

      if (result.data.isSuccess) {
        props.snackbarSetup('Frage wurde erstellt.', 'success');
        props.successCallback && props.successCallback();
      } else {
        props.snackbarSetup('Frage wurde nicht erstellt.', 'error');
      }
    } catch (err) {
      console.log(err);

      props.snackbarSetup('Frage wurde nicht erstellt.', 'error');
    }
  });
};
