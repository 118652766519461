import { AxiosRequestConfig } from 'axios';

class CarDealershipSettingRequestConfig {
  getList = (accountId: string): AxiosRequestConfig => ({
    method: 'POST',
    url: 'autohausEinstellung/getList',
    data: { accountId }
  });
}

export const carDealershipSettingRequestConfig = new CarDealershipSettingRequestConfig();
