import { FormFieldTypes } from 'common/enums';
import { FormFieldModel } from 'common/interfaces';
import { LeasingContractFields } from '../unfolded-task/components/buttons-for-lease-extension/enums';

export const extendLeaseAgreementFormFields: FormFieldModel[] = [
  {
    name: LeasingContractFields.AddedMonths,
    type: FormFieldTypes.Months,
    defaultValue: '',
    label: 'Leasingvertrag verlängern um...',
    keepDataFromField: true,
    validation: {
      required: 'Bitte auswählen',
      min: {
        value: 1,
        message: 'Der Wert in diesem Feld darf nicht unter Eins liegen.'
      },
      max: {
        value: 12,
        message: 'In diesem Feld ist ein Maximalwert von 12 zulässig'
      }
    }
  },
  {
    name: LeasingContractFields.IsChangeMileage,
    type: FormFieldTypes.Toggle,
    defaultValue: false,
    label: 'Laufleistung erhöhen',
    toggleLabels: [
      {
        label: 'Laufleistung erhöhen',
        value: true
      },
      {
        label: 'Laufleistung erhöhen',
        value: false
      }
    ],
    validation: {}
  },
  {
    name: LeasingContractFields.Mileage,
    type: FormFieldTypes.Kilometers,
    defaultValue: '',
    label: 'Laufleistung erhöhen um...',
    keepDataFromField: true,
    hidden: true,
    validation: {
      min: {
        value: 1,
        message: 'Der Wert in diesem Feld darf nicht unter Eins liegen.'
      },
      max: {
        value: 200000,
        message: 'In diesem Feld ist ein Maximalwert von 200000 zulässig'
      }
    }
  },
  {
    name: LeasingContractFields.Comment,
    type: FormFieldTypes.TextArea,
    defaultValue: '',
    keepDataFromField: true,
    label: 'Kommentare',
    validation: {
      maxLength: {
        value: 700,
        message: 'Die maximale Anzahl der verfügbaren Zeichen beträgt 700 Zeichen'
      }
    }
  }
];