import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classes from './filters.module.scss';
import classnames from 'classnames';
import { CloseIcon } from '../../../components/icons/close-icon';
import { MarkIcon } from '../../../components/icons/mark-icon';
import { PrefixForId } from '../../../common/enums';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip/ellipsis-with-tooltip';

const Filter = ({ value, title, children, isOpen, toggleFilterIsOpen, clearFilter, className, style }) => {
  const [isFilterCut, setIsFilterCut] = useState(null);
  const onClearClick = e => {
    clearFilter(title);
    e.stopPropagation();
  };

  const boxRef = useRef();
  const filterTitle = value || title;

  useEffect(() => {
    if (!boxRef.current) {
      return;
    }

    setIsFilterCut(boxRef.current.getClientRects()[0].right > window.innerWidth);

    return () => {
      setIsFilterCut(null);
    };
  }, [isOpen]);

  return (
    <section className={classnames(className, classes.filter)} id={PrefixForId.Filter + title} style={style}>
      <div
        className={classnames(classes.filter_button, { [classes.active]: value })}
        onClick={() => {
          toggleFilterIsOpen(title);
        }}
        id={PrefixForId.Filter + PrefixForId.Button + title}
      >
        <EllipsisWithTooltip tooltip={filterTitle} maxWidth='350px'>
          <span id={PrefixForId.Filter + PrefixForId.Button + PrefixForId.Title + title}>{filterTitle}</span>
        </EllipsisWithTooltip>
        <div>
          {value ? (
            <span
              className={classes.icon}
              onClick={e => {
                onClearClick(e);
              }}
              id={PrefixForId.Filter + PrefixForId.Button + PrefixForId.Close}
            >
              <CloseIcon color={'#FFF'} />
            </span>
          ) : (
            <MarkIcon width={8} height={7} />
          )}
        </div>
      </div>
      {isOpen === title && (
        <div
          ref={boxRef}
          style={{ opacity: isFilterCut === null ? 0 : 1 }}
          className={isFilterCut ? classnames(classes.content, classes.filter_cut_content) : classes.content}
        >
          {children}
        </div>
      )}
    </section>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf
};

export default Filter;
