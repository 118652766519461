import axios from 'axios';
import authService from '../../services/auth-service';
import { API_ENDPOINT } from '../../constants';

export const getRecordsForDealer = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getListForHandler`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};

export const getFiltersForDealer = async (fields = undefined) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getFiltersForHandler`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      fields
    }
  });
};

export const getFiltersForLeasingcompany = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getFiltersForLeasingcompany`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const getRecordsForLeasingcompany = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getListForLeasingCompany`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};

export const getDetails = async params => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/getDetailsForHandler`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};
export const submit = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/updateForHandler`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};
export const submitGlobalReferenceVehicle = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/fahrzeugangebote/updateGlobalForHandler`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getLeasingangebotes = async fahrzeugangeboteId => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getForDriver`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_bezugid: fahrzeugangeboteId
    }
  });
};

export const getLeasingangebotesForGRV = async fahrzeugangeboteId => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getGlobalForDriver`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_bezugid: fahrzeugangeboteId
    }
  });
};

export const getRecalculatedGlobalForDriver = async fahrzeugangeboteId => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getRecalculatedGlobalForDriver`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_bezugid: fahrzeugangeboteId
    }
  });
};

export const getLeasingangebotesForLeasingcompany = async fahrzeugangeboteId => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/getForLeasingcompany`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      uds_bezugid: fahrzeugangeboteId
    }
  });
};

export const updateLeasingangebote = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/updateForLeasingcompany`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const createForGlobalReferenceVehicles = async data => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/createForGlobalReferenceVehicles`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const copyToPersonalConfig = async (leasingangeboteId, personalConfigId, driverSupplement) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/leasingangebote/CopyToPersonalConfig`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      leasingangeboteId,
      personalConfigId,
      driverSupplement
    }
  });
};
