export const COST_TYPE_VALUE = {
  fuelCost: 'Tankkosten'
};

export const statusData = {
  active: {
    label: 'Aktiv',
    value: 0
  },
  inactive: {
    label: 'Inaktiv',
    value: 1
  },
  activeAndInactive: {
    label: 'Alle Fahrzeuge',
    value: 2
  }
};
