import { IconProps } from 'common/interfaces';
import React from 'react';

export const CollapseIcon = ({ width = '12', height = '18', color = '#102F3F', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 12 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.1537 16.3846L5.98448 11.2154L0.815247 16.3846M0.815247 1.61542L5.98448 6.78465L11.1537 1.61542'
      stroke={color}
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);