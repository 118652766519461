/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styles from './car-icon-mobile.module.scss';
import CarDamageMobileIcon from '../icons/car-damage-mobile';
import CarDamageLeftIcon from '../icons/car-damage-left';
import CarDamageRightIcon from '../icons/car-damage-right';
import CarDamageFrontIcon from '../icons/car-damage-front';
import CarDamageBackIcon from '../icons/car-damage-back';
import CarDamageTopIcon from '../icons/car-damage-top';
import { damage } from '../utils';
import { StepProps, DamagePartsItemProps } from '../report-damage.props';

const CarIconMobile = ({ reactHookFormData }: StepProps) => {
  const { watch, setValue } = reactHookFormData;
  useEffect(() => {
    setValue('carImageMobile', 'full');
  }, []);

  const chooseDamage = (color: string, value: number) => {
    let newColors = watch('damageList');
    newColors[value].color = newColors[value].color === color ? '#ACD2E5' : color;
    setValue('damageList', newColors);
    if (
      !!watch('damageParts') &&
      watch('damageParts').find((el: DamagePartsItemProps) => el[0] === damage[value].name)
    ) {
      const filtredArr = watch('damageParts').filter((el: DamagePartsItemProps) => el[0] !== damage[value].name);
      setValue('damageParts', filtredArr);
    } else {
      const damageArr = watch('damageParts');
      const pushItem = [damage[value].name, { value: damage[value].value, type: true }];
      if (Array.isArray(damageArr)) {
        damageArr.push(pushItem);
        setValue('damageParts', damageArr);
      } else {
        setValue('damageParts', [pushItem]);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div id='carIconButton' className={styles.img_container}>
        {watch('carImageMobile') === 'full' && <CarDamageMobileIcon reactHookFormData={reactHookFormData} />}
        {watch('carImageMobile') === 'left' && <CarDamageLeftIcon watch={watch} chooseDamage={chooseDamage} />}
        {watch('carImageMobile') === 'right' && <CarDamageRightIcon watch={watch} chooseDamage={chooseDamage} />}
        {watch('carImageMobile') === 'front' && <CarDamageFrontIcon watch={watch} chooseDamage={chooseDamage} />}
        {watch('carImageMobile') === 'back' && <CarDamageBackIcon watch={watch} chooseDamage={chooseDamage} />}
        {watch('carImageMobile') === 'top' && <CarDamageTopIcon watch={watch} chooseDamage={chooseDamage} />}
      </div>
    </div>
  );
};

export default CarIconMobile;
